import * as React from "react";
import { makeStyles } from "@material-ui/core";
import {
  Avatar,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";

import "react-datepicker/dist/react-datepicker.css";

import {
  addCampRewardsReset,
  addCampRewards,
} from "../../redux/action/addCampRewardsAction";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";
import {
  AddCircleOutlineOutlined,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";
import ErrorMessage from "../../components/ErrorMessage";
import _ from "lodash";
import RedStar from "../../components/RedStar";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useNavigate } from "react-router-dom";
import { getRewardTitle } from "../../redux/action/RewardDetailAction";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import AsyncSelect from "react-select/async";
import { isArray } from "lodash";
import ConfirmationDialog from "../../components/ConfirmationDialog";

import Modal from "react-bootstrap/Modal";
import Buttons from "react-bootstrap/Button";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },

    display: "block",
    display: "flex",
    //fontSize: 16,
    fontSize: "16px",
    marginTop: 16,
    padding: 10,
    fontWeight: "500",
    lineHeight: "18px",
    minHeight: 50,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {},

    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroupFull: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      // width: "25%"
      width: "20%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
}));

function AdminRewards(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rewardtitle, setRewardtitle] = React.useState([
    {
      rewardchoice: "",
      rewardtype: "",
      title: "",
      subtitle: "",
      availablebudget: "",
      allocatedbudget: "",
      points: "",
      pf: "",
      auto: "",
      camp: "",
      count: "",
      titleList: "",
      logo: "",
    },
  ]);
  const [selected1, setSelected1] = React.useState([]);
  const [sponsor, setSponsor] = React.useState([]);
  const [focusError, setFocusError] = React.useState(false);
  const [newField, setNewField] = React.useState(false);
  const [newRewardField, setNewRewardField] = React.useState(false);
  const [areas, setAreas] = React.useState([]);
  const [cityResponse, setCityResponse] = React.useState({});
  const [rewardTypeList, setRewardTypeList] = React.useState(null);
  const [rewardChoice, setRewardChoice] = React.useState([
    {
      choice: "Yes",
    },
    {
      choice: "No",
    },
  ]);
  const [rewardDetailResponseEffect, setrewardDetailResponseEffect] =
    React.useState({});
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    React.useState(false);
  const [addRewardsValues, setaddRewardsValues] = React.useState({
    rewardtype: "",
    title: "",
    subtitle: "",
    sponsor: "",
    points: "",
    pf: "",
    camp: "",
    auto: "",
    count: "",
  });
  const [addRewardsValuesError, setaddRewardsValuesError] = React.useState({
    rewardtypeError: "",
    titleError: "",
    subtitleError: "",
    sponsorError: "",
    allocatedbudgetError: "",
    pointsError: "",
    pfError: "",
    campError: "",
    autoError: "",
    countError: "",
  });
  const [campName, setCampName] = React.useState([]);
  const [globalIndex, setGlobalIndex] = React.useState();
  const [selectedcampname, setselectedcampname] = React.useState([]);
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
    navigate("/marketplace");
  };

  const {
    titleSuccess: rewardDetailSuccess,
    titleResponse: rewardDetailResponse,
  } = useSelector((state) => state.getRewardTitle);
 

  const {
    addcamprewardsSuccess: isAddRewardsSuccess,
    addcamprewardsResponse: addRewardsResponse,
    addcamprewardsError: isAddRewardsError,
    addcamprewardsFetching: isAddrewardsFetching,
  } = useSelector((state) => state.addCampRewards);
  

  const handleChangeRewards1 = (id, event, field, index) => {
   
    // const value = event.target.value;
    const value = [...rewardtitle];
    if (field === "rewardtype") {
      value[index]["titleList"] = rewardDetailResponseEffect;
    }
    if (field === "sponsor") {
      value[index][field] = event;
    } else if (field === "camp") {
      value[index][field] = event[0]["label"];
    } else {
      value[index][field] = event.target.value;
    }
    setRewardtitle(value);
  

    // setSelected([...selected, value[value?.length - 1]]);
  };

  const handleAddRewards = () => {
    
    // console.log(rewardtypeError);
    // console.log(titleError);
    // console.log(subtitleError);
    // console.log(sponsorError);
    // console.log(pointsError);
    // if (addRewardsValues.rewardtype) {
    //   setaddRewardsValuesError({
    //     ...addRewardsValuesError,
    //     rewardtypeError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else if (!addRewardsValues?.subtitle) {
    //   setaddRewardsValuesError({
    //     ...addRewardsValuesError,
    //     subtitleError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else if (!addRewardsValues?.sponsor) {
    //   setaddRewardsValuesError({
    //     ...addRewardsValuesError,
    //     sponsorError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else if (!addRewardsValues?.points) {
    //   setaddRewardsValuesError({
    //     ...addRewardsValuesError,
    //     pointsError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else {

    let formData = new FormData();
    formData.append("rewardtitle", JSON.stringify(rewardtitle));
    dispatch(
      // onboardCampaign({ userid: localStorage.getItem("userid"), formData })
      addCampRewards({ userid: localStorage.getItem("userid"), formData })
    );
    setDisplayConfirmationModal(true);
    dispatch(addCampRewardsReset());
    setRewardtitle([
      {
        rewardtype: "",
        title: "",
        subtitle: "",
        availablebudget: "",
        allocatedbudget: "",
        points: "",
        pf: "",
        auto: "",
        camp: "",
        count: "",
        logo: "",
        // titleList: "",
      },
    ]);
    setselectedcampname("");
    //  }
  };

  const getRewardDetailList = async (i, valll) => {
    try {
      const res = await axios
        .get(`/getrewardtitle/${valll}`)
        .then((response) => {
          const values = [...rewardtitle];
          values[i]["titleList"] = response?.data;
          return response?.data;
        });
    } catch (err) {
      throw err;
    }
  };

  const getRewardTypeLogo = async (i, valll) => {
    try {
      const res = await axios
        .get(`/getrewardtypelogo/${valll}`)
        .then((response) => {
          const values = [...rewardtitle];
          values[i]["titleList"] = response?.data;
          return response?.data;
        });
    } catch (err) {
      throw err;
    }
  };

  const getSponsorList = async () => {
    try {
      const res = await axios.get(`/sponsor`).then((response) => {
        // const values = [...rewardtitle];

        setSponsor(response?.data);
        return response?.data;
      });
    } catch (err) {
      throw err;
    }
  };

  const getRewardTypeList = async () => {
    try {
      const res = await axios.get(`getrewardtypes`);
      setRewardTypeList(res?.data);
     
      return res;
      // }
    } catch (err) {
      throw err;
    }
  };

  const getCampAvailableBudget = async (i, campname) => {
   
    try {
      const res = await axios
        .get(`/camp/availbudget/${campname}`)
        .then((response) => {
          const values = [...rewardtitle];
          values[i]["camp"] = response?.data?.[0]?.title;
          values[i]["availablebudget"] = response?.data?.[0]?.totalbudget;
          // values[i]["titleList"] = response?.data;
          setRewardtitle(values);
       
          return response?.data;
        });
    } catch (err) {
      throw err;
    }
  };

  const getPFAvailableBudget = async (i) => {
   
    try {
      const res = await axios.get(`/pf/availbudget`).then((response) => {
        const values = [...rewardtitle];
        // values[i]["camp"] = response?.data?.[0]?.title;
        values[i]["availablebudget"] = response?.data?.[0]?.totalbudget;
        // values[i]["titleList"] = response?.data;
        setRewardtitle(values);
        
        return response?.data;
      });
    } catch (err) {
      throw err;
    }
  };

  const getCountForPoints = async (i, allocatedbudget) => {
   
    try {
      const res = await axios
        .get(
          `/count/forpoints/${selectedcampname[0]["label"]}/${allocatedbudget}`
        )
        .then((response) => {
          const values = [...rewardtitle];
          // values[i]["camp"] = response?.data?.[0]?.title;
          values[i]["points"] = response?.data?.[0]?.points;
          values[i]["count"] = response?.data?.[0]?.count;
          // values[i]["titleList"] = response?.data;
          setRewardtitle(values);
         
          return response?.data;
        });
    } catch (err) {
      throw err;
    }
  };

  const handleRewardAddFields = () => {
   

    // console.log("index = ", index)
    // setNewRewardField(true);
    if (rewardtitle.length > 0) {
      
      for (let i = 0; i < rewardtitle.length; i++) {
        

        

        if (
          !(rewardtitle?.[rewardtitle.length - 1].rewardtype === "") &&
          !(rewardtitle?.[rewardtitle.length - 1].title === "") &&
          !(rewardtitle?.[rewardtitle.length - 1].subtitle === "") &&
          !(rewardtitle?.[rewardtitle.length - 1].allocatedbudget === "") &&
          !(rewardtitle?.[rewardtitle.length - 1].points === "") &&
          !(rewardtitle?.[rewardtitle.length - 1].count === "") &&
          !(
            rewardtitle?.[rewardtitle.length - 1].pf === "" &&
            rewardtitle?.[rewardtitle.length - 1].camp === ""
          ) &&
          !(
            rewardtitle?.[rewardtitle.length - 1].pf === "No" &&
            rewardtitle?.[rewardtitle.length - 1].camp === ""
          ) &&
          ((rewardtitle?.[rewardtitle.length - 1].pf === "No" &&
            !(selectedcampname.length === 0)) ||
            (rewardtitle?.[rewardtitle.length - 1].pf === "Yes" &&
              selectedcampname.length === 0) ||
            (rewardtitle?.[rewardtitle.length - 1].pf === "" &&
              !(selectedcampname.length === 0)))
        ) {
          // setselectedcampname("")
          setGlobalIndex(i);
          setRewardtitle([
            ...rewardtitle,
            {
              rewardtype: "",
              title: "",
              subtitle: "",
              availablebudget: "",
              allocatedbudget: "",
              points: "",
              pf: "",
              auto: "",
              camp: "",
              count: "",
              titleList: "",
              logo: "",
            },
          ]);
        } else {
          
        }
      }
    }
  };



  const loadOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = campName.filter((category) =>
        category.label.toLowerCase().includes(searchValue.toLowerCase())
      );
      
      callback(filteredOptions);
    });
  };

  React.useEffect(() => {
    getCampName();
    // dispatch(addRewardsReset());
  }, []);

  // React.useEffect(() => {
  //   getPFAvailableBudget();
  //   getCampAvailableBudget();
  // }, [globalIndex]);

  const getCampName = async () => {
    try {
      const res = await axios.get(`/camp/name/${profileResponse?.phoneno}`);
      setCampName(res?.data);

      return res;
    } catch (err) {
      throw err;
    }
  };

  const colorStyles = {
    control: (styles) => ({ ...styles }),
    // option: (styles, {data, isDisabled, isFocussed, isSelected}) => {
    //   return{...styles, color: "blue"};
    // },
    multiValue: (styles, { data }) => {
      return { ...styles, backgroundColor: "#0096FF", color: "#fff" };
    },
    multiValueLabel: (styles, { data }) => {
      return { ...styles, color: "#fff" };
    },
    multiValueRemove: (styles, { data }) => {
      return {
        ...styles,
        color: "#fff",
        cursor: "pointer",
        ":hover": {
          color: "#fff",
        },
      };
    },
  };

  const getRewardDetails = (itemreward, index) => {
    return (
      <div style={{ float: "left", width: "75%", marginTop: 50 }}>
        <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>
          Reward Details
        </span>{" "}
        {/* <span
          style={{
            paddingLeft: 0,
            fontSize: 16,
            fontWeight: 300,
            fontStyle: "italic",
          }}
        >
          {" "}
          ("Area" field is optional, click on "+" icon to enter additional
          locations)
        </span> */}
        <form
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            float: "left",
          }}
        >
          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Campaign
              <RedStar />
            </label>
            <AsyncSelect
              loadOptions={loadOptions}
              defaultOptions={campName}
              value={selectedcampname}
              // value={itemreward.camp}
              isMulti
              placeholder="Select Campaign"
              isOptionDisabled={() => selectedcampname.length >= 1}
              styles={colorStyles}
              onChange={(e) => {
                // dispatch(addRewardsReset());
                setselectedcampname(e);
                handleChangeRewards1(itemreward, e, "camp", index);
                // if (selectedcampname) {
                //   itemreward.pf = "No"
                //   handleChangeRewards1(itemreward, e, "pf", index);
                //   getCampAvailableBudget(index, itemreward.camp);
                // }
                getCampAvailableBudget(index, itemreward.camp);

                // console.log(selectedcampname);
              }}
            />
          </div>

          {/* {profileResponse?.orgrole === "cyaagadmin" ? (
            selectedcampname.length > 0 ? (
              <div className={classes.formGroup1}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Platform Reward <RedStar />
                </label>
                <Select
                  disabled
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                  value="No"
                  onBlur={() => {
                    if (!itemreward.pf) {
                      setaddRewardsValuesError({
                        ...addRewardsValuesError,
                        pfError: true,
                      });
                    } else {
                      setaddRewardsValuesError({
                        ...addRewardsValuesError,
                        pfError: false,
                      });
                    }
                  }}
                  onChange={(e) => {
                    handleChangeRewards1(itemreward, e, "pf", index);
                    setselectedcampname("");
                    // if (itemreward.pf === "Yes" || itemreward.pf === "yes") {
                    //   getPFAvailableBudget(index);
                    // }
                    // dispatch(addRewardsReset());
                    
                    // dispatch(getRewardTitle(item.rewards));
                  }}
                >
                  {rewardChoice.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.choice}>
                      {item.choice}
                    </MenuItem>
                  ))}
                </Select>
                
              </div>
            ) : (
              <div className={classes.formGroup1}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Platform Reward <RedStar />
                </label>
                <Select
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                  value={itemreward.pf}
                  onBlur={() => {
                    if (!itemreward.pf) {
                      setaddRewardsValuesError({
                        ...addRewardsValuesError,
                        pfError: true,
                      });
                    } else {
                      setaddRewardsValuesError({
                        ...addRewardsValuesError,
                        pfError: false,
                      });
                    }
                  }}
                  onChange={(e) => {
                    handleChangeRewards1(itemreward, e, "pf", index);
                    
                    if (itemreward.pf === "Yes" || itemreward.pf === "yes") {
                      getPFAvailableBudget(index);
                    }
                    // dispatch(getRewardTitle(item.rewards));
                  }}
                >
                  {rewardChoice.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.choice}>
                      {item.choice}
                    </MenuItem>
                  ))}
                </Select>
                {addRewardsValuesError?.pfError && (
                  <ErrorMessage message="Please select the reward choice for this reward" />
                )}
              </div>
            )
          ) : (
            ""
          )} */}

          <div className={classes.formGroup1}>
            <label
              htmlFor="create-yaad--title"
              className={classes.label}
              // style={{ marginTop: "0px", fontSize: 15 }}
            >
              Available Budget
            </label>

            <input
              disabled
              // style={{
              //   display: "flex",
              //   flexDirection: "row",
              //   alignItems: "flex-end",
              //   height: "40px",
              //   border: "1px solid",
              // }}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                border: "1px solid",
              }}
              // value={sponsorAvailBudget?.[0]?.initialbudget}
              value={itemreward?.availablebudget}
              // defaultValue={itemreward.sponsorBudget}
              // value={rewardtitle?.[index]?.titleList?.[0]?.initialbudget}
              type="number"
              id="create-yaad--title"
              className={classes.input}
              placeholder="Budget"

              // onChange={(e) =>
              //   handleChangeRewards1(itemreward, e, "sponsoravailbudget", index)
              // }
            />
          </div>

          <div className={classes.formGroup1}>
            <label
              htmlFor="create-yaad--title"
              className={classes.label}
              // style={{ marginTop: "0px", fontSize: 15 }}
            >
              Budget allocated
              <RedStar />
            </label>
            <input
              // style={{
              //   display: "flex",
              //   flexDirection: "row",
              //   alignItems: "flex-end",
              //   height: "40px",
              //   border: "1px solid",
              // }}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                border: "1px solid",
              }}
              value={itemreward?.allocatedbudget}
              // defaultValue={itemreward.sponsorBudget}
              type="number"
              id="create-yaad--title"
              className={classes.input}
              placeholder="Budget"
              onBlur={() => {
                if (!itemreward.allocatedbudget) {
                 
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    allocatedbudgetError: true,
                  });
                } else {
                  if (
                    Number(itemreward?.allocatedbudget) >
                    rewardtitle?.[index]?.sponsorname?.[0]?.availablebudget
                  ) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      allocatedbudgetError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      allocatedbudgetError: false,
                    });
                  }
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    allocatedbudgetError: false,
                  });
                }
                // if (!Number(itemreward.sponsorbudget)) {
                //   console.log("Error");

                //   setaddSponsorError({
                //     ...addSponsorError,
                //     sponsorBudgetError: true,
                //   });
                // } else {
                //   setaddSponsorError({
                //     ...addSponsorError,
                //     sponsorBudgetError: false,
                //   });
                // }
              }}
              onChange={(e) => {
                handleChangeRewards1(itemreward, e, "allocatedbudget", index);
                if (itemreward.pf === "" || itemreward.pf === "No") {
                  getCountForPoints(index, itemreward.allocatedbudget);
                }
              }}
            />
            {addRewardsValuesError?.allocatedbudgetError && (
              <ErrorMessage message="Please enter the budget to be allocated for this reward" />
            )}
            {addRewardsValuesError.allocatedbudgetError && (
              <ErrorMessage message="You have exceeded the budget to be allocated" />
            )}
          </div>

          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Points
              <RedStar />
            </label>
            <input
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                border: "1px solid",
              }}
              value={itemreward?.points}
              type="number"
              id="create-yaad--title"
              className={classes.input}
              placeholder="Points"
              onBlur={() => {
                if (!itemreward.points) {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    pointsError: true,
                  });
                } else {
                  if (!Number(itemreward.points)) {
                    

                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      pointsError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      pointsError: false,
                    });
                  }
                }
              }}
              onChange={(e) =>
                handleChangeRewards1(itemreward, e, "points", index)
              }
            />
            {addRewardsValuesError?.pointsError && (
              <ErrorMessage message="Please enter the points for this reward" />
            )}
          </div>

          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Count <RedStar />
            </label>
            <input
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
              value={itemreward?.count}
              type="number"
              id="create-yaad--title"
              className={classes.input}
              placeholder="Count"
              onBlur={() => {
                if (!itemreward.count) {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    countError: true,
                  });
                } else {
                  if (!Number(itemreward.count)) {
                

                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      countError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      countError: false,
                    });
                  }
                }
              }}
              onChange={(e) =>
                handleChangeRewards1(itemreward, e, "count", index)
              }
            />
            {addRewardsValuesError?.pointsError && (
              <ErrorMessage message="Please enter the count for this reward" />
            )}
          </div>

          {/* <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Recognition <RedStar />
            </label>
            <Select
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
              value={itemreward.auto}
              onBlur={() => {
                if (!itemreward.auto) {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    autoError: true,
                  });
                } else {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    autoError: false,
                  });
                }
              }}
              onChange={(e) => {
                handleChangeRewards1(itemreward, e, "auto", index);
                // dispatch(getRewardTitle(item.rewards));
              }}
            >
              {rewardChoice.map((item, index) => (
                <MenuItem style={{ fontSize: 16 }} value={item.choice}>
                  {item.choice}
                </MenuItem>
              ))}
            </Select>
            {addRewardsValuesError?.autoError && (
              <ErrorMessage message="Please select the reward choice for this reward" />
            )}
          </div> */}

          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Reward Type <RedStar />
            </label>
            <Select
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
              value={itemreward.rewardtype}
              onBlur={() => {
                if (!itemreward.rewardtype) {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    rewardtypeError: true,
                  });
                } else {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    rewardtypeError: false,
                  });
                }
              }}
              onChange={(e) => {
                handleChangeRewards1(itemreward, e, "rewardtype", index);
                // handleChangeRewards1(itemreward, e, "logo", index);
                // dispatch(getRewardTitle(item.rewards));
               
                getRewardDetailList(index, itemreward.rewardtype);
                dispatch(getRewardTitle(itemreward.rewardtype));
              
              }}
            >
              {rewardTypeList &&
                rewardTypeList.map((item, index) => (
                  <MenuItem style={{ fontSize: 16 }} value={item.rewards}>
                    {item.rewards}
                  </MenuItem>
                ))}
            </Select>
            {addRewardsValuesError?.rewardtypeError && (
              <ErrorMessage message="Please enter the reward type for this reward" />
            )}
          </div>
          {(isArray(rewardtitle) && index !== rewardtitle.length - 1) ||
          (index === rewardtitle.length - 1 &&
            rewardtitle[index].title !== "") ||
          index === 0 ? (
            <div className={classes.formGroup1}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Reward Title <RedStar />
              </label>
              <Select
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
                // value={itemreward?.titlelist?.[0].title}
                value={itemreward.title}
                onBlur={() => {
                  if (!itemreward.title) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      titleError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      titleError: false,
                    });
                  }
                }}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "title", index);
                }}
              >
                {rewardDetailSuccess &&
                  isArray(rewardtitle[index]?.titleList) &&
                  rewardtitle[index]?.titleList.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.title}>
                      {item.title}
                    </MenuItem>
                  ))}
              </Select>
              {addRewardsValuesError?.titleError && (
                <ErrorMessage message="Please enter the title for this reward" />
              )}
            </div>
          ) : (
            <div className={classes.formGroup1}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Reward Title <RedStar />
              </label>
              <Select
                // style={{
                //   width: "50%",
                //   fontSize: 14,
                //   height: 56,
                //   boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                // }}

                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
                // value={itemreward?.titlelist?.[0].title}
                value={itemreward.title}
                onBlur={() => {
                  if (!itemreward.title) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      titleError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      titleError: false,
                    });
                  }
                }}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "title", index);
                }}
              >
                {rewardDetailSuccess &&
                  rewardDetailResponse.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.title}>
                      {item.title}
                    </MenuItem>
                  ))}
              </Select>
              {addRewardsValuesError?.titleError && (
                <ErrorMessage message="Please enter the title for this reward" />
              )}
            </div>
          )}

          {(isArray(rewardtitle) && index !== rewardtitle.length - 1) ||
          (index === rewardtitle.length - 1 &&
            rewardtitle[index].subtitle !== "") ||
          index === 0 ? (
            <div className={classes.formGroup1}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Reward Sub Title <RedStar />
              </label>
              <Select
                // style={{
                //   width: "50%",
                //   fontSize: 14,
                //   height: 56,
                //   boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                // }}

                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
                // value={itemreward?.titlelist?.[0].subtitle}
                // value={itemreward.subtitle}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "subtitle", index);
                }}
                onBlur={() => {
                  if (!itemreward.subtitle) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      subtitleError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      subtitleError: false,
                    });
                  }
                }}
              >
                {rewardDetailSuccess &&
                  isArray(rewardtitle[index]?.titleList) &&
                  rewardtitle[index]?.titleList.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.subtitle}>
                      {item.subtitle}
                    </MenuItem>
                  ))}
              </Select>
              {addRewardsValuesError?.subtitleError && (
                <ErrorMessage message="Please enter the subtitle for this reward" />
              )}
            </div>
          ) : (
            <div className={classes.formGroup1}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Reward Sub Title <RedStar />
              </label>
              <Select
                // style={{
                //   width: "50%",
                //   fontSize: 14,
                //   height: 56,
                //   boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                // }}

                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
                // value={itemreward?.titlelist?.[0].subtitle}
                // value={itemreward.subtitle}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "subtitle", index);
                }}
                onBlur={() => {
                  if (!itemreward.subtitle) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      subtitleError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      subtitleError: false,
                    });
                  }
                }}
              >
                {rewardDetailSuccess &&
                  rewardDetailResponse.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.subtitle}>
                      {item.subtitle}
                    </MenuItem>
                  ))}
              </Select>
              {addRewardsValuesError?.subtitleError && (
                <ErrorMessage message="Please enter the subtitle for this reward" />
              )}
            </div>
          )}

          {/* <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Sponsor Name <RedStar />
            </label>

            <Multiselect
              displayValue="sponsor"
              showCheckbox
              selectedValues={selected1}
              options={sponsor}
              selectionLimit={2}
              onSelect={(event) => {
                handleChangeRewards1(itemreward, event, "sponsor", index);
                if (_.isEmpty(event)) {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    sponsorError: true,
                  });
                } else {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    sponsorError: false,
                  });
                }
              }}
              value={selected1}
              style={{
                multiselectContainer: {
                  boxShadow: "0px 3px 6px #00000029",
                },
                searchBox: {
                  minHeight: 54,
                  PaddingTop: 10,
                },
              }}
            />

            {addRewardsValuesError?.sponsorError && (
              <ErrorMessage message="Please enter the Sponsor name for this reward" />
            )}
          </div> */}
        </form>
        {/* {index ? (
          <div
            style={{
              float: "right",
              position: "relative",
              bottom: 44,
              cursor: "pointer",
              left: 19,
            }}
            onClick={(e) => handleRemoveFields(index)}
          >
            <RemoveCircleOutlineOutlined></RemoveCircleOutlineOutlined>
          </div>
        ) : (
          <div
            className="handlePlus"
            style={{
              float: "right",
              bottom: 45,
              position: "relative",
              left: 20,
              cursor: "pointer",
              right: "15px",
            }}
          >
            <AddCircleOutlineOutlined
              onClick={handleRewardAddFields}
            ></AddCircleOutlineOutlined>
          </div>
        )} */}
      </div>
    );
  };

  React.useEffect(() => {
    setRewardtitle([
      {
        rewardtype: "",
        title: "",
        subtitle: "",
        availablebudget: "",
        allocatedbudget: "",
        points: "",
        pf: "",
        auto: "",
        camp: "",
        count: "",
        titleList: "",
        logo: "",
      },
    ]);
  }, []);

  React.useEffect(() => {
    getRewardTypeList();
  }, []);
 

  React.useEffect(() => {
    getSponsorList();
  }, []);
 

  const [showPreviewModal, setShowPreviewModal] = React.useState(false);

  const handleOpenPreview = () => {
   
    
    setShowPreviewModal(true);
    
    
  };

  const hidePreviewModal = () => {
    setShowPreviewModal(false);
  };
 

  
  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Add Campaign Reward
            </div>
            <div style={{ fontFamily: "monospace", fontSize: 16 }}>
              <div
                style={{
                  display: "flex",
                  width: "75%",
                  justifyContent: "flex-end",
                  marginRight: 5,
                  marginBottom: 20,
                }}
              >
                <span style={{ color: "red" }}>
                  Please do not leave this page until you submit as you may risk
                  losing the partially filled in form{" "}
                </span>
              </div>
            </div>
            <form autoComplete="off">
              <div
                style={{
                  border: "1px solid #dadce0",
                  borderRadius: 8,
                  backgroundColor: "#fff",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {/* <Button
                variant="contained"
                style={{ float: "right" }}
                onClick={handleOpenPreview}
                className="btn previewCampaign"
                disableElevation
              >
                <span>Preview</span>
              </Button> */}
                  <div className={classes.formGroupFull}>
                    {rewardtitle.map((itemreward, index) => (
                      <div key={index}>
                        {getRewardDetails(itemreward, index)}
                      </div>
                    ))}
                    {/* <AddCircleOutlineOutlined
                  formStyle={{ paddingTop: "20px" }}
                  onClick={handleAddFields}
                ></AddCircleOutlineOutlined> */}
                  </div>
                </div>
                {/* <div>
                  {isAddRewardsSuccess && (
                    <span
                      style={{
                        color: "green",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      {addRewardsResponse?.message}
                    </span>
                  )}
                  {isAddRewardsError && (
                    // <span style={{ color: "red" }}>Error occured</span>
                    <span
                      style={{
                        color: "red",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      {addRewardsResponse?.message}
                    </span>
                  )}
                </div> */}
                <div
                  className="centerAlign"
                  style={{ padding: "16px 0", marginBottom: 50 }}
                >
                  <Button
                    variant="contained"
                    onClick={handleAddRewards}
                    className="btn save-btn"
                    disableElevation
                    disabled={isAddrewardsFetching}
                  >
                    {isAddrewardsFetching ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      <span>Submit</span>
                    )}
                  </Button>
                  <br />
                  {/* {isCampaignSuccess && 
                  <span style={{ color: "green", textAlign: "center", display: "block" }}>{campaignResponse?.message}</span>}
                  {isCampaignError && (
                    // <span style={{ color: "red" }}>Error occured</span>
                    <span style={{ color: "red", textAlign: "center", display: "block" }}>{campaignResponse?.message}</span>
                  )} */}
                </div>
              </div>
            </form>
          </div>
        </div>
        <CopyRight />

        <FixedFooterIcon />
        <ConfirmationDialog
          showModal={displayConfirmationModal}
          // confirmModal={handleRedeem}
          hideModal={hideConfirmationModal}
          // message={"You do not have enough points to redeem"}
          message={
            addRewardsResponse?.message +
            ". You will be redirected to Marketplace"
          }
        />
      </section>

      <Modal show={showPreviewModal} onHide={hidePreviewModal}>
        <div class="modal-header">
          <h5 class="modal-title">Preview Reward</h5>
          <buttons
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={hidePreviewModal}
          >
            <span aria-hidden="true">&times;</span>
          </buttons>
        </div>
        <Modal.Body>
          <div
            class="marketplaceinrow"
            style={{ marginBottom: "30px", float: "left" }}
          >
            <div class="reward-wrap-marketplace">
              <div style={{ maxHeight: "400px", position: "relative" }}>
                <div>
                
                  <img
                    class="reward-image"
                    // src={reward?.logo}
                    src={"image"}
                    style={{ width: "100%", opacity: 0.4 }}
                  />
                    
                </div>
              </div>
              <div class="reward-details">
                <div>
                  <div>
                    <div id="toggleAccordion">
                      <div class="card mb-1">
                        <div
                          class="card-header"
                          id="headingOne4"
                          style={{
                            borderBottom: "0px",
                            backgroundColor: "white",
                          }}
                        >
                          <h5
                            class="mb-0 mt-0 reward-desc-title"
                            title={rewardtitle?.[0]?.camp}
                            // title="Reward Type"
                          >
                            <span
                              role="menu"
                              class=""
                              data-toggle="collapse"
                              // data-target={"#" + index}

                              aria-expanded="true"
                              aria-controls="iconChangeAccordionOne"
                            >
                              {rewardtitle?.[0]?.rewardtype}
                              
                              <i
                                style={{
                                  marginLeft: "2px",
                                }}
                                class="flaticon-down-arrow float-right"
                              ></i>
                            </span>
                          </h5>
                        </div>

                        <div
                          // id={index}
                          id={0}
                          class="collapse"
                          aria-labelledby="headingOne4"
                          data-parent="#toggleAccordion"
                        >
                          <div class="card-body">
                            <p class="mb-3">
                              <div className={classes.subtitleWrapper}>
                                <span
                                  style={{
                                    fontSize: 12,
                                    color: "blue",
                                  }}
                                >
                                  {" "}
                                  {rewardtitle?.[0]?.subtitle}{" "}
                                  
                                  
                                </span>
                              </div>
                            </p>
                            <p class="mb-3">
                              <div className={classes.subtitleWrapper}>
                                <span
                                  style={{
                                    fontSize: 12,
                                    color: "green",
                                  }}
                                >
                                  {" "}
                                  {rewardtitle?.[0]?.title}{" "}
                                  
                                </span>
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="redeem-pts">
                    <div class="row">
                      {/* <div class="col-6" style={{ display: "inline-flex" }}>
                        {reward?.rewardcat === "rewards" &&
                        reward?.campid === "camp00001" ? (
                          <div class="re-po-left">
                            <img src="https://png.pngtree.com/png-clipart/20220213/original/pngtree-coins-stack-icon-vector-png-image_7267762.png" />
                            <div class="pts">
                              <h5>{reward?.points}</h5>
                              Points 
                              <span>Points</span>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {(reward?.campid === "camp00001" &&
                          reward?.rewardcat === "rewards" &&
                          !(reward?.expired === "yes")) ||
                        (reward?.registrationstatus === "approved" &&
                          !(reward?.campid === "camp00001") &&
                          !(reward?.expired === "yes") &&
                          reward?.rewardcat === "rewards") ||
                        (reward?.registrationstatus === "approved" &&
                          !(reward?.campid === "camp00001") &&
                          !(reward?.expired === "yes") &&
                          reward?.rewardcat === "recognitions" &&
                          reward?.recogeligibility === "yes") ? (
                          <div class="re-po-right">
                            <Button
                              onClick={() => setDisplayAndIndex(true, index)}
                              className={classes.button}
                              style={{ float: "right" }}
                            >
                              <a>Redeem</a>
                            </Button>
                          </div>
                        ) : (
                          // </div>
                          // <div class="col-12">
                          <div class="re-po2-center">
                            <Button
                              disabled
                              className={classes.button}
                              // style={{ float: "right" }}
                            >
                              <a>Redeem</a>
                            </Button>
                          </div>
                          // </div>
                        )}
                      </div> */}
                    </div>
                  </div>

                  <div class="redeem-pts1">
                    <div class="row">
                      <div class="col-12">
                        {/* <div class="re-po1-right">
                          {!(reward?.campid === "camp00001") ? (
                            <Button
                              onClick={() =>
                                navigate(
                                  `/participate/${reward?.campid}/${profileResponse?.phoneno}`
                                )
                              }
                              style={{
                                // float: "right",
                                // width: "114px",
                                textAlign: "left",
                                maxHeight: "72px",
                              }}
                            >
                              <a
                                style={{
                                  fontFamily: "sans-serif",
                                  //   textOverflow:"ellipsis",
                                  // overflow: "hidden",
                                }}
                              >
                                {reward?.campname}
                              </a>
                            </Button>
                          ) : (
                            <Button
                              onClick={() => navigate(`/aboutus`)}
                              style={{
                                // float: "right",
                                // width: "114px",
                                textAlign: "left",
                                maxHeight: "72px",
                              }}
                            >
                              <a
                                style={{
                                  fontFamily: "sans-serif",
                                }}
                              >
                                {reward?.campname}
                              </a>
                            </Button>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>

                  <div class="text-center">
                    <h6 class="sponsor-title">Sponsors</h6>
                    <div class="row sponserWrapper">
                      <div class="">
                      <CheckBoxOutlineBlankIcon style={{
                          width: "auto",
                          height: "100px",
                        }}/>
                      {/* <img
                        style={{
                          width: "auto",
                          height: "72px",
                        }}
                        // src={reward?.sponsorlogo}
                        
                      /> */}
                        
                        
                      </div>
                      {/* <div class="">
                        {isArray(reward?.sponsor) &&
                          reward?.sponsor?.map((reward, index) => (
                            <img
                              style={{
                                width: "auto",
                                height: "72px",
                              }}
                              src={reward?.sponsorlogo}
                            />
                          ))}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Buttons variant="default" onClick={hidePreviewModal}>
            Ok
          </Buttons>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default AdminRewards;
