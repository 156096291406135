import * as React from "react";
import { useEffect, useState } from 'react';
import FormControl from "@mui/material/FormControl";
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Input,
} from "@material-ui/core";
import {
  Avatar,
  Button,
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from 'multiselect-react-dropdown';

// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import ResponsiveDrawer from "../../components/Header";
// import { CopyRight } from "../../components/CopyRight";
// import { CopyRightForSome } from "../../components/CopyRightForSome";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";

import _ from "lodash";
import RedStar from "../../components/RedStar";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useNavigate } from "react-router-dom";

import VideoSideBar from "../../components/VideoSideBar";
import VideoCard from "../../components/VideoCard";
import UploadFiles1Button from "../../components/UploadFiles1Button";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";


const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },

  
}));

function MyOngoingCampaign(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = React.useRef(null);
  const inputRefBanner = React.useRef(null);
  const videoRef = React.useRef(null);
  const [campregisterlist, setCampRegisterList] = useState([]);
  const [campID, setCampID] = useState([]);
  const [type, setType] = useState("public");
  // const [selectedCategory, setSelectedCategory] = useState("Save Cheetah");
  // const [selectedCategory, setSelectedCategory] = useState("Better Life");
  const [selectedCategory, setSelectedCategory] = useState("cYAAG");
  // const [videos, setVideos] = useState([]);
  // const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
  //   (state) => state.profile
  // );
  
  const resetFile = (event) => {
    inputRef.current.value = null;
    // inputRefBanner.current.value = null;
    videoRef.current.value = null;
  };
  
  const handleTriggerFunction = () => {
   
    const timeoutId = setTimeout(() => {
      getCampRegistrationList();
    }, 1000);
    console.log('Function triggered from ChildComponent');
  };

  const getCampRegistrationList = async () => {
    console.log("============= Calling again==========");
    try {
      // const res = await axios.get(
      //   `/camp/video/${selectedCategory}/${localStorage.getItem("userid")}`
      // );
      const res = await axios.get(
        `/list/files/campaign/${selectedCategory}/${localStorage.getItem("userid")}`
      );
      setCampRegisterList(res?.data);
      // JSON.stringify(res)
     
      return res;
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    getCampRegistrationList();

  }, [selectedCategory]);

 

  const getCampID = async () => {
    try {
      // const res = await axios.get(
      //   `/camp/video/${selectedCategory}/${localStorage.getItem("userid")}`
      // );
      const res = await axios.get(
        // `/get/campaignid/${selectedCategory}/${localStorage.getItem("userid")}`
        `/camp/video/name/${selectedCategory}/${localStorage.getItem("userid")}`
      );
      setCampID(res?.data);
      // JSON.stringify(res)
     
      return res;
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    getCampID();

  }, [selectedCategory]);

  
  

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      {/* <section className="containersVideo"> */}
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
          <Button
                onClick={() => navigate(-1)}
                className={classes.backBtn}
            >
                <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <Stack sx={{flexDirection: {sx: "column", md:"row"}}}>
              <Box sx={{height: {sx:"auto", md:"92vh"}, borderRight:"1px solid #FFDEAD", 
                        px:{sx:0, md:2}}}>
              <VideoSideBar 
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />

              </Box>

              <Box p={2} sx={{overflowY: 'auto', height: '90vh', flex: 2, flexWrap:"wrap", order:3 }}>
              {
                selectedCategory === "cYAAG" ? (
                  <Typography variant="h4" fontWeight="bold" mb={2} sx={{color: "black"}}>
                    Campaign Gallery<span style={{color:'#F31503'}}> Public </span>
                  </Typography>
                ): (
                  <Typography variant="h4" fontWeight="bold" mb={2} sx={{color: "black"}}>
                    {selectedCategory} <span style={{color:'#F31503'}}>Public Gallery</span>
                    {campID && campID?.[0]?.myregistration === "yes" &&  (campID?.[0]?.status === "Active" || campID?.[0]?.status === "Completed")? (
                      <div><UploadFiles1Button camp={campID?.[0]?.campid} selectedCategory={selectedCategory} parentTriggerFunction={handleTriggerFunction} /></div>
                    // ):campID && (campID?.[0]?.status === "Completed" || campID?.[0]?.status === "Next") ? (
                      ):campID && campID?.[0]?.status === "Next" ? (
                      <div>
                        <span style={{fontSize:"12px"}}>Please note that the campaign is not active yet.</span>
                      </div>
                          
                    ):campID && campID?.[0]?.myregistration === "no" &&  campID?.[0]?.status === "Active" ? (
                      <div>
                        <span style={{fontSize:"12px"}}>Please register for the campaign in order to upload images/videos. 
                        <button
                          onClick={() => navigate(`/participate/${campID?.[0]?.campid}/${localStorage.getItem("userid")}`)}
                      >
                          <span style={{ paddingLeft: 3, fontSize: 14 }}>Click Here</span>
                      </button>
                      </span>
                      </div>
                    ):campID && campID?.[0]?.myregistration === "no" &&  (campID?.[0]?.status === "Completed") ? (
                      <div>
                        <span style={{fontSize:"12px"}}>Please note that the campaign is Completed and you may not be able to register or upload images/videos. 
                        {/* <button
                          onClick={() => navigate(`/participate/${campID?.[0]?.campid}/${localStorage.getItem("userid")}`)}
                      >
                          <span style={{ paddingLeft: 3, fontSize: 14 }}>Click Here</span>
                      </button> */}
                      </span>
                      </div>
                    ):
                    (
                      ""
                    )
                    }
                  </Typography>
                  
                  // <Typography variant="h5" fontWeight="bold" mb={2} sx={{color: "black"}}>
                  // {selectedCategory} <span style={{color:'#F31503'}}>upload files</span>
                  // </Typography>
                )
              }
                {/* <VideoForOngoingCampaign videos={campregisterlist}/> */}
                <VideoCard video={campregisterlist} camp={campID} type={type}/>
              </Box>
            </Stack> 
           </div>
         </div>
         <CopyRight />
         <FixedFooterIcon />
       </section>
     </div>
  );
}
export default MyOngoingCampaign;