import React, { useRef, useState } from "react";
import FormControl from "@mui/material/FormControl";
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Input,
  Chip,
  useMediaQuery,
} from "@material-ui/core";
import {
  Avatar,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Stack,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { focusAreaList, focusAreaDict, registeredAs, activity } from "../../utils/dict";

import {
  onboardActivityReset,
  onboardBulkReset,
  onboardCampaign,
  onboardCampaignReset,
  onboardIndividualReset,
  onboardOrganization,
  onboardOrganizationReset,
} from "../../redux/action/OnboardAction";
import CallingCode from "../../services/CallingCode";
import { fetchCityAndState } from "../../services/commonServices";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";
import { API_URL } from "../../services/Constants";
import { getOrganization } from "../../redux/action/getOrganization";
import {
  activityDuration,
  campaignParticipate,
  campaignType,
  restrictedCountry,
  currencyType,
} from "../../utils/dict";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  AddCircleOutlineOutlined,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";
import { getCity } from "../../redux/action/cityAction";
import { MenuProps, states } from "../../utils/constants";
import ErrorMessage from "../../components/ErrorMessage";
import _ from "lodash";
import RedStar from "../../components/RedStar";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getRewardTitle } from "../../redux/action/RewardDetailAction";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import DoneIcon from "@mui/icons-material/Done";
import { InputEnable } from "../../components/InputEnableComponent";
import AsyncSelect from "react-select/async";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";

import Modal from "react-bootstrap/Modal";
import Buttons from "react-bootstrap/Button";
import CampaignLeaderBoardIcon from "../../assets/CampaignLeaderBoardIcon.jpeg"

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    paddingTop: 15,
    border: "1px solid #ccc",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },

    display: "block",
    display: "flex",
    //fontSize: 16,
    fontSize: "16px",
    marginTop: 16,
    // padding: 10,
    fontWeight: "500",
    lineHeight: "18px",
    minHeight: 50,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {},

    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroupFull: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroup2: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",
    flexDirection: "column",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "75px 0px 0px 16px",
    // pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroup3: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "20%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroup4: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",
    // alignItems: "centerAlign",
    textAlign: "right",

    borderRadius: "8px",
    marginBottom: "4px",
    // padding: "0 16px 0px 300px",
    padding: "24px 0px 0px 0px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "50%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "25%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "50%",
    },
  },
  formGroup5: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 4px 0px 2px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "20%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "20%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroup6: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 4px 0px 18px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "15%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "20%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroup7: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",
    // alignItems: "centerAlign",
    textAlign: "left",

    borderRadius: "8px",
    marginBottom: "4px",
    // padding: "0 16px 0px 300px",
    padding: "24px 0px 0px 20px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "50%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "25%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroup8: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",
    // alignItems: "centerAlign",
    textAlign: "left",

    borderRadius: "8px",
    marginBottom: "4px",
    // padding: "0 16px 0px 300px",
    padding: "24px 0px 0px 20px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
}));

function CreateCampaign(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationPath = useLocation();
  const inputRef = React.useRef(null);
  const inputRefBanner = React.useRef(null);
  const videoRef = React.useRef(null);
  const [address, setAddress] = React.useState("");
  const [oldaddress, setOldAddress] = React.useState("");

  const [coordinates, setCoordinates] = React.useState({});

  const scrollRef_1 = React.useRef();
  const scrollRef_2 = React.useRef();
  const scrollRef_3 = React.useRef();
  const scrollRef_4 = React.useRef();
  const scrollRef_5 = React.useRef();
  const scrollRef_7 = React.useRef();
  const scrollRef_9 = React.useRef();

  // const [location, setLocation] = React.useState([
  //   { country: "", state: "", city: "", area: "", cityList: "" },
  // ]);
  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "", added: "" },
  ]);

  const [rewardtitle, setRewardtitle] = React.useState([
    {
      rewardtype: "",
      title: "",
      subtitle: "",
      sponsor: "",
      points: "",
      titleList: "",
    },
  ]);

  const numberOfFields = 5; // Set the number of fields

  // Initialize an array to keep track of the visibility of each field
  const [fieldVisibility, setFieldVisibility] = React.useState(
    Array(numberOfFields).fill(false)
  );

  // Toggle the visibility of a specific field by index
  const toggleField = (index) => {
    const updatedFieldVisibility = [...fieldVisibility];
    updatedFieldVisibility[index] = !updatedFieldVisibility[index];
    setFieldVisibility(updatedFieldVisibility);
  };

  const handleSelect = async (value) => {

    const results = await geocodeByAddress(value);
    // console.log(results[0]["formatted_address"]);
    setAddress(results[0]["formatted_address"]);

    // console.log(value);
  };

  const [focusArea, setFocusArea] = React.useState([
    {
      rewards: "Certificate of Participation",
    },
    {
      rewards: "Discount Coupons",
    },
    { rewards: "Gift Cards" },
    { rewards: "Social Media Recognition" },
  ]);

  // const skillArea= [
  //   { skill: "Networking" },
  //   { skill: "Fundraising" },
  //   { skill: "Operational Support" },
  //   { skill: "Content Writing / Editing" },
  //   { skill: "Social Media and Digital marketing" },
  //   { skill: "Team Management" },
  //   { skill: "Painting / Drawing / Sculpture making" },
  //   { skill: "Photography" },
  //   { skill: "Video Editing" },
  //   { skill: "Coaching / Mentoring" },
  //   { skill: "pp Development / Coding" },

  // ];



  const [skill, setSkill] = React.useState([
    { skill: "Networking" },
    { skill: "Fundraising" },
    { skill: "Operational Support" },
    { skill: "Content Writing / Editing" },
    { skill: "Social Media and Digital marketing" },
    { skill: "Team Management" },
    { skill: "Painting / Drawing / Sculpture making" },
    { skill: "Photography" },
    { skill: "Video Editing" },
    { skill: "Coaching / Mentoring" },
    { skill: "Development / Coding" },
  ]);
  const [focusError, setFocusError] = React.useState(false);
  const [newField, setNewField] = React.useState(false);
  const [newRewardField, setNewRewardField] = React.useState(false);
  const [rewardTypeList, setRewardTypeList] = React.useState(null);
  const [areas, setAreas] = React.useState([]);
  const [cityResponse, setCityResponse] = React.useState({});
  const [rewardDetailResponseEffect, setrewardDetailResponseEffect] =
    React.useState({});
  const [notificationShow, setNotificationShow] = React.useState(false);
  const [activityList, setActivityList] = React.useState(undefined);
  const [campCategory, setCampCategory] = React.useState([]);
  const [campTarget, setCampTarget] = React.useState([]);
  const [campIndicator, setCampIndicator] = React.useState([]);
  const [SDGCampMapping, setSDGCampMapping] = React.useState([]);

  const [campSubCategory, setCampSubCategory] = React.useState([]);
  const [campaignValues, setCampaignValues] = React.useState({
    titleOfCampaign: "",
    descriptionOfCampaign: "",
    typeOfCampaign: "",
    campaignTarget: "",
    campaignIndicator: "",
    volunteerCount: "",
    impact: "",
    startDate: new Date(),
    endDate: new Date(),
    durationOfCampaign: "",
    rewardType: "",
    goal: "",
    whattodo: "",
    guidelines: "",
    campaignType: "",
    hourOfParticipation: "",
    pointsToCurrency: "",
    totalBudget: "",
    pointsPerReferral: "",
    budgetPointForReferral: "",
    socialMediaMessage: "",
    campaignLogo: "",
    banner: "",
    campaignVideo: "",
    campaignParticipate: "",
    currencyType: "",
    goalquantity: "",
    unitofgoal: "",
    custommeasurement1: "",
  });
  const [campaignValuesError, setCampaignValuesError] = React.useState({
    titleOfCampaignError: "",
    duplicateTitleOfCampaignError: "",
    descriptionOfCampaignError: "",
    typeOfCampaignError: "",
    campaignTargetError: "",
    campaignIndicatorError: "",
    subtypeOfCampaignError: "",
    volunteerCountError: "",
    impactError: "",
    startDate: new Date(),
    endDate: new Date(),
    durationOfCampaignError: "",
    rewardTypeError: "",
    goalError: "",
    guidelinesError: "",
    whattodoError: "",
    campaignTypeError: "",
    hourOfParticipationError: "",
    pointsToCurrencyError: "",
    totalBudgetError: "",
    pointsPerReferralError: "",
    budgetPointForReferralError: "",
    socialMediaMessageError: "",
    campaignLogoError: "",
    campaignBannerError: "",
    campaignVideoError: "",
    rewardTypeError: "",
    campaignParticipateError: "",
    currencyTypeError: "",
    goalquantityError: "",
    unitofgoalError: "",
    locationError: "",
    custommeasurement1Error: "",
  });

  const [selected, setSelected] = React.useState([]);
  const [selected1, setSelected1] = React.useState([]);
  const [selectedcampcat, setselectedcampcat] = React.useState([]);
  const [selectedcamptarget, setselectedcamptarget] = React.useState([]);
  const [selectedcampindicator, setselectedcampindicator] = React.useState([]);
  const [selectedcampsubcat, setselectedcampsubcat] = React.useState([]);
  const [sponsor, setSponsor] = React.useState([]);
  const [skillselected, setSkillSelected] = React.useState([]);
  const {
    isCampaignSuccess,
    isCampaignFetching,
    campaignResponse,
    isCampaignError,
  } = useSelector((state) => state.onBoard);

  const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
    (state) => state.cities
  );

  const {
    titleSuccess: rewardDetailSuccess,
    titleResponse: rewardDetailResponse,
  } = useSelector((state) => state.getRewardTitle);
  // console.log(rewardDetailResponse)

  function subtractYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);

    return date;
  }

  const handleChangeRewards = (event, index) => {
    const value = event.target.value;

    setSelected([...selected, value[value?.length - 1]]);
  };

  const handleChangeRewards1 = (id, event, field, index) => {
    // const value = event.target.value;
    const value = [...rewardtitle];
    if (field === "rewardtype") {
      value[index]["titleList"] = rewardDetailResponseEffect;
    }
    if (field === "sponsor") {
      value[index][field] = event;
    } else {
      value[index][field] = event.target.value;
    }

    setRewardtitle(value);
  };

  const handleChangeSkill = (event, index) => {
    const value = event.target.value;

    setSelected1([...selected1, value[value?.length - 1]]);
  };

  const handleOnboardCampaign = (e) => {

    if (!campaignValues?.titleOfCampaign) {
      setCampaignValuesError({
        ...campaignValuesError,
        titleOfCampaignError: true,
      });
      scrollRef_1.current.scrollIntoView({ behavior: 'smooth' });
    } else if (campaignValuesError?.duplicateTitleOfCampaignError) {
      setCampaignValuesError({
        ...campaignValuesError,
        duplicateTitleOfCampaignError: true,
      });
      scrollRef_2.current.scrollIntoView({ behavior: 'smooth' });
    } else if (!campaignValues?.descriptionOfCampaign) {
      setCampaignValuesError({
        ...campaignValuesError,
        descriptionOfCampaignError: true,
      });
      scrollRef_3.current.scrollIntoView({ behavior: 'smooth' });
    } else if (!campaignValues?.goal) {
      setCampaignValuesError({
        ...campaignValuesError,
        goalError: true,
      });
      scrollRef_4.current.scrollIntoView({ behavior: 'smooth' });
    } else if (!campaignValues?.whattodo) {
      setCampaignValuesError({
        ...campaignValuesError,
        whattodoError: true,
      });
      window.scrollTo(0, 0);
    } else if (!campaignValues?.guidelines) {
      setCampaignValuesError({
        ...campaignValuesError,
        guidelinesError: true,
      });
      window.scrollTo(0, 0);
    } else if (!campaignValues?.campaignType) {
      setCampaignValuesError({
        ...campaignValuesError,
        campaignTypeError: true,
      });
      window.scrollTo(0, 0);
    } else if (!campaignValues?.campaignParticipate) {
      setCampaignValuesError({
        ...campaignValuesError,
        campaignParticipateError: true,
      });
      window.scrollTo(0, 0);
    } else if (!campaignValues?.currencyType) {
      setCampaignValuesError({
        ...campaignValuesError,
        currencyTypeError: true,
      });
      window.scrollTo(0, 0);
    } else if (!campaignValues?.unitofgoal) {
      setCampaignValuesError({
        ...campaignValuesError,
        unitofgoalError: true,
      });
      window.scrollTo(0, 0);
    } else if (!campaignValues?.goalquantity) {
      setCampaignValuesError({
        ...campaignValuesError,
        goalquantityError: true,
      });
      window.scrollTo(0, 0);
    } else if (!campaignValues?.volunteerCount) {
      setCampaignValuesError({
        ...campaignValuesError,
        volunteerCountError: true,
      });
      scrollRef_9.current.scrollIntoView({ behavior: 'smooth' });
    } else if (!campaignValues?.hourOfParticipation) {
      setCampaignValuesError({
        ...campaignValuesError,
        hourOfParticipationError: true,
      });
      window.scrollTo(0, 0);
    }

    //Commenting location check as GeoCoding API is disabled
    // else if (!campaignValues?.locationError && !address) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     locationError: true,
    //   });
    //   window.scrollTo(1500, 1500);
    // }

    // else if (selected.filter((item) => item).length === 0) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     rewardTypeError: true,
    //   });
    //   window.scrollTo(0, 0);
    // }

    // else if (location.length > 0) {
    //   for (let i = 0; i < location.length; i += 1) {
    //     const formattedArray = [];
    //     // console.log("RegistrationIDDD ==",selectedItem[i].registrationid);
    //     if (location[i]["country"] == "" || location[i]["state"] == "" || location[i]["city"] == ""||
    //           location[i]["area"] == "") {
    //         setCampaignValuesError({
    //           ...campaignValuesError,
    //           locationError: true,
    //         });
    //         window.scrollTo(0, 0);
    //     }
    //   }
    else if (selected1.filter((item) => item).length === 0) {
      setCampaignValuesError({
        ...campaignValuesError,
        skillError: true,
      });
      window.scrollTo(0, 0);
    } else if (!campaignValues?.socialMediaMessage) {
      setCampaignValuesError({
        ...campaignValuesError,
        socialMediaMessageError: true,
      });
      window.scrollTo(0, 0);
    } else if (!campaignValues?.campaignLogo) {
      setCampaignValuesError({
        ...campaignValuesError,
        campaignLogoError: true,
      });
      window.scrollTo(0, 0);
    }
    // else if (!campaignValues?.banner) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     campaignBannerError: true,
    //   });
    //   window.scrollTo(0, 0);
    // }
    else if (!campaignValues?.campaignVideo) {
      setCampaignValuesError({
        ...campaignValuesError,
        campaignVideoError: true,
      });
      window.scrollTo(0, 0);
      // } else if (!campaignValues?.pointsToCurrency) {
      //   setCampaignValuesError({
      //     ...campaignValuesError,
      //     pointsToCurrencyError: true,
      //   });
      //   window.scrollTo(0, 0);
      // }
    } else if (!campaignValues?.totalBudget) {
      setCampaignValuesError({
        ...campaignValuesError,
        totalBudgetError: true,
      });
      window.scrollTo(0, 0);
      // } else if (!campaignValues?.pointsPerReferral) {
      //   setCampaignValuesError({
      //     ...campaignValuesError,
      //     pointsPerReferralError: true,
      //   });
      //   window.scrollTo(0, 0);
      // } else if (!campaignValues?.budgetPointForReferral) {
      //   setCampaignValuesError({
      //     ...campaignValuesError,
      //     budgetPointForReferralError: true,
      //   });
      //   window.scrollTo(0, 0);
    } else {
      dispatch(onboardCampaignReset());
      let formData = new FormData();
      // formData.append("type", campaignValues?.typeOfCampaign);
      formData.append("type", JSON.stringify(selectedcampcat));
      formData.append("campaigntarget", JSON.stringify(selectedcamptarget));
      formData.append(
        "campaignindicator",
        JSON.stringify(selectedcampindicator)
      );
      formData.append("subtype", JSON.stringify(selectedcampsubcat));
      formData.append("title", campaignValues?.titleOfCampaign);
      formData.append("desc", campaignValues?.descriptionOfCampaign);
      formData.append("location", JSON.stringify(location));
      formData.append(
        "hourofparticipation",
        campaignValues?.hourOfParticipation
      );
      // formData.append("pointtocurrency", campaignValues?.pointsToCurrency);
      formData.append("totalbudget", campaignValues?.totalBudget);
      // formData.append("pointsperreferral", campaignValues?.pointsPerReferral);
      // formData.append("budgetpointforreferral", campaignValues?.budgetPointForReferral);
      formData.append("campaigntype", campaignValues?.campaignType);
      formData.append("visibility", campaignValues?.campaignParticipate);
      formData.append("currencytype", campaignValues?.currencyType);
      if (!campaignValues?.custommeasurement1) {
        formData.append("custommeasurement1", "");
      } else {
        formData.append("custommeasurement1", campaignValues?.custommeasurement1);
      }

      formData.append("goalquantity", campaignValues?.goalquantity);
      formData.append("unitofgoal", campaignValues?.unitofgoal);
      formData.append("goal", campaignValues?.goal);
      formData.append("whattodo", campaignValues?.whattodo);
      formData.append("guidelines", campaignValues?.guidelines);
      // formData.append("rewardstobeearned", JSON.stringify(selected));
      formData.append("rewardtitle", JSON.stringify(rewardtitle));
      formData.append("skill", JSON.stringify(selected1));

      formData.append("logo", campaignValues?.campaignLogo);
      // formData.append("banner", campaignValues?.banner);
      formData.append("video", campaignValues?.campaignVideo);
      //formData.append("impact", campaignValues?.impact);
      formData.append("targetvol", campaignValues?.volunteerCount);

      formData.append(
        "startdate",
        moment(campaignValues?.startDate).format("yyyy-MM-DD") + " 00:00:00"
      );
      formData.append(
        "enddate",
        moment(campaignValues?.endDate).format("yyyy-MM-DD") + " 00:00:00"
      );
      //formData.append("timeframe", campaignValues?.durationOfCampaign);
      formData.append("socialmediamsg", campaignValues?.socialMediaMessage);
      // console.log(formData);

      dispatch(
        onboardCampaign({ userid: localStorage.getItem("userid"), formData })
      );
      // console.log("========= value of citylist======");
      // console.log(JSON.stringify(location));
    }
  };


  const handleCampaignFieldChange = (field, e) => {
    (isCampaignError || isCampaignSuccess) && dispatch(onboardCampaignReset());
    setCampaignValues({
      ...campaignValues,
      [field]:
        field === "startDate" || field === "endDate"
          ? e
          : field === "campaignLogo" || field === "campaignVideo"
            ? e.target.files[0]
            : e.target.value,
    });
  };


  const oldCampDetails = async () => {
    try {
      const res = await axios.get(`/a/b/c/d/e/f/g/camp/${localStorage.getItem("userid")}/${locationPath?.pathname.split("/")?.[2]}`);
      setActivityList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };



  const getCampCategory = async () => {
    try {
      const res = await axios.get(`/camp/category`);
      // setActivityList(res?.data);
      setCampCategory(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getCampTarget = async () => {

    try {
      const res = await axios.get(`/a/b/${selectedcampcat["label"]}`);
      // setActivityList(res?.data);
      setCampTarget(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };



  const getCampIndicator = async () => {

    try {
      const res = await axios.get(
        `/a/b/c/${selectedcampcat["label"]}/${JSON.stringify(
          selectedcamptarget
        )}`
      );
      // setActivityList(res?.data);
      setCampIndicator(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getCampSubCategory = async () => {
    try {
      const res = await axios.get(`/camp/sub/category`);
      // setActivityList(res?.data);
      setCampSubCategory(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getSponsorList = async () => {
    try {
      const res = await axios.get(`/sponsor`).then((response) => {
        // const values = [...rewardtitle];

        setSponsor(response?.data);
        return response?.data;
      });
    } catch (err) {
      throw err;
    }
  };

  const getSDGCampMapping = async () => {
    try {
      const res = await axios.get(`camp/sdg/mapping`);
      // setActivityList(res?.data);
      setSDGCampMapping(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  // const getStateList = async (i, valll) => {
  //   try {
  //     const res = await axios.get(`/statecity/${valll}`).then((response) => {
  //       const values = [...location];
  //       values[i]["cityList"] = response?.data;
  //       return response?.data;
  //     });

  //   } catch (err) {
  //     throw err;
  //   }
  // };

  const getRewardDetailList = async (i, valll) => {
    try {
      const res = await axios
        .get(`/getrewardtitle/${valll}`)
        .then((response) => {
          const values = [...rewardtitle];
          values[i]["titleList"] = response?.data;
          return response?.data;
        });
    } catch (err) {
      throw err;
    }
  };

  const getRewardTypeList = async () => {
    try {
      const res = await axios.get(`getrewardtypes`);
      setRewardTypeList(res?.data);
      // console.log(res?.data);
      return res;
      // }
    } catch (err) {
      throw err;
    }
  };

  // const unitOfCampaign = activityList?.filter(
  //   (item, index) => item?.activity === campaignValues?.typeOfCampaign
  // );

  //  const unitOfCampaign = activityList?.filter(
  //   (item, index) => item?.activity === campaignValues?.typeOfCampaign
  // );

  const resetFile = (event) => {
    inputRef.current.value = null;
    // inputRefBanner.current.value = null;
    videoRef.current.value = null;
  };

  const onAddingItem = (event, option, index) => {
    const values = [...focusArea];
    values[index].isChecked = event.target.checked;
    setFocusArea(values);
  };

  const handleTextFieldCheckbox = (event, index) => {
    const values = [...focusArea];
    values[index].rewards = event.target.value;
    setFocusArea(values);
  };

  // const handleTextFieldCheckboxSkill = (event, index) => {
  //   const values = [...skill];
  //   values[index].skill = event.target.value;
  //   setFocusArea(values);
  // };
  // const getStateList = async (valll) => {
  //   try {
  //     const res = await axios.get(`/statecity/${valll}`);
  //    console.log("======xxxxxx=============");
  //    console.log(res?.data);
  //    console.log("======xxxxxx=============");
  //     return res?.data;
  //   } catch (err) {
  //     throw err;
  //   }
  // };

  const updatelocation = (index, address) => {

    const values = [...location];
    if (!address) {

      if (values[index]["added"] === "no") {
        setCampaignValuesError({
          ...campaignValuesError,
          locationError: true,
        });
      }
      const values = [...location];

      values[index]["country"] = "";
      values[index]["state"] = "";
      values[index]["city"] = "";
      values[index]["area"] = "";
      values[index]["added"] = "no";
    } else {
      setCampaignValuesError({
        ...campaignValuesError,
        locationError: false,
      });
      values[index]["country"] = String(
        address.split(",")[address.split(",").length - 1]
      ).trim(" ");
      values[index]["state"] = String(
        address.split(",")[address.split(",").length - 2]
      ).trim(" ");
      values[index]["city"] = String(
        address.split(",")[address.split(",").length - 3]
      ).trim(" ");
      values[index]["area"] = String(
        address.split(",")[address.split(",").length - 4]
      ).trim(" ");
      values[index]["added"] = "yes";
      if (
        values[index]["state"] === "undefined" &&
        values[index]["city"] === "undefined" &&
        values[index]["area"] === "undefined"
      ) {
        values[index]["added"] = "no";
        setCampaignValuesError({
          ...campaignValuesError,
          locationError: true,
        });
      }

      if (values[index]["state"] === "undefined") {
        values[index]["state"] = "";
      }
      if (values[index]["city"] === "undefined") {
        values[index]["city"] = "";
      }
      if (values[index]["area"] === "undefined") {
        values[index]["area"] = "";
      }

      setLocation(values);
      setOldAddress(address);
    }
  };

  // const handleChangeLocation = (id, e, field, index) => {

  //   const values = [...location];
  //   if (field === 'state') {
  //     values[index]["cityList"] = cityResponse;
  //   }

  //   values[index][field] = e.target.value;
  //   setLocation(values);
  //   console.log(location);
  //   // console.log(values);
  // };

  // const handleAddLocation = (id, e, field, index) => {

  //   const values = [...location];

  //   values[index][field] = e.target.value;
  //   setLocation(values);
  //   console.log(location);
  //   // console.log(values);
  // };

  const handleAddFields = () => {

    let errorcount = 0;
    for (let i = 0; i < location.length; i += 1) {
      // console.log("RegistrationIDDD ==",selectedItem[i].registrationid);
      if (
        location[i]["country"] == "" ||
        location[i]["state"] == "" ||
        location[i]["city"] == "" ||
        location[i]["area"] == ""
      ) {
        setNewField(false);
        errorcount = errorcount + 1;
      }
    }
    if (errorcount == 0) {
      setNewField(true);
      // setLocation([...location, { country: "", state: "", city: "", area: "", cityList: "" }]);
      setLocation([
        ...location,
        { country: "", state: "", city: "", area: "", added: "no" },
      ]);
    } else {
      setNewField(false);
    }


    // setRewardtitle([...rewardtitle, { rewardtype: "", title: "", subtitle: "", sponsor: "", titleList: "" }])
  };

  const handleRewardAddFields = () => {
    setNewRewardField(true);
    // setLocation([...location, { country: "", state: "", city: "", area: "", cityList: "" }]);
    setRewardtitle([
      ...rewardtitle,
      {
        rewardtype: "",
        title: "",
        subtitle: "",
        sponsor: "",
        points: "",
        titleList: "",
      },
    ]);
  };

  const handleRemoveFields = (i) => {
    let newFormValues = [...location];
    newFormValues.splice(i, 1);
    setLocation(newFormValues);
  };

  const handleRewardRemoveFields = (i) => {
    // console.log("i");
    // console.log(i);
    let newRewardFormValues = [...rewardtitle];
    newRewardFormValues.splice(i, 1);
    setRewardtitle(newRewardFormValues);
  };



  const getAddress = (item, index) => {
    return (
      <div style={{ float: "left", width: "75%", marginTop: 50 }}>
        {/* <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>Operational Areas</span> <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 300, fontStyle: "italic" }}> ("Area" field is optional, click on "+" icon to enter additional locations)</span> */}
        <form
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            float: "left",
          }}
        >
          {/* {index !== location.length - 1 || (index === location.length - 1 && location[index].country !== "") || index === 0 ? ( */}
          {index == location.length - 1 && location[index]?.country === "" ? (
            <div className={classes.formGroup3}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Country
              </label>
              {!(
                address &&
                index > 0 &&
                location[index]?.added == "no" &&
                index == location.length - 1 &&
                address == oldaddress
              ) ? (
                <span>{address.split(",")[address.split(",").length - 1]}</span>
              ) : (
                <span style={{ fontSize: 12 }}>Search location</span>
              )}
            </div>
          ) : (
            <div className={classes.formGroup3}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Country
              </label>
              <span>{location[index]?.country}</span>
            </div>
          )}
          {index == location.length - 1 && location[index]?.state === "" ? (
            <div className={classes.formGroup3}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                State
              </label>
              {!(
                address &&
                index > 0 &&
                location[index]?.added == "no" &&
                index == location.length - 1 &&
                address == oldaddress
              ) ? (
                <span>{address.split(",")[address.split(",").length - 2]}</span>
              ) : (
                <span style={{ fontSize: 12 }}>Search location</span>
              )}
            </div>
          ) : (
            <div className={classes.formGroup3}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                State
              </label>
              {address ? (
                <span>{location[index]?.state}</span>
              ) : (
                <span style={{ fontSize: 12 }}>Search location</span>
              )}
            </div>
          )}
          {index == location.length - 1 && location[index]?.state === "" ? (
            <div className={classes.formGroup3}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                City/Town
              </label>
              {!(
                address &&
                index > 0 &&
                location[index]?.added == "no" &&
                index == location.length - 1 &&
                address == oldaddress
              ) ? (
                <span>{address.split(",")[address.split(",").length - 3]}</span>
              ) : (
                <span style={{ fontSize: 12 }}>Search location</span>
              )}
            </div>
          ) : (
            <div className={classes.formGroup3}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                City/Town
              </label>
              {address ? (
                <span>{location[index]?.city}</span>
              ) : (
                <span style={{ fontSize: 12 }}>Search location</span>
              )}
            </div>
          )}
          {index == location.length - 1 && location[index]?.area === "" ? (
            <div className={classes.formGroup3}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Area
              </label>
              {!(
                address &&
                index > 0 &&
                location[index]?.added == "no" &&
                index == location.length - 1 &&
                address == oldaddress
              ) ? (
                <span>{address.split(",")[address.split(",").length - 4]}</span>
              ) : (
                <span style={{ fontSize: 12 }}>Search location</span>
              )}
            </div>
          ) : (
            <div className={classes.formGroup3}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Area
              </label>

              {address ? (
                <span>{location[index]?.area}</span>
              ) : (
                <span style={{ fontSize: 12 }}>Search location</span>
              )}
            </div>
          )}
          <div className={classes.formGroup3}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Added
            </label>
            {location[index]?.added == "yes" ? (
              <DoneIcon style={{ color: "green" }} />
            ) : (
              ""
            )}
          </div>
          <div className={classes.formGroup4}>
            <Button
              variant="contained"
              onClick={() => updatelocation(index, address)}
              disableElevation
              style={{
                width: "92px",
                height: "35px",
                background: "#f5eceb",
                color: "green",
                fontSize: 16,
                fontFamily: "Poppins !important",
              }}
            >
              Add
            </Button>
          </div>
          {index === 0 ? (
            <div className={classes.formGroup7}>
              <Button
                variant="contained"
                onClick={() => handleRemoveFields(index)}
                disableElevation
                disabled
                style={{
                  width: "92px",
                  height: "35px",
                  background: "gray",
                  color: "#DC143C",
                  fontSize: 16,
                  fontFamily: "Poppins !important",
                }}
              >
                Delete
              </Button>
            </div>
          ) : (
            <div className={classes.formGroup7}>
              <Button
                variant="contained"
                onClick={() => handleRemoveFields(index)}
                disableElevation
                style={{
                  width: "92px",
                  height: "35px",
                  background: "#f5eceb",
                  color: "red",
                  fontSize: 16,
                  fontFamily: "Poppins !important",
                }}
              >
                Delete
              </Button>
            </div>
          )}
          <div className={classes.formGroup8}>
            <span
              style={{ color: "green", textAlign: "center", display: "block" }}
            >
              {campaignValuesError?.locationError && (
                <ErrorMessage message="Please enter the location" />
              )}
            </span>
          </div>
        </form>

        {index ? (
          <div
            style={{
              float: "right",
              position: "relative",
              bottom: 44,
              cursor: "pointer",
              left: 19,
            }}
            onClick={(e) => handleRemoveFields(index)}
          >
            <RemoveCircleOutlineOutlined></RemoveCircleOutlineOutlined>
          </div>
        ) : (
          <div
            className="handlePlus"
            style={{
              float: "right",
              bottom: 80,
              position: "relative",
              left: 19,
              cursor: "pointer",
            }}
          >
            <AddCircleOutlineOutlined
              onClick={handleAddFields}
            ></AddCircleOutlineOutlined>
          </div>
        )}

        {/* <span style={{ color: "green", textAlign: "center", display: "block" }}>{campaignValuesError?.locationError && (
                      <ErrorMessage message="Please enter the location" />
                    )}</span> */}
      </div>
    );
  };

  React.useEffect(() => {
    dispatch(onboardBulkReset());
    dispatch(onboardIndividualReset());
    dispatch(onboardActivityReset());
    dispatch(onboardCampaignReset());
    dispatch(onboardOrganizationReset());
    // setLocation([{ country: "", state: "", city: "", area: "", cityList: "" }]);
    // setLocation([{ country: "", state: "", city: "", area: "" }]);
    setRewardtitle([
      {
        rewardtype: "",
        title: "",
        subtitle: "",
        sponsor: "",
        points: "",
        titleList: "",
      },
    ]);
  }, []);
  React.useEffect(() => {
    dispatch(getOrganization({ userid: localStorage.getItem("userid") }));
    // getActivityList();
    getSDGCampMapping();
    getCampCategory();
    getCampSubCategory();
    getRewardTypeList();
  }, []);


  React.useEffect(() => {
    oldCampDetails();
  }, [locationPath?.pathname.split("/")?.[2]]);

  React.useEffect(() => {
    if (isCampaignSuccess) {
      setCampaignValues({
        titleOfCampaign: "",
        descriptionOfCampaign: "",
        typeOfCampaign: "",
        // rewardType: "",
        volunteerCount: "",
        impact: "",
        startDate: new Date(),
        endDate: new Date(),
        durationOfCampaign: "",
        goal: "",
        whattodo: "",
        guidelines: "",
        campaignType: "",
        hourOfParticipation: "",
        pointsToCurrency: "",
        totalBudget: "",
        pointsPerReferral: "",
        budgetPointForReferral: "",
        socialMediaMessage: "",
        campaignLogo: "",
        // banner: "",
        campaignVideo: "",
        campaignParticipate: "",
        currencyType: "",
        goalquantity: "",
        unitofgoal: "",
        custommeasurement1: "",
      });
      //setLocation([{ country: "", state: "", city: "", area: "", cityList: "" }]);
      resetFile();
      setFocusArea([
        {
          value: "Certificate of Participation",
          rewards: "Certificate of Participation",
          isChecked: false,
        },
        {
          value: "Discount Coupons",
          rewards: "Discount Coupons",
          isChecked: false,
        },
        { value: "Gift Cards", rewards: "Gift Cards", isChecked: false },
        { value: "Any Other", rewards: "Any Other", isChecked: false },
      ]);
      setAddress("");
      setSelected("");
      setSelected1("");
      // setCampCategory("");
      // setCampSubCategory("");
      setRewardtitle([
        {
          rewardtype: "",
          title: "",
          subtitle: "",
          sponsor: "",
          points: "",
          titleList: "",
        },
      ]);
      setselectedcampcat("");
      setselectedcampsubcat("");
      setselectedcamptarget("");
      setselectedcampindicator("");
      // dispatch(onboardCampaignReset());
    }
  }, [isCampaignSuccess]);


  React.useEffect(() => {
    if (activityList) {
      setCampaignValues({
        titleOfCampaign: activityList?.[0]?.name,
        descriptionOfCampaign: activityList?.[0]?.desc,
        typeOfCampaign: "",
        // rewardType: "",
        volunteerCount: activityList?.[0]?.targetvol,
        impact: "",
        startDate: new Date(),
        endDate: new Date(),
        durationOfCampaign: "",
        goal: activityList?.[0]?.goal,
        whattodo: activityList?.[0]?.whattodo,
        guidelines: activityList?.[0]?.guidelines,
        campaignType: activityList?.[0]?.campaigntype,
        hourOfParticipation: "",
        pointsToCurrency: "",
        totalBudget: activityList?.[0]?.totalbudget,
        pointsPerReferral: "",
        budgetPointForReferral: "",
        socialMediaMessage: activityList?.[0]?.socialmediamsg,
        campaignLogo: "",
        // banner: "",
        campaignVideo: "",
        campaignParticipate: "",
        currencyType: activityList?.[0]?.currencytype,
        goalquantity: activityList?.[0]?.goalquantity,
        unitofgoal: activityList?.[0]?.unitofgoal,
        custommeasurement1: activityList?.[0]?.custommeasurement1,
      });
      setAddress("");
      setSelected("");
      setSelected1("");
      setselectedcampcat("");
      setselectedcampsubcat("");
      setselectedcamptarget("");
      setselectedcampindicator("");
    }
  }, [activityList]);



  React.useEffect(() => {
    getSponsorList();
  }, []);

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };

  const loadOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = campCategory.filter((category) =>
        category.label.toLowerCase().includes(searchValue.toLowerCase())
      );

      callback(filteredOptions);
    });
  };

  const loadCampTargetOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = campTarget.filter((category) =>
        category.label.toLowerCase().includes(searchValue.toLowerCase())
      );

      callback(filteredOptions);
    });
  };

  const loadCampIndicatorOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = campIndicator.filter((category) =>
        category.label.toLowerCase().includes(searchValue.toLowerCase())
      );

      callback(filteredOptions);
    });
  };

  const loadSubOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = campSubCategory.filter((subcategory) =>
        subcategory.label.toLowerCase().includes(searchValue.toLowerCase())
      );

      callback(filteredOptions);
    });
  };

  const colorStyles = {
    control: (styles) => ({ ...styles, minHeight: 54, maxHeight: 54 }),
    // option: (styles, {data, isDisabled, isFocussed, isSelected}) => {
    //   return{...styles, color: "blue",maxHeight:54};
    // },
    multiValue: (styles, { data }) => {
      return { ...styles, backgroundColor: "#0096FF", color: "#fff", maxHeight: 54 };
    },
    multiValueLabel: (styles, { data }) => {
      return { ...styles, color: "#fff" };
    },
    multiValueRemove: (styles, { data }) => {
      return {
        ...styles,
        color: "#fff",
        cursor: "pointer",
        ":hover": {
          color: "#fff",
        },
      };
    },
  };

  const [campName, setCampName] = React.useState("");
  const checkDuplicate = async () => {

    try {
      const res = await axios.get(`/camp/status/dup/${campName}`);

      if (res?.data?.message === "Exist") {
        setCampaignValuesError({
          ...campaignValuesError,
          duplicateTitleOfCampaignError: true,
        });
      } else {
        setCampaignValuesError({
          ...campaignValuesError,
          duplicateTitleOfCampaignError: false,
        });
      }

      // return res;
    } catch (err) {
      throw err;
    }
    // setCampName("");
  };
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [showPreviewModal, setShowPreviewModal] = React.useState(false);

  const handleOpenPreview = () => {

    setShowPreviewModal(true);

  };

  const hidePreviewModal = () => {
    setShowPreviewModal(false);
  };

  const isDesktop = useMediaQuery("(min-width:500px)");


  // const splitString = (val) => {
  //   if (val) {
  //     // const vallArray = val.split(".");
  //     const vallArray = val.split(";");

  //     return (
  //       <ul>
  //         {vallArray.map((bulletVal, index) => (
  //           <li>
  //             {/* <p>{vallArray[index]}.</p> */}
  //             <p>{vallArray[index]}.</p>
  //           </li>
  //         ))}
  //       </ul>
  //     );
  //   }
  // };

  const splitString1 = (val) => {
    if (val) {
      const parts = val.split(/(::)|(,,)|(--)|;/).filter(Boolean);
      let inUnderlinedSection = false;
      let inBoldSection = false;
      let skipLineBreak = true;
      let inHighlightTextSection = false;

      return (
        <ul>
          {parts.map((bulletVal, index) => {
            if (bulletVal === '::') {
              inUnderlinedSection = !inUnderlinedSection;
              skipLineBreak = true;
              return null;
            } else if (bulletVal === ',,') {
              inBoldSection = !inBoldSection;
              skipLineBreak = true;
              return null;
            } else if (bulletVal === '--') {
              inHighlightTextSection = !inHighlightTextSection;
              skipLineBreak = true;
              return null;
            }

            if (inUnderlinedSection) {
              return (
                <li key={index}>
                  <span className="underlined-text">{bulletVal}</span>
                </li>
              );
            } else if (inBoldSection) {
              return (
                <li key={index}>
                  {!skipLineBreak && <br />}
                  <span className="bold-text">{bulletVal}</span>
                </li>
              );
            } else if (inHighlightTextSection) {
              return (
                <li key={index}>
                  {!skipLineBreak && <br />}
                  <span className="highlight-text">{bulletVal}</span>
                </li>
              );
            } else if (bulletVal.trim() !== '') {
              const bulletPoints = bulletVal.split(';').map((point) => point.trim());

              return (
                <li key={index}>
                  {bulletPoints.map((point, subIndex) => {
                    const shouldBreakLine = subIndex > 0;
                    if (shouldBreakLine) {
                      skipLineBreak = false;
                      return (
                        <React.Fragment key={`${index}-${subIndex}`}>
                          <br />
                          <p>{point}</p>
                        </React.Fragment>
                      );
                    }
                    return <p key={`${index}-${subIndex}`} style={{ marginTop: 0 }}>{point}</p>;
                  })}
                </li>
              );
            }
            return null;
          })}
        </ul>
      );
    }
  };

  function validateInput(input) {
    input.value = input.value.replace(/[^A-Za-z]/g, '');
  }

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg createCampaignPage">
            {/* <Link className={classes.backBtn} to={"/home"}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 0 }}> Back</span>
            </Link> */}
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>

            <div style={{ fontFamily: "monospace", fontSize: 16 }}>
              <div
                style={{
                  display: "flex",
                  width: "75%",
                  justifyContent: "flex-end",
                  marginRight: 5,
                  marginBottom: 20,
                }}
              >
                <span style={{ color: "red" }}>
                  Please do not leave this page until you submit as you may risk
                  losing the partially filled in form{" "}
                </span>
              </div>
            </div>
            <div class="topFormContainer">
              <div
                class="content-title"
                style={{
                  fontSize: 25,
                  fontWeight: "600",
                  marginBottom: 15,
                  marginTop: 5,
                  float: "left",
                }}
              >
                Create Campaigns
              </div>
              <Button
                variant="contained"
                style={{ float: "right" }}
                onClick={handleOpenPreview}
                className="btn previewCampaign"
                disableElevation
              >
                <span>Preview</span>
              </Button>
            </div>
            <form autoComplete="off">
              <div
                style={{
                  border: "1px solid #dadce0",
                  borderRadius: 8,
                  backgroundColor: "#fff",
                  float: "left",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label} ref={scrollRef_1}
                    >
                      Name of Campaign
                      <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(1)}
                      ></i>
                    </label>
                    {/* Render the bottom div conditionally */}
                    {fieldVisibility[1] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-35px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        Campaign name must uniquely describe the campaign.
                        However ensure that it is not too long. 40 -50
                        characters or less is recommended.
                      </div>
                    )}

                    <input
                      value={campaignValues?.titleOfCampaign}
                      type="text"
                      className={classes.input}
                      placeholder="Your answer"
                      // maxLength="25"
                      onMouseOut={() => {
                        if (!campaignValues?.titleOfCampaign) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            titleOfCampaignError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            titleOfCampaignError: false,
                          });
                        }
                        setCampName(campaignValues?.titleOfCampaign);
                        checkDuplicate();
                      }}
                      onBlur={() => {
                        if (!campaignValues?.titleOfCampaign) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            titleOfCampaignError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            titleOfCampaignError: false,
                          });
                        }
                        setCampName(campaignValues?.titleOfCampaign);
                        checkDuplicate();
                      }}
                      onChange={(e) => {
                        handleCampaignFieldChange("titleOfCampaign", e);
                        // setCampName(campaignValues?.titleOfCampaign);
                        // checkDuplicate();
                      }}
                    />
                    {campaignValuesError?.titleOfCampaignError && (
                      <ErrorMessage message="Please enter the title of campaign" />
                    )}
                    {campaignValuesError?.duplicateTitleOfCampaignError && (
                      <ErrorMessage message="Campaign name already exists. Please choose a different name" />
                    )}
                  </div>

                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label} ref={scrollRef_2}
                    >
                      Campaign Description
                      <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(2)}
                      ></i>
                    </label>
                    {/* Render the bottom div conditionally */}
                    {fieldVisibility[2] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-35px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        Mention specifics of this campaign. There are separate
                        sections for adding details such as goal, objective and
                        like.
                      </div>
                    )}
                    {/* <input */}
                    <textarea
                      value={campaignValues?.descriptionOfCampaign}
                      type="text"
                      className={classes.input}
                      placeholder="Your answer"
                      // maxLength="200"
                      onBlur={() => {
                        if (!campaignValues?.descriptionOfCampaign) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            descriptionOfCampaignError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            descriptionOfCampaignError: false,
                          });
                        }
                      }}
                      onChange={(e) => {
                        handleCampaignFieldChange("descriptionOfCampaign", e);
                      }}
                    />
                    {campaignValuesError?.descriptionOfCampaignError && (
                      <ErrorMessage message="Please enter the description of campaign" />
                    )}
                  </div>
                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label} ref={scrollRef_3}
                    >
                      Campaign Goal <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(3)}
                      ></i>
                    </label>

                    {fieldVisibility[3] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-35px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        Goal must be as specific as possible. For example, a
                        Tree Planting campaign can have the goal stated as -
                        "Planting 1000 banyan tree saplings in Bengaluru to end
                        desertification and restore degraded land and for
                        increasing proportion of oxygen in the region."
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        value={campaignValues?.goal}
                        type="text"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        onBlur={() => {
                          if (!campaignValues?.goal) {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              goalError: true,
                            });
                          } else {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              goalError: false,
                            });
                          }
                        }}
                        onChange={(e) => {
                          handleCampaignFieldChange("goal", e);
                        }}
                      />
                    </div>
                    {campaignValuesError?.goalError && (
                      <ErrorMessage message="Please enter the goal" />
                    )}
                  </div>
                  {/* <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Category <RedStar />
                    </label>
                    <Select
                      style={{
                        width: "100%",
                        fontSize: 14,
                        height: 56,
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                      }}
                      value={campaignValues.typeOfCampaign}
                      onBlur={() => {
                        if (!campaignValues?.typeOfCampaign) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            typeOfCampaignError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            typeOfCampaignError: false,
                          });
                        }
                      }}
                      onChange={(e) => {
                        handleCampaignFieldChange("typeOfCampaign", e);
                      }}
                    >
                      {activityList?.map((item, index) => (
                        <MenuItem style={{ fontSize: 16 }} value={item.activity}>
                          {item.activity}
                        </MenuItem>
                      ))}
                    </Select>
                    {campaignValuesError?.typeOfCampaignError && (
                      <ErrorMessage message="Please select the campaign category" />
                    )}
                  </div> */}

                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label} ref={scrollRef_4}
                    >
                      Campaign Category SDG <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(4)}
                      ></i>
                    </label>
                    {fieldVisibility[4] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-18px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        You can select only one Category for your campaign.
                        Based on the category, a hashtag is generated
                        accordingly.
                      </div>
                    )}
                    <AsyncSelect
                      placeholder="Select"
                      loadOptions={loadOptions}
                      defaultOptions={campCategory}
                      defaultValue={selectedcampcat}
                      // isMulti
                      styles={colorStyles}
                      // onChange={(e) => {
                      //   handleCampaignFieldChange("typeOfCampaign", e);
                      // }}
                      onChange={(event) => {
                        setselectedcamptarget('');
                        setselectedcampindicator('');
                        setselectedcampcat(event);

                        if (_.isEmpty(event)) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            typeOfCampaignError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            typeOfCampaignError: false,
                          });
                        }
                        getCampTarget();
                      }}
                      onTouchStart={(event) => {
                        setselectedcamptarget('');
                        setselectedcampindicator('');
                        setselectedcampcat(event);

                        if (_.isEmpty(event)) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            typeOfCampaignError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            typeOfCampaignError: false,
                          });
                        }
                        getCampTarget();
                      }}
                      onBlur={() => {
                        if (!selectedcampcat) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            typeOfCampaignError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            typeOfCampaignError: false,
                          });
                        }
                        getCampTarget();
                      }}
                    />

                    {campaignValuesError?.typeOfCampaignError && (
                      <ErrorMessage message="Please select the campaign category" />
                    )}
                  </div>

                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label} ref={scrollRef_5}
                    >
                      Campaign Sub Category <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(5)}
                      ></i>
                    </label>
                    {fieldVisibility[5] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-35px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        You can select one or more of the options corresponding
                        to the impact areas of your campaign.
                      </div>
                    )}
                    <AsyncSelect
                      placeholder="Select"
                      loadOptions={loadCampTargetOptions}
                      defaultOptions={campTarget}
                      value={selectedcamptarget}
                      isMulti
                      styles={colorStyles}
                      // onChange={(e) => {
                      //   handleCampaignFieldChange("typeOfCampaign", e);
                      // }}
                      onChange={(event) => {
                        setselectedcampindicator('');
                        setselectedcamptarget(event);
                        if (_.isEmpty(event)) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            campaignTargetError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            campaignTargetError: false,
                          });
                        }
                        getCampIndicator();
                      }}
                      onTouchStart={(event) => {
                        setselectedcampindicator('');
                        setselectedcamptarget(event);
                        if (_.isEmpty(event)) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            campaignTargetError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            campaignTargetError: false,
                          });
                        }
                        getCampIndicator();
                      }}
                      onBlur={() => {
                        if (!selectedcamptarget) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            campaignTargetError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            campaignTargetError: false,
                          });
                        }
                        getCampIndicator();
                      }}
                    />

                    {campaignValuesError?.campaignTargetError && (
                      <ErrorMessage message="Please select the campaign target" />
                    )}
                  </div>

                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Campaign Indicators <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(6)}
                      ></i>
                    </label>
                    {fieldVisibility[6] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-35px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        You can select one or more of the options corresponding
                        to the impact measurement(s) of your campaign.
                      </div>
                    )}
                    <AsyncSelect
                      placeholder="Select"
                      loadOptions={loadCampIndicatorOptions}
                      defaultOptions={campIndicator}
                      value={selectedcampindicator}
                      isMulti
                      styles={colorStyles}
                      // onChange={(e) => {
                      //   handleCampaignFieldChange("typeOfCampaign", e);
                      // }}
                      onChange={(event) => {
                        setselectedcampindicator(event);
                        if (_.isEmpty(event)) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            campaignIndicatorError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            campaignIndicatorError: false,
                          });
                        }
                      }}
                      onBlur={() => {
                        if (!selectedcampindicator) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            campaignIndicatorError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            campaignIndicatorError: false,
                          });
                        }
                      }}
                    />

                    {campaignValuesError?.campaignIndicatorError && (
                      <ErrorMessage message="Please select the campaign indicator" />
                    )}
                  </div>

                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Nature of Activity <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(7)}
                      ></i>
                    </label>
                    {fieldVisibility[7] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-35px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        Options here indicate the nature of activities involved
                        in your campaign. For example, If your campaign involves
                        designing a sewage system, chose Design. If it involves
                        planting trees or donating blood, chose Implementation.
                      </div>
                    )}
                    <AsyncSelect
                      placeholder="Select"
                      loadOptions={loadSubOptions}
                      defaultOptions={campSubCategory}
                      isMulti
                      value={selectedcampsubcat}
                      styles={colorStyles}
                      onChange={(event) => {
                        setselectedcampsubcat(event);

                        if (_.isEmpty(event)) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            subtypeOfCampaignError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            subtypeOfCampaignError: false,
                          });
                        }
                      }}
                      onBlur={() => {
                        if (!selectedcampsubcat) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            subtypeOfCampaignError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            subtypeOfCampaignError: false,
                          });
                        }
                      }}
                    />

                    {campaignValuesError?.subtypeOfCampaignError && (
                      <ErrorMessage message="Please select the campaign category" />
                    )}
                  </div>

                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label} ref={scrollRef_7}
                    >
                      Campaign Mode <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(8)}
                      ></i>
                    </label>
                    {fieldVisibility[8] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-35px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        Chose "In-Person", if entirely offline or a combination
                        of offline and online mode. Chose "Virtual" if entirely
                        an online campaign.
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                      className="menuDropDown"
                    >
                      <Select
                        style={{
                          width: "100%",
                          fontSize: 14,
                          height: 53,
                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                          border: "1px solid #ccc"
                        }}
                        value={campaignValues.campaignType}
                        placeholder="Select" 
                        onBlur={() => {
                          if (!campaignValues?.campaignType) {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              campaignTypeError: true,
                            });
                          } else {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              campaignTypeError: false,
                            });
                          }
                        }}
                        onChange={(e) =>
                          handleCampaignFieldChange("campaignType", e)
                        }
                      >
                        {campaignType.map((item, index) => (
                          <MenuItem style={{ fontSize: 16 }} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    {campaignValuesError?.campaignTypeError && (
                      <ErrorMessage message="Please select the campaign type" />
                    )}
                  </div>

                  <div className={classes.formGroup}>
                    <label
                      // htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Campaign Participation <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(9)}
                      ></i>
                    </label>
                    {fieldVisibility[9] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-35px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        Chose "Open" - if you prefer the campaign to be open for
                        changemakers from all organizations, Chose "Close" - if
                        you prefer the campaign to be visible only for
                        changemakers of your organization.
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                      className="menuDropDown"
                    >
                      <Select
                        style={{
                          width: "100%",
                          fontSize: 14,
                          height: 53,
                          border: "1px solid #ccc",
                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                        }}
                        placeholder="Select" 
                        value={campaignValues.campaignParticipate}
                        onBlur={() => {
                          if (!campaignValues?.campaignParticipate) {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              campaignParticipateError: true,
                            });
                          } else {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              campaignParticipateError: false,
                            });
                          }
                        }}
                        onChange={(e) =>
                          handleCampaignFieldChange("campaignParticipate", e)
                        }
                      >
                        {campaignParticipate.map((item, index) => (
                          <MenuItem style={{ fontSize: 16 }} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    {campaignValuesError?.campaignParticipateError && (
                      <ErrorMessage message="Please select the campaign participate value" />
                    )}
                  </div>

                  <div className={classes.formGroup}>
                    <label
                      // htmlFor="create-yaad--title"
                      className={classes.label} ref={scrollRef_9}
                    >
                      Number of Changemakers required <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(10)}
                      ></i>
                    </label>
                    {fieldVisibility[10] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-18px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        Number of changemakers you would need for this campaign.
                        Enter in numbers. For example - 10, 40, 1000 etc.
                      </div>
                    )}
                    <input
                      value={campaignValues?.volunteerCount}
                      type="number"
                      id="create-yaad--title"
                      className={classes.input}
                      placeholder="Your answer"
                      onKeyPress={(e) => {
                        preventMinus(e);
                      }}
                      onBlur={() => {
                        if (!campaignValues?.volunteerCount) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            volunteerCountError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            volunteerCountError: false,
                          });
                        }
                      }}
                      onChange={(e) => {
                        handleCampaignFieldChange("volunteerCount", e);
                      }}
                    />
                    {campaignValuesError?.volunteerCountError && (
                      <ErrorMessage message="Please enter the volunteer count" />
                    )}
                  </div>

                  <div className={classes.formGroup}>
                    <label
                      // htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      How to participate? <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(11)}
                      ></i>
                    </label>
                    {fieldVisibility[11] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-35px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        Enter in detail from Changemaker's perspective.
                        Give step-by-step instructions so that changemakers
                        can understand what they are supposed to do to participate
                        in this campaign. Please add a semicolon ';' after each
                        sentence to display it as a new line. To underline the
                        text, add double colons '::' between words. In order to
                        bold the statement, add double comma's ',,' between
                        the words. To highlight the text in yellow, add double dash
                        '--' between words.
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        value={campaignValues?.whattodo}
                        type="text"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        onBlur={() => {
                          if (!campaignValues?.whattodo) {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              whattodoError: true,
                            });
                          } else {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              whattodoError: false,
                            });
                          }
                        }}
                        onChange={(e) => {
                          handleCampaignFieldChange("whattodo", e);
                        }}
                      />
                    </div>
                    {campaignValuesError?.whattodoError && (
                      <ErrorMessage message="Please enter what to do " />
                    )}
                  </div>

                  <div className={classes.formGroup}>
                    <label
                      // htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Campaign Guidelines <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(12)}
                      ></i>
                    </label>
                    {fieldVisibility[12] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-35px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        Your campaign would need changemakers to adhere to
                        certain norms, mention those in detail here. For
                        example, if your campaign needs students from
                        Engineering colleges only to participate, mention it
                        accordingly. Please add a semicolon ';' after each
                        sentence to display it as a new line. To underline the
                        text, add double colons '::' between words. In order to
                        bold the statement, add double comma's ',,' between
                        the words. To highlight the text in yellow, add double dash
                        '--' between words.


                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        value={campaignValues?.guidelines}
                        type="text"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        onBlur={() => {
                          if (!campaignValues?.guidelines) {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              guidelinesError: true,
                            });
                          } else {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              guidelinesError: false,
                            });
                          }
                        }}
                        onChange={(e) => {
                          handleCampaignFieldChange("guidelines", e);
                        }}
                      />
                    </div>
                    {campaignValuesError?.guidelinesError && (
                      <ErrorMessage message="Please enter the guidelines" />
                    )}
                  </div>

                 
                  <div className={classes.formGroup}>
                    <label
                      // htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Start Date <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(13)}
                      ></i>
                    </label>
                    {fieldVisibility[13] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-35px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        This is the start date (inclusive) of the campaign
                        activities.
                      </div>
                    )}

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        // minDate={new Date()}
                        inputFormat={"yyyy-MM-dd"}
                        value={campaignValues.startDate}
                        onChange={(newValue) => {
                          handleCampaignFieldChange("startDate", newValue);
                        }}
                        minDate={new Date()}
                        // maxDate={campaignValues.endDate}

                        renderInput={(params) => (
                          <TextField
                            style={{
                              width: "100%",
                              height: 56,
                              border: "1px solid #ccc",
                              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className={classes.formGroup}>
                    <label
                      // htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      End Date <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(14)}
                      ></i>
                    </label>
                    {fieldVisibility[14] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-35px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        This is the end date (inclusive) of the campaign
                        activities.
                      </div>
                    )}

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        // minDate={new Date()}
                        inputFormat={"yyyy-MM-dd"}
                        value={campaignValues.endDate}
                        onChange={(newValue) => {
                          handleCampaignFieldChange("endDate", newValue);
                        }}
                        // disabled={campaignValues.startDate=== "" ? true: false}
                        // minDate={new Date()}
                        minDate={campaignValues.startDate}
                        //maxDate={}
                        renderInput={(params) => (
                          <TextField
                            style={{
                              width: "100%",
                              height: 56,
                              border: "1px solid #ccc",
                              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className={classes.formGroup}>
                    <label
                      // htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Hours of Participation from a Changemaker{" "}
                      <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(15)}
                      ></i>
                    </label>
                    {fieldVisibility[15] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-18px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        Enter the number of hours required from each Changemaker
                        for this campaign. For example, for a Tree Planting
                        campaign, if you expect a changemaker to contribute for
                        say 3 hours, enter 3 (in number).
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        value={campaignValues?.hourOfParticipation}
                        type="number"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        min="0"
                        onKeyPress={(e) => {
                          preventMinus(e);
                        }}
                        // onKeyDown={(e) => { handleKeypress(e)}}
                        onBlur={() => {
                          if (!campaignValues?.hourOfParticipation) {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              hourOfParticipationError: true,
                            });
                          } else {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              hourOfParticipationError: false,
                            });
                          }
                        }}
                        onChange={(e) => {
                          handleCampaignFieldChange("hourOfParticipation", e);
                        }}
                      />
                    </div>
                    {campaignValuesError?.hourOfParticipationError && (
                      <ErrorMessage message="Please enter the hours of participation" />
                    )}
                  </div>

                  {/* <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Rewards to be Earned <RedStar />
                    </label> */}

                  {/* This is using Multiselect react lib */}
                  {/* <Multiselect
                      displayValue="rewards"
                      showCheckbox
                      selectedValues={selected}
                      options={focusAreaList}
                      onSelect={(event) => {
                        setSelected(event);
                        if (_.isEmpty(event)) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            rewardTypeError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            rewardTypeError: false,
                          });
                        }
                      }}
                      onBlur={(event) => {
                        if (_.isEmpty(event)) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            rewardTypeError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            rewardTypeError: false,
                          });
                        }
                      }}
                      value={selected}
                      style={{
                        multiselectContainer: {
                          boxShadow: "0px 3px 6px #00000029",
                        },
                        searchBox: {
                          minHeight: 54,
                          PaddingTop: 10
                        }
                      }}
                    /> */}

                  {/* This is using multi select drop down */}
                  {/* <Select
                  onBlur={() => {
                    if (_.isEmpty(selected)) {
                      setFocusError(true);
                    } else {
                      setFocusError(false);
                    }
                  }}
                  style={{
                    width: 350,
                    fontSize: 14,
                    height: 56,
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  }}
                  multiple
                  value={selected.map((item) => item?.rewards)}
                  onChange={handleChangeRewards}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {focusAreaList.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox checked={selected?.indexOf(option) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={option?.rewards} />
                    </MenuItem>
                  ))}
                </Select> */}

                  {/* {campaignValuesError?.rewardTypeError && (
                      <ErrorMessage message="Please select rewards to be earned" />
                    )}
                  </div> */}

                  <div className={classes.formGroup}>
                    <label
                      // htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Skills/Tags <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(16)}
                      ></i>
                    </label>
                    {fieldVisibility[16] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-35px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        Chose atleast one or more skill(s) you expect the
                        changemakers to possess for participating in this
                        campaign.
                      </div>
                    )}

                    <Multiselect
                      displayValue="skill"
                      showCheckbox
                      selectedValues={selected1}
                      options={skill}
                      onSelect={(event) => {
                        setSelected1(event);
                        if (_.isEmpty(event)) {
                          setFocusError(true);
                          setCampaignValuesError({
                            ...campaignValuesError,
                            setSkillError: true,
                          });
                        } else {
                          setFocusError(false);
                          setCampaignValuesError({
                            ...campaignValuesError,
                            setSkillError: false,
                          });
                        }
                      }}
                      onRemove={(event) => {
                        if (_.isEmpty(event)) {
                          setFocusError(true);
                          setCampaignValuesError({
                            ...campaignValuesError,
                            setSkillError: true,
                          });
                        } else {
                          setFocusError(false);
                          setCampaignValuesError({
                            ...campaignValuesError,
                            setSkillError: false,
                          });
                        }
                      }}
                      onBlur={() => {
                        if (_.isEmpty(selected1)) {
                          setFocusError(true);
                        } else {
                          setFocusError(false);
                        }
                      }}
                      value={selected1}
                      style={{
                        multiselectContainer: {
                          boxShadow: "0px 3px 6px #00000029",
                        },
                        searchBox: {
                          minHeight: 54,
                          PaddingTop: 10,
                        },
                        
                      }}
                    />

                    
                    {focusError && (
                      <ErrorMessage message="Please select skills/tags" />
                    )}
                  </div>

                  <div className={classes.formGroup}>
                    <label
                      // htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Social Media Message
                      <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(17)}
                      ></i>
                    </label>
                    {fieldVisibility[17] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-35px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        This message will be auto generated when the
                        changemakers share this campaign with their network(s)
                        on Social Media.
                      </div>
                    )}
                    <textarea
                      value={campaignValues?.socialMediaMessage}
                      type="text"
                      id="create-yaad--title"
                      className={classes.input}
                      placeholder="Your answer"
                      // maxLength="500"
                      onBlur={() => {
                        if (!campaignValues?.socialMediaMessage) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            socialMediaMessageError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            socialMediaMessageError: false,
                          });
                        }
                      }}
                      onChange={(e) => {
                        handleCampaignFieldChange("socialMediaMessage", e);
                      }}
                    />
                    {campaignValuesError?.socialMediaMessageError && (
                      <ErrorMessage message="Please enter the social media message" />
                    )}
                  </div>
                  <div className={classes.formGroup}>
                    <label
                      // htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Campaign Poster <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(18)}
                      ></i>
                    </label>
                    {fieldVisibility[18] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-35px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        Campaign poster will be displayed on the campaign card.
                      </div>
                    )}
                    <input
                      ref={inputRef}
                      value={campaignValues?.campaignLogo?.[0]?.name}
                      type="file"
                      accept="image/*"
                      id="example-file"
                      className={classes.input}
                      placeholder="Your answer"
                      onBlur={() => {
                        if (!campaignValues?.campaignLogo) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            campaignLogoError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            campaignLogoError: false,
                          });
                        }
                      }}
                      onChange={(e) => {
                        handleCampaignFieldChange("campaignLogo", e);
                      }}
                    />
                    {campaignValuesError?.campaignLogoError && (
                      <ErrorMessage message="Please upload campaign logo" />
                    )}
                  </div>

                  <div className={classes.formGroup}>
                    <label
                      // htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Campaign Video <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(19)}
                      ></i>
                    </label>
                    {fieldVisibility[19] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-35px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        This must be a 2-3 mins video summarizing the Campaign.
                        Campaign video will be available on the campaign card
                        for the changemakers to watch and understand the essence
                        of this campaign. Please make sure the file size is less than 50 MB.
                      </div>
                    )}
                    <input
                      ref={videoRef}
                      value={campaignValues?.campaignVideo?.[0]?.name}
                      type="file"
                      accept="video/*"
                      id="create-yaad--title"
                      className={classes.input}
                      onBlur={() => {
                        if (!campaignValues?.campaignLogo) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            campaignVideoError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            campaignVideoError: false,
                          });
                        }
                      }}
                      onChange={(e) => {
                        handleCampaignFieldChange("campaignVideo", e);
                      }}
                      progress="line"
                    />
                    {campaignValuesError?.campaignVideoError && (
                      <ErrorMessage message="Please upload campaign video" />
                    )}
                  </div>

                  {/* {rewardtitle.map((itemreward, index) => (
                      <div key={index}>{getRewardDetails(itemreward, index)}</div>
                    ))} */}

                  <div className={classes.formGroup}>
                    <label
                      // htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Total budget <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(20)}
                      ></i>
                    </label>
                    {fieldVisibility[20] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-35px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        Total budget of the campaign preferrably a number
                        without any special characters. For example - 10000,
                        505000, 999999 etc.
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        value={campaignValues?.totalBudget}
                        type="number"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        min="0"
                        onKeyPress={(e) => {
                          preventMinus(e);
                        }}
                        // onKeyDown={(e) => { handleKeypress(e)}}
                        onBlur={() => {
                          if (!campaignValues?.totalBudget) {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              totalBudgetError: true,
                            });
                          } else {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              totalBudgetError: false,
                            });
                          }
                        }}
                        onChange={(e) => {
                          handleCampaignFieldChange("totalBudget", e);
                        }}
                      />
                    </div>
                    {campaignValuesError?.totalBudgetError && (
                      <ErrorMessage message="Please enter the total budget for the campaign" />
                    )}
                  </div>

                  <div className={classes.formGroup}>
                    <label
                      // htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Currency Type <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(21)}
                      ></i>
                    </label>
                    {fieldVisibility[21] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-35px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        Currency type in which the budget amount is entered.
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                      className="menuDropDown"
                    >
                      <Select
                        style={{
                          width: "100%",
                          fontSize: 14,
                          height: 53,
                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                        }}
                        placeholder="Select" 
                        value={campaignValues.currencyType}
                        onBlur={() => {
                          if (!campaignValues?.currencyType) {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              currencyTypeError: true,
                            });
                          } else {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              currencyTypeeError: false,
                            });
                          }
                        }}
                        onChange={(e) =>
                          handleCampaignFieldChange("currencyType", e)
                        }
                      >
                        {currencyType.map((item, index) => (
                          <MenuItem style={{ fontSize: 16 }} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    {campaignValuesError?.currencyTypeError && (
                      <ErrorMessage message="Please select the currency type value" />
                    )}
                  </div>

                  <div className={classes.formGroup}>
                    <label
                      // htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Campaign Goal in Number <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(22)}
                      ></i>
                    </label>
                    {fieldVisibility[22] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-14px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        Quantify this campaign with a number. For example,
                        Tree Planting campaign may have a goal to plant 1000 tree saplings
                        in which case you enter 1000 in this field.
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        value={campaignValues?.goalquantity}
                        type="number"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/\D/g, '');
                        }}
                        min="0"
                        onKeyPress={(e) => {
                          preventMinus(e);
                        }}
                        // onKeyDown={(e) => { handleKeypress(e)}}
                        onBlur={() => {
                          if (!campaignValues?.goalquantity) {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              goalquantityError: true,
                            });
                          } else {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              goalquantityError: false,
                            });
                          }
                        }}
                        onChange={(e) => {
                          handleCampaignFieldChange("goalquantity", e);
                        }}
                      />
                    </div>
                    {campaignValuesError?.goalquantityError && (
                      <ErrorMessage message="Please enter the Goal in number for the campaign" />
                    )}
                  </div>


                  <div className={classes.formGroup}>
                    <label
                      // htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Unit of Measurement for Goal <RedStar />
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(23)}
                      ></i>
                    </label>
                    {fieldVisibility[23] && (
                      <div
                        className="bottom-div"
                        style={{
                          top: "-14px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        Goal of the campaign needs to be measurable. For example,
                        Tree Planting campaign may have a goal to plant 1000 tree saplings
                        in which case you enter "Tree Saplings" or "Trees" in this field.
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        value={campaignValues?.unitofgoal}
                        type="text"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        onInput={(e) => { e.target.value = e.target.value.replace(/[^A-Za-z]/ig, ''); }}

                        onKeyPress={(e) => {
                          preventMinus(e);
                        }}
                        // onKeyDown={(e) => { handleKeypress(e)}}
                        onBlur={() => {
                          if (!campaignValues?.unitofgoal) {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              unitofgoalError: true,
                            });
                          } else {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              unitofgoalError: false,
                            });
                          }
                        }}
                        onChange={(e) => {
                          handleCampaignFieldChange("unitofgoal", e);
                        }}
                      />
                    </div>
                    {campaignValuesError?.unitofgoalError && (
                      <ErrorMessage message="Please enter the unit of goal" />
                    )}
                  </div>


                  <div className={classes.formGroup}>
                    <label
                      // htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Enter Measurement criteria
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(24)}
                      ></i>
                    </label>
                    {fieldVisibility[24] && (
                      <div
                        className="bottom-div"
                        style={{
                          // top: "-14px",
                          // top: "-50px",
                          position: "relative",
                          fontSize: "12px",
                        }}
                      >
                        You may enter measurement criteria of your choice
                        that would be visible under Impact Assessment/Measurement
                        section on completion of this campaign.
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        value={campaignValues?.custommeasurement1}
                        type="text"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        min="0"
                        onKeyPress={(e) => {
                          preventMinus(e);
                        }}
                        // onKeyDown={(e) => { handleKeypress(e)}}
                        onBlur={() => {
                          if (!campaignValues?.custommeasurement1) {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              custommeasurement1Error: true,
                            });
                          } else {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              custommeasurement1Error: false,
                            });
                          }
                        }}
                        onChange={(e) => {
                          handleCampaignFieldChange("custommeasurement1", e);
                        }}
                      />
                    </div>
                    {/* {campaignValuesError?.custommeasurement1Error && (
                      <ErrorMessage message="Please enter the unit of goal" />
                    )} */}
                  </div>


                  <div className={classes.formGroupFull}>
                    <div className={classes.formGroup2}>
                      <span
                        style={{
                          paddingLeft: 0,
                          fontSize: 16,
                          fontWeight: 500,
                        }}
                      >
                        Operational Areas
                      </span>{" "}
                      <span style={{ color: "red" }}>*</span>{" "}
                      <i
                        class="fa fa-question-circle"
                        style={{
                          color: "#DF7F13",
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                        onClick={() => toggleField(25)}
                      ></i>
                      <span
                        style={{
                          paddingLeft: 0,
                          fontSize: 16,
                          fontWeight: 300,
                          fontStyle: "italic",
                        }}
                      >
                        {" "}
                        (Search for location, select and click on Add to
                        confirm. Click on "+" icon to enter additional
                        locations){" "}
                      </span>
                      {fieldVisibility[25] && (
                        <div
                          className="bottom-div"
                          style={{ position: "relative", fontSize: "12px" }}
                        >
                          Step 1 - Search for location, Step 2 - Select from the
                          options in drop down, Step 3 - Click on Add button
                          below to confirm the location. A green tick will
                          appear on successful adding.
                        </div>
                      )}
                      <PlacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div style={{ padding: "25px 0px 0px 0px" }}>
                            {/* <input {...getInputProps({ placeholder: "Type Address", autoFocus: true, endAdornment:<SearchIcon />  })} /> */}
                            <TextField
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment>
                                    <IconButton>
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              {...getInputProps({
                                placeholder: "Search for location..",
                                autoFocus: false,
                              })}
                            />
                            <div>
                              {" "}
                              {loading ? <div>...loading...</div> : null}
                            </div>
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "blue"
                                  : "white",
                              };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                    {location.map((item, index) => (
                      <div key={index}>{getAddress(item, index)}</div>
                    ))}
                    {/* <AddCircleOutlineOutlined
                  formStyle={{ paddingTop: "20px" }}
                  onClick={handleAddFields}
                ></AddCircleOutlineOutlined> */}
                  </div>
                </div>
                <div>
                  {isCampaignSuccess && (
                    campaignResponse?.status === "success" ?
                      <span
                        style={{
                          color: "green",
                          textAlign: "center",
                          display: "block",
                        }}
                      >
                        {campaignResponse?.message}
                      </span>
                      :
                      <span
                        style={{
                          color: "red",
                          textAlign: "center",
                          display: "block",
                        }}
                      >
                        {campaignResponse?.message}
                      </span>
                  )}
                  {isCampaignError && (
                    // <span style={{ color: "red" }}>Error occured</span>
                    <span
                      style={{
                        color: "red",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      {campaignResponse?.message}
                    </span>
                  )}
                </div>
                <div className="centerAlign" style={{ padding: "16px 0" }}>
                  <Button
                    variant="contained"
                    onClick={handleOnboardCampaign}
                    className="btn save-btn"
                    disableElevation
                    disabled={isCampaignFetching}
                  >
                    {isCampaignFetching ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      <span>Submit</span>
                    )}
                  </Button>
                  <br />
                  {/* {isCampaignSuccess && 
                  <span style={{ color: "green", textAlign: "center", display: "block" }}>{campaignResponse?.message}</span>}
                  {isCampaignError && (
                    // <span style={{ color: "red" }}>Error occured</span>
                    <span style={{ color: "red", textAlign: "center", display: "block" }}>{campaignResponse?.message}</span>
                  )} */}
                </div>
              </div>
            </form>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
      <Modal show={showPreviewModal} onHide={hidePreviewModal}>
        <div class="modal-header">
          <h5 class="modal-title">Preview Created Campaign</h5>
          <buttons
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={hidePreviewModal}
          >
            <span aria-hidden="true">&times;</span>
          </buttons>
        </div>
        <Modal.Body>
          <div class="campaigns-wrap campaigns-wrap-new">
            <div>
              <div>
                <div class="campaign-img">
                  <div class="video-wrapper">
                    <div class="video-container" id="video-container">
                      {/* <video controls="" id="video" preload="auto" poster="https://mt-dev-cyaag.s3.ap-south-1.amazonaws.com/Campaign/c00003/camp00025/logo/Capture.JPG">
                        <source src="https://mt-dev-cyaag.s3.ap-south-1.amazonaws.com/Campaign/c00003/camp00025/video/y2mate.com_-_Plastic_Pollution_480p.mp4" type="video/mp4"/>
                      </video> */}

                      <video
                        controls
                        id="video"
                        preload="auto"
                        poster={
                          campaignValues?.campaignLogo
                            ? URL.createObjectURL(campaignValues.campaignLogo)
                            : ""
                        }
                        autoplay
                      >
                        <source
                          src={
                            campaignValues?.campaignVideo
                              ? URL.createObjectURL(
                                campaignValues.campaignVideo
                              )
                              : ""
                          }
                          type="video/mp4"
                          display="none"
                        />
                      </video>
                    </div>
                  </div>
                </div>
                <div class="campaigns-details">
                  <h5
                    title="Quality Education and Disaster Prepardness in Rural India"
                    class="campaign-title"
                  >
                    {campaignValues?.titleOfCampaign
                      ? campaignValues?.titleOfCampaign
                      : "<Campaign Name>"
                      }
                  </h5>

                  
                  <div className={classes.campaigntypewrapper}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={0.5}
                  >
                    <h5 class="campaign-goal">
                      <a
                        class="hash-styles-goal"
                        style={{
                          padding:
                            "0px 5px 0px 0px",
                          cursor: "initial",
                        }}
                      >
                                                  
                        <span style={{ fontSize: "16px", fontWeight: 700 }}>Goal</span>: 
                        {
                        !campaignValues?.goalquantity ? (
                        <span class="text-6" style={{ color: "black" }}>{'<'}Goal of Campaign{'>'} {campaignValues?.unitofgoal}</span>
                        ):(
                        <span class="text-6" style={{ color: "black" }}>{campaignValues?.goalquantity}{" "} {campaignValues?.unitofgoal}</span>
                        )
                        }
                      </a>
                    </h5>
                  </Stack>
                </div>
                  







                  <div class="p-bar-new">
                                                          <div class="row ">
                                                            <div class="col-6">
                                                              <div>
                                                                <h6>
                                                                  <span style={{ color: "#AAAAAA" }}>Pledged: </span>{" "} <span style={{ backgroundColor: "#FFDB58", fontSize:"12px" }}>
                                                                    {/* {Math.round(
                                                                      (campaignValues?.committedcount)
                                                                    )} */}
                                                                    0
                                                                  </span>
                                                                </h6>
                                                              </div>
                                                            </div>
                                                            <div class="col-6">
                                                              <div
                                                                style={{
                                                                  textAlign:
                                                                    "right",
                                                                }}
                                                              >
                                                                <h6>
                                                                  <span style={{ color: "#AAAAAA" }}>Achieved: </span>
                                                                  {" "}
                                                                  <span style={{ backgroundColor: "#A9D18E", fontSize:"12px" }}> 0</span>
                                                                  {" "}
                                                                </h6>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="progress br-30 campaign-progress" style={{ position: "relative" }}>
                                                            <div
                                                              className="progress-bar"
                                                              role="progressbar"
                                                              style={{
                                                                width: `${(campaignValues?.committedcount / campaignValues?.goalquantity) * 100}%`,
                                                                backgroundColor: "#FFDB58",
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                zIndex: 1,
                                                                height: "100%"
                                                              }}
                                                              aria-valuenow={Math.round((campaignValues?.committedcount / campaignValues?.goalquantity) * 100)}
                                                              aria-valuemin="0"
                                                              aria-valuemax="100"
                                                            ></div>

                                                            <div
                                                              className="progress-bar"
                                                              role="progressbar"
                                                              style={{
                                                                width: `${(campaignValues?.achievedcount / campaignValues?.goalquantity) * 100}%`,
                                                                backgroundColor: "#A9D18E",
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                zIndex: 2,
                                                                height: "100%"
                                                              }}
                                                              aria-valuenow={Math.round((campaignValues?.achievedcount / campaignValues?.goalquantity) * 100)}
                                                              aria-valuemin="0"
                                                              aria-valuemax="100"
                                                            ></div>
                                                          </div>
                                                        </div>
                                                        <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                                          <div style={{ flex: 1 }}>
                                                            <h6>
                                                              <span style={{ color: "#AAAAAA" }}>Signed Up:</span>{" "}
                                                              <span style={{ backgroundColor: "#DAE8FD", fontSize:"12px" }}>0</span>
                                                            </h6>
                                                          </div>
                                                          <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                          {campaignValues?.campaignType === "" ? (
                                                                <Chip
                                                                  key={campaignValues?.campaignType}
                                                                  fontSize="small"
                                                                  size="small"
                                                                  label="<Mode>"
                                                                />
                                                              ) : (
                                                                <Chip
                                                                  key={campaignValues?.campaignType}
                                                                  fontSize="small"
                                                                  size="small"
                                                                  label={campaignValues?.campaignType}
                                                                />
                                                              )}
                                                           
                                                            <img
                                                              src={CampaignLeaderBoardIcon}
                                                              style={{ width: 25, height: 25 }}
                                                              // onClick={() =>
                                                              //   navigate(
                                                              //     `/camp/leaderboard/${tile.campid}`
                                                              //   )
                                                              // }
                                                            />
                                                          </div>
                                                        </div>








                </div>
                {/* <div className={classes.campaigntypewrapper}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={0.5}
                  >
                    <h5 class="campaign-goal-landing">
                      <a class="hash-styles-goal">
                        Need {campaignValues?.volunteerCount} changemakers
                      </a>
                      {campaignValues?.campaignType === "" ? (
                        <Chip
                          key={campaignValues?.campaignType}
                          fontSize="small"
                          size="small"
                          label="<campaign type>"
                        />
                      ) : (
                        <Chip
                          key={campaignValues?.campaignType}
                          fontSize="small"
                          size="small"
                          label={campaignValues?.campaignType}
                        />
                      )}
                    </h5>
                  </Stack>
                </div> */}

                

                <div class="row">
                  <div class="col-md-4 col-6 customHalfWidth">
                    <div class="cam-cols">
                      {/* <div>
                        <div style={{ float: "left" }}> */}
                      <div class="timer-here">
                        <div class="text-center">
                          {/* <div class="text-center" > */}

                          <span
                            class="campaign-time-title"
                            style={{ marginLeft: "0px" }}
                          >
                            Earn upto
                          </span>

                          <span
                            className="hoursWrapper leftAlignWrapper"
                            style={{ fontSize: 14 }}
                          >
                            <span className="upto-points-new">XXX </span>
                            points
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 col-6 customHalfWidth">
                    <div class="cam-cols">
                      <div class="timer-here">
                        <div class="text-center">
                          <span class="campaign-time-title">Time Left</span>
                        </div>
                        <div>
                          <ul id="countdown">
                            <li id="hours">
                              <div class="number">dd</div>
                              <div class="label">Days</div>
                            </li>
                            <li id="minutes">
                              <div class="number">hh</div>
                              <div class="label">Hours</div>
                            </li>
                            <li id="seconds">
                              <div class="number">mm</div>
                              <div class="label">Mins</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 customFullWidth">
                    <div class="par-btn-here">
                      <div class="text-center">
                        <a class="par-now-new">
                          <Button
                            style={{
                              color: "white",
                              // padding:"0px 0px 0px 0px",
                              padding: "6px",
                              fontSize: "12px",
                            }}
                            className={classes.button}
                          >
                            Participate Now{" "}
                            <i class="fa fa-arrow-right" aria-hidden="true"></i>
                          </Button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div class="p-bar-new">
              <div class="row ">
                <div class="col-6">
                  <div>
                    <h6>0% submitted</h6>
                  </div>
                </div>
                <div class="col-6">
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <h6>0/{campaignValues?.volunteerCount}</h6>
                  </div>
                </div>
              </div>
              <div class="progress br-30 campaign-progress">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  style={{
                    // width: "20%",
                    width:
                      Math.round((0 / campaignValues?.volunteerCount) * 100) +
                      "%",
                  }}
                  // aria-valuenow="25"
                  aria-valuenow={Math.round(
                    (0 / campaignValues?.volunteerCount) * 100
                  )}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <span class="progress-txt">
                0 changemakers submitted out of {campaignValues?.volunteerCount}
              </span>
            </div> */}

            {/* <div class="p-bar-new">
              <div class="row ">
                <div class="col-6">
                  <div>
                    <h6>0% approved</h6>
                  </div>
                </div>
                <div class="col-6">
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <h6>0/{campaignValues?.volunteerCount}</h6>
                  </div>
                </div>
              </div>
              <div class="progress br-30 campaign-progress">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  style={{
                    // width: "20%",
                    width:
                      Math.round((0 / campaignValues?.volunteerCount) * 100) +
                      "%",
                  }}
                  // aria-valuenow="25"
                  aria-valuenow={Math.round(
                    (0 / campaignValues?.volunteerCount) * 100
                  )}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <span class="progress-txt">
                0 changemakers approved out of {campaignValues?.volunteerCount}
              </span>
            </div> */}

            <div class="logged-cam-detai">
              <div class="aft-log-acc">
                <div class="widget-content widget-content-area creative2-accordion-content">
                  <div id="creativeAccordion">
                    <div class="card mb-3">
                      <div class="card-header" id="creative2headingOne">
                        <h5 class="mb-0 mt-0">
                          <span
                            role="menu"
                            class="changeIcon collapsed"
                            data-toggle="collapse"
                            data-target={
                              "#" + "creative2CollapseOne" + "Active"
                            }
                            aria-expanded="true"
                            aria-controls="creative2CollapseOne"
                          >
                            <span class="icon">
                              <i class="fa fa-plus"></i>
                              <i class="fa fa-minus"></i>
                            </span>
                            <span class="text ml-2">
                              Achieve the goal and win rewards
                            </span>
                          </span>
                        </h5>
                      </div>

                      <div
                        // id="creative2CollapseOne"
                        id={"creative2CollapseOne" + "Active"}
                        class="collapse"
                        aria-labelledby="creative2headingOne"
                        data-parent="#creativeAccordion"
                      >
                        <div class="card-body">
                          <p class="mb-3">1. {campaignValues?.goal}</p>
                          <p class="mb-3">2. {campaignValues?.descriptionOfCampaign}</p>
                          <p class="mb-3">
                            3. Win upto <b> MMM </b>
                            points by Participating or Inviting motivated
                            friends to join this Campaign.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="card mb-3">
                      <div class="card-header" id="creative2HeadingTwo">
                        <h5 class="mb-0 mt-0">
                          <span
                            role="menu"
                            class="changeIcon collapsed"
                            data-toggle="collapse"
                            data-target={
                              "#" + "creative2CollapseTwo" + "Active"
                            }
                            aria-expanded="false"
                            aria-controls="creative2CollapseTwo"
                          >
                            <span class="icon">
                              <i class="fa fa-plus"></i>
                              <i class="fa fa-minus"></i>
                            </span>
                            <span class="text ml-2">
                              How to participate?
                            </span>
                          </span>
                        </h5>
                      </div>
                      <div
                        id={"creative2CollapseTwo" + "Active"}
                        class="collapse"
                        aria-labelledby="creative2HeadingTwo"
                        data-parent="#creativeAccordion"
                      >
                        <div class="card-body">
                          <p class="mb-3">
                            {splitString1(
                              campaignValues?.whattodo
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="card mb-3">
                      <div class="card-header" id="creative2HeadingThree">
                        <h5 class="mb-0 mt-0">
                          <span
                            role="menu"
                            class="changeIcon collapsed"
                            data-toggle="collapse"
                            data-target={
                              "#" + "creative2CollapseThree" + "Active"
                            }
                            aria-expanded="false"
                            aria-controls="creative2CollapseThree"
                          >
                            <span class="icon">
                              <i class="fa fa-plus"></i>
                              <i class="fa fa-minus"></i>
                            </span>
                            <span class="text ml-2">Guidelines</span>
                          </span>
                        </h5>
                      </div>
                      <div
                        id={"creative2CollapseThree" + "Active"}
                        class="collapse"
                        aria-labelledby="creative2HeadingThree"
                        data-parent="#creativeAccordion"
                      >
                        <div class="card-body">
                          <p class="mb-3">
                            {splitString1(
                              campaignValues?.guidelines
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Buttons variant="default" onClick={hidePreviewModal}>
            OK
          </Buttons>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default CreateCampaign;
