/** @format */

const GET_CITY_FETCHING_DATA = "GET_CITY_FETCHING_DATA";
const GET_CITY_FETCHING_DATA_FAILURE = "GET_CITY_FETCHING_DATA_FAILURE";
const GET_CITY_FETCHING_DATA_RESET = "GET_CITY_FETCHING_DATA_RESET";
const GET_CITY_FETCHING_DATA_SUCCESS = "GET_CITY_FETCHING_DATA_SUCCESS";
const GET_CITY_FETCHING_DATA_ATTEMPT = "GET_CITY_FETCHING_DATA_ATTEMPT";
const getCity = (payload) => ({
  type: GET_CITY_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const getCityReset = (payload) => ({
  type: GET_CITY_FETCHING_DATA_RESET,
  payload: payload,
});

export {
  GET_CITY_FETCHING_DATA,
  GET_CITY_FETCHING_DATA_FAILURE,
  GET_CITY_FETCHING_DATA_RESET,
  GET_CITY_FETCHING_DATA_SUCCESS,
  GET_CITY_FETCHING_DATA_ATTEMPT,
  getCity,
  getCityReset,
};
