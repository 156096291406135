/** @format */

const VERIFY_EMAIL_FETCHING_DATA = "VERIFY_EMAIL_FETCHING_DATA";
const VERIFY_EMAIL_FETCHING_DATA_FAILURE = "VERIFY_EMAIL_FETCHING_DATA_FAILURE";
const VERIFY_EMAIL_FETCHING_DATA_RESET = "VERIFY_EMAIL_FETCHING_DATA_RESET";
const VERIFY_EMAIL_FETCHING_DATA_SUCCESS = "VERIFY_EMAIL_FETCHING_DATA_SUCCESS";
const VERIFY_EMAIL_FETCHING_DATA_ATTEMPT = "VERIFY_EMAIL_FETCHING_DATA_ATTEMPT";
const verifyEmail = (payload) => ({
  type: VERIFY_EMAIL_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const verifyEmailReset = (payload) => ({
  type: VERIFY_EMAIL_FETCHING_DATA_RESET,
});
export {
  VERIFY_EMAIL_FETCHING_DATA,
  VERIFY_EMAIL_FETCHING_DATA_FAILURE,
  VERIFY_EMAIL_FETCHING_DATA_RESET,
  VERIFY_EMAIL_FETCHING_DATA_SUCCESS,
  VERIFY_EMAIL_FETCHING_DATA_ATTEMPT,
  verifyEmail,
  verifyEmailReset,
};
