/** @format */

import { call, put } from "redux-saga/effects";
import { get } from "lodash";

import {
  UPDATE_CAMPAIGN_FETCHING_DATA,
  UPDATE_CAMPAIGN_FETCHING_DATA_SUCCESS,
  UPDATE_CAMPAIGN_FETCHING_DATA_FAILURE,


  UPDATE_ORGANIZATION_FETCHING_DATA,
  UPDATE_ORGANIZATION_FETCHING_DATA_FAILURE,
  UPDATE_ORGANIZATION_FETCHING_DATA_RESET,
  UPDATE_ORGANIZATION_FETCHING_DATA_SUCCESS,

  UPDATE_SPONSOR_FETCHING_DATA,
  UPDATE_SPONSOR_FETCHING_DATA_FAILURE,
  UPDATE_SPONSOR_FETCHING_DATA_SUCCESS,

  UPDATE_REWARD_FETCHING_DATA,
  UPDATE_REWARD_FETCHING_DATA_FAILURE,
  UPDATE_REWARD_FETCHING_DATA_SUCCESS,

  UPDATE_KTCUSTOMER_FETCHING_DATA,
  UPDATE_KTCUSTOMER_FETCHING_DATA_FAILURE,
  UPDATE_KTCUSTOMER_FETCHING_DATA_SUCCESS,

  UPDATE_KTCUSTOMERTRN_FETCHING_DATA,
  UPDATE_KTCUSTOMERTRN_FETCHING_DATA_FAILURE,
  UPDATE_KTCUSTOMERTRN_FETCHING_DATA_SUCCESS,

} from "../action/UpdateAction";
import { Api } from "../../services";

const myAPI = new Api();

export function* updateCampaign(action) {
  yield put({
    type: UPDATE_CAMPAIGN_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.updateCampaign, action.payload);
    const payload = get(response, "data");
    yield put({
      type: UPDATE_CAMPAIGN_FETCHING_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    yield put({ type: UPDATE_CAMPAIGN_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}

export function* updateOrg(action) {
  yield put({
    type: UPDATE_ORGANIZATION_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.updateOrg, action.payload);
    const payload = get(response, "data");
    yield put({
      type: UPDATE_ORGANIZATION_FETCHING_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    yield put({ type: UPDATE_ORGANIZATION_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}

export function* updateSponsor(action) {
  yield put({
    type: UPDATE_SPONSOR_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.updateSponsor, action.payload);
    const payload = get(response, "data");
    yield put({
      type: UPDATE_SPONSOR_FETCHING_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    yield put({ type: UPDATE_SPONSOR_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}

export function* updateReward(action) {
 
  yield put({
    type: UPDATE_REWARD_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.updateReward, action.payload);
    const payload = get(response, "data");
    yield put({
      type: UPDATE_REWARD_FETCHING_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    yield put({ type: UPDATE_REWARD_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}

export function* updateKTCustomer(action) {
 
  yield put({
    type: UPDATE_KTCUSTOMER_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.updateKTCustomer, action.payload);
    const payload = get(response, "data");
    yield put({
      type: UPDATE_KTCUSTOMER_FETCHING_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    yield put({ type: UPDATE_KTCUSTOMER_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}


export function* updateKTCustomerTRN(action) {
 
  yield put({
    type: UPDATE_KTCUSTOMERTRN_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.updateKTCustomerTRN, action.payload);
    const payload = get(response, "data");
    yield put({
      type: UPDATE_KTCUSTOMERTRN_FETCHING_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    yield put({ type: UPDATE_KTCUSTOMERTRN_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}
