/** @format */

import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import { Box, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import "../../scss/containerStyles/_home.scss";
import "../../App.css";

import { userProfile } from "../../redux/action/UserProfileActions";
import ResponsiveDrawer from "../../components/Header";
import AdminDashboard from "../../components/AdminDashboard";
import { CopyRight } from "../../components/CopyRight";
import ExecDashboard from "../../components/ExecDashboard";
import { getVolunteerCount } from "../../redux/action/volunteerCountAction";
import ProfileScreen from "../ProfileScreen";
import CampaignListScreen from "../../routes/Campaigns/ListScreen";
import HAScreen from "../../routes/HAScreen/HAScreen";
import { getCampaign } from "../../redux/action/CampaignAction";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 100px 330px",
    },
  },
  homeScreenContainer: {
    padding: "0 0 0 20px",
    zIndex: 100,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8B34C",
    color: "#ffffff",
    textTransform: "uppercase",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function createData(id, fullName, rewardPoint) {
  return { id, fullName, rewardPoint };
}

const rows = [
  createData(1, "Rajib Shrestha", 2000),
  createData(2, "Gautam Bhat", 2000),
  createData(3, "Kiran CP", 2000),
  createData(4, "Rajib Shrestha", 2000),
  createData(5, "Gautam Bhatt", 2000),
];

const HomeScreen = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [notificationShow, setNotificationShow] = useState(false);
  const { response: volunteerCountResponse } = useSelector(
    (state) => state.getVolunteerCount
  );
  // console.log(volunteerCountResponse)
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const { isSuccess: campSuccess, response: campResponse } = useSelector(
    (state) => state.getCampaign
  );
  // console.log(campResponse)
  const userid = localStorage.getItem("userid");
  useEffect(() => {
    dispatch(userProfile(userid));
    dispatch(getVolunteerCount());
    dispatch(getCampaign(profileResponse.orgrole, profileResponse.organization));
    dispatch(getCampaign());
  }, []);

  // console.log("apikey", process.env.REACT_APP_GOOGLE_API_KEY);
  return (
    <div>
      {/* <CampaignListScreen
        notificationShow={notificationShow}
        setNotificationShow={setNotificationShow}
      /> */}
      <HAScreen
        notificationShow={notificationShow}
        setNotificationShow={setNotificationShow}
      />
    </div>
  );
};

export default HomeScreen;
