/** @format */

import { call, put } from "redux-saga/effects";
import { get } from "lodash";

import {
  VALIDATOR_LIST_FETCHING_DATA,
  VALIDATOR_LIST_FETCHING_DATA_SUCCESS,
  VALIDATOR_LIST_FETCHING_DATA_FAILURE,
} from "../action/ValidatorList";
import { Api } from "../../services";

const myAPI = new Api();

export function* validatorList(action) {
  yield put({
    type: VALIDATOR_LIST_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.validatorList, action.payload);
    const payload = get(response, "data");
    // console.log(payload)
    yield put({
      type: VALIDATOR_LIST_FETCHING_DATA_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({ type: VALIDATOR_LIST_FETCHING_DATA_FAILURE });
  }
}
