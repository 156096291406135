/** @format */


import {
    UPDATE_CAMPAIGN_FETCHING_DATA,
    UPDATE_CAMPAIGN_FETCHING_DATA_FAILURE,
    UPDATE_CAMPAIGN_FETCHING_DATA_RESET,
    UPDATE_CAMPAIGN_FETCHING_DATA_SUCCESS,

    UPDATE_ORGANIZATION_FETCHING_DATA,
    UPDATE_ORGANIZATION_FETCHING_DATA_FAILURE,
    UPDATE_ORGANIZATION_FETCHING_DATA_RESET,
    UPDATE_ORGANIZATION_FETCHING_DATA_SUCCESS,

    UPDATE_SPONSOR_FETCHING_DATA,
    UPDATE_SPONSOR_FETCHING_DATA_FAILURE,
    UPDATE_SPONSOR_FETCHING_DATA_RESET,
    UPDATE_SPONSOR_FETCHING_DATA_SUCCESS,

    UPDATE_REWARD_FETCHING_DATA,
    UPDATE_REWARD_FETCHING_DATA_FAILURE,
    UPDATE_REWARD_FETCHING_DATA_RESET,
    UPDATE_REWARD_FETCHING_DATA_SUCCESS,

    UPDATE_KTCUSTOMER_FETCHING_DATA,
    UPDATE_KTCUSTOMER_FETCHING_DATA_FAILURE,
    UPDATE_KTCUSTOMER_FETCHING_DATA_RESET,
    UPDATE_KTCUSTOMER_FETCHING_DATA_SUCCESS,

    UPDATE_KTCUSTOMERTRN_FETCHING_DATA,
    UPDATE_KTCUSTOMERTRN_FETCHING_DATA_FAILURE,
    UPDATE_KTCUSTOMERTRN_FETCHING_DATA_RESET,
    UPDATE_KTCUSTOMERTRN_FETCHING_DATA_SUCCESS,
    

  } from "../action/UpdateAction";
  
  let initialState = {
    campaignResponse: {},
    isCampaignSuccess: false,
    isCampaignError: false,
    isCampaignFetching: false,

    organizationResponse: {},
    isOrganizationSuccess: false,
    isOrganizationError: false,
    isOrganizationFetching: false,

    sponsorResponse: {},
    isSponsorSuccess: false,
    isSponsorError: false,
    isSponsorFetching: false,

    rewardResponse: {},
    isRewardSuccess: false,
    isRewardError: false,
    isRewardFetching: false,

    ktCustomerResponse: {},
    isKTCustomerSuccess: false,
    isKTCustomerError: false,
    isKTCustomerFetching: false,

    ktCustomerTRNResponse: {},
    isKTCustomerTRNSuccess: false,
    isKTCustomerTRNError: false,
    isKTCustomerTRNFetching: false,

  };
  
  export default function UpdateReducer(state = initialState, action) {
    switch (action.type) {
      case UPDATE_CAMPAIGN_FETCHING_DATA:
        return {
          ...state,
          isCampaignFetching: true,
          isCampaignError: false,
          isCampaignSuccess: false,
        };
      case UPDATE_CAMPAIGN_FETCHING_DATA_FAILURE:
        return {
          ...state,
          isCampaignFetching: false,
          isCampaignError: true,
          isCampaignSuccess: false,
        };
      case UPDATE_CAMPAIGN_FETCHING_DATA_SUCCESS:
        return {
          ...state,
          isCampaignFetching: false,
          isCampaignError: false,
          campaignResponse: action.payload,
          isCampaignSuccess: true,
        };
  
      case UPDATE_CAMPAIGN_FETCHING_DATA_RESET:
        return {
          ...state,
          campaignResponse: {},
          isCampaignSuccess: false,
          isCampaignError: false,
          isCampaignFetching: false,
        };


        case UPDATE_ORGANIZATION_FETCHING_DATA:
        return {
          ...state,
          
          isOrganizationSuccess: false,
          isOrganizationError: false,
          isOrganizationFetching: true,
        };
      case UPDATE_ORGANIZATION_FETCHING_DATA_FAILURE:
        return {
          ...state,
          
          isOrganizationSuccess: false,
          isOrganizationError: true,
          isOrganizationFetching: false,
        };
      case UPDATE_ORGANIZATION_FETCHING_DATA_SUCCESS:
        return {
          ...state,
          organizationResponse: action.payload,
          isOrganizationSuccess: true,
          isOrganizationError: false,
          isOrganizationFetching: false,
        };
  
      case UPDATE_ORGANIZATION_FETCHING_DATA_RESET:
        return {
          ...state,
          organizationResponse: {},
          isOrganizationSuccess: false,
          isOrganizationError: false,
          isOrganizationFetching: false,
        };


        case UPDATE_SPONSOR_FETCHING_DATA:
        return {
          ...state,
          
          isSponsorSuccess: false,
          isSponsorError: false,
          isSponsorFetching: true,
        };
      case UPDATE_SPONSOR_FETCHING_DATA_FAILURE:
        return {
          ...state,
          
          isSponsorSuccess: false,
          isSponsorError: true,
          isSponsorFetching: false,
        };
      case UPDATE_SPONSOR_FETCHING_DATA_SUCCESS:
        return {
          ...state,
          sponsorResponse: action.payload,
          isSponsorSuccess: true,
          isSponsorError: false,
          isSponsorFetching: false,
        };
  
      case UPDATE_SPONSOR_FETCHING_DATA_RESET:
        return {
          ...state,
          sponsorResponse: {},
          isSponsorSuccess: false,
          isSponsorError: false,
          isSponsorFetching: false,
        };


        case UPDATE_REWARD_FETCHING_DATA:
          return {
            ...state,
            
            isRewardSuccess: false,
            isRewardError: false,
            isRewardFetching: true,
          };
        case UPDATE_REWARD_FETCHING_DATA_FAILURE:
          return {
            ...state,
            
            isRewardSuccess: false,
            isRewardError: true,
            isRewardFetching: false,
          };
        case UPDATE_REWARD_FETCHING_DATA_SUCCESS:
          return {
            ...state,
            rewardResponse: action.payload,
            isRewardSuccess: true,
            isRewardError: false,
            isRewardFetching: false,
          };
    
        case UPDATE_REWARD_FETCHING_DATA_RESET:
          return {
            ...state,
            rewardResponse: {},
            isRewardSuccess: false,
            isRewardError: false,
            isRewardFetching: false,
          };

          case UPDATE_KTCUSTOMER_FETCHING_DATA:
            return {
              ...state,
              
              isKTCustomerSuccess: false,
              isKTCustomerError: false,
              isKTCustomerFetching: true,
            };
          case UPDATE_KTCUSTOMER_FETCHING_DATA_FAILURE:
            return {
              ...state,
              
              isKTCustomerSuccess: false,
              isKTCustomerError: true,
              isKTCustomerFetching: false,
            };
          case UPDATE_KTCUSTOMER_FETCHING_DATA_SUCCESS:
            return {
              ...state,
              ktCustomerResponse: action.payload,
              isKTCustomerSuccess: true,
              isKTCustomerError: false,
              isKTCustomerFetching: false,
            };
      
          case UPDATE_KTCUSTOMER_FETCHING_DATA_RESET:
            return {
              ...state,
              ktCustomerResponse: {},
              isKTCustomerSuccess: false,
              isKTCustomerError: false,
              isKTCustomerFetching: false,
            };


            case UPDATE_KTCUSTOMERTRN_FETCHING_DATA:
            return {
              ...state,
              
              isKTCustomerTRNSuccess: false,
              isKTCustomerTRNError: false,
              isKTCustomerTRNFetching: true,
            };
          case UPDATE_KTCUSTOMERTRN_FETCHING_DATA_FAILURE:
            return {
              ...state,
              
              isKTCustomerTRNSuccess: false,
              isKTCustomerTRNError: true,
              isKTCustomerTRNFetching: false,
            };
          case UPDATE_KTCUSTOMERTRN_FETCHING_DATA_SUCCESS:
            return {
              ...state,
              ktCustomerTRNResponse: action.payload,
              isKTCustomerTRNSuccess: true,
              isKTCustomerTRNError: false,
              isKTCustomerTRNFetching: false,
            };
      
          case UPDATE_KTCUSTOMERTRN_FETCHING_DATA_RESET:
            return {
              ...state,
              ktCustomerTRNResponse: {},
              isKTCustomerTRNSuccess: false,
              isKTCustomerTRNError: false,
              isKTCustomerTRNFetching: false,
            };


      default:
        return state;
    }
  }

  
  