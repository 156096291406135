import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ResponsiveDrawer from "../Header";
import { CopyRight } from "../CopyRight";
import TopHeaderScreen from "../TopHeader1";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import AsyncSelect from "react-select/async";
import axios from "axios";

import "./Join.css";
import DeleteConfirmation from "../DeleteConfirmation";
import ConfirmationDialog from "../ConfirmationDialog";

const useStyles = makeStyles((theme) => ({
  pageHeading: {
    textTransform: "uppercase",
    // fontSize: 14,
    background: theme.palette.primary.main,
    textAlign: "center",
    color: "#fff",
    padding: 3,
    fontWeight: "bold",
    // borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pageSubheading: {
    padding: 10,
    fontSize: 14,
    background: "#E9E7E7",
    display: "block",
    color: "#000",
    // marginBottom: 14,
    fontWeight: "500",
  },
  // pageHeadingIcon: {
  //   fontSize: 25,
  //   // color: theme.palette.secondary.main,
  //   marginLeft: 8,
  // },
  backBtn: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },

    display: "block",
    display: "flex",
    //fontSize: 16,
    fontSize: "16px",
    // marginTop: 16,
    // padding: 10,
    fontWeight: "500",
    lineHeight: "18px",
    minHeight: 11,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  createroombox: {
    // width: "fit-content",
    width: "310px",
    display: "flex",
    flexDirection: "column",
    // maxWidth: "1000px",
    // height: "fit-content",
    border: "6px solid rgb(134, 133, 133)",
    // marginTop: "10px",
    // marginLeft: "500px",
    background: "white",
    // textAlign: "center",
    borderRadius: "10px",
    borderColor: "#f28800",
    alignItems: "center",
    justifyContent: "center",
    // padding: 10,
    // fontSize: 16,
    margin: "10px auto",
  },
}));

export default function SignIn(props) {
  const [name, setName] = useState("");
  const [room, setRoom] = useState("");
  const [goal, setGoal] = useState("");
  const [availableRoomDetails, setAvailableRoomDetails] = React.useState([]);
  const [availableRooms, setAvailableRooms] = React.useState([]);
  const [selectedroom, setselectedroom] = React.useState([]);
  const [createRoomFlag, setCreateRoomFlag] = React.useState(false);
  const [createRoomGoalFlag, setCreateRoomGoalFlag] = React.useState(false);
  const [joinRoomFlag, setJoinRoomFlag] = React.useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  const [displayInformationModal, setDisplayInformationModal] = useState(false);
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };
  const hideInformationModal = () => {
    setDisplayInformationModal(false);
    refresh();
  };
  const classes = useStyles();
  const navigate = useNavigate();
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  // console.log(profileResponse);

  useEffect(() => {
    if (profileSuccess) {
      setName(profileResponse?.firstname);
    }
  }, [profileSuccess]);

  const getAvailableRoomsList = async () => {
    try {
      const res = await axios.get(`/chat/room/list`);
      // setActivityList(res?.data);
      setAvailableRooms(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getAvailableRooms = async () => {
    try {
      const res = await axios.get(`/chat/room/${profileResponse?.phoneno}`);
      // setActivityList(res?.data);
      setAvailableRoomDetails(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getAvailableRoomsList();
    getAvailableRooms();
  }, []);

  // console.log("availableRoomDetails", availableRoomDetails);

  const loadOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = availableRooms.filter((category) =>
        category.label.toLowerCase().includes(searchValue.toLowerCase())
      );
      // console.log("loadoptions search  value= ", searchValue);
      // console.log("loadoptions filteredOptions = ", filteredOptions);
      callback(filteredOptions);
    });
  };

  const colorStyles = {
    control: (styles) => ({ ...styles }),
    // option: (styles, {data, isDisabled, isFocussed, isSelected}) => {
    //   return{...styles, color: "blue"};
    // },
    multiValue: (styles, { data }) => {
      return { ...styles, backgroundColor: "#0096FF", color: "#fff" };
    },
    multiValueLabel: (styles, { data }) => {
      return { ...styles, color: "#fff" };
    },
    multiValueRemove: (styles, { data }) => {
      return {
        ...styles,
        color: "#fff",
        cursor: "pointer",
        ":hover": {
          color: "#fff",
        },
      };
    },
  };

  const refresh = () => window.location.reload(true)

  const handleRoomCreate = async () => {
    // setDisplayConfirmationModal(true);
    const payload = {
      roomid: room,
      phoneno: profileResponse?.phoneno,
      goal: goal,
    };
    try {
      const res = await axios.post(`/chat/room`, payload);
      // setDisplayConfirmationModal(false);
      setDisplayInformationModal(true);
      
      navigate("/join");
      return res;
    } catch (err) {
      throw err;
    }
  };

  const handleDeleteRoom = async () => {
    // setDisplayConfirmationModal(true);
   
    const payload = {
      roomid: selectedroom["value"],
      // phoneno: profileResponse?.phoneno,
      // goal: goal,
    };
    try {
      const res = await axios.put(`/chat/room/delete`, payload);
      setDisplayConfirmationModal(false);
      refresh();
      navigate("/join");
      return res;
    } catch (err) {
      throw err;
    }
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <span
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
                display: "block",
              }}
            >
              {/* <span className={classes.pageSubHeading} > */}
              Chat with fellow Change Makers
            </span>
            <div className="joinOuterContainer">
              <div className="joinInnerContainer">
                <h1 className="heading">Join</h1>
                <div className={classes.createroombox}>
                  <div>
                    <input
                      placeholder="Name"
                      className="joinInput"
                      type="text"
                      value={name}
                      // onChange={(event) => setName(event.target.value)}
                      // disabled
                    />
                  </div>
                  <div>
                    <input
                      placeholder="Enter Room Name"
                      className="joinInput mt-20"
                      type="text"
                      onChange={(event) => {
                        setRoom(event.target.value);
                        setCreateRoomFlag(true);
                      }}
                      onBlur={(event) => {
                        if (!room) {
                          setCreateRoomFlag(false);
                        }
                      }}
                    />
                  </div>
                  <div>
                    <input
                      placeholder="Enter Goal"
                      className="joinInput mt-20"
                      type="text"
                      onChange={(event) => {
                        setGoal(event.target.value);
                        setCreateRoomGoalFlag(true);
                      }}
                      onBlur={(event) => {
                        if (!goal) {
                          setCreateRoomGoalFlag(false);
                        }
                      }}
                    />
                  </div>
                  {createRoomFlag && createRoomGoalFlag ? (
                    // <Link
                    //   onClick={(e) => (!name || !room ? e.preventDefault() : null)}
                    //   to={`/chat/${name}/${room}`}
                    // >
                    <button
                      className={"button mt-20"}
                      type="submit"
                      style={{ fontSize: "14px" }}
                      onClick={() => handleRoomCreate()}
                    >
                      Create Room
                    </button>
                  ) : (
                    // </Link>
                    <button
                      className={"button mt-20"}
                      disabled
                      style={{ backgroundColor: "gray", fontSize: "14px" }}
                    >
                      Create Room
                    </button>
                  )}
                </div>
                
                <div className={classes.createroombox}>
                <div style={{width: "88%"}}>
                  
                  <label
                    htmlFor="create-yaad--title"
                    className={classes.label}
                  ></label>
                  <AsyncSelect
                    loadOptions={loadOptions}
                    defaultOptions={availableRooms}
                    value={selectedroom}
                    placeholder="  Select Room"
                    styles={colorStyles}
                    // onChange={(e) => {
                    //   handleCampaignFieldChange("typeOfCampaign", e);
                    // }}
                    onChange={(event) => {
                  
                      setselectedroom(event);
                   
                      if (!selectedroom) {
                        setJoinRoomFlag(false);
                      } else {
                        setJoinRoomFlag(true);
                      }
                      // if (_.isEmpty(event)) {
                      //   setCampaignValuesError({
                      //     ...campaignValuesError,
                      //     typeOfCampaignError: true,
                      //   });
                      // } else {
                      //   setCampaignValuesError({
                      //     ...campaignValuesError,
                      //     typeOfCampaignError: false,
                      //   });
                      // }
                    }}
                    onBlur={() => {
                      if (!selectedroom) {
                        setJoinRoomFlag(false);
                      } else {
                        setJoinRoomFlag(true);
                      }
                    }}
                  />
                </div>
                {/* {createRoomFlag && createRoomGoalFlag ? (
                  // <Link
                  //   onClick={(e) => (!name || !room ? e.preventDefault() : null)}
                  //   to={`/chat/${name}/${room}`}
                  // >
                  <button
                    className={"button mt-20"}
                    type="submit"
                    style={{ fontSize: "14px" }}
                    onClick={() => handleRoomCreate()}
                  >
                    Create Room
                  </button>
                ) : (
                  // </Link>
                  <button
                    className={"button mt-20"}
                    disabled
                    style={{ backgroundColor: "gray", fontSize: "14px" }}
                  >
                    Create Room
                  </button>
                )}{" "} */}
                {joinRoomFlag ? (
                  <Link
                    onClick={(e) =>
                      !name || !selectedroom["value"]
                        ? e.preventDefault()
                        : null
                    }
                    style={{width:"100%"}}
                    to={`/chat/${name}/${selectedroom["value"]}`}
                  >
                    <button
                      className={"button mt-20"}
                      type="submit"
                      style={{ fontSize: "14px" }}
                    >
                      Join Room
                    </button>
                  </Link>
                ) : (
                  <button
                    className={"button mt-20"}
                    type="submit"
                    disabled
                    style={{ backgroundColor: "gray", fontSize: "14px" }}
                  >
                    Join A Room
                  </button>
                )}{" "}
                {/* <Link
                  onClick={(e) => (!name || !room ? e.preventDefault() : null)}
                  to={`/chat/${name}/${room}`}
                > */}
                {/* {JSON.stringify(availableRoomDetails)}
                
                {" ============ "} */}
                {/* {JSON.stringify(selectedroom["value"])} */}
                {/* {"+++++++++++++++++"} */}
                {joinRoomFlag &&
                  availableRoomDetails?.map((item, index) =>
                    // <div>
                    // {
                    item?.roomid === selectedroom["value"] &&
                    item?.phoneno === profileResponse?.phoneno ? (
                      <button
                        className={"button mt-20"}
                        type="submit"
                        onClick={() => setDisplayConfirmationModal(true)}
                        // style={{ fontSize: "14px", textAlign: "center" }}
                        style={{ fontSize: "14px" }}
                      >
                        Delete Room
                      </button>
                    ) : (
                      ""
                    )
                    // <button
                    //   className={"button mt-20"}
                    //   type="submit"
                    //   disabled
                    //   style={{ backgroundColor: "gray", fontSize: "14px" }}
                    // >
                    //   Delete Room
                    // </button>
                    // }

                    // </div>
                  )}
                  </div>
                {/* and{JSON.stringify(profileResponse?.phoneno)} */}
                {/* {joinRoomFlag &&
                availableRoomDetails?.map((item, index) => 
                    (item?.roomid === selectedroom["value"] && item?.phoneno === profileResponse?.phoneno))? (
                
                  <button
                    className={"button mt-20"}
                    type="submit"
                    style={{ fontSize: "14px" }}
                  >
                    Delete Room
                  </button>
                ) : (
                  <button
                    className={"button mt-20"}
                    type="submit"
                    disabled
                    style={{ backgroundColor: "gray", fontSize: "14px" }}
                  >
                    Delete Room
                  </button>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={handleDeleteRoom} hideModal={hideConfirmationModal} message={"Are you sure you want to delete this room?"}  />
      <ConfirmationDialog showModal={displayInformationModal} hideModal={hideInformationModal} message={"Room is created"}  />
    </div>
  );
}
