/** @format */

const GET_STATES_UT_FETCHING_DATA = "GET_STATES_UT_FETCHING_DATA";
const GET_STATES_UT_FETCHING_DATA_FAILURE =
  "GET_STATES_UT_FETCHING_DATA_FAILURE";
const GET_STATES_UT_FETCHING_DATA_RESET = "GET_STATES_UT_FETCHING_DATA_RESET";
const GET_STATES_UT_FETCHING_DATA_SUCCESS =
  "GET_STATES_UT_FETCHING_DATA_SUCCESS";
const GET_STATES_UT_FETCHING_DATA_ATTEMPT =
  "GET_STATES_UT_FETCHING_DATA_ATTEMPT";
const getStatesUT = (payload) => ({
  type: GET_STATES_UT_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

export {
  GET_STATES_UT_FETCHING_DATA,
  GET_STATES_UT_FETCHING_DATA_FAILURE,
  GET_STATES_UT_FETCHING_DATA_RESET,
  GET_STATES_UT_FETCHING_DATA_SUCCESS,
  GET_STATES_UT_FETCHING_DATA_ATTEMPT,
  getStatesUT,
};
