import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import { states } from "../utils/constants";
import { isEmpty } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { Table } from "@material-ui/core";
import _ from "lodash";
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement,
  zoomPlugin,
);

const useStyles = makeStyles((theme) => ({

  pointBox: {
    // top: "22px",
    // left: "200px",
    // top: "22%",
    minWidth: "65px",
    width: "160px",
    minHeight: "150px",
    // height: "150px",
    background: "#1A524D 0% 0% no-repeat padding-box",
    borderRadius: "20px",
    opacity: "90%",
    color: "#FFFFFF",
    fontSize: 15,
    fontWeight: "bold",
  },

  mouseHover: {
    "&:hover": {
      textDecoration: "underline",
      color: "blue",
    }

  },
  thead: {
    borderBottom: "1px solid #d3d3d3",
    borderTop: "1px solid #d3d3d3",
    height: 60,
  },
  theadText: {
    fontWeight: "700",
    textAlign: "center",
    fontSize: 16,
    minWidth: 100,
  },
  tbodyText: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width:"200px",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // wordWrap: "breakWord",
    // overflow: "scroll",
  },
  table: {
    width: "100%",
    // marginBottom: "10px",
    borderRadius: "10px",
    borderStyle: "solid",
    borderWidth: "1.5px",
    // tableLayout: "fixed",
  },
  tableRow: {
    borderBottom: "1px solid #d3d3d3",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  chartWrapperContainer: {
    width: "45%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  chartWrapper: {
    padding: 3,

    backgroundColor: "#E9E9E9",
    borderStyle: "solid",
    borderWidth: "1.5px",
    margin: 4,
    borderRadius: 4,
    // display: "flex",
    // display: "inline-flex",
    // display: "block"
  },
}));

export const options = {
  responsive: true,
  innerHeight: 100,
  outerHeight: 10,
  // minBarLength: 2,
  maintainAspectRatio: "false",
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        display: false
      }
    },
    x: {
      ticks: {
        beginAtZero: true,
      },
      grid: {
        display: false
      }
    }
  }
};
export const SOVolOrgChartComponentoptions = {
  responsive: false,
  innerHeight: 100,
  outerHeight: 10,
  // minBarLength: 2,
  maintainAspectRatio: "false",
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "No. of Volunteers in org",
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        display: false
      }
    },
    x: {
      ticks: {
        beginAtZero: true,
      },
      grid: {
        display: false
      }
    }
  }
};

export const SOVolOverTimeChartComponentoptions = {
  responsive: true,
  innerHeight: 100,
  outerHeight: 10,
  // minBarLength: 2,
  maintainAspectRatio: "false",
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "No. of Volunteers from the Organization on platform at given point in time",
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        display: false
      }
    },
    x: {
      ticks: {
        beginAtZero: true,
      },
      grid: {
        display: false
      }
    }
  }
};

export const SOVolOverTimeChartComponentoptions1 = {
  responsive: true,
  innerHeight: 100,
  outerHeight: 10,
  // minBarLength: 2,
  maintainAspectRatio: "false",
  color:"white",
  plugins: {
    legend: {
      position: "top",
      color:"white"
    },
    title: {
      display: true,
      text: "No. of Volunteers from the Organization on platform at given point in time",
      color:"white"
    },
  },
  scales: {
    y: {
      ticks: {
      beginAtZero: true,
      color:"white",
      },
      grid: {
        display: false,
        color:"white"
      }
    },
    x: {
      ticks: {
        beginAtZero: true,
        color:"white"
      },
      grid: {
        display: false,
        color:"white"
      }
    }
  }
};

export const SOVolEmpInOrgChartComponentoptions1 = {
  // responsive: true,
  // innerHeight: 80,
  // outerHeight: 10,
  // minBarLength: 2,
  responsive: false,
  maintainAspectRatio: false,
  color:"white",
  plugins: {
    legend: {
      position: "top",
      color:"white",
      // padding: 50,
    },
    title: {
      display: true,
      text: "No. of Employees and Volunteers",
      color:"white",
    },
  },
  // scales: {
  //   y: {
  //       beginAtZero: true,
  //       grid: {
  //         display: false
  //       }
  //   },
  //   x: {
  //     ticks: {
  //       beginAtZero: true,
  //     },      
  //     grid: {
  //       display: false
  //     }
  //   }
  // }
};

export const SOVolInCampInOrgChartComponentoptions = {
  responsive: true,
  innerHeight: 100,
  outerHeight: 10,
  color:"white",
  // minBarLength: 2,
  maintainAspectRatio: "false",
  plugins: {
    legend: {
      position: "top",
      color:"white",
    },
    title: {
      display: true,
      text: "Expected & Actual Volunteers in campaigns",
      color:"white",
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      color:"white",
      grid: {
        display: false,
        color:"white",
      }
    },
    x: {
      ticks: {
        beginAtZero: true,
        color:"white",
      },
      grid: {
        display: false,
        color:"white",
      }
    }
  }
};

export const SOVolMaleVsFemaleOrgChartComponentoptions1 = {
  responsive: true,
  innerHeight: 100,
  outerHeight: 10,
  // minBarLength: 2,
  maintainAspectRatio: "false",
  color:"white",
  plugins: {
    legend: {
      position: "top",
      color:"white",
    },
    title: {
      display: true,
      text: "Male & Female Volunteers across all campaigns",
      color:"white",
    },
    zoom: {
      pan: {
          enabled: true,
          mode: 'x',
          color:"white",
      },
      zoom: {
          pinch: {
              enabled: true ,      // Enable pinch zooming
              color:"white",
          },
          wheel: {
              enabled: true,       // Enable wheel zooming
          },
          mode: 'x',
          color:"white",
      }
    }
  },
  scales: {
    y: {
      ticks: {
      beginAtZero: true,
      color:"white",
      },
      grid: {
        display: false,
        color:"white",
      }
    },
    x: {
      ticks: {
        beginAtZero: true,
        maxRotation: 45,
        minRotation: 45,
        color:"white",
      },
      grid: {
        display: false,
        color:"white",
      },
      beforeUpdate(axis) {
        const labels = axis.chart.data.labels;
        for (let i=0; i<labels.length; i++) {
          const lbl = labels[i];
          if (typeof lbl === 'string' && lbl.length > 15) {
            labels[i] = lbl.substring(0, 15) + ".."; // cutting
          }
        }
      }
    }
  }
};

export const SOVolInCampOverTimeChartComponentoptions = {
  responsive: true,
  innerHeight: 100,
  outerHeight: 10,
  // minBarLength: 2,
  maintainAspectRatio: "false",
  // maintainAspectRatio: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Volunteer growth in Campaign - Tree Planting",
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        display: false
      }
    },
    x: {
      ticks: {
        beginAtZero: true,
      },
      grid: {
        display: false
      }
    }
  }
};




export const SOVolInCampInOrgChartComponentoptions1 = {
  responsive: true,
  innerHeight: 100,
  outerHeight: 10,
  // minBarLength: 2,
  color:"white",
  maintainAspectRatio: "false",
  plugins: {
    legend: {
      position: "top",
      color:"white",
    },
    title: {
      display: true,
      text: "Expected & Actual Volunteers in campaigns",
      color:"white",
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      color:"white",
      grid: {
        display: false,
        color:"white",
      }
    },
    x: {
      ticks: {
        beginAtZero: true,
        color:"white",
      },
      grid: {
        display: false,
        color:"white",
      }
    }
  }
};

export const SOVolHoursSpentCampChartComponentoptions1 = {
  responsive: true,
  innerHeight: 100,
  outerHeight: 10,
  color:"white",
  // clip: {left: 5, top: false, right: -2, bottom: 0},
  // minBarLength: 2,
  maintainAspectRatio: "false",
  plugins: {
    legend: {
      position: "top",
      color:"white",
    },
    title: {
      display: true,
      text: "Hours contributed by Volunteers across campaigns",
      color:"white",
    },
  },
  scales: {
    y: {
      ticks: {
      beginAtZero: true,
      color:"white",
      },
      grid: {
        display: false,
        color:"white",
      }
    },
    x: {
      ticks: {
        beginAtZero: true,
        maxRotation: 45,
        minRotation: 45,
        color:"white",
      },
      grid: {
        display: false,
        color:"white",
      },
      beforeUpdate(axis) {
        const labels = axis.chart.data.labels;
        for (let i=0; i<labels.length; i++) {
          const lbl = labels[i];
          if (typeof lbl === 'string' && lbl.length > 15) {
            labels[i] = lbl.substring(0, 15) + ".."; // cutting
          }
        }
      }
    }
  }
};

export const SOVolImpactOrgChartComponentoptions = {
  responsive: true,
  innerHeight: 100,
  outerHeight: 10,
  // minBarLength: 2,
  maintainAspectRatio: "false",
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Volunteer Impact on campaigns",
    },
  },
  scales: {
    y: [
      {
        // beginAtZero: true,
        grid: {
          display: false,

        },
        gridLines: {
          display: false,
        },
      },
    ],
    x: {
      ticks: {
        beginAtZero: true,
      },
      grid: {
        display: false
      }
    }
  }
};



export const SOVolMaleVsFemaleOrgChartComponentoptions = {
  responsive: true,
  innerHeight: 100,
  outerHeight: 10,
  // minBarLength: 2,
  maintainAspectRatio: "false",
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Male & Female Volunteers across all campaigns",
    },
    zoom: {
      pan: {
          enabled: true,
          mode: 'x'
      },
      zoom: {
          pinch: {
              enabled: true       // Enable pinch zooming
          },
          wheel: {
              enabled: true       // Enable wheel zooming
          },
          mode: 'x',
      }
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        display: false
      }
    },
    x: {
      ticks: {
        beginAtZero: true,
        maxRotation: 45,
        minRotation: 45,
      },
      grid: {
        display: false
      },
      beforeUpdate(axis) {
        const labels = axis.chart.data.labels;
        for (let i=0; i<labels.length; i++) {
          const lbl = labels[i];
          if (typeof lbl === 'string' && lbl.length > 15) {
            labels[i] = lbl.substring(0, 15) + ".."; // cutting
          }
        }
      }
    }
  }
};

export const SOVolHoursSpentCampChartComponentoptions = {
  responsive: true,
  innerHeight: 100,
  outerHeight: 10,
  
  // clip: {left: 5, top: false, right: -2, bottom: 0},
  // minBarLength: 2,
  maintainAspectRatio: "false",
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Hours contributed by Volunteers across campaigns",
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        display: false
      }
    },
    x: {
      ticks: {
        beginAtZero: true,
        maxRotation: 45,
        minRotation: 45,
      },
      grid: {
        display: false
      },
      beforeUpdate(axis) {
        const labels = axis.chart.data.labels;
        for (let i=0; i<labels.length; i++) {
          const lbl = labels[i];
          if (typeof lbl === 'string' && lbl.length > 15) {
            labels[i] = lbl.substring(0, 15) + ".."; // cutting
          }
        }
      }
    }
  }
};




export const SOVolEmpInOrgChartComponentoptions = {
  // responsive: true,
  // innerHeight: 80,
  // outerHeight: 10,
  // minBarLength: 2,
  responsive: false,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
      // padding: 50,
    },
    title: {
      display: true,
      text: "No. of Employees and Volunteers",
    },
  },
  // scales: {
  //   y: {
  //       beginAtZero: true,
  //       grid: {
  //         display: false
  //       }
  //   },
  //   x: {
  //     ticks: {
  //       beginAtZero: true,
  //     },      
  //     grid: {
  //       display: false
  //     }
  //   }
  // }
};





export const doughnutoptions = {
  responsive: true,
  innerHeight: 100,
  outerHeight: 10,
  // minBarLength: 2,
  maintainAspectRatio: "false",
  // plugins: {

  //   plugin

  // },

};

const labels = states.map((item) => item.name);
// const legendMargin = {
//   id: "legendMargin",
//   beforeInit: function (chart) {
//     const fitValue = chart.legend.fit;
//     chart.legend.fit = function fit() {
//       fitValue.bind(chart.legend)();
//       return (this.height += 40);
//     };
//   }
// };
// const plugin = {
//   beforeInit(chart) {
//     console.log("be");
//     // reference of original fit function
//     const originalFit = chart.legend.fit;

//     // override the fit function
//     chart.legend.fit = function fit() {
//       // call original function and bind scope in order to use `this` correctly inside it
//       originalFit.bind(chart.legend)();
//       // increase the width to add more space
//       this.width -= 20;
//     };
//   }
// };
export const data = {
  labels,
  datasets: [
    {
      label: "Volunteer",
      data: labels.map(() => Math.random() * 1000),
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
  ],
};

export const ChartComponent = ({ response }) => {
  return (

    
    <Bar
      options={options}
      data={{
        labels: !isEmpty(response) && response?.map((item) => item.state),
        datasets: [
          {
            label: "Volunteer",
            data:
              !isEmpty(response) && response?.map((item, index) => item.count),
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      }}
    />
  );
};

export const ActivityChartComponent = ({ response }) => {
  return (
    <Bar
      options={options}
      data={{
        labels: !isEmpty(response) && response?.map((item) => item.Activity),
        datasets: [
          {
            label: "Activity",
            data:
              !isEmpty(response) && response?.map((item, index) => item.count),
            backgroundColor: "rgba(255, 99, 132, 0.5)",

          },
        ],
      }}
    />
  );
};

export const SOVolOrgChartComponent = ({ response }) => {
  // console.log(response)
  return (
    <Bar
      options={SOVolOrgChartComponentoptions}
      data={{
        labels: !isEmpty(response) && response?.map((item) => item.orgname),
        datasets: [
          {
            label: "Volunteer",
            data:
              !isEmpty(response) && response?.map((item, index) => item.volcount),
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            minBarLength: 2,
            borderColor: "red",
            borderWidth: 1,

          },
        ],
      }}
    />
  );
};

export const SOVolOrgDivComponent = ({ response }) => {
  // console.log(response)
  const classes = useStyles();
  return (
    <div style={{
      // marginRight: "35px", 
      // backgroundColor: "rgba(235, 149, 50, 1)", 
      fontSize: 11,
      padding: "0px 0px 0px 0px"
    }}>
      {/* <span>Total no. of volunteers from {!isEmpty(response) && response?.map((item) => item.orgname)} on platform</span> */}
      <span style={{ padding: "0px 0px 0px 16px", marginBottom: "10px",display:"block", width: "100%",textAlign:"center" }}>Total no. of volunteers on platform</span>
      <div className={classes.pointBox}
        style={{
          marginRight: "35px",
          backgroundColor: "rgba(235, 149, 50, 1)",
          fontSize: 72,
          padding: "20px 10px 20px 10px",
          margin: "0 auto",marginRight: 10, marginBottom: 10, textAlign:"center"
        }}>

        {!isEmpty(response) && response?.map((item, index) => item.volcount)}


      </div>
    </div>
    // <Bar
    //   options={SOVolOrgChartComponentoptions}
    //   data={{
    //     labels: !isEmpty(response) && response?.map((item) => item.orgname),
    //     datasets: [
    //       {
    //         label: "Volunteer",
    //         data:
    //           !isEmpty(response) && response?.map((item, index) => item.volcount),
    //         backgroundColor: "rgba(255, 99, 132, 0.5)",
    //         minBarLength: 2,
    //         borderColor:"red",
    //         borderWidth: 1,

    //       },
    //     ],
    //   }}
    // />
  );
};


export const SOVolOverTimeChartComponent = ({ response }) => {
  const classes = useStyles();
  return (
    // <div className={classes.chartWrapper}>
    <div className="chartWrapper1">
    <Line
      options={SOVolOverTimeChartComponentoptions}
      data={{
        labels: !isEmpty(response) && response?.map((item) => item.date),
        datasets: [
          {
            label: "Volunteer",
            data:
              !isEmpty(response) && response?.map((item, index) => item.count),
            // backgroundColor: "rgba(255, 99, 132, 0.5)",
            backgroundColor: "blue",
            minBarLength: 2,
            borderColor: "blue",
            borderWidth: 1,

          },
        ],
      }}
    />
    </div>
  );
};

export const SOVolOverTimeChartComponent1 = ({ response }) => {
  const classes = useStyles();
  return (
    // <div className={classes.chartWrapper}>
    <div className="chartWrapper1">
    <Line
      options={SOVolOverTimeChartComponentoptions1}
      data={{
        labels: !isEmpty(response) && response?.map((item) => item.date),
        color:"white",
        datasets: [
          {
            label: "Volunteer",
            data:
              !isEmpty(response) && response?.map((item, index) => item.count),
            // backgroundColor: "rgba(255, 99, 132, 0.5)",
            backgroundColor: "white",
            minBarLength: 2,
            borderColor: "white",
            color:"white",
            borderWidth: 1,

          },
        ],
      }}
    />
    </div>
  );
};

export const SOVolInCampInOrgChartComponent = ({ response }) => {
  const classes = useStyles();
  return (
    // <div className={classes.chartWrapper}>
      <div className="chartWrapper1">
    <Bar
      options={SOVolInCampInOrgChartComponentoptions}
      data={{
        labels: !isEmpty(response) && response?.map((item) => item.campaignname),
        datasets: [
          {
            label: "Expected",
            data:
              !isEmpty(response) && response?.map((item, index) => item.expectedvol),
            backgroundColor: "rgba(60,120,80,0.9)",
            // backgroundColor: "blue",
            minBarLength: 2,
            borderColor: "blue",
            borderWidth: 1,

          },
          {
            label: "Actual",
            data:
              !isEmpty(response) && response?.map((item, index) => item.actualvol),
            backgroundColor: "rgba(60,120,88,0.1)",
            // backgroundColor: "blue",
            minBarLength: 2,
            borderColor: "blue",
            borderWidth: 1,

          },
        ],
      }}
    />
    </div>
  );
};

export const SOVolEmpInOrgChartComponent = ({ response }) => {
  const classes = useStyles();
  return (
    // <div>
    //   HHHHHHHHHHHHHHH
    // <div className={classes.chartWrapper}>
    <div className="chartWrapper1">
    <Doughnut height={250} style = {{margin: "0 auto"}}
      // options={doughnutoptions}
      options={SOVolEmpInOrgChartComponentoptions}
      data={{
        labels: [
          !isEmpty(response) && response?.map((item) => "Volcount"),
          !isEmpty(response) && response?.map((item) => "EmpCount"),
        ],

        datasets: [
          {
            label: 'My First Dataset',
            data: [
              !isEmpty(response) && response?.map((item, index) => item.volcount),
              !isEmpty(response) && response?.map((item, index) => item.empcount),
            ],
            backgroundColor: [
              "rgba(128,0,128,0.9)",
              "rgba(128,0,128,0.6)",
            ],

            // backgroundColor: "blue",
            // minBarLength: 2,
            // borderColor:"blue",
            // borderWidth: 1,
            //radius: 100,
            // cutout: "50%",
            // spacing: 20,

          },
        ],
      }}
    />
    </div>
  );
};


export const SOVolInCampInOrgChartComponent1 = ({ response }) => {
  const classes = useStyles();
  return (
    // <div className={classes.chartWrapper}>
      <div className="chartWrapper1">
    <Bar
      options={SOVolInCampInOrgChartComponentoptions1}
      data={{
        labels: !isEmpty(response) && response?.map((item) => item.campaignname),
        color:"white",
        datasets: [
          {
            label: "Expected",
            data:
              !isEmpty(response) && response?.map((item, index) => item.expectedvol),
            backgroundColor: "rgba(60,120,80,0.9)",
            // backgroundColor: "blue",
            minBarLength: 2,
            borderColor: "white",
            borderWidth: 1,
            color:"white",

          },
          {
            label: "Actual",
            data:
              !isEmpty(response) && response?.map((item, index) => item.actualvol),
            backgroundColor: "rgba(60,120,88,0.1)",
            // backgroundColor: "blue",
            minBarLength: 2,
            borderColor: "white",
            borderWidth: 1,
            color:"white",

          },
        ],
      }}
    />
    </div>
  );
};

export const SOVolEmpInOrgChartComponent1 = ({ response }) => {
  const classes = useStyles();
  return (
    // <div>
    //   HHHHHHHHHHHHHHH
    // <div className={classes.chartWrapper}>
    <div className="chartWrapper1">
    <Doughnut height={250} style = {{margin: "0 auto"}}
      // options={doughnutoptions}
      options={SOVolEmpInOrgChartComponentoptions1}
      data={{
        labels: [
          !isEmpty(response) && response?.map((item) => "Volcount"),
          !isEmpty(response) && response?.map((item) => "EmpCount"),
        ],
        color:"white",

        datasets: [
          {
            label: 'My First Dataset',
            data: [
              !isEmpty(response) && response?.map((item, index) => item.volcount),
              !isEmpty(response) && response?.map((item, index) => item.empcount),
            ],
            color:"white",
            backgroundColor: [
              "rgba(128,0,128,0.9)",
              "rgba(128,0,128,0.6)",
            ],

            // backgroundColor: "blue",
            // minBarLength: 2,
            // borderColor:"blue",
            // borderWidth: 1,
            //radius: 100,
            // cutout: "50%",
            // spacing: 20,

          },
        ],
      }}
    />
    </div>
  );
};


export const SOVolMaleVsFemaleOrgChartComponent1 = ({ response }) => {
  const classes = useStyles();
  return (
    // <div>
    //   HHHHHHHHHHHHHHH
    // <div className={classes.chartWrapper}>
    <div className="chartWrapper1">
    <Bar
      options={SOVolMaleVsFemaleOrgChartComponentoptions1}
      data={{
        labels: !isEmpty(response) && response?.map((item) => item.campaignname),
        datasets: [
          {
            label: "Female",
            data:
              !isEmpty(response) && response?.map((item, index) => item.female),
            backgroundColor: "rgba(0,128,128,0.9)",
            // backgroundColor: "blue",
            minBarLength: 2,
            borderColor: "blue",
            borderWidth: 1,
            color:"white",
          },
          {
            label: "Male",
            data:
              !isEmpty(response) && response?.map((item, index) => item.male),
            backgroundColor: "rgba(0,128,128,0.1)",
            // backgroundColor: "blue",
            minBarLength: 2,
            borderColor: "blue",
            borderWidth: 1,
            color:"white",
          },
        ],
      }}
    />

    </div>
  );
};

export const SOVolMaleVsFemaleOrgChartComponent = ({ response }) => {
  const classes = useStyles();
  return (
    // <div>
    //   HHHHHHHHHHHHHHH
    // <div className={classes.chartWrapper}>
    <div className="chartWrapper1">
    <Bar
      options={SOVolMaleVsFemaleOrgChartComponentoptions}
      data={{
        labels: !isEmpty(response) && response?.map((item) => item.campaignname),
        datasets: [
          {
            label: "Female",
            data:
              !isEmpty(response) && response?.map((item, index) => item.female),
            backgroundColor: "rgba(0,128,128,0.9)",
            // backgroundColor: "blue",
            minBarLength: 2,
            borderColor: "blue",
            borderWidth: 1,

          },
          {
            label: "Male",
            data:
              !isEmpty(response) && response?.map((item, index) => item.male),
            backgroundColor: "rgba(0,128,128,0.1)",
            // backgroundColor: "blue",
            minBarLength: 2,
            borderColor: "blue",
            borderWidth: 1,

          },
        ],
      }}
    />

    </div>
  );
};

export const SOVolImpactOrgChartComponent = ({ response }) => {
  const classes = useStyles();
  return (
    // <div>
    //   HHHHHHHHHHHHHHH
    // <div className={classes.chartWrapper}>
    <div className="chartWrapper1">
    <Bar
      options={SOVolImpactOrgChartComponentoptions}
      data={{
        labels: !isEmpty(response) && response?.map((item) => item.campaignname),
        datasets: [
          {
            // label: "Female",
            // label: !isEmpty(response) && response?.map((item) => item.impactvalue),
            label: "Impact",
            data:
              !isEmpty(response) && response?.map((item, index) => item.impactvalue),
            backgroundColor: "rgba(255,99,77,0.9)",
            // backgroundColor: "blue",
            minBarLength: 2,
            borderColor: "blue",
            borderWidth: 1,

          },
          // {
          //   label: "Male",
          //   data:
          //     !isEmpty(response) && response?.map((item, index) => item.male),
          //   backgroundColor: "rgba(0,128,128,0.6)",
          //   // backgroundColor: "blue",
          //   minBarLength: 2,
          //   borderColor:"blue",
          //   borderWidth: 1,

          // },
        ],
      }}
    />



    </div>
  );
};

export const SOVolImpactTableComponent = ({ response }) => {
  // console.log(response)
  const classes = useStyles();
  return (
   
    <div class="tableGraph">
    <span style={{ padding: "0px 0px 0px 16px", display: "block",width: "100%",textAlign:"center",marginBottom: "15px", fontSize: 12 }}>Impact of Volunteering</span>
    {/* <Table className={classes.table} aria-label="customized table" style={{ marginBottom: 500 }}> */}
    <Table className={classes.table} aria-label="customized table">
      <thead>
        <tr className={classes.thead}>
          <td className={classes.theadText}>
            <span> Campaign Name </span>
          </td>
          <td className={classes.theadText}>
            <span> Volunteer Impact </span>
          </td>
        </tr>
      </thead>
      <tbody>
        
        {!isEmpty(response) && response?.map((item) => (
          <tr className={classes.tableRow}>
            <td className={classes.tbodyText}>
              <span> {item?.campaignname}</span>
            </td>
            
            <td className={classes.tbodyText}>
              <span> {item?.impactvalue} {" "} {item?.impacttitle}</span>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    </div>

  );
};


export const SOVolImpactDivComponent = ({ response }) => {
  // console.log(response)
  const classes = useStyles();
  return (
    // <div className={classes.chartWrapper}>
    <>
    <span style={{ padding: "0px 0px 0px 16px", display: "block",width: "100%",textAlign:"center",marginBottom: "15px", fontSize: 12 }}>Impact of Volunteering</span>
    {!isEmpty(response) && response?.map((item) => (
    <div style={{
      // marginRight: "35px", 
      // backgroundColor: "rgba(235, 149, 50, 1)", 
      fontSize: 11,
      padding: "0px 10px 0px 0px", float: "left", marginBottom: "10px"
    }}>
      {/* <span>Total no. of volunteers from {!isEmpty(response) && response?.map((item) => item.orgname)} on platform</span> */}
     
      <div className={classes.pointBox} style={{padding:10}}
        // style={{
        //   marginRight: "35px",
        //   backgroundColor: "rgba(235, 149, 50, 1)",
        //   fontSize: 72,
        //   padding: "50px 0px 0px 56px"
        // }}
        >

        {/* <span style={{
          // marginRight: "35px",
          // backgroundColor: "rgba(235, 149, 50, 1)",
          fontSize: 24,
          // padding: "50px 0px 0px 56px"
        }}> */}
        <div style={{fontSize: 14, textAlign: "center", textTransform:"uppercase"}} >
          {item?.campaignname} 
          </div>
        {/* </span> */}
        {" "}

        <div style={{fontSize: 14, textAlign: "center", padding: "10px 0px 0px 0px", color: "silver"}} >
          {item?.impacttitle} 
        {/* </span> */}
        </div> 

        <span style={{
          // marginRight: "35px",
          // backgroundColor: "rgba(235, 149, 50, 1)",
          fontSize: 36,
          padding: "22px 0px 0px 0px",
          textAlign: "center",
          display: "block",
          width: "100%",
          // color: "silver",
        }}>
        {/* {!isEmpty(response) && response?.map((item, index) => item.impactvalue)} */}
        {item?.impactvalue} 
        </span>
        {" "} 
        
        {/* <span style={{
          
          fontSize: 16,
          // padding: "50px 0px 0px 56px"
        }}> */}
        


      </div>
    </div>
    ))}
    </>
    // </div>
  );
};


export const SOVolHoursSpentCampChartComponent = ({ response }) => {
  const classes = useStyles();
  return (
    // <div>
    //   HHHHHHHHHHHHHHH
    // <div className={classes.chartWrapper}>
    <div className="chartWrapper1">
    <Bar
      options={SOVolHoursSpentCampChartComponentoptions}
      data={{
        labels: !isEmpty(response) && response?.map((item) => item.campaignname),
        datasets: [
          {
            // label: "Female",
            // label: !isEmpty(response) && response?.map((item) => item.impactvalue),
            label: "Hours Contributed",
            data:
              !isEmpty(response) && response?.map((item, index) => item.hoursspent),
            backgroundColor: "rgba(255,99,77,0.9)",
            // backgroundColor: "blue",
            minBarLength: 2,
            borderColor: "blue",
            borderWidth: 1,

          },
          // {
          //   label: "Male",
          //   data:
          //     !isEmpty(response) && response?.map((item, index) => item.male),
          //   backgroundColor: "rgba(0,128,128,0.6)",
          //   // backgroundColor: "blue",
          //   minBarLength: 2,
          //   borderColor:"blue",
          //   borderWidth: 1,

          // },
        ],
      }}
    />



    </div>
  );
};

export const SOVolHoursSpentCampChartComponent1 = ({ response }) => {
  const classes = useStyles();
  return (
    // <div>
    //   HHHHHHHHHHHHHHH
    // <div className={classes.chartWrapper}>
    <div className="chartWrapper1">
    <Bar
      options={SOVolHoursSpentCampChartComponentoptions1}
      data={{
        labels: !isEmpty(response) && response?.map((item) => item.campaignname),
        color:"white",
        datasets: [
          {
            // label: "Female",
            // label: !isEmpty(response) && response?.map((item) => item.impactvalue),
            label: "Hours Contributed",
            color:"white",
            data:
              !isEmpty(response) && response?.map((item, index) => item.hoursspent),
            backgroundColor: "rgba(255,99,77,0.9)",
            // backgroundColor: "blue",
            minBarLength: 2,
            borderColor: "blue",
            borderWidth: 1,
            color:"white",
          },
          // {
          //   label: "Male",
          //   data:
          //     !isEmpty(response) && response?.map((item, index) => item.male),
          //   backgroundColor: "rgba(0,128,128,0.6)",
          //   // backgroundColor: "blue",
          //   minBarLength: 2,
          //   borderColor:"blue",
          //   borderWidth: 1,

          // },
        ],
      }}
    />



    </div>
  );
};

export const SOVolInCampOverTimeChartComponent = ({ response }) => {
  const classes = useStyles();
  return (
    // <div className={classes.chartWrapper}>
    <div className="chartWrapper1">
    
    <Line
      options={SOVolInCampOverTimeChartComponentoptions}
      data={{
        labels: !isEmpty(response) && response?.map((item) => item.date),
        datasets: [
          {
            label: "Expected",
            data:
              !isEmpty(response) && response?.map((item, index) => item.targetcount),
            // backgroundColor: "rgba(255, 99, 132, 0.5)",
            backgroundColor: "orange",
            minBarLength: 2,
            borderColor: "orange",
            borderWidth: 1,

          },
          {
              label: "Actual",
              data:
                !isEmpty(response) && response?.map((item, index) => item.count),
              // backgroundColor: "rgba(0,128,128,0.6)",
              backgroundColor: "green",
              minBarLength: 2,
              borderColor:"green",
              borderWidth: 1,
  
            },
        ],
      }}
    />
    </div>
  );
};


export const LineChartComponent = () => {
  return <Line options={options} data={data} />;
};
