import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { Card, makeStyles } from "@material-ui/core";
import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { city, states } from "../utils/constants";
import {
  ActivityChartComponent,
  ChartComponent,
  LineChartComponent,
} from "./chartComponent";
import { useDispatch, useSelector } from "react-redux";
import { getStatesUT } from "../redux/action/statesUTAction";
import { getCity, getCityReset } from "../redux/action/cityAction";
import {
  getReportUserInActivity,
  getReportUserInCity,
} from "../redux/action/getReport";
import MapComponent from "./mapComponent";
import { activity } from "../utils/dict";
import ResponsiveDrawer from "../components/Header";
import Sidebar from "../components/Sidebar";
import { CopyRight } from "../components/CopyRight";
import TopHeaderScreen from "./TopHeader1";
import { Link, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import { FixedFooterIcon } from "../components/FixedFooterIcon";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";

const useStyles = makeStyles((theme) => ({
  createScreenMainContent: {
    padding: "80px 50px 150px 0",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 150px 220px",
    },
  },
  box: {
    width: "250px",
    border: "5px solid black",
  },
  boxlarge: {
    height: "300px",
  },
  boxsmall: {
    height: "100px",
  },
}));



export default function DashboardScreen(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(null);
  const [cityValue, setCityValue] = React.useState(null);
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [allStates, setAllStates] = React.useState("All States");
  const { isSuccess, response: volunteerCountResponse } = useSelector(
    (state) => state?.getVolunteerCount
  );

  const { response: statesResponse } = useSelector((state) => state.states);
  const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
    (state) => state.cities
  );
  const {
    stateResponse,
    isStateSuccess,
    isStateError,
    isStateFetching,
    activiyResponse,
    isActivitySuccess,
    isActivityError,
    isActivityFetching,
  } = useSelector((state) => state.reportInfo);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setValue(event.target.value);
    dispatch(getCityReset());
  };
  const handleCityChange = (event) => {
    setCityValue(event.target.value);
  };
  const handleButtonChange = () => {
    setLoading(true);
    setShow(true);
    dispatch(getReportUserInActivity());
    dispatch(getReportUserInCity(value));
  };

  React.useEffect(() => {
    if (props?.response) {
      dispatch(getStatesUT(props?.response?.[0]?.nssrole));

      if (value) {
        dispatch(getCity(value));
      }
    }
  }, [props?.response, "All States"]);

  React.useEffect(() => {
    if (isActivitySuccess || isStateSuccess) {
      setLoading(false);
    }
  }, [isActivitySuccess, isStateSuccess]);

  const concatenatedData = statesResponse?.[0]?.concat(statesResponse?.[1]);
  const finalData = concatenatedData?.concat({ name: "All States" });

  const sortedData = finalData?.sort(function (a, b) {
    if (a.name < b.name) return -1;
    else if (a.name > b.name) return 1;
    return 0;
  });

  const downloadDocument = () => {
    
    const pdf = new jsPDF("l", "pt", "a3");
    // const pdf = new jsPDF({
    //   orientation: "landscape",
    //   unit: "pt",
    //   format: [4, 2]
    // });
    pdf.html(document.querySelector("#SOADashboardScreen1"), {
      callback: function(pdf){
        var pageCount = pdf.internal.getNumberOfPages();
        // pdf.deletePage(pageCount);
        
        pdf.save("cyaag.pdf");
      }
    })
    // const input = document.getElementById("SOADashboardScreen");
    // console.log("input = ", input);
    // html2canvas(input, {logging:true, willReadFrequently:true, useCORS:true}).then((canvas) => {
    //   const imgwidth= 100;
    //   const imgHeight= canvas.height* imgwidth /canvas.width;
    //   const imagedata = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF("p", "pt", "a4");
    //   console.log("sleeping for 2 secs...");
    //   sleep(2000);
    //   console.log("slept for 2 secs");
      
    //   // pdf.addImage(imagedata, "png", 0, 0, imgwidth, imgHeight);
    //   pdf.addImage(imagedata, "JPEG", "10", "45");
    //   pdf.save("cyaag.pdf");
    // });
  }

  {/* Total Vol for an org */}
  const sdk1 = new ChartsEmbedSDK ( {
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",

    // filter: {
    //   // "clientid": { "$eq": "c00001" }
    //   // "orgrole": { "$eq": "c00001" },
    //   // "organization": { "$eq": profileResponse?.organization }
    // }
        
  });
  
  const SOA_chart1 = sdk1.createChart({chartId: "64b122d5-5865-4120-8951-3da15427ac55", showAttribution: false});
  SOA_chart1.render(document.getElementById("SOA_chart1"));


  {/* Total Vol participated across org */}
  const sdk2 = new ChartsEmbedSDK ( {
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    
  });
  const SOA_chart2 = sdk2.createChart({chartId: "64b1396b-ce94-4ba9-842e-bcb8e8711516", showAttribution: false});
  
  SOA_chart2.render(document.getElementById("SOA_chart2"));
  
  
  {/* Location of CM's across globe */}
  const sdk3 = new ChartsEmbedSDK ( {
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    
  });
  const SOA_chart3 = sdk3.createChart({chartId: "64b13521-5865-454c-85e1-3da15431f5ef", showAttribution: false});
  SOA_chart3.render(document.getElementById("SOA_chart3"));
  
  {/* CM participation from across organization across campaigns */}
  const sdk4 = new ChartsEmbedSDK ( {
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    
  });
  const SOA_chart4 = sdk4.createChart({chartId: "64b13c5c-0ea1-4466-8f47-01f693dea340", showAttribution: false});
  SOA_chart4.render(document.getElementById("SOA_chart4"));

  
  {/* # of campaigns in a given SDG campaign category */}
  const sdk5 = new ChartsEmbedSDK ( {
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    
  });
  const SOA_chart5 = sdk5.createChart({chartId: "dd31fe1b-31ba-47b9-b219-0e949a1b339f", showAttribution: false});
  SOA_chart5.render(document.getElementById("SOA_chart5"));


  {/* Heat map - CM's on map across organizations */}
  const sdk6 = new ChartsEmbedSDK ( {
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    
  });
  const SOA_chart6 = sdk6.createChart({chartId: "518a323f-77a4-4313-ab2c-973d37a4a0d2", showAttribution: false});
  SOA_chart6.render(document.getElementById("SOA_chart6"));


  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      {/* <Sidebar {...props} /> */}
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
          <Button
                onClick={() => navigate(-1)}
                className={classes.backBtn}
            >
                <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Overall Dashboard View
            </div>
            <br></br>
            <br></br>
            {/* <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Dashboard</div>


            <div>
              <Button className="btn download-btn" variant="contained" onClick={downloadDocument}>Download</Button>
            </div> */}
            <header id="SOADashboardScreen1">
            <Box sx={{ minWidth: 200 }}>
            <div id="SOA_chart1" class="column chartMongo"> </div>
            <div id="SOA_chart2" class="column chartMongo"> </div>
            <div id="SOA_chart3" class="column chartMongo"> </div>
            <div id="SOA_chart4" class="column chartMongo"> </div>
            <div id="SOA_chart5" class="column chartMongo"> </div>
            <div id="SOA_chart6" class="column chartMongo"> </div>
            </Box>
            {/* <div class="column" style={{ marginLeft: 200, marginTop: 100, color: "white", height: "804px", overflowY: "hidden" }}>
              <iframe
                title= "User Data"
                src = "https://app.powerbi.com/view?r=eyJrIjoiMDhhOTA4YWQtZmY4NC00ZDY4LTkxM2QtNWQ4Njc2ZjI5ZDY4IiwidCI6ImFiMzZiNDYzLWUwMTgtNGFkNy1hMmY3LTc4OGEwZTY2NzkzOSJ9&filterPaneEnabled=false&navContentPaneEnabled=false"
                width="100%"
                height="100%"
                align="left"
                loading="eager"
                allowFullScreen="true"
                style={{ border: "1px dotted grey" }}
                frameborder="0">
                &nbsp
              </iframe>
              &nbsp */}
              {/* <iframe
                src = "https://dash.cyaag.com/d-solo/5VvYTySVk/volunteers-summary-dashboard-prod-cyaag?orgId=1&from=1682398248473&to=1682419848473&theme=light&panelId=8"
                width="300"
                height="300"
                align="justify"
                loading="eager"
                style={{ border: "1px dotted grey" }}
                frameborder="0">

              </iframe>
              &nbsp
              <iframe
                src = "https://dash.cyaag.com/d-solo/5VvYTySVk/volunteers-summary-dashboard-prod-cyaag?orgId=1&from=1682398287491&to=1682419887491&theme=light&panelId=7"
                width="300"
                height="300"
                align="justify"
                loading="eager"
                style={{ border: "1px dotted grey" }}
                frameborder="0">
              </iframe> */}
            {/* </div> */}

            {/* <div class="column" style={{ marginLeft: 300, marginTop: 50, color: "white" }}>

              <iframe
                src="https://dash.cyaag.com/d-solo/5VvYTySVk/volunteers-summary-dashboard-prod-cyaag?orgId=1&from=1682398312842&to=1682419912842&theme=light&panelId=9"
                width="800"
                height="380"
                align="justify"
                loading="eager"
                style={{ border: "2px solid grey" }}
                frameborder="0">

              </iframe>

            </div>

            <div class="column" style={{ marginLeft: 200, marginTop: 50, color: "white" }}>

              <iframe
                src="https://dash.cyaag.com/d-solo/5VvYTySVk/volunteers-summary-dashboard-prod-cyaag?orgId=1&from=1682398360608&to=1682419960608&theme=light&panelId=12"
                width="300"
                height="340"
                align="left"
                loading="eager"
                style={{ border: "1px dotted grey" }}
                frameborder="0">
              </iframe>
              &nbsp
              <iframe
                src = "https://dash.cyaag.com/d-solo/5VvYTySVk/volunteers-summary-dashboard-prod-cyaag?orgId=1&from=1682398389455&to=1682419989455&theme=light&panelId=6"
                width="300"
                height="340"
                align="justify"
                loading="eager"
                style={{ border: "1px dotted grey" }}
                frameborder="0">
              </iframe>

              &nbsp
              <iframe
                src = "https://dash.cyaag.com/d-solo/5VvYTySVk/volunteers-summary-dashboard-prod-cyaag?orgId=1&from=1682398429336&to=1682420029336&theme=light&panelId=11"
                width="300"
                height="340"
                align="justify"
                loading="eager"
                style={{ border: "1px dotted grey" }}
                frameborder="0">
              </iframe>
            </div> */}
            </header>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>

    // // <Box sx={{ minWidth: 200 }}>
    // //   <div
    // //     style={{
    // //       display: "flex",
    // //       justifyContent: "space-around",
    // //       alignItems: "center",
    // //       width: "50%",
    // //     }}
    // //   >
    // //     <div
    // //       style={{
    // //         display: "flex",
    // //         flexDirection: "column",
    // //       }}
    // //     >



    //       /* COMMENTED START - DO NOT UNCOMMENT*/

    //       /* <InputLabel id="demo-simple-select-label">Select State</InputLabel>
    //       <Select
    //         style={{ width: 200 }}
    //         labelId="demo-simple-select-label"
    //         id="demo-simple-select"
    //         value={value}
    //         onChange={handleChange}
    //       >
    //         {sortedData?.map((item, index) => (
    //           <MenuItem style={{ fontSize: 16 }} value={item.name}>
    //             {item.name}
    //           </MenuItem>
    //         ))}
    //       </Select> */

    //       /* COMMENTED END */



    //     // </div>
    //     // <div
    //     //   style={{
    //     //     display: "flex",
    //     //     flexDirection: "column",
    //     //     justifyContent: "space-between",
    //     //   }}
    //     // >



    //       /* COMMENTED START DO NOT UNCOMMENT */
    //       /* <InputLabel id="demo-simple-select-label">Select City</InputLabel>
    //       <Select
    //         style={{ width: 200 }}
    //         labelId="demo-simple-select-label"
    //         id="demo-simple-select"
    //         value={cityValue}
    //         disabled={!value}
    //         onChange={handleCityChange}
    //       >
    //         {citiesSuccess &&
    //           citiesResponse.map((item, index) => (
    //             <MenuItem style={{ fontSize: 16 }} value={item.name}>
    //               {item.name}
    //             </MenuItem>
    //           ))}
    //       </Select> */

    //       /* COMMENTED END */



    //     // </div>




    //       /* COMMENTED START  DO NOT UNCOMMENT*/}
    //     /* <div style={{ padding: "16px 0", bottom: 50, left: 20 }}>
    //       <Button
    //         variant="contained"
    //         // onClick={handleForm}
    //         className={classes.goalButton}
    //         //disabled={!value}
    //         onClick={handleButtonChange}
    //         // onClick={handleOnboardIndividual}
    //       >
    //         Submit
    //       </Button>
    //     </div> */

    //     /* COMMENTED END */



    //   // </div>




    //   /* COMMENTED START DO NOT UNCOMMENT */

    //   /* <div style={{ display: "flex" }}>
    //     <MapComponent />
    //     <div style={{ width: "500px" }}>
    //       <div>
    //         <span>Summary</span>
    //         <ul>
    //           <li>
    //             Total number of volunteers:
    //             {isSuccess ? volunteerCountResponse : null}
    //           </li>{" "}

    //         </ul>
    //       </div>
    //       <div>
    //         <span>Impact :</span>
    //         <ul>
    //           {activity.map((item) => (
    //             <li>{item.value}</li>
    //           ))}
    //         </ul>
    //       </div>
    //     </div>
    //   </div> */

    //   /* COMMENTED END */


    // //   {loading ? (
    // //     <CircularProgress color="inherit" size={24} />
    // //   ) : (
    // //     show && (
    // //       <div style={{ marginTop: 32 }}>
    // //         <ChartComponent response={stateResponse} />
    // //         <ActivityChartComponent response={activiyResponse} />
    // //       </div>
    // //     )
    // //   )}
    // // </Box>
  );
}
