/** @format */

const PURCHASE_FETCHING_DATA = 'PURCHASE_FETCHING_DATA';
const PURCHASE_FETCHING_DATA_FAILURE = 'PURCHASE_FETCHING_DATA_FAILURE';
const PURCHASE_FETCHING_DATA_RESET = 'PURCHASE_FETCHING_DATA_RESET';
const PURCHASE_FETCHING_DATA_SUCCESS = 'PURCHASE_FETCHING_DATA_SUCCESS';
const PURCHASE_FETCHING_DATA_ATTEMPT = 'PURCHASE_FETCHING_DATA_ATTEMPT';
const purchaseYaag = payload => ({
	type: PURCHASE_FETCHING_DATA_ATTEMPT,
	payload: payload,
});
const purchaseYaagReset = payload => ({
	type: PURCHASE_FETCHING_DATA_RESET,
	payload: payload,
});

export { PURCHASE_FETCHING_DATA, PURCHASE_FETCHING_DATA_FAILURE, PURCHASE_FETCHING_DATA_RESET, PURCHASE_FETCHING_DATA_SUCCESS, PURCHASE_FETCHING_DATA_ATTEMPT, purchaseYaag, purchaseYaagReset };
