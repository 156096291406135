// import * as React from "react";
import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import {  Checkbox,
  ListItemIcon,
  ListItemText,
  makeStyles, } from "@material-ui/core";
import { Button, CircularProgress, MenuItem, Select, Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import {
  onboardActivityReset,
  onboardBulkReset,
  onboardCampaign,
  onboardCampaignReset,
  onboardIndividualReset,
  onboardOrganization,
  onboardOrganizationReset,
} from "../../redux/action/OnboardAction";
import CallingCode from "../../services/CallingCode";
import { fetchCityAndState } from "../../services/commonServices";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";
import { API_URL } from "../../services/Constants";
import { getOrganization } from "../../redux/action/getOrganization";
import {
  activityDuration,
  campaignType,
  restrictedCountry,
  status,
} from "../../utils/dict";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { getCity } from "../../redux/action/cityAction";
import { states } from "../../utils/constants";
import { useLocation, useNavigate, Link } from "react-router-dom";
import TopHeaderScreen from "../../components/TopHeader1";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    fontSize: 15,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    // color: "#949494",
    color: "black",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "block",
    fontSize: 16,
    marginTop: 16,
    padding: 10,
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",

    [theme.breakpoints.only("xl")]: {
      width: "25%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup2: {
    display: "flex",
    justifyContent: "space-around",
    // padding: "16px 0",
    padding: "11px 0px 25px 1px",
    bottom: 50,
    background: "#2D7A74",
    // marginTop: 12,
    marginBottom: 10,
    [theme.breakpoints.only("xl")]: {
      width: "100%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "50%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },

  },
  Comments: {
    
    color: "white",
    
    [theme.breakpoints.only("xl")]: {
      fontSize:"14px"
    },
    [theme.breakpoints.only("lg")]: {
      fontSize:"14px"
    },
    [theme.breakpoints.only("md")]: {
      fontSize:"12px"
    },
    [theme.breakpoints.only("sm")]: {
      fontSize:"12px"
    },
    [theme.breakpoints.only("xs")]: {
      fontSize:"10px"
    },

  },
}));

function OrganizationViewAndReviewScreen(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationPath = useLocation();

  const [reviewstatus, setReviewStatus] = React.useState("");
  const [reviewComments, setReviewComments] = React.useState("");
  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "" },
  ]);

  
  const [activityList, setActivityList] = React.useState(undefined);
  const [orgValues, setOrgValues] = React.useState({
    titleOfOrganization: "",
    totalStaff: "",
    headquarters: "",
    organizationLogo: "",
    registeredAs: "",
    website: "",
    emailId: "",
    contactNo: "",
    totalNumberofVolunteers: "",
    chiefFunctionary: "",
  });
  const {
    isCampaignSuccess,
    isCampaignFetching,

    isCampaignError,
  } = useSelector((state) => state.onBoard);

  
  
  const [response, setResponse] = useState({});

  const handleOnboardCampaign = async () => {
    const payload = {
      clientid: locationPath?.state?.clientid,
      createdate: locationPath?.state?.createdate,
      reviewstatus: reviewstatus,
      reviewcomments: reviewComments,
    };
    try {
      // if (!(reviewstatus === "")) {
        const res = await axios.put(
          `/org/review/${localStorage.getItem("userid")}`,
          payload
        );
        // navigate("/revieworganization");
        setResponse(res?.data);
        return res;
      // } else {
      //   alert("Please chose a review status");
      // }
    } catch (err) {
      throw err;
    }
  };

  const handleCampaignFieldChange = (field, e) => {
    setResponse({});
    (isCampaignError || isCampaignSuccess) && dispatch(onboardCampaignReset());
    setOrgValues({
      ...orgValues,
      [field]: e.target.value,
    });
  };

  const unitOfCampaign = activityList?.filter(
    (item, index) => item?.activity === orgValues?.typeOfCampaign
  );

  

  const handleChangeLocation = (id, e, field, index) => {
    const values = [...location];
    values[index][field] = e.target.value;
    setLocation(values);
  };

  
  const getAddress = (item, index) => {
    return (
      <div style={{ float: "left", width: "100%",marginTop: 25 }}>
        <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>Operational Areas</span>
        <form style={{ display: "flex", flexWrap: "wrap" }}>
          <div className={classes.formGroup1} >
            <label htmlFor="create-yaad--title" className={classes.label}>
              Country
            </label>
            <input
              disabled
              value="India"
              type="text"
              className={classes.input}
            />
          </div>

          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              State
            </label>
            <input
              disabled
              value={item.state}
              type="text"
              className={classes.input}
            />
          </div>
          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              City/Town/Village
            </label>
            <input
              disabled
              value={item.city}
              type="text"
              className={classes.input}
            />
          </div>
          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Area
            </label>

            <input
              disabled
              value={item.area}
              type="text"
              id="create-yaad--title"
              className={classes.input}
              // placeholder="Your Answer"
              onChange={(e) => handleChangeLocation(item, e, "area", index)}
            />
          </div>
        </form>
      </div>
    );
  };

  React.useEffect(() => {
    
    dispatch(onboardBulkReset());
    dispatch(onboardIndividualReset());
    dispatch(onboardActivityReset());
    dispatch(onboardCampaignReset());
    dispatch(onboardOrganizationReset());
  }, []);

  React.useEffect(() => {
    setResponse({});
    if (locationPath?.state) {
      setOrgValues({
        titleOfOrganization: locationPath?.state?.organization,
        totalStaff: locationPath?.state?.noofemployees,
        headquarters: locationPath?.state?.orghq,
        registeredAs: locationPath?.state?.registeredas,
        website: locationPath?.state?.website,
        emailId: locationPath?.state?.emailid,
        chiefFunctionary: locationPath?.state?.chieffunctionary,
        contactNo: locationPath?.state?.contactnumber,
        orgFocusArea: locationPath?.state?.orgfocusarea,
        totalNumberofVolunteers: locationPath?.state?.noofvol,
      });
      setLocation(locationPath?.state?.location);
      // setFocusArea(JSON.parse(locationPath?.state?.rewardstobeearned))
    }
  }, [locationPath?.state]);

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
      <TopHeaderScreen {...props} />
      <div class="content content-wrap">
          <div class="content-mrg">
          <div style={{fontFamily:"monospace", fontSize: 16,  }}>
          <Button
                onClick={() => navigate(-1)}
                className={classes.backBtn}
            >
                <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
          </div>
          <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>View & Review</div> 
        <form autoComplete="off">
          <div style={{ border: "1px solid #dadce0", borderRadius: 8,padding: "13px 30px 40px 30px",backgroundColor:"#fff" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {/* <div className={classes.formGroup} style={{width: "25%"}}> */}
              <div className={classes.formGroup}>
                <label className={classes.label}>Name of Organisation</label>
                <input
                  disabled
                  value={orgValues?.titleOfOrganization}
                  type="text"
                  className={classes.input}
                  placeholder="Your answer"
                  onChange={(e) => {
                    handleCampaignFieldChange("titleOfOrganization", e);
                  }}
                />
              </div>

              {/* <div className={classes.formGroup} style={{width: "25%"}}> */}
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                Headquarters Name
                </label>
                <input
                  disabled
                  value={orgValues?.headquarters}
                  type="text"
                  className={classes.input}
                  placeholder="Your answer"
                  onChange={(e) => {
                    handleCampaignFieldChange("headquarters", e);
                  }}
                />
              </div>
              {/* <div className={classes.formGroup} style={{width: "25%"}}> */}
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                 Website
                </label>
                <input
                  disabled
                  value={orgValues?.website}
                  type="text"
                  className={classes.input}
                  placeholder="Your answer"
                  onChange={(e) => {
                    handleCampaignFieldChange("website", e);
                  }}
                />
              </div>
              {/* <div className={classes.formGroup} style={{width: "25%"}}> */}
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                Name of Chief Functionary
                </label>

                <input
                  disabled
                  value={orgValues?.chiefFunctionary}
                  type="text"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  onChange={(e) => {
                    handleCampaignFieldChange("chiefFunctionary", e);
                  }}
                />
              </div>
              {/* <div className={classes.formGroup} style={{width: "25%"}}> */}
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                Contact Number
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <input
                    disabled
                    value={orgValues?.contactNo}
                    type="number"
                    id="create-yaad--title"
                    className={classes.input}
                    placeholder="Your Answer"
                    onChange={(e) => {
                      handleCampaignFieldChange("contactNo", e);
                    }}
                  />
                  <span style={{ marginLeft: 4 }}>
                    {unitOfCampaign?.[0]?.unit}
                  </span>
                </div>
              </div>

              {/* <div className={classes.formGroup} style={{width: "25%"}}> */}
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                Email Id
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <input
                    disabled
                    value={orgValues?.emailId}
                    type="text"
                    id="create-yaad--title"
                    className={classes.input}
                    placeholder="Your Answer"
                    onChange={(e) => {
                      handleCampaignFieldChange("emailId", e);
                    }}
                  />
                </div>
              </div>
              
              {/* <div className={classes.formGroup} style={{width: "25%"}}> */}
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                Total Staff
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <input
                    disabled
                    value={orgValues?.totalStaff}
                    type="text"
                    id="create-yaad--title"
                    className={classes.input}
                    placeholder="Your Answer"
                    onChange={(e) => {
                      handleCampaignFieldChange("totalStaff", e);
                    }}
                  />
                </div>
              </div>
             

              {/* <div className={classes.formGroup} style={{width: "25%"}}> */}
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                Total No. of Volunteers
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <input
                    disabled
                    value={orgValues?.totalNumberofVolunteers}
                    type="text"
                    id="create-yaad--title"
                    className={classes.input}
                    placeholder="Your Answer"
                    onChange={(e) => {
                      handleCampaignFieldChange("totalNumberofVolunteers", e);
                    }}
                  />
                </div>
              </div>
              {/* <div className={classes.formGroup} style={{width: "100%"}}> */}
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                Focus Thematic Areas
                </label>
                {/* {focusArea?.map((item, index) => ( */}
                {orgValues?.orgFocusArea?.map((item, index) => (
                  <span style={{color:"#0F0F0F",fontFamily:"inherit", padding:"5px"}}> {(index ? ', ' : '')} {item.focus} </span>
                  // <div style={{ display: "flex" }}>
                  //   <input
                  //     disabled
                  //     style={{ fontStyle: "normal" }}
                  //     type="checkbox"
                  //     id={item.focus}
                  //     value={item.focus}
                  //     checked={item.isChecked}
                  //     onChange={(e) => onAddingItem(e, item, index)}
                  //   />
                  //   <div>
                  //     <label className={classes.label1} htmlFor={item.focus}>
                  //       {item.focus}
                  //     </label>
                  //     {/* {item.value === "Any Other" && (
                  //       <input
                  //         disabled
                  //         // className={classes.input}
                  //         style={{ fontStyle: "normal" }}
                  //         type="text"
                  //         onChange={(e) => handleTextFieldCheckbox(e, index)}
                  //       />
                  //     )} */}
                  //   </div>
                  // </div>
                ))}
              </div>

              {/* <div className={classes.formGroup}> */}
                {location.map((item, index) => (
                  <div key={index}>{getAddress(item, index)}</div>
                ))}
                {/* <AddCircleOutlineOutlined
                  formStyle={{ paddingTop: "20px" }}
                  onClick={handleAddFields}
                ></AddCircleOutlineOutlined> */}
              {/* </div> */}
            </div>
          </div>
        </form>
        <br></br>
        
            <br></br>
            {response?.status === "success" && 
                  <span style={{ color: "green", textAlign: "center", display: "block" }}>{response?.message}</span>}
                  {response?.status === "failure" && (
                    // <span style={{ color: "red" }}>Error occured</span>
                    <span style={{ color: "red", textAlign: "center", display: "block" }}>{response?.message}</span>
                  )}
        
        <div className={classes.formGroup2}  >
          <div
            style={{
              display: "flex",
              width: "40%",
              flexDirection: "column",
              justifyContent:"center"
            }}
          >
            <span className={classes.Comments}>Comments</span>
            <textarea
              style={{ height: 100, borderRadius: 8, padding: 8 }}
              placeholder="Comments"
              onChange={(e) => setReviewComments(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>Status</span>
              <Select
                style={{
                  width: 200,
                  fontSize: 14,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  background: "#fff",
                }}
                onChange={(e) => setReviewStatus(e.target.value)}
              >
                {status?.map((item, index) => (
                  <MenuItem style={{ fontSize: 16 }} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            {/* {response && 
                  <span style={{ color: "green", textAlign: "center", display: "block" }}>{response?.message}</span>}
                  {!response && (
                    // <span style={{ color: "red" }}>Error occured</span>
                    <span style={{ color: "red", textAlign: "center", display: "block" }}>{response?.message}</span>
                  )} */}

            
            <Button
              style={{
                marginTop: 10,
                backgroundColor: "#E88A00",
                color: "#fff",
                fontFamily: "Poppins !important",
                width: "100%",
              }}
              variant="contained"
              onClick={handleOnboardCampaign}
              className={classes.goalButton}
            >
              {isCampaignFetching ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
          {/* {response && 
                  <span style={{ color: "green", textAlign: "center", display: "block" }}>{response?.message}</span>}
                  {!response && (
                    // <span style={{ color: "red" }}>Error occured</span>
                    <span style={{ color: "red", textAlign: "center", display: "block" }}>{response?.message}</span>
                  )} */}

          <br />
          {/* {isCampaignSuccess && (
            <span style={{ color: "green" }}>
              Campaign onboarded sucessfully
            </span>
          )}
          {isCampaignError && (
            <span style={{ color: "red" }}>Something error occured</span>
          )} */}
        </div>
        
      </div>
      </div>
      <CopyRight />
      </section>
    </div>
  );
}
export default OrganizationViewAndReviewScreen;
