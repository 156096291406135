import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Protected(props) {
    const {Component} = props
    const navigate = useNavigate();
    React.useEffect(() => {
        let login = localStorage.getItem("userid");
        if (!login) {
            navigate("/")
        }
      }, []);
    return(
        <div>
            <Component />
        </div>
    )
}

export default Protected;