import React, { useEffect, useRef, useState } from "react";
import _, { isArray, round } from "lodash";
import {
  Avatar,
  Button,
  CircularProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { EditOutlined, RemoveRedEyeOutlined } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PauseIcon from "@mui/icons-material/Pause";
import SearchBar from "material-ui-search-bar";
import ReactPlayer from "react-player";
import DownloadIcon from "@mui/icons-material/Download";
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import QrCodeIcon from '@mui/icons-material/QrCode';

const useStyles = makeStyles((theme) => ({
  homeSreenManiContent: {
    padding: "100px 0 0 20px",
    [theme.breakpoints.up("sm")]: {
      padding: "100px 0 0 220px",
    },
  },
  homeScreenContainer: {
    padding: "0 20px",
  },
  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginRight: 10,
    color: "#fff",
  },
  table: {
    width: "100%",
    borderRadius: "10px",
    borderStyle: "solid",
    borderWidth: "1.5px",
    // tableLayout: "fixed",
  },
  tableRow: {
    borderBottom: "1px solid #d3d3d3",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  thead: {
    borderBottom: "1px solid #d3d3d3",
    borderTop: "1px solid #d3d3d3",
    height: 60,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.getContrastText(theme.palette.secondary.dark),
  },
  theadText: {
    fontWeight: "700",
    textAlign: "center",
    fontSize: 16,
    // minWidth: 200,
    padding:"0px 5px 0px 5px",
  },
  theadTextSINo: {
    fontWeight: "700",
    textAlign: "center",
    fontSize: 16,
    minWidth: 50,
    padding:"0px 5px 0px 5px",
  },

  tbodyText: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    [theme.breakpoints.up("sm")]: {
      width: "140px !important",
      height: "140px !important",
    },
    [theme.breakpoints.up("md")]: {
      width: "150px !important",
      height: "150px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "120px !important",
      height: "120px !important",
    },
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // wordWrap: "breakWord",
    // overflow: "scroll",
  },
  tbodyTextRedeemReport: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    [theme.breakpoints.up("sm")]: {
      minWidth: "400px !important",
      height: "140px !important",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "400px !important",
      height: "150px !important",
    },
    [theme.breakpoints.up("xs")]: {
      minWidth: "400px !important",
      height: "120px !important",
    },
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // wordWrap: "breakWord",
    // overflow: "scroll",
  },
  tbodyTextvolstat: {
    height: 80,
    textAlign: "right",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width: "200px",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // wordWrap: "breakWord",
    // overflow: "scroll",
  },
  large: {
    width: "200px !important",
    height: "200px !important",
    margin: "0 auto",
    marginTop: "5px",
    marginBottom: "5px",
    [theme.breakpoints.up("sm")]: {
      width: "140px !important",
      height: "140px !important",
    },
    [theme.breakpoints.up("md")]: {
      width: "150px !important",
      height: "150px !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: "120px !important",
      height: "120px !important",
    },
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "#000",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid green !important",
    // '&:nth-of-type(odd)': {
    // 	backgroundColor: '#f7dbb3',
    // },
    // '&:nth-of-type(even)': {
    // 	backgroundColor: '#e9e7e8',
    // },
    "&:last-child": {
      borderBottom: "none",
    },
  },
}))(TableRow);

export const TableComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setsearched] = useState("");
  const [user, setUser] = React.useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchedRows = (filteredOptions) => {
    setUser(filteredOptions);
    // setsearched(filteredOptions);
  };

  const requestSearch = (searchVal, callback) => {
    const filteredOptions = props.rows.filter((name) =>
      name.firstname.toLowerCase().includes(searchVal.toLowerCase())
    );
    // console.log(searchVal);
    // console.log(filteredOptions);
    handleSearchedRows(filteredOptions);
    // callback(filteredOptions);
  };

  const cancelSearch = () => {
    setsearched("");
    requestSearch(searched);
  };

  function createData(
    id,
    firstname,
    lastname,
    emailid,
    phoneno,
    organization,
    orgrole,
    state,
    city
  ) {
    return {
      id,
      firstname,
      lastname,
      emailid,
      phoneno,
      organization,
      orgrole,
      state,
      city,
    };
  }

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < props.rows.length; i += 1) {
      // console.log(props.rows[i]);
      const formattedArray = [];
      formattedArray.push(
        props.rows[i].firstname,
        props.rows[i].lastname,
        props.rows[i].emailid,
        props.rows[i].phoneno,
        props.rows[i].organization,
        props.rows[i].orgrole,
        props.rows[i].state,
        props.rows[i].city
      );

      temp.push(createData(i, ...formattedArray));
      // console.log(temp);
    }
    setUser(temp);
  }, [props.rows]);

  return (
    <Paper>
      <SearchBar
        value={searched}
        onChange={(searchVal) => requestSearch(searchVal)}
        onCancelSearch={() => cancelSearch()}
        placeholder="Search user with first name"
        style={{ width: "400px" }}
      />
      <TableContainer
        ref={tableRef}
        component={Paper}
        style={{
          padding: 16,
          borderRadius: 8,
          // width: "max-content",
          borderRadius: 6,
          background: "#ffffff",
          marginTop: 16,
          minWidth: "80vw",
        }}
        className="manageUser"
      >
        {!_.isEmpty(user) ? (
          <Table className={classes.table} aria-label="customized table">
            <thead>
              <tr className={classes.thead}>
                <td className={classes.theadText}>
                  <span>First Name</span>
                </td>
                <td className={classes.theadText}>
                  <span>Last Name</span>
                </td>

                {/* <td className={classes.theadText}>
                <span>DOB</span>
              </td> */}
                {/* <td className={classes.theadText}>
                <span>Gender</span>
              </td> */}
                <td className={classes.theadText}>
                  <span>Email Id</span>
                </td>
                <td className={classes.theadText}>
                  <span>Phone Number</span>
                </td>
                <td className={classes.theadText}>
                  <span>Organization</span>
                </td>
                <td className={classes.theadText}>
                  <span>Role</span>
                </td>

                {/* <td className={classes.theadText}>
                <span>Correspondence Address</span>
              </td> */}

                <td className={classes.theadText}>
                  <span>State</span>
                </td>
                <td className={classes.theadText}>
                  <span>City</span>
                </td>
                <td className={classes.theadText}>
                  <span>Edit</span>
                </td>
                <td className={classes.theadText}>
                  <span>Delete</span>
                </td>
              </tr>
            </thead>
            {props.isFetching ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <tbody>
                {/* {JSON.stringify(rows)} */}
                {user
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <tr className={classes.tableRow}>
                      <td className={classes.tbodyText}>
                        <span> {item?.firstname}</span>
                      </td>
                      <td className={classes.tbodyText}>
                        <span> {item?.lastname}</span>
                      </td>

                      {/* <td className={classes.tbodyText}>
                    <span>{item.dob}</span>
                  </td> */}
                      {/* <td className={classes.tbodyText}>
                    <span>{item.gender}</span>
                  </td> */}
                      <td className={classes.tbodyText}>
                        <span>{item.emailid}</span>
                      </td>
                      <td className={classes.tbodyText}>
                        <span>{item.phoneno}</span>
                      </td>
                      <td className={classes.tbodyText}>
                        <span>{item.organization}</span>
                      </td>
                      <td className={classes.tbodyText}>
                        <span>{item.orgrole}</span>
                      </td>

                      {/* <td className={classes.tbodyText}>
                    <span>{item.correspondenceaddress}</span>
                  </td> */}

                      <td className={classes.tbodyText}>
                        <span>{item.state}</span>
                      </td>
                      <td className={classes.tbodyText}>
                        <span>{item.city}</span>
                      </td>
                      {String(item.id) === "undefined" ? (
                        <td
                          className={classes.tbodyText}
                          onClick={() =>
                            //alert("CLICKED")
                            navigate(`/editvolunteer/${item?.phoneno}`, {
                              state: item,
                            })
                          }
                          style={{ textAlign: "center", cursor: "pointer" }}
                        >
                          {/* <RemoveRedEyeOutlined /> */}
                          <EditOutlined
                            style={{ textAlign: "center", cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td
                          className={classes.tbodyText}
                          onClick={() =>
                            //alert("CLICKED")
                            navigate(
                              `/editvolunteer/${props?.rows[item.id]?.phoneno}`,
                              { state: props?.rows[item.id] }
                            )
                          }
                          style={{ textAlign: "center", cursor: "pointer" }}
                        >
                          {/* <RemoveRedEyeOutlined /> */}
                          <EditOutlined
                            style={{ textAlign: "center", cursor: "pointer" }}
                          />
                        </td>
                      )}

                      {String(item.id) === "undefined" ? (
                        <td
                          className={classes.tbodyText}
                          // onClick={handleDeleteUser(item?.phoneno)}
                          onClick={() =>
                            navigate(`/deletevolunteer/${item?.phoneno}`, {
                              state: item,
                            })
                          }
                          style={{ textAlign: "center", cursor: "pointer" }}
                        >
                          <DeleteForeverIcon
                            style={{
                              textAlign: "center",
                              cursor: "pointer",
                              color: "red",
                            }}
                          />
                        </td>
                      ) : (
                        <td
                          className={classes.tbodyText}
                          // onClick={handleDeleteUser(item?.phoneno)}
                          onClick={() =>
                            navigate(
                              `/deletevolunteer/${
                                props?.rows[item.id]?.phoneno
                              }`,
                              { state: props?.rows[item.id] }
                            )
                          }
                          style={{ textAlign: "center", cursor: "pointer" }}
                        >
                          <DeleteForeverIcon
                            style={{
                              textAlign: "center",
                              cursor: "pointer",
                              color: "red",
                            }}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            )}
          </Table>
        ) : (
          <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
            No data found
          </Typography>
        )}
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[10, 20, 40]}
            component="div"
            count={user?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableFooter>
      </TableContainer>
    </Paper>
  );
};

export const CampaignTableComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "80vw",
      }}
    >
      {!_.isEmpty(props.rows) ? (
        <Table className={classes.table} aria-label="customized table">
          <thead>
            <tr className={classes.thead}>
              <td className={classes.theadText}>
                <span>Title</span>
              </td>
              <td className={classes.theadText}>
                <span>Organization Name</span>
              </td>
              <td className={classes.theadText}>
                <span>Phone No</span>
              </td>
              <td className={classes.theadText}>
                <span>Campaign Name</span>
              </td>

              <td className={classes.theadText}>
                <span>Start Date</span>
              </td>

              <td className={classes.theadText}>
                <span>State</span>
              </td>
              <td className={classes.theadText}>
                <span>City</span>
              </td>
              <td className={classes.theadText}>
                <span>Area</span>
              </td>

              <td className={classes.theadText}>
                <span>Impact</span>
              </td>
              <td className={classes.theadText}>
                <span>Social Media Message</span>
              </td>
              <td className={classes.theadText}>
                <span>Logo</span>
              </td>
              <td className={classes.theadText}>
                <span>Video</span>
              </td>
            </tr>
          </thead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <tbody>
              {props.rows
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .filter((item, index) => item.reviewstatus === "Approved")
                .map((item) => (
                  <tr className={classes.tableRow}>
                    <td className={classes.tbodyText}>
                      <span>{item?.title}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item?.organizationname}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item.phoneno}</span>
                    </td>

                    <td className={classes.tbodyText}>
                      <span>{item?.type}</span>
                    </td>

                    <td className={classes.tbodyText}>
                      <span>{item.startdate}</span>
                    </td>

                    <td className={classes.tbodyText}>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            {item?.state === "" ? (
                              <span>All States</span>
                            ) : (
                              <span>{item?.state}</span>
                            )}
                            <br />
                          </>
                        ))}
                    </td>
                    <td className={classes.tbodyText}>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            {item?.city === "" ? (
                              <span>All Cities</span>
                            ) : (
                              <span>{item?.city}</span>
                            )}
                            <br />
                          </>
                        ))}
                    </td>
                    <td className={classes.tbodyText}>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            {item?.area === "" ? (
                              <span>All Areas</span>
                            ) : (
                              <span>{item?.area}</span>
                            )}
                            <br />
                          </>
                        ))}
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item.impact}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item.socialmediamsg}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <Avatar
                        src={item?.logo}
                        style={{ width: 80, height: 80 }}
                      />
                    </td>
                    <td className={classes.tbodyText}>
                      <video
                        controls
                        width="100%"
                        src={item?.video}
                        style={{ width: 200, height: 100 }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableFooter>
    </TableContainer>
  );
};

// export const ReviewCampaignTableComponent = (props) => {
//   const dispatch = useDispatch();
//   const classes = useStyles();
//   const tableRef = useRef(null);
//   const navigate = useNavigate();
//   const [searched, setsearched] = useState("");
//   const [user, setUser] = React.useState();
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(2);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };

//   const handleSearchedRows = (filteredOptions) => {
//     setUser(filteredOptions);
//     // setsearched(filteredOptions);
//   };

//   const requestSearch = (searchVal, callback) => {
//     const filteredOptions = props.rows.filter((name)=>

//         (name.title.toLowerCase().includes(searchVal.toLowerCase()))

//       );
//       // console.log(searchVal);
//       // console.log(filteredOptions);
//       handleSearchedRows(filteredOptions);
//       // callback(filteredOptions);
//   };

//   const cancelSearch = () => {
//     setsearched("");
//     requestSearch(searched);
//   };

//   function createData(id, title, goal, startdate, enddate, visibility, location, reviewstatus, paused, campid) {
//     return {
//       id,
//       title,
//       goal,
//       startdate,
//       enddate,
//       visibility,
//       location,
//       reviewstatus,
//       paused,
//       campid,
//     };
//   }

//   useEffect(() => {
//     let temp = [];
//     for (let i = 0; i < props.rows.length; i += 1) {
//       // console.log(props.rows[i]);
//       const formattedArray = [];
//       formattedArray.push(
//         props.rows[i].title,
//         props.rows[i].goal,
//         props.rows[i].startdate,
//         props.rows[i].enddate,
//         props.rows[i].visibility,
//         props.rows[i].location,
//         props.rows[i].reviewstatus,
//         props.rows[i].paused,
//         props.rows[i].campid,
//       );

//       temp.push(createData(i, ...formattedArray));
//       // console.log(temp);
//     }
//     setUser(temp);
//   }, [props.rows]);

//   return (
//     <Paper>
//       <SearchBar
//         value={searched}
//         onChange={(searchVal) => requestSearch(searchVal)}
//         onCancelSearch={() => cancelSearch()}
//         placeholder="Search with Campaign name"
//         style={{width: "400px"}}

//       />

//     <TableContainer
//       ref={tableRef}
//       component={Paper}
//     >

//       {!_.isEmpty(user) ? (
//         <Table className={classes.table} aria-label="customized table" style={{marginBottom: 50}}>
//           <thead>
//             <tr className={classes.thead}>
//               <td className={classes.theadText}>
//                 <span>S.no</span>
//               </td>
//               {/* <td className={classes.theadText}>
//                 <span>Type</span>
//               </td> */}
//               <td className={classes.theadText}>
//                 <span> Name </span>
//               </td>
//               <td className={classes.theadText}>
//                 <span> Goal </span>
//               </td>
//               {/* <td className={classes.theadText}>
//                 <span>Create Date</span>
//               </td> */}
//               <td className={classes.theadText}>
//                 <span>Start Date</span>
//               </td>
//               <td className={classes.theadText}>
//                 <span>End Date</span>
//               </td>
//               <td className={classes.theadText}>
//                 <span>Visibility</span>
//               </td>
//               <td className={classes.theadText}>
//                 <span>State</span>
//               </td>

//               <td className={classes.theadText}>
//                 <span>City</span>
//               </td>

//               <td className={classes.theadText}>
//                 <span>Status</span>
//               </td>
//               <td className={classes.theadText}>
//                 <span>Pause Status (Yes/No)</span>
//               </td>
//               <td className={classes.theadText}>
//                 <span>View & Review</span>
//               </td>
//               <td className={classes.theadText}>
//                 <span>Edit</span>
//               </td>
//               <td className={classes.theadText}>
//                 <span>Pause</span>
//               </td>
//               <td className={classes.theadText}>
//                 <span>Resume</span>
//               </td>
//             </tr>
//           </thead>
//           {props.isFetching ? (
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//               }}
//             >
//               <CircularProgress />
//             </div>
//           ) : (
//             <tbody>

//               {user.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).
//                 map((item, index) => (
//                 <tr className={classes.tableRow}>
//                   <td className={classes.tbodyText}>
//                     <span>{index + 1}</span>
//                   </td>
//                   {JSON.stringify(item?.campid)}

//                   {/* <td className={classes.tbodyText}>
//                     <span>{item?.type}</span>
//                   </td> */}
//                   <td className={classes.tbodyText}>
//                     <span>{item?.title}</span>
//                     {/* <span style={{ display: "grid"}}>{item?.title}</span> */}
//                   </td>
//                   <td className={classes.tbodyText}>
//                     <span>{item?.goal}</span>
//                   </td>
//                   {/* <td className={classes.tbodyText}>
//                     <span>{item?.createdate}</span>
//                   </td> */}
//                   <td className={classes.tbodyText}>
//                     <span>{item?.startdate}</span>
//                   </td>
//                   <td className={classes.tbodyText}>
//                     <span>{item?.enddate}</span>
//                   </td>
//                   <td className={classes.tbodyText}>
//                     <span>{item?.visibility}</span>
//                   </td>
//                   {/* <td className={classes.tbodyText}>
//                     {_.isArray(item?.location) &&
//                       item?.location.map((item, index) => (
//                         <>
//                           <span>{item?.country}</span>
//                           <br />
//                         </>
//                       ))}
//                   </td> */}
//                   <td className={classes.tbodyText}>
//                     {_.isArray(item?.location) &&
//                       item?.location.map((item, index) => (
//                         <>
//                           {item?.state === "" ? (
//                             <span>All States</span>
//                           ) : (
//                             <span>{item?.state}</span>
//                           )}
//                           <br />
//                         </>
//                       ))}
//                   </td>
//                   <td className={classes.tbodyText}>
//                     {_.isArray(item?.location) &&
//                       item?.location.map((item, index) => (
//                         <>
//                           {item?.city === "" ? (
//                             <span>All Cities</span>
//                           ) : (
//                             <span>{item?.city}</span>
//                           )}
//                           <br />
//                         </>
//                       ))}
//                   </td>
//                   <td className={classes.tbodyText}>
//                     <span
//                       style={{
//                         border:
//                           item?.reviewstatus === "Approved"
//                             ? "1px solid green"
//                             : item?.reviewstatus === "In Review"
//                             ? "1px solid #ffcc00"
//                             : "1px solid red",
//                         color:
//                           item?.reviewstatus === "Approved"
//                             ? "green"
//                             : item?.reviewstatus === "In Review"
//                             ? "#ffcc00"
//                             : "red",
//                         padding: 4,
//                         width: "200px",
//                         borderRadius: 4,
//                       }}
//                     >
//                       {item?.reviewstatus}
//                     </span>
//                   </td>
//                   <td className={classes.tbodyText}>
//                     <span
//                       style={{
//                         border:
//                           item?.paused === "No"
//                             ? "1px solid green"
//                             : item?.paused === "Yes"
//                             ? "1px solid red"
//                             : "1px solid red",
//                         color:
//                           item?.paused === "No"
//                             ? "green"
//                             : item?.paused === "Yes"
//                             ? "red"
//                             : "red",
//                         padding: 4,
//                         width: "200px",
//                         borderRadius: 4,
//                       }}
//                     >
//                       {item?.paused}
//                     </span>
//                   </td>

//                   <td

//                     className={classes.tbodyText}

//                     onClick={() =>
//                       navigate(`/viewcampaign/${item?.campid}`, { state: item })

//                     }

//                   >
//                     <RemoveRedEyeOutlined style={{ textAlign: "center", cursor: "pointer" }}/>
//                   </td>
//                   <td

//                     className={classes.tbodyText}

//                     onClick={() =>
//                       navigate(`/editcampaign/${item?.campid}`, { state: item })
//                     }

//                   >
//                     <EditOutlined style={{ textAlign: "center", cursor: "pointer" }}/>
//                   </td>

//                   {!(item?.reviewstatus === "Approved") ? (
//                   <td
//                     className={classes.tbodyText}
//                   >
//                     <PauseIcon style={{ textAlign: "center", border: "1px solid gray", color:"gray" }}/>
//                   </td>
//                   ):
//                  <td

//                  className={classes.tbodyText}

//                  onClick={() =>
//                    navigate(`/pausecampaign/${item?.campid}`, { state: item })
//                  }

//                >
//                  <PauseIcon style={{ textAlign: "center", cursor: "pointer", color:"red" }}/>
//                </td>
//                }

//                   {!(item?.reviewstatus === "Approved") ? (
//                   <td

//                     className={classes.tbodyText}

//                     // onClick={() =>
//                     //   navigate(`/resumecampaign/${item?.campid}`, { state: item })
//                     // }

//                   >
//                     <RestartAltIcon style={{ textAlign: "center", border: "1px solid gray", color:"gray" }}/>
//                   </td>
//                   ):

//                   <td

//                     className={classes.tbodyText}

//                     onClick={() =>
//                       navigate(`/resumecampaign/${item?.campid}`, { state: item })
//                     }

//                   >
//                     <RestartAltIcon style={{ textAlign: "center", cursor: "pointer", color:"green" }}/>
//                   </td>
// }

//                 </tr>
//               ))}
//             </tbody>
//           )}
//         </Table>
//       ) : (
//         <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
//           No data found
//         </Typography>
//       )}
//       <TableFooter>
//               <TablePagination
//                 rowsPerPageOptions={[2, 4, 8]}
//                 component="div"
//                 count={props?.rows?.length}
//                 rowsPerPage={rowsPerPage}
//                 page={page}
//                 onPageChange={handleChangePage}
//                 onRowsPerPageChange={handleChangeRowsPerPage}
//               />
//             </TableFooter>
//     </TableContainer>
//     </Paper>
//   );
// };

export const ReviewCampaignTableComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [searched, setsearched] = useState("");
  const [user, setUser] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  // const [searchloading, setSearchLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchedRows = (filteredOptions) => {
    setUser(filteredOptions);

    // setsearched(filteredOptions);
  };

  const requestSearch = (searchVal, callback) => {
    const filteredOptions = props.rows.filter((name) =>
      name.title.toLowerCase().includes(searchVal.toLowerCase())
    );
    // console.log(searchVal);
    // console.log(filteredOptions);
    handleSearchedRows(filteredOptions);

    // callback(filteredOptions);
  };

  const cancelSearch = () => {
    setsearched("");
    requestSearch(searched);
  };

  function createData(
    id,
    title,
    organization,
    goal,
    startdate,
    enddate,
    visibility,
    location,
    reviewstatus,
    paused,
    campid,
    active
  ) {
    return {
      id,
      title,
      organization,
      goal,
      startdate,
      enddate,
      visibility,
      location,
      reviewstatus,
      paused,
      campid,
      active,
    };
  }

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < props.rows.length; i += 1) {
      // console.log(props.rows[i]);
      const formattedArray = [];
      formattedArray.push(
        props.rows[i].title,
        props.rows[i].organization,
        props.rows[i].goal,
        props.rows[i].startdate,
        props.rows[i].enddate,
        props.rows[i].visibility,
        props.rows[i].location,
        props.rows[i].reviewstatus,
        props.rows[i].paused,
        props.rows[i].campid,
        props.rows[i].active
      );

      temp.push(createData(i, ...formattedArray));
      // console.log(temp);
    }
    setUser(temp);
  }, [props.rows]);



  return (
    <Paper>
      <SearchBar
        value={searched}
        onChange={(searchVal) => requestSearch(searchVal)}
        onCancelSearch={() => cancelSearch()}
        placeholder="Search with Campaign name"
        style={{ width: "400px" }}
      />

      <TableContainer ref={tableRef} component={Paper}>
        {!_.isEmpty(props?.rows) ? (
          <Table
            className={classes.table}
            aria-label="customized table"
            style={{ marginBottom: 50 }}
          >
            <thead>
              <tr className={classes.thead}>
                <td className={classes.theadText}>
                  <span>S.no</span>
                </td>
                {/* <td className={classes.theadText}>
                <span>Type</span>
              </td> */}
                <td className={classes.theadText}>
                  <span> Name </span>
                </td>
                <td className={classes.theadText}>
                  <span> Organization </span>
                </td>
                <td className={classes.theadText}>
                  <span> Goal </span>
                </td>
                {/* <td className={classes.theadText}>
                <span>Create Date</span>
              </td> */}
                <td className={classes.theadText}>
                  <span>Start Date</span>
                </td>
                <td className={classes.theadText}>
                  <span>End Date</span>
                </td>
                {/* <td className={classes.theadText}>
                  <span>Visibility</span>
                </td> */}
                <td className={classes.theadText}>
                  <span>Status</span>
                </td>
                <td className={classes.theadText}>
                  <span>State</span>
                </td>

                <td className={classes.theadText}>
                  <span>City</span>
                </td>

                <td className={classes.theadText}>
                  <span>Review Status</span>
                </td>
                <td className={classes.theadText}>
                  <span>Pause Status (Yes/No)</span>
                </td>
                <td className={classes.theadText}>
                  <span>View & Review</span>
                </td>
                <td className={classes.theadText}>
                  <span>Edit</span>
                </td>
                <td className={classes.theadText}>
                  <span>Pause</span>
                </td>
                <td className={classes.theadText}>
                  <span>Resume</span>
                </td>
              </tr>
            </thead>
            {props.isFetching ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <tbody>
                {user
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <tr className={classes.tableRow}>
                      <td className={classes.tbodyText}>
                        <span>
                          {page === 0
                            ? index + 1
                            : page * rowsPerPage + index + 1}
                        </span>
                      </td>

                      {/* <td className={classes.tbodyText}>
                    <span>{item?.type}</span>
                  </td> */}
                      {/* {JSON.stringify(item.campid)} */}
                      <td className={classes.tbodyText}>
                        <span>{item?.title}</span>
                        {/* <span style={{ display: "grid"}}>{item?.title}</span> */}
                      </td>
                      <td className={classes.tbodyText}>
                        <span>{item?.organization}</span>
                        {/* <span style={{ display: "grid"}}>{item?.title}</span> */}
                      </td>
                      <td className={classes.tbodyText}>
                        <span>{item?.goal}</span>
                      </td>
                      {/* <td className={classes.tbodyText}>
                    <span>{item?.createdate}</span>
                  </td> */}
                      <td className={classes.tbodyText}>
                        <span>{item?.startdate}</span>
                      </td>
                      <td className={classes.tbodyText}>
                        <span>{item?.enddate}</span>
                      </td>
                      {/* <td className={classes.tbodyText}>
                        <span>{item?.visibility}</span>
                      </td> */}
                      <td className={classes.tbodyText}>
                        <span>{item?.active}</span>
                      </td>
                      {/* <td className={classes.tbodyText}>
                    {_.isArray(item?.location) &&
                      item?.location.map((item, index) => (
                        <>
                          <span>{item?.country}</span>
                          <br />
                        </>
                      ))}
                  </td> */}
                      <td className={classes.tbodyText}>
                        {_.isArray(item?.location) &&
                          item?.location.map((item, index) => (
                            <>
                              {item?.state === "" ? (
                                <span>All States</span>
                              ) : (
                                <span>{item?.state}</span>
                              )}
                              <br />
                            </>
                          ))}
                      </td>
                      <td className={classes.tbodyText}>
                        {_.isArray(item?.location) &&
                          item?.location.map((item, index) => (
                            <>
                              {item?.city === "" ? (
                                <span>All Cities</span>
                              ) : (
                                <span>{item?.city}</span>
                              )}
                              <br />
                            </>
                          ))}
                      </td>
                      <td className={classes.tbodyText}>
                        <span
                          style={{
                            border:
                              item?.reviewstatus === "Approved"
                                ? "1px solid green"
                                : item?.reviewstatus === "In Review"
                                ? "1px solid #ffcc00"
                                : "1px solid red",
                            color:
                              item?.reviewstatus === "Approved"
                                ? "green"
                                : item?.reviewstatus === "In Review"
                                ? "#ffcc00"
                                : "red",
                            padding: 4,
                            width: "200px",
                            borderRadius: 4,
                          }}
                        >
                          {item?.reviewstatus}
                        </span>
                      </td>
                      <td className={classes.tbodyText}>
                        <span
                          style={{
                            border:
                              item?.paused === "No"
                                ? "1px solid green"
                                : item?.paused === "Yes"
                                ? "1px solid red"
                                : "1px solid red",
                            color:
                              item?.paused === "No"
                                ? "green"
                                : item?.paused === "Yes"
                                ? "red"
                                : "red",
                            padding: 4,
                            width: "200px",
                            borderRadius: 4,
                          }}
                        >
                          {item?.paused}
                        </span>
                      </td>
                      {/* page ={JSON.stringify(page)}
                  rowsPerPage ={JSON.stringify(rowsPerPage)} */}

                      {/* {JSON.stringify(item)} */}
                      {/* ********** {JSON.stringify(props?.rows[item.id]?.campid)} */}
                      {/* ********** {JSON.stringify(props?.rows[item.id])} */}
                      {/* ********** {JSON.stringify(props?.rows)}
                 {" "}
                  ========{JSON.stringify(item)} */}
                      {/* len = {JSON.stringify(String(item.id))}
                  =={JSON.stringify(item)} */}
                      {String(item.id) === "undefined" ? (
                        <td
                          className={classes.tbodyText}
                          onClick={() =>
                            // navigate(`/viewcampaign/${item?.campid}`, { state: item })

                            navigate(`/viewcampaign/${item?.campid}`, {
                              state: item,
                            })
                          }
                        >
                          <RemoveRedEyeOutlined
                            style={{ textAlign: "center", cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td
                          className={classes.tbodyText}
                          onClick={() =>
                            // navigate(`/viewcampaign/${item?.campid}`, { state: item })

                            navigate(
                              `/viewcampaign/${props?.rows[item.id]?.campid}`,
                              { state: props?.rows[item.id] }
                            )
                          }
                        >
                          <RemoveRedEyeOutlined
                            style={{ textAlign: "center", cursor: "pointer" }}
                          />
                        </td>
                      )}
                      {String(item.id) === "undefined" ? (
                        <td
                          className={classes.tbodyText}
                          onClick={() =>
                            // navigate(`/viewcampaign/${item?.campid}`, { state: item })

                            navigate(`/editcampaign/${item?.campid}`, {
                              state: item,
                            })
                          }
                        >
                          <EditOutlined
                            style={{ textAlign: "center", cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td
                          className={classes.tbodyText}
                          onClick={() =>
                            // navigate(`/editcampaign/${item?.campid}`, { state: item })

                            navigate(
                              `/editcampaign/${props?.rows[item.id]?.campid}`,
                              { state: props.rows[item.id] }
                            )
                          }
                        >
                          <EditOutlined
                            style={{ textAlign: "center", cursor: "pointer" }}
                          />
                        </td>
                      )}

                      {!(item?.reviewstatus === "Approved") ||
                      !(item?.active === "Active") ? (
                        <td className={classes.tbodyText}>
                          <PauseIcon
                            style={{
                              textAlign: "center",
                              border: "1px solid gray",
                              color: "gray",
                            }}
                          />
                        </td>
                      ) : String(item.id) === "undefined" ? (
                        <td
                          className={classes.tbodyText}
                          onClick={() =>
                            //  navigate(`/pausecampaign/${item?.campid}`, { state: item })
                            navigate(`/pausecampaign/${item?.campid}`, {
                              state: item,
                            })
                          }
                        >
                          <PauseIcon
                            style={{
                              textAlign: "center",
                              cursor: "pointer",
                              color: "red",
                            }}
                          />
                        </td>
                      ) : (
                        <td
                          className={classes.tbodyText}
                          onClick={() =>
                            //  navigate(`/pausecampaign/${item?.campid}`, { state: item })
                            navigate(
                              `/pausecampaign/${props?.rows[item.id]?.campid}`,
                              { state: props.rows[item.id] }
                            )
                          }
                        >
                          <PauseIcon
                            style={{
                              textAlign: "center",
                              cursor: "pointer",
                              color: "red",
                            }}
                          />
                        </td>
                      )}

                      {!(item?.reviewstatus === "Approved") ||
                      !(item?.active === "Active") ? (
                        <td
                          className={classes.tbodyText}

                          // onClick={() =>
                          //   navigate(`/resumecampaign/${item?.campid}`, { state: item })
                          // }
                        >
                          <RestartAltIcon
                            style={{
                              textAlign: "center",
                              border: "1px solid gray",
                              color: "gray",
                            }}
                          />
                        </td>
                      ) : String(item.id) === "undefined" ? (
                        <td
                          className={classes.tbodyText}
                          onClick={() =>
                            // navigate(`/resumecampaign/${item?.campid}`, { state: item })
                            navigate(`/resumecampaign/${item?.campid}`, {
                              state: item,
                            })
                          }
                        >
                          <RestartAltIcon
                            style={{
                              textAlign: "center",
                              cursor: "pointer",
                              color: "green",
                            }}
                          />
                        </td>
                      ) : (
                        <td
                          className={classes.tbodyText}
                          onClick={() =>
                            // navigate(`/resumecampaign/${item?.campid}`, { state: item })
                            navigate(
                              `/resumecampaign/${props?.rows[item.id]?.campid}`,
                              { state: props.rows[item.id] }
                            )
                          }
                        >
                          <RestartAltIcon
                            style={{
                              textAlign: "center",
                              cursor: "pointer",
                              color: "green",
                            }}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            )}
          </Table>
        ) : (
          <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
            No data found
          </Typography>
        )}
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[15, 30, 45, 60, 75, 90]}
            component="div"
            count={props?.rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableFooter>
      </TableContainer>
    </Paper>
  );
};

export const CampaignTableComponentForSOAdmin = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // console.log(props)
  // console.log(props.rows)
  return (
    <TableContainer ref={tableRef} component={Paper}>
      {!_.isEmpty(props.rows) ? (
        <Table className={`${classes.table} customizedOrgTable`} aria-label="customized table">
          <thead>
            <tr className={classes.thead}>
              <td className={classes.theadText}>
                <span>S.no</span>
              </td>
              {/* <td className={classes.theadText}>
                <span>Type</span>
              </td> */}
              <td className={classes.theadText}>
                <span> Name </span>
              </td>
              <td className={classes.theadText}>
                <span>Goal</span>
              </td>
              <td className={classes.theadText}>
                <span>Create Date</span>
              </td>
              <td className={classes.theadText}>
                <span>Start Date</span>
              </td>
              <td className={classes.theadText}>
                <span>End Date</span>
              </td>
              <td className={classes.theadText}>
                <span>Sponsors</span>
              </td>
              <td className={classes.theadText}>
                <span>Visibility</span>
              </td>
              <td className={classes.theadText}>
                <span>State</span>
              </td>

              <td className={classes.theadText}>
                <span>City</span>
              </td>

              <td className={classes.theadText}>
                <span>Status</span>
              </td>
              <td className={classes.theadText}>
                <span>Review Comments</span>
              </td>
              {/* <td className={classes.theadText}>
                <span>View</span>
              </td> */}
            </tr>
          </thead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <tbody>
              {props.rows &&
                props.rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <tr className={classes.tableRow}>
                      <td className={classes.tbodyText}>
                        <span>{index + 1 + (page * rowsPerPage)}</span>
                      </td>
                      {/* <td className={classes.tbodyText}>
                    <span>{item?.type}</span>
                  </td> */}
                      <td className={classes.tbodyText}>
                        <span>{item?.title}</span>
                      </td>
                      {/* <td className={classes.tbodyText} style={{overflowY: "scroll", height:"100px"}}> */}
                      <td className={classes.tbodyText}>
                        <span class="truncateTwoLine" title={item?.goal}>{item?.goal}</span>
                      </td>
                      <td className={classes.tbodyText}>
                        <span>{item?.createdate}</span>
                      </td>
                      <td className={classes.tbodyText}>
                        <span>{item?.startdate}</span>
                      </td>
                      <td className={classes.tbodyText}>
                        <span>{item?.enddate}</span>
                      </td>
                      <td className={classes.tbodyText}>
                        {/* ==item.sponsor== {JSON.stringify(item?.sponsor)} */}
                        {_.isArray(item?.sponsor) &&
                          item?.sponsor.map((item1, index) => (
                            <>
                              {/* <span>{item?.sponsorname}</span> */}
                              {/* ==item1.sponsor== {JSON.stringify(item1)} */}
                              
                              {/* <span style={{color:"#0F0F0F",fontFamily:"inherit", padding:"5px"}}> {item1?.sponsor}  {((index == (item?.sponsor?.length-1)) ? '' : ',')} </span> */}
                              <span style={{fontFamily:"inherit"}}>{item1?.sponsor}{((index == (item?.sponsor?.length-1)) ? '' : ', ')}</span>
                            </>
                          ))}
                      </td>
                      <td className={classes.tbodyText}>
                        <span>{item?.visibility}</span>
                      </td>
                      {/* <td className={classes.tbodyText}>
                    {_.isArray(item?.location) &&
                      item?.location.map((item, index) => (
                        <>
                          <span>{item?.country}</span>
                          <br />
                        </>
                      ))}
                  </td> */}
                      <td className={classes.tbodyText}>
                        {_.isArray(item?.location) &&
                          item?.location.map((item, index) => (
                            <>
                              {item?.state === "" ? (
                                <span>All States</span>
                              ) : (
                                <span>{item?.state}</span>
                              )}
                              <br />
                            </>
                          ))}
                      </td>
                      <td className={classes.tbodyText}>
                        {_.isArray(item?.location) &&
                          item?.location.map((item, index) => (
                            <>
                              {item?.city === "" ? (
                                <span>All Cities</span>
                              ) : (
                                <span>{item?.city}</span>
                              )}
                              <br />
                            </>
                          ))}
                      </td>
                      <td className={classes.tbodyText}>
                        <span
                          style={{
                            border:
                              item?.reviewstatus === "Approved"
                                ? "1px solid green"
                                : item?.reviewstatus === "In Review"
                                ? "1px solid #ffcc00"
                                : "1px solid red",
                            color:
                              item?.reviewstatus === "Approved"
                                ? "green"
                                : item?.reviewstatus === "In Review"
                                ? "#ffcc00"
                                : "red",
                            padding: 4,
                            width: "auto",
                            borderRadius: 4,
                          }}
                        >
                          {item?.reviewstatus}
                        </span>
                      </td>
                      <td className={classes.tbodyText}>
                        <span>{item?.reviewcomments}</span>
                      </td>
                      {/* <td
                    className={classes.tbodyText}
                    onClick={() =>
                      navigate(`/viewcampaign/${item?.campid}`, { state: item })
                    }
                    style={{ textAlign: "center", cursor: "pointer" }}
                  >
                    <RemoveRedEyeOutlined />
                  </td> */}
                    </tr>
                  ))}
            </tbody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableFooter>
    </TableContainer>
  );
};

export const CampaignRegistrationTable = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [fileContent, setFileContent] = useState(null);
  const fileInputRef = useRef(null);

  
  const [displayImage, setDisplayImage] = useState(false);

  // const handleButtonClick = (imageURL) => {
  //   console.log("handleButtonClick")
  //   // window.open(imageURL, "_blank");
    
  // };

  // const handleButtonClick = (imageURL) => {
  //   console.log("handleButtonClick")
  //   setDisplayImage(true);
    
  // };

  const handleButtonClick = (imageUrl) => {
    // Open a popup window with the image
    const popupWindow = window.open('', '_blank', 'width=400, height=300');
    
    // Create a simple HTML document with the image
    const htmlContent = `<html><body style="margin: 0; display: flex; justify-content: center; align-items: center; height: 100%;"><img src="${imageUrl}" alt="Opened Image" style="max-width: 100%; max-height: 100%;"></body></html>`;
    
    // Set the HTML content of the popup window
    popupWindow.document.write(htmlContent);
  };
  

  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "80vw",
        backgroundColor: "inherit",
      }}
    >
      {!_.isEmpty(props.rows) ? (
        <Table className={classes.table} aria-label="customized table">
          <thead>
            <tr className={classes.thead}>
              <td className={classes.theadTextSINo}>
                <span>S.no</span>
              </td>
              <td className={classes.theadText}>
                <span> Campaign Name </span>
              </td>
              <td className={classes.theadText}>
                <span>Participation Status</span>
              </td>
              <td className={classes.theadText}>
                <span>Review Comments</span>
              </td>
              <td className={classes.theadText}>
                <span> Registered Date </span>
              </td>
              <td className={classes.theadText}>
                <span>Committed Date(s) for Participation</span>
              </td>
              {/* // <td className={classes.theadText}>
              //   <span>My Time Commitment in Hour(s)</span>
              // </td> */}
              <td className={classes.theadText}>
                <span>Review Date</span>
              </td>
              <td className={classes.theadText}>
                <span>QR Code</span>
              </td>
              <td className={classes.theadText}>
                <span>Points Awarded</span>
              </td>
            </tr>
          </thead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <tbody>
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <tr className={classes.tableRow}>
                    <td className={classes.tbodyText}>
                      <span>{index + 1 + (page * rowsPerPage)}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item?.formcampaignname}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span
                        style={{
                          border:
                            item?.registrationstatus === "Approved" ||
                            item?.registrationstatus === "approved"
                              ? "1px solid green"
                              : item?.registrationstatus === "Submitted" ||
                                item?.registrationstatus === "submitted"
                              ? "1px solid #ffcc00"
                              : "1px solid red",
                          color:
                            (item?.registrationstatus === "Approved") |
                            (item?.registrationstatus === "approved")
                              ? "green"
                              : item?.registrationstatus === "Submitted" ||
                                item?.registrationstatus === "submitted"
                              ? "#ffcc00"
                              : "red",
                          padding: 4,
                          width: "200px",
                          borderRadius: 4,
                        }}
                      >
                        {String(item?.registrationstatus).toLocaleUpperCase()}
                      </span>
                    </td>
                    <td className={classes.tbodyText}>
                      {item?.reviewcomments ? (
                        <span>{item?.reviewcomments}</span>
                      ) : (
                        <span>No review comments</span>
                      )}
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item?.registerdate}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item?.participatedays}</span>
                    </td>
                   
                    <td className={classes.tbodyText}>
                      <span>{item?.rewarddate}</span>
                    </td>
                    

                    <td
                      className={classes.tbodyText}
                      onClick={() =>
                        handleButtonClick(item?.qrcodeurl)
                      }
                    >
                      <QrCodeIcon style={{ cursor: "pointer" }} />
                                          
                    </td>


                  


                    <td className={classes.tbodyText}>
                      <span>{parseInt(item?.rewardpoints)}</span>
                    </td>
                  </tr>
                
                ))}
                
            </tbody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableFooter>
    </TableContainer>
  );
};

export const ReviewOrgainzationTableComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      // style={{
      //   padding: 16,
      //   borderRadius: 8,
      //   width: "max-content",
      //   borderRadius: 6,
      //   background: "#ffffff",
      //   marginTop: 16,
      //   minWidth: "80vw",
      // }}
    >
      {!_.isEmpty(props.rows) ? (
        <Table className={classes.table} aria-label="customized table">
          <thead>
            <tr className={classes.thead}>
              <td className={classes.theadText}>
                <span>S.no</span>
              </td>
              <td className={classes.theadText}>
                <span>Organization</span>
              </td>
              <td className={classes.theadText}>
                <span>No of volunteers</span>
              </td>
              {/* <td className={classes.theadText}>
                <span>Headquarters Name</span>
              </td>
              <td className={classes.theadText}>
                <span>Chief Functionary</span>
              </td> */}
              {/* <td className={classes.theadText}>
                <span>Country</span>
              </td> */}
              <td className={classes.theadText}>
                <span>State (Operational Area)</span>
              </td>

              <td className={classes.theadText}>
                <span>City (Operational Area)</span>
              </td>

              <td className={classes.theadText}>
                <span>Status</span>
              </td>
              <td className={classes.theadText}>
                <span>View & Review</span>
              </td>
              <td className={classes.theadText}>
                <span>Edit</span>
              </td>
            </tr>
          </thead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <tbody>
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <tr className={classes.tableRow}>
                    <td className={classes.tbodyText}>
                      <span>{index + 1}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item?.organization}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item?.noofvol}</span>
                    </td>
                    {/* <td className={classes.tbodyText}>
                    <span>{item?.orghq}</span>
                  </td> */}
                    {/* <td className={classes.tbodyText}>
                    <span>{item?.chieffunctionary}</span>
                  </td> */}
                    {/* <td className={classes.tbodyText}>
                    {_.isArray(item?.location) &&
                      item?.location.map((item, index) => (
                        <>
                          <span>{item?.country}</span>
                          <br />
                        </>
                      ))}
                  </td> */}
                    <td className={classes.tbodyText}>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            {item?.state === "" ? (
                              <span>All States</span>
                            ) : (
                              <span>{item?.state}</span>
                            )}
                            <br />
                          </>
                        ))}
                    </td>
                    <td className={classes.tbodyText}>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            {item?.city === "" ? (
                              <span>All Cities</span>
                            ) : (
                              <span>{item?.city}</span>
                            )}
                            <br />
                          </>
                        ))}
                    </td>
                    <td className={classes.tbodyText}>
                      <span
                        style={{
                          border:
                            item?.reviewstatus === "Approved"
                              ? "1px solid green"
                              : item?.reviewstatus === "In Review"
                              ? "1px solid #ffcc00"
                              : "1px solid red",
                          color:
                            item?.reviewstatus === "Approved"
                              ? "green"
                              : item?.reviewstatus === "In Review"
                              ? "#ffcc00"
                              : "red",
                          padding: 4,
                          width: "200px",
                          borderRadius: 4,
                        }}
                      >
                        {item?.reviewstatus}
                      </span>
                    </td>
                    <td
                      className={classes.tbodyText}
                      onClick={() =>
                        navigate(`/orgreview/${item?.clientid}`, {
                          state: item,
                        })
                      }
                    >
                      <RemoveRedEyeOutlined style={{ cursor: "pointer" }} />
                    </td>
                    <td
                      className={classes.tbodyText}
                      onClick={() =>
                        navigate(`/editorg/${item?.clientid}`, { state: item })
                      }
                    >
                      <EditOutlined
                        style={{ textAlign: "center", cursor: "pointer" }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableFooter>
    </TableContainer>
  );
};

export const OrganizationTableComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "80vw",
      }}
    >
      {!_.isEmpty(props.rows) ? (
        <Table className={classes.table} aria-label="customized table">
          <thead>
            <tr className={classes.thead}>
              <td className={classes.theadText}>
                <span>Organization</span>
              </td>
              <td className={classes.theadText}>
                <span>Organization Description</span>
              </td>
              <td className={classes.theadText}>
                <span>No of employees</span>
              </td>
              <td className={classes.theadText}>
                <span>Phone No</span>
              </td>
              <td className={classes.theadText}>
                <span>No of Volunteer</span>
              </td>
              <td className={classes.theadText}>
                <span>City (Operation Area)</span>
              </td>
              <td className={classes.theadText}>
                <span>Country</span>
              </td>

              <td className={classes.theadText}>
                <span>State (Operation Area)</span>
              </td>
              <td className={classes.theadText}>
                <span>Area</span>
              </td>

              <td className={classes.theadText}>
                <span>Logo</span>
              </td>
            </tr>
          </thead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <tbody>
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <tr className={classes.tableRow}>
                    <td className={classes.tbodyText}>
                      <span>{item?.organization}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item?.organizationdesc}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item?.noofemployees}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item.contactnumber}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item.noofvol}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            {item?.city === "" ? (
                              <span>All Cities</span>
                            ) : (
                              <span>{item?.city}</span>
                            )}
                            <br />
                          </>
                        ))}
                      {/* {item.location} */}
                    </td>
                    <td className={classes.tbodyText}>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            <span>{item.country}</span>
                            <br />
                          </>
                        ))}
                    </td>

                    <td className={classes.tbodyText}>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            {item?.state === "" ? (
                              <span>All States</span>
                            ) : (
                              <span>{item?.state}</span>
                            )}
                            <br />
                          </>
                        ))}
                    </td>
                    <td className={classes.tbodyText}>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            {item?.area === "" ? (
                              <span>All Areas</span>
                            ) : (
                              <span>{item?.area}</span>
                            )}
                            <br />
                          </>
                        ))}
                    </td>

                    <td className={classes.tbodyText}>
                      <Avatar
                        src={item?.organizationlogo}
                        style={{ width: 80, height: 80 }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableFooter>
    </TableContainer>
  );
};

export const ReviewSponsorTableComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      // style={{
      //   padding: 16,
      //   borderRadius: 8,
      //   width: "max-content",
      //   borderRadius: 6,
      //   background: "#ffffff",
      //   marginTop: 16,
      //   minWidth: "80vw",
      // }}
    >
      {!_.isEmpty(props.rows) ? (
        <Table className={classes.table} aria-label="customized table">
          <thead>
            <tr className={classes.thead}>
              <td className={classes.theadText}>
                <span>S.no</span>
              </td>
              <td className={classes.theadText}>
                <span>Sponsor</span>
              </td>
              <td className={classes.theadText}>
                <span>No of employees</span>
              </td>
              {/* <td className={classes.theadText}>
                <span>Headquarters Name</span>
              </td>
              <td className={classes.theadText}>
                <span>Chief Functionary</span>
              </td> */}
              {/* <td className={classes.theadText}>
                <span>Country</span>
              </td> */}
              <td className={classes.theadText}>
                <span>State (Operational Area)</span>
              </td>

              <td className={classes.theadText}>
                <span>City (Operational Area)</span>
              </td>

              <td className={classes.theadText}>
                <span>Status</span>
              </td>
              {/* <td className={classes.theadText}>
                <span>View</span>
              </td> */}
              <td className={classes.theadText}>
                <span>Edit</span>
              </td>
            </tr>
          </thead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <tbody>
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <tr className={classes.tableRow}>
                    <td className={classes.tbodyText}>
                      <span>{index + 1}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item?.organization}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item?.noofemployees}</span>
                    </td>
                    {/* <td className={classes.tbodyText}>
                    <span>{item?.noofvol}</span>
                  </td> */}
                    {/* <td className={classes.tbodyText}>
                    <span>{item?.orghq}</span>
                  </td> */}
                    {/* <td className={classes.tbodyText}>
                    <span>{item?.chieffunctionary}</span>
                  </td> */}
                    {/* <td className={classes.tbodyText}>
                    {_.isArray(item?.location) &&
                      item?.location.map((item, index) => (
                        <>
                          <span>{item?.country}</span>
                          <br />
                        </>
                      ))}
                  </td> */}
                    <td className={classes.tbodyText}>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            {item?.state === "" ? (
                              <span>All States</span>
                            ) : (
                              <span>{item?.state}</span>
                            )}
                            <br />
                          </>
                        ))}
                    </td>
                    <td className={classes.tbodyText}>
                      {_.isArray(item?.location) &&
                        item?.location.map((item, index) => (
                          <>
                            {item?.city === "" ? (
                              <span>All Cities</span>
                            ) : (
                              <span>{item?.city}</span>
                            )}
                            <br />
                          </>
                        ))}
                    </td>
                    <td className={classes.tbodyText}>
                      <span
                        style={{
                          border:
                            item?.reviewstatus === "Approved"
                              ? "1px solid green"
                              : item?.reviewstatus === "In Review"
                              ? "1px solid #ffcc00"
                              : "1px solid red",
                          color:
                            item?.reviewstatus === "Approved"
                              ? "green"
                              : item?.reviewstatus === "In Review"
                              ? "#ffcc00"
                              : "red",
                          padding: 4,
                          width: "200px",
                          borderRadius: 4,
                        }}
                      >
                        {item?.reviewstatus}
                      </span>
                    </td>
                    {/* <td
                    className={classes.tbodyText}
                    
                    onClick={() =>
                      navigate(`/sponsorreview/${item?.clientid}`, { state: item })
                    }
                    
                  >
                    <RemoveRedEyeOutlined style={{ cursor: "pointer" }}/>
                  </td> */}
                    <td
                      className={classes.tbodyText}
                      onClick={() =>
                        navigate(`/editsponsor/${item?.clientid}`, {
                          state: item,
                        })
                      }
                    >
                      <EditOutlined
                        style={{ textAlign: "center", cursor: "pointer" }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableFooter>
    </TableContainer>
  );
};

export const ManageRewardsTableComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // console.log(props.rows?.[0].sponsor.length);
  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      // style={{
      //   padding: 16,
      //   borderRadius: 8,
      //   width: "max-content",
      //   borderRadius: 6,
      //   background: "#ffffff",
      //   marginTop: 16,
      //   minWidth: "80vw",
      // }}
    >
      {!_.isEmpty(props.rows) ? (
        <Table className={classes.table} aria-label="customized table">
          <thead>
            <tr className={classes.thead}>
              <td className={classes.theadText}>
                <span>S.no</span>
              </td>
              <td className={classes.theadText}>
                <span>Award Name</span>
              </td>
              <td className={classes.theadText}>
                <span>Award Ownership</span>
              </td>
              {/* <td className={classes.theadText}>
                <span>Headquarters Name</span>
              </td>
              <td className={classes.theadText}>
                <span>Chief Functionary</span>
              </td> */}
              {/* <td className={classes.theadText}>
                <span>Country</span>
              </td> */}
              <td className={classes.theadText}>
                <span>Award Title</span>
              </td>

              <td className={classes.theadText}>
                <span>Award Subtitle</span>
              </td>

              {/* <td className={classes.theadText}>
                <span>Sponsor</span>
              </td> */}
              <td className={classes.theadText}>
                <span>Points</span>
              </td>
              {/* <td className={classes.theadText}>
                <span>Edit</span>
              </td> */}
              <td className={classes.theadText}>
                <span>Delete</span>
              </td>
            </tr>
          </thead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <tbody>
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <tr className={classes.tableRow}>
                    <td className={classes.tbodyText}>
                      <span>{index + 1 + (page * rowsPerPage)}</span>
                    </td>

                    <td className={classes.tbodyText}>
                      <span>{item?.rewardtype}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item?.ownership}</span>
                    </td>

                    <td className={classes.tbodyText}>
                      <span>{item?.title}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item?.subtitle}</span>
                    </td>

                    {/* <td className={classes.tbodyText}>
                      {isArray(item?.sponsor) ? (
                        item?.sponsor?.map((item1, index) => (
                          <span style={{ padding: "5px" }}>
                            {item1?.sponsor}{" "}
                            {index == item?.sponsor?.length - 1 ? "" : ","}
                          </span>
                        ))
                      ) : (
                        <span>
                          {item?.sponsor}{" "}
                          {index == item?.sponsor?.length - 1 ? "" : ","}{" "}
                        </span>
                      )}
                    </td> */}

                    <td className={classes.tbodyText}>
                      <span>{item?.points}</span>
                    </td>
                    {/* <td className={classes.tbodyText}>
                    <span
                      style={{
                        border:
                          item?.reviewstatus === "Approved"
                            ? "1px solid green"
                            : item?.reviewstatus === "In Review"
                            ? "1px solid #ffcc00"
                            : "1px solid red",
                        color:
                          item?.reviewstatus === "Approved"
                            ? "green"
                            : item?.reviewstatus === "In Review"
                            ? "#ffcc00"
                            : "red",
                        padding: 4,
                        width: "200px",
                        borderRadius: 4,
                      }}
                    >
                      {item?.reviewstatus}
                    </span>
                  </td> */}
                    {/* <td
                      className={classes.tbodyText}
                      onClick={() =>
                        navigate(`/editreward/${item?.mpid}`, { state: item })
                      }
                    >
                      <EditOutlined
                        style={{ textAlign: "center", cursor: "pointer" }}
                      />
                    </td> */}
                    <td
                      className={classes.tbodyText}
                      // onClick={handleDeleteUser(item?.phoneno)}
                      onClick={() =>
                        navigate(`/deletereward/${item?.phoneno}`, {
                          state: item,
                        })
                      }
                      style={{ textAlign: "center", cursor: "pointer" }}
                    >
                      <DeleteForeverIcon
                        style={{
                          textAlign: "center",
                          cursor: "pointer",
                          color: "red",
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableFooter>
    </TableContainer>
  );
};

export const AcitvityTableComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);
  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        width: "max-content",
        boxShadow: "1px 1px 1px 5px #f0f0f0",
        borderRadius: 8,
        marginTop: 16,
      }}
    >
      {!_.isEmpty(props.rows) ? (
        <Table className={classes.table} aria-label="customized table">
          <thead>
            <tr>
              <td style={{ textAlign: "center" }}>Full Name</td>
              <td style={{ textAlign: "center" }}>Phone No</td>
              <td style={{ textAlign: "center" }}>City</td>
              <td style={{ textAlign: "center" }}>Campaign Name</td>
              <td style={{ textAlign: "center" }}>Activity Date</td>
            </tr>
          </thead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <TableBody>
              {props.rows.map((item) => (
                <StyledTableRow style={{ borderBottom: "1px solid red" }}>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {item?.fullname}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {item.phoneno}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {item.city}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {item.campaignname}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {item.activitydate}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
    </TableContainer>
  );
};

export const VideoDetailsTableComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setsearched] = useState("");
  const [user, setUser] = React.useState();
 

  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        // padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "40vw",
      }}
    >
      {!_.isEmpty(props?.rows) ? (
        <Table className={classes.table} aria-label="customized table">
          <thead>
            <tr className={classes.thead}>
              <td className={classes.theadText}>
                <span>Contributed By</span>
              </td>
              <td className={classes.theadText}>
                <span>Contributed When</span>
              </td>
              <td className={classes.theadText}>
                <span>Contributor's Organization</span>
              </td>
            </tr>
          </thead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <tbody>
              <tr className={classes.tableRow}>
                <td className={classes.tbodyText}>
                  <span> {props?.rows?.username}</span>
                </td>
                <td className={classes.tbodyText}>
                  <span> {props?.rows?.timestamp}</span>
                </td>
                <td className={classes.tbodyText}>
                  <span>{props?.rows?.userorg}</span>
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
    </TableContainer>
  );
};

export const TextContainerComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setsearched] = useState("");
  const [user, setUser] = React.useState();


  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        // padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        // minWidth: "40vw",
        maxWidth: "100vw",
      }}
    >
      {!_.isEmpty(props?.users) ? (
        <Table className={classes.table} aria-label="customized table">
          <thead>
            <tr className={classes.thead}>
              <td className={classes.theadText}>
                <span>SI #</span>
              </td>
              <td className={classes.theadText}>
                <span>Members in the room</span>
              </td>
              {/* <td className={classes.theadText}>
                <span>Time Stamp</span>
              </td> */}

              {/* <td className={classes.theadText}>
                <span>Contributed When</span>
              </td>
              <td className={classes.theadText}>
                <span>Contributor's Organization</span>
              </td> */}
            </tr>
          </thead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <tbody>
              {props.users.map((item, index) => (
                <tr className={classes.tableRow}>
                  <td className={classes.tbodyText}>
                    <span>{index + 1}</span>
                  </td>
                  <td className={classes.tbodyText}>
                    <span> {item.name}</span>
                  </td>
                  {/* <td className={classes.tbodyText}>
                  <span> {new Date().getDay() + "-" + new Date().getMonth() +  " " + 
                            new Date().getHours() + ":" + new Date().getMinutes()}</span>
                </td> */}
                  {/* <td className={classes.tbodyText}>
                  <span>{props?.rows?.userorg}</span>
                </td> */}
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
    </TableContainer>
  );
};

export const VolStatsFileListTableComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setsearched] = useState("");
  const [user, setUser] = React.useState();


  const downloadFileAtURL = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };
  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "20vw",
        // maxWidth: "50vw",
      }}
    >
      {!_.isEmpty(props?.rows) ? (
        <Table className={classes.table} aria-label="customized table">
          <thead>
            <tr className={classes.thead}>
              <td className={classes.theadText}>
                <span>Asset Type</span>
              </td>
              <td className={classes.theadText}>
                <span>Asset URL</span>
              </td>
              <td className={classes.theadText}>
                <span>Asset Uploaded Date</span>
              </td>
              <td className={classes.theadText}>
                <span>Download</span>
              </td>
            </tr>
          </thead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <tbody>
              {props?.rows?.data?.map((item, index) =>
                item.files?.map((item1) => (
                  <tr className={classes.tableRow}>
                    <td className={classes.tbodyText}>
                      <span> {item1?.type}</span>
                    </td>
                    <td className={classes.tbodyTextvolstat}>
                      {item1?.type === "video" ? (
                        <ReactPlayer
                          url={item1?.fileurl}
                          className="react-player-volstate"
                          controls
                        />
                      ) : (
                        <Avatar
                          src={item1?.fileurl}
                          className={classes.large}
                        />
                      )}
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item1?.timestamp}</span>
                    </td>
                    <td
                      className={classes.tbodyText}
                      onClick={() => {
                        downloadFileAtURL(item1?.fileurl);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {/* <span>{item1?.timestamp}</span> */}

                      <DownloadIcon />
                      {/* <Button
                  onClick={() => {
                    downloadFileAtURL(item1?.fileurl);
                  }
                  }
                  >Save</Button> */}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
    </TableContainer>
  );
};

export const RedeemRewardTableComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searched, setsearched] = useState("");
  const [user, setUser] = React.useState();

  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  
  const downloadFileAtURL = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };

  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "80vw",
        // maxWidth: "50vw",
      }}
    >
      {!_.isEmpty(props?.rows) ? (
        <Table className={classes.table} aria-label="customized table">
          <thead>
            <tr className={classes.thead}>
              <td className={classes.theadText}>
                <span>Reward Type</span>
              </td>
              <td className={classes.theadText}>
                <span>Title</span>
              </td>
              <td className={classes.theadText}>
                <span>Sub Title</span>
              </td>
              {/* <td className={classes.theadText}>
                <span>Points</span>
              </td> */}
              <td className={classes.theadText}>
                <span>Redeem Date</span>
              </td>
              <td className={classes.theadText}>
                <span>Download</span>
              </td>
              <td className={classes.theadText}>
                <span>Fulfilment Status</span>
              </td>
              <td className={classes.theadText}>
                <span>Fulfilment Date</span>
              </td>
            </tr>
          </thead>
          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <tbody>
              {/* {props.rows?.data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => 
                item.reward?.map((item1) => ( */}
                {/* props?.rows?.data?.map((item) => item.reward?.map((item1) => item1?.length)) */}
                {props.rows?.data?.map(item=>item.reward?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item1, index) => (
                // item.reward?.map((item1) => (
                  <tr className={classes.tableRow}>
                    <td className={classes.tbodyText}>
                      <span> {item1?.rewardtype}</span>
                    </td>

                    <td className={classes.tbodyTextRedeemReport}>
                      <span class="truncateTwoLine" title={item1?.title}>{item1?.title}</span>
                    </td>
                    <td className={classes.tbodyTextRedeemReport}>
                      <span>{item1?.subtitle}</span>
                    </td>
                    {/* <td className={classes.tbodyText}>
                      <span>{item1?.points}</span>
                    </td> */}
                    <td className={classes.tbodyTextRedeemReport}>
                      <span>{item1?.redeemdate}</span>
                    </td>
                    <td className={classes.tbodyTextRedeemReport}>
                    {item1?.rewardurl ? (
                      <span><CardMembershipIcon onClick={() => {
                        downloadFileAtURL(item1?.rewardurl);
                      }}
                      style={{ cursor: "pointer" }}/></span>
                    ): (
                      <span>Not Available</span>
                    )
                    }
                    </td>
                    
                    <td className={classes.tbodyText}>
                      <span
                      style={{
                        border:
                          item1?.status === "Close" ||
                          item1?.status === "close"
                            ? "1px solid green"
                            : item1?.status === "Scheduled" ||
                              item1?.status === "scheduled"
                            ? "1px solid #ffcc00"
                            : "1px solid red",
                        color:
                          (item1?.status === "Close") |
                          (item1?.status === "close")
                            ? "green"
                            : item1?.status === "Scheduled" ||
                              item1?.status === "scheduled"
                            ? "#ffcc00"
                            : "red",
                        padding: 4,
                        width: "200px",
                        borderRadius: 4,
                      }}
                      
                      >{String(item1?.status).toLocaleUpperCase()}</span>
                    </td>
                    <td className={classes.tbodyTextRedeemReport}>
                      <span>{item1?.fulfilmentdate}</span>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          // count={props?.rows?.data?.length}
          count={props?.rows?.data?.map((item) => item.reward?.length)}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableFooter>
    </TableContainer>
  );
};

export const RewardsReportTableComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(2);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const navigate = useNavigate();
  return (
    <div
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "50vw",
        backgroundColor: "inherit",
      }}
    >
      {!_.isEmpty(props?.rows) ? (
        <Table className={classes.table} aria-label="customized table">
          <thead>
            {/* <StyledTableCell> */}

            <tr className={classes.thead}>
              <td className={classes.theadText}>
                <span>Camp Name</span>
              </td>

              <td className={classes.theadText}>
                <span>Participate Points Budget</span>
              </td>
              <td className={classes.theadText}>
                <span>Participate Points Used</span>
              </td>
              <td className={classes.theadText}>
                <span> Reference Points Budget</span>
              </td>
              <td className={classes.theadText}>
                <span>Reference Points Used</span>
              </td>
              <td className={classes.theadText}>
                <span>Awarded Target Bonus Pts</span>
              </td>
              <td className={classes.theadText}>
                <span>Target Volunteers</span>
              </td>
              {/* <td className={classes.theadText}>
                <span> Volunteers Registered</span>
              </td> */}
              <td className={classes.theadText}>
                <span> Volunteers Participated</span>
              </td>
              <td className={classes.theadText}>
                <span> Campaign Remaining Days</span>
              </td>
            </tr>
            {/* </StyledTableCell> */}
          </thead>

          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <tbody>
              {/* {activityList.map((item, index) => ( */}
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  // {activityList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  // .map((item, index) => (

                  <tr className={classes.tableRow}>
                    <td className={classes.tbodyText}>
                      <span>{item?.campaign}</span>
                    </td>

                    <td className={classes.participate}>
                      <span>{item?.participatebudgetpoints}</span>
                    </td>
                    <td className={classes.participate}>
                      <span>{item?.participatepointused}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.referralbudgetpoints}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.refpointsused}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.targetbonuspointgiven}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item?.targetvol}</span>
                    </td>
                    {/* <td className={classes.tbodyText}>
                    <span>{item?.volregistered}</span>
                  </td> */}
                    <td className={classes.tbodyText}>
                      <span>{item?.volparticipated}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item?.daysrem}</span>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[2, 4, 8]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableFooter>
    </div>
  );
};

export const CampSubmissionReportTableComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        backgroundColor: "inherit",
        overflowX: "auto",
      }}
    >
      {!_.isEmpty(props?.rows) ? (
        <Table className={classes.table} aria-label="customized table">
          <thead>
            {/* <StyledTableCell> */}

            <tr className={classes.thead}>
              <td className={classes.theadText}>
                <span>Camp Name</span>
              </td>

              <td className={classes.theadText}>
                <span>Change maker</span>
              </td>
              <td className={classes.theadText}>
                <span>Contact No.</span>
              </td>
              <td className={classes.theadText}>
                <span>Email ID</span>
              </td>
              <td className={classes.theadText}>
                <span>Organization</span>
              </td>
              <td className={classes.theadText}>
                <span>Registration Date</span>
              </td>
              <td className={classes.theadText}>
                <span>Registration Status</span>
              </td>
              <td className={classes.theadText}>
              {
                
              !(props?.rows?.[0]?.formq7 === "undefined") ? (
              
              <span>{props?.rows?.[0]?.formq7}</span>
              ):""
              }
            </td>

            <td className={classes.theadText}>
              {
                
              !(props?.rows?.[0]?.formq8 === "undefined") ? (
              
              <span>{props?.rows?.[0]?.formq8}</span>
              ):""
              }
            </td>
              
            </tr>
          </thead>

          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <tbody>
              {/* {activityList.map((item, index) => ( */}
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  // {activityList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  // .map((item, index) => (

                  <tr className={classes.tableRow}>
                    <td className={classes.tbodyText}>
                      <span>{item?.campaignname}</span>
                    </td>

                    <td className={classes.participate}>
                      <span>
                        {item?.firstname} {item?.lastname}
                      </span>
                    </td>
                    <td className={classes.participate}>
                      <span>{item?.contactno}</span>
                    </td>
                    <td className={classes.participate}>
                      <span>{item?.emailid}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.organization}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.registerdate}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.registrationstatus}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.formq7a}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.formq8a}</span>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      {!props.isFetching ? (
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[10, 20, 40]}
            component="div"
            count={props?.rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableFooter>
      ) : (
        ""
      )}
    </TableContainer>
  );
};

export const SponsorAdminDashboardTableComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const navigate = useNavigate();
  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        backgroundColor: "inherit",
        overflowX: "auto",
      }}
    >
      {!_.isEmpty(props?.rows) ? (
        <Table className={classes.table} aria-label="customized table">
          <thead>
            {/* <StyledTableCell> */}

            <tr className={classes.thead}>
              <td className={classes.theadText}>
                <span>Camp Name</span>
              </td>

              <td className={classes.theadText}>
                <span>Create Date</span>
              </td>
              <td className={classes.theadText}>
                <span>Start Date</span>
              </td>
              <td className={classes.theadText}>
                <span>End Date</span>
              </td>
              <td className={classes.theadText}>
                <span>Reward Budget Given</span>
              </td>
            </tr>
          </thead>

          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <tbody>
              {/* {activityList.map((item, index) => ( */}
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  // {activityList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  // .map((item, index) => (

                  <tr className={classes.tableRow}>
                    <td className={classes.tbodyText}>
                      <span>{item?.title}</span>
                    </td>

                    <td className={classes.participate}>
                      <span>{item?.createdate}</span>
                    </td>
                    <td className={classes.participate}>
                      <span>{item?.startdate}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.enddate}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.sponsorbudget}</span>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      {!props.isFetching ? (
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[10, 20, 40]}
            component="div"
            count={props?.rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableFooter>
      ) : (
        ""
      )}
    </TableContainer>
  );
};

export const DataAlertReportTableComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        backgroundColor: "inherit",
        overflowX: "auto",
      }}
    >
      {!_.isEmpty(props?.rows) ? (
        <Table className={classes.table} aria-label="customized table">
          <thead>
            {/* <StyledTableCell> */}

            <tr className={classes.thead}>
              <td className={classes.theadText}>
                <span>Alert Message</span>
              </td>
              {/* <td className={classes.theadText}>
                <span>Changemaker Phone</span>
              </td> */}

              {/* <td className={classes.theadText}>
                <span>Alert Date</span>
              </td> */}
              {/* <td className={classes.theadText}>
                <span>Campaign ID</span>
              </td>
              <td className={classes.theadText}>
                <span>Campaign Name</span>
              </td>
              <td className={classes.theadText}>
                <span>Registration Approved</span>
              </td>
              <td className={classes.theadText}>
                <span>Registration Approval Date</span>
              </td>
              <td className={classes.theadText}>
                <span>Reward Points Granted</span>
              </td> */}
              {/* <td className={classes.theadText}>
                <span>Reward Budget</span>
              </td> */}
            </tr>
          </thead>

          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <tbody>
              {/* {activityList.map((item, index) => ( */}
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => item?.map((item1) => (
                // .map((item, index) => (

                  <tr className={classes.tableRow}>
                    {/* <td className={classes.tbodyText}>
                      <span>
                        {item?.firstname} {item?.lastname}
                      </span>
                    </td> */}

                    <td className={classes.participate}>
                      <span>{item1?.datainconsistency}</span>
                    </td>
                    {/* <td className={classes.participate}>
                    <span>{item?.alertdate}</span>
                  </td> */}
                    {/* <td className={classes.referral}>
                      <span>{item?.campid}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.title}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.regapproved}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.regapproveddate}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.rewardpoints}</span>
                    </td> */}
                  </tr>
                )))}
            </tbody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      {!props.isFetching ? (
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[10, 20, 40]}
            component="div"
            count={props?.rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableFooter>
      ) : (
        ""
      )}
    </TableContainer>
  );
};


export const UserPerformanceReportTableComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const navigate = useNavigate();
  return (
    <div
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "50vw",
        backgroundColor: "inherit",
      }}
      className="userPerformanceReport"
    >
      {!_.isEmpty(props?.rows) ? (
        <Table className={classes.table} aria-label="customized table">
          <thead>
            {/* <StyledTableCell> */}

            <tr className={classes.thead}>
              <td className={classes.theadText}>
                <span>User Name</span>
              </td>

              <td className={classes.theadText}>
                <span>Contact No.</span>
              </td>
              <td className={classes.theadText}>
                <span>No. of Campaigns Participated</span>
              </td>
              <td className={classes.theadText}>
                <span> Total hours of effort</span>
              </td>
              <td className={classes.theadText}>
                <span>Total Reward Points</span>
              </td>
              {/* <td className={classes.theadText}>
                <span>Target Volunteers</span>
              </td>  */}
              {/* <td className={classes.theadText}>
                <span> Volunteers Registered</span>
              </td> */}
              {/* <td className={classes.theadText}>
                <span> Volunteers Participated</span>
              </td>
              <td className={classes.theadText}>
                <span> Campaign Remaining Days</span>
              </td> */}
            </tr>
            {/* </StyledTableCell> */}
          </thead>

          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <tbody>
              {/* {activityList.map((item, index) => ( */}
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  // {activityList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  // .map((item, index) => (
                    

                  <tr className={classes.tableRow}>
                    {/* {JSON.stringify(`/user/report/${localStorage.getItem("userid")}/${item?.phoneno}`)} */}
                    <td className={classes.tbodyText}                    
                          onClick={() =>
                            //alert("CLICKED")
                            
                            navigate(`/user/report/${localStorage.getItem("userid")}/${item?.phoneno}`)
                          }
                          style={{ textAlign: "center", cursor: "pointer", color:"blue" }}
                          >
                      <span>{item?.firstname} {item?.lastname}</span>
                    </td>

                    <td className={classes.participate}>
                      <span>{item?.phoneno}</span>
                    </td>
                    <td className={classes.participate}>
                      <span>{item?.participationcount}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.hourscontributed}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.rewardpoint}</span>
                    </td>
                    {/* <td className={classes.tbodyText}>
                      <span>{item?.targetvol}</span>
                    </td> */}
                    {/* <td className={classes.tbodyText}>
                    <span>{item?.volregistered}</span>
                  </td> */}
                    {/* <td className={classes.tbodyText}>
                      <span>{item?.volparticipated}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item?.daysrem}</span>
                    </td> */}
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableFooter>
    </div>
  );
};



export const UserPerformanceReportDetailsTableComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const navigate = useNavigate();
  return (
    <div
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "50vw",
        backgroundColor: "inherit",
      }}
    >
      {!_.isEmpty(props?.rows) ? (
        <Table className={classes.table} aria-label="customized table">
          <thead>
            {/* <StyledTableCell> */}

            <tr className={classes.thead}>
              <td className={classes.theadText}>
                <span>Campaign Name</span>
              </td>

              <td className={classes.theadText}>
                <span>Registered Date</span>
              </td>
              <td className={classes.theadText}>
                <span>Reward Date</span>
              </td>
              <td className={classes.theadText}>
                <span> Points Earned By</span>
              </td>
              <td className={classes.theadText}>
                <span># of times participated</span>
              </td>
              <td className={classes.theadText}>
                <span>Points Earned</span>
              </td> 
              {/* <td className={classes.theadText}>
                <span> Volunteers Registered</span>
              </td> */}
              {/* <td className={classes.theadText}>
                <span> Volunteers Participated</span>
              </td>
              <td className={classes.theadText}>
                <span> Campaign Remaining Days</span>
              </td> */}
            </tr>
            {/* </StyledTableCell> */}
          </thead>

          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <tbody>
              {/* {activityList.map((item, index) => ( */}
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  // {activityList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  // .map((item, index) => (

                  <tr className={classes.tableRow}>
                    <td className={classes.tbodyText}>
                      <span>{item?.campname}</span>
                    </td>

                    <td className={classes.participate}>
                      <span>{item?.registerdate}</span>
                    </td>
                    <td className={classes.participate}>
                      <span>{item?.rewarddate}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.pointsearnedby}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.countofparticipation}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item?.points}</span>
                    </td>
                    {/* <td className={classes.tbodyText}>
                    <span>{item?.volregistered}</span>
                  </td> */}
                    {/* <td className={classes.tbodyText}>
                      <span>{item?.volparticipated}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item?.daysrem}</span>
                    </td> */}
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableFooter>
    </div>
  );
};




// export const SOActionOnRewardsRedeemedTableComponent = (props) => {
//   const dispatch = useDispatch();
//   const classes = useStyles();
//   const tableRef = useRef(null);
//   const navigate = useNavigate();
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(10);
//   const [searched, setsearched] = useState("");
//   const [user, setUser] = React.useState();
//   console.log(props?.rows?.data?.map((item) => item));
//   console.log(
//     props?.rows?.data?.map((item) => item.reward?.map((item1) => item1))
//   );
  
//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };

//   return (
//     <TableContainer
//       ref={tableRef}
//       component={Paper}
//       style={{
//         padding: 16,
//         borderRadius: 8,
//         // width: "max-content",
//         borderRadius: 6,
//         background: "#ffffff",
//         marginTop: 16,
//         minWidth: "80vw",
//         // maxWidth: "50vw",
//       }}
//     >
//       {!_.isEmpty(props?.rows) ? (
//         <Table className={classes.table} aria-label="customized table">
//           <thead>
//             <tr className={classes.thead}>
//               <td className={classes.theadText}>
//                 <span>First Name</span>
//               </td>
//               <td className={classes.theadText}>
//                 <span>Last Name</span>
//               </td>
//               <td className={classes.theadText}>
//                 <span>Phone No</span>
//               </td>
//               <td className={classes.theadText}>
//                 <span>Organization</span>
//               </td>
//               <td className={classes.theadText}>
//                 <span>Reward Type</span>
//               </td>
//               <td className={classes.theadText}>
//                 <span>Reward Title</span>
//               </td>
//               <td className={classes.theadText}>
//                 <span>Reward Sub title</span>
//               </td>
//               <td className={classes.theadText}>
//                 <span>Reward Points</span>
//               </td>
//               <td className={classes.theadText}>
//                 <span>Redeem Date</span>
//               </td>
//             </tr>
//           </thead>
//           {props.isFetching ? (
//             <div
//               style={{
//                 display: "flex",
//                 justifyContent: "center",
//               }}
//             >
//               <CircularProgress />
//             </div>
//           ) : (
//             <tbody>
//               {props.rows?.data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                 .map((item, index) => (
//                 // item.reward?.map((item1) => (

//               // {props?.rows?.data?.map((item, index) =>
//               //   item.reward?.map((item1) => (
//                   <tr className={classes.tableRow}>
//                     <td className={classes.tbodyText}>
//                       <span> {item?.firstname}</span>
//                     </td>

//                     <td className={classes.tbodyText}>
//                       <span>{item?.lastname}</span>
//                     </td>
//                     <td className={classes.tbodyText}>
//                       <span>{item?.phoneno}</span>
//                     </td>
//                     <td className={classes.tbodyText}>
//                       <span>{item?.organization}</span>
//                     </td>
//                     <td className={classes.tbodyText}>
//                       <span>{item?.rewardtype}</span>
//                     </td>
//                     <td className={classes.tbodyText}>
//                       <span>{item?.title}</span>
//                     </td>
//                     <td className={classes.tbodyText}>
//                       <span>{item?.subtitle}</span>
//                     </td>
//                     <td className={classes.tbodyText}>
//                       <span>{item?.points}</span>
//                     </td>
//                     <td className={classes.tbodyText}>
//                       <span>{item?.redeemdate}</span>
//                     </td>
//                   </tr>
//                 // )
//                 )
//               )}
//             </tbody>
//           )}
//         </Table>
//       ) : (
//         <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
//           No data found
//         </Typography>
//       )}
//       <TableFooter>
//         <TablePagination
//           rowsPerPageOptions={[5, 10, 20]}
//           component="div"
//           count={props?.rows?.data?.length}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//       </TableFooter>
//     </TableContainer>
//   );
// };


export const PFRewardsReportTableComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const navigate = useNavigate();
  return (
    <div
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "50vw",
        backgroundColor: "inherit",
      }}
    >
      {!_.isEmpty(props?.rows) ? (
        <Table className={classes.table} aria-label="customized table">
          <thead>
            {/* <StyledTableCell> */}

            <tr className={classes.thead}>
              <td className={classes.theadText}>
                <span>Camp Name</span>
              </td>
              <td className={classes.theadText}>
                <span>Camp Status</span>
              </td>
              <td className={classes.theadText}>
                <span>Total Campaign Budget</span>
              </td>
              <td className={classes.theadText}>
                <span>Platform Fees</span>
              </td>
              <td className={classes.theadText}>
                <span>Campaign Expense Fees</span>
              </td>
              <td className={classes.theadText}>
                <span> Total Reward Budget</span>
              </td>
              <td className={classes.theadText}>
                <span> Platform Reward Budget</span>
              </td>
              <td className={classes.theadText}>
                <span> Campaign Marketplace Budget</span>
              </td>
              {/* <td className={classes.theadText}>
                <span> Campaign Reward Budget unused</span>
              </td> */}
              {/* <td className={classes.theadText}>
                <span> Volunteers Participated</span>
              </td>
              <td className={classes.theadText}>
                <span> Campaign Remaining Days</span>
              </td> */}
            </tr>
            {/* </StyledTableCell> */}
          </thead>

          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <tbody>
              {/* {activityList.map((item, index) => ( */}
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  // {activityList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  // .map((item, index) => (
                    

                  <tr className={classes.tableRow}>
                    {/* {JSON.stringify(`/user/report/${localStorage.getItem("userid")}/${item?.phoneno}`)} */}
                    <td className={classes.tbodyText}                    
                          onClick={() =>
                            //alert("CLICKED")
                            
                            navigate(`/camp/detail/report/${localStorage.getItem("userid")}/${item?.campid}`)
                          }
                          style={{ textAlign: "center",  cursor:"pointer",  color:"blue" }}
                          >
                      <span>{item?.title}</span>
                    </td>
                    <td className={classes.participate}>
                      <span>{item?.status}</span>
                    </td>
                    <td className={classes.participate}>
                      <span>{item?.totalcampbudget}</span>
                    </td>
                    <td className={classes.participate}>
                      <span>{item?.platformfees}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.campaignexpensefees}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.rewardbudget}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item?.platformreward}</span>
                    </td>
                    <td className={classes.tbodyText}>
                    <span>{item?.campaignmarketplacereward}</span>
                  </td>
                  {/* <td className={classes.tbodyText}>
                    <span>{item?.campaignunusedreward}</span>
                  </td> */}
                    {/* <td className={classes.tbodyText}>
                      <span>{item?.volparticipated}</span>
                    </td>
                    <td className={classes.tbodyText}>
                      <span>{item?.daysrem}</span>
                    </td> */}
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[20, 40, 60]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableFooter>
    </div>
  );
};


export const SponsorBasedReportTableComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef(null);
  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <div
      ref={tableRef}
      component={Paper}
      style={{
        padding: 16,
        borderRadius: 8,
        // width: "max-content",
        borderRadius: 6,
        background: "#ffffff",
        marginTop: 16,
        minWidth: "50vw",
        backgroundColor: "inherit",
      }}
    >
      {!_.isEmpty(props?.rows) ? (
        <Table className={classes.table} aria-label="customized table">
          <thead>
            {/* <StyledTableCell> */}

            <tr className={classes.thead}>
              <td className={classes.theadText}>
                <span>Sponsor Name</span>
              </td>
              <td className={classes.theadText}>
                <span>Camp Name</span>
              </td>
              <td className={classes.theadText}>
                <span>Campaign Budget</span>
              </td>
              <td className={classes.theadText}>
                <span>Sponsor's Total Budget</span>
              </td>
              <td className={classes.theadText}>
                <span>Sponsor Budget for Campaign</span>
              </td>
              
            </tr>
            {/* </StyledTableCell> */}
          </thead>

          {props.isFetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <tbody>
              {/* {activityList.map((item, index) => ( */}
              {props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  // {activityList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  // .map((item, index) => (
                    

                  <tr className={classes.tableRow}>
                    {/* {JSON.stringify(`/user/report/${localStorage.getItem("userid")}/${item?.phoneno}`)} */}
                    <td className={classes.tbodyText}                    
                          // onClick={() =>
                          //   //alert("CLICKED")
                            
                          //   navigate(`/camp/detail/report/${localStorage.getItem("userid")}/${item?.campid}`)
                          // }
                          style={{ textAlign: "center",  cursor:"pointer",  color:"blue" }}
                          >
                      <span>{item?.sponsorname}</span>
                    </td>
                    <td className={classes.participate}>
                      <span>{item?.title}</span>
                    </td>
                    <td className={classes.participate}>
                      <span>{item?.camptotalbudget}</span>
                    </td>
                    <td className={classes.participate}>
                      <span>{item?.initialbudget}</span>
                    </td>
                    <td className={classes.referral}>
                      <span>{item?.sponsorbudget}</span>
                    </td>
                    
                  </tr>
                ))}
            </tbody>
          )}
        </Table>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
      <TableFooter>
        <TablePagination
          rowsPerPageOptions={[20, 40, 60]}
          component="div"
          count={props?.rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableFooter>
    </div>
  );
};

