/** @format */

const GET_REPORT_USER_IN_STATE_FETCHING_DATA =
  "GET_REPORT_USER_IN_STATE_FETCHING_DATA";
const GET_REPORT_USER_IN_STATE_FETCHING_DATA_FAILURE =
  "GET_REPORT_USER_IN_STATE_FETCHING_DATA_FAILURE";
const GET_REPORT_USER_IN_STATE_FETCHING_DATA_RESET =
  "GET_REPORT_USER_IN_STATE_FETCHING_DATA_RESET";
const GET_REPORT_USER_IN_STATE_FETCHING_DATA_SUCCESS =
  "GET_REPORT_USER_IN_STATE_FETCHING_DATA_SUCCESS";
const GET_REPORT_USER_IN_STATE_FETCHING_DATA_ATTEMPT =
  "GET_REPORT_USER_IN_STATE_FETCHING_DATA_ATTEMPT";
const getReportUserInCity = (payload) => ({
  type: GET_REPORT_USER_IN_STATE_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const getReportUserInCityReset = (payload) => ({
  type: GET_REPORT_USER_IN_STATE_FETCHING_DATA_RESET,
  payload: payload,
});

export {
  GET_REPORT_USER_IN_STATE_FETCHING_DATA,
  GET_REPORT_USER_IN_STATE_FETCHING_DATA_FAILURE,
  GET_REPORT_USER_IN_STATE_FETCHING_DATA_RESET,
  GET_REPORT_USER_IN_STATE_FETCHING_DATA_SUCCESS,
  GET_REPORT_USER_IN_STATE_FETCHING_DATA_ATTEMPT,
  getReportUserInCity,
  getReportUserInCityReset,
};

// in activity

const GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA =
  "GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA";
const GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_FAILURE =
  "GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_FAILURE";
const GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_RESET =
  "GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_RESET";
const GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_SUCCESS =
  "GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_SUCCESS";
const GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_ATTEMPT =
  "GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_ATTEMPT";
const getReportUserInActivity = (payload) => ({
  type: GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const getReportUserInActivityReset = (payload) => ({
  type: GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_RESET,
  payload: payload,
});

export {
  GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA,
  GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_FAILURE,
  GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_RESET,
  GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_SUCCESS,
  GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_ATTEMPT,
  getReportUserInActivity,
  getReportUserInActivityReset,
};

// in SO Vol by State

const GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA =
  "GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA";
const GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_FAILURE =
  "GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_FAILURE";
const GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_RESET =
  "GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_RESET";
const GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_SUCCESS =
  "GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_SUCCESS";
const GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_ATTEMPT =
  "GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_ATTEMPT";
const getReportVolInOrg = (payload) => ({
  type: GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const getReportVolInOrgReset = (payload) => ({
  type: GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_RESET,
  payload: payload,
});

export {
  GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA,
  GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_FAILURE,
  GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_RESET,
  GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_SUCCESS,
  GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_ATTEMPT,
  getReportVolInOrg,
  getReportVolInOrgReset,
};
