/** @format */

// reducers
import { USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA, USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_FAILURE, USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_RESET, USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_SUCCESS } from '../action/ActionUserOnboardReview';

let initialState = {
	response: {},
	isSuccess: false,
	isError: false,
	isFetching: false,
};

export default function ActionUserOnboardReviewReducer(state = initialState, action) {
	switch (action.type) {
		case USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA:
			return {
				...state,
				isFetching: true,
				isSuccess: false,
				isError: false,
			};
		case USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_FAILURE:
			return {
				...state,
				isFetching: false,
				isSuccess: false,
				isError: action.payload,
			};
		case USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_SUCCESS:
			return {
				...state,
				isFetching: false,
				isError: false,
				isSuccess: true,
				response: action.payload,
			};

		case USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_RESET:
			return initialState;

		default:
			return state;
	}
}
