/** @format */

// reducers
import {
    ADD_CAMP_RECOGNITIONS_FETCHING_DATA,
    ADD_CAMP_RECOGNITIONS_FETCHING_DATA_FAILURE,
    ADD_CAMP_RECOGNITIONS_FETCHING_DATA_RESET,
    ADD_CAMP_RECOGNITIONS_FETCHING_DATA_SUCCESS,
    
  } from "../action/addCampRecognitionsAction";
  
  let initialState = {
    addcamprecognitionsResponse: {},
    addcamprecognitionsSuccess: false,
    addcamprecognitionsError: false,
    addcamprecognitionsFetching: false,
  };
  
  export default function addCampRecognitionsReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_CAMP_RECOGNITIONS_FETCHING_DATA:
            return {
            ...state,
            addcamprecognitionsFetching: true,
            addcamprecognitionsError: false,
            addcamprecognitionsSuccess: false,
        };
        case ADD_CAMP_RECOGNITIONS_FETCHING_DATA_FAILURE:
            return {
            ...state,
            addcamprecognitionsFetching: false,
            addcamprecognitionsError: action.payload,
            addcamprecognitionsSuccess: false,
        };
        case ADD_CAMP_RECOGNITIONS_FETCHING_DATA_SUCCESS:
            return {
            ...state,
            addcamprecognitionsFetching: false,
            addcamprecognitionsError: false,
            addcamprecognitionsResponse: action.payload,
            addcamprecognitionsSuccess: true,
        };
        case ADD_CAMP_RECOGNITIONS_FETCHING_DATA_RESET:
            return {
            ...state,
            addcamprecognitionsFetching: true,
            addcamprecognitionsError: false,
            addcamprecognitionsSuccess: false,
        };
      
      default:
        return state;
    }
  }
  