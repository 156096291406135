import React, { useState, useEffect } from 'react';
import { TextField, Grid, Button, MenuItem, Snackbar, InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { onboardKTCustomer, onboardKTCustomerReset } from '../../redux/action/OnboardAction';
import { useNavigate } from 'react-router-dom';
import ResponsiveDrawer from "../../components/Header";
import TopHeaderScreen from "../../components/TopHeader1";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  timeAgoCell: {
    verticalAlign: 'bottom',
  },
  searchIcon: {
    color: theme.palette.text.secondary,
  },
  evenRow: {
    backgroundColor: theme.palette.grey[100], // Set your desired background color for even rows
  },

  oddRow: {
    backgroundColor: theme.palette.background.default, // Set your desired background color for odd rows
  },
}));

const CustomerPopup = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [partyName, setPartyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [balanceAmount, setBalanceAmount] = useState('');
  const [balanceType, setBalanceType] = useState('You Gave');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const {
    isKTCustomerOnboardSuccess,
    ktCustomerOnboardResponse,
    isKTCustomerOnboardError,
    isKTCustomerOnboardFetching,
  } = useSelector((state) => state.onboardKTCustomer);

  const handleSave = () => {
    dispatch(onboardKTCustomerReset());
    let formData = new FormData();
    formData.append('name', partyName);
    formData.append('phoneno', phoneNumber);
    formData.append('balanceamount', balanceAmount);
    formData.append('type', balanceType);
    dispatch(onboardKTCustomer({ userid: localStorage.getItem('userid'), formData }));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (isKTCustomerOnboardSuccess) {
      setSnackbarMessage(ktCustomerOnboardResponse?.message);
      setSnackbarOpen(true);

      // Close the Snackbar after 3 seconds
      setTimeout(() => {
        setSnackbarOpen(false);
        resetForm(); // Reset the form fields
        dispatch(onboardKTCustomerReset());
        // navigate(`/kt/customer/list`);
        navigate(`/kt`);
      }, 3000);
    } else {
      setSnackbarOpen(false);
    }
  }, [isKTCustomerOnboardSuccess]);

  const resetForm = () => {
    setPartyName('');
    setPhoneNumber('');
    setBalanceAmount('');
    setBalanceType('You Gave');
  };

  const singleSelect1 = [
    { singleSelect1: 'You Gave' },
    { singleSelect1: 'You Got' },
  ];

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg hasScreen">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div style={{ padding: '16px', maxWidth: '400px', margin: 'auto' }}>
              <h3 style={{ textAlign: 'center', marginBottom: '20px' }}>Add a Customer</h3>
              <TextField
                label="Party Name"
                fullWidth
                value={partyName}
                onChange={(e) => setPartyName(e.target.value)}
                variant="outlined"
                style={{ marginBottom: '10px' }}
              />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label="Code"
                  value={"91"}

                  variant="outlined"
                  style={{ marginBottom: '20px', marginRight: '10px', maxWidth: "53px", marginTop: "20px" }}
                  inputProps={{ pattern: '^[0-9]*$' }}
                />

                <TextField
                  label="Phone Number"
                  // fullWidth
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  variant="outlined"
                  style={{ marginBottom: '20px', marginTop: "20px", minWidth:"247px" }}
                  inputProps={{ pattern: '^[0-9]*$' }}
                />
              </div>
              <Grid container spacing={2} style={{ marginBottom: '10px', marginTop: '0px' }}>
                <Grid item xs={6}>
                  <TextField
                    label="Balance Amount"
                    fullWidth
                    value={balanceAmount}
                    onChange={(e) => setBalanceAmount(e.target.value)}
                    variant="outlined"
                    style={{ borderRadius: '8px', }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                    }}
                    inputProps={{ pattern: '^\\d+(\\.\\d{1,2})?$' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    select
                    label="Type"
                    fullWidth
                    value={balanceType}
                    onChange={(e) => setBalanceType(e.target.value)}
                    variant="outlined"
                  >
                    {singleSelect1.map((item, index) => (
                      <MenuItem key={index} style={{ fontSize: 16, overflow: 'auto' }} value={item.singleSelect1}>
                        {item.singleSelect1}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant="contained" color="primary" onClick={handleSave} style={{ borderRadius: '8px', marginTop: '20px' }} disabled={snackbarOpen}>
                  Create
                </Button>
              </div>
              <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <div style={{ background: 'green', padding: '16px', borderRadius: '8px', fontSize: '18px', textAlign: 'center' }}>
                  {snackbarMessage}
                </div>
              </Snackbar>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CustomerPopup;
