import React from "react";
import { useSelector } from "react-redux";

export const CopyRightMarketplace = (props) => {
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  return (
    <footer>
      <span>
        ©{new Date().getFullYear()} {profileResponse?.copyright}
      </span>
    </footer>
  );
};
