// import * as React, {useRef} from "react";
import React, { useEffect, useRef, useState } from "react";
import FormControl from "@mui/material/FormControl";
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TableContainer,
  Paper,
  Table,
  Typography,
  TableRow,
  withStyles,
  TableCell,
  TableFooter,
} from "@material-ui/core";
import {
  Button,
  Skeleton,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";

// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// import {
//   onboardActivityReset,
//   onboardBulkReset,
//   onboardCampaign,
//   onboardCampaignReset,
//   onboardIndividualReset,
//   onboardOrganization,
//   onboardOrganizationReset,
// } from "../redux/action/OnboardAction";

import ResponsiveDrawer from "./Header";
import { CopyRight } from "./CopyRight";
import moment from "moment";
import axios from "axios";

import _ from "lodash";
import RedStar from "./RedStar";
import TopHeaderScreen from "./TopHeader1";
import { Link } from "react-router-dom";
// import { useDispatch } from "react-redux";
import { EditOutlined, RemoveRedEyeOutlined } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import jsPDF from "jspdf";
import { SponsorAdminDashboardTableComponent } from "./Table";
import AsyncSelect from "react-select/async";
import { CSVLink, CSVDownload } from "react-csv";
import { FixedFooterIcon } from "../components/FixedFooterIcon";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";


const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },

    display: "block",
    display: "flex",
    //fontSize: 16,
    fontSize: "16px",
    marginTop: 16,
    padding: 10,
    fontWeight: "500",
    lineHeight: "18px",
    minHeight: 50,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {},

    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "50%",
    },
  },
  formGroupFull: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  table: {
    width: "100%",
    borderRadius: "10px",
    borderStyle: "solid",
    borderWidth: "1.5px",
    // tableLayout: "fixed",
  },
  tableRow: {
    borderBottom: "1px solid #d3d3d3",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  thead: {
    borderBottom: "1px solid #d3d3d3",
    borderTop: "1px solid #d3d3d3",
    height: 60,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.getContrastText(theme.palette.secondary.dark),
  },
  theadText: {
    fontWeight: "700",
    textAlign: "center",
    fontSize: 16,
    minWidth: 100,
  },
  tbodyText: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width: "200px",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // wordWrap: "breakWord",
    // overflow: "scroll",
  },
  participate: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width: "200px",
    fontWeight: "bold",
    // backgroundColor: theme.palette.info.light,
    color: theme.palette.info.light,
  },
  referral: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width: "200px",
    fontWeight: "bold",
    // backgroundColor: theme.palette.info.light,
    color: theme.palette.info.dark,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "#000",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid green !important",
    // '&:nth-of-type(odd)': {
    // 	backgroundColor: '#f7dbb3',
    // },
    // '&:nth-of-type(even)': {
    // 	backgroundColor: '#e9e7e8',
    // },
    "&:last-child": {
      borderBottom: "none",
    },
  },
}))(TableRow);

function SponsorAdminDashboard(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedcampname, setselectedcampname] = React.useState([]);
  const [campSubmissionlist, setCampSubmissionlist] = React.useState([]);
  const [investedBudgetList, setInvestedBudgetList] = React.useState([]);

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  

  const downloadDocument = () => {
   
    const pdf = new jsPDF("l", "pt", "a3");
    // const pdf = new jsPDF({
    //   orientation: "landscape",
    //   unit: "pt",
    //   format: [4, 2]
    // });
    pdf.html(document.querySelector("#SOADashboardScreen"), {
      callback: function (pdf) {
        var pageCount = pdf.internal.getNumberOfPages();
        // pdf.deletePage(pageCount);

        pdf.save("cyaag.pdf");
      },
    });
  };

  const getSponsorAdminDashboard = async () => {

    try {
      // const res = ""
      //   if ((selectedrewardtype.length === 0) && !(selectedpointsrange.length === 0)) {
  
      const res = await axios
        .get(
          `/sponsor/dashboard/${profileResponse?.phoneno}`
        )
        .then((response) => {
          setCampSubmissionlist(response?.data);
          return response;
        });
      //   }
    } catch (err) {
      throw err;
    }
  };

  const getInvestedBudgetForSponsorAdminDashboard = async () => {
    
    // console.log(selectedpointsrange.length === 0);
    try {
      
      const res = await axios
        .get(
          `/sponsor/dashboard/investedbudget/${profileResponse?.phoneno}`
        )
        .then((response) => {
          setInvestedBudgetList(response?.data);
          return response;
        });
      //   }
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getSponsorAdminDashboard();
    getInvestedBudgetForSponsorAdminDashboard();
  }, []);

  const sdk1 = new ChartsEmbedSDK ( {
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",

    filter: {
      // "clientid": { "$eq": "c00001" }
      // "orgrole": { "$eq": "c00001" },
      "sponsor.sponsorname.sponsorid_lookup_sponsor.organization": { "$eq": profileResponse?.organization }
    }
        
  });
 
  const SOA_chart1 = sdk1.createChart({chartId: "d3678010-292f-4b64-923e-acb545755aec", showAttribution: false});
  SOA_chart1.render(document.getElementById("SOA_chart1"));


  const sdk2 = new ChartsEmbedSDK ( {
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",

    filter: {
      // "clientid": { "$eq": "c00001" }
      // "orgrole": { "$eq": "c00001" },
      "sponsor.sponsorname.sponsorid_lookup_sponsor.organization": { "$eq": profileResponse?.organization }
    }
        
  });
  const SOA_chart2 = sdk2.createChart({chartId: "dd3779d8-ee7d-47e5-a22f-d4f7d2a36147", showAttribution: false});
  SOA_chart2.render(document.getElementById("SOA_chart2"));

  return (
    <div className="wrapper">
      {/* {
        // profileSuccess &&


        (profileResponse?.orgrole?.toLowerCase() === "hyphenadmin" ||
          profileResponse?.orgrole?.toLowerCase() === "admin" ? (
          <ResponsiveDrawer {...props} />
        ) : (
          <CustomDrawer menuItems={volunteerMenuItems} {...props} />
        ))} */}

      <ResponsiveDrawer {...props} />

      {/* <ResponsiveDrawer {...props} /> */}
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap"  id = "pdf">
          <div class="content-mrg">
          <Button
                onClick={() => navigate(-1)}
                className={classes.backBtn}
            >
                <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <br></br>
            <br></br>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Dashboard
            </div>
            <div>
              <Button className="btn download-btn" variant="contained" onClick={downloadDocument}>Download</Button>
            </div>
            <header id="SOADashboardScreen">
            <Box sx={{ minWidth: 200 }}>            
            
              <div id="SOA_chart1" class="column chartMongo"> </div>
              <div id="SOA_chart2" class="column chartMongo"> </div>
           

              
            </Box>
            </header>

            <header id="SOADashboardScreen1" style={{position:"absolute",zIndex:"-1",marginTop:"50px"}}>
            <Box sx={{ minWidth: 200 }}>
              {/* <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolInCampOverTimeChartComponent
                    response={volovertimeincampList}
                  />
                </div>
              </div> */}
              {/* <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolOverTimeChartComponent1 response={volovertimeList} />
                </div>
              </div>
              <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolMaleVsFemaleOrgChartComponent1
                    response={volgenderinorgList}
                  />
                </div>
              </div>
              <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolHoursSpentCampChartComponent1
                    response={volhourspentincampList}
                  />
                </div>
              </div>
              <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolEmpInOrgChartComponent1 response={volempinorgList} />
                </div>
              </div> */}
              {/* <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolImpactDivComponent response={volimpactinorgList} />
                </div>
              </div> */}
              {/* <div class="iframeContentWrapper">
                <div class="innerSpace"></div>
              </div>
              <div class="iframeContentWrapper">
                <div class="innerSpace"></div>
              </div> */}
            </Box>
            </header>


          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
      {/* <PDF rootElementId="pdf" downloadFileName="testpdf" /> */}
    </div>
  );
}
export default SponsorAdminDashboard;
