import * as React from "react";
import FormControl from "@mui/material/FormControl";
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import {
  Avatar,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from 'multiselect-react-dropdown';

// import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {
  focusAreaList,
  focusAreaDict,
  registeredAs
} from "../../utils/dict";

import {
  onboardActivityReset,
  onboardBulkReset,
  onboardCampaign,
  onboardCampaignReset,
  onboardIndividualReset,
  onboardOrganization,
  onboardOrganizationReset,
} from "../../redux/action/OnboardAction";
import {
  updateCampaign,
  updateCampaignReset,
} from "../../redux/action/UpdateAction";

import CallingCode from "../../services/CallingCode";
import { fetchCityAndState } from "../../services/commonServices";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";
import { API_URL } from "../../services/Constants";
import { getOrganization } from "../../redux/action/getOrganization";
import {
  activityDuration,
  campaignParticipate,
  campaignType,
  restrictedCountry,
} from "../../utils/dict";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AddCircleOutlineOutlined, RemoveCircleOutlineOutlined } from "@mui/icons-material";
import { getCity } from "../../redux/action/cityAction";
import { MenuProps, states } from "../../utils/constants";
import ErrorMessage from "../../components/ErrorMessage";
import _ from "lodash";
import RedStar from "../../components/RedStar";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link } from "react-router-dom";
import { getCampProfileLogo } from "../../redux/action/campLogoAction";
import IconButton from "@mui/material/IconButton";
import { getCampProfileBanner } from "../../redux/action/campBannerAction";
import ResumeConfirmation from "../../components/ResumeConfirmation";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    // boxShadow: "0px 3px 6px #00000029",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {

    },

    display: "block",
    display: "flex",
    //fontSize: 16,
    fontSize: "16px",
    marginTop: 16,
    padding: 10,
    fontWeight: "500",
    lineHeight: "18px",
    minHeight: 50,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {

    },

    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroupFull: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%"
    },
    [theme.breakpoints.only("md")]: {
      width: "100%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
}));

function ResumeCampaignScreen(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [image, setImage] = React.useState(null);
  const [banner, setBanner] = React.useState(null);
  const inputRef = React.useRef(null);
  const videoRef = React.useRef(null);
  const navigate = useNavigate();
  const [newField, setNewField] = React.useState(false);

  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "" },
  ]);

  const [focusArea, setFocusArea] = React.useState([
    {
      rewards: "Certificate of Participation",
    },
    {
      rewards: "Discount Coupons",
    },
    { rewards: "Gift Cards" },
    { rewards: "Social Media Recognition" },
  ]);

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  // const skillArea= [
  //   { skill: "Networking" },
  //   { skill: "Fundraising" },
  //   { skill: "Operational Support" },
  //   { skill: "Content Writing / Editing" },
  //   { skill: "Social Media and Digital marketing" },
  //   { skill: "Team Management" },
  //   { skill: "Painting / Drawing / Sculpture making" },
  //   { skill: "Photography" },
  //   { skill: "Video Editing" },
  //   { skill: "Coaching / Mentoring" },
  //   { skill: "pp Development / Coding" },

  // ];

  const [skill, setSkill] = React.useState([
    { skill: "Networking", },
    { skill: "Fundraising", },
    { skill: "Operational Support" },
    { skill: "Content Writing / Editing" },
    { skill: "Social Media and Digital marketing" },
    { skill: "Team Management" },
    { skill: "Painting / Drawing / Sculpture making" },
    { skill: "Photography" },
    { skill: "Video Editing" },
    { skill: "Coaching / Mentoring" },
    { skill: "pp Development / Coding" },

  ]);
  const [focusError, setFocusError] = React.useState(false);

  const [areas, setAreas] = React.useState([]);
  const [cityResponse, setCityResponse] = React.useState({});
  const [notificationShow, setNotificationShow] = React.useState(false);
  const [activityList, setActivityList] = React.useState(undefined);
  const [campaignValues, setCampaignValues] = React.useState({
    titleOfCampaign: "",
    descriptionOfCampaign: "",
    typeOfCampaign: "",
    volunteerCount: "",
    // impact: "",
    startDate: new Date(),
    endDate: new Date(),
    durationOfCampaign: "",
    rewardType: "",
    goal: "",
    campaignType: "",
    hourOfParticipation: "",
    socialMediaMessage: "",
    campaignLogo: "",
    campaignVideo: "",
    visibility: "",
  });
  const [campaignValuesError, setCampaignValuesError] = React.useState({
    titleOfCampaignError: "",
    descriptionOfCampaignError: "",
    typeOfCampaignError: "",
    volunteerCountError: "",
    impactError: "",
    startDate: new Date(),
    endDate: new Date(),
    durationOfCampaignError: "",
    rewardTypeError: "",
    goalError: "",
    campaignTypeError: "",
    hourOfParticipationError: "",
    socialMediaMessageError: "",
    campaignLogoError: "",
    campaignVideoError: "",
    rewardTypeError: "",
    visibilityError: "",
  });

  const [selected, setSelected] = React.useState([]);
  const [selected1, setSelected1] = React.useState([]);
  const [skillselected, setSkillSelected] = React.useState([]);
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
  // const {
  //   isCampaignSuccess,
  //   isCampaignFetching,
  //   campaignResponse,
  //   isCampaignError,
  // } = useSelector((state) => state.onBoard);
  const {
    isCampaignSuccess,
    isCampaignFetching,
    campaignResponse,
    isCampaignError,
  } = useSelector((state) => state.updateCampaign);

  const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
    (state) => state.cities
  );

  function subtractYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);

    return date;
  }

  /* For profile image handling */

  const [loading, setLoading] = React.useState(false);
  const [loadingBanner, setLoadingBanner] = React.useState(false);
  // const [image, setImage] = React.useState(null);
  const [show, setShow] = React.useState(false);

  const {
    isSuccess: profileImageSuccess,
    response: profileImageResponse,
    isError: profileImageError,
  } = useSelector((state) => state.profileImage);

  const {
    isSuccess: campLogoSuccess,
    response: campLogoResponse,
    isError: campLogoError,
  } = useSelector((state) => state.getCampProfileLogo);

  const {
    isSuccess: campBannerSuccess,
    response: campBannerResponse,
    isError: campBannerError,
  } = useSelector((state) => state.getCampProfileBanner);

  const handleChangeFile = (e) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("logo", e.target.files[0]);
    dispatch(
      getCampProfileLogo({ campid: locationPath?.state?.campid, userId: localStorage.getItem("userid"), formData })

    );

    setImage(e.target.files[0]);
  };
  // const handleBannerFile = (e) => {
  //   setLoadingBanner(true);
  //   let formData = new FormData();
  //   formData.append("banner", e.target.files[0]);

  //   dispatch(
  //     getCampProfileBanner({ campid: locationPath?.state?.campid, userId: localStorage.getItem("userid"), formData })
  //   );
  //   setBanner(e.target.files[0]);
  // };

  const handleChangeRewards = (event, index) => {
    const value = event.target.value;

    setSelected([...selected, value[value?.length - 1]]);
  };

  const handleChangeSkill = (event, index) => {
    const value = event.target.value;

    setSelected1([...selected1, value[value?.length - 1]]);
  };

  const handleOnboardCampaign = () => {
    // if (!campaignValues?.titleOfCampaign) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     titleOfCampaignError: true,
    //   });
    //   window.scrollTo(0, 0);

    // } else if (!campaignValues?.descriptionOfCampaign) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     descriptionOfCampaignError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else if (!campaignValues?.goal) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     goalError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else if (!campaignValues?.typeOfCampaign) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     typeOfCampaignError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } 
    // else if (!campaignValues?.campaignType) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     campaignTypeError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else if (!campaignValues?.visibility) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     campaignParticipateError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else if (!campaignValues?.volunteerCount) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     volunteerCountError: true,
    //   });
    //   window.scrollTo(0, 0);
    // }  else if (!campaignValues?.hourOfParticipation) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     hourOfParticipationError: true,
    //   });
    //   window.scrollTo(0, 0);
    // }
    // else if (selected.filter((item) => item).length === 0) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     rewardTypeError: true,
    //   });
    //   window.scrollTo(0, 0);
    // }  

    // else if (selected1.filter((item) => item).length === 0) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     setSkillError: true,
    //   });
    //   window.scrollTo(0, 0);
    // }
    // else if (!campaignValues?.socialMediaMessage) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     socialMediaMessageError: true,
    //   });
    //   window.scrollTo(0, 0);
    // }  else if (!campaignValues?.campaignLogo) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     campaignLogoError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else {
    // dispatch(updateCampaignReset());
    let formData = new FormData();
    // console.log(locationPath)
    // console.log(campaignValues)

    formData.append("type", campaignValues?.typeOfCampaign);
    formData.append("title", campaignValues?.titleOfCampaign);
    formData.append("desc", campaignValues?.descriptionOfCampaign);
    formData.append("location", JSON.stringify(location));
    formData.append("hourofparticipation", campaignValues?.hourOfParticipation);
    formData.append("campaigntype", campaignValues?.campaignType);
    formData.append("visibility", campaignValues?.visibility);
    formData.append("goal", campaignValues?.goal);
    // formData.append("rewardstobeearned", JSON.stringify(selectedRewards));
    // formData.append("skill", JSON.stringify(selected1));
    formData.append("skill", JSON.stringify(selectedSkills))
    // console.log(selectedRewards)
    // if (!campaignValues?.campaignLogo) {
    //   formData.append("logo", locationPath?.state?.logo);

    // } else {
    //   formData.append("logo", campaignValues?.campaignLogo);
    // }
    // if (!campaignValues?.campaignVideo) {
    //   formData.append("logo", locationPath?.state?.video);
    // } else {
    //   formData.append("video", campaignValues?.campaignVideo);

    // }
    //formData.append("impact", campaignValues?.impact);
    formData.append("targetvol", campaignValues?.volunteerCount);
    // formData.append("startdate", moment(campaignValues?.startDate, "YYYY-MM-DD") + " 00:00:00");
    formData.append("startdate", campaignValues?.startDate + " 00:00:00");
    formData.append("enddate", campaignValues?.endDate + " 00:00:00");

    // formData.append("enddate", moment(campaignValues?.endDate, "YYYY-MM-DD") + " 00:00:00");
    //formData.append("timeframe", campaignValues?.durationOfCampaign);
    formData.append("socialmediamsg", campaignValues?.socialMediaMessage);
    dispatch(
      updateCampaign({ campid: campaignValues?.campid, formData })
      // onboardCampaign({ userid: localStorage.getItem("userid"), formData })
    );
    // console.log(campaignValues)
    //  }
  };

  

  const handleCampaignFieldChange = (field, e) => {
    (isCampaignError || isCampaignSuccess) && dispatch(onboardCampaignReset());
    setCampaignValues({
      ...campaignValues,
      [field]:
        field === "startDate" || field === "endDate"
          ? e
          : field === "campaignLogo" || field === "campaignVideo"
            // : field === "campaignLogo"
            ? e.target.files[0]
            : e.target.value,
    });
  };
  // const getActivityList = async () => {
  //   try {
  //     const res = await axios.get(`/activity`);
  //     setActivityList(res?.data);
  //     return res;
  //   } catch (err) {
  //     throw err;
  //   }
  // };

  const getStateList = async (i,valll) => {
    try {
     const res = await axios.get(`/statecity/${valll}`).then((response)=>{
     const values = [...location];
     values[i]["cityList"] = response?.data;
      return response?.data;
    });
    
    } catch (err) {
      throw err;
    }
  };

  // const unitOfCampaign = activityList?.filter(
  //   (item, index) => item?.activity === campaignValues?.typeOfCampaign
  // );

  
  const onAddingItem = (event, option, index) => {
    const values = [...focusArea];
    values[index].isChecked = event.target.checked;
    setFocusArea(values);
  };

  const handleTextFieldCheckbox = (event, index) => {
    const values = [...focusArea];
    values[index].rewards = event.target.value;
    setFocusArea(values);
  };

  const handleChangeLocation = (id, e, field, index) => {
    const values = [...location];
    if(field === 'state') {
    values[index]["cityList"] = cityResponse;
    }
    
    values[index][field] = e.target.value;
    setLocation(values);
  };

  const handleAddFields = () => {
    setNewField(true);
    setLocation([...location, { country: "", state: "", city: "", area: "" }]);
  };
  const handleRemoveFields = (i) => {
    let newFormValues = [...location];
    newFormValues.splice(i, 1);
    setLocation(newFormValues);
  };
  React.useEffect(() => {
    setLocation([{ country: "", state: "", city: "", area: "", cityList: "" }]);
  }, []);

  // React.useEffect(() => {
  //   if (campLogoSuccess || campLogoError) {
  //     setLoading(false);
  //   }
  // }, [campLogoSuccess, campLogoError]);

  // React.useEffect(() => {
  //   if (campBannerSuccess || campBannerError) {
  //     setLoadingBanner(false);
  //   }
  // }, [campBannerSuccess, campBannerError]);

  const getAddress = (item, index) => {
    return (
      <div style={{ float: "left", width: "75%", marginTop: 50 }}>
        <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>Operational Areas</span>
        <form style={{ display: "flex", flexWrap: "wrap", width: "100%", float: "left" }}>
          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Country
            </label>
            <input
                disabled
                value={item.country}
                type="text"
                className={classes.input}
              />
            {/* <Select
              disabled
              style={{
                width: "100%",
                fontSize: 14,
                height: 56,
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              }}
              value="India"
              onChange={(e) => handleChangeLocation(item, e, "country", index)}
            >
              {restrictedCountry.map((item, index) => (
                <MenuItem style={{ fontSize: 16 }} value={item.name} disabled>
                  {item.name}
                </MenuItem>
              ))}
            </Select> */}
          </div>

          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              State
            </label>
            {/* {campaignValues?.campaignType === "Virtual" ? ( */}

              <input
                disabled
                value={item.state}
                type="text"
                className={classes.input}
              />
            {/* ) : */}
              {/* <Select

                style={{
                  width: "100%",
                  fontSize: 14,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                value={item.state}
                onChange={(e) => {
                  handleChangeLocation(item, e, "state", index);
                  getStateList(index,item.state);
                  dispatch(getCity(item.state));
                }}
                
              >
                {states.map((item, index) => (
                  <MenuItem style={{ fontSize: 16 }} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select> */}
            {/* } */}
          </div>
          {/* {index !== location.length - 1 || (index === location.length - 1 && location[index].state !== "") || index === 0 ? ( */}
          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              City/Town/Village
            </label>
            
              <input
                disabled
                value={item.city}
                type="text"
                className={classes.input}
              />
             
          </div> 
          {/* ) :  */}
          {/* <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              City/Town/Village
            </label>
              <input
                disabled
                value={item.city}
                type="text"
                className={classes.input}
              />
              <Select
                style={{
                  width: "100%",
                  fontSize: 14,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  border: "none",
                }}
                value={item.city}
                
                onChange={(e) => handleChangeLocation(item, e, "city", index)}
              >
                {citiesSuccess &&
                citiesResponse.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            
          </div>  */}
          {/* } */}
          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Area
            </label>
            {/* {campaignValues?.campaignType === "Virtual" ? ( */}

              <input
                disabled
                value={item.area}
                type="text"
                className={classes.input}
              />
            {/* ) : */}
              {/* <input
                value={item.area}
                type="text"
                id="create-yaad--title"
                className={classes.input}
                placeholder="Your Answer"
                onChange={(e) => handleChangeLocation(item, e, "area", index)}
              /> */}
            {/* } */}
          </div>
        </form>
        {/* {
          index ?
            <div style={{ float: "right", position: "relative", bottom: 44, cursor: "pointer", left: 20 }} onClick={handleRemoveFields}><RemoveCircleOutlineOutlined></RemoveCircleOutlineOutlined></div>
            : <div className="handlePlus" style={{ float: "right", bottom: 45, position: "relative", left: 20, cursor: "pointer" }}><AddCircleOutlineOutlined
              onClick={handleAddFields}
            ></AddCircleOutlineOutlined></div>
        } */}
      </div>
    );
  };


  React.useEffect(() => {
    dispatch(getOrganization({ userid: localStorage.getItem("userid") }));
    // getActivityList();
  }, []);

  const locationPath = useLocation();
  const [selectedSkills, setSelectedSkills] = React.useState([]);
  const [selectedRewards, setSelectedRewards] = React.useState([]);


  React.useEffect(() => {
    // console.log(locationPath)
    if (locationPath?.state?.skill) {
      setSelectedSkills(locationPath?.state?.skill);
    }
    // if (locationPath?.state?.rewardstobeearned) {
    //   setSelectedRewards(locationPath?.state?.rewardstobeearned);
    // }
    if (locationPath?.state) {
      setCampaignValues({
        titleOfCampaign: locationPath?.state?.title,
        descriptionOfCampaign: locationPath?.state?.desc,
        typeOfCampaign: locationPath?.state?.type,
        volunteerCount: locationPath?.state?.targetvol,
        // impact: locationPath?.state?.impact,
        visibility: locationPath?.state.visibility,
        // startDate: moment(locationPath?.state?.startDate).format("YYYY-DD-MM"),
        // endDate: moment(locationPath?.state?.endDate).format("YYYY-DD-MM"),
        // startDate: moment(locationPath?.state?.startdate, "YYYY-MM-DD"),
        // endDate: moment(locationPath?.state?.enddate, "YYYY-MM-DD"),
        startDate: locationPath?.state?.startdate,
        endDate: locationPath?.state?.enddate,
        durationOfCampaign: locationPath?.state?.timeframe,
        goal: locationPath?.state?.goal,
        campaignType: locationPath?.state?.campaigntype,
        hourOfParticipation: locationPath?.state?.hourofparticipation,
        socialMediaMessage: locationPath?.state?.socialmediamsg,
        campaignLogo: locationPath?.state?.logo,
        // banner: locationPath?.state?.banner,
        // campaignLogo: campaignValues.campaignLogo,
        // campaignVideo: locationPath?.state?.video,
        // rewardType: locationPath?.state?.rewardstobeearned,
        campid: locationPath?.state?.campid,
        skill: locationPath?.state?.skill,
      });
      setLocation(locationPath?.state?.location);
      // setFocusArea(JSON.parse(locationPath?.state?.rewardstobeearned))
    }
  }, [locationPath?.state]);
  // console.log(locationPath?.state)
  // console.log(locationPath?.state)

 

  const handleResumeCampaign = async () => {
    // setDisplayConfirmationModal(true);
    const payload = {
      //campid: locationPath?.state?.campid,
      // createdate: locationPath?.state?.createdate,
      // reviewstatus: reviewstatus,
      // reviewcomments: reviewComments,
      phoneno: localStorage.getItem("userid")
      // phoneno: locationPath?.state.phoneno,

    };
    try {
      const res = await axios.put(`/campaign/restart/${campaignValues?.campid}`, payload);
      // navigate("/reviewcampaign");
      // setuserdeletestat(res?.data);
      setDisplayConfirmationModal(false);
      navigate("/reviewcampaign");
      return res;
    } catch (err) {
      throw err;
    }
  };

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
          <Button
                onClick={() => navigate(-1)}
                className={classes.backBtn}
            >
                <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            {/* <div style={{ fontFamily: "monospace", fontSize: 16, }}>
              <div style={{ display: "flex", width: "75%", justifyContent: "flex-end", marginRight: 5, marginBottom: 20, }}>
                <span style={{ color: "red" }}>Please do not leave this page until you submit as you may risk losing the partially filled in form </span>
              </div>
            </div> */}
            <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Resume Campaign</div>
            <div style={{
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              alignItems: "center",
            }}></div>
            <form autoComplete="off">
              <div style={{ border: "1px solid #dadce0", borderRadius: 8, backgroundColor: "#fff" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {/* {JSON.stringify(campaignValues)} */}
                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Name of Campaign (25 Chars)<RedStar />
                    </label>

                    <input
                      value={campaignValues?.titleOfCampaign}
                      type="text"
                      className={classes.input}
                      placeholder="Your answer"
                      maxLength="25"
                      disabled
                      onChange={(e) => {
                        handleCampaignFieldChange("titleOfCampaign", e);
                      }}
                    />
                    {/* {campaignValuesError?.titleOfCampaignError && (
                      <ErrorMessage message="Please enter the title of campaign" />
                    )} */}
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Description (200 Chars) <RedStar />
                    </label>
                    <textarea
                      value={campaignValues?.descriptionOfCampaign}
                      type="text"
                      id="create-yaad--title"
                      className={classes.input}
                      placeholder="Your answer"
                      maxLength="200"
                      disabled
                      // onChange={(e) => {
                      //   handleCampaignFieldChange("socialMediaMessage", e);
                      // }}
                    />
                    {/* <input
                      value={campaignValues?.descriptionOfCampaign}
                      type="text"
                      className={classes.input}
                      placeholder="Your answer"
                      maxLength="200"
                      disabled
                      onChange={(e) => {
                        handleCampaignFieldChange("descriptionOfCampaign", e);
                      }}
                    /> */}
                    {/* {campaignValuesError?.descriptionOfCampaignError && (
                      <ErrorMessage message="Please enter the description of campaign" />
                    )} */}
                  </div>
                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Goal <RedStar />
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        value={campaignValues?.goal}
                        type="text"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        disabled
                        onChange={(e) => {
                          handleCampaignFieldChange("goal", e);
                        }}
                      />
                    </div>
                    {/* {campaignValuesError?.goalError && (
                      <ErrorMessage message="Please enter the goal" />
                    )} */}
                  </div>
                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Category <RedStar />
                    </label>

                    {campaignValues?.typeOfCampaign != "" &&
                    campaignValues?.typeOfCampaign.map((type, index) => (
                      <span style={{color:"#0F0F0F",fontFamily:"inherit", padding:"5px"}}>{type.label} {((index == (campaignValues?.typeOfCampaign.length-1)) ? '' : ',')}</span>
                    ))}
                    {/* <input
                        value={campaignValues?.typeOfCampaign}
                        type="text"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        disabled
                        onChange={(e) => {
                          handleCampaignFieldChange("goal", e);
                        }}
                      /> */}
                    {/* <Select
                      style={{
                        width: "100%",
                        fontSize: 14,
                        height: 56,
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                      }}
                      value={campaignValues?.typeOfCampaign}
                      onChange={(e) => {
                        handleCampaignFieldChange("typeOfCampaign", e);
                      }}
                    >
                      {activityList?.map((item, index) => (
                        <MenuItem style={{ fontSize: 16 }} value={item.activity}>
                          {item.activity}
                        </MenuItem>
                      ))}
                    </Select> */}
                    {/* {campaignValuesError?.typeOfCampaignError && (
                      <ErrorMessage message="Please select the campaign category" />
                    )} */}
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Type <RedStar />
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        value={campaignValues.campaignType}
                        type="text"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        disabled
                        onChange={(e) => {
                          handleCampaignFieldChange("goal", e);
                        }}
                      />
                      {/* <Select
                        style={{
                          width: "100%",
                          fontSize: 14,
                          height: 56,
                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                        }}
                        value={campaignValues.campaignType}

                        onChange={(e) =>
                          handleCampaignFieldChange("campaignType", e)
                        }
                      >
                        {campaignType.map((item, index) => (
                          <MenuItem style={{ fontSize: 16 }} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select> */}
                    </div>
                    {/* {campaignValuesError?.campaignTypeError && (
                      <ErrorMessage message="Please select the campaign type" />
                    )} */}
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Participation <RedStar />
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        value={campaignValues.visibility}
                        type="text"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        disabled
                        onChange={(e) => {
                          handleCampaignFieldChange("goal", e);
                        }}
                      />
                      
                      {/* <Select
                        style={{
                          width: "100%",
                          fontSize: 14,
                          height: 56,
                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",

                        }}
                        value={campaignValues?.visibility}
                        onChange={(e) =>
                          handleCampaignFieldChange("visibility", e)
                        }
                      >
                        {campaignParticipate.map((item, index) => (
                          <MenuItem style={{ fontSize: 16 }} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select> */}
                    </div>
                    {/* {campaignValuesError?.visibilityError && (
                      <ErrorMessage message="Please select the campaign participate value" />
                    )} */}
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Number of volunteers Required <RedStar />
                    </label>

                    <input
                      value={campaignValues?.volunteerCount}
                      type="number"
                      id="create-yaad--title"
                      className={classes.input}
                      placeholder="Your answer"
                      disabled
                      onKeyPress={(e) => { preventMinus(e) }}
                      // onBlur={() => {
                      //   if (!campaignValues?.volunteerCount) {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       volunteerCountError: true,
                      //     });
                      //   } else {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       volunteerCountError: false,
                      //     });
                      //   }
                      // }}
                      onChange={(e) => {
                        handleCampaignFieldChange("volunteerCount", e);
                      }}
                    />
                    {/* {campaignValuesError?.volunteerCountError && (
                      <ErrorMessage message="Please enter the volunteer count" />
                    )} */}
                  </div>
                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Start Date <RedStar />
                    </label>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      {/* {JSON.stringify(campaignValues)} */}
                      <DatePicker
                        // minDate={new Date()}
                        inputFormat={"yyyy-MM-dd"}
                        // inputFormat={"YYYY-MM-DD"}
                        value={campaignValues?.startDate}
                        disabled
                        onChange={(newValue) => {
                          handleCampaignFieldChange("startDate", newValue);
                        }}
                        minDate={new Date()}
                        // maxDate={campaignValues.endDate}

                        renderInput={(params) => (
                          <TextField
                            style={{
                              width: "100%",
                              height: 56,
                              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      End Date <RedStar />
                    </label>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      {/* {JSON.stringify(campaignValues?.endDate)} */}
                      <DatePicker
                        // minDate={new Date()}
                        inputFormat={"yyyy-MM-dd"}
                        value={campaignValues.endDate}
                        disabled
                        onChange={(newValue) => {
                          handleCampaignFieldChange("endDate", newValue);
                        }}
                        // disabled={campaignValues.startDate=== "" ? true: false}
                        // minDate={new Date()}
                        minDate={campaignValues?.startDate}
                        //maxDate={}
                        renderInput={(params) => (
                          <TextField
                            style={{
                              width: "100%",
                              height: 56,
                              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>


                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      No. of Hours of Participation Required from a Volunteer <RedStar />
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        value={campaignValues?.hourOfParticipation}
                        type="number"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        min="0"
                        disabled
                        onKeyPress={(e) => { preventMinus(e) }}

                        onChange={(e) => {
                          handleCampaignFieldChange("hourOfParticipation", e);
                        }}
                      />
                    </div>
                    {/* {campaignValuesError?.hourOfParticipationError && (
                      <ErrorMessage message="Please enter the hours of participation" />
                    )} */}
                  </div>


                  {/* <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Rewards to be Earned <RedStar />
                    </label>
                    
                    {locationPath?.state?.rewardstobeearned.map((item, index) => (
                      <span style={{color:"#0F0F0F",fontFamily:"inherit", padding:"5px"}}> {(index ? ', ' : '')} {item.rewards} </span>
                      ))}
                    
                  </div> */}

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Skills/Tags <RedStar />
                    </label>
                    
                    {locationPath?.state?.skill.map((item, index) => (
                      <span style={{color:"#0F0F0F",fontFamily:"inherit", padding:"5px"}}> {item.skill}  {((index == (locationPath?.state?.skill.length-1)) ? '' : ',')} </span>
                      ))}                    
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Social Media Message (500 chars)<RedStar />
                    </label>
                    <textarea
                      value={campaignValues?.socialMediaMessage}
                      type="text"
                      id="create-yaad--title"
                      className={classes.input}
                      placeholder="Your answer"
                      maxLength="500"
                      disabled
                      onChange={(e) => {
                        handleCampaignFieldChange("socialMediaMessage", e);
                      }}
                    />
                    {/* {campaignValuesError?.socialMediaMessageError && (
                      <ErrorMessage message="Please enter the social media message" />
                    )} */}
                  </div>


                  
                  <div className={classes.formGroupFull}>
                    {location.map((item, index) => (
                      <div key={index}>{getAddress(item, index)}</div>
                    ))}
                    {/* <AddCircleOutlineOutlined
                  formStyle={{ paddingTop: "20px" }}
                  onClick={handleAddFields}
                ></AddCircleOutlineOutlined> */}
                  </div>
                  {/* {JSON.stringify(campaignResponse)} */}

                </div>
                <div className="centerAlign" style={{ padding: "16px 0", marginBottom: 56 }}>
                  <Button

                    variant="contained"
                    // onClick={() => handleSave()}
                    // onClick={() => handleStopCampaign()}
                    onClick={() => setDisplayConfirmationModal(true)}
                    className="btn save-pause-btn"
                    disableElevation
                    disabled={isCampaignFetching}
                  >

                    {/* {isCampaignFetching ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : ( */}
                      <span>Resume</span>
                     {/* )} */}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => navigate("/reviewcampaign")}
                    style={{
                      // marginTop: 1,
                      width: "150px",
                      height: "50px",
                      background: "white",
                      // borderRadius: 20,
                      // color: "#fff",
                      color: "red",
                      fontSize: 16,
                      // borderBlockColor: "orange",
                      // borderBlock: 5,
                      fontFamily: "Poppins !important",
                    }}
                  >
                    <span>Cancel</span>
                  </Button>
                  <br />
                  {/* {isCampaignSuccess && 
                  <span style={{ color: "green", textAlign: "center", display: "block" }}>{campaignResponse?.message}</span>}
                  {isCampaignError && (
                    <span style={{ color: "red", textAlign: "center", display: "block" }}>{campaignResponse?.message}</span>
                  )} */}

                </div>
                {/* {JSON.stringify(isCampaignFetching)} */}
                {/* {isCampaignFetching && 
                  <span className={classes.formGroupFull} style={{ color: "green", textAlign: "center", display: "block", }}>{campaignResponse?.message}</span>}
                  {!isCampaignFetching && (
                    <span className={classes.formGroupFull} style={{ color: "red", textAlign: "center", display: "block", }}>{campaignResponse?.message}</span>
                  )} */}
                {/* {isCampaignSuccess && campaignResponse?.status === "success" && */}
                {isCampaignSuccess &&
                  <span className={classes.formGroupFull} style={{ color: "green", textAlign: "center", display: "block", }}>{campaignResponse?.message}</span>}
                {/* {isCampaignSuccess && !campaignResponse?.status === "success"( */}
                {!isCampaignSuccess && (
                  <span className={classes.formGroupFull} style={{ color: "red", textAlign: "center", display: "block", }}>{campaignResponse?.message}</span>
                )}
              </div>
            </form>

          </div></div>
        <CopyRight />
        <ResumeConfirmation showModal={displayConfirmationModal} confirmModal={handleResumeCampaign} hideModal={hideConfirmationModal} message={"Are you sure you want to Resume this campaign?"}  />
      </section>


    </div>
  );
}
export default ResumeCampaignScreen;
