import React from "react";

function RedStar() {
  return (
    <div>
      <span style={{ color: "red" }}>*</span>
    </div>
  );
}

export default RedStar;
