import { makeStyles, Chip } from "@material-ui/core";
import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "../../components/Header";
import {
  AcitvityTableComponent,
  CampaignTableComponent,
  ReviewOrgainzationTableComponent,
  TableComponent,
  ReviewSponsorTableComponent,
} from "../../components/Table";
import { getVolunteerName } from "../../redux/action/getVolunteerName";
import { userProfile } from "../../redux/action/UserProfileActions";
import { API_URL } from "../../services/Constants";
import { getVolunteerCount } from "../../redux/action/volunteerCountAction";
import { getCampaign } from "../../redux/action/CampaignAction";
import TopHeaderScreen from "../../components/TopHeader";
import { CopyRight } from "../../components/CopyRight";
import { Link } from "react-router-dom";
import { referral } from "../../redux/action/ReferralAction";
import { Title } from "chart.js";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate } from "react-router-dom";
import EarnImage from "../../assets/c-earn-upto.png";
import RewardMainImage from "../../assets/rew.png";
import cYAAGlogo from "../../assets/logo-black.png";
import StreakLeft from "../../assets/streak-left.png";
import StreakRight from "../../assets/streak-right.png";
import Impact1 from "../../assets/impact1.png";
import Impact2 from "../../assets/impact2.png";
import Impact3 from "../../assets/impact3.png";
import BannerRight from "../../assets/banner-right.png";
import { isEmpty, isArray } from "lodash";
import HowcYAAGWorks from "../../assets/howcYAAGWorks.png";
import whoUsescYAAG from "../../assets/whoUsescYAAG.png";
import cYAAGImage from "../../assets/cYAAGImage.png";
import LandingPage1Img from "../../assets/landingpage1.jpg";
import LandingPage2Img from "../../assets/landingpage2.jpg";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    background: "#F5F8FB",
    minHeight: "100vh",
    width: "max-content",
    minWidth: "100vw",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 100px 330px",
    },
  },
  subtitleWrapper: {
    textAlign: "center",
  },
  pointsWrapper: {
    padding: 3,
    margin: 4,
    borderRadius: 4,
    textAlign: "center",
  },
  tagWrapper: {
    padding: 3,
    backgroundColor: "#FF5733",
    color: "white",
    margin: 4,
    borderRadius: 4,
    textAlign: "center",
  },
}));

const AboutusFromLanding = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    // <div className="wrapper">
    <div class="landingPageWrapper">
      {/* <ResponsiveDrawer {...props} /> */}
      {/* <section className="containers"> */}
      {/* <TopHeaderScreen {...props} /> */}
      {/* <div class="content content-wrap">
          <div class="content-mrg"> */}
      {/* <div class="landingPageWrapper"> */}
      {/* <Button onClick={() => navigate(-1)} className={classes.backBtn}>
                <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
              </Button> */}

      <div
        class="desktop-nav header navbar"
        style={{ marginBottom: "0px", padding: "0px 10px" }}
      >
        <div class="container">
          <div class="nav-logo">
            <a href="index.html" class="">
              {" "}
              <img src={cYAAGlogo} class="img-fluid" alt="logo" />
            </a>
          </div>
        </div>
      </div>
      <Button
        onClick={() => navigate(-1)}
        className={classes.backBtn}
        id="aboutusfromlandingback"
      >
        <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
        <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
      </Button>
      <div
                style={{ float: "left", width: "100%" }}
                class="sliderContentWrap"
              >
      <h2 class="pl-3 pr-3 ">
                  About cYAAG
                  <br />
                </h2>
        <div class="item slideaboutus1">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 banner-left-content ">
                <div class="marginWrapper">
                  <p>
                    cYAAG's vision is to enable everyone to be a social
                    entrepreneur and contribute to the achievement of the
                    Sustainable Development Goals (SDG's) of our planet.
                  </p>
                  <p>
                    Our intelligent digital platform empowers and rewards people
                    for accelerating innovative solutions to achieve a triple
                    impact - social, environmental, and financial.
                  </p>
                  <p>
                    We offer highly scalable solutions leveraging the latest AI,
                    Cloud, IoT, and Social Networking technologies.
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center">
                <div class="slider-img d-sm-flex justify-content-center">
                  <img
                    alt="Steer Your Vision"
                    class="img-fluid marginWrapper"
                    height="400px"
                    src={cYAAGImage}
                    title="Steer Your Vision"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 class="pl-3 pr-3 ">
                  How cYAAG works​
                  <br />
                </h2>
        <div class="item slideaboutus2">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-12 col-12 banner-left-content">
                        <p>
                          <img
                            alt="cYAAG works"
                            class="img-fluid marginWrapper"
                            height="400px"
                            src={HowcYAAGWorks}
                            title="ERP Implementation"
                          />
                        </p>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center">
                        <div class="slider-img d-sm-flex justify-content-center">
                          <img
                            alt="ERP Implementation"
                            class="img-fluid marginWrapper"
                            height="600px"
                            // src={BannerRight}
                            src={LandingPage2Img}
                            title="ERP Implementation"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <h2 class="pl-3 pr-3 ">
                  Who uses cYAAG
                  <br />
                </h2>

                <div class="item slideaboutus3">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-sm-12 col-12 banner-left-content">
                        <p>
                          <img
                            alt="cYAAG works"
                            class="img-fluid marginWrapper whiteBg"
                            height="800px"
                            src={whoUsescYAAG}
                            title="ERP Implementation"
                          />
                        </p>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-center">
                        <div class="slider-img d-sm-flex justify-content-center">
                          <img
                            alt="ERP Implementation"
                            class="img-fluid marginWrapper"
                            height="400px"
                            // src={BannerRight}
                            src={LandingPage1Img}
                            title="ERP Implementation"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
      </div>
      <div
        class="tab-pane fade"
        id="animated-underline-contact"
        role="tabpanel"
        aria-labelledby="animated-underline-contact-tab"
      ></div>
    </div>
  );
};

export default AboutusFromLanding;
