import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "../../components/Header";
import { TableComponent } from "../../components/Table";
import { getVolunteerName } from "../../redux/action/getVolunteerName";
import { userProfile } from "../../redux/action/UserProfileActions";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useNavigate } from "react-router-dom";
import { CopyRight } from "../../components/CopyRight";
import {  Button } from "@mui/material";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    background: "#F5F8FB",
    minHeight: "100vh",
    width: "max-content",
    minWidth: "100vw",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 100px 330px",
    },
  },
}));

const VolunteerManageScreen = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { response: volunteerNameResponse, isFetching: volunteerNameFetching } =
    useSelector((state) => state.volunteerName);

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  // useEffect(() => {
  //   dispatch(userProfile(localStorage.getItem("userid")));
  // }, []);
  React.useEffect(() => {
    if (profileResponse) {
      dispatch(
        getVolunteerName({
          organization: profileResponse?.organization,
          userid: localStorage.getItem("userid"),
        })
      );
    }
  }, []);
// }, [profileResponse]);

  return (
    
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
      <TopHeaderScreen {...props} />
      <div class="content content-wrap">
          <div class="content-mrg">
      {/* <main
       
        onClick={() => {
          props.setNotificationShow(false);
        }}
        className={classes.homeSreenMainContent}
        id="homepage-main"
      > */}
        {/* <Link className={classes.backBtn} to={"/home"}>
            <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
            <span style={{ paddingLeft: 0 }}> Back</span>
          </Link> */}
          <Button
                onClick={() => navigate(-1)}
                className={classes.backBtn}
            >
                <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
          <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Manage Users</div>
        {/* <span style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Manage Users</span> */}
        {/* <div
          style={{
            boxShadow: "1px 1px 1px 5px #f0f0f0",
            borderRadius: 8,
            marginTop: 16,
          }}
        > */}
       
        
        
        <TableComponent rows={volunteerNameResponse} />
        {/* </div> */}
      {/* </main> */}
      </div>
      </div>
      <CopyRight />
      <FixedFooterIcon />
      </section>
    </div>
  );
};

export default VolunteerManageScreen;
