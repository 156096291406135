/** @format */

import { call, put } from "redux-saga/effects";
import { get } from "lodash";

import {
  PURCHASE_FETCHING_DATA,
  PURCHASE_FETCHING_DATA_SUCCESS,
  PURCHASE_FETCHING_DATA_FAILURE,
} from "../action/PurchaseYaag";
import { Api } from "../../services";

const myAPI = new Api();

export function* purchaseYaag(action) {
  yield put({
    type: PURCHASE_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.purchaseYaag, action.payload);
    const payload = get(response, "data");
    yield put({
      type: PURCHASE_FETCHING_DATA_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({ type: PURCHASE_FETCHING_DATA_FAILURE });
  }
}
