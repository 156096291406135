/** @format */

const GET_REWARD_TITLE_FETCHING_DATA = "GET_REWARD_TITLE_FETCHING_DATA";
const GET_REWARD_TITLE_FETCHING_DATA_FAILURE = "GET_REWARD_TITLE_FETCHING_DATA_FAILURE";
const GET_REWARD_TITLE_FETCHING_DATA_RESET = "GET_REWARD_TITLE_FETCHING_DATA_RESET";
const GET_REWARD_TITLE_FETCHING_DATA_SUCCESS = "GET_REWARD_TITLE_FETCHING_DATA_SUCCESS";
const GET_REWARD_TITLE_FETCHING_DATA_ATTEMPT = "GET_REWARD_TITLE_FETCHING_DATA_ATTEMPT";
const getRewardTitle = (payload) => ({
  type: GET_REWARD_TITLE_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

// const getRewardTitleSuccess = () => ({
//   type: GET_REWARD_TITLE_FETCHING_DATA_SUCCESS,
// });
const getRewardTitleReset = () => ({
  type: GET_REWARD_TITLE_FETCHING_DATA_RESET,
});

export {
  GET_REWARD_TITLE_FETCHING_DATA,
  GET_REWARD_TITLE_FETCHING_DATA_FAILURE,
  GET_REWARD_TITLE_FETCHING_DATA_RESET,
  GET_REWARD_TITLE_FETCHING_DATA_SUCCESS,
  GET_REWARD_TITLE_FETCHING_DATA_ATTEMPT,
  getRewardTitle,
  // getRewardTitleSuccess,
  getRewardTitleReset,
};

//subtitle

// const GET_REWARD_SUBTITLE_FETCHING_DATA = "GET_REWARD_SUBTITLE_FETCHING_DATA";
// const GET_REWARD_SUBTITLE_FETCHING_DATA_FAILURE = "GET_REWARD_SUBTITLE_FETCHING_DATA_FAILURE";
// const GET_REWARD_SUBTITLE_FETCHING_DATA_RESET = "GET_REWARD_SUBTITLE_FETCHING_DATA_RESET";
// const GET_REWARD_SUBTITLE_FETCHING_DATA_SUCCESS = "GET_REWARD_SUBTITLE_FETCHING_DATA_SUCCESS";
// const GET_REWARD_SUBTITLE_FETCHING_DATA_ATTEMPT = "GET_REWARD_SUBTITLE_FETCHING_DATA_ATTEMPT";
// const getRewardSubTitle = (payload) => ({
//   type: GET_REWARD_SUBTITLE_FETCHING_DATA_ATTEMPT,
//   payload: payload,
// });

// const getRewardSubTitleSuccess = () => ({
//   type: GET_REWARD_SUBTITLE_FETCHING_DATA_SUCCESS,
// });
// const getRewardSubTitleReset = () => ({
//   type: GET_REWARD_SUBTITLE_FETCHING_DATA_RESET,
// });

// export {
//   GET_REWARD_SUBTITLE_FETCHING_DATA,
//   GET_REWARD_SUBTITLE_FETCHING_DATA_FAILURE,
//   GET_REWARD_SUBTITLE_FETCHING_DATA_RESET,
//   GET_REWARD_SUBTITLE_FETCHING_DATA_SUCCESS,
//   GET_REWARD_SUBTITLE_FETCHING_DATA_ATTEMPT,
//   getRewardSubTitle,
//   getRewardSubTitleSuccess,
//   getRewardSubTitleReset,
// };