/** @format */

// reducers
import {
    ONBOARD_REWARDS_FETCHING_DATA,
    ONBOARD_REWARDS_FETCHING_DATA_FAILURE,
    ONBOARD_REWARDS_FETCHING_DATA_RESET,
    ONBOARD_REWARDS_FETCHING_DATA_SUCCESS,
    
  } from "../action/onboardRewardsAction";
  
  let initialState = {
    onboardRewardsResponse: {},
    onboardRewardsSuccess: false,
    onboardRewardsError: false,
    onboardRewardsFetching: false,
  };
  
  export default function OnboardReducer(state = initialState, action) {
    switch (action.type) {
        case ONBOARD_REWARDS_FETCHING_DATA:
            return {
            ...state,
            onboardRewardsFetching: true,
            onboardRewardsError: false,
            onboardRewardsSuccess: false,
        };
        case ONBOARD_REWARDS_FETCHING_DATA_FAILURE:
            return {
            ...state,
            onboardRewardsFetching: false,
            onboardRewardsError: action.payload,
            onboardRewardsSuccess: false,
        };
        case ONBOARD_REWARDS_FETCHING_DATA_SUCCESS:
            return {
            ...state,
            onboardRewardsFetching: false,
            onboardRewardsError: false,
            onboardRewardsResponse: action.payload,
            onboardRewardsSuccess: true,
        };
        case ONBOARD_REWARDS_FETCHING_DATA_RESET:
            return {
            ...state,
            onboardRewardsFetching: true,
            onboardRewardsError: false,
            onboardRewardsSuccess: false,
        };
      
      default:
        return state;
    }
  }
  