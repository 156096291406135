import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';
import { makeStyles } from "@material-ui/core";
import ResponsiveDrawer from "../../components/Header";
import TopHeaderScreen from "../../components/TopHeader1";
import { useNavigate } from "react-router-dom";


const useStyles = makeStyles((theme) =>({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
}));



const ListUser = (props) => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user data using Axios
    axios.get('kt/ktuser') // Replace 'your-api-endpoint' with your actual API endpoint
      .then(response => {
        setUsers(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  console.log("users = ", users)

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                marginRight: 12,
                marginBottom: 4,
              }}
            >

            </div>
            

            <div style={{ fontFamily: "monospace", fontSize: 16 }}>
              <Button onClick={() => navigate(-1)} className={classes.backBtn}>
                <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
              </Button>
            </div>
            <span
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              User List
            </span>
            <TableContainer component={Paper} className={classes.tableContainer}>
              <Table className={classes.table} aria-label="user table">
                <TableHead style={{ backgroundColor: 'gray', color: "white" }}>
                  <TableRow>
                    <TableCell >Name</TableCell>
                    <TableCell >Phone Number</TableCell>
                    <TableCell >Email ID</TableCell>
                    <TableCell >Onboarded Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user, index) => (
                    <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff' }}>
                      <TableCell>{user.firstname} {user.lastname}</TableCell>
                      <TableCell>{user.phoneno}</TableCell>
                      <TableCell>{user.emailid}</TableCell>
                      <TableCell>{user.createdate}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ListUser;
