import {
  makeStyles,
  Chip,
  Avatar,
  useMediaQuery,
  Typography,
  Box,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "../../components/Header";
import TopHeaderScreen from "../../components/TopHeader1";
import { CopyRight } from "../../components/CopyRight";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate } from "react-router-dom";

import LeaderboardIcon from "@mui/icons-material/Leaderboard";

import {
  Stack,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Button,
} from "@mui/material";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import Autocomplete from "@mui/material/Autocomplete";
import { ar } from "date-fns/locale";
// import Avatar from '@mui/material/Avatar';
//   import Select, { SelectChangeEvent } from '@mui/material/Select';
// import ReactPlayer from 'react-player'
import debounce from "lodash/debounce"; // Import lodash debounce function
import CampaignLeaderBoardIcon from "../../assets/CampaignLeaderBoardIcon.jpeg"

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    background: "#F5F8FB",
    minHeight: "100vh",
    width: "max-content",
    minWidth: "100vw",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 100px 330px",
    },
  },
  subtitleWrapper: {
    textAlign: "center",
  },
  pointsWrapper: {
    padding: 3,
    margin: 4,
    borderRadius: 4,
    textAlign: "center",
  },
  tagWrapper: {
    padding: 3,
    backgroundColor: "#FF5733",
    color: "white",
    margin: 4,
    borderRadius: 4,
    textAlign: "center",
  },
  campaigntypewrapper: {
    padding: 1,

    // backgroundColor: "#E8E8E8",
    margin: 12,
    borderRadius: 4,
    display: "block",
    // display: "inline-flex",
  },
}));

const SearchPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const [query, setQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState(""); // State to hold selected option
  const options = ["Changemaker", "Campaign"]; // Dropdown options
  const [changemakerResults, setChangemakerResults] = useState([]);
  const [campaignResults, setCampaignResults] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [changemakerList, setChangemakerList] = useState([]);
  const [SDGCampMapping, setSDGCampMapping] = React.useState([]);

  const fetchChangemakerData = async (searchQuery) => {
    try {
      const response = await axios.get(`/search/vol/test/${searchQuery}`);
      setChangemakerResults(response.data); // Update changemaker results
    } catch (error) {
      console.error("Error fetching Changemaker data:", error);
      setChangemakerResults([]); // Clear changemaker results on error
    }
  };

  const fetchCampaignData = async (searchQuery) => {
    try {
      const response = await axios.get(`/search/camp/test/${searchQuery}/${profileResponse?.organization}`);
      setCampaignResults(response.data); // Update campaign results
    } catch (error) {
      console.error("Error fetching Campaign data:", error);
      setCampaignResults([]); // Clear campaign results on error
    }
  };

  const debouncedFetchData = debounce(async (input) => {
    if (input.length === 0) {
      setChangemakerResults([]);
      setCampaignResults([]);
    } else {
      if (selectedOption === "Changemaker") {
        await fetchChangemakerData(input);
      } else if (selectedOption === "Campaign") {
        await fetchCampaignData(input);
      }
    }
  }, 300);

  const handleInputChange = (event) => {
    const userInput = event.target.value;
    setQuery(userInput);
    debouncedFetchData(userInput);
  };

  const handleOptionChange = (selectedValue) => {
    setSelectedOption(selectedValue);
    setQuery("");
    setChangemakerResults([]);
    setCampaignResults([]); // Clear results when changing options
  };

  const handleCampaignSearchResult = async (selectedItemId) => {
    console.log("Selected item ID:", selectedItemId);

    setCampaignResults([]);
    try {
      const res = await axios
        .get(
          `search/camp/${selectedItemId}/${profileResponse?.orgrole}/${profileResponse?.organization}/${profileResponse?.phoneno}`
        )
        .then((response) => {
          // setSearchResult(response.data);
          // setVolunteerListName([]);
          // setCampaignList(response.data);
          // console.log(response.data)

          if (response) {
            videoRef.current?.load();
            setCampaignList(response.data);
          }
          videoSourceRef.current.src = response.data[0].video;
          setCampaignList(response.data);
        });

      // Replace the above console.log with your endpoint call using axios
    } catch (error) {
      console.error("Error fetching Campaign data:", error);
      // setCampaignResults([]); // Clear campaign results on error
    }
  };

  const handleChangemakerSearchResult = async (selectedItemId) => {
    console.log("Selected item ID:", selectedItemId);
    setChangemakerResults([]);

    try {
      const res = await axios
        .get(`search/vol/${selectedItemId}/${profileResponse?.phoneno}`)
        .then((response) => {
          // setSearchResult(response.data);
          // setVolunteerListName([]);
          setChangemakerList(response.data);
          console.log(response.data);
        });

      // Replace the above console.log with your endpoint call using axios
    } catch (error) {
      console.error("Error fetching Campaign data:", error);
      // setCampaignResults([]); // Clear campaign results on error
    }
  };

  useEffect(() => {
    // currentUserData();
    getSDGCampMapping();
  }, [campaignList]);

  const getSDGCampMapping = async () => {
    try {
      const res = await axios.get(`camp/sdg/mapping`);
      // setActivityList(res?.data);
      setSDGCampMapping(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const videoRef = useRef(null);
  const videoSourceRef = useRef(null);
  const optionsTwo = {
    items: 2,
    margin: 0,
    loop: false,
    nav: true,
    autoplaySpeed: 900,
    autoplay: false,
    dots: false,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div class="content content-pad-0">
              <h2>Search Page</h2>
              <div
                class="searchWrapper"
                style={{
                  float: "left",
                  padding: "20px",
                  width: "100%",
                  border: "1px solid rgb(235, 149, 50)",
                }}
              >
                <InputLabel id="demo-simple-select-label">Search By</InputLabel>

                <div
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* Dropdown menu */}
                  <select
                    value={selectedOption}
                    onChange={(e) => handleOptionChange(e.target.value)}
                    style={{
                      padding: "14px",
                      marginRight: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                      backgroundColor: "#fff",
                      flex: "1", // Expand to take remaining space
                      maxWidth: "180px", // Limit maximum width
                      width: "auto", // Let content determine width
                      fontSize: "18px", // Increase font size
                    }}
                  >
                    <option value="">Select</option>
                    {options.map((option, index) => (
                      <option
                        key={index}
                        value={option}
                        style={{ fontSize: "18px", marginTop: "10px" }}
                      >
                        {option}
                      </option>
                    ))}
                  </select>

                  {/* Search input */}
                  <input
                    type="text"
                    placeholder={`Search ${selectedOption.toLowerCase()}...`}
                    value={query}
                    onChange={handleInputChange}
                    style={{
                      padding: "12px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                      backgroundColor: "#fff",
                      flex: "2", // Expand to take more space than the select
                      maxWidth: "400px", // Limit maximum width on desktop
                      width: "100%", // Fill available width
                      fontSize: "18px", // Increase font size
                    }}
                  />
                </div>

                {/* Display search results */}
                {/* <ul style={{ listStyle: "none", padding: "0px 0px 0px 90px" }}>
                  {selectedOption === "Changemaker" &&
                    changemakerResults.map((result, index) => (
                      <li
                        key={result.id}
                        onClick={() =>
                          handleChangemakerSearchResult(result.firstname)
                        }
                        style={{
                          fontSize: "18px",
                          paddingLeft: "100px",
                          cursor: "pointer",
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#f0f0f0";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor =
                            index % 2 === 0 ? "white" : "#f9f9f9";
                        }}
                      >
                        
                        {result.firstname} {result.lastname}
                      </li>
                    ))}
                  {selectedOption === "Campaign" &&
                    campaignResults.map((result, index) => (
                      <li
                        key={result.campid}
                        onClick={() => handleCampaignSearchResult(result.title)}
                        style={{
                          fontSize: "18px",
                          paddingLeft: "100px",
                          cursor: "pointer",
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#f0f0f0";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor =
                            index % 2 === 0 ? "white" : "#f9f9f9";
                        }}
                      >
                        
                        {result.title}
                      </li>
                    ))}
                </ul> */}

                <ul
                  style={{
                    listStyle: "none",
                    padding: "0",
                    margin: "0",
                  }}
                >
                  {selectedOption === "Changemaker" &&
                    changemakerResults.map((result, index) => (
                      <li
                        key={result.id}
                        onClick={() => handleChangemakerSearchResult(result.firstname)}
                        style={{
                          fontSize: "16px",
                          paddingLeft: "10px",
                          cursor: "pointer",
                          backgroundColor: index % 2 === 0 ? "white" : "#f9f9f9",
                          marginBottom: "5px",
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#f0f0f0";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = index % 2 === 0 ? "white" : "#f9f9f9";
                        }}
                      >
                        {/* Display Changemaker result information */}
                        {result.firstname} {result.lastname}
                      </li>
                    ))}
                  {selectedOption === "Campaign" &&
                    campaignResults.map((result, index) => (
                      <li
                        key={result.campid}
                        onClick={() => handleCampaignSearchResult(result.title)}
                        style={{
                          fontSize: "16px",
                          paddingLeft: "10px",
                          cursor: "pointer",
                          backgroundColor: index % 2 === 0 ? "white" : "#f9f9f9",
                          marginBottom: "5px",
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#f0f0f0";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = index % 2 === 0 ? "white" : "#f9f9f9";
                        }}
                      >
                        {/* Display Campaign result information */}
                        {result.title}
                      </li>
                    ))}
                </ul>



                <div style={{ float: "left", width: "100%" }}>
                  {selectedOption === "Campaign" && (
                    <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                      <OwlCarousel className="owl-theme" {...optionsTwo}>
                        {campaignList.map((tile) => (
                          <div class="item">
                            <div
                              class="campaigns-wrap campaigns-wrap-new"
                              style={{ marginBottom: "50px" }}
                            >
                              <div>
                                <div>
                                  {tile?.active === "Completed" ? (
                                    <Typography
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      <Chip
                                        key={tile.active}
                                        fontSize="small"
                                        size="small"
                                        style={{
                                          backgroundColor: "green",
                                          color: "white",
                                          marginBottom: "5px",
                                        }}
                                        label={tile.active}
                                      />
                                    </Typography>
                                  ) : (
                                    <Typography
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      <Chip
                                        key={tile.active}
                                        fontSize="small"
                                        size="small"
                                        style={{
                                          backgroundColor:
                                            tile.active === "Paused"
                                              ? "#1976d2"
                                              : "red",
                                          color: "white",
                                          marginBottom: "5px",
                                        }}
                                        label={tile.active}
                                      />
                                    </Typography>
                                  )}
                                  <div class="campaign-img">
                                    <div class="video-wrapper">
                                      <div
                                        class="video-container"
                                        id="video-container"
                                      >
                                        <video
                                          controls
                                          id="video"
                                          ref={videoRef}
                                          preload="none"
                                          poster={tile?.logo}
                                          autoplay
                                        >
                                          <source
                                            ref={videoSourceRef}
                                            src={tile?.video}
                                            type="video/mp4"
                                            id="ogg_src"
                                          />
                                        </video>
                                      </div>
                                    </div>
                                  </div>
                                  <h5
                                    title={tile?.name}
                                    class="campaign-title-new"
                                  >
                                    {tile?.name}
                                  </h5>

                                  <div class="campaign-hashtags">
                                    {tile?.tag.map((tag) =>
                                      SDGCampMapping?.map(
                                        (item) =>
                                          item?.cat === tag?.label && (
                                            <a
                                              class="hash-styles"
                                              style={{ cursor: "initial" }}
                                            >
                                              {tag?.tag}{" "}
                                              <a
                                                class="hash-styles-sdg"
                                                style={{ cursor: "initial" }}
                                              >
                                                SDG {item?.sdg}
                                              </a>
                                            </a>
                                          )
                                      )
                                    )}
                                  </div>
                                </div>
                                {/* <div className={classes.campaigntypewrapper}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    gap={0.5}
                                  >
                                    <h5 class="campaign-goal">
                                      <a
                                        class="hash-styles-goal"
                                        style={{
                                          padding: "0px 5px 0px 0px",
                                        }}
                                      >
                                        Need {tile.targetvol} changemakers
                                      </a>
                                      <Chip
                                        key={tile.campaigntype}
                                        fontSize="small"
                                        size="small"
                                        label={tile.campaigntype}
                                      />
                                      <LeaderboardIcon
                                        style={{
                                          marginLeft: "3px",
                                          cursor: "pointer",
                                          verticalAlign: "middle",
                                        }}
                                        onClick={() =>
                                          navigate(
                                            `/camp/leaderboard/${tile.campid}`
                                          )
                                        }
                                      />
                                    </h5>
                                  </Stack>
                                </div> */}

                                <div className={classes.campaigntypewrapper} style={{ margin: "7px 0px" }}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    gap={0.5}
                                  >
                                    <h5 class="campaign-goal">
                                      <a
                                        class="hash-styles-goal"
                                        style={{
                                          padding:
                                            "0px 5px 0px 0px",
                                          cursor: "initial",
                                        }}
                                      >
                                        <span style={{ fontSize: "16px", fontWeight: 700 }}>Goal</span>: <span class="text-6" style={{ color: "black" }}>{tile.goalquantity}{" "} {tile.unitofgoal}</span>
                                      </a>
                                    </h5>
                                  </Stack>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12">
                                  <div>
                                    <div class="campaigns-details">
                                      <div class="p-bar-new">
                                        <div class="row ">
                                          <div class="col-6">
                                            <div>
                                              <h6>
                                                <span style={{ color: "#AAAAAA" }}>Pledged: </span>{" "} <span style={{ backgroundColor: "#FFDB58" }}>
                                                  {Math.round(
                                                    (tile.committedcount)
                                                  )}
                                                </span>
                                              </h6>
                                            </div>
                                          </div>
                                          <div class="col-6">
                                            <div
                                              style={{
                                                textAlign:
                                                  "right",
                                              }}
                                            >
                                              <h6>
                                                <span style={{ color: "#AAAAAA" }}>Achieved: </span>
                                                {" "}
                                                <span style={{ backgroundColor: "#A9D18E" }}> {tile.achievedcount}</span>
                                                {" "}
                                              </h6>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="progress br-30 campaign-progress" style={{ position: "relative" }}>
                                          <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{
                                              width: `${(tile.committedcount / tile.goalquantity) * 100}%`,
                                              backgroundColor: "#FFDB58",
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              zIndex: 1,
                                              height: "100%"
                                            }}
                                            aria-valuenow={Math.round((tile.committedcount / tile.goalquantity) * 100)}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                          ></div>

                                          <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{
                                              width: `${(tile.achievedcount / tile.goalquantity) * 100}%`,
                                              backgroundColor: "#A9D18E",
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              zIndex: 2,
                                              height: "100%"
                                            }}
                                            aria-valuenow={Math.round((tile.achievedcount / tile.goalquantity) * 100)}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                          ></div>
                                        </div>
                                      </div>
                                      <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                        <div style={{ flex: 1 }}>
                                          <h6>
                                            <span style={{ color: "#AAAAAA" }}>Signed Up:</span>{" "}
                                            <span style={{ backgroundColor: "#DAE8FD" }}>{tile.submittedcount}</span>
                                          </h6>
                                        </div>
                                        <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                          <Chip
                                            key={tile.campaigntype}
                                            fontSize="small"
                                            size="small"
                                            label={tile.campaigntype}
                                          />
                                          <img
                                            src={CampaignLeaderBoardIcon}
                                            style={{ width: 25, height: 25, cursor: "pointer", }}
                                            onClick={() =>
                                              navigate(
                                                `/camp/leaderboard/${tile.campid}`
                                              )
                                            }
                                          />
                                        </div>
                                      </div>




                                      <div class="row">
                                        <div class="col-md-4 col-6 customHalfWidth">
                                          <div class="cam-cols">
                                            <div class="timer-here">
                                              <div class="text-center">
                                                <span class="campaign-time-title">
                                                  Earn upto
                                                </span>
                                              </div>
                                              <div>
                                                <ul id="countdown">
                                                  <li id="hours">
                                                    <div class="numberhome"></div>
                                                    <div class="label"></div>
                                                  </li>
                                                  <li id="minutes">
                                                    <div
                                                      class="numberhome"
                                                      style={{
                                                        padding:
                                                          "0px 0px 0px 6px",
                                                      }}
                                                    >
                                                      {tile.maxpointstobeearned}
                                                    </div>
                                                    <div
                                                      class="label"
                                                      style={{
                                                        padding:
                                                          "0px 0px 0px 12px",
                                                      }}
                                                    >
                                                      Points
                                                    </div>
                                                  </li>
                                                  <li id="seconds">
                                                    <div class="numberhome"></div>
                                                    <div class="label"></div>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="col-md-4 col-6 customHalfWidth">
                                          <div class="cam-cols">
                                            <div class="timer-here">
                                              <div class="text-center">
                                                <span class="campaign-time-title">
                                                  Time Left
                                                </span>
                                              </div>
                                              <div>
                                                <ul id="countdown">
                                                  <li id="hours">
                                                    <div class="number">
                                                      {tile.dayrem}
                                                    </div>
                                                    <div class="label">
                                                      Days
                                                    </div>
                                                  </li>
                                                  <li id="minutes">
                                                    <div class="number">
                                                      {tile.hourrem}
                                                    </div>
                                                    <div class="label">
                                                      Hours
                                                    </div>
                                                  </li>
                                                  <li id="seconds">
                                                    <div class="number">
                                                      {tile.minrem}
                                                    </div>
                                                    <div class="label">
                                                      Mins
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-md-4 customFullWidth">
                                          <div class="par-btn-here">
                                            {tile.active === "Active" || tile.active === "Upcoming" ? (
                                              <div class="text-center">
                                                <a class="par-now-new">
                                                  {/* {(campaignList?.data?.[0]?.active === "Active") && ( */}
                                                  <Button
                                                    onClick={() =>
                                                      navigate(
                                                        `/participate/${tile.campid}/${tile?.phoneno}`
                                                      )
                                                    }
                                                    style={{
                                                      color: "white",
                                                      // padding:"0px 0px 0px 0px",
                                                      padding: "6px",
                                                      fontSize: "12px",
                                                    }}
                                                    className={classes.button}
                                                    name={
                                                      "activepnow" + tile.campid
                                                    }
                                                  >
                                                    Participate Now{" "}
                                                    <i
                                                      class="fa fa-arrow-right"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </Button>
                                                  {/* )} */}
                                                </a>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>

                                      {/* <div class="p-bar-new">
                                        <div class="row ">
                                          <div class="col-6">
                                            <div>
                                              <h6>
                                                {Math.round(
                                                  (tile.submittedcount /
                                                    tile.targetvol) *
                                                    100
                                                )}
                                                % submitted
                                              </h6>
                                            </div>
                                          </div>
                                          <div class="col-6">
                                            <div
                                              style={{
                                                textAlign: "right",
                                              }}
                                            >
                                              <h6>
                                                {tile.submittedcount}/
                                                {tile.targetvol}
                                              </h6>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="progress br-30 campaign-progress">
                                          <div
                                            class="progress-bar bg-primary"
                                            role="progressbar"
                                            style={{
                                              width:
                                                Math.round(
                                                  (tile.submittedcount /
                                                    tile.targetvol) *
                                                    100
                                                ) + "%",
                                            }}
                                            aria-valuenow={Math.round(
                                              (tile.submittedcount /
                                                tile.targetvol) *
                                                100
                                            )}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                          ></div>
                                        </div>
                                        <span class="progress-txt">
                                          {tile.submittedcount} changemakers
                                          submitted out of {tile.targetvol}
                                        </span>
                                      </div> */}

                                      {/* <div class="p-bar-new">
                                        <div class="row ">
                                          <div class="col-6">
                                            <div>
                                              <h6>
                                                {Math.round(
                                                  (tile.adoptioncount /
                                                    tile.targetvol) *
                                                    100
                                                )}
                                                % approved
                                              </h6>
                                            </div>
                                          </div>
                                          <div class="col-6">
                                            <div
                                              style={{
                                                textAlign: "right",
                                              }}
                                            >
                                              <h6>
                                                {tile.adoptioncount}/
                                                {tile.targetvol}
                                              </h6>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="progress br-30 campaign-progress">
                                          <div
                                            class="progress-bar bg-approved"
                                            role="progressbar"
                                            style={{
                                              width:
                                                Math.round(
                                                  (tile.adoptioncount /
                                                    tile.targetvol) *
                                                    100
                                                ) + "%",
                                            }}
                                            aria-valuenow="25"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                          ></div>
                                        </div>
                                        <span class="progress-txt">
                                          {tile.adoptioncount} changemakers
                                          approved out of {tile.targetvol}
                                        </span>
                                      </div> */}

                                      <div class="mr-15 text-center">
                                        <a class="campaign-signin">
                                          Join Now{" "}
                                          <i
                                            class="fa fa-arrow-right"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </OwlCarousel>
                    </div>
                  )}

                  {/* Changemaker list drop down */}

                  {selectedOption === "Changemaker" && (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-around",
                      }}
                    >
                      {changemakerList.map((tile) => (
                        <Button
                          key={tile.id}
                          onClick={() => navigate(`/newProfile/${tile?.profileurl}`)}
                          style={{
                            flex: "0 0 calc(50% - 20px)",
                            maxWidth: "calc(50% - 20px)",
                            margin: "10px",
                            padding: "10px",
                            boxSizing: "border-box",
                          }}
                        >
                          <div
                            class="volListWrapper"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ marginBottom: "10px", width: "100%" }}>
                              {tile.profilephoto ? (
                                <img
                                  style={{ width: "100%", height: "150px", objectFit: "cover" }}
                                  src={tile.profilephoto}
                                  alt={`${tile.firstname} ${tile.lastname}`}
                                />
                              ) : (
                                <Avatar
                                  style={{ width: "100%", height: "150px", borderRadius: "0" }}
                                  src="/broken-image.jpg"
                                  alt={`${tile.firstname} ${tile.lastname}`}
                                />
                              )}
                            </div>

                            <div class="rightAlignItem" style={{ textAlign: "center" }}>
                              <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                                {tile.firstname} {tile.lastname}
                              </span>
                              <br />
                              <span>{tile.orgrole}</span>
                              <br />
                              <span>{tile.organization}</span>
                              <br />
                            </div>
                          </div>
                        </Button>
                      ))}
                    </div>
                  )}



                  {/* {selectedOption === "Changemaker" && (
                    <div>
                      {changemakerList.map((tile) => (
                        <Button
                          onClick={() =>
                            navigate(`/newProfile/${tile?.profileurl}`)
                          }
                        >
                          <div
                            class="volListWrapper"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "40px",
                            }}
                          >
                            {tile.profilephoto ? (
                              <img
                                style={{ width: "190px" }}
                                src={tile.profilephoto}
                              />
                            ) : (
                              <Avatar
                                style={{
                                  width: "200px",
                                  borderRadius: "0",
                                  height: "auto",
                                }}
                                src="/broken-image.jpg"
                              />
                            )}

                            <div
                              class="rightAlignItem"
                              style={{ marginLeft: "20px" }}
                            >
                              <span
                                style={{ fontWeight: "bold", fontSize: "22px" }}
                              >
                                {tile.firstname} {tile.lastname}
                              </span>
                              <br />
                              <span>{tile.orgrole}</span>
                              <br />
                              <span>{tile.organization}</span>
                              <br />
                            </div>
                          </div>
                        </Button>
                      ))}
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <CopyRight style={{ paddingBottom: "0px" }} />
        <FixedFooterIcon />
      </section>
    </div>
  );
};

export default SearchPage;
