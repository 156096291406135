/** @format */

import { call, put } from "redux-saga/effects";
import { get } from "lodash";

import {
   USER_ONBOARD_REVIEW_LIST_FETCHING_DATA,
   USER_ONBOARD_REVIEW_LIST_FETCHING_DATA_SUCCESS,
   USER_ONBOARD_REVIEW_LIST_FETCHING_DATA_FAILURE,
} from "../action/UserOnboardReviewAction";
import { Api } from "../../services";

const myAPI = new Api();

export function* userreviewlist(action) {
  yield put({
    type:  USER_ONBOARD_REVIEW_LIST_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.userreviewlist, action.payload);
    const payload = get(response, "data");
    // console.log(payload)
    yield put({
      type:  USER_ONBOARD_REVIEW_LIST_FETCHING_DATA_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({ type:  USER_ONBOARD_REVIEW_LIST_FETCHING_DATA_FAILURE });
  }
}
