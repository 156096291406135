import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "../../components/Header";
import { TableComponent } from "../../components/Table";
import { getVolunteerName } from "../../redux/action/getVolunteerName";
import { userProfile } from "../../redux/action/UserProfileActions";
import TopHeaderScreen from "../../components/TopHeader1";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    background: "#F5F8FB",
    minHeight: "100vh",
    width: "max-content",
    minWidth: "100vw",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 100px 330px",
    },
  },
}));

const VolunteerListScreen = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { response: volunteerNameResponse, isFetching: volunteerNameFetching } =
    useSelector((state) => state.volunteerName);

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  // useEffect(() => {
  //   dispatch(userProfile(localStorage.getItem("userid")));
  // }, []);
  React.useEffect(() => {
    if (profileResponse) {
      dispatch(
        getVolunteerName({
          organization: profileResponse?.organization,
          userid: localStorage.getItem("userid"),
        })
      );
    }
  }, [profileResponse]);
  return (
    <div>
      <ResponsiveDrawer {...props} />
      <TopHeaderScreen {...props} />
      <main
        onClick={() => {
          props.setNotificationShow(false);
        }}
        className={classes.homeSreenMainContent}
        id="homepage-main"
      >
        <span style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Volunteers List</span>
        {/* <div
          style={{
            boxShadow: "1px 1px 1px 5px #f0f0f0",
            borderRadius: 8,
            marginTop: 16,
          }}
        > */}
        <TableComponent
          rows={volunteerNameResponse}
          isFetching={volunteerNameFetching}
        />
        {/* </div> */}
      </main>
    </div>
  );
};

export default VolunteerListScreen;
