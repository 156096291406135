import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  Button,
  CircularProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles,
  TableFooter,
  TablePagination,
  DialogActions,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  Checkbox,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { purchaseYaag } from "../redux/action/PurchaseYaag";
import { validateReward } from "../redux/action/ValidatorAction";
import moment from "moment";
import { validatorList } from "../redux/action/ValidatorList";
import axios from "axios";
import ActionAlert from "./ActionAlert";
import { bindActionCreators } from "redux";
import AssessmentIcon from '@mui/icons-material/Assessment';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { userreviewlist } from "../redux/action/UserOnboardReviewAction";
import { actionUserOnboardReview } from "../redux/action/ActionUserOnboardReview";


const useStyles = makeStyles((theme) => ({
  homeSreenManiContent: {
    padding: "100px 0 0 20px",
    [theme.breakpoints.up("sm")]: {
      padding: "100px 0 0 220px",
    },
  },
  homeScreenContainer: {
    padding: "0 20px",
  },
  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginRight: 10,
    color: "#fff",
  },
  table: {
    width: "100%",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8B34C",
    color: "#ffffff",
    textTransform: "uppercase",
  },
  body: {
    fontSize: 14,
  },
  tbodyText: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width: "200px",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // wordWrap: "breakWord",
    // overflow: "scroll",
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    // '&:nth-of-type(odd)': {
    // 	backgroundColor: '#f7dbb3',
    // },
    // '&:nth-of-type(even)': {
    // 	backgroundColor: '#e9e7e8',
    // },
  },
}))(TableRow);

export const ValidateUserOnboarding = (props) => {
  const dispatch = useDispatch();
  const [checkedAllValue, setCheckedAllValue] = useState();
  const classes = useStyles();
  const [countValue, setCountValue] = useState(true);
  const tableRef = useRef(null);
  const [author, setAuthor] = useState([]);
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [campaignList, setCampaignList] = useState([
    "firstname",
    "lastname",
    "phoneno",
    "organization",
    "orgrole",
  ]);
 
  // const bulkArray = {
  //   registrationid: [
  //     {
  //       registrationid: "",
  //       campid: "",
  //       rewarddate: "",
  //       onboardstatus: "",
  //     },
  //   ],
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const [campaignList, setCampaignList] = useState();
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  // console.log(profileResponse?.phoneno);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  // const { isSuccess: validatorListSuccess, response: validatorListResponse } =
  //   useSelector((state) => state.validatorList);
  // const { isSuccess: validateRewardSuccess, response: validateRewardResponse } =
  //   useSelector((state) => state.validateReward);
  
  const { isSuccess: userreviewlistSuccess, response: userreviewlistResponse } = useSelector(
    (state) => state.userreviewlist
  );
  // useEffect(() => {}, [campaignList]);

  const refresh = async () => {
    await sleep(5000);
  };
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const validationList1 = async () => {
    try {
      const res = await axios.get(
        `/user/onboard/list/${profileResponse?.phoneno}`
      );
      const vaa = await res?.data;
      //setCampaignList(oldau => [...oldau, vaa]);
      return res;
    } catch (err) {
      throw err;
    }
  };
  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };
  useEffect(() => {
    // dispatch(validatorList());
    dispatch(userreviewlist());
    setAuthor(props);
    validationList1();

    var chck = document.getElementsByName("vehicleParent");
    for (var ch of chck) {
      ch.checked = false;
    }
    var checkboxes = document.getElementsByName("vehicle");
    for (var checkbox of checkboxes) {
      checkbox.checked = false;
    }
  }, [userreviewlistSuccess]);

  const xunique = [
    ...new Map(
      props.rows.map((item) => [item["phoneno"], item])
    ).values(),
  ];
  console.log(props.rows);
  console.log(xunique);
  const handleClick = (id, subStatus) => {
    if (subStatus === "approved" || subStatus === "rejected") {
      console.log((id.onboardstatus = subStatus));
      // console.log(
      //   (id.registerdate = moment(new Date()).format("yyyy-MM-DD HH:mm:ss"))
      // );
      console.log(JSON.stringify(props));
    }
  };

  let temp = [];
  const handleBulkClick = (selectedItem, selectionName) => {
    if (
      props.rows.filter((item) => item.onboardstatus === "submitted")
        .length ===
      [].slice
        .call(document.querySelectorAll("[name='vehicle']"))
        .filter(function (e) {
          return e.checked;
        }).length
    ) {
      document.getElementsByName("vehicleParent")[0].checked = true;
    } else {
      document.getElementsByName("vehicleParent")[0].checked = false;
    }
    //setCountValue([].slice.call(document.querySelectorAll("[name='vehicle']")).filter(function(e) { return e.checked; }).length);
    console.log(selectionName === "bulkSelection");
    if (selectionName === "bulkSelection") {
      console.log("cominginside========");
      let i = 0;
      for (let i = 0; i < selectedItem.length; i += 1) {
        const formattedArray = [];
        console.log("Phoneno ==", selectedItem[i].phoneno);
        console.log(selectedItem[i]);
        formattedArray.push(
          selectedItem[i].id,
          selectedItem[i].firstname,
          selectedItem[i].lastname,
          selectedItem[i].phoneno,
          selectedItem[i].organization,
          selectedItem[i].orgrole,
          selectedItem[i].onboardstatus,
          
          
        );
        temp.push(createDataValidator(...formattedArray));
        console.log(temp.length);
      }
    } else {
      console.log("temp value first", temp);
      var positionVal = temp
        .map((e) => e.phoneno)
        .indexOf(selectedItem.phoneno);
      console.log("position val===", positionVal);
      if (
        temp.map((e) => e.phoneno).indexOf(selectedItem.phoneno) >
        -1
      ) {
        console.log("======== slice inside before =====");
        console.log(temp);
        temp.splice(positionVal, 1);
        console.log("======== slice inside after =====");
        console.log(temp);
      } else {
        console.log("====== coming else part ========");
        const formattedArray1 = [];
        formattedArray1.push(
          selectedItem.id,
          selectedItem.firstname,
          selectedItem.lastname,
          selectedItem.phoneno,
          selectedItem.organization,
          selectedItem.orgrole,
          selectedItem.onboardstatus,
          
        );
        if (document.querySelectorAll("[name='vehicle']:checked").length > 0) {
          document.getElementById("acceptAllUser").disabled = true;
        } else {
          document.getElementById("acceptAllUser").disabled = false;
        }

        // setCountValueee(document.querySelectorAll("[name='vehicle']:checked").length);
        temp.push(createDataValidator(...formattedArray1));
      }
    }

    console.log("======== Temp array value=====");
    console.log(temp);
  };

  const checkAllValue = () => {
    temp = [];
    var checkboxes = document.getElementsByName("vehicle");
    if (document.getElementsByName("vehicleParent")[0].checked) {
      for (var checkbox of checkboxes) {
        checkbox.checked = true;
      }
      handleBulkClick(
        props.rows.filter((item) => item.onboardstatus === "submitted"),
        "bulkSelection"
      );
    } else {
      for (var checkbox of checkboxes) {
        checkbox.checked = false;
      }
      handleBulkClick([], "bulkSelection");
    }
    props.rows.filter((item) => item.onboardstatus === "submitted");
  };

  const acceptAll = (selectedVal, statusVal) => {
    console.log(selectedVal);

    if(selectedVal.length == 0) {
      setDialogOpen(true);
      return false;
      }
    let op = selectedVal.map((e, i) => {
      let temp = props.rows.find(
        (element) => element.phoneno == e.phoneno
      );
      console.log(temp);
      if (temp) {
        temp.onboardstatus = statusVal;
        setCheckedAllValue(false);
      }
    });
    var checkboxes = document.getElementsByName("vehicle");
    for (var checkbox of checkboxes) {
      checkbox.checked = false;
    }
    temp = temp.map((entry) => ({ ...entry, onboardstatus: statusVal }));
    // console.log({
    //   toberewardeddata: temp,
    // });
    console.log(temp);
    // if (statusVal === "approved") {
    //   dispatch(
    //     // purchaseYaag({
    //     //   toberewardeddata: temp,
    //     // })
    //     actionUserOnboardReview({
    //       phoneno: temp
    //     })
    //   );
    // }
    dispatch(
      // validateReward({
      //   registrationid: temp,
      // })
      actionUserOnboardReview({
        // phoneno: temp
        // phoneno: temp.phoneno,
        // onboardstatus: temp.onboardstatus,
        // userid: localStorage.getItem("userid"),
        temp,
        userid: localStorage.getItem("userid"),
      })
    );
    console.log(JSON.stringify(props));
  };

  console.log(temp);

  function createDataValidator(
    id,
    firstname,
    lastname,
    phoneno,
    organization,
    orgrole,
    onboardstatus,
    
  ) {
    return {
      id,
      firstname,
      lastname,
      phoneno,
      organization,
      orgrole,
      onboardstatus,
      
    };
  }
  console.log(props.value1);

  return (
    <TableContainer ref={tableRef} component={Paper} style={{ padding: 36,paddingLeft:0,paddingTop:0 }} class="registrationbody">
      {!_.isEmpty(props.rows) ? (
        <div>
          <div class="registrationbody">
            <div>
              <Autocomplete
                disableClearable={props.value1 == ""}
                id="controllable-states-demo"
                value={props.value1}
                onChange={(event, newValue) => {
                  props.setValue1(newValue);
                }}
                inputValue={props.inputValue}
                onInputChange={(event, newInputValue) => {
                  props.setInputValue(newInputValue);
                }}
                options={xunique}
                getOptionLabel={(option) => option.firstname}
                style={{ width: 200 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=<i>Search Change Maker </i>
                    variant="outlined"
                  />
                )}
              />
            </div>
            {props.rows[0].onboardstatus === "submitted" ? (
              <div>
                <StyledTableCell
                  style={{
                    textAlign: "center",
                    borderBottom: "0px",
                    backgroundColor: "transparent",
                  }}
                >
                  {/* <Button
                    className={classes.goalButton}
                    id="acceptAllUser"
                   
                    variant="contained"
                    //disabled={countValue}
                    style={{
                      backgroundColor: "#16a654",
                      minWidth: 110,
                      padding: "7px 0px",
                    }}
                    onClick={() => {
                      acceptAll(temp, "approved");
                      // dispatch(validatorList());
                      // dispatch(actionUserOnboardReview({userid: localStorage.getItem("userid")}));
                      dispatch(actionUserOnboardReview());
                      dispatch(userreviewlist());
                    }}
                  >
                    Accept All
                  </Button> */}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    textAlign: "center",
                    borderBottom: "0px",
                    backgroundColor: "transparent",
                  }}
                >
                  <Button
                    className={classes.goalButton}
                    id="acceptAllUser"
                   
                    variant="contained"
                    //disabled={countValue}
                    style={{
                      backgroundColor: "#16a654",
                      minWidth: 110,
                      padding: "7px 0px",
                      marginBottom:"10px"
                    }}
                    onClick={() => {
                      acceptAll(temp, "approved");
                      // dispatch(validatorList());
                      // dispatch(actionUserOnboardReview({userid: localStorage.getItem("userid")}));
                      dispatch(actionUserOnboardReview());
                      dispatch(userreviewlist());
                    }}
                  >
                    Accept All
                  </Button>
                  
                  <Button
                    style={{
                      backgroundColor: "#a62e2e",
                      minWidth: 110,
                      padding: "7px 0px",
                      marginBottom:"10px"
                    }}
                    className={classes.goalButton}
                    variant="contained"
                    onClick={() => {
                      acceptAll(temp, "rejected");
                      // dispatch(validatorList());
                      dispatch(actionUserOnboardReview());
                      dispatch(userreviewlist());
                    }}
                  >
                    Reject All
                  </Button>
                </StyledTableCell>
               
                <Dialog
        open={dialogOpen}
        onClose={handleCancel}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
           Please select Atleast one item.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button class="btn btn-primary"
            onClick={handleCancel}
          >
            Ok
          </button>
        </DialogActions>
      </Dialog>
            
              </div>
            ) : (
              ""
            )}
          </div>

         <div class="tableContainer" style={{overflowY: "scroll"}}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {props.rows[0].onboardstatus === "submitted" ? (
                  <StyledTableCell style={{ textAlign: "center", width: 50 }}>
                    <input
                      type="checkbox"
                      value="item"
                      name="vehicleParent"
                      onClick={() => {
                        checkAllValue();
                      }}
                    ></input>
                  </StyledTableCell>
                ) : (
                  ""
                )}
                {/* <StyledTableCell style={{ textAlign: "center" }}>
                  Campaign Name
                </StyledTableCell> */}
                <StyledTableCell style={{ textAlign: "center" }}>
                  Volunteer First Name
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Volunteer Last Name
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Volunteer Phone no
                </StyledTableCell>
                {/* <StyledTableCell>Earned</StyledTableCell> */}
                <StyledTableCell style={{ textAlign: "center" }}>
                  Organization
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Onboard Status
                </StyledTableCell>
                {/* <StyledTableCell style={{ textAlign: "center" }}>
                  Documents Uploaded
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Activity Report
                </StyledTableCell> */}
              </TableRow>
            </TableHead>
            {props.isFetching ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <TableBody>
                {/* {JSON.stringify(props)} */}
                {props.inputValue === ""
                  ? props.rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <StyledTableRow>
                          {props.rows[0].onboardstatus === "submitted" ? (
                            <StyledTableCell
                              style={{ textAlign: "center", width: 50 }}
                            >
                              <input
                                type="checkbox"
                                id={item.phoneno}
                                value="item"
                                onClick={() => {
                                  handleBulkClick(item, "singleSelection");
                                }}
                                name="vehicle"
                              ></input>
                            </StyledTableCell>
                          ) : (
                            ""
                          )}

                          {/* <StyledTableCell style={{ textAlign: "center" }}>
                            {item.formcampaignname}
                          </StyledTableCell> */}
                          <StyledTableCell style={{ textAlign: "center" }}>
                            {item.firstname}
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>
                            {item.lastname}
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>
                            {item.phoneno}
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>
                            {item.organization}
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>
                            {item.onboardstatus.charAt(0).toUpperCase() +
                              item.onboardstatus.slice(1)}
                          </StyledTableCell>
                          {/* <StyledTableCell style={{ textAlign: "center" }}>
                            {item.registerdate}
                          </StyledTableCell> */}
                          {/* <StyledTableCell style={{ textAlign: "center" }}>
                            <td className={classes.tbodyText}
                          onClick={() =>
                            //alert("CLICKED")
                            navigate(`/vol/reg/report/${item?.formuserphone}/${item?.registrationid}/${item?.campid}`, {
                              state: item,
                            })
                          }
                          style={{ textAlign: "center", cursor: "pointer", padding:"0px 0px 0px 30px" }}
                          >
                            <FilePresentIcon style={{ textAlign: "center", cursor: "pointer" }} />
                            </td>
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>
                            <td className={classes.tbodyText}
                          onClick={() =>
                            //alert("CLICKED")
                            navigate(`/vol/stat/report/${item?.formuserphone}/${item?.registrationid}/${item?.campid}`, {
                              state: item,
                            })
                          }
                          style={{ textAlign: "center", cursor: "pointer", padding:"0px 0px 0px 30px" }}
                          >
                            <AssessmentIcon style={{ textAlign: "center", cursor: "pointer" }} />
                            </td>
                          </StyledTableCell> */}
                          {!props.actionTaken && (
                            <div>
                              <StyledTableCell
                                style={{
                                  textAlign: "center",
                                  borderBottom: "0px",
                                }}
                              >
                                <Button
                                  className={classes.goalButton}
                                  variant="contained"
                                  style={{ backgroundColor: "#16a654" }}
                                  
                                  onClick={() => {
                                    Promise.all([
                                      dispatch(
                                        actionUserOnboardReview({
                                          temp: [
                                             {
                                              // registrationid:
                                              //   item.registrationid,
                                              // campid: item.campid,
                                              phoneno: item.phoneno,
                                              // userid: localStorage.getItem("userid"),
                                              onboardstatus: "approved",
                                             },
                                          ],
                                          userid: localStorage.getItem("userid"),
                                        })
                                      ),
                                    ])
                                      // .then(
                                      //   () =>
                                      //     dispatch(
                                      //       userreviewlist({
                                      //         phoneno: [
                                      //           {
                                      //             onboardstatus:
                                      //               "approved",
                                      //             // rewarddate: moment(
                                      //             //   new Date()
                                      //             // ).format("DD-MM-yyyy"),
                                      //           },
                                      //         ],
                                      //       })
                                      //     )
                                      //   //  console.log("accept")
                                      // )
                                      .then(() => handleClick(item, "approved"))
                                      .then(() => dispatch(userreviewlist()));
                                      

                                    validationList1();
                                  }}
                                >
                                  Accept
                                </Button>
                                {/* )} */}
                              </StyledTableCell>
                              <StyledTableCell
                                style={{
                                  textAlign: "center",
                                  borderBottom: "0px",
                                }}
                              >
                                <Button
                                  style={{ backgroundColor: "#a62e2e" }}
                                  className={classes.goalButton}
                                  variant="contained"
                                  
                                  onClick={() => {
                                    Promise.all([
                                      // dispatch(
                                      //   validateReward({
                                      //     registrationid: [
                                      //       {
                                      //         registrationid:
                                      //           item.registrationid,
                                      //         campid: item.campid,
                                      //         onboardstatus: "rejected",
                                      //         rewarddate: moment(
                                      //           new Date()
                                      //         ).format("DD-MM-yyyy"),
                                      //       },
                                      //     ],
                                      //   })
                                      // ),
                                      dispatch(
                                        actionUserOnboardReview({
                                          temp: [
                                             {
                                              // registrationid:
                                              //   item.registrationid,
                                              // campid: item.campid,
                                              // phoneno: item.formuserphone,
                                              phoneno: item.phoneno,
                                              userid: localStorage.getItem("userid"),
                                              onboardstatus: "rejected",
                                             },
                                           ],
                                           userid: localStorage.getItem("userid"),
                                        })
                                      ),
                                    ])
                                      .then(() => handleClick(item, "rejected"))
                                      .then(() => dispatch(userreviewlist()));

                                    validationList1();
                                  }}
                                >
                                  Reject
                                </Button>
                              </StyledTableCell>
                            </div>
                            // ))
                          )}
                        </StyledTableRow>
                      ))
                  : props.sortedData.map((item, index) => (
                      <StyledTableRow>
                        <StyledTableCell style={{ textAlign: "center" }}>
                            {item.firstname}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {item.lastname}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {item.phoneno}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                            {item.organization}
                          </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          {item.onboardstatus.charAt(0).toUpperCase() +
                            item.onboardstatus.slice(1)}
                        </StyledTableCell>
                        {/* <StyledTableCell style={{ textAlign: "center" }}>
                          {item.registerdate}
                        </StyledTableCell> */}
                        
                        {!props.actionTaken &&
                          //  campaignList.map((item1) => (
                          (
                            // campaignList
                            // .map(function (e) {
                            //   return e.firstname;
                            // })
                            // .indexOf(item.campid) <= 1 &&
                          item.onboardstatus === "submitted" ? (
                            <div>
                              <StyledTableCell style={{ textAlign: "center" }}>
                                <Button
                                  className={classes.goalButton}
                                  variant="contained"
                                  
                                  style={{ backgroundColor: "#16a654" }}
                                  onClick={() => {
                                    Promise.all([
                                      // dispatch(
                                      //   purchaseYaag({
                                      //     toberewardeddata: [
                                      //       {
                                      //         registrationid:
                                      //           item.registrationid,
                                      //         campid: item.campid,
                                      //         phoneno: item.formuserphone,
                                      //       },
                                      //     ],
                                      //   })
                                      // ),
                                      dispatch(
                                        actionUserOnboardReview({
                                           temp: [
                                             {
                                              // registrationid:
                                              //   item.registrationid,
                                              // campid: item.campid,
                                              // phoneno: item.formuserphone,
                                              phoneno: item.phoneno,
                                              userid: localStorage.getItem("userid"),
                                              onboardstatus: "approved",
                                             },
                                           ],
                                           userid: localStorage.getItem("userid"),
                                        })
                                      ),
                                    ])
                                      // .then(() =>
                                      //   dispatch(
                                      //     validateReward({
                                      //       registrationid: [
                                      //         {
                                      //           registrationid:
                                      //             item.registrationid,
                                      //           campid: item.campid,
                                      //           onboardstatus: "approved",
                                      //           rewarddate: moment(
                                      //             new Date()
                                      //           ).format("DD-MM-yyyy"),
                                      //         },
                                      //       ],
                                      //     })
                                      //   )
                                      // )
                                      .then(() => handleClick(item, "approved"))
                                      .then(() => dispatch(userreviewlist()));
                                    validationList1();
                                  }}
                                >
                                  Accept
                                </Button>
                              </StyledTableCell>
                              <StyledTableCell style={{ textAlign: "center" }}>
                                <Button
                                  style={{ backgroundColor: "#a62e2e" }}
                                  className={classes.goalButton}
                                  variant="contained"
                                  
                                  onClick={() => {
                                    Promise.all([
                                      // dispatch(
                                      //   validateReward({
                                      //     registrationid: [
                                      //       {
                                      //         registrationid:
                                      //           item.registrationid,
                                      //         campid: item.campid,
                                      //         onboardstatus: "rejected",
                                      //         rewarddate: moment(
                                      //           new Date()
                                      //         ).format("DD-MM-yyyy"),
                                      //       },
                                      //     ],
                                      //   })
                                      // ),
                                      dispatch(
                                        actionUserOnboardReview({
                                           temp: [
                                             {
                                              // registrationid:
                                              //   item.registrationid,
                                              // campid: item.campid,
                                              // phoneno: item.formuserphone,
                                              phoneno: item.phoneno,
                                              userid: localStorage.getItem("userid"),
                                              onboardstatus: "rejected",
                                             },
                                           ],
                                           userid: localStorage.getItem("userid"),
                                        })
                                      ),
                                    ])
                                      .then(() => handleClick(item, "rejected"))
                                      .then(() => dispatch(userreviewlist()));
                                    validationList1();
                                  }}
                                >
                                  Reject
                                </Button>
                              </StyledTableCell>
                            </div>
                          ) : null)
                          //  ))
                        }
                      </StyledTableRow>
                    ))}
              </TableBody>
            )}
          </Table>
          <TableFooter>
            <TablePagination
              rowsPerPageOptions={[10, 20, 40]}
              component="div"
              count={props.rows?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableFooter>
          </div>
          {props.rows[0].onboardstatus === "submitted" ? (
            <div>
              {/* <StyledTableCell style={{ textAlign: "center", borderBottom: "0px",backgroundColor:"transparent" }}>
                      
                          <Button
                            className={classes.goalButton}
                            variant="contained"
                            style={{ backgroundColor: "#16a654",minWidth:130, padding: "15px 0px" }}
                            onClick={() => {
                              acceptAll(temp,"approved");
                              dispatch(validatorList());
                            }}
                            
                          >
                            Accept All
                          </Button>
                        

                        </StyledTableCell> */}
              {/* <StyledTableCell style={{ textAlign: "center", borderBottom: "0px",backgroundColor:"transparent" }}>
                        
                          <Button
                            style={{ backgroundColor: "#a62e2e",minWidth:130, padding: "15px 0px" }}
                           
                            className={classes.goalButton}
                            variant="contained"
                            onClick={() => {
                              acceptAll(temp,"rejected");
                              dispatch(validatorList());
                            }}
                          >
                            Reject All
                          </Button>
                         
                        </StyledTableCell> */}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
    </TableContainer>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({}, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidateUserOnboarding);