/** @format */

// reducers
import { MY_RANK_FETCHING_DATA, MY_RANK_FETCHING_DATA_FAILURE, MY_RANK_FETCHING_DATA_RESET, MY_RANK_FETCHING_DATA_SUCCESS } from '../action/MyRankAction';

let initialState = {
	response: {},
	isSuccess: false,
	isError: false,
	isFetching: false,
};

export default function MyRankReducer(state = initialState, action) {
	switch (action.type) {
		case MY_RANK_FETCHING_DATA:
			return {
				...state,
				isFetching: true,
				isError: false,
				isSuccess: false,
			};
		case MY_RANK_FETCHING_DATA_FAILURE:
			return {
				...state,
				isFetching: false,
				isError: action.payload,
				isSuccess: false,
			};
		case MY_RANK_FETCHING_DATA_SUCCESS:
			return {
				...state,
				isFetching: false,
				isError: false,
				response: action.payload,
				isSuccess: true,
			};

		case MY_RANK_FETCHING_DATA_RESET:
			return initialState;

		default:
			return state;
	}
}
