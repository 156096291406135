import React, { useState } from 'react';
import { Dialog, DialogContent, TextField, Button, Typography, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
    updateKTCustomer,
    ktCustomerResponse,
    isKTCustomerSuccess,
    isKTCustomerError,
    isKTCustomerFetching,
    updateKTCustomerReset,
} from '../../redux/action/UpdateAction';
import { useDispatch, useSelector } from "react-redux";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate, useLocation } from 'react-router-dom';
import ResponsiveDrawer from "../../components/Header";
import TopHeaderScreen from "../../components/TopHeader1";
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
    roundedTextField: {
        borderRadius: '8px',
        marginTop: '12px',
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            border: '1px solid #ddd', // Default border color
            '& fieldset': {
                borderColor: '#ddd', // Add your desired border color here
            },
            '&:hover fieldset': {
                borderColor: '#aaa', // Add your desired hover color here
            },
            '&.Mui-focused fieldset': {
                borderColor: '#1976d2', // Add your desired focus color here
            },
        },
    },
    saveButton: {
        marginTop: '170px',
        width: '100%', // Make the button full width
        height: '48px'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '356px', // Adjust the minimum height of the DialogContent
    },
    icon: {
        marginRight: '8px',
    },

}));

const YouGot = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const locationPath = useLocation();
    const [amount, setAmount] = useState("");
    const [amountDate, setAmountDate] = useState(new Date());
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    // console.log("customerphone = ", customerphone)

    const {
        ktCustomerResponse,
        isKTCustomerSuccess,
        isKTCustomerError,
        isKTCustomerFetching,
    } = useSelector((state) => state.updateKTCustomer);

    React.useEffect(() => {
        console.log(" START isKTCustomerSuccess = ", isKTCustomerSuccess)
        if (isKTCustomerSuccess) {
          setSnackbarOpen(true);
          dispatch(updateKTCustomerReset());
        }
      }, [isKTCustomerSuccess]);

      const handleRawChange = (e) => {
        // Parse the raw input and set the amountDate accordingly
        const parsedDate = new Date(e.target.value);
        setAmountDate(parsedDate);
    };


    const handleSave = () => {
        const formattedDate = format(amountDate, 'yyyy-MM-dd HH:mm:ss');
        
        let formData = new FormData();
        
        formData.append("phoneno", locationPath?.pathname.split("/")?.[2]);
        formData.append("payment", amount);
        formData.append("paymentdate", formattedDate);
        formData.append("type", "You Got");

        dispatch(
            updateKTCustomer({ userid: localStorage.getItem("userid"), formData })
        );
        console.log("called dispatch")
        console.log("isKTCustomerSuccess = ", isKTCustomerSuccess)

        // if (isKTCustomerSuccess) {
        //     console.log("Customer updated")
        //     setSnackbarOpen(true);
        // }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
        updateKTCustomerReset();
        // navigate('/kt/customer/list');
        navigate(`/detail/${locationPath?.pathname.split("/")?.[4]}`)
    };

    return (
        <div className="wrapper">
            <ResponsiveDrawer {...props} />
            <section className="containers">
                <TopHeaderScreen {...props} />
                <div class="content content-wrap">
                    <div class="content-mrg hasScreen">
                        <Button onClick={() => navigate(-1)} className={classes.backBtn}>
                            <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                            <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
                        </Button>
                        <div className={classes.root}>
                            <Typography variant="h6">You got ₹{amount} from {decodeURIComponent(locationPath?.pathname.split("/")?.[3])}</Typography>
                            <TextField
                                label="Enter amount"
                                fullWidth
                                variant="outlined"
                                autoComplete="off"
                                className={classes.roundedTextField}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start" style={{ marginRight: '8px' }}>
                                            ₹
                                        </InputAdornment>
                                    ),
                                }}
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                            <DatePicker
                                selected={amountDate}
                                onChange={(date) => setAmountDate(date)}
                                dateFormat="dd/MM/yyyy"
                                onChangeRaw={handleRawChange}
                                // dateFormat="yyyy-MM-dd HH:mm:ss"
                                className={classes.roundedTextField}
                                customInput={
                                    <TextField
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start" className={classes.icon}>
                                                    <CalendarMonthIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                }
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                className={`${classes.saveButton} ${classes.roundedTextField}`}
                                onClick={handleSave}
                                disabled={snackbarOpen}
                            >
                                Save
                            </Button>

                            <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={handleCloseSnackbar}>
                                <MuiAlert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                                    Payment completed
                                </MuiAlert>
                            </Snackbar>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default YouGot;
