/** @format */

const GET_CAMPAIGN_FETCHING_DATA = "GET_CAMPAIGN_FETCHING_DATA";
const GET_CAMPAIGN_FETCHING_DATA_FAILURE =
  "GET_CAMPAIGN_FETCHING_DATA_FAILURE";
const GET_CAMPAIGN_FETCHING_DATA_RESET =
  "GET_CAMPAIGN_FETCHING_DATA_RESET";
const GET_CAMPAIGN_FETCHING_DATA_SUCCESS =
  "GET_CAMPAIGN_FETCHING_DATA_SUCCESS";
const GET_CAMPAIGN_FETCHING_DATA_ATTEMPT =
  "GET_CAMPAIGN_FETCHING_DATA_ATTEMPT";
const getCampaign = (payload) => ({
  type: GET_CAMPAIGN_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

// const getOrganizationReset = (payload) => ({
//   type: GET_ORGANIZATION_FETCHING_DATA_RESET,
//   payload: payload,
// });

export {
  GET_CAMPAIGN_FETCHING_DATA,
  GET_CAMPAIGN_FETCHING_DATA_FAILURE,
  GET_CAMPAIGN_FETCHING_DATA_RESET,
  GET_CAMPAIGN_FETCHING_DATA_SUCCESS,
  GET_CAMPAIGN_FETCHING_DATA_ATTEMPT,
  getCampaign,
//   getOrganizationReset,
};
