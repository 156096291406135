/** @format */

import { call, put } from "redux-saga/effects";
import { get } from "lodash";

import {
  REWARDS_REDEEMED_LIST_FETCHING_DATA,
  REWARDS_REDEEMED_LIST_FETCHING_DATA_SUCCESS,
  REWARDS_REDEEMED_LIST_FETCHING_DATA_FAILURE,
} from "../action/RewardsRedeemedList";
import { Api } from "../../services";

const myAPI = new Api();

export function* rewardsRedeemedList(action) {
  yield put({
    type: REWARDS_REDEEMED_LIST_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.rewardsRedeemedList, action.payload);
    const payload = get(response, "data");
    // console.log(payload)
    yield put({
      type: REWARDS_REDEEMED_LIST_FETCHING_DATA_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({ type: REWARDS_REDEEMED_LIST_FETCHING_DATA_FAILURE });
  }
}
