import React from "react";
import {
  Avatar,
  Button,
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";

import { VideoCard } from "./VideoCard";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { CheckCircle } from "@mui/icons-material";
import ResponsiveDrawer from "./Header";
import { CopyRight } from "./CopyRight";
import TopHeaderScreen from "./TopHeader1";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import DeleteConfirmation from "./DeleteConfirmation";
import { makeStyles } from "@material-ui/core";
import { FixedFooterIcon } from "./FixedFooterIcon";
import { isArray, isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    fontSize: 15,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    color: "#949494",
    // color: "black",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  emailinput: {
    // textTransform: "capitalize",
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    color: "#949494",
    // color: "black",
    fontSize: 15,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "block",
    fontSize: 16,
    marginTop: 16,
    padding: 10,
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
  },
  formGroup2: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    // backgroundColor: "#fff",
    backgroundColor: "#FFFFFF",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    marginBottom: 10,
    [theme.breakpoints.only("xl")]: {
      width: "25%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
}));

const DeleteFileDetails = (props) => {
  const location = useLocation();
  const [campregisterlist, setCampRegisterList] = useState([]);
  const classes = useStyles();
  
  const getCampRegistrationList = async () => {
    try {
      // const res = await axios.get(
      //   `/camp/video/${selectedCategory}/${localStorage.getItem("userid")}`
      // );
      const res = await axios.get(
        `/list/files/campaign/${location?.pathname.split("/")?.[2]}/${
          location?.pathname.split("/")?.[3]
        }/${location?.pathname.split("/")?.[4]}`
      );
      setCampRegisterList(res?.data);
      
      return res;
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    getCampRegistrationList();
  }, [location?.pathname.split("/")?.[2]]);
  

  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const navigate = useNavigate();
  const locationPath = useLocation();
  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };
 
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  
  const handleDeleteFile = async () => {
    // setDisplayConfirmationModal(true);
    const payload = {
      phoneno: location?.pathname.split("/")?.[4],
      campid: location?.pathname.split("/")?.[3],
      id: location?.pathname.split("/")?.[2],
    };
    try {
      const res = await axios.put(
        // `/user/delete/${localStorage.getItem("userid")}`,
        `/camp/file/delete`,
        payload
      );
      // navigate("/reviewcampaign");
      // setuserdeletestat(res?.data);
      setDisplayConfirmationModal(false);
      navigate("/myongoingcampaigns");
      return res;
    } catch (err) {
      throw err;
    }
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Delete File
            </div>
            <form autoComplete="off">
              <div
                style={{
                  border: "1px solid #dadce0",
                  borderRadius: 8,
                  padding: "13px 30px 40px 30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <div className={classes.formGroup2} style={{ width: "100%" }}>
                    <label className={classes.label}>User Name</label>
                    <input
                      disabled
                      // value={volunteerDetails?.fullname}
                      value={campregisterlist?.username}
                      type="text"
                      className={classes.input}
                    />
                  </div>
                  <div className={classes.formGroup2} style={{ width: "100%" }}>
                    <label className={classes.label}>User organization</label>
                    <input
                      disabled
                      value={campregisterlist?.userorg}
                      type="text"
                      className={classes.emailinput}
                    />
                  </div>
                  <div className={classes.formGroup2} style={{ width: "100%" }}>
                    <label className={classes.label}>File uploaded on</label>
                    <input
                      disabled
                      // value={volunteerDetails?.fullname}
                      value={campregisterlist?.timestamp}
                      type="text"
                      className={classes.input}
                      placeholder="Your answer"
                    />
                  </div>
                </div>
              </div>
            </form>
            {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "0px 5px 0px 0px",
              }}
            > */}
            <div className="centerAlign" style={{ padding: "16px",marginRight:"10px",float:"left",width:"100%"}}>
              {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
              {
                isEmpty(campregisterlist) ?
              <Button
                disabled
                // onClick={() => setDisplayConfirmationModal(true)}
                style={{
                  marginTop: 8,
                  // marginLeft:600,
                  width: "200px",
                  // background: "#E88A00",
                  background: "gray",
                  borderRadius: 20,
                  color: "#fff",
                  fontFamily: "Poppins !important",
                  alignSelf: "center",
                  marginRight:"5px",
                }}
              >
                <span>Delete</span>
              </Button>
              : 
              <Button
                onClick={() => setDisplayConfirmationModal(true)}
                style={{
                  marginTop: 8,
                  // marginLeft:600,
                  width: "200px",
                  // background: "#E88A00",
                  background: "red",
                  borderRadius: 20,
                  color: "#fff",
                  fontFamily: "Poppins !important",
                  alignSelf: "center",
                  marginRight:"5px",
                }}
              >
                <span>Delete</span>
              </Button>
}

              <Button
                onClick={() => navigate(-1)}
                style={{
                  marginTop: 8,
                  // marginLeft: 600,
                  width: "200px",
                  background: "grey",
                  borderRadius: 20,
                  color: "#fff",
                  fontFamily: "Poppins !important",
                  alignSelf: "center",
                }}
              >
                <span>Cancel</span>
              </Button>
            </div>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon/>
        <DeleteConfirmation
          showModal={displayConfirmationModal}
          confirmModal={handleDeleteFile}
          hideModal={hideConfirmationModal}
          message={"Are you sure you want to delete this file?"}
        />
      </section>
    </div>
  );
};

export default DeleteFileDetails;
