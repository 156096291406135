/** @format */

// reducers
import {
    ADD_REWARDS_FETCHING_DATA,
    ADD_REWARDS_FETCHING_DATA_FAILURE,
    ADD_REWARDS_FETCHING_DATA_RESET,
    ADD_REWARDS_FETCHING_DATA_SUCCESS,
    
  } from "../action/addRewardsAction";
  
  let initialState = {
    addrewardsResponse: {},
    addrewardsSuccess: false,
    addrewardsError: false,
    addrewardsFetching: false,
  };
  
  export default function OnboardReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_REWARDS_FETCHING_DATA:
            return {
            ...state,
            addrewardsFetching: true,
            addrewardsError: false,
            addrewardsSuccess: false,
        };
        case ADD_REWARDS_FETCHING_DATA_FAILURE:
            return {
            ...state,
            addrewardsFetching: false,
            addrewardsError: action.payload,
            addrewardsSuccess: false,
        };
        case ADD_REWARDS_FETCHING_DATA_SUCCESS:
            return {
            ...state,
            addrewardsFetching: false,
            addrewardsError: false,
            addrewardsResponse: action.payload,
            addrewardsSuccess: true,
        };
        case ADD_REWARDS_FETCHING_DATA_RESET:
            return {
            ...state,
            addrewardsFetching: true,
            addrewardsError: false,
            addrewardsSuccess: false,
        };
      
      default:
        return state;
    }
  }
  