/** @format */

import { call, put } from "redux-saga/effects";
import { get } from "lodash";

import {
    ADD_REWARDS_FETCHING_DATA,
    ADD_REWARDS_FETCHING_DATA_FAILURE,
    ADD_REWARDS_FETCHING_DATA_SUCCESS,
} from "../action/addRewardsAction";
import { Api } from "../../services";

const myAPI = new Api();

export function* addRewards(action) {
  yield put({
    type: ADD_REWARDS_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.addRewards, action.payload);
    yield put({
      type: ADD_REWARDS_FETCHING_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    yield put({ type: ADD_REWARDS_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}