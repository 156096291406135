/** @format */

const VERIFY_OTP_FETCHING_DATA = "VERIFY_OTP_FETCHING_DATA";
const VERIFY_OTP_FETCHING_DATA_FAILURE = "VERIFY_OTP_FETCHING_DATA_FAILURE";
const VERIFY_OTP_FETCHING_DATA_RESET = "VERIFY_OTP_FETCHING_DATA_RESET";
const VERIFY_OTP_FETCHING_DATA_SUCCESS = "VERIFY_OTP_FETCHING_DATA_SUCCESS";
const VERIFY_OTP_FETCHING_DATA_ATTEMPT = "VERIFY_OTP_FETCHING_DATA_ATTEMPT";
const verifyOTP = (payload) => ({
  type: VERIFY_OTP_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const verifyOTPReset = (payload) => ({
  type: VERIFY_OTP_FETCHING_DATA_RESET,
});
export {
  VERIFY_OTP_FETCHING_DATA,
  VERIFY_OTP_FETCHING_DATA_FAILURE,
  VERIFY_OTP_FETCHING_DATA_RESET,
  VERIFY_OTP_FETCHING_DATA_SUCCESS,
  VERIFY_OTP_FETCHING_DATA_ATTEMPT,
  verifyOTP,
  verifyOTPReset,
};
