/** @format */

// reducers
import {
  GET_ORGANIZATION_FETCHING_DATA,
  GET_ORGANIZATION_FETCHING_DATA_FAILURE,
  GET_ORGANIZATION_FETCHING_DATA_RESET,
  GET_ORGANIZATION_FETCHING_DATA_SUCCESS,
} from "../action/getOrganization";

let initialState = {
  response: {},
  isSuccess: false,
  isError: false,
  isFetching: false,
};

export default function GetOrganizationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORGANIZATION_FETCHING_DATA:
      return {
        ...state,
        isFetching: true,
        isError: false,
        isSuccess: false,
      };
    case GET_ORGANIZATION_FETCHING_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: action.payload,
        isSuccess: false,
      };
    case GET_ORGANIZATION_FETCHING_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
        response: action.payload,
        isSuccess: true,
      };

    case GET_ORGANIZATION_FETCHING_DATA_RESET:
      return initialState;

    default:
      return state;
  }
}
