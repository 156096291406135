/** @format */

import React, { useEffect, useState } from "react";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import Header from "../../components/Header";
import ColorMap from "../../assets/color-map.png";
import { connect, useDispatch, useSelector  } from "react-redux";
import { bindActionCreators } from "redux";

import { campLeaderBoard } from "../../redux/action/CampLeaderBoardAction";
// import { useHistory } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { myRank } from "../../redux/action/MyRankAction";
import { CopyRight } from "../../components/CopyRight";
import ResponsiveDrawer from "../../components/Header";
import TopHeaderScreen from "../../components/TopHeader1";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ProfileNoPhoto from "../../assets/profileNoPhoto.png";
import { isEmpty, isArray } from "lodash";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  backBtn: {
    display: "flex",
    alignItems: "center",
  },
  homecard: {
    width: 120,
    margin: "0 7px 10px",
    // maxWidth: 250,
    // borderRadius: 4,
    boxShadow: "none",
    // backgroundColor: "whitesmoke",
  },
  cardContainer: {
    width: 200,
    margin: "0 7px 10px",
    // maxWidth: 250,
    borderRadius: 4,
    boxShadow: "none",
    border: "3px solid #000",
    height: 225,
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  mainContent: {
    padding: "90px 0 0 0",
    [theme.breakpoints.up("sm")]: {
      padding: "90px 0 0 220px",
    },
  },
  container: {
    // padding: "59px 20px",
    padding: "59px 20px 3px 20px",
    margin: "10px 0",
    // backgroundImage: "linear-gradient(#da8d0f, white)",
  },
  cardImg: {
    borderRadius: "5px 5px 0 0",
    height: 100,
    resizeMode: "content",
  },
  cardImgForLvl: {
    borderRadius: "5px 5px 0 0",
    height: 50,
    resizeMode: "content",
  },
  cardTitleForName: {
    background: "#da8d0f",
    color: "#000",
    zIndex: 99,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    boxShadow: "0px 5px 10px rgba(0,0,0,0.2)",
  },
  offerCardImg: {
    borderRadius: "5px 5px 0 0",
    height: 120,
    resizeMode: "content",
  },
  imageWrapper: {
    // background: '#fff',
    padding: 16,
    height: 150,
  },
  table: {
    fontWeight: "bold",
    textAlign: "center",
  },
  offerCardText: {
    fontSize: 16,
    padding: "0 16px",
  },
  offerCardTitle: {
    width: "calc(50% - 14px)",
    textAlign: "center",
    fontSize: 16,
    padding: "0 16px",
  },
  tagWrapper: {
    padding: 3,
    backgroundColor: "#FF5733",
    color: "white",
    margin: 4,
    borderRadius: 4,
    textAlign: "center",
  },
  pointsWrapper: {
    padding: 3,
    margin: 4,
    borderRadius: 4,
    textAlign: "center",
  },
  subtitleWrapper: {
    textAlign: "center",
  },
}));


const CampLeaderBoardPage = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationPath = useLocation();
 
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const { isSuccess: campLeaderBoardSuccess, response: campLeaderBoardResponse } =
    useSelector((state) => state.campLeaderBoard);
 
  useEffect(() => {
    // dispatch(campLeaderBoard(localStorage.getItem("userid"), locationPath?.pathname.split("/")?.[3]));
    dispatch(
      campLeaderBoard({
      phoneno: localStorage.getItem("userid"),
      campid: locationPath?.pathname.split("/")?.[3],
    })
    );
    // dispatch(myRank(localStorage.getItem("userid")));
  }, [dispatch]);

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div className={classes.container}>
          <Button onClick={() => navigate(-1)} className={classes.backBtn}>
            <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
            <span style={{ paddingLeft: 3, fontSize: 14, color: "#1976d2" }}>
              Back
            </span>
          </Button>
          <div>
            <span
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Campaign Leader Board
            </span>
          </div>
        </div>
        <div class="cyagg-sec-space">
          <div class="container">
            <div class="row">
              {/* <div class="col-6"> */}
              <div class="row" style={{marginLeft:"30px", marginRight:"7px"}}>
                <div class="row">
                  <h4 class="site-title" style={{color:"blue"}}>{campLeaderBoardResponse?.[0]?.campname}</h4>
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div
                  class="col-md-4 hide-on-mobile"
                  style={{ paddingRight: "0px" }}
                >
                  <div class="leaderboard-left">
                    <div class="text-center">
                      <h5>Top Player</h5>
                      {
                        campLeaderBoardResponse?.[0]?.profilephoto != "" ? (
                          <img
                            src={campLeaderBoardResponse?.[0]?.profilephoto}
                          ></img>
                        ) : (
                          <span>
                            <AccountCircleIcon fontSize="large" />
                          </span>
                        )
                      }
                      <span style={{ fontSize: 36, padding: 24 }}>
                        {campLeaderBoardResponse &&
                          campLeaderBoardResponse?.[0]?.firstname}{" "}
                        {campLeaderBoardResponse?.[0]?.lastname}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-8 col-12" style={{ paddingLeft: "0px" }}>
                  <div class="u-custom-scrollbar-one-camp">
                    <table class="leader-b-table">
                      <thead>
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            {" "}
                            Rank
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Top Player Name
                          </th>
                          
                          <th scope="col" style={{ textAlign: "center" }}>
                            Total Points
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {campLeaderBoardSuccess &&
                          campLeaderBoardResponse?.map((row) => (
                            <tr>
                              <td
                                data-label="Account"
                                style={{ textAlign: "center" }}
                              >
                                {row?.rank}
                              </td>
                              <td data-label="Due Date">
                                <div class="top-player-box">
                                  <div class="top-player-box" style={{textAlign:"center"}}>
                                    {row?.profilephoto != "" ? (
                                      <img src={row?.profilephoto}></img>
                                    ) : (
                                      <img src={ProfileNoPhoto}></img>
                                    )}
                                    </div>
                                    <div class="top-player-box" style={{textAlign:"center"}}>
                                    
                                    {row?.firstname === "ME" ? (
                                      <span
                                        style={{
                                          color: "orange",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {row?.firstname}
                                      </span>
                                    ) : (
                                      <span>{row?.firstname}</span>
                                    )}
                                  </div>
                                </div>
                              </td>
                              
                              <td
                                data-label="Period"
                                style={{ textAlign: "center" }}
                              >
                                {row?.rewardpoint}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CopyRight />
      </section>
    </div>
  );
};
export default CampLeaderBoardPage;