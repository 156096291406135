/** @format */

const DASHBOARD_REWARD_FETCHING_DATA = 'DASHBOARD_REWARD_FETCHING_DATA';
const DASHBOARD_REWARD_FETCHING_DATA_FAILURE = 'DASHBOARD_REWARD_FETCHING_DATA_FAILURE';
const DASHBOARD_REWARD_FETCHING_DATA_RESET = 'DASHBOARD_REWARD_FETCHING_DATA_RESET';
const DASHBOARD_REWARD_FETCHING_DATA_SUCCESS = 'DASHBOARD_REWARD_FETCHING_DATA_SUCCESS';
const DASHBOARD_REWARD_FETCHING_DATA_ATTEMPT = 'DASHBOARD_REWARD_FETCHING_DATA_ATTEMPT';
// const volDashBoardReward = (payload, userid) => ({
// 	type: DASHBOARD_REWARD_FETCHING_DATA_ATTEMPT,
// 	payload: {userid, ...payload},
// });
const volDashBoardReward = (payload) => ({
	type: DASHBOARD_REWARD_FETCHING_DATA_ATTEMPT,
	payload: payload,
});

export { DASHBOARD_REWARD_FETCHING_DATA, DASHBOARD_REWARD_FETCHING_DATA_FAILURE, DASHBOARD_REWARD_FETCHING_DATA_RESET, DASHBOARD_REWARD_FETCHING_DATA_SUCCESS, DASHBOARD_REWARD_FETCHING_DATA_ATTEMPT, volDashBoardReward };
