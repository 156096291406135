/** @format */

import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  GridList,
  GridListTile,
  Tab,
  Tabs,
  withStyles,
} from "@material-ui/core";
import {
  Avatar,
  Badge,
  CircularProgress,
  Collapse,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ExpandMore,
  HomeOutlined,
  MicNone,
  Notifications,
  SettingsOutlined,
} from "@material-ui/icons";
import _ from "lodash";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import Toolbar from "@mui/material/Toolbar";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
// import "../../scss/containerStyles/_home.scss";
// import "../../App.css";
// import "../../cyaag-style.css";
// import "../../bootstrap.min.css";
// import "../../icon-font.css";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
// import { userProfile, userProfileReset } from "../../redux/action/UserProfileActions";
import {
  userProfile,
  userProfileReset,
} from "../redux/action/UserProfileActions";
import { CopyRight } from "./CopyRight";
import { getVolunteerCount } from "../redux/action/volunteerCountAction";
import { volunteerMenuItems } from "../utils/dict";
import ResponsiveDrawer from "./Header";
import { HomeCard } from "./Card";
import axios from "axios";
import { API_URL } from "../services/Constants";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { socialLoginReset } from "../redux/action/loginAction";
import { getUserProfileImage } from "../redux/action/userProfileImage";
import rewardIcon from "../assets/rewardIconNav.png";
import { volDashBoardReward } from "../redux/action/DashboardReward";
import GradeSharpIcon from "@mui/icons-material/GradeSharp";
import TopHeaderScreen from "./TopHeader1";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { FixedFooterIcon } from "./FixedFooterIcon";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const dispatch = useDispatch();

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 100px 330px",
    },
  },
  homeScreenContainer: {
    padding: "0 0 0 20px",
    zIndex: 100,
  },
  welcomeMessage: {
    fontWeight: "bold",
    marginBottom: 10,
    margin: "10px 0",
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
  },
  welcomeAndFilter: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    textTransform: "uppercase",
    padding: "10px 0",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  filterIcon: {
    fontSize: 26,
    color: "#F8B34C",
    marginLeft: 28,
  },
  homeCards: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    margin: "0 -7px",

    // backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    display: "grid",
    // gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: "1rem",
    [theme.breakpoints.only("xl")]: {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    [theme.breakpoints.only("lg")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    [theme.breakpoints.only("md")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.only("sm")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    [theme.breakpoints.only("xs")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  maxWidthContainer: {
    padding: "25px 0",
    maxWidth: 500,
    margin: "0 auto",
    // borderTop: '2px solid #F8B34C',
  },
  gradientHeading: {
    fontSize: 25,
    fontWeight: "bold",
  },
  rewards: {
    display: "flex",
    justifyContent: "center",
    color: "#F8B34C",
    paddingBottom: 13,
  },
  rewardsText: {
    fontSize: 25,
    fontWeight: "bold",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 9,
  },
  secondaryBorder: {
    padding: "5px 0",
    borderBottom: "2px solid #F8B34C",
  },
  myImpacts: {
    padding: "15px 0 0 0",
    fontSize: 14,
    textAlign: "center",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  myImpactsWrap: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 -8px",
    outline: "none",
  },
  myImpact: {
    width: "150px",
    margin: "0 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    outline: "0 !important",
    border: "none",
  },
  myImpactImgWrap: {
    width: 52,
    height: 50,
    margin: "0 auto 5px",
  },
  myImpactImg: {
    width: "100%",
  },
  myImpactTxt: {
    display: "block",
  },
  myCommunity: {
    borderBottom: "2px solid #F8B34C",
    padding: "15px 0",
    fontSize: 20,
    color: "#F8B34C",
  },
  myCommunityContentWrap: {
    display: "flex",
    justifyContent: "space-between",
  },
  myCommunityImg: {
    width: 100,
  },
  inviteBtn: {
    paddingTop: 15,
    width: 150,
  },
  myCommunityPoints: {
    listStyle: "none",
    margin: "0 0 0 10px",
    padding: 0,
  },
  inviteButton: {
    fontSize: 9,
    fontWeight: "bold",
    lineHeight: 1,
  },
  myRegion: {
    borderBottom: "2px solid #F8B34C",
    padding: "15px 0",
  },
  mapImg: {
    width: 130,
    margin: "0 auto",
  },
  myYaags: {
    padding: "15px 0",
  },

  bottomNavigation: {
    position: "absolute",
    width: "100%",
  },
  tabName: {
    // color: "#fff",
    color: "#00000",
    textTransform: "none",
    // ariaSelected: "true",
  },
  pointBox: {
    // top: "22px",
    // left: "200px",
    // top: "22%",
    minWidth: "65px",
    height: "39px",
    background: "#1A524D 0% 0% no-repeat padding-box",
    borderRadius: "100px",
    opacity: "90%",
    color: "#FFFFFF",
    fontSize: 15,
    fontWeight: "bold",
  },
  rewardclick: {
    position: "relative",
    padding: 7,
    paddingRight: 25,
    color: "black",
    display: "flex",
    cursor: "pointer",
  },
  large: {
    width: "50px !important",
    height: "50px !important",
    flexDirection: "row",
    // display: "flex",
    // justifyContent: "center",
    // textAlign: "center",
  },
  mouseHover: {
    "&:hover": {
      textDecoration: "underline",
      color: "blue",
    },
  },
  cardTitleText: {
    textTransform: "capitalize",
    fontSize: 26,
    fontWeight: "400",

    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      fontWeight: "400",
    },
  },
  goalsWrapper: {
    backgroundColor: "rgba(255,255,255,0.9)",
    borderRadius: 10,
    padding: 16,
    marginTop: 20,
    // boxShadow: "1px 3px 10px rgba(0,0,0,0.3)",
    boxShadow: "0.5px 0.5px 5px rgba(0,0,0,0.3)",
  },
}));

const NotificationArchive = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [campaignList, setCampaignList] = useState([]);
  const [rewarddash, setRewardDash] = useState([]);
  const [checked, setChecked] = useState(false);
  const [value, setValue] = React.useState(0);
  const [image, setImage] = React.useState(null);
  const [activeTrue, setActiveTrue] = React.useState(false);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  
  const { isSuccess: myRewardSuccess, response: myRewardResponse } =
    useSelector((state) => state.volDashBoardReward);

  const userid = localStorage.getItem("userid");
  const clearStorage = () => {
    localStorage.clear();
    dispatch(userProfileReset());
    dispatch(socialLoginReset());
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isActive, setActive] = useState(false);

  const [loading, setLoading] = React.useState(false);

  const handleChangeFile = (e) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("profilephoto", e.target.files[0]);
    dispatch(
      getUserProfileImage({ userId: localStorage.getItem("userid"), formData })
    );
    setImage(e.target.files[0]);
  };

  const updateNotificationFlag = async () => {
    try {
      let formData = new FormData();
      // formData.append("notifyflag", "true");
      formData.append("flag", "true");

      const res = await axios({
        url: `/notification/flag/${profileResponse?.phoneno}`,
        method: "put",
        data: formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      dispatch(userProfile(localStorage.getItem("userid")));
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getVolunteerCamp = async () => {
    try {
      const res = await axios.get(
        `vol_dashboard/camp/${profileResponse?.orgrole}/${profileResponse?.organization}`
      );
      setCampaignList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };
  const getMyReward = async () => {
    try {
      const res = await axios.get(`/vol_dashboard/myreward/${userid}`);
      setRewardDash(res?.data);
      dispatch(volDashBoardReward(res?.data, res?.data?.[0]?.phoneno));
      return res;
    } catch (err) {
      throw err;
    }
  };

  

  useEffect(() => {
    dispatch(userProfile(userid));
    dispatch(getVolunteerCount());
    getVolunteerCamp();
    getMyReward();
  }, []);
  
  return (
    <div className="wrapperNotificationArchive">
      <ResponsiveDrawer {...props} />
      <section className="containers" style={{ overflow: "hidden" }}>
        <TopHeaderScreen {...props} />
        <div class="content content-wrap" style={{ marginBottom: 50 }}>
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>

            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Notifications
            </div>

            <div>
              <div
                className={classes.cardTitleText}
                style={{
                  // position: "absolute",
                  width: 330,

                  // height: "50vh",
                  right: "0%",
                  top: 0,
                  boxShadow: "1px 2px 10px rgba(0,0,0,0.2)",
                  borderRadius: 8,
                  // overflow: "scroll",
                  background: "#fff",
                }}
              >
                <div style={{ marginTop: "0px" }}>
                  {_.isEmpty(profileResponse?.notificationarchive) ? (
                    <div>
                      <span
                        style={{
                          // background: "#fff",
                          // float: "left",
                          width: "100%",
                          // width: "300px",

                          padding: "10px 12px",
                        }}
                      >
                        No notifications yet
                      </span>
                    </div>
                  ) : (
                    profileResponse?.notificationarchive?.map((item, index) => (
                      <div>
                        <div
                          className={classes.goalsWrapper}
                          style={{
                            background: "#fff",
                            float: "left",
                            width: 336,
                            padding: "10px 12px",
                            borderBottom: "1px solid #ccc",
                          }}
                        >
                          <div
                            class=""
                            style={{
                              float: "left",
                              width: "50px",
                              marginRight: "10px",
                            }}
                          >
                            <img
                              src={item.logo}
                              className={classes.large}
                              style={{ borderRadius: "8px" }}
                            />
                          </div>
                          <div
                            class=""
                            style={{
                              color: "#000",
                              float: "left",
                              width: "calc(100% - 60px)",
                            }}
                            onClick={() => {
                              item.type === "campaign" && item.status === "Active"
                                ? // navigate(`/campaigndetails/${item.campid}/${profileResponse?.orgrole}/${profileResponse?.organization}`)
                                  navigate(
                                    `/participate/${item.campid}/${profileResponse?.phoneno}`
                                  )
                                  : item.type === "campaign" && item.status === "Completed" ?
                                      navigate(
                                        `/home`
                                      ) 
                                : navigate(`/mypoints`);
                            }}
                          >
                            <span
                              style={{
                                color: "#000",
                                // display: "flex",
                                display: "inline-block",
                                // justifyContent: "flex-end",
                                fontSize: 16,
                                color: "grey",
                                display: "block",
                              }}
                            >
                              {moment(item?.createdate).fromNow()}
                            </span>
                            <span
                              style={{
                                fontSize: "20px",
                                cursor: "pointer",
                                display: "block",
                              }}
                              className={classes.mouseHover}
                            >
                              {/* <span style={{ fontSize: "20px", display: "block" }}> */}
                              {item?.msg}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
                {/* <b></b> */}
              </div>
            </div>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
};

export default NotificationArchive;
