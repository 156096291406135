import React from 'react'
import { Modal, Button } from "react-bootstrap";
 
const ConfirmationDialog = ({ showModal, hideModal, confirmModal, message }) => {

    return (
        <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body><div className="alert alert-danger">{message}</div></Modal.Body> */}
        <Modal.Body><div className="alert alert-primary">{message}</div></Modal.Body>
        
        <Modal.Footer>
          <Button variant="default" onClick={hideModal}>
            OK
          </Button>
          {/* <Button variant="danger" onClick={() => confirmModal(type, id) }> */}
          {/* <Button variant="danger" onClick={() => confirmModal() }>
            Delete
          </Button> */}
        </Modal.Footer>
      </Modal>
    )
}
 
export default ConfirmationDialog;