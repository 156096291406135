const VALIDATE_REWARD_FETCHING_DATA = "VALIDATE_REWARD_FETCHING_DATA";
const VALIDATE_REWARD_FETCHING_DATA_FAILURE =
  "VALIDATE_REWARD_FETCHING_DATA_FAILURE";
const VALIDATE_REWARD_FETCHING_DATA_RESET =
  "VALIDATE_REWARD_FETCHING_DATA_RESET";
const VALIDATE_REWARD_FETCHING_DATA_SUCCESS =
  "VALIDATE_REWARD_FETCHING_DATA_SUCCESS";
const VALIDATE_REWARD_FETCHING_DATA_ATTEMPT =
  "VALIDATE_REWARD_FETCHING_DATA_ATTEMPT";
const validateReward = (payload) => ({
  type: VALIDATE_REWARD_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

export {
  VALIDATE_REWARD_FETCHING_DATA,
  VALIDATE_REWARD_FETCHING_DATA_FAILURE,
  VALIDATE_REWARD_FETCHING_DATA_RESET,
  VALIDATE_REWARD_FETCHING_DATA_SUCCESS,
  VALIDATE_REWARD_FETCHING_DATA_ATTEMPT,
  validateReward,
};
