import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { makeStyles, ListItemIcon, Checkbox, ListItemText } from "@material-ui/core";
import {
    Avatar,
    Button,
    CircularProgress,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import _, { values } from "lodash";
// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";

import { useLocation, useNavigate } from "react-router-dom";
import TopHeaderScreen from "../../components/TopHeader1";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import { LeftCircleFilled } from "@ant-design/icons";

const useStyles = makeStyles((theme) => ({
    homeSreenMainContent: {
        padding: "80px 0 100px 0px",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("sm")]: {
            padding: "80px 30px 100px 330px",
        },
    },

    input: {
        width: "100%",
        border: "none",
        padding: 10,
        display: "block",
        borderRadius: 6,
        fontSize: 15,
        height: 54,
        boxShadow: "0px 3px 6px #00000029",
        color: "#949494",
        // color: "black",
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        },
    },
    emailinput: {
        // textTransform: "capitalize",
        width: "100%",
        border: "none",
        padding: 10,
        display: "block",
        borderRadius: 6,
        height: 54,
        boxShadow: "0px 3px 6px #00000029",
        // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        color: "#949494",
        // color: "black",
        fontSize: 15,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        },
    },

    goalButton: {
        fontSize: 15,
        fontWeight: "bold",
        lineHeight: 1.2,
        marginLeft: 40,
    },

    label: {
        "& .css-u4tvz2-MuiFormLabel-root": {
            fontFamily: "Poppins",
        },
        fontFamily: "Poppins",
        display: "block",
        fontSize: 16,
        marginTop: 16,
        padding: 10,
        fontWeight: "500",
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
        },
    },
    label1: {
        "& .css-u4tvz2-MuiFormLabel-root": {
            fontFamily: "Poppins",
        },
        fontFamily: "Poppins",
        fontSize: 14,
        fontWeight: "600",
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
        },
    },
    formGroup: {
        "& .react-datepicker__input-container": {
            "& input": {
                height: 40,
            },
        },

        transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
        backgroundColor: "#fff",

        borderRadius: "8px",
        marginBottom: "4px",
        padding: "0 16px 0px 16px",
        pageBreakInside: "avoid",
        [theme.breakpoints.only("xl")]: {
            width: "25%"
          },
          [theme.breakpoints.only("lg")]: {
            width: "50%"
          },
          [theme.breakpoints.only("md")]: {
            width: "50%"
          },
          [theme.breakpoints.only("sm")]: {
            width: "100%"
          },
          [theme.breakpoints.only("xs")]: {
            width: "100%"
          },
    },
    formGroup1: {
        transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
        backgroundColor: "#fff",

        borderRadius: "8px",
        marginBottom: "4px",
        padding: "0 16px 0px 16px",
        pageBreakInside: "avoid",
    },
}));

function RewardDeleteScreen(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const locationPath = useLocation();
    // const [type, setType] = useState(null);
    // const [id, setId] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    // const [deleteMessage, setDeleteMessage] = useState(null);

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    const [volunteerDetails, setvolunteerDetails] = useState({
        // fullname: "",
        campid: "",
        mpid: "",
        points: "",
        rewardtype: "",
        sponsor: "",
        title: "",
        subtitle: "",
        ownership: "",
    });

    // const getORGRole = async () => {
    //     try {
    //         const res = await axios.get(`/orgrole/${localStorage.getItem("userid")}`);
    //         setORGRole(res?.data);
    //         return res;
    //     } catch (err) {
    //         throw err;
    //     }
    // };

    const handleDeleteUser = async () => {
        // setDisplayConfirmationModal(true);
        const payload = {
          // campid: locationPath?.state?.campid,
          // createdate: locationPath?.state?.createdate,
          // reviewstatus: reviewstatus,
          // reviewcomments: reviewComments,
          // phoneno: localStorage.getItem("userid")
          mpid: locationPath?.state.mpid,
    
        };
        try {
          const res = await axios.put(
            // `/user/delete/${localStorage.getItem("userid")}`,
            `/marketplace/reward/delete`,
            payload
          );
          // navigate("/reviewcampaign");
          // setuserdeletestat(res?.data);
          setDisplayConfirmationModal(false);
          navigate("/reviewrewards");
          return res;
        } catch (err) {
          throw err;
        }
      };

    
    const [response, setResponse] = useState({});
    const { isSuccess: profileSuccess, response: VolunteerResponse } = useSelector(
        (state) => state.profile
    );

    useEffect(() => {
        if (locationPath?.state) {
            setvolunteerDetails({
                // fullname: locationPath?.state.fullname,
                // dob: locationPath?.state.dob,

                campid: locationPath?.state.campid,
                mpid: locationPath?.state.mpid,
                points: locationPath?.state.points,
                rewardtype: locationPath?.state.rewardtype,
                sponsor: locationPath?.state.sponsor,
                title: locationPath?.state.title,
                subtitle: locationPath?.state.subtitle,
                ownership: locationPath?.state.ownership,
                
            });
        }
    }, [locationPath?.state]);

    
  
    return (

        <div className="wrapper">

            <ResponsiveDrawer {...props} />
            <section className="containers">
            <TopHeaderScreen {...props} />
            <div class="content content-wrap">
            <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
                    Delete Reward
                </div>
                <form autoComplete="off">
                    <div style={{ border: "1px solid #dadce0", borderRadius: 8, padding: "13px 30px 40px 30px" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                            }}
                        >
                            <div className={classes.formGroup} >
                                <label className={classes.label}>Reward Type</label>
                                <input
                                    disabled
                                    
                                    value={locationPath?.state.rewardtype}
                                    type="text"
                                    className={classes.input}
                                />
                            </div>
                            <div className={classes.formGroup} >
                                <label className={classes.label}>Ownership</label>
                                <input
                                    disabled
                                    
                                    value={locationPath?.state.ownership}
                                    type="text"
                                    className={classes.input}
                                />
                            </div>
                            <div className={classes.formGroup} >
                                <label className={classes.label}>Title</label>
                                <input
                                    disabled
                                    
                                    value={locationPath?.state.title}
                                    type="text"
                                    className={classes.input}
                                />
                            </div>
                            <div className={classes.formGroup} >
                                <label className={classes.label}>Subtitle</label>
                                <input
                                    disabled
                                    
                                    value={locationPath?.state.subtitle}
                                    type="text"
                                    className={classes.input}
                                />
                            </div>
                            {/* <div className={classes.formGroup} style={{ width: "25%" }}>
                                <label className={classes.label}>Sponsor</label>
                                <input
                                    disabled
                                    
                                    value={locationPath?.state.sponsor}
                                    type="text"
                                    className={classes.input}
                                />
                            </div> */}
                            <div className={classes.formGroup} >
                                <label className={classes.label}>Points</label>
                                <input
                                    disabled
                                    
                                    value={locationPath?.state.points}
                                    type="text"
                                    className={classes.input}
                                />
                            </div>

                            

                        </div>
                    </div>
                </form>
                <div className="centerAlign" style={{ padding: "16px",marginRight:"10px",float:"left",width:"100%"}}>
                {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
                    <Button
                        onClick={() => setDisplayConfirmationModal(true)}
                        style={{
                            marginTop: 8,
                            // marginLeft:600,
                            width: "200px",
                            // background: "#E88A00",
                            background: "red",
                            borderRadius: 20,
                            paddingRight:10,
                            color: "#fff",
                            fontFamily: "Poppins !important",
                            alignSelf: "center",
                            marginRight:"5px",
                        }}
                    >
                        <span>Delete</span>
                    </Button>

                    <Button
                        onClick={() => navigate(-1)}
                        style={{
                            marginTop: 8,
                            // marginLeft: 600,
                            width: "200px",
                            background: "grey",
                            borderRadius: 20,
                            color: "#fff",
                            fontFamily: "Poppins !important",
                            alignSelf: "center",
                        }}
                    >
                        <span>Cancel</span>
                    </Button>
                </div>
                {profileSuccess &&
                    <span style={{ color: "green", textAlign: "center", display: "block" }}>{response?.message}</span>}
                {!profileSuccess && (
                    // <span style={{ color: "red" }}>Error occured</span>
                    <span style={{ color: "red", textAlign: "center", display: "block" }}>{response?.message}</span>
                )}
                {/* {response && <span>{response?.message}</span>} */}
            </div>
            </div>
            <CopyRight />
        <FixedFooterIcon />
      </section>
            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={handleDeleteUser} hideModal={hideConfirmationModal} message={"Are you sure you want to delete this reward?"}  />
        </div>
    );
}
export default RewardDeleteScreen;
