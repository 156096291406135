/** @format */

const GET_REWARD_REDEEM_CERT_FETCHING_DATA =
  "GET_REWARD_REDEEM_CERT_FETCHING_DATA";
const GET_REWARD_REDEEM_CERT_FETCHING_DATA_FAILURE =
  "GET_REWARD_REDEEM_CERT_FETCHING_DATA_FAILURE";
const GET_REWARD_REDEEM_CERT_FETCHING_DATA_RESET =
  "GET_REWARD_REDEEM_CERT_FETCHING_DATA_RESET";
const GET_REWARD_REDEEM_CERT_FETCHING_DATA_SUCCESS =
  "GET_REWARD_REDEEM_CERT_FETCHING_DATA_SUCCESS";
const GET_REWARD_REDEEM_CERT_FETCHING_DATA_ATTEMPT =
  "GET_REWARD_REDEEM_CERT_FETCHING_DATA_ATTEMPT";
const getRewardRedeemCert = (payload) => ({
  type: GET_REWARD_REDEEM_CERT_FETCHING_DATA_ATTEMPT,
  payload: payload,
});
const getRewardRedeemCertReset = () => ({
  type: GET_REWARD_REDEEM_CERT_FETCHING_DATA_RESET,
});

export {
  GET_REWARD_REDEEM_CERT_FETCHING_DATA,
  GET_REWARD_REDEEM_CERT_FETCHING_DATA_FAILURE,
  GET_REWARD_REDEEM_CERT_FETCHING_DATA_RESET,
  GET_REWARD_REDEEM_CERT_FETCHING_DATA_SUCCESS,
  GET_REWARD_REDEEM_CERT_FETCHING_DATA_ATTEMPT,
  getRewardRedeemCert,
  getRewardRedeemCertReset,
};