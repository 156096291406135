import React, { useState, useEffect, useRef } from "react";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import Button from "@mui/material/Button";
import { Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { MenuItem, Select, Typography } from "@mui/material";
import { CircularProgress, makeStyles, Checkbox } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import moment from "moment";
import { API_URL } from "../services/Constants";
import { useNavigate, useLocation } from "react-router-dom";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import format from "date-fns/format";
import { CopyRight } from "../components/CopyRight";
import Multiselect from "multiselect-react-dropdown";
import _ from "lodash";
import ErrorMessage from "../components/ErrorMessage";
// import MuiAlert from "@mui/material/Alert";
// import MuiAlert from "@material-ui/lab/Alert";
import Alert from "../components/Alert";
// import {Alert} from 'react-alert';
import ReactJsAlert from "reactjs-alert";
import RedStar from "../components/RedStar";
import { style } from "@mui/system";
import ScrollIntoView from "react-scroll-into-view";
import ResponsiveDrawer from "../components/Header";
import CustomWhatsappShareButton from "../components/CustomWhatsappShareButton";
import TopHeaderScreen from "../components/TopHeader1";
import RefImg from "../assets/referral.png";
import RewardMainImage from "../assets/rew.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { isEmpty, isArray } from "lodash";
import EarnImage from "../assets/c-earn-upto.png";
import FileUploadImg from "../assets/file-up.png";
import RegistrationTopImg from "../assets/thank you.png";
import UploadFiles from "../components/UploadFiles";
import UploadFiles1 from "../components/UploadFiles1";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import UploadDocs from "../components/UploadDocs";
import ShareIcon from "@mui/icons-material/Share";
import { TextareaAutosize } from '@mui/base';
import { FixedFooterIcon } from "./FixedFooterIcon";
import { referral } from "../redux/action/ReferralAction";

const useStyles = makeStyles((theme) => ({
  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
    fontFamily: "Poppins !important",
  },
  goalButtonDisable: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
    fontFamily: "Poppins !important",
    "&:hover": {
      color: "rgba(0, 0, 0, 0.9)",
    },
    // cursor: "not-allowed"
    cursor: "pointer",
  },
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "1px solid #C7C1C1",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "block",
    fontSize: 14,
    marginTop: 12,
    padding: 10,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  labellocation: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "flex",
    fontSize: 14,
    marginTop: 12,
    // padding: 10,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  labellocationnew: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "flex",
    fontSize: 14,
    marginTop: 12,
    // padding: 10,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    padding: "0px 0px 35px 0px",
  },
  calendarWrap: {
    display: "inline-block",
    position: "relative",
  },
  calendarElement: {
    position: "absolute",
    left: 1,
    top: 100,
    border: "1px solid",
    zIndex: 1,
    width: "100%",
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    // padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    marginRight: 16,
    // padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
  },
  formGroupN: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
}));

const Participate = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fieldRef1 = React.useRef();
  const fieldRef2 = React.useRef();
  const fieldRef3 = React.useRef();
  const [referralrewardmsg, setreferralrewardmsg] = useState([]);

  // const alert = useAlert();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /* For alerts */
  const [status, setStatus] = useState(false);
  const [type, setType] = useState("success");
  const [title, setTitle] = useState("This is a alert");
  const [show, setShow] = React.useState(false);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "" },
  ]);
  const [activityList, setActivityList] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [camploading, setCampLoading] = React.useState(false);
  const locationPath = useLocation();
  const [response, setResponse] = useState({});
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const [campaignList, setCampaignList] = useState(undefined);
  const [regStatusList, setRegStatusList] = useState(undefined);
  const [marketplacelist, setmarketplacelist] = useState([]);
  const [campRegFormQ, setCampRegFormQ] = useState([]);
  const [hideShow, setHideShow] = useState(false);
  const [myreferralurl, setmyreferralurl] = useState([]);

  const [singleSelect1, setSingleSelect1] = React.useState([
    { singleSelect1: "Yes" },
    { singleSelect1: "No" },

  ]);
  const [singleSelect2, setSingleSelect2] = React.useState([
    { singleSelect2: "IIT Kharagpur" },
    { singleSelect2: "IIT Bombay" },
    { singleSelect2: "IIT Madras" },
    { singleSelect2: "IIT Kanpur" },
    { singleSelect2: "IIT Delhi" },
    { singleSelect2: "IIT Guwahati" },
    { singleSelect2: "IIT Roorkee" },
    { singleSelect2: "IIT Ropar" },
    { singleSelect2: "IIT Bhubaneswar" },
    { singleSelect2: "IIT Gandhinagar" },
    { singleSelect2: "IIT Hyderabad" },
    { singleSelect2: "IIT Jodhpur" },
    { singleSelect2: "IIT Patna" },
    { singleSelect2: "IIT Indore" },
    { singleSelect2: "IIT Mandi" },
    { singleSelect2: "IIT Varanasi" },
    { singleSelect2: "IIT Palakkad" },
    { singleSelect2: "IIT Tirupati" },
    { singleSelect2: "IIT Dhanbad" },
    { singleSelect2: "IIT Bhilai" },
    { singleSelect2: "IIT Dharwad" },
    { singleSelect2: "IIT Jammu" },
    { singleSelect2: "IIT Goa" },

  ]);
  const [singleSelect3, setSingleSelect3] = React.useState([
    { singleSelect3: "Yes" },
    { singleSelect3: "No" },
    { singleSelect3: "Maybe" },

  ]);

  const [singleSelect4, setSingleSelect4] = React.useState([
    { code: "AN", singleSelect4: "Andaman and Nicobar Islands" },
    { code: "AP", singleSelect4: "Andhra Pradesh" },
    { code: "AR", singleSelect4: "Arunachal Pradesh" },
    { code: "AS", singleSelect4: "Assam" },
    { code: "BR", singleSelect4: "Bihar" },
    { code: "CG", singleSelect4: "Chandigarh" },
    { code: "CH", singleSelect4: "Chhattisgarh" },
    { code: "DH", singleSelect4: "Dadra and Nagar Haveli" },
    { code: "DD", singleSelect4: "Daman and Diu" },
    { code: "DL", singleSelect4: "Delhi" },
    { code: "GA", singleSelect4: "Goa" },
    { code: "GJ", singleSelect4: "Gujarat" },
    { code: "HR", singleSelect4: "Haryana" },
    { code: "HP", singleSelect4: "Himachal Pradesh" },
    { code: "JK", singleSelect4: "Jammu and Kashmir" },
    { code: "JH", singleSelect4: "Jharkhand" },
    { code: "KA", singleSelect4: "Karnataka" },
    { code: "KL", singleSelect4: "Kerala" },
    { code: "LD", singleSelect4: "Lakshadweep" },
    { code: "MP", singleSelect4: "Madhya Pradesh" },
    { code: "MH", singleSelect4: "Maharashtra" },
    { code: "MN", singleSelect4: "Manipur" },
    { code: "ML", singleSelect4: "Meghalaya" },
    { code: "MZ", singleSelect4: "Mizoram" },
    { code: "NL", singleSelect4: "Nagaland" },
    { code: "OR", singleSelect4: "Odisha" },
    { code: "PY", singleSelect4: "Puducherry" },
    { code: "PB", singleSelect4: "Punjab" },
    { code: "RJ", singleSelect4: "Rajasthan" },
    { code: "SK", singleSelect4: "Sikkim" },
    { code: "TN", singleSelect4: "Tamil Nadu" },
    { code: "TS", singleSelect4: "Telangana" },
    { code: "TR", singleSelect4: "Tripura" },
    { code: "UK", singleSelect4: "Uttarakhand" },
    { code: "UP", singleSelect4: "Uttar Pradesh" },
    { code: "WB", singleSelect4: "West Bengal" },
  ]);
  const [selected1, setSelected1] = React.useState([]);
  const [multiSelect1, setMultiSelect1] = React.useState([
    { multiSelect1: "Be a Mentor" },
    { multiSelect1: "Contribute Funds" },
    { multiSelect1: "Follow progress of initiatives on the ground" },
    { multiSelect1: "Help propagate awareness" },
    { multiSelect1: "Be a Volunteer" },
    { multiSelect1: "Drive impact together with your friends" },
    { multiSelect1: "Leave your legacy in your home town/village" },
  ]);
  const [selected2, setSelected2] = React.useState([]);
  // const [multiSelect2, setMultiSelect2] = React.useState([
  //   { multiSelect2: "Spring rejuvenation in HP" },
  //   { multiSelect2: "Borewell recharging" },
  //   { multiSelect2: "Rural tele-health centres" },
  //   { multiSelect2: "Affordable and quality sanitary" },
  //   { multiSelect2: "New born nutritional health" },
  //   { multiSelect2: "Digital economy jobs" },
  //   { multiSelect2: "Ru-Kart cold storage" },
  //   { multiSelect2: "Integrated smart village" },
  //   { multiSelect2: "Rural innovation and social enterprises" },
  //   { multiSelect2: "Mental Health and Resilience" },
  //   { multiSelect2: "Eco-schools" },
  //   { multiSelect2: "Rural Shores and People's Shores" },
  // ]);
  const [multiSelect2, setMultiSelect2] = React.useState([
    { multiSelect2: "Water" },
    { multiSelect2: "Health" },
    { multiSelect2: "Education" },
    { multiSelect2: "Energy" },
    { multiSelect2: "Livelihood" },
    { multiSelect2: "Sustainability" },

  ]);
  const [focusError1, setFocusError1] = React.useState(false);
  const [focusError2, setFocusError2] = React.useState(false);
  //   const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
  //     (state) => state.profile
  // );
  // const { isCampaignSuccess, isCampaignFetching, campaignResponse, sCampaignError, } = useSelector(
  //   (state) => state.onBoard);

  const { isSuccess: campaignSuccess, response: campaignResponse } =
    useSelector((state) => state.onBoard);
  // console.log(data)

  const [checked, setChecked] = React.useState([]);
  const options = {
    items: 3,
    margin: 10,
    loop: false,
    nav: false,
    autoplaySpeed: 900,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const handleCampaignFieldValidationText = (field, e) => {
    // alert("enter")
    // const re = /^[0-9\b]+$/;
    // alert(e.target.value)
    // if (e.target.value === "" || re.test(e.target.value)) {
    // alert("true")

    handleCampaignFieldChange1(field, e);

    // });
    // }
  };

  const handleCampaignFieldValidation = (field, e) => {
    // alert("enter")
    const re = /^[0-9\b]+$/;
    // alert(e.target.value)
    if (e.target.value === "" || re.test(e.target.value)) {
      // alert("true")

      handleCampaignFieldChange1(field, e);

      // });
    }
  };

  const handleCampaignFieldChange1 = (field, e) => {
    setCampaignValues({
      ...campaignValues,
      [field]: field === "startDate" ? e : e.target.value,
    });
  };

  const handleCampaignFieldChange = (field, e) => {
    setrefValues({
      ...refValues,
      [field]: field === "startDate" ? e : e.target.value,
    });
  };

  const [refValues, setrefValues] = React.useState({
    refCode: "",
  });

  console.log("localStorage.getItem(referralid) = ", localStorage.getItem("referralid"))
  useEffect(() => {

    localStorage.getItem("referralid") &&
      dispatch(
        referral({
          referralid: localStorage.getItem("referralid"),
          campid: localStorage.getItem("referedcampid"),
          reflevel: localStorage.getItem("reflevel"),
          phoneno: localStorage.getItem("userid"),
        })
      );
    localStorage.setItem("referralid", null)
    localStorage.setItem("referedcampid", null)
    localStorage.setItem("reflevel", null);
  }, []);

  const handleSubmit = async () => {
    try {
      const res = await axios.post(`/hareferralreward`, {
        campid: campaignList?.data?.[0]?.campid,
        // formuserfullname: profileResponse?.fullname,
        phoneno: profileResponse?.phoneno,
        // reflevel: "1",
        // refuserid: profileResponse?.phoneno,
        refcode: refValues?.refCode,
      });

      // setLoading(false);

      // handleClose();
      // navigate(`/adoption/${data?.[0]?.campid}`);
      setreferralrewardmsg(res?.data);
      return res;
      // }
    } catch (err) {
      // setLoading(false);

      throw err;
    }
  };


  const [campaignValues, setCampaignValues] = React.useState({
    titleOfCampaign: "",
    descriptionOfCampaign: "",
    typeOfCampaign: "",
    volunteerCount: "",
    impact: "",
    visibility: "",
    startDate: new Date(),
    endDate: new Date(),
    durationOfCampaign: "",
    goal: "",
    campaignType: "",
    hourOfParticipation: "",
    socialMediaMessage: "",
    campaignLogo: "",
    campaignVideo: "",
    formq5a: "",
    formq6a: "",
    formq7a: "",
    formq8a: "",
    formq9a: "",
    formq14a: "",
    formq10a: "",
    formq11a: "",
    formq12a: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [regStatusList]);

  const [campaignRegistrationValuesError, setCampaignRegistrationValuesError] =
    React.useState({
      timeCommitmentCampaignError: "",
      impactCommitmentCampaignError: "",
      locationCampaignError: "",
      formq5CampaignError: "",
      formq6CampaignError: "",
      formq7CampaignError: "",
      formq8CampaignError: "",
      formq9CampaignError: "",
      formq14CampaignError: "",
      formq10CampaignError: "",
      formq11CampaignError: "",
      formq12CampaignError: "",
      dateSelection: new Date(),
    });

  const hideShowCal = async () => {
    setHideShow((hideShow) => !hideShow);
  };


  const handleRegister = async () => {
    try {
      console.log("handleRegister")
      console.log("campRegFormQ?.data?.[0]?.qpattern = ", campRegFormQ?.data?.[0]?.qpattern)
      console.log("focusError1 = ", focusError1);
      console.log("focusError2 = ", focusError2);
      console.log("selected1 = ", isEmpty(selected1));
      console.log("selected2 = ", isEmpty(selected2));
      if (isEmpty(selected1) && campRegFormQ?.data?.[0]?.qpattern === "11") {
        setFocusError1(true);
      }
      if (isEmpty(selected2) && campRegFormQ?.data?.[0]?.qpattern === "11") {
        setFocusError2(true);
      }

      if (
        !campaignValues?.hourOfParticipation &&
        !(campRegFormQ?.data?.[0]?.qpattern === "4") &&
        !(campRegFormQ?.data?.[0]?.qpattern === "6") &&
        !(campRegFormQ?.data?.[0]?.qpattern === "7") &&
        !(campRegFormQ?.data?.[0]?.qpattern === "8") &&
        !(campRegFormQ?.data?.[0]?.qpattern === "9") &&
        !(campRegFormQ?.data?.[0]?.qpattern === "10") &&
        !(campRegFormQ?.data?.[0]?.qpattern === "11")
      ) {
        console.log("formq6a hourOfParticipation")
        setCampaignRegistrationValuesError({
          ...campaignRegistrationValuesError,
          timeCommitmentCampaignError: true,
        });
        fieldRef1.current.scrollIntoView({ behavior: "smooth" });
      } else if (
        !campaignValues?.impact &&
        !(campRegFormQ?.data?.[0]?.qpattern === "4") &&
        !(campRegFormQ?.data?.[0]?.qpattern === "6") &&
        !(campRegFormQ?.data?.[0]?.qpattern === "7") &&
        !(campRegFormQ?.data?.[0]?.qpattern === "8") &&
        !(campRegFormQ?.data?.[0]?.qpattern === "9") &&
        !(campRegFormQ?.data?.[0]?.qpattern === "10") &&
        !(campRegFormQ?.data?.[0]?.qpattern === "11")
      ) {
        console.log("impact Error")
        setCampaignRegistrationValuesError({
          ...campaignRegistrationValuesError,
          impactCommitmentCampaignError: true,
        });
        fieldRef2.current.scrollIntoView({ behavior: "smooth" });
      } else if (
        !campaignValues?.formq7a &&
        campRegFormQ?.data?.[0]?.qpattern === "9"
      ) {
        console.log("formq7a 9 Error")
        setCampaignRegistrationValuesError({
          ...campaignRegistrationValuesError,
          formq7CampaignError: true,
        });
        // fieldRef2.current.scrollIntoView({ behavior: "smooth" });
      } else if (
        !campaignValues?.formq7a &&
        campRegFormQ?.data?.[0]?.qpattern === "10"
      ) {
        console.log("formq7a 10,  Error")
        setCampaignRegistrationValuesError({
          ...campaignRegistrationValuesError,
          formq7CampaignError: true,
        });
        // fieldRef2.current.scrollIntoView({ behavior: "smooth" });
      } else if (
        !campaignValues?.formq7a &&
        campRegFormQ?.data?.[0]?.qpattern === "11"
      ) {
        console.log("formq7a 11 Error")
        setCampaignRegistrationValuesError({
          ...campaignRegistrationValuesError,
          formq7CampaignError: true,
        });
        // fieldRef2.current.scrollIntoView({ behavior: "smooth" });
      } else if (
        !campaignValues?.formq8a &&
        campRegFormQ?.data?.[0]?.qpattern === "9"
      ) {
        console.log("formq8a and 9 Error")
        setCampaignRegistrationValuesError({
          ...campaignRegistrationValuesError,
          formq8CampaignError: true,
        });
        // fieldRef2.current.scrollIntoView({ behavior: "smooth" });
      } else if (
        campRegFormQ?.data?.[0]?.qpattern === "11" &&
        !campaignValues?.formq8a &&
        campaignValues?.formq7a === "Yes"

      ) {
        console.log("formq8a and 11 Error")
        setCampaignRegistrationValuesError({
          ...campaignRegistrationValuesError,
          formq8CampaignError: true,
        });
        // fieldRef2.current.scrollIntoView({ behavior: "smooth" });
      } else if (
        !campaignValues?.formq11a &&
        campRegFormQ?.data?.[0]?.qpattern === "11"
      ) {
        console.log("formq11a Error")
        setCampaignRegistrationValuesError({
          ...campaignRegistrationValuesError,
          formq11CampaignError: true,
        });
        // fieldRef2.current.scrollIntoView({ behavior: "smooth" });
      } else if (
        !campaignValues?.formq12a &&
        campRegFormQ?.data?.[0]?.qpattern === "11"
      ) {
        console.log("formq12a Error")
        setCampaignRegistrationValuesError({
          ...campaignRegistrationValuesError,
          formq12CampaignError: true,
        });
        // fieldRef2.current.scrollIntoView({ behavior: "smooth" });
      } else if (
        campRegFormQ?.data?.[0]?.qpattern === "11" &&
        !campaignValues?.formq9a &&
        campaignValues?.formq7a === "Yes"
      ) {
        console.log("formq9a Error")
        setCampaignRegistrationValuesError({
          ...campaignRegistrationValuesError,
          formq9CampaignError: true,
        });
        // fieldRef2.current.scrollIntoView({ behavior: "smooth" });
      } else if (

        // (selected1.filter((item) => item).length === 0) &&
        (focusError1 || isEmpty(selected1)) &&
        campRegFormQ?.data?.[0]?.qpattern === "11"
      ) {
        console.log("formq14a Error")
        setCampaignRegistrationValuesError({
          ...campaignRegistrationValuesError,
          formq14CampaignError: true,
        });
        // fieldRef2.current.scrollIntoView({ behavior: "smooth" });
      } else if (
        campRegFormQ?.data?.[0]?.qpattern === "11" &&
        !campaignValues?.formq10a &&
        campaignValues?.formq7a === "Yes"

      ) {
        console.log("formq10a Error")
        setCampaignRegistrationValuesError({
          ...campaignRegistrationValuesError,
          formq10CampaignError: true,
        });
        // fieldRef2.current.scrollIntoView({ behavior: "smooth" });
      } else if (
        !campaignValues?.formq5a &&
        campRegFormQ?.data?.[0]?.qpattern === "11"
      ) {
        console.log("formq5a Error")
        setCampaignRegistrationValuesError({
          ...campaignRegistrationValuesError,
          formq5CampaignError: true,
        });
        // fieldRef2.current.scrollIntoView({ behavior: "smooth" });
      } else if (
        // selected2.filter((item) => item).length === 0 &&
        (focusError2 || isEmpty(selected2)) &&
        campRegFormQ?.data?.[0]?.qpattern === "11"
      ) {
        console.log("formq6a Error")
        setCampaignRegistrationValuesError({
          ...campaignRegistrationValuesError,
          formq6CampaignError: true,
        });
        // fieldRef2.current.scrollIntoView({ behavior: "smooth" });
      } else if (!checked) {
        console.log("CHECKED ERROR")
        setCampaignRegistrationValuesError({
          ...campaignRegistrationValuesError,
          locationCampaignError: true,
        });
        fieldRef3.current.scrollIntoView({ behavior: "smooth" });
      } else {
        console.log("CALLING API")
        setLoading(true);
        if (
          campRegFormQ?.data?.[0]?.qpattern !== "4" &&
          campRegFormQ?.data?.[0]?.qpattern !== "6" &&
          campRegFormQ?.data?.[0]?.qpattern !== "7" &&
          campRegFormQ?.data?.[0]?.qpattern !== "8" &&
          campRegFormQ?.data?.[0]?.qpattern !== "9" &&
          campRegFormQ?.data?.[0]?.qpattern !== "10" &&
          campRegFormQ?.data?.[0]?.qpattern !== "11"
        ) {
          console.log("qpattern = 3 or 5")
          const res = await axios.post(`/campaign/register`, {

            campid: campaignList?.data?.[0].campid,
            formuserfirstname: profileResponse?.firstname,
            formuserlastname: profileResponse?.lastname,
            formuserphone: profileResponse?.phoneno,
            formorgname: profileResponse?.organization,
            formcampaignname: campaignList?.data?.[0]?.name,
            participatedays:
              moment(range[0].startDate).format("YYYY-MM-DD") +
              "," +
              moment(range[0].endDate).format("YYYY-MM-DD"),

            timecommitment: campaignValues?.hourOfParticipation,
            contribution: campaignValues?.impact,
            location: checked,
            formq7a: " ",
            formq8a: " ",
            formq5a: " ",
            formq6a: " ",
            formq9a: " ",
            formq14a: " ",
            formq10a: " ",
            formq11a: " ",
            formq12a: " ",

            registerdate: moment(new Date()).format("YYYY-MM-DD") + " 00:00:00",
          });
          navigate(
            `/adoption/${campaignList?.data?.[0]?.campid}/${res?.data?.message}`
          );

          return res;
        } else if (campRegFormQ?.data?.[0]?.qpattern === "7") {
          console.log("***7")
          const res = await axios.post(`/campaign/register`, {
            campid: campaignList?.data?.[0].campid,
            formuserfirstname: profileResponse?.firstname,
            formuserlastname: profileResponse?.lastname,
            formuserphone: profileResponse?.phoneno,
            formorgname: profileResponse?.organization,
            formcampaignname: campaignList?.data?.[0]?.name,
            participatedays: moment("2023-05-14").format("YYYY-MM-DD"),
            timecommitment: campaignList?.data?.[0].hourofparticipation,
            contribution: "0",
            location: checked,
            formq7a: " ",
            formq8a: " ",
            formq5a: " ",
            formq6a: " ",
            formq9a: " ",
            formq14a: " ",
            formq10a: " ",
            formq11a: " ",
            formq12a: " ",

            registerdate: moment(new Date()).format("YYYY-MM-DD") + " 00:00:00",
          });
          navigate(
            `/adoption/${campaignList?.data?.[0]?.campid}/${res?.data?.message}`
          );

          return res;
        } else if (campRegFormQ?.data?.[0]?.qpattern === "8") {
          console.log("***8")
          const res = await axios.post(`/campaign/register`, {
            campid: campaignList?.data?.[0].campid,
            // formuserfullname: profileResponse?.fullname,
            formuserfirstname: profileResponse?.firstname,
            formuserlastname: profileResponse?.lastname,
            formuserphone: profileResponse?.phoneno,
            formorgname: profileResponse?.organization,
            formcampaignname: campaignList?.data?.[0]?.name,
            // formcampsponsor: campaignValues?.campaignSponsor,
            // campaignList?.data?.[0].startdate
            // participatedays:
            //   moment(range[0].startDate).format("YYYY-MM-DD") +
            //   "," +
            //   moment(range[0].endDate).format("YYYY-MM-DD"),

            participatedays:
              moment(campaignList?.data?.[0].startdate).format("YYYY-MM-DD") +
              "," +
              moment(campaignList?.data?.[0].enddate).format("YYYY-MM-DD"),

            timecommitment: campaignList?.data?.[0].hourofparticipation,
            contribution: "0",
            // location: location,
            // location: JSON.stringify(checked),
            location: checked,
            formq7a: " ",
            formq8a: " ",
            formq5a: " ",
            formq6a: " ",
            formq9a: " ",
            formq14a: " ",
            formq10a: " ",
            formq11a: " ",
            formq12a: " ",

            registerdate: moment(new Date()).format("YYYY-MM-DD") + " 00:00:00",
          });
          // setParticipateResponse(res?.data?.message);
          // participateResponse &&

          navigate(
            `/adoption/${campaignList?.data?.[0]?.campid}/${res?.data?.message}`
            // `/adoption/${campaignList?.data?.[0]?.campid}/${participateResponse}`
          );

          return res;
        } else if (campRegFormQ?.data?.[0]?.qpattern === "9") {
          console.log("***9")
          const res = await axios.post(`/campaign/register`, {
            campid: campaignList?.data?.[0].campid,
            // formuserfullname: profileResponse?.fullname,
            formuserfirstname: profileResponse?.firstname,
            formuserlastname: profileResponse?.lastname,
            formuserphone: profileResponse?.phoneno,
            formorgname: profileResponse?.organization,
            formcampaignname: campaignList?.data?.[0]?.name,
            participatedays:
              moment(campaignList?.data?.[0].startdate).format("YYYY-MM-DD") +
              "," +
              moment(campaignList?.data?.[0].enddate).format("YYYY-MM-DD"),

            timecommitment: campaignList?.data?.[0].hourofparticipation,
            contribution: "0",
            // location: location,
            // location: JSON.stringify(checked),
            location: checked,
            formq7a: campaignValues?.formq7a,
            formq8a: campaignValues?.formq8a,
            formq5a: " ",
            formq6a: " ",
            formq9a: " ",
            formq14a: " ",
            formq10a: " ",
            formq11a: " ",
            formq12a: " ",

            registerdate: moment(new Date()).format("YYYY-MM-DD") + " 00:00:00",
          });
          // setParticipateResponse(res?.data?.message);
          // participateResponse &&

          navigate(
            `/adoption/${campaignList?.data?.[0]?.campid}/${res?.data?.message}`
            // `/adoption/${campaignList?.data?.[0]?.campid}/${participateResponse}`
          );

          return res;
        } else if (campRegFormQ?.data?.[0]?.qpattern === "10") {
          console.log("***10")
          const res = await axios.post(`/campaign/register`, {
            campid: campaignList?.data?.[0].campid,
            // formuserfullname: profileResponse?.fullname,
            formuserfirstname: profileResponse?.firstname,
            formuserlastname: profileResponse?.lastname,
            formuserphone: profileResponse?.phoneno,
            formorgname: profileResponse?.organization,
            formcampaignname: campaignList?.data?.[0]?.name,
            participatedays:
              moment(campaignList?.data?.[0].startdate).format("YYYY-MM-DD") +
              "," +
              moment(campaignList?.data?.[0].enddate).format("YYYY-MM-DD"),

            timecommitment: campaignList?.data?.[0].hourofparticipation,
            contribution: "0",
            // location: location,
            // location: JSON.stringify(checked),
            location: checked,
            formq7a: campaignValues?.formq7a,
            formq8a: " ",
            formq5a: " ",
            formq6a: " ",
            formq9a: " ",
            formq14a: " ",
            formq10a: " ",
            formq11a: " ",
            formq12a: " ",

            registerdate: moment(new Date()).format("YYYY-MM-DD") + " 00:00:00",
          });
          // setParticipateResponse(res?.data?.message);
          // participateResponse &&

          navigate(
            `/adoption/${campaignList?.data?.[0]?.campid}/${res?.data?.message}`
            // `/adoption/${campaignList?.data?.[0]?.campid}/${participateResponse}`
          );

          return res;
        } else if (campRegFormQ?.data?.[0]?.qpattern === "11") {
          console.log("***11")
          if (campaignValues?.formq7a === "No") {
            const res = await axios.post(`/campaign/register`, {
              campid: campaignList?.data?.[0].campid,
              // formuserfullname: profileResponse?.fullname,
              formuserfirstname: profileResponse?.firstname,
              formuserlastname: profileResponse?.lastname,
              formuserphone: profileResponse?.phoneno,
              formorgname: profileResponse?.organization,
              formcampaignname: campaignList?.data?.[0]?.name,
              participatedays:
                moment(campaignList?.data?.[0].startdate).format("YYYY-MM-DD") +
                "," +
                moment(campaignList?.data?.[0].enddate).format("YYYY-MM-DD"),

              timecommitment: campaignList?.data?.[0].hourofparticipation,
              contribution: "0",
              // location: location,
              // location: JSON.stringify(checked),
              location: checked,
              formq7a: campaignValues?.formq7a,
              formq5a: campaignValues?.formq5a,
              formq6a: JSON.stringify(selected2),
              formq8a: " ",
              formq9a: " ",
              // formq14a: campaignValues?.formq14a,
              formq14a: JSON.stringify(selected1),
              formq10a: " ",
              formq11a: campaignValues?.formq11a,
              formq12a: campaignValues?.formq12a,

              registerdate: moment(new Date()).format("YYYY-MM-DD") + " 00:00:00",
            });
            navigate(
              `/adoption/${campaignList?.data?.[0]?.campid}/${res?.data?.message}`
              // `/adoption/${campaignList?.data?.[0]?.campid}/${participateResponse}`
            );

            return res;
          } else {
            const res = await axios.post(`/campaign/register`, {
              campid: campaignList?.data?.[0].campid,
              // formuserfullname: profileResponse?.fullname,
              formuserfirstname: profileResponse?.firstname,
              formuserlastname: profileResponse?.lastname,
              formuserphone: profileResponse?.phoneno,
              formorgname: profileResponse?.organization,
              formcampaignname: campaignList?.data?.[0]?.name,
              participatedays:
                moment(campaignList?.data?.[0].startdate).format("YYYY-MM-DD") +
                "," +
                moment(campaignList?.data?.[0].enddate).format("YYYY-MM-DD"),

              timecommitment: campaignList?.data?.[0].hourofparticipation,
              contribution: "0",
              // location: location,
              // location: JSON.stringify(checked),
              location: checked,
              formq7a: campaignValues?.formq7a,
              formq5a: campaignValues?.formq5a,
              formq6a: JSON.stringify(selected2),
              formq8a: campaignValues?.formq8a,
              formq9a: campaignValues?.formq9a,
              // formq14a: campaignValues?.formq14a,
              formq14a: JSON.stringify(selected1),
              formq10a: campaignValues?.formq10a,
              formq11a: campaignValues?.formq11a,
              formq12a: campaignValues?.formq12a,

              registerdate: moment(new Date()).format("YYYY-MM-DD") + " 00:00:00",
            });
            navigate(
              `/adoption/${campaignList?.data?.[0]?.campid}/${res?.data?.message}`
              // `/adoption/${campaignList?.data?.[0]?.campid}/${participateResponse}`
            );

            return res;
          }
          // setParticipateResponse(res?.data?.message);
          // participateResponse &&


        } else {
          console.log("***ELSE")
          const res = await axios.post(`/campaign/register`, {
            campid: campaignList?.data?.[0].campid,
            // formuserfullname: profileResponse?.fullname,
            formuserfirstname: profileResponse?.firstname,
            formuserlastname: profileResponse?.lastname,
            formuserphone: profileResponse?.phoneno,
            formorgname: profileResponse?.organization,
            formcampaignname: campaignList?.data?.[0]?.name,
            // formcampsponsor: campaignValues?.campaignSponsor,
            // campaignList?.data?.[0].startdate
            // participatedays:
            //   moment(range[0].startDate).format("YYYY-MM-DD") +
            //   "," +
            //   moment(range[0].endDate).format("YYYY-MM-DD"),

            participatedays:
              moment(campaignList?.data?.[0].startdate).format("YYYY-MM-DD") +
              "," +
              moment(campaignList?.data?.[0].enddate).format("YYYY-MM-DD"),

            timecommitment: campaignList?.data?.[0].hourofparticipation,
            contribution: "0",
            // location: location,
            // location: JSON.stringify(checked),
            location: checked,
            formq7a: " ",
            formq8a: " ",
            formq5a: " ",
            formq6a: " ",
            formq9a: " ",
            formq14a: " ",
            form10a: " ",

            registerdate: moment(new Date()).format("YYYY-MM-DD") + " 00:00:00",
          });
          // setParticipateResponse(res?.data?.message);
          // participateResponse &&

          navigate(
            `/adoption/${campaignList?.data?.[0]?.campid}/${res?.data?.message}`
            // `/adoption/${campaignList?.data?.[0]?.campid}/${participateResponse}`
          );

          return res;
        }

        // navigate(
        //   `/adoption/${campaignList?.data?.[0]?.campid}/${res?.data?.message}`
        // );

        // return res;
      }
    } catch (err) {
      setLoading(false);

      throw err;
    }
  };

  const getRewardsFromMarketplace = async () => {
    try {
      //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);
      const res = await axios
        .get(`/marketplace/read/${profileResponse?.phoneno}`)
        .then((response) => {

          setmarketplacelist(response);
          return response;
        });
      // setmarketplacelist(res?.data);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getRewardsFromMarketplace();
  }, []);


  const getCampaignDetails = async () => {
    try {
      setCampLoading(true);
      console.log("camploading = ", camploading);
      const res = await axios
        .get(
          `vol_dashboard/camp/${locationPath?.pathname.split("/")?.[2]}/${profileResponse?.orgrole
          }/${profileResponse?.organization}/${profileResponse?.phoneno}`
        )
        .then((response) => {
          setCampaignList(response);
          return response;
        });
    } catch (err) {
      throw err;
    }
  };



  useEffect(() => {
    console.log("USEEFFECT")
    getCampaignDetails();
    setCampLoading(false);
  }, []);

  const getRegFormQuestions = async () => {
    try {
      // console.log(profileResponse?.orgrole)
      const res = await axios
        .get(
          // `/vol_dashboard/camp/${location?.pathname.split("/")?.[2]}`

          `campaign/registrationform/${locationPath?.pathname.split("/")?.[2]}`
        )
        .then((response) => {
          setCampRegFormQ(response);
          return response;
        });
      // setCampRegFormQ(res?.data);
      // console.log(res?.data)
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getRegFormQuestions();
  }, []);

  const getRegStatus = async () => {
    try {
      const res = await axios
        .get(
          `/camp/registeredornot/${profileResponse?.phoneno}/${locationPath?.pathname.split("/")?.[2]
          }`
        )
        .then((response) => {
          setRegStatusList(response);
          return response;
        });
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getRegStatus();
  }, []);

  const handleShare = async () => {
    // alert("Handle Share")

    try {
      // setDialogOpen(true);
      const res = await axios
        .get(
          `refrralurl/${locationPath?.pathname.split("/")?.[2]
          }/${localStorage.getItem("userid")}`
        )
        .then((response) => {
          setmyreferralurl(response);
          // generateMessage(response?.data?.url)

          // setHasShared(true);
          // delay(10000);
          return response;
        });
    } catch (err) {
      // setLoading(false);

      throw err;
    }
  };

  // const splitString = (val) => {
  //   if (val) {
  //     const vallArray = val.split(";");


  //     return (
  //       <ul>
  //         {vallArray.map((bulletVal, index) => (
  //           <li>
  //             <p>{vallArray[index]}.</p>
  //           </li>
  //         ))}
  //       </ul>
  //     );
  //   }
  // };

  // const splitStringForParticipateNow = (val) => {
  //   if (val) {
  //     const vallArray = val.split(";");

  //     return (
  //       <ul>
  //         {vallArray.map((bulletVal, index) => (
  //           <li>
  //             <p style={{marginBottom:"0px"}}>{vallArray[index]}.</p>
  //           </li>
  //         ))}
  //       </ul>
  //     );
  //   }
  // };

  const splitString1 = (val) => {
    if (val) {
      const parts = val.split(/(::)|(,,)|(--)|;/).filter(Boolean);
      let inUnderlinedSection = false;
      let inBoldSection = false;
      let skipLineBreak = true;
      let inHighlightTextSection = false;

      return (
        <ul>
          {parts.map((bulletVal, index) => {
            if (bulletVal === '::') {
              inUnderlinedSection = !inUnderlinedSection;
              skipLineBreak = true;
              return null;
            } else if (bulletVal === ',,') {
              inBoldSection = !inBoldSection;
              skipLineBreak = true;
              return null;
            } else if (bulletVal === '--') {
              inHighlightTextSection = !inHighlightTextSection;
              skipLineBreak = true;
              return null;
            }

            if (inUnderlinedSection) {
              return (
                <li key={index}>
                  <span className="underlined-text">{bulletVal}</span>
                </li>
              );
            } else if (inBoldSection) {
              return (
                <li key={index}>
                  {!skipLineBreak && <br />}
                  <span className="bold-text">{bulletVal}</span>
                </li>
              );
            } else if (inHighlightTextSection) {
              return (
                <li key={index}>
                  {!skipLineBreak && <br />}
                  <span className="highlight-text">{bulletVal}</span>
                </li>
              );
            } else if (bulletVal.trim() !== '') {
              const bulletPoints = bulletVal.split(';').map((point) => point.trim());

              return (
                <li key={index}>
                  {bulletPoints.map((point, subIndex) => {
                    const shouldBreakLine = subIndex > 0;
                    if (shouldBreakLine) {
                      skipLineBreak = false;
                      return (
                        <React.Fragment key={`${index}-${subIndex}`}>
                          <br />
                          <p>{point}</p>
                        </React.Fragment>
                      );
                    }
                    return <p key={`${index}-${subIndex}`} style={{ marginTop: 0 }}>{point}</p>;
                  })}
                </li>
              );
            }
            return null;
          })}
        </ul>
      );
    }
  };



  const [range, setRange] = useState([
    {
      // startDate: new Date(),
      // startDate: addDays(new Date(), campaignList?.data?.[0]?.daystostart),
      startDate: addDays(new Date(), 1),


      endDate: addDays(new Date(), 1),
      // endDate: addDays(new Date(), campaignList?.data?.[0]?.daystoend),
      // endDate: new Date(),
      key: "selection",
    },
  ]);
  // const [range, setRange] = useState([])

  useEffect(() => {
    if (!(isEmpty(campaignList))) {
      console.log("seeting Range")
      setRange([{
        startDate: addDays(new Date(), campaignList?.data?.[0]?.daystostart),
        endDate: addDays(new Date(), campaignList?.data?.[0]?.daystoend),
        key: "selection",
      }])
    }
  }, [camploading, isEmpty(campaignList)]);

  console.log("camploading = ", camploading)
  console.log("isArray(campaignList) = ", isArray(campaignList))
  console.log("profileSuccess = ", profileSuccess)
  console.log("isEmpty(campaignList) = ", isEmpty(campaignList))
  console.log("(campaignList) = ", (campaignList))
  console.log("range = ", range);

  // const generateMessage = () => {
  //   const message = `
  //     Hello!
  //     I really liked this new SDP initiative. With SDP, we can participate in many social impact campaigns and win points and rewards. 
  //     I am personally inviting you to participate in this campaign on SDP. 
  //     [Click here](${myreferralurl?.data?.url})

  //     ${myreferralurl?.data?.url}
  //   `;


  //   return message;
  // };
  const [shortenedLink, setShortenedLink] = useState("")
  const generateMessage = async (userInput) => {
    try {
      const response = await axios(
        `https://api.shrtco.de/v2/shorten/dXJsPQ== ${userInput}`
      );
      setShortenedLink(response.data.result.full_short_link);
    } catch (e) {
      console.log(e);
    }
  };

  // const message = `Hello!%0AI really liked this.%0AClick <a href="${myreferralurl?.data?.url}">here</a> to view.`;
  console.log("myreferralurl?.data?.url = ", myreferralurl?.data?.url)
  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <div class="row">
              {/* <Button onClick={() => navigate(-1)} className={classes.backBtn}> */}
              <Button onClick={() => navigate(`/home`)} className={classes.backBtn}>
                <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Home</span>
              </Button>
            </div>


            <div class="row" >
              <div class="col-md-6">
                <div class="cyagg-sec-space reg-camp-sec">
                  <div class="container">
                    <div>
                      <div class="campaigns-wrap campaigns-wrap-new">
                        <div>
                          <div>
                            <div class="campaign-img">
                              <div class="video-wrapper">
                                <div class="video-container">
                                  {campaignList?.data?.[0]?.video ? (
                                    <video
                                      controls
                                      id="video"
                                      preload="auto"
                                      poster={campaignList?.data?.[0]?.logo}
                                      autoplay
                                    >
                                      <source
                                        src={campaignList?.data?.[0]?.video}
                                        type="video/mp4"
                                      />
                                    </video>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            <h5 title={campaignList?.data?.[0]?.name} class="campaign-title-new">
                              {campaignList?.data?.[0]?.name}
                            </h5>

                            <div class="campaign-hashtags">
                              {campaignList?.data?.[0]?.tag.map((tag) => (
                                <a class="hash-styles">{tag.tag}</a>
                              ))}
                            </div>
                          </div>
                        </div>


                        <div className={classes.campaigntypewrapper}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            gap={0.5}
                          >
                            <h5 class="campaign-goal">
                              <a
                                class="hash-styles-goal"
                                style={{
                                  padding:
                                    "0px 5px 0px 0px",
                                  cursor: "initial",
                                }}
                              >
                                <span style={{ fontSize: "16px", fontWeight: 700 }}>Goal</span>: <span class="text-6" style={{ color: "black" }}>{campaignList?.data?.[0]?.goalquantity}{" "} {campaignList?.data?.[0]?.unitofgoal}</span>
                              </a>
                            </h5>
                          </Stack>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <div>
                              <div class="campaigns-details">
                                <div class="row">

                                  <div class="col-md-4 col-6">
                                    <div class="cam-cols">
                                      <div class="timer-here">
                                        <div class="text-center">
                                          <span class="campaign-time-title">
                                            Earn upto
                                          </span>
                                        </div>
                                        <div>
                                          <ul id="countdown">
                                            <li id="hours">
                                              <div class="numberhome"></div>
                                              <div class="label"></div>
                                            </li>
                                            <li id="minutes">
                                              <div class="numberhome">
                                                {
                                                  campaignList?.data?.[0]
                                                    ?.maxpointstobeearned
                                                }
                                              </div>
                                              <div class="label">Points</div>
                                            </li>
                                            <li id="seconds">
                                              <div class="numberhome"></div>
                                              <div class="label"></div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-md-6 col-6">
                                    <div class="cam-cols">
                                      <div class="timer-here">
                                        <div class="text-center">
                                          <span class="campaign-time-title">
                                            Time Left
                                          </span>
                                        </div>
                                        <div>
                                          <ul id="countdown">
                                            <li id="hours">
                                              <div class="number">
                                                {
                                                  campaignList?.data?.[0]
                                                    ?.dayrem
                                                }
                                              </div>
                                              <div class="label">Days</div>
                                            </li>
                                            <li id="minutes">
                                              <div class="number">
                                                {
                                                  campaignList?.data?.[0]
                                                    ?.hourrem
                                                }
                                              </div>
                                              <div class="label">Hrs</div>
                                            </li>
                                            <li id="seconds">
                                              <div class="number">
                                                {
                                                  campaignList?.data?.[0]
                                                    ?.minrem
                                                }
                                              </div>
                                              <div class="label">Min</div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>


                                <div class="p-bar-new">
                                  <div class="row ">
                                    <div class="col-6">
                                      <div>
                                        <h6>
                                          <span style={{ color: "#AAAAAA" }}>Pledged: </span>{" "} <span style={{ backgroundColor: "#FFDB58" }}>
                                            {Math.round(
                                              (campaignList?.data?.[0]?.committedcount)
                                            )}
                                          </span>
                                        </h6>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div
                                        style={{
                                          textAlign:
                                            "right",
                                        }}
                                      >
                                        <h6>
                                          <span style={{ color: "#AAAAAA" }}>Achieved: </span>
                                          {" "}
                                          <span style={{ backgroundColor: "#A9D18E" }}> {campaignList?.data?.[0]?.achievedcount}</span>
                                          {" "}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="progress br-30 campaign-progress" style={{ position: "relative" }}>
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{
                                        width: `${(campaignList?.data?.[0]?.committedcount / campaignList?.data?.[0]?.goalquantity) * 100}%`,
                                        backgroundColor: "#FFDB58",
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        zIndex: 1,
                                        height: "100%"
                                      }}
                                      aria-valuenow={Math.round((campaignList?.data?.[0]?.committedcount / campaignList?.data?.[0]?.goalquantity) * 100)}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>

                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{
                                        width: `${(campaignList?.data?.[0]?.achievedcount / campaignList?.data?.[0]?.goalquantity) * 100}%`,
                                        backgroundColor: "#A9D18E",
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        zIndex: 2,
                                        height: "100%"
                                      }}
                                      aria-valuenow={Math.round((campaignList?.data?.[0]?.achievedcount / campaignList?.data?.[0]?.goalquantity) * 100)}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </div>


                                <div class="mr-15 text-center">
                                  <a class="campaign-signin">
                                    Join Now{" "}
                                    <i
                                      class="fa fa-arrow-right"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="logged-cam-detai mob-space1">
                        <div class="aft-log-acc">
                          <div class="widget-content widget-content-area creative2-accordion-content">
                            <div id="creativeAccordion">
                              <div class="card mb-3">
                                <div
                                  class="card-header"
                                  id="creative2headingOne"
                                >
                                  <h5 class="mb-0 mt-0">
                                    <span
                                      role="menu"
                                      class="changeIcon collapsed"
                                      data-toggle="collapse"
                                      data-target="#creative2CollapseOne"
                                      aria-expanded="true"
                                      aria-controls="creative2CollapseOne"
                                    >
                                      <span class="icon">
                                        <i
                                          class="fa fa-plus"
                                          aria-hidden="true"
                                        ></i>
                                        <i
                                          class="fa fa-minus"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <span class="text ml-2">
                                        Achieve the goal and win rewards
                                      </span>
                                    </span>
                                  </h5>
                                </div>

                                <div
                                  id="creative2CollapseOne"
                                  class="collapse"
                                  aria-labelledby="creative2headingOne"
                                  data-parent="#creativeAccordion"
                                >
                                  <div class="card-body">
                                    <p class="mb-3">
                                      1. {campaignList?.data?.[0]?.goal}
                                    </p>
                                    <p class="mb-3">
                                      2. {campaignList?.data?.[0]?.desc}
                                    </p>
                                    <p class="mb-3">
                                      3. Win upto{" "}
                                      <b>
                                        {" "}
                                        {
                                          campaignList?.data?.[0]
                                            ?.maxpointstobeearned
                                        }{" "}
                                      </b>
                                      points by Participating or Inviting
                                      motivated friends to join this Campaign.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="card mb-3">
                                <div
                                  class="card-header"
                                  id="creative2HeadingTwo"
                                >
                                  <h5 class="mb-0 mt-0">
                                    <span
                                      role="menu"
                                      class="changeIcon collapsed"
                                      data-toggle="collapse"
                                      data-target="#creative2CollapseTwo"
                                      aria-expanded="false"
                                      aria-controls="creative2CollapseTwo"
                                    >
                                      <span class="icon">
                                        <i
                                          class="fa fa-plus"
                                          aria-hidden="true"
                                        ></i>
                                        <i
                                          class="fa fa-minus"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <span class="text ml-2">

                                        How to participate?

                                      </span>
                                    </span>
                                  </h5>
                                </div>
                                <div
                                  id="creative2CollapseTwo"
                                  class="collapse"
                                  aria-labelledby="creative2HeadingTwo"
                                  data-parent="#creativeAccordion"
                                >
                                  <div class="card-body">
                                    <p class="mb-3">
                                      {splitString1(
                                        // campaignList?.data?.[0]?.socialmediamsg
                                        campaignList?.data?.[0]?.whattodo
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="card mb-3">
                                <div
                                  class="card-header"
                                  id="creative2HeadingTwo"
                                >
                                  <h5 class="mb-0 mt-0">

                                    <span
                                      role="menu"
                                      class="changeIcon collapsed"
                                      data-toggle="collapse"
                                      data-target="#creative2CollapseThree"
                                      aria-expanded="false"
                                      aria-controls="creative2CollapseThree"
                                    >
                                      <span class="icon">
                                        <i
                                          class="fa fa-plus"
                                          aria-hidden="true"
                                        ></i>
                                        <i
                                          class="fa fa-minus"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <span class="text ml-2">
                                        Guidelines

                                      </span>
                                    </span>
                                  </h5>
                                </div>
                                <div
                                  id="creative2CollapseThree"
                                  class="collapse"
                                  aria-labelledby="creative2HeadingThree"
                                  data-parent="#creativeAccordion"
                                >
                                  <div class="card-body">
                                    <p class="mb-3">
                                      {splitString1(
                                        // campaignList?.data?.[0]?.socialmediamsg
                                        campaignList?.data?.[0]?.guidelines
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <hr />
                      </div>
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                    {/* </div> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>




              <div class="col-md-6">
                {campaignList && (
                  <div class="reg-banner">
                    <div class="container">
                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 banner-left-content">
                          {/* <p class="p-3"> */}
                          <p class="">
                            <span
                              style={{
                                textAlign: "center",
                                fontSize: 20,
                                display: "block",
                                padding: "5px 15px",
                              }}
                            >
                              <b>
                                Thank you for your interest in{" "}
                                <u>{campaignList?.data?.[0]?.name} </u>! Be a real
                                changemaker by also inviting other volunteers to
                                adopt this campaign.
                              </b>
                            </span>
                            {!(regStatusList?.data?.registered === 0) && (
                              <span
                                style={{
                                  textAlign: "center",
                                  fontSize: 15,
                                  color: "red",
                                  padding: "5px",
                                  display: "block",
                                }}
                              >
                                Please note that you have committed to participate
                                for this campaign on{" "}
                                <u>
                                  <b>{regStatusList?.data?.registerdates}</b>
                                </u>
                              </span>
                            )}
                          </p>
                          <div class=" pl-4 pr-4">
                            <button
                              type="button"
                              // class="view-more-btn btn btn-secondary mb-4 mr-2"
                              class="view-more-btn btn btn-tertiary mb-4 mr-2"
                            >
                              <a href="#participate">Register Now </a>
                              <i class="fa fa-arrow-right"></i>
                            </button>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-center ">
                          <div class="slider-img d-sm-flex justify-content-center">
                            <img
                              alt="ERP Implementation "
                              class="img-fluid hide-on-mobile reg-b-img"
                              height="400px"
                              src={RegistrationTopImg}
                              title="ERP Implementation"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}




                <div class="cyagg-sec-space" style={{ marginTop: "15px" }}>
                  <div class="container">
                    <div class="row">
                      {/* <div class="col-md-6"> */}
                      <div class="text-center mob-space">
                        <h5>Invite Friends</h5>
                        <div class="navigationS">
                          <div
                            class="menuToggleS"
                            onClick={handleShare}
                          >
                            <ShareIcon style={{ color: "orange" }} />
                          </div>
                          <div class="menuS">
                            <ul>
                              <WhatsappShareButton
                                url={
                                  "Hello!" +
                                  "\n" +
                                  "I really liked this new SDP initiative. With SDP, we can participate in many social impact campaigns and win points and rewards. I am personally inviting you to participate in this campaign on SDP." +
                                  "\n" +
                                  "\n" +
                                  campaignList?.data?.[0]?.socialmediamsg +
                                  "\n" +
                                  "\n" +
                                  "Please use the link below and don't forget to enter your personal REFERRAL CODE " +
                                  " " +
                                  myreferralurl?.data?.refcode +
                                  " " +
                                  "when you participate in this campaign as that will win extra points for you!" +
                                  "\n" +
                                  myreferralurl?.data?.url
                                }
                              >

                                <WhatsappIcon
                                  size={32}
                                  round={true}
                                ></WhatsappIcon>
                              </WhatsappShareButton>

                              <FacebookShareButton
                                url={
                                  "Hello!" +
                                  "\n" +
                                  "I really liked this new SDP initiative. With SDP, we can participate in many social impact campaigns and win points and rewards. I am personally inviting you to participate in this campaign on SDP." +
                                  "\n" +
                                  "\n" +
                                  campaignList?.data?.[0]?.socialmediamsg +
                                  "\n" +
                                  "\n" +
                                  "Please use the link below and don't forget to enter your personal REFERRAL CODE " +
                                  " " +
                                  myreferralurl?.data?.refcode +
                                  " " +
                                  "when you participate in this campaign as that will win extra points for you!" +
                                  "\n" +
                                  myreferralurl?.data?.url
                                }
                                hashtag="#cyaag"
                              >
                                <FacebookIcon
                                  size={32}
                                  round={true}
                                ></FacebookIcon>
                              </FacebookShareButton>

                              <EmailShareButton
                                url=""

                                body={
                                  "Hello!" +
                                  "\n" +
                                  "I really liked this new SDP initiative. With SDP, we can participate in many social impact campaigns and win points and rewards. I am personally inviting you to participate in this campaign on SDP." +
                                  "\n" +
                                  "\n" +
                                  campaignList?.data?.[0]?.socialmediamsg +
                                  "\n" +
                                  "\n" +
                                  "Please use the link below and don't forget to enter your personal REFERRAL CODE " +
                                  " " +
                                  myreferralurl?.data?.refcode +
                                  " " +
                                  "when you participate in this campaign as that will win extra points for you!" +
                                  "\n" +
                                  myreferralurl.data?.url
                                }
                                subject="Invitation to cYAAG"
                              >
                                <EmailIcon size={32} round={true} />
                              </EmailShareButton>

                              <TwitterShareButton
                                url={
                                  "Hello!" +
                                  "\n" +
                                  "I am inviting you to participate in this campaign on cYAAG SDP." +
                                  "\n" +
                                  "\n" +
                                  campaignList?.data?.[0]?.socialmediamsg +
                                  "\n" +
                                  "\n" +
                                  "Please use the link below and enter your REFERRAL CODE " +
                                  " " +
                                  myreferralurl?.data?.refcode +
                                  " " +
                                  "as that will win extra points for you!" +
                                  "\n" +
                                  myreferralurl.data?.url
                                }
                              >
                                <TwitterIcon size={32} round={true}></TwitterIcon>
                              </TwitterShareButton>
                            </ul>
                          </div>
                        </div>

                        <p>
                          You can now use a Social Media tool of your choice to
                          invite individuals and win more points. We are providing
                          you a message that you can send as-is or edit before
                          sending.
                        </p>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div id="participate" class="cyagg-sec-space">
              <div class="container">
                <div>
                  <h4>Participate Now</h4>
                </div>
                <br />
                <div style={{ textAlign: "center", color: "#1E90FF" }}>
                  This campaign runs from{" "}
                  <b>{campaignList?.data?.[0]?.startdate}</b> to{" "}
                  <b>{campaignList?.data?.[0]?.enddate}</b>
                </div>
                <div class="">
                  <div >
                    <div class="form-reg multiselectSpace">
                      <div class="row">
                        <div class="col-md-6">
                          <div>
                            <fieldset>
                              <label for="name">First Name</label>
                              <input
                                type="text"
                                id="name"
                                name="user_name"
                                value={profileResponse?.firstname}
                              />

                              <label for="name">Last Name</label>
                              <input
                                type="text"
                                id="name"
                                name="user_name"
                                value={profileResponse?.lastname}
                              />

                              <label for="name">Phone Number</label>
                              <input
                                type="text"
                                id="name"
                                name="user_name"
                                value={profileResponse?.phoneno}
                              />

                              <label for="password">Organization Name</label>
                              <input
                                type="text"
                                id=""
                                name=""
                                value={profileResponse?.organization}
                              />

                              <label for="password">Campaign Name</label>
                              {String(campaignList?.data?.[0]?.name).length >
                                20 ? (
                                <textarea
                                  type="text"
                                  id=""
                                  name=""
                                  value={campaignList?.data?.[0]?.name}
                                />
                              ) : (
                                <input
                                  type="text"
                                  id=""
                                  name=""
                                  value={campaignList?.data?.[0]?.name}
                                />
                              )}
                            </fieldset>
                          </div>
                        </div>


                        <div class="col-md-6">
                          <div>
                            {campRegFormQ?.data?.[0]?.qpattern !== "4" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "6" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "8" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "7" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "9" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "10" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "11" ? (
                              <div
                                className={classes.calendarWrap}
                                style={{ marginBottom: "20px" }}
                              >
                                <label for="password">
                                  {campRegFormQ?.data?.[0]?.formq0}
                                </label>
                                <input
                                  style={{
                                    height: "43px",
                                    width: "250px",
                                    maxWidth: "80%",
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                  }}
                                  value={`${format(
                                    range[0]?.startDate,
                                    "MM/dd/yyyy"
                                  )} to  ${format(
                                    range[0]?.endDate,
                                    "MM/dd/yyyy"
                                  )}`}
                                  readOnly
                                />
                                <i
                                  class="fa fa-calendar"
                                  aria-hidden="true"
                                  style={{
                                    marginLeft: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    hideShowCal();
                                  }}
                                ></i>
                                {hideShow ? (
                                  campaignList?.data?.[0]?.active ===
                                    "Upcoming" ? (
                                    <DateRange
                                      label={
                                        "When are you able to participate?"
                                      }
                                      startDatePlaceholder={campaignList?.data?.[0]?.startdateforui.replaceAll(
                                        ",",
                                        "/"
                                      )}
                                      className={classes.calendarElement}
                                      editableDateInputs={false}
                                      moveRangeOnFirstSelection={false}
                                      minDate={
                                        new Date(
                                          campaignList?.data?.[0]?.startdateforui.replaceAll(
                                            ",",
                                            "/"
                                          )
                                        )
                                      }
                                      maxDate={
                                        new Date(
                                          campaignList?.data?.[0]?.enddateforui.replaceAll(
                                            ",",
                                            "/"
                                          )
                                        )
                                      }
                                      ranges={range}
                                      direction="horizontal"
                                      onChange={(item) =>
                                        setRange([item.selection])
                                      }
                                      value={campaignValues.item}
                                    />
                                  ) : (
                                    <DateRange
                                      label={
                                        "When are you able to participate?"
                                      }
                                      className={classes.calendarElement}
                                      // editableDateInputs={true}
                                      editableDateInputs={false}
                                      moveRangeOnFirstSelection={false}
                                      // minDate = {new Date(2022, 9, 10)}
                                      // minDate={new Date(campaignList?.data?.[0]?.startdateforui)}
                                      // minDate={new Date()}
                                      // maxDate={
                                      //   new Date(
                                      //     campaignList?.data?.[0]?.enddateforui.replaceAll(
                                      //       ",",
                                      //       "/"
                                      //     )
                                      //   )
                                      // }
                                      // minDate={
                                      //   new Date(
                                      //     campaignList?.data?.[0]?.startdateforui.replaceAll(
                                      //       ",",
                                      //       "/"
                                      //     )
                                      //   )
                                      // }
                                      minDate={new Date()}
                                      maxDate={
                                        new Date(
                                          campaignList?.data?.[0]?.enddateforui.replaceAll(
                                            ",",
                                            "/"
                                          )
                                        )
                                      }
                                      ranges={range}
                                      // disabledDates={new Date(regStatusList?.registerdates.split(",")[0])}
                                      direction="horizontal"
                                      onChange={(item) =>
                                        setRange([item.selection])
                                      }
                                      value={campaignValues.item}
                                    />
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : campRegFormQ?.data?.[0]?.qpattern !== "7" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "8" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "9" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "10" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "11" ? (
                              <div
                                className={classes.calendarWrap}
                                style={{ marginBottom: "20px" }}
                              >
                                <label for="password">
                                  {campRegFormQ?.data?.[0]?.formq0}
                                </label>

                                <input
                                  type="text"
                                  id=""
                                  name=""
                                  value={
                                    campaignList?.data?.[0]?.startdate +
                                    // campaignList?.data?.[0].startdateforui +
                                    " to " +
                                    campaignList?.data?.[0]?.enddate
                                    // campaignList?.data?.[0]?.enddateforui
                                  }
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            {campRegFormQ?.data?.[0]?.qpattern === "9" ||
                              campRegFormQ?.data?.[0]?.qpattern === "10" ? (
                              <div>
                                <label for="job">
                                  {campRegFormQ?.data?.[0]?.formq7}
                                </label>
                                <input
                                  type="text"
                                  id="create-yaad--title"
                                  name=""

                                  value={campaignValues?.formq7a}
                                  onBlur={() => {
                                    if (!campaignValues?.formq7a) {
                                      setCampaignRegistrationValuesError({
                                        ...campaignRegistrationValuesError,
                                        formq7CampaignError: true,
                                      });
                                    } else {
                                      setCampaignRegistrationValuesError({
                                        ...campaignRegistrationValuesError,
                                        formq7CampaignError: false,
                                      });
                                    }
                                  }}
                                  onChange={(e) => {
                                    handleCampaignFieldChange1("formq7a", e);
                                  }}
                                />
                                {campaignRegistrationValuesError?.formq7CampaignError && (
                                  <ErrorMessage message="Please type your response" />
                                )}
                              </div>
                            ) : (
                              ""
                            )}

                            {
                              campRegFormQ?.data?.[0]?.qpattern === "11" ? (
                                <div style={{ marginBottom: "10px", maxHeight: "150px", overflow: "auto" }}>
                                  <div className="menuDropDownParticipate" style={{ marginBottom: "10px" }}>
                                    <label for="job">
                                      {campRegFormQ?.data?.[0]?.formq7}
                                    </label>

                                    <Select
                                      style={{
                                        width: "100%",
                                        fontSize: 14,
                                        height: 48,
                                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                                        border: "1px solid #ccc",
                                        color: "#8a97a0",
                                        backgroundColor: "#e8eeef"
                                        // placeholder: "Select"
                                      }}
                                      value={campaignValues?.formq7a}
                                      placeholder="Select"
                                      onBlur={() => {
                                        if (!campaignValues?.formq7a) {
                                          setCampaignRegistrationValuesError({
                                            ...campaignRegistrationValuesError,
                                            formq7CampaignError: true,
                                          });
                                        } else {
                                          setCampaignRegistrationValuesError({
                                            ...campaignRegistrationValuesError,
                                            formq7CampaignError: false,
                                          });
                                        }
                                      }}
                                      onChange={(e) =>
                                        handleCampaignFieldChange1("formq7a", e)
                                      }

                                    >
                                      {singleSelect1.map((item, index) => (
                                        <MenuItem style={{ fontSize: 16, overflow: "auto" }} value={item.singleSelect1}>
                                          {item.singleSelect1}
                                        </MenuItem>
                                      ))}
                                    </Select>

                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            {campaignRegistrationValuesError?.formq7CampaignError && (
                              <ErrorMessage message="Please select your response" />
                            )}


                            {
                              campaignValues?.formq7a === "Yes" &&
                                campRegFormQ?.data?.[0]?.qpattern === "11" ? (
                                <div className="menuDropDownParticipate" style={{ marginBottom: "10px" }}>
                                  <label for="job">
                                    {campRegFormQ?.data?.[0]?.formq10}
                                  </label>

                                  <Select
                                    style={{
                                      width: "100%",
                                      fontSize: 14,
                                      height: 48,
                                      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                                      border: "1px solid #ccc",
                                      placeholder: "Select",
                                      backgroundColor: "#e8eeef",
                                    }}
                                    value={campaignValues?.formq10a}
                                    placeholder="Select"
                                    onBlur={() => {
                                      if (!campaignValues?.formq10a) {
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          formq10CampaignError: true,
                                        });
                                      } else {
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          formq10CampaignError: false,
                                        });
                                      }
                                    }}
                                    onChange={(e) =>
                                      handleCampaignFieldChange1("formq10a", e)
                                    }

                                  >
                                    {singleSelect2.map((item, index) => (
                                      <MenuItem style={{ fontSize: 16 }} value={item.singleSelect2}>
                                        {item.singleSelect2}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {/* </div> */}
                                  {/* <input
                                    type="text"
                                    id="create-yaad--title"
                                    name=""
                                    value={campaignValues?.formq10a}
                                    onBlur={() => {
                                      if (!campaignValues?.formq10a) {
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          formq10CampaignError: true,
                                        });
                                      } else {
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          formq10CampaignError: false,
                                        });
                                      }
                                    }}
                                    onChange={(e) => {
                                      handleCampaignFieldChange1("formq10a", e);
                                    }}
                                  />
                                  {campaignRegistrationValuesError?.formq10CampaignError && (
                                    <ErrorMessage message="Please type your response" />
                                  )} */}
                                </div>
                              ) : (
                                ""
                              )}
                            {campaignRegistrationValuesError?.formq10CampaignError && (
                              <ErrorMessage message="Please select your response" />
                            )}

                            {campRegFormQ?.data?.[0]?.qpattern == "9" ? (
                              <div>
                                <label for="job">
                                  {campRegFormQ?.data?.[0]?.formq8}
                                </label>
                                <input
                                  type="text"
                                  id="create-yaad--title"
                                  name=""
                                  value={campaignValues?.formq8a}
                                  onBlur={() => {
                                    if (!campaignValues?.formq8a) {
                                      setCampaignRegistrationValuesError({
                                        ...campaignRegistrationValuesError,
                                        formq8CampaignError: true,
                                      });
                                    } else {
                                      setCampaignRegistrationValuesError({
                                        ...campaignRegistrationValuesError,
                                        formq8CampaignError: false,
                                      });
                                    }
                                  }}
                                  onChange={(e) => {
                                    handleCampaignFieldChange1("formq8a", e);
                                  }}
                                />
                                {campaignRegistrationValuesError?.formq8CampaignError && (
                                  <ErrorMessage message="Please type your response" />
                                )}
                              </div>
                            ) : (
                              ""
                            )}

                            {
                              campaignValues?.formq7a === "Yes" &&
                                campRegFormQ?.data?.[0]?.qpattern === "11" ? (
                                <div>
                                  <label for="job">
                                    {campRegFormQ?.data?.[0]?.formq8}
                                  </label>
                                  <input
                                    type="text"
                                    id="create-yaad--title"
                                    name=""
                                    value={campaignValues?.formq8a}
                                    placeholder="Your response"
                                    onBlur={() => {
                                      if (!campaignValues?.formq8a) {
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          formq8CampaignError: true,
                                        });
                                      } else {
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          formq8CampaignError: false,
                                        });
                                      }
                                    }}
                                    onChange={(e) => {
                                      handleCampaignFieldChange1("formq8a", e);
                                    }}
                                  />
                                  {campaignRegistrationValuesError?.formq8CampaignError && (
                                    <ErrorMessage message="Please type your response" />
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                            {campaignValues?.formq7a === "Yes" &&
                              campRegFormQ?.data?.[0]?.qpattern === "11" ? (
                              <div>
                                <label for="job">
                                  {campRegFormQ?.data?.[0]?.formq9}
                                </label>
                                <input
                                  type="text"
                                  id="create-yaad--title"
                                  name=""
                                  value={campaignValues?.formq9a}
                                  placeholder="Your response"
                                  onBlur={() => {
                                    if (!campaignValues?.formq9a) {
                                      setCampaignRegistrationValuesError({
                                        ...campaignRegistrationValuesError,
                                        formq9CampaignError: true,
                                      });
                                    } else {
                                      setCampaignRegistrationValuesError({
                                        ...campaignRegistrationValuesError,
                                        formq9CampaignError: false,
                                      });
                                    }
                                  }}
                                  onChange={(e) => {
                                    handleCampaignFieldChange1("formq9a", e);
                                  }}
                                />
                                {campaignRegistrationValuesError?.formq9CampaignError && (
                                  <ErrorMessage message="Please type your response" />
                                )}
                              </div>
                            ) : (
                              ""
                            )}

                            {
                              campRegFormQ?.data?.[0]?.qpattern === "11" ? (
                                <div>
                                  <label for="job">
                                    {campRegFormQ?.data?.[0]?.formq5}
                                  </label>
                                  <input
                                    type="text"
                                    id="create-yaad--title"
                                    name=""
                                    value={campaignValues?.formq5a}
                                    placeholder="Your response"
                                    onBlur={() => {
                                      if (!campaignValues?.formq5a) {
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          formq5CampaignError: true,
                                        });
                                      } else {
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          formq5CampaignError: false,
                                        });
                                      }
                                    }}
                                    onChange={(e) => {
                                      handleCampaignFieldChange1("formq5a", e);
                                    }}
                                  />
                                  {campaignRegistrationValuesError?.formq5CampaignError && (
                                    <ErrorMessage message="Please type your response" />
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                            {
                              campRegFormQ?.data?.[0]?.qpattern === "11" ? (
                                <div>
                                  <label for="job">
                                    {campRegFormQ?.data?.[0]?.formq6}
                                  </label>


                                  {/* <div class="createCampaignPage" style={{maxHeight:"54px"}}> */}
                                  {/* <div className={classes.formGroupN}> */}
                                  <Multiselect
                                    displayValue="multiSelect2"
                                    showCheckbox
                                    selectedValues={selected2}
                                    options={multiSelect2}
                                    onSelect={(event) => {
                                      setSelected2(event);
                                      if (_.isEmpty(event)) {
                                        setFocusError2(true);
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          formq6CampaignError: true,
                                        });
                                      } else {
                                        setFocusError2(false);
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          formq6CampaignError: false,
                                        });
                                      }
                                    }}
                                    onRemove={(event) => {
                                      if (_.isEmpty(event)) {
                                        setFocusError2(true);
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          formq6CampaignError: true,
                                        });
                                      } else {
                                        setFocusError2(false);
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          formq6CampaignError: false,
                                        });
                                      }
                                    }}
                                    onBlur={() => {
                                      if (_.isEmpty(selected2)) {
                                        setFocusError2(true);
                                      } else {
                                        setFocusError2(false);
                                      }
                                    }}
                                    value={selected2}

                                    style={{
                                      // multiselectContainer: {
                                      //   boxShadow: "0px 3px 6px #00000029",
                                      // },

                                      searchBox: {
                                        minHeight: 54,
                                        // maxHeight: 64,
                                        PaddingTop: 10,
                                        padding: "0px",
                                        border: "0px",
                                      },

                                    }}
                                  />
                                  {/* </div> */}

                                  {(focusError2) && (
                                    <ErrorMessage message="Please select atleast 1 option" />
                                  )}


                                  {/* <input
                                  type="text"
                                  id="create-yaad--title"
                                  name=""
                                  value={campaignValues?.formq6a}
                                  onBlur={() => {
                                    if (!campaignValues?.formq6a) {
                                      setCampaignRegistrationValuesError({
                                        ...campaignRegistrationValuesError,
                                        formq6CampaignError: true,
                                      });
                                    } else {
                                      setCampaignRegistrationValuesError({
                                        ...campaignRegistrationValuesError,
                                        formq6CampaignError: false,
                                      });
                                    }
                                  }}
                                  onChange={(e) => {
                                    handleCampaignFieldChange1("formq6a", e);
                                  }}
                                />
                                {campaignRegistrationValuesError?.formq6CampaignError && (
                                  <ErrorMessage message="Please type your response" />
                                )} */}
                                </div>
                              ) : (
                                ""
                              )}

                            {
                              campRegFormQ?.data?.[0]?.qpattern === "11" ? (
                                <div>
                                  <label for="job">
                                    {campRegFormQ?.data?.[0]?.formq14}
                                  </label>
                                  <Multiselect
                                    displayValue="multiSelect1"
                                    showCheckbox
                                    selectedValues={selected1}
                                    options={multiSelect1}
                                    onSelect={(event) => {
                                      setSelected1(event);
                                      if (_.isEmpty(event)) {
                                        setFocusError1(true);
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          formq14CampaignError: true,
                                        });
                                      } else {
                                        setFocusError1(false);
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          formq14CampaignError: false,
                                        });
                                      }
                                    }}
                                    onRemove={(event) => {

                                      if (_.isEmpty(event)) {
                                        setFocusError1(true);
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          formq14CampaignError: true,
                                        });
                                      } else {
                                        setFocusError1(false);
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          formq14CampaignError: false,
                                        });
                                      }
                                    }}
                                    onBlur={() => {
                                      if (_.isEmpty(selected1)) {
                                        setFocusError1(true);
                                      } else {
                                        setFocusError1(false);
                                      }
                                    }}
                                    value={selected1}
                                    style={{
                                      multiselectContainer: {
                                        // boxShadow: "0px 3px 6px #00000029",
                                        // margin: '10px 0',
                                      },

                                      searchBox: {
                                        minHeight: 54,
                                        PaddingTop: 10,
                                        padding: "0px",
                                        border: "0px",
                                      },
                                    }}
                                  />
                                  {(focusError1) && (
                                    <ErrorMessage message="Please select atleast 1 option" />
                                  )}

                                </div>
                              ) : (
                                ""
                              )}


                            {
                              campRegFormQ?.data?.[0]?.qpattern === "11" ? (
                                <div className="menuDropDownParticipate" style={{ marginBottom: "20px" }}>
                                  <label for="job">
                                    {campRegFormQ?.data?.[0]?.formq11}
                                  </label>

                                  <Select
                                    style={{
                                      width: "100%",
                                      fontSize: 14,
                                      height: 48,
                                      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                                      border: "1px solid #ccc",
                                      backgroundColor: "#e8eeef"

                                    }}
                                    value={campaignValues?.formq11a}
                                    placeholder="Select"
                                    onBlur={() => {
                                      if (!campaignValues?.formq11a) {
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          formq11CampaignError: true,
                                        });
                                      } else {
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          formq11CampaignError: false,
                                        });
                                      }
                                    }}
                                    onChange={(e) =>
                                      handleCampaignFieldChange1("formq11a", e)
                                    }
                                  >
                                    {singleSelect3.map((item, index) => (
                                      <MenuItem style={{ fontSize: 16 }} value={item.singleSelect3}>
                                        {item.singleSelect3}
                                      </MenuItem>
                                    ))}
                                  </Select>

                                </div>
                              ) : (
                                ""
                              )}

                            {campaignRegistrationValuesError?.formq11CampaignError && (
                              <ErrorMessage message="Please select your response" />
                            )}


                            {
                              campRegFormQ?.data?.[0]?.qpattern === "11" ? (
                                <div className="menuDropDownParticipate" style={{ marginBottom: "20px" }}>
                                  <label for="job">
                                    {campRegFormQ?.data?.[0]?.formq12}
                                  </label>

                                  <Select
                                    style={{
                                      width: "100%",
                                      fontSize: 14,
                                      height: 48,
                                      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                                      border: "1px solid #ccc",
                                      backgroundColor: "#e8eeef"

                                    }}
                                    value={campaignValues?.formq12a}
                                    placeholder="Select"
                                    onBlur={() => {
                                      if (!campaignValues?.formq12a) {
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          formq12CampaignError: true,
                                        });
                                      } else {
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          formq12CampaignError: false,
                                        });
                                      }
                                    }}
                                    onChange={(e) =>
                                      handleCampaignFieldChange1("formq12a", e)
                                    }
                                  >
                                    {singleSelect4.map((item, index) => (
                                      <MenuItem style={{ fontSize: 16 }} value={item.singleSelect4}>
                                        {item.singleSelect4}
                                      </MenuItem>
                                    ))}
                                  </Select>

                                </div>
                              ) : (
                                ""
                              )}

                            {campaignRegistrationValuesError?.formq12CampaignError && (
                              <ErrorMessage message="Please select your response" />
                            )}


                            {
                              campRegFormQ?.data?.[0]?.qpattern !== "4" &&
                                campRegFormQ?.data?.[0]?.qpattern !== "6" &&
                                campRegFormQ?.data?.[0]?.qpattern !== "8" &&
                                campRegFormQ?.data?.[0]?.qpattern !== "7" &&
                                campRegFormQ?.data?.[0]?.qpattern !== "9" &&
                                campRegFormQ?.data?.[0]?.qpattern !== "10" &&
                                campRegFormQ?.data?.[0]?.qpattern !== "11" ? (
                                <div>
                                  <label for="password">
                                    {campRegFormQ?.data?.[0]?.formq1}
                                  </label>
                                  <input
                                    type="number"
                                    id="create-yaad--title"
                                    name=""
                                    value={campaignValues?.hourOfParticipation}
                                    onBlur={() => {
                                      if (
                                        !campaignValues?.hourOfParticipation
                                      ) {
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          timeCommitmentCampaignError: true,
                                        });
                                      } else {
                                        setCampaignRegistrationValuesError({
                                          ...campaignRegistrationValuesError,
                                          timeCommitmentCampaignError: false,
                                        });
                                      }
                                    }}
                                    onChange={(e) => {
                                      // handleCampaignFieldChange("hourOfParticipation", e);
                                      handleCampaignFieldValidation(
                                        "hourOfParticipation",
                                        e
                                      );
                                    }}
                                  />
                                  {campaignRegistrationValuesError?.timeCommitmentCampaignError && (
                                    <ErrorMessage message="Please enter the time you will commit" />
                                  )}
                                </div>
                              ) : (

                                <div>
                                  {campRegFormQ?.data?.[0]?.qpattern === "9" ||
                                    campRegFormQ?.data?.[0]?.qpattern === "10" ? (

                                    <p class="mb-3" style={{ fontSize: "14px", marginBottom: "0rem" }}>
                                      {splitString1(campRegFormQ?.data?.[0]?.formq1)}
                                    </p>

                                  ) : (
                                    <label for="password">
                                      {campRegFormQ?.data?.[0]?.formq1}
                                    </label>
                                  )}
                                  {campRegFormQ?.data?.[0]?.qpattern !== "7" &&
                                    campRegFormQ?.data?.[0]?.qpattern !== "8" &&
                                    campRegFormQ?.data?.[0]?.qpattern !== "9" &&
                                    campRegFormQ?.data?.[0]?.qpattern !== "10" &&
                                    campRegFormQ?.data?.[0]?.qpattern !== "11" ? (
                                    <input
                                      type="text"
                                      id=""
                                      name=""
                                      value={
                                        campaignList?.data?.[0]
                                          .hourofparticipation
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )
                              // )
                            }

                            {campRegFormQ?.data?.[0]?.qpattern !== "4" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "6" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "8" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "7" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "9" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "10" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "11" ? (
                              <div>
                                <label for="password">
                                  {campRegFormQ?.data?.[0]?.formq2}
                                </label>
                                {/* {JSON.stringify(campRegFormQ?.data?.[0]?.qpattern)} */}
                                <input
                                  type="number"
                                  id="create-yaad--title"
                                  name=""
                                  value={campaignValues?.impact}
                                  onBlur={() => {
                                    if (!campaignValues?.impact) {
                                      setCampaignRegistrationValuesError({
                                        ...campaignRegistrationValuesError,
                                        impactCommitmentCampaignError: true,
                                      });
                                    } else {
                                      setCampaignRegistrationValuesError({
                                        ...campaignRegistrationValuesError,
                                        impactCommitmentCampaignError: false,
                                      });
                                    }
                                  }}
                                  onChange={(e) => {
                                    handleCampaignFieldValidation("impact", e);
                                  }}
                                />{" "}
                              </div>
                            ) : (
                              ""
                            )}

                            <div
                              style={{
                                textAlign: "left",
                                alignItems: "left",
                                justifyContent: "left",
                              }}
                            >

                            </div>
                            {campRegFormQ?.data?.[0]?.qpattern !== "7" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "8" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "9" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "10" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "11" ? (
                              <label for="job">
                                Please select your location
                              </label>
                            ) : (
                              ""
                            )}
                            {campRegFormQ?.data?.[0]?.qpattern !== "7" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "8" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "9" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "10" &&
                              campRegFormQ?.data?.[0]?.qpattern !== "11" ? (
                              <div>
                                <Multiselect
                                  displayValue="city"
                                  showCheckbox
                                  options={campaignList?.data?.[0]?.location}
                                  onSelect={(event) => {
                                    setChecked(event);
                                    if (_.isEmpty(event)) {
                                      setCampaignRegistrationValuesError({
                                        ...campaignRegistrationValuesError,
                                        locationCampaignError: true,
                                      });
                                    } else {
                                      setCampaignRegistrationValuesError({
                                        ...campaignRegistrationValuesError,
                                        locationCampaignError: false,
                                      });
                                    }
                                  }}
                                  onRemove={(event) => {
                                    setChecked(event);
                                    if (_.isEmpty(event)) {
                                      setCampaignRegistrationValuesError({
                                        ...campaignRegistrationValuesError,
                                        locationCampaignError: true,
                                      });
                                    } else {
                                      setCampaignRegistrationValuesError({
                                        ...campaignRegistrationValuesError,
                                        locationCampaignError: false,
                                      });
                                    }
                                  }}
                                  value={checked}
                                  style={{
                                    multiselectContainer: {
                                      boxShadow: "0px 3px 6px #00000029",
                                    },
                                    searchBox: {
                                      minHeight: 54,
                                      PaddingTop: 10,
                                    },
                                  }}
                                />
                                {campaignRegistrationValuesError?.locationCampaignError && (
                                  <ErrorMessage message="Please select the location" />
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          {/* <UploadFiles1 camp={campaignList?.data?.[0]?.campid} /> */}
                        </div>
                      </div>
                      {campRegFormQ?.data?.[0]?.qpattern !== "7" &&
                        campRegFormQ?.data?.[0]?.qpattern !== "9" &&
                        campRegFormQ?.data?.[0]?.qpattern !== "10" &&
                        campRegFormQ?.data?.[0]?.qpattern !== "11" && (
                          <UploadDocs camp={campaignList?.data?.[0]?.campid} />
                        )}

                      <div class="col-12" style={{ marginTop: "10px" }}>
                        <div class="text-center">
                          {/* campRegFormQ?.data?.[0]?.qpattern !== "4" ||
                            campRegFormQ?.data?.[0]?.qpattern !== "6" */}
                          {(campRegFormQ?.data?.[0]?.qpattern === "4" ||
                            campRegFormQ?.data?.[0]?.qpattern === "6" ||
                            campRegFormQ?.data?.[0]?.qpattern === "11") &&
                            regStatusList?.data?.message ===
                            "Already Registered" ? (
                            <Button type="submit" class="reg-m-bttn-disabled">
                              Register
                            </Button>
                          ) : campaignList?.data?.[0]?.paused === "yes" ? (
                            <Button type="submit" class="reg-m-bttn-disabled">
                              Register
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              class="reg-m-bttn"
                              onClick={() => {
                                handleRegister();
                              }}
                            >
                              Register
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>


            <div style={{ textAlign: "center" }}>
              <div class="col-md-6">
                <div class="logged-cam-detai mob-space1">
                  <div class="comm-backg">
                    <div class="points-rank">
                      <div class="row">
                        <div class="col-6">
                          <div class="text-center">
                            <span> My Points</span>
                            <h6 class="cam-curr-point">
                              {
                                campaignList?.data?.[0]?.myrankpoints
                                  ?.rewardpoint
                              }
                            </h6>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="text-center">
                            <span> My Rank</span>
                            <h6 class="cam-curr-point">
                              {
                                campaignList?.data?.[0]?.myrankpoints
                                  ?.rank
                              }
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div>
                      <div class="row">
                        <div class="col-6">
                          <div class="text-center">
                            <span class="pp-p-text">
                              Size of my community
                            </span>
                            <h6 class="pp-point">
                              {
                                campaignList?.data?.[0]?.mycommunity
                                  ?.refcount
                              }
                            </h6>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="text-center">
                            <span class="pp-p-text">
                              Community Points
                            </span>
                            <h6 class="pp-point">
                              {
                                campaignList?.data?.[0]?.mycommunity
                                  ?.rewardpoint
                              }
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>













            {/* <div class="cyagg-sec-space reg-camp-sec">
              <div class="container">
                <div>
                  <h4 class="site-title">Campaign</h4>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div>
                      <div class="campaigns-wrap campaigns-wrap-new">
                        <div>
                          <div>
                            <div class="campaign-img">
                              <div class="video-wrapper">
                                <div class="video-container">
                                  {campaignList?.data?.[0]?.video ? (
                                    <video
                                      controls
                                      id="video"
                                      preload="auto"
                                      poster={campaignList?.data?.[0]?.logo}
                                      autoplay
                                    >
                                      <source
                                        src={campaignList?.data?.[0]?.video}
                                        type="video/mp4"
                                      />
                                    </video>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            <h5 title={campaignList?.data?.[0]?.name} class="campaign-title-new">
                              {campaignList?.data?.[0]?.name}
                            </h5>

                            <div class="campaign-hashtags">
                              {campaignList?.data?.[0]?.tag.map((tag) => (
                                <a class="hash-styles">{tag.tag}</a>
                              ))}
                            </div>
                          </div>
                        </div>


                        <div className={classes.campaigntypewrapper}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            gap={0.5}
                          >
                            <h5 class="campaign-goal">
                              <a
                                class="hash-styles-goal"
                                style={{
                                  padding:
                                    "0px 5px 0px 0px",
                                  cursor: "initial",
                                }}
                              >
                                <span style={{ fontSize: "16px", fontWeight: 700 }}>Goal</span>: <span class="text-6" style={{ color: "black" }}>{campaignList?.data?.[0]?.goalquantity}{" "} {campaignList?.data?.[0]?.unitofgoal}</span>
                              </a>
                            </h5>
                          </Stack>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <div>
                              <div class="campaigns-details">
                                <div class="row">

                                  <div class="col-md-4 col-6">
                                    <div class="cam-cols">
                                      <div class="timer-here">
                                        <div class="text-center">
                                          <span class="campaign-time-title">
                                            Earn upto
                                          </span>
                                        </div>
                                        <div>
                                          <ul id="countdown">
                                            <li id="hours">
                                              <div class="numberhome"></div>
                                              <div class="label"></div>
                                            </li>
                                            <li id="minutes">
                                              <div class="numberhome">
                                                {
                                                  campaignList?.data?.[0]
                                                    ?.maxpointstobeearned
                                                }
                                              </div>
                                              <div class="label">Points</div>
                                            </li>
                                            <li id="seconds">
                                              <div class="numberhome"></div>
                                              <div class="label"></div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-md-6 col-6">
                                    <div class="cam-cols">
                                      <div class="timer-here">
                                        <div class="text-center">
                                          <span class="campaign-time-title">
                                            Time Left
                                          </span>
                                        </div>
                                        <div>
                                          <ul id="countdown">
                                            <li id="hours">
                                              <div class="number">
                                                {
                                                  campaignList?.data?.[0]
                                                    ?.dayrem
                                                }
                                              </div>
                                              <div class="label">Days</div>
                                            </li>
                                            <li id="minutes">
                                              <div class="number">
                                                {
                                                  campaignList?.data?.[0]
                                                    ?.hourrem
                                                }
                                              </div>
                                              <div class="label">Hrs</div>
                                            </li>
                                            <li id="seconds">
                                              <div class="number">
                                                {
                                                  campaignList?.data?.[0]
                                                    ?.minrem
                                                }
                                              </div>
                                              <div class="label">Min</div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>


                                <div class="p-bar-new">
                                  <div class="row ">
                                    <div class="col-6">
                                      <div>
                                        <h6>
                                          <span style={{ color: "#AAAAAA" }}>Pledged: </span>{" "} <span style={{ backgroundColor: "#FFDB58" }}>
                                            {Math.round(
                                              (campaignList?.data?.[0]?.committedcount)
                                            )}
                                          </span>
                                        </h6>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div
                                        style={{
                                          textAlign:
                                            "right",
                                        }}
                                      >
                                        <h6>
                                          <span style={{ color: "#AAAAAA" }}>Achieved: </span>
                                          {" "}
                                          <span style={{ backgroundColor: "#A9D18E" }}> {campaignList?.data?.[0]?.achievedcount}</span>
                                          {" "}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="progress br-30 campaign-progress" style={{ position: "relative" }}>
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{
                                        width: `${(campaignList?.data?.[0]?.committedcount / campaignList?.data?.[0]?.goalquantity) * 100}%`,
                                        backgroundColor: "#FFDB58",
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        zIndex: 1,
                                        height: "100%"
                                      }}
                                      aria-valuenow={Math.round((campaignList?.data?.[0]?.committedcount / campaignList?.data?.[0]?.goalquantity) * 100)}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>

                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{
                                        width: `${(campaignList?.data?.[0]?.achievedcount / campaignList?.data?.[0]?.goalquantity) * 100}%`,
                                        backgroundColor: "#A9D18E",
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        zIndex: 2,
                                        height: "100%"
                                      }}
                                      aria-valuenow={Math.round((campaignList?.data?.[0]?.achievedcount / campaignList?.data?.[0]?.goalquantity) * 100)}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </div>


                                <div class="mr-15 text-center">
                                  <a class="campaign-signin">
                                    Join Now{" "}
                                    <i
                                      class="fa fa-arrow-right"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div>
                      <div class="logged-cam-detai mob-space1">
                        <div class="aft-log-acc">
                          <div class="widget-content widget-content-area creative2-accordion-content">
                            <div id="creativeAccordion">
                              <div class="card mb-3">
                                <div
                                  class="card-header"
                                  id="creative2headingOne"
                                >
                                  <h5 class="mb-0 mt-0">
                                    <span
                                      role="menu"
                                      class="changeIcon collapsed"
                                      data-toggle="collapse"
                                      data-target="#creative2CollapseOne"
                                      aria-expanded="true"
                                      aria-controls="creative2CollapseOne"
                                    >
                                      <span class="icon">
                                        <i
                                          class="fa fa-plus"
                                          aria-hidden="true"
                                        ></i>
                                        <i
                                          class="fa fa-minus"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <span class="text ml-2">
                                        Achieve the goal and win rewards
                                      </span>
                                    </span>
                                  </h5>
                                </div>

                                <div
                                  id="creative2CollapseOne"
                                  class="collapse"
                                  aria-labelledby="creative2headingOne"
                                  data-parent="#creativeAccordion"
                                >
                                  <div class="card-body">
                                    <p class="mb-3">
                                      1. {campaignList?.data?.[0]?.goal}
                                    </p>
                                    <p class="mb-3">
                                      2. {campaignList?.data?.[0]?.desc}
                                    </p>
                                    <p class="mb-3">
                                      3. Win upto{" "}
                                      <b>
                                        {" "}
                                        {
                                          campaignList?.data?.[0]
                                            ?.maxpointstobeearned
                                        }{" "}
                                      </b>
                                      points by Participating or Inviting
                                      motivated friends to join this Campaign.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="card mb-3">
                                <div
                                  class="card-header"
                                  id="creative2HeadingTwo"
                                >
                                  <h5 class="mb-0 mt-0">
                                    <span
                                      role="menu"
                                      class="changeIcon collapsed"
                                      data-toggle="collapse"
                                      data-target="#creative2CollapseTwo"
                                      aria-expanded="false"
                                      aria-controls="creative2CollapseTwo"
                                    >
                                      <span class="icon">
                                        <i
                                          class="fa fa-plus"
                                          aria-hidden="true"
                                        ></i>
                                        <i
                                          class="fa fa-minus"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <span class="text ml-2">

                                        How to participate?

                                      </span>
                                    </span>
                                  </h5>
                                </div>
                                <div
                                  id="creative2CollapseTwo"
                                  class="collapse"
                                  aria-labelledby="creative2HeadingTwo"
                                  data-parent="#creativeAccordion"
                                >
                                  <div class="card-body">
                                    <p class="mb-3">
                                      {splitString1(
                                        // campaignList?.data?.[0]?.socialmediamsg
                                        campaignList?.data?.[0]?.whattodo
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="card mb-3">
                                <div
                                  class="card-header"
                                  id="creative2HeadingTwo"
                                >
                                  <h5 class="mb-0 mt-0">

                                    <span
                                      role="menu"
                                      class="changeIcon collapsed"
                                      data-toggle="collapse"
                                      data-target="#creative2CollapseThree"
                                      aria-expanded="false"
                                      aria-controls="creative2CollapseThree"
                                    >
                                      <span class="icon">
                                        <i
                                          class="fa fa-plus"
                                          aria-hidden="true"
                                        ></i>
                                        <i
                                          class="fa fa-minus"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                      <span class="text ml-2">
                                        Guidelines

                                      </span>
                                    </span>
                                  </h5>
                                </div>
                                <div
                                  id="creative2CollapseThree"
                                  class="collapse"
                                  aria-labelledby="creative2HeadingThree"
                                  data-parent="#creativeAccordion"
                                >
                                  <div class="card-body">
                                    <p class="mb-3">
                                      {splitString1(
                                        // campaignList?.data?.[0]?.socialmediamsg
                                        campaignList?.data?.[0]?.guidelines
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <hr />
                        <div class="comm-backg">
                          <div class="points-rank">
                            <div class="row">
                              <div class="col-6">
                                <div class="text-center">
                                  <span> My Points</span>
                                  <h6 class="cam-curr-point">
                                    {
                                      campaignList?.data?.[0]?.myrankpoints
                                        ?.rewardpoint
                                    }
                                  </h6>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="text-center">
                                  <span> My Rank</span>
                                  <h6 class="cam-curr-point">
                                    {
                                      campaignList?.data?.[0]?.myrankpoints
                                        ?.rank
                                    }
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div>
                            <div class="row">
                              <div class="col-6">
                                <div class="text-center">
                                  <span class="pp-p-text">
                                    Size of my community
                                  </span>
                                  <h6 class="pp-point">
                                    {
                                      campaignList?.data?.[0]?.mycommunity
                                        ?.refcount
                                    }
                                  </h6>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="text-center">
                                  <span class="pp-p-text">
                                    Community Points
                                  </span>
                                  <h6 class="pp-point">
                                    {
                                      campaignList?.data?.[0]?.mycommunity
                                        ?.rewardpoint
                                    }
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div class="container">
              <div class="cyagg-sec-space">
                <div>
                  <div class="row">
                    <div class="col-7">
                      <div>
                        <h4 class="site-title">Win Exciting Rewards!</h4>
                      </div>
                    </div>
                    <div class="col-5">
                      <div
                        style={{ textAlign: "right" }}
                        onClick={() => navigate(`/marketplace`)}
                      >
                        <a class="story-signin">
                          View More <i class="fa fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div>
                      <img
                        class="hide-on-mobile"
                        src={RewardMainImage}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="">
                      {/* <div class="reward-slide owl-carousel owl-theme "> */}
                      <OwlCarousel className="owl-theme" {...options}>
                        {marketplacelist &&
                          marketplacelist?.data?.map(
                            (reward, index) =>
                              index < 5 && (
                                <div class="item">
                                  <div class="reward-wrap">
                                    <div>
                                      <img
                                        class="reward-image"
                                        src={reward?.logo}
                                        style={{ width: "100%" }}
                                      />
                                    </div>
                                    <div class="reward-details">
                                      <div>
                                        <div>
                                          <div id="toggleAccordion">
                                            <div class="card mb-1">
                                              <div
                                                class="card-header"
                                                id="headingOne4"
                                              >
                                                <h5 class="mb-0 mt-0 reward-desc-title">
                                                  <span
                                                    role="menu"
                                                    class=""
                                                    data-toggle="collapse"
                                                    data-target={"#" + index}
                                                    aria-expanded="true"
                                                    aria-controls="iconChangeAccordionOne"
                                                  >
                                                    {reward?.rewardtype}
                                                    <i class="flaticon-down-arrow float-right"></i>
                                                  </span>
                                                </h5>
                                              </div>

                                              <div
                                                id={index}
                                                class="collapse"
                                                aria-labelledby="headingOne4"
                                                data-parent="#toggleAccordion"
                                              >
                                                <div class="card-body">
                                                  <p class="mb-3">
                                                    <div
                                                      className={
                                                        classes.subtitleWrapper
                                                      }
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 12,
                                                          color: "blue",
                                                        }}
                                                      >
                                                        {" "}
                                                        {reward?.subtitle}{" "}
                                                      </span>
                                                    </div>
                                                  </p>
                                                  <p class="mb-3">
                                                    <div
                                                      className={
                                                        classes.subtitleWrapper
                                                      }
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 12,
                                                          color: "green",
                                                        }}
                                                      >
                                                        {" "}
                                                        {reward?.title}{" "}
                                                      </span>
                                                    </div>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="redeem-pts">
                                          <div class="row">
                                            {/* <div class="col-6">
                                              {reward?.rewardcat ===
                                                "rewards" &&
                                              reward?.campid === "camp00001" ? (
                                                <div class="re-po-left">
                                                  <img src="https://png.pngtree.com/png-clipart/20220213/original/pngtree-coins-stack-icon-vector-png-image_7267762.png" />
                                                  <div class="pts">
                                                    <h5>{reward?.points}</h5>
                                                    <span>Points</span>
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div> */}
                                            {/* <div class="col-6">
                                              <div class="re-po-right">
                                                <a>Redeem</a>
                                              </div>
                                            </div> */}
                                          </div>
                                        </div>
                                        <div class="text-center">
                                          <h6 class="sponsor-title">
                                            Sponsors
                                          </h6>
                                          <div class="row sponserWrapper">
                                            <div class="">
                                              {isArray(reward?.sponsor) &&
                                                reward?.sponsor?.map(
                                                  (reward, index) => (
                                                    <img
                                                      style={{
                                                        width: "auto",
                                                        height: "50px",
                                                      }}
                                                      src={reward?.sponsorlogo}
                                                    />
                                                    // <div class="col-3">
                                                    //     <img style={{width:"100%"}} src="assets/img/sponsor.png"/>
                                                    // </div>
                                                    // <div class="col-3">
                                                    //     <img style={{width:"100%"}} src="assets/img/sponsor.png"/>
                                                    // </div>
                                                    // <div class="col-3">
                                                    //     <img style={{width:"100%"}} src="assets/img/sponsor.png"/>
                                                    // </div>
                                                  )
                                                )}
                                            </div>
                                          </div>
                                        </div>

                                        {/* <div>
                                        <img
                                          src="assets/img/won-rewards.jpg"
                                          class="won-re-img"
                                        />
                                      </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        {/* </div> */}
                      </OwlCarousel>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
};

export default Participate;
