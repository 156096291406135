/** @format */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Chip,Avatar } from "@material-ui/core";
import Header from "../components/Header";
import ColorMap from "../assets/color-map.png";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import Point from "../assets/point.png";
import Reward from "../assets/reward.png";
// import Certificate from "../assets/certificate.jpg";
// import Interview from "../assets/interview.jpg";
// import CEO from "../assets/ceo.jpg";
// import Voluntary from "../assets/Voluntary.jpg";
// import Educate from "../assets/educate.jpg";
// import Internship from "../assets/Internship.jpg";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { topAdopters } from "../redux/action/TopAdopterAction";
// import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { myRank } from "../redux/action/MyRankAction";
import { CopyRight } from "../components/CopyRight";
import ResponsiveDrawer from "../components/Header";
import TopHeaderScreen from "../components/TopHeader1";
import { Link } from "react-router-dom";
import {
  userProfile,
  userProfileReset,
} from "../redux/action/UserProfileActions";
import BronzeLvl from "../assets/bronze.png";
import SilverLvl from "../assets/silver.png";
import GoldLvl from "../assets/gold.png";
import DiamondLvl from "../assets/diamond.png";
import PlatinumLvl from "../assets/platinum.png";
import PointsOnAward from "../assets/pngwing 1.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UpNUp from "../assets/upnup.png";
import CAMPPROGRESS from "../assets/right-progress.png";
import RewardImage from "../assets/rewards.png";
import RewardMainImage from "../assets/rew.png";
import ProfileNoPhoto from "../assets/profileNoPhoto.png";
import { isEmpty, isArray } from "lodash";
import { fontWeight } from "@mui/system";
import { Button } from "@mui/material";
import PostRedeemConfirmation from "../components/PostRedeemConfirmation";
import CEOImage from "../assets/ceo.png";
import VideoInterviewImage from "../assets/videointerview.png";
import DiscountCouponImage from "../assets/discountcoupon.png";
import GiftCardImage from "../assets/giftcard.png";
import InternshipImage from "../assets/internship.png";
import SocialMediaImage from "../assets/socialmedia.png";
import CertificateImage from "../assets/certificate.png";
import RedeemConfirmation from "../components/RedeemConfirmation";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { FixedFooterIcon } from "../components/FixedFooterIcon";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const drawerWidth = 240;

// const data = {

//   homeCards: [

//     {
//       cardId: "1",
//       cardImg: Certificate,
//       cardTitle: "Given to you",
//       description: "On reaching silver level or higher",
//     },
//     {
//       cardId: "2",
//       cardImg: CEO,
//       cardTitle: "Meet and learn",
//       description: "On reaching Silver level or higher",
//     },
//     {
//       cardId: "3",
//       cardImg: Interview,
//       cardTitle: "Inspire others",
//       description: "On reaching Silver level or higher",
//     },
//     {
//       cardId: "4",
//       cardImg: Voluntary,
//       cardTitle: "8hrs Voluntary Service",
//       description: "Redeem with: 20000 cYAAG points",
//     },
//     {
//       cardId: "5",
//       cardImg: Educate,
//       cardTitle: "Do Good Now",
//       description: "Redeem with: 40000 cYAAG points",
//     },
//     {
//       cardId: "6",
//       cardImg: Internship,
//       cardTitle: "Gain Experience",
//       description: "On reaching Silver level or higher",
//     },

//   ],
//   table: [
//     // { id: 0, rank: 1, fullname: "Rajib", rewardpoint: 15000 },
//     // { id: 1, rank: 2, fullname: "Gautam", rewardpoint: 15000 },
//     // { id: 2, rank: 3, fullname: "Kiran", rewardpoint: 15000 },
//     { id: 0, rank: 1, firstname: "Rajib", rewardpoint: 15000 },
//     { id: 1, rank: 2, firstname: "Gautam", rewardpoint: 15000 },
//     { id: 2, rank: 3, firstname: "Kiran", rewardpoint: 15000 },
//   ],
// };

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  backBtn: {
    display: "flex",
    alignItems: "center",
  },
  homecard: {
    width: 120,
    margin: "0 7px 10px",
    // maxWidth: 250,
    // borderRadius: 4,
    boxShadow: "none",
    // backgroundColor: "whitesmoke",
  },
  cardContainer: {
    width: 200,
    margin: "0 7px 10px",
    // maxWidth: 250,
    borderRadius: 4,
    boxShadow: "none",
    border: "3px solid #000",
    height: 225,
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  mainContent: {
    padding: "90px 0 0 0",
    [theme.breakpoints.up("sm")]: {
      padding: "90px 0 0 220px",
    },
  },
  container: {
    // padding: "59px 20px",
    padding: "59px 20px 3px 20px",
    margin: "10px 0",
    // backgroundImage: "linear-gradient(#da8d0f, white)",
  },
  cardImg: {
    borderRadius: "5px 5px 0 0",
    height: 100,
    resizeMode: "content",
  },
  cardImgForLvl: {
    borderRadius: "5px 5px 0 0",
    height: 50,
    resizeMode: "content",
  },
  // cardTitleText: {
  //   textTransform: "capitalize",
  //   fontSize: 26,
  //   fontWeight: "500",

  //   [theme.breakpoints.down("sm")]: {
  //     fontSize: 18,
  //     fontWeight: "500",
  //   },
  // },
  cardTitleForName: {
    background: "#da8d0f",
    color: "#000",
    zIndex: 99,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    boxShadow: "0px 5px 10px rgba(0,0,0,0.2)",
  },
  offerCardImg: {
    borderRadius: "5px 5px 0 0",
    height: 120,
    resizeMode: "content",
  },
  imageWrapper: {
    // background: '#fff',
    padding: 16,
    height: 150,
  },
  table: {
    fontWeight: "bold",
    textAlign: "center",
  },
  offerCardText: {
    fontSize: 16,
    padding: "0 16px",
  },
  offerCardTitle: {
    width: "calc(50% - 14px)",
    textAlign: "center",
    fontSize: 16,
    padding: "0 16px",
  },
  tagWrapper: {
    padding: 3,

    // backgroundColor: "#E8E8E8",
    // backgroundColor: "#80BDE3",
    // backgroundColor: "#FFD580",
    backgroundColor: "#FF5733",
    color: "white",

    margin: 4,
    borderRadius: 4,
    // display: "flex",
    // display: "inline-flex",
    textAlign: "center",
  },
  pointsWrapper: {
    padding: 3,

    // backgroundColor: "#E8E8E8",
    // backgroundColor: "#80BDE3",
    // backgroundColor: "#FFD580",
    // backgroundColor: "#FF5733",
    // color: "white",

    margin: 4,
    borderRadius: 4,
    // display: "flex",
    // display: "inline-flex",
    textAlign: "center",
  },
  subtitleWrapper: {
    // padding: 3,

    // backgroundColor: "#E8E8E8",
    // backgroundColor: "#80BDE3",
    // backgroundColor: "#FFD580",
    // backgroundColor: "#FF5733",
    // color: "white",

    // margin: 4,
    // borderRadius: 4,
    // display: "flex",
    // display: "inline-flex",
    textAlign: "center",
  },
  // pageHeadingIcon: {
  //   fontSize: 25,
  //   // color: theme.palette.primary.main,
  //   color: "primary",
  //   marginLeft: 8,
  // },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8B34C",
    color: "#ffffff",
    textTransform: "uppercase",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    // '&:nth-of-type(odd)': {
    // 	backgroundColor: '#f7dbb3',
    // },
    // '&:nth-of-type(even)': {
    // 	backgroundColor: '#e9e7e8',
    // },
  },
}))(TableRow);

function createData(id, rank, firstname, rewardpoint) {
  return {
    id,
    rank,
    // fullname,
    firstname,
    rewardpoint,
  };
}

function MyPoints(props) {
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [level, setLevel] = React.useState("");
  const [nextLevel, setnextLevel] = React.useState("");
  //   const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rank, setRank] = React.useState({});
  const [me, setME] = React.useState(false);
  const [campregisterlist, setCampRegisterList] = useState([]);
  const [marketplacelist, setmarketplacelist] = useState([]);
  const [myredeemedrewards, setmyredeemedrewards] = useState([]);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const { isSuccess: topAdopterSuccess, response: topAdoptersResponse1 } =
    useSelector((state) => state.topAdopters);
  const { topAdoptersResponse, dashboardRewardResponse, myRankResponse } = props;

  function setDisplayAndIndex(bool, index) {
      setDisplayRedeemConfirmationModal(bool);
      setRedeemIndex(index);
    }
  const [displayRedeemConfirmationModal, setDisplayRedeemConfirmationModal] =  useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [redeemindex, setRedeemIndex] = useState(0);
  const [postRedeemDisplayModal, setPostRedeemDisplayModal] = useState(false);
  const [afterRedeem, setAfterRedeem] = useState([]);

  const options = {
    items: 3,
    margin: 10,
    loop: false,
    nav: false,
    autoWidth: false,
    autoplaySpeed: 900,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      566: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const handleRedeem = async () => {
    // setDisplayConfirmationModal(true);

    const payload = {
      //campid: locationPath?.state?.campid,
      // createdate: locationPath?.state?.createdate,
      // reviewstatus: reviewstatus,
      // reviewcomments: reviewComments,
      phoneno: localStorage.getItem("userid"),
      rewardtype: marketplacelist?.data?.[redeemindex]?.rewardtype,
      mpid: marketplacelist?.data?.[redeemindex]?.mpid,
      campid: marketplacelist?.data?.[redeemindex]?.campid,
      title: marketplacelist?.data?.[redeemindex]?.title,
      subtitle: marketplacelist?.data?.[redeemindex]?.subtitle,
      points: marketplacelist?.data?.[redeemindex]?.points,
      // sponsor: JSON.stringify(marketplacelist?.data?.[redeemindex]?.sponsor),
      sponsor: marketplacelist?.data?.[redeemindex]?.sponsor,

      // phoneno: locationPath?.state.phoneno,
    };
    try {
      const res = await axios.post(
        `/reward/redeem/${profileResponse?.phoneno}`,
        payload
      );
      // navigate("/reviewcampaign");
      setAfterRedeem(res?.data);
      // console.log(res?.data?.message);
      // alert("calling set display false")
      setDisplayRedeemConfirmationModal(false);
      if (res?.data?.message === "Reward successfully redeemed") {
        setPostRedeemDisplayModal(true);
        // navigate("/hacampscreen");
      } else {
        setDisplayConfirmationModal(true);
      }
      return res;
    } catch (err) {
      throw err;
    }
  };

  // Hide the modal
  const hideRedeemConfirmationModal = () => {
    setDisplayRedeemConfirmationModal(false);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handlePostRedeemModal = () => {
    setPostRedeemDisplayModal(false);
    navigate("/marketplace");
  };
  useEffect(() => {
    let temp = [];
    for (let i = 0; i < topAdoptersResponse.length; i += 1) {
      const formattedArray = [];
      formattedArray.push(
        topAdoptersResponse[i].rank,
        // topAdoptersResponse[i].fullname,
        topAdoptersResponse[i].firstname,
        topAdoptersResponse[i].rewardpoint,
        topAdoptersResponse[i].profilephoto,
        topAdoptersResponse[i].campparticipatedcount
      );

      temp.push(createData(i, ...formattedArray));
    }
    setRows(temp);
  }, [topAdoptersResponse]);
  

  useEffect(() => {
    dispatch(topAdopters(localStorage.getItem("userid")));
    dispatch(myRank(localStorage.getItem("userid")));
  }, [dispatch]);

  useEffect(() => {
    getCampRegistrationList();
    
  }, []);

  useEffect(() => {
    
    getRewardsFromMarketplace();
  }, []);

  //   console.log(rows, "rank");

  const filteredData = rows && rows.filter((rows) => rows.firstname === "ME");
  //   console.log(filteredData, "filteredData");
  // useEffect(() => {
  // 	if (data.table.filter(rows => rows.fullname === 'ME')) {
  // 		setME(false);
  // 		console.log('me', me);
  // 	} else {
  // 		setME(true);
  // 		console.log('me', me);
  // 	}
  // }, [rows]);

  useEffect(() => {
    if (dashboardRewardResponse.level === "0") {
      setLevel("Starter");
      setnextLevel("Bronze");
    } else if (dashboardRewardResponse.level === "1") {
      setLevel("Bronze");
      setnextLevel("Silver");
    } else if (dashboardRewardResponse.level === "2") {
      setLevel("Silver");
      setnextLevel("Gold");
    } else if (dashboardRewardResponse.level === "3") {
      setLevel("Gold");
      setnextLevel("Diamond");
    } else if (dashboardRewardResponse.level === "4") {
      setLevel("Diamond");
      setnextLevel("Platinum");
    } else if (dashboardRewardResponse.level === "5") {
      setLevel("Platinum");
    }
  }, [dashboardRewardResponse]);

  const getCampRegistrationList = async () => {
    try {
      const res = await axios.get(
        `vol_dashboard/camp/${localStorage.getItem("userid")}`
      );
      setCampRegisterList(res?.data);
      // JSON.stringify(res)
    
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getRewardsFromMarketplace = async () => {
    try {
      //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);
      const res = await axios.get(
        `/marketplace/read/${profileResponse?.phoneno}`
      );
      setmarketplacelist(res?.data);
   
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getMyRedeemedRewards = async () => {
    try {
      //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);

      const res = await axios.get(
        `reward/redeemed/${profileResponse?.phoneno}`
      );
      // console.log(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getMyRedeemedRewards().then((data) => {
      setmyredeemedrewards(data);
    });
  }, []);



  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        {/* <div id="mypoints"> */}
        {/* <Header navTitle="My Points & Rewards" /> */}
        {/* <main className={classes.mainContent} id="mypoints"> */}
        <div className={classes.container}>
          {/* <div class="reward-top-sec cyagg-sec-space" > */}
          {/* <div className={classes.backBtn} onClick={() => history.goBack()}> */}
          {/* <div className={classes.backBtn} onClick={() => navigate.goBack()}>
            <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
            <span style={{ paddingLeft: 4 }}> Back</span>
          </div> */}
          {/* <Link className={classes.backBtn} to={"/home"}>
            <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
            &nbsp;<span style={{ paddingLeft: 0 }}>  Back</span>
          </Link> */}
          <Button onClick={() => navigate(-1)} className={classes.backBtn}>
            <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
            <span style={{ paddingLeft: 3, fontSize: 14, color: "#1976d2" }}>
              Back
            </span>
          </Button>
          <div>
            <span
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Rewards
            </span>
          </div>
          <div class="reward-top-sec cyagg-sec-space">
            <div class="container pointsMobileWrapper">
              <div class="row">
                <div class="col-md-4 order-on-mob-2 col-6 leftAbsolute">
                  <div class="text-center">
                    <div class="t-reward-wrap">
                      <div class="col-one">
                        <img src={PointsOnAward} class="img-fluid"></img>
                      </div>
                      <div class="col-two">
                        <span>Points</span>
                        {/* <h5 style={{fontSize:"14px"}}>{myRankResponse.rewardpoint}</h5> */}
                        <h5 >{myRankResponse.rewardpoint}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="person-r-points">
                    <div class="per-img">
                    {
                                  // !(searchedUser?.[0]?.profilephoto ===     undefined) ||
                                  profileResponse?.profilephoto != "" ? (
                                    <img
                                    class="one1"
                                    src={profileResponse?.profilephoto}
                                  ></img>
                                  ) : profileResponse?.profilephoto === undefined ?  (
                                    <Avatar
                                      class="one1"
                                      style={{
                                        width: "122px",
                                        borderRadius: "50%",
                                        height: "122px",
                                        verticalAlign: "super",
                                        maxWidth: "100%",
                                        margin:"0 auto"
                                      }}
                                      src="/broken-image.jpg"
                                    />
                                    // <Avatar class="one1" style={{display:"inline-block",verticalAlign:"middle"}} src="/broken-image.jpg" />
                                    // <AccountCircleIcon fontSize="large" />
                                    
                                    //   <img
                                    //   src="/broken-image.jpg"
                                    //   class="img-fluid new-profile-image"
                                    // />
                                  ) : (
                                    <Avatar
                                      class="one1"
                                      style={{
                                        width: "122px",
                                        borderRadius: "50%",
                                        height: "122px",
                                        verticalAlign: "super",
                                        maxWidth: "100%",
                                        margin:"0 auto"
                                      }}
                                      src="/broken-image.jpg"
                                    />
                                    // <Avatar class="one1" style={{display:"inline-block",verticalAlign:"middle"}} src="/broken-image.jpg" />
                                    // <AccountCircleIcon fontSize="large" />
                                    
                                    //   <img
                                    //   src="/broken-image.jpg"
                                    //   class="img-fluid new-profile-image"
                                    // />
                                  )
                                  }
                     
                      {/* <img class="two2" src={GoldLvl}></img> */}
                    </div>
                    <div class="per-main-wrap">
                      <span class="per-main-name">
                        {profileResponse?.firstname} {profileResponse?.lastname}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-6 rightAbsolute">
                  <div class="text-center">
                    <div class="overall-wrap">
                      <div class="col-one">
                        {dashboardRewardResponse?.level === "1" ? (
                          <img src={BronzeLvl} class="img-fluid"></img>
                        ) : dashboardRewardResponse?.level === "2" ? (
                          <img src={SilverLvl} class="img-fluid"></img>
                        ) : dashboardRewardResponse?.level === "3" ? (
                          <img src={GoldLvl} class="img-fluid"></img>
                        ) : dashboardRewardResponse?.level === "4" ? (
                          <img src={DiamondLvl} class="img-fluid"></img>
                        ) : (
                          <img src={PlatinumLvl} class="img-fluid"></img>
                        )}
                      </div>
                      <div class="col-two">
                        <span>Level</span>
                        <h5>{level}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              fontSize: 18,
              padding: 8,
              // textTransform: "uppercase",
              textAlign: "center",
              // color: "rgba(175,84,32,1)",
            }}
          >
            <span style={{ fontWeight: "600", color: "rgba(175,84,32,1)" }}>
              {dashboardRewardResponse &&
                dashboardRewardResponse.nextlevel + " "}{" "}
            </span>

            {nextLevel === "" ? "" : ` points more to reach `}
            {nextLevel === "" ? (
              ""
            ) : (
              <span style={{ fontWeight: "600", textTransform: "uppercase" }}>
                {nextLevel + ""}
              </span>
            )}
            {nextLevel === "" ? "" : ` level`}
          </div>
        </div>

        {/* <TableContainer component={Paper} style={{ padding: 16 }}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Rank
                </StyledTableCell>
                <StyledTableCell style={{ textAlign: "center" }}>
                  Top Players
                </StyledTableCell>
                
                <StyledTableCell style={{ textAlign: "center" }}>
                  Points
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledTableRow>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {row.rank}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {" "}
                    
                    {row.firstname}
                  </StyledTableCell>

                  <StyledTableCell style={{ textAlign: "center", color: "rgba(175,84,32,1)" }}>
                    {row.rewardpoint}
                  </StyledTableCell>
                </StyledTableRow>
              ))}

              {filteredData.length < 1 && (
                <StyledTableRow style={{ backgroundColor: "#e9e7e8" }}>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {myRankResponse.myrank}
                  </StyledTableCell>

                  <StyledTableCell style={{ textAlign: "center" }}>
                    ME
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {myRankResponse.rewardpoint}
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer> */}

        <div class="progress-on-campaign cyagg-sec-space">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div>
                  <h4 class="site-title">Your Progress on Active Campaigns</h4>
                </div>
              </div>
            </div>

            <div>
              <div class="row">
                <div class="col-md-5">
                  <div class="u-custom-scrollbar">
                    {campregisterlist &&
                      campregisterlist.length > 0 &&
                      campregisterlist?.map((camp) => (
                        <div class="progressing-a-campaigns">
                          <div class="row">
                            <div class="col-md-7 col-7">
                              <div class  ="content-col-1">
                                <h6>{camp.name}</h6>
                                {/* {camp?.registrationstatus === "approved" ? ( */}
                                <span>
                                  Points: <span>{camp.rewardpoints}</span>
                                </span>
                              </div>
                            </div>
                            <div class="col-md-5 col-5">
                              <div class="content-col-2">
                                <div class="campaigns-leaders-re">
                                  <img src={UpNUp}></img>
                                  {/* <a href="/camp/leaderboard"><img src={UpNUp} onClick="/camp/leaderboard" ></img></a> */}

                                  {camp?.rank?.map((topper) =>
                                    topper?.profilephoto ? (
                                      <a>
                                        <img src={topper?.profilephoto} onClick={() => navigate(`/camp/leaderboard/${camp.campid}`)}></img>
                                      </a>
                                    ) : (
                                      <a>
                                        <img src={ProfileNoPhoto} onClick={() => navigate(`/camp/leaderboard/${camp.campid}`)}></img>
                                      </a>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {campregisterlist &&
                      campregisterlist.length == 0 &&
                      // campregisterlist?.map((camp) => (
                        <div class="progressing-a-0-campaigns">
                          Please register / participate in active campaigns
                          {/* <div class="row">
                            <div class="col-md-7 col-7">
                              <div class="content-col-1">
                                <h6>{camp.name}</h6>
                                
                                <span>
                                  Points: <span>{camp.rewardpoints}</span>
                                </span>
                              </div>
                            </div>
                            <div class="col-md-5 col-5">
                              <div class="content-col-2">
                                <div class="campaigns-leaders-re">
                                  <img src={UpNUp}></img>

                                  {camp?.rank?.map((topper) =>
                                    topper?.profilephoto ? (
                                      <a>
                                        <img src={topper?.profilephoto}></img>
                                      </a>
                                    ) : (
                                      <a>
                                        <img src={ProfileNoPhoto}></img>
                                      </a>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      }
                  </div>
                </div>
                <div class="col-md-7">
                  <div>
                    <img src={CAMPPROGRESS} class="img-fluid"></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cyagg-sec-space">
          <div class="container">
            <div class="row">
              <div class="col-6">
                <div>
                  <h4 class="site-title">Platform Leader Board</h4>
                </div>
              </div>
              <div class="col-6">
                <div 
                  style={{ textAlign: "right" }}
                  onClick={() => navigate(`/marketplace`)
                  }
                >
                  {/* <a href="/marketplace" class="story-signin">
                    Visit Market Place <i class="fa fa-chevron-right"></i>
                    <i class="fa fa-chevron-right"></i>
                  </a> */}
                  <a class="story-signin">
                    Visit Marketplace <i class="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div
                  class="col-md-4 hide-on-mobile"
                  style={{ paddingRight: "0px" }}
                >
                  <div class="leaderboard-left">
                    <div class="text-center">
                      <h5>Top Player</h5>
                      {
                        topAdoptersResponse1?.[0]?.profilephoto != "" ? (
                          <img
                            src={topAdoptersResponse1?.[0]?.profilephoto}
                          ></img>
                        ) : (
                          // <i class="fa fa-user mr-2 fa-lg"></i>
                          <span>
                            <AccountCircleIcon fontSize="large" />
                          </span>
                        )
                        // <img src={ProfileNoPhoto}></img>
                      }
                      <span style={{ fontSize: 36, padding: 24 }}>
                        {topAdoptersResponse1 &&
                          topAdoptersResponse1?.[0]?.firstname}{" "}
                        {topAdoptersResponse1?.[0]?.lastname}
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div class="col-md-8 col-12" style={{paddingLeft: "0px"}}> */}
                <div class="col-md-8 col-12" style={{ paddingLeft: "0px" }}>
                  <div class="fixedHeader" style={{ height: "469px" }}>
                    <table class="leader-b-table" style={{marginLeft:"2px"}}>
                      <thead>
                        <tr>
                          <th scope="col" style={{ textAlign: "center" }}>
                            {" "}
                            Rank
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Top Player Name
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            # of camps
                          </th>
                          <th scope="col" style={{ textAlign: "center" }}>
                            Total Points
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {topAdopterSuccess &&
                          topAdoptersResponse1?.map((row) => (
                            <tr>
                              <td
                                data-label="Account"
                                style={{ textAlign: "center" }}
                              >
                                {row?.rank}
                              </td>
                              {/* <td data-label="Due Date" style={{ padding: "9px 100px" }}> */}
                              <td data-label="Due Date">
                                <div class="top-player-box" >
                                  {/* <div class="leader-b-table-p" > */}
                                  <div class="top-player-box" style={{textAlign:"center"}}>
                                    {row?.profilephoto != "" ? (
                                      <img src={row?.profilephoto}></img>
                                    ) : (
                                      // <AccountCircleIcon fontSize="large"/>
                                      // <i class="fa fa-user mr-2 fa-lg"></i>
                                      <Avatar style={{display:"inline-block",verticalAlign:"middle"}} src="/broken-image.jpg" />
                                    )}
                                    </div>
                                    <div class="top-player-box" style={{textAlign:"center"}}>
                                    {"   "}
                                    {row?.firstname === "ME" ? (
                                      <span
                                        style={{
                                          color: "orange",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {row?.firstname}
                                      </span>
                                    ) : (
                                      <span>{row?.firstname}</span>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td
                                data-label="Amount"
                                style={{ textAlign: "center" }}
                              >
                                {row?.campparticipatedcount}
                              </td>
                              <td
                                data-label="Period"
                                style={{ textAlign: "center" }}
                              >
                                {row?.rewardpoint}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cyagg-sec-space">
          <div class="container">
            <div class="row">
              <div class="col-7">
                <div>
                  <h4 class="site-title">Rewards</h4>
                </div>
              </div>
              <div class="col-5">
              <div 
                  style={{ textAlign: "right" }}
                  onClick={() => navigate(`/marketplace`)
                  }
                >
                  <a class="story-signin">
                    Visit Marketplace <i class="fa fa-arrow-right"></i>
                  </a>
                </div>
              </div>
              <div class="col-5"></div>
            </div>
            <div>
              <div class="row">
                <div class="col-md-4">
                  <div>
                    <img
                      class="hide-on-mobile"
                      src={RewardMainImage}
                      style={{ width: "93%" }}
                    ></img>
                  </div>
                </div>

                <div class="col-md-8">
                  {/* <div> */}
                  <div class="">
                    {/* <div class="u-custom-scrollbar-for-rewards"> */}
                      {/* <div class="row"> */}
                      <OwlCarousel className="owl-theme" {...options}>
                          {marketplacelist &&
                            marketplacelist?.map(
                              (reward, index) =>
                                index < 5 && (
                                  <div class="item">
                                    <div class="reward-wrap">
                                      <div>

                                      {
                                      !(reward?.logo === "") ?(
                                        reward?.expired === "yes" ?
                                        <img
                                          class="reward-image"
                                          src={reward?.logo}
                                          style={{ width: "100%", opacity:0.4}}
                                        />
                                        :
                                        <img
                                          class="reward-image"
                                          src={reward?.logo}
                                          style={{ width: "100%" }}
                                        />
                                      ): (
                                        reward?.expired === "yes" ?
                                        <img
                                          class="reward-image"
                                          src={CEOImage}
                                          style={{ width: "100%", opacity:0.4}}
                                        />
                                        :
                                        <img
                                          class="reward-image"
                                          src={CEOImage}
                                          style={{ width: "100%" }}
                                        />
                                      )
                                      }







                                        {/* {reward?.rewardtype ===
                                        "30 mins with a CEO" ? (
                                          <img
                                            class="reward-imagehm"
                                            src={CEOImage}
                                            style={{ width: "100%" }}
                                          />
                                        ) : reward?.rewardtype ===
                                          "Certificate of Participation" ? (
                                          <img
                                            class="reward-imagehm"
                                            src={CertificateImage}
                                            style={{ width: "100%" }}
                                          />
                                        ) : reward?.rewardtype ===
                                          "Video Interview with You" ? (
                                          <img
                                            class="reward-imagehm"
                                            src={VideoInterviewImage}
                                            style={{ width: "100%" }}
                                          />
                                        ) : reward?.rewardtype ===
                                          "Discount Coupons" ? (
                                          <img
                                            class="reward-imagehm"
                                            src={DiscountCouponImage}
                                            style={{ width: "100%" }}
                                          />
                                        ) : reward?.rewardtype ===
                                          "Gift Cards" ? (
                                          <img
                                            class="reward-imagehm"
                                            src={DiscountCouponImage}
                                            style={{ width: "100%" }}
                                          />
                                        ) : reward?.rewardtype ===
                                          "Internship Opportunity" ? (
                                          <img
                                            class="reward-imagehm"
                                            src={InternshipImage}
                                            style={{ width: "100%" }}
                                          />
                                        ) : reward?.rewardtype ===
                                          "Social Media Recognition" ? (
                                          <img
                                            class="reward-imagehm"
                                            src={SocialMediaImage}
                                            style={{ width: "100%" }}
                                          />
                                        ) : (
                                          <img
                                            class="reward-imagehm"
                                            src={RewardMainImage}
                                            style={{ width: "100%" }}
                                          />
                                        )} */}
                                      </div>
                                      <div class="reward-details">
                                        <div>
                                          <div>
                                            <div id="toggleAccordion">
                                              <div class="card mb-1">
                                                <div
                                                  class="card-header"
                                                  id="headingOne4"
                                                >
                                                  <h5 class="mb-0 mt-0 reward-desc-title">
                                                    <span
                                                      role="menu"
                                                      class=""
                                                      data-toggle="collapse"
                                                      data-target={"#" + index}
                                                      aria-expanded="true"
                                                      aria-controls="iconChangeAccordionOne"
                                                    >
                                                      {reward?.rewardtype}
                                                      <i class="flaticon-down-arrow float-right"></i>
                                                    </span>
                                                  </h5>
                                                </div>

                                                <div
                                                  id={index}
                                                  class="collapse"
                                                  aria-labelledby="headingOne4"
                                                  data-parent="#toggleAccordion"
                                                >
                                                  <div class="card-body">
                                                    <p class="mb-3">
                                                      <div
                                                        className={
                                                          classes.subtitleWrapper
                                                        }
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 12,
                                                            color: "blue",
                                                          }}
                                                        >
                                                          {" "}
                                                          {
                                                            reward?.subtitle
                                                          }{" "}
                                                        </span>
                                                      </div>
                                                    </p>
                                                    <p class="mb-3">
                                                      <div
                                                        className={
                                                          classes.subtitleWrapper
                                                        }
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 12,
                                                            color: "green",
                                                          }}
                                                        >
                                                          {" "}
                                                          {reward?.title}{" "}
                                                        </span>
                                                      </div>
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {/* <div class="redeem-pts">
                                            <div class="row">
                                              <div class="col-6">
                                              {reward?.rewardcat === "rewards" &&  reward?.campid === "camp00001" ? (
                                                <div class="re-po-left">
                                                  <img src="https://png.pngtree.com/png-clipart/20220213/original/pngtree-coins-stack-icon-vector-png-image_7267762.png" />
                                                  <div class="pts">
                                                    <h5>{reward?.points}</h5>
                                                    <span>Points</span>
                                                  </div>
                                                </div>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              
                                            </div>
                                          </div> */}
                                          

                                          <div class="redeem-pts">
                                        <div class="row">
                                        <div class="col-12">
                                          <div class="re-po1-right">
                                            {!(
                                              reward?.campid === "camp00001"
                                            ) ? (
                                              <Button
                                                onClick={
                                                  () =>
                                                    navigate(
                                                      `/participate/${reward?.campid}/${profileResponse?.phoneno}`
                                                    )
                                                  // setDisplayConfirmationModal(true)

                                                  // setRedeemIndex(index)
                                                }
                                                // style={{ backgroundColor: "#2D7A74", color: "white", padding: "9px 10px" }}

                                                style={{
                                                  // float: "right",
                                                  // width: "114px",
                                                  textAlign:"left",
                                                  maxHeight:"50px"
                                                }}
                                              >
                                                <a
                                                  style={{
                                                    fontFamily: "sans-serif",
                                                  //   textOverflow:"ellipsis",
                                                  // overflow: "hidden",
                                                  }}
                                                >
                                                  {/* <Chip key={reward?.campname} fontSize="small" size="small" label={reward?.campname} /> */}
                                                  {reward?.campname}
                                                </a>
                                              </Button>
                                            ) : (
                                              <Button
                                                onClick={
                                                  () => navigate(`/aboutus`)
                                                  // setDisplayConfirmationModal(true)

                                                  // setRedeemIndex(index)
                                                }
                                                // style={{ backgroundColor: "#2D7A74", color: "white", padding: "9px 10px" }}

                                                style={{
                                                  // float: "right",
                                                  // width: "114px",
                                                  textAlign:"left",
                                                  maxHeight:"50px"
                                                }}
                                              >
                                                <a
                                                style={{
                                                  fontFamily: "sans-serif",
                                                }}
                                                
                                                >{reward?.campname}</a>
                                              </Button>
                                            )}
                                          </div>
                                        </div>
                                        </div>
                                        </div>



                                          <div class="text-center">
                                            <h6 class="sponsor-title">
                                              Sponsors
                                            </h6>
                                            <div class="row sponserWrapper">
                                              <div class="">
                                              {isArray(reward?.sponsor) &&
                                                reward?.sponsor?.map(
                                                  (reward, index) => (
                                                    // <div class="">
                                                      <img
                                                        style={{
                                                          width: "auto",
                                                          height: "50px",
                                                        }}
                                                        src={
                                                          reward?.sponsorlogo
                                                        }
                                                      />
                                                    //</div>
                                                  )
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                            )}
                          {/* </div> */}
                        </OwlCarousel>
                      {/* </div> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Typography
          className={classes.cardTitleText}
          variant="subtitle1"
          component="span"
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: 18,
            fontWeight: "600",
            padding: 8,
            textTransform: "uppercase",
            textAlign: "center",
            color: "rgba(4,59,92,1)",
          }}
        >
          Rewards You Could Win
        </Typography> */}
        {/* <div style={{ paddingLeft: 8, marginBottom: 60 }}>
          <GridList className={classes.gridList} cellHeight={280}>
            {data &&
              data.homeCards.map((data) => (
                <GridListTile
                  style={{ width: "auto !important" }}
                  key={data.image}
                >
                  <Card
                    className={classes.cardContainer}
                    onClick={props.onClick}
                  >
                    <div className={classes.imageWrapper}>
                      {
                        <CardMedia
                          image={data.cardImg}
                          className={classes.offerCardImg}
                        />
                      }
                    </div>
                    <CardContent
                      className={classes.cardTitle}
                      style={{ padding: "11px 0px" }}
                    >
                      <span className={classes.offerCardTitle}>
                        {data.cardTitle}
                      </span>
                      <span
                        className={classes.offerCardText}
                        variant="subtitle1"
                        component="span"
                        style={{
                          display: "flex",
                          fontSize: 10,
                          fontWeight: "600",
                        }}
                      >
                        {data.description}
                      </span>
                      {data.description1 && (
                        <span
                          className={classes.offerCardText}
                          variant="subtitle1"
                          component="span"
                          style={{
                            display: "flex",
                            fontSize: 10,
                            fontWeight: "600",
                          }}
                        >
                          {data.description1}
                        </span>
                      )}
                    </CardContent>
                  </Card>
                </GridListTile>
              ))}
          </GridList>
          <span
            style={{
              display: "flex",
              justifyContent: "flex-end",
              color: "red",
            }}
          >
          </span>
        </div> */}
        {/* </div> */}
        {/* </main> */}
        <CopyRight />
        <FixedFooterIcon />
        <RedeemConfirmation
            showModal={displayRedeemConfirmationModal}
            confirmModal={handleRedeem}
            hideModal={hideRedeemConfirmationModal}
            message={"Are you sure you want to redeem this reward?"}
          />
          <ConfirmationDialog
            showModal={displayConfirmationModal}
            // confirmModal={handleRedeem}
            hideModal={hideConfirmationModal}
            message={"You do not have enough points to redeem"}
          />
          <PostRedeemConfirmation
            showModal={postRedeemDisplayModal}
            confirmModal={handlePostRedeemModal}
            // hideModal={handlePostRedeemModal}
            message={"You have successfully redeemed"}
          />
        {/* </div> */}
      </section>
    </div>
  );
}
const mapStateToProps = (state) => ({
  topAdoptersResponse: state.topAdopters.response,
  myRewardResponse: state.myRewardReducer && state.myReward.response,
  myRewardSuccess: state.myRewardReducer && state.myReward.isSuccess,
  //   dashboardRewardResponse:state.dashboardReward && state.dashboardReward.response,
  dashboardRewardResponse:
    state.volDashBoardReward && state.volDashBoardReward.response,
  myRankResponse: state.myRank.response,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        topAdopters,
        myRank,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPoints);
