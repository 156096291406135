import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import HelpIcon from "@mui/icons-material/Help";

export default function BasicPopover(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div style={{display:"inline-block"}}>
      <div class="tooltip">
        <div
          style={{
            cursor: "pointer",
            position: "absolute",
            bottom: -12,
            left: 12,
            // color: "blue",
          }}
          aria-describedby={id}
          onClick={handleClick}
        >
          <HelpIcon color="primary" />
          {/* <HelpIcon color="blue" /> */}
        </div>
        {/* {JSON.stringify(props.content)} */}
        <span class="tooltiptext">{props.content}</span>
      </div>
    </div>
  );
}
