import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
 
const ConfirmationDialog = ({ showModal, hideModal, confirmModal, message }) => {

    return (
        <Modal show={showModal} onHide={hideModal}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header> */}
        <div class="modal-header">
          <h5 class="modal-title">Confirmation</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={hideModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {/* <Modal.Body><div className="alert alert-danger">{message}</div></Modal.Body> */}
        <Modal.Body><div className="alert alert-primary">{message}</div></Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={hideModal}>
            Cancel
          </Button>
          {/* <Button variant="danger" onClick={() => confirmModal(type, id) }> */}
          <Button variant="primary" onClick={() => confirmModal() }>
            Redeem
          </Button>
        </Modal.Footer>
      </Modal>
    )
}
 
export default ConfirmationDialog;