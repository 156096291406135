// import * as React, {useRef} from "react";
import React, { useEffect, useRef, useState } from "react";
import FormControl from "@mui/material/FormControl";
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TableContainer,
  Paper,
  Table,
  Typography,
  TableRow,
  withStyles,
  TableCell,
  TableFooter,
} from "@material-ui/core";
import {
  Avatar,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";

// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";

import _ from "lodash";
import RedStar from "../../components/RedStar";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link } from "react-router-dom";
// import { useDispatch } from "react-redux";
import { EditOutlined, RemoveRedEyeOutlined } from "@material-ui/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import jsPDF from "jspdf";
import { UserPerformanceReportDetailsTableComponent } from "../../components/Table";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import { NonceProvider } from "react-select";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },

    display: "block",
    display: "flex",
    //fontSize: 16,
    fontSize: "16px",
    marginTop: 16,
    padding: 10,
    fontWeight: "500",
    lineHeight: "18px",
    minHeight: 50,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {},

    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroupFull: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  featured:{
    // transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    // backgroundColor: "#fff",
    // display:"flex",
    // justifyContent: "space-between",
    // borderRadius: "8px",
    // marginBottom: "4px",
    // padding: "0 16px 0px 16px",
    // pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%",
      display:"flex",
      justifyContent: "space-between",
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%",
      display:"flex",
      justifyContent: "space-between",
    },
    [theme.breakpoints.only("md")]: {
      width: "80%",
      display:"flex",
      justifyContent: "space-between",
    },
    [theme.breakpoints.only("sm")]: {
      width: "50%",
      display:"flex",
    
    },
    [theme.breakpoints.only("xs")]: {
      width: "33%",
      display:"flex",
    
    },
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  table: {
    width: "100%",
    borderRadius: "10px",
    borderStyle: "solid",
    borderWidth: "1.5px",
    // tableLayout: "fixed",
  },
  tableRow: {
    borderBottom: "1px solid #d3d3d3",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  thead: {
    borderBottom: "1px solid #d3d3d3",
    borderTop: "1px solid #d3d3d3",
    height: 60,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.getContrastText(theme.palette.secondary.dark),
  },
  theadText: {
    fontWeight: "700",
    textAlign: "center",
    fontSize: 16,
    minWidth: 100,
  },
  tbodyText: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width: "200px",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // wordWrap: "breakWord",
    // overflow: "scroll",
  },
  participate: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width: "200px",
    fontWeight: "bold",
    // backgroundColor: theme.palette.info.light,
    color: theme.palette.info.light,
  },
  referral: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width: "200px",
    fontWeight: "bold",
    // backgroundColor: theme.palette.info.light,
    color: theme.palette.info.dark,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "#000",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid green !important",
    // '&:nth-of-type(odd)': {
    // 	backgroundColor: '#f7dbb3',
    // },
    // '&:nth-of-type(even)': {
    // 	backgroundColor: '#e9e7e8',
    // },
    "&:last-child": {
      borderBottom: "none",
    },
  },
  large: {
    width: "300px !important",
    height: "300px !important",
    // alignItems:"center",
  },
}))(TableRow);

function UserPerformanceReportDetails(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationPath = useLocation();
  const [activityList, setActivityList] = React.useState(undefined);
  const [myOrgRank, setMyOrgRank] = React.useState(undefined);

  React.useEffect(() => {
    getPerformanceReportOnUser();
  }, []);

  const getPerformanceReportOnUser = async () => {
    try {
      const res = await axios.get(
        `/so/admin/individual/volreport/${localStorage.getItem("userid")}/${
          locationPath?.pathname.split("/")?.[4]
        }`
      );
      
      setActivityList(res?.data);
      return res;
    } catch (err) {
      
      throw err;
    }
  };

  React.useEffect(() => {
    getCampRankReportOnUser();
  }, []);

  const getCampRankReportOnUser = async () => {
    try {
      const res = await axios.get(
        `/my/org/rank/${locationPath?.pathname.split("/")?.[4]}`
      );
      
      setMyOrgRank(res?.data);
      return res;
    } catch (err) {
      
      throw err;
    }
  };

  const downloadDocument = () => {
 
    const pdf = new jsPDF("l", "pt", "a2");
    var width = pdf.internal.pageSize.getWidth()
    pdf.text('report', width/2, 20)
    
    // const pdf = new jsPDF({
    //   orientation: "landscape",
    //   unit: "pt",
    //   format: [4, 2]
    // });
  //   pdf.autoTable(pdf, {
  //     // head: head,
  //     // body: someData,
  //     didParseCell: (hookData) => {
  //         if (hookData.section === 'head') {
  //             if (hookData.column.dataKey === 'Registered Date') {
  //                 hookData.cell.styles.halign = 'right';
  //             }
  //         }
  //     }
  // }); SOADashboardScreen

    // pdf.html(document.querySelector("#downloadPdf"), {
      pdf.html(document.querySelector("#SOADashboardScreen"), {
      callback: function (pdf) {
        var pageCount = pdf.internal.getNumberOfPages();
        // var width = pdf.internal.pageSize.getWidth()
        // pdf.text('report', width/2, 20, { align: 'center' })
        // pdf.deletePage(pageCount);
        

        pdf.save("cyaag.pdf");
      },
    });
  };

 
  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 20,
                // fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              <span style={{ fontWeight: "1000", fontSize: 22 }}>
                {activityList?.[0]?.firstname} {activityList?.[0]?.lastname}
              </span>
              's Performance Report
            </div>
            {/* <div>
              <Button
                className="btn download-btn"
                variant="contained"
                onClick={downloadDocument}
              >
                Download
              </Button>
            </div> */}
            <div style={{ fontFamily: "monospace", fontSize: 16 }}></div>




            <header id="SOADashboardScreen">
  <form autoComplete="off">
    <div
      style={{
        border: "1px solid #dadce0",
        borderRadius: 8,
        backgroundColor: "#fff",
        padding: "16px",
      }}
    >
      {/* Mobile view */}
      <div className="mobileView">
        <div className={`${classes.formGroupFull} myOrgProfile`} style={{ marginBottom: "15px", textAlign:"center" }}>
          <Avatar
            src={activityList?.[0]?.profilephoto}
            className={classes.large}
            style={{ display: "inline-flex" }}
          />
          <div>
            <Typography color="textSecondary" variant="body2">
              {activityList?.[0]?.emailid}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {activityList?.[0]?.state}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {activityList?.[0]?.country}
            </Typography>
          </div>
        </div>
        <div className={`${classes.formGroupFull} myOrgWrapper`} style={{ marginBottom: "15px", textAlign:"center" }}>
          <Typography
            style={{
              borderRadius: "100px",
              color: "white",
              display: "inline-flex",
              padding: "5px 10px",
              backgroundColor: "rgba(235, 149, 50, 1)",
            }}
          >
            My Organization
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {activityList?.[0]?.organization}
          </Typography>
        </div>
        <div className={`${classes.formGroupFull} myOrgRank`} style={{ marginBottom: "15px", textAlign:"center" }}>
          <Typography
            style={{
              borderRadius: "100px",
              color: "white",
              display: "inline-flex",
              padding: "5px 10px",
              backgroundColor: "rgba(235, 149, 50, 1)",
            }}
          >
            My Organization Rank
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {myOrgRank?.[0].rank}
          </Typography>
        </div>
      </div>

      {/* Desktop view */}
      <div className={`${classes.featured} desktopView`} style={{ display: "none", textAlign:"center" }}>
        <div className={`${classes.formGroupFull} myOrgWrapper`}>
          <Typography
            style={{
              borderRadius: "100px",
              color: "white",
              display: "inline-flex",
              padding: "5px 10px",
              backgroundColor: "rgba(235, 149, 50, 1)",
            }}
          >
            My Organization
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {activityList?.[0]?.organization}
          </Typography>
        </div>
        <div className={`${classes.formGroupFull} myOrgProfile`}>
          <Avatar
            src={activityList?.[0]?.profilephoto}
            className={classes.large}
            style={{ display: "inline-flex" }}
          />
          <div>
            <Typography color="textSecondary" variant="body2">
              {activityList?.[0]?.emailid}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {activityList?.[0]?.state}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {activityList?.[0]?.country}
            </Typography>
          </div>
        </div>
        <div className={`${classes.formGroupFull} myOrgRank`}>
          <Typography
            style={{
              borderRadius: "100px",
              color: "white",
              display: "inline-flex",
              padding: "5px 10px",
              backgroundColor: "rgba(235, 149, 50, 1)",
            }}
          >
            My Organization Rank
          </Typography>
          <Typography color="textSecondary" variant="body2">
            {myOrgRank?.[0].rank}
          </Typography>
        </div>
      </div>

      <div className={classes.formGroupFull}>
        <div id="showAll" style={{ overflow: "auto", textAlign: "center" }}>
          <div id="downloadPdf">
            <UserPerformanceReportDetailsTableComponent rows={activityList} />
          </div>
        </div>
      </div>
    </div>
  </form>
</header>

















            {/* <header id="SOADashboardScreen">
              <form autoComplete="off">
                <div
                  style={{
                    border: "1px solid #dadce0",
                    borderRadius: 8,
                    backgroundColor: "#fff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  ></div>
                  <div
                    className="centerAlign"
                    style={{ padding: "16px 0", marginBottom: 50 }}
                  >
                    <br />
                    <div className={`${classes.featured} desktopView`}>
                      <div className={`${classes.formGroupFull} myOrgWrapper`}>
                        <Typography
                          style={{
                            borderRadius: "100px",
                            color: "white",
                            display: "inline-flex",
                            padding: "5px 10px",
                            backgroundColor: "rgba(235, 149, 50, 1)",
                          }}
                        >
                          My Organization
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {activityList?.[0]?.organization}
                        </Typography>
                      </div>
                      <div className={`${classes.formGroupFull} myOrgProfile`}>
                        <Avatar
                          src={activityList?.[0]?.profilephoto}
                          className={classes.large}
                          style={{ display: "inline-flex" }}
                        />

                        <Typography color="textSecondary" variant="body2">
                          {activityList?.[0]?.emailid}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {activityList?.[0]?.state}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {activityList?.[0]?.country}
                        </Typography>
                        
                      </div>
                      <div className={`${classes.formGroupFull} myOrgRank`}>
                        
                        <Typography
                          style={{
                            borderRadius: "100px",
                            color: "white",
                            display: "inline-flex",
                            padding: "5px 10px",
                            backgroundColor: "rgba(235, 149, 50, 1)",
                          }}
                        >
                          My Organization Rank
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {myOrgRank?.[0].rank}
                        </Typography>
                      </div>
                    </div>

                    <div className="mobileView" style={{display:"none"}}>
                      <div className={`${classes.formGroupFull} myOrgProfile`} style={{marginBottom:"15px"}}>
                        <Avatar
                          src={activityList?.[0]?.profilephoto}
                          className={classes.large}
                          style={{ display: "inline-flex" }}
                        />

                        <Typography color="textSecondary" variant="body2">
                          {activityList?.[0]?.emailid}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {activityList?.[0]?.state}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {activityList?.[0]?.country}
                        </Typography>
                        
                      </div>
                      <div className={`${classes.formGroupFull} myOrgWrapper`} style={{marginBottom:"15px"}}>
                        <Typography
                          style={{
                            borderRadius: "100px",
                            color: "white",
                            display: "inline-flex",
                            padding: "5px 10px",
                            backgroundColor: "rgba(235, 149, 50, 1)",
                          }}
                        >
                          My Organization
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {activityList?.[0]?.organization}
                        </Typography>
                      </div>
                      <div className={`${classes.formGroupFull} myOrgRank`}>
                        <Typography
                          style={{
                            borderRadius: "100px",
                            color: "white",
                            display: "inline-flex",
                            padding: "5px 10px",
                            backgroundColor: "rgba(235, 149, 50, 1)",
                          }}
                        >
                          My Organization Rank
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {myOrgRank?.[0].rank}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.formGroupFull}>
                      <div id="showAll" style={{ overflow: "auto", textAlign:"center" }}>
                        <div id="downloadPdf">
                          <UserPerformanceReportDetailsTableComponent
                            rows={activityList}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </header> */}
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}
export default UserPerformanceReportDetails;
