/** @format */

const VALIDATOR_LIST_FETCHING_DATA = "VALIDATOR_LIST_FETCHING_DATA";
const VALIDATOR_LIST_FETCHING_DATA_FAILURE =
  "VALIDATOR_LIST_FETCHING_DATA_FAILURE";
const VALIDATOR_LIST_FETCHING_DATA_RESET = "VALIDATOR_LIST_FETCHING_DATA_RESET";
const VALIDATOR_LIST_FETCHING_DATA_SUCCESS =
  "VALIDATOR_LIST_FETCHING_DATA_SUCCESS";
const VALIDATOR_LIST_FETCHING_DATA_ATTEMPT =
  "VALIDATOR_LIST_FETCHING_DATA_ATTEMPT";
const validatorList = (payload) => ({
  type: VALIDATOR_LIST_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const validatorListReset = (payload) => ({
  type: VALIDATOR_LIST_FETCHING_DATA_RESET,
});
export {
  VALIDATOR_LIST_FETCHING_DATA,
  VALIDATOR_LIST_FETCHING_DATA_FAILURE,
  VALIDATOR_LIST_FETCHING_DATA_RESET,
  VALIDATOR_LIST_FETCHING_DATA_SUCCESS,
  VALIDATOR_LIST_FETCHING_DATA_ATTEMPT,
  validatorList,
  validatorListReset,
};
