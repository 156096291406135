/** @format */

import React, { useEffect, useState } from "react";
import Rating from "@material-ui/lab/Rating";
import { Link, Redirect, useHistory, useLocation, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  makeStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import _ from "lodash";

import HealthInsurance from "../../assets/health-insurance.png";
import VolunteerActivismSharpIcon from '@mui/icons-material/VolunteerActivismSharp';
import ShareIcon from "../../assets/yaagShare.png";

import CustomDrawer from "../../components/CustomHeader";
import { CopyRight } from "../../components/CopyRight";
import { volunteerMenuItems } from "../../utils/dict";
import { ResponsiveDialog } from "../../components/PopupComponent";
import { API_URL } from "../../services/Constants";
import axios from "axios";
import { hover } from "@testing-library/user-event/dist/hover";
// import { CustomizedProgressBar } from "../../components";
import { myReward } from "../../redux/action/MyRewardAction";
import ResponsiveDrawer from "../../components/Header";
import TopHeaderScreen from "../../components/TopHeader1";
import { userProfile } from "../../redux/action/UserProfileActions";
import { NoBackpackSharp } from "@mui/icons-material";
import { borders } from '@mui/system';
import { referralUrl } from "../../redux/action/ReferralURLAction";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import earth from "../../assets/earth.png";
import people from "../../assets/people.png";
import hands from "../../assets/hands.png";
import community from "../../assets/community.jpg";
import { yaagMyCommunity } from "../../redux/action/YaagMyCommunityAction";
import { volDashBoardReward } from "../../redux/action/DashboardReward";
import { RegistrationForm } from "../../components/RegistrationForm";
import  UploadFiles  from "../../components/UploadFiles";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    padding: "90px 0 0 20px",
    [theme.breakpoints.up("sm")]: {
      padding: "90px 0 0 220px",
    },
  },
  container: {
    padding: "0 20px 50px 20px",
    margin: "10px 0",
  },
  pageSubheading: {
    padding: 10,
    fontSize: 14,
    background: "#E9E7E7",
    display: "block",
    color: "#000",
    // marginBottom: 14,
    fontWeight: "500",
  },
  pageHeading: {
    textTransform: "uppercase",
    // fontSize: 14,
    background: theme.palette.primary.main,
    textAlign: "center",
    color: "#fff",
    padding: 3,
    fontWeight: "bold",
    // borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  // pageHeadingIcon: {
  //   fontSize: 25,
  //   // color: theme.palette.secondary.main,
  //   marginLeft: 8,
  // },
  backBtn: {
    display: "flex",
    alignItems: "center",
  },

  progressBarWrap: {
    // paddingTop: 28,
    paddingTop: 18,
    position: "relative",
  },
  progressBarLabel: {
    position: "absolute",
    bottom: 65,
    right: 0,
    fontWeight: "bold",
    // color: "#DC8E10",
    fontSize: 16,
  },
  progressBarCompleteLabel: {
    backgroundColor: "rgba(255,255,255,0)",
    position: "absolute",
    bottom: 30,
    right: 0,
    zIndex: 0.5,
    fontWeight: "bold",
    // color: "#fff",
    // color: "#FFA500"
  },
  progressBarCaption: {
    fontSize: 15,
    // fontWeight: "bold",
    display: "block",
    textAlign: "center",
    color: theme.palette.secondary.main,
    // color: "rgba(255,0,0,0)",
    color: "rgba(0,0,0,0.9)",
    paddingTop: 12,
  },
  goalsWrapper: {
    backgroundColor: "rgba(255,255,255,0.9)",
    borderRadius: 10,
    padding: 16,
    marginTop: 20,
    // boxShadow: "1px 3px 10px rgba(0,0,0,0.3)",
    boxShadow: "0.5px 0.5px 5px rgba(0,0,0,0.3)",
  },
  goalsWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 0px 20px 0px",
  },
  goalsHeading: {
    fontSize: 14,
    // color: theme.palette.secondary.main,
    // color: "rgba(0,0,0,0.9)",
    color: "rgba(4,59,92,1)",
    textTransform: "uppercase",
    fontWeight: "bold",
    marginRight: 10,
    display: "block",
  },
  goal: {
    fontSize: 14,
    display: "block",
    textAlign: "center",
  },
  bottomBorder: {},
  timeWrap: {
    justifyContent: "center",
    display: "flex",
    maxWidth: "100%",
  },
  leftBorder: {
    // justifyContent: "left",
    // color: theme.palette.secondary.main,
    color: "rgba(255,147,100,1)",
    fontSize: 18,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginRight: 4,
    // marginLeft: 260,
  },
  leftBorderWithBlack: {
    justifyContent: "left",
    // color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    // marginRight: 4,
    // marginLeft: 240,
  },
  leftBorderWithBlackFixed: {
    justifyContent: "left",
    // color: theme.palette.secondary.main,
    color: "rgba(255,147,100,1)",
    fontSize: 18,
    // fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    // marginRight: 4,
    // marginLeft: 230,
  },
  timeBox: {
    // background: theme.palette.primary.main,
    // border: "1px solid #fff",
    color: theme.palette.secondary.main,
    fontSize: 15,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    marginRight: 4,

    // width: "25%",
  },
  timeBoxLabel: {
    fontSize: 12,
    display: "block",
    textAlign: "center",
    fontWeight: 400,
  },
  goalsRewardWrap: {
    padding: "5px 0",
    maxWidth: 500,
    margin: "0 auto",
  },
  goalsRewardHeading: {
    fontWeight: "bold",
    display: "block",
    // textAlign: "center",
    textTransform: "uppercase",
  },
  rewardRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // padding: "4px 0",
    fontSize: 14,
  },
  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginRight: 10,
  },
  myRewards: {
    maxWidth: 500,
    margin: "0 auto",
    padding: "10px 0",
  },
  myRewardsHeading: {
    fontSize: 14,
    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 17,
    color: "rgba(4,59,92,1)",
  },
  myRewardsSubheading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    color: "rgba(4,59,92,1)",
    padding: "15px 0",
  },
  myRewardRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 16,
    padding: "5px 0",
    fontWeight: 500,
  },
  myRewardRowDesc: {
    fontSize: 18,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  plusIcon: {
    fontSize: 21,
    color: "#B6B6BA",
  },
  plusIconleft: {
    marginRight: 10,
  },
  plusIconRight: {
    marginLeft: 10,
  },
  myRewardRowCash: {
    display: "flex",
    alignItems: "center",
    flexShrink: 0,
    marginRight: 15,
  },
  myRewardRowPoints: {
    display: "flex",
    alignItems: "center",
    flexShrink: 0,
    marginLeft: 15,
  },
  myRewardRowvalue: {
    display: "block",
    position: "relative",
  },
  rewards: {
    paddingTop: 30,
  },
  totalRewards: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // color: theme.palette.secondary.main,
    // color: theme.palette.primary.main,
    color: "red",
    fontSize: 25,
    fontWeight: "bold",
    padding: "11px 0",
  },
  myRewardsWrap: {
    borderBottom: "1px solid #F8B34C",
  },
  myImpacts: {
    borderBottom: "2px solid #F8B34C",
    // borderBottom:"100%",
    padding: "25px 0",
    fontSize: 14,
    textAlign: "center",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  myImpactsWrap: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 -8px",
  },
  myImpact: {
    width: "calc(33.33% - 16px)",
    margin: "0 8px",
  },
  myImpactImgWrap: {
    width: 52,
    height: 50,
    margin: "0 auto 5px",
  },
  myImpactImg: {
    width: "100%",
  },
  myImpactTxt: {
    display: "block",
    fontSize: 12,
  },
  myCommunity: {
    borderBottom: "2px solid #F8B34C",
    padding: "15px 0",
    fontSize: 20,
    color: "#F8B34C",
  },
  myCommunityContentWrap: {
    display: "flex",
    justifyContent: "space-between",
  },
  myCommunityImg: {
    width: 100,
  },
  myCommunityPoints: {
    listStyle: "none",
    margin: "0 0 0 10px",
    padding: 0,
    color: "black",
  },
  inviteButton: {
    fontSize: 9,
    fontWeight: "bold",
    lineHeight: 1,
  },
  maxWidth500: {
    maxWidth: 500,
    margin: "0 auto",
    // fontSize: 14,
  },
  verifyButton: {
    fontSize: 12,
    fontWeight: "bold",
    maxWidth: 120,
    lineHeight: 1.2,
    marginRight: 10,
    marginTop: 8,
    top: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
      top: 20,
    },
  },
  fieldWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "50%",
    margin: 20,
    height: 20,
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  text: {
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  textBox: {
    fontSize: 12,
    height: 30,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  affiliation: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    height: 30,
  },
  affiliationText: {
    fontSize: 16,
    // bottom: 40,
    height: 30,
    width: "180px",
    fontSize: 12,
    // width: "150px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      width: "150px",
    },
  },
  validationBox: {
    width: 500,
    height: 200,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      width: "320px",
    },
  },
  button: {
    backgroundColor: "#2D7A74",
    borderRadius: 10,
    fontSize: 14,
    color: "#fff",
    margin: "8px 4px",
    width: "160px",
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    padding: 8,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  cursordefault: {
    backgroundColor: "#2D7A74",
    borderRadius: 10,
    fontSize: 14,
    color: "#fff",
    margin: "8px 4px",
    width: "200px",
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    padding: 8,
    textTransform: "uppercase",
    // "&:hover": {
    //   backgroundColor: "green",
    // },
    cursor: "default",
    "&:hover": {
      backgroundColor: "gray",
    },
  },

  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  shareWrapper: {
    backgroundColor: "rgba(45, 122, 116, 0.3)",
    borderRadius: 10,
    padding: 10,

    //The share button will be disabled for 30 Sep release
    cursor: "default",
    // "&:hover": {
    //   backgroundColor: "gray",
    // },
  },
  progressdef: {
    flexGrow: 1,
  },
  bannerImage: {
    backgroundSize: "100% 100%",
  },
  barroot: {
    backgroundColor: "#21466",
  },
  boxlike: {
    // width: "fit-content",
    width: "200px",
    display: "flex",
    flexDirection: "column",
    // maxWidth: "1000px",
    // height: "fit-content",
    border: "1px solid rgb(134, 133, 133)",
    // marginTop: "10px",
    // marginLeft: "500px",
    background: "white",
    // textAlign: "center",
    borderRadius: "10px",
    borderColor: "blue",
    alignItems: "center",
    justifyContent: "center",
    // padding: 10,
    // fontSize: 16,
    margin: "10px auto",
  },
  a1: {
    animation: "blinking 2s infinite",
    fontSize: 25,
  },
  pointBox: {
    // top: "22px",
    // left: "200px",
    // top: "22%",
    // borderBottom: "2px solid #F8B34C",
    minWidth: "55px",
    height: "39px",
    background: "#1A524D 0% 0% no-repeat padding-box",
    borderRadius: "100px",
    opacity: "90%",
    color: "#FFFFFF",
    fontSize: 15,
    fontWeight: "bold",
    position: "relative",
    padding: 7,
    // paddingRight: 25,
    // padding: "15px 0",
    // color: "black",
    // display: "flex",
  },
  RefCodeWrapper: {
    padding: 10,

    backgroundColor: "#E8E8E8",
    // margin: 4,
    margin: "10px auto",
    borderRadius: 4,
    display: "flex",
    // display: "inline-flex",


    // display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "center",
    width: "226px",
  },
  input: {
    width: "125px",
    textAlign: "center",
  },
  // @keyframes blinking{
  // 0%{  color: "yellow", }
  // 47%{  color: "#000",}
  // 62%{  color: "transparent", }
  // 97%{  color: "transparent",}
  // 100%{ color: "#000",  }
  // },
}));
const dummyData = {
  name: "Test",
  logo: "https://cyaag-dev.sg.png",
  // video: "https://www.youtube.com/watch?v=9xwazD5SyVg",
  banner: "https://cyaag-dev.sg.png",
  campid: "camp00002",
  targetvol: "14",
  days: "14",
  dayrem: "13 ",
  hourrem: "0",
  minrem: "51",
  active: "Active",
};


const CampaignDetailScreen = (match, props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [campaignList, setCampaignList] = useState(null);
  const [rewardList, setRewardList] = useState(null);
  const rewardResponse = useSelector((state) => state.myReward);
  const [rewarddash, setRewardDash] = useState([]);
  const [mycommunity, setmycommunity] = useState([]);
  const [myreferralurl, setmyreferralurl] = useState([]);
  const [referralrewardmsg, setreferralrewardmsg] = useState([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [share, setShare] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [campRegFormQ, setCampRegFormQ] = useState([]);
  
  // console.log(rewardResponse.response?.[0]?.level);
  // const {
  //   isSuccess: organizationListSuccess,
  //   isError: organizationListError,
  //   response: organizationList,
  // } = useSelector((state) => state.organizationList);
  // const response1 = useSelector((state) => state.myReward);
  // console.log(response1);
  // const response = useSelector((state) => state.myReward.response);
  // const responseSuccess = useSelector((state) => state.myReward.isSuccess);

  const { isSuccess: myRewardSuccess, response: myRewardResponse } = useSelector(
    (state) => state.volDashBoardReward
  );

  const {
    response: profileResponse,
    isSuccess: profileSuccess,
    isFetching: isProfileFetching,
  } = useSelector((state) => state.profile);
  // console.log("PROFILE RESPONSE")
  // console.log(profileResponse)

  const referralUrlSuccess = useSelector(
    (state) => state.referralUrl.isSuccess
  );
  const referralUrlResponse = useSelector(
    (state) => state.referralUrl.response
  );
  const yaagMyCommunityResponse = useSelector(
    (state) => state.yaagMyCommunity.response
  );
  // console.log(profileResponse?.phoneno)
  // console.log(yaagMyCommunityResponse)
  // console.log(referralUrlResponse)
  // console.log(localStorage.getItem("userid"))
  // console.log(match.params.)
  const handleCancel = () => {
    setDialogOpen(false);
  };

  const handleShareButton = () => {
    setDialogOpen(true);
    // console.log(match);
    // console.log(match.params);
    // console.log(location?.pathname.split("/")?.[2]);
    // setShare(!share);
    dispatch(
      referralUrl({
        campid: location?.pathname.split("/")?.[2],

        // phoneno: localStorage.getItem("userid"),
        phoneno: profileResponse?.phoneno,
        // yaagid: match.params.yaagid,
        // campid: location?.pathname.split("/")?.[2],
      })
    );
  };

  // const {
  //   rewardresponse: rewardResponse,
  //   isSuccess: rewardSuccess,
  //   isFetching: isRewardFetching,
  // } = useSelector((state) => state.myReward);
  // console.log(rewardResponse)
  const responseSuccess = useSelector((state) => state.myReward.isSuccess);

  useEffect(() => {
    // if (profileSuccess) {
    // window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    localStorage.removeItem("refferalId");
    getCampaignDetails();
    getRegFormQuestions();
    getUserRewardLevel();
    dispatch(userProfile(localStorage.getItem("userid")));
    dispatch(myReward({
      phoneno: localStorage.getItem("userid"),
      campid: rewardList?.[0]?.campid,
    }));
    // }
    setLoading(false);
  }, []);
  // console.log(localStorage.getItem("userid"));

  useEffect(() => {
    // dispatch(yaagMyCommunity(localStorage.getItem("userid"), location?.pathname.split("/")?.[2]));
    dispatch(yaagMyCommunity(profileResponse?.phoneno, location?.pathname.split("/")?.[2]));
    getMyReward();
    getMyYaagCommunity();
    // getMyReferralURL();
    addCampViews();

  }, []);

  // console.log(`vol_dashboard/camp/${location?.pathname.split("/")?.[2]}/${profileResponse?.orgrole}/${profileResponse?.organization}`)
  const getCampaignDetails = async () => {
    try {
      // console.log(profileResponse?.orgrole)
      const res = await axios.get(
        // `/vol_dashboard/camp/${location?.pathname.split("/")?.[2]}`

        `vol_dashboard/camp/${location?.pathname.split("/")?.[2]}/${profileResponse?.orgrole}/${profileResponse?.organization}`
      );
      setCampaignList(res?.data);
      // console.log(res?.data)
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getUserRewardLevel = async () => {
    try {
      const res = await axios.get(
        `adopter_dashboard/camp/mynetworkreward/${profileResponse?.phoneno}/${location?.pathname.split("/")?.[2]}`
      );
      setRewardList(res?.data);
      // console.log(res)
      // console.log(res?.data?.[0]?.campid)
      dispatch(myReward(res?.data, res?.data?.[0]?.phoneno, res?.data?.[0]?.campid))
      return res;
    } catch (err) {
      throw err;
    }
  }
  const getMyReward = async () => {
    try {
      const res = await axios.get(`/vol_dashboard/myreward/${localStorage.getItem("userid")}`);
      setRewardDash(res?.data);
      dispatch(volDashBoardReward(res?.data, res?.data?.[0]?.phoneno));
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getMyYaagCommunity = async () => {
    try {
      
      const res = await axios.get(`adopter_dashboard/mycommunity/${localStorage.getItem("userid")}/${location?.pathname.split("/")?.[2]}`);
      setmycommunity(res?.data);
      dispatch(volDashBoardReward(res?.data, res?.data?.[0]?.phoneno));
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getRegFormQuestions = async () => {
    try {
      // console.log(profileResponse?.orgrole)
      const res = await axios.get(
        // `/vol_dashboard/camp/${location?.pathname.split("/")?.[2]}`

        `campaign/registrationform/${location?.pathname.split("/")?.[2]}`
      );
      setCampRegFormQ(res?.data);
      // console.log(res?.data)
      return res;
    } catch (err) {
      throw err;
    }
  };

  const addCampViews = async () => {
    
    const payload = {
      campid: location?.pathname.split("/")?.[2],
    };
    try {
      const res = await axios.put(`/camp/add/views`, payload);
      
      return res;
    } catch (err) {
      throw err;
    }
  };
  


  const splitString = (val) => {
    if (val) {
      const vallArray = val.split('.');

      return (
        <ul>
          {vallArray.map((bulletVal, index) => (

            <li>
              <p>
                {vallArray[index]}.
              </p>
            </li>

          ))}
        </ul>
      );
    }
  };


  const handleSubmit = async () => {

    try {

      // if (!campaignValues?.hourOfParticipation) {
      //   setCampaignRegistrationValuesError({
      //     ...campaignRegistrationValuesError,
      //     timeCommitmentCampaignError: true,
      //   });
      //  // const element = document.getElementById("errorKum");

      // // if (!fieldRef.current) return;
      //  fieldRef1.current.scrollIntoView({ behavior: "smooth" });
      //   //window.scrollTo(0, 0);
      //  } else if (!campaignValues?.impact) {
      //   setCampaignRegistrationValuesError({
      //     ...campaignRegistrationValuesError,
      //     impactCommitmentCampaignError: true,
      //   });
      //   fieldRef2.current.scrollIntoView({ behavior: "smooth" });
      //  } else if (!checked) {
      //   setCampaignRegistrationValuesError({
      //     ...campaignRegistrationValuesError,
      //     locationCampaignError: true,
      //   });
      //   fieldRef3.current.scrollIntoView({ behavior: "smooth" });
      //  } else {
      //  else {
      // setLoading(true);
      // console.log(location);
      // console.log(checked);

      const res = await axios.post(`/hareferralreward`, {
        campid: campaignList?.[0]?.campid,
        // formuserfullname: profileResponse?.fullname,

        phoneno: profileResponse?.phoneno,
        // reflevel: "1",
        // refuserid: profileResponse?.phoneno,    
        refcode: refValues?.refCode,


      });

      // setLoading(false);

      // handleClose();
      // navigate(`/adoption/${data?.[0]?.campid}`);
      setreferralrewardmsg(res?.data);
      return res;
      // }
    } catch (err) {
      // setLoading(false);

      throw err;
    }
  };

  const handleShare = async () => {

    try {
      setDialogOpen(true);

      // if (!campaignValues?.hourOfParticipation) {
      //   setCampaignRegistrationValuesError({
      //     ...campaignRegistrationValuesError,
      //     timeCommitmentCampaignError: true,
      //   });
      //  // const element = document.getElementById("errorKum");

      // // if (!fieldRef.current) return;
      //  fieldRef1.current.scrollIntoView({ behavior: "smooth" });
      //   //window.scrollTo(0, 0);
      //  } else if (!campaignValues?.impact) {
      //   setCampaignRegistrationValuesError({
      //     ...campaignRegistrationValuesError,
      //     impactCommitmentCampaignError: true,
      //   });
      //   fieldRef2.current.scrollIntoView({ behavior: "smooth" });
      //  } else if (!checked) {
      //   setCampaignRegistrationValuesError({
      //     ...campaignRegistrationValuesError,
      //     locationCampaignError: true,
      //   });
      //   fieldRef3.current.scrollIntoView({ behavior: "smooth" });
      //  } else {
      //  else {
      // setLoading(true);
      // console.log(location);
      // console.log(checked);

      const res = await axios.get(`refrralurl/${location?.pathname.split("/")?.[2]}/${localStorage.getItem("userid")}`, {


      });

      // setLoading(false);

      // handleClose();
      // navigate(`/adoption/${data?.[0]?.campid}`);
      setmyreferralurl(res?.data);
      return res;
      // }
    } catch (err) {
      // setLoading(false);

      throw err;
    }
  };

  const handleCampaignFieldChange = (field, e) => {
    setrefValues({
      ...refValues,
      [field]: field === "startDate" ? e : e.target.value,
    });
  };

  const [refValues, setrefValues] = React.useState({
    refCode: "",

  });

  const progressPercentage = (campaignList?.[0]?.adoptioncount / campaignList?.[0]?.targetvol) * 100;
  // console.log(progressPercentage)

 
  return (

    <div className="wrapper">
      {/* {profileResponse?.orgrole?.toLowerCase() === "hyphenadmin" ||
        profileResponse?.orgrole?.toLowerCase() === "admin" ? (
        <ResponsiveDrawer {...props} />
      ) : (
        <CustomDrawer menuItems={volunteerMenuItems} {...props} />
      )} */}

      <ResponsiveDrawer {...props} />


      {/* <CustomDrawer menuItems={volunteerMenuItems} {...props} /> */}
      <section className="containers" style={{ overflow: "hidden" }}>
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            {/* {JSON.stringify(campaignList)} */}

            {/* <div className={classes.container} id="main"> */}
            {/* <div class="content content-wrap"><div class="content-mrg"> */}
            {/* <Link className={classes.backBtn} to={"/home"}> */}
            <Button
                onClick={() => navigate(-1)}
                className={classes.backBtn}
            >
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>

            {/* <Link className={classes.backBtn} to={navigate(-1)}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}> Back</span>
            </Link> */}
            {/* <br></br> */}
            <span className={classes.pageSubheading} style={{ marginLeft: 0 }}>
              {/* {campaignList?.[0]?.goal}. This is an  {campaignList?.[0]?.campaigntype} campaign */}
              {campaignList?.[0]?.goal}.
            </span>
            <span className={classes.pageHeading}>
              <Typography
                variant="h1"
                component="h1"
                // style={{ margin: 10, textTransform: "capitalize", marginLeft: 0 }}
                style={{ margin: 5, fontSize: "18px", textTransform: "capitalize", marginLeft: 0 }}
              >
                {campaignList?.[0]?.name}
              </Typography>
            </span>
            {/* {JSON.stringify(isProfileFetching)}
            {JSON.stringify(loading)}
            {JSON.stringify(campaignList?.[0]?.video)} */}
            {/* {JSON.stringify(campaignList)} */}

            {
              isProfileFetching ? (
                // loading ? (

                <CircularProgress />
              ) : (
                <video controls
                  width="100%"
                  preload="auto"
                  autoplay
                //  poster={campaignList?.[0]?.logo}
                >
                  <source
                    src={campaignList?.[0]?.video}
                    type="video/mp4"
                    className={classes.imagesImg}
                    style={{ textTransform: "capitalize", marginLeft: 100 }}
                  />
                </video>
                // <img
                //     alt="home"
                //     src={campaignList?.[0]?.banner}
                //     className={classes.imagesImg}
                //     style={{ textTransform: "capitalize", marginLeft: 100 }}
                //     // maxHeight="300px"
                //     // minWidth="500"
                //     // height="300px"
                //     // width="100%"

                //     // // width="1100px"
                //     // display="flex"

                //   />


                //BANNER CODE
                // <div
                //   className={classes.bannerImage}
                //   // alt="home"
                //   // src={campaignList?.[0]?.banner}
                //   // maxHeight="300px"
                //   // minWidth="500"
                //   style={{ height: "800px", width: "100%", display: "flex", backgroundImage: "url(" + campaignList?.[0]?.banner + ")" }}



                // />
              )}


            <div className={classes.goalsWrapper} >
              <div className={classes.goalsWrap}>
                <Typography className={classes.goalsHeading} component="span">
                  Goal:
                </Typography>


                <Typography className={classes.goal} component="span">
                  <span style={{ fontWeight: "bold" }}>

                    {campaignList?.[0]?.targetvol}
                  </span>{" "}
                  Volunteers in{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {campaignList?.[0]?.days}
                  </span>{" "}
                  Days!
                </Typography>
              </div>
              <div className={classes.progressBarWrap}>
                {/* {campaignList?.[0]?.adoptioncount} */}
                {/* <span className={classes.progressBarLabel} style={{left: 100}}>
                {campaignList?.[0]?.adoptioncount}
              </span> */}

                <span
                  className={classes.progressBarCompleteLabel}
                  // style={{right: `${ 100 - (campaignList?.[0]?.adoptioncount / 
                  //       campaignList?.[0]?.targetvol) * 100
                  //   }%`,
                  //   left: 0,
                  // }}
                  style={{
                    // right: `${ 100 - (campaignList?.[0]?.adoptioncount / 
                    //     campaignList?.[0]?.targetvol) * 100  }`,
                    left: 0,
                    marginBottom: 35,
                    fontSize: 16,
                  }}

                >

                  {campaignList?.[0]?.adoptioncount}
                </span>

                {/* {campaignList?.[0]?.adoptioncount} */}
                <span className={classes.progressBarLabel}>
                  {campaignList?.[0]?.targetvol}
                </span>
                {/* <CustomizedProgressBar data={campaignList} /> */}
                {/* <BorderLinearProgress */}
                <div className={classes.progressdef}>
                  <LinearProgress
                    variant="determinate"
                    value={campaignList?.[0]?.adoptionrem === "0" ? "100" : progressPercentage}
                    // color = {{barColorPrimary: barroot}}
                    color="secondary"
                  // sx={{width: 500}}
                  />
                  <LinearProgress
                    variant="determinate"
                    value={campaignList?.[0]?.adoptionrem === "0" ? "100" : progressPercentage}
                    // color = {{barColorPrimary: barroot}}
                    color="secondary"
                  // sx={{width: 500}}
                  />
                  <LinearProgress
                    variant="determinate"
                    value={campaignList?.[0]?.adoptionrem === "0" ? "100" : progressPercentage}
                    // color = {{barColorPrimary: barroot}}
                    color="secondary"
                  // sx={{width: 500}}
                  />
                  <LinearProgress
                    variant="determinate"
                    value={campaignList?.[0]?.adoptionrem === "0" ? "100" : progressPercentage}
                    // color = {{barColorPrimary: barroot}}
                    color="secondary"
                  // sx={{width: 500}}
                  />
                  <LinearProgress
                    variant="determinate"
                    value={campaignList?.[0]?.adoptionrem === "0" ? "100" : progressPercentage}
                    // color = {{barColorPrimary: barroot}}
                    color="secondary"
                  // sx={{width: 500}}
                  />
                  <LinearProgress
                    variant="determinate"
                    value={campaignList?.[0]?.adoptionrem === "0" ? "100" : progressPercentage}
                    // color = {{barColorPrimary: barroot}}
                    color="secondary"
                  // sx={{width: 500}}
                  />
                </div>
                <span className={classes.progressBarCaption}>
                  <b>{campaignList?.[0]?.adoptioncount}</b> Registered So Far
                </span>
              </div>

              {/* <div className={classes.bottomBorder}> */}
              {/* <div className={classes.timeWrap}>
                    <span className={classes.timeBox}>Time Left: </span>
                    <span className={classes.timeBox}>
                      {" " + campaignList?.[0].dayrem} days
                    </span>
                    <span className={classes.timeBox}>
                      {campaignList?.[0].hourrem} hours
                    </span>
                    <span className={classes.timeBox}>
                      {campaignList?.[0].minrem} mins
                    </span>
                  </div> */}
              {/* <br></br> */}
              <div className={classes.boxlike} >
                {/* <span className={classes.leftBorder}>Time Left: </span> */}
                {/* <div class="col-lg-6 text-center">Time Left</div> */}
                {/* <div style={{flexdirection: "column", fontSize: 16}}>Time Left</div> */}

                <span style={{ fontSize: 15, fontWeight: "bold", color: "rgba(4,59,92,1)" }}> Time Left</span>

                {/* <Box component= 'span' sx={{ 
                    border: 100, 
                    borderColor: 'primary.main',
                    color: 'red',
                    height: '100px',
                    width: '100px',
                    marginLeft: '100',
                    padding: '40px',
                    display:"flex",
                    flexDirection:"column",
                    }}> */}


                {/* <div className={classes.bottomBorder}> */}

                {/* <div className={classes.leftBorderWithBlack}>
                    {campaignList?.[0].dayrem} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {campaignList?.[0].hourrem} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {campaignList?.[0].minrem}
                    </div>
                    <div className={classes.leftBorderWithBlackFixed}>
                      days &nbsp; hours &nbsp; mins
                    </div> */}

                {/* <div class="col-lg-8 text-center"> */}
                <div>
                  <span className="hoursWrapper leftAlignWrapper" style={{ fontSize: 14 }}>Days<span className="hoursCount">{campaignList?.[0].dayrem} : </span></span>
                  <span className="hoursWrapper leftAlignWrapper" style={{ fontSize: 14 }}>Hours<span className="hoursCount">{campaignList?.[0].hourrem} : </span></span>
                  <span className="hoursWrapper leftAlignWrapper" style={{ fontSize: 14 }}>Mins<span className="hoursCount">{campaignList?.[0].minrem}</span></span>
                </div>


                {/* <div className={classes.leftBorder}>
                    days
                    </div> */}

                {/* </div> */}
              </div>
              {/* </Box> */}

              {/* </div> */}
              {/* <br></br> */}
              <div className={classes.buttonWrapper}>


                {(campaignList?.[0]?.active === "Active") && (

                  <Button
                    data-toggle="modal"
                    data-target="#exampleModalLong"
                    className={classes.button}
                    onClick={() =>
                      // campaignList?.[0]?.dayrem === "00" 
                      // campaignList?.[0]?.active === "Upcoming" || 
                      // campaignList?.[0]?.active === "Completed"
                      //   ? setOpen(false)
                      //   : setOpen(true)

                      campaignList?.[0]?.active === "Upcoming"

                        ? setOpen(false)
                        : campaignList?.[0]?.active === "Completed"
                          ? setOpen(false)
                          : campaignList?.[0]?.active === "Paused"
                          ? setOpen(false)
                          : setOpen(true)

                    }>

                    {/* <img
                      alt="home"
                      src={HealthInsurance}
                      
                    // style={{ width: 24, height: 22 }}
                    /> */}
                    <VolunteerActivismSharpIcon />
                    {/* <Typography style={{ marginLeft: 12, fontSize: 14 }}> */}
                    <Typography style={{ marginLeft: 12, fontSize: 12 }}>
                      Participate Now
                    </Typography>
                  </Button>
                )}
                {!campaignList?.[0]?.active === "Active" && (

                  <Button className={classes.cursordefault} onClick={() =>
                    campaignList?.[0]?.active === "Upcoming"

                      ? setOpen(false)
                      : campaignList?.[0]?.active === "Completed"
                        ? setOpen(false)
                        : campaignList?.[0]?.active === "Paused"
                        ? setOpen(false)
                        : setOpen(true)

                  }>

                    <img
                      alt="home"
                      src={HealthInsurance}

                    />

                    <Typography style={{ marginLeft: 12, fontSize: 14 }}>
                      Participate Now
                    </Typography>
                  </Button>
                )}
                {(campaignList?.[0]?.active === "Active") && (
                  <Button
                    className={classes.shareWrapper}
                    onClick={handleShare}
                  // disabled
                  >
                    <img
                      alt="share"
                      src={ShareIcon}
                      style={{ width: 16, height: 20 }}
                    />
                  </Button>
                )}
              </div>


              {share && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingTop: 12,
                  }}
                >
                  <WhatsappShareButton
                    url={
                      // referralUrlResponse.referralurl +
                      // myreferralurl.referralurl +
                      myreferralurl.url +
                      "\n" +
                      " " +
                      myreferralurl.refcode +
                      "\n" +
                      " " +
                      campaignList?.[0]?.socialmediamsg

                      // myYaagResponse.whatsappmsg
                    }
                  >
                    <WhatsappIcon size={32} round={true}></WhatsappIcon>
                  </WhatsappShareButton>
                  <FacebookShareButton
                    url="app.cyaag.com"
                    quote={
                      // referralUrlResponse.referralurl +
                      // myreferralurl.referralurl +
                      // "\n" +
                      // " " +

                      myreferralurl.url +
                      "\n" +
                      " " +
                      myreferralurl.refcode +
                      "\n" +
                      " " +
                      campaignList?.[0]?.socialmediamsg

                      // myYaagResponse.whatsappmsg
                    }
                    hashtag="#cyaag"
                  >
                    <FacebookIcon size={32} round={true}></FacebookIcon>
                  </FacebookShareButton>
                  {/* <LinkedinShareButton
                  // url="app.cyaag.com"
                  title="Invitation to cYaag"
                  summary={
                    referralUrlResponse.referralurl +
                    "\n" +
                    " " +
                    myYaagResponse.whatsappmsg
                  }
                  source="Hello"
                >
                  <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton> */}
                  <EmailShareButton
                    url=""
                    body={
                      // referralUrlResponse.referralurl +
                      // myreferralurl.referralurl +
                      // "\n" +
                      // " " +

                      myreferralurl.url +
                      "\n" +
                      " " +
                      myreferralurl.refcode +
                      "\n" +
                      " " +
                      campaignList?.[0]?.socialmediamsg
                      // myYaagResponse.whatsappmsg
                    }
                    subject="Invitation to cYAAG"
                  >
                    <EmailIcon size={32} round={true} />
                  </EmailShareButton>
                  <TwitterShareButton
                    url={"url"}

                    // title={
                    //   referralUrlResponse.referralurl +
                    //   "\n" +
                    //   " " +
                    //   campaignList?.[0]?.socialmediamsg
                    // }

                    title={"Hello"}
                  >
                    <TwitterIcon size={32} round={true}></TwitterIcon>
                  </TwitterShareButton>
                </div>
              )}
              <div className={classes.goalsWrapper} >
                <Typography className={classes.goalsRewardHeading} component="span"
                  style={{
                    // fontWeight: "bold",
                    fontSize: 14,
                    padding: "16px 0",
                    textAlign: "center",
                    // textAlign: "justify",
                    color: "rgba(4,59,92,1)",
                  }}>
                  Enter Your Referral Code
                </Typography>
                {/* <Typography className={classes.RefCodeWrapper}> */}
                <Typography className={classes.RefCodeWrapper}>

                  {/* <span style={{ color: "blue", fontSize: 12 }}>
                  Enter Your Referral Code
                </span> */}
                  {/* {props.data.category} */}
                  <div
                  // style={{
                  //   display: "flex",
                  //   flexDirection: "row",
                  //   alignItems: "flex-end",
                  // }}
                  >
                    <input
                      value={refValues?.refCode}
                      // type="number"
                      type="text"
                      id="create-yaad--title"
                      className={classes.input}
                      placeholder="REFERRAL CODE"
                      min="10"
                      maxLength="10"
                      // onKeyPress={(e) => { preventMinus(e) }}
                      // onKeyDown={(e) => { handleKeypress(e)}}
                      // onBlur={() => {
                      //   if (!campaignValues?.budgetPointForReferral) {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       budgetPointForReferralError: true,
                      //     });
                      //   } else {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       budgetPointForReferralError: false,
                      //     });
                      //   }
                      // }}
                      onChange={(e) => {
                        handleCampaignFieldChange("refCode", e);
                      }}
                    />
                    {/* </div> */}

                    {/* <div className="centerAlign" style={{ padding: "16px 0", bottom: 50 }}> */}
                    <Button

                      variant="contained"
                      onClick={() => {
                        handleSubmit()
                      }}
                      className="btn save-code-btn"
                      disableElevation
                    // disabled={isCampaignFetching}
                    >
                      {loading ? (
                        <CircularProgress color="inherit" size={24} />
                      ) : (
                        "Enter"
                      )}
                    </Button>
                    <br />
                    {referralrewardmsg &&
                      <span style={{ textAlign: "center", display: "block", fontSize: 12, padding: "10px 0px" }}><i><b>{referralrewardmsg?.message}</b></i></span>
                    }
                    {/* {isCampaignSuccess && 
                  <span style={{ color: "green", textAlign: "center", display: "block" }}>{campaignResponse?.message}</span>}
                  {isCampaignError && (
                    // <span style={{ color: "red" }}>Error occured</span>
                    <span style={{ color: "red", textAlign: "center", display: "block" }}>{campaignResponse?.message}</span>
                  )} */}
                  </div>
                </Typography>
              </div>


              {/* </div> */}
              <div className={classes.goalsWrapper} >
                <div className={classes.goalsRewardWrap}>
                  <Typography
                    variant="h3"
                    component="span"
                    // color="secondary"
                    className={classes.goalsRewardHeading}
                    style={{
                      // fontWeight: "bold",
                      fontSize: 14,
                      // padding: "16px 0",
                      textAlign: "center",
                      // textAlign: "justify",
                      color: "rgba(4,59,92,1)",
                    }}
                  >
                    Achieve the goal and win rewards
                  </Typography>
                  <div
                    style={{
                      // display: "flex",
                      flexDirection: "row",
                      // marginLeft: "200",
                    }}
                  >
                    <Typography
                      variant="h3"
                      component="span"
                      //className={classes.rewardText}
                      className={classes.rewardRow}
                      style={{
                        // fontWeight: "bold",
                        fontSize: 14,
                        padding: "10px 0",
                        // textAlign: "center",
                        textAlign: "justify",
                        marginBottom: "7px",
                      }}
                    >
                      {" "}

                      <span>
                        1. {campaignList?.[0]?.desc}

                      </span>
                    </Typography>
                    <Typography
                      variant="h3"
                      component="span"
                      //className={classes.rewardText}
                      className={classes.rewardRow}
                      style={{
                        // fontWeight: "bold",
                        fontSize: 14,
                        padding: "10px 0",
                        // textAlign: "center",
                        textAlign: "justify",
                        marginBottom: "7px",
                      }}
                    >
                      {" "}
                      {/* <span>
                        You win points when you adopt, when you invite a friend, and
                        when that friend adopts.
                      </span> */}
                      <span>
                        2. You win points when you participate and also when you invite a friend and
                        that friend participates.

                      </span>
                      {/* <span>
                      {campaignList?.[0]?.desc}
                      </span> */}
                    </Typography>
                    {/* &nbsp; */}
                    <Typography
                      variant="h3"
                      component="span"
                      // className={classes.rewardText}
                      className={classes.rewardRow}
                      style={{
                        // fontWeight: "bold",
                        fontSize: 14,
                        // padding: "16px 0",
                        // textAlign: "center",
                        textAlign: "justify",
                        // margin: "0 10px",
                        // marginLeft: "200",
                        // maxWidth: "200",
                      }}
                    >
                      <span>
                        3. Win upto <b> {campaignList?.[0].totalbudgetpoints} </b>points by Participating or
                        Inviting motivated friends to join this Campaign.
                      </span>
                    </Typography>
                  </div>
                </div>
              </div>

              <div className={classes.goalsWrapper}>
                <div className={classes.goalsRewardWrap}>
                  <Typography
                    variant="h3"
                    component="span"
                    // color="secondary"
                    className={classes.goalsRewardHeading}
                    style={{
                      // fontWeight: "bold",
                      fontSize: 14,
                      // padding: "16px 0",
                      textAlign: "center",
                      // textAlign: "justify",
                      // margin: "0 10px",
                      // marginLeft: "200",
                      // maxWidth: "200",
                      color: "rgba(4,59,92,1)",
                    }}
                  >
                    {/* {JSON.stringify(campaignList?.[0].adoptioncount)} */}
                    What To Do ?
                    {/* {JSON.stringify(campaignList)} */}
                  </Typography>
                  <div

                    style={{
                      display: "flex",
                      flexDirection: "row",

                    }}
                  >
                    <Typography
                      variant="h3"
                      component="span"
                      // className={classes.rewardText}
                      className={classes.rewardRow}
                      style={{
                        // fontWeight: "bold",
                        fontSize: 14,
                        padding: "10px 0",
                        // fontSize: 18,
                        // padding: "16px 0",
                        // textAlign: "center",
                        textAlign: "justify",
                        marginBottom: "7px",
                      }}
                    >
                      {/* {campaignList?.[0]?.socialmediamsg} */}
                      {splitString(campaignList?.[0]?.socialmediamsg)}
                    </Typography>
                  </div>
                </div>
              </div>




              
              <div className={classes.goalsWrapper}>
                <div className={classes.goalsRewardWrap}>
                  <Typography
                    variant="h3"
                    component="span"
                    // color="secondary"
                    className={classes.goalsRewardHeading}
                    style={{
                      // fontWeight: "bold",
                      fontSize: 14,
                      // padding: "16px 0",
                      textAlign: "center",
                      // textAlign: "justify",
                      // margin: "0 10px",
                      // marginLeft: "200",
                      // maxWidth: "200",
                      color: "rgba(4,59,92,1)",
                    }}
                  >
                    {/* {JSON.stringify(campaignList?.[0].adoptioncount)} */}
                    FILE UPLOAD
                    {/* {JSON.stringify(campaignList)} */}
                  </Typography>
                  <UploadFiles camp={campaignList?.[0]?.campid}/>
                  
                </div>
              </div>










              <div className={classes.myRewardsWrap}>
                <div className={classes.myRewards}>
                  <Typography
                    variant="h1"
                    component="h1"
                    color="secondary"
                    className={classes.myRewardsHeading}
                  >
                    <br></br>
                    {/* My Points {responseSuccess && rewardResponse.response?.[0]?.rewardpoint} */}
                    My Points &nbsp;&nbsp;
                    <span className={classes.pointBox} style={{ fontSize: 18, color: "indigo", backgroundColor: "rgba(235, 149, 50, 1)" }}>{rewarddash.rewardpoint}</span>

                  </Typography>
                  <div className={classes.myImpacts}>
                    {/* <span className={classes.totalRewardsPrice}>₹ {data.myRewards.reduce((acc, reward) => acc + reward, 0)}</span>
								<span className={classes.totalRewardsPoints}>{data.myRewards.reduce((acc, reward) => acc + reward, 0)} pts</span> */}
                  </div>
                  <div className={classes.myRewardsSubheading}>
                    <Typography
                      variant="h2"
                      component="h2"

                      style={{
                        marginBottom: 0,
                        fontWeight: "bold",
                        marginRight: 14,
                        fontSize: 14,
                      }}
                    >

                      My Level

                    </Typography>

                    {/* {JSON.stringify(rewardList?.[0].level)}
                {JSON.stringify(rewardResponse.response?.[0]?.level)}
                
                {JSON.stringify(responseSuccess)} */}
                    {responseSuccess &&
                      <Rating
                        name="read-only"
                        // defaultValue={rewardList?.[0]?.level}
                        // defaultValue={1}
                        // value={rewardList?.[0]?.level}



                        value={responseSuccess && rewardResponse.response?.[0]?.level}
                        readOnly
                      />
                    }
                  </div>
                  {/* <div className={classes.totalRewards}> */}
                  <div className={classes.myImpacts}>
                    {/* <span className={classes.totalRewardsPrice}>₹ {data.myRewards.reduce((acc, reward) => acc + reward, 0)}</span>
								<span className={classes.totalRewardsPoints}>{data.myRewards.reduce((acc, reward) => acc + reward, 0)} pts</span> */}
                  </div>
                  {/* <div className={classes.rewards}>
                {responseSuccess &&
                  response.map((myReward, index) => (
                    <div key={myReward.id}>
                      
                      <div className={`${classes.myRewardRow}`}>
                        
                        <span className={classes.myRewardRowDesc}>
                          {myReward.name}
                        </span>
                        <span className={classes.myRewardRowPoints}>
                          <span className={`${classes.myRewardRowvalue}`}>
                            {myReward.rewardpoint} pts
                          </span>
                        </span>
                      </div>
                    </div>
                  ))}
              </div> */}

                  <div className={classes.myCommunity}>
                    <div className={classes.maxWidth500}>
                      <Typography
                        variant="h1"
                        color="primary"
                        component="span"
                        style={{
                          // textTransform: "uppercase",
                          textAlign: "center",
                          display: "block",
                          fontWeight: "bold",
                          color: "rgba(4,59,92,1)",
                          fontSize: 14,
                        }}
                      >
                        I CREATED A cYAAG COMMUNITY
                      </Typography>
                      <div className={classes.myCommunityContentWrap}>
                        <div className={classes.myCommunityImg}>
                          <img
                            src={community}
                            alt="Community"
                            style={{ width: "100%" }}
                          />
                        </div>
                        <ul className={classes.myCommunityPoints} style={{ fontSize: 14 }}>
                          {/* <li>{yaagMyCommunityResponse.refcount} People</li> */}
                          <li>{mycommunity.refcount} people</li>
                          {/* <li>People</li> */}
                          {/* <li>₹{yaagMyCommunityResponse.awardmoney} in rewards</li> */}
                          {/* <li>{yaagMyCommunityResponse.rewardpoint} points</li> */}
                          <li>{mycommunity.rewardpoint} points</li>
                          {/* <li> points</li> */}
                        </ul>
                      </div>
                    </div>
                  </div>


                  <div className={classes.myImpacts}>
                    <div className={classes.maxWidth500}>
                      <Typography
                        variant="h1"
                        color="primary"
                        style={{ textTransform: "uppercase", fontWeight: "bold", color: "rgba(4,59,92,1)", fontSize: 14 }}
                      >
                        My Impacts
                      </Typography>
                      <div className={classes.myImpactsWrap}>
                        <div className={classes.myImpact}>
                          <div className={classes.myImpactImgWrap}>
                            <img
                              src={earth}
                              alt="Earth"
                              className={classes.myImpactImg}
                            />
                          </div>
                          {/* <span className={classes.myImpactTxt} style={{ fontSize: 14 }}> */}
                          <span className={classes.myImpactTxt}>
                            {/* {myImpactResponse.envimpact} */}
                            {campaignList?.[0]?.envimpact}

                          </span>
                        </div>
                        <div className={classes.myImpact}>
                          <div className={classes.myImpactImgWrap}>
                            <img
                              src={people}
                              alt="People"
                              className={classes.myImpactImg}
                            />
                          </div>
                          <span className={classes.myImpactTxt}>
                            {/* {myImpactResponse.socialimpact} */}
                            {campaignList?.[0]?.socialimpact}
                          </span>
                        </div>
                        <div className={classes.myImpact}>
                          <div className={classes.myImpactImgWrap}>
                            <img
                              src={hands}
                              alt="LIC Hands"
                              className={classes.myImpactImg}
                            />
                          </div>
                          <span className={classes.myImpactTxt}>
                            {/* {myImpactResponse.finimpact} */}
                            {campaignList?.[0]?.finimpact}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>

          </div></div>
        <CopyRight />
      </section>
      <Dialog
        open={dialogOpen}
        onClose={handleCancel}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            {/* You can now use a Social Media tool of your choice to invite max
              of 5 individuals and not any groups. We are providing you a
              message that you can send as-is or edit before sending. */}
            You can now use a Social Media tool of your choice to invite
            individuals and groups. We are providing you a message that you
            can send as-is or edit before sending.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            onClick={() => {
              setShare(!share);
              setDialogOpen(false);
            }}
          >
            Continue
          </button>
        </DialogActions>
      </Dialog>
      <ResponsiveDialog open={open} setOpen={setOpen} data={campaignList} question={campRegFormQ} />
      {/* <RegistrationForm  data={campaignList} /> */}

    </div>

  );
};

export default CampaignDetailScreen;