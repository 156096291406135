/** @format */

import { applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import rootSaga from "./saga";
import { configureStore } from "@reduxjs/toolkit";
import LoginReducer from "./reducers/LoginReducer";
import RegisterReducer from "./reducers/RegisterReducer";
import ProfileReducer from "./reducers/UserProfileReducer";

import VerifyEmailReducer from "./reducers/VerifyEmailReducer";
import VerifyOTPReducer from "./reducers/VerifyOTPReducer";
import VolunteerCountReducer from "./reducers/volunteerCountReducer";
import StateUTReducer from "./reducers/statesUTReducer";
import GetCityReducer from "./reducers/cityReducer";
import OnBoardReducer from "./reducers/OnboardReducer";
import GetProfileImageReducer from "./reducers/userProfileImageReducer";
import GetReportReducer from "./reducers/getReportReducer";
import GetVolunteerNameReducer from "./reducers/getVolunteerNameReducer";
import GetOrganizationReducer from "./reducers/getOrganizationReducer";
import MyRewardReducer from "./reducers/MyRewardReducer";
import UpdateUserReducer from "./reducers/UpdateUserReducer";
import ValidatorListReducer from "./reducers/ValidatorListReducer";
import ValidatorReducer from "./reducers/ValidatorReducer";
import PurchaseYaagReducer from "./reducers/PurchaseYaagReducer";
import UpdateReducer from "./reducers/UpdateReducer";
import DashboardRewardReducer from "./reducers/DashboardRewardReducer";
import CampaignReducer from "./reducers/CampaignReducer";
import orgLogoReducer from "./reducers/orgLogoReducer";
import campLogoReducer from "./reducers/campLogoReducer";
import campBannerReducer from "./reducers/campBannerReducer";
import ReferralURLReducer from "./reducers/ReferralURLReducer";
import YaagMyCommunityReducer from "./reducers/YaagMyCommunityReducer";
import TopAdopterReducer from "./reducers/TopAdopterReducer";
import MyRankReducer from "./reducers/MyRankReducer";
import ReferralReducer from "./reducers/ReferralReducer";
import RewardDetailReducer from "./reducers/RewardDetailReducer";
import campVideoReducer from "./reducers/campVideoReducer";
import addRewardsReducer from "./reducers/addRewardsReducer";
import campFilesReducer from "./reducers/campFilesReducer";
import UserOnboardReviewReducer from "./reducers/UserOnboardReviewReducer";
import ActionUserOnboardReviewReducer from "./reducers/ActionUserOnboardReviewReducer";
import CampLeaderBoardReducer from "./reducers/CampLeaderBoardReducer";
import RewardsRedeemedListReducer from "./reducers/RewardsRedeemedListReducer";
import RewardRedeemeCertificateReducer from "./reducers/RewardRedeemCertificateReducer";
import RewardRedeemFulfilmentReducer from "./reducers/RewardRedeemFulfilmentReducer";
import LoginEmailReducer from "./reducers/LoginEmailReducer";
import onboardRewardsReducer from "./reducers/onboardRewardsReducer";
import addCampRewardsReducer from "./reducers/addCampRewardsReducer";
import addCampRecognitionsReducer from "./reducers/addCampRecognitionsReducer";
import addRecognitionsReducer from "./reducers/addRecognitionsReducer";
import onboardRecognitionsReducer from "./reducers/onboardRecognitionsReducer";
import DeleteReducer from "./reducers/DeleteReducer";

let sagaMiddleware = createSagaMiddleware();
const middleware = [];
middleware.push(sagaMiddleware);
// middleware.push(logger);
const store = configureStore({
  reducer: {
    login: LoginReducer,
    register: RegisterReducer,
    profile: ProfileReducer,
    verifyEmail: VerifyEmailReducer,
    verifyOTP: VerifyOTPReducer,
    getVolunteerCount: VolunteerCountReducer,
    states: StateUTReducer,
    onBoard: OnBoardReducer,
    profileImage: GetProfileImageReducer,
    cities: GetCityReducer,
    reportInfo: GetReportReducer,
    volunteerName: GetVolunteerNameReducer,
    organizationList: GetOrganizationReducer,
    myReward: MyRewardReducer,
    updateUser: UpdateUserReducer,
    validatorList: ValidatorListReducer,
    validateReward: ValidatorReducer,
    purchaseYaag: PurchaseYaagReducer,
    updateCampaign: UpdateReducer,
    updateOrg: UpdateReducer,
    volDashBoardReward: DashboardRewardReducer,
    getCampaign: CampaignReducer,
    updateBoard: UpdateReducer,
    getOrgProfileLogo: orgLogoReducer,
    getCampProfileLogo: campLogoReducer,
    getCampProfileBanner: campBannerReducer,
    referralUrl: ReferralURLReducer,
    yaagMyCommunity: YaagMyCommunityReducer,
    topAdopters: TopAdopterReducer,
    myRank: MyRankReducer,
    referral: ReferralReducer,
    getRewardTitle: RewardDetailReducer,
    // getRewardSubTitle: RewardDetailReducer,
    getCampProfileVideo: campVideoReducer,
    addRewards: addRewardsReducer,
    updateSponsor: UpdateReducer,
    updateReward: UpdateReducer,
    getCampFiles: campFilesReducer,
    getCampDocs: campFilesReducer,
    userreviewlist: UserOnboardReviewReducer,
    actionUserOnboardReview: ActionUserOnboardReviewReducer,
    campLeaderBoard: CampLeaderBoardReducer,
    rewardsRedeemedList: RewardsRedeemedListReducer,
    getRewardRedeemCert: RewardRedeemeCertificateReducer,
    rewardRedeemFulfilment: RewardRedeemFulfilmentReducer,
    socialEmailLogin: LoginEmailReducer,
    onboardRewards: onboardRewardsReducer,
    addCampRewards: addCampRewardsReducer,
    addCampRecognitions: addCampRecognitionsReducer,
    addRecognitions: addRecognitionsReducer,
    onboardRecognitions: onboardRecognitionsReducer,
    onboardKTCustomer: OnBoardReducer,
    updateKTCustomer: UpdateReducer,
    onboardKTAdmin: OnBoardReducer,
    updateKTCustomerTRN: UpdateReducer,
    deleteKTCustomerTRN: DeleteReducer,
    
  },
  middleware,
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
});

sagaMiddleware.run(rootSaga);

export default store;
