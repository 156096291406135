import {
  makeStyles,
  Chip,
  Button,
  Avatar,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "../../components/Header";
import {
  AcitvityTableComponent,
  CampaignTableComponent,
  ReviewOrgainzationTableComponent,
  TableComponent,
  ReviewSponsorTableComponent,
} from "../../components/Table";
import { getVolunteerName } from "../../redux/action/getVolunteerName";
import { userProfile } from "../../redux/action/UserProfileActions";
import { API_URL } from "../../services/Constants";
import { getVolunteerCount } from "../../redux/action/volunteerCountAction";
import { getCampaign } from "../../redux/action/CampaignAction";
import TopHeaderScreen from "../../components/TopHeader";
import { CopyRight } from "../../components/CopyRight";
import { Link } from "react-router-dom";
import { referral } from "../../redux/action/ReferralAction";
import { Title } from "chart.js";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate } from "react-router-dom";
import EarnImage from "../../assets/c-earn-upto.png";
import RewardMainImage from "../../assets/rew.png";
// import cYAAGlogo from "../../assets/logo-black.png";
// import cYAAGlogo from "../../assets/ieeesdp.png";
import cYAAGlogo from "../../assets/sdp.jpeg";
import StreakLeft from "../../assets/streak-left.png";
import StreakRight from "../../assets/streak-right.png";
import Impact1 from "../../assets/impact1.png";
import Impact2 from "../../assets/impact2.png";
import Impact3 from "../../assets/impact3.png";
// import BannerRight from "../../assets/banner-right.png";
import LandingPage1Img from "../../assets/landingpage1.jpg";
import LandingPage2Img from "../../assets/landingpage2.jpg";
import LandingPage3Img from "../../assets/landingpage3.jpg";
import VideoInterviewImage from "../../assets/videointerview.png";
import CertificateImage from "../../assets/certificate.png";
import DiscountCouponImage from "../../assets/discountcoupon.png";
import GiftCardImage from "../../assets/giftcard.png";
import InternshipImage from "../../assets/internship.png";
import SocialMediaImage from "../../assets/socialmedia.png";
import CEOImage from "../../assets/ceo.png";
import { isEmpty, isArray } from "lodash";
import LoginLogo from "../../assets/logo-black.png";
import { Stack } from "@mui/material";
import BronzeLvl from "../../assets/bronze.png";
import SilverLvl from "../../assets/silver.png";
import GoldLvl from "../../assets/gold.png";
import DiamondLvl from "../../assets/diamond.png";
import PlatinumLvl from "../../assets/platinum.png";

import ForceDirectedGraph from '../../components/ForceDirectedGraph';

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    background: "#F5F8FB",
    minHeight: "100vh",
    width: "max-content",
    minWidth: "100vw",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 100px 330px",
    },
  },
  subtitleWrapper: {
    textAlign: "center",
  },
  pointsWrapper: {
    padding: 3,
    margin: 4,
    borderRadius: 4,
    textAlign: "center",
  },
  tagWrapper: {
    padding: 3,
    backgroundColor: "#FF5733",
    color: "white",
    margin: 4,
    borderRadius: 4,
    textAlign: "center",
  },
  campaigntypewrapper: {
    padding: 1,

    // backgroundColor: "#E8E8E8",
    margin: 12,
    borderRadius: 4,
    display: "block",
    // display: "inline-flex",
  },
}));

const LandingPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [campaignList, setCampaignList] = useState([]);
  const [rewardList, setRewardList] = useState([]);
  const [marketPlace, setMarketPlace] = useState([]);
  const [sponsor, setSponsor] = useState([]);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const { isSuccess: campSuccess, response: campResponse } = useSelector(
    (state) => state.getCampaign
  );
  // console.log(campResponse);
  // const refferalId = localStorage.getItem("refferalId");
  // const userid = localStorage.getItem("userid");

  useEffect(() => {
    localStorage.setItem("userid", "");
    localStorage.setItem("profileurl", "");
    localStorage.setItem("token", "");
  }, [campaignList]);

  useEffect(() => {
    getVolunteerCamp();
    getRewardList();
    // getSponsors(); 
  }, []);

  useEffect(() => {
    getMarketPlace();
  }, [setMarketPlace]);
  
  React.useEffect(() => {
    
    dispatch(getVolunteerCount());
    dispatch(
      getCampaign(profileResponse.orgrole, profileResponse.organization)
    );
    getSponsors();
    
  }, [campaignList]);

  
  // console.log(profileResponse);

  // const currentUserData = async () => {
  //   try {
  //     const res = await axios.get(`cyaaguser/${userid}`).then((userData) => {
  //       console.log("====== resource data ======");
  //       console.log(userData.data);
  //       getVolunteerCamp(userData.data);
  //     });
  //   } catch (err) {
  //     throw err;
  //   }
  // };

  const getVolunteerCamp = async () => {
    try {
      const res = await axios
        // .get(`vol_dashboard/camp/${pRes?.orgrole}/${pRes?.organization}`)
        .get(`landing/vol_dashboard/camp`)
        .then((response) => {
          setCampaignList(response?.data);
          return response;
        });

      // console.log(res)

      // }
    } catch (err) {
      throw err;
    }
  };

  const getRewardList = async () => {
    try {
      const res = await axios.get(`/success/stories`).then((response) => {
        setRewardList(response?.data);
        return response;
      });
    } catch (err) {
      throw err;
    }
  };

  const getMarketPlace = async () => {
    try {
      const res = await axios
        .get(`/landing/marketplace/read`)
        .then((response) => {
          setMarketPlace(response?.data);
          return response;
        });
    } catch (err) {
      throw err;
    }
  };

  const getSponsors = async () => {
    try {
      const res = await axios
        .get(`/sponsor`)
        .then((response) => {
          if(response.data) {
          setSponsor(response?.data);
          }
          return response;
        });
    } catch (err) {
      throw err;
    }
  };

  const options = {
    items: 3,
    margin: 10,
    loop: false,
    nav: false,
    autoplaySpeed: 900,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const optionsmob = {
    items: 3,
    margin: 10,
    loop: true,
    nav: false,
    autoplaySpeed: 900,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const optionsFour = {
    items: 4,
    margin: 10,
    loop: true,
    nav: false,
    autoplaySpeed: 900,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  const optionsTwo = {
    items: 2,
    margin: 10,
    loop: false,
    nav: true,
    autoplaySpeed: 900,
    autoplay: false,
    dots: false,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
    touchDrag: true,
  };

  // useEffect(() => {
  //   dispatch(userProfile(localStorage.getItem("userid")));
  // }, []);
  // useEffect(() => {
  //   if (profileResponse?.organization) {
  //     getActivityList();
  //   }
  // }, [profileResponse?.organization]);

  // console.log(campaignList);

  // const campainContent = () => {
  //     return (<div>
  //         {campaignList?.filter((item, index) => item.active === "Active")
  //             .map((tile) => (
  //                 <div class="item">
  //                     <div class="campaigns-wrap">
  //                         <div class="campaign-img">
  //                             <img src={tile.name} className="img-fluid" />
  //                         </div>
  //                         <div class="campaigns-details">
  //                             <h5 class="campaign-title">{tile.name}</h5>
  //                             <div class="campaign-hashtags">
  //                                 <a class="hash-styles">#hashtag1</a> <a class="hash-styles">#hashtag1</a> <a class="hash-styles">#hashtag1</a> <a class="hash-styles">#hashtag1</a>
  //                                 <a class="hash-styles">#hashtag1</a> <a class="hash-styles">#hashtag1</a> <a class="hash-styles">#hashtag1</a>

  //                             </div>

  //                             <div class="row">
  //                                 <div class="col-6">
  //                                     <div class="cam-cols">
  //                                         <div class="earn-upto-div">
  //                                             <img src="assets/img/c-earn-upto.png" />
  //                                             <div>
  //                                                 <span>earn upto <span className="upto-points">5000</span> points</span>
  //                                             </div>
  //                                         </div>
  //                                     </div>
  //                                 </div>
  //                                 <div class="col-6">
  //                                     <div class="cam-cols">
  //                                         <div>
  //                                             <div class="">
  //                                                 <span class="campaign-time-title">Time Left</span>
  //                                             </div>
  //                                             <div class="progress-semi">
  //                                                 <div class="barOverflow">
  //                                                     <div class="bar"></div>
  //                                                 </div>
  //                                                 <span>34</span>
  //                                             </div>
  //                                         </div>
  //                                     </div>
  //                                 </div>
  //                             </div>
  //                             <div class="p-bar">
  //                                 <div class="row ">
  //                                     <div class="col-6">
  //                                         <div>
  //                                             <h6>31% filled up</h6>
  //                                         </div>
  //                                     </div>
  //                                     <div class="col-6">
  //                                         <div>
  //                                             <h6>25/80</h6>
  //                                         </div>
  //                                     </div>
  //                                 </div>
  //                                 <div class="progress br-30 campaign-progress">
  //                                     <div class="progress-bar bg-primary" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>

  //                                 </div>
  //                                 <span class="progress-txt">25 changemakers joined out of 80</span>
  //                             </div>
  //                             <div class="mr-15 text-center">
  //                                 <a class="campaign-signin">Join Now <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
  //                             </div>
  //                         </div>
  //                     </div>
  //                 </div>
  //             ))}
  //     </div>);

  // };
  const isDesktop = useMediaQuery("(min-width:600px)");


  return (
    <div class="landingPageWrapper">
      <div class="desktop-nav header navbar" style={{ marginBottom: "0px", padding:"7px 0px 0px 0px" }}>
        <div class="container">
          <div class="nav-logo-land-direct">
            {/* <a href="index.html" class=""> */}
              {" "}
              {/* <figure> */}
              <img src={cYAAGlogo} class="img-fluid" alt="logo" style={{maxHeight: "45px"}}/>
              
              {/* </figure> */}
            {/* </a> */}
            {/* <figcaption style={{marginLeft:"5px"}}>Powered by cYAAG</figcaption> */}
            {/*<figcaption >Powered by cYAAG</figcaption> */}
            
          </div>
          <ul class="navbar-nav" style={{ display: "inline-block" }}>
            <li class="nav-item" name="login" style={{ display: "inline-block" }}>
              <a
                onClick={() => {
                  navigate("/login");
                }}
              >
                Login
              </a>
            </li>
            <li
              class="nav-item"
              style={{ display: "inline-block", marginLeft: "20px" }}
            >
              <a
                class="cy-reg-btn"
                onClick={() => {
                  navigate("/register");
                }}
              >
                Sign Up
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <OwlCarousel className="owl-theme" items="1" autoplay nav dots loop>
          <div class="item slide1">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 banner-left-content">
                  <h2 class="pl-3 pr-3 ">
                  {/* Welcome to IEEE SDP  */}
                  Welcome to Sustainable Development Platform
                  <br />
                 
                    {/* <span class = "poweredbycyaag" style={{color:"black", fontSize:"14px"}}>Vision</span> */}
                  </h2>
                  {/* <h4 class = "p-3 poweredbycyaag" style={{color:"black", fontSize:"14px"}}>Powered by cYAAG</h4> */}
                  <h4 class="p-3 landtext">
                    A global platform for changemakers to make real social
                    impact{" "}
                  </h4>

                  <h6 class="p-3 landtext">
                    In agriculture, healthcare, education, employment, air,
                    water, energy, climate, animal welfare, waste management,
                    women empowerment, and more!{" "}
                  </h6>
                  <div class="pl-4 pr-4">
                    {/* <button type="button" class="view-more-btn btn mb-4 mr-2">Learn More <i class="fa fa-arrow-right"></i></button> */}
                    <Button
                      // onClick={() => navigate(`/moreaboutus`)}
                      onClick={() => navigate(`/moreaboutus`)}
                      style={{ color: "white" }}
                      class="view-more-btn btn mb-4 mr-2"
                      id="learnmore1"
                    >
                      Learn More{" "}
                      <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </Button>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-center">
                  <div class="slider-img d-sm-flex justify-content-center">
                    <img
                      alt="Steer Your Vision"
                      class="img-fluid"
                      height="800px"
                      src={LandingPage1Img}
                      title="Steer Your Vision"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item slide1">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 banner-left-content">
                  <h2 class="pl-3 pr-3">
                    Participate Now
                    <br />
                    {/* <span>Implementation</span> */}
                  </h2>
                  <h4 class="p-3 landtext">
                    In social campaigns in your neighborhood and across the
                    world{" "}
                  </h4>
                  <h6 class="p-3 landtext">
                    Offered by many corporations, social enterprises, and
                    educational institutions{" "}
                  </h6>
                  <div class=" pl-4 pr-4">
                    {/* <button type="button" class="view-more-btn btn btn-secondary mb-4 mr-2">Learn More <i class="fa fa-arrow-right"></i></button> */}
                    <Button
                      onClick={() => navigate(`/moreaboutus`)}
                      style={{ color: "white" }}
                      class="view-more-btn btn mb-4 mr-2"
                      id="learnmore2"
                    >
                      Learn More{" "}
                      <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </Button>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-center">
                  <div class="slider-img d-sm-flex justify-content-center">
                    <img
                      alt="ERP Implementation"
                      class="img-fluid"
                      height="600px"
                      src={LandingPage2Img}
                      title="ERP Implementation"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item slide1">
            <div class="container">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 banner-left-content">
                  <h2 class="pl-3 pr-3 ">
                    Earn Recognition and Rewards <br />
                    {/* <span>Implementation</span> */}
                  </h2>
                  <h4 class="p-3 landtext">Be known for your social impact</h4>
                  <h6 class="p-3 landtext">
                    Become eligible for course credits, certificates,
                    internships, job opportunities, field trips, free training,
                    mentorship, prizes, cash awards, and many more!
                  </h6>
                  <div class=" pl-4 pr-4">
                    {/* <button type="button" class="view-more-btn btn btn-secondary mb-4 mr-2">Learn More <i class="fa fa-arrow-right"></i></button> */}
                    <Button
                      onClick={() => navigate(`/moreaboutus`)}
                      style={{ color: "white" }}
                      class="view-more-btn btn mb-4 mr-2"
                      id="learnmore3"
                    >
                      Learn More{" "}
                      <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </Button>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-center">
                  <div class="slider-img d-sm-flex justify-content-center">
                    <img
                      alt="ERP Implementation"
                      class="img-fluid"
                      height="600px"
                      src={LandingPage3Img}
                      title="ERP Implementation"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>



        <div class="container">
          <div class="cyagg-sec-space campaigns-tabs">
            <div>
              <div class="row">
                <div class="col-7">
                  <div>
                    <h4 class="site-title">Campaigns</h4>
                  </div>
                </div>
                <div class="col-5">
                  <div class="rightAlign">
                    <a class="story-signin">
                      <Button
                        onClick={() => navigate(`/login`)}
                        // style={{ backgroundColor: "#2D7A74", color: "white", padding: "9px 10px" }}
                        // className={
                        // classes.button
                        // }
                        id="joinnowcampaign"
                        style={{ color: "white" }}
                      >
                        Join Now
                        <i class="fa fa-arrow-right"></i>
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="widget-content animated-underline-content">
              <ul
                class="nav nav-tabs  mb-3 campaign-tabz"
                id="animateLine"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="animated-underline-home-tab"
                    data-toggle="tab"
                    href="#animated-underline-home"
                    role="tab"
                    aria-controls="animated-underline-home"
                    aria-selected="true"
                  >
                    <i className="material-icons directions_run">&#xe566;</i>{" "}
                    <span>Active</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="animated-underline-profile-tab"
                    data-toggle="tab"
                    href="#animated-underline-profile"
                    role="tab"
                    aria-controls="animated-underline-profile"
                    aria-selected="false"
                  >
                    <i className="material-icons edit_calendar">&#xe742;</i>{" "}
                    <span>Next</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="animated-underline-contact-tab"
                    data-toggle="tab"
                    href="#animated-underline-contact"
                    role="tab"
                    aria-controls="animated-underline-contact"
                    aria-selected="false"
                  >
                    <i className="material-icons check_circle_outline">
                      &#xe92d;
                    </i>{" "}
                    <span>Done</span>
                  </a>
                </li>
              </ul>

              <div class="tab-content customOwlNav landingPage" id="animateLineContent-4">
                <div
                  class="tab-pane fade show active"
                  id="animated-underline-home"
                  role="tabpanel"
                  aria-labelledby="animated-underline-home-tab"
                >
                
                  {/* <div class="campaign-sl owl-carousel owl-theme"> */}
                  {/* {campainContent} */}
                  {/* {JSON.stringify(campaignList)} */}
                  {/* {JSON.stringify(campaignList)} */}
                  <OwlCarousel className="owl-theme" {...optionsTwo}>
                    {campaignList
                      ?.filter((item, index) => item.active === "Active")
                      .map((tile) => (
                        <div class="item">
                          <div class="campaigns-wrap">
                            <div class="campaign-img">
                              {/* <img src={tile.logo} className="img-fluid" /> */}
                              <div class="video-wrapper">
                                <div
                                  class="video-container"
                                  id="video-container"
                                >
                                  <video
                                    controls
                                    id="video"
                                    // preload="metadata"
                                    preload="auto"
                                    // poster="//cdn.jsdelivr.net/npm/big-buck-bunny-1080p@0.0.6/poster.jpg"
                                    poster={tile?.logo}
                                    autoplay
                                  >
                                    <source
                                      // src="//cdn.jsdelivr.net/npm/big-buck-bunny-1080p@0.0.6/video.mp4"
                                      src={tile?.video}
                                      type="video/mp4"
                                    />
                                  </video>
                                </div>
                              </div>
                            </div>
                            
                            <div class="campaigns-details">
                              <h5 title={tile?.name} class="campaign-title">{tile?.name}</h5>
                              <div class="campaign-hashtags">
                                {
                                  isArray(tile?.tag) ?
                                    tile.tag.map((tag) => (
                                      <a class="hash-styles">{tag.tag}</a>
                                      )
                                    )
                                    :
                                  
                                    <a class="hash-styles">{tile?.tag}</a>
                                      
                                }
                              </div>
                              {/* <h5 class="campaign-goal">
                                Need {tile.targetvol} changemakers
                              </h5> */}
                              <div className={classes.campaigntypewrapper}  style={{margin:"0px 0px"}}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  gap={0.5}
                                >
                                  {/* <h5 class="campaign-goal">
                                    Need {tile.targetvol} changemakers
                                  </h5> */}
                                  <h5 class="campaign-goal-landing">
                                    <a class="hash-styles-goal">
                                      <span style={{ fontSize: "16px", fontWeight: 700 }}>Goal</span>: <span class="text-6" style={{ color: "black" }}>{tile.goalquantity}{" "} {tile.unitofgoal}</span>
                                    </a>
                                  </h5>
                                </Stack>
                              </div>


                              <div class="p-bar-new">
                                        <div class="row ">
                                          <div class="col-6">
                                            <div>
                                              <h6>
                                                <span style={{ color: "#AAAAAA" }}>Pledged: </span>{" "} <span style={{ backgroundColor: "#FFDB58" }}>
                                                  {Math.round(
                                                    (tile.committedcount)
                                                  )}
                                                </span>
                                              </h6>
                                            </div>
                                          </div>
                                          <div class="col-6">
                                            <div
                                              style={{
                                                textAlign:
                                                  "right",
                                              }}
                                            >
                                              <h6>
                                                <span style={{ color: "#AAAAAA" }}>Achieved: </span>
                                                {" "}
                                                <span style={{ backgroundColor: "#A9D18E" }}> {tile.achievedcount}</span>
                                                {" "}
                                              </h6>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="progress br-30 campaign-progress" style={{ position: "relative" }}>
                                          <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{
                                              width: `${(tile.committedcount / tile.goalquantity) * 100}%`,
                                              backgroundColor: "#FFDB58",
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              zIndex: 1,
                                              height: "100%"
                                            }}
                                            aria-valuenow={Math.round((tile.committedcount / tile.goalquantity) * 100)}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                          ></div>

                                          <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{
                                              width: `${(tile.achievedcount / tile.goalquantity) * 100}%`,
                                              backgroundColor: "#A9D18E",
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              zIndex: 2,
                                              height: "100%"
                                            }}
                                            aria-valuenow={Math.round((tile.achievedcount / tile.goalquantity) * 100)}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                          ></div>
                                        </div>
                                      </div>

                                      <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                        <div style={{ flex: 1 }}>
                                          <h6>
                                            <span style={{ color: "#AAAAAA" }}>Signed Up:</span>{" "}
                                            <span style={{ backgroundColor: "#DAE8FD" }}>{tile.submittedcount}</span>
                                          </h6>
                                        </div>
                                        <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                          <Chip
                                            key={tile.campaigntype}
                                            fontSize="small"
                                            size="small"
                                            label={tile.campaigntype}
                                          />
                                          
                                        </div>
                                      </div>






                              <div class="row" style={{marginTop:"10px"}}>
                                <div class="col-6">
                                  <div class="cam-cols">
                                    <div>
                                      <div style={{ float: "left" }}>
                                        <div
                                          class="text-center"
                                          style={{ marginBottom: "10px" }}
                                        >
                                          <span
                                            class="campaign-time-title"
                                            style={{ marginLeft: "0px" }}
                                          >
                                            Earn upto
                                          </span>
                                        </div>
                                        <span
                                          className="hoursWrapper leftAlignWrapper"
                                          style={{ fontSize: 14 }}
                                        >
                                          <span className="upto-points-new">
                                            {tile.maxpointstobeearned}{" "}
                                          </span>
                                          points
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-6">
                                  <div class="cam-cols">
                                    <div>
                                      <div style={{ float: "right" }}>
                                        <div
                                          class="text-center"
                                          style={{ marginBottom: "10px" }}
                                        >
                                          <span
                                            class="campaign-time-title"
                                            style={{ marginLeft: "0px" }}
                                          >
                                            Time Left
                                          </span>
                                        </div>
                                        <span
                                          className="hoursWrapper"
                                          style={{ fontSize: 14 }}
                                        >
                                          <span className="hoursCount">
                                            {tile.dayrem} :
                                          </span>
                                          Days
                                        </span>
                                        <span
                                          className="hoursWrapper"
                                          style={{ fontSize: 14 }}
                                        >
                                          <span className="hoursCount">
                                            {tile.hourrem} :{" "}
                                          </span>
                                          Hrs
                                        </span>
                                        <span
                                          className="hoursWrapper"
                                          style={{ fontSize: 14 }}
                                        >
                                          <span className="hoursCount">
                                            {tile.minrem}
                                          </span>
                                          Mins
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                  </OwlCarousel>
                </div>

                {/* Second tab */}
                <div
                  class="tab-pane fade"
                  id="animated-underline-profile"
                  role="tabpanel"
                  aria-labelledby="animated-underline-profile-tab"
                >
                  <OwlCarousel className="owl-theme" {...optionsTwo}>
                    {campaignList
                      ?.filter((item, index) => item.active === "Upcoming")
                      .map((tile) => (
                        <div class="item">
                          <div class="campaigns-wrap">
                            <div class="campaign-img">
                              {/* <img src={tile.logo} className="img-fluid" /> */}
                              <div class="video-wrapper">
                                <div
                                  class="video-container"
                                  id="video-container"
                                >
                                  <video
                                    controls
                                    id="video"
                                    // preload="metadata"
                                    preload="auto"
                                    // poster="//cdn.jsdelivr.net/npm/big-buck-bunny-1080p@0.0.6/poster.jpg"
                                    poster={tile?.logo}
                                    autoplay
                                  >
                                    <source
                                      // src="//cdn.jsdelivr.net/npm/big-buck-bunny-1080p@0.0.6/video.mp4"
                                      src={tile?.video}
                                      type="video/mp4"
                                    />
                                  </video>
                                </div>
                              </div>
                            </div>
                            <div class="campaigns-details">
                              {/* <h5 class="campaign-title"></h5> */}
                              <h5 title={tile?.name} class="campaign-title-new">
                                                    {tile?.name}
                                                  </h5>
                              <div class="campaign-hashtags">
                                {tile.tag.map((tag) => (
                                  <a class="hash-styles">{tag.tag}</a>
                                ))}
                              </div>
                              {/* <h5 class="campaign-goal">
                                Need {tile.targetvol} changemakers
                              </h5> */}
                              <div className={classes.campaigntypewrapper}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  gap={0.5}
                                >
                                  {/* <h5 class="campaign-goal">
                                    Need {tile.targetvol} changemakers
                                  </h5> */}
                                  <h5 class="campaign-goal-landing">
                                    <a class="hash-styles-goal">
                                    Need {tile.targetvol}{" "}
                                    changemakers
                                    </a>
                                  </h5>
                                  <Chip
                                    key={tile.campaigntype}
                                    fontSize="small"
                                    size="small"
                                    label={tile.campaigntype}
                                  />
                                  {/* <Chip key={tile.views} size="small" label={tile.views} className={classes.Chip} /> Views */}
                                  {/* <Typography className={classes.tags} > */}{" "}
                                  {/* <Stack direction="row" alignItems="center" justifyContent="center" gap={0.5}> */}
                                  {/* <Stack
                                                      direction="row"
                                                      alignItems="center"
                                                      justifyContent="space-between"
                                                      gap={0.5}
                                                    >
                                                      <Typography
                                                        fontSize="1.2rem"
                                                        variant="body1"
                                                      >
                                                        {parseInt(
                                                          tile.views
                                                        ).toLocaleString()}{" "}
                                                      </Typography>
                                                      <VisibilitySharpIcon fontSize="0px" />
                                                    </Stack> */}
                                </Stack>
                              </div>
                              <div class="row">
                                {/* <div class="col-6">
                                  <div class="cam-cols">
                                    <div class="earn-upto-div">
                                      <img src={EarnImage} />
                                      <div class="rewardPointWrapper">
                                        <span
                                          class="earn"
                                          style={{ display: "inline-block" }}
                                        >
                                          earn upto points
                                        </span>
                                        <span className="upto-points">
                                          {tile.maxpointstobeearned}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}

                                <div class="col-6">
                                  <div class="cam-cols">
                                    <div>
                                      <div style={{ float: "left" }}>
                                        <div
                                          class="text-center"
                                          style={{ marginBottom: "10px" }}
                                        >
                                          <span
                                            class="campaign-time-title"
                                            style={{ marginLeft: "0px" }}
                                          >
                                            Earn upto
                                          </span>
                                        </div>
                                        <span
                                          className="hoursWrapper leftAlignWrapper"
                                          style={{ fontSize: 14 }}
                                        >
                                          <span className="upto-points-new">
                                            {tile.maxpointstobeearned}{" "}
                                          </span>
                                          points
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* <div class="col-6">
                                  <div class="cam-cols">
                                    <div>
                                      <div style={{ float: "right" }}>
                                        <span
                                          className="hoursWrapper"
                                          style={{ fontSize: 14 }}
                                        >
                                          Days
                                          <span className="hoursCount">
                                            {tile.dayrem} :{" "}
                                          </span>
                                        </span>
                                        <span
                                          className="hoursWrapper"
                                          style={{ fontSize: 14 }}
                                        >
                                          Hours
                                          <span className="hoursCount">
                                            {tile.hourrem} :{" "}
                                          </span>
                                        </span>
                                        <span
                                          className="hoursWrapper"
                                          style={{ fontSize: 14 }}
                                        >
                                          Mins
                                          <span className="hoursCount">
                                            {tile.minrem}
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}

                                <div class="col-6">
                                  <div class="cam-cols">
                                    <div>
                                      <div style={{ float: "right" }}>
                                        <div
                                          class="text-center"
                                          style={{ marginBottom: "10px" }}
                                        >
                                          <span
                                            class="campaign-time-title"
                                            style={{ marginLeft: "0px" }}
                                          >
                                            Time Left
                                          </span>
                                        </div>
                                        <span
                                          className="hoursWrapper"
                                          style={{ fontSize: 14 }}
                                        >
                                          <span className="hoursCount">
                                            {/* {tile.dayrem} :{" "} */}
                                            {tile.dayrem} :
                                          </span>
                                          Days
                                        </span>
                                        <span
                                          className="hoursWrapper"
                                          style={{ fontSize: 14 }}
                                        >
                                          <span className="hoursCount">
                                            {tile.hourrem} :{" "}
                                          </span>
                                          Hrs
                                        </span>
                                        <span
                                          className="hoursWrapper"
                                          style={{ fontSize: 14 }}
                                        >
                                          <span className="hoursCount">
                                            {tile.minrem}
                                          </span>
                                          Mins
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="p-bar"
                                style={{ padding: "20px 0px 0px 0px" }}
                              >
                                <div class="row ">
                                  <div class="col-6">
                                    <div>
                                      <h6>
                                        {Math.round(
                                          (tile.submittedcount /
                                            tile.targetvol) *
                                            100
                                        )}
                                        % filled up
                                      </h6>
                                    </div>
                                  </div>
                                  <div class="col-6">
                                    <div class="rightAlign">
                                      <h6>
                                        {tile.submittedcount}/{tile.targetvol}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                                <div class="progress br-30 campaign-progress">
                                  <div
                                    class="progress-bar bg-primary"
                                    role="progressbar"
                                    style={{
                                      width:
                                        Math.round(
                                          (tile.submittedcount /
                                            tile.targetvol) *
                                            100
                                        ) + "%",
                                    }}
                                    aria-valuenow={Math.round(
                                      (tile.submittedcount / tile.targetvol) *
                                        100
                                    )}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <span class="progress-txt">
                                  {tile.submittedcount} changemakers joined out
                                  of {tile.targetvol}
                                </span>
                                
                              </div>
                              <div class="mr-15 text-center">
                                {/* <a class="campaign-signin">Join Now <i class="fa fa-arrow-right" aria-hidden="true"></i></a> */}
                                {/* <a class="story-signin">
                                  <Button
                                    onClick={() => navigate(`/login`)}
                                  
                                    style={{ color: "white" }}
                                    id="joinnowdontknow"
                                  >
                                    Join Now
                                    <i class="fa fa-arrow-right"></i>
                                  </Button>
                                </a> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    {/* </div> */}
                  </OwlCarousel>
                </div>
                <div
                  class="tab-pane fade"
                  id="animated-underline-contact"
                  role="tabpanel"
                  aria-labelledby="animated-underline-contact-tab"
                >
                  <OwlCarousel className="owl-theme" {...optionsTwo}>
                    {campaignList
                      ?.filter((item, index) => item.active === "Completed")
                      .map((tile, index1) => (
                        index1 < 2 &&
                        <div class="item">
                          <div class="campaigns-wrap">
                            <div class="campaign-img">
                              {/* <img src={tile.logo} className="img-fluid" /> */}
                              <div class="video-wrapper">
                                <div
                                  class="video-container"
                                  id="video-container"
                                >
                                  <video
                                    controls
                                    id="video"
                                    // preload="metadata"
                                    preload="auto"
                                    // poster="//cdn.jsdelivr.net/npm/big-buck-bunny-1080p@0.0.6/poster.jpg"
                                    poster={tile?.logo}
                                    autoplay
                                  >
                                    <source
                                      // src="//cdn.jsdelivr.net/npm/big-buck-bunny-1080p@0.0.6/video.mp4"
                                      src={tile?.video}
                                      type="video/mp4"
                                    />
                                  </video>
                                </div>
                              </div>
                            </div>
                            
                            <div class="campaigns-details">
                              <h5 title={tile?.name} class="campaign-title">{tile?.name}</h5>
                              <div class="campaign-hashtags">
                                {
                                  isArray(tile?.tag) ?
                                    tile.tag.map((tag) => (
                                      <a class="hash-styles">{tag.tag}</a>
                                      )
                                    )
                                    :
                                  
                                    <a class="hash-styles">{tile?.tag}</a>
                                      
                                }
                              </div>
                              {/* <h5 class="campaign-goal">
                                Need {tile.targetvol} changemakers
                              </h5> */}
                              <div className={classes.campaigntypewrapper}  style={{margin:"0px 0px"}}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  gap={0.5}
                                >
                                  {/* <h5 class="campaign-goal">
                                    Need {tile.targetvol} changemakers
                                  </h5> */}
                                  <h5 class="campaign-goal-landing">
                                    <a class="hash-styles-goal">
                                      <span style={{ fontSize: "16px", fontWeight: 700 }}>Goal</span>: <span class="text-6" style={{ color: "black" }}>{tile.goalquantity}{" "} {tile.unitofgoal}</span>
                                    </a>
                                  </h5>
                                </Stack>
                              </div>


                              <div class="p-bar-new">
                                        <div class="row ">
                                          <div class="col-6">
                                            <div>
                                              <h6>
                                                <span style={{ color: "#AAAAAA" }}>Pledged: </span>{" "} <span style={{ backgroundColor: "#FFDB58" }}>
                                                  {Math.round(
                                                    (tile.committedcount)
                                                  )}
                                                </span>
                                              </h6>
                                            </div>
                                          </div>
                                          <div class="col-6">
                                            <div
                                              style={{
                                                textAlign:
                                                  "right",
                                              }}
                                            >
                                              <h6>
                                                <span style={{ color: "#AAAAAA" }}>Achieved: </span>
                                                {" "}
                                                <span style={{ backgroundColor: "#A9D18E" }}> {tile.achievedcount}</span>
                                                {" "}
                                              </h6>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="progress br-30 campaign-progress" style={{ position: "relative" }}>
                                          <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{
                                              width: `${(tile.committedcount / tile.goalquantity) * 100}%`,
                                              backgroundColor: "#FFDB58",
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              zIndex: 1,
                                              height: "100%"
                                            }}
                                            aria-valuenow={Math.round((tile.committedcount / tile.goalquantity) * 100)}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                          ></div>

                                          <div
                                            className="progress-bar"
                                            role="progressbar"
                                            style={{
                                              width: `${(tile.achievedcount / tile.goalquantity) * 100}%`,
                                              backgroundColor: "#A9D18E",
                                              position: "absolute",
                                              top: 0,
                                              left: 0,
                                              zIndex: 2,
                                              height: "100%"
                                            }}
                                            aria-valuenow={Math.round((tile.achievedcount / tile.goalquantity) * 100)}
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                          ></div>
                                        </div>
                                      </div>

                                      <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                        <div style={{ flex: 1 }}>
                                          <h6>
                                            <span style={{ color: "#AAAAAA" }}>Signed Up:</span>{" "}
                                            <span style={{ backgroundColor: "#DAE8FD" }}>{tile.submittedcount}</span>
                                          </h6>
                                        </div>
                                        <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                          <Chip
                                            key={tile.campaigntype}
                                            fontSize="small"
                                            size="small"
                                            label={tile.campaigntype}
                                          />
                                          
                                        </div>
                                      </div>






                              <div class="row" style={{marginTop:"10px"}}>
                                <div class="col-6">
                                  <div class="cam-cols">
                                    <div>
                                      <div style={{ float: "left" }}>
                                        <div
                                          class="text-center"
                                          style={{ marginBottom: "10px" }}
                                        >
                                          <span
                                            class="campaign-time-title"
                                            style={{ marginLeft: "0px" }}
                                          >
                                            Earn upto
                                          </span>
                                        </div>
                                        <span
                                          className="hoursWrapper leftAlignWrapper"
                                          style={{ fontSize: 14 }}
                                        >
                                          <span className="upto-points-new">
                                            {tile.maxpointstobeearned}{" "}
                                          </span>
                                          points
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-6">
                                  <div class="cam-cols">
                                    <div>
                                      <div style={{ float: "right" }}>
                                        <div
                                          class="text-center"
                                          style={{ marginBottom: "10px" }}
                                        >
                                          <span
                                            class="campaign-time-title"
                                            style={{ marginLeft: "0px" }}
                                          >
                                            Time Left
                                          </span>
                                        </div>
                                        <span
                                          className="hoursWrapper"
                                          style={{ fontSize: 14 }}
                                        >
                                          <span className="hoursCount">
                                            {tile.dayrem} :
                                          </span>
                                          Days
                                        </span>
                                        <span
                                          className="hoursWrapper"
                                          style={{ fontSize: 14 }}
                                        >
                                          <span className="hoursCount">
                                            {tile.hourrem} :{" "}
                                          </span>
                                          Hrs
                                        </span>
                                        <span
                                          className="hoursWrapper"
                                          style={{ fontSize: 14 }}
                                        >
                                          <span className="hoursCount">
                                            {tile.minrem}
                                          </span>
                                          Mins
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    {/* </div> */}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Second container  */}

        


        <div class="container">
          <div class="cyagg-sec-space">
            <div>
              <div class="row">
                <div class="col-7">
                  <div>
                    <h4 class="site-title">Our Sponsors</h4>
                  </div>
                </div>
                <div class="col-5">
                  <div class="rightAlign">
                    {/* <a class="story-signin">Join Now <i class="fa fa-arrow-right"></i></a> */}
                    <a class="story-signin">
                      <Button
                        onClick={() => navigate(`/login`)}
                        // style={{ backgroundColor: "#2D7A74", color: "white", padding: "9px 10px" }}
                        // className={
                        // classes.button
                        // }
                        style={{ color: "white" }}
                        id="joinnowsponsor"
                      >
                        Join Now
                        <i class="fa fa-arrow-right"></i>
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="home-demo">
              <OwlCarousel className="owl-theme" {...optionsFour}>
                {sponsor?.map((item, index) => (
                  <div class="item">
                    <div class="succ-story-wrap">
                      <div>
                        {/* <img src={item.image} /> */}
                        {item?.sponsorlevel === "bronze" ? (
                          <img src={BronzeLvl} class="img-fluid"></img>
                        ) : item?.sponsorlevel === "silver" ? (
                          <img src={SilverLvl} class="img-fluid"></img>
                        ) : item?.sponsorlevel === "gold" ? (
                          <img src={GoldLvl} class="img-fluid"></img>
                        ) : item?.sponsorlevel === "diamond" ? (
                          <img src={DiamondLvl} class="img-fluid"></img>
                        ) : (
                          <img src={PlatinumLvl} class="img-fluid"></img>
                        )}
                      </div>
                      <div class="text-center succ-story-details">
                        <h6>{item?.sponsor}</h6>
                        <p>{String(item?.sponsorlevel)} </p>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>









        <div class="container">
          <div class="cyagg-sec-space">
            <div>
              <div class="row">
                <div class="col-7">
                  <div>
                    <h4 class="site-title">Win Rewards!</h4>
                  </div>
                </div>
                <div class="col-5">
                  <div class="rightAlign">
                    {/* <a class="story-signin">Join Now <i class="fa fa-arrow-right"></i></a> */}
                    <a class="story-signin">
                      <Button
                        onClick={() => navigate(`/login`)}
                        // style={{ backgroundColor: "#2D7A74", color: "white", padding: "9px 10px" }}
                        style={{ color: "white" }}
                        // className={
                        // classes.button
                        // }
                        id="joinnowrewards"
                      >
                        Join Now
                        <i class="fa fa-arrow-right"></i>
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div>
                  <img class="hide-on-mobile" src={RewardMainImage} />
                </div>
              </div>
              <div class="col-md-8">
                <div class="">
                  <OwlCarousel className="owl-theme" {...options}>
                    {marketPlace?.map(
                      (item, index) =>
                        index < 5 && (
                          <div class="item">
                            <div class="reward-wrap">
                              <div class="landingPageRewardWrapper">
                                {/* <img
                                  class="reward-image"
                                  src={RewardMainImage}
                                  style={{ width: "100%" }}
                                /> */}
                                 {item?.rewardtype ===
                                        "30 mins with a CEO" ? (
                                          <img
                                            class="reward-image"
                                            src={CEOImage}
                                            style={{ width: "100%" }}
                                          />
                                        ) : item?.rewardtype ===
                                          "Certificate of Participation" ? (
                                          <img
                                            class="reward-image"
                                            src={CertificateImage}
                                            style={{ width: "100%" }}
                                          />
                                        ) : item?.rewardtype ===
                                          "Video Interview with You" ? (
                                          <img
                                            class="reward-image"
                                            src={VideoInterviewImage}
                                            style={{ width: "100%" }}
                                          />
                                        ) : item?.rewardtype ===
                                          "Discount Coupons" ? (
                                          <img
                                            class="reward-image"
                                            src={DiscountCouponImage}
                                            style={{ width: "100%" }}
                                          />
                                        ): item?.rewardtype ===
                                          "Gift Cards" ? (
                                          <img
                                            class="reward-image"
                                            src={GiftCardImage}
                                            style={{ width: "100%" }}
                                          />
                                      ):item?.rewardtype ===
                                        "Internship Opportunity" ? (
                                        <img
                                          class="reward-image"
                                          src={InternshipImage}
                                          style={{ width: "100%" }}
                                        />
                                      ):item?.rewardtype ===
                                      "Social Media Recognition" ? (
                                      <img
                                        class="reward-image"
                                        src={SocialMediaImage}
                                        style={{ width: "100%" }}
                                      />
                                    ):(
                                          <img
                                            class="reward-image"
                                            src={RewardMainImage}
                                            style={{ width: "100%" }}
                                          />
                                        )}
                              </div>

                              <div class="reward-details">
                                <div>
                                  <div>
                                    <div id="toggleAccordion">
                                      <div class="card mb-1">
                                        <div
                                          class="card-header"
                                          id="headingOne4"
                                        >
                                          <h5 class="mb-0 mt-0 reward-desc-title">
                                            <span
                                              role="menu"
                                              class=""
                                              data-toggle="collapse"
                                              data-target={"#" + index}
                                              aria-expanded="true"
                                              aria-controls="iconChangeAccordionOne"
                                            >
                                              {item?.rewardtype}
                                              <i class="flaticon-down-arrow float-right"></i>
                                            </span>
                                          </h5>
                                        </div>

                                        <div
                                          id={index}
                                          class="collapse"
                                          aria-labelledby="headingOne4"
                                          data-parent="#toggleAccordion"
                                        >
                                          <div class="card-body">
                                            <p class="mb-3">
                                              <div
                                                className={
                                                  classes.subtitleWrapper
                                                }
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 12,
                                                    color: "blue",
                                                  }}
                                                >
                                                  {" "}
                                                  {item?.subtitle}{" "}
                                                </span>
                                              </div>
                                            </p>
                                            <p class="mb-3">
                                              <div
                                                className={
                                                  classes.subtitleWrapper
                                                }
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 12,
                                                    color: "green",
                                                  }}
                                                >
                                                  {" "}
                                                  {item?.title}{" "}
                                                </span>
                                              </div>
                                            </p>
                                            <p class="mb-3">
                                              <div
                                                className={
                                                  classes.pointsWrapper
                                                }
                                              >
                                                <span style={{ fontSize: 12 }}>
                                                  {" "}
                                                  You need{" "}
                                                </span>
                                                <Chip
                                                  key={item?.points}
                                                  size="small"
                                                  label={item?.points}
                                                  style={{
                                                    backgroundColor: "white",
                                                    color: "#DAA520",
                                                    fontSize: 18,
                                                    fontWeight: "bold",
                                                  }}
                                                />{" "}
                                                <span style={{ fontSize: 12 }}>
                                                  pts to redeem
                                                </span>
                                              </div>
                                            </p>
                                            {/* <p class="mb-3">
                                              <div
                                                className={
                                                  classes.subtitleWrapper
                                                }
                                              >
                                                <span style={{ fontSize: 12 }}>
                                                  {" "}
                                                  Sponsored by{" "}
                                                </span>
                                              </div>
                                              {isArray(item?.sponsor) ? (
                                                item?.sponsor?.map(
                                                  (item, index) => (
                                                    <div
                                                      className={
                                                        classes.tagWrapper
                                                      }
                                                    >
                                                      <Chip
                                                        key={item?.sponsor}
                                                        size="small"
                                                        label={item?.sponsor}
                                                        style={{
                                                          textAlign: "center",
                                                          backgroundColor:
                                                            "white",
                                                          color: "#DAA520",
                                                          fontSize: 12,
                                                          fontWeight: "bold",
                                                        }}
                                                      />
                                                    </div>
                                                    // <div className={classes.tagWrapper}>
                                                    //     {reward.sponsor}

                                                    // </div>
                                                  )
                                                )
                                              ) : (
                                                <div
                                                  className={classes.tagWrapper}
                                                >
                                                  {item.sponsor}
                                                </div>
                                              )}
                                            </p> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="redeem-pts">
                                    <div class="row">
                                      <div class="col-6">
                                        <div class="re-po-left">
                                          <img src="https://png.pngtree.com/png-clipart/20220213/original/pngtree-coins-stack-icon-vector-png-image_7267762.png" />
                                          <div class="pts">
                                            <h5>{item?.points}</h5>
                                            <span>Points</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div
                                          class="re-po-right"
                                          onClick={() => navigate(`/login`)}
                                        >
                                          <a>Redeem</a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="text-center">
                                    <h6 class="sponsor-title">Sponsors</h6>
                                    <div class="row sponserWrapper">
                                    {/* <div class=""> */}
                                    <div class="">
                                      {isArray(item?.sponsor) &&
                                        item?.sponsor?.map((reward, index) => (
                                         
                                            isDesktop ? (
                                              <img
                                                style={{
                                                  width: "auto",
                                                  height: "50px",
                                                }}
                                                src={reward?.sponsorlogo}
                                              />
                                            ) : (
                                              <img
                                                style={{
                                                  width: "auto",
                                                  height: "50px",
                                                }}
                                                src={reward?.sponsorlogo}
                                              />
                                            )
                                         
                                        ))}
                                        </div>

                                    </div>
                                    {/* </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Third container */}

        <div class="container">
          <div class="cyagg-sec-space">
            <div>
              <div class="row">
                <div class="col-7">
                  <div>
                    <h4 class="site-title">Success Stories</h4>
                  </div>
                </div>
                <div class="col-5">
                  <div class="rightAlign">
                    {/* <a class="story-signin">Join Now <i class="fa fa-arrow-right"></i></a> */}
                    <a class="story-signin">
                      <Button
                        onClick={() => navigate(`/login`)}
                        // style={{ backgroundColor: "#2D7A74", color: "white", padding: "9px 10px" }}
                        // className={
                        // classes.button
                        // }
                        style={{ color: "white" }}
                        id="joinnowsuccess"
                      >
                        Join Now
                        <i class="fa fa-arrow-right"></i>
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="home-demo">
              <OwlCarousel className="owl-theme" {...optionsFour}>
                {rewardList?.map((item, index) => (
                  <div class="item">
                    <div class="succ-story-wrap">
                      <div>
                        <img src={item.image} />
                      </div>
                      <div class="text-center succ-story-details">
                        <h6>{item.name}</h6>
                        <p>{item.msg} </p>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>

        {/* Final Container  */}

        {/* <div class="container">
          <div class="cyagg-sec-space">
            <div>
              <div class="row">
                <div class="col-md-7">
                  <div>
                    <h4 class="site-title">Your Way Ahead</h4>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8 add-full-width">
                <div>
                  <OwlCarousel
                    className="owl-theme"
                    items="1"
                    autoplay
                    nav
                    dots
                    loop
                  >
                    <div class="item">
                      <div class="streak-wrap">
                        <div>
                          <div class="row">
                            <div class="col-md-4">
                              <div>
                                <img src={StreakLeft} class="img-fluid" />
                              </div>
                            </div>
                            <div class="col-md-8">
                              <div>
                                <div class="streak-head">
                                  <div class="row">
                                    <div class="col-5">
                                      <div>
                                        <div>
                                          <span>Total points</span>
                                        </div>
                                        <div class="streak-point-sec">
                                          <img
                                            src="assests/img/streak-points.png"
                                            class="img-fluid"
                                          />
                                          <h4>1234</h4>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-7">
                                      <div>
                                        <a class="more-p-btn">
                                          How to Earn More Points?{" "}
                                          <img src="assests/img/qstnmark.png" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="streak-body">
                                  <div class="row">
                                    <div class="col-md-9">
                                      <div>
                                        <section class="signup-step-container">
                                          <div class="container">
                                            <div class="row d-flex justify-content-center">
                                              <div class="col-md-12">
                                                <div class="wizard">
                                                  <div class="wizard-inner">
                                                    <div class="connecting-line"></div>
                                                    <ul
                                                      class="nav nav-tabs"
                                                      role="tablist"
                                                    >
                                                      <li
                                                        role="presentation"
                                                        class="active"
                                                      >
                                                        <a
                                                          href="#step1"
                                                          data-toggle="tab"
                                                          aria-controls="step1"
                                                          role="tab"
                                                          aria-expanded="true"
                                                        >
                                                          <span class="round-tab">
                                                            1{" "}
                                                          </span>{" "}
                                                          <i>Step 1</i>
                                                        </a>
                                                      </li>
                                                      <li
                                                        role="presentation"
                                                        class="disabled"
                                                      >
                                                        <a
                                                          href="#step2"
                                                          data-toggle="tab"
                                                          aria-controls="step2"
                                                          role="tab"
                                                          aria-expanded="false"
                                                        >
                                                          <span class="round-tab">
                                                            2
                                                          </span>{" "}
                                                          <i>Step 2</i>
                                                        </a>
                                                      </li>
                                                      <li
                                                        role="presentation"
                                                        class="disabled"
                                                      >
                                                        <a
                                                          href="#step3"
                                                          data-toggle="tab"
                                                          aria-controls="step3"
                                                          role="tab"
                                                        >
                                                          <span class="round-tab">
                                                            3
                                                          </span>{" "}
                                                          <i>Step 3</i>
                                                        </a>
                                                      </li>
                                                      <li
                                                        role="presentation"
                                                        class="disabled"
                                                      >
                                                        <a
                                                          href="#step4"
                                                          data-toggle="tab"
                                                          aria-controls="step4"
                                                          role="tab"
                                                        >
                                                          <span class="round-tab">
                                                            4
                                                          </span>{" "}
                                                          <i>Step 4</i>
                                                        </a>
                                                      </li>
                                                    </ul>
                                                  </div>

                                                  <form
                                                    role="form"
                                                    action="index.html"
                                                    class="login-box"
                                                  >
                                                    <div
                                                      class="tab-content"
                                                      id="main_form"
                                                    >
                                                      <div
                                                        class="tab-pane active"
                                                        role="tabpanel"
                                                        id="step1"
                                                      >
                                                        <div>
                                                          <span class="streak-point">
                                                            This is spet one
                                                            nothing else
                                                            here..just go 1
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div
                                                        class="tab-pane"
                                                        role="tabpanel"
                                                        id="step2"
                                                      >
                                                        <div>
                                                          <span class="streak-point">
                                                            This is spet one
                                                            nothing else
                                                            here..just go 2
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div
                                                        class="tab-pane"
                                                        role="tabpanel"
                                                        id="step3"
                                                      >
                                                        <div>
                                                          <span class="streak-point">
                                                            This is spet one
                                                            nothing else
                                                            here..just go 3
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div
                                                        class="tab-pane"
                                                        role="tabpanel"
                                                        id="step4"
                                                      >
                                                        <div>
                                                          <span class="streak-point">
                                                            This is spet one
                                                            nothing else
                                                            here..just go 4
                                                          </span>
                                                        </div>
                                                      </div>
                                                      <div class="clearfix"></div>
                                                    </div>
                                                  </form>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </section>
                                      </div>
                                    </div>
                                    <div class="col-md-3 padd-0">
                                      <div class="text-center">
                                        <a class="streak-view-more">
                                          View More{" "}
                                          <i
                                            class="fa fa-chevron-right"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
              <div class="col-md-4 hide-on-spec">
                <div>
                  <img src={StreakRight} />
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div class="container">
          <div class="row"></div>
        </div>

        <div class="container hide-on-mobile">
          {/* <div class="container"> */}
          <div class="cyagg-sec-space">
            <div>
              <div class="row">
                <div class="col-md-8">
                  <h4 class="site-title">Create Triple Impact</h4>
                  <div class="impact-sec">
                    {/* <h4 class="site-title"><i><b>Social Impact</b></i></h4> */}
                    <h4 class="site-title">Social Impact</h4>
                    <p style={{ textAlign: "justify" }}>
                      Through cYAAG you can continually track how much
                      difference you made to people’s lives by participating in
                      campaigns – such as providing education to underprivileged
                      children, improving access to healthcare, supplying
                      drinking water, providing care for senior citizens,
                      improving communications in rural areas, empowering women,
                      providing for animal welfare, making communities cleaner
                      and greener, and addressing homelessness and poverty. You
                      can see the impact you are having as an individual and
                      through the extended community you have created on cYAAG.
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div>
                    <img src={Impact1} />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="col-md-4">
                  <div>
                    <img src={Impact2} />
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="impact-sec">
                    <h4 class="site-title">Financial Impact</h4>
                    <p style={{ textAlign: "justify" }}>
                      Campaigns on cYAAG also provide positive financial
                      benefits to people – making social businesses profitable,
                      creating new jobs, improving income levels, increasing
                      agricultural productivity, creating new businesses, and
                      increasing the number of women entrepreneurs. You can
                      track such financial impact metrics on cYAAG!{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="col-md-8">
                  <div class="impact-sec">
                    <h4 class="site-title">Environmental Impact</h4>
                    <p style={{ textAlign: "justify" }}>
                      cYAAG also helps you track the difference you are making
                      to the environment – like reducing carbon footprint,
                      planting trees, reducing water and air pollution,
                      implementing sustainable energy solutions, improving waste
                      management, reducing microplastics, improving
                      biodiversity, and much more!{" "}
                    </p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div>
                    <img src={Impact3} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container hide-on-desk">
          <div class="cyagg-sec-space">
            <div>
              <OwlCarousel className="owl-theme" {...optionsmob}>
                <div class="item">
                  {/* <h4 class="site-title">Create Triple Impact</h4> */}
                  <div class="impact-mob-card">
                    <div class="text-center">
                      <h4 class="site-title">Social Impact</h4>
                      <p class="text">
                        Through cYAAG you can continually track how much
                        difference you made to people’s lives by participating
                        in campaigns – such as providing education to
                        underprivileged children, improving access to
                        healthcare, supplying drinking water, providing care for
                        senior citizens, improving communications in rural
                        areas, empowering women, providing for animal welfare,
                        making communities cleaner and greener, and addressing
                        homelessness and poverty. You can see the impact you are
                        having as an individual and through the extended
                        community you have created on cYAAG.
                      </p>
                      <img src={Impact1} />
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="impact-mob-card">
                    <div class="text-center">
                      <h4 class="site-title">Financial Impact</h4>
                      <p class="text">
                        Campaigns on cYAAG also provide positive financial
                        benefits to people – making social businesses
                        profitable, creating new jobs, improving income levels,
                        increasing agricultural productivity, creating new
                        businesses, and increasing the number of women
                        entrepreneurs. You can track such financial impact
                        metrics on cYAAG!{" "}
                      </p>
                      <img src={Impact2} />
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div class="impact-mob-card">
                    <div class="text-center">
                      <h4 class="site-title">Environmental Impact</h4>
                      <p class="text">
                        cYAAG also helps you track the difference you are making
                        to the environment – like reducing carbon footprint,
                        planting trees, reducing water and air pollution,
                        implementing sustainable energy solutions, improving
                        waste management, reducing microplastics, improving
                        biodiversity, and much more!{" "}
                      </p>
                      <img src={Impact3} />
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="cyagg-sec-space">
            <div class="testimonial-wrap">
              <div class="row">
                <div class="col-md-4 hide-on-spec">
                  <div class="testi-img">
                    <img src="assests/img/testi.png" />
                  </div>
                </div>
                <div class="col-md-8 add-full-width">
                  <div class="testi-content">
                    <h2>What our changemakers say about cYAAG</h2>
                    <div>
                      {/* <OwlCarousel className='owl-theme' items="1" autoplay nav dots loop> */}
                      <OwlCarousel className="owl-theme" items="1" nav dots>
                        <div class="item">
                          <div class="they-said-wrap">
                            {/* <img class="test-per-img" src="https://www.fuersie.de/sites/default/files/styles/image360w/public/2022-06/egozentrische-person.jpg?h=fa6d1f7a&itok=da_GdqKf" /> */}
                            {/* <img class="test-per-img" src={LoginLogo} height="50%"/> */}
                            {/* <img class="test-per-img" src={LoginLogo} height="50%"/> */}
                            <Avatar> </Avatar>
                            <div class="testi-desc">
                              <h6>Anonymous</h6>
                              <p style={{ fontSize: 16 }}>
                                This platform empowers social changemakers to
                                create impact!
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="item">
                          <div class="they-said-wrap">
                            {/* <img class="test-per-img" src={LoginLogo} height="50%" /> */}
                            <Avatar> </Avatar>
                            <div class="testi-desc">
                              <h6>Anonymous</h6>
                              <p style={{ fontSize: 16 }}>
                                cYAAG gets results. It is the current day
                                Digital Word of Mouth!
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="item">
                          <div class="they-said-wrap">
                            {/* <img class="test-per-img" src={LoginLogo} height="50%" /> */}
                            <Avatar> </Avatar>
                            <div class="testi-desc">
                              <h6>Anonymous</h6>
                              <p style={{ fontSize: 16 }}>
                                The goal was to reach visually impaired people.
                                Participants and recipients were inspired and
                                motivated. This YAAG has wings.
                              </p>
                            </div>
                          </div>
                        </div>
                      </OwlCarousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
