import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ResponsiveDrawer from "./Header";
import { CopyRight } from "./CopyRight";
import TopHeaderScreen from "./TopHeader1";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import AsyncSelect from "react-select/async";
import axios from "axios";

import "./Join/Join.css";
import DeleteConfirmation from "./DeleteConfirmation";
import ConfirmationDialog from "./ConfirmationDialog";
import firebase from "../services/FirebaseConfig";

const useStyles = makeStyles((theme) => ({
  pageHeading: {
    textTransform: "uppercase",
    // fontSize: 14,
    background: theme.palette.primary.main,
    textAlign: "center",
    color: "#fff",
    padding: 3,
    fontWeight: "bold",
    // borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pageSubheading: {
    padding: 10,
    fontSize: 14,
    background: "#E9E7E7",
    display: "block",
    color: "#000",
    // marginBottom: 14,
    fontWeight: "500",
  },
  // pageHeadingIcon: {
  //   fontSize: 25,
  //   // color: theme.palette.secondary.main,
  //   marginLeft: 8,
  // },
  backBtn: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },

    display: "block",
    display: "flex",
    //fontSize: 16,
    fontSize: "16px",
    // marginTop: 16,
    // padding: 10,
    fontWeight: "500",
    lineHeight: "18px",
    minHeight: 11,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  createroombox: {
    // width: "fit-content",
    width: "310px",
    minWidth:310,
    maxWidth:310,
    display: "flex",
    flexDirection: "column",
    // maxWidth: "1000px",
    // height: "fit-content",
    border: "6px solid rgb(134, 133, 133)",
    // marginTop: "10px",
    // marginLeft: "500px",
    background: "white",
    // textAlign: "center",
    borderRadius: "10px",
    borderColor: "#f28800",
    alignItems: "center",
    justifyContent: "center",
    // padding: 10,
    // fontSize: 16,
    // margin: "10px auto",
  },
}));

export default function SignIn(props) {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const locationPath = useLocation();
  const [phoneError, setPhoneError] = useState(false);
  const [phoneTextError, setPhoneTextError] = React.useState("");
  const classes = useStyles();
  const navigate = useNavigate();
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  // var patternPhone = new RegExp(/^([0-9]{7,15})$/g);
  // var patternPhone = new RegExp(/^(a-zA-Z0-9]*[0-9]{3}[^a-zA-Z0-9]*[0-9]{3}[^a-zA-Z0-9]*[0-9]{4})$/g);
  var patternPhone = new RegExp(/^([0-9]{12})$/g);

  useEffect(() => {
    if (profileSuccess) {
      setName(profileResponse?.firstname);
    }
  }, [profileSuccess]);

  
  const handlePhonenoChange = () => {
    
    if (phoneError) {
      setPhoneError(true);
      
    } else {
      setPhoneError(false);
      handleClickLogin();
    }
  };

  const handleClickLogin = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container1"
    );
    var appVerifier = window.recaptchaVerifier;
  
    
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        localStorage.setItem("newuserid", phoneNumber.slice(1))
        navigate("/otpphonechange");
      })
      .catch((error) => {
        
      });
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <span
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
                display: "block",
              }}
            >
              Please update your Phone Number here
            </span>
            <div className="joinOuterContainer">
              <div className="joinInnerContainer">
                <h1 className="heading">Join</h1>
                <div className={classes.createroombox}>
                  <div>
                    <label>Your current Phone Number</label>
                    <input
                      disabled
                      placeholder="Name"
                      className="joinInputPhoneNo"
                      type="text"
                      value={locationPath?.pathname.split("/")?.[2]}
                    />
                  </div>
                  <div>
                  <label>Please enter your new no</label>
                    <input
                      placeholder="Enter New No"
                      className="joinInputPhoneNo"
                      type="text"
                      onChange={(event) => {
                        setPhoneNumber("+"+ (event.target.value));
                        if (event.target.value === locationPath?.pathname.split("/")?.[2]) {
                          setPhoneTextError("Please Enter a new phone number");
                          setPhoneError(true);
                        }
                      }}
                      onBlur={(event) => {
                       

                        if (!patternPhone.test(phoneNumber.slice(1))) {
                         
                          setPhoneTextError("Please Enter a Valid Phone Number");
                          setPhoneError(true);
                        } else {
                          
                          setPhoneTextError(false);
                          setPhoneError(false);
                        }
                        
                      }}
                      
                    />
                  </div>
                  <div id="recaptcha-container1" />
                  {
                    !phoneError ? (
                    <button
                      className={"button mt-20"}
                      type="submit"
                      style={{ fontSize: "14px" }}
                      // onClick={() => handleRoomCreate()}
                      onClick={() => handlePhonenoChange()}
                    >
                      Update
                    </button>
                    ):(
                      <button
                      disabled
                      className={"button mt-20"}
                      type="submit"
                      style={{ fontSize: "14px" , backgroundColor:"gray", cursor:"initial"}}
                      // onClick={() => handleRoomCreate()}
                      // onClick={() => handlePhonenoChange()}
                    >
                      Update
                    </button>
                    )
                  }
                    {
                      phoneError &&
                      <span style={{color:"red"}}>{phoneTextError}</span>
                    }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
