import React, { useState } from 'react';
import { Avatar, Grid, Typography, Box, IconButton, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ResponsiveDrawer from "../../components/Header";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ColorModeContext, useMode, tokens } from "../../theme";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import RunningBalanceIcon from '@mui/icons-material/AccountBalanceWallet';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';
import { format } from 'date-fns';
import { useDispatch, useSelector } from "react-redux";
import {
    deleteKTCustomerTRN,
    deleteKTCustomerTRNReset,
} from '../../redux/action/DeleteAction';

const useStyles = makeStyles((theme) => ({
    homeSreenMainContent: {
        padding: "80px 0 100px 0px",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("sm")]: {
            padding: "0px 30px 100px 25px",
            background: "#F5F8FB",
        },

    },
    modalContainer: {
        zIndex: 1500, // You can adjust the value as needed
        // ... other styles
    },
}));

const EditEntry = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const locationPath = useLocation();
    const dispatch = useDispatch();
    const [isPopupOpen, setPopupOpen] = useState(false);
    // const { customerDetList } = locationPath.state;

    console.log(locationPath?.pathname.split("/")?.[3]);
    console.log(locationPath?.pathname.split("/")?.[4]);
    console.log(locationPath?.pathname.split("/")?.[5]);
    console.log(locationPath?.pathname.split("/")?.[6]);
    console.log(locationPath?.pathname.split("/")?.[7]);
    console.log(locationPath?.pathname.split("/")?.[8]);
    console.log(locationPath?.pathname.split("/")?.[9]);
    console.log(locationPath?.pathname.split("/")?.[10]);

    const {
        ktCustomerTRNDeleteResponse,
        isKTCustomerTRNDeleteSuccess,
        isKTCustomerTRNDeleteError,
        isKTCustomerTRNDeleteFetching,
    } = useSelector((state) => state.deleteKTCustomerTRN);

    console.log("isKTCustomerTRNDeleteSuccess = ",isKTCustomerTRNDeleteSuccess)

    const handleDeleteClick = () => {
        setPopupOpen(true);
    };

    const handleCancelClick = () => {
        setPopupOpen(false);
    };

    const handleDeleteConfirmClick = () => {
        // const formattedDate = format(amountDate, 'yyyy-MM-dd HH:mm:ss');
        // console.log(`Saving amount: ${amount}`);
        let formData = new FormData();
        formData.append("phoneno", locationPath?.pathname.split("/")?.[2]);
        formData.append("payment", 0);
        formData.append("paymentdate", "");
        formData.append("type", "You Gave");
        formData.append("oldpaymentdate", "");
        formData.append("trnid", locationPath?.pathname.split("/")?.[7]);
        formData.append("prevpayment", locationPath?.pathname.split("/")?.[5]);

        dispatch(
            deleteKTCustomerTRN({ userid: localStorage.getItem("userid"), formData })
        );
        console.log("called dispatch") 

        // Close the pop-up
        setPopupOpen(false);
    };

    return (
        <div className="wrapper">
            <ResponsiveDrawer {...props} />
            <section className="containers">
                <TopHeaderScreen {...props} />
                <div class="content content-wrap">
                    <div class="content-mrg hasScreen">
                        <Button onClick={() => navigate(-1)} className={classes.backBtn}>
                            <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                            <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
                        </Button>
                        <Box sx={{ backgroundColor: '#89CFF0', zIndex: 1, padding: '20px' }}>
                            <Box
                                sx={{
                                    zIndex: 3,
                                    border: '1px solid #ccc',
                                    backgroundColor: 'white',
                                    borderRadius: '8px',
                                    padding: '16px',
                                    width: '300px', // Adjust the width as per your requirement
                                    '@media (max-width: 600px)': {
                                        width: '100%', // Make it responsive for smaller screens
                                    },
                                }}
                            >
                                {/* Row 1 */}
                                <Box sx={{ zIndex: 2, display: 'flex', marginBottom: '12px' }}>
                                    {/* Column 1 */}
                                    <Avatar src="/path/to/avatar.jpg" alt="Avatar" sx={{ marginRight: '12px' }} />

                                    {/* Column 2 */}
                                    <Box sx={{ flex: 1 }}>
                                        <Typography variant="subtitle1" fontWeight="bold" >
                                            {locationPath?.pathname.split("/")?.[4]}
                                        </Typography>
                                        <Typography variant="caption" color="textSecondary" style={{ fontSize: "10px" }}>
                                            {decodeURIComponent(locationPath?.pathname.split("/")?.[6]).replace(/\+/g, ' ')}
                                        </Typography>
                                    </Box>

                                    {/* Column 3 */}
                                    <Box sx={{ textAlign: 'right' }}>
                                        <Typography variant="subtitle1" fontWeight="bold" >
                                            ₹ {locationPath?.pathname.split("/")?.[5]}
                                        </Typography>
                                        <Typography variant="caption" color="textSecondary" style={{ fontSize: "10px" }}>
                                            {decodeURIComponent(locationPath?.pathname.split("/")?.[8]).replace(/\+/g, ' ')}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider sx={{ margin: '12px 0' }} />

                                {/* Row 2 */}
                                <Box sx={{ zIndex: 2, display: 'flex', marginBottom: '12px' }}>
                                    {/* Column 1 */}
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        Running balance
                                    </Typography>

                                    {/* Column 2 */}
                                    <Box sx={{ flex: 1, textAlign: 'right' }}>
                                        <Typography variant="h6" fontWeight="bold">
                                            ₹ {locationPath?.pathname.split("/")?.[7]}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider sx={{ margin: '12px 0' }} />

                                {/* Row 3 */}
                                {
                                    decodeURIComponent(locationPath?.pathname.split("/")?.[8]).replace(/\+/g, ' ') === "You Gave" ?
                                        <Box
                                            onClick={() => navigate(`/yougaveedit/${locationPath?.pathname.split("/")?.[9]}/${locationPath?.pathname.split("/")?.[4]}/${locationPath?.pathname.split("/")?.[3]}/${locationPath?.pathname.split("/")?.[5]}/${decodeURIComponent(locationPath?.pathname.split("/")?.[6]).replace(/\+/g, ' ')}/${locationPath?.pathname.split("/")?.[10]}`)}
                                            sx={{ zIndex: 2, marginTop: "40px", display: 'flex', alignItems: 'center', justifyContent: 'center', color: "blue" }}>
                                            <EditIcon sx={{ marginRight: '4px', fontSize: '16px' }} />
                                            <Typography variant="body2">EDIT ENTRY</Typography>
                                        </Box>
                                        : decodeURIComponent(locationPath?.pathname.split("/")?.[8]).replace(/\+/g, ' ') === "You Got" ?
                                            <Box
                                                onClick={() => navigate(`/yougotedit/${locationPath?.pathname.split("/")?.[9]}/${locationPath?.pathname.split("/")?.[4]}/${locationPath?.pathname.split("/")?.[3]}/${locationPath?.pathname.split("/")?.[5]}/${decodeURIComponent(locationPath?.pathname.split("/")?.[6]).replace(/\+/g, ' ')}/${locationPath?.pathname.split("/")?.[10]}`)}
                                                sx={{ zIndex: 2, marginTop: "40px", display: 'flex', alignItems: 'center', justifyContent: 'center', color: "blue" }}>
                                                <EditIcon sx={{ marginRight: '4px', fontSize: '16px' }} />
                                                <Typography variant="body2">EDIT ENTRY</Typography>
                                            </Box>
                                            : ""
                                }


                            </Box>
                            <Box sx={{
                                zIndex: 2,
                                backgroundColor: 'white',
                                border: '1px solid #ccc',
                                marginTop: "100px",
                                borderRadius: '8px',
                                padding: '16px',
                                width: '300px', // Adjust the width as per your requirement
                                '@media (max-width: 600px)': {
                                    width: '100%', // Make it responsive for smaller screens
                                },
                            }}>
                                <Box sx={{ zIndex: 3, display: 'flex', alignItems: 'center', justifyContent: 'center', color: "blue" }}>
                                    <Button startIcon={<DeleteIcon />} color="error" size="small" onClick={handleDeleteClick}>
                                        Delete
                                    </Button>
                                </Box>
                            </Box>
                        </Box>

                        {isPopupOpen && (
                            <div className="popup-editentry">
                                <div className="popup-content-editentry">
                                <p style={{fontWeight: 800}}>Delete entry?</p>
                                <p style={{fontSize: "14px"}} >Delete entry will change your balance</p>
                                <Divider sx={{ margin: '12px 0px 0px 0px' }} />
                                <div className="popup-buttons-editentry">
                                    <Button style={{borderColor: "blue", border: "1px solid", width:"120px"}} onClick={handleCancelClick}>Cancel</Button>
                                    <Button style={{color: "white", backgroundColor:"red", width:"120px" }} onClick={handleDeleteConfirmClick}>Confirm</Button>
                                </div>
                            </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default EditEntry;
