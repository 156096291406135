/** @format */

import { call, put } from "redux-saga/effects";
import { get } from "lodash";

import {
    GET_REWARD_TITLE_FETCHING_DATA,
    GET_REWARD_TITLE_FETCHING_DATA_FAILURE,
    GET_REWARD_TITLE_FETCHING_DATA_SUCCESS,

    // GET_REWARD_SUBTITLE_FETCHING_DATA,
    // GET_REWARD_SUBTITLE_FETCHING_DATA_FAILURE,
    // GET_REWARD_SUBTITLE_FETCHING_DATA_SUCCESS, 
 
} from "../action/RewardDetailAction";
import { Api } from "../../services";

const myAPI = new Api();

export function* getRewardTitle(action) {
  // alert("in saga")
  
  yield put({
    type: GET_REWARD_TITLE_FETCHING_DATA,
  });

  try {
    
    const response = yield call(myAPI.getRewardTitle, action.payload);
    const payload = get(response, "data");
    yield put({
      type: GET_REWARD_TITLE_FETCHING_DATA_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({ type: GET_REWARD_TITLE_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
    
  }
}

// export function* getRewardSubTitle(action) {
//   yield put({
//     type: GET_REWARD_SUBTITLE_FETCHING_DATA,
//   });

//   try {
//     const response = yield call(myAPI.getRewardSubTitle, action.payload);
//     const payload = get(response, "data");
//     yield put({
//       type: GET_REWARD_SUBTITLE_FETCHING_DATA_SUCCESS,
//       payload: response,
//     });
//   } catch (e) {
//     yield put({ 
//       type: GET_REWARD_SUBTITLE_FETCHING_DATA_FAILURE,
//       payload: e,
//     });
//     // alert("Validation Failed");
//   }
// }