/** @format */

const ADD_REWARDS_FETCHING_DATA = "ADD_REWARDS_FETCHING_DATA";
const ADD_REWARDS_FETCHING_DATA_FAILURE = "ADD_REWARDS_FETCHING_DATA_FAILURE";
const ADD_REWARDS_FETCHING_DATA_RESET = "ADD_REWARDS_FETCHING_DATA_RESET";
const ADD_REWARDS_FETCHING_DATA_SUCCESS = "ADD_REWARDS_FETCHING_DATA_SUCCESS";
const ADD_REWARDS_FETCHING_DATA_ATTEMPT = "ADD_REWARDS_FETCHING_DATA_ATTEMPT";
const addRewards = (payload) => ({
  type: ADD_REWARDS_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const addRewardsSuccess = () => ({
  type: ADD_REWARDS_FETCHING_DATA_SUCCESS,
});
const addRewardsReset = () => ({
  type: ADD_REWARDS_FETCHING_DATA_RESET,
});

export {
    ADD_REWARDS_FETCHING_DATA,
    ADD_REWARDS_FETCHING_DATA_FAILURE,
    ADD_REWARDS_FETCHING_DATA_RESET,
    ADD_REWARDS_FETCHING_DATA_SUCCESS,
    ADD_REWARDS_FETCHING_DATA_ATTEMPT,
    addRewards,
    addRewardsSuccess,
    addRewardsReset,
};