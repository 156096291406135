import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { makeStyles, ListItemIcon, Checkbox, ListItemText } from "@material-ui/core";
import {
    Avatar,
    Button,
    CircularProgress,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import _, { values } from "lodash";
// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import {
    onboardActivityReset,
    onboardBulkReset,
    onboardCampaign,
    onboardCampaignReset,
    onboardIndividualReset,
    onboardOrganization,
    onboardOrganizationReset,
} from "../../redux/action/OnboardAction";
import CallingCode from "../../services/CallingCode";
import { fetchCityAndState } from "../../services/commonServices";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";
import { API_URL } from "../../services/Constants";
import { getOrganization } from "../../redux/action/getOrganization";
import {
    activityDuration,
    campaignType,
    restrictedCountry,
    BloodGroup,
    Gender,
    status,
    educationList,
    preferWorkingDays,
    hoursEveryWeek,
    interestAreaDict,
    skillSets
} from "../../utils/dict";
import {
    DatePicker,
    DateTimePicker,
    LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { getCity } from "../../redux/action/cityAction";
import { states, MenuProps } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link } from "react-router-dom";
import DeleteConfirmation from "../../components/DeleteConfirmation";

const useStyles = makeStyles((theme) => ({
    homeSreenMainContent: {
        padding: "80px 0 100px 0px",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("sm")]: {
            padding: "80px 30px 100px 330px",
        },
    },

    input: {
        width: "100%",
        border: "none",
        padding: 10,
        display: "block",
        borderRadius: 6,
        fontSize: 15,
        height: 54,
        boxShadow: "0px 3px 6px #00000029",
        color: "#949494",
        // color: "black",
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        },
    },
    emailinput: {
        // textTransform: "capitalize",
        width: "100%",
        border: "none",
        padding: 10,
        display: "block",
        borderRadius: 6,
        height: 54,
        boxShadow: "0px 3px 6px #00000029",
        // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
        color: "#949494",
        // color: "black",
        fontSize: 15,
        [theme.breakpoints.down("sm")]: {
            fontSize: 12,
        },
    },

    goalButton: {
        fontSize: 15,
        fontWeight: "bold",
        lineHeight: 1.2,
        marginLeft: 40,
    },

    label: {
        "& .css-u4tvz2-MuiFormLabel-root": {
            fontFamily: "Poppins",
        },
        fontFamily: "Poppins",
        display: "block",
        fontSize: 16,
        marginTop: 16,
        padding: 10,
        fontWeight: "500",
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
        },
    },
    label1: {
        "& .css-u4tvz2-MuiFormLabel-root": {
            fontFamily: "Poppins",
        },
        fontFamily: "Poppins",
        fontSize: 14,
        fontWeight: "600",
        [theme.breakpoints.down("sm")]: {
            fontSize: 14,
        },
    },
    formGroup: {
        "& .react-datepicker__input-container": {
            "& input": {
                height: 40,
            },
        },

        transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
        backgroundColor: "#fff",

        borderRadius: "8px",
        marginBottom: "4px",
        padding: "0 16px 0px 16px",
        pageBreakInside: "avoid",
    },
    formGroup1: {
        transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
        backgroundColor: "#fff",

        borderRadius: "8px",
        marginBottom: "4px",
        padding: "0 16px 0px 16px",
        pageBreakInside: "avoid",
    },
}));

function VolunteerDeleteScreen(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const inputRef = React.useRef(null);
    const videoRef = React.useRef(null);
    const [orgRole, setORGRole] = React.useState([]);
    const [org, setORG] = React.useState([]);
    const locationPath = useLocation();
    // const [type, setType] = useState(null);
    // const [id, setId] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    // const [deleteMessage, setDeleteMessage] = useState(null);

    // Hide the modal
    const hideConfirmationModal = () => {
        setDisplayConfirmationModal(false);
    };

    const [volunteerDetails, setvolunteerDetails] = useState({
        // fullname: "",
        firstname: "",
        lastname: "",
        dob: new Date(),
        correspondenceAddress: "",
        emailId: "",
        gender: "",
        phoneNumber: "",
        city: "",
        state: "",
        area: "",
        organization: "",
        highLevelEdu: "",
        preferDays: "",
        interestArea: "",
        hoursPerWeek: "",
        orgRole: "",
        bloodGroup: "",
        skill: "",
    });

    const getORGRole = async () => {
        try {
            const res = await axios.get(`/orgrole/${localStorage.getItem("userid")}`);
            setORGRole(res?.data);
            return res;
        } catch (err) {
            throw err;
        }
    };

    const handleDeleteUser = async () => {
        // setDisplayConfirmationModal(true);
        const payload = {
          // campid: locationPath?.state?.campid,
          // createdate: locationPath?.state?.createdate,
          // reviewstatus: reviewstatus,
          // reviewcomments: reviewComments,
          // phoneno: localStorage.getItem("userid")
          phoneno: locationPath?.state.phoneno,
    
        };
        try {
          const res = await axios.put(
            // `/user/delete/${localStorage.getItem("userid")}`,
            `/user/delete`,
            payload
          );
          // navigate("/reviewcampaign");
          // setuserdeletestat(res?.data);
          setDisplayConfirmationModal(false);
          navigate("/reviewvolunteer");
          return res;
        } catch (err) {
          throw err;
        }
      };

    const getORG = async () => {
        try {
            const res = await axios.get(`/organization/${localStorage.getItem("userid")}`);
            setORG(res?.data);
            return res;
        } catch (err) {
            throw err;
        }
    };

    const [response, setResponse] = useState({});
    const { isSuccess: profileSuccess, response: VolunteerResponse } = useSelector(
        (state) => state.profile
    );

    const handleFieldChange = (field, e) => {
        setResponse({});
        setvolunteerDetails({
            ...volunteerDetails,
            [field]: field === "dob" ? e : e.target.value,
        });
    };
    const getSelectedValue = (orgObj) => {
        // console.log(orgObj);
    }

    const [notificationShow, setNotificationShow] = React.useState(false);

    function subtractYears(numOfYears, date = new Date()) {
        date.setFullYear(date.getFullYear() - numOfYears);

        return date;
    }

    const handleChangeLocation = (id, e, field, index) => {
        const values = [...location];
        values[index][field] = e.target.value;
        setLocation(values);
    };

    //   const handleVolunteer = async () => {
    //     const payload = {
    //       campid: locationPath?.state?.campid,
    //       createdate: locationPath?.state?.createdate,
    //       reviewstatus: reviewstatus,
    //       reviewcomments: reviewComments,
    //     };
    //     try {
    //       const res = await axios.get(
    //         `/cyaaguser/${localStorage.getItem("userid")}`,
    //         payload
    //       );
    //       navigate("/reviewcampaign");
    //       return res;
    //     } catch (err) {
    //       throw err;
    //     }
    //   };

    // console.log(locationPath?.state.dob)
    // console.log(new Date())

    useEffect(() => {
        if (locationPath?.state) {
            setvolunteerDetails({
                // fullname: locationPath?.state.fullname,
                // dob: locationPath?.state.dob,
                firstname: locationPath?.state.firstname,
                lastname: locationPath?.state.lastname,
                dob: moment(locationPath?.state.dob, "MM-DD-YYYY"),
                correspondenceAddress: locationPath?.state.correspondenceaddress,
                emailId: locationPath?.state.emailid,
                gender: locationPath?.state.gender,
                phoneNumber: locationPath?.state.phoneno,
                city: locationPath?.state.city,
                state: locationPath?.state.state,
                area: locationPath?.state.area,
                organization: locationPath?.state.organization,
                orgRole: locationPath?.state.orgrole,
                highLevelEdu: locationPath?.state.highlvledu,
                preferDays: locationPath?.state.preferday,
                interestArea: locationPath?.state.interestarea,
                hoursPerWeek: locationPath?.state.hoursperweek,
                bloodGroup: locationPath?.state.bloodgroup,
                skill: locationPath?.state.skill,
            });
        }
    }, [locationPath?.state]);

    // console.log(locationPath?.state)

    const handleSave = async () => {
        try {
            const res = await axios.put(`/user/profile`, {
                // fullname: volunteerDetails?.fullname,
                firstname: volunteerDetails?.firstname,
                lastname: volunteerDetails?.lastname,
                dob: moment(volunteerDetails?.dob).format("DD-MM-yyyy"),
                phoneno: volunteerDetails?.phoneNumber,
                correspondenceaddress: volunteerDetails?.correspondenceAddress,
                emailid: volunteerDetails?.emailId,
                gender: volunteerDetails?.gender,
                city: volunteerDetails?.city,
                state: volunteerDetails?.state,
                organization: volunteerDetails?.organization,
                highlvledu: volunteerDetails?.highLevelEdu,
                preferday: volunteerDetails?.preferDays,
                hoursperweek: volunteerDetails?.hoursPerWeek,
                interestarea: volunteerDetails?.interestArea,
                orgrole: volunteerDetails?.orgRole,
                bloodgroup: volunteerDetails?.bloodGroup,
                skill: volunteerDetails.skill,
            });
            setResponse(res?.data);
            return res;

        } catch (e) {
            // console.log("error", e);
        }
    };

    //   const handleSave = async () => {
    //     try {
    //       const res = await axios.put(`/user/profile`, {
    //         fullname: values?.fullname,
    //         dob: values?.dob,
    //         phoneno: values?.phoneNumber,
    //         correspondenceaddress: values?.correspondenceAddress,
    //         emailid: values?.emailId,
    //         city: values?.city,
    //         state: values?.state,
    //         organization: values?.organization,
    //         highlvledu: values?.highLevelEdu,
    //         preferday: values?.preferDays,
    //         hoursperweek: values?.hoursPerWeek,
    //         interestarea: values?.interestArea,
    //         orgrole: values?.orgRole,
    //         bloodgroup: values?.bloodGroup,
    //         skill: values.skill,
    //       });
    //       setResponse(res?.data);
    //       return res;
    //     } catch (e) {
    //       console.log("error", e);
    //     }
    //   };

    //   const onAddingItem = (event, option, index) => {
    //     const values = [...focusArea];
    //     values[index].isChecked = event.target.checked;
    //     setFocusArea(values);
    //   };

    //   const handleTextFieldCheckbox = (event, index) => {
    //     const values = [...focusArea];
    //     values[index].rewards = event.target.value;
    //     setFocusArea(values);
    //   };

    //   const handleChangeLocation = (id, e, field, index) => {
    //     const values = [...location];
    //     values[index][field] = e.target.value;
    //     setLocation(values);
    //   };


    // const handleChangeLocation1 = (field, e) => {
    //     const values = [...location];
    //     values[index][field] = e.target.value;
    //     setLocation(values);
    //   };

    //   const handleFieldChange = (field, e) => {
    //     setResponse({});
    //     setValues({
    //       ...values,
    //       [field]: field === "dob" ? e : e.target.value,
    //     });
    //   };

    const handleAddFields = () => {
        setLocation([...location, { country: "", state: "", city: "", area: "" }]);
    };

    const [location, setLocation] = React.useState([
        { country: "", state: "", city: "", area: "" },
    ]);

    const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
        (state) => state.cities
    );

    const {
        isSuccess: organizationListSuccess,
        isError: organizationListError,
        response: organizationList,
    } = useSelector((state) => state.organizationList);

    React.useEffect(() => {
        dispatch(onboardBulkReset());
        dispatch(onboardIndividualReset());
        dispatch(onboardActivityReset());
        dispatch(onboardCampaignReset());
        dispatch(onboardOrganizationReset());
    }, []);

    const handleChangeSkills = (event, index) => {
        let value = Array.from(event.target.selectedOptions, option => option.value);
        this.setState({ values: value });
    };

    useEffect(() => {
        // console.log(locationPath?.state.city)
        if (locationPath?.state.city) {
            dispatch(getCity(locationPath?.state.state));
        }
        getORGRole();
        getORG();
    }, [])

    //handle key press event
    const handleKeypress = (e) => {
        e.preventDefault();
        return false
    }

    return (

        <div>

            <ResponsiveDrawer {...props} />
            <TopHeaderScreen {...props} />
            
            <main
                onClick={() => {
                    props.setNotificationShow(false);
                }}
                className={classes.homeSreenMainContent}
                id="homepage-main"
            >
                <Link className={classes.backBtn} to="/reviewvolunteer">
                    <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                    <span style={{ paddingLeft: 0 }}> Back</span>
                </Link>
                <span style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5, marginLeft:"5px" }}>
                    Delete User Profile
                </span>
                <form autoComplete="off">
                    <div style={{ border: "1px solid #dadce0", borderRadius: 8, padding: "13px 30px 40px 30px" }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                            }}
                        >
                            <div className={classes.formGroup} style={{ width: "25%" }}>
                                <label className={classes.label}>First Name</label>
                                <input
                                    disabled
                                    // value={volunteerDetails?.fullname}
                                    value={volunteerDetails?.firstname}
                                    type="text"
                                    className={classes.input}
                                    // placeholder="Your answer"
                                    onChange={(e) => {
                                        handleFieldChange("firstname", e);
                                    }}
                                />
                            </div>
                            <div className={classes.formGroup} style={{ width: "25%" }}>
                                <label className={classes.label}>Last Name</label>
                                <input
                                    disabled
                                    // value={volunteerDetails?.fullname}
                                    value={volunteerDetails?.lastname}
                                    type="text"
                                    className={classes.input}
                                    placeholder="Your answer"
                                    onChange={(e) => {
                                        handleFieldChange("lastname", e);
                                    }}
                                />
                            </div>
                            {/* <div className={classes.formGroup}>
                                <label htmlFor="create-yaad--title" className={classes.label}>
                                    Date of Birth
                                </label>
                                <input
                                    disabled
                                    value={volunteerDetails?.dob}
                                    type="text"
                                    className={classes.input}
                                />
                            </div> */}

                            <div className={classes.formGroup} style={{ width: "25%" }}>
                                <label className={classes.label}>Date of Birth</label>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        value={volunteerDetails?.dob}
                                        onChange={(newValue) => {
                                            handleFieldChange("dob", newValue);
                                        }}
                                        // maxDate={new Date()}
                                        maxDate={subtractYears(5)}
                                        disabled
                                        renderInput={(params) => (
                                            <TextField
                                                type="text"
                                                onKeyDown={(e) => { handleKeypress(e) }}
                                                style={{
                                                    width: "100%",
                                                    height: 56,
                                                    color: "#949494",
                                                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                                                }}
                                                {...params}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>

                            </div>
                            {/* {JSON.stringify(volunteerDetails)} */}

                            <div className={classes.formGroup} style={{ width: "25%" }}>
                                <label className={classes.label}>Gender</label>
                                <Select
                                    style={{
                                        width: "100%",
                                        fontSize: 14,
                                        height: 56,
                                        
                                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                                    }}
                                    value={volunteerDetails?.gender}
                                    disabled
                                    onChange={(e) => handleFieldChange("gender", e)}
                                >
                                    {Gender.map((item, index) => (
                                        <MenuItem key={item.value} value={item?.value}>
                                            {item.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>

                            <div className={classes.formGroup} style={{ width: "25%" }}>
                                <label className={classes.label}>Blood Group</label>
                                <Select
                                    style={{
                                        width: "100%",
                                        fontSize: 14,
                                        height: 56,

                                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                                    }}
                                    disabled
                                    value={volunteerDetails?.bloodGroup}
                                    onChange={(e) => handleFieldChange("bloodGroup", e)}
                                >
                                    {BloodGroup.map((item, index) => (
                                        <MenuItem key={item.value} value={item?.value}>
                                            {item.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>

                            <div className={classes.formGroup} style={{ width: "25%" }}>
                                <label className={classes.label}>Email ID</label>
                                <input
                                    disabled
                                    value={volunteerDetails?.emailId}
                                    type="text"
                                    className={classes.emailinput}
                                    onChange={(e) => handleFieldChange("emailId", e)}
                                />
                            </div>
                            <div className={classes.formGroup} style={{ width: "25%" }}>
                                <label htmlFor="create-yaad--title" className={classes.label}>
                                    Phone no
                                </label>
                                <input
                                    disabled
                                    value={volunteerDetails?.phoneNumber}
                                    type="text"
                                    className={classes.input}
                                    onChange={(e) => handleFieldChange("phoneNumber", e)}
                                />
                            </div>
                            <div className={classes.formGroup} style={{ width: "25%" }}>
                                <label htmlFor="create-yaad--title" className={classes.label}>
                                    Correspondence Address
                                </label>
                                <input
                                    disabled
                                    value={volunteerDetails?.correspondenceAddress}
                                    type="text"
                                    accept="image/*"
                                    id="create-yaad--title"
                                    className={classes.input}
                                    onChange={(e) => handleFieldChange("correspondenceAddress", e)}
                                />
                            </div>


                            {location.map((item, index) => (
                                <div className={classes.formGroup} style={{ width: "25%" }}>
                                    <label htmlFor="create-yaad--title" className={classes.label}>
                                        State
                                    </label>
                                    {/* HI {JSON.stringify(values)} */}

                                    <Select
                                        style={{
                                            width: "100%",
                                            fontSize: 14,
                                            height: 56,
                                            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                                        }}
                                        // value={volunteerDetails?.state}
                                        value={volunteerDetails?.state}
                                        disabled
                                        onChange={(e) => {
                                            // handleChangeLocation(item, e, "state", index);
                                            handleFieldChange("state", e)
                                            // handleChangeLocation("state", e);
                                            // dispatch(getCity(item.state));
                                            dispatch(getCity(e.target.value));
                                        }}

                                        onBlur={(e) => {
                                            // dispatch(getCity(item.state));
                                        }}
                                    >
                                        {states.map((item, index) => (
                                            <MenuItem style={{ fontSize: 16 }} value={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            ))}

                            {location.map((item, index) => (
                                <div className={classes.formGroup} style={{ width: "25%" }}>
                                    <label htmlFor="create-yaad--title" className={classes.label}>
                                        City/Town/Village
                                    </label>

                                    <Select
                                        style={{ width: "100%", fontSize: 14, height: 56 }}
                                        disabled
                                        value={volunteerDetails?.city}
                                        onChange={(e) => {
                                            handleFieldChange("city", e);
                                        }}
                                    >
                                        {citiesSuccess &&
                                            citiesResponse.map((item, index) => (
                                                <MenuItem style={{ fontSize: 16 }} value={item.name}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </div>
                            ))}

                            <div className={classes.formGroup} style={{ width: "25%" }}>
                                <label htmlFor="create-yaad--title" className={classes.label}>
                                    Highest Level of Education
                                </label>
                                <Select
                                    style={{
                                        width: "100%",
                                        fontSize: 14,
                                        height: 56,

                                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                                    }}
                                    value={volunteerDetails?.highLevelEdu}
                                    disabled
                                    onChange={(e) => handleFieldChange("highLevelEdu", e)}
                                >
                                    {educationList.map((item, index) => (
                                        <MenuItem key={item.value} value={item?.name}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>

                            <div className={classes.formGroup} style={{ width: "25%" }}>
                                <label className={classes.label}>Hours Per Week</label>
                                <Select
                                    style={{
                                        width: "100%",
                                        fontSize: 14,
                                        height: 56,

                                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                                    }}
                                    value={volunteerDetails?.hoursPerWeek}
                                    disabled
                                    onChange={(e) => handleFieldChange("hoursPerWeek", e)}
                                >
                                    {hoursEveryWeek.map((item, index) => (
                                        <MenuItem key={item.value} value={item?.value}>
                                            {item.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            
                            <div className={classes.formGroup} style={{ width: "25%" }}>
                                <label htmlFor="create-yaad--title" className={classes.label}>
                                    Prefer Day
                                </label>
                                <Select
                                    style={{
                                        width: "100%",
                                        fontSize: 14,
                                        height: 56,
                                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                                    }}
                                    value={volunteerDetails?.preferDays}
                                    disabled
                                    onChange={(e) => handleFieldChange("preferDays", e)}
                                >
                                    {preferWorkingDays.map((item, index) => (
                                        <MenuItem key={item.value} value={item?.value}>
                                            {item.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>

                            <div className={classes.formGroup} style={{ width: "25%" }}>
                                <label className={classes.label}> Name of Organisation</label>
                                <Select
                                    style={{
                                        width: "100%",
                                        fontSize: 14,
                                        height: 56,
                                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                                    }}
                                    value={volunteerDetails?.organization}
                                    disabled
                                    onChange={(e) => {
                                        handleFieldChange("organization", e);
                                    }}
                                >
                                    {org?.map((item, index) => (
                                        <MenuItem key={item.name} value={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>

                            <div className={classes.formGroup} style={{ width: "25%" }}>
                                <label className={classes.label}>Organisation Role</label>
                                <Select
                                    style={{
                                        width: "100%",
                                        fontSize: 14,
                                        height: 56,
                                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                                    }}
                                    value={volunteerDetails?.orgRole.toLowerCase()}
                                    disabled
                                    onChange={(e) => {
                                        handleFieldChange("orgRole", e);
                                    }}
                                >
                                    {orgRole?.map((item, index) => (
                                        <MenuItem key={item.role} value={item.role}>
                                            {item.role}
                                        </MenuItem>
                                    ))}
                                </Select>
                            
                            </div>

                        </div>
                    </div>
                </form>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
                    <Button
                        onClick={() => setDisplayConfirmationModal(true)}
                        style={{
                            marginTop: 8,
                            // marginLeft:600,
                            width: "200px",
                            // background: "#E88A00",
                            background: "red",
                            borderRadius: 20,
                            color: "#fff",
                            fontFamily: "Poppins !important",
                            alignSelf: "center",
                        }}
                    >
                        <span>Delete</span>
                    </Button>

                    <Button
                        onClick={() => navigate(-1)}
                        style={{
                            marginTop: 8,
                            // marginLeft: 600,
                            width: "200px",
                            background: "grey",
                            borderRadius: 20,
                            color: "#fff",
                            fontFamily: "Poppins !important",
                            alignSelf: "center",
                        }}
                    >
                        <span>Cancel</span>
                    </Button>
                </div>
                {profileSuccess &&
                    <span style={{ color: "green", textAlign: "center", display: "block" }}>{response?.message}</span>}
                {!profileSuccess && (
                    // <span style={{ color: "red" }}>Error occured</span>
                    <span style={{ color: "red", textAlign: "center", display: "block" }}>{response?.message}</span>
                )}
                {/* {response && <span>{response?.message}</span>} */}
            </main>
            <CopyRight />
            <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={handleDeleteUser} hideModal={hideConfirmationModal} message={"Are you sure you want to delete this user?"}  />
        </div>
    );
}
export default VolunteerDeleteScreen;
