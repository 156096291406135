/** @format */

// reducers
import {
    
  GET_CAMPAIGN_PROFILE_VIDEO_FETCHING_DATA,
  GET_CAMPAIGN_PROFILE_VIDEO_FETCHING_DATA_FAILURE,
  GET_CAMPAIGN_PROFILE_VIDEO_FETCHING_DATA_RESET,
  GET_CAMPAIGN_PROFILE_VIDEO_FETCHING_DATA_SUCCESS,
  
  } from "../action/campVideoAction";
  
  let initialState = {
    response: {},
    isSuccess: false,
    isError: false,
    isFetching: false,
  };
  
  export default function campLogoReducer(state = initialState, action) {
    switch (action.type) {
      case GET_CAMPAIGN_PROFILE_VIDEO_FETCHING_DATA:
        return {
          ...state,
          isFetching: true,
          isError: false,
          isSuccess: false,
        };
      case GET_CAMPAIGN_PROFILE_VIDEO_FETCHING_DATA_FAILURE:
        return {
          ...state,
          isFetching: false,
          isError: action.payload,
          isSuccess: false,
        };
      case GET_CAMPAIGN_PROFILE_VIDEO_FETCHING_DATA_SUCCESS:
        return {
          ...state,
          isFetching: false,
          isError: false,
          response: action.payload,
          isSuccess: true,
        };
  
      case GET_CAMPAIGN_PROFILE_VIDEO_FETCHING_DATA_RESET:
        return initialState;
  
      default:
        return state;
    }
  }