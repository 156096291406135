/** @format */

import { call, put } from "redux-saga/effects";
import { get } from "lodash";

import {
  PROFILE_FETCHING_DATA,
  PROFILE_FETCHING_DATA_SUCCESS,
  PROFILE_FETCHING_DATA_FAILURE,
} from "../action/UserProfileActions";
import { Api } from "../../services";

const myAPI = new Api();

export function* profileSaga(action) {
  yield put({
    type: PROFILE_FETCHING_DATA,
  });

  try {
    // console.log("USER PROFILE SAGA")
    const response = yield call(myAPI.user, action.payload);
    // {JSON.stringify(response)}
    const payload = get(response, "data");
    yield put({
      type: PROFILE_FETCHING_DATA_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({ type: PROFILE_FETCHING_DATA_FAILURE });
  }
}
