import { makeStyles, Table } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import QRCode from "react-qr-code";
import { useLocation, useNavigate } from "react-router-dom";
import CustomDrawer from "../../components/CustomHeader";
import ResponsiveDrawer from "../../components/Header";
import { volunteerMenuItems } from "../../utils/dict";
import ConfettiImage from "../../assets/confetti.svg";
import { useDispatch, useSelector } from "react-redux";
import { CopyRight } from "../../components/CopyRight";
import TopHeaderScreen from "../../components/TopHeader1";
import { myReward } from "../../redux/action/MyRewardAction";
import { onboardCampaign } from "../../redux/saga/OnboardSaga";
import { userProfile } from "../../redux/action/UserProfileActions";
import _ from "lodash";
import WaveImg from "../../assets/wave.png";
import Confetti from "react-confetti";
import CongratsImg from "../../assets/CongratsOnRegistering.png";
// import useWindowSize from 'react-use/lib/useWindowSize';
import { Button } from "@mui/material";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import ShareIcon from '@mui/icons-material/Share';
import { Stack } from "@mui/material";
import { campLeaderBoard } from "../../redux/action/CampLeaderBoardAction";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ProfileNoPhoto from "../../assets/profileNoPhoto.png";
import RewardMainImage from "../../assets/rew.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { isEmpty, isArray } from "lodash";
import { Chip } from "@material-ui/core";
import CampaignLeaderBoardIcon from "../../assets/CampaignLeaderBoardIcon.jpeg"
import { FixedFooterIcon } from "../../components/FixedFooterIcon";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    padding: "80px 40px 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 40px 100px 330px",
    },
  },
  container: {
    padding: "0 20px 50px 20px",
    margin: "10px 0",
  },
  table: {
    width: "100%",
    // tableLayout: "fixed",
  },
  tableRow: {
    borderBottom: "1px solid #d3d3d3",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  thead: {
    borderBottom: "1px solid #d3d3d3",
    borderTop: "1px solid #d3d3d3",
    height: 60,
  },
  theadText: {
    fontWeight: "700",
    textAlign: "center",
    fontSize: 16,
    minWidth: 100,
  },
  tbodyText: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width: "200px",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // wordWrap: "breakWord",
    // overflow: "scroll",
  },
}));
export default function QRScreen(props) {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const locationPath = useLocation();
  const [campaignList, setCampaignList] = useState(null);
  const [newCampaignList, setNewCampaignList] = useState(null);

  const [campregisterlist, setCampRegisterList] = useState([]);
  const [myreferralurl, setmyreferralurl] = useState([]);
  const [share, setShare] = React.useState(false);
  const [marketplacelist, setmarketplacelist] = useState([]);
  const [SDGCampMapping, setSDGCampMapping] = React.useState([]);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const { isSuccess: rewardSuccess, response: rewardResponse } = useSelector(
    (state) => state.myReward
  );
  const { isSuccess: campLeaderBoardSuccess, response: campLeaderBoardResponse } =
    useSelector((state) => state.campLeaderBoard);

    const getSDGCampMapping = async () => {
      try {
        const res = await axios.get(`camp/sdg/mapping`);
        // setActivityList(res?.data);
        setSDGCampMapping(res?.data);
        return res;
      } catch (err) {
        throw err;
      }
    };

    useEffect(() => {
      getSDGCampMapping();
    }, []);

  const getCampaignDetails = async () => {
    try {
      // console.log("HIIIIII")
      const res = await axios.get(
        // `/vol_dashboard/camp/${location?.pathname.split("/")?.[2]}`

        `vol_dashboard/camp/${location?.pathname.split("/")?.[2]}/${profileResponse?.orgrole
        }/${profileResponse?.organization}`
      );
      setCampaignList(res?.data);
      // console.log(res?.data)
      return res;
    } catch (err) {
      throw err;
    }
  };
  const getCampRegistrationList = async () => {
    try {
      const res = await axios.get(
        `camp/reg/status/${localStorage.getItem("userid")}/${location?.pathname.split("/")?.[2]
        }`
      );
      setCampRegisterList(res?.data);
      // JSON.stringify(res)

      return res;
    } catch (err) {
      throw err;
    }
  };

  const getRewardsFromMarketplace = async () => {
    try {
      //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);
      const res = await axios
        .get(`/marketplace/read/${profileResponse?.phoneno}`)
        .then((response) => {

          setmarketplacelist(response);
          return response;
        });
      // setmarketplacelist(res?.data);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getRewardsFromMarketplace();
  }, []);

  useEffect(() => {
    // getQR();
    // getCampaignDetails();
    dispatch(userProfile(localStorage.getItem("userid")));
    // console.log("USEEFFECT")
  }, []);
  // console.log(campaignList)

  useEffect(() => {
    // getQR();
    // dispatch()
    getCampaignDetails();
    getCampRegistrationList();
    // dispatch(userProfile(localStorage.getItem("userid")));
    // console.log("USEEFFECT")
  }, [profileResponse]);

  const handleShare = async () => {
    // alert("Handle Share")
    try {
      // setDialogOpen(true);
      const res = await axios
        .get(
          `refrralurl/${location?.pathname.split("/")?.[2]
          }/${localStorage.getItem("userid")}`
        )
        .then((response) => {
          setmyreferralurl(response);

          return response;
        });
    } catch (err) {
      // setLoading(false);

      throw err;
    }
  };

  const getNewCampaignDetails = async () => {
    try {
      // console.log(profileResponse?.orgrole)
      await axios
        .get(
          // `/vol_dashboard/camp/${location?.pathname.split("/")?.[2]}`

          `vol_dashboard/camp/${location?.pathname.split("/")?.[2]}/${profileResponse?.orgrole
          }/${profileResponse?.organization}/${profileResponse?.phoneno}`
        )
        .then((response) => {
          setNewCampaignList(response);
          return response;
        });
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getNewCampaignDetails();
  }, []);
  useEffect(() => {
    // dispatch(campLeaderBoard(localStorage.getItem("userid"), locationPath?.pathname.split("/")?.[3]));
    dispatch(
      campLeaderBoard({
        phoneno: localStorage.getItem("userid"),
        campid: location?.pathname.split("/")?.[2],
      })
    );
    // dispatch(myRank(localStorage.getItem("userid")));
  }, [dispatch]);

  const options = {
    items: 3,
    margin: 10,
    loop: false,
    nav: false,
    autoplaySpeed: 900,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div style={{ boxShadow: "0px 10px 20px #0000001A", padding: 8, display: "flex", justifyContent: "space-between", flexDirection: "column", alignItems: "center" }} >
              {location?.pathname.split("/")?.[3].startsWith("Already") ? (
                <span
                  style={{
                    fontSize: 20,
                    padding: "20px 0%",
                    textAlign: "center",
                    color: "red",
                  }}
                >
                  You have already registered for these dates - Thank You
                </span>
              ) : location?.pathname.split("/")?.[3].startsWith("Reached") ? (
                <span
                  style={{
                    fontSize: 20,
                    padding: "20px 0%",
                    textAlign: "center",
                    color: "red",
                  }}
                >
                  Please be informed that the campaign has reached its goal.
                  Thank you. Please explore other campaigns and participate and
                  win points/rewards.
                </span>
              ) : location?.pathname.split("/")?.[3].startsWith("Time") ? (
                <span
                  style={{
                    fontSize: 20,
                    padding: "20px 0%",
                    textAlign: "center",
                    color: "red",
                  }}
                >
                  Please be informed that the campaign has completed. Thank you.
                  Please explore other campaigns and participate and win
                  points/rewards.
                </span>
              ) : _.isEmpty(campaignList) ? (
                <span
                  style={{
                    fontSize: 20,
                    padding: "20px 3%",
                    textAlign: "center",
                  }}
                >
                  Thank you for adopting!
                </span>
              ) : (
                <span style={{ fontSize: "32px", textAlign: "center" }}>
                  Thank you for adopting <u>{campaignList[0].name}</u> !
                </span>
              )}
            </div>
            <div style={{ boxShadow: "0px 10px 20px #0000001A", padding: 8, display: "flex", justifyContent: "space-between", flexDirection: "column", marginTop: 20, alignItems: "center" }} >
              <img src={ConfettiImage} style={{ width: 78, height: 78 }} />
              {
                !location?.pathname.split("/")?.[3].startsWith("Already") &&
                  !location?.pathname.split("/")?.[3].startsWith("Time") &&
                  !location?.pathname.split("/")?.[3].startsWith("Reached") ? (
                  <span
                    style={{
                      padding: "20px 0%",
                      textAlign: "center",
                      fontSize: 35,
                    }}
                  >
                    <img src={CongratsImg} class="congrats-logo" />
                  </span>

                ) : ""
              }
            </div>











            <div class="row" style={{ justifyContent: "center", marginTop: "15px" }}>
              <div class="col-md-4">
                <div class="cyagg-sec-space reg-camp-sec">
                  <div class="container">
                    <div>
                      <div class="campaigns-wrap campaigns-wrap-new">
                        <div>
                          <div>
                            {/* <div class="campaign-img">
                              <div class="video-wrapper">
                                <div class="video-container">
                                  {campaignList?.[0]?.video ? (
                                    <video
                                      controls
                                      id="video"
                                      preload="auto"
                                      poster={campaignList?.[0]?.logo}
                                      autoplay
                                    >
                                      <source
                                        src={campaignList?.[0]?.video}
                                        type="video/mp4"
                                      />
                                    </video>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div> */}
                            <h5 title={campaignList?.[0]?.name} class="campaign-title-new">
                              {campaignList?.[0]?.name}
                            </h5>

                            <div class="campaign-hashtags">
                              {campaignList?.[0]?.tag.map((tag) => (
                                <a class="hash-styles">{tag.tag}</a>
                              ))}
                            </div>
                          </div>
                        </div>


                        <div className={classes.campaigntypewrapper}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            gap={0.5}
                          >
                            <h5 class="campaign-goal">
                              <a
                                class="hash-styles-goal"
                                style={{
                                  padding:
                                    "0px 5px 0px 0px",
                                  cursor: "initial",
                                }}
                              >
                                <span style={{ fontSize: "16px", fontWeight: 700 }}>Goal</span>: <span class="text-6" style={{ color: "black" }}>{campaignList?.[0]?.goalquantity}{" "} {campaignList?.[0]?.unitofgoal}</span>
                              </a>
                            </h5>
                          </Stack>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <div>
                              <div class="campaigns-details">
                                <div class="row">

                                  <div class="col-md-4 col-6">
                                    <div class="cam-cols">
                                      <div class="timer-here">
                                        <div class="text-center">
                                          <span class="campaign-time-title">
                                            Earn upto
                                          </span>
                                        </div>
                                        <div>
                                          <ul id="countdown">
                                            <li id="hours">
                                              <div class="numberhome"></div>
                                              <div class="label"></div>
                                            </li>
                                            <li id="minutes">
                                              <div class="numberhome">
                                                {
                                                  campaignList?.[0]
                                                    ?.maxpointstobeearned
                                                }
                                              </div>
                                              <div class="label">Points</div>
                                            </li>
                                            <li id="seconds">
                                              <div class="numberhome"></div>
                                              <div class="label"></div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-md-6 col-6">
                                    <div class="cam-cols">
                                      <div class="timer-here">
                                        <div class="text-center">
                                          <span class="campaign-time-title">
                                            Time Left
                                          </span>
                                        </div>
                                        <div>
                                          <ul id="countdown">
                                            <li id="hours">
                                              <div class="number">
                                                {
                                                  campaignList?.[0]
                                                    ?.dayrem
                                                }
                                              </div>
                                              <div class="label">Days</div>
                                            </li>
                                            <li id="minutes">
                                              <div class="number">
                                                {
                                                  campaignList?.[0]
                                                    ?.hourrem
                                                }
                                              </div>
                                              <div class="label">Hrs</div>
                                            </li>
                                            <li id="seconds">
                                              <div class="number">
                                                {
                                                  campaignList?.[0]
                                                    ?.minrem
                                                }
                                              </div>
                                              <div class="label">Min</div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>


                                <div class="p-bar-new">
                                  <div class="row ">
                                    <div class="col-6">
                                      <div>
                                        <h6>
                                          <span style={{ color: "#AAAAAA" }}>Pledged: </span>{" "} <span style={{ backgroundColor: "#FFDB58" }}>
                                            {Math.round(
                                              (campaignList?.[0]?.committedcount)
                                            )}
                                          </span>
                                        </h6>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div
                                        style={{
                                          textAlign:
                                            "right",
                                        }}
                                      >
                                        <h6>
                                          <span style={{ color: "#AAAAAA" }}>Achieved: </span>
                                          {" "}
                                          <span style={{ backgroundColor: "#A9D18E" }}> {campaignList?.[0]?.achievedcount}</span>
                                          {" "}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="progress br-30 campaign-progress" style={{ position: "relative" }}>
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{
                                        width: `${(campaignList?.[0]?.committedcount / campaignList?.[0]?.goalquantity) * 100}%`,
                                        backgroundColor: "#FFDB58",
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        zIndex: 1,
                                        height: "100%"
                                      }}
                                      aria-valuenow={Math.round((campaignList?.[0]?.committedcount / campaignList?.[0]?.goalquantity) * 100)}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>

                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{
                                        width: `${(campaignList?.[0]?.achievedcount / campaignList?.[0]?.goalquantity) * 100}%`,
                                        backgroundColor: "#A9D18E",
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        zIndex: 2,
                                        height: "100%"
                                      }}
                                      aria-valuenow={Math.round((campaignList?.[0]?.achievedcount / campaignList?.[0]?.goalquantity) * 100)}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    ></div>
                                  </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                  <div style={{ flex: 1 }}>
                                    <h6>
                                      <span style={{ color: "#AAAAAA" }}>Signed Up:</span>{" "}
                                      <span style={{ backgroundColor: "#DAE8FD" }}>{campaignList?.[0]?.submittedcount}</span>
                                    </h6>
                                  </div>
                                  <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                    <Chip
                                      key={campaignList?.[0]?.campaigntype}
                                      fontSize="small"
                                      size="small"
                                      label={campaignList?.[0]?.campaigntype}
                                    />
                                    <img
                                      src={CampaignLeaderBoardIcon}
                                      style={{ width: 25, height: 25, cursor: "pointer", }}
                                      onClick={() =>
                                        navigate(
                                          `/camp/leaderboard/${campaignList?.[0]?.campid}`
                                        )
                                      }
                                    />
                                  </div>
                                </div>


                                {/* <div class="mr-15 text-center">
                                  <a class="campaign-signin">
                                    Join Now{" "}
                                    <i
                                      class="fa fa-arrow-right"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="cyagg-sec-space" style={{ marginTop: "15px" }}>
                  <div class="container">
                    <div class="row">
                      {/* <div class="col-md-6"> */}
                      <div class="text-center mob-space">
                        {/* <h5>Invite Friends</h5> */}
                        <h6>Please also share this campaign with your friends and win more points</h6>
                        <div class="navigationS">
                          <div
                            class="menuToggleS"
                            onClick={handleShare}
                          >
                            <ShareIcon style={{ color: "orange" }} />
                          </div>
                          <div class="menuS">
                            <ul>
                              <WhatsappShareButton
                                url={
                                  "Hello!" +
                                  "\n" +
                                  "I really liked this new SDP initiative. With SDP, we can participate in many social impact campaigns and win points and rewards. I am personally inviting you to participate in this campaign on SDP." +
                                  "\n" +
                                  "\n" +
                                  campaignList?.data?.[0]?.socialmediamsg +
                                  "\n" +
                                  "\n" +
                                  "Please use the link below and don't forget to enter your personal REFERRAL CODE " +
                                  " " +
                                  myreferralurl?.data?.refcode +
                                  " " +
                                  "when you participate in this campaign as that will win extra points for you!" +
                                  "\n" +
                                  myreferralurl?.data?.url
                                }
                              >

                                <WhatsappIcon
                                  size={32}
                                  round={true}
                                ></WhatsappIcon>
                              </WhatsappShareButton>

                              <FacebookShareButton
                                url={
                                  "Hello!" +
                                  "\n" +
                                  "I really liked this new SDP initiative. With SDP, we can participate in many social impact campaigns and win points and rewards. I am personally inviting you to participate in this campaign on SDP." +
                                  "\n" +
                                  "\n" +
                                  campaignList?.data?.[0]?.socialmediamsg +
                                  "\n" +
                                  "\n" +
                                  "Please use the link below and don't forget to enter your personal REFERRAL CODE " +
                                  " " +
                                  myreferralurl?.data?.refcode +
                                  " " +
                                  "when you participate in this campaign as that will win extra points for you!" +
                                  "\n" +
                                  myreferralurl?.data?.url
                                }
                                hashtag="#cyaag"
                              >
                                <FacebookIcon
                                  size={32}
                                  round={true}
                                ></FacebookIcon>
                              </FacebookShareButton>

                              <EmailShareButton
                                url=""

                                body={
                                  "Hello!" +
                                  "\n" +
                                  "I really liked this new SDP initiative. With SDP, we can participate in many social impact campaigns and win points and rewards. I am personally inviting you to participate in this campaign on SDP." +
                                  "\n" +
                                  "\n" +
                                  campaignList?.data?.[0]?.socialmediamsg +
                                  "\n" +
                                  "\n" +
                                  "Please use the link below and don't forget to enter your personal REFERRAL CODE " +
                                  " " +
                                  myreferralurl?.data?.refcode +
                                  " " +
                                  "when you participate in this campaign as that will win extra points for you!" +
                                  "\n" +
                                  myreferralurl.data?.url
                                }
                                subject="Invitation to cYAAG"
                              >
                                <EmailIcon size={32} round={true} />
                              </EmailShareButton>

                              <TwitterShareButton
                                url={
                                  "Hello!" +
                                  "\n" +
                                  "I am inviting you to participate in this campaign on cYAAG SDP." +
                                  "\n" +
                                  "\n" +
                                  campaignList?.data?.[0]?.socialmediamsg +
                                  "\n" +
                                  "\n" +
                                  "Please use the link below and enter your REFERRAL CODE " +
                                  " " +
                                  myreferralurl?.data?.refcode +
                                  " " +
                                  "as that will win extra points for you!" +
                                  "\n" +
                                  myreferralurl.data?.url
                                }
                              >
                                <TwitterIcon size={32} round={true}></TwitterIcon>
                              </TwitterShareButton>
                            </ul>
                          </div>
                        </div>

                        <p>
                          You can now use a Social Media tool of your choice to
                          invite individuals and win more points. We are providing
                          you a message that you can send as-is or edit before
                          sending.
                        </p>
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>









            <div class="row" style={{ justifyContent: "center", marginTop: "15px" }}>
              <div class="col-md-12">
                <div class="cyagg-sec-space reg-camp-sec">
                <div>
                  <div class="row">
                    <div class="col-7">
                      <div>
                        <h4 class="site-title" style={{"marginLeft":"15px"}}>Campaign Leaderboard!</h4>
                      </div>
                    </div>
                    
                  </div>
                </div>
                  <div class="container">
                    <div>
                      <div class="campaigns-wrap campaigns-wrap-new">
                        <div>
                          <div class="cyagg-sec-space">
                            <div class="container">
                              <div class="row">
                                {/* <div class="col-6"> */}
                                <div class="row" style={{ marginLeft: "30px", marginRight: "7px" }}>
                                  <div class="row">
                                    {/* <h4 class="site-title" style={{ color: "blue" }}>{campLeaderBoardResponse?.[0]?.campname}</h4> */}
                                    {/* <h4 class="site-title" style={{ color: "blue" }}>Campaign Leaderboard</h4> */}
                                    <div class="col-7">
                      
                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div class="row">
                                  <div
                                    class="col-md-4 hide-on-mobile"
                                    style={{ paddingRight: "0px" }}
                                  >
                                    <div class="leaderboard-left">
                                      <div class="text-center">
                                        <h5>Top Player</h5>
                                        {
                                          campLeaderBoardResponse?.[0]?.profilephoto != "" ? (
                                            <img
                                              src={campLeaderBoardResponse?.[0]?.profilephoto}
                                            ></img>
                                          ) : (
                                            <span>
                                              <AccountCircleIcon fontSize="large" />
                                            </span>
                                          )
                                        }
                                        <span style={{ fontSize: 36, padding: 24 }}>
                                          {campLeaderBoardResponse &&
                                            campLeaderBoardResponse?.[0]?.firstname}{" "}
                                          {campLeaderBoardResponse?.[0]?.lastname}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-8 col-12" style={{ paddingLeft: "0px" }}>
                                    <div class="u-custom-scrollbar-one-camp">
                                      <table class="leader-b-table">
                                        <thead>
                                          <tr>
                                            <th scope="col" style={{ textAlign: "center" }}>
                                              {" "}
                                              Rank
                                            </th>
                                            <th scope="col" style={{ textAlign: "center" }}>
                                              Top Player Name
                                            </th>

                                            <th scope="col" style={{ textAlign: "center" }}>
                                              Total Points
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {campLeaderBoardSuccess &&
                                            campLeaderBoardResponse?.map((row) => (
                                              <tr>
                                                <td
                                                  data-label="Account"
                                                  style={{ textAlign: "center" }}
                                                >
                                                  {row?.rank}
                                                </td>
                                                <td data-label="Due Date">
                                                  <div class="top-player-box">
                                                    <div class="top-player-box" style={{ textAlign: "center" }}>
                                                      {row?.profilephoto != "" ? (
                                                        <img src={row?.profilephoto}></img>
                                                      ) : (
                                                        <img src={ProfileNoPhoto}></img>
                                                      )}
                                                    </div>
                                                    <div class="top-player-box" style={{ textAlign: "center" }}>

                                                      {row?.firstname === "ME" ? (
                                                        <span
                                                          style={{
                                                            color: "orange",
                                                            fontWeight: "bold",
                                                          }}
                                                        >
                                                          {row?.firstname}
                                                        </span>
                                                      ) : (
                                                        <span>{row?.firstname}</span>
                                                      )}
                                                    </div>
                                                  </div>
                                                </td>

                                                <td
                                                  data-label="Period"
                                                  style={{ textAlign: "center" }}
                                                >
                                                  {row?.rewardpoint}
                                                </td>
                                              </tr>
                                            ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="container">
              <div class="cyagg-sec-space">
                <div>
                  <div class="row">
                    <div class="col-7">
                      <div>
                        <h4 class="site-title">Win Exciting Rewards!</h4>
                      </div>
                    </div>
                    <div class="col-5">
                      <div
                        style={{ textAlign: "right" }}
                        onClick={() => navigate(`/marketplace`)}
                      >
                        <a class="story-signin">
                          View More <i class="fa fa-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4">
                    <div>
                      <img
                        class="hide-on-mobile"
                        src={RewardMainImage}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  <div class="col-md-8">
                    <div class="">
                      {/* <div class="reward-slide owl-carousel owl-theme "> */}
                      <OwlCarousel className="owl-theme" {...options}>
                        {marketplacelist &&
                          marketplacelist?.data?.map(
                            (reward, index) =>
                              index < 5 && (
                                <div class="item">
                                  <div class="reward-wrap">
                                    <div>
                                      <img
                                        class="reward-image"
                                        src={reward?.logo}
                                        style={{ width: "100%" }}
                                      />
                                    </div>
                                    <div class="reward-details">
                                      <div>
                                        <div>
                                          <div id="toggleAccordion">
                                            <div class="card mb-1">
                                              <div
                                                class="card-header"
                                                id="headingOne4"
                                              >
                                                <h5 class="mb-0 mt-0 reward-desc-title">
                                                  <span
                                                    role="menu"
                                                    class=""
                                                    data-toggle="collapse"
                                                    data-target={"#" + index}
                                                    aria-expanded="true"
                                                    aria-controls="iconChangeAccordionOne"
                                                  >
                                                    {reward?.rewardtype}
                                                    <i class="flaticon-down-arrow float-right"></i>
                                                  </span>
                                                </h5>
                                              </div>

                                              <div
                                                id={index}
                                                class="collapse"
                                                aria-labelledby="headingOne4"
                                                data-parent="#toggleAccordion"
                                              >
                                                <div class="card-body">
                                                  <p class="mb-3">
                                                    <div
                                                      className={
                                                        classes.subtitleWrapper
                                                      }
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 12,
                                                          color: "blue",
                                                        }}
                                                      >
                                                        {" "}
                                                        {reward?.subtitle}{" "}
                                                      </span>
                                                    </div>
                                                  </p>
                                                  <p class="mb-3">
                                                    <div
                                                      className={
                                                        classes.subtitleWrapper
                                                      }
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 12,
                                                          color: "green",
                                                        }}
                                                      >
                                                        {" "}
                                                        {reward?.title}{" "}
                                                      </span>
                                                    </div>
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="redeem-pts">
                                          <div class="row">
                                            {/* <div class="col-6">
                                              {reward?.rewardcat ===
                                                "rewards" &&
                                              reward?.campid === "camp00001" ? (
                                                <div class="re-po-left">
                                                  <img src="https://png.pngtree.com/png-clipart/20220213/original/pngtree-coins-stack-icon-vector-png-image_7267762.png" />
                                                  <div class="pts">
                                                    <h5>{reward?.points}</h5>
                                                    <span>Points</span>
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div> */}
                                            {/* <div class="col-6">
                                              <div class="re-po-right">
                                                <a>Redeem</a>
                                              </div>
                                            </div> */}
                                          </div>
                                        </div>
                                        <div class="text-center">
                                          <h6 class="sponsor-title">
                                            Sponsors
                                          </h6>
                                          <div class="row sponserWrapper">
                                            <div class="">
                                              {isArray(reward?.sponsor) &&
                                                reward?.sponsor?.map(
                                                  (reward, index) => (
                                                    <img
                                                      style={{
                                                        width: "auto",
                                                        height: "50px",
                                                      }}
                                                      src={reward?.sponsorlogo}
                                                    />
                                                    // <div class="col-3">
                                                    //     <img style={{width:"100%"}} src="assets/img/sponsor.png"/>
                                                    // </div>
                                                    // <div class="col-3">
                                                    //     <img style={{width:"100%"}} src="assets/img/sponsor.png"/>
                                                    // </div>
                                                    // <div class="col-3">
                                                    //     <img style={{width:"100%"}} src="assets/img/sponsor.png"/>
                                                    // </div>
                                                  )
                                                )}
                                            </div>
                                          </div>
                                        </div>

                                        {/* <div>
                                        <img
                                          src="assets/img/won-rewards.jpg"
                                          class="won-re-img"
                                        />
                                      </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        {/* </div> */}
                      </OwlCarousel>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <hr />







            <div class="row" style={{ justifyContent: "center", marginTop: "15px" }}>
             
              <div class="col-md-5" style={{marginTop:"20px"}}>
                <div class="cyagg-sec-space reg-camp-sec" style={{ border: "2px solid #000", padding: "10px", borderRadius: "15px", borderColor: "lightgray" }}>
                  <div class="container">
                    <div style={{textAlign:"center"}}>
                      <Button
                        // variant="contained"
                        // onClick={navigate(`/home`)}
                        // className="btn home-btn"
                        disableElevation
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={`/home`}
                        >
                          <a class="story-post-reg">
                            
                          
                            {/* <i title="Home" class="flaticon-home-line"></i> */}
                            <i title="Home" class="flaticon-home-fill-x"></i>
                          
                            {" "} Home
                          </a>


                        </span>
                      </Button>
                      <div>
                        <span>Please find out more campaigns by clicking on Home</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>















              <div class="col-md-5" style={{marginTop:"20px", marginBottom:"72px"}}>
                <div class="cyagg-sec-space reg-camp-sec" style={{ border: "2px solid #000", padding: "10px", borderRadius: "15px", borderColor: "lightgray" }}>
                  <div class="container">
                    <div style={{textAlign:"center"}}>
                      <Button
                        onClick={() => navigate(`/campaignregisterstatus`)}
                        
                      >
                        
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={`/campaignregisterstatus`}
                        >
                          <a class="story-post-reg">
                            
                            <i title="Reg" class="flaticon-notes-1"></i>
                           
                            {" "} Registration Status
                          </a>
                        </span>
                      </Button>
                      <div>
                        <span>Please click here to see your current registration status.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>




            {/* <span style={{ padding: "20px 0%", textAlign: "center", fontSize: 20 }} >
              {!location?.pathname.split("/")?.[3].startsWith("Reached") ||
                !location?.pathname.split("/")?.[3].startsWith("Time") &&
                (_.isEmpty(campaignList) ? (
                  <ul> You are just a step away from winning points. </ul>
                ) : (
                  <ul>
                    {" "}
                    You are just a step away from winning{" "}
                    {parseInt(campaignList[0].buylvlpt1, 0)} points.{" "}
                  </ul>
                ))}
              {!location?.pathname.split("/")?.[3].startsWith("Reached") ||
                !location?.pathname.split("/")?.[3].startsWith("Time") && (
                  <ul>
                    You will receive the points on successful participation in
                    the campaign
                  </ul>
                )}

              <ul>
                Please check{" "}

                <Button
                  onClick={() => navigate(`/campaignregisterstatus`)}
                  className={classes.backBtn}
                >
                  <span style={{ textTransform: "" }}>Campaign Participation Status</span>
                </Button>
                to see your current status.
              </ul>

              <div class="text-center mob-space-share">
                <ul>
                  Please also share this campaign with your friends and win
                  more points
                </ul>

                <div class="navigationS">
                  <div class="menuToggleS" onClick={handleShare}><ShareIcon style={{ color: "orange" }} /></div>
                  <div class="menuS">
                    <ul>
                      <WhatsappShareButton
                        url={
                          "Hello!" +
                          "\n" +
                          "I really liked this new SDP initiative. With SDP, we can participate in many social impact campaigns and win points and rewards. I am personally inviting you to participate in this campaign on SDP." +
                          "\n" +
                          "\n" +
                          newCampaignList?.data?.[0]?.socialmediamsg +
                          "\n" +
                          "\n" +
                          "Please use the link below and don't forget to enter your personal REFERRAL CODE " +
                          " " +
                          myreferralurl?.data?.refcode +
                          " " +
                          "when you participate in this campaign as that will win extra points for you!" +
                          "\n" +
                          // myreferralurl.data?.referralurl
                          myreferralurl.data?.url
                        }
                      >
                        <WhatsappIcon size={32} round={true}></WhatsappIcon>
                      </WhatsappShareButton>

                      <FacebookShareButton
                        url={
                          "Hello!" +
                          "\n" +
                          "I really liked this new SDP initiative. With SDP, we can participate in many social impact campaigns and win points and rewards. I am personally inviting you to participate in this campaign on SDP." +
                          "\n" +
                          "\n" +
                          newCampaignList?.data?.[0]?.socialmediamsg +
                          "\n" +
                          "\n" +
                          "Please use the link below and don't forget to enter your personal REFERRAL CODE " +
                          " " +
                          myreferralurl?.data?.refcode +
                          " " +
                          "when you participate in this campaign as that will win extra points for you!" +
                          "\n" +
                          myreferralurl.data?.url
                        }
                        hashtag="#cyaag"
                      >
                        <FacebookIcon size={32} round={true}></FacebookIcon>
                      </FacebookShareButton>

                      <EmailShareButton
                        url=""
                        body={
                          "Hello!" +
                          "\n" +
                          // referralUrlResponse.referralurl +
                          // myreferralurl.referralurl +
                          "I really liked this new SDP initiative. With SDP, we can participate in many social impact campaigns and win points and rewards. I am personally inviting you to participate in this campaign on SDP." +
                          "\n" +
                          "\n" +
                          newCampaignList?.data?.[0]?.socialmediamsg +
                          "\n" +
                          "\n" +
                          "Please use the link below and don't forget to enter your personal REFERRAL CODE " +
                          " " +
                          myreferralurl?.data?.refcode +
                          " " +
                          "when you participate in this campaign as that will win extra points for you!" +
                          "\n" +
                          // myreferralurl.data?.referralurl
                          myreferralurl.data?.url
                        }
                        subject="Invitation to cYAAG"
                      >
                        <EmailIcon size={32} round={true} />
                      </EmailShareButton>

                      <TwitterShareButton
                        url={
                          "Hello!" +
                          "\n" +
                          "I am inviting you to participate in this campaign on SDP." +
                          "\n" +
                          "\n" +
                          newCampaignList?.data?.[0]?.socialmediamsg +
                          "\n" +
                          "\n" +
                          "Please use the link below and enter your REFERRAL CODE " +
                          " " +
                          myreferralurl?.data?.refcode +
                          " " +
                          "as that will win extra points for you!" +
                          "\n" +
                          myreferralurl.data?.url
                        }
                        title={"Hello"}
                      >
                        <TwitterIcon size={32} round={true}></TwitterIcon>
                      </TwitterShareButton>
                    </ul>
                  </div>
                </div>
              </div>
            </span> */}
            <img
              src={WaveImg}
              style={{ width: "100%" }}
              class="congrats-abs"
            ></img>
            {/* </div> */}
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}
