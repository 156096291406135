/** @format */

const UPDATE_USER_FETCHING_DATA = "UPDATE_USER_FETCHING_DATA";
const UPDATE_USER_FETCHING_DATA_FAILURE = "UPDATE_USER_FETCHING_DATA_FAILURE";
const UPDATE_USER_FETCHING_DATA_RESET = "UPDATE_USER_FETCHING_DATA_RESET";
const UPDATE_USER_FETCHING_DATA_SUCCESS = "UPDATE_USER_FETCHING_DATA_SUCCESS";
const UPDATE_USER_FETCHING_DATA_ATTEMPT = "UPDATE_USER_FETCHING_DATA_ATTEMPT";
const updateUser = (payload) => ({
  type: UPDATE_USER_FETCHING_DATA_ATTEMPT,
  payload: payload,
});
const updateUserReset = () => ({
  type: UPDATE_USER_FETCHING_DATA_RESET,
});

export {
  UPDATE_USER_FETCHING_DATA,
  UPDATE_USER_FETCHING_DATA_FAILURE,
  UPDATE_USER_FETCHING_DATA_RESET,
  UPDATE_USER_FETCHING_DATA_SUCCESS,
  UPDATE_USER_FETCHING_DATA_ATTEMPT,
  updateUser,
  updateUserReset,
};
