import * as React from "react";
import FormControl from "@mui/material/FormControl";
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import {
  Avatar,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from 'multiselect-react-dropdown';

// import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {
  focusAreaList,
  focusAreaDict,
  registeredAs,
  currencyType,
} from "../../utils/dict";

import {
  onboardActivityReset,
  onboardBulkReset,
  onboardCampaign,
  onboardCampaignReset,
  onboardIndividualReset,
  onboardOrganization,
  onboardOrganizationReset,
} from "../../redux/action/OnboardAction";
import {
  updateCampaign,
  updateCampaignReset,
} from "../../redux/action/UpdateAction";

import CallingCode from "../../services/CallingCode";
import { fetchCityAndState } from "../../services/commonServices";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";
import { API_URL } from "../../services/Constants";
import { getOrganization } from "../../redux/action/getOrganization";
import {
  activityDuration,
  campaignParticipate,
  campaignType,
  restrictedCountry,
} from "../../utils/dict";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AddCircleOutlineOutlined, RemoveCircleOutlineOutlined } from "@mui/icons-material";
import { getCity } from "../../redux/action/cityAction";
import { MenuProps, states } from "../../utils/constants";
import ErrorMessage from "../../components/ErrorMessage";
import _ from "lodash";
import RedStar from "../../components/RedStar";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link } from "react-router-dom";
import { getCampProfileLogo } from "../../redux/action/campLogoAction";
import IconButton from "@mui/material/IconButton";
import { getCampProfileBanner } from "../../redux/action/campBannerAction";
import { getRewardTitle } from "../../redux/action/RewardDetailAction";
// import { LocalConvenienceStoreOutlined } from "@material-ui/icons";
import { getCampProfileVideo } from "../../redux/action/campVideoAction";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {

    },

    display: "block",
    display: "flex",
    //fontSize: 16,
    fontSize: "16px",
    marginTop: 16,
    padding: 10,
    fontWeight: "500",
    lineHeight: "18px",
    minHeight: 50,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {

    },

    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroupFull: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%"
    },
    [theme.breakpoints.only("md")]: {
      width: "100%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
}));

function EditCampaignScreen(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [image, setImage] = React.useState(null);
  const [banner, setBanner] = React.useState(null);
  const [video, setVideo] = React.useState(null);
  const inputRef = React.useRef(null);
  const videoRef = React.useRef(null);
  const navigate = useNavigate();
  
  const [newField, setNewField] = React.useState(false);
  const [newRewardField, setNewRewardField] = React.useState(false);
  const [rewardDetailResponseEffect, setrewardDetailResponseEffect] = React.useState({});

  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "" },
  ]);

  const [rewardtitle, setRewardtitle] = React.useState([
    { rewardtype: "", title: "", subtitle: "", sponsor: "", points: "", titleList: "" },
  ]);

  const [focusArea, setFocusArea] = React.useState([
    {
      rewards: "Certificate of Participation",
    },
    {
      rewards: "Discount Coupons",
    },
    { rewards: "Gift Cards" },
    { rewards: "Social Media Recognition" },
  ]);

  // const skillArea= [
  //   { skill: "Networking" },
  //   { skill: "Fundraising" },
  //   { skill: "Operational Support" },
  //   { skill: "Content Writing / Editing" },
  //   { skill: "Social Media and Digital marketing" },
  //   { skill: "Team Management" },
  //   { skill: "Painting / Drawing / Sculpture making" },
  //   { skill: "Photography" },
  //   { skill: "Video Editing" },
  //   { skill: "Coaching / Mentoring" },
  //   { skill: "pp Development / Coding" },

  // ];

  const [skill, setSkill] = React.useState([
    { skill: "Networking", },
    { skill: "Fundraising", },
    { skill: "Operational Support" },
    { skill: "Content Writing / Editing" },
    { skill: "Social Media and Digital marketing" },
    { skill: "Team Management" },
    { skill: "Painting / Drawing / Sculpture making" },
    { skill: "Photography" },
    { skill: "Video Editing" },
    { skill: "Coaching / Mentoring" },
    { skill: "pp Development / Coding" },

  ]);
  const [focusError, setFocusError] = React.useState(false);

  const [areas, setAreas] = React.useState([]);
  const [cityResponse, setCityResponse] = React.useState({});
  const [notificationShow, setNotificationShow] = React.useState(false);
  const [activityList, setActivityList] = React.useState(undefined);
  const [campaignValues, setCampaignValues] = React.useState({
    titleOfCampaign: "",
    descriptionOfCampaign: "",
    typeOfCampaign: "",
    volunteerCount: "",
    // impact: "",
    startDate: new Date(),
    endDate: new Date(),
    durationOfCampaign: "",
    rewardType: "",
    goal: "",
    campaignType: "",
    hourOfParticipation: "",
    pointsToCurrency: "",
    totalBudget: "",
    pointsPerReferral: "",
    budgetPointForReferral: "",
    socialMediaMessage: "",
    campaignLogo: "",
    campaignVideo: "",
    visibility: "",
    currencyType: "",
  });
  const [campaignValuesError, setCampaignValuesError] = React.useState({
    titleOfCampaignError: "",
    descriptionOfCampaignError: "",
    typeOfCampaignError: "",
    volunteerCountError: "",
    impactError: "",
    startDate: new Date(),
    endDate: new Date(),
    durationOfCampaignError: "",
    rewardTypeError: "",
    goalError: "",
    campaignTypeError: "",
    hourOfParticipationError: "",
    pointsToCurrencyError: "",
    totalBudgetError: "",
    pointsPerReferralError: "",
    budgetPointForReferralError: "",
    socialMediaMessageError: "",
    campaignLogoError: "",
    campaignVideoError: "",
    rewardTypeError: "",
    visibilityError: "",
    currencyTypeError: "",
  });

  const [selected, setSelected] = React.useState([]);
  const [selected1, setSelected1] = React.useState([]);
  const [skillselected, setSkillSelected] = React.useState([]);
  // const {
  //   isCampaignSuccess,
  //   isCampaignFetching,
  //   campaignResponse,
  //   isCampaignError,
  // } = useSelector((state) => state.onBoard);
  const {
    isCampaignSuccess,
    isCampaignFetching,
    campaignResponse,
    isCampaignError,
  } = useSelector((state) => state.updateCampaign);

  const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
    (state) => state.cities
  );

  const { titleSuccess: rewardDetailSuccess, titleResponse: rewardDetailResponse } = useSelector(
    (state) => state.getRewardTitle
  );
  
  function subtractYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);

    return date;
  }

  /* For profile image handling */

  const [loading, setLoading] = React.useState(false);
  const [loadingBanner, setLoadingBanner] = React.useState(false);
  const [loadingVideo, setLoadingVideo] = React.useState(false);
  // const [image, setImage] = React.useState(null);
  const [show, setShow] = React.useState(false);

  const {
    isSuccess: profileImageSuccess,
    response: profileImageResponse,
    isError: profileImageError,
  } = useSelector((state) => state.profileImage);

  const {
    isSuccess: campLogoSuccess,
    response: campLogoResponse,
    isError: campLogoError,
  } = useSelector((state) => state.getCampProfileLogo);

  const {
    isSuccess: campBannerSuccess,
    response: campBannerResponse,
    isError: campBannerError,
  } = useSelector((state) => state.getCampProfileBanner);

  const {
    isSuccess: campVideoSuccess,
    response: campVideoResponse,
    isError: campVideoError,
  } = useSelector((state) => state.getCampProfileVideo);

  const handleChangeFile = (e) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("logo", e.target.files[0]);
    dispatch(
      getCampProfileLogo({ campid: locationPath?.state?.campid, userId: localStorage.getItem("userid"), formData })

    );

    setImage(e.target.files[0]);
  };
  const handleBannerFile = (e) => {
    setLoadingBanner(true);
    let formData = new FormData();
    formData.append("banner", e.target.files[0]);

    dispatch(
      getCampProfileBanner({ campid: locationPath?.state?.campid, userId: localStorage.getItem("userid"), formData })
    );
    setBanner(e.target.files[0]);
  };

  const handleChangeVideoFile = (e) => {
    setLoadingVideo(true);
    let formData = new FormData();
    formData.append("video", e.target.files[0]);
    dispatch(
      getCampProfileVideo({ campid: locationPath?.state?.campid, userId: localStorage.getItem("userid"), formData })

    );

    setVideo(e.target.files[0]);
  };

  const handleChangeRewards = (event, index) => {
    const value = event.target.value;

    setSelected([...selected, value[value?.length - 1]]);
  };

  const handleChangeRewards1 = (id, event, field, index) => {
    // const value = event.tgetRewardDetailListarget.value;
    const value = [...rewardtitle];
    if (field === 'rewardtype') {
      value[index]["titleList"] = rewardDetailResponseEffect;
    }
    value[index][field] = event.target.value;
    setRewardtitle(value);

    // setSelected([...selected, value[value?.length - 1]]);
  };

  const handleChangeSkill = (event, index) => {
    const value = event.target.value;

    setSelected1([...selected1, value[value?.length - 1]]);
  };

  const handleOnboardCampaign = () => {
    // if (!campaignValues?.titleOfCampaign) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     titleOfCampaignError: true,
    //   });
    //   window.scrollTo(0, 0);

    // } else if (!campaignValues?.descriptionOfCampaign) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     descriptionOfCampaignError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else if (!campaignValues?.goal) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     goalError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else if (!campaignValues?.typeOfCampaign) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     typeOfCampaignError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } 
    // else if (!campaignValues?.campaignType) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     campaignTypeError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else if (!campaignValues?.visibility) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     campaignParticipateError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else if (!campaignValues?.volunteerCount) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     volunteerCountError: true,
    //   });
    //   window.scrollTo(0, 0);
    // }  else if (!campaignValues?.hourOfParticipation) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     hourOfParticipationError: true,
    //   });
    //   window.scrollTo(0, 0);
    // }
    // else if (selected.filter((item) => item).length === 0) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     rewardTypeError: true,
    //   });
    //   window.scrollTo(0, 0);
    // }  

    // else if (selected1.filter((item) => item).length === 0) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     setSkillError: true,
    //   });
    //   window.scrollTo(0, 0);
    // }
    // else if (!campaignValues?.socialMediaMessage) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     socialMediaMessageError: true,
    //   });
    //   window.scrollTo(0, 0);
    // }  else if (!campaignValues?.campaignLogo) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     campaignLogoError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else {
    // dispatch(updateCampaignReset());
    let formData = new FormData();
    // console.log(locationPath)
    // console.log(campaignValues)

    formData.append("type", campaignValues?.typeOfCampaign);
    formData.append("title", campaignValues?.titleOfCampaign);
    formData.append("desc", campaignValues?.descriptionOfCampaign);
    formData.append("location", JSON.stringify(location));
    formData.append("hourofparticipation", campaignValues?.hourOfParticipation);
    formData.append("pointtocurrency", campaignValues?.pointsToCurrency);
    formData.append("totalbudget", campaignValues?.totalBudget);
    formData.append("pointsperreferral", campaignValues?.pointsPerReferral);
    formData.append("budgetpointforreferral", campaignValues?.budgetPointForReferral);
    formData.append("campaigntype", campaignValues?.campaignType);
    formData.append("visibility", campaignValues?.visibility);
    formData.append("currencytype", campaignValues?.currencyType);
    formData.append("goal", campaignValues?.goal);
    // formData.append("rewardstobeearned", JSON.stringify(selectedRewards));
    formData.append("rewardstobeearned", JSON.stringify(rewardtitle));
    // formData.append("skill", JSON.stringify(selected1));
    formData.append("skill", JSON.stringify(selectedSkills))
    // console.log(selectedRewards)
    // if (!campaignValues?.campaignLogo) {
    //   formData.append("logo", locationPath?.state?.logo);

    // } else {
    //   formData.append("logo", campaignValues?.campaignLogo);
    // }
    // if (!campaignValues?.campaignVideo) {
    //   formData.append("logo", locationPath?.state?.video);
    // } else {
    //   formData.append("video", campaignValues?.campaignVideo);

    // }
    //formData.append("impact", campaignValues?.impact);
    formData.append("video", locationPath?.state?.video);
    formData.append("targetvol", campaignValues?.volunteerCount);
    // formData.append("startdate", moment(campaignValues?.startDate, "YYYY-MM-DD") + " 00:00:00");
    // formData.append("startdate", campaignValues?.startDate + " 00:00:00");
    // formData.append("enddate", campaignValues?.endDate + " 00:00:00");
    formData.append("startdate", moment(campaignValues?.startDate).format("yyyy-MM-DD") + " 00:00:00");
    formData.append("enddate", moment(campaignValues?.endDate).format("yyyy-MM-DD") + " 00:00:00");

    // formData.append("enddate", moment(campaignValues?.endDate, "YYYY-MM-DD") + " 00:00:00");
    //formData.append("timeframe", campaignValues?.durationOfCampaign);
    formData.append("socialmediamsg", campaignValues?.socialMediaMessage);
    dispatch(
      updateCampaign({ campid: campaignValues?.campid, formData })
      // onboardCampaign({ userid: localStorage.getItem("userid"), formData })
    );
    // console.log(campaignValues)
    //  }
  };
  

  // console.log(locationPath)
  // console.log(campaignValues?.startDate)

  // const handleSave = async () => {
  //   try {
  //     const res = await axios.put(`/campaign/update`, {

  //       title: campaignValues?.titleOfCampaign,
  //       desc: campaignValues?.descriptionOfCampaign,
  //       type: campaignValues?.typeOfCampaign,
  //       targetvol: campaignValues?.volunteerCount,
  //       campid: locationPath?.state?.campid,
  //       // impact: "",
  //       startDate: moment(campaignValues?.startDate).format("yyyy-MM-DD") + " 00:00:00",
  //       endDate: moment(campaignValues?.endDate).format("yyyy-MM-DD") + " 00:00:00",
  //       // endDate: new Date(),
  //       // durationOfCampaign: "",
  //       rewardstobeearned: JSON.stringify(selected),
  //       goal: campaignValues?.goal,
  //       campaigntype: campaignValues?.campaignType,
  //       hourofparticipation: campaignValues?.hourOfParticipation,
  //       socialmediamsg: campaignValues?.socialMediaMessage,
  //       // campaignLogo: "",
  //       // campaignVideo: "",
  //       visibility: campaignValues?.visibility,
  //       // fullname: values?.fullname,
  //       // dob: moment(values?.dob).format("DD-MM-yyyy"),
  //       // //dob: moment(values?.dob).format("yyyy-MM-yyyy"),
  //       // phoneno: values?.phoneNumber,
  //       // correspondenceaddress: values?.correspondenceAddress,
  //       // emailid: values?.emailId,
  //       // city: values?.city,
  //       // state: values?.state,
  //       // organization: values?.organization,
  //       // highlvledu: values?.highLevelEdu,
  //       // preferday: values?.preferDays,
  //       // hoursperweek: values?.hoursPerWeek,
  //       // // interest: values?.interest,
  //       // interestarea: selectedInterest,
  //       // orgrole: values?.orgRole,
  //       // bloodgroup: values?.bloodGroup,
  //       // //skill: values.skill,
  //       // skill: selectedSkills,
  //     });
  //     //   setResponse(res?.data);
  //     setCampaignValues(res?.data);
  //     return res;
  //   } catch (e) {
  //     console.log("error", e);
  //   }
  // };


  const handleCampaignFieldChange = (field, e) => {
    (isCampaignError || isCampaignSuccess) && dispatch(onboardCampaignReset());
    setCampaignValues({
      ...campaignValues,
      [field]:
        field === "startDate" || field === "endDate"
          ? e
          : field === "campaignLogo" || field === "campaignVideo"
            // : field === "campaignLogo"
            ? e.target.files[0]
            : e.target.value,
    });
  };
  const getActivityList = async () => {
    try {
      const res = await axios.get(`/activity`);
      setActivityList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getStateList = async (i, valll) => {
    try {
      const res = await axios.get(`/statecity/${valll}`).then((response) => {
        const values = [...location];
        values[i]["cityList"] = response?.data;
        return response?.data;
      });

    } catch (err) {
      throw err;
    }
  };

  const getRewardDetailList = async (i, valll) => {
    try {
      const res = await axios.get(`/getrewardtitle/${valll}`).then((response) => {
        const values = [...rewardtitle];
        values[i]["titleList"] = response?.data;
        return response?.data;
      });

    } catch (err) {
      throw err;
    }
  };

  const unitOfCampaign = activityList?.filter(
    (item, index) => item?.activity === campaignValues?.typeOfCampaign
  );

  //  const unitOfCampaign = activityList?.filter(
  //   (item, index) => item?.activity === campaignValues?.typeOfCampaign
  // );

  // const resetFile = (event) => {
  //   inputRef.current.value = null;
  //   // inputRef.current.value = locationPath?.state?.logo;
  //   // inputRef.current.value = campaignValues?.campaignLogo;
  // videoRef.current.value = null;
  // };

  const onAddingItem = (event, option, index) => {
    const values = [...focusArea];
    values[index].isChecked = event.target.checked;
    setFocusArea(values);
  };

  const handleTextFieldCheckbox = (event, index) => {
    const values = [...focusArea];
    values[index].rewards = event.target.value;
    setFocusArea(values);
  };

  // const handleTextFieldCheckboxSkill = (event, index) => {
  //   const values = [...skill];
  //   values[index].skill = event.target.value;
  //   setFocusArea(values);
  // };

  // const handleChangeLocation = (id, e, field, index) => {
  //   const values = [...location];
  //   values[index][field] = e.target.value;
  //   setLocation(values);
  // };

  const handleChangeLocation = (id, e, field, index) => {
    const values = [...location];
    if (field === 'state') {
      values[index]["cityList"] = cityResponse;
    }

    values[index][field] = e.target.value;
    setLocation(values);
  };

  const handleAddFields = () => {
    setNewField(true);
    setLocation([...location, { country: "", state: "", city: "", area: "" }]);
  };

  const handleRewardAddFields = () => {
    setNewRewardField(true);
    // setLocation([...location, { country: "", state: "", city: "", area: "", cityList: "" }]);
    setRewardtitle([...rewardtitle, { rewardtype: "", title: "", subtitle: "", sponsor: "", points: "", titleList: "" }])
  };

  const handleRemoveFields = (i) => {
    let newFormValues = [...location];
    newFormValues.splice(i, 1);
    setLocation(newFormValues);
  };

  const handleRewardsRemoveFields = (i) => {
    let newRewardFormValues = [...rewardtitle];
    newRewardFormValues.splice(i, 1);
    setRewardtitle(newRewardFormValues);
  };

  // React.useEffect(() => {
  //   // console.log(campaignValues?.city)
  //   // console.log(campaignValues)
  //   // console.log(locationPath?.state?.location?.[0]?.city)
  //   if (locationPath?.state?.location?.[0]?.city) {
  //     dispatch(getCity(locationPath?.state?.location?.[0]?.state));

  //   }
  //   // }, [values?.city]);
  // }, []);


  // React.useEffect(() => {
  //   setLocation([{ country: "", state: "", city: "", area: "", cityList: "" }]);
  //   setRewardtitle([{ rewardtype: "", title: "", subtitle: "", sponsor: "", points: "", titleList: "" }])
  // }, []);

  React.useEffect(() => {
    if (campLogoSuccess || campLogoError) {
      setLoading(false);
    }
  }, [campLogoSuccess, campLogoError]);

  React.useEffect(() => {
    if (campBannerSuccess || campBannerError) {
      setLoadingBanner(false);
    }
  }, [campBannerSuccess, campBannerError]);

  React.useEffect(() => {
    if (campVideoSuccess || campVideoError) {
      setLoadingVideo(false);
    }
  }, [campVideoSuccess, campVideoError]);

//   React.useEffect(() => {
//     if (locationPath?.state) {
//       setCampaignValues({
//         titleOfCampaign: locationPath?.state?.title,
//         descriptionOfCampaign: locationPath?.state?.desc,
//         typeOfCampaign: locationPath?.state?.type,
//         volunteerCount: locationPath?.state?.targetvol,
//         // impact: locationPath?.state?.impact,
//         visibility: locationPath?.state.visibility,
//         startDate: moment(locationPath?.state?.startdate).format("YYYY-DD-MM"),
//         endDate: moment(locationPath?.state?.endDate).format("YYYY-DD-MM"),
//         durationOfCampaign: locationPath?.state?.timeframe,
//         goal: locationPath?.state?.goal,
//         campaignType: locationPath?.state?.campaigntype,
//         hourOfParticipation: locationPath?.state?.hourofparticipation,
//         socialMediaMessage: locationPath?.state?.socialMediaMessage,
//         campaignLogo: locationPath?.state?.logo,
//         campaignVideo: locationPath?.state?.video,
//         currencyType: locationPath?.state?.currencyType,
//       });
//       setLocation(locationPath?.state?.location);
//       // setFocusArea(JSON.parse(locationPath?.state?.rewardstobeearned))
//     }
//   // }, [locationPath?.state]);
// }, []);

// console.log(locationPath);


  const getAddress = (item, index) => {
   
    return (
      <div style={{ float: "left", width: "75%", marginTop: 50 }}>
        <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>Operational Areas</span> <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 300, fontStyle: "italic" }}> ("Area" field is optional, click on "+" icon to enter additional locations)</span>
        <form style={{ display: "flex", flexWrap: "wrap", width: "100%", float: "left" }}>
          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Country
            </label>
            <Select
              disabled
              style={{
                width: "100%",
                fontSize: 14,
                height: 56,
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              }}
              value="India"
              onChange={(e) => handleChangeLocation(item, e, "country", index)}
            >
              {restrictedCountry.map((item, index) => (
                <MenuItem style={{ fontSize: 16 }} value={item.name} disabled>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              State
            </label>
            {campaignValues?.campaignType === "Virtual" ? (

              <input
                disabled
                // value={item.state}
                // value=""
                type="text"
                className={classes.input}
              />
            ) :
              <Select

                style={{
                  width: "100%",
                  fontSize: 14,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                value={item.state}
                onChange={(e) => {
                  handleChangeLocation(item, e, "state", index);
                  getStateList(index, item.state);
                  dispatch(getCity(item.state));
                }}
                onBlur={(e) => {
                  // dispatch(getCity(item.state));
                }}
              >
                {states.map((item, index) => (
                  <MenuItem style={{ fontSize: 16 }} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            }
          </div>
          {index !== location.length - 1 || (index === location.length - 1 && location[index].state !== "") || index === 0 ? (
            
            <div className={classes.formGroup1}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                City/Town/Village
              </label>
              {JSON.stringify("--------")}
              {JSON.stringify(citiesSuccess)}
              {campaignValues?.campaignType === "Virtual" ? (

                <input
                  disabled
                  // value={item.city}
                  type="text"
                  className={classes.input}
                />
              ) :

                <Select
                  style={{
                    width: "100%",
                    fontSize: 14,
                    height: 56,
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    border: "none",
                  }}
                  value={item.city}
                  //disabled={!item.state}
                  onChange={(e) => handleChangeLocation(item, e, "city", index)}
                >
                  {citiesSuccess &&
                  citiesResponse.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                 
                  {/* {
                    // location[index]?.cityList.map((item, index) => (
                      location[index]?.map((item, index) => (
                      <MenuItem style={{ fontSize: 16 }} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))} */}
                </Select>
              }
              {JSON.stringify("first condition")}
            </div>
            
            ) : 
            <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              City/Town/Village
            </label>
            {campaignValues?.campaignType === "Virtual" ? (

              <input
                disabled
                // value={item.city}
                type="text"
                className={classes.input}
              />
            ) :

              <Select
                style={{
                  width: "100%",
                  fontSize: 14,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  border: "none",
                }}
                value={item.city}
                //disabled={!item.state}
                onChange={(e) => handleChangeLocation(item, e, "city", index)}
              >
                {citiesSuccess &&
                  citiesResponse.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            }
            {JSON.stringify("second condition")}
          </div>
          }
          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Area
            </label>
            {/* <Select
              style={{
                width: 200,
                fontSize: 14,
                height: 56,
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              }}
              // value={organizationValues.areaOfOrganization}
              // disabled={organizationValues?.pinCodeOfOrganization === ""}
              // onChange={(e) =>
              // handleOrganizationFieldChange("areaOfOrganization", e)
              // }
            >
              {areas?.map((item, index) => (
                <MenuItem style={{ fontSize: 16 }} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select> */}
            {campaignValues?.campaignType === "Virtual" ? (

              <input
                disabled
                // value={item.area}
                type="text"
                className={classes.input}
              />
            ) :
              <input
                value={item.area}
                type="text"
                id="create-yaad--title"
                className={classes.input}
                placeholder="Your Answer"
                onChange={(e) => handleChangeLocation(item, e, "area", index)}
              />
            }
          </div>
        </form>
        {
          index ?
            <div style={{ float: "right", position: "relative", bottom: 44, cursor: "pointer", left: 20 }} onClick={handleRemoveFields}><RemoveCircleOutlineOutlined></RemoveCircleOutlineOutlined></div>
            : <div className="handlePlus" style={{ float: "right", bottom: 45, position: "relative", left: 20, cursor: "pointer" }}><AddCircleOutlineOutlined
              onClick={handleAddFields}
            ></AddCircleOutlineOutlined></div>
        }
      </div>
    );
  };

  
  const getRewardDetails = (itemreward, index) => {
    // { JSON.stringify(itemreward) }
    return (
      <div style={{ float: "left", width: "75%", marginTop: 50 }}>

        <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>Reward Details</span> <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 300, fontStyle: "italic" }}> ("Area" field is optional, click on "+" icon to enter additional locations)</span>
        <form style={{ display: "flex", flexWrap: "wrap", width: "100%", float: "left" }}>

          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Rewards Type <RedStar />
            </label>
            {/* <Select
              // disabled
              style={{
                width: "100%",
                fontSize: 14,
                height: 56,
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              }}
              value="India"
              onChange={(e) => handleChangeLocation(item, e, "country", index)}
            > */}
            {/* {restrictedCountry.map((item, index) => (
                <MenuItem style={{ fontSize: 16 }} value={item.name} disabled>
                  {item.name}
                </MenuItem>
              ))} */}
            {/* {focusAreaList.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.rewards} disabled>
                    {item.rewards}
                  </MenuItem>
                  ))} */}
            {/* Hi {JSON.stringify(itemreward?.rewardtype)} */}
            <Select
              // onBlur={() => {
              //   if (_.isEmpty(selected)) {
              //     setFocusError(true);
              //   } else {
              //     setFocusError(false);
              //   }
              // }}
              // style={{
              //   width: 150,
              //   fontSize: 14,
              //   height: 56,
              //   boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              // }}
              style={{
                width: "100%",
                fontSize: 14,
                height: 56,
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              }}
              // multiple
              // value={selected.map((item) => item?.rewards)}
              // value={item?.rewards}
              value={itemreward.rewardtype}
              // onChange={handleChangeRewards}
              onChange={(e) => {
                handleChangeRewards1(itemreward, e, "rewardtype", index)
                // dispatch(getRewardTitle(item.rewards));
               
                getRewardDetailList(index, itemreward.rewardtype)
                dispatch(getRewardTitle(itemreward.rewardtype));
                
              }}

            // renderValue={(selected) => selected.join(", ")}
            // MenuProps={MenuProps}
            >

              {focusAreaList.map((item, index) => (
                <MenuItem style={{ fontSize: 16 }} value={item.rewards}>
                  {item.rewards}
                  {/* <ListItemIcon>
                        <Checkbox checked={selected?.indexOf(option) > -1} />
                      </ListItemIcon> */}
                  {/* <ListItemText primary={option?.rewards} /> */}
                </MenuItem>
              ))}

              {/* {states.map((item, index) => (
                  <MenuItem style={{ fontSize: 16 }} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))} */}
            </Select>
          </div>
          {/* {JSON.stringify(rewardDetailSuccess)}
          {JSON.stringify("----------------")}
          {JSON.stringify(itemreward)}
          {JSON.stringify("----------------")}
          {JSON.stringify(rewardDetailResponse)} */}
          {index !== rewardtitle.length - 1 || (index === rewardtitle.length - 1 && rewardtitle[index].title !== "") || index === 0 ? (
            <div className={classes.formGroup1}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Reward Title <RedStar />
              </label>
              <Select

                style={{
                  width: "100%",
                  fontSize: 14,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                // value={itemreward?.titlelist?.[0].title}
                value={itemreward.title}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "title", index)

                }}
                onBlur={(e) => {
                  // dispatch(getCity(item.state));
                }}
              >

                {
                rewardDetailSuccess ? (
                  // rewardtitle[index]?.titleList?.map((item, index) => (
                    rewardDetailResponse?.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.title}>
                      {item.title}
                    </MenuItem>
                  ))
                  ): 

                <MenuItem style={{ fontSize: 16 }} value={itemreward.title}>


                  {itemreward.title}
                </MenuItem>
  }
              </Select>
              {/* <input
                // disabled
                // value={
                //   rewardDetailSuccess &&
                //   rewardtitle[index]?.titleList.map((item, index) => (
                //     item.title
                //   ))
                // }
                value={
                  
                  // rewardtitleitemreward.title
                  rewardtitle[index]?.titleList?.[0]?.title
                  
                }
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "title", index)
                  // handleChangeRewards1(rewardtitle[index]?.titleList?.[0]?.title, e, "title", index)
                  // dispatch(getRewardTitle(item.rewards));
                  
                }}
                type="text"
                className={classes.input}
              /> */}

            </div>
          ) :
            <div className={classes.formGroup1}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Reward Title <RedStar />
              </label>
              <Select

                style={{
                  width: "100%",
                  fontSize: 14,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                // value={itemreward?.titlelist?.[0].title}
                value={itemreward.title}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "title", index)

                }}
                onBlur={(e) => {
                  // dispatch(getCity(item.state));
                }}
              >

                {rewardDetailSuccess ? (
                  rewardDetailResponse.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.title}>
                      {item.title}
                    </MenuItem>
                  ))
                ):

                // <MenuItem style={{ fontSize: 16 }} value={locationPath?.rewardstobeearned?.[0].title}>
                      
                      
                //     {locationPath?.rewardstobeearned?.[0].title}
                //       </MenuItem>
                //     }
                <MenuItem style={{ fontSize: 16 }} value={itemreward.title}>
                      
                      
                    {itemreward.title}
                      </MenuItem>
                    }

              </Select>
              {/* <input
                // disabled
                // value={
                //   rewardDetailSuccess &&
                //   rewardDetailResponse?.map((item, index) => (
                //     item.title
                //   ))
                // }
                value = {rewardtitle[index]?.titleList?.[0]?.title}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "title", index)
                  // handleChangeRewards1(rewardtitle[index]?.titleList?.[0]?.title, e, "title", index)
                  // dispatch(getRewardTitle(item.rewards));
                  
                }}
                type="text"
                className={classes.input}
              /> */}
            </div>
          }


          {index !== rewardtitle.length - 1 || (index === rewardtitle.length - 1 && rewardtitle[index].subtitle !== "") || index === 0 ? (
            <div className={classes.formGroup1}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Reward Sub Title <RedStar />
              </label>
              {/* {JSON.stringify(rewardDetailResponse)}
            {JSON.stringify(itemreward)} */}
              {/* {campaignValues?.campaignType === "Virtual" ? ( */}

              {/* <input
              // disabled
              // value={

              //   rewardDetailSuccess &&
                
              //   rewardDetailResponse?.map((item, index) => (
              //     // <MenuItem style={{ fontSize: 16 }} value={item.title}>
              //     // {item.title}
              //     item.subtitle
              //     // </MenuItem>
              //   ))
              // }
              value = {rewardtitle[index]?.titleList?.[0]?.subtitle}
              // rewardDetailResponse[index]?.map((item, index) => (

              //     item.title

              // ))
              // itemreward.title
              // }
              onChange={(e) => {
                handleChangeRewards1(itemreward, e, "subtitle", index)
                // handleChangeRewards1(rewardtitle[index]?.titleList?.[0]?.subtitle, e, "subtitle", index)
                // dispatch(getRewardTitle(item.rewards));
                
              }}
              type="text"
              className={classes.input}
            /> */}
              {/* ) : */}
              {/* {JSON.stringify(rewardDetailResponse)} */}
              {/* {JSON.stringify(itemreward)} */}
              <Select

                style={{
                  width: "100%",
                  fontSize: 14,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                // value={itemreward?.titlelist?.[0].subtitle}
                value={itemreward.subtitle}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "subtitle", index)

                }}
                onBlur={(e) => {
                  // dispatch(getCity(item.state));
                }}
              >

                {rewardDetailSuccess ? (
                  // rewardtitle[index]?.titleList.map((item, index) => (
                    rewardDetailResponse.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.subtitle}>
                      {item.subtitle}
                    </MenuItem>
                  ))

          ):

          <MenuItem style={{ fontSize: 16 }} value={itemreward.subtitle}>
                      
                      
                    {itemreward.subtitle}
                      </MenuItem>
                    }



                {/* {rewardDetailSuccess &&
                rewardDetailResponse.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.subtitle}>
                      {item.subtitle}
                    </MenuItem>
                  ))} */}
                {/* ))} */}
              </Select>
              {/* } */}
            </div>
          ) :
            <div className={classes.formGroup1}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Reward Sub Title <RedStar />
              </label>
              {/* {JSON.stringify(rewardDetailResponse)}
            {JSON.stringify(itemreward)} */}
              {/* {campaignValues?.campaignType === "Virtual" ? ( */}

              {/* <input
              // disabled
              // value={

              //   rewardDetailSuccess &&
                
              //   rewardDetailResponse?.map((item, index) => (
              //     // <MenuItem style={{ fontSize: 16 }} value={item.title}>
              //     // {item.title}
              //     item.subtitle
              //     // </MenuItem>
              //   ))
              // }
              value = {rewardtitle[index]?.titleList?.[0]?.subtitle}
              // rewardDetailResponse[index]?.map((item, index) => (

              //     item.title

              // ))
              // itemreward.title
              // }
              onChange={(e) => {
                handleChangeRewards1(itemreward, e, "subtitle", index)
                // handleChangeRewards1(rewardtitle[index]?.titleList?.[0]?.subtitle, e, "subtitle", index)
                // dispatch(getRewardTitle(item.rewards));
                
              }}
              type="text"
              className={classes.input}
            /> */}
              {/* ) : */}
              {/* {JSON.stringify(rewardDetailResponse)} */}
              {/* {JSON.stringify(itemreward)} */}
              <Select

                style={{
                  width: "100%",
                  fontSize: 14,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                // value={itemreward?.titlelist?.[0].subtitle}
                value={itemreward.subtitle}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "subtitle", index)

                }}
                onBlur={(e) => {
                  // dispatch(getCity(item.state));
                }}
              >

                {/* {rewardDetailSuccess &&
                rewardtitle[index]?.titleList.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.subtitle}>
                      {item.subtitle}
                    </MenuItem>
                  ))} */}

                {rewardDetailSuccess ? (
                  rewardDetailResponse.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.subtitle}>
                      {item.subtitle}
                    </MenuItem>
                  ))
                ):

<MenuItem style={{ fontSize: 16 }} value={itemreward.subtitle}>
                      
                      
                      {itemreward.subtitle}
                        </MenuItem>
                      }

                {/* ))} */}
              </Select>
              {/* } */}
            </div>
          }


          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Sponsor Name <RedStar />
            </label>
            {/* <Select
              style={{
                width: 200,
                fontSize: 14,
                height: 56,
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              }}
              // value={organizationValues.areaOfOrganization}
              // disabled={organizationValues?.pinCodeOfOrganization === ""}
              // onChange={(e) =>
              // handleOrganizationFieldChange("areaOfOrganization", e)
              // }
            >
              {areas?.map((item, index) => (
                <MenuItem style={{ fontSize: 16 }} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select> */}
            {/* {campaignValues?.campaignType === "Virtual" ? ( */}

            {/* <input
                disabled
                value={itemreward.sponsor}
                type="text"
                className={classes.input}
              /> */}
            {/* ) : */}
            <input
              value={itemreward.sponsor}
              type="text"
              id="create-yaad--title"
              className={classes.input}
              placeholder="Your Answer"
              onChange={(e) => handleChangeRewards1(itemreward, e, "sponsor", index)}

            />
            {/* } */}
          </div>

          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Points <RedStar />
            </label>
            {/* <Select
              style={{
                width: 200,
                fontSize: 14,
                height: 56,
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              }}
              // value={organizationValues.areaOfOrganization}
              // disabled={organizationValues?.pinCodeOfOrganization === ""}
              // onChange={(e) =>
              // handleOrganizationFieldChange("areaOfOrganization", e)
              // }
            >
              {areas?.map((item, index) => (
                <MenuItem style={{ fontSize: 16 }} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select> */}
            {/* {campaignValues?.campaignType === "Virtual" ? ( */}

            {/* <input
                disabled
                value={itemreward.sponsor}
                type="text"
                className={classes.input}
              /> */}
            {/* ) : */}
            <input
              value={itemreward.points}
              type="text"
              id="create-yaad--title"
              className={classes.input}
              placeholder="Your Answer"
              onChange={(e) => handleChangeRewards1(itemreward, e, "points", index)}

            />
            {/* } */}
          </div>

        </form>
        {
          index ?
            <div style={{ float: "right", position: "relative", bottom: 44, cursor: "pointer", left: 19 }} onClick={(e) => handleRemoveFields(index)}><RemoveCircleOutlineOutlined></RemoveCircleOutlineOutlined></div>
            : <div className="handlePlus" style={{ float: "right", bottom: 45, position: "relative", left: 20, cursor: "pointer", right: "15px" }}><AddCircleOutlineOutlined
              onClick={handleRewardAddFields}
            ></AddCircleOutlineOutlined></div>
        }
      </div>
    );
  };

  // React.useEffect(() => {
  //   dispatch(onboardBulkReset());
  //   dispatch(onboardIndividualReset());
  //   dispatch(onboardActivityReset());
  //   dispatch(onboardCampaignReset());
  //   dispatch(onboardOrganizationReset());
  // }, []);
  React.useEffect(() => {
    dispatch(getOrganization({ userid: localStorage.getItem("userid") }));
    getActivityList();
  }, []);

  // React.useEffect(() => {
  //   if (isCampaignSuccess) {
  //     // setCampaignValues({
  //     //   titleOfCampaign: "",
  //     //   descriptionOfCampaign: "",
  //     //   typeOfCampaign: "",
  //     //   rewardType: "",
  //     //   volunteerCount: "",
  //     //   impact: "",
  //     //   startDate: new Date(),
  //     //   endDate: new Date(),
  //     //   durationOfCampaign: "",
  //     //   goal: "",
  //     //   campaignType: "",
  //     //   hourOfParticipation: "",
  //     //   socialMediaMessage: "",
  //     //   campaignLogo: "",
  //     //   campaignVideo: "",
  //     //   visibility: "",
  //     // });

  //     // setLocation([{ country: "", state: "", city: "", area: "" }]);
  //     // resetFile();
  //     setFocusArea([
  //       { value: "Certificate of Participation", rewards: "Certificate of Participation", isChecked: false, },
  //       { value: "Discount Coupons", rewards: "Discount Coupons", isChecked: false, },
  //       { value: "Gift Cards", rewards: "Gift Cards", isChecked: false },
  //       { value: "Any Other", rewards: "Any Other", isChecked: false },
  //     ]);

  //     // setSkill([
  //     //   { value: "Networking", skill: "Networking", isChecked: false, },
  //     //   { value: "Fundraising", skill: "Fundraising",  isChecked: false, },
  //     //   { value: "Operational Support", skill: "Operational Support", isChecked: false },
  //     //   { value: "Content Writing / Editing", skill: "Content Writing / Editing", isChecked: false },
  //     //   { value: "Social Media and Digital marketing", skill: "Social Media and Digital marketing",  isChecked: false, },
  //     //   { value: "Team Management", skill: "Team Management",  isChecked: false, },
  //     //   { value: "Painting / Drawing / Sculpture making", skill: "Painting / Drawing / Sculpture making",  isChecked: false, },
  //     //   { value: "Photography", skill: "Photography",  isChecked: false, },
  //     //   { value: "Video Editing", skill: "Video Editing",  isChecked: false, },
  //     //   { value: "Coaching / Mentoring", skill: "Coaching / Mentoring",  isChecked: false, },
  //     //   { value: "App Development / Coding", skill: "App Development / Coding",  isChecked: false, },
  //     // ]);
  //   }
  // }, [isCampaignSuccess]);

  const locationPath = useLocation();
  const [selectedSkills, setSelectedSkills] = React.useState([]);
  const [selectedRewards, setSelectedRewards] = React.useState([]);



  React.useEffect(() => {
    // console.log(locationPath)
    if (locationPath?.state?.skill) {
      setSelectedSkills(locationPath?.state?.skill);
    }
    // if (locationPath?.state?.rewardstobeearned) {
    //   setSelectedRewards(locationPath?.state?.rewardstobeearned);
    // }
    if (locationPath?.state) {
      setCampaignValues({
        titleOfCampaign: locationPath?.state?.title,
        descriptionOfCampaign: locationPath?.state?.desc,
        typeOfCampaign: locationPath?.state?.type,
        volunteerCount: locationPath?.state?.targetvol,
        // impact: locationPath?.state?.impact,
        visibility: locationPath?.state.visibility,
        // startDate: moment(locationPath?.state?.startDate).format("YYYY-DD-MM"),
        // endDate: moment(locationPath?.state?.endDate).format("YYYY-DD-MM"),
        // startDate: moment(locationPath?.state?.startdate, "YYYY-MM-DD"),
        // endDate: moment(locationPath?.state?.enddate, "YYYY-MM-DD"),
        startDate: locationPath?.state?.startdate,
        endDate: locationPath?.state?.enddate,
        durationOfCampaign: locationPath?.state?.timeframe,
        goal: locationPath?.state?.goal,
        campaignType: locationPath?.state?.campaigntype,
        hourOfParticipation: locationPath?.state?.hourofparticipation,
        pointsToCurrency: locationPath?.state?.pointtocurrency,
        currencyType: locationPath?.state?.currencytype,
        totalBudget: locationPath?.state?.totalbudget,
        pointsPerReferral: locationPath?.state?.pointsperreferral,
        budgetPointForReferral: locationPath?.state?.budgetpointforreferral,
        socialMediaMessage: locationPath?.state?.socialmediamsg,
        campaignLogo: locationPath?.state?.logo,
        // banner: locationPath?.state?.banner,
        // campaignLogo: campaignValues.campaignLogo,
        campaignVideo: locationPath?.state?.video,
        rewardType: locationPath?.state?.rewardstobeearned,
        campid: locationPath?.state?.campid,
        skill: locationPath?.state?.skill,
      });
      setLocation(locationPath?.state?.location);
      setRewardtitle(locationPath?.state?.rewardstobeearned)
      // setFocusArea(JSON.parse(locationPath?.state?.rewardstobeearned))
    }
  }, [locationPath?.state]);
  
  
 
  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Link className={classes.backBtn} to="/reviewcampaign">
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 0 }}> Back</span>
            </Link>
            {/* <div style={{ fontFamily: "monospace", fontSize: 16, }}>
              <div style={{ display: "flex", width: "75%", justifyContent: "flex-end", marginRight: 5, marginBottom: 20, }}>
                <span style={{ color: "red" }}>Please do not leave this page until you submit as you may risk losing the partially filled in form </span>
              </div>
            </div> */}
            <div class="content-title">Edit Campaign</div>


            {locationPath?.state?.logo &&
              (loading ? (
                <CircularProgress color="inherit" size={16} />
                // <CircularProgress color="blue" size={16} />
              ) : (
                <div style={{
                  padding: "10px 0",
                  // height: "50%",
                  // boxShadow: "1px 1px 1px 5px #f0f0f0",
                  borderRadius: 30,
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center",
                  alignItems: "center",
                  // backgroundColor: "#fff"
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #dadce0",
                }}>
                  <span ><b>Update Campaign Logo</b></span>
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    // justifyContent= "center"
                    >
                      <Avatar
                        // {/* <img */}
                        src={
                          image
                            ? URL.createObjectURL(image)
                            : locationPath?.state?.logo
                          // : organizationValues?.organizationLogo
                        }
                        className={classes.large}
                        style={{ justifyContent: "center", alignItems: "center" }}
                      />
                    </IconButton>
                  </label>
                  <input
                    name="Upload your image"
                    accept="image/*"
                    className={classes.logobutton}
                    id="icon-button-file"
                    type="file"
                    onChange={handleChangeFile}
                    width="20"
                  />
                </div>
              ))}

            {locationPath?.state?.video &&
              (loadingVideo ? (
                <CircularProgress color="inherit" size={16} />
                // <CircularProgress color="blue" size={16} />
              ) : (
                <div style={{
                  padding: "10px 0",
                  // height: "50%",
                  // boxShadow: "1px 1px 1px 5px #f0f0f0",
                  borderRadius: 30,
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center",
                  alignItems: "center",
                  // backgroundColor: "#fff"
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #dadce0",
                }}>
                  <span ><b>Update Campaign Video</b></span>
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload video"
                      component="span"
                    // justifyContent= "center"
                    >
                      <Avatar
                        src={
                          video
                            ? URL.createObjectURL(video)
                            : locationPath?.state?.video
                          // : organizationValues?.organizationLogo
                        }
                        className={classes.large}
                        style={{ justifyContent: "center", alignItems: "center" }}
                      />
                    </IconButton>
                  </label>
                  <input
                    // ref={videoRef}
                    // value={campaignValues?.campaignVideo?.[0]?.name}

                    accept="video/*"
                    name="Upload your video"
                    className={classes.logobutton}
                    id="icon-button-file"
                    type="file"
                    onChange={handleChangeVideoFile}
                    width="20"
                  />
                </div>
              ))}


            {/* {locationPath?.state?.banner &&
              (loadingBanner ? (
                <CircularProgress color="inherit" size={16} />
                // <CircularProgress color="blue" size={16} />
              ) : (
                <div style={{
                  padding: "10px 0",
                  // height: "50%",
                  // boxShadow: "1px 1px 1px 5px #f0f0f0",
                  borderRadius: 30,
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center",
                  alignItems: "center",
                  // backgroundColor: "#fff"
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #dadce0",
                }}>
                  <span ><b>Update Campaign Banner</b></span>
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    // justifyContent= "center"
                    >
                      <Avatar
                        src={
                          banner
                            ? URL.createObjectURL(banner)
                            : locationPath?.state?.banner
                          // : organizationValues?.organizationLogo
                        }
                        className={classes.large}
                        style={{ justifyContent: "center", alignItems: "center" }}
                      />
                    </IconButton>
                  </label>
                  <input
                    name="Upload your banner"
                    accept="image/*"
                    className={classes.logobutton}
                    id="icon-button-file"
                    type="file"
                    onChange={handleBannerFile}
                    width="20"
                  />
                </div>
              ))} */}



            <div style={{
              // padding: "10px 0",
              // height: "50%",
              // boxShadow: "1px 1px 1px 5px #f0f0f0",
              // borderRadius: 30,
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "#fff"
              // backgroundColor: "#FFFFFF",
              // border: "1px solid #dadce0",
            }}></div>
            <form autoComplete="off">
              <div style={{ border: "1px solid #dadce0", borderRadius: 8, backgroundColor: "#fff" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {/* {JSON.stringify(campaignValues)} */}
                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Name of Campaign (25 Chars)<RedStar />
                    </label>

                    <input
                      value={campaignValues?.titleOfCampaign}
                      type="text"
                      className={classes.input}
                      placeholder="Your answer"
                      maxLength="25"
                      // onBlur={() => {
                      //   if (!campaignValues?.titleOfCampaign) {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       titleOfCampaignError: true,
                      //     });
                      //   } else {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       titleOfCampaignError: false,
                      //     });
                      //   }
                      // }}
                      onChange={(e) => {
                        handleCampaignFieldChange("titleOfCampaign", e);
                      }}
                    />
                    {/* {campaignValuesError?.titleOfCampaignError && (
                      <ErrorMessage message="Please enter the title of campaign" />
                    )} */}
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Description (200 Chars) <RedStar />
                    </label>
                    <input
                      value={campaignValues?.descriptionOfCampaign}
                      type="text"
                      className={classes.input}
                      placeholder="Your answer"
                      maxLength="200"
                      // onBlur={() => {
                      //   if (!campaignValues?.descriptionOfCampaign) {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       descriptionOfCampaignError: true,
                      //     });
                      //   } else {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       descriptionOfCampaignError: false,
                      //     });
                      //   }
                      // }}
                      onChange={(e) => {
                        handleCampaignFieldChange("descriptionOfCampaign", e);
                      }}
                    />
                    {/* {campaignValuesError?.descriptionOfCampaignError && (
                      <ErrorMessage message="Please enter the description of campaign" />
                    )} */}
                  </div>
                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Goal <RedStar />
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        value={campaignValues?.goal}
                        type="text"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        // onBlur={() => {
                        //   if (!campaignValues?.goal) {
                        //     setCampaignValuesError({
                        //       ...campaignValuesError,
                        //       goalError: true,
                        //     });
                        //   } else {
                        //     setCampaignValuesError({
                        //       ...campaignValuesError,
                        //       goalError: false,
                        //     });
                        //   }
                        // }}
                        onChange={(e) => {
                          handleCampaignFieldChange("goal", e);
                        }}
                      />
                    </div>
                    {/* {campaignValuesError?.goalError && (
                      <ErrorMessage message="Please enter the goal" />
                    )} */}
                  </div>
                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Category <RedStar />
                    </label>
                    <Select
                      style={{
                        width: "100%",
                        fontSize: 14,
                        height: 56,
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                      }}
                      value={campaignValues?.typeOfCampaign}
                      // onBlur={() => {
                      //   if (!campaignValues?.typeOfCampaign) {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       typeOfCampaignError: true,
                      //     });
                      //   } else {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       typeOfCampaignError: false,
                      //     });
                      //   }
                      // }}
                      onChange={(e) => {
                        handleCampaignFieldChange("typeOfCampaign", e);
                      }}
                    >
                      {activityList?.map((item, index) => (
                        <MenuItem style={{ fontSize: 16 }} value={item.activity}>
                          {item.activity}
                        </MenuItem>
                      ))}
                    </Select>
                    {/* {campaignValuesError?.typeOfCampaignError && (
                      <ErrorMessage message="Please select the campaign category" />
                    )} */}
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Type <RedStar />
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <Select
                        style={{
                          width: "100%",
                          fontSize: 14,
                          height: 56,
                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                        }}
                        value={campaignValues.campaignType}
                        // onBlur={() => {
                        //   if (!campaignValues?.campaignType) {
                        //     setCampaignValuesError({
                        //       ...campaignValuesError,
                        //       campaignTypeError: true,
                        //     });
                        //   } else {
                        //     setCampaignValuesError({
                        //       ...campaignValuesError,
                        //       campaignTypeError: false,
                        //     });
                        //   }
                        // }}
                        onChange={(e) =>
                          handleCampaignFieldChange("campaignType", e)
                        }
                      >
                        {campaignType.map((item, index) => (
                          <MenuItem style={{ fontSize: 16 }} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    {/* {campaignValuesError?.campaignTypeError && (
                      <ErrorMessage message="Please select the campaign type" />
                    )} */}
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Participation <RedStar />
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      {/* {JSON.stringify(campaignValues?.visibility)} */}
                      <Select
                        style={{
                          width: "100%",
                          fontSize: 14,
                          height: 56,
                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",

                        }}
                        value={campaignValues?.visibility}
                        // onBlur={() => {
                        //   if (!campaignValues?.visibility) {
                        //     setCampaignValuesError({
                        //       ...campaignValuesError,
                        //       visibilityError: true,
                        //     });
                        //   } else {
                        //     setCampaignValuesError({
                        //       ...campaignValuesError,
                        //       visibilityError: false,
                        //     });
                        //   }
                        // }}
                        onChange={(e) =>
                          handleCampaignFieldChange("visibility", e)
                        }
                      >
                        {campaignParticipate.map((item, index) => (
                          <MenuItem style={{ fontSize: 16 }} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    {/* {campaignValuesError?.visibilityError && (
                      <ErrorMessage message="Please select the campaign participate value" />
                    )} */}
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Number of volunteers Required <RedStar />
                    </label>

                    <input
                      value={campaignValues?.volunteerCount}
                      type="number"
                      id="create-yaad--title"
                      className={classes.input}
                      placeholder="Your answer"
                      onKeyPress={(e) => { preventMinus(e) }}
                      // onBlur={() => {
                      //   if (!campaignValues?.volunteerCount) {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       volunteerCountError: true,
                      //     });
                      //   } else {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       volunteerCountError: false,
                      //     });
                      //   }
                      // }}
                      onChange={(e) => {
                        handleCampaignFieldChange("volunteerCount", e);
                      }}
                    />
                    {/* {campaignValuesError?.volunteerCountError && (
                      <ErrorMessage message="Please enter the volunteer count" />
                    )} */}
                  </div>
                  {/* <div className={classes.formGroup} style={{ width: "25%" }}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Impact
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <input
                    value={campaignValues?.impact}
                    type="number"
                    id="create-yaad--title"
                    className={classes.input}
                    placeholder="Your Answer"
                    onBlur={() => {
                      if (!campaignValues?.impact) {
                        setCampaignValuesError({
                          ...campaignValuesError,
                          impactError: true,
                        });
                      } else {
                        setCampaignValuesError({
                          ...campaignValuesError,
                          impactError: false,
                        });
                      }
                    }}
                    onChange={(e) => {
                      handleCampaignFieldChange("impact", e);
                    }}
                  />
                  <span style={{ marginLeft: 4 }}>
                    {unitOfCampaign?.[0]?.unit}
                  </span>
                </div>
                {campaignValuesError?.impactError && (
                  <ErrorMessage message="Please enter the impact" />
                )}
              </div> */}
                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Start Date <RedStar />
                    </label>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      {/* {JSON.stringify(campaignValues)} */}
                      <DatePicker
                        // minDate={new Date()}
                        inputFormat={"yyyy-MM-dd"}
                        // inputFormat={"YYYY-MM-DD"}
                        value={campaignValues?.startDate}
                        onChange={(newValue) => {
                          handleCampaignFieldChange("startDate", newValue);
                        }}
                        minDate={new Date()}
                        // maxDate={campaignValues.endDate}

                        renderInput={(params) => (
                          <TextField
                            style={{
                              width: "100%",
                              height: 56,
                              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      End Date <RedStar />
                    </label>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      {/* {JSON.stringify(campaignValues?.endDate)} */}
                      <DatePicker
                        // minDate={new Date()}
                        inputFormat={"yyyy-MM-dd"}
                        value={campaignValues.endDate}
                        onChange={(newValue) => {
                          handleCampaignFieldChange("endDate", newValue);
                        }}
                        // disabled={campaignValues.startDate=== "" ? true: false}
                        // minDate={new Date()}
                        minDate={campaignValues?.startDate}
                        //maxDate={}
                        renderInput={(params) => (
                          <TextField
                            style={{
                              width: "100%",
                              height: 56,
                              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>


                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      No. of Hours of Participation Required from a Volunteer <RedStar />
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        value={campaignValues?.hourOfParticipation}
                        type="number"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        min="0"
                        onKeyPress={(e) => { preventMinus(e) }}
                        // onKeyDown={(e) => { handleKeypress(e)}}
                        // onBlur={() => {
                        //   if (!campaignValues?.hourOfParticipation) {
                        //     setCampaignValuesError({
                        //       ...campaignValuesError,
                        //       hourOfParticipationError: true,
                        //     });
                        //   } else {
                        //     setCampaignValuesError({
                        //       ...campaignValuesError,
                        //       hourOfParticipationError: false,
                        //     });
                        //   }
                        // }}
                        onChange={(e) => {
                          handleCampaignFieldChange("hourOfParticipation", e);
                        }}
                      />
                    </div>
                    {/* {campaignValuesError?.hourOfParticipationError && (
                      <ErrorMessage message="Please enter the hours of participation" />
                    )} */}
                  </div>


                  {/* <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Rewards to be Earned <RedStar />
                    </label> */}
                  {/* Hi {JSON.stringify(locationPath?.state?.rewardstobeearned)} */}
                  {/* <Multiselect
                      displayValue="rewards"
                      showCheckbox
                      selectedValues={locationPath?.state?.rewardstobeearned} */}

                  {/* // options={focusArea}
                      // onSelect={(event) => { */}
                  {/* //   setSelectedRewards(event);
                        // if (_.isEmpty(event)) {
                        //   setCampaignValuesError({
                        //     ...campaignValuesError,
                        //     rewardTypeError: true,
                        //   });
                        // } else {
                        //   setCampaignValuesError({
                        //     ...campaignValuesError,
                        //     rewardTypeError: false,
                        //   });
                        // }
                      // }}
                      // onBlur={(event) => {
                      //   if (_.isEmpty(event)) {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       rewardTypeError: true,
                      //     });
                      //   } else {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       rewardTypeError: false,
                      //     });
                      //   }
                      // }}
                    //   value={selectedRewards}
                    //   style={{
                    //     multiselectContainer: {
                    //       boxShadow: "0px 3px 6px #00000029",
                    //     },
                    //     searchBox: {
                    //       minHeight: 54,
                    //       PaddingTop: 10
                    //     }
                    //   }}

                    // /> */}

                  {/* <Select
                  onBlur={() => {
                    if (_.isEmpty(selected)) {
                      setFocusError(true);
                    } else {
                      setFocusError(false);
                    }
                  }}
                  style={{
                    width: 350,
                    fontSize: 14,
                    height: 56,
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  }}
                  multiple
                  value={selected.map((item) => item?.rewards)}
                  onChange={handleChangeRewards}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {focusAreaList.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox checked={selected?.indexOf(option) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={option?.rewards} />
                    </MenuItem>
                  ))}
                </Select> */}

                  {/* {campaignValuesError?.rewardTypeError && (
                      <ErrorMessage message="Please select rewards to be earned" />
                    )} */}
                  {/* </div> */}

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Skills/Tags <RedStar />
                    </label>
                    {/* {JSON.stringify(locationPath?.state?.skill)} */}

                    <Multiselect
                      displayValue="skill"
                      showCheckbox
                      //    selectedValues={selected1}
                      selectedValues={locationPath?.state?.skill}
                      // selectedValues={campaignValues?.skill}
                      options={skill}
                      onSelect={(event) => {
                        // setSelected1(event);
                        setSelectedSkills(event)
                        // if (_.isEmpty(event)) {
                        //   setCampaignValuesError({
                        //     ...campaignValuesError,
                        //     setSkillError: true,
                        //   });
                        // } else {
                        //   setCampaignValuesError({
                        //     ...campaignValuesError,
                        //     setSkillError: false,
                        //   });
                        // }
                      }}
                      // value={locationPath?.state?.skill}
                      value={selectedSkills}

                      style={{
                        multiselectContainer: {
                          boxShadow: "0px 3px 6px #00000029",
                        },
                        searchBox: {
                          minHeight: 54,
                          PaddingTop: 10
                        }
                      }}


                    />
                    {/* {locationPath?.state} */}

                    {/* <Select
                  onBlur={() => {
                    if (_.isEmpty(selected1)) {
                      setFocusError(true);
                    } else {
                      setFocusError(false);
                    }
                  }}
                  style={{
                    width: 350,
                    fontSize: 14,
                    height: 56,
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  }}
                  multiple
                  value={selected1.map((item) => item?.skill)}
                  onChange={handleChangeSkill}
                  renderValue={(selected1) => selected1.join(", ")}
                  MenuProps={MenuProps}
                >
                  {skill.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox checked={selected1?.indexOf(option) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={option?.skill} />
                    </MenuItem>
                  ))}
                </Select> */}

                    {/* {campaignValuesError?.setSkillError && (
                      <ErrorMessage message="Please select skills/tags" />
                    )} */}
                  </div>

                  {/* <div className={classes.formGroup} style={{ width: "25%" }}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Duration (In Days)
                </label> */}
                  {/* <Select
                  style={{
                    width: 200,
                    fontSize:14,
                    height: 56,
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  }}
                  value={campaignValues.durationOfCampaign}
                  onChange={(e) =>
                    handleCampaignFieldChange("durationOfCampaign", e)
                  }
                >
                  {activityDuration.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.value}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select> */}
                  {/* <input
                  className={classes.input}
                  placeholder="Your Answer"
                  value={campaignValues?.durationOfCampaign}
                  style={{ fontStyle: "normal" }}
                  type="text"
                  onBlur={() => {
                    if (!campaignValues?.durationOfCampaign) {
                      setCampaignValuesError({
                        ...campaignValuesError,
                        durationOfCampaignError: true,
                      });
                    } else {
                      setCampaignValuesError({
                        ...campaignValuesError,
                        durationOfCampaignError: false,
                      });
                    }
                  }}
                  onChange={(e) => {
                    handleCampaignFieldChange("durationOfCampaign", e);
                  }}
                />
                {campaignValuesError?.durationOfCampaignError && (
                  <ErrorMessage message="Please enter the duration in days" />
                )}
              </div> */}

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Social Media Message (500 chars)<RedStar />
                    </label>
                    <textarea
                      value={campaignValues?.socialMediaMessage}
                      type="text"
                      id="create-yaad--title"
                      className={classes.input}
                      placeholder="Your answer"
                      maxLength="500"
                      // onBlur={() => {
                      //   if (!campaignValues?.socialMediaMessage) {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       socialMediaMessageError: true,
                      //     });
                      //   } else {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       socialMediaMessageError: false,
                      //     });
                      //   }
                      // }}
                      onChange={(e) => {
                        handleCampaignFieldChange("socialMediaMessage", e);
                      }}
                    />
                    {/* {campaignValuesError?.socialMediaMessageError && (
                      <ErrorMessage message="Please enter the social media message" />
                    )} */}
                  </div>


                  {/* <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Logo <RedStar />
                    </label>
                    {JSON.stringify(campaignValues?.campaignLogo?.[0]?.name)}
                    <input
                      ref={inputRef}
                      selected={campaignValues?.campaignLogo?.[0]?.name}
                      // value={campaignValues?.campaignLogo?.[0]?.name}
                      value={campaignValues?.campaignLogo?.[0]?.name}
                      type="file"
                      accept="image/*"
                      id="example-file"
                      className={classes.input}
                      placeholder="Your answer"
                      onBlur={() => {
                        if (!campaignValues?.socialMediaMessage) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            campaignLogoError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            campaignLogoError: false,
                          });
                        }
                      }}
                      onChange={(e) => {
                        handleCampaignFieldChange("campaignLogo", e);
                      }}
                    />
                    {campaignValuesError?.campaignLogoError && (
                      <ErrorMessage message="Please enter the social media message" />
                    )}
                  </div> */}


                  {/* <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Video <RedStar />
                    </label>
                    <input
                      ref={videoRef}
                      value={campaignValues?.campaignVideo?.[0]?.name}
                      type="file"
                      accept="video/*"
                      id="create-yaad--title"
                      className={classes.input}
                      onChange={(e) => {
                        handleCampaignFieldChange("campaignVideo", e);
                      }}
                      progress="line"
                    />
                  </div> */}


                  <div className={classes.formGroupFull}>
                    {rewardtitle.map((itemreward, index) => (
                      <div key={index}>{getRewardDetails(itemreward, index)}</div>
                    ))}
                    {/* <AddCircleOutlineOutlined
                  formStyle={{ paddingTop: "20px" }}
                  onClick={handleAddFields}
                ></AddCircleOutlineOutlined> */}
                  </div>


                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Total budget for the campaign <RedStar />
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        value={campaignValues?.totalBudget}
                        type="number"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        min="0"
                        onKeyPress={(e) => { preventMinus(e) }}
                        // onKeyDown={(e) => { handleKeypress(e)}}
                        onBlur={() => {
                          if (!campaignValues?.totalBudget) {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              totalBudgetError: true,
                            });
                          } else {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              totalBudgetError: false,
                            });
                          }
                        }}
                        onChange={(e) => {
                          handleCampaignFieldChange("totalBudget", e);
                        }}
                      />
                    </div>
                    {campaignValuesError?.totalBudgetError && (
                      <ErrorMessage message="Please enter the total budget for the campaign" />
                    )}
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Currency Type <RedStar />
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <Select
                        style={{
                          width: "100%",
                          fontSize: 14,
                          height: 56,
                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                        }}
                        value={campaignValues.currencyType}
                        onBlur={() => {
                          if (!campaignValues?.currencyType) {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              currencyTypeError: true,
                            });
                          } else {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              currencyTypeeError: false,
                            });
                          }
                        }}
                        onChange={(e) =>
                          handleCampaignFieldChange("currencyType", e)
                        }
                      >
                        {currencyType.map((item, index) => (
                          <MenuItem style={{ fontSize: 16 }} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    {campaignValuesError?.currencyTypeError && (
                      <ErrorMessage message="Please select the currency type value" />
                    )}
                  </div>

                      ************* {JSON.stringify(location)}
                  <div className={classes.formGroupFull}>
                    {location.map((item, index) => (
                      <div key={index}>{getAddress(item, index)}</div>
                    ))}
                    {/* <AddCircleOutlineOutlined
                  formStyle={{ paddingTop: "20px" }}
                  onClick={handleAddFields}
                ></AddCircleOutlineOutlined> */}
                  </div>
                  {/* {JSON.stringify(campaignResponse)} */}

                </div>

                <div>
                  {isCampaignSuccess &&
                    // <span>Hi </span>

                    <span className={classes.formGroupFull} style={{ color: "green", textAlign: "center", display: "block", }}>{campaignResponse?.message}</span>
                  }
                  {isCampaignError &&
                    <span className={classes.formGroupFull} style={{ color: "red", textAlign: "center", display: "block", }}>{campaignResponse?.message}</span>

                  }
                  {/* {isCampaignSuccess && 
                  campaignResponse &&
                    campaignResponse?.status === "success" &&
                      <span className={classes.formGroupFull} style={{ color: "green", textAlign: "center", display: "block", }}>{campaignResponse?.message}</span>}
                {isCampaignSuccess && 
                  campaignResponse &&
                    !campaignResponse.status === "success"(
                      <span className={classes.formGroupFull} style={{ color: "red", textAlign: "center", display: "block", }}>{campaignResponse?.message}</span>
                )} */}

                </div>
                <div className="centerAlign" style={{ padding: "16px 0", marginBottom: 100 }}>
                  <Button

                    variant="contained"
                    // onClick={() => handleSave()}
                    onClick={() => handleOnboardCampaign()}
                    className="btn save-btn"
                    disableElevation
                    disabled={isCampaignFetching}
                  >

                    {isCampaignFetching ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      <span>Submit</span>
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => navigate("/reviewcampaign")}
                    style={{
                      // marginTop: 1,
                      width: "150px",
                      height: "50px",
                      background: "white",
                      // borderRadius: 20,
                      // color: "#fff",
                      color: "red",
                      fontSize: 16,
                      // borderBlockColor: "orange",
                      // borderBlock: 5,
                      fontFamily: "Poppins !important",
                      marginBottom: "50px",
                    }}
                  >
                    <span>Cancel</span>
                  </Button>
                  <br />
                  {/* {isCampaignSuccess && 
                  <span style={{ color: "green", textAlign: "center", display: "block" }}>{campaignResponse?.message}</span>}
                  {isCampaignError && (
                    <span style={{ color: "red", textAlign: "center", display: "block" }}>{campaignResponse?.message}</span>
                  )} */}

                </div>
                {/* {JSON.stringify(isCampaignFetching)} */}
                {/* {isCampaignFetching && 
                  <span className={classes.formGroupFull} style={{ color: "green", textAlign: "center", display: "block", }}>{campaignResponse?.message}</span>}
                  {!isCampaignFetching && (
                    <span className={classes.formGroupFull} style={{ color: "red", textAlign: "center", display: "block", }}>{campaignResponse?.message}</span>
                  )} */}


                {/* {isCampaignSuccess && 
                  campaignResponse &&
                    campaignResponse?.status === "success" &&
                      <span className={classes.formGroupFull} style={{ color: "green", textAlign: "center", display: "block", }}>{campaignResponse?.message}</span>}
                {isCampaignSuccess && 
                  campaignResponse &&
                    !campaignResponse?.status === "success"(
                      <span className={classes.formGroupFull} style={{ color: "red", textAlign: "center", display: "block", }}>{campaignResponse?.message}</span>
                )} */}

                {/* {isCampaignSuccess && 
                  // <span>Hi </span>
                  
                      <span className={classes.formGroupFull} style={{ color: "green", textAlign: "center", display: "block", }}>{campaignResponse?.message}</span>
                } */}

              </div>
            </form>

          </div></div>
        <CopyRight />
      </section>


    </div>
  );
}
export default EditCampaignScreen;
