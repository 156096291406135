import React, {useState} from "react";
import {
  Avatar,
  Button,
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import {
  demoThumbnailUrl,
  demoChannelUrl,
  demoVideoUrl,
  demoChannelTitle,
  demoVideoTitle,
  demoProfilePicture,
} from "../utils/constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Chip } from "@material-ui/core";


export const VideoCard = (video, camp, type) => {
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const navigate = useNavigate();
  const locationPath = useLocation();
 

  return (
    <>
      <Grid container>
        {video?.video?.map((item) =>
          item.files?.map((item1) => (
            <Grid item xs={12} sm={8} md={6} lg={3} xl={3}>
              <Box p={1}>
                <Card
                  sx={{
                    // width: { xs: "70%", sm: "60%", md: "200px", lg: "40%" },
                    width: { xs: "70%", sm: "60%", md: "200px" },
                    // width: { xs: '100%', sm: '358px', md: "196px", },
                    boxShadow: "none",
                    borderRadius: 0,
                    flexDirection: { sx: "column", md: "row" },
                    marginBottom: "5px",
                    maxWidth:"100%",
                  }}
                  onClick={video?.onClick}
                >
                  {item1?.type === "video" ? (
                    <Link
                      to={`/video/${item1?.id}/${item.campid}/${item?.phoneno}`}
                    >
                      <CardMedia
                        image={item1?.logo}
                        alt={item?.campname}
                        sx={{ width: "100%", height: 180 }}
                        style={{paddingRight:"15px"}}
                        // sx={{ width: { xs: '100%', sm: '358px', md: 200}, height: 180 }}
                      />
                    </Link>
                  ) : (
                    <CardMedia
                      image={item1?.fileurl}
                      alt={item?.campname}
                      sx={{ width: "100%", height: 180 }}
                      style={{paddingRight:"15px"}}
                    />
                  )}

                  <CardContent
                    sx={{
                      backgroundColor: "#1e1e1e",
                      height: "80px",
                      padding: "6px",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      fontWeight="bold"
                      color="#FFF"
                      textAlign="center"
                    >
                      <Chip key={item1?.id} size="small" label={item1?.type} style={{backgroundColor: "#404040"}}/>
                    </Typography>
                    {/* <Link
                      to={
                        item1?.type === "video"
                          ? `/video/${item1?.id}/${item.campid}/${item?.phoneno}`
                          : `/campaigndetails/${item?.campid}/${profileResponse?.orgrole}/${profileResponse?.organization}`
                      }
                    > */}
                      <Typography
                        variant="subtitle2"
                        fontWeight="bold"
                        color="#E9DCC9"
                        fontSize="12px"
                      >
                        
                        
                        
                        {item1?.timestamp.split(" ")?.[0].slice(0,10)}
                      </Typography>
                    {/* </Link> */}

                    {/* <Link
                      to={`/video/${item1?.id}/${item.campid}/${item?.phoneno}`}
                    > */}
                      <Typography
                        variant="subtitle2"
                        fontWeight="bold"
                        color="gray"
                      >
                        {item1?.username}
                        <CheckCircle
                          sx={{ fontSize: 12, color: "gray", ml: "5px" }}
                        />
                        {item?.uniqueuserid === profileResponse?.uniqueuserid && video["type"]==="public" ?  (
                          
                        <Button 
                          sx={{ fontSize: 10,  color: "red", ml: "22px", backgroundColor: "silver",
                          borderRadius: 20, lineHeight: 0.5, minWidth:"0px" }}
                          
                          // onClick={() => navigate(`/file/${item1?.id}/${item.campid}/${item?.phoneno}`)}
                          onClick={() => navigate(`/file/${item1?.id}/${item.campid}/${localStorage.getItem("userid")}`)}
                          
                          >
                        Delete
                      </Button>
                          ): item?.uniqueuserid === profileResponse?.uniqueuserid && video["type"]==="personal" ?  (
                            <Button 
                          sx={{ fontSize: 10,  color: "red", ml: "22px", backgroundColor: "silver",
                          borderRadius: 20, lineHeight: 0.5, minWidth:"0px" }}
                          
                          // onClick={() => navigate(`/file/personal/${item1?.id}/${item.campid}/${item?.phoneno}`)}
                          onClick={() => navigate(`/file/personal/${item1?.id}/${item.campid}/${localStorage.getItem("userid")}`)}
                          
                          >
                        Delete
                      </Button>
                          ): ""
                        
                        
}
                      </Typography>
                    {/* </Link> */}
                    
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          ))
        )}
      </Grid>
      
    </>
  );
};

export default VideoCard;
