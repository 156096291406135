import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { socialLogin } from "../redux/action/loginAction";
import { userProfile } from "../redux/action/UserProfileActions";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import firebase from "../services/FirebaseConfig";
import { Button } from "@mui/material";

import { registerUser } from "../redux/action/registerAction";
import { useLocation } from "react-router";
import OtpInput from "react-otp-input";
import axios from "axios";
import jwt from 'jwt-decode';

const useStyles = makeStyles((theme) => ({
  typography: {},
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  homeSreenManiContent: {
    padding: "30px 0 0 0",
    marginTop: "70px",
    [theme.breakpoints.up("sm")]: {
      padding: "30px 0 0 0",
      horizantalalignment: "center",
    },
  },
  otpTitle: {
    textAlign: "center",
    fontSize: "25px",
    fontWeight: "900",
    color: "#4a4b4d",
    fontFamily: "Raleway, Arial",
  },
  otpdes: {
    marginTop: "15px",
    color: "#c6c6c9",
    textAlign: "center",
    fontSize: "15px",
    fontWeight: "700",
    // fontFamily: "Roboto",
  },
  otpfield: {
    fontSize: "30px",
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "50px",
    border: "1px solid white",
    margin: "0px 2px 2px 2px",
    borderRadius: "15px",
    width: "50px !important",
    height: "45px !important",
    background: "#f2f2f2",
    display: "flex",
    padding: "10px 0 0 0",
    [theme.breakpoints.up("sm")]: {
      padding: "10px 0 0 0",
      width: "65px !important",
      height: "65px !important",
      borderRadius: "15px",
    },
  },
  bigField: {},
  button: {
    borderRadius: 30,
    fontSize: 14,
    textTransform: "uppercase",
    padding: 8,
    width: "60%",
    background: "#e1a23b",
    boxShadow: "none",
    fontWeight: "bold",
    marginTop: "40px",
    [theme.breakpoints.up("sm")]: {
      width: "70%",
    },
  },
}));

function Otp(props) {
  const [otp, setOtp] = React.useState("");
  const checking = "false";
  const navigate = useNavigate();
  const userid = localStorage.getItem("userid");
  const [otpAccepted, setOtpAccepted] = React.useState(false);
  const [loginAuthentication, setLoginSuccess] = React.useState(false);
  const [otpError, setOtpError] = React.useState(false);
  const location = useLocation();

  const { isSuccess: loginSuccess, response: login } = useSelector(
    (state) => state?.login
  );
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state?.profile
  );
  const { isSuccess: registerSuccess, response: register } = useSelector(
    (state) => state.register
  );

  const dispatch = useDispatch();
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  useEffect(() => {
    if (userid) {
      dispatch(userProfile(userid));
    }
    localStorage.setItem("profileurl", profileResponse?.profileurl)
  }, []);
  useEffect(() => {
    if (userid) {
      setLoginSuccess(true);
    }
  }, [userid]);

  useEffect(() => {
    if (userid) {
      localStorage.setItem("profileurl", profileResponse?.profileurl)
    }
    
  }, [userid]);

  var replacedLoginPhoneNumber = localStorage.getItem(
    "replacedLoginPhoneNumber"
  );

  const replacedRegisteredPhoneNumber = localStorage.getItem("registerNumber");
  const registerNumberRemovedPlus = replacedRegisteredPhoneNumber.replace(
    /\+/g,
    ""
  );

  const classes = useStyles();
  // const number = localStorage.getItem("loginNumber").slice("0", "4");
  var loginNumber = localStorage.getItem("loginNumber");

  let vcode = "";
  useEffect(() => {
    vcode = otp[0] + otp[1] + otp[2] + otp[3] + otp[4] + otp[5];
  }, [otp]);
  // const loginNumber=localStorage.getItem("loginNumber")
  const handleSendCode = () => {
    // Request to send OTP
    // setOtp(new Array(6).fill(""));

    var applicationVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container111"
    );
    firebase
      .auth()
      .signInWithPhoneNumber(
        loginNumber.length > 3 ? loginNumber : location?.state?.userid,
        applicationVerifier
      )
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        // NotificationManager.success(
        //   "A new Otp has been send to your phone number",
        //   "Successful!",
        //   2000
        // );
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const handleVerify = () => {
    // alert("CALLED")
    window.confirmationResult
      .confirm(vcode)
      .then((result) => {
        // User signed in successfully.
        // document.querySelector("label").textContent =
        //   localStorage.getItem("userid");
        localStorage.setItem(
          "userid",
          replacedLoginPhoneNumber.length > 3
            ? replacedLoginPhoneNumber
            : replacedRegisteredPhoneNumber
        );
        setOtpAccepted(true);
        //  navigate('/home');
        setLoginSuccess(true);
        if (profileSuccess) {
          // localStorage.setItem("token", profileResponse?.token)
          localStorage.setItem("token", JSON.stringify(jwt(profileResponse?.token)))
          // axios.defaults.headers.common['Authorization'] = `Bearer ${profileResponse?.token}`;
          
        }
        // if(localStorage.getItem('referralid') !== null && localStorage.getItem('referedcampid') !== null && localStorage.getItem('reflevel') !== null &&
        //         localStorage.getItem('referralid') !== "" && localStorage.getItem('referedcampid') !== "" && localStorage.getItem('reflevel') !== "") {
          
        //     navigate(`/participate/${localStorage.getItem('referedcampid')}/${profileResponse?.phoneno}`)
        // } 
        if (profileResponse?.orgrole?.toLowerCase() === "investor") {
          navigate("/create_yaag");
        } else if (profileResponse?.orgrole?.toLowerCase() === "national") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "volunteer") {
          // navigate("/volunteer");
          // navigate("/home");
          navigate("/kt");
        } else if (profileResponse?.orgrole?.toLowerCase() === "cyaagadmin") {
          navigate("/kt");
        } else if (profileResponse?.orgrole?.toLowerCase() === "sponsoradmin") {
          navigate("/home");
        } else if (profileResponse?.orgrole?.toLowerCase() === "admin") {
          navigate("/kt");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region1") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region2") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region3") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region4") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region5") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region6") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region7") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region8") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region9") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region10") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region11") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region12") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region13") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region14") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "region15") {
          navigate("/exec");
        } else if (profileResponse?.orgrole?.toLowerCase() === "campadmin") {
          navigate("/home");
        }
      })
      .catch((error) => {
        // console.log("error", error);
        setOtpError(true);
      });

    dispatch(
      userProfile(
        replacedLoginPhoneNumber.length > 3
          ? replacedLoginPhoneNumber
          : replacedRegisteredPhoneNumber
      )
    );
    dispatch(
      socialLogin(
        replacedLoginPhoneNumber.length > 3
          ? replacedLoginPhoneNumber
          : replacedRegisteredPhoneNumber
      )
    );
  };

  // useEffect(() => {
  //   if (registerSuccess && register.message === "inserted user") {
  //     localStorage.setItem(
  //       "userid",
  //       replacedLoginPhoneNumber.length > 3
  //         ? replacedLoginPhoneNumber
  //         : replacedRegisteredPhoneNumber
  //     );

  //     dispatch(userProfile(location?.state?.userid));
  //     setOtpAccepted(true);
  //     setLoginSuccess(true);
  //     if (localStorage.getItem("role") === "investor") {
  //       navigate("/create_yaag");
  //     } else if (localStorage.getItem("role") === "exec") {
  //       navigate("/exec");
  //     } else if (localStorage.getItem("role") === "validator") {
  //       navigate("/validator");
  //     } else if (localStorage.getItem("role") === "teamadmin") {
  //       navigate("/CreateTeam");
  //     } else {
  //       navigate("/home");
  //     }
  //   }
  // }, [registerSuccess]);
  return (
    <div>
      {/* <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14, color: "#1976d2" }}>
                Back
              </span>
            </Button> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          verticalAlign: "center",
          marginTop: "5%",
        }}
      >
        <div className={classes.homeSreenManiContent}>
          <div className={classes.otpTitle}>
            {/* We Have Sent an OTP to your Mobile */}
            An OTP is sent to your phone number
          </div>
          <div className={classes.otpdes}>
            Please check your phone number +
            {replacedLoginPhoneNumber.length > 3
              ? replacedLoginPhoneNumber
              : replacedRegisteredPhoneNumber}
            <br></br>
            to continue
          </div>

          {otpError && (
            <div style={{ color: "red", textAlign: "center" }}>
              You have entered incorrect OTP
              <br />
              <Button
                style={{
                  color: "#e1a23b",
                  paddingLeft: "3px",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
                onClick={handleSendCode}
              >
                Click Here
              </Button>
              <span style={{ color: "#000000" }}>to resend the OTP</span>
            </div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              verticalAlign: "center",
            }}
          >
            <OtpInput
              inputStyle={classes.otpfield}
              value={otp}
              onChange={(e) => setOtp(e)}
              numInputs={6}
              separator={<span> </span>}
              placeholder="*"
              isInputNum
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  // alert("CALLEDDDDD")
                  dispatch(handleVerify);
                }
              }}
              autoComplete="one-time-code"
            />
            {/* {otp.map((data, index) => {
              return (
                <input
                  inputProps={{
                    min: 0,
                    style: { textAlign: "center" },
                  }}
                  className={classes.otpfield}
                  type="numeric"
                  max={6}
                  size="medium"
                  placeholder="*"
                  value={data}
                  key={index}
                  variant="outlined"
                  onChange={(e) => handleChange(e.target, index)}
                  onFocus={(e) => e.target.select()}
                />
              );
            })} */}
          </div>
          <Typography
            onClick={() => navigate("/")}
            variant="body1"
            component="p"
            style={{ marginBottom: 8, margin: 12, cursor: "pointer" }}
          >
            {registerSuccess &&
            register.message === "cannot insert existing users"
              ? `You have been registered already, 
            please Sign in!`
              : ""}
          </Typography>
          {loginSuccess && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                verticalAlign: "center",
              }}
              id="recaptcha-container111"
            />
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              verticalAlign: "center",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={handleVerify}
              // onKeyPress={(event) => {
              //   if (event.key === "Enter") {
              //     {handleVerify};
              //   }
              // }}
            >
              Verify
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              verticalAlign: "center",
            }}
          >
            <div
              style={{ fontSize: "18px", marginTop: "15px", display: "flex" }}
            >
              <Button style={{ color: "#c6c6c9" }}>Didn't Recieve OTP? </Button>
              <Button
                style={{ color: "#e1a23b", paddingLeft: "3px" }}
                onClick={handleSendCode}
              >
                Click Here
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Otp;
