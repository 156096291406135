/** @format */

const MY_RANK_FETCHING_DATA = 'MY_RANK_FETCHING_DATA';
const MY_RANK_FETCHING_DATA_FAILURE = 'MY_RANK_FETCHING_DATA_FAILURE';
const MY_RANK_FETCHING_DATA_RESET = 'MY_RANK_FETCHING_DATA_RESET';
const MY_RANK_FETCHING_DATA_SUCCESS = 'MY_RANK_FETCHING_DATA_SUCCESS';
const MY_RANK_FETCHING_DATA_ATTEMPT = 'MY_RANK_FETCHING_DATA_ATTEMPT';
const myRank = payload => ({
	type: MY_RANK_FETCHING_DATA_ATTEMPT,
	payload: payload,
});

export { MY_RANK_FETCHING_DATA, MY_RANK_FETCHING_DATA_FAILURE, MY_RANK_FETCHING_DATA_RESET, MY_RANK_FETCHING_DATA_SUCCESS, MY_RANK_FETCHING_DATA_ATTEMPT, myRank };
