/** @format */

const REFERRAL_URL_FETCHING_DATA = 'REFERRAL_URL_FETCHING_DATA';
const REFERRAL_URL_FETCHING_DATA_FAILURE = 'REFERRAL_URL_FETCHING_DATA_FAILURE';
const REFERRAL_URL_FETCHING_DATA_RESET = 'REFERRAL_URL_FETCHING_DATA_RESET';
const REFERRAL_URL_FETCHING_DATA_SUCCESS = 'REFERRAL_URL_FETCHING_DATA_SUCCESS';
const REFERRAL_URL_FETCHING_DATA_ATTEMPT = 'REFERRAL_URL_FETCHING_DATA_ATTEMPT';
const referralUrl = payload => ({
	type: REFERRAL_URL_FETCHING_DATA_ATTEMPT,
	payload: payload,
});

export { REFERRAL_URL_FETCHING_DATA, REFERRAL_URL_FETCHING_DATA_FAILURE, REFERRAL_URL_FETCHING_DATA_RESET, REFERRAL_URL_FETCHING_DATA_SUCCESS, REFERRAL_URL_FETCHING_DATA_ATTEMPT, referralUrl };
