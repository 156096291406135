/** @format */

import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import {
  DialogActions,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
  Checkbox,
  Button,
} from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HealthInsurance from "../assets/health-insurance.png";
const useStyles = makeStyles((theme) => ({
  homecard: {
    margin: "0 7px 10px",
    // maxWidth: 250,
    borderRadius: 4,
    boxShadow: "1px 2px 10px rgba(0,0,0,0.2)",
    // border: "1px solid #F8B34C",
    marginTop: 15,
  },
  imageContainer: {
    boxShadow: "1px 1px 1px #000",
  },
  cardTitle: {
    // background: "#2C7973",
    color: "#000",

    zIndex: 99,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    boxShadow: "0px 5px 10px rgba(0,0,0,0.2)",
  },
  subtitle: {
    display: "flex",
    flexDirection: "row",
    width: "100%",

    alignItems: "center",
  },
  tagWrapperContainer: {
    width: "45%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  tagWrapper: {
    padding: 8,
    backgroundColor: "#E8E8E8",
    margin: 4,
    borderRadius: 4,
    display: "flex",
  },
  tags: { textTransform: "capitalize", fontSize: 9 },
  whitebackground: {
    backgroundColor: "rgba(255,255,255,0.5)",
    height: 60,
    width: "100%",
    position: "absolute",
    top: 160,
    zIndex: 90,
  },
  cardTitleText: {
    textTransform: "capitalize",
    fontSize: 26,
    fontWeight: "500",

    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      fontWeight: "500",
    },
  },
  cardImg: {
    borderRadius: "5px 5px 0 0",
    height: 220,
  },
  // maxpointsWrapper: { width: "5%" },
  maxpoints: {
    textTransform: "none",
    fontSize: 13,
  },
  flex: {
    display: "flex",
  },
  timeLeft: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  timeLeftText: {
    textTransform: "none",
    fontSize: 14,
  },
  timeLabelWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  timeLabelText: {
    fontSize: 10,
    color: "rgba(0,0,0,0.6)",
    textTransform: "none",
  },
  timeLabelSubText: {
    fontSize: 26,
    color: "rgba(0,0,0,1)",
    textTransform: "none",
  },

  blackBox: {
    background: "#000",
    display: "block",
    width: "100%",
    color: "#F8B34C",
    textAlign: "center",
    padding: 4,
    fontSize: 14,
    borderRadius: 4,
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  primaryBox: {
    background: "#2C7973",
    display: "block",
    width: "33.33%",
    color: "#fff",
    padding: 4,
    fontSize: 11,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  timeLabel: {
    fontSize: 14,
    color: "#fff",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  // timeLabelText: {
  //   fontSize: 14,
  //   [theme.breakpoints.down("sm")]: {
  //     fontSize: 10,
  //   },
  // },
  borderTop: {
    borderTop: "1px solid #fff",
  },
  borderLeft: {
    borderLeft: "1px solid #fff",
  },
  button: {
    backgroundColor: "#2D7A74",
    borderRadius: 10,
    fontSize: 14,
    color: "#fff",
    margin: "8px 4px",
    width: "170px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 8,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  adopterCountText: {
    fontSize: 12,
    textTransform: "none",
    fontWeight: "600",
  },
}));

export const HomeCard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };

  return (

    <Card className={classes.homecard} onClick={props.onClick}>
      {/* HHHHHHHHHHH {JSON.stringify(props.onClick)} */}
      {
        // <Link to="/volunteer" className={classes.imageContainer}>
        <Link to={
          props.userDetail &&
          `/campaigndetails/${props.data.campid}/${props.userDetail.orgrole}/${props.userDetail.organization}`}
          className={classes.imageContainer}>
          <CardMedia
            image={props.data.logo}
            title={props.data.name}
            className={classes.cardImg}
          />

        </Link>

      }
      <CardContent style={{ padding: 0 }}>
        {/* {JSON.stringify(props.campaignList)} */}
        <CardContent
          className={classes.cardTitle}
          style={{ padding: "4px 10px" }}
        >

          <span className={classes.cardTitleText}>{props.data.name}</span>
        </CardContent>

        <CardContent className={classes.timeLeft}>
          <div>

            <Typography className={classes.timeLeftText}>
              Ends in
            </Typography>
          </div>
          <div className={classes.timeLabelWrapper}>
            <div>
              <Typography className={classes.timeLabelText}>Days</Typography>
              <Typography className={classes.timeLabelSubText}>
                {props.data.dayrem} :
              </Typography>
            </div>
            <div>
              <Typography className={classes.timeLabelText}>Hours</Typography>
              <Typography className={classes.timeLabelSubText}>
                {props.data.hourrem} :
              </Typography>
            </div>
            <div>
              <Typography className={classes.timeLabelText}>Minutes</Typography>
              <Typography className={classes.timeLabelSubText}>
                {props.data.minrem}
              </Typography>
            </div>
          </div>

        </CardContent>

        {!props.campaignList && (
          <div className={classes.buttonWrapper}>
            {/* <div className={classes.tagWrapper}>
            <Typography className={classes.adopterCountText}>
              <span style={{ color: "#E88A00" }}>
                {props.data.adoptioncount}
              </span>
              /{props.data.targetadopt} Adopters
            </Typography>
          </div> */}
            {!props.isCampaignScreen && (
              <Button
                onClick={() =>
                  // navigate(`/campaigndetails/${props.data.campid}`)
                  navigate(`/campaigndetails/${props.data.campid}/${props.userDetail.orgrole}/${props.userDetail.organization}`)
                }
                style={{ backgroundColor: "grey" }}
                className={classes.button}
              >
                <img
                  alt="home"
                  src={HealthInsurance}
                // style={{ width: 24, height: 22 }}
                />
                <span
                  style={{ marginLeft: 12, fontSize: 12, borderBottomWidth: 0 }}
                >
                  Learn More
                </span>
              </Button>
            )}

          </div>

        )}
      </CardContent>
    </Card>
    //  </div>

  );
};
