/** @format */

const PROFILE_FETCHING_DATA = 'PROFILE_FETCHING_DATA';
const PROFILE_FETCHING_DATA_FAILURE = 'PROFILE_FETCHING_DATA_FAILURE';
const PROFILE_FETCHING_DATA_RESET = 'PROFILE_FETCHING_DATA_RESET';
const PROFILE_FETCHING_DATA_SUCCESS = 'PROFILE_FETCHING_DATA_SUCCESS';
const PROFILE_FETCHING_DATA_ATTEMPT = 'PROFILE_FETCHING_DATA_ATTEMPT';
const userProfile = payload => ({
	type: PROFILE_FETCHING_DATA_ATTEMPT,
	payload: payload,
});
const userProfileReset = () => ({
	type: PROFILE_FETCHING_DATA_RESET,
});

export { PROFILE_FETCHING_DATA, PROFILE_FETCHING_DATA_FAILURE, PROFILE_FETCHING_DATA_RESET, PROFILE_FETCHING_DATA_SUCCESS, PROFILE_FETCHING_DATA_ATTEMPT, userProfile, userProfileReset };
