import {
  makeStyles,
  MenuItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  Box,
} from "@material-ui/core";
import {
  Avatar,
  FormControl,
  Select,
  TextField,
  CircularProgress,
  Button,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomDrawer from "../../components/CustomHeader";
import ResponsiveDrawer from "../../components/Header";
import { getCity } from "../../redux/action/cityAction";
import { MenuProps, states } from "../../utils/constants";
import "react-datepicker/dist/react-datepicker.css";
import ErrorMessage from "../../components/ErrorMessage";
import _ from "lodash";
import Multiselect from "multiselect-react-dropdown";
import { getUserProfileImage } from "../../redux/action/userProfileImage";
import {
  BloodGroup,
  educationList,
  execMenuItems,
  Gender,
  volunteerMenuItems,
  skillSets,
  preferWorkingDays,
  hoursEveryWeek,
  interestAreaDict,
} from "../../utils/dict";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import DoneIcon from "@mui/icons-material/Done";
import { CopyRight } from "../../components/CopyRight";
// import PhoneNumberEmailChangeConfirmation from "../../components/PhoneNumberEmailChangeConfirmation";
import firebase from "../../services/FirebaseConfig";
// import PhoneNumberEmailChangeOTPDialog from "../../components/PhoneNumberEmailChangeOTPDialog";
import ConfirmationDialog from "../../components/ConfirmationDialog";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },
  large: {
    width: "150px !important",
    height: "150px !important",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  input: {
    // textTransform: "capitalize",
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    // color: "#949494",
    color: "black",
    fontSize: 15,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  emailinput: {
    // textTransform: "capitalize",
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    // color: "#949494",
    color: "black",
    fontSize: 15,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  logobutton: {
    // width: "15%",
    width: "30%",
    border: "none",
    // padding: 10,
    padding: 1,
    // display: "block",
    display: "flex",
    // borderRadius: 6,
    height: 40,
    // boxShadow: "0px 3px 6px #00000029",
    color: "#949494",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "block",
    fontSize: 16,
    marginTop: 16,
    padding: 4,
    minHeight: 38,
    marginBottom: 0,
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "block",
    fontSize: 16,
    marginTop: 16,
    // padding: 4,
    minHeight: 38,
    marginBottom: 0,
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    // width: "100%",
    //flexBasis: "33.3333%",
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroup1: {
    // width: "100%",
    //flexBasis: "33.3333%",
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroup2: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",
    flexDirection: "column",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "50px 0px 0px 16px",
    // pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
}));

const EditProfileScreen = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [cityValue, citySelectedValue] = React.useState("");
  const navigate = useNavigate();
  const [image, setImage] = React.useState(null);
  const [dateofBirth, setDateofBirth] = React.useState(
    new Date().setFullYear(new Date().getFullYear() - 5)
  );
  const [value5, setValue5] = React.useState(
    new Date().setFullYear(new Date().getFullYear() - 5)
  );

  const [values, setValues] = useState({
    // fullname: "",
    firstname: "",
    lastname: "",
    dob: new Date(),
    correspondenceAddress: "",
    emailId: "",
    phoneNumber: "",
    city: "",
    state: "",
    organization: "",
    highLevelEdu: "",
    preferDays: "",
    interestArea: "",
    hoursPerWeek: "",
    orgRole: "",
    bloodGroup: "",
    skill: "",
    expertise: "",
  });
  const [response, setResponse] = useState({});
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const [selected, setSelected] = React.useState([]);
  //const [selected, setSelected] = React.useState([]);
  const [skillsError, setskillsError] = React.useState(false);
  const [interestError, setinterestError] = React.useState(false);
  const [address, setAddress] = React.useState("");
  const [correspondenceaddress, setcorrespendeceaddress] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [area, setArea] = React.useState("");
  const [added, setAdded] = React.useState("no");
  const [state, setState] = React.useState("");
  const [city, setCity] = React.useState("");
  const [displayConfirmationModal, setDisplayConfirmationModal] =  React.useState(false);
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleSelect = async (value) => {
    // console.log(value);
    const results = await geocodeByAddress(value);
    // console.log(results[0]["formatted_address"]);
    setAddress(results[0]["formatted_address"]);

    // console.log(value);
  };

  const handleFieldChange = (field, e) => {
    // if (changeinphone) {
    //   // alert(changeinphone);
    //   setDisplayPhoneNumberEmailChangeModal(true);
    //   setChangeinphone(false);
    // } else {
    //   setDisplayPhoneNumberEmailChangeModal(false);
    // }
    setResponse({});
    setValues({
      ...values,
      [field]: field === "dob" ? e : e.target.value,
    });
  };

  const [changeinphone, setChangeinphone] = useState(false);
  const [originalphone, setOriginalphone] = useState("");
  const [errorOnSave, setErrorOnSave] = useState("");

  const handlePhonenoChange = (field, e) => {
    
   
    if (values?.phoneNumber != originalphone) {
      setChangeinphone(true);
      // setDisplayPhoneNumberEmailChangeModal(true);
      // setResponse({});
      // setValues({
      //   ...values,
      //   [field]: field === "dob" ? e : e.target.value,
      // });

    }
    // if(changeinphone){
    //   handleClickLogin();
    // }
    
  };

  const handlePhonenoChange1 = (field, e) => {
    
  
    if (values?.phoneNumber != originalphone) {
      // setChangeinphone(true);
      // setDisplayPhoneNumberEmailChangeModal(true);
      // setResponse({});
      // setValues({
      //   ...values,
      //   [field]: field === "dob" ? e : e.target.value,
      // });
      handleClickLogin();
    }
    
  };

  function subtractYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);

    return date;
  }

  useEffect(() => {
    if (profileResponse?.interestarea) {
      setSelectedInterest(profileResponse?.interestarea);
    }
    if (profileResponse?.skill) {
      setSelectedSkills(profileResponse?.skill);
    }
    // if (profileResponse?.fullname) {
    if (profileResponse?.firstname) {
      setValues({
        // fullname: profileResponse?.fullname,
        firstname: profileResponse?.firstname,
        lastname: profileResponse?.lastname,
        // dob: profileResponse?.dob,
        // dob: moment(profileResponse?.dob).format("DD-MM-YYYY"),
        dob: moment(profileResponse?.dob, "MM-DD-YYYY"),
        gender: profileResponse?.gender,
        correspondenceAddress: profileResponse?.correspondenceaddress,
        emailId: profileResponse?.emailid,
        phoneNumber: profileResponse?.phoneno,
        country: profileResponse?.country,
        city: profileResponse?.city,
        state: profileResponse?.state,
        area: profileResponse?.area,
        organization: profileResponse?.organization,
        orgRole: profileResponse?.orgrole,
        highLevelEdu: profileResponse?.highlvledu,
        preferDays: profileResponse?.preferday,
        interestArea: profileResponse?.interestarea,
        hoursPerWeek: profileResponse?.hoursperweek,
        bloodGroup: profileResponse?.bloodgroup,
        skill: profileResponse?.skill,
        expertise: profileResponse?.expertise,
      });
      setCountry(profileResponse?.country);
      setState(profileResponse?.state);
      setCity(profileResponse?.city);
      setArea(profileResponse?.area);
      setOriginalphone(profileResponse?.phoneno);
    }
  }, [profileResponse]);

  const handleSave = async () => {
    try {
    
      if (
        values?.firstname != "" &&
        values?.lastname != "" &&
        values?.correspondenceAddress != "" &&
        values?.emailId != "" &&
        country != "" &&
        state != ""
      ) {
      const res = await axios.put(`/user/profile`, {
        // fullname: values?.fullname,
        firstname: values?.firstname,
        lastname: values?.lastname,
        dob: moment(values?.dob).format("DD-MM-yyyy"),
        gender: values?.gender,
        //dob: moment(values?.dob).format("yyyy-MM-yyyy"),
        phoneno: values?.phoneNumber,
        correspondenceaddress: values?.correspondenceAddress,
        emailid: values?.emailId,

        country: country,
        area: area,
        city: city,
        state: state,
        organization: values?.organization,
        highlvledu: values?.highLevelEdu,
        preferday: values?.preferDays,
        hoursperweek: values?.hoursPerWeek,
        // interest: values?.interest,
        interestarea: selectedInterest,
        orgrole: values?.orgRole,
        bloodgroup: values?.bloodGroup,
        //skill: values.skill,
        skill: selectedSkills,
        expertise: values?.expertise,
      });
      setResponse(res?.data);
      setDisplayConfirmationModal(true);
      setErrorOnSave("");
      return res;
    } else {
      setErrorOnSave("Please do not leave any field empty and click on Save");
      setDisplayConfirmationModal(false);
    }
    } catch (e) {
      // console.log("error", e);
    }
  };
  const [selectedSkills, setSelectedSkills] = React.useState([]);
  const [selectedInterest, setSelectedInterest] = React.useState([]);

  const handleChangeLocation = (id, e, field, index) => {
    const values = [...location];
    values[index][field] = e.target.value;
    setLocation(values);
  };

  const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
    (state) => state.cities
  );

  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "" },
  ]);

  const handleChangeSkills = (event, index) => {
    let value = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    this.setState({ values: value });
  };

  const handleChangeInterest = (event, index) => {
    let value = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    this.setState({ values: value });
  };

  // const [selected, setSelected] = React.useState([]);
  const [selected1, setSelected1] = React.useState([]);

  
  //handle key press event
  const handleKeypress = (e) => {
    e.preventDefault();
    return false;
  };

  /* For profile image handling */

  const [loading, setLoading] = React.useState(false);
  // const [image, setImage] = React.useState(null);
  const [show, setShow] = React.useState(false);
  const [displayPhoneNumberEmailChangeModal, setDisplayPhoneNumberEmailChangeModal] =
    useState(false);

  const [displayPhoneNumberEmailOTP, setDisplayPhoneNumberEmailOTP] = useState(false);

  const {
    isSuccess: profileImageSuccess,
    response: profileImageResponse,
    isError: profileImageError,
  } = useSelector((state) => state.profileImage);

  const handleChangeFile = (e) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("profilephoto", e.target.files[0]);
    dispatch(
      getUserProfileImage({ userId: localStorage.getItem("userid"), formData })
    );
    setImage(e.target.files[0]);
  };

  React.useEffect(() => {
    if (profileImageSuccess || profileImageError) {
      setLoading(false);
    }
  }, [profileImageSuccess, profileImageError]);

  // React.useEffect(() => {
  //   dispatch(userProfile(localStorage.getItem("userid")));
  // }, []);

  const updatelocation = (address) => {
    // console.log("index");
    // console.log(index);
  
    // if (!address) {
    //     setCountryError(true);
    // } else {
    //     setCountryError(false);
    //     setAdded("yes");
    // }

    if (address) {
      setCountry(
        String(address.split(",")[address.split(",").length - 1]).trim(" ")
      );
      setState(
        String(address.split(",")[address.split(",").length - 2]).trim(" ")
      );
      setCity(
        String(address.split(",")[address.split(",").length - 3]).trim(" ")
      );
      setArea(
        String(address.split(",")[address.split(",").length - 4]).trim(" ")
      );
      setAdded("yes");
    }
  };

  // Hide the modal
  const hidePhoneNumberEmailChangeModalModal = () => {
    setDisplayPhoneNumberEmailChangeModal(false);
  };

  const hidePhoneNumberEmailOTP = () => {
    setDisplayPhoneNumberEmailOTP(false);
  };


  const phoneEmailChangeConfirm = async () => {
    // setDisplayConfirmationModal(true);
   
    handleClickLogin();
  };
  
  const handleClickLogin1 = () => {
   
    firebase.auth().settings.appVerificationDisabledForTesting = true;
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container2", {
        size: "invisible",
        'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        
          navigate("/otp");
        }
    }
    );
   
    // firebase.auth().settings.appVerificationDisabledForTesting = true;
    // console.log("no captcha");
    // var testVerificationCode = "12345";
    // var appVerifier = window.recaptchaVerifier;
    // firebase
    //   .auth()
    //   .signInWithPhoneNumber(values?.phoneNumber, appVerifier)
    //   // .signInWithPhoneNumber(values?.phoneNumber)
    //   .then(function (confirmationResult){
    //     // window.confirmationResult = confirmationResult;
    //     window.confirmationResult.confirm(testVerificationCode)
    //     navigate("/otp");
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  const handleClickLogin = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container1"
    );
    var appVerifier = window.recaptchaVerifier;
   
    firebase
      .auth()
      .signInWithPhoneNumber(values?.phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        navigate("/otp");
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  return (
    <div className="wrapper">
      
      
        {/* {profileResponse?.orgrole?.toLowerCase() === "hyphenadmin" ||
        profileResponse?.orgrole?.toLowerCase() === "admin" ? (
        <ResponsiveDrawer {...props} />
      ) : (
        <CustomDrawer
          menuItems={
            profileResponse?.orgrole?.toLowerCase() === "volunteer"
              ? volunteerMenuItems
              : execMenuItems
          }
          {...props}
        />
      )} */}

        <ResponsiveDrawer {...props} />
        <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            {/* <main
        onClick={() => {
          props.setNotificationShow(false);
        }}
        className={classes.homeSreenMainContent}
        id="homepage-main"
      > */}
            <Button onClick={() => navigate("/newprofile/"+localStorage.getItem("userProfileUrl"))} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div>
            <span
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Edit My Profile
            </span>
            </div>
            {profileSuccess &&
              (loading ? (
                <CircularProgress color="inherit" size={16} />
              ) : (
                <div
                  style={{
                    padding: "10px 0",
                    // height: "50%",
                    // boxShadow: "1px 1px 1px 5px #f0f0f0",
                    borderRadius: 30,
                    marginTop: 16,
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: "#fff"
                    backgroundColor: "#FFFFFF",
                    // width: "50%",
                    border: "1px solid #dadce0",
                  }}
                >
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      // justifyContent= "center"
                    >
                      <Avatar
                        src={
                          image
                            ? URL.createObjectURL(image)
                            : profileResponse?.profilephoto
                        }
                        className={classes.large}
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      />
                    </IconButton>
                  </label>
                  <input
                    name="Upload your image"
                    accept="image/*"
                    // className={classes.logobutton}
                    id="icon-button-file"
                    type="file"
                    onChange={handleChangeFile}
                    width="20"
                    style={{border:"1px solid"}}
                  />
                  
                </div>
              ))}
            <div
              style={{
                padding: "20px 0",
                height: "100%",
                boxShadow: "1px 1px 1px 5px #f0f0f0",
                borderRadius: 8,
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
                float:"left",
                width: "100%"
              }}
            >
              
              <form>
                <div
                  style={{
                    // border: "1px solid #dadce0",
                    borderRadius: 8,
                    padding: "13px 30px 40px 30px",
                  }}
                >
                  <div
                    // style={{
                    //   paddingBottom: 32,
                    //   display: "flex",
                    //   flexDirection: "row",
                    //   flexWrap: "wrap",
                    //   paddingLeft: 60,
                    //   paddingRight: 60,
                    // }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <div className={classes.formGroup}>
                      <label className={classes.label}>First Name</label>
                      <input
                        value={values?.firstname}
                        type="text"
                        className={classes.input}
                        onChange={(e) => handleFieldChange("firstname", e)}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label className={classes.label}>Last Name</label>
                      <input
                        value={values?.lastname}
                        type="text"
                        className={classes.input}
                        onChange={(e) => handleFieldChange("lastname", e)}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label className={classes.label}>Date of Birth</label>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          value={values?.dob}
                          onChange={(newValue) => {
                            handleFieldChange("dob", newValue);
                          }}
                          // maxDate={new Date()}
                          maxDate={subtractYears(5)}
                          renderInput={(params) => (
                            <TextField
                              type="text"
                              onKeyDown={(e) => {
                                handleKeypress(e);
                              }}
                              style={{
                                width: "100%",
                                height: 56,
                                color: "#949494",
                                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                              }}
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className={classes.formGroup}>
                      <label className={classes.label}>Blood Group</label>
                      <Select
                        style={{
                          width: "100%",
                          fontSize: 14,
                          height: 56,

                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                        }}
                        value={values?.bloodGroup}
                        onChange={(e) => handleFieldChange("bloodGroup", e)}
                      >
                        {BloodGroup.map((item, index) => (
                          <MenuItem key={item.value} value={item?.value}>
                            {item.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className={classes.formGroup}>
                      <label
                        htmlFor="create-yaad--title"
                        className={classes.label}
                      >
                        Highest Level of Education
                      </label>
                      <Select
                        style={{
                          width: "100%",
                          fontSize: 14,
                          height: 56,

                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                        }}
                        value={values?.highLevelEdu}
                        onChange={(e) => handleFieldChange("highLevelEdu", e)}
                      >
                        {educationList.map((item, index) => (
                          <MenuItem key={item.value} value={item?.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className={classes.formGroup}>
                      <label className={classes.label}>Hours Per Week</label>
                      <Select
                        style={{
                          width: "100%",
                          fontSize: 14,
                          height: 56,
                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                        }}
                        value={values?.hoursPerWeek}
                        onChange={(e) => handleFieldChange("hoursPerWeek", e)}
                      >
                        {hoursEveryWeek.map((item, index) => (
                          <MenuItem key={item.value} value={item?.value}>
                            {item.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>

                    <div className={classes.formGroup}>
                      <label
                        htmlFor="create-yaad--title"
                        className={classes.label}
                      >
                        Correspondence Address
                      </label>
                      <input
                        value={values?.correspondenceAddress}
                        type="text"
                        accept="image/*"
                        id="create-yaad--title"
                        className={classes.input}
                        onChange={(e) =>
                          handleFieldChange("correspondenceAddress", e)
                        }
                      />
                    </div>

                    <div className={classes.formGroup}>
                      <label className={classes.label}>Email ID</label>
                      <input
                        value={values?.emailId}
                        type="text"
                        className={classes.emailinput}
                        autoCapitalize="none"
                        onChange={(e) => handleFieldChange("emailId", e)}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label
                        // htmlFor="create-yaad--title"
                        className={classes.label}
                      >
                        Phone no <Button style={{fontSize:"9px"}} onClick={() => navigate(`/phonenochange/${values?.phoneNumber}`)}>Click here to change </Button>
                      </label>
                      
                      <input
                        disabled
                        value={values?.phoneNumber}
                        type="text"
                        className={classes.input}
                        onChange={(e) => handleFieldChange("phoneNumber", e)}
                        onBlur={() => {
                          if (changeinphone) {
                            setDisplayPhoneNumberEmailChangeModal(true);
                            setChangeinphone(false);
                          } else {
                            setDisplayPhoneNumberEmailChangeModal(false);
                          }
                        }}
                        onMouseOut={(e) => handlePhonenoChange("phoneNumber", e)}
                        // onKeyUp={(e) => handlePhonenoChange1("phoneNumber", e)}
                      />
                    </div>
                    <div id="recaptcha-container1" />

                    <div className={classes.formGroup}>
                      <label
                        htmlFor="create-yaad--title"
                        className={classes.label}
                      >
                        Prefer Day
                      </label>
                      <Select
                        style={{
                          width: "100%",
                          fontSize: 14,
                          height: 56,
                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                        }}
                        value={values?.preferDays}
                        onChange={(e) => handleFieldChange("preferDays", e)}
                      >
                        {preferWorkingDays.map((item, index) => (
                          <MenuItem key={item.value} value={item?.value}>
                            {item.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>

                    {/* <div className={classes.formGroup} style={{ width: "75%" }}> */}
                    <div className={classes.formGroup}>
                      <label
                        htmlFor="create-yaad--title"
                        className={classes.label}
                      >
                        Interest Area
                      </label>
                      <Multiselect
                        displayValue="interest"
                        showCheckbox
                        selectedValues={profileResponse?.interestarea}
                        //  selectedValues={selectedInterest}
                        options={interestAreaDict}
                        onSelect={(event) => {
                          setSelectedInterest(event);
                        }}
                        onRemove={(event) => {
                          setSelectedInterest(event);
                        }}
                        value={selectedInterest}
                        style={{
                          multiselectContainer: {
                            boxShadow: "0px 3px 6px #00000029",
                          },
                          searchBox: {
                            minHeight: 54,
                            PaddingTop: 10,
                          },
                        }}
                      />
                    </div>
                    {/* <div className={classes.formGroup} style={{ width: "50%" }}> */}
                    
                    <div className={classes.formGroup}>
                      <label
                        htmlFor="create-yaad--title"
                        className={classes.label}
                      >
                        Skills
                      </label>
                      <Multiselect
                        displayValue="skill"
                        showCheckbox
                        selectedValues={profileResponse?.skill}
                        options={skillSets}
                        onSelect={(event) => {
                          setSelectedSkills(event);
                        }}
                        onRemove={(event) => {
                          setSelectedSkills(event);
                        }}
                        value={selectedSkills}
                        style={{
                          multiselectContainer: {
                            boxShadow: "0px 3px 6px #00000029",
                          },
                          searchBox: {
                            minHeight: 54,
                            PaddingTop: 10,
                          },
                        }}
                      />
                    </div>

                    <div className={classes.formGroup}>
                  <label htmlFor="create-yaad--title" className={classes.label}>
                    Expertise (200 words)
                    
                  </label>
                  {/* <input */}
                  <textarea
                    value={values?.expertise}
                    type="text"
                    className={classes.input}
                    placeholder="Your answer"
                    maxLength="200"
                    wrap="hard"
                    cols="10"
                    rows="10"
                    onChange={(e) => handleFieldChange("expertise", e)}
                  />
                </div>

                    <div className={classes.formGroup2}>
                      <label
                        htmlFor="create-yaad--title"
                        className={classes.label2}
                      >
                        Edit your location{" "}
                        <span
                          style={{
                            paddingLeft: 0,
                            fontSize: 16,
                            fontWeight: 300,
                            fontStyle: "italic",
                          }}
                        >
                          {" "}
                          (Search for location, select and click on Confirm.)
                        </span>
                      </label>
                      {/* <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>Enter your location </span>  */}

                      <PlacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelect}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div style={{ padding: "25px 0px 0px 0px" }}>
                            <TextField
                              value={correspondenceaddress}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment>
                                    <IconButton>
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              {...getInputProps({
                                placeholder: "Search for address..",
                                autoFocus: false,
                              })}
                            />
                            <div>
                              {" "}
                              {loading ? <div>...loading...</div> : null}
                            </div>
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "blue"
                                  : "white",
                              };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                    {/* <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        width: "100%",
                        transition:
                          "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
                        backgroundColor: "#fff",
                      }}
                    > */}
                    <div
                    // style={{
                    //   paddingBottom: 32,
                    //   display: "flex",
                    //   flexDirection: "row",
                    //   flexWrap: "wrap",
                    //   paddingLeft: 60,
                    //   paddingRight: 60,
                    // }}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                      <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                          Country
                          {/* <RedStar /> */}
                        </label>
                        {/* <span style={{backgroundColor: "#9e9e9e", width:"100%"}}> */}
                        {address
                          ? address.split(",")[address.split(",").length - 1]
                          : values?.country}
                          {/* </span> */}
                      </div>

                      <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                          State
                          {/* <RedStar /> */}
                        </label>
                        {address
                          ? address.split(",")[address.split(",").length - 2]
                          : values?.state}
                      </div>

                      <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                          City/Town/Village
                          {/* <RedStar /> */}
                        </label>
                        {address
                          ? address.split(",")[address.split(",").length - 3]
                          : values?.city}
                      </div>

                      <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                          Area
                          {/* <RedStar /> */}
                        </label>
                        {address
                          ? address.split(",")[address.split(",").length - 4]
                          : values?.area}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      // display: "flex",
                      // flexDirection: "row",
                      // flexWrap: "wrap",
                      // width: "100%",
                      transition:
                        "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
                      backgroundColor: "#fff",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        padding: "20px 0px 0px 0px",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => updatelocation(address)}
                        // className="btn save-btn"
                        disableElevation
                        style={{
                          // marginTop: 1,
                          width: "86px",
                          height: "35px",
                          background: "#f5eceb",
                          // borderRadius: 20,
                          // color: "#fff",
                          color: "green",
                          fontSize: 16,
                          // borderBlockColor: "orange",
                          // borderBlock: 5,
                          fontFamily: "Poppins !important",
                        }}
                      >
                        Confirm
                      </Button>
                      {added == "yes" ? (
                        <DoneIcon
                          style={{ color: "green", fontSize: "30px" }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      marginTop: "10px",
                      // width: "100%",
                      // transition:
                      //   "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
                      // backgroundColor: "#fff",
                      // padding: "0px 0px 0px 66px",
                    }}
                  >
                    <div className={classes.formGroup} style={{ width: "100%" }}>
                      <label
                        htmlFor="create-yaad--title"
                        className={classes.label}
                      >
                        House/Door/Flat No.
                      </label>
                      <input
                        // disabled
                        value={values?.correspondenceAddress}
                        type="text"
                        accept="image/*"
                        id="create-yaad--title"
                        className={classes.input}
                        onChange={(e) =>
                          handleFieldChange("correspondenceAddress", e)
                        }
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Button
                  onClick={() => handleSave()}
                  style={{
                    marginTop: 8,
                    width: "200px",
                    //width:"100%",
                    background: "#E88A00",
                    borderRadius: 20,
                    color: "#fff",
                    fontFamily: "Poppins !important",
                  }}
                >
                  <span>Save</span>
                </Button>
                <br />

                <Button
                  onClick={() => navigate("/profile")}
                  style={{
                    marginTop: 8,
                    width: "200px",
                    background: "grey",
                    borderRadius: 20,
                    color: "#fff",
                    fontFamily: "Poppins !important",
                    marginBottom: 40,
                  }}
                >
                  <span>Cancel</span>
                </Button>
              </div>
              {errorOnSave && (
                <span
                  style={{
                    color: "red",
                    textAlign: "center",
                    display: "block",
                  }}
                >
                  Please do not keep any field empty
                </span>
              )}
              {/* {profileSuccess && (
                <span
                  style={{
                    color: "green",
                    textAlign: "center",
                    display: "block",
                  }}
                >
                  {response?.message}
                </span>
              )}
              {!profileSuccess && (
                <span
                  style={{
                    color: "red",
                    textAlign: "center",
                    display: "block",
                  }}
                >
                  {response?.message}
                </span>
              )} */}
            </div>
          </div>
        </div>
        {/* </main> */}
        <CopyRight />
        <ConfirmationDialog
          showModal={displayConfirmationModal}
          // confirmModal={handleRedeem}
          hideModal={hideConfirmationModal}
          // message={"You do not have enough points to redeem"}
          
          message={response?.message}
          
        />
        {/* <PhoneNumberEmailChangeConfirmation
          showModal={displayPhoneNumberEmailChangeModal}
          confirmModal={phoneEmailChangeConfirm}
          hideModal={hidePhoneNumberEmailChangeModalModal}
          message={"Are you sure you want to update your phone number?"}
        /> */}
        {/* <PhoneNumberEmailChangeOTPDialog
          phoneValue = {values?.phoneNumber}
          // showModal={displayPhoneNumberEmailOTP}
          // confirmModal={handleRedeem}
          // hideModal={hidePhoneNumberEmailOTP}
          // message={"Are you sure you want to update your phone number?"}


        /> */}
      </section>
    </div>
  );
};

export default EditProfileScreen;
