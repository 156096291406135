/** @format */

const REWARD_REDEEM_FULFILMENT_FETCHING_DATA =
  "REWARD_REDEEM_FULFILMENT_FETCHING_DATA";
const REWARD_REDEEM_FULFILMENT_FETCHING_DATA_FAILURE =
  "REWARD_REDEEM_FULFILMENT_FETCHING_DATA_FAILURE";
const REWARD_REDEEM_FULFILMENT_FETCHING_DATA_RESET =
  "REWARD_REDEEM_FULFILMENT_FETCHING_DATA_RESET";
const REWARD_REDEEM_FULFILMENT_FETCHING_DATA_SUCCESS =
  "REWARD_REDEEM_FULFILMENT_FETCHING_DATA_SUCCESS";
const REWARD_REDEEM_FULFILMENT_FETCHING_DATA_ATTEMPT =
  "REWARD_REDEEM_FULFILMENT_FETCHING_DATA_ATTEMPT";
const rewardRedeemFulfilment = (payload) => ({
  type: REWARD_REDEEM_FULFILMENT_FETCHING_DATA_ATTEMPT,
  payload: payload,
});
const rewardRedeemFulfilmentReset = () => ({
  type: REWARD_REDEEM_FULFILMENT_FETCHING_DATA_RESET,
});

export {
  REWARD_REDEEM_FULFILMENT_FETCHING_DATA,
  REWARD_REDEEM_FULFILMENT_FETCHING_DATA_FAILURE,
  REWARD_REDEEM_FULFILMENT_FETCHING_DATA_RESET,
  REWARD_REDEEM_FULFILMENT_FETCHING_DATA_SUCCESS,
  REWARD_REDEEM_FULFILMENT_FETCHING_DATA_ATTEMPT,
  rewardRedeemFulfilment,
  rewardRedeemFulfilmentReset,
};