/** @format */

import { call, put } from "redux-saga/effects";
import { get } from "lodash";

import {
  GET_CITY_FETCHING_DATA,
  GET_CITY_FETCHING_DATA_SUCCESS,
  GET_CITY_FETCHING_DATA_FAILURE,
} from "../action/cityAction";
import { Api } from "../../services";

const myAPI = new Api();

export function* getCity(action) {
  
  yield put({
    type: GET_CITY_FETCHING_DATA,
  });

  try {
    
    const response = yield call(myAPI.getCity, action.payload);
    const payload = get(response, "data");
    yield put({
      type: GET_CITY_FETCHING_DATA_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({ type: GET_CITY_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}
