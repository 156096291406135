import { Button, makeStyles, Checkbox } from "@material-ui/core";
import { Avatar } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustomDrawer from "../../components/CustomHeader";
import ResponsiveDrawer from "../../components/Header";

import TopHeaderScreen from "../../components/TopHeader1";
import { CopyRight } from "../../components/CopyRight";
import { ColorModeContext, useMode, tokens } from "../../theme";
import { isEmpty, isArray, size } from "lodash";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import SocialProfileExpertizeImage from "../../assets/pro-p.png";
import { userProfile } from "../../redux/action/UserProfileActions";
import axios from "axios";
import moment from "moment";
import PeopleIcon from "@mui/icons-material/People";
import jsPDF from "jspdf";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
// import Diversity3Icon from "@mui/icons-material/Diversity3";

// import AvatarProfile from "../../assets/broken-image.jpg";
// import Markdown from 'markdown-to-jsx';
import html2canvas from "html2canvas";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ShareIcon from "@mui/icons-material/Share";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "0px 30px 100px 25px",
      background: "#F5F8FB",
    },
  },
  large: {
    width: "150px !important",
    height: "150px !important",
  },
  input: {
    // textTransform: "capitalize",
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    // boxShadow: "0px 3px 6px #00000029",
    // backgroundColor: "#fff",
    backgroundColor: "#FFFFFF",
    // color: "#949494",
    color: "#0F0F0F",
    fontSize: 17,
    // fontWeight: "520",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  input1: {
    // textTransform: "capitalize",
    width: "25%",
    border: "none",
    padding: 7,
    display: "block",
    borderRadius: 6,
    height: 0,
    // boxShadow: "0px 3px 6px #00000029",
    // backgroundColor: "#fff",
    backgroundColor: "#FFFFFF",
    // color: "#949494",
    color: "#0F0F0F",
    fontSize: 17,
    // fontWeight: "520",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
    marginLeft: 10,
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "block",

    fontSize: 17,
    marginTop: 16,
    padding: 10,
    fontWeight: "500",
    lineHeight: "15px",
    minHeight: 45,
    paddingBottom: 0,
    color: "#808080",
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: 14,
    // },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    // backgroundColor: "#fff",
    backgroundColor: "#FFFFFF",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    marginBottom: 10,
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  pageHeadingIcon: {
    //   fontSize: 25,
    // color: theme.palette.secondary.main,
    color: "#1976d2",
    // marginLeft: 8,
  },
}));

const SocialProfileExternal = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationPath = useLocation();
  const [theme, colorMode] = useMode();
  const [image, setImage] = React.useState(null);
  const [searchedUser, setSearchedUser] = React.useState("");
  const [activityList, setActivityList] = React.useState("");
  const [mycommunity, setmycommunity] = React.useState([]);
  const [url, setURL] = React.useState([]);
  const browserurl = window.location.href;

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  // console.log(browserurl.split("/"))
  // console.log(browserurl.replace("newprofile","cm"));
  const getSearchedUserProfile = async () => {
    try {
      const id = locationPath?.pathname.split("/")?.[2];
     

      const res = await axios.get(
        `/profile/user/${locationPath?.pathname.split("/")?.[2]}`
      );
     
      setSearchedUser(res?.data);
      return res;
    } catch (err) {
   
      throw err;
    }
  };

  React.useEffect(() => {
    getSearchedUserProfile();
  }, []);

 
  const getActivityList = async () => {
    try {
      const res = await axios.get(
        `/activity/user/${locationPath?.pathname.split("/")?.[2]}`
      );
      
      setActivityList(res?.data);
      return res;
    } catch (err) {
      
      throw err;
    }
  };
  

  React.useEffect(() => {
    getActivityList();
  }, []);

  const getMyCommunityDetails = async () => {
    try {
      //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);
      const res = await axios.get(
        // `adopter_dashboard/mycommunity/${localStorage.getItem("userid")}`
        `adopter_dashboard/mycommunity/${
          locationPath?.pathname.split("/")?.[2]
        }`
      );
      // console.log(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getMyCommunityDetails().then((data) => {
      setmycommunity(data);
    });
  }, []);

  useEffect(() => {
    // window.location.reload(true);
    // navigate("../../../public/index.html");
  }, []);

  // const getFileURL = async () => {
  //   const name = "cyaag.pdf"
  //   try {
  //     //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);
  //     // const id = locationPath?.pathname.split("/")?.[2];

  //       const res = await axios.get(
  //         `camp/files/file1/${localStorage.getItem("userid")}/${name}`
  //       );
  //       console.log(res?.data);
  //       setURL(res?.data);
  //       return res;

  //   } catch (err) {
  //     throw err;
  //   }
  // };



  //  console.log("profileResponse len = ", String(profileResponse).length());
  return (
    <div className="wrapper">
      {/* {profileResponse?.orgrole?.toLowerCase() === "hyphenadmin" ||
      profileResponse?.orgrole?.toLowerCase() === "admin" ? (
      <ResponsiveDrawer {...props} />
    ) : (
      <CustomDrawer
        menuItems={
          profileResponse?.orgrole?.toLowerCase() === "volunteer"
            ? volunteerMenuItems
            : execMenuItems
        }
        {...props}
      />
    )} */}
      {isArray(profileResponse) && <ResponsiveDrawer {...props} />}
      <section className="containers">
        {isArray(profileResponse) && (
          <ColorModeContext.Provider value={colorMode}>
            <TopHeaderScreen {...props} />
          </ColorModeContext.Provider>
        )}
        <div class="content content-wrap">
          <div class="content-mrg">
            {isArray(profileResponse) && (
              <Button onClick={() => navigate(-1)} className={classes.backBtn}>
                <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                <span
                  style={{ paddingLeft: 3, fontSize: 14, color: "#1976d2" }}
                >
                  Back
                </span>
              </Button>
            )}
            <div>
              {searchedUser?.[0]?.phoneno === localStorage.getItem("userid") ? (
                <div class="">
                  <div class="container">
                    <div class="">
                      <div class="new-profile">
                        <div class="row">
                          <div class="col-md-7">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="new-pro-first-wrap">
                                  {!searchedUser?.[0]?.profilephoto ===
                                    undefined ||
                                  searchedUser?.[0]?.profilephoto != "" ? (
                                    <img
                                      src={searchedUser?.[0]?.profilephoto}
                                      class="img-fluid new-profile-image"
                                    />
                                  ) : (
                                    <Avatar
                                      class="img-fluid new-profile-image"
                                      style={{
                                        width: "100px",
                                        borderRadius: "0",
                                        height: "auto",
                                        verticalAlign: "super",
                                      }}
                                      src="/broken-image.jpg"
                                    />
                                    //   <img
                                    //   src="/broken-image.jpg"
                                    //   class="img-fluid new-profile-image"
                                    // />
                                  )}
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="new-pro-name-sec">
                                  <h4>
                                    {searchedUser?.[0]?.firstname}{" "}
                                    {searchedUser?.[0]?.lastname}
                                  </h4>
                                  <span
                                    class="float-r"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                  >
                                    <Button
                                      onClick={() => navigate("/editProfile")}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-edit"
                                      >
                                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                      </svg>
                                    </Button>
                                  </span>
                                  <div
                                    id="contentz"
                                    clasnew-pro-name-secs="basic-detail"
                                  >
                                    <div id="horz-list">
                                      <ul>
                                        <li>
                                          <a
                                            href="#"
                                            style={{ cursor: "initial" }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="feather feather-briefcase"
                                              style={{
                                                color: "#808080",
                                                cursor: "initial",
                                              }}
                                            >
                                              <rect
                                                x="2"
                                                y="7"
                                                width="20"
                                                height="14"
                                                rx="2"
                                                ry="2"
                                              ></rect>
                                              <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                                            </svg>{" "}
                                            <span
                                              style={{
                                                verticalAlign: "super",
                                                color: "#808080",
                                                cursor: "initial",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {searchedUser?.[0]?.orgrole}
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#"
                                            style={{ cursor: "initial" }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="feather feather-map-pin"
                                              style={{
                                                color: "#808080",
                                                cursor: "initial",
                                              }}
                                            >
                                              <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                              <circle
                                                cx="12"
                                                cy="10"
                                                r="3"
                                              ></circle>
                                            </svg>{" "}
                                            <span
                                              style={{
                                                verticalAlign: "super",
                                                color: "#808080",
                                                cursor: "initial",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {searchedUser?.[0]?.state} {","}{" "}
                                              {searchedUser?.[0]?.country}
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#"
                                            style={{ cursor: "initial" }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="feather feather-phone"
                                              style={{
                                                color: "#808080",
                                                cursor: "initial",
                                              }}
                                            >
                                              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                                            </svg>{" "}
                                            <span
                                              style={{
                                                verticalAlign: "super",
                                                color: "#808080",
                                                cursor: "initial",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {searchedUser?.[0]?.phoneno}
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#"
                                            style={{ cursor: "initial" }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="feather feather-mail"
                                              style={{
                                                color: "#808080",
                                                cursor: "initial",
                                              }}
                                            >
                                              <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                              <polyline points="22,6 12,13 2,6"></polyline>
                                            </svg>{" "}
                                            <span
                                              style={{
                                                verticalAlign: "super",
                                                color: "#808080",
                                                cursor: "initial",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {searchedUser?.[0]?.emailid}
                                            </span>
                                          </a>
                                        </li>
                                        {/* <li>
                                      <a href="#">
                                        <span>DOB : {profileResponse?.dob}</span>
                                      </a>
                                    </li> */}
                                        <li>
                                          <a
                                            href="#"
                                            style={{ cursor: "initial" }}
                                          >
                                            {/* <span style={{verticalAlign:"super"}}> */}
                                            <svg
                                              viewBox="0 0 24 24"
                                              width="20"
                                              height="20"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              fill="none"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="css-i6dzq1"
                                              style={{
                                                color: "#808080",
                                                cursor: "initial",
                                              }}
                                            >
                                              <circle
                                                cx="12"
                                                cy="12"
                                                r="10"
                                              ></circle>
                                              <line
                                                x1="12"
                                                y1="8"
                                                x2="12"
                                                y2="12"
                                              ></line>
                                              <line
                                                x1="12"
                                                y1="16"
                                                x2="12.01"
                                                y2="16"
                                              ></line>
                                            </svg>
                                            <span
                                              style={{
                                                verticalAlign: "super",
                                                marginLeft: "4px",
                                                color: "#808080",
                                                cursor: "initial",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {searchedUser?.[0]?.organization}
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                      <div class="pers-social">
                                        {/* <a
                                        href="#"
                                        class=" fab fa-facebook"
                                      ></a>
                                      <a href="#" class="fab fa-twitter"></a>
                                      <a href="#" class="fab fa-google"></a>
                                      <a href="#" class="fab fa-linkedin"></a> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-5">
                            {/* <div class="new-pro-main-btn">
                            <a class="new-pro-message">Connect</a>{" "}
                            <a class="second-new-pro-btn">Other button</a>
                          </div> */}
                            <div>
                              <div class="text-center mob-space">
                                <div class="navigationS">
                                  <div class="menuToggleS">
                                    <ShareIcon style={{ color: "orange" }} />
                                  </div>
                                  <div class="menuS">
                                    <ul>
                                      {/* <li>
                                      <a href="#"  target="_blank">
                                        <i
                                          title="Github"
                                          class="fa-brands fab fa-facebook"
                                          style={{ color: "#3B5998" }}
                                        ></i>
                                      </a>
                                    </li> */}
                                      <WhatsappShareButton
                                        // onClick={downloadDocument}
                                        url={
                                          "Hello!" +
                                          "\n" +
                                          "Please see my SDP cYAAG Profile" +
                                          "\n" +
                                          browserurl.replace("newprofile", "cm")
                                        }
                                      >
                                        <WhatsappIcon
                                          size={32}
                                          round={true}
                                        ></WhatsappIcon>
                                      </WhatsappShareButton>
                                      <FacebookShareButton
                                        // url="app.cyaag.com"
                                        url={browserurl.replace(
                                          "newprofile",
                                          "cm"
                                        )}
                                        title={
                                          "Hello!" +
                                          "\n" +
                                          "Please see my SDP cYAAG Profile" +
                                          "\n" +
                                          browserurl.replace("newprofile", "cm")
                                        }
                                        hashtag="#cyaag"
                                      >
                                        <FacebookIcon
                                          size={32}
                                          round={true}
                                        ></FacebookIcon>
                                      </FacebookShareButton>

                                      <EmailShareButton
                                        url=""
                                        // body={
                                        //   // referralUrlResponse.referralurl +
                                        //   // myreferralurl.referralurl +
                                        //   // "\n" +
                                        //   // " " +

                                        //   myreferralurl?.data?.url +
                                        //   "\n" +
                                        //   " " +
                                        //   myreferralurl?.data?.refcode +
                                        //   "\n" +
                                        //   " " +
                                        //   campaignList?.data?.[0]?.socialmediamsg
                                        //   // myYaagResponse.whatsappmsg
                                        // }
                                        body={
                                          "Hello!" +
                                          "\n" +
                                          "Please see my SDP cYAAG Profile" +
                                          "\n" +
                                          browserurl.replace("newprofile", "cm")
                                        }
                                        subject="My SDP cYAAG Profile"
                                      >
                                        <EmailIcon size={32} round={true} />
                                      </EmailShareButton>

                                      <TwitterShareButton
                                        // url={"url"}
                                        url={
                                          "Hello!" +
                                          "\n" +
                                          "Please see my SDP cYAAG Profile" +
                                          "\n" +
                                          browserurl.replace("newprofile", "cm")
                                        }
                                        // title={
                                        //   referralUrlResponse.referralurl +
                                        //   "\n" +
                                        //   " " +
                                        //   campaignList?.[0]?.socialmediamsg
                                        // }

                                        title={"Hello"}
                                      >
                                        <TwitterIcon
                                          size={32}
                                          round={true}
                                        ></TwitterIcon>
                                      </TwitterShareButton>
                                      {/* <li>
                                      <a href="#"  target="_blank">
                                        <i
                                          title="LinkedIn"
                                          class="fa-brands fab fa-linkedin"
                                          style={{ color: "#0072b1" }}
                                        ></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#"  target="_blank">
                                        <i
                                          title="CodePen"
                                          class="fa-brands fab fa-codepen"
                                          style={{ color: "black" }}
                                        ></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#"  target="_blank">
                                        <i
                                          title="Instagram"
                                          class="fa-brands fab fa-instagram"
                                          style={{ color: "purple" }}
                                        ></i>
                                      </a>
                                    </li> */}
                                    </ul>
                                  </div>
                                  {/* </div> */}
                                </div>
                                <h6 class="share-profile">Share Profile</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <hr> */}
                        <header id="SOADashboardScreen">
                          <div>
                            <div class="row">
                              <div class="col-md-8">
                                <div>
                                  <div class="new-pro-space">
                                    <div class="clr-f">
                                      <h5 class="float-l">Expertise </h5>
                                      {/* <span
                                    class="float-r"
                                    data-toggle="modal"
                                    data-target="#expertize-modal"
                                  >
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-edit"
                                  >
                                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                  </svg>
                                  </span> */}
                                    </div>
                                    <p style={{wordBreak:"break-all"}}>
                                      {searchedUser?.[0]?.expertise}
                                      <br></br>
                                    </p>
                                  </div>
                                  <hr />
                                </div>
                                <div>
                                  <div class="pro-page-count-sec">
                                    <div class="row">
                                      <div class="col-md-4">
                                        <div>
                                          <img
                                            src={SocialProfileExpertizeImage}
                                            class="img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div class="col-md-8">
                                        <div class="pro-points-counter">
                                          <div class="">
                                            <div class="points-rank">
                                              <div class="row">
                                                <div class="col-6">
                                                  <div class="text-center">
                                                    <span
                                                      class="gray-text"
                                                      style={{
                                                        marginBottom: "0px",
                                                      }}
                                                    >
                                                      My Points
                                                    </span>
                                                    <h6 class="cam-curr-point">
                                                      {
                                                        mycommunity?.data
                                                          ?.mylevel?.rewardpoint
                                                      }
                                                    </h6>
                                                  </div>
                                                </div>
                                                <div class="col-6">
                                                  <div class="text-center">
                                                    <span
                                                      class="gray-text"
                                                      style={{
                                                        marginBottom: "0px",
                                                      }}
                                                    >
                                                      My Rank
                                                    </span>
                                                    <h6 class="cam-curr-point">
                                                      {
                                                        mycommunity?.data
                                                          ?.myrankpoints?.myrank
                                                      }
                                                    </h6>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {/* <hr> */}
                                            <div>
                                              <div class="row">
                                                <div class="col-6">
                                                  <div class="text-center">
                                                    <span
                                                      class="pp-p-text gray-text"
                                                      style={{
                                                        marginTop: "10px",
                                                      }}
                                                    >
                                                      Community Size
                                                    </span>
                                                    <h6
                                                      class="pp-point"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        navigate(
                                                          `/mycommunity/${localStorage.getItem(
                                                            "userid"
                                                          )}`
                                                        )
                                                      }
                                                    >
                                                      {
                                                        mycommunity?.data
                                                          ?.refcount
                                                      }
                                                    </h6>
                                                  </div>
                                                </div>
                                                <div class="col-6">
                                                  <div class="text-center">
                                                    <span
                                                      class="pp-p-text gray-text"
                                                      style={{
                                                        marginTop: "10px",
                                                      }}
                                                    >
                                                      Community Points
                                                    </span>
                                                    <h6 class="pp-point">
                                                      {
                                                        mycommunity?.data
                                                          ?.rewardpoint
                                                      }
                                                    </h6>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div class="row">
                                    <div class="col-md-6">



                                    <div>
                                        {/* {isArray(activityList) ? ( */}
                                          {/* <div class="new-pro-space new-job-pro-right-wrap" style={{ overflowY: "scroll", height: "190px" }} > */}
                                            <div class="clr-f">
                                              <h5 class="float-l">
                                                My Activities{" "}
                                              </h5>
                                            </div>
                                            {isArray(activityList) ? (
                                            
                                            <div  class="new-pro-space new-job-pro-right-wrap" style={{ overflowY: "scroll", height: "190px" }}>
                                              <div>
                                                {activityList &&
                                                  activityList?.map(
                                                    (action) => (
                                                      <div class="m-acti-wrap">
                                                        <div class="sec-1">
                                                          <div class="ones">
                                                            <img
                                                              src={
                                                                action?.camplogo
                                                              }
                                                              class="img-fluid"
                                                            />
                                                            <div></div>
                                                          </div>
                                                          <div class="two">
                                                            {/* <span>{action?.date}</span> */}
                                                            <span
                                                              style={{
                                                                color: "#000",
                                                                // display: "flex",
                                                                display:
                                                                  "inline-block",
                                                                // justifyContent: "flex-end",
                                                                fontSize: 14,
                                                                color: "grey",
                                                                display:
                                                                  "block",
                                                              }}
                                                            >
                                                              {moment(
                                                                action?.date
                                                              ).fromNow()}
                                                            </span>
                                                            <h6>
                                                              {action?.desc}
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                              </div>
                                            </div>
                                          // </div>
                                        ) : (
                                          <div
                                            class="new-pro-space new-job-pro-right-wrap"
                                            // style={{
                                            //   overflowY: "scroll",
                                            //   height: "100px",
                                            // }}
                                          >
                                            {/* <div class="clr-f">
                                              <h5 class="float-l">
                                                My Activities{" "}
                                              </h5>
                                            </div> */}
                                            <div>
                                              <div class="m-acti-wrap">
                                                <div class="sec-1">
                                                  {/* <div class="ones">
                                                    <img
                                                      src={action?.camplogo}
                                                      class="img-fluid"
                                                    />
                                                  </div> */}
                                                  <span
                                                    style={{
                                                      // color: "#000",
                                                      // display: "flex",
                                                      display: "inline-block",
                                                      // justifyContent: "flex-end",
                                                      fontSize: 16,
                                                      // color: "grey",
                                                      display: "block",
                                                      fontWeight: "400",
                                                      marginLeft: "2px",
                                                    }}
                                                  >
                                                    {activityList?.message}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>



                                    </div>
                                    {/* <div class="col-md-6">
                                  <div>
                                    <div class="new-pro-space new-job-pro-right-wrap">
                                      <div class="clr-f">
                                        <h5 class="float-l">
                                          Impact done by my community.
                                        </h5>
                                      </div>
                                      <div>
                                        <div>
                                          <div class="row">
                                            <div class="col-md-4 col-6">
                                              <div class="text-center margin-bottom-10">
                                                <img
                                                  src="https://www.pngall.com/wp-content/uploads/1/Community-PNG-Image.png"
                                                  class="img-fluid cam-commun"
                                                />
                                                <span class="cam-commun-text">
                                                  Lorem ipsum dolor sit amet
                                                  consectetur adipisicing elit
                                                </span>
                                              </div>
                                            </div>
                                            <div class="col-md-4 col-6">
                                              <div class="text-center margin-bottom-10">
                                                <img
                                                  src="https://www.pngitem.com/pimgs/m/162-1625008_save-the-earth-png-transparent-png.png"
                                                  class="img-fluid cam-commun"
                                                />
                                                <span class="cam-commun-text">
                                                  Lorem ipsum dolor sit amet
                                                  consectetur adipisicing elit
                                                </span>
                                              </div>
                                            </div>
                                            <div class="col-md-4 col-6">
                                              <div class="text-center margin-bottom-10">
                                                <img
                                                  src="https://cdn-icons-png.flaticon.com/512/1684/1684388.png"
                                                  class="img-fluid cam-commun"
                                                />
                                                <span class="cam-commun-text">
                                                  Lorem ipsum dolor sit amet
                                                  consectetur adipisicing elit
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div>
                                  <div class="new-pro-space new-job-pro-right-wrap">
                                    <div class="clr-f">
                                      <h5 class="float-l">Skills </h5>
                                      <span
                                        class="float-r"
                                        data-toggle="modal"
                                        data-target="#skills-modal"
                                      >
                                        {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-edit"
                                  >
                                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                  </svg> */}
                                      </span>
                                    </div>
                                    {!isEmpty(searchedUser?.[0]?.skill) ? (
                                      <div class="job-req-wrap">
                                        {searchedUser?.[0]?.skill?.map(
                                          (item) => (
                                            <a class="skills">{item?.skill}</a>
                                          )
                                        )}
                                      </div>
                                    ) : (
                                      <div class="job-req-wrap">
                                        {/* <a class="skills">No skills selected</a> */}
                                        <a>No skills selected</a>
                                      </div>
                                    )}
                                  </div>

                                  <div class="new-pro-space new-job-pro-right-wrap">
                                    <div class="clr-f">
                                      <h5 class="float-l">Interested Areas </h5>
                                      <span
                                        class="float-r"
                                        data-toggle="modal"
                                        data-target="#in-areas"
                                      >
                                        {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-edit"
                                  >
                                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                  </svg> */}
                                      </span>
                                    </div>
                                    <div>
                                      {!isEmpty(
                                        searchedUser?.[0]?.interestarea
                                      ) ? (
                                        <ul class="i-areas">
                                          {searchedUser?.[0]?.interestarea?.map(
                                            (item) => (
                                              <li class="interestareas">
                                                <span>{item?.interest}</span>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      ) : (
                                        <ul class="i-areas">
                                          <li>
                                            <span>No interests selected</span>
                                          </li>
                                        </ul>
                                      )}
                                    </div>
                                  </div>

                                  <div class="new-pro-space new-job-pro-right-wrap">
                                    <div class="clr-f">
                                      <h5 class="float-l">My Network</h5>
                                      <span
                                        class="float-r"
                                        data-toggle="modal"
                                        data-target="#in-areas"
                                      ></span>
                                    </div>

                                    <span>
                                      <PeopleIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          navigate(
                                            `/mycommunity/${searchedUser?.[0]?.phoneno}`
                                          )
                                        }
                                      />
                                    </span>
                                  </div>

                                  {/* <div class="new-pro-space new-job-pro-right-wrap">
                                <div class="clr-f">
                                  <h5 class="float-l">My Network</h5>
                                  <span
                                    class="float-r"
                                    data-toggle="modal"
                                    data-target="#in-areas"
                                  ></span>
                                </div>
                                
                                <span><PeopleIcon /></span>
                                
                              </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </header>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div class="">
                  <div class="container">
                    <div class="">
                      <div class="new-profile">
                        <div class="row">
                          <div class="col-md-7">
                            <div class="row">
                              <div class="col-md-3">
                                <div class="new-pro-first-wrap">
                                  {searchedUser?.[0]?.profilephoto ? (
                                    <img
                                      src={searchedUser?.[0]?.profilephoto}
                                      class="img-fluid new-profile-image"
                                    />
                                  ) : (
                                    <Avatar
                                      style={{
                                        width: "100px",
                                        borderRadius: "0",
                                        height: "auto",
                                        verticalAlign: "super",
                                      }}
                                      src="/broken-image.jpg"
                                    />
                                    //   <img
                                    //   src="/broken-image.jpg"
                                    //   class="img-fluid new-profile-image"
                                    // />
                                  )}
                                </div>
                              </div>
                              <div class="col-md-9">
                                <div class="new-pro-name-sec">
                                  <h4>
                                    {searchedUser?.[0]?.firstname}{" "}
                                    {searchedUser?.[0]?.lastname}
                                  </h4>
                                  <span
                                    class="float-r"
                                    data-toggle="modal"
                                    data-target="#myModal"
                                  >
                                    {/* <Button
                                  onClick={() => navigate("/editProfile")}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-edit"
                                  >
                                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                  </svg>
                                </Button> */}
                                  </span>
                                  <div
                                    id="contentz"
                                    clasnew-pro-name-secs="basic-detail"
                                  >
                                    <div id="horz-list">
                                      <ul>
                                        <li>
                                          <a
                                            href="#"
                                            style={{ cursor: "initial" }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="feather feather-briefcase"
                                              style={{
                                                color: "#808080",
                                                cursor: "initial",
                                              }}
                                            >
                                              <rect
                                                x="2"
                                                y="7"
                                                width="20"
                                                height="14"
                                                rx="2"
                                                ry="2"
                                              ></rect>
                                              <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                                            </svg>{" "}
                                            <span
                                              style={{
                                                verticalAlign: "super",
                                                color: "#808080",
                                                cursor: "initial",
                                              }}
                                            >
                                              {searchedUser?.[0]?.orgrole}
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#"
                                            style={{ cursor: "initial" }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="feather feather-map-pin"
                                              style={{
                                                color: "#808080",
                                                cursor: "initial",
                                              }}
                                            >
                                              <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                              <circle
                                                cx="12"
                                                cy="10"
                                                r="3"
                                              ></circle>
                                            </svg>{" "}
                                            <span
                                              style={{
                                                verticalAlign: "super",
                                                color: "#808080",
                                                cursor: "initial",
                                              }}
                                            >
                                              {searchedUser?.[0]?.state} {","}{" "}
                                              {searchedUser?.[0]?.country}
                                            </span>
                                          </a>
                                        </li>
                                        {/* <li>
                                      <a href="#">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          class="feather feather-phone"
                                        >
                                          <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                                        </svg>{" "}
                                        <span>
                                          {profileResponse?.phoneno}
                                        </span>
                                      </a>
                                    </li> */}
                                        {/* <li>
                                      <a href="#">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          class="feather feather-mail"
                                        >
                                          <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                          <polyline points="22,6 12,13 2,6"></polyline>
                                        </svg>{" "}
                                        <span>
                                          {profileResponse?.emailid}
                                        </span>
                                      </a>
                                    </li> */}
                                        {/* <li>
                                      <a href="#">
                                        <span>DOB : {profileResponse?.dob}</span>
                                      </a>
                                    </li> */}
                                        <li>
                                          <a
                                            href="#"
                                            style={{ cursor: "initial" }}
                                          >
                                            {/* <span style={{verticalAlign:"super"}}> */}
                                            <svg
                                              viewBox="0 0 24 24"
                                              width="24"
                                              height="24"
                                              stroke="currentColor"
                                              stroke-width="2"
                                              fill="none"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="css-i6dzq1"
                                              style={{
                                                color: "#808080",
                                                cursor: "initial",
                                              }}
                                            >
                                              <circle
                                                cx="12"
                                                cy="12"
                                                r="10"
                                              ></circle>
                                              <line
                                                x1="12"
                                                y1="8"
                                                x2="12"
                                                y2="12"
                                              ></line>
                                              <line
                                                x1="12"
                                                y1="16"
                                                x2="12.01"
                                                y2="16"
                                              ></line>
                                            </svg>
                                            <span
                                              style={{
                                                verticalAlign: "super",
                                                marginLeft: "4px",
                                                color: "#808080",
                                                cursor: "initial",
                                              }}
                                            >
                                              {searchedUser?.[0]?.organization}
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                      <div class="pers-social">
                                        {/* <a
                                        href="#"
                                        class=" fab fa-facebook"
                                      ></a>
                                      <a href="#" class="fab fa-twitter"></a>
                                      <a href="#" class="fab fa-google"></a>
                                      <a href="#" class="fab fa-linkedin"></a> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-5">
                            <div class="new-pro-main-btn">
                              {/* <a class="new-pro-message">Connect</a>{" "} */}
                              {/* <a class="second-new-pro-btn">Other button</a> */}
                            </div>
                            <div>
                              <div class="text-center">
                                <div class="navigationS">
                                  {/* <div class="menuToggleS"></div> */}
                                  {/* <div class="menuS">
                                <ul>
                                  <li>
                                    <a href="#" target="_blank">
                                      <i
                                        title="Github"
                                        class="fa-brands fab fa-facebook"
                                        style={{ color: "#3B5998" }}
                                      ></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" target="_blank">
                                      <i
                                        title="LinkedIn"
                                        class="fa-brands fab fa-linkedin"
                                        style={{ color: "#0072b1" }}
                                      ></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" target="_blank">
                                      <i
                                        title="CodePen"
                                        class="fa-brands fab fa-codepen"
                                        style={{ color: "black" }}
                                      ></i>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#" target="_blank">
                                      <i
                                        title="Instagram"
                                        class="fa-brands fab fa-instagram"
                                        style={{ color: "purple" }}
                                      ></i>
                                    </a>
                                  </li>
                                </ul>
                              </div> */}
                                </div>
                                <Button
                                  className="btn save-btn"
                                  variant="contained"
                                  onClick={() => navigate("/")}
                                >
                                  <h6 class="share-profile">
                                    Explore Platform
                                  </h6>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <hr> */}
                        <div>
                          <div class="row">
                            <div class="col-md-8">
                              <div>
                                <div class="new-pro-space">
                                  <div class="clr-f">
                                    <h5 class="float-l">Expertise </h5>
                                    <span
                                      class="float-r"
                                      data-toggle="modal"
                                      data-target="#expertize-modal"
                                    >
                                      {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-edit"
                                  >
                                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                  </svg> */}
                                    </span>
                                  </div>
                                  <p style={{wordBreak:"break-all"}}>
                                    {searchedUser?.[0]?.expertise}
                                    <br></br>
                                  </p>
                                </div>
                                <hr />
                              </div>
                              <div>
                                <div class="pro-page-count-sec">
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div>
                                        <img
                                          src={SocialProfileExpertizeImage}
                                          class="img-fluid"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-8">
                                      <div class="pro-points-counter">
                                        <div class="">
                                          <div class="points-rank">
                                            <div class="row">
                                              <div class="col-6">
                                                <div class="text-center">
                                                  <span
                                                    class="gray-text"
                                                    style={{
                                                      marginBottom: "0px",
                                                    }}
                                                  >
                                                    My Points
                                                  </span>
                                                  <h6 class="cam-curr-point">
                                                    {
                                                      mycommunity?.data?.mylevel
                                                        ?.rewardpoint
                                                    }
                                                  </h6>
                                                </div>
                                              </div>
                                              <div class="col-6">
                                                <div class="text-center">
                                                  <span
                                                    class="gray-text"
                                                    style={{
                                                      marginBottom: "0px",
                                                    }}
                                                  >
                                                    My Ranking
                                                  </span>
                                                  <h6 class="cam-curr-point">
                                                    {
                                                      mycommunity?.data
                                                        ?.myrankpoints?.myrank
                                                    }
                                                  </h6>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {/* <hr> */}
                                          <div>
                                            <div class="row">
                                              <div class="col-6">
                                                <div class="text-center">
                                                  <span
                                                    class="pp-p-text gray-text"
                                                    style={{
                                                      marginTop: "10px",
                                                    }}
                                                  >
                                                    Community Size
                                                  </span>
                                                  <h6
                                                    class="pp-point"
                                                    // style={{cursor:"pointer"}}
                                                    // onClick={() => navigate(`/mycommunity/${searchedUser?.[0]?.phoneno}`)}
                                                  >
                                                    {
                                                      mycommunity?.data
                                                        ?.refcount
                                                    }
                                                  </h6>
                                                </div>
                                              </div>
                                              <div class="col-6">
                                                <div class="text-center">
                                                  <span
                                                    class="pp-p-text gray-text"
                                                    style={{
                                                      marginTop: "10px",
                                                    }}
                                                  >
                                                    Community Points
                                                  </span>
                                                  <h6 class="pp-point">
                                                    {
                                                      mycommunity?.data
                                                        ?.rewardpoint
                                                    }
                                                  </h6>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div class="row">
                                  <div class="col-md-6">


                                  <div>
                                        {/* {isArray(activityList) ? ( */}
                                          {/* <div class="new-pro-space new-job-pro-right-wrap" style={{ overflowY: "scroll", height: "190px" }} > */}
                                            <div class="clr-f">
                                              <h5 class="float-l">
                                                My Activities{" "}
                                              </h5>
                                            </div>
                                            {isArray(activityList) ? (
                                            
                                            <div  class="new-pro-space new-job-pro-right-wrap" style={{ overflowY: "scroll", height: "190px" }}>
                                              <div>
                                                {activityList &&
                                                  activityList?.map(
                                                    (action) => (
                                                      <div class="m-acti-wrap">
                                                        <div class="sec-1">
                                                          <div class="ones">
                                                            <img
                                                              src={
                                                                action?.camplogo
                                                              }
                                                              class="img-fluid"
                                                            />
                                                            <div></div>
                                                          </div>
                                                          <div class="two">
                                                            {/* <span>{action?.date}</span> */}
                                                            <span
                                                              style={{
                                                                color: "#000",
                                                                // display: "flex",
                                                                display:
                                                                  "inline-block",
                                                                // justifyContent: "flex-end",
                                                                fontSize: 14,
                                                                color: "grey",
                                                                display:
                                                                  "block",
                                                              }}
                                                            >
                                                              {moment(
                                                                action?.date
                                                              ).fromNow()}
                                                            </span>
                                                            <h6>
                                                              {action?.desc}
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                              </div>
                                            </div>
                                          // </div>
                                        ) : (
                                          <div
                                            class="new-pro-space new-job-pro-right-wrap"
                                            // style={{
                                            //   overflowY: "scroll",
                                            //   height: "100px",
                                            // }}
                                          >
                                            {/* <div class="clr-f">
                                              <h5 class="float-l">
                                                My Activities{" "}
                                              </h5>
                                            </div> */}
                                            <div>
                                              <div class="m-acti-wrap">
                                                <div class="sec-1">
                                                  {/* <div class="ones">
                                                    <img
                                                      src={action?.camplogo}
                                                      class="img-fluid"
                                                    />
                                                  </div> */}
                                                  <span
                                                    style={{
                                                      // color: "#000",
                                                      // display: "flex",
                                                      display: "inline-block",
                                                      // justifyContent: "flex-end",
                                                      fontSize: 16,
                                                      // color: "grey",
                                                      display: "block",
                                                      fontWeight: "400",
                                                      marginLeft: "2px",
                                                    }}
                                                  >
                                                    {activityList?.message}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>



                                  </div>
                                  {/* <div class="col-md-6"> */}
                                    {/* <div>
                                  <div class="new-pro-space new-job-pro-right-wrap">
                                    <div class="clr-f">
                                      <h5 class="float-l">
                                        Impact done by my community.
                                      </h5>
                                    </div>
                                    <div>
                                      <div>
                                        <div class="row">
                                          <div class="col-md-4 col-6">
                                            <div class="text-center margin-bottom-10">
                                              <img
                                                src="https://www.pngall.com/wp-content/uploads/1/Community-PNG-Image.png"
                                                class="img-fluid cam-commun"
                                              />
                                              <span class="cam-commun-text">
                                                Lorem ipsum dolor sit amet
                                                consectetur adipisicing elit
                                              </span>
                                            </div>
                                          </div>
                                          <div class="col-md-4 col-6">
                                            <div class="text-center margin-bottom-10">
                                              <img
                                                src="https://www.pngitem.com/pimgs/m/162-1625008_save-the-earth-png-transparent-png.png"
                                                class="img-fluid cam-commun"
                                              />
                                              <span class="cam-commun-text">
                                                Lorem ipsum dolor sit amet
                                                consectetur adipisicing elit
                                              </span>
                                            </div>
                                          </div>
                                          <div class="col-md-4 col-6">
                                            <div class="text-center margin-bottom-10">
                                              <img
                                                src="https://cdn-icons-png.flaticon.com/512/1684/1684388.png"
                                                class="img-fluid cam-commun"
                                              />
                                              <span class="cam-commun-text">
                                                Lorem ipsum dolor sit amet
                                                consectetur adipisicing elit
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                                  {/* </div> */}
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4">
                              <div>
                                <div class="new-pro-space new-job-pro-right-wrap">
                                  <div class="clr-f">
                                    <h5 class="float-l">Skills </h5>
                                    <span
                                      class="float-r"
                                      data-toggle="modal"
                                      data-target="#skills-modal"
                                    >
                                      {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-edit"
                                  >
                                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                  </svg> */}
                                    </span>
                                  </div>
                                  {!isEmpty(searchedUser?.[0]?.skill) ? (
                                    <div class="job-req-wrap">
                                      {searchedUser?.[0]?.skill?.map((item) => (
                                        <a class="skills">{item?.skill}</a>
                                      ))}
                                    </div>
                                  ) : (
                                    <div class="job-req-wrap">
                                      {/* <a class="skills">No skills selected</a> */}
                                      <a>No skills selected</a>
                                    </div>
                                  )}
                                </div>

                                <div class="new-pro-space new-job-pro-right-wrap">
                                  <div class="clr-f">
                                    <h5 class="float-l">Interested Areas </h5>
                                    <span
                                      class="float-r"
                                      data-toggle="modal"
                                      data-target="#in-areas"
                                    >
                                      {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-edit"
                                  >
                                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                  </svg> */}
                                    </span>
                                  </div>
                                  <div>
                                    {!isEmpty(
                                      searchedUser?.[0]?.interestarea
                                    ) ? (
                                      <ul class="i-areas">
                                        {searchedUser?.[0]?.interestarea?.map(
                                          (item) => (
                                            <li class="interestareas">
                                              <span>{item?.interest}</span>
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    ) : (
                                      <ul class="i-areas">
                                        <li>
                                          <span>
                                            No interest areas selected
                                          </span>
                                        </li>
                                      </ul>
                                    )}
                                  </div>
                                </div>

                                {/* <div class="new-pro-space new-job-pro-right-wrap">
                                <div class="clr-f">
                                  <h5 class="float-l">My Network</h5>
                                  <span
                                    class="float-r"
                                    data-toggle="modal"
                                    data-target="#in-areas"
                                  ></span>
                                </div>
                                <div>
                                  {!isEmpty(
                                    searchedUser?.[0]?.interestarea
                                  ) ? (
                                    <ul class="i-areas">
                                      {searchedUser?.[0]?.interestarea?.map(
                                        (item) => (
                                          <li class="interestareas">
                                            <span>{item?.interest}</span>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    <ul class="i-areas">
                                      <li>
                                        <span>No interests selected</span>
                                      </li>
                                    </ul>
                                  )}
                                </div>
                              </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
};

export default SocialProfileExternal;
