import React from "react";
import { isEmpty, isArray } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { userProfile } from "../redux/action/UserProfileActions";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "../components/Header";
import { CopyRightMarketplace } from "../components/CopyRightMarketplace";
import TopHeaderScreen from "../components/TopHeader1";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import RedeemConfirmation from "../components/RedeemConfirmation";
import ConfirmationDialog from "../components/ConfirmationDialog";
import RewardMainImage from "../assets/rew.png";
import { Button } from "@mui/material";
import PostRedeemConfirmation from "../components/PostRedeemConfirmation";
import CEOImage from "../assets/ceo.png";
import AsyncSelect from "react-select/async";
import { getRewardTitle } from "../redux/action/RewardDetailAction";
import { FixedFooterIcon } from "../components/FixedFooterIcon";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
//   PointElement,
//   LineElement,
//   ArcElement,
// );

const useStyles = makeStyles((theme) => ({
  pointBox: {
    // top: "22px",
    // left: "200px",
    // top: "22%",
    minWidth: "65px",
    width: "240px",
    minHeight: "295px",
    // height: "150px",
    // background: "#1A524D 0% 0% no-repeat padding-box",
    background: "white",
    borderRadius: "20px",
    // opacity: "90%",
    color: "#FFFFFF",
    fontSize: 15,
    fontWeight: "bold",
    borderWidth: "3px",
    // borderWidth: "thick",
    borderStyle: "solid",
    borderColor: "black",
    overflow: "hidden",
  },
  blackBox: {
    // top: "22px",
    // left: "200px",
    // top: "22%",
    minWidth: "65px",
    // width: "136px",
    minHeight: "138px",
    // height: "150px",
    // background: "black",
    background: "#FFD580",
    // borderRadius: "20px",
    opacity: "90%",
    // color: "#FFFFFF",
    color: "black",
    fontSize: 15,
    fontWeight: "bold",
  },

  mouseHover: {
    "&:hover": {
      textDecoration: "underline",
      color: "blue",
    },
  },
  thead: {
    borderBottom: "1px solid #d3d3d3",
    borderTop: "1px solid #d3d3d3",
    height: 60,
  },
  theadText: {
    fontWeight: "700",
    textAlign: "center",
    fontSize: 16,
    minWidth: 100,
  },
  tbodyText: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width: "200px",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // wordWrap: "breakWord",
    // overflow: "scroll",
  },
  table: {
    width: "100%",
    // marginBottom: "100px",
    borderRadius: "10px",
    borderStyle: "solid",
    borderWidth: "1.5px",
    // tableLayout: "fixed",
  },
  tableRow: {
    borderBottom: "1px solid #d3d3d3",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  chartWrapperContainer: {
    width: "45%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  chartWrapper: {
    padding: 3,

    backgroundColor: "#E9E9E9",
    borderStyle: "solid",
    borderWidth: "1.5px",
    margin: 4,
    borderRadius: 4,
    // display: "flex",
    // display: "inline-flex",
    // display: "block"
  },
  tagWrapper: {
    padding: 3,

    // backgroundColor: "#E8E8E8",
    // backgroundColor: "#80BDE3",
    // backgroundColor: "#FFD580",
    backgroundColor: "#FF5733",

    margin: 4,
    borderRadius: 4,
    // display: "flex",
    display: "inline-flex",
    textAlign: "center",
  },
  tags: {
    // textTransform: "capitalize",
    fontSize: 9,
  },
  pointWrapper: {
    padding: 3,

    // backgroundColor: "#E8E8E8",
    // margin: 4,
    // borderRadius: 4,
    // display: "flex",
    // display: "inline-flex",
    textAlign: "center",
  },
  Chip: {
    display: "block",
    // display: "inline-flex",
    // flexDirection:"column",
    // textAlign: "center",
  },
}));

function Marketplace(props) {
  // console.log(response)
  const classes = useStyles();
  const navigate = useNavigate();
  const [marketplacelist, setmarketplacelist] = useState(null);
  const [rewardTypeList, setRewardTypeList] = React.useState([]);
  const [pointsList, setPointsList] = React.useState([]);
  const [selectedrewardtype, setselectedrewardtype] = React.useState([]);
  const [selectedpointsrange, setselectedpointsrange] = React.useState([]);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [afterRedeem, setAfterRedeem] = useState([]);
  const [displayRedeemConfirmationModal, setDisplayRedeemConfirmationModal] =
    useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [redeemindex, setRedeemIndex] = useState(0);
  const [postRedeemDisplayModal, setPostRedeemDisplayModal] = useState(false);
  const [redeemCount, setRedeemCount] = useState(0);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const [searchValues, setSearchValues] = React.useState({
    rewardType: "",
    points: "",
  });
  const handleSearchFieldChange = (field, e) => {
    // (isCampaignError || isCampaignSuccess) && dispatch(onboardCampaignReset());
    setSearchValues({
      ...searchValues,
      [field]:
        field === "startDate" || field === "endDate"
          ? e
          : field === "campaignLogo" || field === "campaignVideo"
          ? e.target.files[0]
          : e.target.value,
    });
  };

  function setDisplayAndIndex(bool, index) {
    setDisplayRedeemConfirmationModal(bool);
    setRedeemIndex(index);
  }
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(userProfile(localStorage.getItem("userid")));
    // getRewardsFromMarketplace();
  }, []);

  // React.useEffect(() => {
  //   getRewardsFromMarketplace().then((data) => {
  //     setmarketplacelist(data);
  //   });
  // }, []);
  React.useEffect(() => {
    getRewardsFromMarketplace();
  }, [redeemCount]);

  React.useEffect(() => {
    searchRewardTypeList();
  }, []);

  React.useEffect(() => {
    searchPoints();
  }, []);

  React.useEffect(() => {
    if (selectedrewardtype.length === 0 && !selectedpointsrange.length === 0) {
    
      getRewardsFromMarketplaceOnSearch();
    } else if (
      !selectedrewardtype.length === 0 &&
      selectedpointsrange.length === 0
    ) {
     
      getRewardsFromMarketplaceOnSearch();
    } else {
      getRewardsFromMarketplaceOnSearch();
    }
  }, []);
 
  // const getRewardsFromMarketplace = async () => {
  //     try {
  //         //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);
  //         const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);
  //         setmarketplacelist(res?.data);
  //         console.log(res?.data);
  //         return res;
  //     } catch (err) {
  //         throw err;
  //     }
  // };

  // const getRewardsFromMarketplace = async () => {
  //   try {
  //     //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);
  //     const res = await axios.get(
  //       `/marketplace/read/${profileResponse?.phoneno}`
  //     );
  //     // setmarketplacelist(res?.data);
  //     console.log(res?.data);
  //     return res;
  //   } catch (err) {
  //     throw err;
  //   }
  // };

  const getRewardsFromMarketplace = async () => {
    try {
      //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);
      const res = await axios
        .get(`/marketplace/read/${profileResponse?.phoneno}`)
        .then((response) => {
          setmarketplacelist(response?.data);
          return response;
        });
      // setmarketplacelist(res?.data);
      // console.log(res?.data);
      // return res;
    } catch (err) {
      throw err;
    }
  };

  const handleImageLoad = async(img) => {
    const im = await Promise.all(img);
    console.log(im);
    if(im) {
      return true;
    } else {
      return false;
    }
  
    // Additional actions to perform after the image is loaded
  };

  const getRewardsFromMarketplaceOnSearch = async () => {
   
    // console.log(selectedpointsrange.length === 0);
    try {
      // const res = ""
      if (
        selectedrewardtype.length === 0 &&
        !(selectedpointsrange.length === 0)
      ) {
        
        const res = await axios
          .get(
            `/marketplace/read/${profileResponse?.phoneno}/"SPACE"/${selectedpointsrange[0]["label"]}`
          )
          .then((response) => {
            setmarketplacelist(response?.data);
            return response;
          });
      } else if (
        !(selectedrewardtype.length === 0) &&
        selectedpointsrange.length === 0
      ) {
       
        const res = await axios
          .get(
            `/marketplace/read/${profileResponse?.phoneno}/${JSON.stringify(
              selectedrewardtype
            )}/SPACE`
          )
          .then((response) => {
            setmarketplacelist(response?.data);
            return response;
          });
      } else if (
        !(selectedrewardtype.length === 0) &&
        !(selectedpointsrange.length === 0)
      ) {
        

        const res = await axios
          .get(
            `/marketplace/read/${profileResponse?.phoneno}/${JSON.stringify(
              selectedrewardtype
            )}/${selectedpointsrange[0]["label"]}`
            // `/marketplace/read/${profileResponse?.phoneno}/${selectedrewardtype[0]["label"]}/${selectedpointsrange[0]["label"]}`
          )
          .then((response) => {
            setmarketplacelist(response?.data);
            return response;
          });
      } else {
      
        const res = await axios
          .get(`/marketplace/read/${profileResponse?.phoneno}`)
          .then((response) => {
            setmarketplacelist(response?.data);
            return response;
          });
      }
    } catch (err) {
      throw err;
    }
  };

  // const getCampCategory = async () => {
  //   try {
  //     const res = await axios.get(`/camp/category`);
  //     // setActivityList(res?.data);
  //     setCampCategory(res?.data);
  //     return res;
  //   } catch (err) {
  //     throw err;
  //   }
  // };

  const searchRewardTypeList = async () => {
    try {
      const res = await axios.get(`searchrewardtypes/${profileResponse?.phoneno}`);
      setRewardTypeList(res?.data);
     
      return res;
      // }
    } catch (err) {
      throw err;
    }
  };

  const searchPoints = async () => {
    try {
      const res = await axios.get(`searchpoints`);
      setPointsList(res?.data);
  
      return res;
      // }
    } catch (err) {
      throw err;
    }
  };

  // const searchRewardsFromMarketplace = async () => {
  //   try {
  //     //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);
  //     const res = await axios.get(
  //       `/marketplace/read/${profileResponse?.phoneno}/$`
  //     );
  //     // setmarketplacelist(res?.data);
  //     console.log(res?.data);
  //     return res;
  //   } catch (err) {
  //     throw err;
  //   }
  // };
 

  const handleRedeem = async () => {
    // setDisplayConfirmationModal(true);
   

    const payload = {
      //campid: locationPath?.state?.campid,
      // createdate: locationPath?.state?.createdate,
      // reviewstatus: reviewstatus,
      // reviewcomments: reviewComments,
      phoneno: localStorage.getItem("userid"),
      rewardtype: marketplacelist?.[redeemindex]?.rewardtype,
      mpid: marketplacelist?.[redeemindex]?.mpid,
      campid: marketplacelist?.[redeemindex]?.campid,
      title: marketplacelist?.[redeemindex]?.title,
      subtitle: marketplacelist?.[redeemindex]?.subtitle,
      points: marketplacelist?.[redeemindex]?.points,
      // sponsor: JSON.stringify(marketplacelist?.data?.[redeemindex]?.sponsor),
      sponsor: marketplacelist?.[redeemindex]?.sponsor,
      rewardfamilyid: marketplacelist?.[redeemindex]?.rewardfamilyid,

      // phoneno: locationPath?.state.phoneno,
    };
    try {
      const res = await axios.post(
        `/reward/redeem/${profileResponse?.phoneno}`,
        payload
      );
      // navigate("/reviewcampaign");
      setAfterRedeem(res?.data);
      
      // alert("calling set display false")
      setDisplayRedeemConfirmationModal(false);
      if (res?.data?.message === "Reward successfully redeemed") {
        setPostRedeemDisplayModal(true);
        // navigate("/marketplace");
      } else {
        setDisplayConfirmationModal(true);
      }
      // navigate('/marketplace');
      setRedeemCount(redeemCount + 1);
      return res;
    } catch (err) {
      throw err;
    }
  };

  // Hide the modal
  const hideRedeemConfirmationModal = () => {
    setDisplayRedeemConfirmationModal(false);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handlePostRedeemModal = () => {
    setPostRedeemDisplayModal(false);
    navigate("/marketplace");
  };

  const options = {
    items: 3,
    margin: 10,
    loop: false,
    nav: false,
    autoplaySpeed: 900,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const rewardTypeloadOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = rewardTypeList.filter((category) =>
        category.label.toLowerCase().includes(searchValue.toLowerCase())
      );
     
      callback(filteredOptions);
    });
  };

  const pointsloadOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = pointsList.filter((category) =>
        category.label.toLowerCase().includes(searchValue.toLowerCase())
      );
    
      callback(filteredOptions);
    });
  };

  const colorStyles = {
    control: (styles) => ({ ...styles }),
    // option: (styles, {data, isDisabled, isFocussed, isSelected}) => {
    //   return{...styles, color: "blue"};
    // },
    multiValue: (styles, { data }) => {
      return { ...styles, backgroundColor: "#0096FF", color: "#fff" };
    },
    multiValueLabel: (styles, { data }) => {
      return { ...styles, color: "#fff" };
    },
    multiValueRemove: (styles, { data }) => {
      return {
        ...styles,
        color: "#fff",
        cursor: "pointer",
        ":hover": {
          color: "#fff",
        },
      };
    },
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14, color: "#1976d2" }}>
                Back
              </span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Marketplace
            </div>
            <>
              <div>
                <div class="formMarketPlace">
                  <div>
                    <div
                      class="form-market-wrap marketplaceSearchTitle"
                      id=""
                      role="tabpanel"
                      aria-labelledby="nav-places-tab"
                      
                    >
                      <h6>What are you looking for?</h6>
                      <form action="#" method="get">
                        {/* <select class="custom-select">
                                        <option selected="">Your Destinations</option>
                                        <option value="1">New York</option>
                                        <option value="2">Latvia</option>
                                        <option value="3">Dhaka</option>
                                        <option value="4">Melbourne</option>
                                        <option value="5">London</option>
                                    </select> */}
                        <div
                          // style={{
                          //   padding: "0px 30px 0px 0px",
                          //   width: "300px",
                          //   marginBottom: "5px",
                          //   maxWidth: "100%",
                          // }}
                          class="marketplaceSearch"
                          name="rewardtype"
                        >
                          <AsyncSelect
                            maxMenuHeight={160} 
                            loadOptions={rewardTypeloadOptions}
                            defaultOptions={rewardTypeList}
                            value={selectedrewardtype}
                            isMulti
                            styles={colorStyles}
                            class="selectDrop"
                            inputId="rewardtype"
                            placeholder="Search rewards"
                            // onChange={(e) => {
                            //   handleSearchFieldChange("rewardtype", e);
                            // }}
                            onChange={(event) => {
                              setselectedrewardtype(event);
                             
                              // if (_.isEmpty(event)) {
                              //   setCampaignValuesError({
                              //     ...campaignValuesError,
                              //     typeOfCampaignError: true,
                              //   });
                              // } else {
                              //   setCampaignValuesError({
                              //     ...campaignValuesError,
                              //     typeOfCampaignError: false,
                              //   });
                              // }
                            }}
                            // onBlur={() => {
                            //   if (!selectedcampcat) {
                            //     setCampaignValuesError({
                            //       ...campaignValuesError,
                            //       typeOfCampaignError: true,
                            //     });
                            //   } else {
                            //     setCampaignValuesError({
                            //       ...campaignValuesError,
                            //       typeOfCampaignError: false,
                            //     });
                            //   }
                            // }}
                          />
                        </div>
                        <div
                          // style={{
                          //   padding: "0px 30px 0px 0px",
                          //   width: "300px",
                          //   marginBottom: "5px",
                          //   maxWidth: "100%",
                          // }}
                          class="marketplaceSearch"
                        >
                          <AsyncSelect
                            maxMenuHeight={160} 
                            loadOptions={pointsloadOptions}
                            defaultOptions={pointsList}
                            value={selectedpointsrange}
                            isMulti
                            placeholder="Search points range"
                            isOptionDisabled={() =>
                              selectedpointsrange.length >= 1
                            }
                            styles={colorStyles}
                            name="pointsrange"
                            // onChange={(e) => {
                            //   handleCampaignFieldChange("typeOfCampaign", e);
                            // }}
                            onChange={(event) => {
                              setselectedpointsrange(event);
                           
                              // if (_.isEmpty(event)) {
                              //   setCampaignValuesError({
                              //     ...campaignValuesError,
                              //     typeOfCampaignError: true,
                              //   });
                              // } else {
                              //   setCampaignValuesError({
                              //     ...campaignValuesError,
                              //     typeOfCampaignError: false,
                              //   });
                              // }
                            }}
                            // onBlur={() => {
                            //   if (!selectedcampcat) {
                            //     setCampaignValuesError({
                            //       ...campaignValuesError,
                            //       typeOfCampaignError: true,
                            //     });
                            //   } else {
                            //     setCampaignValuesError({
                            //       ...campaignValuesError,
                            //       typeOfCampaignError: false,
                            //     });
                            //   }
                            // }}
                          />
                        </div>
                        {/* <div style={{padding:"0px 30px 0px 0px", minWidth:"200px", marginBottom:"5px"}}>
                                    <AsyncSelect
                                      loadOptions={loadOptions}
                                      defaultOptions ={campCategory}
                                      value={selectedcampcat}
                                      isMulti
                                      styles={colorStyles}
                                      // onChange={(e) => {
                                      //   handleCampaignFieldChange("typeOfCampaign", e);
                                      // }}
                                      // onChange={(event) => {
                                      //   setselectedcampcat(event);
                                      //   console.log(selectedcampcat);
                                      //   if (_.isEmpty(event)) {
                                      //     setCampaignValuesError({
                                      //       ...campaignValuesError,
                                      //       typeOfCampaignError: true,
                                      //     });
                                      //   } else {
                                      //     setCampaignValuesError({
                                      //       ...campaignValuesError,
                                      //       typeOfCampaignError: false,
                                      //     });
                                      //   }
                                      // }}
                                      // onBlur={() => {
                                      //   if (!selectedcampcat) {
                                      //     setCampaignValuesError({
                                      //       ...campaignValuesError,
                                      //       typeOfCampaignError: true,
                                      //     });
                                      //   } else {
                                      //     setCampaignValuesError({
                                      //       ...campaignValuesError,
                                      //       typeOfCampaignError: false,
                                      //     });
                                      //   }
                                      // }}
                                    />
                                    </div> */}
                        {/* <select class="custom-select">
                                        <option selected="">All Catagories</option>
                                        <option value="1">Catagories 1</option>
                                        <option value="2">Catagories 2</option>
                                        <option value="3">Catagories 3</option>
                                    </select>
                                    <select class="custom-select">
                                        <option selected="">Price Range</option>
                                        <option value="1">$100 - $499</option>
                                        <option value="2">$500 - $999</option>
                                        <option value="3">$1000 - $4999</option>
                                    </select> */}
                        {/* <button type="submit" class="btn market-search"><i class="fa fa-search pr-2" aria-hidden="true"></i> Search</button> */}
                        <Button
                          class="btn market-search"
                          variant="contained"
                          // onClick={handleSearch}
                          onClick={getRewardsFromMarketplaceOnSearch}
                          className="btn save-btn"
                          disableElevation
                          name="search"
                          // disabled={isAddrewardsFetching}
                        >
                          {/* {isAddrewardsFetching ? (
                                        <CircularProgress color="inherit" size={24} />
                                      ) : ( */}
                          <i class="fa fa-search pr-2" aria-hidden="true"></i>
                          <span>Search</span>
                          {/* )} */}
                        </Button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div class="containers">
                <div class="cyagg-sec-space">
                  {/* <div class="row"> */}
                  <div>
                    <div class="col-md-12">
                      <div class="">
                        {/* <div class="reward-slide owl-carousel owl-theme "> */}
                        {/* <OwlCarousel className="owl-theme" {...options}> */}
                        <div>
                          {marketplacelist &&
                            // marketplacelist?.data?.map((reward, index) => (
                            marketplacelist?.map((reward, index) => (
                              // <div class="item" style={{marginBottom:"10px"}}>

                              <div class="marketplaceinrow" style={{ marginBottom: "30px", float: "left" }} >
                                <div class="reward-wrap-marketplace">
                                  <div style={{maxHeight:"400px", position:"relative"}}>
                                  <div>
                                    {
                                      !(reward?.logo === "") ?(
                                        reward?.expired === "yes" ?
                                        <img
                                          class="reward-image"
                                          src={reward?.logo}
                                          style={{ width: "100%", opacity:0.4}}
                                        />
                                        :
                                        <img
                                          class="reward-image"
                                          src={reward?.logo}
                                          style={{ width: "100%" }}
                                        />
                                      ): (
                                        reward?.expired === "yes" ?
                                        <img
                                          class="reward-image"
                                          src={CEOImage}
                                          style={{ width: "100%", opacity:0.4}}
                                        />
                                        :
                                        <img
                                          class="reward-image"
                                          src={CEOImage}
                                          style={{ width: "100%" }}
                                        />
                                      )
                                    }
                                  </div>
                                  {
                                    reward?.expired ==="yes" ? (
                                    <h3 class="expiry-txt" style={{color:"red"}}>
                                        EXPIRED
                                    </h3>
                                    ):""
                                  }
                                  {/* {
                                  reward?.expired ==="yes" ? (
                                  // <Chip key={reward?.mid} size="big" label="EXPIRED" style={{backgroundColor: "white", marginTop:"-229px", width:"100%", height:"50px", color:"red", fontWeight:"bolder", letterSpacing:"9px", fontSize:"18px"}}/>
                                  <span style={{backgroundColor: "white", marginTop:"-229px", width:"100%", height:"50px", color:"red", fontWeight:"bolder", letterSpacing:"9px", fontSize:"18px"}}>EXPIRED</span>
                                  ):""
                                    } */}
                                  </div>
                                  <div class="reward-details">
                                    <div>
                                      <div>
                                        <div id="toggleAccordion">
                                          {/* <div class="card mb-1" style={{height:"54px"}}> */}
                                          <div class="card mb-1">
                                            <div
                                              class="card-header"
                                              id="headingOne4"
                                              style={{borderBottom:"0px", backgroundColor:"white"}}
                                            >
                                              <h5 class="mb-0 mt-0 reward-desc-title" title={reward?.rewardtype}>
                                                <span
                                                  role="menu"
                                                  class=""
                                                  data-toggle="collapse"
                                                  data-target={"#" + index}
                                                  aria-expanded="true"
                                                  aria-controls="iconChangeAccordionOne"
                                                >
                                                  {reward?.rewardtype}
                                                  <i
                                                    style={{
                                                      marginLeft: "2px",
                                                    }}
                                                    class="flaticon-down-arrow float-right"
                                                  ></i>
                                                </span>
                                              </h5>
                                            </div>

                                            <div
                                              id={index}
                                              class="collapse"
                                              aria-labelledby="headingOne4"
                                              data-parent="#toggleAccordion"
                                            >
                                              <div class="card-body">
                                                <p class="mb-3">
                                                  <div
                                                    className={
                                                      classes.subtitleWrapper
                                                    }
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 12,
                                                        color: "blue",
                                                      }}
                                                    >
                                                      {" "}
                                                      {reward?.subtitle}{" "}
                                                    </span>
                                                  </div>
                                                </p>
                                                <p class="mb-3">
                                                  <div
                                                    className={
                                                      classes.subtitleWrapper
                                                    }
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 12,
                                                        color: "green",
                                                      }}
                                                    >
                                                      {" "}
                                                      {reward?.title}{" "}
                                                    </span>
                                                  </div>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="redeem-pts">
                                        <div class="row">
                                        <div class="col-6" style={{display:"inline-flex"}}>
                                          {reward?.rewardcat === "rewards" &&
                                          reward?.campid === "camp00001" ? (
                                            
                                              <div class="re-po-left">
                                                <img src="https://png.pngtree.com/png-clipart/20220213/original/pngtree-coins-stack-icon-vector-png-image_7267762.png" />
                                                <div class="pts">
                                                  <h5>{reward?.points}</h5>
                                                  <span>Points</span>
                                                </div>
                                              </div>
                                            
                                          ) : (
                                            ""
                                          )}

                                          {
                                          ((reward?.campid === "camp00001" &&
                                          reward?.rewardcat === "rewards" && !(reward?.expired === "yes")) || (reward?.registrationstatus ===
                                            "approved" && !(reward?.campid === "camp00001") && !(reward?.expired === "yes") &&
                                            reward?.rewardcat === "rewards") ||
                                            (reward?.registrationstatus ===  "approved" && !(reward?.campid === "camp00001") && !(reward?.expired === "yes") &&
                                              reward?.rewardcat === "recognitions" && reward?.recogeligibility === "yes")) ? (
                                            
                                              <div class="re-po-right">
                                                <Button
                                                  onClick={() =>
                                                    setDisplayAndIndex(
                                                      true,
                                                      index
                                                    )
                                                  }
                                                  className={classes.button}
                                                  style={{ float: "right" }}
                                                >
                                                  <a>Redeem</a>
                                                </Button>
                                              </div>
                                            // </div>
                                          ) : (
                                            // <div class="col-12">
                                              <div class="re-po2-center">
                                                <Button
                                                  disabled
                                                  className={classes.button}
                                                  // style={{ float: "right" }}
                                                >
                                                  <a>Redeem</a>
                                                </Button>
                                              </div>
                                            // </div>
                                          )}
                                        </div>
                                      </div>
                                      </div>

                                      <div class="redeem-pts1">
                                        <div class="row">
                                          <div class="col-12">
                                            <div class="re-po1-right">
                                              {!(
                                                reward?.campid === "camp00001"
                                              ) ? (
                                                <Button
                                                  onClick={() =>
                                                    navigate(
                                                      `/participate/${reward?.campid}/${profileResponse?.phoneno}`
                                                    )
                                                  }
                                                  style={{
                                                    // float: "right",
                                                    // width: "114px",
                                                    textAlign: "left",
                                                    maxHeight: "72px",
                                                  }}
                                                >
                                                  <a
                                                    style={{
                                                      fontFamily: "sans-serif",
                                                      //   textOverflow:"ellipsis",
                                                      // overflow: "hidden",
                                                    }}
                                                  >
                                                    {reward?.campname}
                                                  </a>
                                                </Button>
                                              ) : (
                                                <Button
                                                  onClick={() =>
                                                    navigate(`/aboutus`)
                                                  }
                                                  style={{
                                                    // float: "right",
                                                    // width: "114px",
                                                    textAlign: "left",
                                                    maxHeight: "72px",
                                                  }}
                                                >
                                                  <a
                                                    style={{
                                                      fontFamily: "sans-serif",
                                                    }}
                                                  >
                                                    {reward?.campname}
                                                  </a>
                                                </Button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="text-center">
                                        <h6 class="sponsor-title">Sponsors</h6>
                                        <div class="row sponserWrapper">
                                        <div class="">
                                          {isArray(reward?.sponsor) &&
                                            reward?.sponsor?.map(
                                              (reward, index) => (
                                                handleImageLoad(reward.sponsorlogo) ? (
                                                  <img
                                                    style={{
                                                      width: "auto",
                                                      height: "72px",
                                                    }}
                                                    src={reward.sponsorlogo}
        key={index} alt="reward"
                                                  />
                                                  ) : null
                                              )
                                            )}
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>

        <RedeemConfirmation
          showModal={displayRedeemConfirmationModal}
          confirmModal={handleRedeem}
          hideModal={hideRedeemConfirmationModal}
          message={"Are you sure you want to redeem this reward?"}
        />
        <ConfirmationDialog
          showModal={displayConfirmationModal}
          // confirmModal={handleRedeem}
          hideModal={hideConfirmationModal}
          message={"Either You do not have enough points or reward is not available for redeem"}
        />
        <PostRedeemConfirmation
          showModal={postRedeemDisplayModal}
          confirmModal={handlePostRedeemModal}
          // hideModal={handlePostRedeemModal}
          message={
            "Congratulations!!! You have successfully redeemed the reward. You can check the redeem status from Rewards -> Redeem Status on left nav pane."
          }
        />
        <div className="marketplaceCopyright">
        <CopyRightMarketplace />
        </div>
        <FixedFooterIcon />
      </section>
    </div>
  );
}
export default Marketplace;
