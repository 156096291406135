/** @format */

import { call, put } from "redux-saga/effects";
import { get } from "lodash";

import {
  GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA,
  GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_SUCCESS,
  GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_FAILURE,
} from "../action/getReport";
import { Api } from "../../services";
import {
  GET_REPORT_USER_IN_STATE_FETCHING_DATA,
  GET_REPORT_USER_IN_STATE_FETCHING_DATA_FAILURE,
  GET_REPORT_USER_IN_STATE_FETCHING_DATA_SUCCESS,
} from "../action/getReport";
import {
  GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA,
  GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_FAILURE,
  GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_SUCCESS,
} from "../action/getReport";

const myAPI = new Api();

export function* getReportActivitySaga(action) {
  yield put({
    type: GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.getReportActivity, action.payload);
    const payload = get(response, "data");
    yield put({
      type: GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({ type: GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}

export function* getReportStateSaga(action) {
  yield put({
    type: GET_REPORT_USER_IN_STATE_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.getReportFromState, action.payload);
    const payload = get(response, "data");
    yield put({
      type: GET_REPORT_USER_IN_STATE_FETCHING_DATA_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({ type: GET_REPORT_USER_IN_STATE_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}

export function* getSOVolReportSaga(action) {
  yield put({
    type: GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.getSOVolReportSaga, action.payload);
    const payload = get(response, "data");
    yield put({
      type: GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({ type: GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}
