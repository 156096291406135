/** @format */

// reducers
import {
    GET_REWARD_TITLE_FETCHING_DATA,
    GET_REWARD_TITLE_FETCHING_DATA_FAILURE,
    GET_REWARD_TITLE_FETCHING_DATA_RESET,
    GET_REWARD_TITLE_FETCHING_DATA_SUCCESS,
    
    // GET_REWARD_SUBTITLE_FETCHING_DATA,
    // GET_REWARD_SUBTITLE_FETCHING_DATA_FAILURE,
    // GET_REWARD_SUBTITLE_FETCHING_DATA_RESET,
    // GET_REWARD_SUBTITLE_FETCHING_DATA_SUCCESS,
    
  } from "../action/RewardDetailAction";
  
  let initialState = {
    titleResponse: {},
    titleSuccess: false,
    titleError: false,
    titleFetching: false,

    // subtitleResponse: {},
    // subtitleSuccess: false,
    // subtitleError: false,
    // subtitleFetching: false,
  };
  
  export default function RewardDetailReducer(state = initialState, action) {
    switch (action.type) {
      case GET_REWARD_TITLE_FETCHING_DATA:
        return {
          ...state,
          titleFetching: true,
          titleError: false,
          titleSuccess: false,
        };
      case GET_REWARD_TITLE_FETCHING_DATA_FAILURE:
        return {
          ...state,
          titleFetching: false,
          titleError: action.payload,
          titleSuccess: false,
        };
      case GET_REWARD_TITLE_FETCHING_DATA_SUCCESS:
        return {
          ...state,
          titleFetching: false,
          titleError: false,
          titleResponse: action.payload,
          titleSuccess: true,
        };
      case GET_REWARD_TITLE_FETCHING_DATA_RESET:
        return {
          ...state,
          titleResponse: {},
          titleSuccess: false,
          titleError: false,
          titleFetching: false,
        };

      // case GET_REWARD_SUBTITLE_FETCHING_DATA:
      //   return {
      //       ...state,
      //       subtitleFetching: true,
      //       subtitleError: false,
      //       subtitleSuccess: false,
      //   };
      // case GET_REWARD_SUBTITLE_FETCHING_DATA_FAILURE:
      //   return {
      //       ...state,
      //       subtitleFetching: false,
      //       subtitleError: action.payload,
      //       subtitleSuccess: false,
      //   };
      // case GET_REWARD_SUBTITLE_FETCHING_DATA_SUCCESS:
      //   return {
      //       ...state,
      //       subtitleFetching: false,
      //       subtitleError: false,
      //       subtitleResponse: action.payload,
      //       subtitleSuccess: true,
      //   };
      // case GET_REWARD_SUBTITLE_FETCHING_DATA_RESET:
      //   return {
      //       ...state,
      //       subtitleResponse: {},
      //       subtitleSuccess: false,
      //       subtitleError: false,
      //       subtitleFetching: false,
      //   };
      default:
        return state;
    }
  }
  