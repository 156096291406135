import * as React from "react";
import FormControl from "@mui/material/FormControl";
import {
  makeStyles,
  
} from "@material-ui/core";
import {
  Avatar,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import {
  focusAreaList,
  focusAreaDict,
  registeredAs
} from "../../utils/dict";

import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";

import axios from "axios";
import ErrorMessage from "../../components/ErrorMessage";
import _ from "lodash";
import RedStar from "../../components/RedStar";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link } from "react-router-dom";
import { getRewardTitle } from "../../redux/action/RewardDetailAction";
import { useLocation, useNavigate } from "react-router-dom";
// import { updateReward } from "../../redux/saga/UpdateSaga";
import { useEffect, useState } from "react";
import Multiselect from 'multiselect-react-dropdown';

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },

    display: "block",
    display: "flex",
    //fontSize: 16,
    fontSize: "16px",
    marginTop: 16,
    padding: 10,
    fontWeight: "500",
    lineHeight: "18px",
    minHeight: 50,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {

    },

    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroupFull: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%"
    },
    [theme.breakpoints.only("md")]: {
      width: "100%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      // width: "25%"
      width: "20%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
}));

function RewardEditScreen(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationPath = useLocation();
  
  const [response, setResponse] = useState({});
  // const [rewardtitle, setRewardtitle] = React.useState([
  //   { rewardtype: "", title: "", subtitle: "", sponsor: "", points: "", titleList: "" },
  // ]);
  const [rewardtitle, setRewardtitle] = React.useState([
    { rewardtype: "", title: "", subtitle: "", sponsor: "", points: "" },
  ]);
  const [selected1, setSelected1] = React.useState([]);
  const [sponsor, setSponsor] = React.useState([]);

  // const [focusError, setFocusError] = React.useState(false);
  // const [newField, setNewField] = React.useState(false);
  // const [newRewardField, setNewRewardField] = React.useState(false);
  // const [areas, setAreas] = React.useState([]);
  // const [cityResponse, setCityResponse] = React.useState({});
  // const [rewardDetailResponseEffect, setrewardDetailResponseEffect] = React.useState({});
  // const [notificationShow, setNotificationShow] = React.useState(false);
  // const [activityList, setActivityList] = React.useState(undefined);
  const [addRewardsValues, setaddRewardsValues] = React.useState({
    rewardtype: "",
    title: "",
    subtitle: "",
    sponsor:"",
    points:"",
  });
  const [addRewardsValuesError, setaddRewardsValuesError] = React.useState({
    rewardtypeError: "",
    titleError: "",
    subtitleError: "",
    sponsorError:"",
    pointsError:"",
  });



  const { titleSuccess: rewardDetailSuccess, titleResponse: rewardDetailResponse } = useSelector(
    (state) => state.getRewardTitle
  );
  

  const handleChangeRewards = (id, event, field) => {
   
    setResponse({});
    
    
    // if (field === 'rewardtype') {
    //   value[index]["titleList"] = rewardDetailResponseEffect;
    // }
    // value[index][field] = event.target.value;
    // value[field] = event.target.value;
    // console.log("value [field]");
    // console.log(value[field]);
    // setRewardtitle(value);
   
 
   
    var rewardtype1, title1, subtitle1, sponsor1, points1;
    
 
    // console.log("title1");
    // console.log(title1);
    // console.log("subtitle1");
    // console.log(subtitle1);
    // console.log("sponsor1");
    // console.log(sponsor1);
    // console.log("points1");
    // console.log(points1);

    setRewardtitle({
      ...rewardtitle,
      [field]: field === "dob" ? event : 
               field === "sponsor"? event:
                                    event.target.value,
      // rewardtype: event.target.value,
      // title: rewardDetailResponse?.[0].title,
      // subtitle: rewardDetailResponse?.[0].subtitle,
      // sponsor: rewardDetailResponse?.[0].sponsor,
      // points: rewardDetailResponse?.[0].points,
    });
    
  
  

    // setSelected([...selected, value[value?.length - 1]]);
  };

//   const handleFieldChange = (id, event, field) => {
//     setResponse({});
//     setvolunteerDetails({
//         ...volunteerDetails,
//         [field]: field === "dob" ? e : e.target.value,
//     });
// };
React.useEffect(() => {

  getSponsorList();
}, []);

const getSponsorList = async () => {
  try {
    const res = await axios.get(`/sponsor`).then((response) => {
      // const values = [...rewardtitle];
      
      setSponsor(response?.data)
      return response?.data;
    });

  } catch (err) {
    throw err;
  }
};

  const handleSave = async () => {
  
    try {
      if(addRewardsValuesError?.pointsError === true || 
          addRewardsValuesError?.rewardtypeError === true || 
            addRewardsValuesError?.titleError === true ||
            addRewardsValuesError?.subtitleError === true || 
            addRewardsValuesError?.sponsorError === true) {
              window.scrollTo(0, 0);
      } else {
        if (rewardDetailSuccess) {
          const res = await axios.put(`/marketplace/reward/edit`, {
              mpid: locationPath?.state?.mpid,
              rewardtype: rewardtitle?.rewardtype,
              title: rewardDetailResponse?.[0].title,
              subtitle: rewardDetailResponse?.[0].subtitle,
              sponsor: rewardtitle?.sponsor,
              points: rewardtitle?.points,
              
          });
          setResponse(res?.data);
          return res;
        } else {
          const res = await axios.put(`/marketplace/reward/edit`, {
            mpid: locationPath?.state?.mpid,
            rewardtype: rewardtitle?.rewardtype,
            title: rewardtitle?.title,
            subtitle: rewardtitle?.subtitle,
            sponsor: rewardtitle?.sponsor,
            points: rewardtitle?.points,
            
        });
        setResponse(res?.data);
        
        return res;
        }
    }

    } catch (e) {
       
    }
};


  const handleAddRewards = () => {
    // console.log(addRewardsValues);
    // console.log(rewardtitle.length);
    // console.log(rewardtitle?.rewardtype);
    // console.log(rewardtypeError);
    // console.log(titleError);
    // console.log(subtitleError);
    // console.log(sponsorError);
    // console.log(pointsError);
      // if (addRewardsValues.rewardtype) {
      //   setaddRewardsValuesError({
      //     ...addRewardsValuesError,
      //     rewardtypeError: true,
      //   });
      //   window.scrollTo(0, 0);
      // } else if (!addRewardsValues?.subtitle) {
      //   setaddRewardsValuesError({
      //     ...addRewardsValuesError,
      //     subtitleError: true,
      //   });
      //   window.scrollTo(0, 0);
      // } else if (!addRewardsValues?.sponsor) {
      //   setaddRewardsValuesError({
      //     ...addRewardsValuesError,
      //     sponsorError: true,
      //   });
      //   window.scrollTo(0, 0);
      // } else if (!addRewardsValues?.points) {
      //   setaddRewardsValuesError({
      //     ...addRewardsValuesError,
      //     pointsError: true,
      //   });
      //   window.scrollTo(0, 0);
      // } else {
        // dispatch(addRewardsReset());
        let formData = new FormData();
        formData.append("rewardtitle", JSON.stringify(rewardtitle));
        dispatch(
          // onboardCampaign({ userid: localStorage.getItem("userid"), formData })
          // addRewards({ userid: localStorage.getItem("userid"), formData })
          // updateReward({mpid: locationPath?.state.mpid, formData})
        );
    //  }
  };

  
  // console.log(rewardDetailResponse);
  // console.log(rewardtitle);

  const getRewardDetails = (itemreward) => {
   
    return (
      <div style={{ float: "left", width: "75%", marginTop: 50 }}>

        <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>Reward Details</span>
        <form style={{ display: "flex", flexWrap: "wrap", width: "100%", float: "left" }}>
        {rewardDetailSuccess ? (
          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Reward Type
            </label>
            <Select
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
              // value={rewardDetailResponse?.[0].rewardtype}
              value={itemreward.rewardtype}
              onBlur={() => {
                if (!itemreward.rewardtype) {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    rewardtypeError: true,
                  });
                } else {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    rewardtypeError: false,
                  });
                }
              }}
              onChange={(e) => {
                handleChangeRewards(itemreward, e, "rewardtype")
                // dispatch(getRewardTitle(item.rewards));
                
                // getRewardDetailList(itemreward.rewardtype)
                dispatch(getRewardTitle(e.target.value));
              }}
            >
              {focusAreaList.map((item, index) => (
                <MenuItem style={{ fontSize: 16 }} value={item.rewards}>
                  {item.rewards}

                </MenuItem>
              ))}
            </Select>
            {addRewardsValuesError?.rewardtypeError && (
                      <ErrorMessage message="Please enter the reward type for this reward" />
            )}
          </div>
        ) :
        <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Reward Type
            </label>
            <Select
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
              value={locationPath?.state.rewardtype}
              // value={itemreward.rewardtype}
              onBlur={() => {
                if (!itemreward.rewardtype) {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    rewardtypeError: true,
                  });
                } else {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    rewardtypeError: false,
                  });
                }
              }}
              onChange={(e) => {
                handleChangeRewards(itemreward, e, "rewardtype")
                // getRewardDetailList(itemreward.rewardtype)
                dispatch(getRewardTitle(e.target.value));
              }}
            >
              {focusAreaList.map((item, index) => (
                <MenuItem style={{ fontSize: 16 }} value={item.rewards}>
                  {item.rewards}

                </MenuItem>
              ))}
            </Select>
            {addRewardsValuesError?.rewardtypeError && (
                      <ErrorMessage message="Please enter the reward type for this reward" />
            )}
          </div>
  }
          
          {/* {index !== rewardtitle.length - 1 || (index === rewardtitle.length - 1 && rewardtitle[index].title !== "") || index === 0 ? ( */}
          {rewardDetailSuccess ? (
            <div className={classes.formGroup1}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Reward Title
              </label>
              <Select
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
                // value={itemreward?.titlelist?.[0].title}
                // value={itemreward.title}
                value={rewardDetailResponse?.[0].title}
                onBlur={() => {
                  if (!itemreward.title) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      titleError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      titleError: false,
                    });
                  }
                }}
                onChange={(e) => {
                  handleChangeRewards(itemreward, e, "title")
                }}
              >
                {/* {rewardDetailSuccess && */}
                {/* // rewardtitle[index]?.titleList.map((item, index) => ( */}
                {/* //     rewardtitle?.titleList.map((item, index) => (  */}
                     {rewardDetailSuccess &&
                      rewardDetailResponse.map((item, index) => (
                     <MenuItem style={{ fontSize: 16 }} value={item.title}>
                      
                      
                      {item.title}
                      </MenuItem>
  ))}
                    
                  {/* ))} */}

              </Select>
              {addRewardsValuesError?.titleError && (
                      <ErrorMessage message="Please enter the title for this reward" />
            )}
            </div>
          ) : 

<div className={classes.formGroup1}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Reward Title
              </label>
              <Select
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
                // value={itemreward?.titlelist?.[0].title}
                // value={itemreward.title}
                value={locationPath?.state.title}
                onBlur={() => {
                  if (!itemreward.title) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      titleError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      titleError: false,
                    });
                  }
                }}
                onChange={(e) => {
                  handleChangeRewards(itemreward, e, "title")
                }}
              >
                {/* {rewardDetailSuccess && */}
                {/* // rewardtitle[index]?.titleList.map((item, index) => ( */}
                {/* //     rewardtitle?.titleList.map((item, index) => (  */}
                     {/* {rewardDetailSuccess &&
                      rewardDetailResponse.map((item, index) => ( */}
                     <MenuItem style={{ fontSize: 16 }} value={locationPath?.state.title}>
                      
                      
                      {locationPath?.state.title}
                      </MenuItem>
  {/* ))} */}
                    
                  {/* ))} */}

              </Select>
              {addRewardsValuesError?.titleError && (
                      <ErrorMessage message="Please enter the title for this reward" />
            )}
            </div>
  }

            
          {/* } */}


          {/* {index !== rewardtitle.length - 1 || (index === rewardtitle.length - 1 && rewardtitle[index].subtitle !== "") || index === 0 ? ( */}
           
          {rewardDetailSuccess ? (
            <div className={classes.formGroup1}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Reward Sub Title
              </label>
              <Select

                // style={{
                //   width: "50%",
                //   fontSize: 14,
                //   height: 56,
                //   boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                // }}

                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}


                // value={itemreward?.titlelist?.[0].subtitle}
                // value={itemreward.subtitle}
                // value={itemreward.subtitle}
                value={rewardDetailResponse?.[0].subtitle}
                
                onChange={(e) => {
                  handleChangeRewards(itemreward, e, "subtitle")

                }}
                onBlur={() => {
                  if (!itemreward.subtitle) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      subtitleError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      subtitleError: false,
                    });
                  }
                }}
              >

                {/* {rewardDetailSuccess &&
                   rewardtitle[index]?.titleList.map((item, index) => (
                    rewardtitle?.titleList.map((item, index) => ( */}
                        {rewardDetailSuccess &&
                      rewardDetailResponse.map((item, index) => (
                     <MenuItem style={{ fontSize: 16 }} value={item.subtitle}>
                      
                      
                      {item.subtitle}
                      </MenuItem>
  ))}
                  {/* ))} */}
              </Select>
              {addRewardsValuesError?.subtitleError && (
                      <ErrorMessage message="Please enter the subtitle for this reward" />
            )}
            </div>

          ) :

          <div className={classes.formGroup1}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Reward Sub Title
              </label>
              <Select

                // style={{
                //   width: "50%",
                //   fontSize: 14,
                //   height: 56,
                //   boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                // }}

                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}


                // value={itemreward?.titlelist?.[0].subtitle}
                // value={itemreward.subtitle}
                // value={itemreward.subtitle}
                value={locationPath?.state.subtitle}
                
                onChange={(e) => {
                  handleChangeRewards(itemreward, e, "subtitle")

                }}
                onBlur={() => {
                  if (!itemreward.subtitle) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      subtitleError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      subtitleError: false,
                    });
                  }
                }}
              >

                {/* {rewardDetailSuccess &&
                   rewardtitle[index]?.titleList.map((item, index) => (
                    rewardtitle?.titleList.map((item, index) => ( */}
                        {/* {rewardDetailSuccess &&
                      rewardDetailResponse.map((item, index) => ( */}
                     <MenuItem style={{ fontSize: 16 }} value={locationPath?.state.subtitle}>
                      
                      
                      {locationPath?.state.subtitle}
                      </MenuItem>
  {/* ))} */}
                  {/* ))} */}
              </Select>
              {addRewardsValuesError?.subtitleError && (
                      <ErrorMessage message="Please enter the subtitle for this reward" />
            )}
            </div>
  }
            
          {/* } */}


          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Sponsor Name
            </label>
            <Multiselect
                      displayValue="sponsor"
                      showCheckbox
                      //    selectedValues={selected1}
                      selectedValues={locationPath?.state?.sponsor}
                      // selectedValues={campaignValues?.skill}
                      options={sponsor}
                      onSelect={(event) => {
                        // setSelected1(event);
                        // setSelectedSkills(event)
                        handleChangeRewards(itemreward, event, "sponsor")
                        if (_.isEmpty(event)) {
                      
                          setaddRewardsValuesError({
                            ...addRewardsValuesError,
                            sponsorError: true,
                          });
                        } else {
                          setaddRewardsValuesError({
                            ...addRewardsValuesError,
                            sponsorError: false,
                          });
                        }
                      }}
                      onRemove= {(event) => {
                        // setSelectedSkills(event)
                        handleChangeRewards(itemreward, event, "sponsor")
                        if (_.isEmpty(event)) {
                          
                          setaddRewardsValuesError({
                            ...addRewardsValuesError,
                            sponsorError: true,
                          });
                        } else {
                          setaddRewardsValuesError({
                            ...addRewardsValuesError,
                            sponsorError: false,
                          });
                        }
                       }}

                       
                      // value={locationPath?.state?.skill}
                      value={selected1}

                      style={{
                        multiselectContainer: {
                          boxShadow: "0px 3px 6px #00000029",
                        },
                        searchBox: {
                          minHeight: 54,
                          PaddingTop: 10
                        }
                      }}


                    />
            
            {addRewardsValuesError?.sponsorError && (
                <ErrorMessage message="Please enter the Sponsor name for this reward" />
            )}
          </div>

          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Points
            </label>
            <input
              // style={{
              //   width: "50%",
              //   fontSize: 14,
              //   height: 56,
              //   boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              // }}

              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
              // value={locationPath?.state.points}
              defaultValue={locationPath?.state.points}
              // type="text"
              id="reward-points"
              className={classes.input}
              placeholder="Your Answer"
              
              onBlur={() => {
                
                if (!itemreward.points) {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    pointsError: true,
                  });
                } else {
                  if (!Number(itemreward.points)) {
                   
                    
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      pointsError: true,
                    });
                  } else{
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    pointsError: false,
                  });
                }
                }
              }}
              onChange={(e) => 
                
                handleChangeRewards(itemreward, e, "points")
              }

            />
            {addRewardsValuesError?.pointsError && (
                      <ErrorMessage message="Please enter points in numbers" />
            )}
          </div>

        </form>
        {/* {
          index ?
            <div style={{ float: "right", position: "relative", bottom: 44, cursor: "pointer", left: 19 }} onClick={(e) => handleRemoveFields(index)}><RemoveCircleOutlineOutlined></RemoveCircleOutlineOutlined></div>
            : <div className="handlePlus" style={{ float: "right", bottom: 45, position: "relative", left: 20, cursor: "pointer", right: "15px" }}><AddCircleOutlineOutlined
              onClick={handleRewardAddFields}
            ></AddCircleOutlineOutlined></div>
        } */}
      </div>
    );
  };


  React.useEffect(() => {
    
    if (locationPath?.state) {
      setRewardtitle({
        rewardtype: locationPath?.state?.rewardtype,
        title: locationPath?.state?.title,
        subtitle: locationPath?.state?.subtitle,
        sponsor: locationPath?.state?.sponsor,
        points: locationPath?.state?.points,
      });
    }
  }, [locationPath?.state]);


  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
          <Button
              onClick={() => navigate(-1)}
              className={classes.backBtn}
          >
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
          </Button>
            <div class="content-title">Edit Rewards</div>
            <div style={{ fontFamily: "monospace", fontSize: 16, }}>
              <div style={{ display: "flex", width: "75%", justifyContent: "flex-end", marginRight: 5, marginBottom: 20, }}>
                <span style={{ color: "red" }}>Please do not leave this page until you submit as you may risk losing the partially filled in form </span>
              </div>
            </div>
            <form autoComplete="off">
            {/* {JSON.stringify(rewardtitle)}
            {JSON.stringify(locationPath?.state)} */}
              <div style={{ border: "1px solid #dadce0", borderRadius: 8, backgroundColor: "#fff" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >


                  <div className={classes.formGroupFull}>
                    {/* {rewardtitle.map((itemreward, index) => ( */}
                      {/* <div key={index}>{getRewardDetails(rewardtitle)}</div> */}
                      <div >{getRewardDetails(rewardtitle)}</div>
                    {/* ))} */}

                    {/* {locationPath?.state?.map((itemreward, index) => (
                      <div key={index}>{getRewardDetails(itemreward, index)}</div>
                    ))} */}
                    
                  </div>

                </div>
                <div>
                {response && (
                <span style={{ color: "green",textAlign: "center", display: "block" }}>
                  {response?.message}
                </span>
              )}
              {!response && (
                <span style={{ color: "red", textAlign: "center", display: "block" }}>Please verify all the fields before submitting</span>
              )}
                </div>
                <div className="centerAlign" style={{ padding: "16px 0", marginBottom: 50 }}>
                  <Button

                    variant="contained"
                    onClick={handleSave}
                    className="btn save-btn"
                    disableElevation
                    // disabled={isRewardFetching}
                  >
                    {/* {isRewardFetching ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : ( */}
                      <span>Submit</span>
                    {/* )} */}
                  </Button>
                  <br />
                  
                </div>
              </div>
            </form>
          </div>
        </div>
        <CopyRight />
      </section>
    </div>
  );
}
export default RewardEditScreen;
