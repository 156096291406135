import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import CampaignIcon from "@mui/icons-material/Campaign";
import ScoreIcon from "@mui/icons-material/Score";
import PersonIcon from "@mui/icons-material/Person";
import { OrgIcon } from "../assets/icon/OrgIcon";
import { VolunteerIcon } from "../assets/icon/VolunteerIcon";
import { CampIcon } from "../assets/icon/CampaignIcon";
import { VolIcon } from "../assets/icon/VolunteerIcon";
import { LogoutIcon } from "../assets/icon/LogoutIcon";
import { CircleBulletIcon } from "../assets/icon/CircleBullet"

import {
  ExpandMore,
  HomeOutlined,
  Notifications,
  SettingsOutlined,
} from "@material-ui/icons";
import {
  ExpandLess,
  LogoutOutlined,
  Logout,
  NotificationAddOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  userProfile,
  userProfileReset,
} from "../redux/action/UserProfileActions";
import { socialLoginReset } from "../redux/action/loginAction";
import { getVolunteerCount } from "../redux/action/volunteerCountAction";
import {
  Avatar,
  Badge,
  CircularProgress,
  Collapse,
  MenuItem,
  useMediaQuery,
  useTheme,
  
} from "@mui/material";
import { getUserProfileImage } from "../redux/action/userProfileImage";
import { makeStyles } from "@material-ui/styles";
import {Dashboard} from "@mui/icons-material";
import moment from "moment";
import _ from "lodash";

import axios from "axios";
import { API_URL } from "../services/Constants";

const drawerWidth = 500;

const menuItems = [

  { 
    id: 1, 
    label: "Dashboard", 
    path: "/dashboard", 
    // icon: <Dashboard /> 
  },
]


const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "start",
    // alignItems: "flex-start",
    // WebkitAlignItems: "start",
  },
  input: {
    display: "none",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function Sidebar(props) {
  const classes = useStyles();
  const { window } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const [show, setShow] = React.useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  // const { isSuccess, response: volunteerCountResponse } = useSelector(
  //   (state) => state?.getVolunteerCount
  // );

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const {
    isSuccess: profileImageSuccess,
    response: profileImageResponse,
    isError: profileImageError,
  } = useSelector((state) => state.profileImage);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const clearStorage = () => {
    localStorage.clear();
    dispatch(userProfileReset());
    dispatch(socialLoginReset());
  };

  const updateNotificationFlag = async () => {
    try {
      let formData = new FormData();
      formData.append("notifyflag", "true");

      const res = await axios({
        url: `/notification/flag/${profileResponse?.phoneno}`,
        method: "put",
        data: formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      dispatch(userProfile(localStorage.getItem("userid")));
      return res;
    } catch (err) {
      throw err;
    }
  };

  const handleChangeFile = (e) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("profilephoto", e.target.files[0]);
    dispatch(
      getUserProfileImage({ userId: localStorage.getItem("userid"), formData })
    );
    setImage(e.target.files[0]);
  };

  React.useEffect(() => {
    dispatch(getVolunteerCount());
  }, []);

  React.useEffect(() => {
    if (profileImageSuccess || profileImageError) {
      setLoading(false);
    }
  }, [profileImageSuccess, profileImageError]);

  React.useEffect(() => {
    dispatch(userProfile(localStorage.getItem("userid")));
  }, []);

  const drawer = (
    <div style={{ marginLeft: 300, backgroundColor: "rgb(255, 0, 0)", height: "5%" }}>
      <Toolbar />
    
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box
      onClick={() =>
        props.notificationShow ? props.setNotificationShow(false) : null
      }
      sx={{ display: "flex" }}
    >
      {/* <img
        src={profileResponse?.logo}
        style={{
          width: 190,
          zIndex: 99999,
          marginTop: 10,
          marginRight: 25,
          maxHeight: 90,
          marginLeft: 45,
          position: "fixed",
        }}
      /> */}
      <CssBaseline />

      <AppBar
        onClick={() =>
          props.notificationShow ? props.setNotificationShow(false) : null
        }
        position="fixed" style={{
          backgroundColor: "#ffffff"
        }}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
            style={{ fill: "#D1D3E2" }}
          >
            <MenuIcon />
          </IconButton>
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            {/* <span
              style={{
                fontWeight: "bolder",
                fontSize: 22, color: "#000000"
              }}
            >
              Total volunteers on the platform:{" "}
              {isSuccess ? volunteerCountResponse : null}
            </span> */}
          </div>
          {JSON.stringify(props)}
          <div
            onClick={() => !props.notificationShow && updateNotificationFlag()}
            style={{ marginRight: 14, zIndex: 999999 }}
          >
            <Badge
              badgeContent={profileResponse?.notificationcount}
              color="error"
            >
              <Notifications
                style={{ cursor: "pointer", fill: "#D1D3E2" }}
                onClick={() => {
                  // setShow(!show);
                  props.setNotificationShow(!props.notificationShow);
                }}
              />
            </Badge>
            {props.notificationShow && (
              <div
                style={{
                  position: "absolute",
                  width: 300,
                  height: "50vh",
                  background: "#fff",
                  right: "5%",
                  top: 60,
                  boxShadow: "1px 2px 10px rgba(0,0,0,0.2)",
                  borderRadius: 8,
                  overflow: "scroll",
                }}
              >
                {_.isEmpty(profileResponse?.notification) ? (
                  <div>
                    <span>No any notifications yet</span>
                  </div>
                ) : (
                  profileResponse?.notification?.map((item, index) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: 8,
                      }}
                    >
                      <div
                        style={{
                          background: "#d3d3d3",
                          padding: 8,
                          borderRadius: 8,
                        }}
                      >
                        <span
                          style={{
                            color: "#000",
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          {item?.msg}
                        </span>
                        <span
                          style={{
                            color: "#000",
                            display: "flex",
                            justifyContent: "flex-end",
                            fontSize: 12,
                            color: "grey",
                          }}
                        >
                          {moment(item?.createdate).fromNow()}
                        </span>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
          <span style={{ fontSize: 12, fontWeight: "bold", minWidth: 35 }}>
            {profileResponse?.firstname}
          </span>
          {profileSuccess &&
            (loading ? (
              <CircularProgress color="inherit" size={16} />
            ) : (
              <div style={{ width: 56 }}>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="icon-button-file"
                  type="file"
                  onChange={handleChangeFile}
                />
                <label htmlFor="icon-button-file">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <Avatar
                      src={
                        image
                          ? URL.createObjectURL(image)
                          : profileResponse?.profilephoto
                      }
                      className={classes.large}
                    />
                  </IconButton>
                </label>
              </div>
            ))}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

Sidebar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Sidebar;
