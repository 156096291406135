import { createTheme } from "@mui/material";

export const appTheme = createTheme({
  typography: {
    fontFamily: [
      "Poppins",
      "Lato",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Arial",
      "sans-serif",
    ].join(","),
    fontSize: 14,
    h1: {
      fontSize: 20,
      marginBottom: 20,
    },
    h2: {
      fontSize: 18,
      marginBottom: 20,
    },
    h3: {
      fontSize: 17,
      marginBottom: 10,
    },
  },
  palette: {
    primary: {
      main: "#2C7973",
      dark: "#055F55",
    },
    secondary: {
      main: "#F8B34C",
    },
    error: {
      main: "#FF0033",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1024,
      xl: 1200,
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: 10,
        fontWeight: "bold",
        border: "1px solid #ffffff",
      },
      head: {
        fontWeight: "bold",
        border: "1px solid #ffffff",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
    },
  },
});
