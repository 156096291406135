import * as React from "react";
import {
  Box,
  Card,
  makeStyles,
  Typography,
  Paper,
  CssBaseline,
  Grid,
  useMediaQuery,
  CircularProgress,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  CardActions,
  CardContent,
  Chip,
  CardMedia,
} from "@material-ui/core";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "./Header";
import { CopyRight } from "./CopyRight";
import TopHeaderScreen from "./TopHeader1";
import { Link } from "react-router-dom";
// import { Map, GoogleApiWrapper } from "google-maps-react";
import {
  GoogleMap,
  useLoadScript,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api";
// import { formatRelative } from "date-fns";
import GoogleMapReact from "google-map-react";
import axios from "axios";
import { useState, useEffect, createRef } from "react";
import { JavascriptRounded } from "@mui/icons-material";
import { add, isEmpty } from "lodash";
import mapstyle from "../utils/mapstyle";
// import { current } from "@reduxjs/toolkit";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionText,
} from "@reach/combobox";
// import "@reach/combobox/styles.css";
import { clear } from "@testing-library/user-event/dist/clear";
import {
  LocationOnOutlined,
  MicNone,
  NoEncryption,
  Place,
} from "@material-ui/icons";
import { fontSize } from "@mui/system";
import RoomIcon from "@mui/icons-material/Room";
import { useNavigate } from "react-router-dom";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import { FixedFooterIcon } from "../components/FixedFooterIcon";

const useStyles = makeStyles((theme) => ({
  createScreenMainContent: {
    padding: "80px 50px 150px 0",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 150px 220px",
    },
  },
  box: {
    width: "250px",
    border: "5px solid black",
  },
  boxlarge: {
    height: "300px",
  },
  boxsmall: {
    height: "100px",
  },
  paper: {
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  mapContainer: {
    // width: "100vw",
    // height: "85vh",
    width: "60vw",
    height: "90vh",
  },
  markerContainer: {
    position: "absolute",
    // top: 5rem;
    // left: 60%;
    transform: "translateX(-50%)",
    // width: 100%;
    // max-width: 400px;
    zIndex: 10,
    /* margin: 0;
    padding: 0; */
  },
  pointer: {
    cursor: "pointer",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginBottom: "30px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  loading: {
    height: "600px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    padding: "18px",
  },
  marginBottom: {
    marginBottom: "30px",
  },
  list: {
    height: "75vh",
    overflow: "auto",
  },
  placemarker: {
    background: "none",
    border: "none",
  },
  click: {
    position: "relative",
    padding: 7,
    paddingRight: 25,
    color: "black",
    display: "flex",
    cursor: "pointer",
  },
  backBtn: {
    display: "flex",
    alignItems: "center",
  },
}));

const Marker = ({ children }) => children;

export default function VolunteerOnMap(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const libraries = ["places"];
  const [markers, setMarkers] = useState([]);
  const [selected, setSelected] = useState(null);
  const [coordinates, setCoordinates] = useState({});
 

  const [bounds, setBounds] = useState({});
  const [campList, setCampList] = useState([]);
  const [allCampList, setAllCampList] = useState([]);
  const [places, setPlaces] = useState([]);
  const [childClicked, setChildClicked] = useState({});
  const [vol, setVol] = useState([]);
  const [volCoordinates, setVolCoordinates] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [type, setType] = useState("All Volunteers");

  React.useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        setCoordinates({ lat: latitude, lng: longitude });
      }
    );
  }, []);

  React.useEffect(() => {
    // console.log(coordinates);
  }, [coordinates]);

  // React.useEffect(() => {
  //   setIsLoading(true);
  //   console.log(coordinates, bounds);
  //   getCampListForMaps().then((data) => {
  //     console.log(data);
  //     // setCampList(data);
  //     setPlaces(data);
  //     setIsLoading(false);
  //   });
  // }, [coordinates, bounds]);

  // React.useEffect(() => {
  //   setIsLoading(true);
  //   console.log(coordinates, bounds);
  //   getAllCampListForMaps().then((data) => {
  //     console.log(data);
  //     // setCampList(data);
  //     setPlaces(data);
  //     setIsLoading(false);
  //   });
  // }, [coordinates, bounds]);

  // React.useEffect(() => {
  //   setIsLoading(true);
  //   console.log(coordinates, bounds);
  //   getAllTypesOfCampListForMaps().then((data) => {
  //     console.log(data);
  //     // setCampList(data);
  //     setPlaces(data);
  //     setIsLoading(false);
  //   });
  // }, [type, coordinates, bounds]);

  // React.useEffect(() => {
  //   getVolListOnMaps().then((data) => {
  //     console.log(data);
  //     // setCampList(data);
  //     // setPlaces(data);
  //     setVol(data);
  //   });
  // }, []);

  React.useEffect(() => {
    setIsLoading(true);
    getAllVolListOnMaps().then((data) => {
      // console.log(data);
      // setCampList(data);
      // setPlaces(data);
      setVol(data);
      setIsLoading(false);
    });
  }, [type, coordinates, bounds]);



  // const getVolListOnMaps = async () => {
  //   try {
  //     const res = await axios.get(`cyaaguser`);
  //     // const res = await axios.get(`map/campaign/list/${bounds.sw.lat}/${bounds.ne.lng}`);
  //     setVol(res?.data);
  //     // console.log(res?.data)
  //     return res;
  //   } catch (err) {
  //     throw err;
  //   }
  // };

  const getAllVolListOnMaps = async () => {
    try {
      const res = await axios.get(
        `map/vol/list/${localStorage.getItem("userid")}/${type}`
      );
      // const res = await axios.get(`map/campaign/list/${bounds.sw.lat}/${bounds.ne.lng}`);
      setVol(res?.data);
      // console.log(res?.data)
      return res;
    } catch (err) {
      throw err;
    }
  };

  // const getCampListForMaps = async () => {
  //   try {
  //     const res = await axios.get(`cyaaguser`);
  //     setCampList(res?.data);
  //     // console.log(res?.data)
  //     return res;
  //   } catch (err) {
  //     throw err;
  //   }
  // };

  const onMapClick = React.useCallback((event) => {
    // console.log(event);
    setMarkers((current) => [
      ...current,
      {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
        time: new Date(),
      },
    ]);
  }, []);

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(18);
  });

  const { isLoaded, loadError } = useLoadScript({
    // googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    googleMapsApiKey: "AIzaSyDkELxH1CVeAGbfVZsyCvcHPjcw-BrOYa8",
    libraries,
  });

  // REACT_APP_GOOGLE_MAPS_API_KEY="AIzaSyDGPTX-Pq124GpCkg6O0m97SAVvfpp4aUc1"

  if (loadError) return "Error loading google maps";
  if (!isLoaded) return "Loading....";
  // if (isLoaded) return "LOADED....";

  // const mapContainer = {
  //   width: "80vw",
  //   height: "90vh",
  //   marginLeft: "330px",
  //   marginTop: "50px",
  // };

  const options = {
    // styles: mapstyle,
    disableDefaultUI: true,
    zoomControl: true,
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <Button onClick={() => navigate(-1)} className={classes.backBtn}>
          <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
          <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
        </Button>
        <Grid container spacing={3} style={{ width: "80%", marginTop: "20px" }}>
          <Grid item xs={12} md={3}>
            <List
              places={places}
              childClicked={childClicked}
              isLoading={isLoading}
              type={type}
              setType={setType}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Map
              setCoordinates={setCoordinates}
              setBounds={setBounds}
              coordinates={coordinates}
              places={places}
              setChildClicked={setChildClicked}
              vol={vol}
              childClicked={childClicked}
              type={type}
            />
          </Grid>
        </Grid>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}

export const List = ({ vol, childClicked, isLoading, type, setType }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  // const [type, setType] = useState("campaigns")

  
  const [elRefs, setElRefs] = useState([]);


  // console.log(Array(places?.data));
  // console.log(places);

  return (
    <div className={classes.container}>
      <Button onClick={() => navigate(-1)} className={classes.backBtn}>
          <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
          <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
        </Button>
      <Typography variant="h5">Know the Changemakers...</Typography>
      {isLoading ? (
        <div className={classes.loading}>
          <CircularProgress size="5rem" />
        </div>
      ) : (
        <>
          <FormControl className={classes.formControl}>
            <InputLabel>Type</InputLabel>
            <Select value={type} onChange={(e) => setType(e.target.value)}>
              <MenuItem value="Top Adopters">Top Adopters</MenuItem>
              <MenuItem value="All Volunteers">All Volunteers</MenuItem>
            </Select>
          </FormControl>
          <Grid container spacing={3} className={classes.list}>
            {vol?.data?.map((vol, id) => (
              <Grid item key={id} xs={12}>
                <VolDetails
                  vol={vol}
                  // selected={Number(childClicked) === id}
                  // refProp={elRefs[id]}
                  // type={type}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </div>
  );
};

export const Map = ({
  setCoordinates,
  setBounds,
  coordinates,
  places,
  setChildClicked,
  vol,
  childClicked,
}) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery("(min-width:600px)");
  // const [markers, setMarkers] = useState([]);
  const [selected, setSelected] = useState(null);
  const mapRef = React.useRef();

  // const coordinates = {
  //   lat: 28.704060,
  //   lng: 77.102493,
  // };
  // const coordinates1 = coordinates;
  // console.log(coordinates);
  // console.log(places);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(18);
  });

  return (
    <div className={classes.mapContainer}>
      {/* <Search panTo={panTo} /> */}
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDGPTX-Pq124GpCkg6O0m97SAVvfpp4aUc" }}
        // style={{width: "100%", height: "100vh"}}
        defaultCenter={coordinates}
        center={coordinates}
        defaultZoom={5}
        // margin={[100, 100, 100, 50]}
        onChange={(e) => {
          // console.log(e);
          setCoordinates({ lat: e.center.lat, lng: e.center.lng });
          setBounds({ ne: e.marginBounds.ne, sw: e.marginBounds.sw });
        }}
        // onChildClick={(child) => setChildClicked(child)}
        onChildMouseEnter={(child) => setChildClicked(child)}
        onChildMouseLeave={(child) => setChildClicked(null)}
      >
        {/* {
          places?.data?.map((place, i) => (
            <div
              className={classes.markerContainer}
              lat={Number((place.lat))}
              lng={Number((place.lng))}
              key={i}

            >
              {
                !isDesktop ? (
                  <LocationOnOutlined text={place.title} color="primary" fontSize="large" />
                ) : (
                  <CampaignIcon className={classes.pointer} color="primary" fontSize="large" />
                )
              }

              {childClicked == i ? (
                <CampDetails place={place} />
              ) : null
              }
            </div>
          ))} */}

        {vol?.data?.map((vol, i) => (
          <div
            className={classes.markerContainer}
            lat={Number(vol.lat)}
            lng={Number(vol.lng)}
            key={i}
          >
            {!isDesktop ? (
              <RoomIcon color="primary" fontSize="large" />
            ) : (
              <PersonPinCircleIcon
                className={classes.pointer}
                color="secondary"
                fontSize="small"
              />
              // <Paper elevation={10} className={classes.paper}>
              //   <Typography variant="subtitle4" gutterBottom>
              //     {place.title}
              //   </Typography>
              //   <img
              //     className={classes.pointer}
              //     src={place.photo ? place.photo : "https://mt-dev-cyaag.s3.ap-south-1.amazonaws.com/Campaign/The Hyphen/c00001/logo/Vrikshi_Logo.png"}
              //     // style={{ maxWidth: "100%", paddingLeft: 15, paddingRight: 15}}
              //     style={{ maxWidth: "100%"}}

              //     />
              // </Paper>
            )}
            {childClicked == i ? <VolDetails vol={vol} /> : null}
          </div>
        ))}
      </GoogleMapReact>
    </div>
  );
};

// export const PlaceDetails = ({ vol, selected, refProp }) => {
//   // console.log(place);
//   const classes = useStyles();
//   const navigate = useNavigate();
//   console.log(selected);
//   if (selected) {
//     refProp?.current?.scrollIntoView({ behaviour: "smooth", block: "start" })
//     console.log(refProp);
//   }
//   const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
//     (state) => state.profile
//   );
//   return (
//     <Card elevation={6}>
//       <CardMedia
//         className={classes.click}
//         style={{ height: 169 }}
//         image={vol.profilephoto ? vol.profilephoto : "https://mt-dev-cyaag.s3.ap-south-1.amazonaws.com/Campaign/The Hyphen/c00001/logo/Vrikshi_Logo.png"}
//         title={vol.firstname}
//         // onClick={() =>

//         //   navigate(`/campaigndetails/${place.campid}/${profileResponse?.orgrole}/${profileResponse?.organization}`)
//         //   // navigate(`/campaigndetails/${tile.campid}/${profileResponse?.orgrole}/${profileResponse?.organization}`)
//         // }
//       />
//       <CardContent style={{ padding: 8 }}>
//         <Typography gutterBottom variant="h7">{vol.firstname}</Typography>
//         {/* <Box display="flex" justifyContent="space-between">
//           <Typography variant="subtitle2">
//             Campaign Type
//           </Typography>
//           <Typography gutterBottom variant="subtitle2">
//             {place.type}
//           </Typography>
//         </Box>
//         <Box display="flex" justifyContent="space-between">
//           <Typography variant="subtitle2">
//             Target Vol
//           </Typography>
//           <Typography gutterBottom variant="subtitle2">
//             {place.targetvol}
//           </Typography>
//         </Box> */}
//         {/* <Chip key={place.campaigntype} size="small" label={place.campaigntype} className={classes.Chip} /> */}
//         {/* <Box display="flex" justifyContent="space-between">
//           <Typography variant="subtitle1">
//             Mode
//           </Typography>
//           <Typography gutterBottom variant="subtitle1">
//             {place.campaigntype}
//           </Typography>
//         </Box> */}
//       </CardContent>
//     </Card>
//   )
// }

export const VolDetails = ({ vol, type }) => {
  // console.log(place);
  const classes = useStyles();
  const navigate = useNavigate();
  // console.log(selected);

  return (
    <Card elevation={6}>
      <CardMedia
        className={classes.click}
        style={{ height: 75, width: 80 }}
        // image={vol.profilephoto ? vol.profilephoto : "/images/volu-con.png"}
        image={vol.profilephoto ? vol.profilephoto : "/images/volu-con.png"}
        title={vol.firstname}
      />
      <CardContent style={{ padding: 8, fontSize: 8 }}>
        {/* <Typography gutterBottom variant="h8">{place.title}</Typography> */}
        {/* <Typography  fontSize= "6px">{place.title}</Typography> */}

        <span style={{ fontSize: "10" }}>{vol.firstname}</span>
        {!(type === "All Volunteers") ? (
          <span style={{ fontSize: "10" }}>
            {", "} Rank {vol.rank}
            {", "}
            Points {vol.rewardpoint}
          </span>
        ) : (
          ""
        )}
      </CardContent>
    </Card>
  );
};

// function Search({ panTo }) {
export const Search = ({ panTo }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 28.70406, lng: () => 77.102493 },
      radius: 200 * 1000,
    },
  });
  return (
    <div className="search">
      <Combobox
        onSelect={async (address) => {
          // console.log(address);
          setValue(address, false);
          clearSuggestions();
          try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            // console.log(lat, lng);
            panTo({ lat, lng });
          } catch (error) {
           
          }
        }}
      >
        <ComboboxInput
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          disabled={!ready}
          placeholder="Enter address"
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption key={id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
};
