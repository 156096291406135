/** @format */

const CAMP_LEADERBOARD_FETCHING_DATA = 'CAMP_LEADERBOARD_FETCHING_DATA';
const CAMP_LEADERBOARD_FETCHING_DATA_FAILURE = 'CAMP_LEADERBOARD_FETCHING_DATA_FAILURE';
const CAMP_LEADERBOARD_FETCHING_DATA_RESET = 'CAMP_LEADERBOARD_FETCHING_DATA_RESET';
const CAMP_LEADERBOARD_FETCHING_DATA_SUCCESS = 'CAMP_LEADERBOARD_FETCHING_DATA_SUCCESS';
const CAMP_LEADERBOARD_FETCHING_DATA_ATTEMPT = 'CAMP_LEADERBOARD_FETCHING_DATA_ATTEMPT';
const campLeaderBoard = payload => ({
	type: CAMP_LEADERBOARD_FETCHING_DATA_ATTEMPT,
	payload: payload,
});

export { CAMP_LEADERBOARD_FETCHING_DATA, CAMP_LEADERBOARD_FETCHING_DATA_FAILURE, CAMP_LEADERBOARD_FETCHING_DATA_RESET, CAMP_LEADERBOARD_FETCHING_DATA_SUCCESS, CAMP_LEADERBOARD_FETCHING_DATA_ATTEMPT, campLeaderBoard };
