/** @format */

// reducers
import {
  ONBOARD_ACTIVITY_FETCHING_DATA,
  ONBOARD_ACTIVITY_FETCHING_DATA_FAILURE,
  ONBOARD_ACTIVITY_FETCHING_DATA_RESET,
  ONBOARD_ACTIVITY_FETCHING_DATA_SUCCESS,
  ONBOARD_BULK_FETCHING_DATA,
  ONBOARD_BULK_FETCHING_DATA_FAILURE,
  ONBOARD_BULK_FETCHING_DATA_RESET,
  ONBOARD_BULK_FETCHING_DATA_SUCCESS,
  ONBOARD_CAMPAIGN_FETCHING_DATA,
  ONBOARD_CAMPAIGN_FETCHING_DATA_FAILURE,
  ONBOARD_CAMPAIGN_FETCHING_DATA_RESET,
  ONBOARD_CAMPAIGN_FETCHING_DATA_SUCCESS,
  ONBOARD_INDIVIDUAL_FETCHING_DATA,
  ONBOARD_INDIVIDUAL_FETCHING_DATA_FAILURE,
  ONBOARD_INDIVIDUAL_FETCHING_DATA_RESET,
  ONBOARD_INDIVIDUAL_FETCHING_DATA_SUCCESS,
  ONBOARD_ORGANIZATION_FETCHING_DATA,
  ONBOARD_ORGANIZATION_FETCHING_DATA_FAILURE,
  ONBOARD_ORGANIZATION_FETCHING_DATA_RESET,
  ONBOARD_ORGANIZATION_FETCHING_DATA_SUCCESS,
  ONBOARD_SPONSOR_FETCHING_DATA,
  ONBOARD_SPONSOR_FETCHING_DATA_FAILURE,
  ONBOARD_SPONSOR_FETCHING_DATA_RESET,
  ONBOARD_SPONSOR_FETCHING_DATA_SUCCESS,
  ONBOARD_SELF_FETCHING_DATA,
  ONBOARD_SELF_FETCHING_DATA_FAILURE,
  ONBOARD_SELF_FETCHING_DATA_RESET,
  ONBOARD_SELF_FETCHING_DATA_SUCCESS,

  ONBOARD_KTCUSTOMER_FETCHING_DATA,
  ONBOARD_KTCUSTOMER_FETCHING_DATA_FAILURE,
  ONBOARD_KTCUSTOMER_FETCHING_DATA_RESET,
  ONBOARD_KTCUSTOMER_FETCHING_DATA_SUCCESS,

  ONBOARD_KTADMIN_FETCHING_DATA,
  ONBOARD_KTADMIN_FETCHING_DATA_FAILURE,
  ONBOARD_KTADMIN_FETCHING_DATA_RESET,
  ONBOARD_KTADMIN_FETCHING_DATA_SUCCESS,

} from "../action/OnboardAction";

let initialState = {
  individualResponse: {},
  isIndividualSuccess: false,
  isIndividualError: false,
  isIndividualFetching: false,
  bulkResponse: {},
  isBulkSuccess: false,
  isBulkError: false,
  isBulkFetching: false,
  activityResponse: {},
  isActivitySuccess: false,
  isActivityError: false,
  isActivityFetching: false,
  organizationResponse: {},
  isOrganizationSuccess: false,
  isOrganizationError: false,
  isOrganizationFetching: false,
  campaignResponse: {},
  isCampaignSuccess: false,
  isCampaignError: false,
  isCampaignFetching: false,
  sponsorResponse: {},
  isSponsorSuccess: false,
  isSponsorError: false,
  isSponsorFetching: false,
  selfOnboardResponse: {},
  isSelfOnboardSuccess: false,
  isSelfOnboardError: false,
  isSelfOnboardFetching: false,

  ktCustomerOnboardResponse: {},
  isKTCustomerOnboardSuccess: false,
  isKTCustomerOnboardError: false,
  isKTCustomerOnboardFetching: false,

  ktAdminOnboardResponse: {},
  isKTAdminOnboardSuccess: false,
  isKTAdminOnboardError: false,
  isKTAdminOnboardFetching: false,
};

export default function OnboardReducer(state = initialState, action) {
  switch (action.type) {
    case ONBOARD_INDIVIDUAL_FETCHING_DATA:
      return {
        ...state,
        isIndividualFetching: true,
        isIndividualError: false,
        isIndividualSuccess: false,
      };
    case ONBOARD_INDIVIDUAL_FETCHING_DATA_FAILURE:
      return {
        ...state,
        isIndividualFetching: false,
        isIndividualError: action.payload,
        isIndividualSuccess: false,
      };
    case ONBOARD_INDIVIDUAL_FETCHING_DATA_SUCCESS:
      return {
        ...state,
        isIndividualFetching: false,
        isIndividualError: false,
        individualResponse: action.payload,
        isIndividualSuccess: true,
      };

    case ONBOARD_BULK_FETCHING_DATA:
      return {
        ...state,
        isBulkFetching: true,
        isBulkError: false,
        isBulkSuccess: false,
      };
    case ONBOARD_BULK_FETCHING_DATA_FAILURE:
      return {
        ...state,
        isBulkFetching: false,
        isBulkError: true,
        isBulkSuccess: false,
      };
    case ONBOARD_BULK_FETCHING_DATA_SUCCESS:
      return {
        ...state,
        isBulkFetching: false,
        isBulkError: false,
        bulkResponse: action.payload,
        isBulkSuccess: true,
      };

    case ONBOARD_BULK_FETCHING_DATA_RESET:
      return {
        ...state,
        bulkResponse: {},
        isBulkSuccess: false,
        isBulkError: false,
        isBulkFetching: false,
      };

    case ONBOARD_INDIVIDUAL_FETCHING_DATA_RESET:
      return {
        ...state,
        individualResponse: {},
        isIndividualSuccess: false,
        isIndividualError: false,
        isIndividualFetching: false,
      };

    case ONBOARD_ACTIVITY_FETCHING_DATA:
      return {
        ...state,
        isActivityFetching: true,
        isActivityError: false,
        isActivitySuccess: false,
      };
    case ONBOARD_ACTIVITY_FETCHING_DATA_FAILURE:
      return {
        ...state,
        isActivityFetching: false,
        isActivityError: true,
        isActivitySuccess: false,
      };
    case ONBOARD_ACTIVITY_FETCHING_DATA_SUCCESS:
      return {
        ...state,
        isActivityFetching: false,
        isActivityError: false,
        activityResponse: action.payload,
        isActivitySuccess: true,
      };

    case ONBOARD_ACTIVITY_FETCHING_DATA_RESET:
      return {
        ...state,
        activityResponse: {},
        isActivitySuccess: false,
        isActivityError: false,
        isActivityFetching: false,
      };

    case ONBOARD_CAMPAIGN_FETCHING_DATA:
      return {
        ...state,
        isCampaignFetching: true,
        isCampaignError: false,
        isCampaignSuccess: false,
      };
    case ONBOARD_CAMPAIGN_FETCHING_DATA_FAILURE:
      return {
        ...state,
        isCampaignFetching: false,
        isCampaignError: true,
        isCampaignSuccess: false,
      };
    case ONBOARD_CAMPAIGN_FETCHING_DATA_SUCCESS:
      return {
        ...state,
        isCampaignFetching: false,
        isCampaignError: false,
        campaignResponse: action.payload,
        isCampaignSuccess: true,
      };

    case ONBOARD_CAMPAIGN_FETCHING_DATA_RESET:
      return {
        ...state,
        campaignResponse: {},
        isCampaignSuccess: false,
        isCampaignError: false,
        isCampaignFetching: false,
      };

    case ONBOARD_ORGANIZATION_FETCHING_DATA:
      return {
        ...state,
        isOrganizationFetching: true,
        isOrganizationError: false,
        isOrganizationSuccess: false,
      };
    case ONBOARD_ORGANIZATION_FETCHING_DATA_FAILURE:
      return {
        ...state,
        isOrganizationFetching: false,
        isOrganizationError: true,
        isOrganizationSuccess: false,
      };
    case ONBOARD_ORGANIZATION_FETCHING_DATA_SUCCESS:
      return {
        ...state,
        isOrganizationFetching: false,
        isOrganizationError: false,
        organizationResponse: action.payload,
        isOrganizationSuccess: true,
      };

    case ONBOARD_ORGANIZATION_FETCHING_DATA_RESET:
      return {
        ...state,
        organizationResponse: {},
        isOrganizationSuccess: false,
        isOrganizationError: false,
        isOrganizationFetching: false,
      };
    case ONBOARD_ORGANIZATION_FETCHING_DATA:
      return {
        ...state,
        isOrganizationFetching: true,
        isOrganizationError: false,
        isOrganizationSuccess: false,
      };
    case ONBOARD_ORGANIZATION_FETCHING_DATA_FAILURE:
      return {
        ...state,
        isOrganizationFetching: false,
        isOrganizationError: true,
        isOrganizationSuccess: false,
      };
    case ONBOARD_ORGANIZATION_FETCHING_DATA_SUCCESS:
      return {
        ...state,
        isOrganizationFetching: false,
        isOrganizationError: false,
        organizationResponse: action.payload,
        isOrganizationSuccess: true,
      };

    case ONBOARD_ORGANIZATION_FETCHING_DATA_RESET:
      return {
        ...state,
        organizationResponse: {},
        isOrganizationSuccess: false,
        isOrganizationError: false,
        isOrganizationFetching: false,
      };   
      case ONBOARD_SPONSOR_FETCHING_DATA:
        return {
          ...state,
          isSponsorFetching: true,
          isSponsorError: false,
          isSponsorSuccess: false,
        };
      case ONBOARD_SPONSOR_FETCHING_DATA_FAILURE:
        return {
          ...state,
          isSponsorFetching: false,
          isSponsorError: true,
          isSponsorSuccess: false,
        };
      case ONBOARD_SPONSOR_FETCHING_DATA_SUCCESS:
        return {
          ...state,
          isSponsorFetching: false,
          isSponsorError: false,
          sponsorResponse: action.payload,
          isSponsorSuccess: true,
        };
  
      case ONBOARD_SPONSOR_FETCHING_DATA_RESET:
        return {
          ...state,
          sponsorResponse: {},
          isSponsorSuccess: false,
          isSponsorError: false,
          isSponsorFetching: false,
        };

      case ONBOARD_SELF_FETCHING_DATA:
        return {
          ...state,
          isSelfOnboardFetching: true,
          isSelfOnboardError: false,
          isSelfOnboardSuccess: false,
        };
      case ONBOARD_SELF_FETCHING_DATA_FAILURE:
        return {
          ...state,
          isSelfOnboardFetching: false,
          isSelfOnboardError: true,
          isSelfOnboardSuccess: false,
        };
      case ONBOARD_SELF_FETCHING_DATA_SUCCESS:
        return {
          ...state,
          isSelfOnboardFetching: false,
          isSelfOnboardError: false,
          selfOnboardResponse: action.payload,
          isSelfOnboardSuccess: true,
        };
  
      case ONBOARD_SELF_FETCHING_DATA_RESET:
        return {
          ...state,
          selfOnboardResponse: {},
          isSelfOnboardSuccess: false,
          isSelfOnboardError: false,
          isSelfOnboardFetching: false,
        };
      
      case ONBOARD_KTCUSTOMER_FETCHING_DATA:
        return {
          ...state,
          isKTCustomerOnboardFetching: true,
          isKTCustomerOnboardError: false,
          isKTCustomerOnboardSuccess: false,
        };
      case ONBOARD_KTCUSTOMER_FETCHING_DATA_FAILURE:
        return {
          ...state,
          isKTCustomerOnboardFetching: false,
          isKTCustomerOnboardError: true,
          isKTCustomerOnboardSuccess: false,
        };
      case ONBOARD_KTCUSTOMER_FETCHING_DATA_SUCCESS:
        return {
          ...state,
          isKTCustomerOnboardFetching: false,
          isKTCustomerOnboardError: false,
          ktCustomerOnboardResponse: action.payload,
          isKTCustomerOnboardSuccess: true,
        };
  
      case ONBOARD_KTCUSTOMER_FETCHING_DATA_RESET:
        return {
          ...state,
          ktCustomerOnboardResponse: {},
          isKTCustomerOnboardSuccess: false,
          isKTCustomerOnboardError: false,
          isKTCustomerOnboardFetching: false,
        };



        case ONBOARD_KTADMIN_FETCHING_DATA:
          return {
            ...state,
            isKTAdminOnboardFetching: true,
            isKTAdminOnboardError: false,
            isKTAdminOnboardSuccess: false,
          };
        case ONBOARD_KTADMIN_FETCHING_DATA_FAILURE:
          return {
            ...state,
            isKTAdminOnboardFetching: false,
            isKTAdminOnboardError: true,
            isKTAdminOnboardSuccess: false,
          };
        case ONBOARD_KTADMIN_FETCHING_DATA_SUCCESS:
          return {
            ...state,
            isKTAdminOnboardFetching: false,
            isKTAdminOnboardError: false,
            ktAdminOnboardResponse: action.payload,
            isKTAdminOnboardSuccess: true,
          };
    
        case ONBOARD_KTADMIN_FETCHING_DATA_RESET:
          return {
            ...state,
            ktAdminOnboardResponse: {},
            isKTAdminOnboardSuccess: false,
            isKTAdminOnboardError: false,
            isKTAdminOnboardFetching: false,
          };

    default:
      return state;
  }
}
