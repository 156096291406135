/** @format */

const ONBOARD_REWARDS_FETCHING_DATA = "ONBOARD_REWARDS_FETCHING_DATA";
const ONBOARD_REWARDS_FETCHING_DATA_FAILURE = "ONBOARD_REWARDS_FETCHING_DATA_FAILURE";
const ONBOARD_REWARDS_FETCHING_DATA_RESET = "ONBOARD_REWARDS_FETCHING_DATA_RESET";
const ONBOARD_REWARDS_FETCHING_DATA_SUCCESS = "ONBOARD_REWARDS_FETCHING_DATA_SUCCESS";
const ONBOARD_REWARDS_FETCHING_DATA_ATTEMPT = "ONBOARD_REWARDS_FETCHING_DATA_ATTEMPT";
const onboardRewards = (payload) => ({
  type: ONBOARD_REWARDS_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const onboardRewardsSuccess = () => ({
  type: ONBOARD_REWARDS_FETCHING_DATA_SUCCESS,
});
const onboardRewardsReset = () => ({
  type: ONBOARD_REWARDS_FETCHING_DATA_RESET,
});

export {
    ONBOARD_REWARDS_FETCHING_DATA,
    ONBOARD_REWARDS_FETCHING_DATA_FAILURE,
    ONBOARD_REWARDS_FETCHING_DATA_RESET,
    ONBOARD_REWARDS_FETCHING_DATA_SUCCESS,
    ONBOARD_REWARDS_FETCHING_DATA_ATTEMPT,
    onboardRewards,
    onboardRewardsSuccess,
    onboardRewardsReset,
};