// import * as React, {useRef} from "react";
import React, { useEffect, useRef, useState } from "react";
import FormControl from "@mui/material/FormControl";
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TableContainer,
  Paper,
  Table,
  Typography,
  TableRow,
  withStyles,
  TableCell,
  TableFooter,
} from "@material-ui/core";
import {
  Avatar,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import { isEmpty, isArray } from "lodash";

// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// import {
//   onboardActivityReset,
//   onboardBulkReset,
//   onboardCampaign,
//   onboardCampaignReset,
//   onboardIndividualReset,
//   onboardOrganization,
//   onboardOrganizationReset,
// } from "../redux/action/OnboardAction";

import ResponsiveDrawer from "./Header";
import { CopyRight } from "./CopyRight";
import moment from "moment";
import axios from "axios";

import _ from "lodash";

import TopHeaderScreen from "./TopHeader1";
import { useNavigate } from "react-router-dom";

import CampImpactComponent from "./CampImpactComponent";
import AsyncSelect from "react-select/async";
import { CSVLink, CSVDownload } from "react-csv";
import { FixedFooterIcon } from "./FixedFooterIcon";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { Audio, RotatingTriangles } from "react-loader-spinner";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },

    display: "block",
    display: "flex",
    //fontSize: 16,
    fontSize: "16px",
    marginTop: 16,
    padding: 10,
    fontWeight: "500",
    lineHeight: "18px",
    minHeight: 50,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {},

    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "50%",
    },
  },
  formGroupFull: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  table: {
    width: "100%",
    borderRadius: "10px",
    borderStyle: "solid",
    borderWidth: "1.5px",
    // tableLayout: "fixed",
  },
  tableRow: {
    borderBottom: "1px solid #d3d3d3",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  thead: {
    borderBottom: "1px solid #d3d3d3",
    borderTop: "1px solid #d3d3d3",
    height: 60,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.getContrastText(theme.palette.secondary.dark),
  },
  theadText: {
    fontWeight: "700",
    textAlign: "center",
    fontSize: 16,
    minWidth: 100,
  },
  tbodyText: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width: "200px",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // wordWrap: "breakWord",
    // overflow: "scroll",
  },
  participate: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width: "200px",
    fontWeight: "bold",
    // backgroundColor: theme.palette.info.light,
    color: theme.palette.info.light,
  },
  referral: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width: "200px",
    fontWeight: "bold",
    // backgroundColor: theme.palette.info.light,
    color: theme.palette.info.dark,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "#000",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid green !important",
    // '&:nth-of-type(odd)': {
    // 	backgroundColor: '#f7dbb3',
    // },
    // '&:nth-of-type(even)': {
    // 	backgroundColor: '#e9e7e8',
    // },
    "&:last-child": {
      borderBottom: "none",
    },
  },
}))(TableRow);

function SOACampaignImpact(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = React.useRef(null);
  const inputRefBanner = React.useRef(null);
  const videoRef = React.useRef(null);
  const [show, setShow] = React.useState(false);

  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "", cityList: "" },
  ]);

  const [focusArea, setFocusArea] = React.useState([
    {
      rewards: "Certificate of Participation",
    },
    {
      rewards: "Discount Coupons",
    },
    { rewards: "Gift Cards" },
    { rewards: "Social Media Recognition" },
  ]);

  const [campName, setCampName] = React.useState([]);

  const [selectedcampname, setselectedcampname] = React.useState([]);
  const [campSubmissionlist, setCampSubmissionlist] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  React.useEffect(() => {
    getCampName();
  }, []);

  const loadOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = campName.filter((category) =>
        category.label.toLowerCase().includes(searchValue.toLowerCase())
      );
    
      callback(filteredOptions);
    });
  };

  const getCampName = async () => {
    try {
      // const res = await axios.get(
      //   `/camp/name/report/${profileResponse?.phoneno}`
      // );
      const res = await axios.get(
          `/a/b/c/d/${profileResponse?.phoneno}`
        );
      setCampName(res?.data);

      return res;
    } catch (err) {
      throw err;
    }
  };


  const getRewardsFromMarketplaceOnSearch = async () => {
   
    setIsLoading(true);

    // console.log(selectedpointsrange.length === 0);
    try {
      // const res = ""
      //   if ((selectedrewardtype.length === 0) && !(selectedpointsrange.length === 0)) {
     
      const res = await axios
        .get(`/a/${selectedcampname[0]["label"]}/${profileResponse?.phoneno}`)
        .then((response) => {
          setCampSubmissionlist(response?.data);

          // return response;
          setIsLoading(false);
        });
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
  };

  const colorStyles = {
    control: (styles) => ({ ...styles }),
    // option: (styles, {data, isDisabled, isFocussed, isSelected}) => {
    //   return{...styles, color: "blue"};
    // },
    multiValue: (styles, { data }) => {
      return { ...styles, backgroundColor: "#0096FF", color: "#fff" };
    },
    multiValueLabel: (styles, { data }) => {
      return { ...styles, color: "#fff" };
    },
    multiValueRemove: (styles, { data }) => {
      return {
        ...styles,
        color: "#fff",
        cursor: "pointer",
        ":hover": {
          color: "#fff",
        },
      };
    },
  };

  const ren = (
    <header id="SOADashboardScreen">
      <form autoComplete="off">
        <div
          style={{
            border: "1px solid #dadce0",
            borderRadius: 8,
            backgroundColor: "#fff",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          ></div>
          <div
            className="centerAlign"
            style={{ padding: "16px 0", marginBottom: 50 }}
          >
            <br />

            <div className={classes.formGroupFull}>
              <div>
                <CampImpactComponent rows={campSubmissionlist} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </header>
  );



  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Impact Assessment of Campaign
            </div>
            {/* <div>
              <Button className="btn download-btn" variant="contained" onClick={downloadDocument}>Download</Button>
            </div> */}
            {/* <div style={{ fontFamily: "monospace", fontSize: 16, }}>
            </div> */}

            <div class="formMarketPlace">
              <div>
                <div
                  class="form-market-wrap"
                  id=""
                  role="tabpanel"
                  aria-labelledby="nav-places-tab"
                >
                  <h6>Please select a Campaign</h6>
                  <form action="#" method="get">
                    <div
                      style={{
                        // padding: "0px 30px 0px 0px",
                        padding: "0px 0px 0px 0px",
                        width: "300px",
                        marginBottom: "5px",
                        maxWidth: "100%",
                      }}
                    >
                      <AsyncSelect
                        loadOptions={loadOptions}
                        defaultOptions={campName}
                        value={selectedcampname}
                        isMulti
                        placeholder="Select Campaign"
                        isOptionDisabled={() => selectedcampname.length >= 1}
                        styles={colorStyles}
                        onChange={(event) => {
                          setselectedcampname(event);
                        }}
                      />
                    </div>

                    <Button
                      class="btn camp-report-gen responsiveViewCenter"
                      variant="contained"
                      onClick={getRewardsFromMarketplaceOnSearch}
                      className="btn save-btn"
                      disableElevation
                      style={{ marginLeft: "30px" }}
                      // disabled={isLoading}
                    >
                      <i class="fa fa-search pr-2" aria-hidden="true"></i>
                      <span>Generate Impact Assessment</span>
                    </Button>
                  </form>
                </div>
              </div>
            </div>

            <header id="SOADashboardScreen">
              <form autoComplete="off">
                <div
                  style={{
                    borderRadius: 8,
                    backgroundColor: "#fff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  ></div>
                  <div
                    className="centerAlign"
                    style={{ padding: "16px 0", marginBottom: 50 }}
                  >
                    <br />

                    <div className={classes.formGroupFull} >
                      {!isLoading && campSubmissionlist.length === 0 ? (
                        "Please select a campaign and click on Generate Impact Assessment"
                      ) : isLoading ? (
                        <div>
                          <div>
                        <RotatingTriangles
                          type="TailSpin"
                          color="rgb(155, 236, 34)"
                          height={70}
                          width={70}
                          timeout={5000}
                          style={{display:"inherit"}}
                          ariaLabel="three-circles-rotating"
                        />
                        
                        </div>
                        <span><i>Please wait while your AI based report is being generated</i></span>
                        </div>
                        
                      ) : (
                        <div>
                          <CampImpactComponent rows={campSubmissionlist} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </header>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}
export default SOACampaignImpact;
