import * as React from "react";
import FormControl from "@mui/material/FormControl";
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import {
  Alert,
  Button,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  onboardActivityReset,
  onboardBulk,
  onboardBulkReset,
  onboardCampaignReset,
  onboardIndividual,
  onboardIndividualReset,
  onboardOrganizationReset,
} from "../../redux/action/OnboardAction";

import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import { getCity } from "../../redux/action/cityAction";
import {
  BloodGroup,
  Category,
  educationList,
  focusAreaDict,
  Gender,
  hoursEveryWeek,
  interestAreaDict,
  NSSRole,
  preferWorkingDays,
  restrictedCountry,
  skillSets,
} from "../../utils/dict";
import {
  ITEM_HEIGHT,
  ITEM_PADDING_TOP,
  MenuProps,
  states,
} from "../../utils/constants";
import { getOrganization } from "../../redux/action/getOrganization";
import { PhonelinkLockOutlined, Upload, UploadFile } from "@mui/icons-material";
import CallingCode from "../../services/CallingCode";
import axios from "axios";
import { API_URL } from "../../services/Constants";
import RedStar from "../../components/RedStar";
import BasicPopover from "../../components/InformationPopup";
import ErrorMessage from "../../components/ErrorMessage";
import { useNavigate } from "react-router-dom";
import _, { set } from "lodash";
import { red } from "@material-ui/core/colors";
import Multiselect from "multiselect-react-dropdown";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@material-ui/core/IconButton";
// import InputAdornment from "@material-ui/core/InputAdornment";
import DoneIcon from "@mui/icons-material/Done";
import { SettingsSystemDaydreamRounded } from "@material-ui/icons";
import PaidIcon from "@mui/icons-material/Paid";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import ConfirmationDialog from "../../components/ConfirmationDialog";

const form = [
  { id: 1, formQuestion: "First Name" },
  { id: 2, formQuestion: "Middle Name" },
  { id: 3, formQuestion: "Last Name" },
  { id: 4, formQuestion: "Father Name" },
  { id: 5, formQuestion: "Date of Birth" },
  { id: 6, formQuestion: "Adhaar No" },
  { id: 7, formQuestion: "Category" },
  { id: 8, formQuestion: "Gender" },
  { id: 9, formQuestion: "Blood Group" },
  { id: 10, formQuestion: "Present Address" },
  { id: 11, formQuestion: "Permanent Address" },
  { id: 12, formQuestion: "Email Address" },
  {
    id: 13,
    formQuestion: "Session",
  },
  { id: 14, formQuestion: "Roll Number" },
  { id: 15, formQuestion: "Phone Number (Mobile Number)" },
  {
    id: 16,
    formQuestion: "Institute Name",
  },
  { id: 17, formQuestion: "University Name" },
  {
    id: 19,
    formQuestion: "State",
  },
  { id: 18, formQuestion: "City/Town/Village" },
  { id: 20, formQuestion: "Organization" },
  { id: 21, formQuestion: "Organization Role" },
  { id: 22, formQuestion: "Organization Unit" },
];

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 40px 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 40px 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    fontSize: 15,
    boxShadow: "0px 3px 6px #00000029",
    border:"1px solid #ccc",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },

  phoneInput: {
    "& .MuiSelect-select": {
      padding: "0px 0px 0px 5px",
    },
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "flex",
    fontSize: "16px",
    marginTop: 16,
    padding: 10,
    fontWeight: "500",
    color: "#000000",
    minHeight: 55,
    lineHeight: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label2: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "flex",
    fontSize: "16px",
    marginTop: 16,
    padding: 10,
    fontWeight: "500",
    color: "#000000",
    // minHeight: 55,
    lineHeight: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
  },
  formGroup2: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",
    flexDirection: "column",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "75px 0px 0px 16px",
    // pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

function OnBoardVolunteer1(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [dateofBirth, setDateofBirth] = React.useState(
    new Date().setFullYear(new Date().getFullYear() - 5)
  );

  const [orgRole, setORGRole] = React.useState([]);

  const [selectedFiles, setSelectedFiles] = React.useState("");
  const [registerCallingCode, setRegisterCallingCode] = React.useState("");
  const [value1, setValue1] = React.useState("");
  const [expertise, setExpertise] = React.useState("");
  const [value1Error, setValue1Error] = React.useState(false);
  const [lastname, setLastname] = React.useState("");
  const [lastnameError, setLastnameError] = React.useState(false);
  const [value2, setValue2] = React.useState("");
  const [value2Error, setValue2Error] = React.useState(false);

  const [value3, setValue3] = React.useState("");
  const [value3Error, setValue3Error] = React.useState(false);
  const [value4, setValue4] = React.useState("");
  const [value4Error, setValue4Error] = React.useState(false);
  const [value5, setValue5] = React.useState(
    new Date().setFullYear(new Date().getFullYear() - 5)
  );
  const [gender, setGender] = React.useState("");
  const [genderError, setGenderError] = React.useState(false);
  const [value7, setValue7] = React.useState("");
  const [value7Error, setValue7Error] = React.useState(false);
  const [value8, setValue8] = React.useState("");
  const [value8Error, setValue8Error] = React.useState(false);
  const [value9, setValue9] = React.useState("");
  const [value9Error, setValue9Error] = React.useState(false);
  const [value10, setValue10] = React.useState("");
  const [value10Error, setValue10Error] = React.useState(false);
  const [value11, setValue11] = React.useState("");
  const [value11Error, setValue11Error] = React.useState(false);
  const [value12, setValue12] = React.useState("");
  const [value12Error, setValue12Error] = React.useState(false);
  const [emailTextError, setEmailTextError] = React.useState("");
  const [value13, setValue13] = React.useState("");
  const [value13Error, setValue13Error] = React.useState(false);
  const [value14, setValue14] = React.useState("");
  const [value14Error, setValue14Error] = React.useState(false);
  const [value15, setValue15] = React.useState("");
  const [value15Error, setValue15Error] = React.useState(false);
  const [value16, setValue16] = React.useState("");
  const [value16Error, setValue16Error] = React.useState(false);
  const [countryError, setCountryError] = React.useState(false);
  const [value18, setValue18] = React.useState("");
  const [value18Error, setValue18Error] = React.useState(false);
  const [value19, setValue19] = React.useState("");
  const [value19Error, setValue19Error] = React.useState(false);
  const [areaError, setAreaError] = React.useState(false);
  const [value20, setValue20] = React.useState("");
  const [value20Error, setValue20Error] = React.useState(false);
  const [value21, setValue21] = React.useState("");
  const [value21Error, setValue21Error] = React.useState(false);
  const [value22, setValue22] = React.useState("");
  const [value22Error, setValue22Error] = React.useState(false);
  const [highLevelEdu, setHighLevelEdu] = React.useState("");
  const [highLevelEduError, setHighLevelEduError] = React.useState(false);
  const [noOfHours, setNoOfHours] = React.useState("");
  const [noOfHoursError, setNoOfHoursError] = React.useState(false);
  const [preferDays, setPreferDays] = React.useState("");
  const [preferDaysError, setPreferDaysError] = React.useState(false);
  const [interestArea, setInterestArea] = React.useState("");
  const [interestAreaError, setinterestAreaError] = React.useState(false);
  const [address, setAddress] = React.useState("");
  const [correspondenceaddress, setcorrespendeceaddress] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [area, setArea] = React.useState("");
  const [added, setAdded] = React.useState("no");
  const [displayConfirmationModal, setDisplayConfirmationModal] =  React.useState(false);

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleSelect = async (value) => {
    // console.log(value);
    const results = await geocodeByAddress(value);
    // console.log(results[0]["formatted_address"]);
    setAddress(results[0]["formatted_address"]);

    // console.log(value);
  };

  const handleChange1 = (event) => {
    setcorrespendeceaddress(event.target.value);
    
  };

  const handletextentered = (e) => {
    
    setcorrespendeceaddress(e.target.value);

    // console.log(values);
  };
  

  // const [radioValue, setRadioValue] = React.useState("");

  const [focusArea, setFocusArea] = React.useState([
    { interest: "No Poverty", isAdded: false },
    { interest: "Zero Hunger", isAdded: false },
    { interest: "Good Health and Well-Being", isAdded: false },
    { interest: "Quality Education", isAdded: false },
    { interest: "Gender Equality", isAdded: false },
    { interest: "Clean Water and Sanitation", isAdded: false },
    { interest: "Affordable and clean energy", isAdded: false },
    { interest: "Decent work and economic growth", isAdded: false },
    { interest: "Industry Innovation and Infrastructure", isAdded: false },
    { interest: "Reduced Inequalities", isAdded: false },
    { interest: "Sustainable cities and communities", isAdded: false },
    { interest: "Responsible Consumption and Production", isAdded: false },
    { interest: "Climate Action", isAdded: false },
    { interest: "Life Below Water", isAdded: false },
    { interest: "Life on Land", isAdded: false },
    { interest: "Peace, Justice and Strong Institutions", isAdded: false },
    {
      interest: "Partnerships for achieving Sustainable Development Goals",
      isAdded: false,
    },
  ]);
  const [focusAreaError, setFocusAreaError] = React.useState(false);
  const [skillsError, setskillsError] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [selectedSkills, setSelectedSkills] = React.useState([]);

  const {
    isIndividualSuccess,
    isIndividualFetching,
    isIndividualError,
    isBulkSuccess,
    isBulkError,
    isBulkFetching,
    individualResponse,
    bulkResponse,
  } = useSelector((state) => state.onBoard);

  const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
    (state) => state.cities
  );

  const {
    isSuccess: organizationListSuccess,
    isError: organizationListError,
    response: organizationList,
  } = useSelector((state) => state.organizationList);

  React.useEffect(() => {
    dispatch(onboardBulkReset());
    dispatch(onboardIndividualReset());
    dispatch(onboardActivityReset());
    dispatch(onboardCampaignReset());
    dispatch(onboardOrganizationReset());
  }, []);

  const isAllSelected =
    interestAreaDict.length > 0 && selected.length === interestAreaDict.length;

  const handleChange = (event, index) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(
        selected.length === interestAreaDict.length ? [] : interestAreaDict
      );
      return;
    }

    setSelected([...selected, value[value?.length - 1]]);
  };

  const handleChangeSkills = (event, index) => {
    const value = event.target.value;

    setSelectedSkills([...selectedSkills, value[value?.length - 1]]);
  };

  const handleFileInput = (e) => {
    setSelectedFiles(e.target.files[0]);
    handleFile(e.target.files[0]);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const onAddingItem = (event, option, index) => {
    const values = [...focusArea];
    values[index].isAdded = event.target.checked;
    setFocusArea(values);
    setFocusAreaError(false);
  };

  const handleFile = (selectedFiles) => {
    const formData = new FormData();
    formData.append("bulkupload", selectedFiles);

    dispatch(onboardBulk(formData));
  };

  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  function subtractYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);

    return date;
  }
  const handleCityChange = (event) => {
    setValue19(event.target.value);
  };
  const handleHoursDedicatedChange = (event) => {
    setNoOfHours(event.target.value);
    setNoOfHoursError(false);
  };

  const handlePreferDays = (event) => {
    setPreferDays(event.target.value);
    setPreferDaysError(false);
  };

  const getORGRole = async () => {
    try {
      const res = await axios.get(`/orgrole/${localStorage.getItem("userid")}`);
      setORGRole(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const sortedCallingCode = CallingCode.sort(function (a, b) {
    if (a.name < b.name) return -1;
    else if (a.name > b.name) return 1;
    return 0;
  });
  const handleChangeRegisterCallingCode = (event) => {
    setRegisterCallingCode(event.target.value);
  };

  const replacedPhoneNumber =
    (registerCallingCode === ""
      ? "91"
      : registerCallingCode.replace(/\+/g, "")) + value15;

  const handleOnboardIndividual = () => {
    if (!value1) {
      setValue1Error(true);

      window.scrollTo(0, 0);
    } else if (!lastname) {
      setLastnameError(true);

      window.scrollTo(0, 0);
    } else if (!highLevelEdu) {
      setHighLevelEduError(true);

      window.scrollTo(0, 100);
    } else if (!noOfHours) {
      setNoOfHoursError(true);

      window.scrollTo(0, 200);
    } else if (!preferDays) {
      setPreferDaysError(true);

      window.scrollTo(0, 200);
    } else if (!gender) {
      setGenderError(true);

      window.scrollTo(0, 200);
    } else if (!value9) {
      setValue9Error(true);
      window.scrollTo(0, 200);
    } else if (!value10) {
      setValue10Error(true);
      window.scrollTo(0, 200);
    } else if (!value12) {
      setValue12Error(true);
      window.scrollTo(0, 200);
    } else if (emailTextError) {
      window.scrollTo(0, 200);
    } else if (!value15) {
      setValue15Error(true);
      window.scrollTo(0, 200);
    } else if (countryError) {
      setCountryError(true);
      window.scrollTo(0, 1000);
    } else if (!value18) {
      setValue18Error(true);
      window.scrollTo(0, 50);
    } else if (!value19) {
      setValue19Error(true);
      window.scrollTo(0, 50);
    } else if (!area) {
      setAreaError(true);
      window.scrollTo(0, 50);
    } else if (!value20) {
      setValue20Error(true);
      window.scrollTo(0, 200);
    } else if (!value21) {
      setValue21Error(true);
      window.scrollTo(0, 200);
    } else if (selected.filter((item) => item).length < 1) {
      setFocusAreaError(true);
      window.scrollTo(0, 400);
    } else if (selectedSkills.filter((item) => item).length < 1) {
      setskillsError(true);
      window.scrollTo(0, 400);
    } else {
      dispatch(onboardIndividualReset());
      let formData = new FormData();
      // formData.append("fullname", value1);
      formData.append("firstname", value1);
      formData.append("lastname", lastname);
      formData.append(
        "dob",
        value5 === ""
          ? moment(dateofBirth).format("DD-MM-yyyy")
          : moment(value5).format("DD-MM-yyyy")
      );
      formData.append("bloodgroup", value9);
      formData.append("gender", gender);
      formData.append("correspondenceaddress", value10);
      formData.append("emailid", value12);
      formData.append("phoneno", replacedPhoneNumber);
      formData.append("country", country);
      formData.append("state", value18);
      formData.append("city", value19);
      formData.append("area", area);
      formData.append("organization", value20);
      formData.append("orgrole", value21);
      formData.append("highlvledu", highLevelEdu);
      formData.append("hoursperweek", noOfHours);
      formData.append("preferday", preferDays);
      // formData.append(
      //   "interestarea",
      //   JSON.stringify(
      //     selected.map((item) => {
      //       const data = { interest: item.interest };
      //       return data;
      //     })
      //   )
      // );
      formData.append("interestarea", JSON.stringify(selected));
      formData.append("skill", JSON.stringify(selectedSkills));
      if (expertise === "") {
        formData.append("expertise", "");
      } else {
        formData.append("expertise", expertise);
      }

      dispatch(onboardIndividual(formData));
      setDisplayConfirmationModal(true);
    }
  };
  React.useEffect(() => {
    if (value18) {
      dispatch(getCity(value18));
    }
  }, [value18]);

  React.useEffect(() => {
    dispatch(getOrganization({ userid: localStorage.getItem("userid") }));
  }, []);

  React.useEffect(() => {
    if (isIndividualSuccess && individualResponse?.status === "success") {
      setValue1("");
      setLastname("");
      setValue2("");
      setValue3("");
      setValue4("");
      setValue5("");
      setValue8("");
      setValue9("");
      setValue10("");
      setValue11("");
      setValue12("");
      setValue13("");
      setValue14("");
      setValue15("");
      setValue16("");
      setValue18("");
      setValue19("");
      setValue20("");
      setValue21("");
      setValue22("");
      setGender("");
      setNoOfHours("");
      setHighLevelEdu("");
      setPreferDays("");
      setSelected([]);
      setSelectedSkills([]);
      setExpertise("");
      setCountry("");
      setArea("");
      setAdded("");
      setAddress("");
    } else if (isBulkSuccess) {
      setOpen(true);
    }
  }, [isIndividualSuccess, isBulkSuccess, isIndividualError]);

  React.useEffect(() => {
    getORGRole();
  }, []);

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  //handle key press event
  const handleKeypress = (e) => {
    e.preventDefault();
    return false;
  };

  //on focus show calendar
  // const onFocusIn = (e) => {
  //   this.dateObj.show();
  //   return false
  // }
  const updatelocation = (address) => {
    // console.log("index");
    // console.log(index);
   

    if (!address) {
      setCountryError(true);
      setAdded("no");
    } else if (
      String(address.split(",")[address.split(",").length - 2]).trim(" ") ===
      "undefined"
    ) {
      setCountryError(true);
      setAdded("no");
    } else {
      setCountryError(false);
      setCountry(
        String(address.split(",")[address.split(",").length - 1]).trim(" ")
      );
      setValue18(
        String(address.split(",")[address.split(",").length - 2]).trim(" ")
      );
      setValue19(
        String(address.split(",")[address.split(",").length - 3]).trim(" ")
      );
      setArea(
        String(address.split(",")[address.split(",").length - 4]).trim(" ")
      );
      setAdded("yes");
    }
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                marginRight: 12,
                marginBottom: 4,
              }}
            >
              <Button variant="contained" component="label">
                {isBulkFetching ? (
                  <CircularProgress size={22} color="inherit" />
                ) : (
                  <>
                    Bulk Upload
                    <Upload />
                    <input
                      hidden
                      accept=".xlsx"
                      multiple
                      type="file"
                      onChange={handleFileInput}
                    />
                  </>
                )}
              </Button>

              {/* {isBulkSuccess && (
            <span style={{ paddingLeft: 20, color: "green" }}>
              
              {bulkResponse?.message}
            </span>
          )
          }
          {isBulkError && (
            <>

              <span style={{ paddingLeft: 20, color: "green"}}>
                "Please verify the file uploaded is in the right format"
              </span>

            </>
          )} */}
            </div>
            <div style={{ textAlign: "right" }}>
              {isBulkSuccess && (
                <span
                  style={{
                    marginLeft: 400,
                    marginTop: 50,
                    color: "rgb(0,200,50)",
                  }}
                >
                  {bulkResponse?.message}
                </span>
              )}
              {isBulkError && (
                <>
                  <span
                    style={{
                      marginLeft: 600,
                      marginTop: 500,
                      color: "rgb(255,0,0)",
                    }}
                  >
                    "Please verify the file uploaded is in the right format"
                  </span>
                </>
              )}
            </div>
            <div style={{ fontFamily: "monospace", fontSize: 16 }}>
              <Button onClick={() => navigate(-1)} className={classes.backBtn}>
                <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
              </Button>
              {/* <Link className={classes.backBtn} to={"/home"}>
                                <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                                <span style={{ paddingLeft: 0 }}> Back</span>
                            </Link> */}
              <div
                style={{
                  display: "flex",
                  width: "75%",
                  justifyContent: "flex-end",
                  marginRight: 5,
                  marginBottom: 20,
                }}
              >
                <span style={{ color: "red" }}>
                  Please do not leave this page until you submit as you may risk
                  losing the partially filled in form{" "}
                </span>
              </div>
            </div>
            <span
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Onboard Users
            </span>
            <form autoComplete="off" className="onboardUser">
              <div
                style={{
                  border: "1px solid #dadce0",
                  borderRadius: 8,
                  backgroundColor: "#fff",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      First Name <RedStar />
                    </label>
                    <input
                      value={value1}
                      autoFocus="true"
                      type="text"
                      id="first-name"
                      className={classes.input}
                      placeholder="Your answer"
                      onChange={(e) => {
                        setValue1(e.target.value);
                      }}
                      onBlur={() => {
                        if (!value1) {
                          setValue1Error(true);
                        } else {
                          setValue1Error(false);
                        }
                      }}
                    />
                    {value1Error && (
                      <ErrorMessage message="Please enter first name" />
                    )}
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Last Name <RedStar />
                    </label>
                    <input
                      value={lastname}
                      type="text"
                      id="last-name"
                      className={classes.input}
                      placeholder="Your answer"
                      onChange={(e) => {
                        setLastname(e.target.value);
                      }}
                      onBlur={() => {
                        if (!lastname) {
                          setLastnameError(true);
                        } else {
                          setLastnameError(false);
                        }
                      }}
                    />
                    {lastnameError && (
                      <ErrorMessage message="Please enter last name" />
                    )}
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Date of Birth
                      <RedStar />
                    </label>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        style={{
                          boxShadow: "0px 3px 6px #00000029",
                          fontFamily: "Poppins",
                          border:"1px solid #ccc",
                          fontSize: 12,
                        }}
                        // onKeyDown={(e) => { handleKeypress(e)}}
                        // defaultValue=""
                        maxDate={subtractYears(5)}
                        // InputProps={{ readOnly: true }}
                        // minDate={moment(). toDate()}
                        inputFormat={"dd-MM-yyyy"}
                        value={dateofBirth}
                        onChange={(newValue) => {
                          setDateofBirth(newValue);
                          setValue5(newValue);
                        }}
                        // focus={this.onFocusIn.bind(this)} ref={date => this.dateObj = date}
                        // onChangeRaw = {(e) => {handleDateChangeRaw(e)}}
                        // customInput={<button>{{dateofBirth}.toLocaleDateString()}</button>}

                        renderInput={(params) => (
                          <TextField
                            disabled={true}
                            onKeyDown={(e) => {
                              handleKeypress(e);
                            }}
                            defaultValue=""
                            style={{
                              width: "100%",
                              height: 56,
                              border:"1px solid #ccc",
                              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                            }}
                            // InputProps={{ readOnly: true }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Gender
                      <RedStar />
                      <BasicPopover content="The role you will play in your organization on this platform" />
                    </label>

                    <Select
                      style={{
                        width: "100%",
                        fontSize: 14,
                        height: 56,
                        border:"1px solid #ccc",
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                      }}
                      onBlur={() => {
                        if (!gender) {
                          setGenderError(true);
                        } else {
                          setGenderError(false);
                        }
                      }}
                      value={gender}
                      onChange={(event) => setGender(event.target.value)}
                    >
                      {Gender.map((item, index) => (
                        <MenuItem style={{ fontSize: 16 }} value={item.value}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                    {genderError && (
                      <ErrorMessage message="Please select Gender" />
                    )}
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      {form[8]?.formQuestion}
                      <RedStar />
                    </label>

                    <Select
                      style={{
                        width: "100%",
                        fontSize: 14,
                        height: 56,
                        border:"1px solid #ccc",
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                      }}
                      onBlur={() => {
                        if (!value9) {
                          setValue9Error(true);
                        } else {
                          setValue9Error(false);
                        }
                      }}
                      value={value9}
                      onChange={(event) => setValue9(event.target.value)}
                    >
                      {BloodGroup.map((item, index) => (
                        <MenuItem style={{ fontSize: 16 }} value={item.value}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                    {value9Error && (
                      <ErrorMessage message="Please select blood group" />
                    )}
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      {form[11]?.formQuestion}
                      <RedStar />
                    </label>
                    <input
                      value={value12}
                      type="text"
                      id="create-yaad--title"
                      className={classes.input}
                      placeholder="Your answer"
                      onBlur={() => {
                        if (!value12) {
                          setValue12Error(true);
                          setEmailTextError("Please Enter a Valid Email");
                        } else if (!pattern.test(value12)) {
                          setValue12Error(false);
                          // setEmailTextError("Please Enter a Valid Email");
                        } else if (pattern.test(value12)) {
                          setEmailTextError(false);
                          setValue12Error(false);
                        } else {
                          setValue12Error(false);
                        }
                      }}
                      onChange={(e) => {
                        setValue12(e.target.value);
                        // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                      }}
                    />
                    {value12Error && (
                      <ErrorMessage message="Please enter email address" />
                    )}
                    {!value12Error && emailTextError && (
                      <ErrorMessage message="Please enter valid email address" />
                    )}
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      {form[14]?.formQuestion}
                      <RedStar />
                    </label>

                    <div className={classes.phoneInput}>
                      <Select
                        style={{
                          width: 70,
                          fontSize: 14,
                          padding: "0px 0px 0px 5px",
                          marginRight: 12,
                          height: 56,
                        }}
                        className={classes.input}
                        value={
                          registerCallingCode === ""
                            ? "+91"
                            : registerCallingCode
                        }
                        onChange={handleChangeRegisterCallingCode}
                      >
                        {restrictedCountry.map((item, index) => (
                          <MenuItem value={item.dial_code}>
                            {item.dial_code}
                          </MenuItem>
                        ))}
                      </Select>
                      <OutlinedInput
                        onBlur={() => {
                          if (!value15) {
                            setValue15Error(true);
                          } else {
                            setValue15Error(false);
                          }
                        }}
                        value={value15}
                        style={{
                          height: 56,
                          width: "calc(100% - 82px)",
                          boxShadow: "0px 3px 6px #00000029",
                          fontFamily: "Poppins",
                          border:"1px solid #ccc"
                        }}
                        required
                        className={classes.phoneNumber}
                        id="login-phone-number"
                        startAdornment={
                          <InputAdornment position="start">
                            <PhonelinkLockOutlined color="#2C7973" />
                          </InputAdornment>
                        }
                        placeholder="Phone No."
                        onChange={(e) => {
                          setValue15(e.target.value);
                        }}
                      />
                      {value15Error && (
                        <ErrorMessage message="Please enter mobile number" />
                      )}
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      {form[19]?.formQuestion}
                      <RedStar />
                    </label>
                    <Select
                      style={{
                        width: "100%",
                        fontSize: 14,
                        height: 56,
                        border:"1px solid #ccc",
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                      }}
                      onBlur={() => {
                        if (!value20) {
                          setValue20Error(true);
                        } else {
                          setValue20Error(false);
                        }
                      }}
                      value={value20}
                      onChange={(event) => setValue20(event.target.value)}
                    >
                      {organizationListSuccess &&
                        organizationList?.map((item, index) =>
                          item?.label === "sponsor" ? (
                            <MenuItem
                              style={{ fontSize: 16 }}
                              value={item.name}
                            >
                              {item.name} <PaidIcon />
                            </MenuItem>
                          ) : (
                            <MenuItem
                              style={{ fontSize: 16 }}
                              value={item.name}
                            >
                              {item.name}
                            </MenuItem>
                          )
                        )}
                    </Select>
                    {value20Error && (
                      <ErrorMessage message="Please select organization" />
                    )}
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      {form[20]?.formQuestion}
                      <BasicPopover content="The role you will play in your organization on this platform" />
                      <RedStar />
                    </label>
                    <Select
                      style={{
                        width: "100%",
                        fontSize: 14,
                        height: 56,
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                        border:"1px solid #ccc"
                      }}
                      onBlur={() => {
                        if (!value21) {
                          setValue21Error(true);
                        } else {
                          setValue21Error(false);
                        }
                      }}
                      value={value21}
                      onChange={(event) => setValue21(event.target.value)}
                    >
                      {orgRole.map((item, index) => (
                        <MenuItem style={{ fontSize: 16 }} value={item.role}>
                          {item.role}
                        </MenuItem>
                      ))}
                    </Select>
                    {value21Error && (
                      <ErrorMessage message="Please select organization role" />
                    )}
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Highest Level of Education
                      <RedStar />
                    </label>

                    <Select
                      style={{
                        width: "100%",
                        fontSize: 14,
                        height: 56,
                        border:"1px solid #ccc",
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                      }}
                      onBlur={() => {
                        if (!highLevelEdu) {
                          setHighLevelEduError(true);
                        } else {
                          setHighLevelEduError(false);
                        }
                      }}
                      value={highLevelEdu}
                      onChange={(event) => setHighLevelEdu(event.target.value)}
                    >
                      {educationList.map((item, index) => (
                        <MenuItem style={{ fontSize: 16 }} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {highLevelEduError && (
                      <ErrorMessage message="Please select education level" />
                    )}
                  </div>

                  {/* <div className="col-xs-12 col-sm-6 col-md-9 col-lg-9"> */}
                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Volunteering hours / week you can commit
                      <RedStar />
                    </label>

                    <Select
                      style={{
                        width: "100%",
                        fontSize: 14,
                        height: 56,
                        border:"1px solid #ccc",
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                      }}
                      onBlur={() => {
                        if (!noOfHours) {
                          setNoOfHoursError(true);
                        } else {
                          setNoOfHoursError(false);
                        }
                      }}
                      value={noOfHours}
                      onChange={handleHoursDedicatedChange}
                    >
                      {hoursEveryWeek.map((item, index) => (
                        <MenuItem style={{ fontSize: 16 }} value={item.value}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                    {noOfHoursError && (
                      <ErrorMessage message="Please select the no of hours you want to dedicate" />
                    )}
                  </div>

                  {/* <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 customFieldWidth"> */}
                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Which days do you prefer for volunteering?
                      <RedStar />
                    </label>

                    <Select
                      style={{
                        width: "100%",
                        fontSize: 14,
                        height: 56,
                        border:"1px solid #ccc",
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                      }}
                      onBlur={() => {
                        if (!preferDays) {
                          setPreferDaysError(true);
                        } else {
                          setPreferDaysError(false);
                        }
                      }}
                      value={preferDays}
                      onChange={handlePreferDays}
                    >
                      {preferWorkingDays.map((item, index) => (
                        <MenuItem style={{ fontSize: 16 }} value={item.value}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>

                    {preferDaysError && (
                      <ErrorMessage message="Please select days you prefer for volunteering" />
                    )}
                  </div>

                  {/* <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 customFieldWidth"> */}
                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Which volunteering areas interest you?
                      <RedStar />
                    </label>
                    {/* {JSON.stringify(interestAreaDict)} */}

                    <Multiselect
                      displayValue="interest"
                      showCheckbox
                      selectedValues={selected}
                      options={interestAreaDict}
                      onSelect={(event) => {
                        setSelected(event);
                      }}
                      onRemove={(event) => {
                        setSelected(event);
                      }}
                      value={selected}
                      style={{
                        multiselectContainer: {
                          boxShadow: "0px 3px 6px #00000029",
                          border:"1px solid #ccc"
                        },
                        searchBox: {
                          minHeight: 54,
                          PaddingTop: 10,
                        },
                      }}
                    />
                    {/* <MenuItem



                {/* <Select
                  onBlur={() => {
                    if (_.isEmpty(selected)) {
                      setFocusAreaError(true);
                    } else {
                      setFocusAreaError(false);
                    }
                  }}
                  style={{
                    width: "100%",
                    fontSize: 14,
                    height: 56,
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  }}
                  multiple
                  value={selected.map((item) => item?.interest)}
                  onChange={handleChange}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelected ? classes.selectedAll : "",
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelected}
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < interestAreaDict.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {interestAreaDict.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox checked={selected?.indexOf(option) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={option?.interest} />
                    </MenuItem>
                  ))}
                </Select> */}

                    {/* {focusArea?.map((item, index) => (
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="hobbies"
                      id={item.interest}
                      value={item.interest}
                      checked={item.isAdded}
                      onChange={(e) => onAddingItem(e, item, index)}
                    />
                    <label className={classes.label1} htmlFor={item.interest}>
                      {item.interest}
                    </label>
                  </div>
                ))} */}
                    {focusAreaError && (
                      <ErrorMessage message="Please select volunteering areas interest you" />
                    )}
                  </div>

                  {/* <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 customFieldWidth"> */}
                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Tell us what are you good at?
                      <RedStar />
                    </label>

                    <Multiselect
                      displayValue="skill"
                      showCheckbox
                      selectedValues={selectedSkills}
                      options={skillSets}
                      onSelect={(event) => {
                        setSelectedSkills(event);
                      }}
                      onRemove={(event) => {
                        setSelectedSkills(event);
                      }}
                      value={selectedSkills}
                      style={{
                        multiselectContainer: {
                          boxShadow: "0px 3px 6px #00000029",
                        },
                        searchBox: {
                          minHeight: 54,
                          PaddingTop: 10,
                        },
                      }}
                    />

                    {skillsError && (
                      <ErrorMessage message="Please select skills you are good at" />
                    )}
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                  <label htmlFor="create-yaad--title" className={classes.label}>
                    Expertise
                    
                  </label>
                  {/* <input */}
                  <textarea
                    value={expertise}
                    type="text"
                    className={classes.input}
                    placeholder="Your answer"
                    maxLength="200"
                    onChange={(e) => {
                      setExpertise(e.target.value);
                    }}
                  />
                </div>
                </div>

                {/* <div className={classes.formGroup}> */}
                

                <div className={classes.formGroup2}>
                  <label
                    htmlFor="create-yaad--title"
                    className={classes.label2}
                  >
                    Enter your location <RedStar />{" "}
                    <span
                      style={{
                        paddingLeft: 0,
                        fontSize: 16,
                        fontWeight: 300,
                        fontStyle: "italic",
                      }}
                    >
                      {" "}
                      (Search for location, select and click on Confirm.)
                    </span>
                  </label>
                  {/* <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>Enter your location </span>  */}

                  <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div style={{ padding: "25px 0px 0px 0px" }}>
                        {/* <input {...getInputProps({ placeholder: "Type Address", autoFocus: true, endAdornment:<SearchIcon />  })} /> */}
                        <TextField
                          // onChange={handleChange1}
                          // onChange={(e) => setcorrespendeceaddress(e.target.value)}
                          // onFilled={(e) => { handletextentered(e) }}
                          // onBlur={(e) => { handletextentered(e) }}
                          // onFocus={(e) => { handletextentered(e) }}
                          value={correspondenceaddress}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <IconButton>
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onBlur={() => {
                            if (!country) {
                              setCountryError(true);
                            } else {
                              setCountryError(false);
                            }
                          }}
                          {...getInputProps({
                            placeholder: "Search for address..",
                            autoFocus: false,
                          })}
                        />
                        <div> {loading ? <div>...loading...</div> : null}</div>
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "blue"
                              : "white",
                          };
                          // console.log("Hi");
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </PlacesAutocomplete>
                  {countryError && (
                    <ErrorMessage message="Please chose the location" />
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Country
                      <RedStar />
                    </label>
                    {address.split(",")[address.split(",").length - 1]}
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      {form[17]?.formQuestion}
                      <RedStar />
                    </label>
                    {address.split(",")[address.split(",").length - 2]}
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      {form[18]?.formQuestion}
                      <RedStar />
                    </label>
                    {address.split(",")[address.split(",").length - 3]}
                  </div>

                  <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Area
                      <RedStar />
                    </label>
                    {address.split(",")[address.split(",").length - 4]}
                  </div>

                  {/* <div className={classes.formGroup4} >
                                        <Button
                                            variant="contained"
                                            onClick={() => updatelocation(address)}
                                            // className="btn save-btn"
                                            disableElevation
                                            style={{
                                                // marginTop: 1,
                                                width: "50px",
                                                height: "35px",
                                                background: "#f5eceb",
                                                // borderRadius: 20,
                                                // color: "#fff",
                                                color: "green",
                                                fontSize: 16,
                                                // borderBlockColor: "orange",
                                                // borderBlock: 5,
                                                fontFamily: "Poppins !important",

                                            }}

                                        >Confirm
                                        </Button>
                                        {added == "yes" ? (
                                            <DoneIcon style={{ color: "green", }} />
                                        ) :
                                            ""
                                        }
                                    </div> */}
                </div>
                <div
                  style={{ textAlign: "center", padding: "20px 0px 0px 0px" }}
                >
                  <Button
                    variant="contained"
                    onClick={() => updatelocation(address)}
                    // className="btn save-btn"
                    disableElevation
                    style={{
                      // marginTop: 1,
                      width: "86px",
                      height: "35px",
                      background: "#f5eceb",
                      // borderRadius: 20,
                      // color: "#fff",
                      color: "green",
                      fontSize: 16,
                      // borderBlockColor: "orange",
                      // borderBlock: 5,
                      fontFamily: "Poppins !important",
                    }}
                  >
                    Confirm
                  </Button>
                  {added == "yes" ? (
                    <DoneIcon style={{ color: "green", fontSize: "30px" }} />
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                  <label htmlFor="create-yaad--title" className={classes.label}>
                    House/Door/Flat No.
                    <RedStar />
                  </label>

                  <input
                    value={value10}
                    //   value={correspondenceaddress}
                    type="text"
                    id="create-yaad--title"
                    className={classes.input}
                    placeholder="Your answer"
                    onBlur={() => {
                      if (!value10) {
                        setValue10Error(true);
                      } else {
                        setValue10Error(false);
                      }
                    }}
                    onChange={(e) => {
                      setValue10(e.target.value);
                      // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                    }}
                  />
                  {value10Error && (
                    <ErrorMessage message="Please enter correspondence address" />
                  )}
                </div>

                <b></b>

                {/* <div style={{ padding: "16px 0", bottom: 50, left: 20 }}> */}
                <div
                  className="centerAlign"
                  style={{ padding: "60px 0", bottom: 50 }}
                >
                  {/* <span>
            {isIndividualSuccess && (
                <>
                  {individualResponse?.status === "success" ? (
                    <span style={{ paddingLeft: 20, color: "green" }}>
                      {individualResponse?.message}
                    </span>
                  ) : (
                    <span style={{ paddingLeft: 20,color: "red" }}>
                      {individualResponse?.message}
                    </span>
                  )}
                </>
              )}
            </span> */}
                  <Button
                    style={{ marginLeft: 20 }}
                    variant="contained"
                    // onClick={handleForm}
                    // className={classes.goalButton}
                    className="btn save-btn"
                    disableElevation
                    onClick={handleOnboardIndividual}
                    disabled={isIndividualFetching}
                  >
                    <span>Submit</span>
                  </Button>
                  <br />
                  {/* {isIndividualSuccess && (
                    <>
                      {individualResponse?.status === "success" ? (
                        <span style={{ paddingLeft: 20, color: "green" }}>
                          {individualResponse?.message}
                        </span>
                      ) : (
                        <span style={{ paddingLeft: 20, color: "red" }}>
                          {individualResponse?.message}
                        </span>
                      )}
                    </>
                  )} */}
                </div>
              </div>
            </form>

            <div>
              {/* {isBulkSuccess && (
            <span style={{ marginLeft: 400, marginTop:50,  color:"rgb(0,200,50)" }}>
              {bulkResponse?.message}        
              
            </span>
            )}
             {isBulkError && (
            <>

              <span style={{ marginLeft: 600, marginTop:500, color:"rgb(255,0,0)"}}>
                "Please verify the file uploaded is in the right format"
              </span>

            </>
          )} */}
            </div>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
        <ConfirmationDialog
          showModal={displayConfirmationModal}
          // confirmModal={handleRedeem}
          hideModal={hideConfirmationModal}
          // message={"You do not have enough points to redeem"}
          message={individualResponse?.message}
        />
      </section>

      {/* <div className={classes.snack}>
        <Snackbar open={open} autoHideDuration={8000} onClose={handleClose}>
          <Alert
            style={{ width: "50%" }}
            onClose={handleClose}
            // severity="success"
          >
            {isBulkSuccess && (
            <span style={{ wordBreak: "break-word" }}>
              {bulkResponse?.message}        
              
            </span>
            )}
             {isBulkError && (
            <>

              <span style={{ wordBreak: "break-word", color: red}}>
                "Please verify the file uploaded is in the right format"
              </span>

            </>
          )}

          </Alert>

        </Snackbar>
      </div> */}

      {/* <CopyRight /> */}
    </div>
  );
}
export default OnBoardVolunteer1;
