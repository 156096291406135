/** @format */

// reducers
import { CAMP_LEADERBOARD_FETCHING_DATA, CAMP_LEADERBOARD_FETCHING_DATA_FAILURE, CAMP_LEADERBOARD_FETCHING_DATA_RESET, CAMP_LEADERBOARD_FETCHING_DATA_SUCCESS } from '../action/CampLeaderBoardAction';

let initialState = {
	response: {},
	isSuccess: false,
	isError: false,
	isFetching: false,
};

export default function CampLeaderBoardReducer(state = initialState, action) {
	switch (action.type) {
		case CAMP_LEADERBOARD_FETCHING_DATA:
			return {
				...state,
				isFetching: true,
				isError: false,
				isSuccess: false,
			};
		case CAMP_LEADERBOARD_FETCHING_DATA_FAILURE:
			return {
				...state,
				isFetching: false,
				isError: action.payload,
				isSuccess: false,
			};
		case CAMP_LEADERBOARD_FETCHING_DATA_SUCCESS:
			return {
				...state,
				isFetching: false,
				isError: false,
				response: action.payload,
				isSuccess: true,
			};

		case CAMP_LEADERBOARD_FETCHING_DATA_RESET:
			return initialState;

		default:
			return state;
	}
}
