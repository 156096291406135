/** @format */

const USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA = 'USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA';
const USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_FAILURE = 'USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_FAILURE';
const USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_RESET = 'USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_RESET';
const USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_SUCCESS = 'USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_SUCCESS';
const USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_ATTEMPT = 'USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_ATTEMPT';
const actionUserOnboardReview = payload => ({
	type: USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_ATTEMPT,
	payload: payload,
});
const actionUserOnboardReviewReset = payload => ({
	type: USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_RESET,
	payload: payload,
});

export { USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA, USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_FAILURE, USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_RESET, USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_SUCCESS, USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_ATTEMPT, 
	actionUserOnboardReview, 
	actionUserOnboardReviewReset 
};
