/** @format */

const REWARDS_REDEEMED_LIST_FETCHING_DATA = "REWARDS_REDEEMED_LIST_FETCHING_DATA";
const REWARDS_REDEEMED_LIST_FETCHING_DATA_FAILURE =
  "REWARDS_REDEEMED_LIST_FETCHING_DATA_FAILURE";
const REWARDS_REDEEMED_LIST_FETCHING_DATA_RESET = "REWARDS_REDEEMED_LIST_FETCHING_DATA_RESET";
const REWARDS_REDEEMED_LIST_FETCHING_DATA_SUCCESS =
  "REWARDS_REDEEMED_LIST_FETCHING_DATA_SUCCESS";
const REWARDS_REDEEMED_LIST_FETCHING_DATA_ATTEMPT =
  "REWARDS_REDEEMED_LIST_FETCHING_DATA_ATTEMPT";
const rewardsRedeemedList = (payload) => ({
  type: REWARDS_REDEEMED_LIST_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const rewardsRedeemedListReset = (payload) => ({
  type: REWARDS_REDEEMED_LIST_FETCHING_DATA_RESET,
});
export {
  REWARDS_REDEEMED_LIST_FETCHING_DATA,
  REWARDS_REDEEMED_LIST_FETCHING_DATA_FAILURE,
  REWARDS_REDEEMED_LIST_FETCHING_DATA_RESET,
  REWARDS_REDEEMED_LIST_FETCHING_DATA_SUCCESS,
  REWARDS_REDEEMED_LIST_FETCHING_DATA_ATTEMPT,
  rewardsRedeemedList,
  rewardsRedeemedListReset,
};
