import { makeStyles } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "../../components/Header";
import {
  AcitvityTableComponent,
  CampaignTableComponent,
  TableComponent,
} from "../../components/Table";
import { getVolunteerName } from "../../redux/action/getVolunteerName";
import { userProfile } from "../../redux/action/UserProfileActions";
import { API_URL } from "../../services/Constants";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 100px 330px",
    },
  },
}));

const ActivityListScreen = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activityList, setActivityList] = useState([]);
  const { response: volunteerNameResponse, isFetching: volunteerNameFetching } =
    useSelector((state) => state.volunteerName);

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const getActivityList = async () => {
    try {
      const res = await axios.get(`/report/volunteeractivity`);
      setActivityList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    dispatch(userProfile(localStorage.getItem("userid")));
    getActivityList();
  }, []);

  return (
    <div>
      <ResponsiveDrawer {...props} />
      <main
        onClick={() => {
          props.setNotificationShow(false);
        }}
        className={classes.homeSreenMainContent}
        id="homepage-main"
      >
        <span style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Activity List</span>

        <AcitvityTableComponent rows={activityList} />
      </main>
    </div>
  );
};

export default ActivityListScreen;
