import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar, InputAdornment, IconButton, Typography, Button, Grid, Snackbar } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from "axios";
import CustomerDetailsPopup from './CustomerDetailsPopup';
import ResponsiveDrawer from "../../components/Header";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useNavigate, useLocation } from "react-router-dom";
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import parseISO from 'date-fns/parseISO';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CustomerPopup from "./CustomerPopup";
import YouGot from "./YouGot";
import YouGave from "./YouGave";




const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  timeAgoCell: {
    verticalAlign: 'bottom',
  },
  searchIcon: {
    color: theme.palette.text.secondary,
  },
  evenRow: {
    backgroundColor: theme.palette.grey[100], // Set your desired background color for even rows
  },

  oddRow: {
    backgroundColor: theme.palette.background.default, // Set your desired background color for odd rows
  },

  whiteComponent: {
    // backgroundColor: '#ffa800',
    // borderRadius: '8px',
    // padding: '8px',
    marginBottom: '16px',
  },

  greenText: {
    color: 'green',
  },

  redText: {
    color: 'red',
  },

  grayText: {
    color: 'gray',
    fontSize: '9px',

  },
}));

const KTCustomerList = ({ open, onClose, props }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchTerm, setSearchTerm] = useState('');
  const [customers, setCustomers] = useState([]);
  const [customerDetList, setCustomerDetList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerPopupOpen, setCustomerPopupOpen] = useState(false);
  const [shouldRerender, setShouldRerender] = useState(false);
  const [openYouGotPopup, setOpenYouGotPopup] = useState(false);

  const [isCustomerDetailsOpen, setIsCustomerDetailsOpen] = useState(false);
  const [selectedCustomerDetails, setSelectedCustomerDetails] = useState(null);
  const [myAccountSummary, setMyAccountSummary] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [smsviaSNS, setSMSviaSNS] = useState([]);


  // const handleYouGotSave = () => {
  //   setShouldRerender(!shouldRerender);
  // };

  const handlePaymentCompleted = () => {
    // Add any logic here that you want to execute when payment is completed
    // For example, you can fetch the updated customer list
    getCustomerList();
  };

  const handleAddCustomer = () => {
    setCustomerPopupOpen(true);
    navigate(`/kt/customer/create`)
  };

  useEffect(() => {
    getCustomerList();
  }, []);

  const getCustomerList = async () => {
    try {
      const res = await axios.get(`/kt/get/customer/list/${localStorage.getItem("userid")}`);
      setCustomerDetList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    getAccountSummary();
  }, []);

  const getAccountSummary = async () => {
    try {
      const res = await axios.get(`/kt/get/your/acc/summary/${localStorage.getItem("userid")}`);
      setMyAccountSummary(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  console.log("accountSummary = ", myAccountSummary);

  console.log(`/sendsms/sns/${customerDetList?.[0]?.phoneno}/${customerDetList?.[0]?.name}/${customerDetList?.[0]?.balanceamount}`)

  const handleSmsIconClick = async () => {
    try {
      // Replace 'your-api-endpoint' with the actual API endpoint
      const res = await axios.get(`/sendsms/sns/${customerDetList?.[0]?.phoneno}/${customerDetList?.[0]?.name}/${customerDetList?.[0]?.balanceamount}`);
      setSMSviaSNS(res?.data);

      setSnackbarMessage(res?.data?.message);
      setSnackbarOpen(true);

      // Close the Snackbar after 3 seconds
      setTimeout(() => {
        setSnackbarOpen(false);

        // navigate(`/kt/customer/list`);
      }, 3000);

    } catch (error) {
      console.error('Error calling API:', error);
      // Handle error or display a message to the user
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Filter customers based on the search term
  const filteredCustomers = customerDetList.length > 0
    ? customerDetList.filter(customer =>
      customer.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : [];

  const handleRowClick = (customer) => {
    setSelectedCustomerDetails(customer);
    setIsCustomerDetailsOpen(true);
  };


  const handleClosePopup = () => {
    setSelectedCustomer(null);
  };

  const formatDate = (dateString) => {
    const timeDifference = formatDistanceToNow(parseISO(dateString));
    return `${timeDifference} ago`;
  };

  const handleYouGotSave = () => {
    // Trigger a rerender by toggling the shouldRerender state
    setShouldRerender((prev) => !prev);
  };

  useEffect(() => {
    // Fetch updated customer list whenever shouldRerender changes
    getCustomerList();
  }, [shouldRerender]);

  console.log("myAccountSummary?.[0]?.yougave = ",myAccountSummary?.[0]?.yougave)

  const CountUpEffectYouGave = ({ startValue, endValue, duration }) => {
    const [count, setCount] = useState(startValue);
  
    useEffect(() => {
      let startTime;
      let animationFrameId;
  
      const startAnimation = (timestamp) => {
        if (!startTime) startTime = timestamp;
  
        const progress = timestamp - startTime;
        const percentage = Math.min(1, progress / duration);
        
      //   console.log("endValue = ", endValue);
      //   console.log('startValue:', startValue);
      
      // console.log('percentage:', percentage);

      if (endValue !== undefined && typeof endValue === 'number' && !isNaN(endValue)) {
        const currentValue = startValue + percentage * (endValue - startValue);
        console.log('currentValue:', currentValue);
        setCount(currentValue);
      }
  
        if (progress < duration) {
          // Continue the animation
          animationFrameId = requestAnimationFrame(startAnimation);
        }
      };
  
      // Start the animation
      animationFrameId = requestAnimationFrame(startAnimation);
  
      // Cleanup function to cancel animation frame when component unmounts
      return () => cancelAnimationFrame(animationFrameId);
    }, [startValue, endValue, duration]);
    console.log("count = ",count)
  
    return <span>{count}</span>;
  };


  const CountUpEffect = ({ startValue, endValue, duration }) => {
    const [count, setCount] = useState(startValue);
  
    useEffect(() => {
      let startTime;
      let animationFrameId;
  
      const startAnimation = (timestamp) => {
        if (!startTime) startTime = timestamp;
  
        const progress = timestamp - startTime;
        const percentage = Math.min(1, progress / duration);
  
        setCount(Math.floor(percentage * ((endValue) - startValue) + startValue));
  
        if (progress < duration) {
          // Continue the animation
          animationFrameId = requestAnimationFrame(startAnimation);
        }
      };
  
      // Start the animation
      animationFrameId = requestAnimationFrame(startAnimation);
  
      // Cleanup function to cancel animation frame when component unmounts
      return () => cancelAnimationFrame(animationFrameId);
    }, [startValue, endValue, duration]);
    console.log("count = ",count)
  
    return <span>{count}</span>;
  };


  const CountUpEffectYouGot = ({ startValue, endValue, duration }) => {
    const [count, setCount] = useState(startValue);
  
    useEffect(() => {
      let startTime;
      let animationFrameId;
  
      const startAnimation = (timestamp) => {
        if (!startTime) startTime = timestamp;
  
        const progress = timestamp - startTime;
        const percentage = Math.min(1, progress / duration);
  
        setCount(Math.floor(percentage * ((endValue) - startValue) + startValue));
  
        if (progress < duration) {
          // Continue the animation
          animationFrameId = requestAnimationFrame(startAnimation);
        }
      };
  
      // Start the animation
      animationFrameId = requestAnimationFrame(startAnimation);
  
      // Cleanup function to cancel animation frame when component unmounts
      return () => cancelAnimationFrame(animationFrameId);
    }, [startValue, endValue, duration]);
    console.log("count = ",count)
  
    return <span>{count}</span>;
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg hasScreen">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>

            <Grid container spacing={1} style={{ backgroundColor: '#faf0e6', borderRadius: '8px', border: '1px solid', borderColor: "black", marginBottom: '16px', margin: "auto", width: "100%", textAlign: "center" }}>
              <Grid item xs={6}>
                <div>
                  <Typography variant="subtitle1" className={classes.greenText} style={{fontSize: '14px', fontWeight: 600}}>
                    {/* ₹ {myAccountSummary?.[0]?.yougot} */}
                    ₹ <CountUpEffect startValue={0} endValue={myAccountSummary?.[0]?.yougot} duration={1000} />
                  </Typography>
                  <Typography variant="body2" className={classes.grayText} style={{fontSize: '11px',}}>
                    You will Give
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div >
                  <Typography variant="subtitle1" className={classes.redText} style={{fontSize: '14px', fontWeight: 600}}>
                    {/* ₹ {myAccountSummary?.[1]?.yougave} */}
                    ₹ <CountUpEffect startValue={0} endValue={myAccountSummary?.[1]?.yougave} duration={1000} />
                  </Typography>
                  <Typography variant="body2" className={classes.grayText} style={{fontSize: '11px',}}>
                    You will Get
                  </Typography>
                </div>
              </Grid>
            </Grid>

            <TextField
              label="Search Customer"
              fullWidth
              variant="outlined"
              margin="normal"
              autoComplete="off"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton className={classes.searchIcon} edge="start" color="inherit">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* </Grid> */}

            <TableContainer component={Paper} className={classes.tableContainer}>
              <Table>
                <TableHead style={{ backgroundColor: 'gray', color: "white" }}>
                  <TableRow>
                    <TableCell style={{ color: "white" }}>Customer Name</TableCell>
                    <TableCell style={{ color: "white" }}>Balance Payment</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredCustomers.length > 0 ? (
                    filteredCustomers.map((customer, index) => (
                      <TableRow key={customer.id}
                        className={`${classes.clickableRow} ${index % 2 === 0 ? classes.evenRow : classes.oddRow}`}
                        onClick={() => navigate(`/detail/${customer?.customerid}`)}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell style={{ display: "flex", alignItems: "center" }}>
                          <Avatar>{customer.avatar}</Avatar>
                          <div style={{ marginLeft: '8px' }}>
                            <div>{customer.name}</div>
                            <div style={{ fontSize: 'calc(14px - 2px)' }}>{formatDate(customer.createdate).startsWith('about') ?
                              formatDate(customer.createdate).slice(6) :
                              formatDate(customer.createdate)}</div>
                          </div>
                        </TableCell>

                        <TableCell style={{ textAlign: "center" }}>
                          <span style={{ color: customer.balanceamount < 0 ? 'green' : (customer.type === 'You Gave' ? 'red' : 'black') }}>
                            ₹ {customer.balanceamount >= 0 ? customer.balanceamount : `${Math.abs(customer.balanceamount)}`} {" "}
                          </span>
                          {customer.balanceamount >= 0 &&
                            <div style={{ marginLeft: '8px', fontSize: "10px" }}>
                              {/* <Button variant="contained" color="primary" onClick={handleSmsIconClick}> */}
                              {/* <Button onClick={handleSmsIconClick}> */}
                              <Button onClick={(e) => { e.stopPropagation(); handleSmsIconClick(); }}>
                                REMIND
                              </Button>
                            </div>
                          }
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography variant="body2" color="textSecondary" align="center">
                          No customers found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleSnackbarClose}
              message={snackbarMessage}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <div style={{ background: 'green', padding: '16px', borderRadius: '8px', fontSize: '18px', textAlign: 'center' }}>
                {snackbarMessage}
              </div>
            </Snackbar>
            {/* </div> */}
            {/* </Grid> */}
          </div>
        </div>
      </section>
      <div style={{ position: 'fixed', bottom: '16px', right: '16px', zIndex: '1000' }}>
        <Button variant="contained" color="primary" startIcon={<PersonAddIcon />} onClick={handleAddCustomer}>
          Add Customer
        </Button>
      </div>
    </div>
  );
};

export default KTCustomerList;
