/** @format */

import { call, put } from "redux-saga/effects";
import { get } from "lodash";

import {
  USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA,
  USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_SUCCESS,
  USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_FAILURE,
} from "../action/ActionUserOnboardReview";
import { Api } from "../../services";

const myAPI = new Api();

export function* actionUserOnboardReview(action) {
  yield put({
    type: USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.actionUserOnboardReview, action.payload);
    // const payload = get(response, "data");
    yield put({
      type: USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    yield put({ type: USER_ONBOARD_REVIEW_ACTION_FETCHING_DATA_FAILURE });
  }
}
