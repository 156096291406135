import {
    makeStyles,
    Box,
    GridList,
    GridListTile,
    Tab,
    Tabs,
    Typography,
  } from "@material-ui/core";
  import PropTypes from "prop-types";
  import axios from "axios";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import ResponsiveDrawer from "../../components/CustomHeader";
  import {
    AcitvityTableComponent,
    CampaignTableComponent,
    TableComponent,
  } from "../../components/Table";
  import { getVolunteerName } from "../../redux/action/getVolunteerName";
  import { userProfile } from "../../redux/action/UserProfileActions";
  import { API_URL } from "../../services/Constants";
  import { HomeCard } from "../../components/Card";
  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={0}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  const useStyles = makeStyles((theme) => ({
    homeSreenMainContent: {
      padding: "80px 0 100px 0px",
      display: "flex",
      background: "#F5F8FB",
      minHeight: "100vh",
      width: "max-content",
      minWidth: "100vw",
      flexDirection: "column",
      [theme.breakpoints.up("sm")]: {
        padding: "0px 30px 100px 25px",
      },
    },
    tabName: {
      color: "#fff",
      textTransform: "none",
    },
  }));
  
  const ListScreenCampForVol = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [activityList, setActivityList] = useState([]);
    const [value, setValue] = React.useState(0);
    const [activeTrue, setActiveTrue] = React.useState(false);
  
    const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
      (state) => state.profile
    );
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const getActivityList = async () => {
      try {
        const res = await axios.get(
          `vol_dashboard/camp/${profileResponse?.orgrole}/${profileResponse?.organization}`
        );
        setActivityList(res?.data);
        return res;
      } catch (err) {
        throw err;
      }
    };
    useEffect(() => {
      dispatch(userProfile(localStorage.getItem("userid")));
    }, []);
    useEffect(() => {
      getActivityList();
    }, [profileResponse]);
  
    return (
      <div>
        <ResponsiveDrawer {...props} />
        <main
          onClick={() => {
            props.setNotificationShow(false);
          }}
          className={classes.homeSreenMainContent}
          id="homepage-main"
        >
          <span style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Campaign List CampForVol</span>
  
          {/* <CampaignTableComponent rows={activityList} /> */}
          <Tabs
            style={{
              background: "#1A524D 0% 0% no-repeat padding-box",
              width: 585,
              borderRadius: 20,
            }}
            value={value}
            onChange={handleChange}
          >
            <Tab label="Upcoming Campaigns" className={classes.tabName} />
            <Tab label="Active Campaigns" className={classes.tabName} />
            <Tab label="Completed Campaigns" className={classes.tabName} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <div className={classes.myImpacts}>
              <GridList className={classes.gridList} cellHeight={450}>
                {activityList
                  ?.filter((item, index) => item.active === "Upcoming")
                  .map((tile) => (
                    <GridListTile
                      style={{ width: "auto !important" }}
                      key={tile.image}
                    >
                      <HomeCard
                        isCampaignScreen
                        data={tile}
                        key={tile.cardTitle}
                        userDetail={profileResponse ? profileResponse : ""}
                      />
                    </GridListTile>
                  ))}
              </GridList>
            </div>
  
            {activeTrue && (
              <div className={classes.myImpacts}>
                <GridList className={classes.gridList} cellHeight={450}>
                  <GridListTile
                    style={{
                      width: "300px",
                      height: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // backgroundColor: "rgba(0,0,0,0.3)",
                    }}
                    // key={tile.image}?
                  >
                    <Typography style={{ color: "#fff" }}>
                      no active yaags right now
                    </Typography>
                  </GridListTile>
                </GridList>
              </div>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className={classes.myImpacts}>
              <GridList className={classes.gridList} cellHeight={450}>
                {activityList
                  ?.filter((item, index) => item.active === "Active")
                  .map((tile) => (
                    <GridListTile
                      style={{ width: "auto !important" }}
                      key={tile.image}
                    >
                      <HomeCard
                        isCampaignScreen
                        data={tile}
                        key={tile.cardTitle}
                        userDetail={profileResponse ? profileResponse : ""}
                      />
                    </GridListTile>
                  ))}
              </GridList>
            </div>
          </TabPanel>
  
          <TabPanel value={value} index={2}>
            <div className={classes.myImpacts}>
              <GridList className={classes.gridList} cellHeight={450}>
                {activityList
                  ?.filter((item, index) => item.active === "Completed")
                  .map((tile) => (
                    <GridListTile
                      style={{ width: "auto !important" }}
                      key={tile.image}
                    >
                      <HomeCard
                        isCampaignScreen
                        data={tile}
                        key={tile.cardTitle}
                        userDetail={profileResponse ? profileResponse : ""}
                      />
                    </GridListTile>
                  ))}
              </GridList>
            </div>
          </TabPanel>
        </main>
      </div>
    );
  };
  
  export default ListScreenCampForVol;
  