/** @format */


//KT Customer TRN DELETE
const DELETE_KTCUSTOMERTRN_FETCHING_DATA = "DELETE_KTCUSTOMERTRN_FETCHING_DATA";
const DELETE_KTCUSTOMERTRN_FETCHING_DATA_FAILURE = "DELETE_KTCUSTOMERTRN_FETCHING_DATA_FAILURE";
const DELETE_KTCUSTOMERTRN_FETCHING_DATA_RESET = "DELETE_KTCUSTOMERTRN_FETCHING_DATA_RESET";
const DELETE_KTCUSTOMERTRN_FETCHING_DATA_SUCCESS = "DELETE_KTCUSTOMERTRN_FETCHING_DATA_SUCCESS";
const DELETE_KTCUSTOMERTRN_FETCHING_DATA_ATTEMPT = "DELETE_KTCUSTOMERTRN_FETCHING_DATA_ATTEMPT";
const deleteKTCustomerTRN = (payload) => ({
  type: DELETE_KTCUSTOMERTRN_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const deleteKTCustomerTRNSuccess = () => ({
  type: DELETE_KTCUSTOMERTRN_FETCHING_DATA_SUCCESS,
});
const deleteKTCustomerTRNReset = () => ({
  type: DELETE_KTCUSTOMERTRN_FETCHING_DATA_RESET,
});

export {
  DELETE_KTCUSTOMERTRN_FETCHING_DATA,
  DELETE_KTCUSTOMERTRN_FETCHING_DATA_FAILURE,
  DELETE_KTCUSTOMERTRN_FETCHING_DATA_RESET,
  DELETE_KTCUSTOMERTRN_FETCHING_DATA_SUCCESS,
  DELETE_KTCUSTOMERTRN_FETCHING_DATA_ATTEMPT,
  deleteKTCustomerTRN,
  deleteKTCustomerTRNSuccess,
  deleteKTCustomerTRNReset,
};