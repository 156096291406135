/** @format */

// Delete KT Customer TRN

import {
  
    DELETE_KTCUSTOMERTRN_FETCHING_DATA,
    DELETE_KTCUSTOMERTRN_FETCHING_DATA_FAILURE,
    DELETE_KTCUSTOMERTRN_FETCHING_DATA_RESET,
    DELETE_KTCUSTOMERTRN_FETCHING_DATA_SUCCESS,
    
  } from "../action/DeleteAction";
  
  let initialState = {
    ktCustomerTRNDeleteResponse: {},
    isKTCustomerTRNDeleteSuccess: false,
    isKTCustomerTRNDeleteError: false,
    isKTCustomerTRNDeleteFetching: false,
  };
  
  export default function DeleteReducer(state = initialState, action) {
    switch (action.type) {
      
            case DELETE_KTCUSTOMERTRN_FETCHING_DATA:
            return {
              ...state,
              
              isKTCustomerTRNDeleteSuccess: false,
              isKTCustomerTRNDeleteError: false,
              isKTCustomerTRNDeleteFetching: true,
            };
          case DELETE_KTCUSTOMERTRN_FETCHING_DATA_FAILURE:
            return {
              ...state,
              
              isKTCustomerTRNDeleteSuccess: false,
              isKTCustomerTRNDeleteError: true,
              isKTCustomerTRNDeleteFetching: false,
            };
          case DELETE_KTCUSTOMERTRN_FETCHING_DATA_SUCCESS:
            return {
              ...state,
              ktCustomerTRNDeleteResponse: action.payload,
              isKTCustomerTRNDeleteSuccess: true,
              isKTCustomerTRNDeleteError: false,
              isKTCustomerTRNDeleteFetching: false,
            };
      
          case DELETE_KTCUSTOMERTRN_FETCHING_DATA_RESET:
            return {
              ...state,
              ktCustomerTRNDeleteResponse: {},
              isKTCustomerTRNDeleteSuccess: false,
              isKTCustomerTRNDeleteError: false,
              isKTCustomerTRNDeleteFetching: false,
            };


      default:
        return state;
    }
  }

  
  