/** @format */

const SOCIAL_LOGIN_FETCHING_DATA = 'SOCIAL_LOGIN_FETCHING_DATA';
const SOCIAL_LOGIN_FETCHING_DATA_FAILURE = 'SOCIAL_LOGIN_FETCHING_DATA_FAILURE';
const SOCIAL_LOGIN_FETCHING_DATA_RESET = 'SOCIAL_LOGIN_FETCHING_DATA_RESET';
const SOCIAL_LOGIN_FETCHING_DATA_SUCCESS = 'SOCIAL_LOGIN_FETCHING_DATA_SUCCESS';
const SOCIAL_LOGIN_FETCHING_DATA_ATTEMPT = 'SOCIAL_LOGIN_FETCHING_DATA_ATTEMPT';
const socialLogin = payload => ({
	type: SOCIAL_LOGIN_FETCHING_DATA_ATTEMPT,
	payload: payload,
});

const socialLoginSuccess = () => ({
	type: SOCIAL_LOGIN_FETCHING_DATA_SUCCESS,
});
const socialLoginReset = () => ({
	type: SOCIAL_LOGIN_FETCHING_DATA_RESET,
});

export { SOCIAL_LOGIN_FETCHING_DATA, SOCIAL_LOGIN_FETCHING_DATA_FAILURE, SOCIAL_LOGIN_FETCHING_DATA_RESET, SOCIAL_LOGIN_FETCHING_DATA_SUCCESS, SOCIAL_LOGIN_FETCHING_DATA_ATTEMPT, socialLogin, socialLoginSuccess, socialLoginReset };


