/** @format */



//campaign

const UPDATE_CAMPAIGN_FETCHING_DATA = "UPDATE_CAMPAIGN_FETCHING_DATA";
const UPDATE_CAMPAIGN_FETCHING_DATA_FAILURE = "UPDATE_CAMPAIGN_FETCHING_DATA_FAILURE";
const UPDATE_CAMPAIGN_FETCHING_DATA_RESET = "UPDATE_CAMPAIGN_FETCHING_DATA_RESET";
const UPDATE_CAMPAIGN_FETCHING_DATA_SUCCESS = "UPDATE_CAMPAIGN_FETCHING_DATA_SUCCESS";
const UPDATE_CAMPAIGN_FETCHING_DATA_ATTEMPT = "UPDATED_CAMPAIGN_FETCHING_DATA_ATTEMPT";
const updateCampaign = (payload) => ({
  type: UPDATE_CAMPAIGN_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const updateCampaignSuccess = () => ({
  type: UPDATE_CAMPAIGN_FETCHING_DATA_SUCCESS,
});
const updateCampaignReset = () => ({
  type: UPDATE_CAMPAIGN_FETCHING_DATA_RESET,
});

export {
  UPDATE_CAMPAIGN_FETCHING_DATA,
  UPDATE_CAMPAIGN_FETCHING_DATA_FAILURE,
  UPDATE_CAMPAIGN_FETCHING_DATA_RESET,
  UPDATE_CAMPAIGN_FETCHING_DATA_SUCCESS,
  UPDATE_CAMPAIGN_FETCHING_DATA_ATTEMPT,
  updateCampaign,
  updateCampaignSuccess,
  updateCampaignReset,
};

//organization

const UPDATE_ORGANIZATION_FETCHING_DATA = "UPDATE_ORGANIZATION_FETCHING_DATA";
const UPDATE_ORGANIZATION_FETCHING_DATA_FAILURE = "UPDATE_ORGANIZATION_FETCHING_DATA_FAILURE";
const UPDATE_ORGANIZATION_FETCHING_DATA_RESET = "UPDATE_ORGANIZATION_FETCHING_DATA_RESET";
const UPDATE_ORGANIZATION_FETCHING_DATA_SUCCESS = "UPDATE_ORGANIZATION_FETCHING_DATA_SUCCESS";
const UPDATE_ORGANIZATION_FETCHING_DATA_ATTEMPT = "UPDATED_ORGANIZATION_FETCHING_DATA_ATTEMPT";
const updateOrg = (payload) => ({
  type: UPDATE_ORGANIZATION_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const updateOrgSuccess = () => ({
  type: UPDATE_ORGANIZATION_FETCHING_DATA_SUCCESS,
});
const updateOrgReset = () => ({
  type: UPDATE_ORGANIZATION_FETCHING_DATA_RESET,
});

export {
  UPDATE_ORGANIZATION_FETCHING_DATA,
  UPDATE_ORGANIZATION_FETCHING_DATA_FAILURE,
  UPDATE_ORGANIZATION_FETCHING_DATA_RESET,
  UPDATE_ORGANIZATION_FETCHING_DATA_SUCCESS,
  UPDATE_ORGANIZATION_FETCHING_DATA_ATTEMPT,
  updateOrg,
  updateOrgSuccess,
  updateOrgReset,
};

//sponsor

const UPDATE_SPONSOR_FETCHING_DATA = "UPDATE_SPONSOR_FETCHING_DATA";
const UPDATE_SPONSOR_FETCHING_DATA_FAILURE = "UPDATE_SPONSOR_FETCHING_DATA_FAILURE";
const UPDATE_SPONSOR_FETCHING_DATA_RESET = "UPDATE_SPONSOR_FETCHING_DATA_RESET";
const UPDATE_SPONSOR_FETCHING_DATA_SUCCESS = "UPDATE_SPONSOR_FETCHING_DATA_SUCCESS";
const UPDATE_SPONSOR_FETCHING_DATA_ATTEMPT = "UPDATED_SPONSOR_FETCHING_DATA_ATTEMPT";
const updateSponsor = (payload) => ({
  type: UPDATE_SPONSOR_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const updateSponsorSuccess = () => ({
  type: UPDATE_SPONSOR_FETCHING_DATA_SUCCESS,
});
const updateSponsorReset = () => ({
  type: UPDATE_SPONSOR_FETCHING_DATA_RESET,
});

export {
  UPDATE_SPONSOR_FETCHING_DATA,
  UPDATE_SPONSOR_FETCHING_DATA_FAILURE,
  UPDATE_SPONSOR_FETCHING_DATA_RESET,
  UPDATE_SPONSOR_FETCHING_DATA_SUCCESS,
  UPDATE_SPONSOR_FETCHING_DATA_ATTEMPT,
  updateSponsor,
  updateSponsorSuccess,
  updateSponsorReset,
};

//reward

const UPDATE_REWARD_FETCHING_DATA = "UPDATE_REWARD_FETCHING_DATA";
const UPDATE_REWARD_FETCHING_DATA_FAILURE = "UPDATE_REWARD_FETCHING_DATA_FAILURE";
const UPDATE_REWARD_FETCHING_DATA_RESET = "UPDATE_REWARD_FETCHING_DATA_RESET";
const UPDATE_REWARD_FETCHING_DATA_SUCCESS = "UPDATE_REWARD_FETCHING_DATA_SUCCESS";
const UPDATE_REWARD_FETCHING_DATA_ATTEMPT = "UPDATED_REWARD_FETCHING_DATA_ATTEMPT";
const updateReward = (payload) => ({
  type: UPDATE_REWARD_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const updateRewardSuccess = () => ({
  type: UPDATE_REWARD_FETCHING_DATA_SUCCESS,
});
const updateRewardReset = () => ({
  type: UPDATE_REWARD_FETCHING_DATA_RESET,
});

export {
  UPDATE_REWARD_FETCHING_DATA,
  UPDATE_REWARD_FETCHING_DATA_FAILURE,
  UPDATE_REWARD_FETCHING_DATA_RESET,
  UPDATE_REWARD_FETCHING_DATA_SUCCESS,
  UPDATE_REWARD_FETCHING_DATA_ATTEMPT,
  updateReward,
  updateRewardSuccess,
  updateRewardReset,
};


//KT Customer
const UPDATE_KTCUSTOMER_FETCHING_DATA = "UPDATE_KTCUSTOMER_FETCHING_DATA";
const UPDATE_KTCUSTOMER_FETCHING_DATA_FAILURE = "UPDATE_KTCUSTOMER_FETCHING_DATA_FAILURE";
const UPDATE_KTCUSTOMER_FETCHING_DATA_RESET = "UPDATE_KTCUSTOMER_FETCHING_DATA_RESET";
const UPDATE_KTCUSTOMER_FETCHING_DATA_SUCCESS = "UPDATE_KTCUSTOMER_FETCHING_DATA_SUCCESS";
const UPDATE_KTCUSTOMER_FETCHING_DATA_ATTEMPT = "UPDATE_KTCUSTOMER_FETCHING_DATA_ATTEMPT";
const updateKTCustomer = (payload) => ({
  type: UPDATE_KTCUSTOMER_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const updateKTCustomerSuccess = () => ({
  type: UPDATE_KTCUSTOMER_FETCHING_DATA_SUCCESS,
});
const updateKTCustomerReset = () => ({
  type: UPDATE_KTCUSTOMER_FETCHING_DATA_RESET,
});

export {
  UPDATE_KTCUSTOMER_FETCHING_DATA,
  UPDATE_KTCUSTOMER_FETCHING_DATA_FAILURE,
  UPDATE_KTCUSTOMER_FETCHING_DATA_RESET,
  UPDATE_KTCUSTOMER_FETCHING_DATA_SUCCESS,
  UPDATE_KTCUSTOMER_FETCHING_DATA_ATTEMPT,
  updateKTCustomer,
  updateKTCustomerSuccess,
  updateKTCustomerReset,
};


//KT Customer
const UPDATE_KTCUSTOMERTRN_FETCHING_DATA = "UPDATE_KTCUSTOMERTRN_FETCHING_DATA";
const UPDATE_KTCUSTOMERTRN_FETCHING_DATA_FAILURE = "UPDATE_KTCUSTOMERTRN_FETCHING_DATA_FAILURE";
const UPDATE_KTCUSTOMERTRN_FETCHING_DATA_RESET = "UPDATE_KTCUSTOMERTRN_FETCHING_DATA_RESET";
const UPDATE_KTCUSTOMERTRN_FETCHING_DATA_SUCCESS = "UPDATE_KTCUSTOMERTRN_FETCHING_DATA_SUCCESS";
const UPDATE_KTCUSTOMERTRN_FETCHING_DATA_ATTEMPT = "UPDATE_KTCUSTOMERTRN_FETCHING_DATA_ATTEMPT";
const updateKTCustomerTRN = (payload) => ({
  type: UPDATE_KTCUSTOMERTRN_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const updateKTCustomerTRNSuccess = () => ({
  type: UPDATE_KTCUSTOMERTRN_FETCHING_DATA_SUCCESS,
});
const updateKTCustomerTRNReset = () => ({
  type: UPDATE_KTCUSTOMERTRN_FETCHING_DATA_RESET,
});

export {
  UPDATE_KTCUSTOMERTRN_FETCHING_DATA,
  UPDATE_KTCUSTOMERTRN_FETCHING_DATA_FAILURE,
  UPDATE_KTCUSTOMERTRN_FETCHING_DATA_RESET,
  UPDATE_KTCUSTOMERTRN_FETCHING_DATA_SUCCESS,
  UPDATE_KTCUSTOMERTRN_FETCHING_DATA_ATTEMPT,
  updateKTCustomerTRN,
  updateKTCustomerTRNSuccess,
  updateKTCustomerTRNReset,
};