import React from "react";
import {
  Avatar,
  Button,
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import { VideoCard } from "./VideoCard";
import { VideoCard4Sides } from "./VideoCard4Sides";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { CheckCircle } from "@mui/icons-material";
import ResponsiveDrawer from "./Header";
import { CopyRight } from "./CopyRight";
import TopHeaderScreen from "./TopHeader1";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Chip, makeStyles } from "@material-ui/core";
import { VideoDetailsTableComponent } from "../components/Table";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    padding: "90px 0 0 20px",
    [theme.breakpoints.up("sm")]: {
      padding: "90px 0 0 220px",
    },
  },
  container: {
    padding: "0 20px 50px 20px",
    margin: "10px 0",
  },
  pageSubheading: {
    padding: 10,
    fontSize: 14,
    background: "#E9E7E7",
    display: "block",
    color: "#000",
    // marginBottom: 14,
    fontWeight: "500",
  },
  pageHeading: {
    textTransform: "uppercase",
    // fontSize: 14,
    background: theme.palette.primary.main,
    textAlign: "center",
    color: "#fff",
    padding: 3,
    fontWeight: "bold",
    // borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  // pageHeadingIcon: {
  //   fontSize: 25,
  //   // color: theme.palette.secondary.main,
  //   marginLeft: 8,
  // },
  backBtn: {
    display: "flex",
    alignItems: "center",
  },

  progressBarWrap: {
    // paddingTop: 28,
    paddingTop: 18,
    position: "relative",
  },
  progressBarLabel: {
    position: "absolute",
    bottom: 65,
    right: 0,
    fontWeight: "bold",
    // color: "#DC8E10",
    fontSize: 16,
  },
  progressBarCompleteLabel: {
    backgroundColor: "rgba(255,255,255,0)",
    position: "absolute",
    bottom: 30,
    right: 0,
    zIndex: 0.5,
    fontWeight: "bold",
    // color: "#fff",
    // color: "#FFA500"
  },
  progressBarCaption: {
    fontSize: 15,
    // fontWeight: "bold",
    display: "block",
    textAlign: "center",
    color: theme.palette.secondary.main,
    // color: "rgba(255,0,0,0)",
    color: "rgba(0,0,0,0.9)",
    paddingTop: 12,
  },
  goalsWrapper: {
    backgroundColor: "rgba(255,255,255,0.9)",
    borderRadius: 10,
    padding: 16,
    marginTop: 20,
    // boxShadow: "1px 3px 10px rgba(0,0,0,0.3)",
    boxShadow: "0.5px 0.5px 5px rgba(0,0,0,0.3)",
  },
  goalsWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 0px 20px 0px",
  },
  goalsHeading: {
    fontSize: 14,
    // color: theme.palette.secondary.main,
    // color: "rgba(0,0,0,0.9)",
    color: "rgba(4,59,92,1)",
    textTransform: "uppercase",
    fontWeight: "bold",
    marginRight: 10,
    display: "block",
  },
  goal: {
    fontSize: 14,
    display: "block",
    textAlign: "center",
  },
  bottomBorder: {},
  timeWrap: {
    justifyContent: "center",
    display: "flex",
    maxWidth: "100%",
  },
  leftBorder: {
    // justifyContent: "left",
    // color: theme.palette.secondary.main,
    color: "rgba(255,147,100,1)",
    fontSize: 18,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginRight: 4,
    // marginLeft: 260,
  },
  leftBorderWithBlack: {
    justifyContent: "left",
    // color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    // marginRight: 4,
    // marginLeft: 240,
  },
  leftBorderWithBlackFixed: {
    justifyContent: "left",
    // color: theme.palette.secondary.main,
    color: "rgba(255,147,100,1)",
    fontSize: 18,
    // fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    // marginRight: 4,
    // marginLeft: 230,
  },
  timeBox: {
    // background: theme.palette.primary.main,
    // border: "1px solid #fff",
    color: theme.palette.secondary.main,
    fontSize: 15,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    marginRight: 4,

    // width: "25%",
  },
  timeBoxLabel: {
    fontSize: 12,
    display: "block",
    textAlign: "center",
    fontWeight: 400,
  },
  goalsRewardWrap: {
    padding: "5px 0",
    maxWidth: 500,
    margin: "0 auto",
  },
  goalsRewardHeading: {
    fontWeight: "bold",
    display: "block",
    // textAlign: "center",
    textTransform: "uppercase",
  },
  rewardRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // padding: "4px 0",
    fontSize: 14,
  },
  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginRight: 10,
  },
  myRewards: {
    maxWidth: 500,
    margin: "0 auto",
    padding: "10px 0",
  },
  myRewardsHeading: {
    fontSize: 14,
    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 17,
    color: "rgba(4,59,92,1)",
  },
  myRewardsSubheading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    color: "rgba(4,59,92,1)",
    padding: "15px 0",
  },
  myRewardRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 16,
    padding: "5px 0",
    fontWeight: 500,
  },
  myRewardRowDesc: {
    fontSize: 18,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  plusIcon: {
    fontSize: 21,
    color: "#B6B6BA",
  },
  plusIconleft: {
    marginRight: 10,
  },
  plusIconRight: {
    marginLeft: 10,
  },
  myRewardRowCash: {
    display: "flex",
    alignItems: "center",
    flexShrink: 0,
    marginRight: 15,
  },
  myRewardRowPoints: {
    display: "flex",
    alignItems: "center",
    flexShrink: 0,
    marginLeft: 15,
  },
  myRewardRowvalue: {
    display: "block",
    position: "relative",
  },
  rewards: {
    paddingTop: 30,
  },
  totalRewards: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // color: theme.palette.secondary.main,
    // color: theme.palette.primary.main,
    color: "red",
    fontSize: 25,
    fontWeight: "bold",
    padding: "11px 0",
  },
  myRewardsWrap: {
    borderBottom: "1px solid #F8B34C",
  },
  myImpacts: {
    borderBottom: "2px solid #F8B34C",
    // borderBottom:"100%",
    padding: "25px 0",
    fontSize: 14,
    textAlign: "center",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  myImpactsWrap: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 -8px",
  },
  myImpact: {
    width: "calc(33.33% - 16px)",
    margin: "0 8px",
  },
  myImpactImgWrap: {
    width: 52,
    height: 50,
    margin: "0 auto 5px",
  },
  myImpactImg: {
    width: "100%",
  },
  myImpactTxt: {
    display: "block",
    fontSize: 12,
  },
  myCommunity: {
    borderBottom: "2px solid #F8B34C",
    padding: "15px 0",
    fontSize: 20,
    color: "#F8B34C",
  },
  myCommunityContentWrap: {
    display: "flex",
    justifyContent: "space-between",
  },
  myCommunityImg: {
    width: 100,
  },
  myCommunityPoints: {
    listStyle: "none",
    margin: "0 0 0 10px",
    padding: 0,
    color: "black",
  },
  inviteButton: {
    fontSize: 9,
    fontWeight: "bold",
    lineHeight: 1,
  },
  maxWidth500: {
    maxWidth: 500,
    margin: "0 auto",
    // fontSize: 14,
  },
  verifyButton: {
    fontSize: 12,
    fontWeight: "bold",
    maxWidth: 120,
    lineHeight: 1.2,
    marginRight: 10,
    marginTop: 8,
    top: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
      top: 20,
    },
  },
  fieldWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "50%",
    margin: 20,
    height: 20,
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  text: {
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  textBox: {
    fontSize: 12,
    height: 30,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  affiliation: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    height: 30,
  },
  affiliationText: {
    fontSize: 16,
    // bottom: 40,
    height: 30,
    width: "180px",
    fontSize: 12,
    // width: "150px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      width: "150px",
    },
  },
  validationBox: {
    width: 500,
    height: 200,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      width: "320px",
    },
  },
  button: {
    backgroundColor: "#2D7A74",
    borderRadius: 10,
    fontSize: 14,
    color: "#fff",
    margin: "8px 4px",
    width: "160px",
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    padding: 8,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  cursordefault: {
    backgroundColor: "#2D7A74",
    borderRadius: 10,
    fontSize: 14,
    color: "#fff",
    margin: "8px 4px",
    width: "200px",
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    padding: 8,
    textTransform: "uppercase",
    // "&:hover": {
    //   backgroundColor: "green",
    // },
    cursor: "default",
    "&:hover": {
      backgroundColor: "gray",
    },
  },

  buttonWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  shareWrapper: {
    backgroundColor: "rgba(45, 122, 116, 0.3)",
    borderRadius: 10,
    padding: 10,

    //The share button will be disabled for 30 Sep release
    cursor: "default",
    // "&:hover": {
    //   backgroundColor: "gray",
    // },
  },
  progressdef: {
    flexGrow: 1,
  },
  bannerImage: {
    backgroundSize: "100% 100%",
  },
  barroot: {
    backgroundColor: "#21466",
  },
  boxlike: {
    // width: "fit-content",
    width: "200px",
    display: "flex",
    flexDirection: "column",
    // maxWidth: "1000px",
    // height: "fit-content",
    border: "1px solid rgb(134, 133, 133)",
    // marginTop: "10px",
    // marginLeft: "500px",
    background: "white",
    // textAlign: "center",
    borderRadius: "10px",
    borderColor: "blue",
    alignItems: "center",
    justifyContent: "center",
    // padding: 10,
    // fontSize: 16,
    margin: "10px auto",
  },
  a1: {
    animation: "blinking 2s infinite",
    fontSize: 25,
  },
  pointBox: {
    // top: "22px",
    // left: "200px",
    // top: "22%",
    // borderBottom: "2px solid #F8B34C",
    minWidth: "55px",
    height: "39px",
    background: "#1A524D 0% 0% no-repeat padding-box",
    borderRadius: "100px",
    opacity: "90%",
    color: "#FFFFFF",
    fontSize: 15,
    fontWeight: "bold",
    position: "relative",
    padding: 7,
    // paddingRight: 25,
    // padding: "15px 0",
    // color: "black",
    // display: "flex",
  },
  RefCodeWrapper: {
    padding: 10,
    backgroundColor: "#E8E8E8",
    // margin: 4,
    margin: "10px auto",
    borderRadius: 4,
    display: "flex",
    // display: "inline-flex",
    // display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "center",
    width: "226px",
  },
  input: {
    width: "125px",
    textAlign: "center",
  },
}));

const VideoDetails = (props) => {
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [campregisterlist, setCampRegisterList] = useState([]);
  const [campWithFilesList, setCampWithFilesList] = useState([]);



  const getCampRegistrationList = async () => {
    try {
      // const res = await axios.get(
      //   `/camp/video/${selectedCategory}/${localStorage.getItem("userid")}`
      // );
      const res = await axios.get(
        `/list/files/campaign/${location?.pathname.split("/")?.[2]}/${
          location?.pathname.split("/")?.[3]
        }/${location?.pathname.split("/")?.[4]}`
      );
      setCampRegisterList(res?.data);
      // JSON.stringify(res)
     
      return res;
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    getCampRegistrationList();
  }, [location?.pathname.split("/")?.[2]]);


  const getCampFileList = async () => {
    try {
      // const res = await axios.get(
      //   `/camp/video/${selectedCategory}/${localStorage.getItem("userid")}`
      // );
      const res = await axios.get(
        `/list/files/campaignwithid/${
          location?.pathname.split("/")?.[3]
        }/${localStorage.getItem("userid")}`
      );
      setCampWithFilesList(res?.data);
      // JSON.stringify(res)
    
      return res;
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    getCampFileList();
  }, []);


  const navigate = useNavigate();
  const locationPath = useLocation();

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
 

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <Grid container>
              {/* <Grid item xs={12} sm={8} md={6} lg={3} xl={3}> */}
              {/* <Grid item mx={5} my={5}> */}
              <Grid item mx={2} my={2} className="playerWrapper">
                <Box>
                  <ReactPlayer
                    url={campregisterlist?.fileurl}
                    className="react-player"
                    controls
                  />
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <VideoDetailsTableComponent rows={campregisterlist} />
                </Box>
              </Grid>
              <Box
                sx={{
                  height: { sx: "auto", md: "92vh" },
                  borderRight: "1px solid #FFDEAD",
                  px: { sx: 0, md: 2 },
                }}
              ></Box>
              <Grid item mx={1} my={1} overflowY="auto">
                <Box sx={{ textAlign: "center" }}>
                  <VideoCard4Sides video={campWithFilesList} />
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </section>
    </div>
  );
};

export default VideoDetails;
