/** @format */

// reducers
import {
    VALIDATOR_LIST_FETCHING_DATA,
    VALIDATOR_LIST_FETCHING_DATA_FAILURE,
    VALIDATOR_LIST_FETCHING_DATA_RESET,
    VALIDATOR_LIST_FETCHING_DATA_SUCCESS,
  } from "../action/ValidatorList";
  
  let initialState = {
    response: {},
    isSuccess: false,
    isError: false,
    isFetching: false,
  };
  
  export default function ValidatorListReducer(state = initialState, action) {
    switch (action.type) {
      case VALIDATOR_LIST_FETCHING_DATA:
        return {
          ...state,
          isFetching: true,
          isError: false,
          isSuccess: false,
        };
      case VALIDATOR_LIST_FETCHING_DATA_FAILURE:
        return {
          ...state,
          isFetching: false,
          isError: action.payload,
          isSuccess: false,
        };
      case VALIDATOR_LIST_FETCHING_DATA_SUCCESS:
        return {
          ...state,
          isFetching: false,
          isError: false,
          response: action.payload,
          isSuccess: true,
        };
  
      case VALIDATOR_LIST_FETCHING_DATA_RESET:
        return initialState;
  
      default:
        return state;
    }
  }
  