/** @format */

import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
// import Logo from "../../assets/logo-black.png";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

// import "../scss/containerStyles/_home.scss";
// import "../App.css";

import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ResponsiveDrawer from "../../components/Header";

import { CopyRight } from "../../components/CopyRight";
// import ExecHeader from "../components/ExecHeader";
import { ValidateUserOnboarding } from "../../components/ValidateUserOnboarding";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { validatorList } from "../../redux/action/ValidatorList";
// import { purchaseYaagReset } from "../redux/action/PurchaseYaag";
import { Checkbox } from "@material-ui/core";
import { updateUser } from "../../redux/action/UpdateUserAction";
import { userProfile } from "../../redux/action/UserProfileActions";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useNavigate } from "react-router-dom";
import {  Button } from "@mui/material";
import { userreviewlist } from "../../redux/action/UserOnboardReviewAction";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";

const useStyles = makeStyles((theme) => ({
  homeSreenManiContent: {
    // padding: "45px 0 0 20px",
    [theme.breakpoints.up("sm")]: {
      // padding: "45px 0 0 0px",
    },
  },
  homeScreenContainer: {
    padding: "0 20px",
  },
  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginRight: 10,
    color: "#fff",
  },
  table: {
    width: "100%",
  },
  backBtn: {
    display: "flex",
    color: "rgb(25, 118, 210)",
    
    // alignItems: "center",
    // [theme.breakpoints.up("xl")]: {
    //   padding: "160px 0 0 260px",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   padding: "160px 0 0 260px",
    // },
    // [theme.breakpoints.up("md")]: {
    //   padding: "120px 0 0 180px",
    // },
    // [theme.breakpoints.up("sm")]: {
    //   padding: "82px 0 0 130px",
    // },
    // [theme.breakpoints.up("xs")]: {
    //   padding: "82px 0 0 0px",
    // },
    [theme.breakpoints.only("xl")]: {
      // padding: "5%"
      width: "100%"
    },
    [theme.breakpoints.only("lg")]: {
      // padding: "5%"
      width: "100%"
    },
    [theme.breakpoints.only("md")]: {
      // padding: "10%"
      width: "100%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      padding: "0px 0 0 10px"
      // width: "10%"
    },
  },
  

}));




const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8B34C",
    color: "#ffffff",
    textTransform: "uppercase",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    // '&:nth-of-type(odd)': {
    // 	backgroundColor: '#f7dbb3',
    // },
    // '&:nth-of-type(even)': {
    // 	backgroundColor: '#e9e7e8',
    // },
  },
}))(TableRow);

function createDataValidator(
  id,
  firstname,
  lastname,
  phoneno,
  organization,
  orgrole,
  onboardstatus,
  
) {
  return {
    id,
    firstname,
    lastname,
    phoneno,
    organization,
    orgrole,
    onboardstatus,
    
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  // console.log(props)
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
  // console.log(props)
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ReviewUserOnboarding = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [welcomeOpen, setWelcomeOpen] = React.useState(true);
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const userid = localStorage.getItem("userid");
  const [value1, setValue1] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [reviewStatus, setReviewStatus] = React.useState("");
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [checked, setChecked] = useState(false);
  // const [campaignList, setCampaignList] = useState(
  //   [
  //     'firstname',
  //     'lastname',
  //     'phoneno',
  //     'organization',
  //     'orgrole',
  //     'onboardstatus',
  //   ]
  // );
  const { isSuccess: userreviewlistSuccess, response: userreviewlistResponse } = useSelector(
    (state) => state.userreviewlist
  );
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
 
 

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(userreviewlist());
    // dispatch(validatorList(newValue));
  };

  const handleConfirmWelcome = () => {
    localStorage.setItem("firstTime", true);
    setWelcomeOpen(false);
    if (
      profileSuccess &&
      (profileResponse[0]?.legal === "no" ||
        profileResponse[0]?.legal === undefined)
    )
      dispatch(
        // updateUser({
        //   fullname: profileSuccess && profileResponse[0]?.fullname,
        //   email: profileSuccess && profileResponse[0]?.email,
        //   affiliate: profileSuccess && profileResponse[0]?.affiliate,
        //   userid: profileSuccess && profileResponse[0]?.userid,
        //   role: "validator",
        //   joiningbonus: 50,
        //   legal: "yes",
        // })
      );
  };
  useEffect(() => {
    dispatch(userreviewlist(profileResponse));
    dispatch(userProfile(userid)); 
  }, []);
  // useEffect(() => {
  //   if (
  //     profileSuccess &&
  //     (profileResponse[0]?.legal === "no" ||
  //       profileResponse[0]?.legal === undefined)
  //   ) {
  //     setWelcomeOpen(true);
  //   } else {
  //     setWelcomeOpen(false);
  //   }
  // }, [profileResponse]);

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < userreviewlistResponse.length; i += 1) {
      const formattedArray = [];
      formattedArray.push(
        // userreviewlistResponse[i].phoneno,
        userreviewlistResponse[i].firstname,
        userreviewlistResponse[i].lastname,
        userreviewlistResponse[i].phoneno,
        userreviewlistResponse[i].organization,
        userreviewlistResponse[i].orgrole,
        userreviewlistResponse[i].onboardstatus,
      );

      temp.push(createDataValidator(i, ...formattedArray));
    }
    setRows(temp);
  }, [userreviewlistResponse]);

  useEffect(() => {
    const firstTime = localStorage.getItem("firstTime");
    if (firstTime) {
      setWelcomeOpen(true);
    }
  }, []);

  
  const reviewedData = rows.filter((item) => item.onboardstatus === "submitted");
  const acceptedData = rows.filter((item) => item.onboardstatus === "approved");
  const rejectedData = rows.filter((item) => item.onboardstatus === "rejected");

  const sortedDataReview =
    inputValue === ""
      ? ""
      : reviewedData.filter((item) => item.firstname === inputValue);

  const sortedDataApprove =
    inputValue === ""
      ? ""
      : acceptedData.filter((item) => item.firstname === inputValue);

  const sortedDataRejected =
    inputValue === ""
      ? ""
      : rejectedData.filter((item) => item.firstname === inputValue);

  return (
    <div>
      <ResponsiveDrawer {...props} />
      <section className="containers">
      <TopHeaderScreen {...props} />
      <div class="content content-wrap">
          <div class="content-mrg">
          <Button
                onClick={() => navigate(-1)}
                className={classes.backBtn}
                style={{justifyContent:"left"}}
            >
                <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
      <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Review User Onboarding</div>
      <main className={classes.homeSreenManiContent} id="exec" style={{ marginLeft: "400" }} >
        <Tabs
          style={{ display: "flex", justifyContent: "center", marginRight: "100" }}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Submitted" />
          <Tab label="Accepted" />
          <Tab label="Rejected" />
        </Tabs>
        <TabPanel value={value} index={0} >
          <ValidateUserOnboarding
            value1={value1}
            setValue1={setValue1}
            inputValue={inputValue}
            setInputValue={setInputValue}
            rows={reviewedData}
            sortedData={sortedDataReview}
            actionTaken={false}
            setReviewStatus={setReviewStatus}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ValidateUserOnboarding
            value1={value1}
            setValue1={setValue1}
            inputValue={inputValue}
            setInputValue={setInputValue}
            rows={acceptedData}
            sortedData={sortedDataApprove}
            actionTaken
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ValidateUserOnboarding
            value1={value1}
            setValue1={setValue1}
            inputValue={inputValue}
            setInputValue={setInputValue}
            rows={rejectedData}
            sortedData={sortedDataRejected}
            actionTaken
          />
        </TabPanel>
      </main>
      </div>
      </div>
      <CopyRight />
      <FixedFooterIcon />
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({}, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewUserOnboarding);
