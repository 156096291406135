/** @format */

import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
// import Logo from "../../assets/logo-black.png";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Select from 'react-select';
// import "../scss/containerStyles/_home.scss";
// import "../App.css";

import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ResponsiveDrawer from "../../components/Header";

import { CopyRight } from "../../components/CopyRight";
// import ExecHeader from "../components/ExecHeader";
import { ValidatorTable } from "../../components/validatorTable1";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { validatorList } from "../../redux/action/ValidatorList";
// import { purchaseYaagReset } from "../redux/action/PurchaseYaag";
import { Checkbox } from "@material-ui/core";
import { updateUser } from "../../redux/action/UpdateUserAction";
import { userProfile } from "../../redux/action/UserProfileActions";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import { purchaseYaag } from "../../redux/action/PurchaseYaag";
import { validateReward } from "../../redux/action/ValidatorAction";
import moment from "moment";
import QRScanner from "../../components/QRScanner";
import CropFreeIcon from '@mui/icons-material/CropFree';
import axios from "axios";


const useStyles = makeStyles((theme) => ({
  homeSreenManiContent: {
    // padding: "45px 0 0 20px",
    [theme.breakpoints.up("sm")]: {
      // padding: "45px 0 0 0px",
    },
  },
  homeScreenContainer: {
    padding: "0 20px",
  },
  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginRight: 10,
    color: "#fff",
  },
  table: {
    width: "100%",
  },
  backBtn: {
    display: "flex",
    color: "rgb(25, 118, 210)",

    [theme.breakpoints.only("xl")]: {
      // padding: "5%"
      width: "100%"
    },
    [theme.breakpoints.only("lg")]: {
      // padding: "5%"
      width: "100%"
    },
    [theme.breakpoints.only("md")]: {
      // padding: "10%"
      width: "100%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      padding: "0px 0 0 10px"
      // width: "10%"
    },
  },


}));


const data = [
  {
    id: 0,
    userid: 9779860513158,
    fullname: "Rajib",
    affiliaton: "Tribhuwan University",
    yaagName: "Bentech",
  },
  {
    id: 1,
    userid: 9779865479912,
    fullname: "Gautam",
    affiliaton: "Banglore University",
    yaagName: "Flora",
  },
  {
    id: 2,
    userid: 9779823561856,
    fullname: "Kiran",
    affiliaton: "IIT",
    yaagName: "Poorti",
  },
  {
    id: 0,
    userid: 9779860513158,
    fullname: "Rajib",
    affiliaton: "Tribhuwan University",
    yaagName: "Bentech",
  },
  {
    id: 1,
    userid: 9779865479912,
    fullname: "Gautam",
    affiliaton: "Banglore University",
    yaagName: "Flora",
  },
  {
    id: 2,
    userid: 9779823561856,
    fullname: "Kiran",
    affiliaton: "IIT",
    yaagName: "Poorti",
  },
  {
    id: 0,
    userid: 9779860513158,
    fullname: "Rajib",
    affiliaton: "Tribhuwan University",
    yaagName: "Bentech",
  },
  {
    id: 1,
    userid: 9779865479912,
    fullname: "Gautam",
    affiliaton: "Banglore University",
    yaagName: "Flora",
  },
  {
    id: 2,
    userid: 9779823561856,
    fullname: "Kiran",
    affiliaton: "IIT",
    yaagName: "Poorti",
  },
];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8B34C",
    color: "#ffffff",
    textTransform: "uppercase",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    // '&:nth-of-type(odd)': {
    // 	backgroundColor: '#f7dbb3',
    // },
    // '&:nth-of-type(even)': {
    // 	backgroundColor: '#e9e7e8',
    // },
  },
}))(TableRow);

function createDataValidator(
  id,
  formcampaignname,
  // formuserfullname,
  formuserfirstname,
  formuserlastname,
  formuserphone,
  campid,
  registrationstatus,
  registerdate,
  registrationid,
  rewarddate,
  documentuploadcount,
) {
  return {
    id,
    formcampaignname,
    // formuserfullname,
    formuserfirstname,
    formuserlastname,
    formuserphone,
    campid,
    registrationstatus,
    registerdate,
    registrationid,
    rewarddate,
    documentuploadcount,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  // console.log(props)
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
  // console.log(props)
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ReviewCampaignRegistrationScreen = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [welcomeOpen, setWelcomeOpen] = React.useState(true);
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const userid = localStorage.getItem("userid");
  const [value1, setValue1] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [reviewStatus, setReviewStatus] = React.useState("");
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [checked, setChecked] = useState(false);
  const [campaignList, setCampaignList] = useState(
    [
      'campaignname',
      'formuserfirstname',
      'formuserfirstname',
      'formuserphoneno',
      'registrationstatus',
      'rewarddate'
    ]
  );
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const theme = useTheme();
  const tableRef = useRef(null);
  // const validatorListResponse = useSelector((state) => state.validatorList);
  const { isSuccess: validatorListSuccess, response: validatorListResponse } = useSelector(
    (state) => state.validatorList
  );

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  // console.log(profileResponse?.phoneno)
  const handleAcceptClick = (item) => {
    // console.log(item._id, "item");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(validatorList());
    // dispatch(validatorList(newValue));
  };

  const handleConfirmWelcome = () => {
    localStorage.setItem("firstTime", true);
    setWelcomeOpen(false);
    if (
      profileSuccess &&
      (profileResponse[0]?.legal === "no" ||
        profileResponse[0]?.legal === undefined)
    )
      dispatch(
        // updateUser({
        //   fullname: profileSuccess && profileResponse[0]?.fullname,
        //   email: profileSuccess && profileResponse[0]?.email,
        //   affiliate: profileSuccess && profileResponse[0]?.affiliate,
        //   userid: profileSuccess && profileResponse[0]?.userid,
        //   role: "validator",
        //   joiningbonus: 50,
        //   legal: "yes",
        // })
      );
  };
  useEffect(() => {

    // console.log("start 2222")    
    // validationList1();
    dispatch(validatorList(profileResponse));
    dispatch(userProfile(userid));
    // validatorList
    // console.log(campaignList);
    // console.log("end 2222")   

  }, [campaignList]);
  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    // dispatch(validatorList());
    dispatch(validatorList(profileResponse));
    dispatch(userProfile(userid));
  }, []);

  useEffect(() => {
    if (
      profileSuccess &&
      (profileResponse[0]?.legal === "no" ||
        profileResponse[0]?.legal === undefined)
    ) {
      setWelcomeOpen(true);
    } else {
      setWelcomeOpen(false);
    }
  }, [profileResponse]);

  useEffect(() => {
    // console.log("useeffect")
    let temp = [];
    for (let i = 0; i < validatorListResponse.length; i += 1) {
      const formattedArray = [];
      // console.log(validatorListResponse[i])
      formattedArray.push(
        // validatorListResponse[i]._id,
        validatorListResponse[i].formcampaignname,
        // validatorListResponse[i].formuserfullname,
        validatorListResponse[i].formuserfirstname,
        validatorListResponse[i].formuserlastname,
        validatorListResponse[i].formuserphone,
        // validatorListResponse[i].affiliation,
        validatorListResponse[i].campid,
        validatorListResponse[i].registrationstatus,
        validatorListResponse[i].registerdate,
        validatorListResponse[i].registrationid,
        // validatorListResponse[i].formuserlastname,
        validatorListResponse[i].rewarddate,
        validatorListResponse[i].documentuploadcount,


      );
      console.log(formattedArray);
      temp.push(createDataValidator(i, ...formattedArray));
      console.log("temp");
      console.log(temp);
    }
    setRows(temp);
  }, [validatorListResponse]);

  useEffect(() => {
    setScannedMessage("");
    const firstTime = localStorage.getItem("firstTime");
    if (firstTime) {
      setWelcomeOpen(true);
    }
  }, []);

  // const validationList1 = async () => {
  //   try {
  //     // console.log(profileResponse)
  //     // if (profileSuccess) {
  //     // console.log(profileResponse?.orgrole)
  //     // console.log(profileResponse?.organization)
  //     // console.log()
  //     // const res = await axios.get(`/validator/list/${validatorListResponse[0]?.formuserphone}`);
  //     const res = await axios.get(`/validator/list/${profileResponse?.phoneno}`);

  //     setCampaignList(res?.data);
  //     // console.log(res)
  //     return res;
  //     // }
  //   } catch (err) {
  //     throw err;
  //   }
  // };

  // useEffect(() => {
  //   if (purchaseSuccess || validateSuccess) {
  //   //   dispatch(validatorList());
  //   //   dispatch(purchaseYaagReset());
  //   }
  // }, [purchaseSuccess, validateSuccess]);
  // console.log(rows)
  // console.log(value)
  const reviewedData = Array.isArray(rows) ?  rows.filter((item) => item.registrationstatus === "submitted"):[];
  const acceptedData = Array.isArray(rows) ? rows.filter((item) => item.registrationstatus === "approved"):[];
  const rejectedData = Array.isArray(rows) ? rows.filter((item) => item.registrationstatus === "rejected"):[];
  // console.log(item.reviewStatus)
  // console.log(item.submissionstatus)

  const sortedDataReview =
    inputValue === ""
      ? ""
      : reviewedData.filter((item) => item.formcampaignname === inputValue);

  const sortedDataApprove =
    inputValue === ""
      ? ""
      : acceptedData.filter((item) => item.formcampaignname === inputValue);

  const sortedDataRejected =
    inputValue === ""
      ? ""
      : rejectedData.filter((item) => item.formcampaignname === inputValue);

  console.log(rows);
  // console.log(sortedDataApprove)
  // console.log(sortedDataRejected)


  const validationList1 = async () => {
    try {
      const res = await axios.get(
        `/validator/list/${profileResponse?.phoneno}`
      );
      const vaa = await res?.data;
      //setCampaignList(oldau => [...oldau, vaa]);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const PopupWindow = ({ children }) => {
    const popupStyle = {
      width: '300px',
      height: '250px',
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      border: '1px solid #ccc',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
      backgroundColor: '#fff',
      zIndex: '1',
    };
    return <div style={popupStyle}>{children}</div>;
    // <div style={popupStyle}>
    //   {React.cloneElement(children, { onClose })}
    // </div>
  }


  const handleONEScanButtonClick = () => {
    setScannedMessage("");
    console.log("handleScanButtonClick")
    // setClickedItem(item);
    if (scannerVisible) {
      setScannerVisible(false);
    } else {
      setScannerVisible(true);
      setScannedMessage('')
    }

  };

  const [scannerVisible, setScannerVisible] = useState(false);
  // const [clickedItem, setClickedItem] = useState(null);
  const [scannedData, setScannedData] = useState('');
  const [postScan, setPostScan] = useState('');
  const [postScanDecrypt, setPostScanDecrypt] = useState([]);
  const [scanned, setScanned] = useState(false);
  const [scannedMessage, setScannedMessage] = useState('');

  const [options, setOptions] = useState([]);
  const [hideScanner, setHideScanner] = useState(true);

  useEffect(() => {
    setSelectedOption([]);
    campaignDropDownList();
  }, []);


  const closeScannerPopup = () => {
    console.log("closeScannerPopup called")
    // setClickedItem(null);
    setScannerVisible(false);
  };

  const handleScannedData = (data) => {
    console.log("ReviewRegistration - handleScannedData")
    setScannedMessage('');
    setScannedData(data);
    setScanned(true);
  };

 
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("calling decryptQRCode");
        const decryptedData = await decryptQRCode();
        console.log("called decryptQRCode = ", decryptedData)

        // if (decryptedData) {
        console.log("decrypt executed and now calling analyzeQRCode")
        // await analyzeQRCode();
        // } else {
        //   console.error("Error: No decrypted data");
        // }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    console.log("before scannerVisible = ", scannerVisible)
    console.log("before scanned = ", scanned)
    console.log("before postScanDecrypt.length = ", postScanDecrypt.length)
    if (scannerVisible && scanned) {
      fetchData();
    }
  }, [scannerVisible, scanned]);

  useEffect(() => {
    console.log("postScanDecrypt updated:", postScanDecrypt);
    analyzeQRCode();
  }, [postScanDecrypt]);



  const decryptQRCode = async () => {
    try {
      console.log("scannedData = ", scannedData);
      if (!(scannedData === "Error")) {
        console.log("scannedData[uniqueuserid] = ", scannedData["uniqueuserid"]);
        console.log("scannedData[campid] = ", scannedData["campid"]);
        console.log("scannedData[registrationid] = ", scannedData["registrationid"]);

        const resqr = await axios.get(
          `/decode/qr/${scannedData["uniqueuserid"]}/${scannedData["campid"]}/${scannedData["registrationid"]}`
        );

        console.log("decryptQRCode - API Response:", resqr?.data);
        setPostScanDecrypt(resqr?.data);
        console.log("postScanDecrypt after set:", postScanDecrypt);
        return resqr;

      } else {
        setScannedMessage("QR Code Invalid");
        setScanned(false);
      }


    } catch (err) {
      throw err;
    }
  };


  const analyzeQRCode = async () => {
    try {
      console.log("analyzeQRCode->postScanDecrypt = ", postScanDecrypt);
      console.log("analyzeQRCode->postScanDecrypt length = ", postScanDecrypt.length);
      if (postScanDecrypt) {
        console.log("postScanDecrypt[uniqueuserid] = ", postScanDecrypt["uniqueuserid"]);
        console.log("postScanDecrypt[campid] = ", postScanDecrypt["campid"]);
        console.log("postScanDecrypt[registrationid] = ", postScanDecrypt["registrationid"]);

        const res = await axios.get(
          `/analyze/qr/camp/${postScanDecrypt["uniqueuserid"]}/${postScanDecrypt["campid"]}/${postScanDecrypt["registrationid"]}`
        );
        setPostScan(res?.data);
        console.log("res.data = ", res?.data)
        console.log("res.data[message] = ", res?.data["message"])

        if (res?.data["message"] === "Approved") {
          Promise.all([
            dispatch(
              purchaseYaag({
                toberewardeddata: [
                  {
                    registrationid: postScanDecrypt["registrationid"],
                    campid: postScanDecrypt["campid"],
                    phoneno: scannedData["phoneno"],
                  },
                ],
              })
            ),
          ])
            .then(
              () =>
                dispatch(
                  validateReward({
                    registrationid: [
                      {
                        registrationid: postScanDecrypt["registrationid"],
                        campid: postScanDecrypt["campid"],
                        reviewcomments: "QR code scanned",
                        registrationstatus: "approved",
                        rewarddate: moment(
                          new Date()
                        ).format("DD-MM-yyyy"),
                      },
                    ],
                  })
                )
            )

            // .then(() => handleClick(clickedItem, "approved"))
            .then(() => dispatch(validatorList()));
          // .then(() => acceptRejectIndividual());

          setScannedMessage("Approved");
          console.log("scanned message Approve ====", scannedMessage);
        } else {
          setScannedMessage("Rejected")
          console.log("scanned message Reject ====", scannedMessage);
        }
        validationList1();

        console.log("API executed")

      } else if (!(scannedData === "Error")){
        setScannedMessage("Invalid Code")
      }
      else {
        setScannedMessage("Please Scan Again")
      }
      setScanned(false);
      // setPostScanDecrypt("");


      // return res;
    } catch (err) {
      console.log("Error in analyzeQRCode()")
      throw err;
    }
  };

  const [selectedOption, setSelectedOption] = useState(null);

  //Dropdown campaign list
  const campaignDropDownList = async () => {
    try {
      const res = await axios.get(
        `camp/list/qr/${profileResponse?.phoneno}`
      );
      console.log("====== qr======", res);
      const data1 = res.data;
      // const optionsArray =  data || [];
      if (Array.isArray(data1)) {
        // Transform data1 into optionsArray
        const optionsArray = data1.map((item) => ({
          value: item.campid,
          label: item.campname,
        }));

        setOptions(optionsArray);
      } else {
        console.error("Unexpected data format:", data1);
      }
    } catch (err) {
      throw err;
    }
  };

  const handleInvalidBarcode = () => {
    setScannedMessage("QR Code Invalid");
  };

  // Handle change in the selected option
  const handleChange1 = (selected) => {
    console.log("selectedValue=======", selected.value);
    if (selected.value !== "") {
      setHideScanner(false);
    }
    setSelectedOption(selected);
  };


  return (
    <div class="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button
              onClick={() => navigate(-1)}
              className={classes.backBtn}
              style={{ justifyContent: "left" }}
            >
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Review Registrations</div>
            <main className={classes.homeSreenManiContent} id="exec" style={{ marginLeft: "400" }} >

              <div>
                <div className="campaignDropDown" style={{ maxWidth: "300px", margin: "0 auto", marginBottom: "20px" }}>

                  <label>Select an option:</label>

                  <Select
                    value={selectedOption}
                    onChange={handleChange1}
                    options={options}
                    isSearchable={true} // Enable search functionality
                    placeholder="Select..."
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderRadius: 8,
                        borderColor: '#ccc',
                        boxShadow: 'none',
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? '#007bff' : 'white',
                        color: state.isSelected ? 'white' : 'black',
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: '#333',
                      }),
                    }}

                  />
                 
                </div>
                {!hideScanner && selectedOption.length !== 0 && (
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <span
                      className={classes.tbodyText}
                      onClick={() => {
                        handleONEScanButtonClick();
                      }}
                      style={{
                        textAlign: "center", display: "inline-block"
                      }}
                    >

                      <CropFreeIcon style={{ cursor: "pointer", display: "block", margin: "auto", height: "100px", width: "100px" }} />
                      <p>Click to Scan</p>
                      {scannerVisible && !hideScanner && (
                        <PopupWindow>
                          <QRScanner onClose={closeScannerPopup} onScan={handleScannedData} scanStatus={scannedMessage} selectedCampId={selectedOption} invalidBarcode={handleInvalidBarcode} />
                        </PopupWindow>
                      )}
                      
                    </span>
                  </div>
                )}
              </div>

              <div class="registrationreview-tab">
              <Tabs
                style={{ display: "flex", justifyContent: "center", marginRight: "100" }}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Submitted" />
                <Tab label="Approved" />
                <Tab label="Rejected" />
              </Tabs>
              <TabPanel value={value} index={0} >
                <ValidatorTable
                  value1={value1}
                  setValue1={setValue1}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  // response={response}
                  // isFetching={isFetching}
                  rows={reviewedData}
                  sortedData={sortedDataReview}
                  actionTaken={false}
                  setReviewStatus={setReviewStatus}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ValidatorTable
                  value1={value1}
                  setValue1={setValue1}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  // response={response}
                  // isFetching={isFetching}
                  rows={acceptedData}
                  sortedData={sortedDataApprove}
                  actionTaken
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <ValidatorTable
                  value1={value1}
                  setValue1={setValue1}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  // response={response}
                  // isFetching={isFetching}
                  rows={rejectedData}
                  sortedData={sortedDataRejected}
                  actionTaken
                />
              </TabPanel>
              </div>
            </main>

          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({}, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewCampaignRegistrationScreen);