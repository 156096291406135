/** @format */

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery, Avatar, Chip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Button, Stack } from "@mui/material";
import _ from "lodash";
import "../scss/containerStyles/_home.scss";
import "../App.css";
import "../cyaag-style.css";
import "../font-awesome.min.css";
import "../bootstrap.min.css";
import "../icon-font.css";
import "../flaticon-font.css";
import {
    userProfile,
    userProfileReset,
} from "../redux/action/UserProfileActions";
import { CopyRight } from "../components/CopyRight";
import { FixedFooterIcon } from "../components/FixedFooterIcon";
import { getVolunteerCount } from "../redux/action/volunteerCountAction";
import ResponsiveDrawer from "../components/Header";
import TopHeaderScreen from "../components/TopHeader1";
import axios from "axios";
import { Link, useNavigate, useLocation, useParams} from "react-router-dom";
import { getCampaign } from "../redux/action/CampaignAction";
import Card from "@material-ui/core/Card";
import { referral } from "../redux/action/ReferralAction";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const useStyles = makeStyles((theme) => ({
    homeSreenMainContent: {
        padding: "80px 0 100px 0px",
        display: "flex",
        flexDirection: "column",

        [theme.breakpoints.up("sm")]: {
            padding: "80px 0 100px 330px",
        },
    },
    homeScreenContainer: {
        // padding: "0 0 0 20px",

        paddingLeft: "10px",
        zIndex: 100,
    },
    welcomeMessage: {
        fontWeight: "bold",
        marginBottom: 10,
        margin: "10px 0",
        [theme.breakpoints.up("md")]: {
            marginBottom: 0,
        },
    },
    welcomeAndFilter: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        textTransform: "uppercase",
        padding: "10px 0",
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            alignItems: "center",
        },
    },
    filterIcon: {
        fontSize: 26,
        color: "#F8B34C",
        marginLeft: 28,
    },
    homeCards: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        margin: "0 -7px",
    },

    maxWidthContainer: {
        padding: "25px 0",
        maxWidth: 500,
        margin: "0 auto",
    },
    gradientHeading: {
        fontSize: 25,
        fontWeight: "bold",
    },
    rewards: {
        display: "flex",
        justifyContent: "center",
        color: "#F8B34C",
        paddingBottom: 13,
    },
    rewardsText: {
        fontSize: 25,
        fontWeight: "bold",
    },
    flexContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: 9,
    },
    secondaryBorder: {
        padding: "5px 0",
        borderBottom: "2px solid #F8B34C",
    },
    myImpacts: {
        padding: "15px 0 0 0",
        fontSize: 14,
        textAlign: "center",
        fontWeight: "bold",
        textTransform: "uppercase",
    },
    myImpactsWrap: {
        display: "flex",
        justifyContent: "space-between",
        margin: "0 -8px",
        outline: "none",
    },
    myImpact: {
        width: "150px",
        margin: "0 8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        outline: "0 !important",
        border: "none",
    },
    myImpactImgWrap: {
        width: 52,
        height: 50,
        margin: "0 auto 5px",
    },
    myImpactImg: {
        width: "100%",
    },
    myImpactTxt: {
        display: "block",
    },
    myCommunity: {
        borderBottom: "2px solid #F8B34C",
        padding: "15px 0",
        fontSize: 20,
        color: "#F8B34C",
    },
    myCommunityContentWrap: {
        display: "flex",
        justifyContent: "space-between",
    },
    myCommunityImg: {
        width: 100,
    },
    inviteBtn: {
        paddingTop: 15,
        width: 150,
    },
    myCommunityPoints: {
        listStyle: "none",
        margin: "0 0 0 10px",
        padding: 0,
    },
    inviteButton: {
        fontSize: 9,
        fontWeight: "bold",
        lineHeight: 1,
    },
    myRegion: {
        borderBottom: "2px solid #F8B34C",
        padding: "15px 0",
    },
    mapImg: {
        width: 130,
        margin: "0 auto",
    },
    myYaags: {
        padding: "15px 0",
    },

    bottomNavigation: {
        position: "absolute",
        width: "100%",
    },
    tabName: {
        // color: "#fff",
        color: "#00000",
        textTransform: "none",
        // ariaSelected: "true",
    },
    maxpoints: {
        textTransform: "none",
        fontSize: 13,
    },
    tagWrapperContainer: {
        width: "45%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
    },
    tagWrapper: {
        padding: 4,

        backgroundColor: "#E8E8E8",
        margin: 4,
        borderRadius: 4,
        // display: "flex",
        display: "inline-flex",
    },
    campaigntypewrapper: {
        padding: 1,

        // backgroundColor: "#E8E8E8",
        margin: 2,
        borderRadius: 4,
        display: "block",
        // display: "inline-flex",
    },
    tags: {
        textTransform: "capitalize",
        fontSize: 12,
    },
    earnupto: {
        textTransform: "capitalize",
        fontSize: 12,
    },
    adopterCountText: {
        fontSize: 12,
        textTransform: "none",
        fontWeight: "600",
        backgroundColor: "#E8E8E8",
        borderRadius: 4,
        padding: 4,
    },
    cardImg: {
        borderRadius: "5px 5px 0 0",
        // height: 280,
        height: 200,
        backgroundSize: "100% 100%",
        // width: 100,
    },
    cardTitle: {
        // background: "#2C7973",
        color: "#000",
        zIndex: 99,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        boxShadow: "0px 5px 10px rgba(0,0,0,0.2)",
        overflow: "auto",
    },
    cardTitle1: {
        // background: "#2C7973",
        color: "#000",
        // height:"500",
        zIndex: 99,
        display: "flex",
        flexDirection: "row",
        // justifyContent: "flex-start",
        // alignItems: "flex-start",
        // boxShadow: "0px 5px 10px rgba(0,0,0,0.2)",
        overflow: "auto",
    },
    button: {
        padding: "5px 2px",
        fontSize: "10px",
    },
    ChipCompleted: {
        fontSize: 12,
        textTransform: "none",
        fontWeight: "600",
        backgroundColor: "#228B22",
        borderRadius: 4,
        padding: 4,
    },
    subtitleWrapper: {
        textAlign: "center",
    },
}));

const GetAToken = (props) => {
    // console.log("start HAScreen")
    const navigate = useNavigate();
    const locationPath = useLocation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [ad, setAD] = useState([]);
    const [adToken, setADToken] = useState('');
    
    // console.log("calling profile")
    const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
        (state) => state.profile
    );
    
    const userid = localStorage.getItem("userid");

    React.useEffect(() => {
        dispatch(userProfile(userid));
    }, []);
    
    useEffect(() => {
        getADLogin();
    }, []);
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const formData = new FormData();

        for (const [key, value] of urlParams.entries()) {
            formData.append(key, value);
        }
        
        console.log("formData = ", formData)
        setADToken(formData);

    // const queryString = paramsArray.join('&');
        
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const sessiondate = urlParams.get('session_state')
   
    console.log(code); // This will log the value of 'code' from the URL
    console.log(state); // This will log the value of 'code' from the URL
    console.log(sessiondate); // This will log the value of 'code' from the URL
      }, [profileSuccess]);

   console.log("adToken = ", adToken);
   console.log((!(adToken === "")))
   
    const getADLogin = async () => {
        try {
            if (adToken && adToken.entries().next().value) {     
                console.log("adToken has entries 1= ", adToken.entries().next().value)
                console.log("adToken has entries 2= ", adToken.entries().next().value)
                axios.post('/getAToken', adToken)
                    .then(response => {
                        console.log('Response from backend:', response.data);
                    })
                    .catch(error => {
                        console.error('Error sending params to backend:', error);
                    });
                // return res;
            }
            // }
        } catch (err) {
            
            throw err;
        }
    };


    return (
        <Card className={classes.homecard} onClick={props.onClick}>
            <div className="wrapper">
                <ResponsiveDrawer {...props} />
                <section className="containers">
                    <TopHeaderScreen {...props} />
                    <div class="content content-wrap">
                       
                        <div class="content-mrg hasScreen">
                           
                            <div>
                               HIIIIIIIIIIII {ad}
                            </div>

                        </div>
                    </div>
                    <CopyRight />
                    <FixedFooterIcon />
                   
                </section>
            </div>
        </Card>
    );
};

export default GetAToken;
