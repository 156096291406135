/** @format */

const MY_REWARD_FETCHING_DATA = 'MY_REWARD_FETCHING_DATA';
const MY_REWARD_FETCHING_DATA_FAILURE = 'MY_REWARD_FETCHING_DATA_FAILURE';
const MY_REWARD_FETCHING_DATA_RESET = 'MY_REWARD_FETCHING_DATA_RESET';
const MY_REWARD_FETCHING_DATA_SUCCESS = 'MY_REWARD_FETCHING_DATA_SUCCESS';
const MY_REWARD_FETCHING_DATA_ATTEMPT = 'MY_REWARD_FETCHING_DATA_ATTEMPT';
const myReward = (payload, phoneno, campid) => ({
	type: MY_REWARD_FETCHING_DATA_ATTEMPT,
	payload: { phoneno, campid, ...payload },
});

export { MY_REWARD_FETCHING_DATA, MY_REWARD_FETCHING_DATA_FAILURE, MY_REWARD_FETCHING_DATA_RESET, MY_REWARD_FETCHING_DATA_SUCCESS, MY_REWARD_FETCHING_DATA_ATTEMPT, myReward };
