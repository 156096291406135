import React, { useState } from 'react';
import { Button, Container, Grid, Paper } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import ResponsiveDrawer from '../../components/Header';
import TopHeaderScreen from '../../components/TopHeader1';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  button: {
    backgroundColor: '#4CAF50',
    color: 'white',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#45a049',
      transform: 'translateY(-2px)',
    },
  },
}));

const MPINChange = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleGenerateNewPin = () => {
    // Handle generate new PIN logic here
    console.log('Generate New Pin clicked');
  };

  const handleChangePin = () => {
    // Handle change PIN logic here
    console.log('Change Your Pin clicked');
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div className="content content-wrap">
          <div className="content-mrg">
           

            <div style={{ fontFamily: 'monospace', fontSize: 16 }}>
              <Button onClick={() => navigate(-1)}>
                <i className={`fa fa-caret-left`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
              </Button>
            </div>
            <span
              style={{
                fontSize: 25,
                fontWeight: '600',
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              MPIN
            </span>

            <div className={classes.buttonContainer}>
              <Button className={classes.button} onClick={handleGenerateNewPin}>
                Generate new MPIN
              </Button>
              <Button className={classes.button} onClick={handleChangePin}>
                Change MPIN
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MPINChange;
