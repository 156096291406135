/** @format */

// reducers
import { DASHBOARD_REWARD_FETCHING_DATA, DASHBOARD_REWARD_FETCHING_DATA_FAILURE, DASHBOARD_REWARD_FETCHING_DATA_RESET, DASHBOARD_REWARD_FETCHING_DATA_SUCCESS } from '../action/DashboardReward';

let initialState = {
	response: {},
	isSuccess: false,
	isError: false,
	isFetching: false,
};

export default function DashboardRewardReducer(state = initialState, action) {
	switch (action.type) {
		case DASHBOARD_REWARD_FETCHING_DATA:
			return {
				...state,
				isFetching: true,
				isError: false,
				isSuccess: false,
			};
		case DASHBOARD_REWARD_FETCHING_DATA_FAILURE:
			return {
				...state,
				isFetching: false,
				isError: action.payload,
				isSuccess: false,
			};
		case DASHBOARD_REWARD_FETCHING_DATA_SUCCESS:
			return {
				...state,
				isFetching: false,
				isError: false,
				response: action.payload,
				isSuccess: true,
			};

		case DASHBOARD_REWARD_FETCHING_DATA_RESET:
			return initialState;

		default:
			return state;
	}
}
