import React from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import { isEmpty, isArray } from "lodash";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Avatar, Button } from "@mui/material";
import ResponsiveDrawer from "../components/Header";
import { CopyRight } from "../components/CopyRight";
import { FixedFooterIcon } from "../components/FixedFooterIcon";
import TopHeaderScreen from "../components/TopHeader1";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },
  referral: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width: "200px",
    fontWeight: "bold",
    // backgroundColor: theme.palette.info.light,
    color: theme.palette.info.dark,
  },
}));

const MyCommunityTree = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const locationPath = useLocation();
  const navigate = useNavigate();

  // let { id, label, uploadUrl } = props;
  const [activityList1, setActivityList1] = React.useState("");


  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  // console.log(`/adopter_dashboard/pf/mynetworkreward/test/${locationPath?.pathname.split("/")?.[2]}/${props.camp}`)
  // const getPerformanceReportOnUser1 = async () => {
  //   try {
  //     const res = await axios.get(
  //       `/adopter_dashboard/pf/mynetworkreward/test/${locationPath?.pathname.split("/")?.[2]}/${props.camp}`
  //     );
  //     console.log(res);
  //     setActivityList1(res?.data);
  //     return res;
  //   } catch (err) {
  //     console.log(err);
  //     console.log(err?.response?.data);
  //     setActivityList1(err?.response?.data);
  //     throw err;
  //   }
  // };

  // React.useEffect(() => {
  //   getPerformanceReportOnUser1().then((response) => {
  //     console.log(response?.data);
  //     setActivityList1(response);
  //     return response;
  //   });
  // }, []);

  const optionsFour = {
    items: 4,
    // margin: 10,
    margin: 0,
    loop: false,
    nav: true,
    autoplaySpeed: 900,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 4,
      },
      1000: {
        items: 6,
      },
    },
  };



  return (
    // <div className="wrapper">

    //   <section className="containers">
    //     <div class="content content-wrap">
    //       <div class="content-mrg">

    <div class="containers">
      <div class="cyagg-sec-space">
        {/* <div class="row"> */}
        <div>
          <div class="col-md-12">
            <div
              style={{
                padding: "10px 0",
                // height: "50%",
                // boxShadow: "1px 1px 1px 5px #f0f0f0",
                borderRadius: 30,
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "#fff"
                backgroundColor: "#FFFFFF",
                // width: "50%",
                border: "1px solid #dadce0",
              }}
            >
              <Avatar
                src={profileResponse?.profilephoto}
                // className={classes.large}
                style={{
                  display: "inline-flex",
                  height: "100px",
                  width: "100px",
                }}
              />
            </div>
            <div class="tab-content customOwlNav" id="animateLineContent-4" style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
              {/* <OwlCarousel className="owl-theme" {...optionsFour}> */}
              <div>
                {props.reftree &&
                  props.reftree?.data?.map(
                    (item) =>
                    
                    !isEmpty(item?.referral)  ? 
                     
                      item &&
                      item?.referral.map((item1) => (
                        
                        <div  class="marketplaceinrow" style={{  marginBottom: "30px", float: "left",  textAlign: "center", }}>
                          <div class="reward-wrap-marketplace">
                            <h6 class="sponsor-title">Level {item?.level}</h6>
                            <div
                              style={{ fontSize: "20px", fontWeight: "500" }}
                            >
                              <div>{item1.name}</div>
                            </div>
                          </div>
                        </div>
                      )
                      ):(
                        <div
                          class="marketplaceinrow"
                          style={{
                            marginBottom: "30px",
                            float: "left",
                            textAlign: "center",
                          }}
                        >
                          <div class="reward-wrap-marketplace">
                            {/* <h6 class="sponsor-title">Level {item?.level}</h6> */}
                            <div
                              style={{ fontSize: "20px", fontWeight: "500" }}
                            >
                              <div>You have not built your network</div>
                            </div>
                          </div>
                        </div>
                      )
                      
                  )}
              </div>
              {/* </OwlCarousel> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
    // </div>
    // <CopyRight />
    // <FixedFooterIcon />
    // </section>
    // </div>
  );
};

export default MyCommunityTree;
