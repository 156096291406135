// import * as React, {useRef} from "react";
import React, { useEffect, useRef, useState } from "react";
import FormControl from "@mui/material/FormControl";
import {
  makeStyles,
} from "@material-ui/core";
import {
  Avatar,
  Button,
  
} from "@mui/material";

import "react-datepicker/dist/react-datepicker.css";

import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import axios from "axios";
import _ from "lodash";
import TopHeaderScreen from "../../components/TopHeader1";
import { EditOutlined, RemoveRedEyeOutlined } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import {
  RewardsReportTableComponent,
} from "../../components/Table";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import { CSVLink, CSVDownload } from "react-csv";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },

    display: "block",
    display: "flex",
    //fontSize: 16,
    fontSize: "16px",
    marginTop: 16,
    padding: 10,
    fontWeight: "500",
    lineHeight: "18px",
    minHeight: 50,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {

    },

    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroupFull: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%"
    },
    [theme.breakpoints.only("md")]: {
      width: "100%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  table: {
    width: "100%",
    borderRadius: "10px",
    borderStyle: "solid",
    borderWidth: "1.5px",
    // tableLayout: "fixed",
  },
  tableRow: {
    borderBottom: "1px solid #d3d3d3",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  thead: {
    borderBottom: "1px solid #d3d3d3",
    borderTop: "1px solid #d3d3d3",
    height: 60,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.getContrastText(theme.palette.secondary.dark),
  },
  theadText: {
    fontWeight: "700",
    textAlign: "center",
    fontSize: 16,
    minWidth: 100,
  },
  tbodyText: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width:"200px",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // wordWrap: "breakWord",
    // overflow: "scroll",
  },
  participate: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width:"200px",
    fontWeight: "bold",
    // backgroundColor: theme.palette.info.light,
    color: theme.palette.info.light,
  },
  referral: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width:"200px",
    fontWeight: "bold",
    // backgroundColor: theme.palette.info.light,
    color: theme.palette.info.dark,
  },
}));

function RewardsReport(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  
  const [activityList, setActivityList] = React.useState(undefined);
  
  React.useEffect(() => {
    // dispatch(getOrganization({ userid: localStorage.getItem("userid") }));
    getReportOnRewards();
    // getActivityList();
  }, [activityList]);

    
  
  const getReportOnRewards = async () => {
    try {
      const res = await axios.get(`/rewards/usage/${localStorage.getItem("userid")}`);
     
      setActivityList(res?.data);
      return res;
    } catch (err) {
        
        throw err;
    }
  };
  
  const downloadDocument = () => {
    
    const pdf = new jsPDF("l", "pt", "a2");
    var width = pdf.internal.pageSize.getWidth()
    pdf.text('report', width/2, 20)
    // const pdf = new jsPDF({
    //   orientation: "landscape",
    //   unit: "pt",
    //   format: [4, 2]
    // });
    pdf.html(document.querySelector("#SOADashboardScreen"), {
      callback: function(pdf){
        var pageCount = pdf.internal.getNumberOfPages();
        // pdf.deletePage(pageCount);
        
        pdf.save("cyaag.pdf");
      }
    })
    // const input = document.getElementById("SOADashboardScreen");
    // console.log("input = ", input);
    // html2canvas(input, {logging:true, willReadFrequently:true, useCORS:true}).then((canvas) => {
    //   const imgwidth= 100;
    //   const imgHeight= canvas.height* imgwidth /canvas.width;
    //   const imagedata = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF("p", "pt", "a4");
    //   console.log("sleeping for 2 secs...");
    //   sleep(2000);
    //   console.log("slept for 2 secs");
      
    //   // pdf.addImage(imagedata, "png", 0, 0, imgwidth, imgHeight);
    //   pdf.addImage(imagedata, "JPEG", "10", "45");
    //   pdf.save("cyaag.pdf");
    // });
  }


  
  
  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
          <Button
              onClick={() => navigate(-1)}
              className={classes.backBtn}
          >
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
          </Button>
            <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Report on Rewards</div>
            <div>
              {/* <Button className="btn download-btn" variant="contained" onClick={downloadDocument}>Download</Button> */}
              <Button 
                className="btn download-btn" 
                // variant="contained"
              >
                {
                  activityList && 
              <CSVLink data={activityList}>
                  Download Report
              </CSVLink>
}
              </Button>
              
            </div>
            <div style={{ fontFamily: "monospace", fontSize: 16, }}>
             
            </div>
            
            <header id="SOADashboardScreen">
            <form autoComplete="off">
              <div style={{ border: "1px solid #dadce0", borderRadius: 8, backgroundColor: "#fff" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  

                  {/* <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Participation <RedStar />
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <Select
                        style={{
                          width: "100%",
                          fontSize: 14,
                          height: 56,
                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                        }}
                        value={campaignValues.campaignParticipate}
                        onBlur={() => {
                          if (!campaignValues?.campaignParticipate) {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              campaignParticipateError: true,
                            });
                          } else {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              campaignParticipateError: false,
                            });
                          }
                        }}
                        onChange={(e) =>
                          handleCampaignFieldChange("campaignParticipate", e)
                        }
                      >
                        
                        {activityList &&
                            activityList.map((item, index) => (
                                <MenuItem style={{ fontSize: 16 }} value={item.campid}>
                                    {item.campid}
                                </MenuItem>
                        ))}
                        
                      </Select>
                    </div>
                    {campaignValuesError?.campaignParticipateError && (
                      <ErrorMessage message="Please select the campaign participate value" />
                    )}
                  </div> */}

                  

                </div>
                {/* <div>
                {isCampaignSuccess && 
                  <span style={{ color: "green", textAlign: "center", display: "block" }}>{campaignResponse?.message}</span>}
                  {isCampaignError && (
                    // <span style={{ color: "red" }}>Error occured</span>
                    <span style={{ color: "red", textAlign: "center", display: "block" }}>{campaignResponse?.message}</span>
                  )}
                </div> */}
                <div className="centerAlign" style={{ padding: "16px 0", marginBottom: 50 }}>
                  {/* <Button

                    variant="contained"
                    // onClick={<RewardsReportTableComponent rows={activityList} />}
                    // onClick={RewardsReportTableComponent}
                    onClick={handleButtonChange}
                    handleButtonChange
                    // onClick={() => RewardsReportTableComponent }
                    className="btn save-btn"
                    disableElevation
                    disabled={isCampaignFetching}
                  >
                    {isCampaignFetching ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      <span>Submit</span>
                    )}
                  </Button> */}
                  <br />
                  {/* {isCampaignSuccess && 
                  <span style={{ color: "green", textAlign: "center", display: "block" }}>{campaignResponse?.message}</span>}
                  {isCampaignError && (
                    // <span style={{ color: "red" }}>Error occured</span>
                    <span style={{ color: "red", textAlign: "center", display: "block" }}>{campaignResponse?.message}</span>
                  )} */}

                    <div className={classes.formGroupFull}>
                    {/* {rewardtitle.map((itemreward, index) => ( */}
                      <div id="showAll" style={{overflow:"auto"}}>
                        <div id="downloadPdf">
                        <RewardsReportTableComponent rows={activityList}/>
                        </div>
                        </div>
                    {/* ))} */}
                    {/* <AddCircleOutlineOutlined
                  formStyle={{ paddingTop: "20px" }}
                  onClick={handleAddFields}
                ></AddCircleOutlineOutlined> */}
                  </div>
                </div>
              </div>
            </form>
            </header>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>

      
    </div>
  );
}
export default RewardsReport;
