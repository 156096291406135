/** @format */

import { call, put } from "redux-saga/effects";
import { get } from "lodash";

import {
    GET_CAMPAIGN_PROFILE_BANNER_FETCHING_DATA,
    GET_CAMPAIGN_PROFILE_BANNER_FETCHING_DATA_FAILURE,
    // GET_ORG_PROFILE_LOGO_FETCHING_DATA_RESET,
    GET_CAMPAIGN_PROFILE_BANNER_FETCHING_DATA_SUCCESS,
} from "../action/campBannerAction";
import { Api } from "../../services";

const myAPI = new Api();

export function* getCampProfileBanner(action) {
  yield put({
    type: GET_CAMPAIGN_PROFILE_BANNER_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.campProfileBanner, action.payload);
    const payload = get(response, "data");
    yield put({
      type: GET_CAMPAIGN_PROFILE_BANNER_FETCHING_DATA_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({ type: GET_CAMPAIGN_PROFILE_BANNER_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}
