/** @format */

// reducers
import { REFERRAL_URL_FETCHING_DATA, REFERRAL_URL_FETCHING_DATA_FAILURE, REFERRAL_URL_FETCHING_DATA_RESET, REFERRAL_URL_FETCHING_DATA_SUCCESS } from '../action/ReferralURLAction';

let initialState = {
	response: {},

	isError: false,
	isFetching: false,
};

export default function ReferralURLReducer(state = initialState, action) {
	switch (action.type) {
		case REFERRAL_URL_FETCHING_DATA:
			return {
				...state,
				isFetching: true,
				isError: false,
			};
		case REFERRAL_URL_FETCHING_DATA_FAILURE:
			return {
				...state,
				isFetching: false,
				isError: action.payload,
			};
		case REFERRAL_URL_FETCHING_DATA_SUCCESS:
			return {
				...state,
				isFetching: false,
				isError: false,
				response: action.payload,
			};

		case REFERRAL_URL_FETCHING_DATA_RESET:
			return initialState;

		default:
			return state;
	}
}
