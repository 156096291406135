import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { makeStyles, useFormControl } from "@material-ui/core";
import {
  Avatar,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import {
  onboardActivity,
  onboardActivityReset,
  onboardBulk,
  onboardBulkReset,
  onboardCampaign,
  onboardCampaignReset,
  onboardIndividual,
  onboardIndividualReset,
  onboardOrganization,
  onboardOrganizationReset,
} from "../redux/action/OnboardAction";
import {
  activity,
  activityDuration,
  binaryOption,
  BloodGroup,
  Category,
  Gender,
  NSSRole,
} from "../utils/dict";
import { SnackBarComponent } from "./Snackbar";
import moment from "moment";
import { states } from "../utils/constants";
import { getCity } from "../redux/action/cityAction";
import { getVolunteerName } from "../redux/action/getVolunteerName";
import { getOrganization } from "../redux/action/getOrganization";
import CallingCode from "../services/CallingCode";
import { fetchCityAndState } from "../services/commonServices";

import axios from "axios";
import RedStar from "./RedStar";
import { API_URL } from "../services/Constants";

const form = [
  { id: 1, formQuestion: "Volunteer First Name" },
  { id: 2, formQuestion: "Volunteer Middle Name" },
  { id: 3, formQuestion: "Volunteer Last Name" },
  { id: 4, formQuestion: "Volunteer Father Name" },
  { id: 5, formQuestion: "Date of Birth" },
  { id: 6, formQuestion: "Adhaar No" },
  { id: 7, formQuestion: "Category" },
  { id: 8, formQuestion: "Gender" },
  { id: 9, formQuestion: "Blood Group" },
  { id: 10, formQuestion: "Present Address" },
  { id: 11, formQuestion: "Permanent Address" },
  { id: 12, formQuestion: "Email Address" },
  {
    id: 13,
    formQuestion: "Session",
  },
  { id: 14, formQuestion: "Roll Number" },
  { id: 15, formQuestion: "Phone Number" },
  {
    id: 16,
    formQuestion: "College Name",
  },
  { id: 17, formQuestion: "University Name" },
  {
    id: 19,
    formQuestion: "State",
  },
  { id: 18, formQuestion: "City" },
  { id: 20, formQuestion: "Organization" },
  { id: 21, formQuestion: "ORG Role" },
  { id: 22, formQuestion: "ORG unit" },
];

const useStyles = makeStyles((theme) => ({
  createScreenMainContent: {
    padding: "80px 50px 150px 0",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 150px 220px",
    },
  },
  heading: {
    fontSize: 32,
    fontWeight: "400",
    lineHeight: "135%",
    color: "#202124",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      fontWeight: "400",
    },
  },
  subHeading: {
    fontSize: 18,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      fontWeight: "600",
    },
  },
  question: {
    fontSize: 16,
    fontWeight: "600",

    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      fontWeight: "600",
    },
  },
  createScreenContainer: {
    width: "100%",
    margin: "0",
    padding: "30px 12px",
  },
  form: {
    height: 50,
    width: "100%",
    padding: "8px 0",
    margin: 16,
    [theme.breakpoints.down("sm")]: {
      padding: "24px 0",
    },
  },
  formGroupLastChild: {
    marginBottom: 18,
  },
  label: {
    display: "block",
    background: "#E9E7E7",
    padding: 10,
    // textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },
  input: {
    width: 200,
    border: "1px solid #C6C5CB",
    padding: 10,
    display: "block",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  textarea: {
    width: "100%",
    border: "1px solid #C6C5CB",
    padding: 10,
    display: "block",
  },
  sliderLabel: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 10px 0",
  },
  sliderLabelSpan: {
    display: "block",
    fontSize: 9,
  },
  fileUploadWrapper: {
    position: "relative",
    padding: 10,
    border: "1px solid #C6C5CB",
  },
  fileUploadTxt: {
    fontSize: 9,
  },
  fileUploadBtn: {
    display: "inline-block",
    color: theme.palette.primary.main,
    fontSize: 15,
    cursor: "pointer",
    position: "absolute",
    right: 10,
    top: 6,
  },
  imagesWapper: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: "0 -7px",
  },
  image: {
    width: "calc(33.33% - 14px)",
    margin: 7,
  },
  imagesImg: {
    width: "100%",
    height: "100%",
  },
  formSubmitButton: {
    fontSize: 12,
    padding: "6px 13px",
  },
  textCenter: {
    textAlign: "center",
  },
  crossButton: {
    background: "transparent",
    marginTop: 5,
    boxShadow: "none",
    color: theme.palette.primary.main,
  },
  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },
  pageHeadingIcon: {
    fontSize: 25,
    color: theme.palette.secondary.main,
    marginLeft: 8,
  },
  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "block",
    fontSize: 18,
    marginTop: 16,
    padding: 10,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },
    // fontSize: 14,
    // margin: '16px 0',
    // background: '#fff',
    // borderRadius: 8,
    // boxShadow: '1px 1px 12px 0px rgba(0, 0, 0, 0.75)',
    // padding: 8,
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
  },
  cardImg: {
    borderRadius: "5px 5px 0 0",
    height: "159.7829036635007px",
    maxHeight: "22.469470827679785vw",
    maxWidth: "90vw",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto",
    [theme.breakpoints.down("sm")]: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
    },
  },
}));

export default function AdminDashboard(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [radioValue, setRadioValue] = React.useState("");
  const [selectedFiles, setSelectedFiles] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("false");
  const [dateofBirth, setDateofBirth] = React.useState(
    new Date().setFullYear(new Date().getFullYear() - 5)
  );
  const [value1, setValue1] = React.useState("");
  const [value2, setValue2] = React.useState("");
  const [value3, setValue3] = React.useState("");
  const [value4, setValue4] = React.useState("");
  const [value5, setValue5] = React.useState("");
  const [value6, setValue6] = React.useState("");
  const [value7, setValue7] = React.useState("");
  const [value8, setValue8] = React.useState("");
  const [value9, setValue9] = React.useState("");
  const [value10, setValue10] = React.useState("");
  const [value11, setValue11] = React.useState("");
  const [value12, setValue12] = React.useState("");
  const [value13, setValue13] = React.useState("");
  const [value14, setValue14] = React.useState("");
  const [value15, setValue15] = React.useState("");
  const [value16, setValue16] = React.useState("");
  const [value17, setValue17] = React.useState("");
  const [value18, setValue18] = React.useState("");
  const [value19, setValue19] = React.useState("");
  const [value20, setValue20] = React.useState("");
  const [value21, setValue21] = React.useState("");
  const [value22, setValue22] = React.useState("");

  const [activityList, setActivityList] = React.useState(undefined);
  const [activityType, setActivityType] = React.useState("");
  const [volunteerName, setVolunteerName] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [dateofActivity, setdateofActivity] = React.useState(new Date());
  const [activityDescription, setActivityDescription] = React.useState("");
  const [activityDurationDescription, setActivityDurationDescription] =
    React.useState("");
  const [certificate, setCertificate] = React.useState("");
  const [impact, setimpact] = React.useState("");
  const [campaignName, setCampaignName] = React.useState("");
  const [organizationName, setOrganizationName] = React.useState("");
  const [areas, setAreas] = React.useState([]);
  const [campaignValues, setCampaignValues] = React.useState({
    titleOfCampaign: "",
    descriptionOfCampaign: "",
    typeOfCampaign: "",
    volunteerCount: "",
    impact: "",
    startDate: new Date(),
    durationOfCampaign: "",
    country: "",
    stateofCampaign: "",
    cityOfCampaign: "",
    areaOfCampaign: "",
    pinCodeOfCampaign: "",
    socialMediaMessage: "",
    campaignLogo: "",
    campaignVideo: "",
  });

  const [organizationValues, setOrganizationValues] = React.useState({
    titleOfOrganization: "",
    descriptionOfOrganization: "",
    volunteerCount: "",
    country: "",
    stateofOrganization: "",
    cityOfOrganization: "",
    areaOfOrganization: "",
    pinCodeOfOrganization: "",
    organizationLogo: "",
  });
  const {
    isBulkSuccess,
    isBulkError,
    isIndividualSuccess,
    isCampaignSuccess,
    isCampaignFetching,
    isOrganizationSuccess,
    isOrganizationFetching,
    isOrganizationError,
    isCampaignError,
  } = useSelector((state) => state.onBoard);
  const {
    isSuccess: organizationListSuccess,
    isError: organizationListError,
    response: organizationList,
  } = useSelector((state) => state.organizationList);

  const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
    (state) => state.cities
  );

  const { response: volunteerNameResponse } = useSelector(
    (state) => state.volunteerName
  );

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event) => {
    setRadioValue(event.target.value);
    dispatch(onboardBulkReset());
    dispatch(onboardIndividualReset());
    dispatch(onboardActivityReset());
    dispatch(onboardCampaignReset());
    dispatch(onboardOrganizationReset());
    setCampaignValues({});
    setOrganizationValues({});
    setAreas([]);
    setActivityType([]);
  };

  const handleFileInput = (e) => {
    setSelectedFiles(e.target.files[0]);
  };

  const handleFile = () => {
    const formData = new FormData();
    formData.append("bulkupload", selectedFiles);

    dispatch(onboardBulk(formData));
  };
  function subtractYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);

    return date;
  }

  const handleOnboardIndividual = () => {
    let formData = new FormData();
    formData.append("firstname", value1);
    formData.append("middlename", value2);
    formData.append("lastname", value3);
    formData.append("fathername", value4);
    formData.append(
      "dob",
      value5 === ""
        ? moment(dateofBirth).format("DD-MM-yyyy")
        : moment(value5).format("DD-MM-yyyy")
    );
    formData.append("adhaarno", value6);
    formData.append("category", value7);
    formData.append("gender", value8);
    formData.append("bloodgroup", value9);
    formData.append("presentaddress", value10);
    formData.append("permanentaddress", value11);
    formData.append("emailid", value12);
    formData.append("session", value13);
    formData.append("rollno", value14);
    formData.append("phoneno", value15);
    formData.append("college", value16);
    formData.append("university", value17);
    formData.append("state", value18);
    formData.append("city", value19);
    formData.append("organization", value20);
    formData.append("orgrole", value21);
    formData.append("orgunit", value22);
    dispatch(onboardIndividual(formData));
  };

  const handleOnboardActivity = () => {
    let formData = new FormData();
    formData.append("activitytype", activityType);
    formData.append(
      "activitydate",
      moment(dateofActivity).format("DD-MM-yyyy")
    );
    formData.append("activitydesc", activityDescription);
    formData.append("activityduration", activityDurationDescription);
    formData.append("activitycert", certificate);
    // formData.append("nsscertificate", nssCertificateReceived);
    formData.append("fullname", volunteerName.fullname);
    formData.append("impact", impact);
    formData.append("campaignname", campaignName);
    formData.append("organizationname", organizationName);

    dispatch(
      onboardActivity({
        userid: volunteerName.phoneno,
        formData,
      })
    );
  };

  const handleOnboardCampaign = () => {
    dispatch(onboardCampaignReset());
    let formData = new FormData();
    formData.append("type", campaignValues?.typeOfCampaign);
    formData.append("title", campaignValues?.titleOfCampaign);
    formData.append("desc", campaignValues?.descriptionOfCampaign);
    formData.append("country", campaignValues?.country);
    formData.append("state", campaignValues?.stateofCampaign);
    formData.append("city", campaignValues?.cityOfCampaign);
    formData.append("pincode", campaignValues?.pinCodeOfCampaign);
    formData.append("area", campaignValues?.areaOfCampaign);
    formData.append("logo", campaignValues?.campaignLogo);
    formData.append("video", campaignValues?.campaignVideo);
    formData.append("impact", campaignValues?.impact);
    formData.append("targetvol", campaignValues?.volunteerCount);
    formData.append(
      "startdate",
      moment(campaignValues?.startDate).format("DD-MM-yyyy")
    );
    formData.append("timeframe", campaignValues?.durationOfCampaign);
    formData.append("socialmediamsg", campaignValues?.socialMediaMessage);
    dispatch(
      onboardCampaign({ userid: localStorage.getItem("userid"), formData })
    );
  };

  const handleOnboardOrganization = () => {
    dispatch(onboardOrganizationReset());
    let formData = new FormData();
    formData.append(
      "organizationname",
      organizationValues?.titleOfOrganization
    );
    formData.append("noofemployees", organizationValues?.volunteerCount);
    formData.append(
      "organizationdesc",
      organizationValues?.descriptionOfOrganization
    );
    formData.append("country", organizationValues?.country);
    formData.append("state", organizationValues?.stateofOrganization);
    formData.append("city", organizationValues?.cityOfOrganization);
    formData.append("pincode", organizationValues?.pinCodeOfOrganization);
    formData.append("area", organizationValues?.areaOfOrganization);
    formData.append("organizationlogo", organizationValues?.organizationLogo);
    dispatch(
      onboardOrganization({ userid: localStorage.getItem("userid"), formData })
    );
  };

  const handleCityChange = (event) => {
    setValue19(event.target.value);
  };

  const handleCampaignFieldChange = (field, e) => {
    (isCampaignError || isCampaignSuccess) && dispatch(onboardCampaignReset());
    setCampaignValues({
      ...campaignValues,
      [field]:
        field === "startDate"
          ? e
          : field === "campaignLogo" || field === "campaignVideo"
          ? e.target.files[0]
          : e.target.value,
    });
  };

  const handleOrganizationFieldChange = (field, e) => {
    (isOrganizationError || isOrganizationSuccess) &&
      dispatch(onboardOrganizationReset());
    setOrganizationValues({
      ...organizationValues,
      [field]:
        field === "organizationLogo" ? e.target.files[0] : e.target.value,
    });
  };

  const getActivityList = async () => {
    try {
      const res = await axios.get(`/activity`);
      setActivityList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const unitOfActivity = activityList?.filter(
    (item, index) => item?.activity === activityType
  );
  const unitOfCampaign = activityList?.filter(
    (item, index) => item?.activity === campaignValues?.typeOfCampaign
  );

  React.useEffect(() => {
    if (isBulkSuccess) {
      setOpen(true);
      setMessage("File upload suceeded");
      // dispatch(onboardBulkReset());
      setOpen(false);
    } else if (isBulkError) {
      setMessage("File upload failed");
      // dispatch(onboardBulkReset());
    }
  }, [isBulkSuccess]);

  React.useEffect(() => {
    if (value18) {
      dispatch(getCity(value18));
    }
  }, [value18]);
  React.useEffect(() => {
    if (props?.response) {
      dispatch(
        getVolunteerName({
          organization: props?.response?.organization,
          college: props?.response?.college,
        })
      );
    }
  }, [props?.response]);

  React.useEffect(() => {
    dispatch(getOrganization({ userid: localStorage.getItem("userid") }));
    getActivityList();
  }, []);

  return (
    <FormControl>
      <SnackBarComponent open={open} message={message} onClose={handleClose} />
      <FormLabel className={classes.label} id="demo-radio-buttons-group-label">
        Onboard Users
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={radioValue}
        onChange={handleChange}
      >
        <FormControlLabel
          value="one"
          control={<Radio />}
          label="Want to onboard one user?"
        />
        {radioValue === "one" ? (
          <div style={{ border: "1px solid #dadce0", borderRadius: 8 }}>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[0].formQuestion}
                <RedStar />
              </label>
              <input
                type="text"
                id="create-yaad--title"
                className={classes.input}
                placeholder="Your answer"
                onChange={(e) => {
                  setValue1(e.target.value);
                }}
              />
            </div>

            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[1].formQuestion}
              </label>
              <input
                type="text"
                id="create-yaad--title"
                className={classes.input}
                placeholder="Your answer"
                onChange={(e) => {
                  setValue2(e.target.value);
                  // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                }}
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[2].formQuestion}
              </label>
              <input
                type="text"
                id="create-yaad--title"
                className={classes.input}
                placeholder="Your answer"
                onChange={(e) => {
                  setValue3(e.target.value);
                  // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                }}
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[3].formQuestion}
              </label>
              <input
                type="text"
                id="create-yaad--title"
                className={classes.input}
                placeholder="Your answer"
                onChange={(e) => {
                  setValue4(e.target.value);
                  // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                }}
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[4].formQuestion}
              </label>
              <DatePicker
                selected={dateofBirth}
                maxDate={subtractYears(5)}
                onChange={(date) => {
                  setDateofBirth(date);
                  setValue5(date);
                }}
                dateFormat={"dd-MM-yyyy"}
                placeholderText="dd-MM-yyyy"
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[5].formQuestion}
              </label>

              <input
                type="text"
                id="create-yaad--title"
                className={classes.input}
                placeholder="Your Answer"
                onChange={(e) => {
                  setValue6(e.target.value);
                  // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                }}
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[6].formQuestion}
              </label>
              <Select
                style={{ width: 350, height: 36 }}
                value={value7}
                onChange={(event) => setValue7(event.target.value)}
              >
                {Category.map((item, index) => (
                  <MenuItem style={{ fontSize: 16 }} value={item.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[7].formQuestion}
              </label>

              <Select
                style={{ width: 350, height: 36 }}
                value={value8}
                onChange={(event) => setValue8(event.target.value)}
              >
                {Gender.map((item, index) => (
                  <MenuItem style={{ fontSize: 16 }} value={item.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[8].formQuestion}
              </label>

              <Select
                style={{ width: 350, height: 36 }}
                value={value9}
                onChange={(event) => setValue9(event.target.value)}
              >
                {BloodGroup.map((item, index) => (
                  <MenuItem style={{ fontSize: 16 }} value={item.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[9].formQuestion}
              </label>
              <input
                type="text"
                id="create-yaad--title"
                className={classes.input}
                placeholder="Your answer"
                onChange={(e) => {
                  setValue10(e.target.value);
                  // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                }}
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[10].formQuestion}
              </label>
              <input
                type="text"
                id="create-yaad--title"
                className={classes.input}
                placeholder="Your answer"
                onChange={(e) => {
                  setValue11(e.target.value);
                  // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                }}
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[11].formQuestion}
              </label>
              <input
                type="text"
                id="create-yaad--title"
                className={classes.input}
                placeholder="Your answer"
                onChange={(e) => {
                  setValue12(e.target.value);
                  // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                }}
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[12].formQuestion}
              </label>
              <input
                type="text"
                id="create-yaad--title"
                className={classes.input}
                placeholder="YYYY-YYYY"
                onChange={(e) => {
                  setValue13(e.target.value);
                  // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                }}
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[13].formQuestion}
              </label>
              <input
                type="text"
                id="create-yaad--title"
                className={classes.input}
                placeholder="Your answer"
                onChange={(e) => {
                  setValue14(e.target.value);
                  // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                }}
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[14].formQuestion}
              </label>
              <input
                type="text"
                id="create-yaad--title"
                className={classes.input}
                placeholder="Your answer"
                onChange={(e) => {
                  setValue15(e.target.value);
                  // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                }}
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[15].formQuestion}
              </label>
              <input
                type="text"
                id="create-yaad--title"
                className={classes.input}
                placeholder="Your answer"
                onChange={(e) => {
                  setValue16(e.target.value);
                  // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                }}
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[16].formQuestion}
              </label>
              <input
                type="text"
                id="create-yaad--title"
                className={classes.input}
                placeholder="Your answer"
                onChange={(e) => {
                  setValue17(e.target.value);
                  // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                }}
              />
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[17].formQuestion}
              </label>
              <Select
                style={{ width: 350, height: 38 }}
                value={value18}
                onChange={(event) => setValue18(event.target.value)}
              >
                {states.map((item, index) => (
                  <MenuItem style={{ fontSize: 16 }} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[18].formQuestion}
              </label>
              <Select
                style={{ width: 350, height: 38 }}
                value={value19}
                disabled={!value18}
                onChange={handleCityChange}
              >
                {citiesSuccess &&
                  citiesResponse.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </div>

            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[19].formQuestion}
              </label>
              <Select
                style={{ width: 350, height: 38 }}
                value={value20}
                onChange={(event) => setValue20(event.target.value)}
              >
                {organizationList.map((item, index) => (
                  <MenuItem style={{ fontSize: 16 }} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[20].formQuestion}
              </label>
              <Select
                style={{ width: 350, height: 38 }}
                value={value21}
                onChange={(event) => setValue21(event.target.value)}
              >
                {NSSRole.map((item, index) => (
                  <MenuItem style={{ fontSize: 16 }} value={item.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                {form[21].formQuestion}
              </label>
              <input
                type="text"
                id="create-yaad--title"
                className={classes.input}
                placeholder="Your answer"
                onChange={(e) => {
                  setValue22(e.target.value);
                  // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                }}
              />
            </div>

            <div style={{ padding: "16px 0", bottom: 50, left: 20 }}>
              <Button
                variant="contained"
                // onClick={handleForm}
                className={classes.goalButton}
                disableElevation
                onClick={handleOnboardIndividual}
              >
                Submit
              </Button>
              <br />
              {isIndividualSuccess && (
                <span style={{ color: "green" }}>
                  User onboarded successfully
                </span>
              )}
            </div>
          </div>
        ) : null}
        <FormControlLabel
          value="many"
          control={<Radio />}
          label="Want to onboard bulk users at same time?"
        />
        {radioValue === "many" ? (
          <>
            <span>Fill and Upload the file( in excel format only)</span>
            <input onChange={handleFileInput} type={"file"} accept=".xlsx" />
            <div style={{ padding: "16px 0", bottom: 50 }}>
              <Button
                variant="contained"
                onClick={handleFile}
                className={classes.goalButton}
                disableElevation
              >
                Submit
              </Button>
              <br />
              {isBulkSuccess && (
                <span style={{ color: "green" }}>
                 
                </span>
              )}
            </div>
          </>
        ) : null}
        <FormControlLabel
          value="activity"
          control={<Radio />}
          label="Want to onboard user's activity?"
        />
        {radioValue === "activity" ? (
          <>
            <div
              style={{
                border: "1px solid #dadce0",
                borderRadius: 8,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <div className={classes.formGroup}>
                <label className={classes.label}>Activity Type</label>
                <Select
                  style={{ width: 350, height: 36 }}
                  value={activityType}
                  autoFocus={false}
                  onChange={(event) => setActivityType(event.target.value)}
                >
                  {activityList?.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.activity}>
                      {item.activity}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Volunteer Name
                </label>
                <Select
                  style={{ width: 350, height: 36 }}
                  value={volunteerName}
                  onChange={(event) => setVolunteerName(event.target.value)}
                >
                  {volunteerNameResponse?.map((item, index) => (
                    <MenuItem
                      style={{ fontSize: 16 }}
                      value={item}
                      onChange={() => setUserId(item.phoneno)}
                    >
                      {item.fullname}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Date of Activity
                </label>
                <DatePicker
                  style={{ height: 36 }}
                  selected={dateofActivity}
                  maxDate={subtractYears(0)}
                  onChange={(date) => {
                    setdateofActivity(date);
                    // setValue5(date);
                  }}
                  dateFormat={"dd-MM-yyyy"}
                  placeholderText="dd-MM-yyyy"
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Activity Description
                </label>
                <input
                  type="text"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  onChange={(e) => {
                    setActivityDescription(e.target.value);
                  }}
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Activity Duration in mins
                </label>
                <Select
                  style={{ width: 350, height: 36 }}
                  value={activityDurationDescription}
                  onChange={(event) =>
                    setActivityDurationDescription(event.target.value)
                  }
                >
                  {activityDuration.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.value}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Impact
                </label>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <input
                    type="number"
                    id="create-yaad--title"
                    className={classes.input}
                    placeholder="Your Answer"
                    onChange={(e) => {
                      setimpact(e.target.value);
                      // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                    }}
                  />
                  <span style={{ marginLeft: 4 }}>
                    {unitOfActivity?.[0]?.unit}
                  </span>
                </div>
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Campaign Name
                </label>

                <input
                  type="text"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your Answer"
                  onChange={(e) => {
                    setCampaignName(e.target.value);
                    // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                  }}
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Organization Name
                </label>

                <input
                  type="text"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your Answer"
                  onChange={(e) => {
                    setOrganizationName(e.target.value);
                    // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                  }}
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Certificate
                </label>
                <input
                  type="text"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your Answer"
                  onChange={(e) => {
                    setCertificate(e.target.value);
                    // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                  }}
                />
              </div>
            </div>
            <div style={{ padding: "16px 0", bottom: 50 }}>
              <Button
                variant="contained"
                onClick={handleOnboardActivity}
                className={classes.goalButton}
                disableElevation
              >
                Submit
              </Button>
            </div>
          </>
        ) : null}
        <FormControlLabel
          value="campaign"
          control={<Radio />}
          label="Want to onboard your campaign?"
        />
        {radioValue === "campaign" ? (
          <div style={{ border: "1px solid #dadce0", borderRadius: 8 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <div className={classes.formGroup}>
                <label className={classes.label}>Title of Campaign</label>
                <input
                  type="text"
                  className={classes.input}
                  placeholder="Your answer"
                  onChange={(e) => {
                    handleCampaignFieldChange("titleOfCampaign", e);
                  }}
                />
              </div>

              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Description of Campaign
                </label>
                <input
                  type="text"
                  className={classes.input}
                  placeholder="Your answer"
                  onChange={(e) => {
                    handleCampaignFieldChange("descriptionOfCampaign", e);
                  }}
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Type of Campaign
                </label>
                <Select
                  style={{ width: 350, height: 36, border: "none" }}
                  value={campaignValues.typeOfCampaign}
                  onChange={(e) => {
                    handleCampaignFieldChange("typeOfCampaign", e);
                  }}
                >
                  {activityList?.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.activity}>
                      {item.activity}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Volunteer Count
                </label>

                <input
                  type="number"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  onChange={(e) => {
                    handleCampaignFieldChange("volunteerCount", e);
                  }}
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Impact
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <input
                    type="number"
                    id="create-yaad--title"
                    className={classes.input}
                    placeholder="Your Answer"
                    onChange={(e) => {
                      handleCampaignFieldChange("impact", e);
                    }}
                  />
                  <span style={{ marginLeft: 4 }}>
                    {unitOfCampaign?.[0]?.unit}
                  </span>
                </div>
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Start Date
                </label>

                <DatePicker
                  style={{ height: 36 }}
                  selected={campaignValues.startDate}
                  maxDate={subtractYears(0)}
                  onChange={(e) => {
                    handleCampaignFieldChange("startDate", e);
                    // setValue5(date);
                  }}
                  dateFormat={"dd-MM-yyyy"}
                  placeholderText="dd-MM-yyyy"
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Duration
                </label>
                <Select
                  style={{ width: 350, height: 36 }}
                  value={campaignValues.durationOfCampaign}
                  onChange={(e) =>
                    handleCampaignFieldChange("durationOfCampaign", e)
                  }
                >
                  {activityDuration.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.value}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Country
                </label>
                <Select
                  style={{ width: 350, height: 36 }}
                  value={campaignValues.country}
                  onChange={(e) => handleCampaignFieldChange("country", e)}
                >
                  {CallingCode.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Pincode
                </label>
                <input
                  type="number"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  onBlur={(e) => {
                    fetchCityAndState(e.target.value).then((res) => {
                      if (res) {
                        setCampaignValues({
                          stateofCampaign: res?.state,
                          cityOfCampaign: res?.city,
                        });
                        setAreas(res?.formatted_address);
                      }
                    });
                    handleCampaignFieldChange("pinCodeOfCampaign", e);
                  }}
                  onChange={(e) => {
                    handleCampaignFieldChange("pinCodeOfCampaign", e);
                  }}
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  State
                </label>

                {/* <Select
                  style={{ width: 350, height: 36 }}
                  value={campaignValues.stateofCampaign}
                  onChange={(e) =>
                    handleCampaignFieldChange("stateofCampaign", e)
                  }
                >
                  {states.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select> */}
                <input
                  value={campaignValues?.stateofCampaign}
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  disabled
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  City
                </label>
                <input
                  value={campaignValues?.cityOfCampaign}
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  disabled
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Area
                </label>
                <Select
                  style={{ width: 350, height: 36, border: "none" }}
                  value={campaignValues.areaOfCampaign}
                  disabled={campaignValues?.pinCodeOfCampaign === ""}
                  onChange={
                    (e) => handleCampaignFieldChange("areaOfCampaign", e)
                    
                  }
                >
                  {areas?.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Social Media Message
                </label>
                <textarea
                  type="text"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  onChange={(e) => {
                    handleCampaignFieldChange("socialMediaMessage", e);
                  }}
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Campaign Logo
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  onChange={(e) => {
                    handleCampaignFieldChange("campaignLogo", e);
                  }}
                />
                {campaignValues?.campaignLogo && (
                  <Avatar
                    src={URL.createObjectURL(campaignValues?.campaignLogo)}
                    style={{ height: 100, width: 100 }}
                  />
                )}
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Campaign Video
                </label>
                <input
                  type="file"
                  accept="video/*"
                  id="create-yaad--title"
                  className={classes.input}
                  onChange={(e) => {
                    handleCampaignFieldChange("campaignVideo", e);
                  }}
                />
                {campaignValues?.campaignVideo && (
                  <video controls width={"50%"}>
                    <source
                      src={URL.createObjectURL(campaignValues?.campaignVideo)}
                      style={{ height: 100, width: 100 }}
                    />
                  </video>
                )}
              </div>
            </div>
            <div style={{ padding: "16px 0", bottom: 50 }}>
              <Button
                style={{ marginLeft: 20 }}
                variant="contained"
                onClick={handleOnboardCampaign}
                className={classes.goalButton}
                disableElevation
                disabled={
                  !campaignValues?.titleOfCampaign ||
                  !campaignValues?.descriptionOfCampaign ||
                  !campaignValues?.typeOfCampaign ||
                  !campaignValues?.volunteerCount ||
                  !campaignValues?.impact ||
                  !campaignValues?.startDate ||
                  !campaignValues?.durationOfCampaign ||
                  !campaignValues?.country ||
                  !campaignValues?.stateofCampaign ||
                  !campaignValues?.cityOfCampaign ||
                  !campaignValues?.areaOfCampaign ||
                  !campaignValues?.pinCodeOfCampaign ||
                  !campaignValues?.socialMediaMessage ||
                  !campaignValues?.campaignLogo ||
                  !campaignValues?.campaignVideo
                }
              >
                {isCampaignFetching ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Submit"
                )}
              </Button>
              <br />
              {isCampaignSuccess && (
                <span style={{ color: "green" }}>
                  Campaign onboarded sucessfully
                </span>
              )}
              {isCampaignError && (
                <span style={{ color: "red" }}>Something error occured</span>
              )}
            </div>
          </div>
        ) : null}

        <FormControlLabel
          value="organization"
          control={<Radio />}
          label="Want to onboard your organization?"
        />
        {radioValue === "organization" ? (
          <div style={{ border: "1px solid #dadce0", borderRadius: 8 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <div className={classes.formGroup}>
                <label className={classes.label}> Title of Organization</label>
                <input
                  type="text"
                  className={classes.input}
                  placeholder="Your answer"
                  onChange={(e) => {
                    handleOrganizationFieldChange("titleOfOrganization", e);
                  }}
                />
              </div>

              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Description of Organization
                </label>
                <input
                  type="text"
                  className={classes.input}
                  placeholder="Your answer"
                  onChange={(e) => {
                    handleOrganizationFieldChange(
                      "descriptionOfOrganization",
                      e
                    );
                  }}
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Organization Logo
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  onChange={(e) => {
                    handleOrganizationFieldChange("organizationLogo", e);
                  }}
                />
                {organizationValues?.organizationLogo && (
                  <Avatar
                    src={URL.createObjectURL(
                      organizationValues?.organizationLogo
                    )}
                    style={{ height: 100, width: 100 }}
                  />
                )}
              </div>

              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Volunteer Count
                </label>

                <input
                  type="number"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  onChange={(e) => {
                    handleOrganizationFieldChange("volunteerCount", e);
                  }}
                />
              </div>

              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Country
                </label>
                <Select
                  style={{ width: 350, height: 36 }}
                  value={organizationValues.country}
                  onChange={(e) => handleOrganizationFieldChange("country", e)}
                >
                  {CallingCode.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Pincode
                </label>
                <input
                  type="number"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  onBlur={(e) => {
                    fetchCityAndState(e.target.value).then((res) => {
                      if (res) {
                        setOrganizationValues({
                          stateofOrganization: res?.state,
                          cityOfOrganization: res?.city,
                        });
                        setAreas(res?.formatted_address);
                      }
                    });
                    handleOrganizationFieldChange("pinCodeOfOrganization", e);
                  }}
                  onChange={(e) => {
                    handleOrganizationFieldChange("pinCodeOfOrganization", e);
                  }}
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  State
                </label>

                {/* <Select
                  style={{ width: 350, height: 36 }}
                  value={OrganizationValues.stateofOrganization}
                  onChange={(e) =>
                    handleOrganizationFieldChange("stateofOrganization", e)
                  }
                >
                  {states.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select> */}
                <input
                  value={organizationValues?.stateofOrganization}
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  disabled
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  City
                </label>
                <input
                  value={organizationValues?.cityOfOrganization}
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  disabled
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Area
                </label>
                <Select
                  style={{ width: 350, height: 36 }}
                  value={organizationValues.areaOfOrganization}
                  disabled={organizationValues?.pinCodeOfOrganization === ""}
                  onChange={
                    (e) =>
                      handleOrganizationFieldChange("areaOfOrganization", e)
                    
                  }
                >
                  {areas?.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                {/* <input
                  value={organizationValues?.areaOfOrganization}
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  disabled
                /> */}
              </div>
            </div>
            <div style={{ padding: "16px 0", bottom: 50 }}>
              <Button
                style={{ marginLeft: 20 }}
                variant="contained"
                onClick={handleOnboardOrganization}
                className={classes.goalButton}
                disableElevation
                disabled={
                  !organizationValues?.titleOforganization ||
                  !organizationValues?.descriptionOforganization ||
                  !organizationValues?.volunteerCount ||
                  !organizationValues?.country ||
                  !organizationValues?.stateoforganization ||
                  !organizationValues?.cityOforganization ||
                  !organizationValues?.areaOforganization ||
                  !organizationValues?.pinCodeOforganization ||
                  !organizationValues?.organizationLogo
                }
              >
                {isOrganizationFetching ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Submit"
                )}
              </Button>
              <br />
              {isOrganizationSuccess && (
                <span style={{ color: "green" }}>
                  Organization onboarded sucessfully
                </span>
              )}
              {isOrganizationError && (
                <span style={{ color: "red" }}>Something error occured</span>
              )}
            </div>
          </div>
        ) : null}
      </RadioGroup>
    </FormControl>
  );
}
