import * as React from "react";
import { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import SOADashboardScreen from "../components/SOADashboardScreen";
const PDF = ({ rootElementId, downloadFileName }) => {
  const downloadDocument = () => {
    
    const input = document.getElementById(rootElementId);
   
    html2canvas(input).then((canvas) => {
      const imagedata = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt", "a4");
      pdf.addImage(imagedata, "JPEG", "10", "54");
      pdf.save(`${downloadFileName}`);
    });
  };
  return (
    <div>
      <label>HI</label>
      <button onClick={downloadDocument}>Download</button>
      <SOADashboardScreen />
    </div>
  );
};

export default PDF;
