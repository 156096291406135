/** @format */

import { call, put } from "redux-saga/effects";
import { get } from "lodash";

import {
  YAAG_MY_COMMUNITY_FETCHING_DATA,
  YAAG_MY_COMMUNITY_FETCHING_DATA_SUCCESS,
  YAAG_MY_COMMUNITY_FETCHING_DATA_FAILURE,
} from "../action/YaagMyCommunityAction";
import { Api } from "../../services";

const myAPI = new Api();

export function* yaagMyCommunity(action) {
  yield put({
    type: YAAG_MY_COMMUNITY_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.yaagMyCommunity, action.payload);
    const payload = get(response, "data");
    yield put({
      type: YAAG_MY_COMMUNITY_FETCHING_DATA_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({ type: YAAG_MY_COMMUNITY_FETCHING_DATA_FAILURE });
  }
}
