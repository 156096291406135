/** @format */

const TOP_ADOPTERS_FETCHING_DATA = 'TOP_ADOPTERS_FETCHING_DATA';
const TOP_ADOPTERS_FETCHING_DATA_FAILURE = 'TOP_ADOPTERS_FETCHING_DATA_FAILURE';
const TOP_ADOPTERS_FETCHING_DATA_RESET = 'TOP_ADOPTERS_FETCHING_DATA_RESET';
const TOP_ADOPTERS_FETCHING_DATA_SUCCESS = 'TOP_ADOPTERS_FETCHING_DATA_SUCCESS';
const TOP_ADOPTERS_FETCHING_DATA_ATTEMPT = 'TOP_ADOPTERS_FETCHING_DATA_ATTEMPT';
const topAdopters = payload => ({
	type: TOP_ADOPTERS_FETCHING_DATA_ATTEMPT,
	payload: payload,
});

export { TOP_ADOPTERS_FETCHING_DATA, TOP_ADOPTERS_FETCHING_DATA_FAILURE, TOP_ADOPTERS_FETCHING_DATA_RESET, TOP_ADOPTERS_FETCHING_DATA_SUCCESS, TOP_ADOPTERS_FETCHING_DATA_ATTEMPT, topAdopters };
