/** @format */

import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  GridList,
  GridListTile,
  Tab,
  Tabs,
  withStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";

import "../../scss/containerStyles/_home.scss";
import "../../App.css";

import { userProfile } from "../../redux/action/UserProfileActions";
import { CopyRight } from "../../components/CopyRight";
import { getVolunteerCount } from "../../redux/action/volunteerCountAction";
import { volunteerMenuItems } from "../../utils/dict";
import CustomDrawer from "../../components/CustomHeader";
import { HomeCard } from "../../components/Card";
import axios from "axios";
import { API_URL } from "../../services/Constants";
import TopHeaderScreen from "../../components/TopHeader1";
import ResponsiveDrawer from "../../components/Header";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 100px 330px",
    },
  },
  homeScreenContainer: {
    padding: "0 0 0 20px",
    zIndex: 100,
  },
  welcomeMessage: {
    fontWeight: "bold",
    marginBottom: 10,
    margin: "10px 0",
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
  },
  welcomeAndFilter: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    textTransform: "uppercase",
    padding: "10px 0",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  filterIcon: {
    fontSize: 26,
    color: "#F8B34C",
    marginLeft: 28,
  },
  homeCards: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    margin: "0 -7px",

    // backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  maxWidthContainer: {
    padding: "25px 0",
    maxWidth: 500,
    margin: "0 auto",
    // borderTop: '2px solid #F8B34C',
  },
  gradientHeading: {
    fontSize: 25,
    fontWeight: "bold",
  },
  rewards: {
    display: "flex",
    justifyContent: "center",
    color: "#F8B34C",
    paddingBottom: 13,
  },
  rewardsText: {
    fontSize: 25,
    fontWeight: "bold",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 9,
  },
  secondaryBorder: {
    padding: "5px 0",
    borderBottom: "2px solid #F8B34C",
  },
  myImpacts: {
    padding: "15px 0 0 0",
    fontSize: 14,
    textAlign: "center",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  myImpactsWrap: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 -8px",
    outline: "none",
  },
  myImpact: {
    width: "150px",
    margin: "0 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    outline: "0 !important",
    border: "none",
  },
  myImpactImgWrap: {
    width: 52,
    height: 50,
    margin: "0 auto 5px",
  },
  myImpactImg: {
    width: "100%",
  },
  myImpactTxt: {
    display: "block",
  },
  myCommunity: {
    borderBottom: "2px solid #F8B34C",
    padding: "15px 0",
    fontSize: 20,
    color: "#F8B34C",
  },
  myCommunityContentWrap: {
    display: "flex",
    justifyContent: "space-between",
  },
  myCommunityImg: {
    width: 100,
  },
  inviteBtn: {
    paddingTop: 15,
    width: 150,
  },
  myCommunityPoints: {
    listStyle: "none",
    margin: "0 0 0 10px",
    padding: 0,
  },
  inviteButton: {
    fontSize: 9,
    fontWeight: "bold",
    lineHeight: 1,
  },
  myRegion: {
    borderBottom: "2px solid #F8B34C",
    padding: "15px 0",
  },
  mapImg: {
    width: 130,
    margin: "0 auto",
  },
  myYaags: {
    padding: "15px 0",
  },

  bottomNavigation: {
    position: "absolute",
    width: "100%",
  },
  tabName: {
    color: "#fff",
    textTransform: "none",
  },
}));

const VolunteerScreen = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [campaignList, setCampaignList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [value, setValue] = React.useState(0);
  const [activeTrue, setActiveTrue] = React.useState(false);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const userid = localStorage.getItem("userid");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getVolunteerCamp = async () => {
    try {
      
      const res = await axios.get(
        `vol_dashboard/camp/${profileResponse?.orgrole}/${profileResponse?.organization}`
        // `vol_dashboard/camp/${profileResponse?.orgrole}/${profileResponse?.organization}`
      );
      setCampaignList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };
  // const getVolunteerCampDetails = async () => {
  //   try {
  //     
  //     const res = await axios.get(`vol_dashboard/camp/${profileResponse?.campid}/${profileResponse?.orgrole}/${profileResponse?.organization}`
  //     );
  //     setCampaignList(res?.data);
  //     return res;
  //   } catch (err) {
  //     throw err;
  //   }
  // };
  useEffect(() => {
    dispatch(userProfile(userid));
    dispatch(getVolunteerCount());
    getVolunteerCamp();
    // getVolunteerCampDetails();
  }, []);
  
  return (
    <div>
      {/* <CustomDrawer {...props} menuItems={volunteerMenuItems} /> */}
      <ResponsiveDrawer {...props} />
      <TopHeaderScreen {...props} />
      <main
        onClick={() => {
          props.setNotificationShow(false);
        }}
        className={classes.homeSreenMainContent}
        id="homepage-main"
      >
        {/* <div>
        {JSON.stringify(campaignList)}
        </div> */}
        <div className={classes.homeScreenContainer}>
          <div className={classes.myImpacts}>
            <Tabs
              style={{
                background: "#1A524D 0% 0% no-repeat padding-box",
                width: 585,
                borderRadius: 20,
                color: "white"
              }}
              value={value}
              onChange={handleChange}
            >
              <Tab label="Upcoming Campaigns" className={classes.tabName} />
              <Tab label="Active Campaigns" className={classes.tabName} />
              <Tab label="Completed Campaigns" className={classes.tabName} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <div className={classes.myImpacts}>
                <GridList className={classes.gridList} cellHeight={450}>
                  {campaignList
                    ?.filter((item, index) => item.active === "Upcoming")
                    .map((tile) => (
                      <GridListTile
                        style={{ width: "auto !important" }}
                        key={tile.image}
                      >
                        <HomeCard
                          data={tile}
                          key={tile.cardTitle}
                          userDetail={profileResponse ? profileResponse : ""}
                        />
                      </GridListTile>
                    ))}
                </GridList>
              </div>

              {activeTrue && (
                <div className={classes.myImpacts}>
                  <GridList className={classes.gridList} cellHeight={450}>
                    <GridListTile
                      style={{
                        width: "300px",
                        height: "300px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // backgroundColor: "rgba(0,0,0,0.3)",
                      }}
                      // key={tile.image}?
                    >
                      <Typography style={{ color: "#fff" }}>
                        No Active campaigns
                      </Typography>
                    </GridListTile>
                  </GridList>
                </div>
              )}
            </TabPanel>
            
            <TabPanel value={value} index={1}>
              <div className={classes.myImpacts}>
                <GridList className={classes.gridList} cellHeight={450}>
                  {campaignList
                    ?.filter((item, index) => item.active === "Active")
                    .map((tile) => (
                      <GridListTile
                        // style={{ width: "auto !important" }}
                        style={{ width: "300px" }}
                        key={tile.image}
                        // style={{
                        //   width: "300px",
                        //   height: "300px",
                        //   display: "flex",
                        //   justifyContent: "center",
                        //   alignItems: "center",
                        //   // backgroundColor: "rgba(0,0,0,0.3)",
                        // }}
                      >
                        <HomeCard
                          data={tile}
                          key={tile.cardTitle}
                          userDetail={profileResponse ? profileResponse : ""}
                        />
                      </GridListTile>
                    ))}
                </GridList>
              </div>
            </TabPanel>

            <TabPanel value={value} index={2}>
              <div className={classes.myImpacts}>
                <GridList className={classes.gridList} cellHeight={450}>
                  {campaignList
                    ?.filter((item, index) => item.active === "Completed")
                    .map((tile) => (
                      <GridListTile
                        style={{ width: "auto !important" }}
                        key={tile.image}
                      >
                        <HomeCard
                          data={tile}
                          key={tile.cardTitle}
                          userDetail={profileResponse ? profileResponse : ""}
                        />
                      </GridListTile>
                    ))}
                </GridList>
              </div>
            </TabPanel>
          </div>
        </div>
      </main>
      <div style={{marginTop: 500}}>
        <CopyRight companyName={"cYAAG"} />
      </div>
      
    </div>
  );
};

export default VolunteerScreen;
