import * as React from "react";
import FormControl from "@mui/material/FormControl";
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Avatar,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import {
  onboardActivityReset,
  onboardBulkReset,
  onboardCampaign,
  onboardCampaignReset,
  onboardIndividualReset,
  onboardOrganization,
  onboardOrganizationReset,
} from "../../redux/action/OnboardAction";
import CallingCode from "../../services/CallingCode";
import { fetchCityAndState } from "../../services/commonServices";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";
import { API_URL } from "../../services/Constants";
import { getOrganization } from "../../redux/action/getOrganization";
import {
  activityDuration,
  campaignType,
  restrictedCountry,
  status,
} from "../../utils/dict";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { getCity } from "../../redux/action/cityAction";
import { states } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link } from "react-router-dom";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    fontSize: 17,
    height: 54,
    backgroundColor: "#FFFFFF",
    // boxShadow: "0px 3px 6px #00000029",
    // color: "#949494",
    color: "#0F0F0F",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {},
    display: "block",
    fontSize: 16,
    marginTop: 16,
    padding: 10,
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {},
    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  // formGroup: {
  //   "& .react-datepicker__input-container": {
  //     "& input": {
  //       height: 40,
  //     },
  //   },

  //   transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
  //   backgroundColor: "#fff",

  //   borderRadius: "8px",
  //   marginBottom: "4px",
  //   padding: "0 16px 0px 16px",
  //   pageBreakInside: "avoid",
  // },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    // padding: "0 16px 0px 16px",
    padding: "0 0px 0px 16px",
    pageBreakInside: "avoid",
  },
}));

function OrganizationViewScreen(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = React.useRef(null);
  const videoRef = React.useRef(null);

  const locationPath = useLocation();

  const [reviewstatus, setReviewStatus] = React.useState("");
  const [reviewComments, setReviewComments] = React.useState("");
  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "" },
  ]);

  const [focusArea, setFocusArea] = React.useState([
    { focus: "Water & Sanitation" },
    { focus: "Education" },
    { focus: "Climate Change and Sustainability" },
    { focus: "Poverty Alleviation" },
    { focus: "Teaching / Mentoring" },
    { focus: "Rural Development" },
    { focus: "Women Empowerment" },
    { focus: "Child Rights" },
    { focus: "Animal Rights" },
  ]);

  const [areas, setAreas] = React.useState([]);
  const [notificationShow, setNotificationShow] = React.useState(false);
  const [activityList, setActivityList] = React.useState(undefined);
  const [orgValues, setOrgValues] = React.useState({
    titleOfOrganization: "",
    totalStaff: "",
    headquarters: "",
    organizationLogo: "",
    registeredAs: "",
    website: "",
    emailId: "",
    contactNo: "",
    totalNumberofVolunteers: "",
    chiefFunctionary: "",
  });
  const {
    isCampaignSuccess,
    isCampaignFetching,

    isCampaignError,
  } = useSelector((state) => state.onBoard);

  const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
    (state) => state.cities
  );

  function subtractYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);

    return date;
  }

  const handleOnboardOrganization = async () => {
    const payload = {
      clientid: locationPath?.state?.clientid,
      createdate: locationPath?.state?.createdate,
      reviewstatus: reviewstatus,
      reviewcomments: reviewComments,
    };
    try {
      const res = await axios.put(
        `/org/review/${localStorage.getItem("userid")}`,
        payload
      );
      navigate("/revieworganization");
      return res;
    } catch (err) {
      throw err;
    }
  };

  const handleCampaignFieldChange = (field, e) => {
    (isCampaignError || isCampaignSuccess) && dispatch(onboardCampaignReset());
    setOrgValues({
      ...orgValues,
      [field]: e.target.value,
    });
  };

  const unitOfCampaign = activityList?.filter(
    (item, index) => item?.activity === orgValues?.typeOfCampaign
  );

  const onAddingItem = (event, option, index) => {
    const values = [...focusArea];
    values[index].isChecked = event.target.checked;
    setFocusArea(values);
  };

  const handleTextFieldCheckbox = (event, index) => {
    const values = [...focusArea];
    values[index].rewards = event.target.value;
    setFocusArea(values);
  };

  const handleChangeLocation = (id, e, field, index) => {
    const values = [...location];
    values[index][field] = e.target.value;
    setLocation(values);
  };

  const handleAddFields = () => {
    setLocation([...location, { country: "", state: "", city: "", area: "" }]);
  };

  const getAddress = (item, index) => {
    return (
      <div style={{ float: "left", width: "100%", marginTop: 25 }}>
        <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>
          {" "}
          Operational Areas{" "}
        </span>
        <form style={{ display: "flex", flexWrap: "wrap" }}>
          <div
            className={classes.formGroup1} >
            <label htmlFor="create-yaad--title" className={classes.label}>
              Country
            </label>

            <input
              disabled
              value="India"
              type="text"
              className={classes.input}
            />
          </div>

          <div
            className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              State
            </label>
            <input
              disabled
              value={item.state}
              type="text"
              className={classes.input}
            />
          </div>
          <div
            className={classes.formGroup1} >
            <label htmlFor="create-yaad--title" className={classes.label}>
              City/Town/Village
            </label>
            <input
              disabled
              value={item.city}
              type="text"
              className={classes.input}
            />
          </div>
          <div
            className={classes.formGroup1} >
            <label htmlFor="create-yaad--title" className={classes.label}>
              Area
            </label>

            <input
              disabled
              value={item.area}
              type="text"
              id="create-yaad--title"
              className={classes.input}
              //placeholder="Your Answer"
              onChange={(e) => handleChangeLocation(item, e, "area", index)}
            />
          </div>
        </form>
      </div>
    );
  };

  React.useEffect(() => {
    dispatch(onboardBulkReset());
    dispatch(onboardIndividualReset());
    dispatch(onboardActivityReset());
    dispatch(onboardCampaignReset());
    dispatch(onboardOrganizationReset());
  }, []);

  React.useEffect(() => {
    if (locationPath?.state) {
      setOrgValues({
        titleOfOrganization: locationPath?.state?.organization,
        totalStaff: locationPath?.state?.noofemployees,
        headquarters: locationPath?.state?.orghq,
        registeredAs: locationPath?.state?.registeredas,
        website: locationPath?.state?.website,
        emailId: locationPath?.state?.emailid,
        chiefFunctionary: locationPath?.state?.chieffunctionary,
        contactNo: locationPath?.state?.contactnumber,
        orgFocusArea: locationPath?.state?.orgfocusarea,
        totalNumberofVolunteers: locationPath?.state?.noofvol,
        organizationLogo: locationPath?.state.organizationlogo,
      });
      setLocation(locationPath?.state?.location);
      // setFocusArea(JSON.parse(locationPath?.state?.rewardstobeearned))
    }
  }, [locationPath?.state]);

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Organization Profile
            </div>
            <form autoComplete="off">
              <div
                style={{
                  border: "1px solid #dadce0",
                  borderRadius: 8,
                  padding: "13px 30px 40px 30px",
                  backgroundColor: "#fff",
                }}
              >
                {/* <label className={classes.label}>Organization Profile</label> */}
                <br></br>
                

                <div class="list-img">
                  <img
                    src={orgValues?.organizationLogo}
                    style={{
                      // width: 190,
                      zIndex: 99999,
                      marginTop: 5,
                      maxHeight: 90,
                      // marginLeft: 605,
                      alignSelf: "center",
                      // position: "fixed",
                      // display: "flex",
                    }}
                  />
                </div>

                {/* <br></br> */}
                {/* <br></br> */}
                {/* <br></br>
            <br></br> */}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <div className={classes.formGroup}>
                    <label className={classes.label}>
                      Name of Organisation
                    </label>
                    <div
                      className={classes.input}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <span> {orgValues?.titleOfOrganization} </span>
                      {/* <input
                  disabled
                  value={orgValues?.titleOfOrganization}
                  type="text"
                  className={classes.input}
                  //placeholder="Your answer"
                  onChange={(e) => {
                    handleCampaignFieldChange("titleOfOrganization", e);
                  }}
                /> */}
                    </div>
                  </div>

                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Headquarter's
                    </label>
                    <div
                      className={classes.input}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <span> {orgValues?.headquarters} </span>
                      {/* <input
                  disabled
                  value={orgValues?.headquarters}
                  type="text"
                  className={classes.input}
                  //placeholder="Your answer"
                  onChange={(e) => {
                    handleCampaignFieldChange("headquarters", e);
                  }}
                /> */}
                    </div>
                  </div>
                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Website
                    </label>
                    <div
                      className={classes.input}
                      style={{
                        // display: "flex",
                        display: "contents",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <span> {orgValues?.website} </span>
                      {/* <input
                  disabled
                  value={orgValues?.website}
                  type="text"
                  className={classes.input}
                  //placeholder="Your answer"
                  onChange={(e) => {
                    handleCampaignFieldChange("website", e);
                  }}
                /> */}
                    </div>
                  </div>
                  {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Name of Chief Functionary
                    </label>

                    <div
                      className={classes.input}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <span> {orgValues?.chiefFunctionary} </span>

                      {/* <input
                  disabled
                  value={orgValues?.chiefFunctionary}
                  type="text"
                  id="create-yaad--title"
                  className={classes.input}
                  //placeholder="Your answer"
                  onChange={(e) => {
                    handleCampaignFieldChange("chiefFunctionary", e);
                  }}
                /> */}
                    </div>
                  </div>
                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Contact Number
                    </label>
                    <div
                      className={classes.input}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <span> {orgValues?.contactNo} </span>
                      {/* <input
                    disabled
                    value={orgValues?.contactNo}
                    type="number"
                    id="create-yaad--title"
                    className={classes.input}
                    //placeholder="Your Answer"
                    onChange={(e) => {
                      handleCampaignFieldChange("contactNo", e);
                    }}
                  /> */}
                      <span style={{ marginLeft: 4 }}>
                        {unitOfCampaign?.[0]?.unit}
                      </span>
                    </div>
                  </div>

                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Email Id
                    </label>
                    <div
                      className={classes.input}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <span> {orgValues?.emailId} </span>
                      {/* <input
                    disabled
                    value={orgValues?.emailId}
                    type="text"
                    id="create-yaad--title"
                    className={classes.input}
                    //placeholder="Your Answer"
                    onChange={(e) => {
                      handleCampaignFieldChange("emailId", e);
                    }}
                  /> */}
                    </div>
                  </div>

                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Total Staff
                    </label>

                    <div
                      className={classes.input}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <span> {orgValues?.totalStaff} </span>
                      {/* <input
                    disabled
                    value={orgValues?.totalStaff}
                    type="text"
                    id="create-yaad--title"
                    className={classes.input}
                    //placeholder="Your Answer"
                    onChange={(e) => {
                      handleCampaignFieldChange("totalStaff", e);
                    }}
                  /> */}
                    </div>
                  </div>

                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Total No. of Volunteers
                    </label>
                    {/* {JSON.stringify(orgValues)} */}

                    <div
                      className={classes.input}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <span> {orgValues?.totalNumberofVolunteers} </span>
                      {/* <input
                    disabled
                    value={orgValues?.totalNumberofVolunteers}
                    type="text"
                    id="create-yaad--title"
                    className={classes.input}
                    //placeholder="Your Answer"
                    onChange={(e) => {
                      handleCampaignFieldChange("totalNumberofVolunteers", e);
                    }}
                  /> */}
                    </div>
                  </div>
                  <div className={classes.formGroup} style={{ width: "100%" }}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Focus Thematic Areas
                    </label>
                    <div
                      className={classes.input}
                      style={{
                        // display: "flex",
                        // flexDirection: "row",
                        // alignItems: "flex-end",
                      }}
                    >

                    {
                      // focusArea?.map((item, index) =>
                      orgValues?.orgFocusArea?.map((item, index) => (
                        <>
                        {/* <span  style={{ padding: "10px", fontSize:"12px"}} > */}
                        <span   >
                        {item?.focus}{((index == (item?.focus?.length-1)) ? '' : ', ')}
                          {/* {" "} */}
                          {/* {index ? ", " : ""} {item.focus}{" "} */}
                          {/* <span >{item?.focus}{((index == (item?.focus?.length-1)) ? '' : ',')}</span> */}
                        </span>
                        </>
                      ))
                    }
                    </div>

                    {/* {focusArea?.map((item, index) => ( */}
                    {/* <div style={{ display: "flex" }}> */}
                    {/* <input
                      disabled
                      style={{ fontStyle: "normal" }}
                      type="checkbox"
                      id={item.focus}
                      value={item.focus}
                      checked={item.isChecked}
                      onChange={(e) => onAddingItem(e, item, index)}
                    /> */}
                    {/* <div> */}
                    {/* <label className={classes.label1} htmlFor={item.focus}>
                        {item.focus}
                      </label> */}
                    {/* {item.value === "Any Other" && (
                        <input
                          disabled
                          // className={classes.input}
                          style={{ fontStyle: "normal" }}
                          type="text"
                          onChange={(e) => handleTextFieldCheckbox(e, index)}
                        />
                      )} */}
                    {/* </div> */}
                    {/* </div> */}
                    {/* ))} */}
                  </div>

                  {/* <div className={classes.formGroup}> */}
                    {location.map((item, index) => (
                      <div key={index}>{getAddress(item, index)}</div>
                    ))}
                    
                  {/* </div> */}
                </div>
              </div>
            </form>

            {/* <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            padding: "16px 0",
            bottom: 50,
            background: "#2D7A74",
            marginTop: 12,
          }}
        >
          <div
            style={{
              display: "flex",
              width: "70%",
              flexDirection: "column",
            }}
          >
            <span style={{ color: "white" }}>Comments</span>
            <textarea
              style={{ height: 100, borderRadius: 8, padding: 8 }}
              placeholder="Comments"
              onChange={(e) => setReviewComments(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>Status</span>
              <Select
                style={{
                  width: 200,
                  fontSize: 14,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  background: "#fff",
                }}
                onChange={(e) => setReviewStatus(e.target.value)}
              >
                {status?.map((item, index) => (
                  <MenuItem style={{ fontSize: 16 }} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <Button
              style={{
                marginTop: 10,
                backgroundColor: "#E88A00",
                color: "#fff",
                fontFamily: "Poppins !important",
                width: "100%",
              }}
              variant="contained"
              onClick={handleOnboardOrganization}
              className={classes.goalButton}
            >
              {isCampaignFetching ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                "Submit"
              )}
            </Button>
          </div>

          <br />
          {isCampaignSuccess && (
            <span style={{ color: "green" }}>
              Campaign onboarded sucessfully
            </span>
          )}
          {isCampaignError && (
            <span style={{ color: "red" }}>Something error occured</span>
          )}
        </div> */}
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}
export default OrganizationViewScreen;
