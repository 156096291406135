/** @format */

import { call, put } from "redux-saga/effects";
import { get } from "lodash";

import {
    ONBOARD_REWARDS_FETCHING_DATA,
    ONBOARD_REWARDS_FETCHING_DATA_FAILURE,
    ONBOARD_REWARDS_FETCHING_DATA_SUCCESS,
} from "../action/onboardRewardsAction";
import { Api } from "../../services";

const myAPI = new Api();

export function* onboardRewards(action) {
  yield put({
    type: ONBOARD_REWARDS_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.onboardRewards, action.payload);
    yield put({
      type: ONBOARD_REWARDS_FETCHING_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    yield put({ type: ONBOARD_REWARDS_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}