import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  Button,
  CircularProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles,
  TableFooter,
  TablePagination,
  DialogActions,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  Checkbox,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { purchaseYaag } from "../redux/action/PurchaseYaag";
import { validateReward } from "../redux/action/ValidatorAction";
import moment from "moment";
import { validatorList } from "../redux/action/ValidatorList";
import axios from "axios";
import ActionAlert from "./ActionAlert";
import { bindActionCreators } from "redux";
import AssessmentIcon from "@mui/icons-material/Assessment";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { rewardsRedeemedList } from "../redux/action/RewardsRedeemedList";
import { rewardRedeemFulfilment } from "../redux/action/RewardRedeemFulfilmentAction";
import UploadRedeemedCertificate from "./UploadRedeemedCertificate";

const useStyles = makeStyles((theme) => ({
  homeSreenManiContent: {
    padding: "100px 0 0 20px",
    [theme.breakpoints.up("sm")]: {
      padding: "100px 0 0 220px",
    },
  },
  homeScreenContainer: {
    padding: "0 20px",
  },
  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginRight: 10,
    color: "#fff",
  },
  table: {
    width: "100%",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8B34C",
    color: "#ffffff",
    textTransform: "uppercase",
  },
  body: {
    fontSize: 14,
  },
  tbodyText: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    // width: "200px",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // wordWrap: "breakWord",
    // overflow: "scroll",
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    // '&:nth-of-type(odd)': {
    // 	backgroundColor: '#f7dbb3',
    // },
    // '&:nth-of-type(even)': {
    // 	backgroundColor: '#e9e7e8',
    // },
  },
}))(TableRow);


export const ValidateRewardsRedeemed = (props) => {
  const dispatch = useDispatch();
  const [checkedAllValue, setCheckedAllValue] = useState();
  const classes = useStyles();
  const [countValue, setCountValue] = useState(true);
  const tableRef = useRef(null);
  const [author, setAuthor] = useState([]);
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [reviewComments, setReviewComments] = React.useState("");
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [campaignList, setCampaignList] = useState([
    "firstname",
    "lastname",
    "phoneno",
    "organization",
    "orgrole",
  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  const { isSuccess: userreviewlistSuccess, response: userreviewlistResponse } =
    useSelector((state) => state.rewardsRedeemedList);
  console.log(userreviewlistResponse);

  const validationList1 = async () => {
    try {
      const res = await axios.get(
        `/fulfilment/reward/${profileResponse?.phoneno}`
      );
      const vaa = await res?.data;
      //setCampaignList(oldau => [...oldau, vaa]);
      return res;
    } catch (err) {
      throw err;
    }
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };
  useEffect(() => {
    dispatch(rewardsRedeemedList());
    setAuthor(props);
    validationList1();

    var chck = document.getElementsByName("vehicleParent");
    for (var ch of chck) {
      ch.checked = false;
    }
    var checkboxes = document.getElementsByName("vehicle");
    for (var checkbox of checkboxes) {
      checkbox.checked = false;
    }
  }, [userreviewlistSuccess]);

  const xunique = [
    ...new Map(props.rows.map((item) => [item["phoneno"], item])).values(),
  ];
  console.log(props.rows);
  console.log(xunique);
  const handleClick = (id, subStatus) => {
    if (subStatus === "close" || subStatus === "Close") {
      console.log((id.status = subStatus));
      console.log(JSON.stringify(props));
    }
  };

  let temp = [];
  const handleBulkClick = (selectedItem, selectionName) => {
    console.log("start of handleBulkClick")
    console.log("selectedItem = ", selectedItem);
    console.log("selectionName = ", selectionName);
    if (
      props.rows.filter((item) => item.status === "open").length ===
      [].slice
        .call(document.querySelectorAll("[name='vehicle']"))
        .filter(function (e) {
          return e.checked;
        }).length
    ) {
      document.getElementsByName("vehicleParent")[0].checked = true;
    } else {
      document.getElementsByName("vehicleParent")[0].checked = false;
    }
    console.log(selectionName === "bulkSelection");
    if (selectionName === "bulkSelection") {
      console.log("cominginside========");
      let i = 0;
      for (let i = 0; i < selectedItem.length; i += 1) {
        const formattedArray = [];
        console.log("Phoneno ==", selectedItem[i].phoneno);
        console.log(selectedItem[i]);
        formattedArray.push(
          selectedItem[i].id,
          selectedItem[i].firstname,
          selectedItem[i].lastname,
          selectedItem[i].phoneno,
          selectedItem[i].organization,
          selectedItem[i].rewardtype,
          selectedItem[i].title,
          selectedItem[i].subtitle,
          selectedItem[i].points,
          moment(
            new Date()
          ).format("DD-MM-yyyy"),
          selectedItem[i].status,
          selectedItem[i].mpid
        );
        temp.push(createDataValidator(...formattedArray));
        console.log(temp.length);
      }
    } else {
      console.log("temp value first", temp);
      var positionVal = temp
        .map((e) => e.phoneno)
        .indexOf(selectedItem.phoneno);
      console.log("position val===", positionVal);
      if (temp.map((e) => e.phoneno).indexOf(selectedItem.phoneno) > -1) {
        console.log("======== slice inside before =====");
        console.log(temp);
        temp.splice(positionVal, 1);
        console.log("======== slice inside after =====");
        console.log(temp);
      } else {
        console.log("====== coming else part ========");
        const formattedArray1 = [];
        formattedArray1.push(
          selectedItem.id,
          selectedItem.firstname,
          selectedItem.lastname,
          selectedItem.phoneno,
          selectedItem.organization,
          selectedItem.rewardtype,
          selectedItem.title,
          selectedItem.subtitle,
          selectedItem.points,
          moment(
            new Date()
          ).format("DD-MM-yyyy"),
          selectedItem.status,
          selectedItem.mpid
        );
        if (document.querySelectorAll("[name='vehicle']:checked").length > 0) {
          // document.getElementById("acceptAllUser").disabled = true;
        } else {
          // document.getElementById("acceptAllUser").disabled = false;
        }
        temp.push(createDataValidator(...formattedArray1));
      }
    }

    console.log("======== Temp array value=====");
    console.log(temp);
  };

  const checkAllValue = () => {
    temp = [];
    console.log("CheckAllValue");
    var checkboxes = document.getElementsByName("vehicle");
    if (document.getElementsByName("vehicleParent")[0].checked) {
      for (var checkbox of checkboxes) {
        checkbox.checked = true;
      }
      handleBulkClick(
        props.rows.filter((item) => item.status === "open"),
        "bulkSelection"
      );
    } else {
      for (var checkbox of checkboxes) {
        checkbox.checked = false;
      }
      handleBulkClick([], "bulkSelection");
    }
    props.rows.filter((item) => item.status === "open");
  };

  const acceptAll = (selectedVal, statusVal) => {
    console.log(selectedVal);

    if (selectedVal.length == 0) {
      setDialogOpen(true);
      return false;
    }
    let op = selectedVal.map((e, i) => {
      let temp = props.rows.find((element) => element.id == e.id);
      console.log(temp);
      if (temp) {
        temp.status = statusVal;
        setCheckedAllValue(false);
      }
    });
    var checkboxes = document.getElementsByName("vehicle");
    for (var checkbox of checkboxes) {
      checkbox.checked = false;
    }
    temp = temp.map((entry) => ({ ...entry, status: statusVal }));
    // props.rows = temp;
    console.log(temp);
    dispatch(
      rewardRedeemFulfilment({
        // phoneno: temp.phoneno,
        // mpid: temp.mpid,
        temp,
        userid: localStorage.getItem("userid"),
      })
    );
    console.log(JSON.stringify(props));
  };

  console.log(temp);

  function createDataValidator(
    id,
    firstname,
    lastname,
    phoneno,
    organization,
    rewardtype,
    title,
    subtitle,
    points,
    rewarddate,
    status,
    mpid
  ) {
    return {
      id,
      firstname,
      lastname,
      phoneno,
      organization,
      rewardtype,
      title,
      subtitle,
      points,
      rewarddate,
      status,
      mpid,
    };
  }
  console.log("props.value1", props.value1);
  console.log("props.rows", props.rows);
  console.log("props", props);

  return (
    <TableContainer
      ref={tableRef}
      component={Paper}
      style={{ padding: 36, paddingLeft: 0, paddingTop: 0 }}
      class="registrationbody"
    >
      {!_.isEmpty(props.rows) ? (
        <div>
          <div class="registrationbody">
            <div>
              <Autocomplete
                disableClearable={props.value1 == ""}
                id="controllable-states-demo"
                value={props.value1}
                onChange={(event, newValue) => {
                  props.setValue1(newValue);
                }}
                inputValue={props.inputValue}
                onInputChange={(event, newInputValue) => {
                  props.setInputValue(newInputValue);
                }}
                options={xunique}
                getOptionLabel={(option) => option.firstname}
                style={{ width: 200 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=<i>Search Change Maker </i>
                    variant="outlined"
                  />
                )}
              />
            </div>
            {props.rows[0].status === "open" ? (
              <div>
                <StyledTableCell
                  style={{
                    textAlign: "center",
                    borderBottom: "0px",
                    backgroundColor: "transparent",
                  }}
                >
                  {/* <textarea
                    style={{
                      height: 70,
                      borderRadius: 8,
                      padding: 28,
                    }}
                    id="commentsId"
                    placeholder="Comments"
                    maxLength="300"
                    onChange={(e) =>
                      setReviewComments(e.target.value)
                    }
                  /> */}
                  </StyledTableCell>
                  <StyledTableCell
                  
                  style={{
                    textAlign: "center",
                    borderBottom: "0px",
                    backgroundColor: "transparent",
                  }}
                  >
                  <Button
                    className={classes.goalButton}
                    id="acceptAllUser"
                    variant="contained"
                    //disabled={countValue}
                    style={{
                      backgroundColor: "#16a654",
                      minWidth: 110,
                      padding: "7px 0px",
                    }}
                    onClick={() => {
                      acceptAll(temp, "close");
                      // dispatch(rewardRedeemFulfilment());
                      dispatch(rewardsRedeemedList());
                      validationList1();
                    }}
                  >
                    Submit All
                  </Button>
                </StyledTableCell>
                
                <Dialog
                  open={dialogOpen}
                  onClose={handleCancel}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogContent>
                    <DialogContentText>
                      Please select Atleast one item.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <button class="btn btn-primary" onClick={handleCancel}>
                      Ok
                    </button>
                  </DialogActions>
                </Dialog>
              </div>
            ) : (
              ""
            )}
          </div>

          <div class="tableContainer" style={{ overflowY: "scroll" }}>
            <Table className={`${classes.table} customizedTableWidth`} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {props.rows[0].status === "open" ? (
                    <StyledTableCell style={{ textAlign: "center", width: 50 }}>
                      <input
                        type="checkbox"
                        value="item"
                        name="vehicleParent"
                        onClick={() => {
                          checkAllValue();
                        }}
                      ></input>
                    </StyledTableCell>
                  ) : (
                    ""
                  )}
                  
                  <StyledTableCell style={{ textAlign: "center" }}>
                    Volunteer First Name
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    Volunteer Last Name
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    Volunteer Phone no
                  </StyledTableCell>

                  <StyledTableCell style={{ textAlign: "center" }}>
                    Organization
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    Reward Type
                  </StyledTableCell>
                  <StyledTableCell className="extendContent" style={{ textAlign: "center" }}>
                    Reward Title
                  </StyledTableCell>
                  <StyledTableCell className="extendContent" style={{ textAlign: "center" }}>
                    Reward Subtitle
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    Fulfilment Status
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    Upload Certificate
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              {props.isFetching ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <TableBody>
                  
                  {props.inputValue === ""
                    ? props.rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item, index) => (
                          <StyledTableRow>
                            {props.rows[0].status === "open" ? (
                              <StyledTableCell
                                style={{ textAlign: "center", width: 50 }}
                              >
                                <input
                                  type="checkbox"
                                  id={item.id}
                                  value="item"
                                  onClick={() => {
                                    handleBulkClick(item, "singleSelection");
                                  }}
                                  name="vehicle"
                                ></input>
                              </StyledTableCell>
                            ) : (
                              ""
                            )}
                            <StyledTableCell style={{ textAlign: "center" }}>
                              {item.firstname}
                            </StyledTableCell>
                            <StyledTableCell style={{ textAlign: "center" }}>
                              {item.lastname}
                            </StyledTableCell>
                            <StyledTableCell style={{ textAlign: "center" }}>
                              {item.phoneno}
                            </StyledTableCell>
                            <StyledTableCell style={{ textAlign: "center" }}>
                              {item.organization}
                            </StyledTableCell>
                            <StyledTableCell style={{ textAlign: "center" }}>
                              <span class="truncateTwoLine fullSpan" title= {item.rewardtype}> {item.rewardtype}</span>
                            </StyledTableCell>
                            <StyledTableCell style={{ textAlign: "center" }}>
                              <span class="truncateTwoLine fullSpan" title={item.title}>{item.title}</span>
                            </StyledTableCell>
                            <StyledTableCell style={{ textAlign: "center" }}>
                            
                             <span class="truncateTwoLine fullSpan" title= {item.subtitle}>{item.subtitle}</span>
                            </StyledTableCell>
                            <StyledTableCell style={{ textAlign: "center" }}>
                              {item.status.charAt(0).toUpperCase() +
                                item.status.slice(1)}
                            </StyledTableCell>
                            <StyledTableCell style={{ textAlign: "center" }}>
                              
                              <UploadRedeemedCertificate
                                phoneno={item.phoneno}
                                rewardtype={item.rewardtype}
                                mpid={item.mpid}
                              />
                            </StyledTableCell>

                            {!props.actionTaken && (
                              <div>
                                <StyledTableCell
                                  style={{
                                    textAlign: "center",
                                    borderBottom: "0px",
                                    padding: "48px 0px 0px 0px",
                                  }}
                                >
                                  {/* <textarea
                                    style={{
                                      height: 70,
                                      borderRadius: 8,
                                      padding: 28,
                                    }}
                                    id="commentsId"
                                    placeholder="Comments"
                                    maxLength="300"
                                    onChange={(e) =>
                                      setReviewComments(e.target.value)
                                    }
                                  /> */}
                                  <Button
                                    className={classes.goalButton}
                                    variant="contained"
                                    style={{
                                      backgroundColor: "#16a654",
                                      maxWidth: "70px",
                                    }}
                                    onClick={() => {
                                      Promise.all([
                                        dispatch(
                                          rewardRedeemFulfilment({
                                            temp:[{
                                            phoneno: item.phoneno,
                                            mpid: item.mpid,
                                            rewarddate: moment(
                                              new Date()
                                            ).format("DD-MM-yyyy"),

                                          }],
                                            userid:
                                              localStorage.getItem("userid"),
                                          })
                                        ),
                                      ])
                                        
                                        .then(() => handleClick(item, "close"))
                                        .then(() =>
                                          dispatch(rewardsRedeemedList())
                                        );

                                      validationList1();
                                    }}
                                  >
                                    Submit
                                  </Button>
                                  
                                </StyledTableCell>
                              </div>
                              // ))
                            )}
                          </StyledTableRow>
                        ))
                    : props.sortedData.map((item, index) => (
                        <StyledTableRow>
                          {props.rows[0].status === "open" ? (
                              <StyledTableCell
                                style={{ textAlign: "center", width: 50 }}
                              >
                                <input
                                  type="checkbox"
                                  id={item.phoneno}
                                  value="item"
                                  onClick={() => {
                                    handleBulkClick(item, "singleSelection");
                                  }}
                                  name="vehicle"
                                ></input>
                              </StyledTableCell>
                            ) : (
                              ""
                            )}
                        {/* //   <StyledTableCell style={{ textAlign: "center" }}>
                        //     {item.firstname}
                        //   </StyledTableCell>
                        //   <StyledTableCell style={{ textAlign: "center" }}>
                        //     {item.lastname}
                        //   </StyledTableCell>
                        //   <StyledTableCell style={{ textAlign: "center" }}>
                        //     {item.phoneno}
                        //   </StyledTableCell>
                        //   <StyledTableCell style={{ textAlign: "center" }}>
                        //     {item.organization}
                        //   </StyledTableCell>
                        //   <StyledTableCell style={{ textAlign: "center" }}>
                        //     {item.status.charAt(0).toUpperCase() +
                        //       item.status.slice(1)}
                        //   </StyledTableCell> */}
                        <StyledTableCell style={{ textAlign: "center" }}>
                              {item.firstname}
                            </StyledTableCell>
                            <StyledTableCell style={{ textAlign: "center" }}>
                              {item.lastname}
                            </StyledTableCell>
                            <StyledTableCell style={{ textAlign: "center" }}>
                              {item.phoneno}
                            </StyledTableCell>
                            <StyledTableCell style={{ textAlign: "center" }}>
                              {item.organization}
                            </StyledTableCell>
                            <StyledTableCell style={{ textAlign: "center" }}>
                              {item.rewardtype}
                            </StyledTableCell>
                            <StyledTableCell style={{ textAlign: "center" }}>
                              {item.title}
                            </StyledTableCell>
                            <StyledTableCell style={{ textAlign: "center" }}>
                              {item.subtitle}
                            </StyledTableCell>
                            <StyledTableCell style={{ textAlign: "center" }}>
                              {item.status.charAt(0).toUpperCase() +
                                item.status.slice(1)}
                            </StyledTableCell>
                            <StyledTableCell style={{ textAlign: "center" }}>
                              
                              <UploadRedeemedCertificate
                                phoneno={item.phoneno}
                                rewardtype={item.rewardtype}
                                mpid={item.mpid}
                              />
                            </StyledTableCell>
                          {
                            !props.actionTaken &&
                              (item.status === "open" ? (
                                <div>
                                  <StyledTableCell
                                    style={{ textAlign: "center",
                                    borderBottom: "0px",
                                    padding: "48px 0px 0px 0px", }}
                                  
                                  >
                                    <Button
                                      className={classes.goalButton}
                                      variant="contained"
                                      style={{ backgroundColor: "#16a654" }}
                                      onClick={() => {
                                        Promise.all([
                                          dispatch(
                                            rewardRedeemFulfilment({
                                              temp:[{
                                              phoneno: item.phoneno,
                                              mpid: item.mpid,
                                              }],
                                              userid:
                                                localStorage.getItem("userid"),
                                            })
                                          ),
                                        ])
                                          .then(() => handleClick(item, "close"))
                                          .then(() =>
                                            dispatch(rewardsRedeemedList())
                                          );
                                        validationList1();
                                      }}
                                    >
                                      Submit
                                    </Button>
                                  </StyledTableCell>
                                  {/* <StyledTableCell
                                    style={{ textAlign: "center" }}
                                  >
                                    <Button
                                      style={{ backgroundColor: "#a62e2e" }}
                                      className={classes.goalButton}
                                      variant="contained"
                                      onClick={() => {
                                        Promise.all([
                                          dispatch(
                                            rewardRedeemFulfilment({
                                              phoneno: item.phoneno,
                                              mpid: item.mpid,
                                              userid:
                                                localStorage.getItem("userid"),
                                            })
                                          ),
                                        ])
                                          // .then(() => handleClick(item, "rejected"))
                                          .then(() =>
                                            dispatch(rewardsRedeemedList())
                                          );
                                        validationList1();
                                      }}
                                    >
                                      Reject
                                    </Button>
                                  </StyledTableCell> */}
                                </div>
                              ) : null)
                            //  ))
                          }
                        </StyledTableRow>
                      ))}
                </TableBody>
              )}
            </Table>
            <TableFooter>
              <TablePagination
                rowsPerPageOptions={[10, 20, 40]}
                component="div"
                count={props.rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableFooter>
          </div>
          {props.rows[0].status === "open" ? (
            <div>
              {/* <StyledTableCell style={{ textAlign: "center", borderBottom: "0px",backgroundColor:"transparent" }}>
                      
                          <Button
                            className={classes.goalButton}
                            variant="contained"
                            style={{ backgroundColor: "#16a654",minWidth:130, padding: "15px 0px" }}
                            onClick={() => {
                              acceptAll(temp,"approved");
                              dispatch(validatorList());
                            }}
                            
                          >
                            Accept All
                          </Button>
                        

                        </StyledTableCell> */}
              {/* <StyledTableCell style={{ textAlign: "center", borderBottom: "0px",backgroundColor:"transparent" }}>
                        
                          <Button
                            style={{ backgroundColor: "#a62e2e",minWidth:130, padding: "15px 0px" }}
                           
                            className={classes.goalButton}
                            variant="contained"
                            onClick={() => {
                              acceptAll(temp,"rejected");
                              dispatch(validatorList());
                            }}
                          >
                            Reject All
                          </Button>
                         
                        </StyledTableCell> */}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <Typography style={{ textAlign: "center", fontStyle: "italic" }}>
          No data found
        </Typography>
      )}
    </TableContainer>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({}, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ValidateRewardsRedeemed);