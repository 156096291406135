/** @format */

import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import PhoneIcon from "@material-ui/icons/Phone";

import EmailIcon from "@material-ui/icons/Email";
import Person from "@material-ui/icons/Person";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
// import Login from "../assets/Login.png";
import Login from "../assets/Login.png";
import LoginLogo from "../assets/kt.png";
// import LoginLogo from "../assets/logo-black.png";
import { socialLogin } from "../redux/action/loginAction";
import { userProfile } from "../redux/action/UserProfileActions";
import { connect, useDispatch, useSelector } from "react-redux";

import firebase from "../services/FirebaseConfig";
import "../App.css";
import CallingCode from "../services/CallingCode";
import { MenuItem, Select, Radio, FormLabel } from "@material-ui/core";
// import { NotificationManager } from 'react-notifications';
import { useAlert } from "react-alert-template-mui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { restrictedCountry } from "../utils/dict";
import HyphenLogo from "../assets/hyphenLogin.png";
import TopHeaderScreen from "../components/TopHeader1";
import { socialEmailLogin } from "../redux/action/LoginEmailAction";
import axios from "axios";
import { PublicClientApplication } from '@azure/msal-browser';

const useStyles = makeStyles((theme) => ({
  loginPgHeader: {
    // background: "#f6f6f6",
    background:"white",
    paddingTop: "0px",
  },
  loginPgContainer: {
    maxWidth: 500,
    margin: "0 auto",
    padding: "0 0px",
    // background: "#f6f6f6",
    background:"white",
  },

  loginPgLogo: {
    position: "relative",
    width: "100%",
    margin: "0 auto",
    // background: "#f6f6f6",
    background:"white",
    [theme.breakpoints.up("md")]: {
      width: "100%",
      // background: "#f6f6f6",
      background:"white",
    },
  },
  loginPgMainContent: {
    padding: "0px 50px 0px",
    borderRadius: "0px 0px 0 0",
    marginTop: "0px",
    // background: "#f6f6f6",
    background:"white",
    // height: "500px",
  },
  label: {
    marginBottom: 15,
    fontWeight: "bold",
    fontSize: 16,
  },
  labelPhone: {
    marginBottom: 15,
    fontWeight: "bold",
    fontSize: 12,
  },
  containerdiv: {
    Display: "flex",
    Height: "100vh",
    Width: "100vw",

    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    borderRadius: 30,
    fontSize: 14,
    textTransform: "uppercase",
    // padding: 15,
    width: "80%",
    background: "#e1a23b",
    boxShadow: "none",
    fontWeight: "bold",
    marginTop: "10px",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      width: "80%",
    },
  },
  phoneandemailbutton: {
    borderRadius: 30,
    fontSize: 14,
    // textTransform: "uppercase",
    // padding: 7,
    width: "80%",
    background: "#edc789",
    boxShadow: "none",
    // fontWeight: "bold",
    marginTop: "10px",
    // alignItems: "center",
    alignSelf: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      width: "80%",
    },
  },
  formControl: {
    marginBottom: 30,
    textAlign: "center",
  },
  otherSigninOptions: {
    padding: "60px 0 20px",
    textAlign: "center",
  },
  signinOptions: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "stretch",
    margin: "0 -6px",
  },
  option: {
    width: "calc(33.33% - 12px)",
    margin: "0 6px",
    padding: "9px 20px",
    border: "1px solid rgba(0, 0, 0, 0.15)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none !important",
    "&:hover": {
      border: "1px solid #F8B34C",
    },
  },
  optionImage: {
    width: "33px",
  },
  callingInput: {
    width: "36%",
    paddingRight: 4,
    height: 54,
    marginRight: 4,
  },
  phoneNumber: {
    // width: "70%",
    // border: "1px solid rgba(0, 0, 0, 0.26)",
    border: "1px solid rgba(0, 0, 0, 0.26)",
    padding: "0px 0px 0px 6px",
  },
  phoneInput: {
    display: "flex",
    // margin: "0 auto",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop:"5px",
  },
  emailInput: {
    // display: "flex",
    // margin: "0 auto",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop:"5px",
  },
  affiliation: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  login: {
    textAlign: "center",
    fontSize: "20px",
    marginTop: "30px",
    // fontWeight: "900",
    color: "#4a4b4d",
    // fontFamily: "Metropolis",
    fontFamily: "fantasy",
    letterSpacing: "4px",
    paddingBottom: "20px",
  },
  login2: {
    textAlign: "center",
    fontSize: "15px",
    fontWeight: "700",
    color: "#cccccc",
    fontFamily: "Metropolis",
    paddingBottom: "20px",
  },
}));

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    background:"white",
    "& $notchedOutline": {
      borderColor: "black",
    },
    "&:hover $notchedOutline": {
      borderColor: "black",
    },
    "&$focused $notchedOutline": {
      borderColor: "black",
    },
     "&.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "black"
    }
  },
   
    error: {
      "& $notchedOutline": {
        borderColor: "black",
      },
      "&:hover $notchedOutline": {
        borderColor: "black",
      },
      "&$focused $notchedOutline": {
        borderColor: "black",
      }
    
  },
  focused: {},
  notchedOutline: {},
}));

/* This is for SSO */

const currentOrigin = window.location.origin;
const newparam = "login"
// const newUrl = `${currentUrl}?param=${"finaldestination"}`;
const newUrl = `${currentOrigin}/login`;


const LoginScreen = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [otpAccepted, setOtpAccepted] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [loginPhoneNumber, setLoginPhoneNumber] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumberisValid, setPhoneNumberisValid] = React.useState(true);
  const [signIn, setSignIn] = React.useState(false);
  const [callingCode, setCallingCode] = React.useState("");
  const [registerCallingCode, setRegisterCallingCode] = React.useState("");
  const [nameError, setNameError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [emailTextError, setEmailTextError] = React.useState("");
  const [nameTextError, setNameTextError] = React.useState("");
  const [phoneTextError, setPhoneTextError] = React.useState("");
  const [phoneError, setPhoneError] = React.useState(false);
  // const [loginError, setLoginError] = React.useState(false);
  const [loginError, setLoginError] = React.useState(false);
  const [loginSuccess1, setloginSuccess1] = React.useState(false);
  const [inHoverLogin, setHoverLogin] = React.useState(false);
  const [loginAuthentication, setLoginSuccess] = React.useState(false);
  const [institute, setInstitute] = useState("");
  const [logintype, setLogintype] = useState(true);
  const [ad, setAD] = useState([]);

  const [phoneerrorcount, setphoneerrorcount] = useState(1);
  const [emailerrorcount, setemailerrorcount] = useState(1);
  const {
    isSuccess: loginSuccess,
    isFetching: loginFetching,
    isError: loginError1,
    response: login,
  } = useSelector((state) => state?.login);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state?.profile
  );
  

  const {
    isSuccess: emailloginSuccess,
    isFetching: emailloginFetching,
    isError: emailloginError1,
    response: emaillogin,
  } = useSelector((state) => state?.socialEmailLogin);
  // console.log("emaillogin = ", emaillogin);
  // console.log("emaillogin.message = ", emaillogin.message);
  // console.log("emailloginSuccess = ", emailloginSuccess);
  // console.log("emailloginFetching = ", emailloginFetching);


  // console.log(profileSuccess);
  // console.log(loginSuccess);
  // console.log(profileResponse.message);
  // console.log(profileResponse);
  // console.log(login);
  // console.log(login.message)
  const userid = localStorage.getItem("userid");
  // console.log("userid = ", userid);

  useEffect(() => {

    if (logintype === "phoneno" && login.message === "Sorry, you are not registered on platform.") {
      setPhoneTextError("Please Enter your registered Phone Number");
      setPhoneError(true);
    } else {
      setPhoneTextError("");
      setPhoneError(false);
    }
  }, [login.message, phoneerrorcount]);

  useEffect(() => {
  
    if (logintype === "email" &&  emaillogin.message === "Sorry, you are not registered on platform with this emailid") {
      setEmailTextError("Please Enter your registered Email ID");
      setEmailError(true);
    } else {
      setEmailTextError("");
      setEmailError(false);
    }
  }, [emaillogin.message, emailerrorcount]);

  // useEffect(() => {
  //   if (userid) {
  //     setLoginSuccess(true);
  //     setloginSuccess1(true);
  //   }
  // }, [userid]);
  const roles = "adopter";

  // profileSuccess && profileResponse[0]?.role;
  localStorage.setItem("role", roles);

  const handleClickOpen = () => {
    if (phoneNumber.length >= 10) {
      setOpen(true);
    } else {
      setPhoneNumberisValid(false);
    }
  };
  const handleChangeCallingCode = (event) => {
    setCallingCode(event.target.value);
    localStorage.setItem("code", event.target.value);
  };
  const handleChangeRegisterCallingCode = (event) => {
    setRegisterCallingCode(event.target.value);
    localStorage.setItem("number", phoneNumber);
  };

  const handleInstitute = (event) => {
    setInstitute(event.target.value);
  };
  const replacedPhoneNumber =
    (registerCallingCode === ""
      ? "91"
      : registerCallingCode.replace(/\+/g, "")) + phoneNumber;
  const registerNumber =
    (registerCallingCode === "" ? "+91" : registerCallingCode) + phoneNumber;
  localStorage.setItem("registerNumber", replacedPhoneNumber);
  const loginNumber =
    (callingCode === "" ? "+91" : callingCode) + loginPhoneNumber;
  localStorage.setItem("loginNumber", loginNumber);
  const replacedLoginPhoneNumber =
    (callingCode === "" ? "91" : callingCode.replace(/\+/g, "")) +
    loginPhoneNumber;
  localStorage.setItem("replacedLoginPhoneNumber", replacedLoginPhoneNumber);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOtp = () => {
    //check OTP here

    //route if correct (routed for anything now)
    // history.push('/home');
    setOtpAccepted(true);
    setOpen(false);
  };
  const handleSignIn = () => {
    setSignIn(!signIn);
  };
  // console.log("rolesadsa", profileResponse[0] && profileResponse[0].role);
  // console.log(profileResponse);
  const handleClick = () => {
    // navigate('/otp', { userid: replacedPhoneNumber, fullname: name, email: email, role: 'adopter', joiningbonus: 50, affiliate: institute });
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container"
    );
    var appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(registerNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        navigate("/otp", {
          state: {
            userid: replacedPhoneNumber,
            fullname: name,
            email: email,
            role: "adopter",
            joiningbonus: 50,
            affiliate: institute,
          },
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const handleClickLogin = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container1"
    );
    var appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(loginNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        navigate("/otp");
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const handleClickEmailLogin = () => {
    const phoneno = emaillogin.phoneno;
    const profileurl = emaillogin.profileurl;
  
    localStorage.setItem("userid", phoneno);
    localStorage.setItem("profileurl", profileurl);
    
    navigate(`/emailverifypr/${phoneno}/${email}`);
    // navigate(`/emailverifypr/${email}`);

    // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    //   "recaptcha-container2"
    // );
    // var appVerifier = window.recaptchaVerifier;
    // firebase
    //   .auth()
    //   .signInWithEmailLink(email, appVerifier, actionCodeSettings)
    //   .then((confirmationResult) => {
    //     window.confirmationResult = confirmationResult;
    //     navigate("/otp");
    //   })
    //   .catch((error) => {
    //     // console.log(error);
    //   });
  };

  const sendEmailOTP = async () => {
    const phoneno = emaillogin.phoneno;
   
    const res = await axios.post(`/verifyemail`, {
      phoneno: phoneno,
      email: email,
    });

    // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    //   "recaptcha-container2"
    // );
    // var appVerifier = window.recaptchaVerifier;
    // firebase
    //   .auth()
    //   .signInWithEmailLink(email, appVerifier, actionCodeSettings)
    //   .then((confirmationResult) => {
    //     window.confirmationResult = confirmationResult;
    //     navigate("/otp");
    //   })
    //   .catch((error) => {
    //     // console.log(error);
    //   });
  };

  //THIS IS WORKING
  // useEffect(() => {
  //   if (loginSuccess && login !== []) {
  //     handleClickLogin();

  //     dispatch(userProfile(replacedLoginPhoneNumber));
  //   }
  // }, [loginSuccess]);

  useEffect(() => {
    if (
      loginSuccess &&
      login !== [] &&
      login.message !== "Sorry, you are not registered on platform."
    ) {
      handleClickLogin();

      dispatch(userProfile(replacedLoginPhoneNumber));
    }
  }, [loginSuccess]);

  useEffect(() => {
    if (
      emailloginSuccess &&
      emaillogin !== [] &&
      emaillogin.message !== "Sorry, you are not registered on platform with this emailid"
    ) {
     

      sendEmailOTP();
      handleClickEmailLogin();

      dispatch(userProfile(email));
    }
  }, [emailloginSuccess]);

  useEffect(() => {
    if (registerNumber) {
      // handleClick();
    }
  }, [registerNumber]);

  
  useEffect(() => {
    if (loginAuthentication) {
      // if(localStorage.getItem('referralid') !== null && localStorage.getItem('referedcampid') !== null && localStorage.getItem('reflevel') !== null &&
      //           localStorage.getItem('referralid') !== "" && localStorage.getItem('referedcampid') !== "" && localStorage.getItem('reflevel') !== "") {
        
      //     navigate(`/participate/${localStorage.getItem('referedcampid')}/${profileResponse?.phoneno}`)
      // } 
      if (profileResponse?.orgrole?.toLowerCase() === "investor") {
        navigate("/create_yaag");
      } else if (profileResponse?.orgrole?.toLowerCase() === "national") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "volunteer") {
        // navigate("/volunteer");
        navigate("/kt");
      } else if (profileResponse?.orgrole?.toLowerCase() === "cyaagadmin") {
        navigate("/kt");
      } else if (profileResponse?.orgrole?.toLowerCase() === "sponsoradmin") {
        navigate("/home");
      } else if (profileResponse?.orgrole?.toLowerCase() === "admin") {
        navigate("/kt");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region1") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region2") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region3") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region4") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region5") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region6") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region7") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region8") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region9") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region10") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region11") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region12") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region13") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region14") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "region15") {
        navigate("/exec");
      } else if (profileResponse?.orgrole?.toLowerCase() === "campadmin") {
        navigate("/home");
      }
    }
  }, [loginAuthentication, profileSuccess]);

  useEffect(() => {
    if (!userid) {
      navigate("/login");
    } else {
      // if (profileResponse?.[0]?.orgrole?.toLowerCase() === "investor") {
      //   navigate("/create_yaag");
      // } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "National") {
      //   navigate("/exec");
      // } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "Volunteer") {
      //   // navigate("/volunteer");
      //   navigate("/home");
      // } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "Admin") {
      //   navigate("/home");
      // } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "Hyphenadmin") {
      //   navigate("/home");
      // }
    //   if(localStorage.getItem('referralid') !== null && localStorage.getItem('referedcampid') !== null && localStorage.getItem('reflevel') !== null &&
    //             localStorage.getItem('referralid') !== "" && localStorage.getItem('referedcampid') !== "" && localStorage.getItem('reflevel') !== "") {
    //     navigate(`/participate/${localStorage.getItem('referedcampid')}/${profileResponse?.phoneno}`)
    // } 
    if (profileResponse?.[0]?.orgrole?.toLowerCase() === "investor") {
        navigate("/create_yaag");
      } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "national") {
        navigate("/exec");
      } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "volunteer") {
        // navigate("/volunteer");
        navigate("/kt");
      } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "admin") {
        navigate("/kt");
      } else if (
        profileResponse?.[0]?.orgrole?.toLowerCase() === "cyaagadmin"
      ) {
        navigate("/kt");
      } else if (profileResponse?.orgrole?.toLowerCase() === "sponsoradmin") {
        navigate("/home");
      } else if (profileResponse?.orgrole?.toLowerCase() === "campadmin") {
        navigate("/home");
      }
    }
  }, [userid]);

  useEffect(() => {
    // if (profileSuccess) {
    //   if (profileResponse?.[0]?.orgrole?.toLowerCase() === "investor") {
    //     navigate("/create_yaag");
    //   } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "National") {
    //     navigate("/exec");
    //   } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "Volunteer") {
    //     navigate("/volunteer");
    //   } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "Admin") {
    //     navigate("/home");
    //   } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "Hyphenadmin") {
    //     navigate("/home");
    //   }
    // }
    if (profileSuccess) {
    //   if(localStorage.getItem('referralid') !== null && localStorage.getItem('referedcampid') !== null && localStorage.getItem('reflevel') !== null &&
    //             localStorage.getItem('referralid') !== "" && localStorage.getItem('referedcampid') !== "" && localStorage.getItem('reflevel') !== "") {
    //     navigate(`/participate/${localStorage.getItem('referedcampid')}/${profileResponse?.phoneno}`)
    // } 
    if (profileResponse?.[0]?.orgrole?.toLowerCase() === "investor") {
        navigate("/create_yaag");
      } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "national") {
        navigate("/exec");
      } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "volunteer") {
        // navigate("/volunteer");
        navigate("/kt");
      } else if (profileResponse?.[0]?.orgrole?.toLowerCase() === "admin") {
        navigate("/kt");
      } else if (
        profileResponse?.[0]?.orgrole?.toLowerCase() === "cyaagadmin"
      ) {
        navigate("/kt");
      } else if (profileResponse?.orgrole?.toLowerCase() === "sponsoradmin") {
        navigate("/home");
      } else if (profileResponse?.orgrole?.toLowerCase() === "campadmin") {
        navigate("/home");
      }
    }
    // else{
    //   navigate("/login");
    // }
  }, []);
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  var patternName = new RegExp(/(.*[a-z]){3}/i);
  // console.log("emailError", emailTextError);

  var patternPhone = new RegExp(/^([0-9]{10})$/g);

  var patternEmail = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);

  const sortedCallingCode = restrictedCountry.sort(function (a, b) {
    if (a.name < b.name) return -1;
    else if (a.name > b.name) return 1;
    return 0;
  });
  const outlinedInputClasses = useOutlinedInputStyles();

  return (
    <>
      <header className={classes.loginPgHeader}>
        <div className={classes.loginPgContainer}>
          <div className={classes.loginPgLogo} style={{ textAlign: "center" }}>
            <img
              src={Login}
              style={{ width: "100%", alignItems: "center", height: "300px"}}
            />
           
            <img
              src={LoginLogo}
            
            />
          
          </div>
        </div>
        <div className={classes.login}>Login using</div>

      </header>
      <main className={classes.loginPgMainContent}>
        
        <div
          className={classes.loginPgContainer}
          style={{ display: `${!signIn ? "block" : "none"}`, margin: "0 auto" }}
        >
          <FormControl
            fullWidth
            className={classes.formControl}
            variant="outlined"
          >
            
            <Button
              variant="contained"
              color="secondary"
              className={classes.phoneandemailbutton}
              style={{backgroundColor: "#6a34a0", color:"white"}}
              onClick={() => {
                setLogintype("phoneno");
                setPhoneTextError(false);
                setPhoneError(false);
                setEmailTextError(false);
                setEmailError(false);
                
              }}
            >
              <InputAdornment position="start">
                <PhoneIcon color="#2C7973" />
              </InputAdornment>
              Phone No
            </Button>

            <Button
              variant="contained"
              color="secondary"
              className={classes.phoneandemailbutton}
              style={{backgroundColor: "#6a34a0", color:"white"}}
              onClick={() => {
                setLogintype("email");
                setPhoneTextError(false);
                setPhoneError(false);
                setEmailTextError(false);
                setEmailError(false);
              }}
            >
              <InputAdornment position="start">
                <EmailIcon color="#2C7973" />
              </InputAdornment>
              Email Id
            </Button>

            <br></br>
            
            {logintype === "phoneno" ? (
              
              <div className={classes.phoneInput}>
                <Select
                  // disabled
                  className={classes.callingInput}
                  value={callingCode === "" ? "+91" : callingCode}
                  onChange={handleChangeCallingCode}
                >
                  {sortedCallingCode.map((item, index) => (
                    <MenuItem value={item.dial_code}>
                      {item.name + "\t" + item.dial_code}
                    </MenuItem>
                  ))}
                </Select>
                <OutlinedInput
                  classes={outlinedInputClasses}
                  required
                  className={classes.phoneNumber}
                  id="login-phone-number"
                  error={loginError}
                  startAdornment={
                    // <InputAdornment position="start">
                      <PhoneIcon color="#2C7973" />
                    // </InputAdornment>
                  }
                  onMouseEnter={() => setHoverLogin(true)}
                  onMouseLeave={() => setHoverLogin(false)}
                  placeholder="Phone Number"
                  disabled={otpAccepted}
                  onBlur={(e) => {
                    // setName(e.target.value);
                    if (!patternPhone.test(e.target.value)) {
                      setPhoneTextError("Please Enter your registered Phone Number");
                      setPhoneError(true);
                     } 
                    else {
                      setPhoneTextError(false);
                      setPhoneError(false);
                    }

                  }}
                  onChange={(e) => {
                    setLoginPhoneNumber(e.target.value);
                    setPhoneNumberisValid(true);
                  }}
                  onClick={() => {
                    // setPhoneNumberisValid(true);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      dispatch(socialLogin(replacedLoginPhoneNumber));
                    }
                  }}
                />
              </div>
            ) : logintype === "email" ? (
              <div className={classes.emailInput}>
               
                <OutlinedInput
                  required
                  className={classes.phoneNumber}
                  classes={outlinedInputClasses}
                  id="login-email"
                  error={loginError}
                  startAdornment={
                    // <InputAdornment position="start">
                      <EmailIcon color="#2C7973" />
                    // </InputAdornment>
                  }
                  onMouseEnter={() => setHoverLogin(true)}
                  onMouseLeave={() => setHoverLogin(false)}
                  placeholder="Email Id"
                  disabled={otpAccepted}
                  onBlur={(e) => {
                    // setName(e.target.value);
                    if (!patternEmail.test(e.target.value)) {
                      setEmailTextError(
                        "Please Enter your registered Email ID"
                      );
                      setEmailError(true);
                    } 
                    
                    else {
                      setEmailTextError(false);
                      setEmailError(false);
                    }
                  }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onClick={() => {
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      dispatch(socialEmailLogin(email));
                    }
                  }}
                />
              </div>
            ) : (
              ""
            )}

            {phoneError && (
              <span style={{ color: "red" }}> {phoneTextError}</span>
            )}

            {emailError && (
              <span style={{ color: "red" }}> {emailTextError}</span>
            )}

          </FormControl>
          {
           
          loginSuccess &&  <div id="recaptcha-container1" />
          
          }
          {
           
          emailloginSuccess &&  <div id="recaptcha-container2" />
          
          }
          <label></label>
         
          <div
           
            style={{ width: "100%", textAlign: "center" }}
          >
            <Button
              variant="contained"
              color="secondary"
              style={{backgroundColor: "#553074", color:"white"}}
              className={classes.button}
              onClick={() => {
                if (!loginPhoneNumber) {
                  setLoginError(true);
                } else {
                 
                  setLoginError(false);
                  dispatch(socialLogin(replacedLoginPhoneNumber));
                
                  if (logintype === "phoneno" && login.message === "Sorry, you are not registered on platform.") {
                    setphoneerrorcount(phoneerrorcount + 1)
                    setLoginError(true);

                  } else {
                    setemailerrorcount(emailerrorcount + 1)
                    setLoginError(false);
                    setPhoneError(false);
                  }

                }
                if (!email) {
                  setLoginError(true);
                } else {
                  setLoginError(false);
                  dispatch(socialEmailLogin(email));
                  if (logintype === "email" &&  emaillogin.message === "Sorry, you are not registered on platform with this emailid") {
                    setemailerrorcount(emailerrorcount + 1)
                    setLoginError(true);
                  }else {
                    setemailerrorcount(emailerrorcount + 1)
                    setLoginError(false);
                    setEmailError(false);
                  }
                  // sendEmailOTP();
                }
                // handleClickLogin();
                // dispatch(socialLogin(replacedLoginPhoneNumber));
              }}
            >
              Sign In
            </Button>
            <br></br>
            <br></br>
            
            {/* Not Registered? <a href="/register">Click Here</a> */}
          </div>
        </div>
      </main>
    </>
  );
};

export default LoginScreen;
