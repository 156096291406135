import React, { useState, useEffect } from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import ResponsiveDrawer from "../components/Header";
import TopHeaderScreen from "../components/TopHeader1";
import { userProfile } from '../redux/action/UserProfileActions';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import{ isArray } from "lodash";
import { useNavigate } from "react-router-dom";

const currentUrl = window.location.href;
const newUrl = `${currentUrl}?param=${"finaldestination"}`;
const msalConfig = {
  auth: {
    clientId: '0d4a6bc3-f0a2-461e-97b2-29db9d62c676',
    authority: 'https://login.microsoftonline.com/ab36b463-e018-4ad7-a2f7-788a0e667939',
    // redirectUri: 'https://dev.cyaag.com/finaldestination' // Your React app URL
    redirectUri: newUrl // Your React app URL
  }
};

const FinalDestination = (props) => {
  const [account, setAccount] = useState(null);
  const [pca, setPca] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [user, setUser] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
);

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        const pcaInstance = new PublicClientApplication(msalConfig);
        await pcaInstance.initialize({
          // Add any additional configuration options if needed
        });
        setPca(pcaInstance);
        await pcaInstance.handleRedirectPromise(); // Ensure any pending redirect operations are handled
        setInitialized(true);
        console.log("INITIALIZED TRUE");
      } catch (error) {
        console.error("MSAL initialization error:", error);
        // Handle the error - you might set an error state or display an error message
      }
    };
  
    initializeMsal();
  }, []);


  useEffect(() => {
      try {
        if (account){
          getUserDetails().then(response => {
            dispatch(userProfile(response?.data?.phoneno))
            console.log('Response from backend:', response.data);
        })

        }
        
      } catch (error) {
        console.error("MSAL initialization error:", error);
        // Handle the error - you might set an error state or display an error message
      }
   
  }, [account]);


  const getUserDetails = async () => {
    try {
        
        axios.get(`/cyaaguser/email/${account.username}`)
            .then(response => {
                setUser(response?.data)
                console.log('Response from backend:', response.data);
                if (!("message"in (response?.data))) {
                  localStorage.setItem("userid", response?.data?.phoneno);
                  localStorage.setItem("profileurl", response?.data?.profileurl);
                  navigate('/home')
                } else {
                  navigate('/register')
                }
            })
            .catch(error => {
                console.error('Error sending params to backend:', error);
            });

        
            
    } catch (err) {
        
        throw err;
    }
};

console.log("user = ", user);
  const logout = () => {
    if (pca) {
      pca.logout();
      setAccount(null);
    }
  };

  const handleLogin = async () => {
    if (!pca) return;

    try {
      const loginRequest = {
        // scopes: ['openid', 'profile', 'User.Read']
        scopes: ['openid', 'profile', 'User.ReadBasic.All']
      };
      const loginResponse = await pca.loginPopup(loginRequest);
      console.log("loginresponse = ", loginResponse);
      setAccount(loginResponse.account);
    } catch (error) {
      console.log(error);
    }
  };

  if (!initialized) {
    return <div>Loading...</div>;
  }
  return (
    <div className="wrapper">
    <ResponsiveDrawer {...props} />
    <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
           
            <div class="content-mrg hasScreen">
    <div>
      {account ? (
        <div>
         
          
            {isArray(user) ?
            <div>
            <p>Hello, {account.name}</p>
            <button onClick={logout}>Logout</button>
            </div>
            :
            <p>{user?.message}</p>
}
          
          
        </div>
      ) : (
        <button onClick={handleLogin}>Login with Microsoft</button>
      )}
    </div>
    </div>
    </div>
    </section>
    </div>
  );
};

export default FinalDestination;
