/** @format */

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { userProfile } from "../redux/action/UserProfileActions";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";

function Error(props) {
  const userid = localStorage.getItem("userid");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const roles = localStorage.getItem("role");
  
  return <div> Hi </div>;
}




export default Error;
