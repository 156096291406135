/** @format */

import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  GridList,
  GridListTile,
  Tab,
  Tabs,
  withStyles,
} from "@material-ui/core";
import {
  Avatar,
  Badge,
  CircularProgress,
  Collapse,
  MenuItem,
  useMediaQuery,
  useTheme,

} from "@mui/material";
import {
  ExpandMore,
  HomeOutlined,
  MicNone,
  Notifications,
  SettingsOutlined,
} from "@material-ui/icons";
import _ from "lodash";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import Toolbar from "@mui/material/Toolbar";
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
// import "../../scss/containerStyles/_home.scss";
// import "../../App.css";
// import "../../cyaag-style.css";
// import "../../bootstrap.min.css";
// import "../../icon-font.css";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
// import { userProfile, userProfileReset } from "../../redux/action/UserProfileActions";
import { userProfile, userProfileReset } from "../redux/action/UserProfileActions";
import { CopyRight } from "../components/CopyRight";
import { getVolunteerCount } from "../redux/action/volunteerCountAction";
import { volunteerMenuItems } from "../utils/dict";
import ResponsiveDrawer from "../components/Header";
import { HomeCard } from "../components/Card";
import axios from "axios";
import { API_URL } from "../services/Constants";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { socialLoginReset } from "../redux/action/loginAction";
import { getUserProfileImage } from "../redux/action/userProfileImage";
import rewardIcon from "../assets/rewardIconNav.png";
import { volDashBoardReward } from "../redux/action/DashboardReward";
import GradeSharpIcon from '@mui/icons-material/GradeSharp';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const dispatch = useDispatch();

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 100px 330px",
    },
  },
  homeScreenContainer: {
    padding: "0 0 0 20px",
    zIndex: 100,
  },
  welcomeMessage: {
    fontWeight: "bold",
    marginBottom: 10,
    margin: "10px 0",
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
  },
  welcomeAndFilter: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    textTransform: "uppercase",
    padding: "10px 0",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  filterIcon: {
    fontSize: 26,
    color: "#F8B34C",
    marginLeft: 28,
  },
  homeCards: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    margin: "0 -7px",

    // backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    display: "grid",
    // gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: "1rem",
    [theme.breakpoints.only("xl")]: {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    [theme.breakpoints.only("lg")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    [theme.breakpoints.only("md")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.only("sm")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    [theme.breakpoints.only("xs")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  maxWidthContainer: {
    padding: "25px 0",
    maxWidth: 500,
    margin: "0 auto",
    // borderTop: '2px solid #F8B34C',
  },
  gradientHeading: {
    fontSize: 25,
    fontWeight: "bold",
  },
  rewards: {
    display: "flex",
    justifyContent: "center",
    color: "#F8B34C",
    paddingBottom: 13,
  },
  rewardsText: {
    fontSize: 25,
    fontWeight: "bold",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 9,
  },
  secondaryBorder: {
    padding: "5px 0",
    borderBottom: "2px solid #F8B34C",
  },
  myImpacts: {
    padding: "15px 0 0 0",
    fontSize: 14,
    textAlign: "center",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  myImpactsWrap: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 -8px",
    outline: "none",
  },
  myImpact: {
    width: "150px",
    margin: "0 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    outline: "0 !important",
    border: "none",
  },
  myImpactImgWrap: {
    width: 52,
    height: 50,
    margin: "0 auto 5px",
  },
  myImpactImg: {
    width: "100%",
  },
  myImpactTxt: {
    display: "block",
  },
  myCommunity: {
    borderBottom: "2px solid #F8B34C",
    padding: "15px 0",
    fontSize: 20,
    color: "#F8B34C",
  },
  myCommunityContentWrap: {
    display: "flex",
    justifyContent: "space-between",
  },
  myCommunityImg: {
    width: 100,
  },
  inviteBtn: {
    paddingTop: 15,
    width: 150,
  },
  myCommunityPoints: {
    listStyle: "none",
    margin: "0 0 0 10px",
    padding: 0,
  },
  inviteButton: {
    fontSize: 9,
    fontWeight: "bold",
    lineHeight: 1,
  },
  myRegion: {
    borderBottom: "2px solid #F8B34C",
    padding: "15px 0",
  },
  mapImg: {
    width: 130,
    margin: "0 auto",
  },
  myYaags: {
    padding: "15px 0",
  },

  bottomNavigation: {
    position: "absolute",
    width: "100%",
  },
  tabName: {
    // color: "#fff",
    color: '#00000',
    textTransform: "none",
    // ariaSelected: "true",
  },
  pointBox: {
    // top: "22px",
    // left: "200px",
    // top: "22%",
    minWidth: "65px",
    height: "39px",
    background: "#1A524D 0% 0% no-repeat padding-box",
    borderRadius: "100px",
    opacity: "90%",
    color: "#FFFFFF",
    fontSize: 15,
    fontWeight: "bold",
  },
  rewardclick: {
    position: "relative",
    padding: 7,
    paddingRight: 25,
    color: "black",
    display: "flex",
    cursor: "pointer",

  },
  large: {
    width: "50px !important",
    height: "50px !important",
    flexDirection: "row",
    // display: "flex",
    // justifyContent: "center",
    // textAlign: "center",

  },
  mouseHover: {
    "&:hover": {
      textDecoration: "underline",
      color: "blue",
    }

  },
}));

const TopHeaderScreen = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [campaignList, setCampaignList] = useState([]);
  const [rewarddash, setRewardDash] = useState([]);
  const [checked, setChecked] = useState(false);
  const [value, setValue] = React.useState(0);
  const [image, setImage] = React.useState(null);
  const [activeTrue, setActiveTrue] = React.useState(false);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  // console.log(profileResponse)
  const { isSuccess: myRewardSuccess, response: myRewardResponse } = useSelector(
    (state) => state.volDashBoardReward
  );
  // const { isSuccess: myrewardSuccess, response: response } = useSelector(
  //   (state) => state
  // );
  // const rewardResponse1 = useSelector((state) => state.volDashBoardReward);
  // console.log(rewardResponse1)
  // console.log(myRewardResponse)
  // console.log(rewarddash)
  const userid = localStorage.getItem("userid");
  const clearStorage = () => {
    localStorage.clear();
    dispatch(userProfileReset());
    dispatch(socialLoginReset());
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isActive, setActive] = useState(false);

  // const toggleMenu = () => {
  //   $('.sidebar').toggleClass('hide');
  //   $(this).toggleClass("click");
  // };

  const [loading, setLoading] = React.useState(false);

  const handleChangeFile = (e) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("profilephoto", e.target.files[0]);
    dispatch(
      getUserProfileImage({ userId: localStorage.getItem("userid"), formData })
    );
    setImage(e.target.files[0]);
  };

  const updateNotificationFlag = async () => {
    try {
      let formData = new FormData();
      // formData.append("notifyflag", "true");
      formData.append("flag", "true");

      const res = await axios({
        url: `/notification/flag/${profileResponse?.phoneno}`,
        method: "put",
        data: formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      dispatch(userProfile(localStorage.getItem("userid")));
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getVolunteerCamp = async () => {
    try {
      
      const res = await axios.get(`vol_dashboard/camp/${profileResponse?.orgrole}/${profileResponse?.organization}`
      );
      setCampaignList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };
  const getMyReward = async () => {
    try {
      
      const res = await axios.get(`/vol_dashboard/myreward/${userid}`);
      setRewardDash(res?.data);
      dispatch(volDashBoardReward(res?.data, res?.data?.[0]?.phoneno));
      return res;
    } catch (err) {
      throw err;
    }
  };


  useEffect(() => {
    dispatch(userProfile(userid));
    dispatch(getVolunteerCount());
    getVolunteerCamp();
    getMyReward();
    // dispatch(volDashBoardReward(profileResponse?.phoneno))
    // dispatch(volDashBoardReward(profileResponse?.phoneno, profileResponse));
    // dispatch(volDashBoardReward(profileResponse, userid));
    // dispatch(volDashBoardReward({userid}));
  }, []);
  // console.log(`/campaigndetails/${item.campid}/${profileResponse?.orgrole}/${profileResponse?.organization}`)
  return (
    <div className="wrappingMenuToggle">
      <div className="nav-top">
        <nav class="navbar-expand-lg navbar-white mobileNavBar">
          <div class="collapse navbar-collapse">
            <ul class="navbar-nav ml-auto">

              {/* <li class="nav-item dropdown">
                <a href="#" class="nav-link dropdown-toggle icon-clr" data-toggle="dropdown">
                    <i class="fa fa-envelope"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" href="#"><i class="fa fa-fw fa-user"></i> Profile</a>
                </div>
            </li> */}

              {/* {JSON.stringify(myRewardResponse)} */}
              <li class="nav-item dropdown" >
                <div className={classes.pointBox} style={{ marginRight: "35px", backgroundColor: "rgba(235, 149, 50, 1)" }} >
                  {/* <div className={classes.pointBox} style={{marginRight: "35px", backgroundColor: "rgba(204, 95, 57, 1)"}} > */}
                  <div className={classes.rewardclick}
                    onClick={() =>

                      // navigate(`/campaigndetails/${props.data.campid}/${props.userDetail.orgrole}/${props.userDetail.organization}`)
                      navigate(`/mypoints`)
                    }
                  // style={{
                  //   position: "relative",
                  //   padding: 7,
                  //   paddingRight: 25,
                  //   color: "black",
                  //   display: "flex",
                  //   cursor:"pointer",


                  //           // flexDirection: "column",

                  //   // color: "rgba(244, 196, 48, 1)",
                  //   // // height: "10vh",
                  //   // right: "92%",
                  //   // top: "20%",
                  //   // marginLeft: "300",
                  //   // top: "30",
                  //   // boxShadow: "1px 2px 10px rgba(0,0,0,0.2)",
                  //   // borderRadius: 8,
                  //   // overflow: "scroll",
                  //   // background: "rgb(45, 122, 116)"

                  // }}
                  >
                    {/* <img src={rewardIcon} style={{ width: "20px"}} /> */}

                    <GradeSharpIcon style={{ width: "25px", color: "yellow" }}
                    />
                    &nbsp;
                    &nbsp;

                    {/* {rewarddash.rewardpoint} */}
                    {/* {rewarddash.rewardpoint} */}
                    <span style={{ fontSize: 18, color: "indigo" }}>{rewarddash.rewardpoint}</span>
                  </div>

                </div>

                {/* <img src={rewardIcon} style={{ width: "30%", alignItems: "center"}} /> */}
                {/* <div>Hi</div> */}
                {/* <div
                style={{
                  position: "absolute",
                  width: 100,
                  height: "10vh",
                  right: "0%",
                  top: 0,
                  boxShadow: "1px 2px 10px rgba(0,0,0,0.2)",
                  borderRadius: 8,
                  overflow: "scroll",
                  background: "rgb(45, 122, 116)"
                }}
              >
                </div> */}
                {/* <ToggleOffIcon />  */}
                {/* {JSON.stringify(profileResponse)} */}

              </li>
              <li class="nav-item dropdown ">
                <a href="#" class="nav-link dropdown-toggle icon-clr" data-toggle="dropdown">

                  <i class="fa fa-bell fa-lg" style={{ color: "gray" }}
                    onClick={() => !props.notificationShow && updateNotificationFlag()}></i>
                  <Badge
                    badgeContent={profileResponse?.notificationcount}
                    color="error"
                    onClick={() => !props.notificationShow && updateNotificationFlag()}
                  >
                    {/* {
                    props.setNotificationShow(!props.notificationShow)
                    } */}
                    {/* <Notifications
                      style={{ cursor: "pointer", fill: "#D1D3E2" }}
                      onClick={() => {
                        // setShow(!show);
                        props.setNotificationShow(!props.notificationShow);
                      }}
                    /> */}
                  </Badge>

                </a>

                <div class="dropdown-menu dropdown-menu-right">

                  {/* <Badge
                    badgeContent={profileResponse?.notificationcount}
                    color="error"
                  >
                    <Notifications
                      style={{ cursor: "pointer", fill: "#D1D3E2" }}
                      onClick={() => {
                        // setShow(!show);
                        props.setNotificationShow(!props.notificationShow);
                      }}
                    />
                  </Badge> */}
                  <div class="notificationWrapper"
                    // onClick={() => !props.notificationShow && updateNotificationFlag()}
                    // style={{ marginRight: 14, zIndex: 999999 }}
                    style={{
                      position: "absolute",
                      width: 330,
                      height: "50vh",
                      right: "0%",
                      top: 0,
                      boxShadow: "1px 2px 10px rgba(0,0,0,0.2)",
                      borderRadius: 8,
                      overflow: "scroll",
                      background: "#fff"
                    }}
                  >
                    <div class="messageHeader" style={{textTransform: "capitalize",float:"left",width:"330px",backgroundColor:"#00399a",color: "#fff",fontSize:"18px",padding: "10px",position:"fixed"}}>
                            Alerts center
                          </div>
                          <div style={{marginTop: "48px"}}>
                    {_.isEmpty(profileResponse?.notification) ? (
                      <div>
                        <span style={{
                              background: "#fff",
                              float: "left",
                              width: "100%",
                              padding: "10px 12px"}}>No notifications yet</span>
                      </div>
                    ) : (
                      profileResponse?.notification?.map((item, index) => (
                        <div
                          style={{
                            
                          }}
                        > 
                          <div
                            class=""
                            style={{
                              background: "#fff",
                              float: "left",
                              width: "100%",
                              padding: "10px 12px",
                              borderBottom: "1px solid #ccc"
                              // padding: 8,
                              // padding: "1px 2px 1px 2px",
                              // borderRadius: 8,
                            }}
                          >
                            <div class=""
                              style={{
                                float: "left",
                                width: "50px",
                                marginRight: "10px"
                              }}
                            >
                              <img
                                src={item.logo}
                                className={classes.large}
                                style={{borderRadius:"8px"}}
                              />
                            </div>
                            {/* <span
                              style={{
                                color: "#000",
                                fontSize: 12,
                                fontWeight: "600",
                              }}
                            > */}
                            {/* <div
                              style={{
                                color: "#000",
                                fontSize: 12,
                                fontWeight: "600",
                              }}
                            > */}
                            <div class=""
                              style={{
                                color: "#000",
                                float: "left",
                                width: "calc(100% - 60px)"
                              }}
                              onClick={() =>
                                
                                navigate(`/campaigndetails/${item.campid}/${profileResponse?.orgrole}/${profileResponse?.organization}`)
                                // navigate(`/campaigndetails/${tile.campid}/${profileResponse?.orgrole}/${profileResponse?.organization}`)
                              }
                            >
                              {/* console.log(`/campaigndetails/${item.campid}/${profileResponse?.orgrole}/${profileResponse?.organization}`) */}
                              <span
                                style={{
                                  color: "#000",
                                  // display: "flex",
                                  display: "inline-block",
                                  // justifyContent: "flex-end",
                                  fontSize: 10,
                                  color: "grey",
                                  display: "block"
                                }}
                              // onClick={() =>
                                
                              //   navigate(`/campaigndetails/${item.campid}/${profileResponse?.orgrole}/${profileResponse?.organization}`)
                              //   // navigate(`/campaigndetails/${tile.campid}/${profileResponse?.orgrole}/${profileResponse?.organization}`)
                              // }
                              >
                                {moment(item?.createdate).fromNow()}
                              </span>
                              <span style={{fontSize: "12px",cursor: "pointer",display:"block"}} className={classes.mouseHover}>
                              {item?.msg}
                              </span>
                              {/* </span> */}
                            </div>
                          </div>
                        </div>
                      ))
                    )
                    }
                  </div>
                  </div>
                  {/* <div>
                    HI
                  </div> */}
                </div>
              </li>
              &nbsp;
              &nbsp;

              {/* <span style={{ fontSize: 12, fontWeight: "bold", minWidth: 35 }}>
                      {profileResponse?.firstname}
                  </span> */}
              <div style={{ fontSize: 13, fontWeight: "bold", minWidth: 35, position: "relative", padding: 7, paddingRight: 25, }}>
                {/* <li>Hello </li> */}
                {profileResponse?.firstname}
              </div>
              {/* &nbsp;&nbsp; */}

              <li class="nav-item dropdown">

                {/* <a href="#" class="nav-link dropdown-toggle icon-clr" data-toggle="dropdown" className={profileResponse?.profilephoto ? "removePadding" : ""}> */}
                <a href="#" class="nav-link" size="40" data-toggle="dropdown" className={profileResponse?.profilephoto ? "removePadding" : ""}>
                  {profileResponse?.profilephoto ? (
                    <Avatar
                      src={
                        image
                          ? URL.createObjectURL(image)
                          : profileResponse?.profilephoto
                      }
                      className={classes.large}
                      style={{}}
                    />) : (
                    <i class="fa fa-user mr-2 fa-lg"></i>
                  )}
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item" onClick={() => navigate("/profile")}><i class="fa fa-fw fa-user"></i> Profile</a>
                  <a class="dropdown-item" title="Available in future release" disabled="disabled" href="#"><i class="fa fa-fw fa-gear"></i> Settings</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" onClick={() => {
                    navigate("/");
                    clearStorage();
                  }}><i class="fa fa-fw fa-power-off"></i> Log Out</a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div class="btns toggleMenu" id="toggleMenuClick">
        <span class="fa fa-bars"></span>
      </div>
    </div>
  );
};

export default TopHeaderScreen;