/** @format */

// reducers
import {
    ADD_CAMP_REWARDS_FETCHING_DATA,
    ADD_CAMP_REWARDS_FETCHING_DATA_FAILURE,
    ADD_CAMP_REWARDS_FETCHING_DATA_RESET,
    ADD_CAMP_REWARDS_FETCHING_DATA_SUCCESS,
    
  } from "../action/addCampRewardsAction";
  
  let initialState = {
    addcamprewardsResponse: {},
    addcamprewardsSuccess: false,
    addcamprewardsError: false,
    addcamprewardsFetching: false,
  };
  
  export default function OnboardReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_CAMP_REWARDS_FETCHING_DATA:
            return {
            ...state,
            addcamprewardsFetching: true,
            addcamprewardsError: false,
            addcamprewardsSuccess: false,
        };
        case ADD_CAMP_REWARDS_FETCHING_DATA_FAILURE:
            return {
            ...state,
            addcamprewardsFetching: false,
            addcamprewardsError: action.payload,
            addcamprewardsSuccess: false,
        };
        case ADD_CAMP_REWARDS_FETCHING_DATA_SUCCESS:
            return {
            ...state,
            addcamprewardsFetching: false,
            addcamprewardsError: false,
            addcamprewardsResponse: action.payload,
            addcamprewardsSuccess: true,
        };
        case ADD_CAMP_REWARDS_FETCHING_DATA_RESET:
            return {
            ...state,
            addcamprewardsFetching: true,
            addcamprewardsError: false,
            addcamprewardsSuccess: false,
        };
      
      default:
        return state;
    }
  }
  