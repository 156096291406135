import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";

import CampaignIcon from "@mui/icons-material/Campaign";
import CampaignSharpIcon from '@mui/icons-material/CampaignSharp';
import ScoreIcon from "@mui/icons-material/Score";
import PersonIcon from "@mui/icons-material/Person";
import PeopleTwoToneIcon from '@mui/icons-material/PeopleTwoTone';
import PeopleSharpIcon from '@mui/icons-material/PeopleSharp';
import CampaignTwoToneIcon from '@mui/icons-material/CampaignTwoTone';
import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';
import CorporateFareSharpIcon from '@mui/icons-material/CorporateFareSharp';
import { OrgIcon } from "../assets/icon/OrgIcon";
import { VolunteerIcon } from "../assets/icon/VolunteerIcon";
import { CampIcon } from "../assets/icon/CampaignIcon";
import { VolIcon } from "../assets/icon/VolunteerIcon";
import { LogoutIcon } from "../assets/icon/LogoutIcon";
import { CircleBulletIcon } from "../assets/icon/CircleBullet";

import {
  ExpandLess,
  LogoutOutlined,
  Logout,
  NotificationAddOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  userProfile,
  userProfileReset,
} from "../redux/action/UserProfileActions";
import { socialLoginReset } from "../redux/action/loginAction";
import { getVolunteerCount } from "../redux/action/volunteerCountAction";
import {
  Avatar,
  Badge,
  CircularProgress,
  Collapse,
  MenuItem,
  useMediaQuery,
  useTheme,
  
} from "@mui/material";
import { getUserProfileImage } from "../redux/action/userProfileImage";
import { makeStyles } from "@material-ui/styles";
import {Dashboard} from "@mui/icons-material";
import moment from "moment";
import _ from "lodash";

import axios from "axios";
import { API_URL } from "../services/Constants";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import StoreIcon from '@mui/icons-material/Store';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StarsIcon from '@mui/icons-material/Stars';
import InfoIcon from '@mui/icons-material/Info';
import RedeemIcon from '@mui/icons-material/Redeem';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const drawerWidth = 300;





const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "start",
    // alignItems: "flex-start",
    // WebkitAlignItems: "start",
  },
  input: {
    display: "none",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  logoBackground: {
    [theme.breakpoints.only("xs")]: {
    backgroundColor: "none",
    
    }
  }
  ,
}));

function ResponsiveDrawer(props) {
  const classes = useStyles();
  const { window } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [menuOpen, setMenuOpen] = React.useState(localStorage.getItem('myActiveMenu') === "" ? 'Campaigns' : localStorage.getItem('myActiveMenu') );

  const [subMenuOpen, setSubMenuOpen] = React.useState(localStorage.getItem('mySubMenu') === "" ? 'Home' : localStorage.getItem('mySubMenu') );

  const [loading, setLoading] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const [show, setShow] = React.useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  // const { isSuccess, response: volunteerCountResponse } = useSelector(
  //   (state) => state?.getVolunteerCount
  // );

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );


  const getProfileInfo = async () => {
    
    try {
      const res = await axios.get(
        `cyaaguser/${localStorage.getItem("userid")}`
      );
      console.log("===== getrpofle info===");
      localStorage.setItem("userProfileUrl", res.data.profileurl);
// console.log(res.data.profileurl);
      // setMyCampaignList(res?.data);
       //console.log("hhh",res?.data)
       
      return res;
      // }
    } catch (err) {
      throw err;
    }
  };


  const menuItems = [

    { 
      id: 1, 
      label: "Dashboard", 
      // path: "/dashboard", 
      icon: <Dashboard />,
      subitems: [
        {
          id: 1,
          name: <item><span style={{ marginLeft: 10 }}>Overall View</span></item>,
          path: "/dashboard",
        },
      ],
    },
    {
      id: 5, 
      label: "Customer List", 
      path: "/kt", 
      icon: <StoreIcon /> 
    },    
    {
      id: 3,
      label: "Users",
      //icon: <VolIcon />,
      // icon: <PersonIcon />,
      // icon: <PeopleTwoToneIcon />,
      icon: <PeopleSharpIcon />,
      subitems: [
        {
          id: 1, 
          name: <item><span style={{ marginLeft: 10 }}>Add User</span></item>,
          path: "/kt/add/user", 
          // icon: <PersonAddIcon /> 
        }, 
        {
          id: 2, 
          name: <item><span style={{ marginLeft: 10 }}>User List</span></item>,
          path: "/kt/list/user", 
          // icon: <PersonAddIcon /> 
        },
        // {
        //   id: 3, 
        //   name: <item><span style={{ marginLeft: 10 }}>MPIN</span></item>,
        //   path: "/kt/mpin/change", 
        //   // icon: <PersonAddIcon /> 
        // },
      ],
    },
    
    
    // { 
    //   id: 6, 
    //   label: "Marketplace", 
    //   path: "/kt/customer/details", 
    //   icon: <StoreIcon /> 
    // },
      
    { 
      id: 8, 
      label: "Profile", 
      path: `/newprofile/${localStorage.getItem("userProfileUrl")}`, 
      // path: `/newprofile/${localStorage.getItem("userid")}`,
      icon: <PersonIcon /> 
    },  
    { 
      id: 10, 
      label: "Logout", 
      path: "/", 
      icon: <LogoutOutlined /> 
    },
  ];
  // This is for SO Admin
  const adminMenu = [
    {
        id: 1, 
        label: "Dashboard", 
        icon: <Dashboard />,
        // subitems: [
        //   {
        //     id: 1,
        //     name: "Overall View",
        //     path: "/SOAdashboard",
            
        //   },
        // ],
    },    
     {
      id: 5, 
      label: "Customer List", 
      path: "/kt", 
      icon: <StoreIcon /> 
    },   
    // {
    //   id: 3,
    //   label: "Users",
    //   icon: <PeopleSharpIcon />,
    //   subitems: [
        
    //     {
    //       id: 1, 
    //       name: <item><span style={{ marginLeft: 10 }}>MPIN</span></item>,
    //       path: "/kt/mpin/change", 
    //       // icon: <PersonAddIcon /> 
    //     },
    //   ],
    // }, 
    { 
      id: 7, 
      label: "Profile", 
      path: `/newprofile/${localStorage.getItem("userProfileUrl")}`,
      // path: `/newprofile/${localStorage.getItem("userid")}`,
      icon: <PersonIcon /> 
    },    
    { 
      id: 9, 
      label: "Logout", 
      path: "/", 
      icon: <Logout /> 
    },
  ];
  
  const volMenu = [
    {
      id: 1,
      label: "Campaigns",
      icon: <CampaignIcon />,
      subitems: [
        
        {
          id: 1,
          name: <item><CircleBulletIcon/><span style={{marginLeft: 10}}>Home</span></item>,
          // path: "/volunteer",
          path: "/home",
        },
       
        {
          id: 2,
          name: <item><CircleBulletIcon/><span style={{ marginLeft: 10 }}>Registration Status</span></item>,
          path: "/campaignregisterstatus",
        },
        {
          id: 3,
          name: <item><CircleBulletIcon/><span style={{ marginLeft: 10 }}>Campaign Gallery</span></item>,
          path: "/myongoingcampaigns",
        },
        {
          id: 3,
          name: <item><CircleBulletIcon/><span style={{ marginLeft: 10 }}>Campaign Documents</span></item>,
          path: "/myongoingcampaignspvt",
        },
        
      ],
    },
    {
      id: 2,
      label: "Rewards",
      //icon: <VolIcon />,
      icon: <EmojiEventsIcon />,
      
      subitems: [
        {
          id: 1,
          name: <item><CircleBulletIcon/><span style={{ marginLeft: 10 }}>My Rewards</span></item>,
          path: "/mypoints",
        },
        {
          id: 2,
          name: <item><CircleBulletIcon/><span style={{ marginLeft: 10 }}>Redeem Status</span></item>,
          path: "/redeemreport",
        },
        
      ],
    },
    {
      id: 3, 
      label: "Customer List", 
      path: "/kt/customer/details", 
      icon: <StoreIcon /> 
    },
    
    { id: 4, 
      label: "Profile", 
      path: `/newprofile/${localStorage.getItem("userProfileUrl")}`, 
      // path: `/newprofile/${localStorage.getItem("userid")}`,
      icon: <PersonIcon /> 
    },
    { 
      id: 5, 
      label: "About Us", 
      path: "/aboutus", 
      icon: <InfoIcon /> 
    },
  
    { id: 6, 
      label: "Logout", 
      path: "/", 
      icon: <Logout /> 
    },
  ];
  
  const sponsorAdminMenu = [
    {
      id: 1, 
      label: "Dashboard", 
      // path: "/SOAdashboard", 
      icon: <Dashboard />,
      subitems: [
        {
          id: 1,
          name: <item><span style={{ marginLeft: 10 }}>Overall View</span></item>,
          path: "/sponsoradmindashboard",
          // path: "/pdf",
        },
        {
          id: 2,
          name: <item><span style={{ marginLeft: 10 }}>Campaign Budget Report</span></item>,
          path: "/sponsoradminreport",
          // path: "/pdf",
        },
      ],
  },
    {
      id: 1,
      label: "Campaigns",
      icon: <CampaignIcon />,
      subitems: [
        
        {
          id: 1,
          name: <item><CircleBulletIcon/><span style={{marginLeft: 10}}>Home</span></item>,
          // path: "/volunteer",
          path: "/home",
        },
       
        {
          id: 2,
          name: <item><CircleBulletIcon/><span style={{ marginLeft: 10 }}>Participation Status</span></item>,
          path: "/campaignregisterstatus",
        },
        {
          id: 3,
          name: <item><CircleBulletIcon/><span style={{ marginLeft: 10 }}>Campaign Gallery</span></item>,
          path: "/myongoingcampaigns",
        },
        {
          id: 3,
          name: <item><CircleBulletIcon/><span style={{ marginLeft: 10 }}>Campaign Documents</span></item>,
          path: "/myongoingcampaignspvt",
        },
        
      ],
    },
    { 
      id: 2, 
      label: "Rewards", 
      path: "/mypoints", 
      icon: <EmojiEventsIcon /> 
    },
  
    {
      id: 3, 
      label: "Marketplace", 
      path: "/marketplace", 
      icon: <StoreIcon /> 
    },
    
    { id: 4, 
      label: "Profile", 
      path: `/newprofile/${localStorage.getItem("userProfileUrl")}`, 
      // path: `/newprofile/${localStorage.getItem("userid")}`,
      icon: <PersonIcon /> 
    },
    { 
      id: 5, 
      label: "About Us", 
      path: "/aboutus", 
      icon: <InfoIcon /> 
    },
  
    { id: 6, 
      label: "Logout", 
      path: "/", 
      icon: <Logout /> 
    },
  ];
  
  const campAdminMenu = [
    {
        id: 1, 
        label: "Dashboard", 
        // path: "/SOAdashboard", 
        icon: <Dashboard />,
        subitems: [
          {
            id: 1,
            name: <item><span style={{ marginLeft: 10 }}>Overall View</span></item>,
            path: "/SOAdashboard",
            // path: "/pdf",
          },
          // {
          //   id: 1,
          //   name: <item><span style={{ marginLeft: 10 }}>Overall View</span></item>,
          //   path: "/SOAdashboard",
          //   // path: "/pdf",
          // },
          {
            id: 2,
            name: <item><span style={{ marginLeft: 10 }}>Report on Campaign</span></item>,
            path: "/campownerreport",
            // path: "/pdf",
          },
        ],
    },
    {
      
      id: 2,
      label: "Organizations",
      // icon: <OrgIcon />,
      // icon: <CorporateFareTwoToneIcon />,
      icon: <CorporateFareSharpIcon />,
      subitems: [
        {
          id: 1,
          //name: <item><CircleBulletIcon /><span style={{ marginLeft: 10 }}>List</span></item>,
          name: "Organization Profile",
          path: "/organizationlist",
        },
      ],
    },
    {
      id: 3,
      label: "Users",
      //icon: <VolIcon />,
      icon: <PersonIcon />,
      // icon: <PeopleTwoToneIcon />,
      icon: <PeopleSharpIcon />,
      subitems: [
        {
          id: 1,
          name: "Onboard Users",
          path: "/onboardvolunteer",
        },
        // {
        //   id: 2,
        //   name: "Manage Users",
        //   path: "/reviewvolunteer",
        // },
        {
          id: 3,
          name: "Review User Onboarding",
          path: "/reviewcm",
        },
        // {
        //   id: 4,
        //   name: "Performance Report",
        //   path: "/userperfreport",
        // },
      ],
    },
    {
      id: 4,
      label: "Campaigns",
      icon: <CampaignSharpIcon />,
      subitems: [
        {
          id: 1,
          name: "Home",
          path: "/home",
        },
        {
          id: 2,
          name: "Create Campaigns",
          path: "/createcampaign",
        },
        { 
          id: 3, 
          name: "My Org Campaigns", 
          path: "/mycampaignview", 
        },
        { 
          id: 5, 
          name: "Review Registrations", 
          path: "/reviewcampaignregistration", 
        },
        {
          id: 6,
          name: "Registration Status",
          path: "/campaignregisterstatus",
        },
        {
          id: 7,
          name: "Campaign Gallery",
          path: "/myongoingcampaigns",
        },
        {
          id: 8,
          name: "Campaign Documents",
          path: "/myongoingcampaignspvt",
        },
        // {
        //   id: 9,
        //   name: "Report on Rewards",
        //   path: "/rewardsreport",
        // },
      ],
    },
     {
      id: 5, 
      label: "Marketplace", 
      path: "/marketplace", 
      icon: <StoreIcon /> 
    },
    {
      id: 6,
      label: "Redeem Reward",
      //icon: <VolIcon />,
      icon: <RedeemIcon />,
      
      subitems: [
        {
          id: 2,
          name: "Redeem Status",
          path: "/redeemreport",
        },
        
      ],
    },
    // { 
    //   id: 7, 
    //   label: "Rewards Admin", 
    //   path: "/addrewards", 
    //   icon: <StarsIcon />,
    //   subitems: [
        
    //     {
    //       id: 1,
    //       name: <item><span style={{ marginLeft: 10 }}>Add Campaign Rewards</span></item>,
    //       path: "/addcamprewards",
    //     },
        
    //     {
    //       id: 2,
    //       name: <item><span style={{ marginLeft: 10 }}>Add Campaign Recognitions</span></item>,
    //       path: "/addcamprecog",
    //     },
        
    //     {
    //       id: 3,
    //       name: <item><span style={{ marginLeft: 10 }}>Manage Rewards</span></item>,
    //       path: "/reviewrewards",
    //     },
    //     {
    //       id: 4,
    //       name: <item><span style={{ marginLeft: 10 }}>Report</span></item>,
    //       path: "/rewardsreport",
    //     },
                
    //   ],
    // },
    
    { 
      id: 8, 
      label: "Profile", 
      path: `/newprofile/${localStorage.getItem("userProfileUrl")}`,
      // path: `/newprofile/${localStorage.getItem("userid")}`,
      icon: <PersonIcon /> 
    },
    { 
      id: 9, 
      label: "About Us", 
      path: "/aboutus", 
      icon: <InfoIcon /> 
    },
  
    { 
      id: 10, 
      label: "Logout", 
      path: "/", 
      icon: <Logout /> 
    },
    
  ];



  const handleClick = event => {
     event.currentTarget.classList.toggle('active');
    event.currentTarget.parentNode.classList.toggle('acitveParent');
  };

  const activeSingleMenu = (singleMenuName,itemPath) => {
    navigate(itemPath);
    if(localStorage.getItem('myActiveMenu') !== "")
    {
      localStorage.removeItem('myActiveMenu');
    }
    if(localStorage.getItem('mySubMenu') !== "")
    {
      localStorage.removeItem('mySubMenu');
    }
    localStorage.setItem('mySubMenu', singleMenuName);
    setMenuOpen("");
    setSubMenuOpen(singleMenuName);
  }

  const activeNav = (parentMenuName,subMenuName,itemPath) => {
    // Prevent the default behavior of the anchor element
    navigate(itemPath);
    console.log("Parent Label Text====",parentMenuName);
    console.log("Submenu Label Text====",subMenuName);
    // console.log(subMenuName);
    // if (typeof subMenuName === 'object') {
    //   console.log("It is object",subMenuName);
    //   console.log("==========Kumaran=======",subMenuName.props.children.props.children);
    //   subMenuName = subMenuName.props.children.props.children;
    // } else {
    //   console.log("It is not object",subMenuName);
    // }
    if(localStorage.getItem('myActiveMenu') !== "")
    {
      localStorage.removeItem('myActiveMenu');
    }
    if(localStorage.getItem('mySubMenu') !== "")
    {
      localStorage.removeItem('mySubMenu');
      console.log("Submenu Inside====",subMenuName);
    }
    console.log("Submenu outside====",subMenuName);
    localStorage.setItem('myActiveMenu', parentMenuName);
    localStorage.setItem('mySubMenu', subMenuName);
    setMenuOpen(parentMenuName);
    setSubMenuOpen(subMenuName);
  };

  const {
    isSuccess: profileImageSuccess,
    response: profileImageResponse,
    isError: profileImageError,
  } = useSelector((state) => state.profileImage);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const clearStorage = () => {
    localStorage.clear();
    dispatch(userProfileReset());
    dispatch(socialLoginReset());
  };

  
  React.useEffect(() => {
    dispatch(getVolunteerCount());
  }, []);

  React.useEffect(() => {
    if (profileImageSuccess || profileImageError) {
      setLoading(false);
    }
  }, [profileImageSuccess, profileImageError]);

  React.useEffect(() => {
    
    dispatch(userProfile(localStorage.getItem("userid")));
  }, []);

  React.useEffect(() => {
    Promise.all([getProfileInfo()])
  })


  const iconMenu = (iconLabel) => {
  if(iconLabel === 'Dashboard')
  {
    return <Dashboard />
  }
  else if(iconLabel === 'Organizations')
  {
    // return  <OrgIcon />
    // return <CorporateFareTwoToneIcon />
    return <CorporateFareSharpIcon />

  }
  else if(iconLabel === 'Sponsors')
  {
    // return  <OrgIcon />
    // return <CorporateFareTwoToneIcon />
    return <AccountBalanceIcon />

  }
   else if(iconLabel === 'Users')
   {
    // return <VolIcon />
    // return <PeopleTwoToneIcon />
    return <PeopleSharpIcon />
   }
   else if(iconLabel === 'Campaigns')
   {
    // return  <CampIcon />
    // return <CampaignTwoToneIcon />
    return <CampaignSharpIcon />
   } 
   else if(iconLabel === 'Add User')
   {
    // return  <CampIcon />
    // return <CampaignTwoToneIcon />
    return <PersonAddIcon />
   }
   else if(iconLabel === 'Marketplace')
   {
    // return  <CampIcon />
    // return <CampaignTwoToneIcon />
    return <StoreIcon />
   }
   else if(iconLabel === 'Redeem Reward')
   {
    // return  <CampIcon />
    // return <CampaignTwoToneIcon />
    return <RedeemIcon />
   }
   
   else if(iconLabel === 'Rewards Admin')
   {
    // return  <CampIcon />
    // return <CampaignTwoToneIcon />
    return <StarsIcon />
   }
   else if(iconLabel === 'Profile') 
   {
    return <PersonIcon />
   }
   else if(iconLabel === 'Rewards') 
   {
    return <EmojiEventsIcon />
   }
   else if(iconLabel === 'About Us') 
   {
    return <InfoIcon />
   }
   
   else {
    return <Logout /> 
   }
};



  const drawer = (
      <List className="sidebar-menu">
        {profileResponse?.orgrole?.toLowerCase() === "cyaagadmin"
          ? menuItems.map((text, index) => (
            
            // <li
            //   className={classes.root}
            //   // onClick={() => {
            //   //   navigate(text.path);
            //   //   text.label === "Logout" && clearStorage();
            //   // }}
              
            //   key={text}
            //   disablePadding
            // >
             

            //         <a name={text.path} style={{cursor:"pointer"}} className="feat-btn" onClick={text.hasOwnProperty('subitems') ? handleClick:() => { navigate(text.path); text.label === "Logout" && clearStorage();}} ><i> {iconMenu(text.label)}</i> 
                
            <li
            className={`${classes.root} ${menuOpen === text.label ? 'acitveParent' : ''}`}
              key={text}
              disablePadding
            >               
               <a name={text.path} style={{cursor:"pointer"}} className={`feat-btn ${subMenuOpen === text.label && !text.hasOwnProperty('subitems') ? 'acitveChild' : ''}`} onClick={text.hasOwnProperty('subitems') ? handleClick:() => { activeSingleMenu(text.label,text.path);text.label === "Logout" && clearStorage();}} ><i> {iconMenu(text.label)}</i> 
                {text.label}<span style={{display: text.hasOwnProperty('subitems') ? "block":"none"}} class="fa fa-angle-up "></span></a>
                
                <ul className="feat-show">
                {text.subitems?.map((item, index) => {
                  return (
                    
                      // <li style={{cursor:"pointer"}}>
                      //   <a name={item.path} onClick={() => navigate(item.path)}><i class="fa fa-circle-o list-dic" aria-hidden="true"></i>{item.name}</a>
                      
                      // </li>
                      <li style={{cursor:"pointer"}} className={`${subMenuOpen === item.name ? 'acitveChild' : ''}`}>
                      <a name={item.path} onClick={(e) => activeNav(text.label,item.name,item.path)}><i class="fa fa-circle-o list-dic" aria-hidden="true"></i>{item.name}</a>
                   
                    </li>
                  );
                })}
                </ul>
              {/* </div> */}
            </li>
          ))
          : profileResponse?.orgrole?.toLowerCase() === "admin" || profileResponse?.orgrole?.toLowerCase() === "Admin"
          ? adminMenu.map((text, index) => (
            <li
            className={`${classes.root} ${menuOpen === text.label ? 'acitveParent' : ''}`}
              key={text}
              disablePadding
            >               
               <a name={text.path} style={{cursor:"pointer"}} className={`feat-btn ${subMenuOpen === text.label && !text.hasOwnProperty('subitems') ? 'acitveChild' : ''}`} onClick={text.hasOwnProperty('subitems') ? handleClick:() => { activeSingleMenu(text.label,text.path);text.label === "Logout" && clearStorage();}} ><i> {iconMenu(text.label)}</i> 
              
                {text.label}<span style={{display: text.hasOwnProperty('subitems') ? "block":"none"}} class="fa fa-angle-up "></span></a>

               <ul className="feat-show">
                {text.subitems?.map((item, index) => {
                  return (
                    
                      <li style={{cursor:"pointer"}} className={`${subMenuOpen === item.name ? 'acitveChild' : ''}`}>
                        <a name={item.path} onClick={(e) => activeNav(text.label,item.name,item.path)}><i class="fa fa-circle-o list-dic" aria-hidden="true"></i>{item.name}</a>
                     
                      </li>
                    // </ListItemButton>
                  );
                })}
                </ul>
            </li>
          )) : profileResponse?.orgrole?.toLowerCase() === "sponsoradmin" || profileResponse?.orgrole?.toLowerCase() === "sponsoradmin"
          ? sponsorAdminMenu.map((text, index) => (
            <li
            className={`${classes.root} ${menuOpen === text.label ? 'acitveParent' : ''}`}
              key={text}
              disablePadding
            >               
               <a name={text.path} style={{cursor:"pointer"}} className={`feat-btn ${subMenuOpen === text.label && !text.hasOwnProperty('subitems') ? 'acitveChild' : ''}`} onClick={text.hasOwnProperty('subitems') ? handleClick:() => { activeSingleMenu(text.label,text.path);text.label === "Logout" && clearStorage();}} ><i> {iconMenu(text.label)}</i> 
              
                {text.label}<span style={{display: text.hasOwnProperty('subitems') ? "block":"none"}} class="fa fa-angle-up "></span></a>

               <ul className="feat-show">
                {text.subitems?.map((item, index) => {
                  return (
                    
                      // <li style={{cursor:"pointer"}}>
                      //   <a name={item.path} onClick={() => navigate(item.path)}><i class="fa fa-circle-o list-dic" aria-hidden="true"></i>{item.name}</a>
                     
                      // </li>

                      <li style={{cursor:"pointer"}} className={`${subMenuOpen === item.name ? 'acitveChild' : ''}`}>
                         
                          <a name={item.path} onClick={(e) => activeNav(text.label,item.name,item.path)}><i class="fa fa-circle-o list-dic" aria-hidden="true"></i>{item.name}</a>
  
                          
                        </li>
                  );
                })}
                </ul>
            </li>
            
            
            )) : profileResponse?.orgrole?.toLowerCase() === "campadmin" || profileResponse?.orgrole?.toLowerCase() === "campadmin"
            ? campAdminMenu.map((text, index) => (
              <li
              className={`${classes.root} ${menuOpen === text.label ? 'acitveParent' : ''}`}
                key={text}
                disablePadding
              >               
                 <a name={text.path} style={{cursor:"pointer"}} className={`feat-btn ${subMenuOpen === text.label && !text.hasOwnProperty('subitems') ? 'acitveChild' : ''}`} onClick={text.hasOwnProperty('subitems') ? handleClick:() => { activeSingleMenu(text.label,text.path);text.label === "Logout" && clearStorage();}} ><i> {iconMenu(text.label)}</i> 
                
                  {text.label}<span style={{display: text.hasOwnProperty('subitems') ? "block":"none"}} class="fa fa-angle-up "></span></a>
  
                
                 <ul className="feat-show">
                  {text.subitems?.map((item, index) => {
                    return (
                      
                        // <li style={{cursor:"pointer"}}>
                        //   <a name={item.path} onClick={() => navigate(item.path)}><i class="fa fa-circle-o list-dic" aria-hidden="true"></i>{item.name}</a>
                       
                        // </li>
                        <li style={{cursor:"pointer"}} className={`${subMenuOpen === item.name ? 'acitveChild' : ''}`}>
                          
                          <a name={item.path} onClick={(e) => activeNav(text.label,item.name,item.path)}><i class="fa fa-circle-o list-dic" aria-hidden="true"></i>{item.name}</a>
  
                          
                        </li>
                        
                    );
                  })}
                  </ul>
              </li>
              
              
              )): volMenu.map((text, index) => (
              // <li
              //   className={classes.root}
              //   key={text}
              //   disablePadding
              // >
              <li
            className={`${classes.root} ${menuOpen === text.label ? 'acitveParent' : ''}`}
              key={text}
              disablePadding
            >          
                 {/* <a name={text.path} style={{cursor:"pointer"}} className="feat-btn" onClick={text.hasOwnProperty('subitems') ? handleClick:() => { navigate(text.path); text.label === "Logout" && clearStorage();}} ><i> {iconMenu(text.label)}</i> */}
                 <a name={text.path} style={{cursor:"pointer"}} className={`feat-btn ${subMenuOpen === text.label && !text.hasOwnProperty('subitems') ? 'acitveChild' : ''}`} onClick={text.hasOwnProperty('subitems') ? handleClick:() => { activeSingleMenu(text.label,text.path);text.label === "Logout" && clearStorage();}} ><i> {iconMenu(text.label)}</i> 
                  {text.label}<span style={{display: text.hasOwnProperty('subitems') ? "block":"none"}} class="fa fa-angle-up "></span></a>
  
                
                 <ul className="feat-show">
                  {text.subitems?.map((item, index) => {
                    return (
                     
                        // <li style={{cursor:"pointer"}}>
                          <li style={{cursor:"pointer"}} className={`${subMenuOpen === item.name ? 'acitveChild' : ''}`}>
                          {/* {JSON.stringify(item.path)} */}
                          {/* <a onClick={() => navigate(item.path)}><i class="fa fa-circle-o list-dic" aria-hidden="true"></i>{item.name}</a> */}
                          {/* <a onClick={() => navigate(item.path)}><i class="list-dic" aria-hidden="true"></i>{item.name}</a> */}
                          <a name={item.path} onClick={(e) => activeNav(text.label,item.name,item.path)}><i class="fa fa-circle-o list-dic" aria-hidden="true"></i>{item.name}</a>
  
                          
                        </li>
                      // </ListItemButton>
                    );
                  })}
                  </ul>
              </li>
          ))}
      </List>
  );

  return (
    <nav class="sidebar">
     <div className="text"><img src={profileResponse?.logo} alt="cYAAG"/></div>
      {drawer}   
      </nav>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;