/** @format */

const GET_VOLUNTEER_NAME_FETCHING_DATA = "GET_VOLUNTEER_NAME_FETCHING_DATA";
const GET_VOLUNTEER_NAME_FETCHING_DATA_FAILURE =
  "GET_VOLUNTEER_NAME_FETCHING_DATA_FAILURE";
const GET_VOLUNTEER_NAME_FETCHING_DATA_RESET =
  "GET_VOLUNTEER_NAME_FETCHING_DATA_RESET";
const GET_VOLUNTEER_NAME_FETCHING_DATA_SUCCESS =
  "GET_VOLUNTEER_NAME_FETCHING_DATA_SUCCESS";
const GET_VOLUNTEER_NAME_FETCHING_DATA_ATTEMPT =
  "GET_VOLUNTEER_NAME_FETCHING_DATA_ATTEMPT";
const getVolunteerName = (payload) => ({
  type: GET_VOLUNTEER_NAME_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const getVolunteerNameReset = (payload) => ({
  type: GET_VOLUNTEER_NAME_FETCHING_DATA_RESET,
  payload: payload,
});

export {
  GET_VOLUNTEER_NAME_FETCHING_DATA,
  GET_VOLUNTEER_NAME_FETCHING_DATA_FAILURE,
  GET_VOLUNTEER_NAME_FETCHING_DATA_RESET,
  GET_VOLUNTEER_NAME_FETCHING_DATA_SUCCESS,
  GET_VOLUNTEER_NAME_FETCHING_DATA_ATTEMPT,
  getVolunteerName,
  getVolunteerNameReset,
};
