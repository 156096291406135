/** @format */

const SOCIAL_EMAIL_LOGIN_FETCHING_DATA = 'SOCIAL_EMAIL_LOGIN_FETCHING_DATA';
const SOCIAL_EMAIL_LOGIN_FETCHING_DATA_FAILURE = 'SOCIAL_EMAIL_LOGIN_FETCHING_DATA_FAILURE';
const SOCIAL_EMAIL_LOGIN_FETCHING_DATA_RESET = 'SOCIAL_EMAIL_LOGIN_FETCHING_DATA_RESET';
const SOCIAL_EMAIL_LOGIN_FETCHING_DATA_SUCCESS = 'SOCIAL_EMAIL_LOGIN_FETCHING_DATA_SUCCESS';
const SOCIAL_EMAIL_LOGIN_FETCHING_DATA_ATTEMPT = 'SOCIAL_EMAIL_LOGIN_FETCHING_DATA_ATTEMPT';
const socialEmailLogin = payload => ({
	type: SOCIAL_EMAIL_LOGIN_FETCHING_DATA_ATTEMPT,
	payload: payload,
});

const socialEmailLoginSuccess = () => ({
	type: SOCIAL_EMAIL_LOGIN_FETCHING_DATA_SUCCESS,
});
const socialEmailLoginReset = () => ({
	type: SOCIAL_EMAIL_LOGIN_FETCHING_DATA_RESET,
});

export { SOCIAL_EMAIL_LOGIN_FETCHING_DATA, SOCIAL_EMAIL_LOGIN_FETCHING_DATA_FAILURE, SOCIAL_EMAIL_LOGIN_FETCHING_DATA_RESET, SOCIAL_EMAIL_LOGIN_FETCHING_DATA_SUCCESS, SOCIAL_EMAIL_LOGIN_FETCHING_DATA_ATTEMPT, socialEmailLogin, socialEmailLoginSuccess, socialEmailLoginReset };


