/** @format */

import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  GridList,
  GridListTile,
  Tab,
  Tabs,
  withStyles,
} from "@material-ui/core";
import {
  Avatar,
  Badge,
  CircularProgress,
  Collapse,
  Icon,
  MenuItem,
  useMediaQuery,
  useTheme,
  createTheme,
  ThemeProvider,
  Tooltip,
} from "@mui/material";

import _ from "lodash";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import Toolbar from "@mui/material/Toolbar";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
// import "../../scss/containerStyles/_home.scss";
// import "../../App.css";
// import "../../cyaag-style.css";
// import "../../bootstrap.min.css";
// import "../../icon-font.css";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
// import { userProfile, userProfileReset } from "../../redux/action/UserProfileActions";
import {
  userProfile,
  userProfileReset,
} from "../redux/action/UserProfileActions";
import { CopyRight } from "./CopyRight";
import { getVolunteerCount } from "../redux/action/volunteerCountAction";
import { volunteerMenuItems } from "../utils/dict";
import ResponsiveDrawer from "./Header";
import { HomeCard } from "./Card";
import axios from "axios";
import { API_URL } from "../services/Constants";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { socialLoginReset } from "../redux/action/loginAction";
import { getUserProfileImage } from "../redux/action/userProfileImage";
import rewardIcon from "../assets/rewardIconNav.png";
import { volDashBoardReward } from "../redux/action/DashboardReward";
import GradeSharpIcon from "@mui/icons-material/GradeSharp";
import { ColorModeContext, useMode, tokens } from "../theme";
import { LogoutOutlined } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import FireplaceIcon from '@mui/icons-material/Fireplace';
import PersonIcon from "@mui/icons-material/Person";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const dispatch = useDispatch();

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const theme = createTheme({
  components: {
    MuiIcon: {
      styleOverrides: {
        root: {
          // Match 24px = 3 * 2 + 1.125 * 16
          boxSizing: "content-box",
          padding: 3,
          fontSize: "1.125rem",
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      'sans-serif'
    ].join(','),
  },
});

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 100px 330px",
    },
  },
  homeScreenContainer: {
    padding: "0 0 0 20px",
    zIndex: 100,
  },
  welcomeMessage: {
    fontWeight: "bold",
    marginBottom: 10,
    margin: "10px 0",
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
  },
  welcomeAndFilter: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    textTransform: "uppercase",
    padding: "10px 0",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  filterIcon: {
    fontSize: 26,
    color: "#F8B34C",
    marginLeft: 28,
  },
  homeCards: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    margin: "0 -7px",

    // backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    display: "grid",
    // gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: "1rem",
    [theme.breakpoints.only("xl")]: {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    [theme.breakpoints.only("lg")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    [theme.breakpoints.only("md")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.only("sm")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    [theme.breakpoints.only("xs")]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
  },
  maxWidthContainer: {
    padding: "25px 0",
    maxWidth: 500,
    margin: "0 auto",
    // borderTop: '2px solid #F8B34C',
  },
  gradientHeading: {
    fontSize: 25,
    fontWeight: "bold",
  },
  rewards: {
    display: "flex",
    justifyContent: "center",
    color: "#F8B34C",
    paddingBottom: 13,
  },
  rewardsText: {
    fontSize: 25,
    fontWeight: "bold",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 9,
  },
  secondaryBorder: {
    padding: "5px 0",
    borderBottom: "2px solid #F8B34C",
  },
  myImpacts: {
    padding: "15px 0 0 0",
    fontSize: 14,
    textAlign: "center",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  myImpactsWrap: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 -8px",
    outline: "none",
  },
  myImpact: {
    width: "150px",
    margin: "0 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    outline: "0 !important",
    border: "none",
  },
  myImpactImgWrap: {
    width: 52,
    height: 50,
    margin: "0 auto 5px",
  },
  myImpactImg: {
    width: "100%",
  },
  myImpactTxt: {
    display: "block",
  },
  myCommunity: {
    borderBottom: "2px solid #F8B34C",
    padding: "15px 0",
    fontSize: 20,
    color: "#F8B34C",
  },
  myCommunityContentWrap: {
    display: "flex",
    justifyContent: "space-between",
  },
  myCommunityImg: {
    width: 100,
  },
  inviteBtn: {
    paddingTop: 15,
    width: 150,
  },
  myCommunityPoints: {
    listStyle: "none",
    margin: "0 0 0 10px",
    padding: 0,
  },
  inviteButton: {
    fontSize: 9,
    fontWeight: "bold",
    lineHeight: 1,
  },
  myRegion: {
    borderBottom: "2px solid #F8B34C",
    padding: "15px 0",
  },
  mapImg: {
    width: 130,
    margin: "0 auto",
  },
  myYaags: {
    padding: "15px 0",
  },

  bottomNavigation: {
    position: "absolute",
    width: "100%",
  },
  tabName: {
    // color: "#fff",
    color: "#00000",
    textTransform: "none",
    // ariaSelected: "true",
  },
  pointBox: {
    // top: "22px",
    // left: "200px",
    // top: "22%",
    minWidth: "65px",
    height: "39px",
    background: "#1A524D 0% 0% no-repeat padding-box",
    borderRadius: "100px",
    opacity: "90%",
    color: "#FFFFFF",
    fontSize: 15,
    fontWeight: "bold",
  },
  rewardclick: {
    position: "relative",
    padding: 7,
    paddingRight: 25,
    color: "black",
    display: "flex",
    cursor: "pointer",
  },
  large: {
    width: "50px !important",
    height: "50px !important",
    flexDirection: "row",
    // display: "flex",
    // justifyContent: "center",
    // textAlign: "center",
  },
  small: {
    width: "40px !important",
    height: "40px !important",
    flexDirection: "row",
    // display: "flex",
    // justifyContent: "center",
    // textAlign: "center",
  },
  mouseHover: {
    "&:hover": {
      textDecoration: "underline",
      color: "blue",
    },
  },
  chatmouseHover: {
    "&:hover": {
      textDecoration: "underline",
      color: "#f28800",
      title: "Chat",
      // backgroundColor: "#f28800",
      // border: "3px solid #f28800",
    },
  },
}));

const TopHeaderScreen1 = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [campaignList, setCampaignList] = useState([]);
  const [rewarddash, setRewardDash] = useState([]);
  const [checked, setChecked] = useState(false);
  const [value, setValue] = React.useState(0);
  const [image, setImage] = React.useState(null);
  const [activeTrue, setActiveTrue] = React.useState(false);

  const [menuOpen, setMenuOpen] = React.useState(localStorage.getItem('myActiveMenu') === "" ? 'Campaigns' : localStorage.getItem('myActiveMenu') );

  const [subMenuOpen, setSubMenuOpen] = React.useState(localStorage.getItem('mySubMenu') === "" ? 'Home' : localStorage.getItem('mySubMenu') );

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  // console.log(profileResponse)
  const { isSuccess: myRewardSuccess, response: myRewardResponse } =
    useSelector((state) => state.volDashBoardReward);
  // const { isSuccess: myrewardSuccess, response: response } = useSelector(
  //   (state) => state
  // );
  // const rewardResponse1 = useSelector((state) => state.volDashBoardReward);
  // console.log(rewardResponse1)
  // console.log(myRewardResponse)
  // console.log(rewarddash)
  const userid = localStorage.getItem("userid");
  const clearStorage = () => {
    localStorage.clear();
    dispatch(userProfileReset());
    dispatch(socialLoginReset());
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isActive, setActive] = useState(false);

  // const toggleMenu = () => {
  //   $('.sidebar').toggleClass('hide');
  //   $(this).toggleClass("click");
  // };

  const [loading, setLoading] = React.useState(false);

  const handleChangeFile = (e) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("profilephoto", e.target.files[0]);
    dispatch(
      getUserProfileImage({ userId: localStorage.getItem("userid"), formData })
    );
    setImage(e.target.files[0]);
  };

  const updateNotificationFlag = async () => {
    try {
      let formData = new FormData();
      // formData.append("notifyflag", "true");
      formData.append("flag", "true");

      const res = await axios({
        url: `/notification/flag/${profileResponse?.phoneno}`,
        method: "put",
        data: formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      dispatch(userProfile(localStorage.getItem("userid")));
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getVolunteerCamp = async () => {
    try {
      const res = await axios.get(
        `vol_dashboard/camp/${profileResponse?.orgrole}/${profileResponse?.organization}`
      );
      setCampaignList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };
  const getMyReward = async () => {
    try {
      const res = await axios.get(`/vol_dashboard/myreward/${userid}`);
      setRewardDash(res?.data);
      dispatch(volDashBoardReward(res?.data, res?.data?.[0]?.phoneno));
      return res;
    } catch (err) {
      throw err;
    }
  };

  const activeNav = (parentMenuName,subMenuName,itemPath) => {
    navigate(itemPath);
    console.log("Label Text====",parentMenuName);
    if(localStorage.getItem('myActiveMenu') !== "")
    {
      localStorage.removeItem('myActiveMenu');
    }
    if(localStorage.getItem('mySubMenu') !== "")
    {
      localStorage.removeItem('mySubMenu');
    }
    localStorage.setItem('myActiveMenu', parentMenuName);
    localStorage.setItem('mySubMenu', subMenuName);
    setMenuOpen(parentMenuName);
    setSubMenuOpen(subMenuName);
  };

  const activeSingleMenu = (singleMenuName,itemPath) => {
    navigate(itemPath);
    if(localStorage.getItem('myActiveMenu') !== "")
    {
      localStorage.removeItem('myActiveMenu');
    }
    if(localStorage.getItem('mySubMenu') !== "")
    {
      localStorage.removeItem('mySubMenu');
    }
    localStorage.setItem('mySubMenu', singleMenuName);
    setMenuOpen("");
    setSubMenuOpen(singleMenuName);
  }


  useEffect(() => {
    dispatch(userProfile(userid));
    dispatch(getVolunteerCount());
    getVolunteerCamp();
    getMyReward();
    // dispatch(volDashBoardReward());
  }, []);

  return (
    <div className="wrappingMenuToggle">
      <div className="nav-top">
        <nav class="navbar-expand-lg navbar-white mobileNavBar">
          <div class="collapse navbar-collapse">
            <ul class="navbar-nav ml-auto">

            <li
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  marginRight: "15px",
                }}
                className="homeIcon"
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={(e) => activeNav("Campaigns","Home","/kthome")}
                >
                  <i title="Home" class="flaticon-home-line"></i>
                </span>
              </li>

              <li
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  marginRight: "15px",
                }}
                className="SearchIcon"
              >
                <span
                  style={{ cursor: "pointer" }}
                  title="search"
                  //onClick={() => navigate(`/searchpage`)}
                  onClick={(e) => activeNav("NoMenu","NoSubMenu","/searchpage")}
                >
                  <SearchIcon />
                </span>
              </li>
              {/* <li class="nav-item dropdown">
                <div
                  className={classes.pointBox}
                  style={{
                    marginRight: "35px",
                    backgroundColor: "rgba(235, 149, 50, 1)",
                  }}
                >
                  <div
                    className={classes.rewardclick}
                    onClick={(e) => activeNav("NoMenu","NoSubMenu","/mypoints")}
                  >
                    <img
                      src="https://www.iconpacks.net/icons/2/free-binance-coin-icon-2211-thumb.png"
                      class="rewardHeaderIcon"
                    />
                    &nbsp; &nbsp;
                    <span style={{ fontSize: 18, color: "indigo" }}>
                      {rewarddash.rewardpoint}
                    </span>
                  </div>
                </div>
              </li> */}
              {/* <li class="nav-item dropdown "> */}
              <li class="nav-item  ">
                <a
                  href="#"
                  class="nav-link dropdown-toggle icon-clr"
                  data-toggle="dropdown"
                >
                  <i
                    class="fa fa-bell fa-lg"
                    style={{ color: "gray" }}
                    onClick={() =>
                      !props.notificationShow && updateNotificationFlag()
                    }
                  ></i>
                  {/* <Badge
                    badgeContent={profileResponse?.notificationcount}
                    color="error"
                    onClick={() =>
                      !props.notificationShow && updateNotificationFlag()
                    }
                  ></Badge> */}
                </a>

                <div class="dropdown-menu dropdown-menu-right">
                  {/* <div
                    class="notificationWrapper"
                    style={{
                      position: "absolute",
                      width: 330,
                      height: "50vh",
                      right: "0%",
                      top: 0,
                      boxShadow: "1px 2px 10px rgba(0,0,0,0.2)",
                      borderRadius: 8,
                      overflow: "scroll",
                      background: "#fff",
                    }}
                  > */}
                    {/* <div
                      class="messageHeader"
                      style={{
                        textTransform: "capitalize",
                        float: "left",
                        width: "330px",
                        backgroundColor: "#00399a",
                        color: "#fff",
                        fontSize: "18px",
                        padding: "10px",
                        position: "fixed",
                      }}
                    >
                      Alerts center
                    </div> */}
                    {/* <div style={{ marginTop: "48px" }}>
                      {_.isEmpty(profileResponse?.notification) ? (
                        <div>
                          <span
                            style={{
                              background: "#fff",
                              float: "left",
                              width: "100%",
                              padding: "10px 12px",
                            }}
                          >
                            No notifications yet
                          </span>
                        </div>
                      ) : (
                        profileResponse?.notification?.map((item, index) => (
                          <div>
                            <div
                              class=""
                              style={{
                                background: "#fff",
                                float: "left",
                                width: "100%",
                                padding: "10px 12px",
                                borderBottom: "1px solid #ccc",
                              }}
                            >
                              <div
                                class=""
                                style={{
                                  float: "left",
                                  width: "50px",
                                  marginRight: "10px",
                                }}
                              >
                                <img
                                  src={item.logo}
                                  className={classes.large}
                                  style={{ borderRadius: "8px" }}
                                />
                              </div>
                              <div
                                class=""
                                style={{
                                  color: "#000",
                                  float: "left",
                                  width: "calc(100% - 60px)",
                                }}
                                
                                onClick={() => {
                                  item.type === "campaign" && item.status === "Active" 
                                    ? 
                                      activeNav("NoMenu","NoSubMenu",`/participate/${item.campid}/${profileResponse?.phoneno}`)
                                      : item.type === "campaign" && item.status === "Upcoming" ?
                                      activeNav("NoMenu","NoSubMenu",`/participate/${item.campid}/${profileResponse?.phoneno}`)
                                      : item.type === "campaign" && item.status === "Completed" ?
                                      activeNav("NoMenu","NoSubMenu",`/kthome`)
                                    : activeNav("NoMenu","NoSubMenu",`/mypoints`)
                                }}
                              
                              >
                                <span
                                  style={{
                                    color: "#000",
                                    // display: "flex",
                                    display: "inline-block",
                                    // justifyContent: "flex-end",
                                    fontSize: 10,
                                    color: "grey",
                                    display: "block",
                                  }}
                                >
                                  {moment(item?.createdate).fromNow()}
                                </span>
                                <span
                                  style={{
                                    fontSize: "12px",
                                    cursor: "pointer",
                                    display: "block",
                                  }}
                                  className={classes.mouseHover}
                                >
                                  {item?.msg}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div> */}
                    {/* <div
                      style={{
                        fontSize: "12px",
                        cursor: "pointer",
                        display: "block",
                        marginTop: "48px",
                      }}
                      className={classes.mouseHover}
                      onClick={(e) => activeNav("NoMenu","NoSubMenu","/notificationarchive")}
                    >
                      <u>See More</u>
                    </div> */}
                  {/* </div> */}
                  {/* <div>
                    HI
                  </div> */}
                </div>
              </li>
              &nbsp; &nbsp;
              
              <li class="nav-item dropdown">
                <a
                  href="#"
                  class="nav-link"
                  size="40"
                  data-toggle="dropdown"
                  className={
                    profileResponse?.profilephoto ? "removePadding" : ""
                  }
                >
                  {profileResponse?.profilephoto ? (
                    <Avatar
                      src={
                        image
                          ? URL.createObjectURL(image)
                          : profileResponse?.profilephoto
                      }
                      className={classes.large}
                      style={{}}
                    />
                  ) : (
                    
                    <Avatar
                      src={<PersonIcon />}
                      className={classes.small}
                      style={{backgroundColor:"white", color:"gray"}}
                    />
                  )}
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a
                    class="dropdown-item"
                    onClick={() =>
                      activeSingleMenu("Profile",`/newprofile/${profileResponse?.profileurl}`)
                    }
                  >
                    <i class="fa fa-fw fa-user"></i> Profile
                  </a>
                  <a
                    class="dropdown-item"
                    title="Available in future release"
                    disabled="disabled"
                    href="#"
                  >
                    <i class="fa fa-fw fa-gear"></i> Settings
                  </a>
                  <div class="dropdown-divider"></div>
                  <a
                    class="dropdown-item"
                    onClick={() => {
                      navigate("/");
                      clearStorage();
                    }}
                  >
                  
                    
                    <Box display="flex" alignItems="center">
                    <Box sx={{ paddingRight: 2.5, display: 'flex', alignItems: 'center' }}>
                      <LogoutOutlined sx={{ fontSize: 18, marginLeft: 0.5 }} />
                      
                      </Box>
                      
                      <Typography variant="body1" sx={{ fontSize: 18, marginLeft: 0.5, fontFamily: 'Roboto, sans-serif' }}
                      style={{ fontFamily: 'Roboto, sans-serif', fontWeight:400 }}>Logout</Typography>
                    </Box>
                    
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div class="btns toggleMenu" id="toggleMenuClick">
        <span class="fa fa-bars"></span>
      </div>
    </div>
  );
};

export default TopHeaderScreen1;
