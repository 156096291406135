/** @format */

const REFERRAL_FETCHING_DATA = 'REFERRAL_FETCHING_DATA';
const REFERRAL_FETCHING_DATA_FAILURE = 'REFERRAL_FETCHING_DATA_FAILURE';
const REFERRAL_FETCHING_DATA_RESET = 'REFERRAL_FETCHING_DATA_RESET';
const REFERRAL_FETCHING_DATA_SUCCESS = 'REFERRAL_FETCHING_DATA_SUCCESS';
const REFERRAL_FETCHING_DATA_ATTEMPT = 'REFERRAL_FETCHING_DATA_ATTEMPT';
const referral = payload => ({
	type: REFERRAL_FETCHING_DATA_ATTEMPT,
	payload: payload,
});
const referralReset = payload => ({
	type: REFERRAL_FETCHING_DATA_RESET,
	payload: payload,
});

export { REFERRAL_FETCHING_DATA, REFERRAL_FETCHING_DATA_FAILURE, REFERRAL_FETCHING_DATA_RESET, REFERRAL_FETCHING_DATA_SUCCESS, REFERRAL_FETCHING_DATA_ATTEMPT, referral, referralReset };
