/** @format */

import { call, put } from "redux-saga/effects";
import { get } from "lodash";

import {
  GET_USER_PROFILE_IMAGE_FETCHING_DATA,
  GET_USER_PROFILE_IMAGE_FETCHING_DATA_SUCCESS,
  GET_USER_PROFILE_IMAGE_FETCHING_DATA_FAILURE,
} from "../action/userProfileImage";
import { Api } from "../../services";

const myAPI = new Api();

export function* getUserProfileImage(action) {
  yield put({
    type: GET_USER_PROFILE_IMAGE_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.userProfileImage, action.payload);
    const payload = get(response, "data");
    yield put({
      type: GET_USER_PROFILE_IMAGE_FETCHING_DATA_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({ type: GET_USER_PROFILE_IMAGE_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}
