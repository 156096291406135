/** @format */

import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
// import Logo from "../../assets/logo-black.png";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

// import "../scss/containerStyles/_home.scss";
// import "../App.css";

import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import ResponsiveDrawer from "../../components/Header";

import { CopyRight } from "../../components/CopyRight";
// import ExecHeader from "../components/ExecHeader";
import { ValidateRewardsRedeemed } from "../../components/ValidateRewardsRedeemed";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { rewardsRedeemedList } from "../../redux/action/RewardsRedeemedList";
// import { purchaseYaagReset } from "../redux/action/PurchaseYaag";
import { Checkbox } from "@material-ui/core";
import { updateUser } from "../../redux/action/UpdateUserAction";
import { userProfile } from "../../redux/action/UserProfileActions";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useNavigate } from "react-router-dom";
import {  Button } from "@mui/material";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";

const useStyles = makeStyles((theme) => ({
  homeSreenManiContent: {
    // padding: "45px 0 0 20px",
    [theme.breakpoints.up("sm")]: {
      // padding: "45px 0 0 0px",
    },
  },
  homeScreenContainer: {
    padding: "0 20px",
  },
  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginRight: 10,
    color: "#fff",
  },
  table: {
    width: "100%",
  },
  backBtn: {
    display: "flex",
    color: "rgb(25, 118, 210)",
    
    // alignItems: "center",
    // [theme.breakpoints.up("xl")]: {
    //   padding: "160px 0 0 260px",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   padding: "160px 0 0 260px",
    // },
    // [theme.breakpoints.up("md")]: {
    //   padding: "120px 0 0 180px",
    // },
    // [theme.breakpoints.up("sm")]: {
    //   padding: "82px 0 0 130px",
    // },
    // [theme.breakpoints.up("xs")]: {
    //   padding: "82px 0 0 0px",
    // },
    [theme.breakpoints.only("xl")]: {
      // padding: "5%"
      width: "100%"
    },
    [theme.breakpoints.only("lg")]: {
      // padding: "5%"
      width: "100%"
    },
    [theme.breakpoints.only("md")]: {
      // padding: "10%"
      width: "100%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      padding: "0px 0 0 10px"
      // width: "10%"
    },
  },
  

}));


const data = [
  {
    id: 0,
    userid: 9779860513158,
    fullname: "Rajib",
    affiliaton: "Tribhuwan University",
    yaagName: "Bentech",
  },
  {
    id: 1,
    userid: 9779865479912,
    fullname: "Gautam",
    affiliaton: "Banglore University",
    yaagName: "Flora",
  },
  {
    id: 2,
    userid: 9779823561856,
    fullname: "Kiran",
    affiliaton: "IIT",
    yaagName: "Poorti",
  },
  {
    id: 0,
    userid: 9779860513158,
    fullname: "Rajib",
    affiliaton: "Tribhuwan University",
    yaagName: "Bentech",
  },
  {
    id: 1,
    userid: 9779865479912,
    fullname: "Gautam",
    affiliaton: "Banglore University",
    yaagName: "Flora",
  },
  {
    id: 2,
    userid: 9779823561856,
    fullname: "Kiran",
    affiliaton: "IIT",
    yaagName: "Poorti",
  },
  {
    id: 0,
    userid: 9779860513158,
    fullname: "Rajib",
    affiliaton: "Tribhuwan University",
    yaagName: "Bentech",
  },
  {
    id: 1,
    userid: 9779865479912,
    fullname: "Gautam",
    affiliaton: "Banglore University",
    yaagName: "Flora",
  },
  {
    id: 2,
    userid: 9779823561856,
    fullname: "Kiran",
    affiliaton: "IIT",
    yaagName: "Poorti",
  },
];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F8B34C",
    color: "#ffffff",
    textTransform: "uppercase",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    // '&:nth-of-type(odd)': {
    // 	backgroundColor: '#f7dbb3',
    // },
    // '&:nth-of-type(even)': {
    // 	backgroundColor: '#e9e7e8',
    // },
  },
}))(TableRow);

function createDataValidator(
    id,
    firstname,
    // formuserfullname,
    lastname,
    phoneno,
    organization,
    rewardtype,
    title,
    subtitle,
    points,
    status,
    rewarddate,
    mpid,
) {
  return {
    id,
    firstname,
    // formuserfullname,
    lastname,
    phoneno,
    organization,
    rewardtype,
    title,
    subtitle,
    points,
    status,
    rewarddate,
    mpid,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  // console.log(props)
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
  // console.log(props)
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const SOActionOnRewardsRedeemed = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [welcomeOpen, setWelcomeOpen] = React.useState(true);
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const userid = localStorage.getItem("userid");
  const [value1, setValue1] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [reviewStatus, setReviewStatus] = React.useState("");
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [checked, setChecked] = useState(false);
  const [campaignList, setCampaignList] = useState(
    [
      'campaignname',
      'formuserfirstname',
      'formuserfirstname',
      'formuserphoneno',
      'registrationstatus',
      'rewarddate'
    ]
  );
  const theme = useTheme();
  const tableRef = useRef(null);
  // const validatorListResponse = useSelector((state) => state.validatorList);
  const { isSuccess: validatorListSuccess, response: validatorListResponse } = useSelector(
    (state) => state.rewardsRedeemedList
  );
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  // console.log(profileResponse?.phoneno)
  const handleAcceptClick = (item) => {
    // console.log(item._id, "item");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(rewardsRedeemedList());
    // dispatch(validatorList(newValue));
  };

  const handleConfirmWelcome = () => {
    localStorage.setItem("firstTime", true);
    setWelcomeOpen(false);
    if (
      profileSuccess &&
      (profileResponse[0]?.legal === "no" ||
        profileResponse[0]?.legal === undefined)
    )
      dispatch(
        // updateUser({
        //   fullname: profileSuccess && profileResponse[0]?.fullname,
        //   email: profileSuccess && profileResponse[0]?.email,
        //   affiliate: profileSuccess && profileResponse[0]?.affiliate,
        //   userid: profileSuccess && profileResponse[0]?.userid,
        //   role: "validator",
        //   joiningbonus: 50,
        //   legal: "yes",
        // })
      );
  };
  useEffect(() => {

    // console.log("start 2222")    
    // validationList1();
    dispatch(rewardsRedeemedList(profileResponse));
    dispatch(userProfile(userid));
    // validatorList
    // console.log(campaignList);
    // console.log("end 2222")   

  }, [campaignList]);
  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    // dispatch(validatorList());
    dispatch(rewardsRedeemedList(profileResponse));
    dispatch(userProfile(userid));
  }, []);

  useEffect(() => {
    if (
      profileSuccess &&
      (profileResponse[0]?.legal === "no" ||
        profileResponse[0]?.legal === undefined)
    ) {
      setWelcomeOpen(true);
    } else {
      setWelcomeOpen(false);
    }
  }, [profileResponse]);

  useEffect(() => {
    // console.log("useeffect")
    let temp = [];
    for (let i = 0; i < validatorListResponse.length; i += 1) {
      const formattedArray = [];
      // console.log(validatorListResponse[i])
      formattedArray.push(
        // validatorListResponse[i]._id,
        validatorListResponse[i].firstname,
        // validatorListResponse[i].formuserfullname,
        validatorListResponse[i].lastname,
        validatorListResponse[i].phoneno,
        validatorListResponse[i].organization,
        // validatorListResponse[i].affiliation,
        validatorListResponse[i].rewardtype,
        validatorListResponse[i].title,
        validatorListResponse[i].subtitle,
        validatorListResponse[i].points,
        validatorListResponse[i].status,
        validatorListResponse[i].rewarddate,
        validatorListResponse[i].mpid,
        // validatorListResponse[i].documentuploadcount,

       
      );
      console.log(formattedArray);
      temp.push(createDataValidator(i, ...formattedArray));
      console.log("temp");
      console.log(temp);
    }
    setRows(temp);
  }, [validatorListResponse]);

  useEffect(() => {
    const firstTime = localStorage.getItem("firstTime");
    if (firstTime) {
      setWelcomeOpen(true);
    }
  }, []);

  
  const reviewedData = rows.filter((item) => item.status === "open");
  const acceptedData = rows.filter((item) => item.status === "close");
  // const rejectedData = rows.filter((item) => item.registrationstatus === "rejected");

  const sortedDataReview =
    inputValue === ""
      ? ""
      : reviewedData.filter((item) => item.firstname === inputValue);

  const sortedDataApprove =
    inputValue === ""
      ? ""
      : acceptedData.filter((item) => item.firstname === inputValue);

  // const sortedDataRejected =
  //   inputValue === ""
  //     ? ""
  //     : rejectedData.filter((item) => item.organization === inputValue);

  console.log(rows);
  // console.log(sortedDataApprove)
  // console.log(sortedDataRejected)
  return (
    <div class="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
      <TopHeaderScreen {...props} />
      {/* <Link className="backBtnSOAdmin" to={"/home"}> */}
      <div class="content content-wrap">
          <div class="content-mrg">
          <Button
                onClick={() => navigate(-1)}
                className={classes.backBtn}
                style={{justifyContent:"left"}}
            >
                <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
      <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Manage Reward Fulfilment</div>
      <main className={classes.homeSreenManiContent} id="exec" style={{ marginLeft: "400" }} >
        <Tabs
          style={{ display: "flex", justifyContent: "center", marginRight: "100" }}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="open" />
          <Tab label="close" />
          {/* <Tab label="Rejected" /> */}
        </Tabs>
        <TabPanel value={value} index={0} >
          <ValidateRewardsRedeemed
            value1={value1}
            setValue1={setValue1}
            inputValue={inputValue}
            setInputValue={setInputValue}
            // response={response}
            // isFetching={isFetching}
            rows={reviewedData}
            sortedData={sortedDataReview}
            actionTaken={false}
            setReviewStatus={setReviewStatus}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ValidateRewardsRedeemed
            value1={value1}
            setValue1={setValue1}
            inputValue={inputValue}
            setInputValue={setInputValue}
            // response={response}
            // isFetching={isFetching}
            rows={acceptedData}
            sortedData={sortedDataApprove}
            actionTaken
          />
        </TabPanel>
        {/* <TabPanel value={value} index={2}>
          <ValidatorTable
            value1={value1}
            setValue1={setValue1}
            inputValue={inputValue}
            setInputValue={setInputValue}
            // response={response}
            // isFetching={isFetching}
            rows={rejectedData}
            sortedData={sortedDataRejected}
            actionTaken
          />
        </TabPanel> */}
      </main>

      {/* {welcomeOpen && (
        <Dialog
          className={classes.welcomeDialog}
          open={welcomeOpen}
          onClose={handleCancel}
          aria-labelledby="responsive-dialog-title"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Checkbox
              checked={checked}
              onChange={handleChangeChecked}
              disabled={profileResponse[0]?.legal === "yes" ? true : false}
            />
            <Typography>
              I hereby accept the terms and conditions.
              <a href="https://www.cyaag.com/policies" target="_blank">
                {" "}
                https://www.cyaag.com/policies
              </a>
            </Typography>
          </div>
          <DialogActions>
            {checked && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
                onClick={handleConfirmWelcome}
              >{`Next >>>>`}</div>
            )}
          </DialogActions>
        </Dialog>
      )} */}

      {/* </section> */}

      {/* <CopyRight /> */}
      </div>
      </div>
      <CopyRight />
      <FixedFooterIcon />
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({}, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SOActionOnRewardsRedeemed);