/** @format */

const ADD_RECOGNITIONS_FETCHING_DATA = "ADD_RECOGNITIONS_FETCHING_DATA";
const ADD_RECOGNITIONS_FETCHING_DATA_FAILURE = "ADD_RECOGNITIONS_FETCHING_DATA_FAILURE";
const ADD_RECOGNITIONS_FETCHING_DATA_RESET = "ADD_RECOGNITIONS_FETCHING_DATA_RESET";
const ADD_RECOGNITIONS_FETCHING_DATA_SUCCESS = "ADD_RECOGNITIONS_FETCHING_DATA_SUCCESS";
const ADD_RECOGNITIONS_FETCHING_DATA_ATTEMPT = "ADD_RECOGNITIONS_FETCHING_DATA_ATTEMPT";
const addRecognitions = (payload) => ({
  type: ADD_RECOGNITIONS_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const addRecognitionsSuccess = () => ({
  type: ADD_RECOGNITIONS_FETCHING_DATA_SUCCESS,
});
const addRecognitionsReset = () => ({
  type: ADD_RECOGNITIONS_FETCHING_DATA_RESET,
});

export {
    ADD_RECOGNITIONS_FETCHING_DATA,
    ADD_RECOGNITIONS_FETCHING_DATA_FAILURE,
    ADD_RECOGNITIONS_FETCHING_DATA_RESET,
    ADD_RECOGNITIONS_FETCHING_DATA_SUCCESS,
    ADD_RECOGNITIONS_FETCHING_DATA_ATTEMPT,
    addRecognitions,
    addRecognitionsSuccess,
    addRecognitionsReset,
};