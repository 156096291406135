/** @format */

const ONBOARD_RECOGNITIONS_FETCHING_DATA = "ONBOARD_RECOGNITIONS_FETCHING_DATA";
const ONBOARD_RECOGNITIONS_FETCHING_DATA_FAILURE = "ONBOARD_RECOGNITIONS_FETCHING_DATA_FAILURE";
const ONBOARD_RECOGNITIONS_FETCHING_DATA_RESET = "ONBOARD_RECOGNITIONS_FETCHING_DATA_RESET";
const ONBOARD_RECOGNITIONS_FETCHING_DATA_SUCCESS = "ONBOARD_RECOGNITIONS_FETCHING_DATA_SUCCESS";
const ONBOARD_RECOGNITIONS_FETCHING_DATA_ATTEMPT = "ONBOARD_RECOGNITIONS_FETCHING_DATA_ATTEMPT";
const onboardRecognitions = (payload) => ({
  type: ONBOARD_RECOGNITIONS_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const onboardRecognitionsSuccess = () => ({
  type: ONBOARD_RECOGNITIONS_FETCHING_DATA_SUCCESS,
});
const onboardRecognitionsReset = () => ({
  type: ONBOARD_RECOGNITIONS_FETCHING_DATA_RESET,
});

export {
    ONBOARD_RECOGNITIONS_FETCHING_DATA,
    ONBOARD_RECOGNITIONS_FETCHING_DATA_FAILURE,
    ONBOARD_RECOGNITIONS_FETCHING_DATA_RESET,
    ONBOARD_RECOGNITIONS_FETCHING_DATA_SUCCESS,
    ONBOARD_RECOGNITIONS_FETCHING_DATA_ATTEMPT,
    onboardRecognitions,
    onboardRecognitionsSuccess,
    onboardRecognitionsReset,
};