import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
const KTCustomerTRNReport = ({ open, onClose, props }) => {
    const [customerDetList, setCustomerDetList] = useState([]);
    const [generatedHTML, setGeneratedHTML] = useState('');
    const [myAccountSummary, setMyAccountSummary] = useState([]);

    const locationPath = useLocation();
    console.log("locationPath?.pathname.split?.[3] = ", locationPath?.pathname.split("/")?.[3])

    useEffect(() => {

        const getCustomerList = async () => {
            try {
    
                const res = await axios.get(`/kt/get/customer/trn/details/${locationPath?.pathname.split("/")?.[3]}`);
                setCustomerDetList(res.data);
                return res;
            } catch (err) {
                console.error(err);
            }
        };

        const getIndividualDebitCreditTotal = async () => {
            try {
                const res = await axios.get(`/kt/get/your/acc/summary/debit/credit/report/${locationPath?.pathname.split("/")?.[3]}`);
                setMyAccountSummary(res?.data);
                return res;
            } catch (err) {
                console.error('Error fetching account summary:', err);
            }
        };
        
        const loadData = async () => {
            await Promise.all([
                getCustomerList(),
                getIndividualDebitCreditTotal(),
               
            ]);
    
            // After loading data, generate HTML
            generateHTML();
        };
    
        loadData();
    }, [customerDetList.length, generatedHTML.length, myAccountSummary.length]);

    const generateHTML = () => {
        if (customerDetList.length > 0 && myAccountSummary.length > 0) {
            let html = '<html><body>';
        
            html += `<div style="text-align: center; border-bottom: 1px solid #ccc; padding: 10px;">
                <h1 style="color: blue;">${customerDetList[0]?.name} Statement</h1>
                <p style="color: blue;">Phone Number: <span style="color: black;">${customerDetList[0]?.phoneno}</span></p>
                <p style="color: blue;">Payment Date: <span style="color: black;">${customerDetList[0]?.paymentdate}</span></p>
                <table style="width: 100%; border-collapse: collapse; margin-top: 10px;">
                    <tr>
                        <th style="border: 1px solid #ccc; padding: 8px;">Opening Balance</th>
                        <th style="border: 1px solid #ccc; padding: 8px;">Total Debit</th>
                        <th style="border: 1px solid #ccc; padding: 8px;">Total Credit</th>
                        <th style="border: 1px solid #ccc; padding: 8px;">Balance</th>
                    </tr>
                    <tr>
                        <td style="border: 1px solid #ccc; padding: 8px;">0.0</td>
                        <td style="border: 1px solid #ccc; padding: 8px; text-align: center;">₹ ${-myAccountSummary?.[1]?.yougave}</td>
                        <td style="border: 1px solid #ccc; padding: 8px; text-align: center;">₹ ${myAccountSummary?.[0]?.yougot}</td>
                        <td style="border: 1px solid #ccc; padding: 8px; text-align: center;">₹ ${myAccountSummary?.[2].balance}</td>
                        </tr>
                </table>
                <p style="text-align: left; margin-top: 10px;">No. of entries: ${customerDetList.length}</p>
                <table style="width: 100%; border-collapse: collapse; margin-top: 10px;">
                    <tr>
                        <th style="border: 1px solid #ccc; padding: 8px;">Date</th>
                        <th style="border: 1px solid #ccc; padding: 8px;">Details</th>
                        <th style="border: 1px solid #ccc; padding: 8px; ">Debit</th>
                        <th style="border: 1px solid #ccc; padding: 8px;">Credit</th>
                        <th style="border: 1px solid #ccc; padding: 8px;">Balance</th>
                    </tr>`;
        
            customerDetList.forEach(customer => {
                const debitColor = customer.type === "You Gave" ? 'red' : 'black';
                const creditColor = customer.type === "You Got" ? 'green' : 'black';
        
                html += `
                    <tr>
                        <td style="border: 1px solid #ccc; padding: 8px;">${customer.paymentdate}</td>
                        <td style="border: 1px solid #ccc; padding: 8px;"></td>
                        <td style="border: 1px solid #ccc; padding: 8px; color: ${debitColor};">${customer.payment >= 0 ? customer.payment : ''}</td>
                        <td style="border: 1px solid #ccc; padding: 8px; color: ${creditColor};">${customer.payment < 0 ? -customer.payment : ''}</td>
                        <td style="border: 1px solid #ccc; padding: 8px;">${customer.balanceamount}</td>
                    </tr>`;
            });
        
            // Close the table and div tags
            html += `
                </table>
            </div>`;
            
            html += '</body></html>';
            
            setGeneratedHTML(html);
        } else {
            setGeneratedHTML('');
        }
    };
    return (
        <div dangerouslySetInnerHTML={{__html: generatedHTML}} />
    );
};

export default KTCustomerTRNReport;