// import * as React, {useRef} from "react";
import React, { useEffect, useRef, useState } from "react";
import FormControl from "@mui/material/FormControl";
import {
  makeStyles,
  TableRow,
  withStyles,
  TableCell,
  TableFooter,
} from "@material-ui/core";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";

import _ from "lodash";
import RedStar from "../../components/RedStar";
import TopHeaderScreen from "../../components/TopHeader1";
import { useNavigate, useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import { CSVLink, CSVDownload } from "react-csv";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },

    display: "block",
    display: "flex",
    //fontSize: 16,
    fontSize: "16px",
    marginTop: 16,
    // padding: 10,
    fontWeight: "500",
    lineHeight: "18px",
    // minHeight: 50,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {},

    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup0: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#F0F8FF",
    

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroup00: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#FAEBD7",
    

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroupFull: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  table: {
    width: "100%",
    borderRadius: "10px",
    borderStyle: "solid",
    borderWidth: "1.5px",
    // tableLayout: "fixed",
  },
  tableRow: {
    borderBottom: "1px solid #d3d3d3",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  thead: {
    borderBottom: "1px solid #d3d3d3",
    borderTop: "1px solid #d3d3d3",
    height: 60,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.getContrastText(theme.palette.secondary.dark),
  },
  theadText: {
    fontWeight: "700",
    textAlign: "center",
    fontSize: 16,
    minWidth: 100,
  },
  tbodyText: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width: "200px",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // wordWrap: "breakWord",
    // overflow: "scroll",
  },
  participate: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width: "200px",
    fontWeight: "bold",
    // backgroundColor: theme.palette.info.light,
    color: theme.palette.info.light,
  },
  referral: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width: "200px",
    fontWeight: "bold",
    // backgroundColor: theme.palette.info.light,
    color: theme.palette.info.dark,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "#000",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function PFRewardsDetailReport(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationPath = useLocation();
  const [pfrewards, setPFrewards] = React.useState(undefined);
  const [pfrewardsvalue, setPFrewardsValue] = React.useState(undefined);

  

  React.useEffect(() => {
    getPFRewardsReport();
  }, []);

  
  const getPFRewardsReport = async () => {
    try {
      const res = await axios.get(
        `/pa/pf/rewards/report/camp/${locationPath?.pathname.split("/")?.[4]}/${
          locationPath?.pathname.split("/")?.[5]
        }`
      );
    
      setPFrewards(res?.data);
      return res;
    } catch (err) {
     
      throw err;
    }
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Campaign Financial Tracking
            </div>
            <div
              class="content-title"
              style={{
                fontSize: 20,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
                textAlign: "center",
              }}
            ></div>

            <div style={{ fontFamily: "monospace", fontSize: 16 }}></div>
            <header id="SOADashboardScreen">
              <form autoComplete="off">
                <form autoComplete="off">
                  <div style={{ border: "2px solid #dadce0", borderRadius: 8, backgroundColor: "#fff", float: "left", width: "100%", marginBottom:"10px" }} >
                    <div style={{ display: "flex", flexDirection: "row",  flexWrap: "wrap", }} >
                      <div className={classes.formGroup0}>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label}
                        >
                          Total Camp Budget
                        </label>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                          <i>(Total Budget)</i>
                        </label>

                        <input
                          value={pfrewards?.[0]?.totalcampbudget}
                          type="text"
                          className={classes.input}
                          placeholder="Your answer"
                          // maxLength="25"
                        />
                      </div>

                      <div className={classes.formGroup0}>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label}
                        >
                          Platform Fees
                        </label>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                          <i>(60% Total Budget)</i>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <input
                            value={pfrewards?.[0]?.platformfees}
                            type="text"
                            id="create-yaad--title"
                            className={classes.input}
                            placeholder="Your Answer"
                          />
                        </div>
                      </div>

                      <div className={classes.formGroup0}>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label}
                        >
                          Campaign Fees 
                        </label>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                          <i>(20% Total Budget)</i>
                        </label>

                        <input
                          value={pfrewards?.[0]?.campaignexpensefees}
                          type="number"
                          id="create-yaad--title"
                          className={classes.input}
                          placeholder="Your answer"
                        />
                      </div>

                      <div className={classes.formGroup0}>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label}
                        >
                          Reward Budget 
                        </label>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                          <i>(20% Total Budget)</i>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <input
                            value={pfrewards?.[0]?.rewardbudget}
                            type="text"
                            id="create-yaad--title"
                            className={classes.input}
                            placeholder="Your Answer"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ border: "2px solid #dadce0", borderRadius: 8, backgroundColor: "#FAEBD7", float: "left", width: "100%", marginBottom:"10px" }} >
                    <div style={{ display: "flex", flexDirection: "row",  flexWrap: "wrap", }} >
                     
                      <div className={classes.formGroup00}>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label}
                        >
                          Reward Budget 
                        </label>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                          <i>(20% Total Budget)</i>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <input
                            value={pfrewards?.[0]?.rewardbudget}
                            type="text"
                            id="create-yaad--title"
                            className={classes.input}
                            placeholder="Your Answer"
                          />
                        </div>
                      </div>
                     
                      <div className={classes.formGroup00}>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label}
                        >
                          Platform Reward Budget
                        </label>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                          <i>(20% Reward Budget)</i>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <input
                            value={pfrewards?.[0]?.platformreward}
                            type="text"
                            id="create-yaad--title"
                            className={classes.input}
                            placeholder="Your Answer"
                          />
                        </div>
                      </div>

                      <div className={classes.formGroup00}>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label}
                        >
                          Campaign Reward Budget 
                        </label>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                          <i>(80% Reward Budget)</i>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <input
                            value={pfrewards?.[0]?.campaignreward}
                            type="number"
                            id="create-yaad--title"
                            className={classes.input}
                            placeholder="Your Answer"
                            min="0"
                          />
                        </div>
                      </div>

                      <div className={classes.formGroup00}>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label}
                        >
                          Campaign Marketplace Budget
                        </label>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                          <i>(20% Campaign Reward Budget )</i>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <input
                            value={pfrewards?.[0]?.campaignmarketplacereward}
                            type="number"
                            id="create-yaad--title"
                            className={classes.input}
                            placeholder="Your Answer"
                            min="0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>


                  <div style={{ border: "2px solid #dadce0", borderRadius: 8, backgroundColor: "#fff", float: "left", width: "100%", marginBottom:"10px" }} >
                    <div style={{ display: "flex", flexDirection: "row",  flexWrap: "wrap",backgroundColor: "#F0F8FF" }} >
                      
                    <div className={classes.formGroup0}>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label}
                        >
                          Campaign Marketplace Budget
                        </label>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                          <i>(20% Campaign Reward Budget )</i>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <input
                            value={pfrewards?.[0]?.campaignmarketplacereward}
                            type="number"
                            id="create-yaad--title"
                            className={classes.input}
                            placeholder="Your Answer"
                            min="0"
                          />
                        </div>
                      </div>

                      <div className={classes.formGroup0}>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label}
                        >
                          Campaign Marketplace Budget
                        </label>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                          <i>(Unused)</i>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <input
                            value={
                              pfrewards?.[0]?.campaignmarketplaceunusedreward
                            }
                            type="number"
                            id="create-yaad--title"
                            className={classes.input}
                            placeholder="Your Answer"
                          />
                        </div>
                      </div>
                    </div>
                  </div>


                  <div style={{ border: "2px solid #dadce0", borderRadius: 8, backgroundColor: "#fff", float: "left", width: "100%", marginBottom:"10px" }} >
                    <div style={{ display: "flex", flexDirection: "row",  flexWrap: "wrap", backgroundColor: "#F0F8FF"}} >

                      <div className={classes.formGroup00}>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label}
                        >
                          Campaign Action Budget
                        </label>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                          <i>(80% Campaign Reward Budget )</i>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <input
                            value={pfrewards?.[0]?.campaignactionreward}
                            type="number"
                            id="create-yaad--title"
                            className={classes.input}
                            placeholder="Your Answer"
                          />
                        </div>
                      </div>

                      <div className={classes.formGroup00}>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label}
                        >
                          Participate Budget 
                        </label>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                            
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <input
                            value={pfrewards?.[0]?.participatebudgetpoints}
                            type="number"
                            id="create-yaad--title"
                            className={classes.input}
                            placeholder="Your Answer"
                          />
                        </div>
                      </div>

                      <div className={classes.formGroup00}>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label}
                        >
                          Referral Budget 
                        </label>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                            
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <input
                            value={pfrewards?.[0]?.referralbudgetpoints}
                            type="number"
                            id="create-yaad--title"
                            className={classes.input}
                            placeholder="Your Answer"
                          />
                        </div>
                      </div>
                      <div className={classes.formGroup00}>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label}
                        >
                          Target Bonus Budget 
                        </label>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                            
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <input
                            value={pfrewards?.[0]?.targetbonuspoints}
                            type="number"
                            id="create-yaad--title"
                            className={classes.input}
                            placeholder="Your Answer"
                          />
                        </div>
                      </div>
                    </div>
                  </div>


                  <div style={{ border: "2px solid #dadce0", borderRadius: 8, backgroundColor: "#fff", float: "left", width: "100%", marginBottom:"10px" }} >
                    <div style={{ display: "flex", flexDirection: "row",  flexWrap: "wrap", backgroundColor: "#F0F8FF"}} >

                      <div className={classes.formGroup0}>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label}
                        >
                          Campaign Action Budget
                        </label>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                          <i>(Unused)</i>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <input
                            value={pfrewards?.[0]?.campaigninactionunusedreward}
                            type="number"
                            id="create-yaad--title"
                            className={classes.input}
                            placeholder="Your Answer"
                          />
                        </div>
                      </div>

                      <div className={classes.formGroup0}>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label}
                        >
                          Participate Budget 
                        </label>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                          <i>(Unused)</i>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <input
                            value={pfrewards?.[0]?.participatebudgetunused}
                            type="number"
                            id="create-yaad--title"
                            className={classes.input}
                            placeholder="Your Answer"
                          />
                        </div>
                      </div>

                      <div className={classes.formGroup0}>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label}
                        >
                          Referral Budget 
                        </label>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                          <i>(Unused)</i>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <input
                            value={pfrewards?.[0]?.referralbudgetsunused}
                            type="number"
                            id="create-yaad--title"
                            className={classes.input}
                            placeholder="Your Answer"
                          />
                        </div>
                      </div>
                      <div className={classes.formGroup0}>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label}
                        >
                          Target Bonus Budget 
                        </label>
                        <label
                          htmlFor="create-yaad--title"
                          className={classes.label1}
                        >
                          <i>(Unused)</i>
                        </label>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-end",
                          }}
                        >
                          <input
                            value={pfrewards?.[0]?.targetbonusbudgetsunused}
                            type="number"
                            id="create-yaad--title"
                            className={classes.input}
                            placeholder="Your Answer"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  
                </form>
              </form>
            </header>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>
  );
}
export default PFRewardsDetailReport;
