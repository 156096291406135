/** @format */

const USER_ONBOARD_REVIEW_LIST_FETCHING_DATA = " USER_ONBOARD_REVIEW_LIST_FETCHING_DATA";
const  USER_ONBOARD_REVIEW_LIST_FETCHING_DATA_FAILURE =
  " USER_ONBOARD_REVIEW_LIST_FETCHING_DATA_FAILURE";
const  USER_ONBOARD_REVIEW_LIST_FETCHING_DATA_RESET = " USER_ONBOARD_REVIEW_LIST_FETCHING_DATA_RESET";
const  USER_ONBOARD_REVIEW_LIST_FETCHING_DATA_SUCCESS =
  " USER_ONBOARD_REVIEW_LIST_FETCHING_DATA_SUCCESS";
const  USER_ONBOARD_REVIEW_LIST_FETCHING_DATA_ATTEMPT =
  " USER_ONBOARD_REVIEW_LIST_FETCHING_DATA_ATTEMPT";
const userreviewlist = (payload) => ({
  type:  USER_ONBOARD_REVIEW_LIST_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const userreviewlistReset = (payload) => ({
  type:  USER_ONBOARD_REVIEW_LIST_FETCHING_DATA_RESET,
});
export {
   USER_ONBOARD_REVIEW_LIST_FETCHING_DATA,
   USER_ONBOARD_REVIEW_LIST_FETCHING_DATA_FAILURE,
   USER_ONBOARD_REVIEW_LIST_FETCHING_DATA_RESET,
   USER_ONBOARD_REVIEW_LIST_FETCHING_DATA_SUCCESS,
   USER_ONBOARD_REVIEW_LIST_FETCHING_DATA_ATTEMPT,
   userreviewlist,
   userreviewlistReset,
};
