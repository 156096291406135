import React from 'react'
// import './main.scss';
import _ from 'lodash';
// import API from '../../services';
// import { Progress } from 'reactstrap';
import { useState } from 'react';
import uplodIcon from '../assets/upload.png';
import { getCampFiles } from "../redux/action/campFilesAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import FileUploadImg from "../assets/file-up.png";
import {Button  } from "@material-ui/core";

const UploadFiles = (props) => {

    const dispatch = useDispatch();
    const locationPath = useLocation();
    // let { id, label, uploadUrl } = props;
    const [isUploding, setUploding] = useState(false);
    const [uploadedImgs, setUplodedImgs] = useState([]);
    const [uploadProgress, setProgress] = useState(0);
    const [selectedFile, setSelectedFile] = useState();
  

    const {
        isSuccess: campFilesSuccess,
        response: campFilesResponse,
        isError: campFilesError,
      } = useSelector((state) => state.getCampFiles);

    const handleChange = async e => {
        let { files } = e.target;
        setSelectedFile(e.target.files[0]);
     
        let formData = new FormData();
        setUploding(true);
        _.forEach(files, file => {
            formData.append('files', file);
            dispatch(
                getCampFiles({ campid: props.camp, userId: localStorage.getItem("userid"), formData })
          
              );
        });

        // setUploding(true);
        // let { data } = await API.post(uploadUrl, formData, {
        //     onUploadProgress: ({ loaded, total }) => {
        //         let progress = ((loaded / total) * 100).toFixed(2);
        //         setProgress(progress);
        //     }
        // });
        // setUplodedImgs(data);
        setUploding(false);
        alert("File uploaded");

    }


    return (

        <div class="cyagg-sec-space">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div>
                  <div class="filecard">
                    <h4>Upload Files</h4>
                    <div class="drop_box">
                      <header>
                        <h4>Select File here</h4>
                      </header>
                      <p>Files Supported: PDF, TEXT, DOC , DOCX</p>
                      <input
                        type="file"
                        // hidden
                        accept=".doc,.docx,.pdf"
                        id="fileID"
                        // style={{ color:"orange", display: "none" }}
                        // style={{ color:"orange", backgroundColor:"orange" }}
                        multiple
                        onChange={handleChange}
                        placeholder="Select File and Upload"
                        // class="btn"
                      />
                      {/* <button class="btn" onClick={handleChange}>Choose File</button> */}
                      {/* <button class="btn">Choose File</button> */}
                    </div>
                    {
                    isUploding ? (
                        <div className="flex-grow-1 px-2">
                          TRUE
                          {/* <p>Filename: {selectedFile.name}</p>
					                <p>Filetype: {selectedFile.type}</p> */}
                          <div className="text-center">{uploadProgress}%</div>
                            {/* <Progress value={uploadProgress} /> */}
                        </div>
                    ) : null
                }
                  </div>
                  {
                    uploadedImgs && !isUploding ? (
                        uploadedImgs.map(uploadedImg => (
                            <img src={uploadedImg} key={uploadedImg} alt="UploadedImage" className="img-thumbnail img-fluid uploaded-img mr-2" />
                        ))
                    ) : null
                }
                </div>
              </div>
              <div class="col-md-6">
                <div class="text-center">
                  <img
                    src={FileUploadImg}
                    class="img-fluid mob-space1"
                    style={{ width: "300px" }}
                  />
                  <p style={{textAlign: "justify", fontSize: 12}}>
                    1. Please upload images and video's of your participation in the
                    campaigns and you can view those from "My Ongoing Campaigns" section
                  </p>
                  <p style={{textAlign: "justify", fontSize: 12}}>
                    2. Please ensure that file size is less than 35 MB
                  </p>
                  <p style={{textAlign: "justify", fontSize: 12}}>
                    3. All your uploaded files will be visible to other change makers
                  </p>
                </div>
              </div>
              {campFilesSuccess &&
            <span>Files uploaded</span>
            }
            </div>
          </div>
        </div>






        // <div className="form-group">
        //     <label htmlFor="create-yaad--title" className="text-primary font-weight-bold"></label>
        //     <div className="d-flex">
        //         <div className="d-flex">
        //             <div className="file-uploader-mask d-flex justify-content-center align-items-center">
        //                 <img className="file-uploader-icon" src={uplodIcon} height="40px" alt="Upload-Icon" />
        //             </div>
        //             <input multiple className="file-input" type="file" id="create-yaad--title" onChange={handleChange} />
        //         </div>
        //         {
        //             isUploding ? (
        //                 <div className="flex-grow-1 px-2">
        //                     <div className="text-center">{uploadProgress}%</div>
        //                     {/* <Progress value={uploadProgress} /> */}
        //                 </div>
        //             ) : null
        //         }
        //     </div>
        //     <div className="d-flex flex-wrap mt-4">
        //         {
        //             uploadedImgs && !isUploding ? (
        //                 uploadedImgs.map(uploadedImg => (
        //                     <img src={uploadedImg} key={uploadedImg} alt="UploadedImage" className="img-thumbnail img-fluid uploaded-img mr-2" />
        //                 ))
        //             ) : null
        //         }
        //     </div>
        //     {campFilesSuccess &&
        //     <span>Files uploaded</span>
        //     }
        // </div>
    )
}

export default UploadFiles;