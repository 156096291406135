import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "@material-ui/core";
import {
  Avatar,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import {
  onboardActivityReset,
  onboardBulkReset,
  onboardCampaign,
  onboardCampaignReset,
  onboardIndividualReset,
  onboardOrganization,
  onboardOrganizationReset,
} from "../../redux/action/OnboardAction";
import CallingCode from "../../services/CallingCode";
import { fetchCityAndState } from "../../services/commonServices";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";
import { API_URL } from "../../services/Constants";
import { getOrganization } from "../../redux/action/getOrganization";
import {
  activityDuration,
  campaignType,
  restrictedCountry,
  status,
} from "../../utils/dict";

import { useLocation, useNavigate } from "react-router-dom";
import TopHeaderScreen from "../../components/TopHeader1";
import Multiselect from "multiselect-react-dropdown";
import _ from "lodash";
import ErrorMessage from "../../components/ErrorMessage";
import RedStar from "../../components/RedStar";
import {
  AddCircleOutlineOutlined,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";
import { isArray } from "lodash";
const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    fontSize: 17,
    height: 54,
    backgroundColor: "#FFFFFF",
    // boxShadow: "0px 3px 6px #00000029",
    // color: "#949494",
    color: "#0F0F0F",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "block",
    fontSize: 17,
    marginTop: 16,
    padding: 10,
    fontWeight: "500",
    color: "#808080",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroupFull: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroup2: {
    display: "flex",
    justifyContent: "space-around",
    // padding: "16px 0",
    padding: "11px 0px 25px 1px",
    bottom: 50,
    background: "#2D7A74",
    // marginTop: 12,
    marginBottom: 10,
    [theme.breakpoints.only("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  Comments: {
    color: "white",

    [theme.breakpoints.only("xl")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "10px",
    },
  },
}));

function ViewCampaignScreen(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationPath = useLocation();

  const [reviewstatus, setReviewStatus] = React.useState("");
  const [reviewComments, setReviewComments] = React.useState("");
  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "" },
  ]);
  const [activityList, setActivityList] = React.useState(undefined);
  const [sponsor, setSponsor] = React.useState([]);
  const [campStatus, setCampStatus] = React.useState([]);

  const [sponsorAvailBudget, setSponsorAvailBudget] = React.useState([]);

  const [reviewstat, setreviewstat] = React.useState([]);
  const [selected1, setSelected1] = React.useState([]);
  const [dupSponsor, setDupSponsor] = React.useState(false);

  // const [countOfSponsor, setCountOfSponsor] = React.useState(0);
  const [selectedCategory, setSelectedCategory] = React.useState("cYAAG");
  const [addSponsorError, setaddSponsorError] = React.useState({
    sponsorNameError: false,
    sponsorBudgetError: false,
    sponsorBudgetExceeded: false,
    sponsorDupError: false,
  });
  const [rewardtitle, setRewardtitle] = React.useState([
    { sponsorname: "", sponsorbudget: "" },
  ]);
  // const [rewardtitle, setRewardtitle] = React.useState([]);
  const [campaignValues, setCampaignValues] = React.useState({
    titleOfCampaign: "",
    descriptionOfCampaign: "",
    typeOfCampaign: "",
    volunteerCount: "",
    //impact: "",
    visibility: "",
    startDate: new Date(),
    endDate: new Date(),
    durationOfCampaign: "",
    goal: "",
    campaignType: "",
    hourOfParticipation: "",
    socialMediaMessage: "",
    campaignLogo: "",
    campaignVideo: "",
    totalbudget: "",
    sponsor:"",
  });
  const {
    isCampaignSuccess,
    isCampaignFetching,

    isCampaignError,
  } = useSelector((state) => state.onBoard);

  const handleOnboardCampaign = async () => {
    if (!addSponsorError?.sponsorBudgetExceeded && !dupSponsor) {
      const payload = {
        campid: locationPath?.state?.campid,
        createdate: locationPath?.state?.createdate,
        reviewstatus: reviewstatus,
        reviewcomments: reviewComments,
        sponsordet: rewardtitle,
      };
      try {
        const res = await axios.put(
          `/campaign/review/${localStorage.getItem("userid")}`,
          payload
        );
        // navigate("/reviewcampaign");
        setreviewstat(res?.data);
        return res;
      } catch (err) {
        throw err;
      }
    }
  };
  // console.log(locationPath);
  // console.log(state.rewardstobeearned);

  const getSponsorList = async () => {
    try {
      const res = await axios.get(`/sponsor`).then((response) => {
        // const values = [...rewardtitle];

        setSponsor(response?.data);
        return response?.data;
      });
    } catch (err) {
      throw err;
    }
  };


  const getCampReviewstatus = async () => {
    try {
      const res = await axios.get(`/campaign/review/status/${locationPath?.state?.campid}`).then((response) => {
        setCampStatus(response?.data);
        return response?.data;
      });
    } catch (err) {
      throw err;
    }
  };

  React.useEffect(() => {
    getSponsorList();
  }, []);

  
  React.useEffect(() => {
    getCampReviewstatus();
  }, []);

  // const getSponsorAvailableBudget = async () => {
  //   try {
  //     const res = await axios.get(`/sponsor/available/budget/${selectedCategory}`).then((response) => {
  //       // const values = [...rewardtitle];

  //       setSponsorAvailBudget(response?.data);
  //       return response?.data;
  //     });
  //   } catch (err) {
  //     throw err;
  //   }
  // };
  // console.log(selectedCategory)
  // console.log(sponsorAvailBudget)

  React.useEffect(() => {
    if (rewardtitle.length === 1) {
      setDupSponsor(false);
    }
  }, [rewardtitle.length]);

  React.useEffect(() => {
    const value = [...rewardtitle];
    // console.log("useeffect len of rewardtitle = ", rewardtitle.length)
    if (value.length > 1) {
      for (let i = 0; i < value.length - 1; i++) {
        

        if (
          value.length != 0 &&
          value?.[i].sponsorname?.[0]?.sponsor ===
            value?.[value.length - 1].sponsorname?.[0]?.sponsor &&
          value?.[value.length - 1].sponsorname?.[0]?.sponsor != undefined
        ) {
          // console.log("setting dupSponsor TRUE");
          setDupSponsor(true);
          setaddSponsorError({
            ...addSponsorError,
            sponsorDupError: true,
          });
          break;
        } else {
          // console.log("setting dupSponsor FALSE");
          setDupSponsor(false);
          setaddSponsorError({
            ...addSponsorError,
            sponsorDupError: false,
          });
        }
      }
    }
  }, [rewardtitle.length]);

  const handleChangeRewards1 = (itemreward, event, field, index) => {
    // console.log(field);
    // console.log(event);

    // const value = event.target.value;
    const value = [...rewardtitle];

    if (field === "sponsorname") {
      value[index][field] = event;
      // value[index]["titleList"] = event;
      // setSelectedCategory(value[0]["sponsorname"][0]["sponsorid"])
      // setSelectedCategory(event[0]["sponsorid"])
      // setCountOfSponsor(index - 1);
      for (let i = 0; i < index; i++) {
        // console.log("inside for loop = ", i, " and index = ", index)
        // console.log("i = ", i, " value = ", value[i][field][0]["sponsor"]);
        // console.log(
        //   "index = ",
        //   index,
        //   "value = ",
        //   value[index][field][0]["sponsor"]
        // );
        if (
          index != 0 &&
          value[i][field][0]["sponsor"] === value[index][field][0]["sponsor"]
        ) {
          setDupSponsor(true);
          setaddSponsorError({
            ...addSponsorError,
            sponsorDupError: true,
          });
          break;
        } else {
          setDupSponsor(false);
          setaddSponsorError({
            ...addSponsorError,
            sponsorDupError: false,
          });
        }
      }
      if (index === 0) {
        setDupSponsor(false);
      }
      //   value[index]["titleList"] = sponsorAvailBudget;
      //   console.log("value[index][titleList] = ", value[index]["titleList"]);
    } else {
      if (field === "sponsorbudget") {
        value[index][field] = parseFloat(event.target.value);
      } else {
        value[index][field] = event.target.value;
      }
      // console.log("event.target.value = ", event.target.value);
      // console.log("value[index][field]  = ", value[index][field]);
      // value[field] = event.target.value;
    }
    setRewardtitle(value);
    // console.log(value);
    // console.log(rewardtitle);

    // setSelected([...selected, value[value?.length - 1]]);
  };
  

  // const handleCampaignFieldChange = (field, e) => {
  //   (isCampaignError || isCampaignSuccess) && dispatch(onboardCampaignReset());
  //   setCampaignValues({
  //     ...campaignValues,
  //     [field]:
  //       field === "startDate" || field === "endDate"
  //         ? e
  //         : field === "campaignLogo" || field === "campaignVideo"
  //         ? e.target.files[0]
  //         : e.target.value,
  //   });
  // };

  const unitOfCampaign = activityList?.filter(
    (item, index) => item?.activity === campaignValues?.typeOfCampaign
  );
  const handleChangeLocation = (id, e, field, index) => {
    const values = [...location];
    values[index][field] = e.target.value;
    setLocation(values);
  };

  
  // console.log(locationPath?.state?.rewardstobeearned)
  // console.log(locationPath?.state?.rewardstobeearned?.rewards)
  // console.log(props);
  const getAddress = (item, index) => {
    return (
      <div style={{ float: "left", width: "100%", marginTop: 25 }}>
        <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>
          Operational Areas
        </span>
        <form style={{ display: "flex", flexWrap: "wrap" }}>
          {/* <div className={classes.formGroup1} style={{ paddingLeft: 0 }}> */}
          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Country
            </label>

            <input
              disabled
              value={item.country}
              type="text"
              className={classes.input}
            />
          </div>

          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              State
            </label>
            <input
              disabled
              value={item.state}
              type="text"
              className={classes.input}
            />
          </div>
          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              City/Town/Village
            </label>
            <input
              disabled
              value={item.city}
              type="text"
              className={classes.input}
            />
          </div>
          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Area
            </label>
            <input
              disabled
              value={item.area}
              type="text"
              id="create-yaad--title"
              className={classes.input}
              // placeholder="Your Answer"
              onChange={(e) => handleChangeLocation(item, e, "area", index)}
            />
          </div>
        </form>
      </div>
    );
  };

  // React.useEffect(() => {
  //   dispatch(onboardBulkReset());
  //   dispatch(onboardIndividualReset());
  //   dispatch(onboardActivityReset());
  //   dispatch(onboardCampaignReset());
  //   dispatch(onboardOrganizationReset());
  // }, []);

  React.useEffect(() => {
    if (locationPath?.state) {
      setCampaignValues({
        titleOfCampaign: locationPath?.state?.title,
        descriptionOfCampaign: locationPath?.state?.desc,
        typeOfCampaign: locationPath?.state?.type,
        volunteerCount: locationPath?.state?.targetvol,
        // impact: locationPath?.state?.impact,
        visibility: locationPath?.state.visibility,
        startDate: moment(locationPath?.state?.startdate).format("YYYY-DD-MM"),
        endDate: moment(locationPath?.state?.endDate).format("YYYY-DD-MM"),
        durationOfCampaign: locationPath?.state?.timeframe,
        goal: locationPath?.state?.goal,
        campaignType: locationPath?.state?.campaigntype,
        hourOfParticipation: locationPath?.state?.hourofparticipation,
        socialMediaMessage: locationPath?.state?.socialMediaMessage,
        campaignLogo: locationPath?.state?.logo,
        campaignVideo: locationPath?.state?.video,
        totalbudget: locationPath?.state?.totalbudget,
        sponsor: locationPath?.state?.sponsor,
      });
      setLocation(locationPath?.state?.location);
      // setFocusArea(JSON.parse(locationPath?.state?.rewardstobeearned))
    }
  }, [locationPath?.state]);


  // const [newRewardField, setNewRewardField] = React.useState(false);
  const handleRewardAddFields = () => {

    if (rewardtitle.length > 0) {
      
      for (let i = 0; i < rewardtitle.length; i++) {
        
        
        if ( rewardtitle?.[rewardtitle.length - 1].sponsorname?.[0]?.sponsor != undefined) {
          setRewardtitle([...rewardtitle, { sponsorname: "", sponsorbudget: "" }]);
          // break;
        } 
      }
      // setRewardtitle([...rewardtitle, { sponsorname: "", sponsorbudget: "" }]);
    }
  };
  const handleRemoveFields = (i) => {
    // console.log("handleRemoveFields before rewardtitle = ", rewardtitle);
    // console.log("index to be deleted = ", i);
    let newFormValues = [...rewardtitle];
    // console.log("newFormValues before = ", newFormValues);
    newFormValues.splice(i, 1);
    // console.log("newFormValues after = ", newFormValues);
    setRewardtitle(newFormValues);
    // console.log("handleRemoveFields after rewardtitle = ", rewardtitle);
  };
  const getRewardDetails = (itemreward, index) => {
    // console.log("itemreward = ", itemreward);
    return (
      <div style={{ float: "left", width: "100%", marginTop: 25 }}>
        <form
          style={{
            display: "flex",
            flexWrap: "wrap",
            // width: "100%",
            // float: "left",
          }}
        >
          
            
            {/* <div className={classes.formGroup1}> */}
            {/* <div style={{ display: "inline-flex" }}> */}
              <div className={classes.formGroup1}>
                <label
                  htmlFor="create-yaad--title"
                  className={classes.label}
                  style={{ marginTop: "0px", fontSize: 15 }}
                >
                  Sponsor Name
                </label>
                <Multiselect
                  displayValue="sponsor"
                  showCheckbox
                  // selectedValues={selected1}
                  // selectedValues={itemreward?.[index]?.sponsorname?.[0]?.sponsor}
                  // selectedValues={itemreward?.[0]?.sponsorname?.[0]?.sponsor}
                  // selectedValues={rewardtitle?.sponsorname?.[0]?.sponsor}
                  selectedValues={itemreward?.sponsor}
                  options={sponsor}
                  selectionLimit={1}
                  onSelect={(event) => {
                    handleChangeRewards1(
                      itemreward,
                      event,
                      "sponsorname",
                      index
                    );
                    if (_.isEmpty(event)) {
                      setaddSponsorError({
                        ...addSponsorError,
                        sponsorNameError: true,
                      });
                    } else {
                      setaddSponsorError({
                        ...addSponsorError,
                        sponsorNameError: false,
                      });
                    }
                  }}
                  // value={selected1}
                  value={rewardtitle?.[index]?.sponsorname?.[0]?.sponsor}
                  style={{
                    multiselectContainer: {
                      // boxShadow: "0px 3px 6px #00000029",
                      border:"1px solid",
                      borderRadius:"6px",
                      padding:"5px",
                    },
                    
                    searchBox: {
                      // minHeight: 54,
                      PaddingTop: 10,
                    },
                  }}
                />

                {addSponsorError?.sponsorNameError && (
                  <ErrorMessage message="Please select the Sponsor for this campaign" />
                )}
                {dupSponsor && (
                  <ErrorMessage message="Please select another Sponsor for this campaign" />
                )}
              </div>

              <div className={classes.formGroup1}>
                <label
                  htmlFor="create-yaad--title"
                  className={classes.label}
                  style={{ marginTop: "0px", fontSize: 15 }}
                >
                  Available Budget
                </label>

                <input
                  disabled
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    height: "40px",
                    border: "1px solid",
                  }}
                  // value={sponsorAvailBudget?.[0]?.initialbudget}
                  value={
                    parseFloat(rewardtitle?.[index]?.sponsorname?.[0]?.availablebudget)
                  }
                  // defaultValue={itemreward.sponsorBudget}
                  // value={rewardtitle?.[index]?.titleList?.[0]?.initialbudget}
                  type="number"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Budget"

                  // onChange={(e) =>
                  //   handleChangeRewards1(itemreward, e, "sponsoravailbudget", index)
                  // }
                />
              </div>

              
              <div className={classes.formGroup1}>
                <label
                  htmlFor="create-yaad--title"
                  className={classes.label}
                  style={{ marginTop: "0px", fontSize: 15 }}
                >
                  Budget allocated
                  {/* <RedStar /> */}
                </label>
                <input
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    height: "40px",
                    border: "1px solid",
                  }}
                  value={parseFloat(itemreward?.sponsorbudget)}
                  // defaultValue={itemreward.sponsorBudget}
                  type="number"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Budget"
                  onBlur={() => {
                    if (!itemreward.sponsorbudget) {
                      
                      setaddSponsorError({
                        ...addSponsorError,
                        sponsorBudgetError: true,
                      });
                    } else {
                      if (Number(itemreward?.sponsorbudget) >
                        rewardtitle?.[index]?.sponsorname?.[0]?.availablebudget
                      ) {
                        setaddSponsorError({
                          ...addSponsorError,
                          sponsorBudgetExceeded: true,
                        });
                      } else {
                        setaddSponsorError({
                          ...addSponsorError,
                          sponsorBudgetExceeded: false,
                        });
                      }
                      setaddSponsorError({
                        ...addSponsorError,
                        sponsorBudgetError: false,
                      });
                    }
                      // if (!Number(itemreward.sponsorbudget)) {
                      //   console.log("Error");

                      //   setaddSponsorError({
                      //     ...addSponsorError,
                      //     sponsorBudgetError: true,
                      //   });
                      // } else {
                      //   setaddSponsorError({
                      //     ...addSponsorError,
                      //     sponsorBudgetError: false,
                      //   });
                      // }
                      
                    
                  }}
                  onChange={(e) =>
                    handleChangeRewards1(itemreward, e, "sponsorbudget", index)
                  }
                />
                {addSponsorError?.sponsorBudgetError && (
                  <ErrorMessage message="Please enter the budget for this sponsor" />
                )}
                {addSponsorError.sponsorBudgetExceeded && (
                  <ErrorMessage message="You have exceeded the budget" />
                )}
              </div>
            {/* </div> */}
          
        </form>
        {index ? (
          <div
            style={{
              float: "right",
              position: "relative",
              bottom: 44,
              cursor: "pointer",
              left: 19,
            }}
            onClick={(e) => handleRemoveFields(index)}
          >
            <RemoveCircleOutlineOutlined></RemoveCircleOutlineOutlined>
          </div>
        ) : (
          <div
            className="handlePlus"
            style={{
              float: "right",
              bottom: 45,
              position: "relative",
              left: 20,
              cursor: "pointer",
              right: "15px",
            }}
          >
            <AddCircleOutlineOutlined
              onClick={handleRewardAddFields}
            ></AddCircleOutlineOutlined>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            {/* <div class="content content-wrap"> */}
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              View & Review Campaign
            </div>
            {/* <main
        onClick={() => {
          props.setNotificationShow(false);
        }}
        className={classes.homeSreenMainContent}
        id="homepage-main"
      > */}

            <form autoComplete="off">
              <div
                style={{
                  border: "1px solid #dadce0",
                  borderRadius: 8,
                  padding: "13px 30px 40px 30px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
                  <div className={classes.formGroup}>
                    <label className={classes.label}>Title of Campaign</label>

                    <input
                      disabled
                      value={campaignValues?.titleOfCampaign}
                      type="text"
                      className={classes.input}
                      // placeholder="Your answer"
                      // onChange={(e) => {
                      //   handleCampaignFieldChange("titleOfCampaign", e);
                      // }}
                    />
                  </div>

                  {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Description
                    </label>
                    <input
                      disabled
                      value={campaignValues?.descriptionOfCampaign}
                      type="text"
                      className={classes.input}
                      placeholder="Your answer"
                      // onChange={(e) => {
                      //   handleCampaignFieldChange("descriptionOfCampaign", e);
                      // }}
                    />
                  </div>
                  {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Campaign Category
                    </label>
                    {
                      campaignValues?.typeOfCampaign != "" &&
                      
                        <input
                          disabled
                          value={campaignValues?.typeOfCampaign.label}
                          type="text"
                          className={classes.input}
                          placeholder="Your answer"
                          // onChange={(e) => {
                          //   handleCampaignFieldChange(
                          //     "descriptionOfCampaign",
                          //     e
                          //   );
                          // }}
                        />
                     
                      }
                  </div>
                  {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Number of volunteers Required
                    </label>

                    <input
                      disabled
                      value={campaignValues?.volunteerCount}
                      type="number"
                      id="create-yaad--title"
                      className={classes.input}
                      placeholder="Your answer"
                      // onChange={(e) => {
                      //   handleCampaignFieldChange("volunteerCount", e);
                      // }}
                    />
                  </div>
                  {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Campaign Participation
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        disabled
                        value={campaignValues?.visibility}
                        type="text"
                        //type="number"
                        id="create-yaad--title"
                        className={classes.input}
                        // placeholder="Your Answer"
                        // onChange={(e) => {
                        //   handleCampaignFieldChange("visibility", e);
                        // }}
                      />
                      <span style={{ marginLeft: 4 }}>
                        {unitOfCampaign?.[0]?.unit}
                      </span>
                    </div>
                  </div>
                  {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Start Date
                    </label>
                    <input
                      disabled
                      // value={campaignValues?.startDate}
                      value={locationPath?.state?.startdate}
                      type="text"
                      className={classes.input}
                    />

                  </div>

                  {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      End Date
                    </label>
                    <input
                      disabled
                      // value={campaignValues?.endDate}
                      value={locationPath?.state?.enddate}
                      type="text"
                      className={classes.input}
                    />

                  </div>

                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Hours of Volunteer Participation
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        disabled
                        value={campaignValues?.hourOfParticipation}
                        type="number"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        // onChange={(e) => {
                        //   handleCampaignFieldChange("hourOfParticipation", e);
                        // }}
                      />
                    </div>
                  </div>

                  {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Campaign Type
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        disabled
                        value={campaignValues.campaignType}
                        type="text"
                        className={classes.input}
                      />
                      {/* <Select
                    disabled
                    style={{
                      width: 200,
                      fontSize: 14,
                      height: 56,
                      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    }}
                    value={campaignValues.campaignType}
                    onChange={(e) =>
                      handleCampaignFieldChange("campaignType", e)
                    }
                  >
                    {campaignType.map((item, index) => (
                      <MenuItem style={{ fontSize: 16 }} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select> */}
                    </div>
                  </div>

                  {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Campaign Goal
                    </label>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        disabled
                        value={campaignValues?.goal}
                        type="text"
                        id="create-yaad--title"
                        className={classes.input}
                        // placeholder="Your Answer"
                        // onChange={(e) => {
                        //   handleCampaignFieldChange("goal", e);
                        // }}
                      />
                    </div>
                  </div>

                  {/* <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Rewards to be Earned
                    </label>
                    {locationPath.state.rewardstobeearned.map((item, index) => (
                      <span className={classes.input}>
                        {" "}
                        {index ? ", " : ""} {item.rewardtype}{" "}
                      </span>
                    ))}
                  </div> */}
                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Duration (in days)
                    </label>
                    
                    <input
                      disabled
                      value={campaignValues?.durationOfCampaign}
                      type="text"
                      id="create-yaad--title"
                      className={classes.input}
                      // placeholder="Your answer"
                      // onChange={(e) => {
                      //   handleCampaignFieldChange("durationofCampaign", e);
                      // }}
                    />
                  </div>

                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Total Budget
                    </label>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        disabled
                        value={campaignValues?.totalbudget}
                        type="text"
                        id="create-yaad--title"
                        className={classes.input}
                      />
                    </div>
                  </div>
                  <div className={classes.formGroup}>
                    <label
                      htmlFor="create-yaad--title"
                      className={classes.label}
                    >
                      Sponsors
                    </label>
                    {
                      isArray(campaignValues?.sponsor)? (
                        campaignValues?.sponsor?.map((spon => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      {/* <span>{spon?.sponsorname}</span> */}
                      <input
                        disabled
                        value={spon?.sponsor}
                        type="text"
                        id="create-yaad--title"
                        className={classes.input}
                      />
                    </div>
                     )))
                      ):""
                  }
                  </div>

                  {location.map((item, index) => (
                    <div key={index}>{getAddress(item, index)}</div>
                  ))}
                </div>
              </div>
            </form>

            <div style={{ float: "left", width: "75%", marginTop: 15 }}>
              <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>
                Sponsor Details
              </span>{" "}
              <span
                style={{
                  paddingLeft: 0,
                  fontSize: 16,
                  fontWeight: 300,
                  fontStyle: "italic",
                }}
              >
                {" "}
                (click on "+" icon to enter additional sponsors)
              </span>
              {rewardtitle.map((itemreward, index) => (
                <div key={index}>{getRewardDetails(itemreward, index)}</div>
              ))}
            </div>

            
            <div className={classes.formGroup2}>

              
              <div
                style={{
                  display: "flex",
                  width: "40%",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <span className={classes.Comments}>
                  Comments (Max 50 chars)
                </span>
                <textarea
                  style={{ height: 100, borderRadius: 8, padding: 8 }}
                  placeholder="Comments"
                  maxLength="50"
                  onChange={(e) => setReviewComments(e.target.value)}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span>Status</span>
                  <Select
                    style={{
                      width: 183,
                      fontSize: 14,
                      height: 56,
                      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                      background: "#fff",
                    }}
                    onChange={(e) => setReviewStatus(e.target.value)}
                  >
                    {status?.map((item, index) => (
                      <MenuItem style={{ fontSize: 16 }} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                {
                  (campStatus?.[0]?.reviewstatus === "Approved" || 
                  campStatus?.[0]?.reviewstatus === "approved" ||
                  campStatus?.[0]?.reviewstatus === "Rejected" ||
                  campStatus?.[0]?.reviewstatus === "rejected" )
                  ? (
                <Button
                  disabled
                  style={{
                    marginTop: 10,
                    backgroundColor: "gray",
                    color: "silver",
                    fontFamily: "Poppins !important",
                    width: "80%",
                    marginLeft:"9px",
                  }}
                  variant="contained"
                  // onClick={handleOnboardCampaign}
                  className={classes.goalButton}
                >
                  {isCampaignFetching ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Submit"
                  )}
                </Button>
                  ):(
                    <Button
                  style={{
                    marginTop: 10,
                    backgroundColor: "#E88A00",
                    color: "#fff",
                    fontFamily: "Poppins !important",
                    width: "80%",
                    marginLeft:"9px",
                  }}
                  variant="contained"
                  onClick={handleOnboardCampaign}
                  className={classes.goalButton}
                >
                  {isCampaignFetching ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Submit"
                  )}
                </Button>
                  )
}
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              {reviewstat?.status === "success" && (
                <span style={{ color: "green" }}> {reviewstat.message} </span>
              )}
              {reviewstat?.status === "failure" && (
                <span style={{ color: "red" }}>{reviewstat.message}</span>
              )}
            </div>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
}
export default ViewCampaignScreen;
