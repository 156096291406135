/** @format */

// reducers
import {
    ONBOARD_RECOGNITIONS_FETCHING_DATA,
    ONBOARD_RECOGNITIONS_FETCHING_DATA_FAILURE,
    ONBOARD_RECOGNITIONS_FETCHING_DATA_RESET,
    ONBOARD_RECOGNITIONS_FETCHING_DATA_SUCCESS,
    
  } from "../action/onboardRecognitionsAction";
  
  let initialState = {
    onboardRecognitionsResponse: {},
    onboardRecognitionsSuccess: false,
    onboardRecognitionsError: false,
    onboardRecognitionsFetching: false,
  };
  
  export default function OnboardReducer(state = initialState, action) {
    switch (action.type) {
        case ONBOARD_RECOGNITIONS_FETCHING_DATA:
            return {
            ...state,
            onboardRecognitionsFetching: true,
            onboardRecognitionsError: false,
            onboardRecognitionsSuccess: false,
        };
        case ONBOARD_RECOGNITIONS_FETCHING_DATA_FAILURE:
            return {
            ...state,
            onboardRecognitionsFetching: false,
            onboardRecognitionsError: action.payload,
            onboardRecognitionsSuccess: false,
        };
        case ONBOARD_RECOGNITIONS_FETCHING_DATA_SUCCESS:
            return {
            ...state,
            onboardRecognitionsFetching: false,
            onboardRecognitionsError: false,
            onboardRecognitionsResponse: action.payload,
            onboardRecognitionsSuccess: true,
        };
        case ONBOARD_RECOGNITIONS_FETCHING_DATA_RESET:
            return {
            ...state,
            onboardRecognitionsFetching: true,
            onboardRecognitionsError: false,
            onboardRecognitionsSuccess: false,
        };
      
      default:
        return state;
    }
  }
  