import * as React from "react";
import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import { Card, makeStyles } from "@material-ui/core";
import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { city, states } from "../utils/constants";
// import {
//   ActivityChartComponent,
//   ChartComponent,
//   LineChartComponent,
//   SOVolOrgChartComponent,
//   SOVolOverTimeChartComponent,
//   SOVolOverTimeChartComponent1,
//   SOVolInCampInOrgChartComponent,
//   SOVolEmpInOrgChartComponent,
//   SOVolEmpInOrgChartComponent1,
//   SOVolOrgDivComponent,
//   SOVolMaleVsFemaleOrgChartComponent,
//   SOVolMaleVsFemaleOrgChartComponent1,
//   SOVolImpactOrgChartComponent,
//   SOVolImpactTableComponent,
//   SOVolImpactDivComponent,
//   SOVolHoursSpentCampChartComponent,
//   SOVolHoursSpentCampChartComponent1,
//   SOVolInCampOverTimeChartComponent,
// } from "./chartComponent";
import { useDispatch, useSelector } from "react-redux";
import { getStatesUT } from "../redux/action/statesUTAction";
import { getCity, getCityReset } from "../redux/action/cityAction";
import {
  getReportUserInActivity,
  getReportUserInCity,
  getReportVolInOrg,
} from "../redux/action/getReport";
import MapComponent from "./mapComponent";
import { activity } from "../utils/dict";
import ResponsiveDrawer from "../components/Header";
import { CopyRight } from "../components/CopyRight";
import CustomDrawer from "../components/CustomHeader";
import TopHeaderScreen from "./TopHeader1";
import { volunteerMenuItems } from "../utils/dict";
import { userProfile } from "../redux/action/UserProfileActions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PDF from "../components/PDF";
import { FixedFooterIcon } from "../components/FixedFooterIcon";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";

const useStyles = makeStyles((theme) => ({
  createScreenMainContent: {
    padding: "80px 50px 150px 0",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 150px 220px",
    },
  },
  divnext: {
    display: "flex",
    // flexDirection: "row",
    // marginTop: 100,
    width: "40%",
  },
}));

export default function SOAdashboardScreen(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(null);
  const [cityValue, setCityValue] = React.useState(null);
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  // console.log(profileResponse)

  const { response: statesResponse } = useSelector((state) => state.states);
  const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
    (state) => state.cities
  );
  const {
    stateResponse,
    isStateSuccess,
    isStateError,
    isStateFetching,
    activiyResponse,
    isActivitySuccess,
    isActivityError,
    isActivityFetching,
    SOVolOrgResponse,
    isSOVolOrgSuccess,
    isSOVolOrgError,
    isSOVolOrgFetching,
  } = useSelector((state) => state.reportInfo);
  // console.log(SOVolOrgResponse)
  // console.log(stateResponse)
  const dispatch = useDispatch();

  // React.useEffect(() => {
  //   if (props?.response) {
  //     dispatch(getStatesUT(props?.response?.[0]?.nssrole));

  //     if (value) {
  //       dispatch(getCity(value));
  //     }
  //   }
  // }, [props?.response, value]);
  // console.log(isSOVolOrgSuccess)
  // console.log(props)
  React.useEffect(() => {
    if (isActivitySuccess || isStateSuccess) {
      setLoading(false);
    }
  }, [isActivitySuccess, isStateSuccess]);

  React.useEffect(() => {
    if (isSOVolOrgSuccess)
      dispatch(userProfile(localStorage.getItem("userid")));
    // dispatch(getReportVolInOrg());
    // getSOVolReportSaga();
    // getSOVolOverTimeReportSaga();
    // getSOVolInCampInOrgReportSaga();
    // getSOVolEmpInOrgReportSaga();
    // getSOVolGenderInOrgReportSaga();
    // getSOVolImpactInOrgReportSaga();
    // getSOVolHoursSpentInCampReportSaga();
    // getSOVolOverTimeInCampReportSaga();
  }, [isSOVolOrgSuccess]);

  // React.useEffect(() => {
  //   // if (isSOVolOrgSuccess)
  //   dispatch(userProfile(localStorage.getItem('userid')))
  //   dispatch(getSOVolOverTimeReportSaga());
  //   getSOVolOverTimeReportSaga();
  //   // getSOVolReportSaga();
  // }, []);

  const concatenatedData = statesResponse?.[0]?.concat(statesResponse?.[1]);
  const finalData = concatenatedData?.concat({ name: "All States" });
  const [campaignList, setCampaignList] = useState(null);
  const [volovertimeList, setVolovertimeList] = useState(null);
  const [volincampinorgList, setvolincampinorgList] = useState(null);
  const [volempinorgList, setvolempinorgList] = useState(null);
  const [volgenderinorgList, setvolgenderinorgList] = useState(null);
  const [chart1Value, setChart1Value] = useState(false);
  const [chart2Value, setChart2Value] = useState(false);
  const [chart3Value, setChart3Value] = useState(false);
  const [chart4Value, setChart4Value] = useState(false);
  const [chart5Value, setChart5Value] = useState(false);
  const [chart6Value, setChart6Value] = useState(false);
  const [chart7Value, setChart7Value] = useState(false);
  const [chart8Value, setChart8Value] = useState(false);
  const [chart9Value, setChart9Value] = useState(false);
  const [volhourspentincampList, setvolhourspentincampList] = useState(null);
  const [volovertimeincampList, setvolovertimeincampList] = useState(null);

  React.useEffect(() => {
    if (document.getElementById("SOA_chart1") === null) {
      setChart1Value(false);
    } else {
      setChart1Value(true);
    }
  }, [document.getElementById("SOA_chart1")]);

  React.useEffect(() => {
    if (document.getElementById("SOA_chart2") === null) {
      setChart2Value(false);
    } else {
      setChart2Value(true);
    }
  }, [document.getElementById("SOA_chart2")]);

  React.useEffect(() => {
    if (document.getElementById("SOA_chart3") === null) {
      setChart3Value(false);
    } else {
      setChart3Value(true);
    }
  }, [document.getElementById("SOA_chart3")]);

  React.useEffect(() => {
    if (document.getElementById("SOA_chart4") === null) {
      setChart4Value(false);
    } else {
      setChart4Value(true);
    }
  }, [document.getElementById("SOA_chart4")]);

  React.useEffect(() => {
    if (document.getElementById("SOA_chart5") === null) {
      setChart5Value(false);
    } else {
      setChart5Value(true);
    }
  }, [document.getElementById("SOA_chart5")]);

  React.useEffect(() => {
    if (document.getElementById("SOA_chart6") === null) {
      setChart6Value(false);
    } else {
      setChart6Value(true);
    }
  }, [document.getElementById("SOA_chart6")]);

  React.useEffect(() => {
    if (document.getElementById("SOA_chart7") === null) {
      setChart7Value(false);
    } else {
      setChart7Value(true);
    }
  }, [document.getElementById("SOA_chart7")]);

  React.useEffect(() => {
    if (document.getElementById("SOA_chart8") === null) {
      setChart8Value(false);
    } else {
      setChart8Value(true);
    }
  }, [document.getElementById("SOA_chart8")]);

  React.useEffect(() => {
    if (document.getElementById("SOA_chart9") === null) {
      setChart9Value(false);
    } else {
      setChart9Value(true);
    }
  }, [document.getElementById("SOA_chart9")]);

  const sortedData = finalData?.sort(function (a, b) {
    if (a.name < b.name) return -1;
    else if (a.name > b.name) return 1;
    return 0;
  });

  //   const getCampaignDetails = async () => {
  //     try {
  //       // console.log(profileResponse?.orgrole)
  //       const res = await axios.get(
  //         // `/vol_dashboard/camp/${location?.pathname.split("/")?.[2]}`

  //         `vol_dashboard/camp/${location?.pathname.split("/")?.[2]}/${profileResponse?.orgrole}/${profileResponse?.organization}`
  //       );
  //       setCampaignList(res?.data);
  //       console.log(res?.data)
  //       return res;
  //     } catch (err) {
  //       throw err;
  //     }
  //   };

  // const plugin = {
  //   beforeInit(chart) {
  //     console.log("be");
  //     // reference of original fit function
  //     const originalFit = chart.legend.fit;

  //     // override the fit function
  //     chart.legend.fit = function fit() {
  //       // call original function and bind scope in order to use `this` correctly inside it
  //       originalFit.bind(chart.legend)();
  //       // increase the width to add more space
  //       this.width -= 20;
  //     };
  //   }
  // };

  // console.log(`/soa/vol/${profileResponse?.phoneno}`)
  // console.log(`/so/vol/overtime/${profileResponse?.phoneno}`)
  // console.log(`/so/vol/emp/${profileResponse?.phoneno}`)
  // console.log(profileResponse)

  const getSOVolReportSaga = async () => {
    try {
      const res = await axios.get(`/soa/vol/${profileResponse?.phoneno}`);
      setCampaignList(res?.data);
      // console.log(res?.data)
      return res;
    } catch (err) {
      throw err;
    }
  };
  // console.log(campaignList)

  const getSOVolOverTimeReportSaga = async () => {
    try {
      const res = await axios.get(
        `/so/vol/cummulativeovertime/${profileResponse?.phoneno}`
      );
      setVolovertimeList(res?.data);
      // console.log(res?.data)
      return res;
    } catch (err) {
      throw err;
    }
  };
  // console.log(volovertimeList);
  const getSOVolInCampInOrgReportSaga = async () => {
    try {
      const res = await axios.get(`/so/vol/camp/${profileResponse?.phoneno}`);
      setvolincampinorgList(res?.data);
      // console.log(res?.data)
      return res;
    } catch (err) {
      throw err;
    }
  };
  // console.log(volincampinorgList);

  const getSOVolEmpInOrgReportSaga = async () => {
    try {
      const res = await axios.get(`/so/vol/emp/${profileResponse?.phoneno}`);
      setvolempinorgList(res?.data);
      // console.log(res?.data)
      return res;
    } catch (err) {
      throw err;
    }
  };
  // console.log(volempinorgList);

  const getSOVolGenderInOrgReportSaga = async () => {
    try {
      const res = await axios.get(`/so/vol/gender/${profileResponse?.phoneno}`);
      setvolgenderinorgList(res?.data);
      // console.log(res?.data)
      return res;
    } catch (err) {
      throw err;
    }
  };

  // const getSOVolImpactInOrgReportSaga = async () => {
  //   try {
  //     const res = await axios.get(`/so/vol/impact/${profileResponse?.phoneno}`);
  //     setvolimpactinorgList(res?.data);
  //     // console.log(res?.data)
  //     return res;
  //   } catch (err) {
  //     throw err;
  //   }
  // };

  // const getSOVolHoursSpentInCampReportSaga = async () => {
  //   try {
  //     const res = await axios.get(
  //       `/so/vol/hoursspent/${profileResponse?.phoneno}`
  //     );
  //     setvolhourspentincampList(res?.data);
  //     // console.log(res?.data)
  //     return res;
  //   } catch (err) {
  //     throw err;
  //   }
  // };

  // const getSOVolOverTimeInCampReportSaga = async () => {
  //   try {
  //     const res = await axios.get(
  //       `/so/vol/camp/register/demo/${profileResponse?.phoneno}`
  //     );
  //     setvolovertimeincampList(res?.data);
  //     // console.log(res?.data)
  //     return res;
  //   } catch (err) {
  //     throw err;
  //   }
  // };

  // const Downloadpage = ({rootElementId, downloadFileName}) => {
  //   const downloadDocument = () => {
  //     const input = document.getElementById(rootElementId);
  //     html2canvas(input).then((canvas) => {
  //       const imagedata = canvas.toDataURL("image/png");
  //       const pdf = new jsPDF("p", "pt", "a4");
  //       pdf.addImage(imagedata, "JPEG", "10", "54");
  //       pdf.save(`${downloadFileName}`);
  //     })
  //   }
  // }
  function sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  // const downloadDocument = () => {
  //   console.log("inside");
  //   const input = document.getElementById("SOADashboardScreen");
  //   console.log("input = ", input);
  //   html2canvas(input, {logging:true, willReadFrequently:true, useCORS:true}).then((canvas) => {
  //     const imgwidth= 100;
  //     const imgHeight= canvas.height* imgwidth /canvas.width;
  //     const imagedata = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "pt", "a4");
  //     console.log("sleeping for 2 secs...");
  //     sleep(2000);
  //     console.log("slept for 2 secs");

  //     // pdf.addImage(imagedata, "png", 0, 0, imgwidth, imgHeight);
  //     pdf.addImage(imagedata, "JPEG", "10", "45");
  //     pdf.save("cyaag.pdf");
  //   });
  // }

  // const downloadDocument = () => {
  //   console.log("inside");
  //   const input = document.getElementById("SOADashboardScreen");
  //   console.log("input = ", input);
  //   html2canvas(input, {useCORS:true, willReadFrequently:true, onrendered: function(canvas) {
  //     const imgwidth= 100;
  //     const imgHeight= canvas.height* imgwidth /canvas.width;
  //     const imagedata = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "mm", "a4");
  //     console.log("sleeping for 2 secs...");
  //     sleep(2000);
  //     console.log("slept for 2 secs");

  //     pdf.addImage(imagedata, "png", 0, 0, imgwidth, imgHeight);
  //     console.log("pdf created");
  //     pdf.save("cyaag.pdf");
  //     console.log("pdf saved");
  //   }

  //   });
  //   console.log("complete");
  // }

  const downloadDocument = () => {
   
    const pdf = new jsPDF("l", "pt", "a2");
    // const pdf = new jsPDF({
    //   orientation: "landscape",
    //   unit: "pt",
    //   format: [4, 2]
    // });
    pdf.html(document.querySelector("#SOADashboardScreen1"), {
      callback: function (pdf) {
        var pageCount = pdf.internal.getNumberOfPages();
        // pdf.deletePage(pageCount);

        pdf.save("cyaag.pdf");
      },
    });
    // const input = document.getElementById("SOADashboardScreen");
    // console.log("input = ", input);
    // html2canvas(input, {logging:true, willReadFrequently:true, useCORS:true}).then((canvas) => {
    //   const imgwidth= 100;
    //   const imgHeight= canvas.height* imgwidth /canvas.width;
    //   const imagedata = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF("p", "pt", "a4");
    //   console.log("sleeping for 2 secs...");
    //   sleep(2000);
    //   console.log("slept for 2 secs");

    //   // pdf.addImage(imagedata, "png", 0, 0, imgwidth, imgHeight);
    //   pdf.addImage(imagedata, "JPEG", "10", "45");
    //   pdf.save("cyaag.pdf");
    // });
  };

  // React.useEffect(() => {
  //     if (profileSuccess) {
  //       const sdk = new ChartsEmbedSDK ( {
  //         baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",

  //         filter: {
  //           // "clientid": { "$eq": "c00001" }
  //           // "orgrole": { "$eq": "c00001" },
  //           // "organization": { "$eq": `${profileResponse?.organization}` }
  //           "organization": { "$eq": profileResponse?.organization }
  //         }

  //         // filter:
  //         //   {
  //         //     "$match": {
  //         //       "$and": [
  //         //         {
  //         //           "clientid": {
  //         //             "$eq": "c00002"
  //         //         }
  //         //         },
  //         //         // {
  //         //         //   "organization": {
  //         //         //     "$eq": "IEEE"
  //         //         //   }
  //         //         // }
  //         //       ]
  //         //     }
  //         //   },

  //       });
  //     }
  //   }, [profileSuccess]);

  
  const sdk1 = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",

    filter: {
      // "clientid": { "$eq": "c00001" }
      // "orgrole": { "$eq": "c00001" },
      organization: { $eq: profileResponse?.organization },
    },
  });
  
  const SOA_chart1 = sdk1.createChart({
    chartId: "64db1b64-636d-4f73-8261-c8e3766e416a",
    showAttribution: false,
  });
 
  // }
  SOA_chart1.render(document.getElementById("SOA_chart1"));

  // console.log("getUserToken = ", getUserToken: () => JSON.parse(localStorage.getItem("token")))
  // const ChartsEmbedSDK = window.ChartsEmbedSDK;

  const sdk2 = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    // getUserToken: () => (JSON.parse(localStorage.getItem("token"))),
    // getUserToken: () => `${localStorage.getItem("token")}`,
    // getUserToken: async function() {
    //   return await login();
    // },
    // getUserToken: () => ((localStorage.getItem("token"))),
    filter: {
      // "clientid": { "$eq": "c00001" }
      // "orgrole": { "$eq": profileResponse?.orgrole },
      organization: { $eq: profileResponse?.organization },
    },
  });
  // console.log("sdk2 = ", sdk2)

  const SOA_chart2 = sdk2.createChart({
    chartId: "64dbb167-636d-48e2-816d-c8e3761e6913",
    showAttribution: false,
  });
  
  // console.log("sdk2  ELEMENT_NODE = ", document.getElementById("SOA_chart2").ELEMENT_NODE.length)
  // console.log("sdk2  getAttributeNode = ", document.getElementById("SOA_chart2").getAttributeNode.length)
  // if (!(document.getElementById("SOA_chart2").firstChild === null)) {


  SOA_chart2.render(document.getElementById("SOA_chart2"));

  // }
  // SOA_chart2.render(document.getElementById("SOA_chart2")).catch(() => window.alert('Chart failed to initialise'));
  // React.useEffect(() => {
  //   login1();

  // }, []);
  // async function login1() {
  //   const sdk2 = new ChartsEmbedSDK ( {
  //     baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
  //     // getUserToken: () => {(localStorage.getItem("token"))},
  //     // getUserToken: () => `${localStorage.getItem("token")}`,
  //     // getUserToken: async function() {
  //     //   return await login();
  //     // },
  //     // getUserToken: () => {(JSON.parse(localStorage.getItem("token")))},
  //     filter: {
  //       // "clientid": { "$eq": "c00001" }
  //       // "orgrole": { "$eq": profileResponse?.orgrole },
  //       "organization": { "$eq": profileResponse?.organization }
  //     }

  //   });
  //   console.log("sdk2 = ", sdk2);
  //   console.log("token = ", localStorage.getItem("token"))
  //   const SOA_chart2 = sdk2.createChart({chartId: "64dbb167-636d-48e2-816d-c8e3761e6913", showAttribution: false});
  //   await SOA_chart2.render(document.getElementById("SOA_chart2")).catch(e => window.alert(e));
  //   console.log("SOA_chart2 = ", SOA_chart2);
  //   // return localStorage.getItem("token");
  //   }

 
  // console.log(JSON.parse(localStorage.getItem("token")))
  const sdk3 = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    // getUserToken: () => JSON.parse(localStorage.getItem("token")),
    // getUserToken: () => (localStorage.getItem("token")),

    filter: {
      // "clientid": { "$eq": "c00001" }
      // "orgrole": { "$eq": profileResponse?.orgrole },
      // formorgname: { $eq: profileResponse?.organization },
      "campid_lookup_campaign.organization": { $eq: profileResponse?.organization },
    },
  });

  const SOA_chart3 = sdk3.createChart({
    chartId: "64dc7da7-ef75-4917-8a43-fb10770d44a0",
    showAttribution: false,
  });
  // console.log("sdk3 ELEMENT_NODE = ", document.getElementById("SOA_chart3").ELEMENT_NODE.length)
  // console.log("sdk3 attributeNode = ", document.getElementById("SOA_chart3").getAttributeNode.length)
  // if (!(document.getElementById("SOA_chart3").firstChild === null)) {
  SOA_chart3.render(document.getElementById("SOA_chart3"));
  // }

  // document.getElementById("data-from").addEventListener('change', e => {
  //   console.log("IIIIIIIIIIIIIIIIII")
  //   const date = new Date(e.target.value);
  //   SOA_chart3.setFilter({registerdate:{$gte: date}})
  // })

  const sdk4 = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    // getUserToken: () => localStorage.getItem("token"),

    filter: {
      // "clientid": { "$eq": "c00001" }
      // "orgrole": { "$eq": profileResponse?.orgrole },
      "campid_lookup_campaign.organization": { $eq: profileResponse?.organization },
    },
  });

  const SOA_chart4 = sdk4.createChart({
    chartId: "5148b346-6151-4201-8a89-123f598b8b20",
    showAttribution: false,
  });
  SOA_chart4.render(document.getElementById("SOA_chart4"));

  {
    /* Total Vol and Emp */
  }
  const sdk5 = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",

    filter: {
      organization: { $eq: profileResponse?.organization },
    },
  });

  const SOA_chart5 = sdk5.createChart({
    chartId: "64dd06cc-20b7-4883-805b-12e34ee7ca5b",
    showAttribution: false,
  });
  SOA_chart5.render(document.getElementById("SOA_chart5"));

  {
    /* Heat Map Chart*/
  }
  const sdk6 = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    //theme:"dark",

    filter: {
      // "clientid": { "$eq": "c00001" }
      // "orgrole": { "$eq": profileResponse?.orgrole },
      organization: { $eq: profileResponse?.organization },
    },
  });

  const SOA_chart6 = sdk6.createChart({
    chartId: "64dcffb3-8c93-4df3-882f-968630db5e93",
    showAttribution: false,
  });
  SOA_chart6.render(document.getElementById("SOA_chart6"));

  {
    /* My versus other org vol in camp*/
  }
  const sdk7 = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",

    filter: {
      // "clientid": { "$eq": "c00001" }
      // "orgrole": { "$eq": profileResponse?.orgrole },
      "formcampaignname_lookup_campaign.organization": {
        $eq: profileResponse?.organization,
      },
    },
  });

  const SOA_chart7 = sdk7.createChart({
    chartId: "9b0f63e3-2e39-4299-8456-be5343bfa126",
    showAttribution: false,
  });
  SOA_chart7.render(document.getElementById("SOA_chart7"));

  {
    /* User's time commitment as in profile*/
  }
  const sdk8 = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    //theme:"dark",
    // getUserToken: () => localStorage.getItem("userid"),

    filter: {
      // "clientid": { "$eq": "c00001" }
      // "orgrole": { "$eq": profileResponse?.orgrole },
      organization: { $eq: profileResponse?.organization },
    },
  });

  const SOA_chart8 = sdk8.createChart({
    chartId: "96865e46-3e52-4372-a558-065ae30e9091",
    showAttribution: false,
  });
  SOA_chart8.render(document.getElementById("SOA_chart8"));

  {
    /* Summary of Campaigns by Category*/
  }
  const sdk9 = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-hyphen-cyaag-gkkmp",
    //theme:"dark",
    // getUserToken: () => localStorage.getItem("userid"),

    filter: {
      // "clientid": { "$eq": "c00001" }
      // "orgrole": { "$eq": profileResponse?.orgrole },
      organization: { $eq: profileResponse?.organization },
    },
  });

  const SOA_chart9 = sdk9.createChart({
    chartId: "b199303c-7773-467a-9ce5-b3eeb4085993",
    showAttribution: false,
  });
  SOA_chart9.render(document.getElementById("SOA_chart9"));


  return (
    <div className="wrapper">
      {/* {
        // profileSuccess &&


        (profileResponse?.orgrole?.toLowerCase() === "hyphenadmin" ||
          profileResponse?.orgrole?.toLowerCase() === "admin" ? (
          <ResponsiveDrawer {...props} />
        ) : (
          <CustomDrawer menuItems={volunteerMenuItems} {...props} />
        ))} */}

      <ResponsiveDrawer {...props} />

      {/* <ResponsiveDrawer {...props} /> */}
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap" id="pdf">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Overall Dashboard View
            </div>
            {/* <br></br>
            <br></br> */}
            {/* <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Dashboard
            </div>
            <div>
              <Button
                className="btn download-btn"
                variant="contained"
                onClick={downloadDocument}
              >
                Download
              </Button>
            </div> */}
            {/* <header id="SOADashboardScreen1" style={{zIndex:"-10",marginTop:"50px"}}> */}
            <header id="SOADashboardScreen1" style={{zIndex:"-10"}}>
              {/* <Box sx={{ minWidth: 200 }}> */}
                {/* <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolInCampOverTimeChartComponent
                    response={volovertimeincampList}
                  />
                </div>
              </div> */}
                <div class="row">
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <div id="SOA_chart3" class="column chartMongo">
                      {" "}
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <div id="SOA_chart4" class="column chartMongo">
                      
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <div id="SOA_chart6" class="column chartMongo">
                     
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <div id="SOA_chart9" class="column chartMongo">
                     
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <div id="SOA_chart7" class="column chartMongo">
                      
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <div id="SOA_chart2" class="column chartMongo">
                      
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <div id="SOA_chart8" class="column chartMongo">
                     
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <div
                      id="SOA_chart5"
                      class="column chartMongo"
                      style={{ textAlign: "center" }}
                    >
                      
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-12 col-xs-12">
                    <div id="SOA_chart1" class="column chartMongo">
                      
                    </div>
                  </div>
                </div>

                
              {/* </Box> */}
            </header>

            {/* <header id="SOADashboardScreen2" style={{position:"absolute",zIndex:"-1",marginTop:"50px"}}>
            <Box sx={{ minWidth: 200 }}> */}
            {/* <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolInCampOverTimeChartComponent
                    response={volovertimeincampList}
                  />
                </div>
              </div> */}
            {/* <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolOverTimeChartComponent1 response={volovertimeList} />
                </div>
              </div>
              <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolMaleVsFemaleOrgChartComponent1
                    response={volgenderinorgList}
                  />
                </div>
              </div>
              <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolHoursSpentCampChartComponent1
                    response={volhourspentincampList}
                  />
                </div>
              </div>
              <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolEmpInOrgChartComponent1 response={volempinorgList} />
                </div>
              </div> */}
            {/* <div class="iframeContentWrapper">
                <div class="innerSpace">
                  <SOVolImpactDivComponent response={volimpactinorgList} />
                </div>
              </div> */}
            {/* <div class="iframeContentWrapper">
                <div class="innerSpace"></div>
              </div>
              <div class="iframeContentWrapper">
                <div class="innerSpace"></div>
              </div> */}
            {/* </Box>
            </header> */}
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
      {/* <PDF rootElementId="pdf" downloadFileName="testpdf" /> */}
    </div>
  );
}
