import { makeStyles } from "@material-ui/core";
import { Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "../../components/Header";
import { ReviewSponsorTableComponent } from "../../components/Table";
import { userProfile } from "../../redux/action/UserProfileActions";
import { API_URL } from "../../services/Constants";
import TopHeaderScreen from "../../components/TopHeader1";
import { CopyRight } from "../../components/CopyRight";
import { Link, useNavigate } from "react-router-dom";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    background: "#F5F8FB",
    minHeight: "100vh",
    width: "max-content",
    minWidth: "100vw",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 100px 330px",
    },
  },
}));

const SponsorReviewScreen = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activityList, setActivityList] = useState([]);

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  // console.log(`/org/status/${localStorage.getItem("userid")}/${profileResponse?.organization}`)
  const getActivityList = async () => {
    try {
      const res = await axios.get(
        `/sponsor/status/${localStorage.getItem("userid")}/${
          profileResponse?.organization
        }`
      );
      setActivityList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    dispatch(userProfile(localStorage.getItem("userid")));
  }, []);
  useEffect(() => {
    if (profileResponse?.organization) {
      getActivityList();
    }
  }, [profileResponse?.organization]);

  return (
    <div className="wrapper">
      
      <ResponsiveDrawer {...props} />
      <section className="containers" style={{overflow:"hidden"}}>
      <TopHeaderScreen {...props} />
      <div class="content content-wrap">
          <div class="content-mrg">
          <Button onClick={() => navigate(-1)} className={classes.backBtn}>
            <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
            <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
          </Button>
        {/* <span style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Review</span> */}

        <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Manage Sponsor Organization(s)</div> 
        <ReviewSponsorTableComponent rows={activityList} />
      </div>
      </div>
      <CopyRight />
      <FixedFooterIcon />
      </section>
    </div>
  );
};

export default SponsorReviewScreen;
