/** @format */

// reducers for camp files with public visibility
import {
    
    GET_CAMPAIGN_FILES_FETCHING_DATA,
    GET_CAMPAIGN_FILES_FETCHING_DATA_FAILURE,
    GET_CAMPAIGN_FILES_FETCHING_DATA_RESET,
    GET_CAMPAIGN_FILES_FETCHING_DATA_SUCCESS,

    GET_CAMPAIGN_DOCS_FETCHING_DATA,
    GET_CAMPAIGN_DOCS_FETCHING_DATA_FAILURE,
    GET_CAMPAIGN_DOCS_FETCHING_DATA_RESET,
    GET_CAMPAIGN_DOCS_FETCHING_DATA_SUCCESS,

  } from "../action/campFilesAction";
  
  let initialState = {
    response: {},
    isSuccess: false,
    isError: false,
    isFetching: false,

    campDocresponse: {},
    isCampDocSuccess: false,
    isCampDocError: false,
    isCampDocFetching: false,
  };
  
  export default function campFilesReducer(state = initialState, action) {
    switch (action.type) {
      case GET_CAMPAIGN_FILES_FETCHING_DATA:
        return {
          ...state,
          isFetching: true,
          isError: false,
          isSuccess: false,
        };
      case GET_CAMPAIGN_FILES_FETCHING_DATA_FAILURE:
        return {
          ...state,
          isFetching: false,
          isError: action.payload,
          isSuccess: false,
        };
      case GET_CAMPAIGN_FILES_FETCHING_DATA_SUCCESS:
        return {
          ...state,
          isFetching: false,
          isError: false,
          response: action.payload,
          isSuccess: true,
        };
  
      case GET_CAMPAIGN_FILES_FETCHING_DATA_RESET:
        return initialState;



      
      case GET_CAMPAIGN_DOCS_FETCHING_DATA:
        return {
          ...state,
          isCampDocFetching: true,
          isCampDocError: false,
          isCampDocSuccess: false,
        };
      case GET_CAMPAIGN_DOCS_FETCHING_DATA_FAILURE:
        return {
          ...state,
          isCampDocFetching: false,
          isCampDocError: action.payload,
          isCampDocSuccess: false,
        };
      case GET_CAMPAIGN_DOCS_FETCHING_DATA_SUCCESS:
        return {
          ...state,
          isCampDocFetching: false,
          isCampDocError: false,
          campDocresponse: action.payload,
          isCampDocSuccess: true,
        };
  
      case GET_CAMPAIGN_DOCS_FETCHING_DATA_RESET:
        return initialState;


  
      default:
        return state;
    }
  }
