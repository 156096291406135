import { Button, makeStyles, Checkbox } from "@material-ui/core";
import { Avatar, FormControl, Select, MenuItem, CssBaseline } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomDrawer from "../../components/CustomHeader";
import ResponsiveDrawer from "../../components/Header";
import { execMenuItems, volunteerMenuItems } from "../../utils/dict";
import { MenuProps, states } from "../../utils/constants";
import { getCity } from "../../redux/action/cityAction";
import TopHeaderScreen from "../../components/TopHeader1";
import { CopyRight } from "../../components/CopyRight";
import {
  activityDuration,
  campaignParticipate,
  campaignType,
  restrictedCountry,
} from "../../utils/dict";
import { Link } from "react-router-dom";
import { ColorModeContext, useMode, tokens } from "../../theme";
import { ThemeProvider } from "@emotion/react";
import { isArray } from "lodash";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";


const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "0px 30px 100px 25px", background: "#F5F8FB"
    },
  },
  large: {
    width: "150px !important",
    height: "150px !important",
  },
  input: {
    // textTransform: "capitalize",
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    // boxShadow: "0px 3px 6px #00000029",
    // backgroundColor: "#fff",
    backgroundColor: "#FFFFFF",
    // color: "#949494",
    color: "#0F0F0F",
    fontSize: 17,
    // fontWeight: "520",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  input1: {
    // textTransform: "capitalize",
    width: "25%",
    border: "none",
    padding: 7,
    display: "block",
    borderRadius: 6,
    height: 0,
    // boxShadow: "0px 3px 6px #00000029",
    // backgroundColor: "#fff",
    backgroundColor: "#FFFFFF",
    // color: "#949494",
    color: "#0F0F0F",
    fontSize: 17,
    // fontWeight: "520",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
    marginLeft: 10,
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "block",

    fontSize: 17,
    marginTop: 16,
    padding: 10,
    fontWeight: "500",
    lineHeight: "15px",
    minHeight: 45,
    paddingBottom: 0,
    color: "#808080",
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: 14,
    // },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    // backgroundColor: "#fff",
    backgroundColor: "#FFFFFF",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    marginBottom: 10,
    [theme.breakpoints.only("xl")]: {
      width: "25%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  pageHeadingIcon: {
  //   fontSize: 25,
    // color: theme.palette.secondary.main,
    color:"#1976d2",
    // marginLeft: 8,
  },
}));


const ProfileScreen = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [theme, colorMode] = useMode();
  const [image, setImage] = React.useState(null);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const handleChangeLocation = (id, e, field, index) => {
    const values = [...location];
    values[index][field] = e.target.value;
    setLocation(values);
  };

  const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
    (state) => state.cities
  );

  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "" },
  ]);


  // This function will called only once
  useEffect(() => {
    dispatch(getCity(profileResponse?.state));
  }, [])


  //dispatch(getCity(profileResponse?.stat));
  return (
    <div className="wrapper">
      {/* {profileResponse?.orgrole?.toLowerCase() === "hyphenadmin" ||
        profileResponse?.orgrole?.toLowerCase() === "admin" ? (
        <ResponsiveDrawer {...props} />
      ) : (
        <CustomDrawer
          menuItems={
            profileResponse?.orgrole?.toLowerCase() === "volunteer"
              ? volunteerMenuItems
              : execMenuItems
          }
          {...props}
        />
      )} */}
      <ResponsiveDrawer {...props} />
      <section className="containers">
      <ColorModeContext.Provider value={colorMode} >
        {/* <ThemeProvider theme={theme}>
          <CssBaseline/> */}
          <TopHeaderScreen {...props} />
        {/* </ThemeProvider> */}
      </ColorModeContext.Provider>
      {/* <TopHeaderScreen {...props} /> */}
        
        <div class="content content-wrap">
          <div class="content-mrg">
          <Button
              onClick={() => navigate(-1)}
              className={classes.backBtn}
          >
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14, color: "#1976d2" }}>Back</span>
          </Button>
            {/* <main
              onClick={() => {
                props.setNotificationShow(false);
              }}
              className={classes.homeSreenMainContent}
              id="homepage-main"
            > */}
            <div>
              <span style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>User Profile</span>
              </div>
              <div
                style={{
                  boxShadow: "1px 1px 1px 5px #f0f0f0",
                  borderRadius: 8,
                  paddingTop: 30,
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  float:"left",
                  width: "100%"
                }}
              >
                <Avatar
                  src={profileResponse?.profilephoto}
                  className={classes.large}
                />
                <Button
                  onClick={() => navigate("/editProfile")}
                  style={{
                    marginTop: 15,
                    width: "200px",
                    borderRadius: 20,
                    color: "#fff",
                    fontFamily: "Poppins !important",
                    marginBottom: "25px",
                    textTransform: "none",
                    border: "1px dotted #E88A00",
                  }}
                >
                  <span style={{ color: "#E88A00" }}>Edit Profile</span>
                </Button>
                <FormControl>
                  <div
                    style={{
                      paddingBottom: 32,
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      paddingLeft: 60,
                      paddingRight: 60
                    }}
                  >
                    <div className={classes.formGroup}>
                      <label className={classes.label}>First Name</label>
                      <input
                        disabled
                        value={profileResponse?.firstname}
                        type="text"
                        className={classes.input}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label className={classes.label}>Last Name</label>
                      <input
                        disabled
                        value={profileResponse?.lastname}
                        type="text"
                        className={classes.input}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label htmlFor="create-yaad--title" className={classes.label}>
                        Date of Birth
                      </label>
                      <input
                        disabled
                        value={profileResponse?.dob}
                        type="text"
                        className={classes.input}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label htmlFor="create-yaad--title" className={classes.label}>
                        Blood Group
                      </label>
                      <input
                        disabled
                        value={profileResponse?.bloodgroup}
                        type="text"
                        className={classes.input}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label className={classes.label}>Email ID</label>
                      <input
                        disabled
                        value={profileResponse?.emailid}
                        type="text"
                        className={classes.input}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label htmlFor="create-yaad--title" className={classes.label}>
                        Phone no
                      </label>
                      <input
                        disabled
                        value={profileResponse?.phoneno}
                        type="text"
                        className={classes.input}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label htmlFor="create-yaad--title" className={classes.label}>
                        Correspondence Address
                      </label>
                      <input
                        disabled
                        value={profileResponse?.correspondenceaddress}
                        type="text"
                        accept="image/*"
                        id="create-yaad--title"
                        className={classes.input}
                      />
                    </div>

                    {location.map((item, index) => (
                      <div className={classes.formGroup}>
                        <label htmlFor="create-yaad--title" className={classes.label}>
                          State
                        </label>

                        <input
                          disabled
                          value={profileResponse?.state}
                          type="text"
                          accept="image/*"
                          id="create-yaad--title"
                          className={classes.input}
                        />

                        {/* <Select
                style={{
                  width: "100%",
                  fontSize: 14,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                disabled={profileResponse?.state}
                value= {profileResponse?.state}
                onChange={(e) => {
                  handleChangeLocation(item, e, "state", index);
                 // dispatch(getCity(item.state));
                }}
                onBlur={(e) => {
                  // dispatch(getCity(item.state));
                }}
              >
                {states.map((item, index) => (
                  <MenuItem style={{ fontSize: 16 }} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select> */}
                      </div>
                    ))}
                    {location.map((item, index) => (
                      <div className={classes.formGroup}>
                        <label htmlFor="create-yaad--title" className={classes.label}>
                          City/Town/Village
                        </label>

                        <input
                          disabled
                          value={profileResponse?.city}
                          type="text"
                          accept="image/*"
                          id="create-yaad--title"
                          className={classes.input}
                        />

                        {/* <Select
            style={{ width: "100%", fontSize: 14, height: 56 }}
            value={profileResponse?.city}
            onChange={(e) => {
              handleChangeLocation(item, e, "city", index);
            }}
            disabled
          >
            {citiesSuccess &&
              citiesResponse.map((item, index) => (
                <MenuItem style={{ fontSize: 16 }} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
          </Select> */}
                      </div>
                    ))}
                    <div className={classes.formGroup}>
                      <label htmlFor="create-yaad--title" className={classes.label}>
                        High Level Education
                      </label>
                      <input
                        disabled
                        value={profileResponse?.highlvledu}
                        type="text"
                        accept="image/*"
                        id="create-yaad--title"
                        className={classes.input}
                      />
                    </div>

                    <div className={classes.formGroup}>
                      <label className={classes.label}>Hours Per Week</label>
                      <input
                        disabled
                        value={profileResponse?.hoursperweek}
                        type="text"
                        className={classes.input}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label htmlFor="create-yaad--title" className={classes.label}>
                        Prefer Day
                      </label>
                      <input
                        disabled
                        value={profileResponse?.preferday}
                        type="text"
                        accept="image/*"
                        id="create-yaad--title"
                        className={classes.input}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label htmlFor="create-yaad--title" className={classes.label}>
                        Interest Area
                      </label>
                      {/* <input
                  disabled
                  value=""
                  type="text"
                  accept="image/*"
                  id="create-yaad--title"
                  className={classes.input1}
                /> */}

                      {/* <input
                  disabled
                  value={profileResponse?.interestarea}
                  type="text"
                  accept="image/*"
                  id="create-yaad--title"
                  className={classes.input}
                /> */}
                      {/* <ul style={{marginTop: 0}}>
                {
                profileResponse?.interestarea.map(link =>
                <li key={link.interest}>{link.interest}</li> 
                )}
                </ul> */}

                      {/* <ul style={{marginTop: 0, color: "black"}}> */}

                      {
                        isArray(profileResponse?.interestarea) &&
                        profileResponse?.interestarea.map((link, index) => (
                          // <span style={{ padding: "10px" }}> {(index ? ', ' : '')} {link.interest} </span>
                          <span style={{ color:"#0F0F0F",fontFamily:"inherit", padding:"5px" }}> {link.interest} {((index == (profileResponse?.interestarea?.length-1)) ? '': ',')} </span>
                      ))}
                      

                    </div>
                    <div className={classes.formGroup}>
                      <label htmlFor="create-yaad--title" className={classes.label}>
                        Skills
                      </label>
                      {/* <ul style={{marginTop: 0}}> */}
                      {/* {
                  profileResponse?.skill.map(link =>
                    // <li key={link.skill}>{link.skill}</li> 
                    <div>

                      <input
                        disabled
                        key={link.skill}
                        value={link.skill}
                        type="text"
                        accept="image/*"
                        id="create-yaad--title"
                        className={classes.input}
                      />

                    </div>
                  )} */}                  
                      {
                        isArray(profileResponse?.skill) &&
                        profileResponse?.skill.map((link, index) =>
                          (
                          
                          // <span style={{ color: "#0F0F0F", fontFamily: "inherit", padding: "10px" }}> {(index ? ', ' : '')} {link.skill} </span>
                          <span style={{ color:"#0F0F0F",fontFamily:"inherit", padding:"5px" }}> {link.skill} {((index == (profileResponse?.skill?.length-1)) ? '': ',')} </span>
                          )
                        )
                      }
                      
                    </div>
                  </div>
                </FormControl>
              </div>
            {/* </main> */}
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
      </section>
    </div>


  );
};

export default ProfileScreen;
