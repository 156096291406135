import { makeStyles } from "@material-ui/core";
import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "1px solid #C7C1C1",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "block",
    fontSize: 14,
    marginTop: 12,
    padding: 10,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
  },
}));

export const InputDisable = ({ label, value, onChange, onBlur, datePicker }) => {
  const classes = useStyles();
  return (
    <div>
      <label className={classes.label}>{label}</label>
      {datePicker ? (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            inputFormat={"yyyy-MM-dd"}
            value={value}
            onChange={onChange}
            renderInput={(params) => (
              <TextField
                style={{
                  width: 180,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                {...params}
              />
            )}
          />
        </LocalizationProvider>
      ) : (
        <input
          className={classes.input}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          placeholder="Your answer"
          disabled="true"
        />
      )}
    </div>
  );
};
