/** @format */

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery, Avatar, Chip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Button, Stack } from "@mui/material";
import _ from "lodash";
import "../../scss/containerStyles/_home.scss";
import "../../App.css";
import "../../cyaag-style.css";
import "../../font-awesome.min.css";
import "../../bootstrap.min.css";
import "../../icon-font.css";
import "../../flaticon-font.css";
import {
  userProfile,
  userProfileReset,
} from "../../redux/action/UserProfileActions";
import { Modal } from "react-bootstrap";
import { CopyRight } from "../../components/CopyRight";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import { getVolunteerCount } from "../../redux/action/volunteerCountAction";
import ResponsiveDrawer from "../../components/Header";
import TopHeaderScreen from "../../components/TopHeader1";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getCampaign } from "../../redux/action/CampaignAction";
import Card from "@material-ui/core/Card";
import { referral } from "../../redux/action/ReferralAction";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { volDashBoardReward } from "../../redux/action/DashboardReward";

import { isArray } from "lodash";

import RedeemConfirmation from "../../components/RedeemConfirmation";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import PostRedeemConfirmation from "../../components/PostRedeemConfirmation";

import { Audio, Circles } from "react-loader-spinner";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 100px 330px",
    },
  },
  homeScreenContainer: {
    // padding: "0 0 0 20px",

    paddingLeft: "10px",
    zIndex: 100,
  },
  welcomeMessage: {
    fontWeight: "bold",
    marginBottom: 10,
    margin: "10px 0",
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
  },
  welcomeAndFilter: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    textTransform: "uppercase",
    padding: "10px 0",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  filterIcon: {
    fontSize: 26,
    color: "#F8B34C",
    marginLeft: 28,
  },
  homeCards: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    margin: "0 -7px",
  },

  maxWidthContainer: {
    padding: "25px 0",
    maxWidth: 500,
    margin: "0 auto",
  },
  gradientHeading: {
    fontSize: 25,
    fontWeight: "bold",
  },
  rewards: {
    display: "flex",
    justifyContent: "center",
    color: "#F8B34C",
    paddingBottom: 13,
  },
  rewardsText: {
    fontSize: 25,
    fontWeight: "bold",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 9,
  },
  secondaryBorder: {
    padding: "5px 0",
    borderBottom: "2px solid #F8B34C",
  },
  myImpacts: {
    padding: "15px 0 0 0",
    fontSize: 14,
    textAlign: "center",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  myImpactsWrap: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 -8px",
    outline: "none",
  },
  myImpact: {
    width: "150px",
    margin: "0 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    outline: "0 !important",
    border: "none",
  },
  myImpactImgWrap: {
    width: 52,
    height: 50,
    margin: "0 auto 5px",
  },
  myImpactImg: {
    width: "100%",
  },
  myImpactTxt: {
    display: "block",
  },
  myCommunity: {
    borderBottom: "2px solid #F8B34C",
    padding: "15px 0",
    fontSize: 20,
    color: "#F8B34C",
  },
  myCommunityContentWrap: {
    display: "flex",
    justifyContent: "space-between",
  },
  myCommunityImg: {
    width: 100,
  },
  inviteBtn: {
    paddingTop: 15,
    width: 150,
  },
  myCommunityPoints: {
    listStyle: "none",
    margin: "0 0 0 10px",
    padding: 0,
  },
  inviteButton: {
    fontSize: 9,
    fontWeight: "bold",
    lineHeight: 1,
  },
  myRegion: {
    borderBottom: "2px solid #F8B34C",
    padding: "15px 0",
  },
  mapImg: {
    width: 130,
    margin: "0 auto",
  },
  myYaags: {
    padding: "15px 0",
  },

  bottomNavigation: {
    position: "absolute",
    width: "100%",
  },
  tabName: {
    // color: "#fff",
    color: "#00000",
    textTransform: "none",
    // ariaSelected: "true",
  },
  maxpoints: {
    textTransform: "none",
    fontSize: 13,
  },
  tagWrapperContainer: {
    width: "45%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  tagWrapper: {
    padding: 4,

    backgroundColor: "#E8E8E8",
    margin: 4,
    borderRadius: 4,
    // display: "flex",
    display: "inline-flex",
  },
  campaigntypewrapper: {
    padding: 1,

    // backgroundColor: "#E8E8E8",
    margin: 2,
    borderRadius: 4,
    display: "block",
    // display: "inline-flex",
  },
  tags: {
    textTransform: "capitalize",
    fontSize: 12,
  },
  earnupto: {
    textTransform: "capitalize",
    fontSize: 12,
  },
  adopterCountText: {
    fontSize: 12,
    textTransform: "none",
    fontWeight: "600",
    backgroundColor: "#E8E8E8",
    borderRadius: 4,
    padding: 4,
  },
  cardImg: {
    borderRadius: "5px 5px 0 0",
    // height: 280,
    height: 200,
    backgroundSize: "100% 100%",
    // width: 100,
  },
  cardTitle: {
    // background: "#2C7973",
    color: "#000",
    zIndex: 99,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    boxShadow: "0px 5px 10px rgba(0,0,0,0.2)",
    overflow: "auto",
  },
  cardTitle1: {
    // background: "#2C7973",
    color: "#000",
    // height:"500",
    zIndex: 99,
    display: "flex",
    flexDirection: "row",
    // justifyContent: "flex-start",
    // alignItems: "flex-start",
    // boxShadow: "0px 5px 10px rgba(0,0,0,0.2)",
    overflow: "auto",
  },
  button: {
    padding: "5px 2px",
    fontSize: "10px",
  },
  ChipCompleted: {
    fontSize: 12,
    textTransform: "none",
    fontWeight: "600",
    backgroundColor: "#228B22",
    borderRadius: 4,
    padding: 4,
  },
  subtitleWrapper: {
    textAlign: "center",
  },
}));

const SubscriptionListScreen = (props) => {
  // console.log("start HAScreen")
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [campaignList, setCampaignList] = useState([]);
  const [myCampaignList, setMyCampaignList] = useState([]);
  const [myRecommendedCampList, setMyRecommendedCampList] = useState([]);
  const [campStatusCount, setCampStatusCount] = useState([]);
  const [SDGCampMapping, setSDGCampMapping] = React.useState([]);

  const [value, setValue] = React.useState(0);
  const [image, setImage] = React.useState(null);

  const [rewarddash, setRewardDash] = useState([]);
  const [mycommunity, setmycommunity] = useState([]);
  const [myredeemedrewards, setmyredeemedrewards] = useState([]);
  const [marketplacelist, setmarketplacelist] = useState([]);
  const [successStories, setSuccessStories] = useState([]);
  const [afterRedeem, setAfterRedeem] = useState([]);
  const [displayRedeemConfirmationModal, setDisplayRedeemConfirmationModal] =
    useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [redeemindex, setRedeemIndex] = useState(0);
  const [postRedeemDisplayModal, setPostRedeemDisplayModal] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [reLoading, setReLoading] = React.useState(false);
  const [newValue, setNewValue] = React.useState({});

  const [showModal, setShowModal] = React.useState(false);
  const [modalData, setModalData] = useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  function setDisplayAndIndex(bool, index) {
    setDisplayRedeemConfirmationModal(bool);
    setRedeemIndex(index);
  }

  // console.log("calling profile")
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  useEffect(() => {
    let temp = [];
    localStorage.getItem("refferalId") &&
      dispatch(
        referral({
          campid: localStorage.getItem("refferalId"),
          phoneno: localStorage.getItem("refferalPhoneNumber"),
          reflevel: localStorage.getItem("reflevel"),
          refuserid: localStorage.getItem("userid"),
        })
      );
    // dispatch(socialLoginReset());
    // dispatch(registerReset());
    // dispatch(yaagFormAnswerReset());
    // dispatch(dashboardYaag());
    // dispatch(dashboardReward(userid));
    // dispatch(yaagReward(userid));
    // dispatch(myImpact(userid));
    // dispatch(userProfile(userid));
    // dispatch(myCommunity(userid));
    temp.push(localStorage.getItem("userid"));
  }, [myCampaignList, myredeemedrewards, marketplacelist]);

  // React.useEffect(() => {
  //   dispatch(userProfile(userid));
  //   // dispatch(getVolunteerCount());
  //   // getVolunteerCamp();
  //   // dispatch(getCampaign(profileResponse.orgrole, profileResponse.organization));

  // }, [profileResponse]);
  const userid = localStorage.getItem("userid");

  React.useEffect(() => {
    dispatch(userProfile(userid));
  }, [reLoading]);

  const refferalId = localStorage.getItem("refferalId");

  // console.log("calling useeffect 2")
  useEffect(() => {
    getCampainUpdateList();
  }, [reLoading]);

  useEffect(() => {
    getMyRedeemedRewards();
  }, []);

  useEffect(() => {
    //if (profileSuccess) {
    getMyCommunityDetails().then((data) => {
      setmycommunity(data);
    });
    // } else {
    setLoading(false);
    //  }
  }, [reLoading]);

  // console.log(mycommunity);

  useEffect(() => {
    getSuccessStories().then((data) => {
      setSuccessStories(data);
    });
  }, []);

  useEffect(() => {
    // setLoading(false);
    getRecommendedCampDetails();
    // setLoading(true);
  }, [reLoading]);

  useEffect(() => {
    getCampStatusCount();
  }, []);

  useEffect(() => {
    getSDGCampMapping();
  }, [reLoading]);

  // const [loading, setLoading] = React.useState(false);
  const getCampainUpdateList = () => {
    dispatch(userProfile(userid));
    dispatch(getVolunteerCount());
    Promise.all([getProfileInfo()]).then((data) =>
      getVolunteerCampDetails(data)
    );
    dispatch(
      getCampaign(profileResponse.orgrole, profileResponse.organization)
    );
  };
  const getProfileInfo = async () => {
    try {
      const res = await axios.get(`cyaaguser/${userid}`);

      // setMyCampaignList(res?.data);
      //console.log("hhh",res?.data)

      return res;
      // }
    } catch (err) {
      throw err;
    }
  };

  const getVolunteerCampDetails = async (dd) => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `/campaign/all/open/${dd[0].data?.orgrole}/${dd[0].data?.organization}/${dd[0].data?.phoneno}`
      );

      setMyCampaignList(res);
      setNewValue(dd[0]?.data);
      // getRewardsFromMarketplace();
      setLoading(true);
      setIsLoading(false);
      // setMyCampaignList(res?.data);

      return res;
      // }
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
  };

  // console.log(myCampaignList?.[0]);
  console.log("campStatusCount = ", campStatusCount);
  console.log("campStatusCount = ", campStatusCount?.data?.[2].donecount);

  const getMyReward = async () => {
    try {
      const res = await axios.get(`/vol_dashboard/myreward/${userid}`);
      setRewardDash(res?.data);
      dispatch(volDashBoardReward(res?.data, res?.data?.[0]?.phoneno));
      return res;
    } catch (err) {
      throw err;
    }
  };
  const getRewardsFromMarketplace = async () => {
    try {
      //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);
      const res = await axios.get(`/marketplace/read/${userid}`);
      setmarketplacelist(res?.data);
      // console.log(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getMyCommunityDetails = async () => {
    try {
      //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);

      const res = await axios.get(`adopter_dashboard/mycommunity/${userid}`);
      // console.log(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getMyRedeemedRewards = async () => {
    try {
      //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);

      const res = await axios.get(`reward/redeemed/${userid}`);
      setmyredeemedrewards(res);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getSuccessStories = async () => {
    try {
      const res = await axios.get(`/success/stories`);
      // console.log(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getRecommendedCampDetails = async () => {
    try {
      const res = await axios
        .get(`recommendation/${userid}`)
        .then((response) => {
          // console.log(response?.data);
          setMyRecommendedCampList(response);
          return response;
        });

      // setMyCampaignList(res?.data);
      // console.log(res)
      // return res;
      // }
    } catch (err) {
      throw err;
    }
  };

  const getCampStatusCount = async () => {
    try {
      const res = await axios
        .get(
          `camp/count/${newValue?.orgrole}/${newValue?.organization}/${userid}`
        )
        .then((response) => {
          // console.log(response?.data);
          setCampStatusCount(response);
          return response;
        });

      // setMyCampaignList(res?.data);
      // console.log(res)
      // return res;
      // }
    } catch (err) {
      throw err;
    }
  };

  const getSDGCampMapping = async () => {
    try {
      const res = await axios.get(`camp/sdg/mapping`);
      // setActivityList(res?.data);
      setSDGCampMapping(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const handleRedeem = async () => {
    // setDisplayConfirmationModal(true);

    const payload = {
      //campid: locationPath?.state?.campid,
      // createdate: locationPath?.state?.createdate,
      // reviewstatus: reviewstatus,
      // reviewcomments: reviewComments,
      phoneno: localStorage.getItem("userid"),
      rewardtype: marketplacelist?.data?.[redeemindex]?.rewardtype,
      mpid: marketplacelist?.data?.[redeemindex]?.mpid,
      campid: marketplacelist?.data?.[redeemindex]?.campid,
      title: marketplacelist?.data?.[redeemindex]?.title,
      subtitle: marketplacelist?.data?.[redeemindex]?.subtitle,
      points: marketplacelist?.data?.[redeemindex]?.points,
      // sponsor: JSON.stringify(marketplacelist?.data?.[redeemindex]?.sponsor),
      sponsor: marketplacelist?.data?.[redeemindex]?.sponsor,

      // phoneno: locationPath?.state.phoneno,
    };
    try {
      const res = await axios.post(
        `/reward/redeem/${profileResponse?.phoneno}`,
        payload
      );
      // navigate("/reviewcampaign");
      setAfterRedeem(res?.data);
      // console.log(res?.data?.message);
      // alert("calling set display false")
      setDisplayRedeemConfirmationModal(false);
      if (res?.data?.message === "Reward successfully redeemed") {
        setPostRedeemDisplayModal(true);
        // navigate("/hacampscreen");
      } else {
        setDisplayConfirmationModal(true);
      }
      return res;
    } catch (err) {
      throw err;
    }
  };

  // Hide the modal
  const hideRedeemConfirmationModal = () => {
    setDisplayRedeemConfirmationModal(false);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handlePostRedeemModal = () => {
    setPostRedeemDisplayModal(false);
    navigate("/marketplace");
  };

  const options = {
    items: 3,
    margin: 10,
    loop: false,
    nav: false,
    autoWidth: false,
    autoplaySpeed: 900,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      566: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const optionsTwo = {
    items: 2,
    margin: 0,
    loop: false,
    nav: true,
    autoplaySpeed: 900,
    autoplay: false,
    dots: false,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const optionsFour = {
    items: 4,
    margin: 10,
    loop: true,
    nav: false,
    autoplaySpeed: 900,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  const subscribeAction = async (campIdVal, subscribeVal) => {
    setModalData({ campIdValue: campIdVal, subscribeValue: subscribeVal });

    if (subscribeVal === "subscribe") {
      setShowModal(true);
      return false;
    } else {
      subscribeNow(campIdVal, subscribeVal);
    }
  };

  const closeModalWindow = () => {
    setShowModal(false);
  };

  const subscribeNow = async (campIdVal, subscribeVal) => {
    try {
      console.log(
        `${
          subscribeVal === "subscribe" ? "unsubscribe" : "subscribe"
        }/camp/${campIdVal}/${userid}`
      );
      const res = await axios
        .put(
          `${
            subscribeVal === "subscribe" ? "unsubscribe" : "subscribe"
          }/camp/${campIdVal}/${userid}`
        )
        .then((response) => {
          return response;
        });
      setShowModal(false);
      getCampainUpdateList();
    } catch (err) {
      throw err;
    }
  };

  const splitString = (val) => {
    if (val) {
      // const vallArray = val.split(".");
      const vallArray = val.split(";");

      return (
        <ul>
          {vallArray.map((bulletVal, index) => (
            <li>
              <p>{vallArray[index]}.</p>
            </li>
          ))}
        </ul>
      );
    }
  };

  const splitString1 = (val) => {
    if (val) {
      const parts = val.split(/(::)|(,,)|(--)|;/).filter(Boolean);
      let inUnderlinedSection = false;
      let inBoldSection = false;
      let skipLineBreak = true;
      let inHighlightTextSection = false;

      return (
        <ul>
          {parts.map((bulletVal, index) => {
            if (bulletVal === "::") {
              inUnderlinedSection = !inUnderlinedSection;
              skipLineBreak = true;
              return null;
            } else if (bulletVal === ",,") {
              inBoldSection = !inBoldSection;
              skipLineBreak = true;
              return null;
            } else if (bulletVal === "--") {
              inHighlightTextSection = !inHighlightTextSection;
              skipLineBreak = true;
              return null;
            }

            if (inUnderlinedSection) {
              return (
                <li key={index}>
                  <span className="underlined-text">{bulletVal}</span>
                </li>
              );
            } else if (inBoldSection) {
              return (
                <li key={index}>
                  {!skipLineBreak && <br />}
                  <span className="bold-text">{bulletVal}</span>
                </li>
              );
            } else if (inHighlightTextSection) {
              return (
                <li key={index}>
                  {!skipLineBreak && <br />}
                  <span className="highlight-text">{bulletVal}</span>
                </li>
              );
            } else if (bulletVal.trim() !== "") {
              const bulletPoints = bulletVal
                .split(";")
                .map((point) => point.trim());

              return (
                <li key={index}>
                  {bulletPoints.map((point, subIndex) => {
                    const shouldBreakLine = subIndex > 0;
                    if (shouldBreakLine) {
                      skipLineBreak = false;
                      return (
                        <React.Fragment key={`${index}-${subIndex}`}>
                          <br />
                          <p>{point}</p>
                        </React.Fragment>
                      );
                    }
                    return (
                      <p key={`${index}-${subIndex}`} style={{ marginTop: 0 }}>
                        {point}
                      </p>
                    );
                  })}
                </li>
              );
            }
            return null;
          })}
        </ul>
      );
    }
  };

  const isDesktop = useMediaQuery("(min-width:500px)");

  return (
    <Card className={classes.homecard} onClick={props.onClick}>
      <div className="wrapper">
        <ResponsiveDrawer {...props} />
        <section className="containers">
          <TopHeaderScreen {...props} />
          <div class="content content-wrap">
            <div class="content-mrg hasScreen">
              <Button onClick={() => navigate(-1)} className={classes.backBtn}>
                <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
              </Button>
              <div class="content content-pad-0">
                <div class="row">
                  <div class="col-lg-12">
                    <section id="tabs" class="project-tab">
                      <div
                        class="container-fluids"
                        style={{ maxWidth: "1440px" }}
                      >
                        <div class="cyagg-camp-space campaigns-tabs">
                          <div>
                            <div class="row">
                              <div class="col-12">
                                <div>
                                  <h4 class="site-title">Campaigns</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                          {isLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
  <Circles
    type="TailSpin"
    color="rgb(155, 236, 34)"
    height={70}
    width={70}
    timeout={5000}
    ariaLabel="three-circles-rotating"
  />
</div>

                          ) : (
                            <div class="widget-content animated-underline-content">
                              <div
                                class="tab-content customOwlNav"
                                id="animateLineContent-4"
                              >
                                <div
                                  class="tab-pane fade show active"
                                  id="animated-underline-home"
                                  role="tabpanel"
                                  aria-labelledby="animated-underline-home-tab"
                                >
                                  {campStatusCount &&
                                  campStatusCount?.data?.[0]?.activecount ===
                                    0 ? (
                                    <span>
                                      <i>
                                        <b>
                                          Please keep watching this space for
                                          new campaigns. You will also be
                                          notified here.
                                        </b>
                                      </i>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  <div className="subscribeCampaignList row">
                                    {myCampaignList &&
                                      myCampaignList?.data
                                        ?.filter(
                                          (item, index) =>
                                            item.active === "Active" ||
                                            item.active === "Upcoming"
                                        )
                                        .map((tile, index) => (
                                          <div class="item col-md-4 col-sm-6 col-xs-12">
                                            <div class="">
                                              <div class="campaigns-wrap campaigns-wrap-new">
                                                <div>
                                                  <div>
                                                    <div class="campaign-img">
                                                      <div class="video-wrapper">
                                                        <div
                                                          class="video-container"
                                                          id="video-container"
                                                        >
                                                          <video
                                                            controls
                                                            controlsList="nodownload"
                                                            id="video"
                                                            // preload="metadata"
                                                            preload="auto"
                                                            // poster="//cdn.jsdelivr.net/npm/big-buck-bunny-1080p@0.0.6/poster.jpg"
                                                            poster={tile?.logo}
                                                            autoplay
                                                          >
                                                            <source
                                                              // src="//cdn.jsdelivr.net/npm/big-buck-bunny-1080p@0.0.6/video.mp4"
                                                              src={tile?.video}
                                                              type="video/mp4"
                                                              display="none"
                                                            />
                                                          </video>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <h5
                                                      title={tile?.name}
                                                      class="campaign-title-new"
                                                    >
                                                      {tile?.name}
                                                    </h5>
                                                    <div class="campaign-hashtags">
                                                      {tile.tag.map((tag) =>
                                                        SDGCampMapping?.map(
                                                          (item) =>
                                                            item?.cat ===
                                                              tag?.label && (
                                                              <a
                                                                class="hash-styles"
                                                                style={{
                                                                  cursor:
                                                                    "initial",
                                                                }}
                                                              >
                                                                {tag?.tag}{" "}
                                                                <a
                                                                  class="hash-styles-sdg"
                                                                  style={{
                                                                    cursor:
                                                                      "initial",
                                                                  }}
                                                                >
                                                                  SDG{" "}
                                                                  {item?.sdg}
                                                                </a>
                                                              </a>
                                                            )
                                                        )
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div
                                                    className={
                                                      classes.campaigntypewrapper
                                                    }
                                                  >
                                                    <Stack
                                                      direction="row"
                                                      alignItems="center"
                                                      justifyContent="space-between"
                                                      gap={0.5}
                                                    >
                                                      <h5 class="campaign-goal">
                                                        <a
                                                          class="hash-styles-goal"
                                                          style={{
                                                            padding:
                                                              "0px 5px 0px 0px",
                                                            cursor: "initial",
                                                          }}
                                                        >
                                                          {/* Need {tile.targetvol}{" "}
                                                          changemaker(s) */}
                                                          <span style={{ fontSize: "16px", fontWeight: 700 }}>Goal</span>: <span class="text-6" style={{ color: "black" }}>{tile.goalquantity}{" "} {tile.unitofgoal}</span>
                                                        </a>
                                                        
                                                      </h5>
                                                    </Stack>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col-md-12">
                                                    <div>
                                                      <div class="campaigns-details">


                                                      <div class="p-bar-new">
                                                        <div class="row ">
                                                          <div class="col-6">
                                                            <div>
                                                              <h6>
                                                                <span style={{ color: "#AAAAAA" }}>Pledged: </span>{" "} <span style={{ backgroundColor: "#FFDB58" }}>
                                                                  {Math.round(
                                                                    (tile.committedcount)
                                                                  )}
                                                                </span>
                                                              </h6>
                                                            </div>
                                                          </div>
                                                          <div class="col-6">
                                                            <div
                                                              style={{
                                                                textAlign:
                                                                  "right",
                                                              }}
                                                            >
                                                              <h6>
                                                                <span style={{ color: "#AAAAAA" }}>Achieved: </span>
                                                                {" "}
                                                                <span style={{ backgroundColor: "#A9D18E" }}> {tile.achievedcount}</span>
                                                                {" "}
                                                              </h6>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="progress br-30 campaign-progress" style={{ position: "relative" }}>
                                                          <div
                                                            className="progress-bar"
                                                            role="progressbar"
                                                            style={{
                                                              width: `${(tile.committedcount / tile.goalquantity) * 100}%`,
                                                              backgroundColor: "#FFDB58",
                                                              position: "absolute",
                                                              top: 0,
                                                              left: 0,
                                                              zIndex: 1,
                                                              height: "100%"
                                                            }}
                                                            aria-valuenow={Math.round((tile.committedcount / tile.goalquantity) * 100)}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                          ></div>

                                                          <div
                                                            className="progress-bar"
                                                            role="progressbar"
                                                            style={{
                                                              width: `${(tile.achievedcount / tile.goalquantity) * 100}%`,
                                                              backgroundColor: "#A9D18E",
                                                              position: "absolute",
                                                              top: 0,
                                                              left: 0,
                                                              zIndex: 2,
                                                              height: "100%"
                                                            }}
                                                            aria-valuenow={Math.round((tile.achievedcount / tile.goalquantity) * 100)}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                          ></div>
                                                        </div>
                                                      </div>

                                                      <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                                        <div style={{ flex: 1 }}>
                                                          <h6>
                                                            <span style={{ color: "#AAAAAA" }}>Signed Up:</span>{" "}
                                                            <span style={{ backgroundColor: "#DAE8FD" }}>{tile.submittedcount}</span>
                                                          </h6>
                                                        </div>
                                                        <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                          <Chip
                                                            key={tile.campaigntype}
                                                            fontSize="small"
                                                            size="small"
                                                            label={tile.campaigntype}
                                                          />
                                                          
                                                        </div>
                                                      </div>




                                                        <div class="row" style={{marginTop:"10px"}}>
                                                          <div class="col-md-4 col-6 customHalfWidth">
                                                            <div class="cam-cols">
                                                              <div class="timer-here">
                                                                <div class="text-center">
                                                                  <span class="campaign-time-title">
                                                                    Earn upto
                                                                  </span>
                                                                </div>
                                                                <div>
                                                                  <ul id="countdown">
                                                                    <li id="hours">
                                                                      <div class="numberhome"></div>
                                                                      <div class="label"></div>
                                                                    </li>
                                                                    <li id="minutes">
                                                                      <div
                                                                        class="numberhome"
                                                                        style={{
                                                                          padding:
                                                                            "0px 0px 0px 6px",
                                                                        }}
                                                                      >
                                                                        {
                                                                          tile.maxpointstobeearned
                                                                        }
                                                                      </div>
                                                                      <div
                                                                        class="label"
                                                                        style={{
                                                                          padding:
                                                                            "0px 0px 0px 12px",
                                                                        }}
                                                                      >
                                                                        Points
                                                                      </div>
                                                                    </li>
                                                                    <li id="seconds">
                                                                      <div class="numberhome"></div>
                                                                      <div class="label"></div>
                                                                    </li>
                                                                  </ul>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div class="col-md-4 col-6 customHalfWidth">
                                                            <div class="cam-cols">
                                                              <div class="timer-here">
                                                                <div class="text-center">
                                                                  <span class="campaign-time-title" style={{margin:"0px 0px"}}>
                                                                    Time Left
                                                                  </span>
                                                                </div>
                                                                <div>
                                                                  <ul id="countdown">
                                                                    <li id="hours">
                                                                      <div class="number">
                                                                        {
                                                                          tile.dayrem
                                                                        }
                                                                      </div>
                                                                      <div class="label">
                                                                        Days
                                                                      </div>
                                                                    </li>
                                                                    <li id="minutes">
                                                                      <div class="number">
                                                                        {
                                                                          tile.hourrem
                                                                        }
                                                                      </div>
                                                                      <div class="label">
                                                                        Hours
                                                                      </div>
                                                                    </li>
                                                                    <li id="seconds">
                                                                      <div class="number">
                                                                        {
                                                                          tile.minrem
                                                                        }
                                                                      </div>
                                                                      <div class="label">
                                                                        Mins
                                                                      </div>
                                                                    </li>
                                                                  </ul>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div
                                                            class="col-md-4 customFullWidth"
                                                            style={{
                                                              paddingLeft:
                                                                "0px",
                                                            }}
                                                          >
                                                            <div class="par-btn-here">
                                                              <div class="text-center">
                                                                <a class="par-now-new-subscribe">
                                                                  {/* {(campaignList?.data?.[0]?.active === "Active") && ( */}
                                                                  <Button
                                                                    className={
                                                                      tile.issubscribed ===
                                                                      "subscribe"
                                                                        ? "unsubscribe"
                                                                        : "subscribe"
                                                                    }
                                                                    onClick={() =>
                                                                      subscribeAction(
                                                                        tile.campid,
                                                                        tile.issubscribed
                                                                      )
                                                                    }
                                                                    style={{
                                                                      color:
                                                                        "white",
                                                                      // padding:"0px 0px 0px 0px",
                                                                      padding:
                                                                        "6px",
                                                                      fontSize:
                                                                        "12px",
                                                                    }}
                                                                    name={
                                                                      "activepnow" +
                                                                      tile.campid
                                                                    }
                                                                  >
                                                                    {tile.issubscribed ===
                                                                    "subscribe"
                                                                      ? "unsubscribe"
                                                                      : "subscribe"}
                                                                    {tile.issubscribed ===
                                                                    "subscribe" ? (
                                                                      <i
                                                                        class="fa fa-bell fa-bell-slash"
                                                                        aria-hidden="true"
                                                                        style={{
                                                                          marginLeft:
                                                                            "5px",
                                                                        }}
                                                                      ></i>
                                                                    ) : (
                                                                      <i
                                                                        class="fa fa-bell"
                                                                        aria-hidden="true"
                                                                        style={{
                                                                          marginLeft:
                                                                            "5px",
                                                                        }}
                                                                      ></i>
                                                                    )}
                                                                  </Button>
                                                                  {/* )} */}
                                                                </a>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="mr-15 text-center">
                                                          <a class="campaign-signin">
                                                            Join Now{" "}
                                                            <i
                                                              class="fa fa-arrow-right"
                                                              aria-hidden="true"
                                                            ></i>
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        ))}

                                    {/* Box model for confirmation message for unsubscribte */}

                                    <Modal
                                      show={showModal}
                                      onHide={() => closeModalWindow()}
                                    >
                                      <Modal.Header>
                                        <Modal.Title>Confirmation</Modal.Title>
                                        <button
                                          type="button"
                                          onClick={() => closeModalWindow()}
                                          class="close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <span aria-hidden="true">×</span>
                                        </button>
                                      </Modal.Header>
                                      <Modal.Body>
                                        <p>
                                          Are you sure you want to unsubscribe
                                          this campaign??
                                        </p>
                                      </Modal.Body>
                                      <Modal.Footer>
                                        <Button
                                          variant="secondary"
                                          style={{
                                            backgroundColor: "#db8d0f",
                                            color: "#fff",
                                          }}
                                          onClick={() => closeModalWindow()}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          variant="secondary"
                                          style={{
                                            backgroundColor: "#db8d0f",
                                            color: "#fff",
                                          }}
                                          onClick={() =>
                                            subscribeNow(
                                              modalData.campIdValue,
                                              modalData.subscribeValue
                                            )
                                          }
                                        >
                                          Unsubscribe
                                        </Button>
                                      </Modal.Footer>
                                    </Modal>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </section>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CopyRight />
          <FixedFooterIcon />
          <RedeemConfirmation
            showModal={displayRedeemConfirmationModal}
            confirmModal={handleRedeem}
            hideModal={hideRedeemConfirmationModal}
            message={"Are you sure you want to redeem this reward?"}
          />
          <ConfirmationDialog
            showModal={displayConfirmationModal}
            // confirmModal={handleRedeem}
            hideModal={hideConfirmationModal}
            message={"You do not have enough points to redeem"}
          />
          <PostRedeemConfirmation
            showModal={postRedeemDisplayModal}
            confirmModal={handlePostRedeemModal}
            // hideModal={handlePostRedeemModal}
            message={"You have successfully redeemed"}
          />
        </section>
        {/* <div style={{marginTop: 500}}>
        <CopyRight companyName={"cYAAG"} />
      </div> */}
      </div>
    </Card>
  );
};

export default SubscriptionListScreen;
