import * as React from "react";
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import {
  Avatar,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";

import "react-datepicker/dist/react-datepicker.css";

import {
  onboardRecognitionsReset,
  onboardRecognitions,
} from "../../redux/action/onboardRecognitionsAction";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";
import {
  AddCircleOutlineOutlined,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";
import ErrorMessage from "../../components/ErrorMessage";
import _ from "lodash";
import RedStar from "../../components/RedStar";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useNavigate } from "react-router-dom";
import { getRewardTitle } from "../../redux/action/RewardDetailAction";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import AsyncSelect from "react-select/async";
import ConfirmationDialog from "../../components/ConfirmationDialog";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },

    display: "block",
    display: "flex",
    //fontSize: 16,
    fontSize: "16px",
    marginTop: 16,
    padding: 10,
    fontWeight: "500",
    lineHeight: "18px",
    minHeight: 50,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {},

    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroupFull: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      // width: "25%"
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
}));

function OnboardRecognitions(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rewardtitle, setRewardtitle] = React.useState([
    {
      
      rewardtype: "",
      title: "",
      subtitle: "",
      
    },
  ]);
  const [selected1, setSelected1] = React.useState([]);
  const [sponsor, setSponsor] = React.useState([]);
  const [focusError, setFocusError] = React.useState(false);
  const [newField, setNewField] = React.useState(false);
  const [newRewardField, setNewRewardField] = React.useState(false);
  const [areas, setAreas] = React.useState([]);
  const [cityResponse, setCityResponse] = React.useState({});
  const [rewardTypeList, setRewardTypeList] = React.useState(null);
  const [rewardChoice, setRewardChoice] = React.useState([
    {
      choice: "Yes",
    },
    {
      choice: "No",
    },
  ]);
  const [rewardDetailResponseEffect, setrewardDetailResponseEffect] =
    React.useState({});
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const [displayConfirmationModal, setDisplayConfirmationModal] =  React.useState(false);
  const [addRewardsValues, setaddRewardsValues] = React.useState({
    rewardtype: "",
    title: "",
    subtitle: "",
    sponsor: "",
    points: "",
  });
  const [addRewardsValuesError, setaddRewardsValuesError] = React.useState({
    rewardtypeError: "",
    titleError: "",
    subtitleError: "",
    sponsorError: "",
    pointsError: "",
  });
  const [campName, setCampName] = React.useState([]);
  const [selectedcampname, setselectedcampname] = React.useState([]);

  const {
    titleSuccess: rewardDetailSuccess,
    titleResponse: rewardDetailResponse,
  } = useSelector((state) => state.getRewardTitle);
 
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };
  const {
    onboardRecognitionsSuccess: isOnboardRewardsSuccess,
    onboardRecognitionsResponse: onboardRewardsResponse,
    onboardRecognitionsError: isAddRewardsError,
    onboardRecognitionsFetching: isOnboardRewardsFetching,
  } = useSelector((state) => state.onboardRecognitions);
  

  const handleChangeRewards1 = (id, event, field, index) => {
  
    // const value = event.target.value;
    const value = [...rewardtitle];
    if (field === "rewardtype") {
      value[index]["titleList"] = rewardDetailResponseEffect;
    }
    if (field === "sponsor") {
      value[index][field] = event;
    } else {
      value[index][field] = event.target.value;
    }
    setRewardtitle(value);
    

    // setSelected([...selected, value[value?.length - 1]]);
  };

  const handleAddRewards = () => {
    
 
    // console.log(rewardtypeError);
    // console.log(titleError);
    // console.log(subtitleError);
    // console.log(sponsorError);
    // console.log(pointsError);
    // if (addRewardsValues.rewardtype) {
    //   setaddRewardsValuesError({
    //     ...addRewardsValuesError,
    //     rewardtypeError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else if (!addRewardsValues?.subtitle) {
    //   setaddRewardsValuesError({
    //     ...addRewardsValuesError,
    //     subtitleError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else if (!addRewardsValues?.sponsor) {
    //   setaddRewardsValuesError({
    //     ...addRewardsValuesError,
    //     sponsorError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else if (!addRewardsValues?.points) {
    //   setaddRewardsValuesError({
    //     ...addRewardsValuesError,
    //     pointsError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else {
      
    dispatch(onboardRecognitionsReset());
    let formData = new FormData();
    formData.append("rewardtitle", JSON.stringify(rewardtitle));
    dispatch(
      // onboardCampaign({ userid: localStorage.getItem("userid"), formData })
      onboardRecognitions({ userid: localStorage.getItem("userid"), formData })
    );
    setRewardtitle([
      {
        rewardtype: "",
        title: "",
        subtitle: "",
       
      },
    ]);
    setDisplayConfirmationModal(true);
    //  }
  };

  const getRewardDetailList = async (i, valll) => {
    try {
      const res = await axios
        .get(`/getrewardtitle/${valll}`)
        .then((response) => {
          const values = [...rewardtitle];
          values[i]["titleList"] = response?.data;
          return response?.data;
        });
    } catch (err) {
      throw err;
    }
  };

  const getSponsorList = async () => {
    try {
      const res = await axios.get(`/sponsor`).then((response) => {
        // const values = [...rewardtitle];

        setSponsor(response?.data);
        return response?.data;
      });
    } catch (err) {
      throw err;
    }
  };

 

  const getRewardTypeList = async () => {
    try {
      const res = await axios.get(`getrewardtypes`);
      setRewardTypeList(res?.data);
    
      return res;
      // }
    } catch (err) {
      throw err;
    }
  };

  const handleRewardAddFields = () => {
    // setNewRewardField(true);
    if (rewardtitle.length > 0) {
  
      for (let i = 0; i < rewardtitle.length; i++) {
        if ( !(rewardtitle?.[rewardtitle.length - 1].rewardtype === "") && 
          !(rewardtitle?.[rewardtitle.length - 1].title === "") &&
          !(rewardtitle?.[rewardtitle.length - 1].subtitle === "")) {
            setRewardtitle([...rewardtitle, {  
              rewardtype: "",
              title: "",
              subtitle: ""
            }]);
        } 
      }
    }
  };

  const handleRemoveFields = (i) => {
    let newFormValues = [...rewardtitle];
    newFormValues.splice(i, 1);
    setRewardtitle(newFormValues);
  };


  const loadOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = campName.filter((category) =>
        category.label.toLowerCase().includes(searchValue.toLowerCase())
      );
  
      callback(filteredOptions);
    });
  };

  React.useEffect(() => {
    getCampName();
  }, []);

  const getCampName = async () => {
    try {
      const res = await axios.get(`/camp/name/${profileResponse?.phoneno}`);
      setCampName(res?.data);

      return res;
    } catch (err) {
      throw err;
    }
  };

  const colorStyles = {
    control: (styles) => ({ ...styles }),
    // option: (styles, {data, isDisabled, isFocussed, isSelected}) => {
    //   return{...styles, color: "blue"};
    // },
    multiValue: (styles, { data }) => {
      return { ...styles, backgroundColor: "#0096FF", color: "#fff" };
    },
    multiValueLabel: (styles, { data }) => {
      return { ...styles, color: "#fff" };
    },
    multiValueRemove: (styles, { data }) => {
      return {
        ...styles,
        color: "#fff",
        cursor: "pointer",
        ":hover": {
          color: "#fff",
        },
      };
    },
  };

  const getRewardDetails = (itemreward, index) => {
    return (
      <div style={{ float: "left", width: "75%", marginTop: 10 }}>
        
        <form
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            float: "left",
          }}
        >
          

          <div className={classes.formGroup1}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Reward Type <RedStar />
            </label>
            <input
              style={{
                // display: "flex",
                // flexDirection: "row",
                // alignItems: "flex-end",
                width: "100%",
                marginLeft:"2px"
              }}
              value={itemreward.rewardtype}
              onBlur={() => {
                if (!itemreward.rewardtype) {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    rewardtypeError: true,
                  });
                } else {
                  setaddRewardsValuesError({
                    ...addRewardsValuesError,
                    rewardtypeError: false,
                  });
                }
              }}
              onChange={(e) => {
                handleChangeRewards1(itemreward, e, "rewardtype", index);
                // dispatch(getRewardTitle(item.rewards));
               
                getRewardDetailList(index, itemreward.rewardtype);
                dispatch(getRewardTitle(itemreward.rewardtype));
                
              }}
            />
              
            {addRewardsValuesError?.rewardtypeError && (
              <ErrorMessage message="Please enter the reward type for this reward" />
            )}
          </div>
          
            <div className={classes.formGroup1}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Reward Title <RedStar />
              </label>
              <input
                style={{
                  // display: "flex",
                  // flexDirection: "row",
                  // alignItems: "flex-end",
                  width: "100%",
                  marginLeft:"2px"
                }}
                // value={itemreward?.titlelist?.[0].title}
                value={itemreward.title}
                onBlur={() => {
                  if (!itemreward.title) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      titleError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      titleError: false,
                    });
                  }
                }}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "title", index);
                }}
              />
              {addRewardsValuesError?.titleError && (
                <ErrorMessage message="Please enter the title for this reward" />
              )}
            </div>
          

          
            <div className={classes.formGroup1}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Reward Sub Title <RedStar />
              </label>
              <input
                // style={{
                //   width: "50%",
                //   fontSize: 14,
                //   height: 56,
                //   boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                // }}

                style={{
                  // display: "flex",
                  // flexDirection: "row",
                  // alignItems: "flex-end",
                  width: "100%",
                  marginLeft:"2px"
                }}
                // value={itemreward?.titlelist?.[0].subtitle}
                // value={itemreward.subtitle}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "subtitle", index);
                }}
                onBlur={() => {
                  if (!itemreward.subtitle) {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      subtitleError: true,
                    });
                  } else {
                    setaddRewardsValuesError({
                      ...addRewardsValuesError,
                      subtitleError: false,
                    });
                  }
                }}
              />
                
              {addRewardsValuesError?.subtitleError && (
                <ErrorMessage message="Please enter the subtitle for this reward" />
              )}
            </div>
        </form>
        {/* {index ? (
          <div
            style={{
              float: "right",
              position: "relative",
              bottom: 44,
              cursor: "pointer",
              left: 19,
            }}
            onClick={(e) => handleRemoveFields(index)}
          >
            <RemoveCircleOutlineOutlined></RemoveCircleOutlineOutlined>
          </div>
        ) : (
          <div
            className="handlePlus"
            style={{
              float: "right",
              bottom: 45,
              position: "relative",
              left: 20,
              cursor: "pointer",
              right: "15px",
            }}
          >
            <AddCircleOutlineOutlined
              onClick={handleRewardAddFields}
            ></AddCircleOutlineOutlined>
          </div>
        )} */}
      </div>
    );
  };

  React.useEffect(() => {
    setRewardtitle([
      {
        rewardtype: "",
        title: "",
        subtitle: "",
       
      },
    ]);
  }, []);

  React.useEffect(() => {
    getRewardTypeList();
  }, []);
 

  React.useEffect(() => {
    getSponsorList();
  }, []);

  

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <div
              class="content-title"
              style={{
                fontSize: 25,
                fontWeight: "600",
                marginBottom: 15,
                marginTop: 5,
              }}
            >
              Onboard Recognition
            </div>
            <div style={{ fontFamily: "monospace", fontSize: 16 }}>
              <div
                style={{
                  display: "flex",
                  width: "75%",
                  justifyContent: "flex-end",
                  marginRight: 5,
                  marginBottom: 20,
                }}
              >
                <span style={{ color: "red" }}>
                  Please do not leave this page until you submit as you may risk
                  losing the partially filled in form{" "}
                </span>
              </div>
            </div>
            <form autoComplete="off">
              <div
                style={{
                  border: "1px solid #dadce0",
                  borderRadius: 8,
                  backgroundColor: "#fff",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <div className={classes.formGroupFull}>
                  <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>
                Reward Details
              </span>{" "}
              {/* <span
                style={{
                  paddingLeft: 0,
                  fontSize: 16,
                  fontWeight: 300,
                  fontStyle: "italic",
                }}
              >
                {" "}
                (click on "+" icon to enter additional new rewards)
              </span> */}
                    {rewardtitle.map((itemreward, index) => (
                      <div key={index}>
                        {getRewardDetails(itemreward, index)}
                      </div>
                    ))}
                    {/* <AddCircleOutlineOutlined
                  formStyle={{ paddingTop: "20px" }}
                  onClick={handleAddFields}
                ></AddCircleOutlineOutlined> */}
                  </div>

                  
                </div>
                <div>
                  {/* {isOnboardRewardsSuccess && (
                    <span
                      style={{
                        color: "green",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      {onboardRewardsResponse?.message}
                    </span>
                  )} */}
                  {/* {isAddRewardsError && (
                    // <span style={{ color: "red" }}>Error occured</span>
                    <span
                      style={{
                        color: "red",
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      {onboardRewardsResponse?.message}
                    </span>
                  )} */}
                </div>
                <div
                  className="centerAlign"
                  style={{ padding: "16px 0", marginBottom: 10 }}
                >
                  <Button
                    variant="contained"
                    onClick={handleAddRewards}
                    className="btn save-btn"
                    disableElevation
                    disabled={isOnboardRewardsFetching}
                  >
                    {isOnboardRewardsFetching ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      <span>Submit</span>
                    )}
                  </Button>
                  <br />
                  {/* {isCampaignSuccess && 
                  <span style={{ color: "green", textAlign: "center", display: "block" }}>{campaignResponse?.message}</span>}
                  {isCampaignError && (
                    // <span style={{ color: "red" }}>Error occured</span>
                    <span style={{ color: "red", textAlign: "center", display: "block" }}>{campaignResponse?.message}</span>
                  )} */}
                </div>
              </div>
            </form>
          </div>
        </div>
        <CopyRight />
        <FixedFooterIcon />
        <ConfirmationDialog
          showModal={displayConfirmationModal}
          // confirmModal={handleRedeem}
          hideModal={hideConfirmationModal}
          // message={"You do not have enough points to redeem"}
          message={onboardRewardsResponse?.message}
        />
      </section>
    </div>
  );
}
export default OnboardRecognitions;
