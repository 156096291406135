import * as React from "react";
import FormControl from "@mui/material/FormControl";
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { Button, CircularProgress, MenuItem, Select, Avatar , TextField} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import Multiselect from 'multiselect-react-dropdown';
// import {
//   onboardActivityReset,
//   onboardBulkReset,
//   onboardCampaignReset,
//   onboardIndividualReset,
//   onboardOrganization,
//   onboardOrganizationReset,
//     updateOrgReset
// } from "../../redux/action/UpdateAction";
import CallingCode from "../../services/CallingCode";
import { fetchCityAndState } from "../../services/commonServices";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import {
  focusAreaDict,
  registeredAs,
  restrictedCountry,
} from "../../utils/dict";
import { getOrganizationReset } from "../../redux/action/getOrganization";
import { MenuProps, states } from "../../utils/constants";
import { AddCircleOutlineOutlined, RemoveCircleOutlineOutlined } from "@material-ui/icons";
import { getCity } from "../../redux/action/cityAction";
import _ from "lodash";
import ErrorMessage from "../../components/ErrorMessage";
import RedStar from "../../components/RedStar";
import BasicPopover from "../../components/InformationPopup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { height } from "@mui/system";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useLocation, useNavigate} from "react-router-dom";
import {
    // updateOrg,
    // updateOrgReset,
    updateSponsor,
    updateSponsorReset,
  } from "../../redux/action/UpdateAction";
import IconButton from "@mui/material/IconButton";
import { getOrgProfileLogo } from "../../redux/action/orgLogoAction";
import axios from "axios";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import DoneIcon from '@mui/icons-material/Done';


const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    // color: "#949494",
    color: "#black",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
  },
  logobutton: {
    width: "15%",
    border: "none",
    padding: 10,
    // display: "block",
    display: "flex",
    // borderRadius: 6,
    height: 40,
    // boxShadow: "0px 3px 6px #00000029",
    color: "#949494",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
  },
  large: {
    width: "150px !important",
    height: "150px !important",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",

  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
    },
    //display: "block",
    display: "flex",
    //fontSize: 16,
    minHeight: 55,
    fontSize: "16px",
    marginTop: 16,
    padding: 10,
    marginBottom: 0,
    fontWeight: "500",
    lineHeight: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("lg")]: {
      minHeight: "55px"
    }
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {
    },
    fontSize: 14,
    fontWeight: "600",
    marginBottom: 0,
    minHeight: 55,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("lg")]: {
      minHeight: "55px",
    }
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",
    width: "25%",
    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid", 
    [theme.breakpoints.only("xl")]: {
      width: "25%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
     width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid", 
    [theme.breakpoints.only("xl")]: {
      width: "25%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
     width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  focusThematic: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid", 
    [theme.breakpoints.only("xl")]: {
      width: "50%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
     width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup2: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",
    flexDirection: "column",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "75px 0px 0px 16px",
    // pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%"
    },
    [theme.breakpoints.only("md")]: {
      width: "100%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup3: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "20%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup4: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",
    // alignItems: "centerAlign",
    textAlign: "right",

    borderRadius: "8px",
    marginBottom: "4px",
    // padding: "0 16px 0px 300px",
    padding: "24px 0px 0px 0px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "50%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "25%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup5: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",


    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 4px 0px 2px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "20%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "20%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup6: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",


    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 4px 0px 18px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "15%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "20%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup7: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",
    // alignItems: "centerAlign",
    textAlign: "left",

    borderRadius: "8px",
    marginBottom: "4px",
    // padding: "0 16px 0px 300px",
    padding: "24px 0px 0px 20px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "50%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "25%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
}));

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

function EditSponsorScreen(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationPath = useLocation();
  const [image, setImage] = React.useState(null);
  const inputRef = React.useRef(null);
  const [areas, setAreas] = React.useState([]);
  const [cityResponse, setCityResponse] = React.useState({});
  const [newField, setNewField] = React.useState(false);
  const [addressId, setAddressId] = React.useState(0);
  const [address, setAddress] = React.useState("");
  const [oldaddress, setOldAddress] = React.useState("");
  const [addressList, setAddressList] = React.useState([addressId]);
  const [thematicFocusAreaError, setThematicFocusAreaError] = React.useState("");
  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "", added: "" },
  ]);
  // const [notificationShow, setNotificationShow] = React.useState(false);
  //const [image,setImage] = React.useState('');
  const [organizationValues, setOrganizationValues] = React.useState({
    titleOfOrganization: "",
    totalStaff: "",
    headquarters: "",
    // organizationLogo: "",
    registeredAs: "",
    website: "",
    emailId: "",
    contactNo: "",
    totalNumberofVolunteers: "",
    chiefFunctionary: "",
  });
  const [organizationValuesError, setOrganizationValuesError] = React.useState({
    titleOfOrganizationError: false,
    totalStaffError: false,
    headquartersError: false,
    // organizationLogoError: false,
    registeredAsError: false,
    websiteError: false,
    emailIdError: false,
    contactNoError: false,
    totalNumberofVolunteersError: false,
    chiefFunctionaryError: false,
  });
  const [focusError, setFocusError] = React.useState(false);
  const [focusArea, setFocusArea] = React.useState([
    { focus: "No Poverty", isAdded: false },
    { focus: "Zero Hunger", isAdded: false },
    { focus: "Good Health and Well-Being", isAdded: false },
    { focus: "Quality Education", isAdded: false },
    { focus: "Gender Equality", isAdded: false },
    { focus: "Clean Water and Sanitation", isAdded: false },
    { focus: "Affordable and clean energy", isAdded: false },
    { focus: "Decent work and economic growth", isAdded: false },
    { focus: "Industry Innovation and Infrastructure", isAdded: false },
    { focus: "Reduced Inequalities", isAdded: false },
    { focus: "Sustainable cities and communities", isAdded: false },
    { focus: "Responsible Consumption and Production", isAdded: false },
    { focus: "Climate Action", isAdded: false },
    { focus: "Life Below Water", isAdded: false },
    { focus: "Life on Land", isAdded: false },
    { focus: "Peace, Justice and Strong Institutions", isAdded: false },
    {
      focus: "Partnerships for achieving Sustainable Development Goals",
      isAdded: false,
    },
  ]);
  const [selected, setSelected] = React.useState([]);

  // const [location, setLocation] = React.useState([
  //   { country: "India", state: "", city: "", area: "" },
  // ]);

  
  const [locationError, setLocationError] = React.useState([
    { country: false, state: false, city: false, area: false },
  ]);

  // const {
  //   isOrganizationSuccess,
  //   isOrganizationFetching,
  //   isOrganizationError,
  //   organizationResponse,
  // } = useSelector((state) => state.onBoard);

  const {
    isSponsorSuccess,
    isSponsorFetching,
    isSponsorError,
    sponsorResponse,
  } = useSelector((state) => state.updateSponsor);

  const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
    (state) => state.cities
  );

  // const resetFile = (event) => {
  //   inputRef.current.value = null;
  // };

  React.useEffect(() => {
  
    dispatch(updateSponsorReset());
  
  }, []);

  // React.useEffect(() => {
  //   // dispatch(onboardBulkReset());
  //   // dispatch(onboardIndividualReset());
  //   // dispatch(onboardActivityReset());
  //   // dispatch(onboardCampaignReset());
    // dispatch(updateOrgReset());
  
  // }, []);
  React.useEffect(() => {
    // console.log(campaignValues?.city)
    // console.log(campaignValues)
    // console.log(locationPath?.state?.location?.[0]?.city)
    if (locationPath?.state?.location?.[0]?.city) {
      dispatch(getCity(locationPath?.state?.location?.[0]?.state));

    }
  }, []);

  const [selectedFocusOption, setSelectedFocusOption] =  React.useState([]);

  const getStateList = async (i, valll) => {
    try {
      const res = await axios.get(`/statecity/${valll}`).then((response) => {
        const values = [...location];
        values[i]["cityList"] = response?.data;
        return response?.data;
      });

    } catch (err) {
      throw err;
    }
  };

// const focusOptions = [
//   { focus: "Water & Sanitation" },
//   { focus: "Education" },
//   { focus: "Climate Change and Sustainability" },
//   { focus: "Poverty Alleviation" },
//   { focus: "Teaching / Mentoring" },
//   { focus: "Rural Development" },
//   { focus: "Women Empowerment" },
//   { focus: "Child Rights" },
//   { focus: "Animal Rights" },
// ];

const focusOptions = [

  { focus: "No Poverty" },
	{ focus: "Zero Hunger" },
	{ focus: "Good Health and Well-Being" },
	{ focus: "Quality Education" },
	{ focus:	"Gender Equality"},
	{ focus: "Clean Water and Sanitation"},
	{ focus: "Affordable and Clean Energy" },
	{ focus: "Decent Work and Economic Growth" },
	{ focus: "Industry Innovation and Infrastructure" },
	{ focus: "Reduced Inequalities" },
	{ focus: "Sustainable Cities and Communities" },
	{ focus: "Responsible Consumption and Production" },
	{ focus: "Climate Action" },
	{ focus: "Life Below Water" },
	{ focus: "Life on Land" },
	{ focus: "Peace, Justice and Strong Institutions" },
	{ focus: "Partnerships for the Goals" },
	
];

  const handleOnboardOrganization = () => {
    console.log(organizationValues?.headquarters);

    if (!organizationValues?.titleOfOrganization) {
      setOrganizationValuesError({
        ...organizationValuesError,
        titleOfOrganizationError: true,
      });
      dispatch(updateSponsorReset());
      window.scrollTo(0, 400);
     } else if (!organizationValues?.totalStaff) {
      setOrganizationValuesError({
        ...organizationValuesError,
        totalStaffError: true,
      });
      dispatch(updateSponsorReset());
      window.scrollTo(0, 400);
    } else if (!organizationValues?.totalNumberofVolunteers) {
      setOrganizationValuesError({
        ...organizationValuesError,
        totalNumberofVolunteersError: true,
      });
      dispatch(updateSponsorReset());
      window.scrollTo(0, 400);
    } else if (!organizationValues?.headquarters) {
      setOrganizationValuesError({
        ...organizationValuesError,
        headquartersError: true,
      });
      dispatch(updateSponsorReset());
      window.scrollTo(0, 400);
    } else if (!organizationValues?.chiefFunctionary) {
      setOrganizationValuesError({
        ...organizationValuesError,
        chiefFunctionaryError: true,
      });
      dispatch(updateSponsorReset());
      window.scrollTo(0, 400);
    } else if (!organizationValues?.contactNo) {
      setOrganizationValuesError({
        ...organizationValuesError,
        contactNoError: true,
      });
      dispatch(updateSponsorReset());
      window.scrollTo(0, 400);
    } else if (!organizationValues?.emailId) {
      setOrganizationValuesError({
        ...organizationValuesError,
        emailIdError: true,
      });
      dispatch(updateSponsorReset());
      window.scrollTo(0, 400);
    } else if (!organizationValues?.totalNumberofVolunteers) {
      setOrganizationValuesError({
        ...organizationValuesError,
        totalNumberofVolunteersError: true,
      });
      dispatch(updateSponsorReset());
      window.scrollTo(0, 600);
    } else if (!organizationValues?.website) {
      setOrganizationValuesError({
        ...organizationValuesError,
        websiteError: true,
      });
      dispatch(updateSponsorReset());
      window.scrollTo(0, 0);
    } else if (thematicFocusAreaError === "true") {
      
      dispatch(updateSponsorReset());
      window.scrollTo(0, 600);
    } else if (organizationValuesError?.locationError) {
      setOrganizationValuesError({
        ...organizationValuesError,
        locationError: true,
      });
      window.scrollTo(1500, 1500);
    }
    else {
      dispatch(updateSponsorReset());
      let formData = new FormData();
      formData.append("organization", organizationValues?.titleOfOrganization);
      formData.append("noofemployees", organizationValues?.totalStaff);
      formData.append("orghq", organizationValues?.headquarters);
    //   formData.append("organizationlogo", organizationValues?.organizationLogo);
      formData.append("registeredas", organizationValues?.registeredAs);
      formData.append("website", organizationValues?.website);
      formData.append("chieffunctionary", organizationValues?.chiefFunctionary);
      formData.append("contactnumber", organizationValues?.contactNo);
      formData.append("emailid", organizationValues?.emailId);
      formData.append("_noofvol", organizationValues?.totalNumberofVolunteers);
      formData.append("orgfocusarea", JSON.stringify(selectedFocusOption));
      // formData.append("orgfocusarea", JSON.stringify(selectedSOrgFocusArea));
    
      formData.append("location", JSON.stringify(location));

    //   formData.append("location", JSON.stringify(location));
      dispatch(
        updateSponsor({
          clientid: organizationValues?.clientid,
          userid: localStorage.getItem("userid"),
          formData,
        })
      );
    }
  };
  const handleOrganizationFieldChange = (field, e) => {
    const re = /^[0-9\b]+$/;
    // if (e.target.value === '' || re.test(e.target.value)) {
      
    (isSponsorError || isSponsorSuccess) &&  dispatch(updateSponsorReset());
        setOrganizationValues({
            ...organizationValues,
            [field]:
                field === "organizationlogo" ? e.target.files[0] : e.target.value,
    
    });
  // }
  };

  const handleOrganizationFieldValidation = (field, e) => {
    // alert("enter")
    const re = /^[0-9\b]+$/;
    // alert(e.target.value)
    if (e.target.value === '' || re.test(e.target.value)) {
      // alert("true")
      
      handleOrganizationFieldChange(field, e);
    
    // });
  }
  };

  const onAddingItem = (event, option, index) => {
    const values = [...focusArea];
    values[index].isAdded = event.target.checked;
    setFocusArea(values);
  };

  // const handleChangeLocation = (id, e, field, index) => {
  //   const values = [...location];
  //   values[index][field] = e.target.value;
  //   setLocation(values);
  // };

  const handleChangeLocation = (id, e, field, index) => {
    const values = [...location];
    if (field === 'state') {
      values[index]["cityList"] = cityResponse;
    }

    values[index][field] = e.target.value;
    setLocation(values);
  };

  const handleChange = (event, index) => {
    const value = event.target.value;

    setSelected([...selected, value[value?.length - 1]]);
  };

  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  // const handleAddFields = () => {
  //   setLocation([...location, { country: "", state: "", city: "", area: "" }]);
  // };

  // const updatelocation = (index, address) => {
  //   console.log("addressin updatelocation");
  //   console.log(address);
  //   const values = [...location];
  //   if (!address) {
  //     console.log("address empty")
  //     setOrganizationValuesError({
  //       ...organizationValuesError,
  //       locationError: true,
  //     });
  //     const values = [...location];
  //     // values[index]["country"] = "";
  //     // values[index]["state"] = "";
  //     // values[index]["city"] = "";
  //     // values[index]["area"] = "";
  //     // values[index]["added"] = "no";
  //   } else {
  //     setOrganizationValuesError({
  //       ...organizationValuesError,
  //       locationError: false,
  //     });
  //     values[index]["country"] = String(address.split(",")[(address.split(",").length - 1)]).trim(" ");
  //     values[index]["state"] = String(address.split(",")[(address.split(",").length - 2)]).trim(" ");
  //     values[index]["city"] = String(address.split(",")[(address.split(",").length - 3)]).trim(" ");
  //     values[index]["area"] = String(address.split(",")[(address.split(",").length - 4)]).trim(" ");
  //     values[index]["added"] = "yes";
  //     setLocation(values);
  //     setOldAddress(address);
  //   }
  //   console.log("location in updatelocation");
  //   console.log(location);
  //   console.log("len of location in updatelocation");
  //   console.log(location.length);
  // };

  const updatelocation = (index, address) => {
  
    const values = [...location];
    if (!address) {
      
      if (values[index]["added"] === "no") {
      setOrganizationValuesError({
        ...organizationValuesError,
        locationError: true,
      });
    }
      const values = [...location];
      // values[index]["country"] = "";
      // values[index]["state"] = "";
      // values[index]["city"] = "";
      // values[index]["area"] = "";
      // values[index]["added"] = "no";
    } else {
      setOrganizationValuesError({
        ...organizationValuesError,
        locationError: false,
      });
      values[index]["country"] = String(address.split(",")[(address.split(",").length - 1)]).trim(" ");
      values[index]["state"] = String(address.split(",")[(address.split(",").length - 2)]).trim(" ");
      values[index]["city"] = String(address.split(",")[(address.split(",").length - 3)]).trim(" ");
      values[index]["area"] = String(address.split(",")[(address.split(",").length - 4)]).trim(" ");
      values[index]["added"] = "yes";
     
      if (values[index]["state"] === "undefined" && values[index]["city"] === "undefined"
          && values[index]["area"] === "undefined") {
          values[index]["added"] = "no";
          setOrganizationValuesError({
            ...organizationValuesError,
            locationError: true,
          });
        }
  
        if (values[index]["state"] === "undefined") {
          values[index]["state"] = ""
        }
        if (values[index]["city"] === "undefined") {
          values[index]["city"] = ""
        }
        if (values[index]["area"] === "undefined") {
          values[index]["area"] = ""
        }
        if (values[index]["state"] == "" && values[index]["city"] == "" && values[index]["area"] == "") {
          setOrganizationValuesError({
            ...organizationValuesError,
            locationError: true,
          });
          values[index]["added"] = "no";
          // console.log(organizationValuesError)
        } else {
          setLocation(values);
          setOldAddress(address);
        }
    }
  
  };

  const handleAddFields = () => {
  
    let errorcount = 0;
    for (let i = 0; i < location.length; i += 1) {
      
      // console.log("RegistrationIDDD ==",selectedItem[i].registrationid);
      if (location[i]["country"] == "" || location[i]["state"] == "" || location[i]["city"] == ""|| 
            location[i]["added"] == "no") {
              setNewField(false);
              errorcount = errorcount + 1;
      }
    }
    if (errorcount == 0) {
    setNewField(true);
    // setLocation([...location, { country: "", state: "", city: "", area: "", cityList: "" }]);
    setLocation([...location, { country: "", state: "", city: "", area: "", added: "no"}]);
    } else {
      setNewField(false);
    }
   
    
    // setRewardtitle([...rewardtitle, { rewardtype: "", title: "", subtitle: "", sponsor: "", titleList: "" }])
  };
  const handleSelect = async (value) => {

    // console.log(value);
    const results = await geocodeByAddress(value);
    // console.log(results[0]["formatted_address"]);
    setAddress(results[0]["formatted_address"]);

    // console.log(value);
  }

  const handleRemoveFields = (i) => {
   let newFormValues = [...location];
   newFormValues.splice(i,1);
   setLocation(newFormValues);
  };
  React.useEffect(() => {
    // console.log(locationPath)
    if (locationPath?.state?.orgfocusarea) {
        // setselectedSOrgFocusArea(locationPath?.state?.orgfocusarea);
        setSelectedFocusOption(locationPath?.state?.orgfocusarea);
        
    }
    // if (locationPath?.state?.rewardstobeearned) {
    //   setSelectedRewards(locationPath?.state?.rewardstobeearned);
    // }
    if (locationPath?.state) {
        setOrganizationValues({
        titleOfOrganization: locationPath?.state?.organization,
        totalStaff: locationPath?.state?.noofemployees,
        headquarters: locationPath?.state?.orghq,
        // organizationLogo: locationPath?.state?,
        registeredAs: locationPath?.state?.registeredas,
        website: locationPath?.state?.website,
        emailId: locationPath?.state?.emailid,
        contactNo: locationPath?.state?.contactnumber,
        totalNumberofVolunteers: locationPath?.state?.noofvol,
        chiefFunctionary: locationPath?.state?.chieffunctionary,
        // OrgFocusArea: locationPath?.state?.selectedSOrgFocusArea,
        OrgFocusArea: locationPath?.state?.orgfocusarea,
        clientid: locationPath?.state?.clientid,
      });
      setLocation(locationPath?.state?.location);
      // setFocusArea(JSON.parse(locationPath?.state?.rewardstobeearned))
    }
  }, [locationPath?.state]);
  
  const [selectedSOrgFocusArea, setselectedSOrgFocusArea] = React.useState([]);

  /* For profile image handling */

  const [loading, setLoading] = React.useState(false);
  // const [image, setImage] = React.useState(null);
  const [show, setShow] = React.useState(false);

  const {
    isSuccess: profileImageSuccess,
    response: profileImageResponse,
    isError: profileImageError,
  } = useSelector((state) => state.profileImage);

  const {
    isSuccess: orgLogoSuccess,
    response: orgLogoResponse,
    isError: orgLogoError,
  } = useSelector((state) => state.getOrgProfileLogo);

  const handleChangeFile = (e) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("organizationlogo", e.target.files[0]);
    dispatch(
      getOrgProfileLogo({ clientid: locationPath?.state?.clientid, userId: localStorage.getItem("userid"), formData })
    );
    setImage(e.target.files[0]);
  };

  // React.useEffect(() => {
  //   if (profileImageSuccess || profileImageError) {
  //     setLoading(false);
  //   }
  // }, [profileImageorganizationValuesSuccess, profileImageError]);
  
  React.useEffect(() => {
    if (orgLogoSuccess || orgLogoError) {
      setLoading(false);
    }
  }, [orgLogoSuccess, orgLogoError]);
  


  // console.log(organizationValues);
//   console.log(locationPath);
const getAddress = (item, index) => {
  
  return (
    <div style={{ float: "left", width: "75%", marginTop: 50 }}>
      <form style={{ display: "flex", flexWrap: "wrap", width: "100%", float: "left" }}>
        {
          (index == location.length - 1 && location[index]?.country === "") ? (
            <div className={classes.formGroup3}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Country
              </label>
              {!(address && index > 0 && location[index]?.added == "no" && index == location.length - 1 && address == oldaddress) ? (
                <span >{address.split(",")[(address.split(",").length - 1)]}</span>
              ) :
                <span style={{ fontSize: 12 }}>Search location</span>
              }
            </div>
          )
            :
            <div className={classes.formGroup3}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Country
              </label>
              <span >{location[index]?.country}</span>
            </div>
        }
        {
          (index == location.length - 1 && location[index]?.state === "") ? (
            <div className={classes.formGroup3}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                State
              </label>
              {!(address && index > 0 && location[index]?.added == "no" && index == location.length - 1 && address == oldaddress) ? (
                <span >{address.split(",")[(address.split(",").length - 2)]}</span>
              ) : 
                <span style={{ fontSize: 12 }}>Search location</span>
              }
            </div>
          ) :
            <div className={classes.formGroup3}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                State
              </label>
              {address ? (
                <span >{location[index]?.state}</span>
              ) : !address ? (
                <span>{item.state}</span>
              ) : 
              <span style={{ fontSize: 12 }}>Search location</span>
              }
            </div>
        }

        {
          (index == location.length - 1 && location[index]?.state === "") ? (

            <div className={classes.formGroup3}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                City/Town
              </label>
              {!(address && index > 0 && location[index]?.added == "no" && index == location.length - 1 && address == oldaddress) ? (
                <span >{address.split(",")[(address.split(",").length - 3)]}</span>
              ) :
                <span style={{ fontSize: 12 }}>Search location</span>
              }
            </div>
          ) :
            <div className={classes.formGroup3}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                City/Town
              </label>
              {address ? (
                <span >{location[index]?.city}</span>
              ) : !address ? (
                <span>{item.city}</span>
              ) : 
                <span style={{ fontSize: 12 }}>Search location</span>
              }
            </div>
        }

        {
          (index == location.length - 1 && location[index]?.area === "") ? (

            <div className={classes.formGroup3}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Area
              </label>
              {!(address && index > 0 && location[index]?.added == "no" && index == location.length - 1 && address == oldaddress) ? (
                <span >{address.split(",")[(address.split(",").length - 4)]}</span>
              ) :
                <span style={{ fontSize: 12 }}>Search location</span>
              }
            </div>
          ) :

            <div className={classes.formGroup3}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Area
              </label>

              {address ? (
                <span >{location[index]?.area}</span>
              ) : !address ? (
                <span>{item.area}</span>
              ) : 
                <span style={{ fontSize: 12 }}>Search location</span>
              }
            </div>
        }
        <div className={classes.formGroup3}>
          <label htmlFor="create-yaad--title" className={classes.label}>
            Added
          </label>
          {location[index]?.added == "yes" ? (
            <DoneIcon style={{ color: "green", }} />
          ) :
            ""
          }
        </div>
        <div className={classes.formGroup4} >
          <Button
            variant="contained"
            onClick={() => updatelocation(index, address)}
            // className="btn save-btn"
            disableElevation
            style={{
              width: "50px",
              height: "35px",
              background: "#f5eceb",
              color: "green",
              fontSize: 16,
              fontFamily: "Poppins !important",

            }}

          >Add
          </Button>
        </div>
        {(index === 0) ? (

          <div className={classes.formGroup7} >
            <Button

              variant="contained"
              onClick={() => handleRemoveFields(index)}
              disableElevation
              disabled

              style={{
                width: "92px",
                height: "35px",
                background: "gray",
                color: "#DC143C",
                fontSize: 16,
                fontFamily: "Poppins !important",
              }}
            >
              Delete
            </Button>
          </div>
        ) :
          <div className={classes.formGroup7} >
            <Button
              variant="contained"
              onClick={() => handleRemoveFields(index)}
              // className="btn save-btn"
              disableElevation
              style={{
                width: "92px",
                height: "35px",
                background: "#f5eceb",
                color: "red",
                fontSize: 16,
                fontFamily: "Poppins !important",
              }}
            >
              Delete
            </Button>
          </div>
        }
      </form>
      {
        index ?
          <div style={{ float: "right", position: "relative", bottom: 44, cursor: "pointer", left: 19 }} onClick={(e) => handleRemoveFields(index)}><RemoveCircleOutlineOutlined></RemoveCircleOutlineOutlined></div>
          : <div className="handlePlus" style={{ float: "right", bottom: 45, position: "relative", left: 20, cursor: "pointer", right: "15px" }}><AddCircleOutlineOutlined
            onClick={handleAddFields}
          ></AddCircleOutlineOutlined></div>
      }
    </div>
  );
};

  React.useEffect(() => {
    if (organizationValues?.stateofOrganization) {
      dispatch(getCity(organizationValues?.stateofOrganization));
    }
  }, [organizationValues?.stateofOrganization]);

  // React.useEffect(() => {
  //   if (isOrganizationSuccess && organizationResponse?.status === "success") {
  //     setOrganizationValues({
  //       titleOfOrganization: "",
  //       descriptionOfOrganization: "",
  //       volunteerCount: "",
  //       organizationLogo: "",
  //       registeredAs: "",
  //       website: "",
  //       emailId: "",
  //       contactNo: "",
  //       totalNumberofVolunteers: "",
  //       chiefFunctionary: "",
  //       headquarters: "",
  //       totalStaff: "",
  //     });
  //     // resetFile();
  //     setSelected([]);
  //     setLocation([{ country: "", state: "", city: "", area: "" }]);
  //   }
  // }, [isOrganizationSuccess]);

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
        e.preventDefault();
    }
};

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      
      <section className="containers">
      <TopHeaderScreen {...props} />
      <div class="content content-wrap">
          <div class="content-mrg">
        <div style={{fontFamily:"monospace", fontSize: 16,  }}>
        <Button
              onClick={() => navigate(-1)}
              className={classes.backBtn}
          >
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
          </Button>
          {/* <div style={{display: "flex", width: "75%", justifyContent: "flex-end", marginRight: 5, marginBottom: 20, }}>               
          <span style={{ color: "red" }}>Please do not leave this page until you submit as you may risk losing the partially filled in form </span>
          </div> */}
        </div>
        {/* <span style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>
          Edit Organization
        </span> */}
        <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Edit Sponsor Organization</div>


        { locationPath &&
            (loading ? (
              <CircularProgress color="inherit" size={16} />
              // <CircularProgress color="blue" size={16} />
            ) : (
              <div style={{
                padding: "10px 0",
                // height: "50%",
                // boxShadow: "1px 1px 1px 5px #f0f0f0",
                borderRadius: 30,
                marginTop: 16,
                display: "flex",
                flexDirection: "column",
                // justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "#fff"
                backgroundColor: "#FFFFFF",
                border: "1px solid #dadce0",
              }}>
                <span ><b>Update Sponsor Logo</b></span>
                <label htmlFor="icon-button-file">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    // justifyContent= "center"
                  >
                    <Avatar
                      src={
                        image
                          ? URL.createObjectURL(image)
                          : locationPath?.state?.organizationlogo
                          // : organizationValues?.organizationLogo
                      }
                      className={classes.large}
                      style={{ justifyContent: "center", alignItems: "center" }}
                    />
                  </IconButton>
                </label>
                 <input 
                 name="Upload your image"
                  accept="image/*"
                  className={classes.logobutton}
                  id="icon-button-file"
                  type="file"
                  onChange={handleChangeFile}
                  width="20"
                /> 
              </div>
            ))}


        
        <form autoComplete="off">
          <div style={{ border: "1px solid #dadce0", borderRadius: 8, marginTop: 15, backgroundColor:"#fff" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: 15,
              }}
            >
              <div className={classes.formGroup}>
                <label className={classes.label}> Name of Sponsor <RedStar /></label>
                <input
                  value={organizationValues?.titleOfOrganization}
                  // defaultValue={organizationValues?.titleOfOrganization}
                  type="text"
                  className={classes.input}
                  placeholder="Your answer"
                  onBlur={() => {
                    if (!organizationValues?.titleOfOrganization) {
                      setOrganizationValuesError({
                        ...organizationValuesError,
                        titleOfOrganizationError: true,
                      });
                    } else {
                      setOrganizationValuesError({
                        ...organizationValuesError,
                        titleOfOrganizationError: false,
                      });
                    }
                  }}
                  onChange={(e) => {
                    // handleOrganizationFieldChange("titleOfOrganization", e);
                    handleOrganizationFieldChange("titleOfOrganization", e);
                  }}
                />
                {organizationValuesError?.titleOfOrganizationError && (
                  <ErrorMessage message="Please enter the title of organization" />
                )}
              </div>

              <div className={classes.formGroup1}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                Registered as <RedStar />
                <BasicPopover content="Please chose the registration type for your organization" />
                </label>
                {/* <BasicPopover content="Please chose the registration type for your organization" /> */}
                <Select
                  onBlur={() => {
                    if (!organizationValues?.registeredAs) {
                      setOrganizationValuesError({
                        ...organizationValuesError,
                        registeredAsError: true,
                      });
                    } else {
                      setOrganizationValuesError({
                        ...organizationValuesError,
                        registeredAsError: false,
                      });
                    }
                  }}
                  style={{
                    width: "100%",
                    fontSize: 14,
                    height: 56,
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                    border: "none",
                  }}
                  value={organizationValues?.registeredAs}
                  onChange={(e) => {
                    if (!organizationValues?.registeredAs) {
                      setOrganizationValuesError({
                        ...organizationValuesError,
                        registeredAsError: true,
                      });
                    } else {
                      setOrganizationValuesError({
                        ...organizationValuesError,
                        registeredAsError: false,
                      });
                    }
                    handleOrganizationFieldChange("registeredAs", e);
                  }}
                >
                  {registeredAs.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {organizationValuesError?.registeredAsError && (
                  <ErrorMessage message="Please select registered as" />
                )}
              </div>

              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                Headquarters <RedStar />
                <BasicPopover content="Please enter city name where your company has the HQ " />
                </label>

                <input
                  onBlur={() => {
                    if (!organizationValues?.headquarters) {
                      setOrganizationValuesError({
                        ...organizationValuesError,
                        headquartersError: true,
                      });
                    } else {
                      setOrganizationValuesError({
                        ...organizationValuesError,
                        headquartersError: false,
                      });
                    }
                  }}
                  value={organizationValues?.headquarters}
                  // defaultValue={organizationValues?.headquarters}
                  type="text"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  onChange={(e) => {
                    handleOrganizationFieldChange("headquarters", e);
                    // if (!organizationValues?.headquarters) {
                    //   setOrganizationValuesError({
                    //     ...organizationValuesError,
                    //     headquartersError: true,
                    //   });
                    // } else {
                    //   setOrganizationValuesError({
                    //     ...organizationValuesError,
                    //     headquartersError: false,
                    //   });
                    // }
                  }}
                />
                {organizationValuesError?.headquartersError && (
                  <ErrorMessage message="Please enter the headquarter" />
                )}
              </div>


              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Website (if any) <RedStar />
                </label>

                <input
                  value={organizationValues?.website}
                  // defaultValue={organizationValues?.website}
                  type="text"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  onChange={(e) => {
                    handleOrganizationFieldChange("website", e);
                  }}
                />
                {organizationValuesError?.websiteError && (
                  <ErrorMessage message="Please enter the website" />
                )}
              </div>

              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                Name of Chief Functionary <RedStar />
                <BasicPopover content="Please enter name of Head of your organization" />
                </label>

                <input
                  value={organizationValues?.chiefFunctionary}
                  // defaultValue={organizationValues?.chiefFunctionary}
                  type="text"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  onBlur={() => {
                    if (!organizationValues?.chiefFunctionary) {
                      setOrganizationValuesError({
                        ...organizationValuesError,
                        chiefFunctionaryError: true,
                      });
                    } else {
                      setOrganizationValuesError({
                        ...organizationValuesError,
                        chiefFunctionaryError: false,
                      });
                    }
                  }}
                  onChange={(e) => {
                    handleOrganizationFieldChange("chiefFunctionary", e);
                  }}
                />
                {organizationValuesError?.chiefFunctionaryError && (
                  <ErrorMessage message="Please enter the name of chief functionary" />
                )}
              </div>

              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Contact No <RedStar />
                  <BasicPopover content="Please add country code along with 10 digit phone number" />
                </label>

                <input
                  value={organizationValues?.contactNo}
                  // defaultValue={organizationValues?.contactNo}
                  type="text"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  onKeyPress={(e) => {preventMinus(e)}}
                  onBlur={() => {
                    if (!organizationValues?.contactNo) {
                      setOrganizationValuesError({
                        ...organizationValuesError,
                        contactNoError: true,
                      });
                    } else {
                      setOrganizationValuesError({
                        ...organizationValuesError,
                        contactNoError: false,
                      });
                    }
                  }}
                  onChange={(e) => {
                    handleOrganizationFieldChange("contactNo", e);
                  }}
                />
                {organizationValuesError?.contactNoError && (
                  <ErrorMessage message="Please enter the contact number" />
                )}
              </div>



              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Email Id <RedStar />
                </label>

                <input
                  value={organizationValues?.emailId}
                  // defaultValue={organizationValues?.emailId}
                  type="text"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  onBlur={() => {
                    if (!organizationValues?.emailId) {
                      setOrganizationValuesError({
                        ...organizationValuesError,
                        emailIdError: true,
                      });
                    } else {
                      setOrganizationValuesError({
                        ...organizationValuesError,
                        emailIdError: false,
                      });
                    }
                  }}
                  onChange={(e) => {
                    handleOrganizationFieldChange("emailId", e);
                  }}
                />
                {organizationValuesError?.emailIdError && (
                  <ErrorMessage message="Please enter the email id" />
                )}
              </div>

              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                Total Staff <RedStar />
                <BasicPopover content="Please enter number of employees in your organization" />
                </label>
                <input
                  type="text"
                  value={organizationValues?.totalStaff}
                  // defaultValue={organizationValues?.totalStaff}
                  onKeyPress={(e) => {preventMinus(e)}}
                  // min="0"
                  // type="text"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  onBlur={() => {
                    if (!organizationValues?.totalStaff) {
                      setOrganizationValuesError({
                        ...organizationValuesError,
                        totalStaffError: true,
                      });
                    } else {
                      setOrganizationValuesError({
                        ...organizationValuesError,
                        totalStaffError: false,
                      });
                    }
                  }}
                  onChange={(e) => {
                    // handleOrganizationFieldChange("totalStaff", e);
                    handleOrganizationFieldValidation("totalStaff", e);
                  }}
                />
                {organizationValuesError?.totalStaffError && (
                  <ErrorMessage message="Please enter the no of employees" />
                )}
              </div>

              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Total No. of Volunteers <RedStar />
                  <BasicPopover content="Please enter number of volunteers associated with your organization" />
                </label>

                <input
                  value={organizationValues?.totalNumberofVolunteers}
                  // defaultValue={organizationValues?.totalNumberofVolunteers}
                  type="text"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  onKeyPress={(e) => {preventMinus(e)}}
                  min="0"
                  onBlur={() => {
                    if (!organizationValues?.totalNumberofVolunteers) {
                      setOrganizationValuesError({
                        ...organizationValuesError,
                        totalNumberofVolunteersError: true,
                      });
                    } else {
                      setOrganizationValuesError({
                        ...organizationValuesError,
                        totalNumberofVolunteersError: false,
                      });
                    }
                  }}
                  onChange={(e) => {
                    // handleOrganizationFieldChange("totalNumberofVolunteers", e);
                    handleOrganizationFieldValidation("totalNumberofVolunteers", e);
                  }}
                />
                {organizationValuesError?.totalNumberofVolunteersError && (
                  <ErrorMessage message="Please enter total no of volunteers" />
                )}
              </div>

              <div className={classes.focusThematic}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Focus Thematic Areas <RedStar />
                  <BasicPopover content="Please select the focus areas for your organization" />
                </label>
                <Multiselect
                   displayValue="focus"
                   showCheckbox
                //    selectedValues={selectedFocusOption}
                    // selectedValues={selectedSOrgFocusArea}
                    selectedValues={locationPath?.state?.orgfocusarea}
                    options={focusOptions}
                   onSelect = {(event) => {
                    setSelectedFocusOption(event);
                    if (_.isEmpty(event)){
                      
                      setThematicFocusAreaError("true")
                      } else {
                        setThematicFocusAreaError("false")
                      
                    }
                   }}
                   onRemove= {(event) => {
                    setSelectedFocusOption(event);
                    if (_.isEmpty(event)){
                      
                      setThematicFocusAreaError("true")
                      } else {
                        setThematicFocusAreaError("false")
                      
                    }
                   }}
                    value={selectedFocusOption} 
                    style={{
                      multiselectContainer: {
                        boxShadow: "0px 3px 6px #00000029",
                      },
                      searchBox: {
                        minHeight: 54,
                        PaddingTop: 10
                      }
                    }}                

                />
                {thematicFocusAreaError==="true" && (
                  <ErrorMessage message="Please select focus thematic areas" />
                )}
                  {/* <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelected ? classes.selectedAll : "",
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelected}
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < focusAreaDict.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem> */}
                  {/* {focusAreaDict.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox checked={selected?.indexOf(option) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={option?.focus} />
                    </MenuItem>
                  ))}
                </Select>
                {focusError && (
                  <ErrorMessage message="Please select focus thematic areas" />
                )} */}
              </div>
              <div
                className={classes.formGroup}
                style={{ paddingLeft: "20px", width: "100%" }}
              >
                <div className={classes.formGroup2}>
                      <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>Operational Areas</span><span style={{ color: "red" }}>*</span> <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 300, fontStyle: "italic" }}> (Search for location, select and click on Add to confirm. Click on "+" icon to enter additional locations)</span>
                      <PlacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelect}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) =>
                          <div style={{ padding: "25px 0px 0px 0px" }}>

                            {/* <input {...getInputProps({ placeholder: "Type Address", autoFocus: true, endAdornment:<SearchIcon />  })} /> */}
                            <TextField
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment>
                                    <IconButton>
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                              {...getInputProps({ placeholder: "Search for location..", autoFocus: false })} />
                            <div> {loading ? <div>...loading...</div> : null}</div>
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active ? "blue" : "white"
                              }
                              return (
                                <div {...getSuggestionItemProps(suggestion, { style })}>
                                  {suggestion.description}

                                </div>
                              )
                            })}
                          </div>

                        }

                      </PlacesAutocomplete>
                    </div>
                {location.map((item, index) => (
                  <div key={index}>{getAddress(item, index)}</div>
                ))}

               
              </div>
              {/* {JSON.stringify(isOrganizationFetching)}
              {JSON.stringify(isOrganizationSuccess)} */}
            </div>
            {/* {isOrganizationFetching && (
                <span style={{ color: "green",textAlign: "center", display: "block" }}>
                  {organizationResponse?.message}
                </span>
              )}
              {!isOrganizationFetching && (
                <span style={{ color: "red", textAlign: "center", display: "block" }}>Please verify all the fields before submitting</span>
              )} */}

            {isSponsorSuccess &&
            sponsorResponse?.status === "success" && (
                <span style={{ color: "green",textAlign: "center", display: "block" }}>
                  {sponsorResponse?.message}
                </span>
              )}
              {isSponsorSuccess && sponsorResponse?.status === "failure" && (
                <span style={{ color: "red", textAlign: "center", display: "block" }}>Please verify all the fields before submitting</span>
              )}

{ 
              organizationValuesError?.locationError && (
                <span style={{ color: "red", textAlign: "center", display: "block" }}>Please search for location, select it and click on "Add"</span>
              )
            }
            <div className="centerAlign" style={{ padding: "16px 0", marginBottom: 56 }}>
              <Button
                style={{ marginLeft: 20 }}
                variant="contained"
                onClick={handleOnboardOrganization}
                className="btn save-btn"
                disableElevation
                disabled={isSponsorFetching}
              >
                {/* {isOrganizationFetching ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  <span>Submit</span>
                )} */}

                {isSponsorFetching ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  <span>Submit</span>
                )}
              </Button>
              <br />
              {/* {isOrganizationFetching && (
                <span style={{ color: "green",textAlign: "center", display: "block" }}>
                  {organizationResponse?.message}
                </span>
              )}
              {isOrganizationFetching && (
                <span style={{ color: "red", textAlign: "center", display: "block" }}>Please verify all the fields before submitting</span>
              )} */}
            </div>
          </div>
        </form>
      </div>
      </div>
      <CopyRight />
      </section>
     
    </div>
  );
}
export default EditSponsorScreen;
