/** @format */

const YAAG_MY_COMMUNITY_FETCHING_DATA = 'YAAG_MY_COMMUNITY_FETCHING_DATA';
const YAAG_MY_COMMUNITY_FETCHING_DATA_FAILURE = 'YAAG_MY_COMMUNITY_FETCHING_DATA_FAILURE';
const YAAG_MY_COMMUNITY_FETCHING_DATA_RESET = 'YAAG_MY_COMMUNITY_FETCHING_DATA_RESET';
const YAAG_MY_COMMUNITY_FETCHING_DATA_SUCCESS = 'YAAG_MY_COMMUNITY_FETCHING_DATA_SUCCESS';
const YAAG_MY_COMMUNITY_FETCHING_DATA_ATTEMPT = 'YAAG_MY_COMMUNITY_FETCHING_DATA_ATTEMPT';
const yaagMyCommunity = payload => ({
	type: YAAG_MY_COMMUNITY_FETCHING_DATA_ATTEMPT,
	payload: payload,
});

export { YAAG_MY_COMMUNITY_FETCHING_DATA, YAAG_MY_COMMUNITY_FETCHING_DATA_FAILURE, YAAG_MY_COMMUNITY_FETCHING_DATA_RESET, YAAG_MY_COMMUNITY_FETCHING_DATA_SUCCESS, YAAG_MY_COMMUNITY_FETCHING_DATA_ATTEMPT, yaagMyCommunity };
