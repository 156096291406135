/** @format */

import axios from "axios";
import { get, post, _ } from "lodash";
import { API_URL } from "./Constants";
// import { API_URL } from "./config.json";

axios.defaults.baseURL = API_URL;

export default class Api {
  async login(payload) {
    try {
      const res = await axios.post("/cyaaguser/" + payload.userid, payload);
      return res;
    } catch (err) {
      //console.log(err);

      throw err;
    }
  }
  async register(payload) {
    try {
      const res = await axios.post("/user", payload);
      return res;
    } catch (err) {
      throw err;
    }
  }
  async onboardIndividual(payload) {
    try {
      // const res = await axios.post("individualupload", payload);
      const res = await axios({
        url: `/individualupload`,
        method: "post",
        data: payload,
        headers: {
          "content-type": "application/json",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }
  async onboardKTAdmin(payload) {
    try {
      // const res = await axios.post("individualupload", payload);
      const res = await axios({
        url: `/kt/onboarduser`,
        method: "post",
        data: payload,
        headers: {
          "content-type": "application/json",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }
  async onboardSelf(payload) {
    try {
      // const res = await axios.post("individualupload", payload);
      const res = await axios({
        url: `/selfupload`,
        method: "post",
        data: payload,
        headers: {
          "content-type": "application/json",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }
  async onboardActivity(payload) {
    try {
      const res = await axios({
        url: `/nssactivity/${payload.userid}`,
        method: "post",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }


  async onboardKTCustomer(payload) {
    try {
      const res = await axios({
        url: `/kt/create/customer/${payload.userid}`,
        // url: `/a/b/c/d/e/f/g/h/i/j/k/l/m/${payload.userid}`,
        method: "post",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }
  


  async user(payload) {
    try {
      const res = await axios.get("/cyaaguser/" + payload);
      // sessionStorage.setItem("userData", res);
      return res;
    } catch (err) {
      throw err;
    }
  }
  async useremail(payload) {
    try {
      
      const res = await axios.get("/cyaaguser/email/" + payload);
      // sessionStorage.setItem("userData", res);
      return res;
    } catch (err) {
      throw err;
    }
  }
  // async useremail(payload) {
  //   try {
  //     const res = await axios.get("/cyaaguser/email/" + payload);
  //     // sessionStorage.setItem("userData", res);
  //     return res;
  //   } catch (err) {
  //     throw err;
  //   }
  // }
  async userProfileImage(payload) {
    try {
      const res = await axios({
        url: `/user/profile/image/${payload.userId}`,
        method: "post",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }
  async orgProfileLogo(payload) {
    try {
      const res = await axios({
        url: `/org/logo/${payload.clientid}/${payload.userId}`,
        method: "post",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }
  async campProfileLogo(payload) {
    try {
      const res = await axios({
        url: `/camp/logo/${payload.campid}/${payload.userId}`,
        method: "post",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }
  async campFiles(payload) {
    try {
      
      const res = await axios({
        url: `/camp/files/${payload.campid}/${payload.userId}`,
        method: "post",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }

  async campDocs(payload) {
    try {
    
      const res = await axios({
        url: `/camp/files/personal/${payload.campid}/${payload.userId}`,
        method: "post",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }

  async campProfileBanner(payload) {
    try {
      const res = await axios({
        url: `/camp/banner/${payload.campid}/${payload.userId}`,
        method: "post",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }
  async campProfileVideo(payload) {
    try {
      const res = await axios({
        url: `/camp/video/id/${payload.campid}/${payload.userId}`,
        method: "post",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }

  async addRewards(payload) {
    try {
      const res = await axios({
        url: `/rewards/add/pf/${payload.userid}`,
        method: "post",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }

  async addRecognitions(payload) {
    try {
      const res = await axios({
        url: `/recognitions/add/pf/${payload.userid}`,
        method: "post",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }


  async addCampRewards(payload) {
    try {
      const res = await axios({
        url: `/rewards/add/camp/${payload.userid}`,
        method: "post",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }

  async addCampRecognitions(payload) {
    try {
      const res = await axios({
        url: `/recognitions/add/camp/${payload.userid}`,
        method: "post",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }


  async onboardRewards(payload) {
    try {
      const res = await axios({
        url: `/rewards/onboard/${payload.userid}`,
        method: "post",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }

  async onboardRecognitions(payload) {
    try {
      const res = await axios({
        url: `/recognitions/onboard/${payload.userid}`,
        method: "post",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }

  async updateCampaign(payload) {
    try {
      const res = await axios({
        url: `/campaign/update/${payload.campid}`,
        method: "put",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }
  async updateOrg(payload) {
    try {
      const res = await axios({
        url: `/org/update/${payload.clientid}/${payload.userid}`,
        method: "put",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }

  async updateSponsor(payload) {
    try {
      const res = await axios({
        url: `/sponsor/update/${payload.clientid}/${payload.userid}`,
        method: "put",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }

  async updateReward(payload) {
    
    try {
      const res = await axios({
        url: `/marketplace/reward/edit/${payload.mpid}`,
        method: "put",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }

  async updateKTCustomer(payload) {
    try {
      console.log("calling updateKTCustomer from API")
      const res = await axios({
        url: `/kt/update/customer/${payload.userid}`,
        method: "put",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      console.log("Exception = ", err)
      throw err;
    }
  }

  async updateKTCustomerTRN(payload) {
    try {
      
      const res = await axios({
        url: `/kt/update/customer/trn/${payload.userid}`,
        method: "put",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      console.log("Exception = ", err)
      throw err;
    }
  }

  async deleteKTCustomerTRN(payload) {
    try {
      
      const res = await axios({
        url: `/kt/delete/customer/trn/${payload.userid}`,
        method: "put",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      console.log("Exception = ", err)
      throw err;
    }
  }

  async onboardBulk(payload) {
    try {
      const res = await axios({
        url: `/bulkupload/${localStorage.getItem("userid")}`,
        method: "post",
        data: payload,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }

  async onboardCampaign(payload) {
    try {
      const res = await axios({
        url: `/campaign/${payload.userid}`,
        method: "post",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }

  async onboardOrganization(payload) {
    try {
      const res = await axios({
        url: `/org/${payload.userid}`,
        method: "post",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }

  async onboardSponsor(payload) {
    try {
      const res = await axios({
        url: `/sponsor/${payload.userid}`,
        method: "post",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");
      return data;
    } catch (err) {
      throw err;
    }
  }

  async getVolunteerCount(payload) {
    try {
      const res = await axios.get("/volunteercount", payload);
      return res;
    } catch (err) {
      throw err;
    }
  }
  async getStatesUT(payload) {
    try {
      const res = await axios.get(payload ? `/stateut/${payload}` : "stateut");
      return res;
    } catch (err) {
      throw err;
    }
  }
  async getCity(payload) {
    try {
     
      const res = await axios.get("/statecity/" + payload, payload);
      return res;
    } catch (err) {
      throw err;
    }
  }
  async getRewardTitle(payload) {
    try {
     
      const res = await axios.get("/getrewardtitle/" + payload, payload);
   
      return res;
    } catch (err) {
      // throw err;
      
    }
  }

  async getReportFromState(payload) {
    try {
      const res = await axios.get(
        payload === "All States"
          ? "report/userfromstate"
          : "report/userfromstate" + "/" + payload
      );
      return res;
    } catch (err) {
      throw err;
    }
  }
  // async getSOVolReportSaga(payload) {
  //   try {
  //     const res = await axios.get(`/soa/vol/${payload.userid}`, payload);
  //     return res;
  //   } catch (err) {
  //     throw err;
  //   }
  // }
  async getSOVolReportSaga(payload) {
    try {
      const res = await axios.get("/soa/vol/" + payload, payload);
      return res;
    } catch (err) {
      throw err;
    }
  }
  async getReportActivity(payload) {
    try {
      const res = await axios.get("/report/userinactivity", payload);
      return res;
    } catch (err) {
      throw err;
    }
  }
  async referralUrl(payload) {
    try {
      const res = await axios.get(
        "refrralurl/" + payload.campid + "/" + payload.userid
      );
      return res;
    } catch (err) {
      throw err;
    }
  }
  async referral(payload) {
    try {
      const res = await axios.post("hareferralreward", payload);
      return res;
    } catch (err) {
      throw err;
    }
  }
  
  
  async yaagMyCommunity(payload) {
    try {
      const res = await axios.get(
        "adopter_dashboard/mycommunity/" + payload.userid + "/" + payload.campid
      );
      return res;
    } catch (err) {
      throw err;
    }
  }
  async myRank(payload) {
    try {
      const res = await axios.get("myrank/" + payload);
      return res;
    } catch (err) {
      throw err;
    }
  }
  async topAdopters(payload) {
    try {
      const res = await axios.get("topadopters/" + payload);
      return res;
    } catch (err) {
      throw err;
    }
  }
  async campLeaderBoard(payload) {
    try {
     
      // const res = await axios.get("my/camp/rank/lb/" + payload
      const res = await axios.get(`my/camp/rank/lb/${payload.phoneno}/${payload.campid}`, payload
      );
      return res;
    } catch (err) {
      throw err;
    }
  }
  async getVolunteerName(payload) {
    try {
      const res = await axios.get(
        `/volunteerlist/${payload.organization}/${payload.userid}`,
        payload
      );
      return res;
    } catch (err) {
      throw err;
    }
  }
  async getCampDetails(payload) {
    try {
      const res = await axios.get(
        `/vol_dashboard/camp/${payload.orgrole}/${payload.organization}`,
        payload
      );
      return res;
    } catch (err) {
      throw err;
    }
  }
  async getOrganization(payload) {
    try {
      const res = await axios.get(`/organization/${payload.userid}`, payload);
      return res;
    } catch (err) {
      throw err;
    }
  }
  async myReward(payload) {
    try {
      // alert("PAYLOAD")
      // console.log(payload);
      const res = await axios.get(`/adopter_dashboard/camp/mynetworkreward/${payload.phoneno}/${payload.campid}`, payload);
      return res;
    } catch (err) {
      throw err;
    }
  }
  async volDashBoardReward(payload) {
    try {
      const res = await axios.get(`/vol_dashboard/myreward/${payload.phoneno}`, payload);
      return res;
    } catch (err) {
      throw err;
    }
  }
  async validatorList(payload) {
    try {
      // alert("VALIDATORLIST API.js")
      // console.log(payload);
      const res = await axios.get(`/validator/list/${payload.phoneno}`, payload);
      // console.log(res)
      return res;
    } catch (err) {
      throw err;
    }
  }
  async userreviewlist(payload) {
    try {
      // alert("VALIDATORLIST API.js")
      // console.log(payload);
      const res = await axios.get(`/user/onboard/list/${payload.phoneno}`, payload);
      // console.log(res)
      return res;
    } catch (err) {
      throw err;
    }
  }
  async rewardsRedeemedList(payload) {
    try {
      // alert("rewardsRedeemedList API.js")
      // console.log(payload);
      const res = await axios.get(`/fulfilment/reward/${payload.phoneno}`, payload);
      // console.log(res)
      return res;
    } catch (err) {
      throw err;
    }
  }
  // async validateReward(payload) {
  //   try {
  //     // console.log(payload);
  //     const res = await axios.post(`validator/reviewaction`, payload);
  //     return res;
  //   } catch (err) {
  //     throw err;
  //   }
  // }

  async validateReward(payload) {
    try {
      // console.log(payload);
      const res = await axios.post(`validator/bulk/reviewaction`, payload);
      return res;
    } catch (err) {
      throw err;
    }
  }
  // async purchaseYaag(payload) {
  //   try {
  //     const res = await axios.post("participatereward", payload);
  //     return res;
  //   } catch (err) {
  //     throw err;
  //   }
  // }
  async purchaseYaag(payload) {
    try {
      const res = await axios.post("participatereward/multiple", payload);
      return res;
    } catch (err) {
      throw err;
    }
  }
  async actionUserOnboardReview(payload) {
    try {
      const res = await axios.put(`user/onboard/action/multiple/${payload.userid}`, payload);
      return res;
    } catch (err) {
      throw err;
    }
  }
  async getRewardRedeemCert(payload) {
    try {
      const res = await axios({
        url: `/upload/redeem/certificate/${payload.userid}/${payload.phoneno}/${payload.rewardtype}/${payload.mpid}`,
        method: "post",
        data: payload.formData,
        headers: {
          "content-type": "multipart/form-data",
        },
      });
      const data = get(res, "data");

      return res;
    } catch (err) {
      throw err;
    }
  }
  async rewardRedeemFulfilment(payload) {
    try {
      const res = await axios.post(`fulfilment/complete/${payload.userid}`, payload);
      
      return res;
    } catch (err) {
      throw err;
    }
  }
}


