import React from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import { isEmpty, isArray } from "lodash";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Avatar, Button } from "@mui/material";
import ResponsiveDrawer from "../components/Header";
import { CopyRight } from "../components/CopyRight";
import { FixedFooterIcon } from "../components/FixedFooterIcon";
import TopHeaderScreen from "../components/TopHeader1";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },
  referral: {
    height: 80,
    textAlign: "center",
    color: "#565656",
    fontSize: 15,
    fontWeight: "400",
    width: "200px",
    fontWeight: "bold",
    // backgroundColor: theme.palette.info.light,
    color: theme.palette.info.dark,
  },
}));

const CampImpactComponent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const locationPath = useLocation();
  const navigate = useNavigate();

  // let { id, label, uploadUrl } = props;
  const [activityList1, setActivityList1] = React.useState("");
  

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const [loading, setLoading] = React.useState(false);

  

  // React.useEffect(() => {
  //   if (!props) {
  //     setLoading(true);
  //   } else {
  //     setLoading(false);
  //   }

  // }, []);

  const splitString = (val) => {
    if (val) {
      // const vallArray = val.split(".");
      const vallArray = val.split("\n\n");

      return (
        <ul>
          {vallArray.map((bulletVal, index) => (
            <li>
              {/* <p>{vallArray[index]}.</p> */}
              <p>{vallArray[index]}.</p>
            </li>
          ))}
        </ul>
      );
    }
  };

  return (
    <div class="containers">
      <div class="cyagg-sec-space">
        {/* <div> */}
        <div class="borderWrapper">
          <div
            class="tab-content customOwlNav row"
            id="animateLineContent-4"
            style={{
              display: "flex",
              flexDirection: "row",
              // alignItems: "center",
            }}
          >
            {/* <div style={{maxWidth:"400px", position:"relative"}}> */}

            <div class="col-md-4 col-sm-12 col-xs-12">
              <div
                class="campimpact-wrap-marketplace"
                style={{ border: "solid" }}
              >
                <h6 style={{ fontSize: "20px", fontWeight: "500" }}>
                  Money Invested ({props?.rows?.[0].currencytype})
                </h6>
                <div style={{ fontSize: "30px", fontWeight: "800" }}>
                  <div>{props?.rows?.[0].moneyinvested}</div>
                </div>
              </div>
            </div>



            <div class="col-md-4 col-sm-12 col-xs-12">
              <div
                class="campimpact-wrap-marketplace"
                style={{ border: "solid" }}
              >
                <h6 style={{ fontSize: "20px", fontWeight: "500" }}>
                  Time Invested (Hours)
                </h6>
                <div style={{ fontSize: "30px", fontWeight: "800" }}>
                  <div>{props?.rows?.[0].timeinvested}</div>
                </div>
              </div>
            </div>

            {/* <div class="col-md-4 col-sm-12 col-xs-12">
              <div
                class="campimpact-wrap-marketplace"
                style={{ border: "solid" }}
              >
                <h6 style={{ fontSize: "20px", fontWeight: "500" }}>
                  Location
                </h6>
                <div style={{ fontSize: "30px", fontWeight: "500" }}>
                  {props?.rows?.[0].location.map((item) => (
                    <div>
                      {item?.country}, {item?.state}, {item?.city}
                    </div>
                  ))}
                </div>
              </div>

            </div> */}
              
            
            {!(props?.rows?.[0]?.unitofgoal === "") ?

            


            <div class="col-md-4 col-sm-12 col-xs-12">
              <div
                class="campimpact-wrap-marketplace"
                style={{ border: "solid" }}
              >
                <h6 style={{ fontSize: "20px", fontWeight: "500" }}>
                 {props?.rows?.[0].unitofgoal}
                </h6>
                <div style={{ fontSize: "30px", fontWeight: "800" }}>
                  <div>{props?.rows?.[0].goalquantity}</div>
                </div>
              </div>
            </div>
            : ""
            }

            {/* </div> */}
            {/* </div> */}
          </div>
        </div>
        {/* </div> */}
        <div class="borderWrapper" style={{ marginTop: "30px" }}>
          <div class="col-md-12">
            <div
              class="tab-content customOwlNav"
              id="animateLineContent-4"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                }}
              >
                <h6 style={{ fontSize: "20px", fontWeight: "500" }}>
                  Impact Assessment
                </h6>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    textAlign: "justify",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "normal",
                      fontSize: "16px",
                      marginTop: "20px",
                    }}
                  >
                    {splitString(props?.rows?.[0].chatgpt1)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        





        {
          !(props?.rows?.[0].chatgpt2 ==="") &&
        <div class="borderWrapper" style={{ marginTop: "30px" }}>
          <div class="col-md-12">
            <div
              class="tab-content customOwlNav"
              id="animateLineContent-4"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                }}
              >
                <h6 style={{ fontSize: "20px", fontWeight: "500" }}>
                {props?.rows?.[0].question2}
                </h6>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    textAlign: "justify",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "normal",
                      fontSize: "16px",
                      marginTop: "20px",
                    }}
                  >
                    {splitString(props?.rows?.[0].chatgpt2)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        }










      </div>
    </div>
  );
};

export default CampImpactComponent;
