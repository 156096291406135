/** @format */

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { userProfile } from "../redux/action/UserProfileActions";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";

function Redirect(props) {
  const userid = localStorage.getItem("userid");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = localStorage.getItem("role");
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const roles = localStorage.getItem("role");
  useEffect(() => {
    if (!userid) {
      navigate("/login");
    } else {
      // role === 'adopter' ? navigate('/home') : navigate('/create_yaag');
      // navigate('/home');
      if (roles == "adopter") {
        navigate("/home");
      }
      if (roles == "exec") {
        navigate("/exec");
      }
      if (roles === "teamadmin") {
        navigate("/CreateTeam");
      }

      if (roles == "investor") {
        navigate("/create_yaag");
      }
      if (roles == "validator") {
        navigate("/validator");
      }
    }
    if (props.location) {
      const refferalId = props.location.search.replace(/[?]/gi, "").split("=");
      localStorage.setItem("refferalPhoneNumber", refferalId[0]);
      localStorage.setItem("refferalId", refferalId[1]);
      localStorage.setItem("reflevel", refferalId[2]);
    }
  }, []);
  return <div></div>;
}

const mapStateToProps = (state) => ({
  login: state.login,
  register: state.register,
  registerSuccess: state.register && state.register.isSuccess,
  loginSuccess: state.login && state.login.isSuccess,
  profileResponse: state.profile && state.profile.response,
  profileSuccess: state.profile && state.profile.isSuccess,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        userProfile,
      },
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Redirect);
