import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "@material-ui/core";
import {
  Avatar,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";

import {
  onboardActivity,
  onboardActivityReset,
  onboardBulkReset,
  onboardCampaign,
  onboardCampaignReset,
  onboardIndividualReset,
  onboardOrganization,
  onboardOrganizationReset,
} from "../../redux/action/OnboardAction";
import CallingCode from "../../services/CallingCode";
import { fetchCityAndState } from "../../services/commonServices";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";
import { API_URL } from "../../services/Constants";
import { getOrganization } from "../../redux/action/getOrganization";
import { activityDuration } from "../../utils/dict";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getVolunteerName } from "../../redux/action/getVolunteerName";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 100px 330px",
    },
  },

  input: {
    width: 200,
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "block",
    fontSize: 14,
    marginTop: 16,
    padding: 10,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
  },
}));

function CreateActivity(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [areas, setAreas] = React.useState([]);
  const [notificationShow, setNotificationShow] = React.useState(false);
  const [activityList, setActivityList] = React.useState(undefined);
  const [activityType, setActivityType] = React.useState("");
  const [volunteerName, setVolunteerName] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [dateofActivity, setdateofActivity] = React.useState(new Date());
  const [activityDescription, setActivityDescription] = React.useState("");
  const [activityDurationDescription, setActivityDurationDescription] =
    React.useState("");
  const [certificate, setCertificate] = React.useState("");
  const [impact, setimpact] = React.useState("");
  const [campaignName, setCampaignName] = React.useState("");
  const [organizationName, setOrganizationName] = React.useState("");
  const {
    isActivitySuccess,
    isActivityFetching,

    isActivityError,
  } = useSelector((state) => state.onBoard);

  const { response: volunteerNameResponse, isSuccess: volunteerNameSuccess } =
    useSelector((state) => state.volunteerName);
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  function subtractYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);

    return date;
  }

  const handleOnboardActivity = () => {
    let formData = new FormData();
    formData.append("activitytype", activityType);
    formData.append(
      "activitydate",
      moment(dateofActivity).format("DD-MM-yyyy")
    );
    formData.append("activitydesc", activityDescription);
    formData.append("activityduration", activityDurationDescription);
    formData.append("activitycert", certificate);
    // formData.append("nsscertificate", nssCertificateReceived);
    formData.append(
      "fullname",
      volunteerName?.firstname +
        " " +
        volunteerName?.middlename +
        " " +
        volunteerName?.lastname
    );
    formData.append("impact", impact);
    formData.append("campaignname", campaignName);
    formData.append("organizationname", organizationName);

    dispatch(
      onboardActivity({
        userid: volunteerName.phoneno,
        formData,
      })
    );
  };

  const getActivityList = async () => {
    try {
      const res = await axios.get(`/activity`);
      setActivityList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const unitOfActivity = activityList?.filter(
    (item, index) => item?.activity === activityType
  );
  React.useEffect(() => {
    dispatch(onboardBulkReset());
    dispatch(onboardIndividualReset());
    dispatch(onboardActivityReset());
    dispatch(onboardCampaignReset());
    dispatch(onboardOrganizationReset());
    setAreas([]);
  }, []);

  React.useEffect(() => {
    if (isActivitySuccess) {
      setActivityType("");
      setVolunteerName("");
      setActivityDescription("");
      setActivityDurationDescription("");
      setCertificate("");
      setimpact("");
      setCampaignName("");
      setOrganizationName("");
      dispatch(onboardActivityReset());
    }
  }, [isActivitySuccess]);
  React.useEffect(() => {
    if (profileResponse) {
      dispatch(
        getVolunteerName({
          organization: profileResponse?.organization,
          userid: profileResponse?.phoneno,
        })
      );
    }
  }, [profileResponse]);

  React.useEffect(() => {
    dispatch(getOrganization({ userid: localStorage.getItem("userid") }));
    getActivityList();
  }, []);

  return (
    <div>
      <ResponsiveDrawer {...props} />
      <main
        onClick={() => {
          props.setNotificationShow(false);
        }}
        className={classes.homeSreenMainContent}
        id="homepage-main"
      >
        <FormControl>
          <div style={{ border: "1px solid #dadce0", borderRadius: 8 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <div className={classes.formGroup}>
                <label className={classes.label}>Activity Type</label>
                <Select
                  style={{
                    width: 200,
                    fontSize: 14,
                    height: 56,
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  }}
                  value={activityType}
                  autoFocus={false}
                  onChange={(event) => setActivityType(event.target.value)}
                >
                  {activityList?.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.activity}>
                      {item.activity}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Volunteer Name
                </label>
                <Select
                  style={{
                    width: 200,
                    fontSize: 14,
                    height: 56,
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  }}
                  value={volunteerName}
                  onChange={(event) => setVolunteerName(event.target.value)}
                >
                  {volunteerNameSuccess &&
                    volunteerNameResponse?.map((item, index) => (
                      <MenuItem
                        style={{ fontSize: 16 }}
                        value={item}
                        onChange={() => setUserId(item.phoneno)}
                      >
                        {item.firstname +
                          " " +
                          item.middlename +
                          " " +
                          item.lastname}
                      </MenuItem>
                    ))}
                </Select>
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Date of Activity
                </label>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    style={{ boxShadow: "0px 3px 6px #00000029" }}
                    inputFormat={"dd-MM-yyyy"}
                    selected={dateofActivity}
                    maxDate={subtractYears(0)}
                    onChange={(date) => {
                      setdateofActivity(date);
                    }}
                    renderInput={(params) => (
                      <TextField
                        style={{
                          width: 150,
                          height: 56,
                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                        }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
                {/* <DatePicker
                  style={{
                    height: 56,
                  }}
                  selected={dateofActivity}
                  maxDate={subtractYears(0)}
                  onChange={(date) => {
                    setdateofActivity(date);
                    // setValue5(date);
                  }}
                  dateFormat={"dd-MM-yyyy"}
                  placeholderText="dd-MM-yyyy"
                /> */}
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Activity Description
                </label>
                <input
                  value={activityDescription}
                  type="text"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your answer"
                  onChange={(e) => {
                    setActivityDescription(e.target.value);
                  }}
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Activity Duration in mins
                </label>
                <Select
                  style={{
                    width: 200,
                    fontSize: 14,
                    height: 56,
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  }}
                  value={activityDurationDescription}
                  onChange={(event) =>
                    setActivityDurationDescription(event.target.value)
                  }
                >
                  {activityDuration.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.value}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Impact
                </label>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <input
                    value={impact}
                    type="number"
                    style={{
                      WebkitAppearance: "textfield",
                      margin: 0,
                      MozAppearance: "textfield",
                    }}
                    id="create-yaad--title"
                    className={classes.input}
                    placeholder="Your Answer"
                    onChange={(e) => {
                      setimpact(e.target.value);
                      // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                    }}
                  />
                  <span style={{ marginLeft: 4 }}>
                    {unitOfActivity?.[0]?.unit}
                  </span>
                </div>
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Campaign Name
                </label>

                <input
                  value={campaignName}
                  type="text"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your Answer"
                  onChange={(e) => {
                    setCampaignName(e.target.value);
                    // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                  }}
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Organization Name
                </label>

                <input
                  value={organizationName}
                  type="text"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your Answer"
                  onChange={(e) => {
                    setOrganizationName(e.target.value);
                    // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                  }}
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Certificate
                </label>
                <input
                  value={certificate}
                  type="text"
                  id="create-yaad--title"
                  className={classes.input}
                  placeholder="Your Answer"
                  onChange={(e) => {
                    setCertificate(e.target.value);
                    // setFormQ0([...formQ0, { formQuestion: e.target.value }]);
                  }}
                />
              </div>
            </div>
            <div style={{ padding: "16px 0", bottom: 50 }}>
              <Button
                style={{ marginLeft: 20 }}
                variant="contained"
                onClick={handleOnboardActivity}
                className={classes.goalButton}
                disableElevation
                disabled={
                  isActivityFetching ||
                  !activityType ||
                  !volunteerName ||
                  !dateofActivity ||
                  !activityDescription ||
                  !activityDuration ||
                  !impact ||
                  !campaignName ||
                  !organizationName ||
                  !certificate
                }
              >
                {isActivityFetching ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Submit"
                )}
              </Button>
              <br />
              {isActivitySuccess && (
                <span style={{ color: "green" }}>
                  Activity updated sucessfully
                </span>
              )}
              {isActivityError && (
                <span style={{ color: "red" }}>Something error occured</span>
              )}
            </div>
          </div>
        </FormControl>
      </main>
      <CopyRight />
    </div>
  );
}
export default CreateActivity;
