import {
  makeStyles,
  Box,
  GridList,
  GridListTile,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import {
  AcitvityTableComponent,
  CampaignTableComponent,
  TableComponent,
} from "../../components/Table";
import { getVolunteerName } from "../../redux/action/getVolunteerName";
import { userProfile } from "../../redux/action/UserProfileActions";
import { API_URL } from "../../services/Constants";
import { HomeCard } from "../../components/Card";
import TopHeaderScreen from "../../components/TopHeader1";
import { getCampaign } from "../../redux/action/CampaignAction";
import { volunteerMenuItems } from "../../utils/dict";
import CustomDrawer from "../../components/CustomHeader";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    background: "#F5F8FB",
    // minHeight: "100vh",
    width: "max-content",
    minWidth: "100vw",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "0px 30px 100px 25px",
    },
  },
  tabName: {
    color: "#fff",
    textTransform: "none",
  },
  gridList: {
		flexWrap: 'nowrap',
		transform: 'translateZ(0)',
	},
  myImpacts: {
		padding: '15px 0 0 0',
		fontSize: 14,
		textAlign: 'center',
		fontWeight: 'bold',
		textTransform: 'uppercase',
	},
}));

const ActivityListScreen = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activityList, setActivityList] = useState([]);
  const [value, setValue] = React.useState(0);
  const [activeTrue, setActiveTrue] = React.useState(false);

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getActivityList = async () => {
    try {
      // console.log(profileResponse)
      const res = await axios.get(
        `vol_dashboard/camp/${profileResponse?.orgrole}/${profileResponse?.organization}`
        // `vol_dashboard/camp/${localStorage.getItem("orgrole")}/${localStorage.getItem("organization")}`
        
      );
      setActivityList(res?.data);
      return res;
    } catch (err) {
        // const error = err as AxiosError
        // if (error.response)
        //   console.log(error.response.data)
        throw err;

    }
  };
  useEffect(() => {
    dispatch(userProfile(localStorage.getItem("userid")));
    dispatch(getCampaign(profileResponse?.orgrole, profileResponse?.organization))
  }, []);
  useEffect(() => {
    getActivityList();
    
    getCampaign({
      orgrole: profileResponse?.orgrole,
      organization: profileResponse?.organization,
    })
  }, [profileResponse]);

  return (
    <div className="wrapper">
      {/* {
        profileSuccess &&
          (profileResponse?.orgrole?.toLowerCase() === "hyphenadmin" ||
            profileResponse?.orgrole?.toLowerCase() === "admin" ? (
            <ResponsiveDrawer {...props} />
          ) : (
            <CustomDrawer menuItems={volunteerMenuItems} {...props} />
          ))
      } */}


      <ResponsiveDrawer {...props} />
      <section className="containers">
      <TopHeaderScreen {...props} />
      <div class="content content-wrap"><div class="content-mrg">
     
       <div class="content-title">Campaign list</div> 

        {/* <CampaignTableComponent rows={activityList} /> */}
        <Tabs
          style={{
            background: "#1A524D 0% 0% no-repeat padding-box",
            width: 585,
            borderRadius: 20,
          }}
          value={value}
          onChange={handleChange}
        >
          <Tab label="Upcoming Campaigns" className={classes.tabName} />
          <Tab label="Active Campaigns" className={classes.tabName} />
          <Tab label="Completed Campaigns" className={classes.tabName} />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div className={classes.myImpacts}>
          {/* <div className={classes.gridList} cellHeight={450} style={{display:"grid",gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",gridGap: "1rem"}}> */}
            <GridList className={classes.gridList} cellHeight={450}>
              {activityList
                ?.filter((item, index) => item.active === "Upcoming")
                .map((tile) => (
                  <GridListTile
                    style={{ width: "auto !important" }}
                    key={tile.image}
                  >
                    <HomeCard
                      isCampaignScreen
                      data={tile}
                      key={tile.cardTitle}
                      userDetail={profileResponse ? profileResponse : ""}
                    />
                  </GridListTile>
                ))}
            </GridList>
          </div>

          {activeTrue && (
            <div className={classes.myImpacts}>
              <GridList className={classes.gridList} cellHeight={450}>
                <GridListTile
                  style={{
                    width: "300px",
                    height: "300px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: "rgba(0,0,0,0.3)",
                  }}
                  // key={tile.image}?
                >
                  <Typography style={{ color: "#fff" }}>
                    no active yaags right now
                  </Typography>
                </GridListTile>
              </GridList>
            </div>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className={classes.myImpacts}>
            <GridList className={classes.gridList} cellHeight={450}>
              {activityList
                ?.filter((item, index) => item.active === "Active")
                .map((tile) => (
                  <GridListTile
                    style={{ width: "auto !important" }}
                    key={tile.image}
                  >
                    <HomeCard
                      isCampaignScreen
                      data={tile}
                      key={tile.cardTitle}
                      userDetail={profileResponse ? profileResponse : ""}
                    />
                  </GridListTile>
                ))}
            </GridList>
          </div>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <div className={classes.myImpacts}>
            <GridList className={classes.gridList} cellHeight={450}>
              {activityList
                ?.filter((item, index) => item.active === "Completed")
                .map((tile) => (
                  <GridListTile
                    style={{ width: "auto !important" }}
                    key={tile.image}
                  >
                    <HomeCard
                      isCampaignScreen
                      data={tile}
                      key={tile.cardTitle}
                      userDetail={profileResponse ? profileResponse : ""}
                    />
                  </GridListTile>
                ))}
            </GridList>
          </div>
        </TabPanel>
      </div>
      </div>
      <CopyRight />
      </section>
    </div>
  );
};

export default ActivityListScreen;
