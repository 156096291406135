import React, { useState, useRef } from 'react';
import { Dialog, Grid, Box, Typography, DialogContent, Avatar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, IconButton, Snackbar } from '@mui/material';
import ResponsiveDrawer from "../../components/Header";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ColorModeContext, useMode, tokens } from "../../theme";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";

import SmsIcon from '@mui/icons-material/Sms';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PhoneIcon from '@mui/icons-material/Phone';
import { format, parseISO } from 'date-fns';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "0px 30px 100px 25px",
      background: "#F5F8FB",
    },

  },
  modalContainer: {
    zIndex: 1500, // You can adjust the value as needed
    // ... other styles
  },
}));

const CustomerDetailsPopup = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const locationPath = useLocation();
  const [theme, colorMode] = useMode();
  const [customerDetList, setCustomerDetList] = useState([]);
  const [smsviaSNS, setSMSviaSNS] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const tableContainerRef = useRef(null);
  

  // console.log(`/profile/user/${customer.phoneno}`)
  React.useEffect(() => {
    getCustomerList();
  }, []);

  console.log("locationPath?.pathname.split()?.[2] = ", locationPath?.pathname.split("/")?.[2])
  const getCustomerList = async () => {
    try {
      // const res = await axios.get(`/kt/get/customer/details/${locationPath?.pathname.split("/")?.[2]}`);
      const res = await axios.get(`/kt/get/customer/trn/details/${locationPath?.pathname.split("/")?.[2]}`);
      setCustomerDetList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const formatCurrency = (amount) => {
    return `₹${amount}`;
  };

  const formatDate = (paymentdate) => {
    try {
      const parsedDate = parseISO(paymentdate);
      return format(parsedDate, 'dd MMM yyyy - hh:mm a');
    } catch (error) {
        console.error("Error formatting date:", error.message);
        return "Invalid Date";
    }
};

// Assuming monthNames is an array of month names
const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];


// const handleReportClick = () => {
//   if (tableContainerRef.current) {
//     html2canvas(tableContainerRef.current).then(canvas => {
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF();
//       pdf.addImage(imgData, 'PNG', 0, 0);
//       pdf.save('report.pdf');
//     });
//   }
// };

const handleReportClick = () => {
  if (tableContainerRef.current) {
    html2canvas(tableContainerRef.current).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      
      // Adjust the x, y position to center the image in the PDF
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pdfWidth;
      const imgHeight = (canvas.height * pdfWidth) / canvas.width;

      const offsetX = (pdfWidth - imgWidth) / 2;
      const offsetY = (pdfHeight - imgHeight) / 2;

      pdf.addImage(imgData, 'PNG', offsetX, offsetY, imgWidth, imgHeight);
      const fileName = `report_${customerDetList?.[0]?.name || 'unknown'}.pdf`;
      pdf.save(fileName);
    });
  }
};
  const handleSmsIconClick = async () => {
    try {
      // Replace 'your-api-endpoint' with the actual API endpoint
      const res = await axios.get(`/sendsms/sns/${customerDetList?.[0]?.phoneno}/${customerDetList?.[0]?.name}/${customerDetList?.[0]?.balanceamount}`);
      setSMSviaSNS(res?.data);

      setSnackbarMessage(res?.data?.message);
      setSnackbarOpen(true);

      // Close the Snackbar after 3 seconds
      setTimeout(() => {
        setSnackbarOpen(false);

        // navigate(`/kt/customer/list`);
      }, 3000);

    } catch (error) {
      console.error('Error calling API:', error);
      // Handle error or display a message to the user
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  console.log("customerDetList = ", customerDetList)

  const handleRowClick = (customerId) => {
    // Navigate to the new component and pass customerDetList as state
    navigate(`/edit/entry/${customerId}`, { state: { customerDetList } });
  };


  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg hasScreen">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <Grid container spacing={2}>
              <Grid item xs={12} md={9}>
                <Box display="flex" flexDirection="row" alignItems="flex-start" marginTop="30px">
                  <Avatar>{customerDetList?.[0]?.avatar}</Avatar>
                  <Typography variant="h5">
                    {customerDetList?.[0]?.name}
                    <span
                      className="float-r"
                      data-toggle="modal"
                      data-target="#myModal"
                    ></span>
                    <div id="contentz" className="new-pro-name-secs" style={{ basicDetail: '' }}>
                      <div id="horz-list">
                        <ul>
                          <a href="#" style={{ cursor: "initial" }}>
                            
                            <PhoneIcon
                              style={{
                                color: "#808080",
                                cursor: "initial",
                                height: "26px",
                                fontSize: "16px"

                              }}
                            />
                            <span
                              style={{
                                verticalAlign: "super",
                                color: "#808080",
                                cursor: "initial",
                                fontSize: "14px",
                                marginLeft: "2px",
                              }}
                            >
                              {customerDetList?.[0]?.phoneno}
                            </span>
                          </a>
                        </ul>
                      </div>
                    </div>
                  </Typography>
                </Box>
              </Grid>
            </Grid>

          

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleSnackbarClose}
              message={snackbarMessage}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <div style={{ background: 'green', padding: '16px', borderRadius: '8px', fontSize: '18px', textAlign: 'center' }}>
                {snackbarMessage}
              </div>
            </Snackbar>


            <hr style={{ flex: 1, borderColor: 'rgba(0, 0, 0, 0.12)', height: '2px', margin: '0px' }} />
            <div >
              <div style={{ display: 'flex', alignItems: 'center', margin: '0 8px' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                  <PictureAsPdfIcon fontSize="large" onClick={handleReportClick} style={{ cursor: 'pointer' }} />
                  <div style={{ fontSize: "13px", marginLeft: '4px' }}>
                    <span>Report</span>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '16px' }}>
                  <IconButton color="primary" onClick={handleSmsIconClick}>
                    <SmsIcon fontSize="large" />
                  </IconButton>
                  <div style={{ fontSize: "13px" }}>
                    <span>SMS</span>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '26px' }}>
                  
                  <span style={{fontSize: "24px"}}>₹</span>
                  <div style={{ fontSize: "13px", marginLeft: '4px' }}>
                    <span>Payment</span>
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ flex: 1, borderColor: 'rgba(0, 0, 0, 0.12)', height: '2px', margin: '0px' }} />


            <TableContainer ref={tableContainerRef}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: '110px', fontSize: '12px' }}>ENTRIES</TableCell>
                    <TableCell style={{ minWidth: '80px', fontSize: '12px', whiteSpace: 'nowrap' }}>YOU GAVE</TableCell>
                    <TableCell style={{ minWidth: '80px', fontSize: '12px' }}>YOU GOT</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {

                    customerDetList?.map((item, index) => (
                      item?.type === "You Gave" ? (
                        <TableRow 
                        key={index} 
                        style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff' , cursor: "pointer"}}
                        
                        onClick={() => navigate(`/edit/entry/${customerDetList?.[0]?.customerid}/${customerDetList?.[0]?.name}/${(item?.payment)}/${formatDate(item?.paymentdate)}/${item?.balanceamount}/${"You Gave"}/${customerDetList?.[0]?.phoneno}/${item?.trnid}`)}
                        
                        >
                          
                          <TableCell style={{ minWidth: '110px', fontSize: '12px' }}>
                            {formatDate(item?.paymentdate)}
                            <br></br>
                            Bal. {formatCurrency(item?.balanceamount)}
                          </TableCell>
                          <TableCell style={{ color: 'red', minWidth: '80px', fontSize: '12px' }}>
                            {formatCurrency(item?.payment)}
                          </TableCell>
                          <TableCell style={{ color: 'green', minWidth: '80px', fontSize: '12px' }}>
                            -
                          </TableCell>
                        </TableRow>
                      ) : item?.type === "You Got" ? (
                        <TableRow 
                        key={index} 
                        style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff', cursor: "pointer" }}
                        onClick={() => navigate(`/edit/entry/${customerDetList?.[0]?.customerid}/${customerDetList?.[0]?.name}/${(item?.payment)}/${formatDate(item?.paymentdate)}/${item?.balanceamount}/${"You Got"}/${customerDetList?.[0]?.phoneno}${item?.trnid}`)}
                        // onClick={() => handleRowClick(customerDetList?.[0]?.customerid)}
                        >
                          <TableCell style={{ minWidth: '110px', fontSize: '12px' }}>
                            {formatDate(item?.paymentdate)}
                            <br></br>
                            Bal. {formatCurrency(item?.balanceamount)}
                          </TableCell>
                          <TableCell style={{ color: 'red', minWidth: '80px', fontSize: '12px' }}>
                            -
                          </TableCell>
                          <TableCell style={{ color: 'green', minWidth: '80px', fontSize: '12px' }}>
                            {formatCurrency(item?.payment)}
                          </TableCell>
                        </TableRow>
                      ) : ""
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', marginBottom: '30px' }}>
              <Button
                variant="contained"
                onClick={() => navigate(`/yougave/${customerDetList?.[0]?.phoneno}/${customerDetList?.[0]?.name}/${customerDetList?.[0]?.customerid}`)}
                style={{ backgroundColor: 'red', color: 'white', width: "120px", height: "30px", cursor: "pointer" }}
              >
                You Gave {" "} {formatCurrency("")}
              </Button>
              <Button
                variant="contained"
                // onClick={() => setOpenYouGotPopup(true)}
                onClick={() => navigate(`/yougot/${customerDetList?.[0]?.phoneno}/${customerDetList?.[0]?.name}/${customerDetList?.[0]?.customerid}`)}
                
                style={{ backgroundColor: 'darkgreen', color: 'white', width: "120px", height: "30px", cursor: "pointer" }}
              >
                You Got {" "} {formatCurrency("")}
              </Button>
            </div>
          </div>
        </div>
      </section>
    </div>

  );
};

export default CustomerDetailsPopup;
