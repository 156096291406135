import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { Card, makeStyles } from "@material-ui/core";
import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { city, states } from "../utils/constants";
import {
  ActivityChartComponent,
  ChartComponent,
  LineChartComponent,
} from "./chartComponent";
import { useDispatch, useSelector } from "react-redux";
import { getStatesUT } from "../redux/action/statesUTAction";
import { getCity, getCityReset } from "../redux/action/cityAction";
import {
  getReportUserInActivity,
  getReportUserInCity,
} from "../redux/action/getReport";
import MapComponent from "./mapComponent";
import { activity } from "../utils/dict";

const useStyles = makeStyles((theme) => ({
  createScreenMainContent: {
    padding: "80px 50px 150px 0",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 150px 220px",
    },
  },
}));

export default function ExecDashboard(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [cityValue, setCityValue] = React.useState(null);
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [allStates, setAllStates] = React.useState("All States");
  const { isSuccess, response: volunteerCountResponse } = useSelector(
    (state) => state?.getVolunteerCount
  );

  const { response: statesResponse } = useSelector((state) => state.states);
  const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
    (state) => state.cities
  );
  const {
    stateResponse,
    isStateSuccess,
    isStateError,
    isStateFetching,
    activiyResponse,
    isActivitySuccess,
    isActivityError,
    isActivityFetching,
  } = useSelector((state) => state.reportInfo);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setValue(event.target.value);
    dispatch(getCityReset());
  };
  const handleCityChange = (event) => {
    setCityValue(event.target.value);
  };
  const handleButtonChange = () => {
    setLoading(true);
    setShow(true);
    dispatch(getReportUserInActivity());
    dispatch(getReportUserInCity(value));
  };

  React.useEffect(() => {
    if (props?.response) {
      dispatch(getStatesUT(props?.response?.[0]?.nssrole));

      if (value) {
        dispatch(getCity(value));
      }
    }
  }, [props?.response, value]);

  React.useEffect(() => {
    if (isActivitySuccess || isStateSuccess) {
      setLoading(false);
    }
  }, [isActivitySuccess, isStateSuccess]);

  const concatenatedData = statesResponse?.[0]?.concat(statesResponse?.[1]);
  const finalData = concatenatedData?.concat({ name: "All States" });

  const sortedData = finalData?.sort(function (a, b) {
    if (a.name < b.name) return -1;
    else if (a.name > b.name) return 1;
    return 0;
  });

  return (
    <Box sx={{ minWidth: 200 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          width: "50%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <InputLabel id="demo-simple-select-label">Select State</InputLabel>
          <Select
            style={{ width: 200 }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            onChange={handleChange}
          >
            {sortedData?.map((item, index) => (
              <MenuItem style={{ fontSize: 16 }} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <InputLabel id="demo-simple-select-label">Select City</InputLabel>
          <Select
            style={{ width: 200 }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={cityValue}
            disabled={!value}
            onChange={handleCityChange}
          >
            {citiesSuccess &&
              citiesResponse.map((item, index) => (
                <MenuItem style={{ fontSize: 16 }} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </div>

        <div style={{ padding: "16px 0", bottom: 50, left: 20 }}>
          <Button
            variant="contained"
            // onClick={handleForm}
            className={classes.goalButton}
            disabled={!value}
            onClick={handleButtonChange}
            // onClick={handleOnboardIndividual}
          >
            Submit
          </Button>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        <MapComponent />
        <div style={{ width: "500px" }}>
          <div>
            <span>Summary</span>
            <ul>
              <li>
                Total number of volunteers:
                {isSuccess ? volunteerCountResponse : null}
              </li>{" "}
              <li>Total number of hours:</li> <li>55% Male, 45% Female</li>
            </ul>
          </div>
          <div>
            <span>Impact :</span>
            <ul>
              {activity.map((item) => (
                <li>{item.value}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {loading ? (
        <CircularProgress color="inherit" size={24} />
      ) : (
        show && (
          <div style={{ marginTop: 32 }}>
            <ChartComponent response={stateResponse} />
            <ActivityChartComponent response={activiyResponse} />
          </div>
        )
      )}



        
          {/* <div style={{ marginTop: 32 }}>
            <ChartComponent response={stateResponse} />
            <ActivityChartComponent response={activiyResponse} />
          </div> */}
        


    </Box>
  );
}
