import React from 'react'
import { Modal, Button } from "react-bootstrap";
 
const PauseConfirmation = ({ showModal, hideModal, confirmModal, message }) => {

    return (
        <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Pause Campaign Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body><div className="alert alert-danger">{message}</div></Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={hideModal}>
            Cancel
          </Button>
          {/* <Button variant="danger" onClick={() => confirmModal(type, id) }> */}
          <Button variant="danger" onClick={() => confirmModal() }>
            Pause
          </Button>
        </Modal.Footer>
      </Modal>
    )
}
 
export default PauseConfirmation;