import React, { useState, useEffect } from "react";
import queryString from "query-string";
import io from "socket.io-client";

import TextContainer from "../TextContainer/TextContainer";
import Messages from "../Messages/Messages";
import InfoBar from "../InfoBar/InfoBar";
import Input from "../Input/Input";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import ResponsiveDrawer from "../Header";
import { CopyRight } from "../CopyRight";
import TopHeaderScreen from "../TopHeader1";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { TextContainerComponent } from "../Table";

import "./Chat.css";

const useStyles = makeStyles((theme) => ({
  pageHeading: {
    textTransform: "uppercase",
    // fontSize: 14,
    background: theme.palette.primary.main,
    textAlign: "center",
    color: "#fff",
    padding: 3,
    fontWeight: "bold",
    // borderRadius: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  // pageHeadingIcon: {
  //   fontSize: 25,
  //   // color: theme.palette.secondary.main,
  //   marginLeft: 8,
  // },
  backBtn: {
    display: "flex",
    alignItems: "center",
  },
}));

// const ENDPOINT = 'http://localhost:5000';
const ENDPOINT = 'https://stagechatapi.cyaag.com';
let socket;

const Chat = ({ location, props }) => {
  const [name, setName] = useState("");
  const [room, setRoom] = useState("");
  const [users, setUsers] = useState("");
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const locationPath = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  // console.log(profileResponse);
  // console.log(locationPath);

  function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
  const [count, setCount]=React.useState(0);

  useEffect(() => {
    setCount(count+1);
  
    socket = io(ENDPOINT, {
      "force new connection" : true,
        "reconnectionAttempts": "Infinity", 
        // "timeout" : 10000, 
        "transports": ['websocket', 'polling', 'flashsocket'],            
    });



    // while (socket.connected === true) {
      setName(profileResponse?.firstname);

      setRoom(locationPath?.pathname.split("/")?.[3]);

      // sleep(4000).then(()=>{

      // console.log("calling socket,emit");
      // socket.emit('join', { name, room }, (error) => {
      if (name && room) {
        socket.emit("join", { name, room }, (error) => {
          // console.log("emit client name = ", name);
          // console.log("emit client room =", room);
          // count = count + 1;
          // console.log("count  = ", count);
          if (error) {
          
            alert(error);
          }
        });
      }
    // }

    // })
 
 
  // }, [profileSuccess]);
}, [profileSuccess]);



  // useEffect(() => {

  //   sleep(4000).then(()=>{
  //     console.log(name);
  //   console.log(room);
  //     console.log("calling socket,emit");
  //     // socket.emit('join', { name, room }, (error) => {
  //       socket.emit('join', { name, room }, (error) => {
  //       console.log("emit client name = ", name);
  //       console.log("emit client room =", room);
  //       if(error) {
  //         console.log(error);
  //         alert(error);
  //       }
  //     });
  // })

  // }, [name]);

  useEffect(() => {
    socket.on("message", (message) => {
      setMessages((messages) => [...messages, message]);
     
    });

    socket.on("roomData", ({ users }) => {
      setUsers(users);
    });
  }, [room]);

  const sendMessage = (event) => {
    event.preventDefault();
  
    if (message) {
      socket.emit("sendMessage", message, () => setMessage(""));
     
    }
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <span  style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5,display:"block" }}>
            {/* <span className={classes.pageSubHeading} > */}
                    cYAAG Chat 
                </span>
            <div className="outerContainer">
              <div className="chatcontainer">
                <InfoBar room={room} />
                <Messages messages={messages} name={name} />
                <Input
                  message={message}
                  setMessage={setMessage}
                  sendMessage={sendMessage}
                />
              </div>
              {/* <TextContainer users={users}/> */}
              {/* <TextContainerComponent users={users}/> */}
              
            </div>
            <div className="tableouterContainer">
            <div className="tablecontainer">
                
              <TextContainerComponent users={users} />
              
              </div>
              </div>
            {/* <TextContainerComponent users={users} /> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Chat;
