/** @format */

// reducers
import {
  GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA,
  GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_FAILURE,
  GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_RESET,
  GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_SUCCESS,
  GET_REPORT_USER_IN_STATE_FETCHING_DATA,
  GET_REPORT_USER_IN_STATE_FETCHING_DATA_FAILURE,
  GET_REPORT_USER_IN_STATE_FETCHING_DATA_RESET,
  GET_REPORT_USER_IN_STATE_FETCHING_DATA_SUCCESS,
  GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA,
  GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_FAILURE,
  GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_RESET,
  GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_SUCCESS,
} from "../action/getReport";

let initialState = {
  stateResponse: {},
  isStateSuccess: false,
  isStateError: false,
  isStateFetching: false,

  activiyResponse: {},
  isActivitySuccess: false,
  isActivityError: false,
  isActivityFetching: false,

  SOVolOrgResponse: {},
  isSOVolOrgSuccess: false,
  isSOVolOrgError: false,
  isSOVolOrgFetching: false,
};

export default function GetReportReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA:
      return {
        ...state,
        isActivityFetching: true,
        isActivityError: false,
        isActivitySuccess: false,
      };
    case GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_FAILURE:
      return {
        ...state,
        isActivityFetching: false,
        isActivityError: action.payload,
        isActivitySuccess: false,
      };
    case GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_SUCCESS:
      return {
        ...state,
        isActivityFetching: false,
        isActivityError: false,
        activiyResponse: action.payload,
        isActivitySuccess: true,
      };

    case GET_REPORT_USER_IN_ACTIVITY_FETCHING_DATA_RESET:
      return {
        ...state,
        activiyResponse: {},
        isActivitySuccess: false,
        isActivityError: false,
        isActivityFetching: false,
      };

    case GET_REPORT_USER_IN_STATE_FETCHING_DATA:
      return {
        ...state,
        isStateFetching: true,
        isStateError: false,
        isStateSuccess: false,
      };
    case GET_REPORT_USER_IN_STATE_FETCHING_DATA_FAILURE:
      return {
        ...state,
        isStateFetching: false,
        isStateError: action.payload,
        isStateSuccess: false,
      };
    case GET_REPORT_USER_IN_STATE_FETCHING_DATA_SUCCESS:
      return {
        ...state,
        isStateFetching: false,
        isStateError: false,
        stateResponse: action.payload,
        isStateSuccess: true,
      };

    case GET_REPORT_USER_IN_STATE_FETCHING_DATA_RESET:
      return {
        ...state,
        stateResponse: {},
        isStateSuccess: false,
        isStateError: false,
        isStateFetching: false,
      };

      case GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA:
      return {
        ...state,
                
        isSOVolOrgSuccess: false,
        isSOVolOrgError: false,
        isSOVolOrgFetching: true,
      };
    case GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_FAILURE:
      return {
        ...state,
       
        isSOVolOrgSuccess: false,
        isSOVolOrgError: action.payload,
        isSOVolOrgFetching: false,
      };
    case GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_SUCCESS:
      return {
        ...state,

        SOVolOrgResponse: action.payload,
        isSOVolOrgSuccess: true,
        isSOVolOrgError: false,
        isSOVolOrgFetching: false,
      };

    case GET_REPORT_VOL_IN_ORGANIZATION_FETCHING_DATA_RESET:
      return {
        ...state,
        
        SOVolOrgResponse: {},
        isSOVolOrgSuccess: false,
        isSOVolOrgError: false,
        isSOVolOrgFetching: false,
      };

    default:
      return state;
  }
}
