import React, { useState, useEffect, useRef } from "react";
import { DateRange } from 'react-date-range';
import { addDays } from "date-fns";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { MenuItem, Select, Typography } from "@mui/material";
import { InputDisable } from "./InputDisableComponent";
import { InputEnable } from "./InputEnableComponent";
import { CircularProgress, makeStyles, Checkbox } from "@material-ui/core";
import { restrictedCountry } from "../utils/dict";
import { getCity } from "../redux/action/cityAction";
import { useDispatch, useSelector } from "react-redux";
import { states } from "../utils/constants";
import axios from "axios";
import { userProfile } from "../redux/action/UserProfileActions";
import { getCampaign } from "../redux/action/CampaignAction";
import { myReward } from "../redux/action/MyRewardAction";
import moment from "moment";
import { API_URL } from "../services/Constants";
import { useNavigate, useLocation } from "react-router-dom";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import format from 'date-fns/format';
import { CopyRight } from "../components/CopyRight";
import Multiselect from 'multiselect-react-dropdown';
import _ from "lodash";
import ErrorMessage from "../components/ErrorMessage";
// import MuiAlert from "@mui/material/Alert";
// import MuiAlert from "@material-ui/lab/Alert";
import Alert from "../components/Alert";
// import {Alert} from 'react-alert';
import ReactJsAlert from "reactjs-alert";
import RedStar from "../components/RedStar";
import { style } from "@mui/system";
import ScrollIntoView from 'react-scroll-into-view';


const useStyles = makeStyles((theme) => ({
  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
    fontFamily: "Poppins !important",
  },
  goalButtonDisable: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
    fontFamily: "Poppins !important",
    "&:hover": {
      color: "rgba(0, 0, 0, 0.9)",
    },
    // cursor: "not-allowed"
    cursor: "pointer",
  },
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "1px solid #C7C1C1",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "block",
    fontSize: 14,
    marginTop: 12,
    padding: 10,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  labellocation: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "flex",
    fontSize: 14,
    marginTop: 12,
    // padding: 10,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  labellocationnew: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "flex",
    fontSize: 14,
    marginTop: 12,
    // padding: 10,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    padding: "0px 0px 35px 0px",
  },
  calendarWrap: {
    display: "inline-block",
    position: "relative",
  },
  calendarElement: {
    position: "absolute",
    left: 1,
    top: 30,
    border: "1px solid",

  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    // padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    marginRight: 16,
    // padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
  },
}));

export const ResponsiveDialog = ({ contentText, open, setOpen, data, question }) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fieldRef1 = React.useRef();
  const fieldRef2 = React.useRef();
  const fieldRef3 = React.useRef();
  // const alert = useAlert();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /* For alerts */
  const [status, setStatus] = useState(false);
  const [type, setType] = useState("success");
  const [title, setTitle] = useState("This is a alert");
  const [show, setShow] = React.useState(false);


  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "" },
  ]);
  const [activityList, setActivityList] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const locationPath = useLocation();
  const [response, setResponse] = useState({});
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );
  const [campaignList, setCampaignList] = useState(undefined);
  const [regStatusList, setRegStatusList] = useState(undefined);



  //   const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
  //     (state) => state.profile
  // );
  // const { isCampaignSuccess, isCampaignFetching, campaignResponse, sCampaignError, } = useSelector(
  //   (state) => state.onBoard);

  const { isSuccess: campaignSuccess, response: campaignResponse } = useSelector(
    (state) => state.onBoard
  );
  // console.log(data)


  const [checked, setChecked] = React.useState([]);


  // const Alert = React.forwardRef(function Alert(props, ref) {
  //   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  // });

  // function Alert(props) {
  //   return <MuiAlert elevation={6} 
  //                    variant="filled" {...props} />;
  // }

  const getLocation = () => {
    try {
      // alert("CALLED")
      // if (data?.[0]?.location.map) {
      // const res = data?.[0]?.location.map();
      // const res = data?.[0]?.location.map((item, index) => (index, item.state))
      // setChecked(res?.data);
      setChecked(data?.[0]?.location.map((item, index) => (index, item.state)));
      // console.log(checked);
      // console.log(res)
      // return res;
      // }
    } catch (err) {
      throw err;
    }
  };

  // console.log(data?.[0]?.location.map((item, index) => (index, item.state)))
  // console.log(data?.[0]?.location.map((item, index) => (index)))
  // console.log(data?.[0]?.location.map((item, index) => (item.state)))

  const handleChange = (event, index) => {
    // setChecked({ ...state, [event.target.name]: event.target.checked });
    const value = event.target.value;
    // console.log(value);
    setChecked([value]);
  };

  // const handleChangeSkill = (event, index) => {
  //   const value = event.target.value;
  //   console.log(value);

  //   setChecked([...checked, value[value?.length - 1]]);
  // };

  const [campaignValues, setCampaignValues] = React.useState({
    titleOfCampaign: "",
    descriptionOfCampaign: "",
    typeOfCampaign: "",
    volunteerCount: "",
    impact: "",
    visibility: "",
    startDate: new Date(),
    endDate: new Date(),
    durationOfCampaign: "",
    goal: "",
    campaignType: "",
    hourOfParticipation: "",
    socialMediaMessage: "",
    campaignLogo: "",
    campaignVideo: "",
  });

  const [campaignRegistrationValuesError, setCampaignRegistrationValuesError] = React.useState({
    timeCommitmentCampaignError: "",
    impactCommitmentCampaignError: "",
    locationCampaignError: "",
    dateSelection: new Date(),
  });

  const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
    (state) => state.cities
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleChangeLocation = (e, field) => {
  //   const values = [...location];
  //   values[0][field] = e.target.value;
  //   setLocation(values);
  // };

  const handleChangeLocation = (e, field) => {
    const values = [...location];
    values[0][field] = e.target.value;
    setLocation(values);
  };

  const handleCampaignFieldValidation = (field, e) => {
    // alert("enter")
    const re = /^[0-9\b]+$/;
    // alert(e.target.value)
    if (e.target.value === '' || re.test(e.target.value)) {
      // alert("true")

      handleCampaignFieldChange(field, e);

      // });
    }
  };

  const handleCampaignFieldChange = (field, e) => {
    setCampaignValues({
      ...campaignValues,
      [field]: field === "startDate" ? e : e.target.value,
    });
  };

  const getActivityList = async () => {
    try {
      const res = await axios.get(`/activity`);
      setActivityList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const unitOfCampaign = activityList?.filter(
    (item, index) => item?.activity === campaignValues?.campaignType
  );

  const [range, setRange] = useState([
    {

      startDate: new Date(),
      //endDate: addDays(new Date(), 7),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  const registeredOK = (message) => {
    return <Alert message={message} />
  }

  const handleSubmit = async () => {

    try {

      if (!campaignValues?.hourOfParticipation) {
        setCampaignRegistrationValuesError({
          ...campaignRegistrationValuesError,
          timeCommitmentCampaignError: true,
        });
        // const element = document.getElementById("errorKum");

        // if (!fieldRef.current) return;
        fieldRef1.current.scrollIntoView({ behavior: "smooth" });
        //window.scrollTo(0, 0);
      } else if (!campaignValues?.impact) {
        setCampaignRegistrationValuesError({
          ...campaignRegistrationValuesError,
          impactCommitmentCampaignError: true,
        });
        fieldRef2.current.scrollIntoView({ behavior: "smooth" });
      } else if (!checked) {
        setCampaignRegistrationValuesError({
          ...campaignRegistrationValuesError,
          locationCampaignError: true,
        });
        fieldRef3.current.scrollIntoView({ behavior: "smooth" });
      } else {
        //  else {
        setLoading(true);
        // console.log(location);
        // console.log(checked);

        const res = await axios.post(`/campaign/register`, {
          campid: data?.[0]?.campid,
          // formuserfullname: profileResponse?.fullname,
          formuserfirstname: profileResponse?.firstname,
          formuserlastname: profileResponse?.lastname,
          formuserphone: profileResponse?.phoneno,
          formorgname: profileResponse?.organization,
          formcampaignname: data?.[0]?.name,
          // formcampsponsor: campaignValues?.campaignSponsor,
          participatedays:
            moment(range[0].startDate).format("YYYY-MM-DD") + "," + moment(range[0].endDate).format("YYYY-MM-DD"),

          timecommitment: campaignValues?.hourOfParticipation,
          contribution: campaignValues?.impact,
          // location: location,
          // location: JSON.stringify(checked),
          location: checked,

          registerdate: moment(new Date()).format("YYYY-MM-DD") + " 00:00:00",


        });

        // setLoading(false);

        // handleClose();
        navigate(`/adoption/${data?.[0]?.campid}/${res?.data?.message}`);
        // console.log(`/adoption/${data?.[0]?.campid}/${res?.data?.message}`);
        // console.log(res?.data)
        return res;
      }
    } catch (err) {
      setLoading(false);

      throw err;
    }
  };

  // const locationdetails = () =>(
  //   data?.[0]?.location.map((item, index) => (

  //     setChecked(index, item.state)
  //   )

  // );

  React.useEffect(() => {
    getActivityList();
    getLocation();
    localStorage.removeItem("refferalId");
    getCampaignDetails();
    getRegStatus();
    // window.scrollTo(0, 0);
    // window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [profileResponse]);

  React.useEffect(() => {
    // window.scrollTo(0, 0);
    // window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    dispatch(userProfile(localStorage.getItem("userid")));
    dispatch(getCampaign(profileResponse.orgrole, profileResponse.organization));
    dispatch(myReward({
      phoneno: localStorage.getItem("userid"),
      campid: data?.[0]?.campid,
    }));
  }, []);
  // console.log(location)
  // console.log(checked);
  // console.log(activityList);
  // console.log(campaignList?.[0]?.location);
  // console.log(campaignList?.[0]?.location?.state);
  // console.log(`vol_dashboard/camp/${location?.pathname.split("/")?.[2]}/${profileResponse?.orgrole}/${profileResponse?.organization}`)
  // console.log(`vol_dashboard/camp/${data?.[0]?.campid}/${profileResponse?.orgrole}/${profileResponse?.organization}`)

  const getRegStatus = async () => {
    try {
      const res = await axios.get(`/camp/registeredornot/${profileResponse?.phoneno}/${data?.[0]?.campid}`);
      setRegStatusList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getCampaignDetails = async () => {
    try {
      // console.log(profileResponse?.orgrole)
      const res = await axios.get(
        // `/vol_dashboard/camp/${location?.pathname.split("/")?.[2]}`

        `vol_dashboard/camp/${data?.[0]?.campid}/${profileResponse?.orgrole}/${profileResponse?.organization}`
      );
      setCampaignList(res?.data);
      // console.log(res?.data)
      return res;
    } catch (err) {
      throw err;
    }
  };

  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 3
      }}
    />
  );

  const ColoredLine1 = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 0.005
      }}
    />
  );
  // console.log(data?.[0]?.location.map((item, index) => (index, item.state)))
  // console.log(regStatusList?.registerdates);
  // console.log(regStatusList?.registerdates.split(",")[0]);
  // console.log(regStatusList?.registerdates.split(",").length)
  return (
    <div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content" style={{ maxHeight: "calc(100vh - 3.5rem)", overflow: "hidden" }}>

          <div class="headerContent" style={{ textAlign: "center", marginTop: 15, position: "relative" }}>
            <img src={data?.[0]?.logo} style={{ height: 100 }} />
            <span style={{ textAlign: "center", fontSize: 20, display: "block", padding: "5px 15px" }}>
              <b>Thank you for adopting <u>{data?.[0]?.name} </u>! Be a real changemaker by also
                inviting other volunteers to adopt this campaign.</b>
            </span>
            {
              !(regStatusList?.registered === 0) && (
                <span style={{ textAlign: "center", fontSize: 15, color: "red", padding: "33px" }}>Please note that you have already registered for this campaign on <u><b>{regStatusList?.registerdates}</b></u></span>
              )
            }
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{ position: "absolute", top: 0, right: 15 }}>
              <span aria-hidden="true">&times;</span>
            </button>
            <ColoredLine color="orange" />
          </div>
          <div class="modal-body" style={{ overflowY: "auto" }}>
            {/* {JSON.stringify(data)} */}
            <InputDisable

              height="100"
              label={"First Name"}
              // value={campaignValues?.firstName}
              value={profileResponse?.firstname}
            // onChange={(e) => {
            //   handleCampaignFieldChange("firstName", e);
            // }}
            />
            <br></br>

            <InputDisable

              height="100"
              label={"Last Name"}
              // value={campaignValues?.firstName}
              value={profileResponse?.lastname}
            // onChange={(e) => {
            //   handleCampaignFieldChange("firstName", e);
            // }}
            />


            <br></br>
            <ColoredLine1 color="silver" />
            <InputDisable

              label={"Mobile Number"}
              value={profileResponse?.phoneno}
            // onChange={(e) => {
            //   handleCampaignFieldChange("mobileNo", e);
            // }}
            />


            <br></br>
            <ColoredLine1 color="silver" />
            <InputDisable

              value={profileResponse?.organization}
              label={"Organization Name"}
            // onChange={(e) => {
            //   handleCampaignFieldChange("organizationName", e);
            // }}
            />
            <br></br>
            <ColoredLine1 color="silver" />
            <InputDisable

              value={data?.[0]?.name}
              label={"Campaign Name"}
            // onChange={(e) => {
            //   handleCampaignFieldChange("campaignName", e);
            // }}
            />

            <br></br>
            <ColoredLine1 color="silver" />

            {/* <span><b>  Select date of participation </b></span> */}
            <span><b>  {question?.[0]?.formq0} </b></span>
            
            {/* </div> */}
            <br></br>
            <br></br>
            <div className={classes.calendarWrap}>
              <input
                value={`${format(range[0].startDate, "MM/dd/yyyy")} to  ${format(range[0].endDate, "MM/dd/yyyy")}`}
                readOnly
              />
              {open &&
                <DateRange
                  label={"When are you able to participate?"}
                  className={classes.calendarElement}
                  // editableDateInputs={true}
                  editableDateInputs={false}
                  moveRangeOnFirstSelection={false}
                  // minDate = {new Date(2022, 9, 10)}
                  minDate={new Date()}
                  maxDate={new Date(data?.[0]?.enddateforui.replaceAll(",", "/"))}
                  ranges={range}
                  
                  // disabledDates={new Date(regStatusList?.registerdates.split(",")[0])}
                  
                  
                  direction="horizontal"
                  onChange={item => setRange([item.selection])}

                  value={campaignValues.item}
                />
              }
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <ColoredLine1 color="silver" />
            <div ref={fieldRef1}></div>
            <label htmlFor="create-yaad--title" className={classes.labellocation}>
              {question?.[0]?.formq1} <RedStar />
              </label>
            <InputEnable
              // label={
              //   "How much time will you commit for this campaign? (In Hours)"
              // }
              // label={question?.[0]?.formq1 }
              value={campaignValues?.hourOfParticipation}
              // value={data[0]?.hourOfParticipation}
              onBlur={() => {
                if (!campaignValues?.hourOfParticipation) {
                  setCampaignRegistrationValuesError({
                    ...campaignRegistrationValuesError,
                    timeCommitmentCampaignError: true,
                  });
                } else {
                  setCampaignRegistrationValuesError({
                    ...campaignRegistrationValuesError,
                    timeCommitmentCampaignError: false,
                  });
                }
              }}
              onChange={(e) => {
                // handleCampaignFieldChange("hourOfParticipation", e);
                handleCampaignFieldValidation("hourOfParticipation", e);
              }}
            />
            {campaignRegistrationValuesError?.timeCommitmentCampaignError && (
              <ErrorMessage message="Please enter the time you will commit" />
            )}
            <br></br>
            <br></br>
            <ColoredLine1 color="silver" />
            <div ref={fieldRef2}></div>
            {
              // <div style={{ display: "flex", alignItems: "flex-end", flexDirection: "column"}}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label htmlFor="create-yaad--title" className={classes.labellocation}>
              {question?.[0]?.formq2} <RedStar />
              </label>
               
                <InputEnable
                  // label={"What / How much do you commit to contribute? (for example, no. of trees, quantity of waste collection, etc)" } 
                  // label={question?.[0]?.formq2}
                  
                  value={campaignValues?.impact}
                  onBlur={() => {
                    if (!campaignValues?.impact) {
                      setCampaignRegistrationValuesError({
                        ...campaignRegistrationValuesError,
                        impactCommitmentCampaignError: true,
                      });
                    } else {
                      setCampaignRegistrationValuesError({
                        ...campaignRegistrationValuesError,
                        impactCommitmentCampaignError: false,
                      });
                    }
                  }}
                  onChange={(e) => {
                    // handleCampaignFieldChange("impact", e);
                    handleCampaignFieldValidation("impact", e);
                    // handleCampaignFieldValidation("impact", e);
                  }}
                />{" "} 
                <div style={{ textAlign: "left", alignItems: "left", justifyContent: "left" }}>{campaignRegistrationValuesError?.impactCommitmentCampaignError && (
                  <ErrorMessage message="Please enter your impact commitment" />
                )}
                </div>
              </div>
            }
            <br></br>
            <br></br>
            <ColoredLine1 color="silver" />
            <div ref={fieldRef3}></div>
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.labellocation}>
              {question?.[0]?.formq4} <RedStar />
              </label>
              <Multiselect
                // displayValue="{location}"
                // displayValue={data?.[0]?.location.map((item, index) => (index))}
                displayValue="city"
                // displayValue="state"
                showCheckbox
                // selectedValues={campaignList?.[0]?.location}
                // selectedValues={data?.[0]?.location}
                options={
                  // data?.[0]?.location.map((item, index) => (item.state))
                  // campaignList?.[0]?.location
                  data?.[0]?.location
                }
                onSelect={(event) => {
                  setChecked(event);
                  if (_.isEmpty(event)) {
                    setCampaignRegistrationValuesError({
                      ...campaignRegistrationValuesError,
                      locationCampaignError: true,
                    });
                  } else {
                    setCampaignRegistrationValuesError({
                      ...campaignRegistrationValuesError,
                      locationCampaignError: false,
                    });
                  }
                }}
                onRemove={(event) => {
                  setChecked(event);
                  if (_.isEmpty(event)) {
                    setCampaignRegistrationValuesError({
                      ...campaignRegistrationValuesError,
                      locationCampaignError: true,
                    });
                  } else {
                    setCampaignRegistrationValuesError({
                      ...campaignRegistrationValuesError,
                      locationCampaignError: false,
                    });
                  }
                }}
                value={checked}
                style={{
                  multiselectContainer: {
                    boxShadow: "0px 3px 6px #00000029",
                  },
                  searchBox: {
                    minHeight: 54,
                    PaddingTop: 10
                  }
                }}

              />
              {campaignRegistrationValuesError?.locationCampaignError && (
                <ErrorMessage message="Please select the location" />
              )}
            </div>
            <br></br>
          </div>
          <div class="modal-footer">
          <Button
                  style={{
                    marginTop: 8,
                    width: "50%",
                    background: "#E88A00",
                    borderRadius: 20,
                    color: "#fff",
                    fontFamily: "Poppins !important",
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: "10%",
                  }}
                  autoFocus
                  data-dismiss="modal"
                  className={classes.goalButton}
                  onClick={() => {
                    handleSubmit()
                  }}
                >

                  {loading ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Submit"
                  )}
                </Button>
                
            {/* {
              (regStatusList?.registered === 0) && (
                <Button
                  style={{
                    marginTop: 8,
                    width: "50%",
                    background: "#E88A00",
                    borderRadius: 20,
                    color: "#fff",
                    fontFamily: "Poppins !important",
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: "10%",
                  }}
                  autoFocus
                  data-dismiss="modal"
                  className={classes.goalButton}
                  onClick={() => {
                    handleSubmit()
                  }}
                >

                  {loading ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Submit"
                  )}
                </Button>
              )}
            {
              !(regStatusList?.registered === 0) && (
                <Button
                  style={{
                    marginTop: 8,
                    width: "50%",
                    background: "#8c8c8c",
                    borderRadius: 20,
                    color: "#fff",
                    fontFamily: "Poppins !important",
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: "10%",
                    cursor: "not-allowed"

                  }}
                  // disabled
                  autoFocus
                  title="You have already registered"
                  className={classes.goalButtonDisable}
                  onClick={() => {

                  }}
                >

                  {loading ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Submit"
                  )}
                </Button>
              )} */}
            <Button style={{
              marginTop: 8,
              width: "50%",
              background: "#E88A00",
              borderRadius: 20,
              color: "#fff",
              fontFamily: "Poppins !important",
              display: "flex",
              justifyContent: "center",
              marginLeft: "10%"
            }}
              className={classes.goalButton} data-dismiss="modal">Close</Button>
          </div>
        </div>
      </div>
    </div>
  );
};