/** @format */

const GET_CAMPAIGN_FILES_FETCHING_DATA =
  "GET_CAMPAIGN_FILES_FETCHING_DATA";
const GET_CAMPAIGN_FILES_FETCHING_DATA_FAILURE =
  "GET_CAMPAIGN_FILES_FETCHING_DATA_FAILURE";
const GET_CAMPAIGN_FILES_FETCHING_DATA_RESET =
  "GET_CAMPAIGN_FILES_FETCHING_DATA_RESET";
const GET_CAMPAIGN_FILES_FETCHING_DATA_SUCCESS =
  "GET_CAMPAIGN_FILES_FETCHING_DATA_SUCCESS";
const GET_CAMPAIGN_FILES_FETCHING_DATA_ATTEMPT =
  "GET_CAMPAIGN_FILES_FETCHING_DATA_ATTEMPT";
const getCampFiles = (payload) => ({
  type: GET_CAMPAIGN_FILES_FETCHING_DATA_ATTEMPT,
  payload: payload,
});
const getCampFilesReset = () => ({
  type: GET_CAMPAIGN_FILES_FETCHING_DATA_RESET,
});

export {
  GET_CAMPAIGN_FILES_FETCHING_DATA,
  GET_CAMPAIGN_FILES_FETCHING_DATA_FAILURE,
  GET_CAMPAIGN_FILES_FETCHING_DATA_RESET,
  GET_CAMPAIGN_FILES_FETCHING_DATA_SUCCESS,
  GET_CAMPAIGN_FILES_FETCHING_DATA_ATTEMPT,
  getCampFiles,
  getCampFilesReset,
};

// Campaign personal docs
const GET_CAMPAIGN_DOCS_FETCHING_DATA =
  "GET_CAMPAIGN_DOCS_FETCHING_DATA";
const GET_CAMPAIGN_DOCS_FETCHING_DATA_FAILURE =
  "GET_CAMPAIGN_DOCS_FETCHING_DATA_FAILURE";
const GET_CAMPAIGN_DOCS_FETCHING_DATA_RESET =
  "GET_CAMPAIGN_DOCS_FETCHING_DATA_RESET";
const GET_CAMPAIGN_DOCS_FETCHING_DATA_SUCCESS =
  "GET_CAMPAIGN_DOCS_FETCHING_DATA_SUCCESS";
const GET_CAMPAIGN_DOCS_FETCHING_DATA_ATTEMPT =
  "GET_CAMPAIGN_DOCS_FETCHING_DATA_ATTEMPT";
const getCampDocs = (payload) => ({
  type: GET_CAMPAIGN_DOCS_FETCHING_DATA_ATTEMPT,
  payload: payload,
});
const getCampDocsReset = () => ({
  type: GET_CAMPAIGN_DOCS_FETCHING_DATA_RESET,
});

export {
  GET_CAMPAIGN_DOCS_FETCHING_DATA,
  GET_CAMPAIGN_DOCS_FETCHING_DATA_FAILURE,
  GET_CAMPAIGN_DOCS_FETCHING_DATA_RESET,
  GET_CAMPAIGN_DOCS_FETCHING_DATA_SUCCESS,
  GET_CAMPAIGN_DOCS_FETCHING_DATA_ATTEMPT,
  getCampDocs,
  getCampDocsReset,
};
