/** @format */

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery, Avatar, Chip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Button, Stack } from "@mui/material";
import _ from "lodash";
import "../../scss/containerStyles/_home.scss";
import "../../App.css";
import "../../cyaag-style.css";
import "../../font-awesome.min.css";
import "../../bootstrap.min.css";
import "../../icon-font.css";
import "../../flaticon-font.css";
import {
  userProfile,
  userProfileReset,
} from "../../redux/action/UserProfileActions";
import { CopyRight } from "../../components/CopyRight";
import { FixedFooterIcon } from "../../components/FixedFooterIcon";
import { getVolunteerCount } from "../../redux/action/volunteerCountAction";
import ResponsiveDrawer from "../../components/Header";
import TopHeaderScreen from "../../components/TopHeader1";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { socialLoginReset } from "../../redux/action/loginAction";
import { getCampaign } from "../../redux/action/CampaignAction";
import Card from "@material-ui/core/Card";
import { referral } from "../../redux/action/ReferralAction";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { volDashBoardReward } from "../../redux/action/DashboardReward";
import TestiImg from "../../assets/testi.png";
import OverallStatusImg from "../../assets/left-o-status.png";
import RewardMainImage from "../../assets/rew.png";
import { isArray } from "lodash";
import WonIcon from "../../assets/won-rewards.jpg";
import RedeemConfirmation from "../../components/RedeemConfirmation";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import Typography from "@material-ui/core/Typography";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import PostRedeemConfirmation from "../../components/PostRedeemConfirmation";
import CEOImage from "../../assets/ceo.png";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { Audio, Circles } from "react-loader-spinner";
import CampaignLeaderBoardIcon from "../../assets/CampaignLeaderBoardIcon.jpeg"

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("sm")]: {
      padding: "80px 0 100px 330px",
    },
  },
  homeScreenContainer: {
    // padding: "0 0 0 20px",

    paddingLeft: "10px",
    zIndex: 100,
  },
  welcomeMessage: {
    fontWeight: "bold",
    marginBottom: 10,
    margin: "10px 0",
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
    },
  },
  welcomeAndFilter: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    textTransform: "uppercase",
    padding: "10px 0",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  filterIcon: {
    fontSize: 26,
    color: "#F8B34C",
    marginLeft: 28,
  },
  homeCards: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    margin: "0 -7px",
  },

  maxWidthContainer: {
    padding: "25px 0",
    maxWidth: 500,
    margin: "0 auto",
  },
  gradientHeading: {
    fontSize: 25,
    fontWeight: "bold",
  },
  rewards: {
    display: "flex",
    justifyContent: "center",
    color: "#F8B34C",
    paddingBottom: 13,
  },
  rewardsText: {
    fontSize: 25,
    fontWeight: "bold",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 9,
  },
  secondaryBorder: {
    padding: "5px 0",
    borderBottom: "2px solid #F8B34C",
  },
  myImpacts: {
    padding: "15px 0 0 0",
    fontSize: 14,
    textAlign: "center",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  myImpactsWrap: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 -8px",
    outline: "none",
  },
  myImpact: {
    width: "150px",
    margin: "0 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    outline: "0 !important",
    border: "none",
  },
  myImpactImgWrap: {
    width: 52,
    height: 50,
    margin: "0 auto 5px",
  },
  myImpactImg: {
    width: "100%",
  },
  myImpactTxt: {
    display: "block",
  },
  myCommunity: {
    borderBottom: "2px solid #F8B34C",
    padding: "15px 0",
    fontSize: 20,
    color: "#F8B34C",
  },
  myCommunityContentWrap: {
    display: "flex",
    justifyContent: "space-between",
  },
  myCommunityImg: {
    width: 100,
  },
  inviteBtn: {
    paddingTop: 15,
    width: 150,
  },
  myCommunityPoints: {
    listStyle: "none",
    margin: "0 0 0 10px",
    padding: 0,
  },
  inviteButton: {
    fontSize: 9,
    fontWeight: "bold",
    lineHeight: 1,
  },
  myRegion: {
    borderBottom: "2px solid #F8B34C",
    padding: "15px 0",
  },
  mapImg: {
    width: 130,
    margin: "0 auto",
  },
  myYaags: {
    padding: "15px 0",
  },

  bottomNavigation: {
    position: "absolute",
    width: "100%",
  },
  tabName: {
    // color: "#fff",
    color: "#00000",
    textTransform: "none",
    // ariaSelected: "true",
  },
  maxpoints: {
    textTransform: "none",
    fontSize: 13,
  },
  tagWrapperContainer: {
    width: "45%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  tagWrapper: {
    padding: 4,

    backgroundColor: "#E8E8E8",
    margin: 4,
    borderRadius: 4,
    // display: "flex",
    display: "inline-flex",
  },
  campaigntypewrapper: {
    padding: 1,

    // backgroundColor: "#E8E8E8",
    margin: 2,
    borderRadius: 4,
    display: "block",
    // display: "inline-flex",
  },
  tags: {
    textTransform: "capitalize",
    fontSize: 12,
  },
  earnupto: {
    textTransform: "capitalize",
    fontSize: 12,
  },
  adopterCountText: {
    fontSize: 12,
    textTransform: "none",
    fontWeight: "600",
    backgroundColor: "#E8E8E8",
    borderRadius: 4,
    padding: 4,
  },
  cardImg: {
    borderRadius: "5px 5px 0 0",
    // height: 280,
    height: 200,
    backgroundSize: "100% 100%",
    // width: 100,
  },
  cardTitle: {
    // background: "#2C7973",
    color: "#000",
    zIndex: 99,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    boxShadow: "0px 5px 10px rgba(0,0,0,0.2)",
    overflow: "auto",
  },
  cardTitle1: {
    // background: "#2C7973",
    color: "#000",
    // height:"500",
    zIndex: 99,
    display: "flex",
    flexDirection: "row",
    // justifyContent: "flex-start",
    // alignItems: "flex-start",
    // boxShadow: "0px 5px 10px rgba(0,0,0,0.2)",
    overflow: "auto",
  },
  button: {
    padding: "5px 2px",
    fontSize: "10px",
  },
  ChipCompleted: {
    fontSize: 12,
    textTransform: "none",
    fontWeight: "600",
    backgroundColor: "#228B22",
    borderRadius: 4,
    padding: 4,
  },
  subtitleWrapper: {
    textAlign: "center",
  },
}));

const HAScreen = (props) => {
  // console.log("start HAScreen")
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [campaignList, setCampaignList] = useState([]);
  const [myCampaignList, setMyCampaignList] = useState([]);
  const [myRecommendedCampList, setMyRecommendedCampList] = useState([]);
  const [campStatusCount, setCampStatusCount] = useState([]);
  const [SDGCampMapping, setSDGCampMapping] = React.useState([]);

  const [value, setValue] = React.useState(0);
  const [image, setImage] = React.useState(null);

  const [rewarddash, setRewardDash] = useState([]);
  const [mycommunity, setmycommunity] = useState([]);
  const [myredeemedrewards, setmyredeemedrewards] = useState([]);
  const [marketplacelist, setmarketplacelist] = useState([]);
  const [successStories, setSuccessStories] = useState([]);
  const [afterRedeem, setAfterRedeem] = useState([]);
  const [displayRedeemConfirmationModal, setDisplayRedeemConfirmationModal] =
    useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [redeemindex, setRedeemIndex] = useState(0);
  const [postRedeemDisplayModal, setPostRedeemDisplayModal] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [reLoading, setReLoading] = React.useState(false);
  const [newValue, setNewValue] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  function setDisplayAndIndex(bool, index) {
    setDisplayRedeemConfirmationModal(bool);
    setRedeemIndex(index);
  }

  // console.log("calling profile")
  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  // useEffect(() => {
  //   let temp = [];
  //   localStorage.getItem("refferalId") &&
  //     dispatch(
  //       referral({
  //         campid: localStorage.getItem("refferalId"),
  //         phoneno: localStorage.getItem("refferalPhoneNumber"),
  //         reflevel: localStorage.getItem("reflevel"),
  //         refuserid: localStorage.getItem("userid"),
  //       })
  //     );
  //   // dispatch(socialLoginReset());
  //   // dispatch(registerReset());
  //   // dispatch(yaagFormAnswerReset());
  //   // dispatch(dashboardYaag());
  //   // dispatch(dashboardReward(userid));
  //   // dispatch(yaagReward(userid));
  //   // dispatch(myImpact(userid));
  //   // dispatch(userProfile(userid));
  //   // dispatch(myCommunity(userid));
  //   temp.push(localStorage.getItem("userid"));
  // }, [myCampaignList, myredeemedrewards, marketplacelist]);

  // 
 
  const userid = localStorage.getItem("userid");

  React.useEffect(() => {
    dispatch(userProfile(userid));
  }, [reLoading]);

  // const refferalId = localStorage.getItem("refferalId");

  // console.log("calling useeffect 2")
  useEffect(() => {
    dispatch(userProfile(userid));
    dispatch(getVolunteerCount());
    Promise.all([getProfileInfo()])
      .then((data) => { getVolunteerCampDetails(data); getCampStatusCount(data) })
      .then(() => getMyReward())
      .then(() => getRewardsFromMarketplace())
      .then(() => getMyRedeemedRewards())
      ;
    dispatch(
      getCampaign(profileResponse.orgrole, profileResponse.organization)
    );
  }, [reLoading]);

  useEffect(() => {
    getMyRedeemedRewards();
  }, []);

  useEffect(() => {
    //if (profileSuccess) {
    getMyCommunityDetails().then((data) => {
      setmycommunity(data);
    });
    // } else {
    setLoading(false);
    //  }
  }, [reLoading]);

  // console.log(mycommunity);

  useEffect(() => {
    getSuccessStories().then((data) => {
      setSuccessStories(data);
    });
  }, []);

  useEffect(() => {
    // setLoading(false);
    getRecommendedCampDetails();
    // setLoading(true);
  }, [reLoading]);

  // useEffect(() => {
  //   getCampStatusCount();
  // }, []);

  useEffect(() => {
    getSDGCampMapping();
  }, [reLoading]);

  // const [loading, setLoading] = React.useState(false);

  const getProfileInfo = async () => {
    console.log("user = ", userid);
    try {
      const res = await axios.get(`cyaaguser/${userid}`);

      // setMyCampaignList(res?.data);
      //console.log("hhh",res?.data)
      return res;
      // }
    } catch (err) {
      throw err;
    }
  };

  const getVolunteerCampDetails = async (dd) => {
    setIsLoading(true);
    try {
      // console.log(`vol_dashboard/camp/my/${dd[0].data?.orgrole}/${dd[0].data?.organization}/${dd[0].data?.phoneno}`)
      const res = await axios.get(
        `vol_dashboard/camp/my/${dd[0].data?.orgrole}/${dd[0].data?.organization}/${dd[0].data?.phoneno}`
      );

      setMyCampaignList(res);
      setNewValue(dd[0]?.data);
      console.log("dd[0] = ", dd[0]);
      // getRewardsFromMarketplace();
      // setLoading(true);
      // setMyCampaignList(res?.data);
      setIsLoading(false);

      return res;
      // }
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
  };

  // console.log(myCampaignList?.[0]);
  console.log("campStatusCount = ", campStatusCount);
  console.log("campStatusCount = ", campStatusCount?.data?.[2].donecount);

  const getMyReward = async () => {
    try {
      const res = await axios.get(`/vol_dashboard/myreward/${userid}`);
      setRewardDash(res?.data);
      dispatch(volDashBoardReward(res?.data, res?.data?.[0]?.phoneno));
      return res;
    } catch (err) {
      throw err;
    }
  };
  const getRewardsFromMarketplace = async () => {
    try {
      //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);
      const res = await axios.get(`/marketplace/read/${userid}`);
      setmarketplacelist(res?.data);
      // console.log(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getMyCommunityDetails = async () => {
    try {
      //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);

      const res = await axios.get(`adopter_dashboard/mycommunity/${userid}`);
      // console.log(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getMyRedeemedRewards = async () => {
    try {
      //   const res = await axios.get(`/marketplace/read/${profileResponse?.phoneno}`);

      const res = await axios.get(`reward/redeemed/${userid}`);
      setmyredeemedrewards(res);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getSuccessStories = async () => {
    try {
      const res = await axios.get(`/success/stories`);
      // console.log(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getRecommendedCampDetails = async () => {
    try {
      const res = await axios
        .get(`recommendation/${userid}`)
        .then((response) => {
          // console.log(response?.data);
          setMyRecommendedCampList(response);
          return response;
        });

      // setMyCampaignList(res?.data);
      // console.log(res)
      // return res;
      // }
    } catch (err) {
      throw err;
    }
  };

  const getCampStatusCount = async (profileData) => {
    try {
      const res = await axios
        // .get(
        //   `camp/count/${newValue?.orgrole}/${newValue?.organization}/${userid}`
        // )
        .get(
          `camp/count/${profileData[0].data?.orgrole}/${profileData[0].data?.organization}/${userid}`
        )
        .then((response) => {
          // console.log(response?.data);
          setCampStatusCount(response);
          return response;
        });

      // setMyCampaignList(res?.data);
      // console.log(res)
      // return res;
      // }
    } catch (err) {
      console.log("err = ", err);
      console.log("newValue = ", newValue);
      throw err;
    }
  };

  const getSDGCampMapping = async () => {
    try {
      const res = await axios.get(`camp/sdg/mapping`);
      // setActivityList(res?.data);
      setSDGCampMapping(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const handleRedeem = async () => {
    // setDisplayConfirmationModal(true);

    const payload = {
      //campid: locationPath?.state?.campid,
      // createdate: locationPath?.state?.createdate,
      // reviewstatus: reviewstatus,
      // reviewcomments: reviewComments,
      phoneno: localStorage.getItem("userid"),
      rewardtype: marketplacelist?.data?.[redeemindex]?.rewardtype,
      mpid: marketplacelist?.data?.[redeemindex]?.mpid,
      campid: marketplacelist?.data?.[redeemindex]?.campid,
      title: marketplacelist?.data?.[redeemindex]?.title,
      subtitle: marketplacelist?.data?.[redeemindex]?.subtitle,
      points: marketplacelist?.data?.[redeemindex]?.points,
      // sponsor: JSON.stringify(marketplacelist?.data?.[redeemindex]?.sponsor),
      sponsor: marketplacelist?.data?.[redeemindex]?.sponsor,

      // phoneno: locationPath?.state.phoneno,
    };
    try {
      const res = await axios.post(
        `/reward/redeem/${profileResponse?.phoneno}`,
        payload
      );
      // navigate("/reviewcampaign");
      setAfterRedeem(res?.data);
      // console.log(res?.data?.message);
      // alert("calling set display false")
      setDisplayRedeemConfirmationModal(false);
      if (res?.data?.message === "Reward successfully redeemed") {
        setPostRedeemDisplayModal(true);
        // navigate("/hacampscreen");
      } else {
        setDisplayConfirmationModal(true);
      }
      return res;
    } catch (err) {
      throw err;
    }
  };

  // Hide the modal
  const hideRedeemConfirmationModal = () => {
    setDisplayRedeemConfirmationModal(false);
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handlePostRedeemModal = () => {
    setPostRedeemDisplayModal(false);
    navigate("/marketplace");
  };

  const options = {
    items: 3,
    margin: 10,
    loop: true,
    nav: false,
    autoWidth: false,
    autoplaySpeed: 900,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: {
      0: {
        items: 1,
      },
      566: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
    touchDrag: true,
  };

  const optionsTwo = {
    items: 2,
    margin: 0,
    loop: false,
    nav: true,
    autoplaySpeed: 900,
    autoplay: false,
    dots: false,
    navText: [
      '<i class="fa fa-angle-left" aria-hidden="true"></i>',
      '<i class="fa fa-angle-right" aria-hidden="true"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
    touchDrag: true,
  };

  const optionsFour = {
    items: 4,
    margin: 10,
    loop: true,
    nav: false,
    autoplaySpeed: 900,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
      },
      560: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
    touchDrag: true,
  };

  // console.log(myredeemedrewards);
  // console.log(
  //   myredeemedrewards?.data?.map((reward, index) =>
  //     reward?.reward?.map((item1) => item1)
  //   )
  // );
  // console.log(myredeemedrewards?.data?.map((reward, index) => reward));
  // console.log(
  //   myredeemedrewards?.data?.map((reward, index) =>
  //     reward.reward.map((mm) => mm.rewardtype)
  //   )
  // );
  // console.log(successStories);

  const splitString = (val) => {
    if (val) {
      // const vallArray = val.split(".");
      const vallArray = val.split(";");

      return (
        <ul>
          {vallArray.map((bulletVal, index) => (
            <li>
              <p>{vallArray[index]}.</p>
            </li>
          ))}
        </ul>
      );
    }
  };

  // const splitString1 = (val) => {
  //   if (val) {
  //     const parts = val.split(/(::)|(,,)|;/).filter(Boolean);
  //     let inUnderlinedSection = false;
  //     let inBoldSection = false;

  //     return (
  //       <ul>
  //         {parts.map((bulletVal, index) => {
  //           if (bulletVal === '::') {
  //             inUnderlinedSection = !inUnderlinedSection; // Toggle underlined section
  //             return null; // Skip rendering double colons
  //           } else if (bulletVal === ',,') {
  //             inBoldSection = !inBoldSection; // Toggle bold section
  //             return null; // Skip rendering double commas
  //           }

  //           if (inUnderlinedSection) {
  //             return (
  //               <li key={index}>
  //                 <span className="underlined-text">{bulletVal}</span>
  //               </li>
  //             );
  //           } else if (inBoldSection) {
  //             return (
  //               <li key={index}>
  //                 <span className="bold-text">{bulletVal}</span>
  //               </li>
  //             );
  //           } else if (bulletVal.trim() !== '') {
  //             // Render semicolon-separated text as separate lines
  //             const bulletPoints = bulletVal.split(';');
  //             {/* console.log("bulletPoints = ", bulletPoints, "index = ", index) */}
  //             return (
  //               <li key={index}>
  //                 {bulletPoints.map((point, subIndex) => (
  //                   <p key={subIndex}>{point.trim()}</p>
  //                 ))}
  //               </li>
  //             );
  //           }
  //           return null;
  //         })}
  //       </ul>
  //     );
  //   }
  // };

  // const splitString1 = (val) => {
  //   if (val) {
  //     const parts = val.split(/(::)|(,,)|;/).filter(Boolean);
  //     let inUnderlinedSection = false;
  //     let inBoldSection = false;

  //     return (
  //       <ul>
  //         {parts.map((bulletVal, index) => {
  //           if (bulletVal === '::') {
  //             inUnderlinedSection = !inUnderlinedSection; // Toggle underlined section
  //             return null; // Skip rendering double colons
  //           } else if (bulletVal === ',,') {
  //             inBoldSection = !inBoldSection; // Toggle bold section
  //             return null; // Skip rendering double commas
  //           }

  //           if (inUnderlinedSection) {
  //             return (
  //               <li key={index}>
  //                 <span className="underlined-text">{bulletVal}</span>
  //               </li>
  //             );
  //           } else if (inBoldSection) {
  //             return (
  //               <li key={index}>
  //                 <span className="bold-text">{bulletVal}</span>
  //               </li>
  //             );
  //           } else if (bulletVal.trim() !== '') {
  //             // Render semicolon-separated text as separate lines
  //             const bulletPoints = bulletVal.split(';').map((point) => point.trim());

  //             return (
  //               <li key={index}>
  //                 {bulletPoints.map((point, subIndex) => (
  //                   <React.Fragment key={`${index}-${subIndex}`}>
  //                     {subIndex > 0 ? <br /> : null}
  //                     <p>{point}</p>
  //                   </React.Fragment>
  //                 ))}
  //               </li>
  //             );
  //           }
  //           return null;
  //         })}
  //       </ul>
  //     );
  //   }
  // };

  // const splitString1 = (val) => {
  //   if (val) {
  //     const parts = val.split(/(::)|(,,)|;/).filter(Boolean);
  //     let inUnderlinedSection = false;
  //     let inBoldSection = false;
  //     let semicolonFound = false;

  //     return (
  //       <ul>
  //         {parts.map((bulletVal, index) => {
  //           if (bulletVal === '::') {
  //             inUnderlinedSection = !inUnderlinedSection; // Toggle underlined section
  //             semicolonFound = false; // Reset semicolon flag
  //             return null; // Skip rendering double colons
  //           } else if (bulletVal === ',,') {
  //             inBoldSection = !inBoldSection; // Toggle bold section
  //             semicolonFound = false; // Reset semicolon flag
  //             return null; // Skip rendering double commas
  //           }

  //           if (inUnderlinedSection) {
  //             return (
  //               <li key={index}>
  //                 <span className="underlined-text">{bulletVal}</span>
  //               </li>
  //             );
  //           } else if (inBoldSection) {
  //             return (
  //               <li key={index}>
  //                 <span className="bold-text">{bulletVal}</span>
  //               </li>
  //             );
  //           } else if (bulletVal.trim() !== '') {
  //             // Render semicolon-separated text as separate lines
  //             const bulletPoints = bulletVal.split(';').map((point) => point.trim());

  //             return (
  //               <li key={index}>
  //                 {bulletPoints.map((point, subIndex) => {
  //                   if (subIndex > 0 && semicolonFound) {
  //                     return (
  //                       <React.Fragment key={`${index}-${subIndex}`}>
  //                         <br />
  //                         <p>{point}</p>
  //                       </React.Fragment>
  //                     );
  //                   }
  //                   semicolonFound = true;
  //                   return <p key={`${index}-${subIndex}`}>{point}</p>;
  //                 })}
  //               </li>
  //             );
  //           }
  //           return null;
  //         })}
  //       </ul>
  //     );
  //   }
  // };

  const splitString1 = (val) => {
    if (val) {
      const parts = val.split(/(::)|(,,)|(--)|;/).filter(Boolean);
      let inUnderlinedSection = false;
      let inBoldSection = false;
      let skipLineBreak = true;
      let inHighlightTextSection = false;

      return (
        <ul>
          {parts.map((bulletVal, index) => {
            if (bulletVal === "::") {
              inUnderlinedSection = !inUnderlinedSection;
              skipLineBreak = true;
              return null;
            } else if (bulletVal === ",,") {
              inBoldSection = !inBoldSection;
              skipLineBreak = true;
              return null;
            } else if (bulletVal === "--") {
              inHighlightTextSection = !inHighlightTextSection;
              skipLineBreak = true;
              return null;
            }

            if (inUnderlinedSection) {
              return (
                <li key={index}>
                  <span className="underlined-text">{bulletVal}</span>
                </li>
              );
            } else if (inBoldSection) {
              return (
                <li key={index}>
                  {!skipLineBreak && <br />}
                  <span className="bold-text">{bulletVal}</span>
                </li>
              );
            } else if (inHighlightTextSection) {
              return (
                <li key={index}>
                  {!skipLineBreak && <br />}
                  <span className="highlight-text">{bulletVal}</span>
                </li>
              );
            } else if (bulletVal.trim() !== "") {
              const bulletPoints = bulletVal
                .split(";")
                .map((point) => point.trim());

              return (
                <li key={index}>
                  {bulletPoints.map((point, subIndex) => {
                    const shouldBreakLine = subIndex > 0;
                    if (shouldBreakLine) {
                      skipLineBreak = false;
                      return (
                        <React.Fragment key={`${index}-${subIndex}`}>
                          <br />
                          <p>{point}</p>
                        </React.Fragment>
                      );
                    }
                    return (
                      <p key={`${index}-${subIndex}`} style={{ marginTop: 0 }}>
                        {point}
                      </p>
                    );
                  })}
                </li>
              );
            }
            return null;
          })}
        </ul>
      );
    }
  };

  const handleTouch = (e) => {
    e.stopPropagation();
  };

  const isDesktop = useMediaQuery("(min-width:500px)");
  console.log(
    "myRecommendedCampList = ",
    myRecommendedCampList?.data?.[0]?.[0]?.matchtype
  );
  console.log(
    "isArray(myRecommendedCampList) = ",
    isArray(myRecommendedCampList?.data)
  );

  return (
    <Card className={classes.homecard} onClick={props.onClick}>
      <div className="wrapper">
        <ResponsiveDrawer {...props} />
        <section className="containers">
          <TopHeaderScreen {...props} />
          <div class="content content-wrap">
            <div class="content-mrg hasScreen">
              <Button onClick={() => navigate(-1)} className={classes.backBtn}>
                <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
              </Button>
              <div class="content content-pad-0">
                <div class="row">
                  <div class="col-lg-12">
                    <section id="tabs" class="project-tab">
                      <div
                        class="container-fluids"
                        style={{ maxWidth: "1440px" }}
                      >
                        <div class="cyagg-camp-space campaigns-tabs">
                          <div>
                            <div class="row">
                              <div class="col-12">
                                <div>
                                  <h4 class="site-title">Campaigns</h4>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="widget-content animated-underline-content">
                            <ul
                              class="nav nav-tabs  mb-3 campaign-tabz innerCampaign"
                              id="animateLine"
                              role="tablist"
                            >
                              <li class="nav-item">
                                <a
                                  style={{ display: "flex" }}
                                  class="nav-link active"
                                  id="animated-underline-home-tab"
                                  data-toggle="tab"
                                  href="#animated-underline-home"
                                  role="tab"
                                  aria-controls="animated-underline-home"
                                  aria-selected="true"
                                >
                                  <i class="material-icons directions_run">
                                    &#xe566;
                                  </i>{" "}
                                  <span>Active</span>
                                  <span
                                    style={{
                                      color: "#696969",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {campStatusCount?.data?.[0]?.activecount}
                                  </span>
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  style={{ display: "flex" }}
                                  class="nav-link"
                                  id="animated-underline-profile-tab"
                                  data-toggle="tab"
                                  href="#animated-underline-profile"
                                  role="tab"
                                  aria-controls="animated-underline-profile"
                                  aria-selected="false"
                                >
                                  <i class="material-icons edit_calendar">
                                    &#xe742;
                                  </i>{" "}
                                  <span>Next</span>
                                  <span
                                    style={{
                                      color: "#696969",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {campStatusCount?.data?.[1]?.nextcount}
                                  </span>
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  style={{ display: "flex" }}
                                  class="nav-link"
                                  id="animated-underline-contact-tab"
                                  data-toggle="tab"
                                  href="#animated-underline-contact"
                                  role="tab"
                                  aria-controls="animated-underline-contact"
                                  aria-selected="false"
                                >
                                  <i class="material-icons check_circle_outline">
                                    &#xe92d;
                                  </i>{" "}
                                  <span>Done</span>
                                  <span
                                    style={{
                                      color: "#696969",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {campStatusCount?.data?.[2]?.donecount}
                                  </span>
                                </a>
                              </li>

                              <li class="nav-item">
                                <a
                                  style={{ display: "flex" }}
                                  class="nav-link"
                                  id="animated-underline-recommended-tab"
                                  data-toggle="tab"
                                  href="#animated-underline-recommended"
                                  role="tab"
                                  aria-controls="animated-underline-recommended"
                                  aria-selected="false"
                                >
                                  <ThumbUpAltRoundedIcon />
                                  {/* <span>Recommended</span> */}
                                  <span>For You</span>
                                </a>
                              </li>
                            </ul>
                            {isLoading ? (
                              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20vh' }}>
                                <Circles
                                  type="TailSpin"
                                  color="rgb(155, 236, 34)"
                                  height={70}
                                  width={70}
                                  timeout={5000}
                                  ariaLabel="three-circles-rotating"
                                />
                              </div>
                            ) : (
                              <div
                                class="tab-content customOwlNav"
                                id="animateLineContent-4"
                              >
                                <div
                                  class="tab-pane fade show active"
                                  id="animated-underline-home"
                                  role="tabpanel"
                                  aria-labelledby="animated-underline-home-tab"
                                  onTouchStart={handleTouch}
                                  onTouchMove={handleTouch}
                                  onTouchEnd={handleTouch}
                                >
                                  {campStatusCount &&
                                    campStatusCount?.data?.[0]?.activecount ===
                                    0 ? (
                                    <span>
                                      <i>
                                        <b>
                                          Please keep watching this space for
                                          new campaigns. You will also be
                                          notified here.
                                        </b>
                                      </i>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  <OwlCarousel
                                    className="owl-theme"
                                    {...optionsTwo}
                                  >
                                    {myCampaignList && isArray(myCampaignList) &&
                                      myCampaignList?.data
                                        ?.filter(
                                          (item, index) =>
                                            item.active === "Active"
                                        )
                                        .map((tile, index) => (
                                          <div class="item">
                                            <div class="">
                                              <div class="campaigns-wrap campaigns-wrap-new">
                                                <div>
                                                  <div>
                                                    <div class="campaign-img">
                                                      <div class="video-wrapper">
                                                        <div
                                                          class="video-container"
                                                          id="video-container"
                                                        >
                                                          <video
                                                            controls
                                                            controlsList="nodownload"
                                                            id="video"
                                                            // preload="metadata"
                                                            preload="auto"
                                                            // poster="//cdn.jsdelivr.net/npm/big-buck-bunny-1080p@0.0.6/poster.jpg"
                                                            poster={tile?.logo}
                                                            autoplay
                                                          >
                                                            <source
                                                              // src="//cdn.jsdelivr.net/npm/big-buck-bunny-1080p@0.0.6/video.mp4"
                                                              src={tile?.video}
                                                              type="video/mp4"
                                                              display="none"
                                                            />
                                                          </video>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <h5
                                                      title={tile?.name}
                                                      class="campaign-title-new"
                                                      style={{ color: "black" }}
                                                    >
                                                      {tile?.name}
                                                    </h5>
                                                    <div class="campaign-hashtags">
                                                      {tile.tag.map((tag) =>
                                                        SDGCampMapping?.map(
                                                          (item) =>
                                                            item?.cat ===
                                                            tag?.label && (
                                                              <a
                                                                class="hash-styles"
                                                                style={{
                                                                  cursor:
                                                                    "initial",
                                                                }}
                                                              >
                                                                {tag?.tag}{" "}
                                                                <a
                                                                  class="hash-styles-sdg"
                                                                  style={{
                                                                    cursor:
                                                                      "initial",
                                                                    color: "white",
                                                                    marginTop: "2px",
                                                                  }}
                                                                >
                                                                  SDG{" "}
                                                                  {item?.sdg}
                                                                </a>
                                                              </a>
                                                            )
                                                        )
                                                      )}
                                                    </div>
                                                  </div>

                                                  <div className={classes.campaigntypewrapper}>
                                                    <Stack
                                                      direction="row"
                                                      alignItems="center"
                                                      justifyContent="space-between"
                                                      gap={0.5}
                                                    >
                                                      <h5 class="campaign-goal">
                                                        <a
                                                          class="hash-styles-goal"
                                                          style={{
                                                            padding:
                                                              "0px 5px 0px 0px",
                                                            cursor: "initial",
                                                          }}
                                                        >
                                                          <span style={{ fontSize: "16px", fontWeight: 700 }}>Goal</span>: <span class="text-6" style={{ color: "black" }}>{tile.goalquantity}{" "} {tile.unitofgoal}</span>
                                                        </a>
                                                      </h5>
                                                    </Stack>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col-md-12">
                                                    <div>
                                                      <div class="campaigns-details">


                                                        <div class="p-bar-new">
                                                          <div class="row ">
                                                            <div class="col-6">
                                                              <div>
                                                                <h6>
                                                                  <span style={{ color: "#AAAAAA" }}>Pledged: </span>{" "} <span style={{ backgroundColor: "#FFDB58" }}>
                                                                    {Math.round(
                                                                      (tile.committedcount)
                                                                    )}
                                                                  </span>
                                                                </h6>
                                                              </div>
                                                            </div>
                                                            <div class="col-6">
                                                              <div
                                                                style={{
                                                                  textAlign:
                                                                    "right",
                                                                }}
                                                              >
                                                                <h6>
                                                                  <span style={{ color: "#AAAAAA" }}>Achieved: </span>
                                                                  {" "}
                                                                  <span style={{ backgroundColor: "#A9D18E" }}> {tile.achievedcount}</span>
                                                                  {" "}
                                                                </h6>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="progress br-30 campaign-progress" style={{ position: "relative" }}>
                                                            <div
                                                              className="progress-bar"
                                                              role="progressbar"
                                                              style={{
                                                                width: `${(tile.committedcount / tile.goalquantity) * 100}%`,
                                                                backgroundColor: "#FFDB58",
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                zIndex: 1,
                                                                height: "100%"
                                                              }}
                                                              aria-valuenow={Math.round((tile.committedcount / tile.goalquantity) * 100)}
                                                              aria-valuemin="0"
                                                              aria-valuemax="100"
                                                            ></div>

                                                            <div
                                                              className="progress-bar"
                                                              role="progressbar"
                                                              style={{
                                                                width: `${(tile.achievedcount / tile.goalquantity) * 100}%`,
                                                                backgroundColor: "#A9D18E",
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                zIndex: 2,
                                                                height: "100%"
                                                              }}
                                                              aria-valuenow={Math.round((tile.achievedcount / tile.goalquantity) * 100)}
                                                              aria-valuemin="0"
                                                              aria-valuemax="100"
                                                            ></div>
                                                          </div>
                                                        </div>
                                                        <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                                          <div style={{ flex: 1 }}>
                                                            <h6>
                                                              <span style={{ color: "#AAAAAA" }}>Signed Up:</span>{" "}
                                                              <span style={{ backgroundColor: "#DAE8FD" }}>{tile.submittedcount}</span>
                                                            </h6>
                                                          </div>
                                                          <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                            <Chip
                                                              key={tile.campaigntype}
                                                              fontSize="small"
                                                              size="small"
                                                              label={tile.campaigntype}
                                                            />
                                                            <img
                                                              src={CampaignLeaderBoardIcon}
                                                              style={{ width: 25, height: 25, cursor: "pointer", }}
                                                              onClick={() =>
                                                                navigate(
                                                                  `/camp/leaderboard/${tile.campid}`
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </div>



                                                        <div class="row">
                                                          <div class="col-md-4 col-6 customHalfWidth">
                                                            <div class="cam-cols">
                                                              <div class="timer-here">
                                                                <div class="text-center">
                                                                  <span class="campaign-time-title">
                                                                    Earn upto
                                                                  </span>
                                                                </div>
                                                                <div>
                                                                  <ul id="countdown">
                                                                    <li id="hours">
                                                                      <div class="numberhome"></div>
                                                                      <div class="label"></div>
                                                                    </li>
                                                                    <li id="minutes">
                                                                      <div
                                                                        class="numberhome"
                                                                        style={{
                                                                          padding:
                                                                            "0px 0px 0px 6px",
                                                                        }}
                                                                      >
                                                                        {
                                                                          tile.maxpointstobeearned
                                                                        }
                                                                      </div>
                                                                      <div
                                                                        class="label"
                                                                        style={{
                                                                          padding:
                                                                            "0px 0px 0px 12px",
                                                                        }}
                                                                      >
                                                                        Points
                                                                      </div>
                                                                    </li>
                                                                    <li id="seconds">
                                                                      <div class="numberhome"></div>
                                                                      <div class="label"></div>
                                                                    </li>
                                                                  </ul>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div class="col-md-4 col-6 customHalfWidth">
                                                            <div class="cam-cols">
                                                              <div class="timer-here">
                                                                <div class="text-center">
                                                                  <span class="campaign-time-title">
                                                                    Time Left
                                                                  </span>
                                                                </div>
                                                                <div>
                                                                  <ul id="countdown">
                                                                    <li id="hours">
                                                                      <div class="number">
                                                                        {
                                                                          tile.dayrem
                                                                        }
                                                                      </div>
                                                                      <div class="label">
                                                                        Days
                                                                      </div>
                                                                    </li>
                                                                    <li id="minutes">
                                                                      <div class="number">
                                                                        {
                                                                          tile.hourrem
                                                                        }
                                                                      </div>
                                                                      <div class="label">
                                                                        Hours
                                                                      </div>
                                                                    </li>
                                                                    <li id="seconds">
                                                                      <div class="number">
                                                                        {
                                                                          tile.minrem
                                                                        }
                                                                      </div>
                                                                      <div class="label">
                                                                        Mins
                                                                      </div>
                                                                    </li>
                                                                  </ul>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div class="col-md-4 customFullWidth">
                                                            <div class="par-btn-here">
                                                              <div class="text-center">
                                                                <a class="par-now-new">
                                                                  <Button
                                                                    onClick={() =>
                                                                      navigate(
                                                                        `/participate/${tile.campid}/${profileResponse?.phoneno}`
                                                                      )
                                                                    }
                                                                    style={{
                                                                      color:
                                                                        "white",
                                                                      padding:
                                                                        "6px",
                                                                      fontSize:
                                                                        "12px",
                                                                    }}
                                                                    className={
                                                                      classes.button
                                                                    }
                                                                    name={
                                                                      "activepnow" +
                                                                      tile.campid
                                                                    }
                                                                  >
                                                                    Participate Now{" "}
                                                                    <i
                                                                      class="fa fa-arrow-right"
                                                                      aria-hidden="true"
                                                                    ></i>
                                                                  </Button>
                                                                </a>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="mr-15 text-center">
                                                          <a class="campaign-signin">
                                                            Join Now{" "}
                                                            <i
                                                              class="fa fa-arrow-right"
                                                              aria-hidden="true"
                                                            ></i>
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="logged-cam-detai">
                                                <div class="aft-log-acc">
                                                  <div class="widget-content widget-content-area creative2-accordion-content">
                                                    <div id="creativeAccordion">
                                                      <div class="card mb-3">
                                                        <div
                                                          class="card-header"
                                                          id="creative2headingOne"
                                                        >
                                                          <h5 class="mb-0 mt-0">
                                                            <span
                                                              role="menu"
                                                              class="changeIcon collapsed"
                                                              data-toggle="collapse"
                                                              data-target={
                                                                "#" +
                                                                "creative2CollapseOne" +
                                                                "Active" +
                                                                index
                                                              }
                                                              aria-expanded="true"
                                                              aria-controls="creative2CollapseOne"
                                                            >
                                                              <span class="icon">
                                                                <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                              </span>
                                                              <span class="text ml-2">
                                                                Achieve the goal
                                                                and win rewards
                                                              </span>
                                                              {/* <span class="text">
                                                              rewards
                                                            </span> */}
                                                            </span>
                                                          </h5>
                                                        </div>

                                                        <div
                                                          // id="creative2CollapseOne"
                                                          id={
                                                            "creative2CollapseOne" +
                                                            "Active" +
                                                            index
                                                          }
                                                          class="collapse"
                                                          aria-labelledby="creative2headingOne"
                                                          data-parent="#creativeAccordion"
                                                        >
                                                          <div class="card-body">
                                                            <p class="mb-3">
                                                              1. {tile?.goal}
                                                            </p>
                                                            <p class="mb-3">
                                                              2. {tile?.desc}
                                                            </p>
                                                            {/* <p class="mb-3">
                                                            3. You win points
                                                            when you participate
                                                            and also when you
                                                            invite a friend and
                                                            that friend
                                                            participates.
                                                          </p> */}
                                                            <p class="mb-3">
                                                              3. Win upto{" "}
                                                              <b>
                                                                {" "}
                                                                {
                                                                  tile?.maxpointstobeearned
                                                                }{" "}
                                                              </b>
                                                              points by
                                                              Participating or
                                                              Inviting motivated
                                                              friends to join
                                                              this Campaign.
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="card mb-3">
                                                        <div
                                                          class="card-header"
                                                          id="creative2HeadingTwo"
                                                        >
                                                          <h5 class="mb-0 mt-0">
                                                            <span
                                                              role="menu"
                                                              class="changeIcon collapsed"
                                                              data-toggle="collapse"
                                                              data-target={
                                                                "#" +
                                                                "creative2CollapseTwo" +
                                                                "Active" +
                                                                index
                                                              }
                                                              aria-expanded="false"
                                                              aria-controls="creative2CollapseTwo"
                                                            >
                                                              <span class="icon">
                                                                <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                              </span>
                                                              <span class="text ml-2">
                                                                {/* What to do ? */}
                                                                How to
                                                                participate?
                                                              </span>
                                                            </span>
                                                          </h5>
                                                        </div>
                                                        <div
                                                          id={
                                                            "creative2CollapseTwo" +
                                                            "Active" +
                                                            index
                                                          }
                                                          class="collapse"
                                                          aria-labelledby="creative2HeadingTwo"
                                                          data-parent="#creativeAccordion"
                                                        >
                                                          <div class="card-body">
                                                            <p class="mb-3">
                                                              {splitString1(
                                                                // tile?.socialmediamsg
                                                                tile?.whattodo
                                                              )}
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="card mb-3">
                                                        <div
                                                          class="card-header"
                                                          id="creative2HeadingThree"
                                                        >
                                                          <h5 class="mb-0 mt-0">
                                                            <span
                                                              role="menu"
                                                              class="changeIcon collapsed"
                                                              data-toggle="collapse"
                                                              data-target={
                                                                "#" +
                                                                "creative2CollapseThree" +
                                                                "Active" +
                                                                index
                                                              }
                                                              aria-expanded="false"
                                                              aria-controls="creative2CollapseThree"
                                                            >
                                                              <span class="icon">
                                                                <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                              </span>
                                                              <span class="text ml-2">
                                                                Guidelines
                                                              </span>
                                                            </span>
                                                          </h5>
                                                        </div>
                                                        <div
                                                          id={
                                                            "creative2CollapseThree" +
                                                            "Active" +
                                                            index
                                                          }
                                                          class="collapse"
                                                          aria-labelledby="creative2HeadingThree"
                                                          data-parent="#creativeAccordion"
                                                        >
                                                          <div class="card-body">
                                                            <p class="mb-3">
                                                              {splitString1(
                                                                // tile?.socialmediamsg
                                                                tile?.guidelines
                                                              )}
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <hr />
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                  </OwlCarousel>
                                </div>

                                <div
                                  class="tab-pane fade"
                                  id="animated-underline-profile"
                                  role="tabpanel"
                                  aria-labelledby="animated-underline-profile-tab"
                                  onTouchStart={handleTouch}
                                  onTouchMove={handleTouch}
                                  onTouchEnd={handleTouch}
                                >
                                  {campStatusCount?.data?.[1]?.nextcount ===
                                    0 && (
                                      <span>
                                        <i>
                                          <b>
                                            Please keep watching this space for
                                            new campaigns. You will also be
                                            notified here.
                                          </b>
                                        </i>
                                      </span>
                                    )}
                                  {/* <div class="campaign-sl owl-carousel owl-theme"> */}
                                  <OwlCarousel
                                    className="owl-theme"
                                    {...optionsTwo}
                                  >
                                    {isArray(myCampaignList) && myCampaignList?.data
                                      ?.filter(
                                        (item, index) =>
                                          item.active === "Upcoming"
                                      )
                                      .map((tile, index) => (
                                        <div class="item">
                                          <div class="">
                                            <div class="campaigns-wrap campaigns-wrap-new">
                                              <div>
                                                <div>
                                                  <div class="campaign-img">
                                                    <div class="video-wrapper">
                                                      <div
                                                        class="video-container"
                                                        id="video-container"
                                                      >
                                                        <video
                                                          controls
                                                          controlsList="nodownload"
                                                          id="video"
                                                          // preload="metadata"
                                                          preload="auto"
                                                          poster={tile?.logo}
                                                          autoplay
                                                        >
                                                          <source
                                                            // src="//cdn.jsdelivr.net/npm/big-buck-bunny-1080p@0.0.6/video.mp4"
                                                            src={tile?.video}
                                                            type="video/mp4"
                                                          />
                                                        </video>

                                                        {/* <div class="play-button-wrapper">
                                                      <div
                                                        title="Play video"
                                                        class="play-gif"
                                                        id="circle-play-b"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 80 80"
                                                        >
                                                          <path d="M40 0a40 40 0 1040 40A40 40 0 0040 0zM26 61.56V18.44L64 40z" />
                                                        </svg>
                                                      </div>
                                                    </div> */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <h5
                                                    title={tile?.name}
                                                    class="campaign-title-new"
                                                  >
                                                    {tile?.name}
                                                  </h5>
                                                  <div class="campaign-hashtags">
                                                    {/* {tile.tag.map((tag) => (
                                                    <a class="hash-styles">
                                                      {tag?.tag}
                                                    </a>
                                                  ))} */}
                                                    {tile.tag.map((tag) =>
                                                      SDGCampMapping?.map(
                                                        (item) =>
                                                          item?.cat ===
                                                          tag?.label && (
                                                            <a
                                                              class="hash-styles"
                                                              style={{
                                                                cursor: "initial",

                                                              }}
                                                            >
                                                              {tag?.tag}{" "}
                                                              <a
                                                                class="hash-styles-sdg"
                                                                style={{
                                                                  cursor: "initial",
                                                                  color: "white",
                                                                  marginTop: "2px",
                                                                }}
                                                              >
                                                                SDG {item?.sdg}
                                                              </a>
                                                            </a>
                                                          )
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                                <div
                                                  className={
                                                    classes.campaigntypewrapper
                                                  }
                                                >
                                                  <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    gap={0.5}
                                                  >
                                                    {/* <h5 class="campaign-goal"> */}
                                                    <h5 class="campaign-goal">
                                                      <a
                                                        class="hash-styles-goal"
                                                        style={{
                                                          padding:
                                                            "0px 5px 0px 0px",
                                                        }}
                                                      >
                                                        <span style={{ fontSize: "16px", fontWeight: 700 }}>Goal</span>: <span class="text-6" style={{ color: "black" }}>{tile.goalquantity}{" "} {tile.unitofgoal}</span>
                                                      </a>
                                                    </h5>
                                                  </Stack>
                                                </div>
                                              </div>
                                              <div class="row">
                                                <div class="col-md-12">
                                                  <div>
                                                    <div class="campaigns-details">

                                                      <div class="p-bar-new">
                                                        <div class="row ">
                                                          <div class="col-6">
                                                            <div>
                                                              <h6>
                                                                <span style={{ color: "#AAAAAA" }}>Pledged: </span>{" "} <span style={{ backgroundColor: "#FFDB58" }}>
                                                                  {Math.round(
                                                                    (tile.committedcount)
                                                                  )}
                                                                </span>
                                                              </h6>
                                                            </div>
                                                          </div>
                                                          <div class="col-6">
                                                            <div
                                                              style={{
                                                                textAlign:
                                                                  "right",
                                                              }}
                                                            >
                                                              <h6>
                                                                <span style={{ color: "#AAAAAA" }}>Achieved: </span>
                                                                {" "}
                                                                <span style={{ backgroundColor: "#A9D18E" }}> {tile.achievedcount}</span>
                                                                {" "}
                                                              </h6>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="progress br-30 campaign-progress" style={{ position: "relative" }}>
                                                          <div
                                                            className="progress-bar"
                                                            role="progressbar"
                                                            style={{
                                                              width: `${(tile.committedcount / tile.goalquantity) * 100}%`,
                                                              backgroundColor: "#FFDB58",
                                                              position: "absolute",
                                                              top: 0,
                                                              left: 0,
                                                              zIndex: 1,
                                                              height: "100%"
                                                            }}
                                                            aria-valuenow={Math.round((tile.committedcount / tile.goalquantity) * 100)}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                          ></div>

                                                          <div
                                                            className="progress-bar"
                                                            role="progressbar"
                                                            style={{
                                                              width: `${(tile.achievedcount / tile.goalquantity) * 100}%`,
                                                              backgroundColor: "#A9D18E",
                                                              position: "absolute",
                                                              top: 0,
                                                              left: 0,
                                                              zIndex: 2,
                                                              height: "100%"
                                                            }}
                                                            aria-valuenow={Math.round((tile.achievedcount / tile.goalquantity) * 100)}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                          ></div>
                                                        </div>
                                                      </div>
                                                      <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                                        <div style={{ flex: 1 }}>
                                                          <h6>
                                                            <span style={{ color: "#AAAAAA" }}>Signed Up:</span>{" "}
                                                            <span style={{ backgroundColor: "#DAE8FD" }}>{tile.submittedcount}</span>
                                                          </h6>
                                                        </div>
                                                        <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                          <Chip
                                                            key={tile.campaigntype}
                                                            fontSize="small"
                                                            size="small"
                                                            label={tile.campaigntype}
                                                          />
                                                          <img
                                                            src={CampaignLeaderBoardIcon}
                                                            style={{ width: 25, height: 25, cursor: "pointer", }}
                                                            onClick={() =>
                                                              navigate(
                                                                `/camp/leaderboard/${tile.campid}`
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </div>



                                                      <div class="row">
                                                        <div class="col-md-4 col-6 customHalfWidth">
                                                          <div class="cam-cols">
                                                            <div class="timer-here">
                                                              <div class="text-center">
                                                                <span class="campaign-time-title">
                                                                  Earn upto
                                                                </span>
                                                              </div>
                                                              <div>
                                                                <ul id="countdown">
                                                                  <li id="hours">
                                                                    <div class="numberhome"></div>
                                                                    <div class="label"></div>
                                                                  </li>
                                                                  <li id="minutes">
                                                                    <div
                                                                      class="numberhome"
                                                                      style={{
                                                                        padding:
                                                                          "0px 0px 0px 6px",
                                                                      }}
                                                                    >
                                                                      {
                                                                        tile.maxpointstobeearned
                                                                      }
                                                                    </div>
                                                                    <div
                                                                      class="label"
                                                                      style={{
                                                                        padding:
                                                                          "0px 0px 0px 12px",
                                                                      }}
                                                                    >
                                                                      Points
                                                                    </div>
                                                                  </li>
                                                                  <li id="seconds">
                                                                    <div class="numberhome"></div>
                                                                    <div class="label"></div>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div class="col-md-4 col-6 customHalfWidth">
                                                          <div class="cam-cols">
                                                            <div class="timer-here">
                                                              <div class="text-center">
                                                                <span class="campaign-time-title">
                                                                  Time Left
                                                                </span>
                                                              </div>
                                                              <div>
                                                                <ul id="countdown">
                                                                  <li id="hours">
                                                                    <div class="number">
                                                                      {
                                                                        tile.dayrem
                                                                      }
                                                                    </div>
                                                                    <div class="label">
                                                                      Days
                                                                    </div>
                                                                  </li>
                                                                  <li id="minutes">
                                                                    <div class="number">
                                                                      {
                                                                        tile.hourrem
                                                                      }
                                                                    </div>
                                                                    <div class="label">
                                                                      Hours
                                                                    </div>
                                                                  </li>
                                                                  <li id="seconds">
                                                                    <div class="number">
                                                                      {
                                                                        tile.minrem
                                                                      }
                                                                    </div>
                                                                    <div class="label">
                                                                      Mins
                                                                    </div>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="col-md-4 customFullWidth">
                                                          <div class="par-btn-here">
                                                            <div class="text-center">
                                                              <a class="par-now-new">
                                                                {isDesktop ? (
                                                                  <Button
                                                                    onClick={() =>
                                                                      navigate(
                                                                        `/participate/${tile.campid}/${profileResponse?.phoneno}`
                                                                      )
                                                                    }
                                                                    style={{
                                                                      color:
                                                                        "white",
                                                                      // padding:"0px 0px 0px 0px",
                                                                      padding:
                                                                        "6px",
                                                                      fontSize:
                                                                        "12px",
                                                                    }}
                                                                    className={
                                                                      classes.button
                                                                    }
                                                                    name={
                                                                      "upcomingpnow" +
                                                                      tile.campid
                                                                    }
                                                                  >
                                                                    Participate
                                                                    Now{" "}
                                                                    <i
                                                                      class="fa fa-arrow-right"
                                                                      aria-hidden="true"
                                                                    ></i>
                                                                  </Button>
                                                                ) : (
                                                                  <Button
                                                                    onClick={() =>
                                                                      navigate(
                                                                        `/participate/${tile.campid}/${profileResponse?.phoneno}`
                                                                      )
                                                                    }
                                                                    style={{
                                                                      color:
                                                                        "white",
                                                                      fontSize: 10,
                                                                    }}
                                                                    className={
                                                                      classes.button
                                                                    }
                                                                    name={
                                                                      "upcomingpnow" +
                                                                      tile.campid
                                                                    }
                                                                  >
                                                                    Participate
                                                                    Now{" "}
                                                                    <i
                                                                      class="fa fa-arrow-right"
                                                                      aria-hidden="true"
                                                                    ></i>
                                                                  </Button>
                                                                )}
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>

                                                      {/* <div class="p-bar-new">
                                                        <div class="row ">
                                                          <div class="col-6">
                                                            <div>
                                                              <h6>
                                                                {Math.round(
                                                                  (tile.submittedcount /
                                                                    tile.targetvol) *
                                                                  100
                                                                )}
                                                                % submitted
                                                              </h6>
                                                            </div>
                                                          </div>
                                                          <div class="col-6">
                                                            <div
                                                              style={{
                                                                textAlign:
                                                                  "right",
                                                              }}
                                                            >
                                                              <h6>
                                                                {
                                                                  tile.submittedcount
                                                                }
                                                                /
                                                                {tile.targetvol}
                                                              </h6>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="progress br-30 campaign-progress">
                                                          <div
                                                            class="progress-bar bg-primary"
                                                            role="progressbar"
                                                            style={{
                                                              // width: "20%",
                                                              width:
                                                                Math.round(
                                                                  (tile.submittedcount /
                                                                    tile.targetvol) *
                                                                  100
                                                                ) + "%",
                                                            }}
                                                            // aria-valuenow="25"
                                                            aria-valuenow={Math.round(
                                                              (tile.submittedcount /
                                                                tile.targetvol) *
                                                              100
                                                            )}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                          ></div>
                                                        </div>
                                                        <span class="progress-txt">
                                                          {tile.submittedcount}{" "}
                                                          changemaker(s)
                                                          submitted out of{" "}
                                                          {tile.targetvol}
                                                        </span>
                                                      </div> */}

                                                      {/* <div class="p-bar-new">
                                                        <div class="row ">
                                                          <div class="col-6">
                                                            <div>
                                                              <h6>
                                                                {Math.round(
                                                                  (tile.adoptioncount /
                                                                    tile.targetvol) *
                                                                  100
                                                                )}
                                                                % approved
                                                              </h6>
                                                            </div>
                                                          </div>
                                                          <div class="col-6">
                                                            <div
                                                              style={{
                                                                textAlign:
                                                                  "right",
                                                              }}
                                                            >
                                                              <h6>
                                                                {
                                                                  tile.adoptioncount
                                                                }
                                                                /
                                                                {tile.targetvol}
                                                              </h6>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="progress br-30 campaign-progress">
                                                          <div
                                                            class="progress-bar bg-approved"
                                                            role="progressbar"
                                                            style={{
                                                              width:
                                                                Math.round(
                                                                  (tile.adoptioncount /
                                                                    tile.targetvol) *
                                                                  100
                                                                ) + "%",
                                                            }}
                                                            aria-valuenow="25"
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                          ></div>
                                                        </div>
                                                        <span class="progress-txt">
                                                          {tile.adoptioncount}{" "}
                                                          changemaker(s)
                                                          approved out of{" "}
                                                          {tile.targetvol}
                                                        </span>
                                                      </div> */}
                                                      <div class="mr-15 text-center">
                                                        <a class="campaign-signin">
                                                          Join Now{" "}
                                                          <i
                                                            class="fa fa-arrow-right"
                                                            aria-hidden="true"
                                                          ></i>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="logged-cam-detai">
                                              <div class="aft-log-acc">
                                                <div class="widget-content widget-content-area creative2-accordion-content">
                                                  <div id="creativeAccordion">
                                                    <div class="card mb-3">
                                                      <div
                                                        class="card-header"
                                                        id="creative2headingOne"
                                                      >
                                                        <h5 class="mb-0 mt-0">
                                                          <span
                                                            role="menu"
                                                            class="changeIcon collapsed"
                                                            data-toggle="collapse"
                                                            // data-target="#creative2CollapseOne"
                                                            data-target={
                                                              "#" +
                                                              "creative2CollapseOne" +
                                                              "Upcoming" +
                                                              index
                                                            }
                                                            aria-expanded="true"
                                                            aria-controls="creative2CollapseOne"
                                                          >
                                                            <span class="icon">
                                                              <i class="fa fa-plus"></i>
                                                              <i class="fa fa-minus"></i>
                                                            </span>
                                                            <span class="text ml-2">
                                                              Achieve the goal
                                                              and win rewards
                                                            </span>
                                                          </span>
                                                        </h5>
                                                      </div>

                                                      <div
                                                        id={
                                                          "creative2CollapseOne" +
                                                          "Upcoming" +
                                                          index
                                                        }
                                                        class="collapse"
                                                        aria-labelledby="creative2headingOne"
                                                        data-parent="#creativeAccordion"
                                                      >
                                                        <div class="card-body">
                                                          <p class="mb-3">
                                                            1. {tile?.goal}
                                                          </p>
                                                          <p class="mb-3">
                                                            2. {tile?.desc}
                                                          </p>
                                                          {/* <p class="mb-3">
                                                          3. You win points when
                                                          you participate and
                                                          also when you invite a
                                                          friend and that friend
                                                          participates.
                                                        </p> */}
                                                          <p class="mb-3">
                                                            3. Win upto{" "}
                                                            <b>
                                                              {" "}
                                                              {
                                                                tile?.maxpointstobeearned
                                                              }{" "}
                                                            </b>
                                                            points by
                                                            Participating or
                                                            Inviting motivated
                                                            friends to join this
                                                            Campaign.
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div class="card mb-3">
                                                      <div
                                                        class="card-header"
                                                        id="creative2HeadingTwo"
                                                      >
                                                        <h5 class="mb-0 mt-0">
                                                          <span
                                                            role="menu"
                                                            class="changeIcon collapsed"
                                                            data-toggle="collapse"
                                                            data-target={
                                                              "#" +
                                                              "creative2CollapseTwo" +
                                                              "Upcoming" +
                                                              index
                                                            }
                                                            aria-expanded="false"
                                                            aria-controls="creative2CollapseTwo"
                                                          >
                                                            <span class="icon">
                                                              <i class="fa fa-plus"></i>
                                                              <i class="fa fa-minus"></i>
                                                            </span>
                                                            <span class="text ml-2">
                                                              {/* What to do ? */}
                                                              How to
                                                              participate?
                                                            </span>
                                                          </span>
                                                        </h5>
                                                      </div>
                                                      <div
                                                        id={
                                                          "creative2CollapseTwo" +
                                                          "Upcoming" +
                                                          index
                                                        }
                                                        class="collapse"
                                                        aria-labelledby="creative2HeadingTwo"
                                                        data-parent="#creativeAccordion"
                                                      >
                                                        <div class="card-body">
                                                          <p class="mb-3">
                                                            {splitString1(
                                                              // tile?.socialmediamsg
                                                              tile?.whattodo
                                                            )}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div class="card mb-3">
                                                      <div
                                                        class="card-header"
                                                        id="creative2HeadingThree"
                                                      >
                                                        <h5 class="mb-0 mt-0">
                                                          <span
                                                            role="menu"
                                                            class="changeIcon collapsed"
                                                            data-toggle="collapse"
                                                            data-target={
                                                              "#" +
                                                              "creative2CollapseThree" +
                                                              "Upcoming" +
                                                              index
                                                            }
                                                            aria-expanded="false"
                                                            aria-controls="creative2CollapseThree"
                                                          >
                                                            <span class="icon">
                                                              <i class="fa fa-plus"></i>
                                                              <i class="fa fa-minus"></i>
                                                            </span>
                                                            <span class="text ml-2">
                                                              Guidelines
                                                            </span>
                                                          </span>
                                                        </h5>
                                                      </div>
                                                      <div
                                                        id={
                                                          "creative2CollapseThree" +
                                                          "Upcoming" +
                                                          index
                                                        }
                                                        class="collapse"
                                                        aria-labelledby="creative2HeadingThree"
                                                        data-parent="#creativeAccordion"
                                                      >
                                                        <div class="card-body">
                                                          <p class="mb-3">
                                                            {splitString1(
                                                              // tile?.socialmediamsg
                                                              tile?.guidelines
                                                            )}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <hr />
                                              {/* <div class="comm-backg">
                                              <div class="points-rank">
                                                <div class="row">
                                                  <div class="col-6">
                                                    <div class="text-center">
                                                      <span>
                                                        My Points
                                                      </span>
                                                      <h6 class="cam-curr-point">
                                                        
                                                        {
                                                          tile?.myrankpoints
                                                            ?.rewardpoint
                                                        }
                                                      </h6>
                                                    </div>
                                                  </div>
                                                  <div class="col-6">
                                                    <div class="text-center">
                                                      <span>
                                                        My Rank
                                                      </span>
                                                      <h6 class="cam-curr-point">
                                                        {
                                                          tile?.myrankpoints
                                                            ?.rank
                                                        }
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <hr />
                                              <div>
                                                <div class="row">
                                                  <div class="col-6">
                                                    <div class="text-center">
                                                      <span class="pp-p-text">
                                                        Community Size
                                                      </span>
                                                      <h6 class="pp-point">
                                                        {
                                                          tile?.mycommunity
                                                            ?.refcount
                                                        }
                                                      </h6>
                                                    </div>
                                                  </div>
                                                  <div class="col-6">
                                                    <div class="text-center">
                                                      <span class="pp-p-text">
                                                        Community Points
                                                      </span>
                                                      <h6 class="pp-point">
                                                        {
                                                          tile?.mycommunity
                                                            ?.rewardpoint
                                                        }
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div> */}
                                              {/* <hr /> */}
                                              {/* <div>
                                              <h6>
                                                Impact done by my community.{" "}
                                              </h6>
                                              <div class="row">
                                                <div class="col-md-4 col-6">
                                                  <div class="text-center margin-bottom-10">
                                                    <img
                                                      src="https://www.pngall.com/wp-content/uploads/1/Community-PNG-Image.png"
                                                      class="img-fluid cam-commun"
                                                    />
                                                    <span class="cam-commun-text">
                                                      Lorem ipsum dolor sit amet
                                                      consectetur adipisicing
                                                      elit
                                                    </span>
                                                  </div>
                                                </div>
                                                <div class="col-md-4 col-6">
                                                  <div class="text-center margin-bottom-10">
                                                    <img
                                                      src="https://www.pngitem.com/pimgs/m/162-1625008_save-the-earth-png-transparent-png.png"
                                                      class="img-fluid cam-commun"
                                                    />
                                                    <span class="cam-commun-text">
                                                      Lorem ipsum dolor sit amet
                                                      consectetur adipisicing
                                                      elit
                                                    </span>
                                                  </div>
                                                </div>
                                                <div class="col-md-4 col-6">
                                                  <div class="text-center margin-bottom-10">
                                                    <img
                                                      src="https://cdn-icons-png.flaticon.com/512/1684/1684388.png"
                                                      class="img-fluid cam-commun"
                                                    />
                                                    <span class="cam-commun-text">
                                                      Lorem ipsum dolor sit amet
                                                      consectetur adipisicing
                                                      elit
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <hr /> */}
                                              {/* {tile?.campregdetails?.map(
                                              (item) => (
                                                <div>
                                                  <span>
                                                    Participation Status
                                                  </span>{" "}
                                                  {item?.campregistrationstatus ===
                                                  "submitted" ? (
                                                    <a class="p-regstatus">
                                                      {
                                                        item?.campregistrationstatus
                                                      }
                                                    </a>
                                                  ) : item?.campregistrationstatus ===
                                                    "approved" ? (
                                                    <a class="p-apprstatus">
                                                      {
                                                        item?.campregistrationstatus
                                                      }
                                                    </a>
                                                  ) : item?.campregistrationstatus ===
                                                    "rejected" ? (
                                                    <a class="p-rejstatus">
                                                      {
                                                        item?.campregistrationstatus
                                                      }
                                                    </a>
                                                  ) : (
                                                    <a class="p-notregstatus">
                                                      {
                                                        item?.campregistrationstatus
                                                      }
                                                    </a>
                                                  )}
                                                  
                                                </div>
                                              )
                                            )} */}
                                              {/* <hr /> */}
                                              {/* <div class="text-center">
                                              <a class="par-now">
                                                {isDesktop ? (
                                                  <Button
                                                    onClick={() =>
                                                      navigate(
                                                        `/participate/${tile.campid}/${profileResponse?.phoneno}`
                                                      )
                                                    }
                                                    style={{ color: "white" }}
                                                    className={classes.button}
                                                    name="participatenow"
                                                  >
                                                    Participate Now{" "}
                                                    <i
                                                      class="fa fa-arrow-right"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </Button>
                                                ) : (
                                                  <Button
                                                    onClick={() =>
                                                      navigate(
                                                        `/participate/${tile.campid}/${profileResponse?.phoneno}`
                                                      )
                                                    }
                                                    style={{
                                                      color: "white",
                                                      fontSize: 10,
                                                    }}
                                                    className={classes.button}
                                                    name="participatenow"
                                                  >
                                                    Participate Now{" "}
                                                    <i
                                                      class="fa fa-arrow-right"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </Button>
                                                )}
                                              </a>
                                            </div> */}
                                              {/* <hr /> */}
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                  </OwlCarousel>
                                </div>
                                <div
                                  class="tab-pane fade"
                                  id="animated-underline-contact"
                                  role="tabpanel"
                                  aria-labelledby="animated-underline-contact-tab"
                                >
                                  {/* <div class="campaign-sl owl-carousel owl-theme"> */}
                                  {campStatusCount?.data?.[2]?.donecount ===
                                    0 && (
                                      <span>
                                        <i>
                                          <b>
                                            Completed and Paused campaigns will
                                            appear here.
                                          </b>
                                        </i>
                                      </span>
                                    )}
                                  <OwlCarousel
                                    className="owl-theme"
                                    {...optionsTwo}
                                  >
                                    {isArray(myCampaignList) && myCampaignList?.data
                                      ?.filter(
                                        (item, index) =>
                                          item.active === "Completed" ||
                                          item.active === "Paused"
                                      )
                                      .map((tile, index) => (
                                        <div class="item">
                                          <div class="">
                                            <div class="campaigns-wrap campaigns-wrap-new">
                                              <div>
                                                <div>
                                                  {tile?.active ===
                                                    "Completed" ? (
                                                    <Typography
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      <Chip
                                                        key={tile.active}
                                                        fontSize="small"
                                                        size="small"
                                                        style={{
                                                          backgroundColor:
                                                            "green",
                                                          color: "white",
                                                          marginBottom: "5px",
                                                        }}
                                                        label={tile.active}
                                                      />
                                                    </Typography>
                                                  ) : (
                                                    <Typography
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      <Chip
                                                        key={tile.active}
                                                        fontSize="small"
                                                        size="small"
                                                        style={{
                                                          backgroundColor:
                                                            "#1976d2",
                                                          color: "white",
                                                          marginBottom: "5px",
                                                        }}
                                                        label={tile.active}
                                                      />
                                                    </Typography>
                                                  )}
                                                  <div class="campaign-img">
                                                    <div class="video-wrapper">
                                                      <div
                                                        class="video-container"
                                                        id="video-container"
                                                      >
                                                        <video
                                                          controls
                                                          controlsList="nodownload"
                                                          id="video"
                                                          // preload="metadata"
                                                          preload="auto"
                                                          poster={tile?.logo}
                                                          autoplay
                                                        >
                                                          <source
                                                            // src="//cdn.jsdelivr.net/npm/big-buck-bunny-1080p@0.0.6/video.mp4"
                                                            src={tile?.video}
                                                            type="video/mp4"
                                                          />
                                                        </video>

                                                        {/* <div class="play-button-wrapper">
                                                      <div
                                                        title="Play video"
                                                        class="play-gif"
                                                        id="circle-play-b"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 80 80"
                                                        >
                                                          <path d="M40 0a40 40 0 1040 40A40 40 0 0040 0zM26 61.56V18.44L64 40z" />
                                                        </svg>
                                                      </div>
                                                    </div> */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <h5
                                                    title={tile?.name}
                                                    class="campaign-title-new"
                                                  >
                                                    {tile?.name}
                                                  </h5>
                                                  <div class="campaign-hashtags">
                                                    {/* {tile.tag.map((tag) => (
                                                    <a class="hash-styles">
                                                      {tag?.tag}
                                                    </a>
                                                  ))} */}
                                                    {tile.tag.map((tag) =>
                                                      SDGCampMapping?.map(
                                                        (item) =>
                                                          item?.cat ===
                                                          tag?.label && (
                                                            <a
                                                              class="hash-styles"
                                                              style={{
                                                                cursor:
                                                                  "initial",
                                                              }}
                                                            >
                                                              {tag?.tag}{" "}
                                                              <a
                                                                class="hash-styles-sdg"
                                                                style={{
                                                                  cursor:
                                                                    "initial",
                                                                  color: "white",
                                                                  marginTop: "2px",
                                                                }}
                                                              >
                                                                SDG {item?.sdg}
                                                              </a>
                                                            </a>
                                                          )
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                                <div
                                                  className={
                                                    classes.campaigntypewrapper
                                                  }
                                                >
                                                  <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    gap={0.5}
                                                  >
                                                    {/* <h5 class="campaign-goal"> */}
                                                    <h5 class="campaign-goal">
                                                      <a
                                                        class="hash-styles-goal"
                                                        style={{
                                                          padding:
                                                            "0px 5px 0px 0px",
                                                        }}
                                                      >
                                                        {/* Need {tile.targetvol}{" "}
                                                        changemakers */}
                                                        <span style={{ fontSize: "16px", fontWeight: 700 }}>Goal</span>: <span class="text-6" style={{ color: "black" }}>{tile.goalquantity}{" "} {tile.unitofgoal}</span>
                                                      </a>

                                                    </h5>

                                                  </Stack>
                                                </div>
                                              </div>
                                              <div class="row">
                                                <div class="col-md-12">
                                                  <div>
                                                    <div class="campaigns-details">
                                                      <div class="p-bar-new">
                                                        <div class="row ">
                                                          <div class="col-6">
                                                            <div>
                                                              <h6>
                                                                <span style={{ color: "#AAAAAA" }}>Pledged: </span>{" "} <span style={{ backgroundColor: "#FFDB58" }}>
                                                                  {Math.round(
                                                                    (tile.committedcount)
                                                                  )}
                                                                </span>
                                                              </h6>
                                                            </div>
                                                          </div>
                                                          <div class="col-6">
                                                            <div
                                                              style={{
                                                                textAlign:
                                                                  "right",
                                                              }}
                                                            >
                                                              <h6>
                                                                <span style={{ color: "#AAAAAA" }}>Achieved: </span>
                                                                {" "}
                                                                <span style={{ backgroundColor: "#A9D18E" }}> {tile.achievedcount}</span>
                                                                {" "}
                                                              </h6>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="progress br-30 campaign-progress" style={{ position: "relative" }}>
                                                          <div
                                                            className="progress-bar"
                                                            role="progressbar"
                                                            style={{
                                                              width: `${(tile.committedcount / tile.goalquantity) * 100}%`,
                                                              backgroundColor: "#FFDB58",
                                                              position: "absolute",
                                                              top: 0,
                                                              left: 0,
                                                              zIndex: 1,
                                                              height: "100%"
                                                            }}
                                                            aria-valuenow={Math.round((tile.committedcount / tile.goalquantity) * 100)}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                          ></div>

                                                          <div
                                                            className="progress-bar"
                                                            role="progressbar"
                                                            style={{
                                                              width: `${(tile.achievedcount / tile.goalquantity) * 100}%`,
                                                              backgroundColor: "#A9D18E",
                                                              position: "absolute",
                                                              top: 0,
                                                              left: 0,
                                                              zIndex: 2,
                                                              height: "100%"
                                                            }}
                                                            aria-valuenow={Math.round((tile.achievedcount / tile.goalquantity) * 100)}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                          ></div>
                                                        </div>
                                                      </div>
                                                      <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                                        <div style={{ flex: 1 }}>
                                                          <h6>
                                                            <span style={{ color: "#AAAAAA" }}>Signed Up:</span>{" "}
                                                            <span style={{ backgroundColor: "#DAE8FD" }}>{tile.submittedcount}</span>
                                                          </h6>
                                                        </div>
                                                        <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                          <Chip
                                                            key={tile.campaigntype}
                                                            fontSize="small"
                                                            size="small"
                                                            label={tile.campaigntype}
                                                          />
                                                          <img
                                                            src={CampaignLeaderBoardIcon}
                                                            style={{ width: 25, height: 25, cursor: "pointer", }}
                                                            onClick={() =>
                                                              navigate(
                                                                `/camp/leaderboard/${tile.campid}`
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                      <div class="row">
                                                        <div class="col-md-4 col-6 customHalfWidth">
                                                          <div class="cam-cols">
                                                            <div class="timer-here">
                                                              <div class="text-center">
                                                                <span class="campaign-time-title">
                                                                  Earn upto
                                                                </span>
                                                              </div>
                                                              <div>
                                                                <ul id="countdown">
                                                                  <li id="hours">
                                                                    <div class="numberhome"></div>
                                                                    <div class="label"></div>
                                                                  </li>
                                                                  <li id="minutes">
                                                                    <div
                                                                      class="numberhome"
                                                                      style={{
                                                                        padding:
                                                                          "0px 0px 0px 6px",
                                                                      }}
                                                                    >
                                                                      {
                                                                        tile.maxpointstobeearned
                                                                      }
                                                                    </div>
                                                                    <div
                                                                      class="label"
                                                                      style={{
                                                                        padding:
                                                                          "0px 0px 0px 12px",
                                                                      }}
                                                                    >
                                                                      Points
                                                                    </div>
                                                                  </li>
                                                                  <li id="seconds">
                                                                    <div class="numberhome"></div>
                                                                    <div class="label"></div>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div class="col-md-4 col-6 customHalfWidth">
                                                          <div class="cam-cols">
                                                            <div class="timer-here">
                                                              <div class="text-center">
                                                                <span class="campaign-time-title">
                                                                  Time Left
                                                                </span>
                                                              </div>
                                                              <div>
                                                                <ul id="countdown">
                                                                  <li id="hours">
                                                                    <div class="number">
                                                                      {
                                                                        tile.dayrem
                                                                      }
                                                                    </div>
                                                                    <div class="label">
                                                                      Days
                                                                    </div>
                                                                  </li>
                                                                  <li id="minutes">
                                                                    <div class="number">
                                                                      {
                                                                        tile.hourrem
                                                                      }
                                                                    </div>
                                                                    <div class="label">
                                                                      Hours
                                                                    </div>
                                                                  </li>
                                                                  <li id="seconds">
                                                                    <div class="number">
                                                                      {
                                                                        tile.minrem
                                                                      }
                                                                    </div>
                                                                    <div class="label">
                                                                      Mins
                                                                    </div>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="col-md-4 customFullWidth">
                                                          {/* <div class="par-btn-here">
                                                          <div class="text-center">
                                                            <a class="par-now-new">
                                                              <Button
                                                                onClick={() =>
                                                                  navigate(
                                                                    `/participate/${tile.campid}/${profileResponse?.phoneno}`
                                                                  )
                                                                }
                                                                style={{ color: "white"}}
                                                                className={
                                                                  classes.button
                                                                }
                                                              >
                                                                Participate Now{" "}
                                                                <i
                                                                  class="fa fa-arrow-right"
                                                                  aria-hidden="true"
                                                                ></i>
                                                              </Button>
                                                            </a>
                                                          </div>
                                                        </div> */}
                                                        </div>
                                                      </div>

                                                      {/* <div class="p-bar-new">
                                                        <div class="row ">
                                                          <div class="col-6">
                                                            <div>
                                                              <h6>
                                                                {Math.round(
                                                                  (tile.submittedcount /
                                                                    tile.targetvol) *
                                                                  100
                                                                )}
                                                                % submitted
                                                              </h6>
                                                            </div>
                                                          </div>
                                                          <div class="col-6">
                                                            <div
                                                              style={{
                                                                textAlign:
                                                                  "right",
                                                              }}
                                                            >
                                                              <h6>
                                                                {
                                                                  tile.submittedcount
                                                                }
                                                                /
                                                                {tile.targetvol}
                                                              </h6>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="progress br-30 campaign-progress">
                                                          <div
                                                            class="progress-bar bg-primary"
                                                            role="progressbar"
                                                            style={{
                                                              // width: "20%",
                                                              width:
                                                                Math.round(
                                                                  (tile.submittedcount /
                                                                    tile.targetvol) *
                                                                  100
                                                                ) + "%",
                                                            }}
                                                            // aria-valuenow="25"
                                                            aria-valuenow={Math.round(
                                                              (tile.submittedcount /
                                                                tile.targetvol) *
                                                              100
                                                            )}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                          ></div>
                                                        </div>
                                                        <span class="progress-txt">
                                                          {tile.submittedcount}{" "}
                                                          changemaker(s)
                                                          submitted out of{" "}
                                                          {tile.targetvol}
                                                        </span>
                                                      </div> */}

                                                      {/* <div class="p-bar-new">
                                                        <div class="row ">
                                                          <div class="col-6">
                                                            <div>
                                                              <h6>
                                                                {Math.round(
                                                                  (tile.adoptioncount /
                                                                    tile.targetvol) *
                                                                  100
                                                                )}
                                                                % approved
                                                              </h6>
                                                            </div>
                                                          </div>
                                                          <div class="col-6">
                                                            <div
                                                              style={{
                                                                textAlign:
                                                                  "right",
                                                              }}
                                                            >
                                                              <h6>
                                                                {
                                                                  tile.adoptioncount
                                                                }
                                                                /
                                                                {tile.targetvol}
                                                              </h6>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="progress br-30 campaign-progress">
                                                          <div
                                                            class="progress-bar bg-approved"
                                                            role="progressbar"
                                                            style={{
                                                              width:
                                                                Math.round(
                                                                  (tile.adoptioncount /
                                                                    tile.targetvol) *
                                                                  100
                                                                ) + "%",
                                                            }}
                                                            aria-valuenow="25"
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                          ></div>
                                                        </div>
                                                        <span class="progress-txt">
                                                          {tile.adoptioncount}{" "}
                                                          changemaker(s)
                                                          approved out of{" "}
                                                          {tile.targetvol}
                                                        </span>
                                                      </div> */}
                                                      <div class="mr-15 text-center">
                                                        <a class="campaign-signin">
                                                          Join Now{" "}
                                                          <i
                                                            class="fa fa-arrow-right"
                                                            aria-hidden="true"
                                                          ></i>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="logged-cam-detai">
                                              <div class="aft-log-acc">
                                                <div class="widget-content widget-content-area creative2-accordion-content">
                                                  <div id="creativeAccordion">
                                                    <div class="card mb-3">
                                                      <div
                                                        class="card-header"
                                                        id="creative2headingOne"
                                                      >
                                                        <h5 class="mb-0 mt-0">
                                                          <span
                                                            role="menu"
                                                            class="changeIcon collapsed"
                                                            data-toggle="collapse"
                                                            // data-target="#creative2CollapseOne"
                                                            data-target={
                                                              "#" +
                                                              "creative2CollapseOne" +
                                                              "Completed" +
                                                              index
                                                            }
                                                            aria-expanded="true"
                                                            aria-controls="creative2CollapseOne"
                                                          >
                                                            <span class="icon">
                                                              <i class="fa fa-plus"></i>
                                                              <i class="fa fa-minus"></i>
                                                            </span>
                                                            <span class="text ml-2">
                                                              Achieve the goal
                                                              and win rewards
                                                            </span>
                                                          </span>
                                                        </h5>
                                                      </div>

                                                      <div
                                                        id={
                                                          "creative2CollapseOne" +
                                                          "Completed" +
                                                          index
                                                        }
                                                        class="collapse"
                                                        aria-labelledby="creative2headingOne"
                                                        data-parent="#creativeAccordion"
                                                      >
                                                        <div class="card-body">
                                                          <p class="mb-3">
                                                            1. {tile?.goal}
                                                          </p>
                                                          <p class="mb-3">
                                                            2. {tile?.desc}
                                                          </p>
                                                          {/* <p class="mb-3">
                                                          3. You win points when
                                                          you participate and
                                                          also when you invite a
                                                          friend and that friend
                                                          participates.
                                                        </p> */}
                                                          <p class="mb-3">
                                                            3. Win upto{" "}
                                                            <b>
                                                              {" "}
                                                              {
                                                                tile?.maxpointstobeearned
                                                              }{" "}
                                                            </b>
                                                            points by
                                                            Participating or
                                                            Inviting motivated
                                                            friends to join this
                                                            Campaign.
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div class="card mb-3">
                                                      <div
                                                        class="card-header"
                                                        id="creative2HeadingTwo"
                                                      >
                                                        <h5 class="mb-0 mt-0">
                                                          <span
                                                            role="menu"
                                                            class="changeIcon collapsed"
                                                            data-toggle="collapse"
                                                            data-target={
                                                              "#" +
                                                              "creative2CollapseTwo" +
                                                              "Completed" +
                                                              index
                                                            }
                                                            aria-expanded="false"
                                                            aria-controls="creative2CollapseTwo"
                                                          >
                                                            <span class="icon">
                                                              <i class="fa fa-plus"></i>
                                                              <i class="fa fa-minus"></i>
                                                            </span>
                                                            <span class="text ml-2">
                                                              {/* What to do ? */}
                                                              How to
                                                              participate?
                                                            </span>
                                                          </span>
                                                        </h5>
                                                      </div>
                                                      <div
                                                        id={
                                                          "creative2CollapseTwo" +
                                                          "Completed" +
                                                          index
                                                        }
                                                        class="collapse"
                                                        aria-labelledby="creative2HeadingTwo"
                                                        data-parent="#creativeAccordion"
                                                      >
                                                        <div class="card-body">
                                                          <p class="mb-3">
                                                            {splitString1(
                                                              // tile?.socialmediamsg
                                                              tile?.whattodo
                                                            )}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div class="card mb-3">
                                                      <div
                                                        class="card-header"
                                                        id="creative2HeadingThree"
                                                      >
                                                        <h5 class="mb-0 mt-0">
                                                          <span
                                                            role="menu"
                                                            class="changeIcon collapsed"
                                                            data-toggle="collapse"
                                                            data-target={
                                                              "#" +
                                                              "creative2CollapseThree" +
                                                              "Completed" +
                                                              index
                                                            }
                                                            aria-expanded="false"
                                                            aria-controls="creative2CollapseThree"
                                                          >
                                                            <span class="icon">
                                                              <i class="fa fa-plus"></i>
                                                              <i class="fa fa-minus"></i>
                                                            </span>
                                                            <span class="text ml-2">
                                                              Guidelines
                                                            </span>
                                                          </span>
                                                        </h5>
                                                      </div>
                                                      <div
                                                        id={
                                                          "creative2CollapseThree" +
                                                          "Completed" +
                                                          index
                                                        }
                                                        class="collapse"
                                                        aria-labelledby="creative2HeadingThree"
                                                        data-parent="#creativeAccordion"
                                                      >
                                                        <div class="card-body">
                                                          <p class="mb-3">
                                                            {splitString1(
                                                              // tile?.socialmediamsg
                                                              tile?.guidelines
                                                            )}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              {/* <hr /> */}
                                              {/* <div class="comm-backg">
                                              <div class="points-rank">
                                                <div class="row">
                                                  <div class="col-6">
                                                    <div class="text-center">
                                                      <span>
                                                        My Points
                                                      </span>
                                                      <h6 class="cam-curr-point">
                                                        {
                                                          tile?.myrankpoints
                                                            ?.rewardpoint
                                                        }
                                                      </h6>
                                                    </div>
                                                  </div>
                                                  <div class="col-6">
                                                    <div class="text-center">
                                                      <span>
                                                        My Rank
                                                      </span>
                                                      <h6 class="cam-curr-point">
                                                        {
                                                          tile?.myrankpoints
                                                            ?.rank
                                                        }
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <hr />
                                              <div>
                                                <div class="row">
                                                  <div class="col-6">
                                                    <div class="text-center">
                                                      <span class="pp-p-text">
                                                        
                                                        Community Size
                                                      </span>
                                                      <h6 class="pp-point">
                                                        {
                                                          tile?.mycommunity
                                                            ?.refcount
                                                        }
                                                      </h6>
                                                    </div>
                                                  </div>
                                                  <div class="col-6">
                                                    <div class="text-center">
                                                      <span class="pp-p-text">
                                                        Community Points
                                                      </span>
                                                      <h6 class="pp-point">
                                                        {
                                                          tile?.mycommunity
                                                            ?.rewardpoint
                                                        }
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <hr /> */}
                                              {/* <div>
                                              <h6>
                                                Impact done by my community.{" "}
                                              </h6>
                                              <div class="row">
                                                <div class="col-md-4 col-6">
                                                  <div class="text-center margin-bottom-10">
                                                    <img
                                                      src="https://www.pngall.com/wp-content/uploads/1/Community-PNG-Image.png"
                                                      class="img-fluid cam-commun"
                                                    />
                                                    <span class="cam-commun-text">
                                                      Lorem ipsum dolor sit amet
                                                      consectetur adipisicing
                                                      elit
                                                    </span>
                                                  </div>
                                                </div>
                                                <div class="col-md-4 col-6">
                                                  <div class="text-center margin-bottom-10">
                                                    <img
                                                      src="https://www.pngitem.com/pimgs/m/162-1625008_save-the-earth-png-transparent-png.png"
                                                      class="img-fluid cam-commun"
                                                    />
                                                    <span class="cam-commun-text">
                                                      Lorem ipsum dolor sit amet
                                                      consectetur adipisicing
                                                      elit
                                                    </span>
                                                  </div>
                                                </div>
                                                <div class="col-md-4 col-6">
                                                  <div class="text-center margin-bottom-10">
                                                    <img
                                                      src="https://cdn-icons-png.flaticon.com/512/1684/1684388.png"
                                                      class="img-fluid cam-commun"
                                                    />
                                                    <span class="cam-commun-text">
                                                      Lorem ipsum dolor sit amet
                                                      consectetur adipisicing
                                                      elit
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div> */}
                                              {/* <hr /> */}
                                              {/* {tile?.campregdetails?.map(
                                              (item) => (
                                                <div>
                                                  <span>
                                                    Participation Status
                                                  </span>{" "}
                                                  {item?.campregistrationstatus ===
                                                  "submitted" ? (
                                                    <a class="p-regstatus">
                                                      {
                                                        item?.campregistrationstatus
                                                      }
                                                    </a>
                                                  ) : item?.campregistrationstatus ===
                                                    "approved" ? (
                                                    <a class="p-apprstatus">
                                                      {
                                                        item?.campregistrationstatus
                                                      }
                                                    </a>
                                                  ) : item?.campregistrationstatus ===
                                                    "rejected" ? (
                                                    <a class="p-rejstatus">
                                                      {
                                                        item?.campregistrationstatus
                                                      }
                                                    </a>
                                                  ) : (
                                                    <a class="p-notregstatus">
                                                      {
                                                        item?.campregistrationstatus
                                                      }
                                                    </a>
                                                  )}
                                                  
                                                </div>
                                              )
                                            )} */}
                                              {/* <hr /> */}
                                              {/* <div class="text-center">
                                              <a class="par-now">

                                                <Button
                                                  onClick={() =>
                                                    navigate(
                                                      `/participate/${tile.campid}/${profileResponse?.phoneno}`
                                                    )
                                                  }
                                                  style={{ color: "white"}}
                                                  className={classes.button}
                                                >
                                                  Participate Now{" "}
                                                  <i
                                                    class="fa fa-arrow-right"
                                                    aria-hidden="true"
                                                  ></i>
                                                </Button>
                                              </a>
                                            </div> */}
                                              <hr />
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                  </OwlCarousel>
                                </div>
                                <div
                                  class="tab-pane fade"
                                  id="animated-underline-paused"
                                  role="tabpanel"
                                  aria-labelledby="animated-underline-paused-tab"
                                >
                                  {/* <div class="campaign-sl owl-carousel owl-theme"> */}
                                  <OwlCarousel
                                    className="owl-theme"
                                    {...optionsTwo}
                                  >
                                    {isArray(myCampaignList) && myCampaignList?.data
                                      ?.filter(
                                        (item, index) =>
                                          item.active === "Paused"
                                      )
                                      .map((tile, index) => (
                                        <div class="item">
                                          <div class="">
                                            <div class="campaigns-wrap campaigns-wrap-new">
                                              <div>
                                                <div>
                                                  <div class="campaign-img">
                                                    <div class="video-wrapper">
                                                      <div
                                                        class="video-container"
                                                        id="video-container"
                                                      >
                                                        <video
                                                          controls
                                                          controlsList="nodownload"
                                                          id="video"
                                                          // preload="metadata"
                                                          preload="auto"
                                                          // poster="//cdn.jsdelivr.net/npm/big-buck-bunny-1080p@0.0.6/poster.jpg"
                                                          poster={tile?.logo}
                                                          autoplay
                                                        >
                                                          <source
                                                            // src="//cdn.jsdelivr.net/npm/big-buck-bunny-1080p@0.0.6/video.mp4"
                                                            src={tile?.video}
                                                            type="video/mp4"
                                                          />
                                                        </video>

                                                        {/* <div class="play-button-wrapper">
                                                      <div
                                                        title="Play video"
                                                        class="play-gif"
                                                        id="circle-play-b"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 80 80"
                                                        >
                                                          <path d="M40 0a40 40 0 1040 40A40 40 0 0040 0zM26 61.56V18.44L64 40z" />
                                                        </svg>
                                                      </div>
                                                    </div> */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <h5
                                                    title={tile?.name}
                                                    class="campaign-title-new"
                                                  >
                                                    {tile?.name}
                                                  </h5>
                                                  <div class="campaign-hashtags">
                                                    {/* {tile.tag.map((tag) => (
                                                    <a class="hash-styles">
                                                      {tag?.tag}
                                                    </a>
                                                  ))} */}
                                                    {tile.tag.map((tag) =>
                                                      SDGCampMapping?.map(
                                                        (item) =>
                                                          item?.cat ===
                                                          tag?.label && (
                                                            <a
                                                              class="hash-styles"
                                                              style={{
                                                                cursor:
                                                                  "initial",
                                                              }}
                                                            >
                                                              {tag?.tag}{" "}
                                                              <a
                                                                class="hash-styles-sdg"
                                                                style={{
                                                                  cursor:
                                                                    "initial",
                                                                  color: "white",
                                                                  marginTop: "2px",
                                                                }}
                                                              >
                                                                SDG {item?.sdg}
                                                              </a>
                                                            </a>
                                                          )
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                                <div
                                                  className={
                                                    classes.campaigntypewrapper
                                                  }
                                                >
                                                  <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    gap={0.5}
                                                  >
                                                    {/* <h5 class="campaign-goal"> */}
                                                    <h5 class="campaign-goal">
                                                      <a
                                                        class="hash-styles-goal"
                                                        style={{
                                                          padding:
                                                            "0px 5px 0px 0px",
                                                        }}
                                                      >
                                                        {/* Need {tile.targetvol}{" "}
                                                        changemakers */}
                                                        <span style={{ fontSize: "16px", fontWeight: 700 }}>Goal</span>: <span class="text-6" style={{ color: "black" }}>{tile.goalquantity}{" "} {tile.unitofgoal}</span>
                                                      </a>

                                                    </h5>

                                                  </Stack>
                                                </div>
                                              </div>
                                              <div class="row">
                                                <div class="col-md-12">
                                                  <div>
                                                    <div class="campaigns-details">

                                                      <div class="p-bar-new">
                                                        <div class="row ">
                                                          <div class="col-6">
                                                            <div>
                                                              <h6>
                                                                <span style={{ color: "#AAAAAA" }}>Pledged: </span>{" "} <span style={{ backgroundColor: "#FFDB58" }}>
                                                                  {Math.round(
                                                                    (tile.committedcount)
                                                                  )}
                                                                </span>
                                                              </h6>
                                                            </div>
                                                          </div>
                                                          <div class="col-6">
                                                            <div
                                                              style={{
                                                                textAlign:
                                                                  "right",
                                                              }}
                                                            >
                                                              <h6>
                                                                <span style={{ color: "#AAAAAA" }}>Achieved: </span>
                                                                {" "}
                                                                <span style={{ backgroundColor: "#A9D18E" }}> {tile.achievedcount}</span>
                                                                {" "}
                                                              </h6>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div className="progress br-30 campaign-progress" style={{ position: "relative" }}>
                                                          <div
                                                            className="progress-bar"
                                                            role="progressbar"
                                                            style={{
                                                              width: `${(tile.committedcount / tile.goalquantity) * 100}%`,
                                                              backgroundColor: "#FFDB58",
                                                              position: "absolute",
                                                              top: 0,
                                                              left: 0,
                                                              zIndex: 1,
                                                              height: "100%"
                                                            }}
                                                            aria-valuenow={Math.round((tile.committedcount / tile.goalquantity) * 100)}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                          ></div>

                                                          <div
                                                            className="progress-bar"
                                                            role="progressbar"
                                                            style={{
                                                              width: `${(tile.achievedcount / tile.goalquantity) * 100}%`,
                                                              backgroundColor: "#A9D18E",
                                                              position: "absolute",
                                                              top: 0,
                                                              left: 0,
                                                              zIndex: 2,
                                                              height: "100%"
                                                            }}
                                                            aria-valuenow={Math.round((tile.achievedcount / tile.goalquantity) * 100)}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                          ></div>
                                                        </div>
                                                      </div>
                                                      <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                                        <div style={{ flex: 1 }}>
                                                          <h6>
                                                            <span style={{ color: "#AAAAAA" }}>Signed Up:</span>{" "}
                                                            <span style={{ backgroundColor: "#DAE8FD" }}>{tile.submittedcount}</span>
                                                          </h6>
                                                        </div>
                                                        <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                          <Chip
                                                            key={tile.campaigntype}
                                                            fontSize="small"
                                                            size="small"
                                                            label={tile.campaigntype}
                                                          />
                                                          <img
                                                            src={CampaignLeaderBoardIcon}
                                                            style={{ width: 25, height: 25, cursor: "pointer", }}
                                                            onClick={() =>
                                                              navigate(
                                                                `/camp/leaderboard/${tile.campid}`
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                      <div class="row">

                                                        <div class="col-md-4 col-6 customHalfWidth">
                                                          <div class="cam-cols">
                                                            <div class="timer-here">
                                                              <div class="text-center">
                                                                <span class="campaign-time-title">
                                                                  Earn upto
                                                                </span>
                                                              </div>
                                                              <div>
                                                                <ul id="countdown">
                                                                  <li id="hours">
                                                                    <div class="numberhome"></div>
                                                                    <div class="label"></div>
                                                                  </li>
                                                                  <li id="minutes">
                                                                    <div
                                                                      class="numberhome"
                                                                      style={{
                                                                        padding:
                                                                          "0px 0px 0px 6px",
                                                                      }}
                                                                    >
                                                                      {
                                                                        tile.maxpointstobeearned
                                                                      }
                                                                    </div>
                                                                    <div
                                                                      class="label"
                                                                      style={{
                                                                        padding:
                                                                          "0px 0px 0px 12px",
                                                                      }}
                                                                    >
                                                                      Points
                                                                    </div>
                                                                  </li>
                                                                  <li id="seconds">
                                                                    <div class="numberhome"></div>
                                                                    <div class="label"></div>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div class="col-md-4 col-6 customHalfWidth">
                                                          <div class="cam-cols">
                                                            <div class="timer-here">
                                                              <div class="text-center">
                                                                <span class="campaign-time-title">
                                                                  Time Left
                                                                </span>
                                                              </div>
                                                              <div>
                                                                <ul id="countdown">
                                                                  <li id="hours">
                                                                    <div class="number">
                                                                      {
                                                                        tile.dayrem
                                                                      }
                                                                    </div>
                                                                    <div class="label">
                                                                      Days
                                                                    </div>
                                                                  </li>
                                                                  <li id="minutes">
                                                                    <div class="number">
                                                                      {
                                                                        tile.hourrem
                                                                      }
                                                                    </div>
                                                                    <div class="label">
                                                                      Hours
                                                                    </div>
                                                                  </li>
                                                                  <li id="seconds">
                                                                    <div class="number">
                                                                      {
                                                                        tile.minrem
                                                                      }
                                                                    </div>
                                                                    <div class="label">
                                                                      Mins
                                                                    </div>
                                                                  </li>
                                                                </ul>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="col-md-4 customFullWidth">
                                                          {/* <div class="par-btn-here">
                                                          <div class="text-center">
                                                            <a class="par-now-new">
                                                            
                                                              <Button
                                                                onClick={() =>
                                                                  navigate(
                                                                    `/participate/${tile.campid}/${profileResponse?.phoneno}`
                                                                  )
                                                                }
                                                                style={{ color: "white"}}
                                                                className={
                                                                  classes.button
                                                                }
                                                              >
                                                                Participate Now{" "}
                                                                <i
                                                                  class="fa fa-arrow-right"
                                                                  aria-hidden="true"
                                                                ></i>
                                                              </Button>
                                                            
                                                            </a>
                                                          </div>
                                                        </div> */}
                                                        </div>
                                                      </div>

                                                      {/* <div class="p-bar-new">
                                                        <div class="row ">
                                                          <div class="col-6">
                                                            <div>
                                                              <h6>
                                                                {Math.round(
                                                                  (tile.submittedcount /
                                                                    tile.targetvol) *
                                                                  100
                                                                )}
                                                                % submitted
                                                              </h6>
                                                            </div>
                                                          </div>
                                                          <div class="col-6">
                                                            <div
                                                              style={{
                                                                textAlign:
                                                                  "right",
                                                              }}
                                                            >
                                                              <h6>
                                                                {
                                                                  tile.submittedcount
                                                                }
                                                                /
                                                                {tile.targetvol}
                                                              </h6>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="progress br-30 campaign-progress">
                                                          <div
                                                            class="progress-bar bg-primary"
                                                            role="progressbar"
                                                            style={{
                                                              // width: "20%",
                                                              width:
                                                                Math.round(
                                                                  (tile.submittedcount /
                                                                    tile.targetvol) *
                                                                  100
                                                                ) + "%",
                                                            }}
                                                            // aria-valuenow="25"
                                                            aria-valuenow={Math.round(
                                                              (tile.submittedcount /
                                                                tile.targetvol) *
                                                              100
                                                            )}
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                          ></div>
                                                        </div>
                                                        <span class="progress-txt">
                                                          {tile.submittedcount}{" "}
                                                          changemaker(s)
                                                          submitted out of{" "}
                                                          {tile.targetvol}
                                                        </span>
                                                      </div> */}

                                                      {/* <div class="p-bar-new">
                                                        <div class="row ">
                                                          <div class="col-6">
                                                            <div>
                                                              <h6>
                                                                {Math.round(
                                                                  (tile.adoptioncount /
                                                                    tile.targetvol) *
                                                                  100
                                                                )}
                                                                % approved
                                                              </h6>
                                                            </div>
                                                          </div>
                                                          <div class="col-6">
                                                            <div
                                                              style={{
                                                                textAlign:
                                                                  "right",
                                                              }}
                                                            >
                                                              <h6>
                                                                {
                                                                  tile.adoptioncount
                                                                }
                                                                /
                                                                {tile.targetvol}
                                                              </h6>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div class="progress br-30 campaign-progress">
                                                          <div
                                                            class="progress-bar bg-approved"
                                                            role="progressbar"
                                                            style={{
                                                              width:
                                                                Math.round(
                                                                  (tile.adoptioncount /
                                                                    tile.targetvol) *
                                                                  100
                                                                ) + "%",
                                                            }}
                                                            aria-valuenow="25"
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                          ></div>
                                                        </div>
                                                        <span class="progress-txt">
                                                          {tile.adoptioncount}{" "}
                                                          changemaker(s)
                                                          approved out of{" "}
                                                          {tile.targetvol}
                                                        </span>
                                                      </div> */}
                                                      <div class="mr-15 text-center">
                                                        <a class="campaign-signin">
                                                          Join Now{" "}
                                                          <i
                                                            class="fa fa-arrow-right"
                                                            aria-hidden="true"
                                                          ></i>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="logged-cam-detai">
                                              <div class="aft-log-acc">
                                                <div class="widget-content widget-content-area creative2-accordion-content">
                                                  <div id="creativeAccordion">
                                                    <div class="card mb-3">
                                                      <div
                                                        class="card-header"
                                                        id="creative2headingOne"
                                                      >
                                                        <h5 class="mb-0 mt-0">
                                                          <span
                                                            role="menu"
                                                            class="changeIcon collapsed"
                                                            data-toggle="collapse"
                                                            data-target={
                                                              "#" +
                                                              "creative2CollapseOne" +
                                                              "Paused" +
                                                              index
                                                            }
                                                            aria-expanded="true"
                                                            aria-controls="creative2CollapseOne"
                                                          >
                                                            <span class="icon">
                                                              <i class="fa fa-plus"></i>
                                                              <i class="fa fa-minus"></i>
                                                            </span>
                                                            <span class="text ml-2">
                                                              Achieve the goal
                                                              and win rewards
                                                            </span>
                                                          </span>
                                                        </h5>
                                                      </div>

                                                      <div
                                                        id={
                                                          "creative2CollapseOne" +
                                                          "Paused" +
                                                          index
                                                        }
                                                        class="collapse"
                                                        aria-labelledby="creative2headingOne"
                                                        data-parent="#creativeAccordion"
                                                      >
                                                        <div class="card-body">
                                                          <p class="mb-3">
                                                            1. {tile?.goal}
                                                          </p>
                                                          <p class="mb-3">
                                                            2. {tile?.desc}
                                                          </p>
                                                          {/* <p class="mb-3">
                                                          3. You win points when
                                                          you participate and
                                                          also when you invite a
                                                          friend and that friend
                                                          participates.
                                                        </p> */}
                                                          <p class="mb-3">
                                                            3. Win upto{" "}
                                                            <b>
                                                              {" "}
                                                              {
                                                                tile?.maxpointstobeearned
                                                              }{" "}
                                                            </b>
                                                            points by
                                                            Participating or
                                                            Inviting motivated
                                                            friends to join this
                                                            Campaign.
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div class="card mb-3">
                                                      <div
                                                        class="card-header"
                                                        id="creative2HeadingTwo"
                                                      >
                                                        <h5 class="mb-0 mt-0">
                                                          <span
                                                            role="menu"
                                                            class="changeIcon collapsed"
                                                            data-toggle="collapse"
                                                            data-target={
                                                              "#" +
                                                              "creative2CollapseTwo" +
                                                              "Paused" +
                                                              index
                                                            }
                                                            aria-expanded="false"
                                                            aria-controls="creative2CollapseTwo"
                                                          >
                                                            <span class="icon">
                                                              <i class="fa fa-plus"></i>
                                                              <i class="fa fa-minus"></i>
                                                            </span>
                                                            <span class="text ml-2">
                                                              {/* What to do ? */}
                                                              How to
                                                              participate?
                                                            </span>
                                                          </span>
                                                        </h5>
                                                      </div>
                                                      <div
                                                        id={
                                                          "creative2CollapseTwo" +
                                                          "Paused" +
                                                          index
                                                        }
                                                        class="collapse"
                                                        aria-labelledby="creative2HeadingTwo"
                                                        data-parent="#creativeAccordion"
                                                      >
                                                        <div class="card-body">
                                                          <p class="mb-3">
                                                            {splitString1(
                                                              // tile?.socialmediamsg
                                                              tile?.whattodo
                                                            )}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div class="card mb-3">
                                                      <div
                                                        class="card-header"
                                                        id="creative2HeadingThree"
                                                      >
                                                        <h5 class="mb-0 mt-0">
                                                          <span
                                                            role="menu"
                                                            class="changeIcon collapsed"
                                                            data-toggle="collapse"
                                                            data-target={
                                                              "#" +
                                                              "creative2CollapseThree" +
                                                              "Paused" +
                                                              index
                                                            }
                                                            aria-expanded="false"
                                                            aria-controls="creative2CollapseThree"
                                                          >
                                                            <span class="icon">
                                                              <i class="fa fa-plus"></i>
                                                              <i class="fa fa-minus"></i>
                                                            </span>
                                                            <span class="text ml-2">
                                                              Guidelines
                                                            </span>
                                                          </span>
                                                        </h5>
                                                      </div>
                                                      <div
                                                        id={
                                                          "creative2CollapseThree" +
                                                          "Paused" +
                                                          index
                                                        }
                                                        class="collapse"
                                                        aria-labelledby="creative2HeadingThree"
                                                        data-parent="#creativeAccordion"
                                                      >
                                                        <div class="card-body">
                                                          <p class="mb-3">
                                                            {splitString1(
                                                              // tile?.socialmediamsg
                                                              tile?.guidelines
                                                            )}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              {/* <hr /> */}
                                              {/* <div class="comm-backg">
                                              <div class="points-rank">
                                                <div class="row">
                                                  <div class="col-6">
                                                    <div class="text-center">
                                                      <span>
                                                        My Points
                                                      </span>
                                                      <h6 class="cam-curr-point">
                                                        {
                                                          tile?.myrankpoints
                                                            ?.rewardpoint
                                                        }
                                                      </h6>
                                                    </div>
                                                  </div>
                                                  <div class="col-6">
                                                    <div class="text-center">
                                                      <span>
                                                        My Rank
                                                      </span>
                                                      <h6 class="cam-curr-point">
                                                        {
                                                          tile?.myrankpoints
                                                            ?.rank
                                                        }
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <hr />
                                              <div>
                                                <div class="row">
                                                  <div class="col-6">
                                                    <div class="text-center">
                                                      <span class="pp-p-text">
                                                        Community Size
                                                      </span>
                                                      <h6 class="pp-point">
                                                        {
                                                          tile?.mycommunity
                                                            ?.refcount
                                                        }
                                                      </h6>
                                                    </div>
                                                  </div>
                                                  <div class="col-6">
                                                    <div class="text-center">
                                                      <span class="pp-p-text">
                                                        Community Points
                                                      </span>
                                                      <h6 class="pp-point">
                                                        {
                                                          tile?.mycommunity
                                                            ?.rewardpoint
                                                        }
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div> */}
                                              {/* <hr /> */}
                                              {/* <div>
                                              <h6>
                                                Impact done by my community.{" "}
                                              </h6>
                                              <div class="row">
                                                <div class="col-md-4 col-6">
                                                  <div class="text-center margin-bottom-10">
                                                    <img
                                                      src="https://www.pngall.com/wp-content/uploads/1/Community-PNG-Image.png"
                                                      class="img-fluid cam-commun"
                                                    />
                                                    <span class="cam-commun-text">
                                                      Lorem ipsum dolor sit amet
                                                      consectetur adipisicing
                                                      elit
                                                    </span>
                                                  </div>
                                                </div>
                                                <div class="col-md-4 col-6">
                                                  <div class="text-center margin-bottom-10">
                                                    <img
                                                      src="https://www.pngitem.com/pimgs/m/162-1625008_save-the-earth-png-transparent-png.png"
                                                      class="img-fluid cam-commun"
                                                    />
                                                    <span class="cam-commun-text">
                                                      Lorem ipsum dolor sit amet
                                                      consectetur adipisicing
                                                      elit
                                                    </span>
                                                  </div>
                                                </div>
                                                <div class="col-md-4 col-6">
                                                  <div class="text-center margin-bottom-10">
                                                    <img
                                                      src="https://cdn-icons-png.flaticon.com/512/1684/1684388.png"
                                                      class="img-fluid cam-commun"
                                                    />
                                                    <span class="cam-commun-text">
                                                      Lorem ipsum dolor sit amet
                                                      consectetur adipisicing
                                                      elit
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div> */}
                                              {/* <hr /> */}
                                              {/* {tile?.campregdetails?.map(
                                              (item) => (
                                                <div>
                                                  <span>
                                                    Participation Status
                                                  </span>{" "}
                                                  {item?.campregistrationstatus ===
                                                  "submitted" ? (
                                                    <a class="p-regstatus">
                                                      {
                                                        item?.campregistrationstatus
                                                      }
                                                    </a>
                                                  ) : item?.campregistrationstatus ===
                                                    "approved" ? (
                                                    <a class="p-apprstatus">
                                                      {
                                                        item?.campregistrationstatus
                                                      }
                                                    </a>
                                                  ) : item?.campregistrationstatus ===
                                                    "rejected" ? (
                                                    <a class="p-rejstatus">
                                                      {
                                                        item?.campregistrationstatus
                                                      }
                                                    </a>
                                                  ) : (
                                                    <a class="p-notregstatus">
                                                      {
                                                        item?.campregistrationstatus
                                                      }
                                                    </a>
                                                  )}
                                                  
                                                </div>
                                              )
                                            )} */}
                                              {/* <hr /> */}
                                              {/* <div class="text-center">
                                              <a class="par-now">
                                                
                                                
                                                <Button
                                                  onClick={() =>
                                                    navigate(
                                                      `/participate/${tile.campid}/${profileResponse?.phoneno}`
                                                    )
                                                  }
                                                  style={{ color: "white"}}
                                                  className={classes.button}
                                                >
                                                  Participate Now{" "}
                                                  <i
                                                    class="fa fa-arrow-right"
                                                    aria-hidden="true"
                                                  ></i>
                                                </Button>
                                                
                                              </a>
                                            </div> */}
                                              {/* <hr /> */}
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                  </OwlCarousel>
                                </div>

                                {/* This is for RECOMMENDED */}

                                <div
                                  class="tab-pane fade"
                                  id="animated-underline-recommended"
                                  role="tabpanel"
                                  aria-labelledby="animated-underline-recommended-tab"
                                >
                                  {/* <div class="campaign-sl owl-carousel owl-theme"> */}
                                  {!isArray(myRecommendedCampList?.data) &&
                                    myRecommendedCampList?.data?.length === 0 ? (
                                    <span>
                                      <i>
                                        <b>
                                          Campaigns that matches your skills and
                                          interests will be shown here
                                        </b>
                                      </i>
                                    </span>
                                  ) : (
                                    <span>
                                      <i>
                                        <b>Campaigns you might be interested</b>
                                      </i>
                                    </span>
                                  )}
                                  <OwlCarousel
                                    className="owl-theme"
                                    {...optionsTwo}
                                  >
                                    {isArray(myRecommendedCampList?.data) &&
                                      myRecommendedCampList &&
                                      myRecommendedCampList?.data?.map(
                                        (tile, index) => (
                                          // tile?.map(tile,index) (

                                          <div class="item">
                                            <div class="">
                                              <div class="campaigns-wrap campaigns-wrap-new">
                                                <div>
                                                  <div>
                                                    <div class="campaign-img">
                                                      {/* {JSON.stringify(myRecommendedCampList)} */}

                                                      <div class="video-wrapper">
                                                        <div
                                                          class="video-container"
                                                          id="video-container"
                                                        >
                                                          <video
                                                            controls
                                                            controlsList="nodownload"
                                                            id="video"
                                                            // preload="metadata"
                                                            preload="auto"
                                                            poster={
                                                              tile[0]?.logo
                                                            }
                                                            autoplay
                                                          >
                                                            <source
                                                              // src="//cdn.jsdelivr.net/npm/big-buck-bunny-1080p@0.0.6/video.mp4"
                                                              src={
                                                                tile?.[0]?.video
                                                              }
                                                              type="video/mp4"
                                                            />
                                                          </video>

                                                          {/* <div class="play-button-wrapper">
                                                      <div
                                                        title="Play video"
                                                        class="play-gif"
                                                        id="circle-play-b"
                                                      >
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 80 80"
                                                        >
                                                          <path d="M40 0a40 40 0 1040 40A40 40 0 0040 0zM26 61.56V18.44L64 40z" />
                                                        </svg>
                                                      </div>
                                                    </div> */}
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <Typography
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      <span
                                                        className="blink"
                                                        style={{
                                                          color: "green",
                                                          fontSize: 12,
                                                        }}
                                                      >
                                                        Matches your{" "}
                                                        {tile?.[0]?.matchtype}
                                                      </span>
                                                    </Typography>
                                                    {/* <div>
                                                Matches your {" "} {tile[0]?.matchtype}
                                                </div> */}
                                                    <h5
                                                      title={tile?.[0]?.name}
                                                      class="campaign-title-new"
                                                    >
                                                      {tile?.[0]?.name}
                                                    </h5>
                                                    <div class="campaign-hashtags">
                                                      {/* {tile[0].tag.map((tag) => (
                                                      <a class="hash-styles">
                                                        {tag?.tag}
                                                      </a>
                                                    ))} */}
                                                      {/* {JSON.stringify(SDGCampMapping?.map((item) => item?.cat))}
                                                    {JSON.stringify(tile[0].tag?.map((tag) => tag))} */}
                                                      {/* {tile?.[0].tag.map((tag) =>
                                                      SDGCampMapping?.map(
                                                        (item) =>
                                                          item?.cat ===
                                                            tag?.label && (
                                                            <a
                                                              class="hash-styles"
                                                              style={{
                                                                cursor:
                                                                  "initial",
                                                              }}
                                                            >
                                                              {tag?.tag}{" "}
                                                              <a
                                                                class="hash-styles-sdg"
                                                                style={{
                                                                  cursor:
                                                                    "initial",
                                                                }}
                                                              >
                                                                SDG {item?.sdg}
                                                              </a>
                                                            </a>
                                                          )
                                                      )
                                                    )} */}
                                                      {tile?.[0]?.tag.map(
                                                        (tag) =>
                                                          SDGCampMapping?.map(
                                                            (item) =>
                                                              item?.cat ===
                                                              tag?.label && (
                                                                <a
                                                                  class="hash-styles"
                                                                  style={{
                                                                    cursor:
                                                                      "initial",
                                                                  }}
                                                                >
                                                                  {tag?.tag}{" "}
                                                                  <a
                                                                    class="hash-styles-sdg"
                                                                    style={{
                                                                      cursor:
                                                                        "initial",
                                                                      color: "white",
                                                                      marginTop: "2px",
                                                                    }}
                                                                  >
                                                                    SDG{" "}
                                                                    {item?.sdg}
                                                                  </a>
                                                                </a>
                                                              )
                                                          )
                                                      )}
                                                    </div>
                                                  </div>
                                                  <div
                                                    className={
                                                      classes.campaigntypewrapper
                                                    }
                                                  >
                                                    <Stack
                                                      direction="row"
                                                      alignItems="center"
                                                      justifyContent="space-between"
                                                      gap={0.5}
                                                    >
                                                      {/* <h5 class="campaign-goal"> */}
                                                      <h5 class="campaign-goal">
                                                        <a
                                                          class="hash-styles-goal"
                                                          style={{
                                                            padding:
                                                              "0px 5px 0px 0px",
                                                          }}
                                                        >
                                                          {/* Need{" "}
                                                          {tile[0].targetvol}{" "}
                                                          changemakers */}
                                                          <span style={{ fontSize: "16px", fontWeight: 700 }}>Goal</span>: <span class="text-6" style={{ color: "black" }}>{tile.goalquantity}{" "} {tile.unitofgoal}</span>
                                                        </a>

                                                      </h5>

                                                    </Stack>
                                                  </div>
                                                </div>
                                                <div class="row">
                                                  <div class="col-md-12">
                                                    <div>
                                                      <div class="campaigns-details">

                                                        <div class="p-bar-new">
                                                          <div class="row ">
                                                            <div class="col-6">
                                                              <div>
                                                                <h6>
                                                                  <span style={{ color: "#AAAAAA" }}>Pledged: </span>{" "} <span style={{ backgroundColor: "#FFDB58" }}>
                                                                    {Math.round(
                                                                      (tile.committedcount)
                                                                    )}
                                                                  </span>
                                                                </h6>
                                                              </div>
                                                            </div>
                                                            <div class="col-6">
                                                              <div
                                                                style={{
                                                                  textAlign:
                                                                    "right",
                                                                }}
                                                              >
                                                                <h6>
                                                                  <span style={{ color: "#AAAAAA" }}>Achieved: </span>
                                                                  {" "}
                                                                  <span style={{ backgroundColor: "#A9D18E" }}> {tile.achievedcount}</span>
                                                                  {" "}
                                                                </h6>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="progress br-30 campaign-progress" style={{ position: "relative" }}>
                                                            <div
                                                              className="progress-bar"
                                                              role="progressbar"
                                                              style={{
                                                                width: `${(tile.committedcount / tile.goalquantity) * 100}%`,
                                                                backgroundColor: "#FFDB58",
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                zIndex: 1,
                                                                height: "100%"
                                                              }}
                                                              aria-valuenow={Math.round((tile.committedcount / tile.goalquantity) * 100)}
                                                              aria-valuemin="0"
                                                              aria-valuemax="100"
                                                            ></div>

                                                            <div
                                                              className="progress-bar"
                                                              role="progressbar"
                                                              style={{
                                                                width: `${(tile.achievedcount / tile.goalquantity) * 100}%`,
                                                                backgroundColor: "#A9D18E",
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                zIndex: 2,
                                                                height: "100%"
                                                              }}
                                                              aria-valuenow={Math.round((tile.achievedcount / tile.goalquantity) * 100)}
                                                              aria-valuemin="0"
                                                              aria-valuemax="100"
                                                            ></div>
                                                          </div>
                                                        </div>
                                                        <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                                                          <div style={{ flex: 1 }}>
                                                            <h6>
                                                              <span style={{ color: "#AAAAAA" }}>Signed Up:</span>{" "}
                                                              <span style={{ backgroundColor: "#DAE8FD" }}>{tile.submittedcount}</span>
                                                            </h6>
                                                          </div>
                                                          <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                            <Chip
                                                              key={tile.campaigntype}
                                                              fontSize="small"
                                                              size="small"
                                                              label={tile.campaigntype}
                                                            />
                                                            <img
                                                              src={CampaignLeaderBoardIcon}
                                                              style={{ width: 25, height: 25, cursor: "pointer", }}
                                                              onClick={() =>
                                                                navigate(
                                                                  `/camp/leaderboard/${tile.campid}`
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </div>

                                                        <div class="row">


                                                          <div class="col-md-4 col-6 customHalfWidth">
                                                            <div class="cam-cols">
                                                              <div class="timer-here">
                                                                <div class="text-center">
                                                                  <span class="campaign-time-title">
                                                                    Earn upto
                                                                  </span>
                                                                </div>
                                                                <div>
                                                                  <ul id="countdown">
                                                                    <li id="hours">
                                                                      <div class="numberhome"></div>
                                                                      <div class="label"></div>
                                                                    </li>
                                                                    <li id="minutes">
                                                                      <div
                                                                        class="numberhome"
                                                                        style={{
                                                                          padding:
                                                                            "0px 0px 0px 6px",
                                                                        }}
                                                                      >
                                                                        {
                                                                          tile[0]
                                                                            ?.maxpointstobeearned
                                                                        }
                                                                      </div>
                                                                      <div
                                                                        class="label"
                                                                        style={{
                                                                          padding:
                                                                            "0px 0px 0px 12px",
                                                                        }}
                                                                      >
                                                                        Points
                                                                      </div>
                                                                    </li>
                                                                    <li id="seconds">
                                                                      <div class="numberhome"></div>
                                                                      <div class="label"></div>
                                                                    </li>
                                                                  </ul>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div class="col-md-4 col-6 customHalfWidth">
                                                            <div class="cam-cols">
                                                              <div class="timer-here">
                                                                <div class="text-center">
                                                                  <span class="campaign-time-title">
                                                                    Time Left
                                                                  </span>
                                                                </div>
                                                                <div>
                                                                  <ul id="countdown">
                                                                    <li id="hours">
                                                                      <div class="number">
                                                                        {
                                                                          tile[0]
                                                                            .dayrem
                                                                        }
                                                                      </div>
                                                                      <div class="label">
                                                                        Days
                                                                      </div>
                                                                    </li>
                                                                    <li id="minutes">
                                                                      <div class="number">
                                                                        {
                                                                          tile[0]
                                                                            .hourrem
                                                                        }
                                                                      </div>
                                                                      <div class="label">
                                                                        Hours
                                                                      </div>
                                                                    </li>
                                                                    <li id="seconds">
                                                                      <div class="number">
                                                                        {
                                                                          tile[0]
                                                                            .minrem
                                                                        }
                                                                      </div>
                                                                      <div class="label">
                                                                        Mins
                                                                      </div>
                                                                    </li>
                                                                  </ul>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div class="col-md-4 customFullWidth">
                                                            <div class="par-btn-here">
                                                              <div class="text-center">
                                                                <a class="par-now-new">
                                                                  {isDesktop ? (
                                                                    <Button
                                                                      onClick={() =>
                                                                        navigate(
                                                                          `/participate/${tile[0].campid}/${profileResponse?.phoneno}`
                                                                        )
                                                                      }
                                                                      style={{
                                                                        color:
                                                                          "white",
                                                                        // padding:"0px 0px 0px 0px",
                                                                        padding:
                                                                          "6px",
                                                                        fontSize:
                                                                          "12px",
                                                                      }}
                                                                      className={
                                                                        classes.button
                                                                      }
                                                                    >
                                                                      Participate
                                                                      Now{" "}
                                                                      <i
                                                                        class="fa fa-arrow-right"
                                                                        aria-hidden="true"
                                                                      ></i>
                                                                    </Button>
                                                                  ) : (
                                                                    <Button
                                                                      onClick={() =>
                                                                        navigate(
                                                                          `/participate/${tile[0].campid}/${profileResponse?.phoneno}`
                                                                        )
                                                                      }
                                                                      style={{
                                                                        color:
                                                                          "white",
                                                                        fontSize: 10,
                                                                      }}
                                                                      className={
                                                                        classes.button
                                                                      }
                                                                    >
                                                                      Participate
                                                                      Now{" "}
                                                                      <i
                                                                        class="fa fa-arrow-right"
                                                                        aria-hidden="true"
                                                                      ></i>
                                                                    </Button>
                                                                  )}
                                                                </a>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        {/* <div class="p-bar-new">
                                                          <div class="row ">
                                                            <div class="col-6">
                                                              <div>
                                                                <h6>
                                                                  {Math.round(
                                                                    (tile[0]
                                                                      .submittedcount /
                                                                      tile[0]
                                                                        .targetvol) *
                                                                    100
                                                                  )}
                                                                  % submitted
                                                                </h6>
                                                              </div>
                                                            </div>
                                                            <div class="col-6">
                                                              <div
                                                                style={{
                                                                  textAlign:
                                                                    "right",
                                                                }}
                                                              >
                                                                <h6>
                                                                  {
                                                                    tile[0]
                                                                      .submittedcount
                                                                  }
                                                                  /
                                                                  {
                                                                    tile[0]
                                                                      .targetvol
                                                                  }
                                                                </h6>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div class="progress br-30 campaign-progress">
                                                            <div
                                                              class="progress-bar bg-primary"
                                                              role="progressbar"
                                                              style={{
                                                                // width: "20%",
                                                                width:
                                                                  Math.round(
                                                                    (tile[0]
                                                                      .submittedcount /
                                                                      tile[0]
                                                                        .targetvol) *
                                                                    100
                                                                  ) + "%",
                                                              }}
                                                              // aria-valuenow="25"
                                                              aria-valuenow={Math.round(
                                                                (tile[0]
                                                                  .submittedcount /
                                                                  tile[0]
                                                                    .targetvol) *
                                                                100
                                                              )}
                                                              aria-valuemin="0"
                                                              aria-valuemax="100"
                                                            ></div>
                                                          </div>
                                                          <span class="progress-txt">
                                                            {
                                                              tile[0]
                                                                .submittedcount
                                                            }{" "}
                                                            changemaker(s)
                                                            submitted out of{" "}
                                                            {tile[0].targetvol}
                                                          </span>
                                                        </div> */}

                                                        {/* <div class="p-bar-new">
                                                          <div class="row ">
                                                            <div class="col-6">
                                                              <div>
                                                                <h6>
                                                                  {Math.round(
                                                                    (tile[0]
                                                                      .adoptioncount /
                                                                      tile[0]
                                                                        .targetvol) *
                                                                    100
                                                                  )}
                                                                  % approved
                                                                </h6>
                                                              </div>
                                                            </div>
                                                            <div class="col-6">
                                                              <div
                                                                style={{
                                                                  textAlign:
                                                                    "right",
                                                                }}
                                                              >
                                                                <h6>
                                                                  {
                                                                    tile[0]
                                                                      .adoptioncount
                                                                  }
                                                                  /
                                                                  {
                                                                    tile[0]
                                                                      .targetvol
                                                                  }
                                                                </h6>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div class="progress br-30 campaign-progress">
                                                            <div
                                                              class="progress-bar bg-approved"
                                                              role="progressbar"
                                                              style={{
                                                                width:
                                                                  Math.round(
                                                                    (tile[0]
                                                                      .adoptioncount /
                                                                      tile[0]
                                                                        .targetvol) *
                                                                    100
                                                                  ) + "%",
                                                              }}
                                                              aria-valuenow="25"
                                                              aria-valuemin="0"
                                                              aria-valuemax="100"
                                                            ></div>
                                                          </div>
                                                          <span class="progress-txt">
                                                            {
                                                              tile[0]
                                                                .adoptioncount
                                                            }{" "}
                                                            changemaker(s)
                                                            approved out of{" "}
                                                            {tile[0].targetvol}
                                                          </span>
                                                        </div> */}

                                                        <div class="mr-15 text-center">
                                                          <a class="campaign-signin">
                                                            Join Now{" "}
                                                            <i
                                                              class="fa fa-arrow-right"
                                                              aria-hidden="true"
                                                            ></i>
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="logged-cam-detai">
                                                <div class="aft-log-acc">
                                                  <div class="widget-content widget-content-area creative2-accordion-content">
                                                    <div id="creativeAccordion">
                                                      <div class="card mb-3">
                                                        <div
                                                          class="card-header"
                                                          id="creative2headingOne"
                                                        >
                                                          <h5 class="mb-0 mt-0">
                                                            <span
                                                              role="menu"
                                                              class="changeIcon collapsed"
                                                              data-toggle="collapse"
                                                              // data-target="#creative2CollapseOne"
                                                              data-target={
                                                                "#" +
                                                                "creative2CollapseOne" +
                                                                "Recommended" +
                                                                index
                                                              }
                                                              aria-expanded="true"
                                                              aria-controls="creative2CollapseOne"
                                                            >
                                                              <span class="icon">
                                                                <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                              </span>
                                                              <span class="text ml-2">
                                                                Achieve the goal
                                                                and win rewards
                                                              </span>
                                                            </span>
                                                          </h5>
                                                        </div>

                                                        <div
                                                          id={
                                                            "creative2CollapseOne" +
                                                            "Recommended" +
                                                            index
                                                          }
                                                          class="collapse"
                                                          aria-labelledby="creative2headingOne"
                                                          data-parent="#creativeAccordion"
                                                        >
                                                          <div class="card-body">
                                                            <p class="mb-3">
                                                              1. {tile[0]?.goal}
                                                            </p>
                                                            <p class="mb-3">
                                                              2. {tile[0]?.desc}
                                                            </p>
                                                            {/* <p class="mb-3">
                                                          3. You win points when
                                                          you participate and
                                                          also when you invite a
                                                          friend and that friend
                                                          participates.
                                                        </p> */}
                                                            <p class="mb-3">
                                                              3. Win upto{" "}
                                                              <b>
                                                                {" "}
                                                                {
                                                                  tile[0]
                                                                    ?.maxpointstobeearned
                                                                }{" "}
                                                              </b>
                                                              points by
                                                              Participating or
                                                              Inviting motivated
                                                              friends to join
                                                              this Campaign.
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="card mb-3">
                                                        <div
                                                          class="card-header"
                                                          id="creative2HeadingTwo"
                                                        >
                                                          <h5 class="mb-0 mt-0">
                                                            <span
                                                              role="menu"
                                                              class="changeIcon collapsed"
                                                              data-toggle="collapse"
                                                              data-target={
                                                                "#" +
                                                                "creative2CollapseTwo" +
                                                                "Recommended" +
                                                                index
                                                              }
                                                              aria-expanded="false"
                                                              aria-controls="creative2CollapseTwo"
                                                            >
                                                              <span class="icon">
                                                                <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                              </span>
                                                              <span class="text ml-2">
                                                                {/* What to do ? */}
                                                                How to
                                                                participate?
                                                              </span>
                                                            </span>
                                                          </h5>
                                                        </div>
                                                        <div
                                                          id={
                                                            "creative2CollapseTwo" +
                                                            "Recommended" +
                                                            index
                                                          }
                                                          class="collapse"
                                                          aria-labelledby="creative2HeadingTwo"
                                                          data-parent="#creativeAccordion"
                                                        >
                                                          <div class="card-body">
                                                            <p class="mb-3">
                                                              {splitString1(
                                                                // tile?.socialmediamsg
                                                                tile[0]
                                                                  ?.whattodo
                                                              )}
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div class="card mb-3">
                                                        <div
                                                          class="card-header"
                                                          id="creative2HeadingThree"
                                                        >
                                                          <h5 class="mb-0 mt-0">
                                                            <span
                                                              role="menu"
                                                              class="changeIcon collapsed"
                                                              data-toggle="collapse"
                                                              data-target={
                                                                "#" +
                                                                "creative2CollapseThree" +
                                                                "Recommended" +
                                                                index
                                                              }
                                                              aria-expanded="false"
                                                              aria-controls="creative2CollapseThree"
                                                            >
                                                              <span class="icon">
                                                                <i class="fa fa-plus"></i>
                                                                <i class="fa fa-minus"></i>
                                                              </span>
                                                              <span class="text ml-2">
                                                                Guidelines
                                                              </span>
                                                            </span>
                                                          </h5>
                                                        </div>
                                                        <div
                                                          id={
                                                            "creative2CollapseThree" +
                                                            "Recommended" +
                                                            index
                                                          }
                                                          class="collapse"
                                                          aria-labelledby="creative2HeadingThree"
                                                          data-parent="#creativeAccordion"
                                                        >
                                                          <div class="card-body">
                                                            <p class="mb-3">
                                                              {splitString1(
                                                                // tile?.socialmediamsg
                                                                tile[0]
                                                                  ?.guidelines
                                                              )}
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                {/* <hr /> */}
                                                {/* <div class="comm-backg">
                                                <div class="points-rank">
                                                  <div class="row">
                                                    <div class="col-6">
                                                      <div class="text-center">
                                                        <span>My Points</span>
                                                        <h6 class="cam-curr-point">
                                                          
                                                          {
                                                            tile[0]
                                                              ?.myrankpoints?.[0]
                                                              ?.rewardpoint
                                                          }
                                                        </h6>
                                                      </div>
                                                    </div>
                                                    <div class="col-6">
                                                      <div class="text-center">
                                                        <span>My Rank</span>
                                                        <h6 class="cam-curr-point">
                                                          {
                                                            tile[0]
                                                              ?.myrankpoints?.[0]
                                                              ?.rank
                                                          }
                                                        </h6>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <hr />
                                                <div>
                                                  <div class="row">
                                                    <div class="col-6">
                                                      <div class="text-center">
                                                        <span class="pp-p-text">
                                                          Community Size
                                                        </span>
                                                        <h6 class="pp-point">
                                                          {
                                                            tile[0]?.mycommunity
                                                              ?.refcount
                                                          }
                                                        </h6>
                                                      </div>
                                                    </div>
                                                    <div class="col-6">
                                                      <div class="text-center">
                                                        <span class="pp-p-text">
                                                          Community Points
                                                        </span>
                                                        <h6 class="pp-point">
                                                          {
                                                            tile[0]?.mycommunity
                                                              ?.rewardpoint
                                                          }
                                                        </h6>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div> */}
                                                {/* <hr /> */}
                                                {/* <div>
                                              <h6>
                                                Impact done by my community.{" "}
                                              </h6>
                                              <div class="row">
                                                <div class="col-md-4 col-6">
                                                  <div class="text-center margin-bottom-10">
                                                    <img
                                                      src="https://www.pngall.com/wp-content/uploads/1/Community-PNG-Image.png"
                                                      class="img-fluid cam-commun"
                                                    />
                                                    <span class="cam-commun-text">
                                                      Lorem ipsum dolor sit amet
                                                      consectetur adipisicing
                                                      elit
                                                    </span>
                                                  </div>
                                                </div>
                                                <div class="col-md-4 col-6">
                                                  <div class="text-center margin-bottom-10">
                                                    <img
                                                      src="https://www.pngitem.com/pimgs/m/162-1625008_save-the-earth-png-transparent-png.png"
                                                      class="img-fluid cam-commun"
                                                    />
                                                    <span class="cam-commun-text">
                                                      Lorem ipsum dolor sit amet
                                                      consectetur adipisicing
                                                      elit
                                                    </span>
                                                  </div>
                                                </div>
                                                <div class="col-md-4 col-6">
                                                  <div class="text-center margin-bottom-10">
                                                    <img
                                                      src="https://cdn-icons-png.flaticon.com/512/1684/1684388.png"
                                                      class="img-fluid cam-commun"
                                                    />
                                                    <span class="cam-commun-text">
                                                      Lorem ipsum dolor sit amet
                                                      consectetur adipisicing
                                                      elit
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <hr /> */}
                                                {/* {tile?.campregdetails?.map(
                                              (item) => (
                                                <div>
                                                  <span>
                                                    Participation Status
                                                  </span>{" "}
                                                  {item?.campregistrationstatus ===
                                                  "submitted" ? (
                                                    <a class="p-regstatus">
                                                      {
                                                        item?.campregistrationstatus
                                                      }
                                                    </a>
                                                  ) : item?.campregistrationstatus ===
                                                    "approved" ? (
                                                    <a class="p-apprstatus">
                                                      {
                                                        item?.campregistrationstatus
                                                      }
                                                    </a>
                                                  ) : item?.campregistrationstatus ===
                                                    "rejected" ? (
                                                    <a class="p-rejstatus">
                                                      {
                                                        item?.campregistrationstatus
                                                      }
                                                    </a>
                                                  ) : (
                                                    <a class="p-notregstatus">
                                                      {
                                                        item?.campregistrationstatus
                                                      }
                                                    </a>
                                                  )}
                                                  
                                                </div>
                                              )
                                            )} */}
                                                {/* <hr /> */}
                                                {/* <div class="text-center">
                                                <a class="par-now">
                                                  {isDesktop ? (
                                                    <Button
                                                      onClick={() =>
                                                        navigate(
                                                          `/participate/${tile[0].campid}/${profileResponse?.phoneno}`
                                                        )
                                                      }
                                                      style={{ color: "white" }}
                                                      className={classes.button}
                                                    >
                                                      Participate Now{" "}
                                                      <i
                                                        class="fa fa-arrow-right"
                                                        aria-hidden="true"
                                                      ></i>
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      onClick={() =>
                                                        navigate(
                                                          `/participate/${tile[0].campid}/${profileResponse?.phoneno}`
                                                        )
                                                      }
                                                      style={{
                                                        color: "white",
                                                        fontSize: 10,
                                                      }}
                                                      className={classes.button}
                                                    >
                                                      Participate Now{" "}
                                                      <i
                                                        class="fa fa-arrow-right"
                                                        aria-hidden="true"
                                                      ></i>
                                                    </Button>
                                                  )}
                                                </a>
                                              </div> */}
                                                <hr />
                                              </div>
                                            </div>
                                          </div>
                                          // )
                                        )
                                      )}
                                  </OwlCarousel>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </section>{" "}
                  </div>
                </div>
              </div>

              <div class="container-fluid">
                <div class="cyagg-sec-space">
                  <div>
                    <div class="row">
                      <div class="col-8">
                        <div>
                          <h4 class="site-title">My Overall Status</h4>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="row">
                      <div class="col-md-6">
                        <div>
                          <img
                            src={OverallStatusImg}
                            class="img-fluid"
                            style={{
                              // width: "62%",
                              width: "36%",
                              margin: "0px auto",
                              display: "block",
                            }}
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="impact-sec-counts-wrap">
                          <div class="impact-sec-counts">
                            <div class="row">
                              <div class="col-md-3 col-6">
                                <div>
                                  <span>My Points</span>
                                  <h5>
                                    {mycommunity?.data?.mylevel?.rewardpoint}
                                  </h5>
                                </div>
                              </div>
                              <div class="col-md-3 col-6">
                                <div>
                                  <span>My Rank</span>
                                  <h5>
                                    {mycommunity?.data?.myrankpoints?.myrank}
                                  </h5>
                                </div>
                              </div>
                              <div class="col-md-3 col-6">
                                <div>
                                  <span>My Level</span>
                                  <h5>
                                    {mycommunity?.data?.mylevel?.levelname}
                                  </h5>
                                </div>
                              </div>
                              <div class="col-md-3 col-6">
                                <div>
                                  <span>Community</span>
                                  <h5>{mycommunity?.data?.refcount}</h5>
                                </div>
                              </div>
                            </div>
                            <div>
                              {/* <h6 class="progress-head">Achievements</h6> */}
                              <h6 class="progress-head">
                                Across all campaigns
                              </h6>
                              {/* <section class="cd-horizontal-timeline  overall-status-progress loaded">
                                <div class="timeline timeline1 ">
                                  <div class="events-wrapper">
                                    <div
                                      class="events"
                                      style={{ width: "1800px" }}
                                    >
                                      <ol>
                                        <li>
                                          <a
                                            href="#0"
                                            data-date="16/01/2016"
                                            class="selected"
                                          >
                                            16 Jan
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#0" data-date="28/02/2016">
                                            28 Feb
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#0" data-date="20/04/2016">
                                            20 Mar
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#0" data-date="20/05/2016">
                                            20 May
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#0" data-date="09/07/2016">
                                            09 Jul
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#0" data-date="30/08/2016">
                                            30 Aug
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href="#0"
                                            data-date="15/09/2016"
                                            style={{ width: "140px" }}
                                          >
                                            15 Sep
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#0" data-date="01/11/2016">
                                            01 Nov
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#0" data-date="10/12/2016">
                                            10 Dec
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#0" data-date="19/01/2017">
                                            29 Jan
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#0" data-date="03/03/2017">
                                            3 Mar
                                          </a>
                                        </li>
                                      </ol>
                                      <span
                                        class="filling-line"
                                        aria-hidden="true"
                                      ></span>
                                    </div>
                                  </div>
                                  <ul class="cd-timeline-navigation">
                                    <li>
                                      <a href="#0" class="prev inactive">
                                        Prev
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#0" class="next">
                                        Next
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <div class="events-content">
                                  <ol>
                                    <li class="selected" data-date="16/01/2016">
                                      <div class="media"></div>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit. Illum praesentium
                                        officia, fugit recusandae ipsa.
                                      </p>
                                    </li>
                                    <li data-date="28/02/2016">
                                      <div class="media"></div>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit. Illum praesentium
                                        officia, fugit recusandae ipsa.
                                      </p>
                                    </li>
                                    <li data-date="20/04/2016">
                                      <div class="media"></div>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit. Illum praesentium
                                        officia, fugit recusandae ipsa.
                                      </p>
                                    </li>
                                    <li data-date="20/05/2016">
                                      <div class="media"></div>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit. Illum praesentium
                                        officia, fugit recusandae ipsa.
                                      </p>
                                    </li>
                                    <li data-date="09/07/2016">
                                      <div class="media"></div>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit. Illum praesentium
                                        officia, fugit recusandae ipsa.
                                      </p>
                                    </li>
                                    <li data-date="30/08/2016">
                                      <div class="media"></div>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit. Illum praesentium
                                        officia, fugit recusandae ipsa.
                                      </p>
                                    </li>
                                    <li data-date="15/09/2016">
                                      <div class="media"></div>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit. Illum praesentium
                                        officia, fugit recusandae ipsa.
                                      </p>
                                    </li>
                                    <li data-date="01/11/2016">
                                      <div class="media"></div>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit. Illum praesentium
                                        officia, fugit recusandae ipsa.
                                      </p>
                                    </li>
                                    <li data-date="10/12/2016">
                                      <div class="media"></div>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit. Illum praesentium
                                        officia, fugit recusandae ipsa.
                                      </p>
                                    </li>
                                    <li data-date="19/01/2017">
                                      <div class="media"></div>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit. Illum praesentium
                                        officia, fugit recusandae ipsa.
                                      </p>
                                    </li>
                                    <li data-date="03/03/2017">
                                      <div class="media"></div>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur
                                        adipisicing elit. Illum praesentium
                                        officia, fugit recusandae ipsa.
                                      </p>
                                    </li>
                                  </ol>
                                </div>
                              </section> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container-fluid">
                <div class="cyagg-sec-space">
                  <div>
                    <div class="row">
                      <div class="col-7">
                        <div>
                          <h4 class="site-title">Win Exciting Rewards!</h4>
                        </div>
                      </div>
                      <div class="col-5">
                        <div
                          style={{ textAlign: "right" }}
                          onClick={() => navigate(`/marketplace`)}
                        >
                          {/* <a href="/marketplace" class="story-signin">
                            View More <i class="fa fa-arrow-right"></i>
                          </a> */}
                          <a class="story-signin">
                            View More <i class="fa fa-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4">
                      <div>
                        <img
                          class="hide-on-mobile"
                          src={RewardMainImage}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="">
                        {/* <div class="reward-slide owl-carousel owl-theme "> */}
                        <OwlCarousel className="owl-theme" {...options}>
                          {marketplacelist &&
                            marketplacelist?.map(
                              (reward, index) =>
                                index < 5 && (
                                  <div class="item">
                                    <div class="reward-wrap">
                                      <div>
                                        {/* {reward?.rewardtype ===
                                        "30 mins with a CEO" ? (
                                          <img
                                            class="reward-imagehm"
                                            src={CEOImage}
                                            style={{ width: "100%" }}
                                          />
                                        ) : reward?.rewardtype ===
                                          "Certificate of Participation" ? (
                                          <img
                                            class="reward-imagehm"
                                            src={CertificateImage}
                                            style={{ width: "100%" }}
                                          />
                                        ) : reward?.rewardtype ===
                                          "Video Interview with You" ? (
                                          <img
                                            class="reward-imagehm"
                                            src={VideoInterviewImage}
                                            style={{ width: "100%" }}
                                          />
                                        ) : reward?.rewardtype ===
                                          "Discount Coupons" ? (
                                          <img
                                            class="reward-imagehm"
                                            src={DiscountCouponImage}
                                            style={{ width: "100%" }}
                                          />
                                        ) : reward?.rewardtype ===
                                          "Gift Card" ? (
                                          <img
                                            class="reward-imagehm"
                                            src={DiscountCouponImage}
                                            style={{ width: "100%" }}
                                          />
                                        ) : reward?.rewardtype ===
                                          "Internship Opportunity" ? (
                                          <img
                                            class="reward-imagehm"
                                            src={InternshipImage}
                                            style={{ width: "100%" }}
                                          />
                                        ) : reward?.rewardtype ===
                                          "Social Media Recognition" ? (
                                          <img
                                            class="reward-imagehm"
                                            src={SocialMediaImage}
                                            style={{ width: "100%" }}
                                          />
                                        ) : (
                                          <img
                                            class="reward-imagehm"
                                            src={RewardMainImage}
                                            style={{ width: "100%" }}
                                          />
                                        )} */}
                                        {!(reward?.logo === "") ? (
                                          reward?.expired === "yes" ? (
                                            <img
                                              class="reward-imagehm"
                                              src={reward?.logo}
                                              style={{
                                                width: "100%",
                                                opacity: 0.4,
                                              }}
                                            />
                                          ) : (
                                            <img
                                              class="reward-imagehm"
                                              src={reward?.logo}
                                              style={{ width: "100%" }}
                                            />
                                          )
                                        ) : reward?.expired === "yes" ? (
                                          <img
                                            class="reward-imagehm"
                                            src={CEOImage}
                                            style={{
                                              width: "100%",
                                              opacity: 0.4,
                                            }}
                                          />
                                        ) : (
                                          <img
                                            class="reward-imagehm"
                                            src={CEOImage}
                                            style={{ width: "100%" }}
                                          />
                                        )}
                                      </div>
                                      <div class="reward-details">
                                        <div>
                                          <div>
                                            <div id="toggleAccordion">
                                              <div class="card mb-1">
                                                <div
                                                  class="card-header"
                                                  id="headingOne4"
                                                >
                                                  <h5 class="mb-0 mt-0 reward-desc-title">
                                                    <span
                                                      role="menu"
                                                      class=""
                                                      data-toggle="collapse"
                                                      data-target={"#" + index}
                                                      aria-expanded="true"
                                                      aria-controls="iconChangeAccordionOne"
                                                    // style={{whiteSpace:"nowrap", textTransform: "none", textOverflow: "ellipsis", overflow: "hidden", maxWidth:"64%"}}
                                                    >
                                                      {reward?.rewardtype}
                                                      <i class="flaticon-down-arrow float-right"></i>
                                                    </span>
                                                  </h5>
                                                </div>

                                                <div
                                                  id={index}
                                                  class="collapse"
                                                  aria-labelledby="headingOne4"
                                                  data-parent="#toggleAccordion"
                                                >
                                                  <div class="card-body">
                                                    <p class="mb-3">
                                                      <div
                                                        className={
                                                          classes.subtitleWrapper
                                                        }
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 12,
                                                            color: "blue",
                                                          }}
                                                        >
                                                          {" "}
                                                          {
                                                            reward?.subtitle
                                                          }{" "}
                                                        </span>
                                                      </div>
                                                    </p>
                                                    <p class="mb-3">
                                                      <div
                                                        className={
                                                          classes.subtitleWrapper
                                                        }
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 12,
                                                            color: "green",
                                                          }}
                                                        >
                                                          {" "}
                                                          {reward?.title}{" "}
                                                        </span>
                                                      </div>
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {/* <div class="redeem-pts">
                                            <div class="row">
                                              <div class="col-6">
                                                {reward?.rewardcat ===
                                                  "rewards" &&
                                                reward?.campid ===
                                                  "camp00001" ? (
                                                  <div class="re-po-left">
                                                    <img src="https://png.pngtree.com/png-clipart/20220213/original/pngtree-coins-stack-icon-vector-png-image_7267762.png" />
                                                    <div class="pts">
                                                      <h5>{reward?.points}</h5>
                                                      <span>Points</span>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              
                                            </div>
                                          </div> */}

                                          <div class="redeem-pts1">
                                            <div class="row">
                                              <div class="col-12">
                                                <div class="re-po1-right">
                                                  {!(
                                                    reward?.campid ===
                                                    "camp00001"
                                                  ) ? (
                                                    <Button
                                                      onClick={
                                                        () =>
                                                          navigate(
                                                            `/participate/${reward?.campid}/${profileResponse?.phoneno}`
                                                          )
                                                        // setDisplayConfirmationModal(true)

                                                        // setRedeemIndex(index)
                                                      }
                                                      // style={{ backgroundColor: "#2D7A74", color: "white", padding: "9px 10px" }}

                                                      style={{
                                                        // float: "right",
                                                        // width: "114px",
                                                        textAlign: "left",
                                                        maxHeight: "50px",
                                                      }}
                                                    >
                                                      <a
                                                        style={{
                                                          fontFamily:
                                                            "sans-serif",
                                                          // whiteSpace:"nowrap", textTransform: "none", textOverflow: "ellipsis", overflow: "hidden", maxWidth:"64%"
                                                        }}
                                                      >
                                                        {/* <Chip key={reward?.campname} fontSize="small" size="small" label={reward?.campname} /> */}
                                                        {reward?.campname}
                                                      </a>
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      onClick={
                                                        () =>
                                                          navigate(`/aboutus`)
                                                        // setDisplayConfirmationModal(true)

                                                        // setRedeemIndex(index)
                                                      }
                                                      // style={{ backgroundColor: "#2D7A74", color: "white", padding: "9px 10px" }}

                                                      style={{
                                                        // float: "right",
                                                        // width: "114px",
                                                        textAlign: "left",
                                                        maxHeight: "50px",
                                                      }}
                                                    >
                                                      <a
                                                        style={{
                                                          fontFamily:
                                                            "sans-serif",
                                                          // whiteSpace:"nowrap", textTransform: "none", textOverflow: "ellipsis", overflow: "hidden", maxWidth:"64%"
                                                        }}
                                                      >
                                                        {reward?.campname}
                                                      </a>
                                                    </Button>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="text-center">
                                            <h6 class="sponsor-title">
                                              Sponsors
                                            </h6>
                                            <div class="row sponserWrapper">
                                              <div class="">
                                                {isArray(reward?.sponsor) &&
                                                  reward?.sponsor?.map(
                                                    (reward, index) => (
                                                      <img
                                                        style={{
                                                          width: "auto",
                                                          height: "50px",
                                                        }}
                                                        src={
                                                          reward?.sponsorlogo
                                                        }
                                                      />
                                                    )
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                            )}
                          {/* </div> */}
                        </OwlCarousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container-fluid">
                <div class="cyagg-sec-space">
                  <div>
                    <div class="row">
                      <div class="col-7">
                        <div>
                          <h4 class="site-title">Rewards I Redeemed</h4>
                        </div>
                      </div>
                      <div class="col-5">
                        <div
                          style={{ textAlign: "right" }}
                          onClick={() => navigate(`/redeemreport`)}
                        >
                          <a class="story-signin">
                            {/* <button */}
                            {/* > */}
                            View Details
                            <i class="fa fa-arrow-right"></i>
                            {/* </button> */}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4">
                      <div>
                        <img
                          class="hide-on-mobile"
                          src={RewardMainImage}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div class="col-md-8">
                      <OwlCarousel className="owl-theme" {...options}>
                        {myredeemedrewards &&
                          myredeemedrewards?.data?.map(
                            (reward, index) =>
                              index < 2 &&
                              reward?.reward?.map(
                                (item1, index1) =>
                                  index1 < 5 && (
                                    <div class="item">
                                      <div class="reward-wrap">
                                        {/* <div>
                                          <img
                                            class="reward-image"
                                            src={RewardMainImage}
                                            style={{ width: "100%" }}
                                          />
                                        </div> */}
                                        <div>
                                          {!(
                                            item1?.logo === "" ||
                                            item1?.logo === undefined
                                          ) ? (
                                            <img
                                              class="reward-image"
                                              src={item1?.logo}
                                              style={{ width: "100%" }}
                                            />
                                          ) : item1?.logo === undefined ? (
                                            <img
                                              class="reward-image"
                                              src={CEOImage}
                                              style={{ width: "100%" }}
                                            />
                                          ) : (
                                            <img
                                              class="reward-image"
                                              src={CEOImage}
                                              style={{ width: "100%" }}
                                            />
                                          )}
                                          {/* {item1?.rewardtype ===
                                          "30 mins with a CEO" ? (
                                            <img
                                              class="reward-imagehm"
                                              src={CEOImage}
                                              style={{ width: "100%" }}
                                            />
                                          ) : item1?.rewardtype ===
                                            "Certificate of Participation" ? (
                                            <img
                                              class="reward-imagehm"
                                              src={CertificateImage}
                                              style={{ width: "100%" }}
                                            />
                                          ) : item1?.rewardtype ===
                                            "Video Interview with You" ? (
                                            <img
                                              class="reward-imagehm"
                                              src={VideoInterviewImage}
                                              style={{ width: "100%" }}
                                            />
                                          ) : item1?.rewardtype ===
                                            "Discount Coupons" ? (
                                            <img
                                              class="reward-imagehm"
                                              src={DiscountCouponImage}
                                              style={{ width: "100%" }}
                                            />
                                          ) : item1?.rewardtype ===
                                            "Gift Cards" ? (
                                            <img
                                              class="reward-imagehm"
                                              src={DiscountCouponImage}
                                              style={{ width: "100%" }}
                                            />
                                          ) : item1?.rewardtype ===
                                            "Internship Opportunity" ? (
                                            <img
                                              class="reward-imagehm"
                                              src={InternshipImage}
                                              style={{ width: "100%" }}
                                            />
                                          ) : item1?.rewardtype ===
                                            "Social Media Recognition" ? (
                                            <img
                                              class="reward-imagehm"
                                              src={SocialMediaImage}
                                              style={{ width: "100%" }}
                                            />
                                          ) : (
                                            <img
                                              class="reward-imagehm"
                                              src={RewardMainImage}
                                              style={{ width: "100%" }}
                                            />
                                          )} */}
                                        </div>
                                        <div class="reward-details">
                                          <div>
                                            <div>
                                              <div id="toggleAccordion">
                                                <div class="card mb-1">
                                                  <div
                                                    class="card-header"
                                                    id="headingOne4"
                                                  >
                                                    <h5 class="mb-0 mt-0 reward-desc-title">
                                                      <span
                                                        role="menu"
                                                        class=""
                                                        data-toggle="collapse"
                                                        data-target={
                                                          "#" + 1 + index1
                                                        }
                                                        aria-expanded="true"
                                                        aria-controls="iconChangeAccordionOne"
                                                      >
                                                        {item1?.rewardtype}
                                                        <i class="flaticon-down-arrow float-right"></i>
                                                      </span>
                                                    </h5>
                                                  </div>

                                                  <div
                                                    id={"1" + index1}
                                                    class="collapse"
                                                    aria-labelledby="headingOne4"
                                                    data-parent="#toggleAccordion"
                                                  >
                                                    <div class="card-body">
                                                      <p class="mb-3">
                                                        <div
                                                          className={
                                                            classes.subtitleWrapper
                                                          }
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 12,
                                                              color: "blue",
                                                            }}
                                                          >
                                                            {" "}
                                                            {
                                                              item1?.subtitle
                                                            }{" "}
                                                          </span>
                                                        </div>
                                                      </p>
                                                      <p class="mb-3">
                                                        <div
                                                          className={
                                                            classes.subtitleWrapper
                                                          }
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 12,
                                                              color: "green",
                                                            }}
                                                          >
                                                            {" "}
                                                            {item1?.title}{" "}
                                                          </span>
                                                        </div>
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {/* <div class="redeem-pts">
                                              <div class="row">
                                                <div class="col-6">
                                                {reward?.rewardcat ===
                                                  "rewards" &&
                                                reward?.campid ===
                                                  "camp00001" ? (
                                                  <div class="re-po-left">
                                                    <img src="https://png.pngtree.com/png-clipart/20220213/original/pngtree-coins-stack-icon-vector-png-image_7267762.png" />
                                                    <div class="pts">
                                                      <h5>{item1?.points}</h5>
                                                      <span>Points</span>
                                                    </div>
                                                  </div>
                                                   ) : (
                                                    ""
                                                  )}
                                                </div>
                                              </div>
                                            </div> */}

                                            <div class="redeem-pts">
                                              <div class="row">
                                                <div class="col-12">
                                                  <div class="re-po1-right">
                                                    {!(
                                                      item1?.campid ===
                                                      "camp00001"
                                                    ) ? (
                                                      <Button
                                                        onClick={
                                                          () =>
                                                            navigate(
                                                              `/participate/${item1?.campid}/${profileResponse?.phoneno}`
                                                            )
                                                          // setDisplayConfirmationModal(true)

                                                          // setRedeemIndex(index)
                                                        }
                                                        // style={{ backgroundColor: "#2D7A74", color: "white", padding: "9px 10px" }}

                                                        style={{
                                                          // float: "right",
                                                          // width: "114px",
                                                          textAlign: "left",
                                                          maxHeight: "50px",
                                                        }}
                                                      >
                                                        <a
                                                          style={{
                                                            fontFamily:
                                                              "sans-serif",
                                                            //   textOverflow:"ellipsis",
                                                            // overflow: "hidden",
                                                          }}
                                                        >
                                                          {/* <Chip key={reward?.campname} fontSize="small" size="small" label={reward?.campname} /> */}
                                                          {item1?.campname}
                                                        </a>
                                                      </Button>
                                                    ) : (
                                                      <Button
                                                        onClick={
                                                          () =>
                                                            navigate(`/aboutus`)
                                                          // setDisplayConfirmationModal(true)

                                                          // setRedeemIndex(index)
                                                        }
                                                        // style={{ backgroundColor: "#2D7A74", color: "white", padding: "9px 10px" }}

                                                        style={{
                                                          // float: "right",
                                                          // width: "114px",
                                                          textAlign: "left",
                                                          maxHeight: "50px",
                                                        }}
                                                      >
                                                        <a
                                                          style={{
                                                            fontFamily:
                                                              "sans-serif",
                                                          }}
                                                        >
                                                          {item1?.campname}
                                                        </a>
                                                      </Button>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div class="text-center">
                                              <h6 class="sponsor-title">
                                                Sponsors
                                              </h6>
                                              <div class="row sponserWrapper">
                                                <div class="">
                                                  {isArray(item1?.sponsor) &&
                                                    item1?.sponsor?.map(
                                                      (reward, index) => (
                                                        <img
                                                          style={{
                                                            width: "auto",
                                                            height: "50px",
                                                          }}
                                                          src={
                                                            reward?.sponsorlogo
                                                          }
                                                        />
                                                      )
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                            <div>
                                              <img
                                                src={WonIcon}
                                                class="won-re-img"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>{" "}
                                    </div>
                                  )
                              )
                          )}
                      </OwlCarousel>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container-fluid">
                <div class="cyagg-sec-space">
                  <div>
                    <div class="row">
                      <div class="col-7">
                        <div>
                          <h4 class="site-title">Success Stories</h4>
                        </div>
                      </div>
                      {/* <div class="col-5">
                        <div style={{ textAlign: "right" }}>
                          <a class="story-signin">
                            View More <i class="fa fa-arrow-right"></i>
                          </a>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div class="home-demo">
                    {/* <div class="owl-carousel owl-theme"> */}
                    <OwlCarousel className="owl-theme" {...optionsFour}>
                      {successStories?.data?.map((item, index) => (
                        <div class="item">
                          <div class="succ-story-wrap">
                            <div>
                              {/* <img src="https://www.fuersie.de/sites/default/files/styles/image360w/public/2022-06/egozentrische-person.jpg?h=fa6d1f7a&itok=da_GdqKf" /> */}
                              <img src={item.image} />
                            </div>
                            <div class="text-center succ-story-details">
                              <h6>{item?.name}</h6>
                              <p>{item?.msg}</p>
                            </div>
                          </div>
                        </div>
                      ))}

                      {/* </div> */}
                    </OwlCarousel>
                  </div>
                </div>
              </div>

              <div class="container-fluid">
                <div class="cyagg-sec-space">
                  <div class="testimonial-wrap">
                    <div class="row">
                      <div class="col-md-4 hide-on-spec">
                        <div class="testi-img">
                          <img src={TestiImg} style={{ width: "100%" }} />
                        </div>
                      </div>
                      <div class="col-md-8 add-full-width">
                        <div class="testi-content">
                          <h2>What our changemakers say about cYAAG</h2>
                          <div>
                            {/* <div class="testi-sl owl-carousel owl-theme"> */}
                            <OwlCarousel
                              className="owl-theme"
                              items="1"
                              autoplay
                              nav
                              dots
                              loop
                            >
                              <div class="item">
                                <div class="they-said-wrap">
                                  {/* <img
                                    class="test-per-img"
                                    src="https://www.fuersie.de/sites/default/files/styles/image360w/public/2022-06/egozentrische-person.jpg?h=fa6d1f7a&itok=da_GdqKf"
                                  /> */}
                                  <Avatar> </Avatar>
                                  <div class="testi-desc">
                                    <h6>Anonymous</h6>
                                    <p style={{ fontSize: 16 }}>
                                      cYAAG gets results. It is the current day
                                      Digital Word of Mouth!
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="item">
                                <div class="they-said-wrap">
                                  {/* <img
                                    class="test-per-img"
                                    src="https://www.fuersie.de/sites/default/files/styles/image360w/public/2022-06/egozentrische-person.jpg?h=fa6d1f7a&itok=da_GdqKf"
                                  /> */}
                                  <Avatar> </Avatar>
                                  <div class="testi-desc">
                                    <h6>Anonymous</h6>
                                    <p style={{ fontSize: 16 }}>
                                      This platform empowers social changemakers
                                      to create impact!
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="item">
                                <div class="they-said-wrap">
                                  {/* <img
                                    class="test-per-img"
                                    src="https://www.fuersie.de/sites/default/files/styles/image360w/public/2022-06/egozentrische-person.jpg?h=fa6d1f7a&itok=da_GdqKf"
                                  /> */}
                                  <Avatar> </Avatar>
                                  <div class="testi-desc">
                                    <h6>Anonymous</h6>
                                    <p style={{ fontSize: 16 }}>
                                      The goal was to reach visually impaired
                                      people. Participants and recipients were
                                      inspired and motivated. This YAAG has
                                      wings.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* </div> */}
                            </OwlCarousel>
                          </div>
                          {/* </OwlCarousel> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className={classes.homeScreenContainer}>
          <div className={classes.myImpacts}>
            <Tabs
              style={{
                background: "#1A524D 0% 0% no-repeat padding-box",
                width: 585,
                borderRadius: 20,
                color: "white"
              }}
              value={value}
              onChange={handleChange}
            >
                            
              <Tab label="Upcoming Campaigns" className={classes.tabName} />
              <Tab label="Active Campaigns" className={classes.tabName} />
              <Tab label="Completed Campaigns" className={classes.tabName} />
            </Tabs>

          
            <TabPanel value={value} index={0}>
              <div className={classes.myImpacts}>
                <GridList className={classes.gridList} cellHeight={450}>
                  {campaignList
                    ?.filter((item, index) => item.active === "Upcoming")
                    .map((tile) => (
                      <GridListTile
                        style={{ width: "auto !important" }}
                        key={tile.image}
                      >
                        <HomeCard
                          data={tile}
                          key={tile.cardTitle}
                          userDetail={profileResponse ? profileResponse : ""}
                        />
                      </GridListTile>
                    ))}
                </GridList>
              </div>

              {activeTrue && (
                <div className={classes.myImpacts}>
                  <GridList className={classes.gridList} cellHeight={450}>
                    <li style={{ width: "auto !important", margin: 8, listStyle: "none",height: "auto !important" }}>
                      <Typography style={{ color: "#fff" }}>
                        No Active campaigns
                      </Typography>
                    </li>
                  </GridList>
                </div>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className={classes.myImpacts}>
                <GridList className={classes.gridList} cellHeight={450}>
                  {campaignList
                    ?.filter((item, index) => item.active === "Active")
                    .map((tile) => (
                      <li
                        style={{ width: "auto !important", margin: 8, listStyle: "none",height: "auto !important" }}
                        key={tile.image}
                      >
                        <HomeCard
                          data={tile}
                          key={tile.cardTitle}
                          userDetail={profileResponse ? profileResponse : ""}
                        />
                      </li>
                    ))}
                </GridList>
              </div>
            </TabPanel>

            <TabPanel value={value} index={2}>
              <div className={classes.myImpacts}>
                <GridList className={classes.gridList} cellHeight={450}>
                  {campaignList
                    ?.filter((item, index) => item.active === "Completed")
                    .map((tile) => (
                      <GridListTile
                        style={{ width: "auto !important" }}
                        key={tile.image}
                      >
                        <HomeCard
                          data={tile}
                          key={tile.cardTitle}
                          userDetail={profileResponse ? profileResponse : ""}
                        />
                      </GridListTile>
                    ))}
                </GridList>
              </div>
            </TabPanel>
          </div>
        </div> */}
            </div>
          </div>
          <CopyRight />
          <FixedFooterIcon />
          <RedeemConfirmation
            showModal={displayRedeemConfirmationModal}
            confirmModal={handleRedeem}
            hideModal={hideRedeemConfirmationModal}
            message={"Are you sure you want to redeem this reward?"}
          />
          <ConfirmationDialog
            showModal={displayConfirmationModal}
            // confirmModal={handleRedeem}
            hideModal={hideConfirmationModal}
            message={"You do not have enough points to redeem"}
          />
          <PostRedeemConfirmation
            showModal={postRedeemDisplayModal}
            confirmModal={handlePostRedeemModal}
            // hideModal={handlePostRedeemModal}
            message={"You have successfully redeemed"}
          />
        </section>
        {/* <div style={{marginTop: 500}}>
        <CopyRight companyName={"cYAAG"} />
      </div> */}
      </div>
    </Card>
  );
};

export default HAScreen;
