/** @format */

// reducers
import {
    ADD_RECOGNITIONS_FETCHING_DATA,
    ADD_RECOGNITIONS_FETCHING_DATA_FAILURE,
    ADD_RECOGNITIONS_FETCHING_DATA_RESET,
    ADD_RECOGNITIONS_FETCHING_DATA_SUCCESS,
    
  } from "../action/addRecognitionsAction";
  
  let initialState = {
    addrecognitionsResponse: {},
    addrecognitionsSuccess: false,
    addrecognitionsError: false,
    addrecognitionsFetching: false,
  };
  
  export default function OnboardReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_RECOGNITIONS_FETCHING_DATA:
            return {
            ...state,
            addrecognitionsFetching: true,
            addrecognitionsError: false,
            addrecognitionsSuccess: false,
        };
        case ADD_RECOGNITIONS_FETCHING_DATA_FAILURE:
            return {
            ...state,
            addrecognitionsFetching: false,
            addrecognitionsError: action.payload,
            addrecognitionsSuccess: false,
        };
        case ADD_RECOGNITIONS_FETCHING_DATA_SUCCESS:
            return {
            ...state,
            addrecognitionsFetching: false,
            addrecognitionsError: false,
            addrecognitionsResponse: action.payload,
            addrecognitionsSuccess: true,
        };
        case ADD_RECOGNITIONS_FETCHING_DATA_RESET:
            return {
            ...state,
            addrecognitionsFetching: true,
            addrecognitionsError: false,
            addrecognitionsSuccess: false,
        };
      
      default:
        return state;
    }
  }
  