import * as React from "react";
import FormControl from "@mui/material/FormControl";
import {
  makeStyles,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import {
  Avatar,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import _, { values } from "lodash";
// import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import {
  onboardActivityReset,
  onboardBulkReset,
  onboardCampaign,
  onboardCampaignReset,
  onboardIndividualReset,
  onboardOrganization,
  onboardOrganizationReset,
} from "../../redux/action/OnboardAction";
import CallingCode from "../../services/CallingCode";
import { fetchCityAndState } from "../../services/commonServices";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";
import { API_URL } from "../../services/Constants";
import { getOrganization } from "../../redux/action/getOrganization";
import {
  activityDuration,
  campaignType,
  restrictedCountry,
  BloodGroup,
  Gender,
  status,
  educationList,
  preferWorkingDays,
  hoursEveryWeek,
  interestAreaDict,
  skillSets,
} from "../../utils/dict";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { getCity } from "../../redux/action/cityAction";
import { states, MenuProps } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import DoneIcon from "@mui/icons-material/Done";
import PaidIcon from '@mui/icons-material/Paid';

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    fontSize: 15,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    // color: "#949494",
    color: "black",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  emailinput: {
    // textTransform: "capitalize",
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    // boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    // color: "#949494",
    color: "black",
    fontSize: 15,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "block",
    fontSize: 16,
    marginTop: 16,
    padding: 10,
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "block",
    fontSize: 16,
    marginTop: 16,
    // padding: 4,
    minHeight: 38,
    marginBottom: 0,
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
  },
  formGroup2: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",
    flexDirection: "column",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "75px 0px 0px 16px",
    // pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%",
    },
    [theme.breakpoints.only("md")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
}));

function VolunteerEditScreen(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = React.useRef(null);
  const videoRef = React.useRef(null);
  const [orgRole, setORGRole] = React.useState([]);
  const [org, setORG] = React.useState([]);
  const locationPath = useLocation();
  const [address, setAddress] = React.useState("");
  const [correspondenceaddress, setcorrespendeceaddress] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [area, setArea] = React.useState("");
  const [added, setAdded] = React.useState("no");
  const [state, setState] = React.useState("");
  const [city, setCity] = React.useState(false);

  

  const handleSelect = async (value) => {
    setSearchSelect(true);

    // console.log(value);
    
    const results = await geocodeByAddress(value);
    // console.log(results[0]["formatted_address"]);
    setAddress(results[0]["formatted_address"]);

    // console.log(value);
  };

  const [volunteerDetails, setvolunteerDetails] = useState({
    // fullname: "",
    firstname: "",
    lastname: "",
    dob: new Date(),
    correspondenceAddress: "",
    emailId: "",
    gender: "",
    phoneNumber: "",
    city: "",
    state: "",
    area: "",
    organization: "",
    highLevelEdu: "",
    preferDays: "",
    interestArea: "",
    hoursPerWeek: "",
    orgRole: "",
    bloodGroup: "",
    skill: "",
    expertise: "",
  });

  const getORGRole = async () => {
    try {
      const res = await axios.get(`/orgrole/${localStorage.getItem("userid")}`);
      setORGRole(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getORG = async () => {
    try {
      const res = await axios.get(
        `/organization/${localStorage.getItem("userid")}`
      );
      setORG(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const [response, setResponse] = useState({});
  const [errorOnSave, setErrorOnSave] = useState("");
  const [searchSelect, setSearchSelect] = useState(false);
  const { isSuccess: profileSuccess, response: VolunteerResponse } =
    useSelector((state) => state.profile);

  const handleFieldChange = (field, e) => {
    setResponse({});
    setvolunteerDetails({
      ...volunteerDetails,
      [field]: field === "dob" ? e : e.target.value,
    });
  };
  const getSelectedValue = (orgObj) => {
    // console.log(orgObj);
  };

  const [notificationShow, setNotificationShow] = React.useState(false);

  function subtractYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);

    return date;
  }

  const handleChangeLocation = (id, e, field, index) => {
    const values = [...location];
    values[index][field] = e.target.value;
    setLocation(values);
  };

  //   const handleVolunteer = async () => {
  //     const payload = {
  //       campid: locationPath?.state?.campid,
  //       createdate: locationPath?.state?.createdate,
  //       reviewstatus: reviewstatus,
  //       reviewcomments: reviewComments,
  //     };
  //     try {
  //       const res = await axios.get(
  //         `/cyaaguser/${localStorage.getItem("userid")}`,
  //         payload
  //       );
  //       navigate("/reviewcampaign");
  //       return res;
  //     } catch (err) {
  //       throw err;
  //     }
  //   };

  // console.log(locationPath?.state.dob)
  // console.log(new Date())
 

  useEffect(() => {
    if (locationPath?.state) {
      setvolunteerDetails({
        // fullname: locationPath?.state.fullname,
        // dob: locationPath?.state.dob,
        firstname: locationPath?.state.firstname,
        lastname: locationPath?.state.lastname,
        dob: moment(locationPath?.state.dob, "MM-DD-YYYY"),
        correspondenceAddress: locationPath?.state.correspondenceaddress,
        emailId: locationPath?.state.emailid,
        gender: locationPath?.state.gender,
        phoneNumber: locationPath?.state.phoneno,
        country: locationPath?.state.country,
        city: locationPath?.state.city,
        state: locationPath?.state.state,
        area: locationPath?.state.area,
        organization: locationPath?.state.organization,
        orgRole: locationPath?.state.orgrole,
        highLevelEdu: locationPath?.state.highlvledu,
        preferDays: locationPath?.state.preferday,
        interestArea: locationPath?.state.interestarea,
        hoursPerWeek: locationPath?.state.hoursperweek,
        bloodGroup: locationPath?.state.bloodgroup,
        skill: locationPath?.state.skill,
        expertise: locationPath?.state.expertise,
      });
    }
  }, [locationPath?.state]);



  const handleSave = async () => {
    try {
    
      if (
        volunteerDetails?.firstname != "" &&
        volunteerDetails?.lastname != "" &&
        volunteerDetails?.correspondenceAddress != "" &&
        volunteerDetails?.emailId != "" &&
        country != "" &&
        state != ""
      ) {
        const res = await axios.put(`/user/profile`, {
          // fullname: volunteerDetails?.fullname,
          firstname: volunteerDetails?.firstname,
          lastname: volunteerDetails?.lastname,
          dob: moment(volunteerDetails?.dob).format("DD-MM-yyyy"),
          phoneno: volunteerDetails?.phoneNumber,
          correspondenceaddress: volunteerDetails?.correspondenceAddress,
          emailid: volunteerDetails?.emailId,
          gender: volunteerDetails?.gender,
          // city: volunteerDetails?.city,
          // state: volunteerDetails?.state,
          country: country,
          state: state,
          city: city,
          area: area,
          organization: volunteerDetails?.organization,
          highlvledu: volunteerDetails?.highLevelEdu,
          preferday: volunteerDetails?.preferDays,
          hoursperweek: volunteerDetails?.hoursPerWeek,
          interestarea: volunteerDetails?.interestArea,
          orgrole: volunteerDetails?.orgRole.toLowerCase(),
          bloodgroup: volunteerDetails?.bloodGroup,
          skill: volunteerDetails.skill,
          expertise: volunteerDetails?.expertise,
        });
        setResponse(res?.data);
        setErrorOnSave("");
        return res;
      } else if (
        volunteerDetails?.firstname != "" &&
        volunteerDetails?.lastname != "" &&
        volunteerDetails?.correspondenceAddress != "" &&
        volunteerDetails?.emailId != "" &&
        volunteerDetails.country != "" &&
        volunteerDetails.state != ""
      ) {
        const res = await axios.put(`/user/profile`, {
          // fullname: volunteerDetails?.fullname,
          firstname: volunteerDetails?.firstname,
          lastname: volunteerDetails?.lastname,
          dob: moment(volunteerDetails?.dob).format("DD-MM-yyyy"),
          phoneno: volunteerDetails?.phoneNumber,
          correspondenceaddress: volunteerDetails?.correspondenceAddress,
          emailid: volunteerDetails?.emailId,
          gender: volunteerDetails?.gender,
          // city: volunteerDetails?.city,
          // state: volunteerDetails?.state,
          country: volunteerDetails.country,
          state: volunteerDetails.state,
          city: volunteerDetails.city,
          area: volunteerDetails.area,
          organization: volunteerDetails?.organization,
          highlvledu: volunteerDetails?.highLevelEdu,
          preferday: volunteerDetails?.preferDays,
          hoursperweek: volunteerDetails?.hoursPerWeek,
          interestarea: volunteerDetails?.interestArea,
          orgrole: volunteerDetails?.orgRole.toLowerCase(),
          bloodgroup: volunteerDetails?.bloodGroup,
          skill: volunteerDetails.skill,
          expertise: volunteerDetails?.expertise,
        });
        setResponse(res?.data);
        setErrorOnSave("");
        return res;
      } else {
        setErrorOnSave("Please do not leave any field empty and click on Save");
      }
    } catch (e) {
      // console.log("error", e);
    }
  };

  //   const handleSave = async () => {
  //     try {
  //       const res = await axios.put(`/user/profile`, {
  //         fullname: values?.fullname,
  //         dob: values?.dob,
  //         phoneno: values?.phoneNumber,
  //         correspondenceaddress: values?.correspondenceAddress,
  //         emailid: values?.emailId,
  //         city: values?.city,
  //         state: values?.state,
  //         organization: values?.organization,
  //         highlvledu: values?.highLevelEdu,
  //         preferday: values?.preferDays,
  //         hoursperweek: values?.hoursPerWeek,
  //         interestarea: values?.interestArea,
  //         orgrole: values?.orgRole,
  //         bloodgroup: values?.bloodGroup,
  //         skill: values.skill,
  //       });
  //       setResponse(res?.data);
  //       return res;
  //     } catch (e) {
  //       console.log("error", e);
  //     }
  //   };

  //   const onAddingItem = (event, option, index) => {
  //     const values = [...focusArea];
  //     values[index].isChecked = event.target.checked;
  //     setFocusArea(values);
  //   };

  //   const handleTextFieldCheckbox = (event, index) => {
  //     const values = [...focusArea];
  //     values[index].rewards = event.target.value;
  //     setFocusArea(values);
  //   };

  //   const handleChangeLocation = (id, e, field, index) => {
  //     const values = [...location];
  //     values[index][field] = e.target.value;
  //     setLocation(values);
  //   };

  // const handleChangeLocation1 = (field, e) => {
  //     const values = [...location];
  //     values[index][field] = e.target.value;
  //     setLocation(values);
  //   };

  //   const handleFieldChange = (field, e) => {
  //     setResponse({});
  //     setValues({
  //       ...values,
  //       [field]: field === "dob" ? e : e.target.value,
  //     });
  //   };

  const handleAddFields = () => {
    setLocation([...location, { country: "", state: "", city: "", area: "" }]);
  };

  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "" },
  ]);

  const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
    (state) => state.cities
  );

  const {
    isSuccess: organizationListSuccess,
    isError: organizationListError,
    response: organizationList,
  } = useSelector((state) => state.organizationList);

  React.useEffect(() => {
    dispatch(onboardBulkReset());
    dispatch(onboardIndividualReset());
    dispatch(onboardActivityReset());
    dispatch(onboardCampaignReset());
    dispatch(onboardOrganizationReset());
  }, []);

  const handleChangeSkills = (event, index) => {
    let value = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    this.setState({ values: value });
  };

  useEffect(() => {
    // console.log(locationPath?.state.city)
    if (locationPath?.state.city) {
      dispatch(getCity(locationPath?.state.state));
    }
    getORGRole();
    getORG();
  }, []);

  //handle key press event
  const handleKeypress = (e) => {
    e.preventDefault();
    return false;
  };
 

  const updatelocation = (address) => {
    // console.log("index");
    // console.log(index);
  
    // if (!address) {
    //     setCountryError(true);
    // } else {
    //     setCountryError(false);
    //     setAdded("yes");
    // }
    if (!address) {
      // setCountryError(true);
      setAdded("no");
    } else if (
      String(address.split(",")[address.split(",").length - 2]).trim(" ") ===
      "undefined"
    ) {
      // setCountryError(true);
      setAdded("no");
    } else {
      // setCountryError(false);
      setCountry(
        String(address.split(",")[address.split(",").length - 1]).trim(" ")
      );
      setState(
        String(address.split(",")[address.split(",").length - 2]).trim(" ")
      );
      setCity(
        String(address.split(",")[address.split(",").length - 3]).trim(" ")
      );
      setArea(
        String(address.split(",")[address.split(",").length - 4]).trim(" ")
      );
      setAdded("yes");
    }

    // setCountry(String(address.split(",")[(address.split(",").length - 1)]).trim(" "));
    // setState(String(address.split(",")[(address.split(",").length - 2)]).trim(" "));
    // setCity(String(address.split(",")[(address.split(",").length - 3)]).trim(" "));
    // setArea(String(address.split(",")[(address.split(",").length - 4)]).trim(" "))
  };

  return (
    <div>
      <ResponsiveDrawer {...props} />
      <TopHeaderScreen {...props} />
      <main
        onClick={() => {
          props.setNotificationShow(false);
        }}
        className={classes.homeSreenMainContent}
        id="homepage-main"
      >
        {/* <Link className={classes.backBtn} to="/reviewvolunteer">
                    <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                    <span style={{ paddingLeft: 0 }}> Back</span>
                </Link> */}
        <Button
          onClick={() => navigate(-1)}
          className={classes.backBtn}
          style={{ justifyContent: "left" }}
        >
          <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
          <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
        </Button>
        <span
          style={{
            fontSize: 25,
            fontWeight: "600",
            marginBottom: 15,
            marginTop: 5,
            marginLeft: "5px",
          }}
        >
          Edit User Profile
        </span>
        <form autoComplete="off">
          {/* <div style={{ border: "1px solid #dadce0", borderRadius: 8, padding: "13px 30px 40px 30px" }}> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
            <div className={classes.formGroup}>
              <label className={classes.label}>First Name</label>
              <input
                // value={volunteerDetails?.fullname}
                value={volunteerDetails?.firstname}
                type="text"
                className={classes.input}
                placeholder="Your answer"
                onChange={(e) => {
                  handleFieldChange("firstname", e);
                }}
              />
            </div>
            {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
            <div className={classes.formGroup}>
              <label className={classes.label}>Last Name</label>
              <input
                // value={volunteerDetails?.fullname}
                value={volunteerDetails?.lastname}
                type="text"
                className={classes.input}
                placeholder="Your answer"
                onChange={(e) => {
                  handleFieldChange("lastname", e);
                }}
              />
            </div>
            {/* <div className={classes.formGroup}>
                                <label htmlFor="create-yaad--title" className={classes.label}>
                                    Date of Birth
                                </label>
                                <input
                                    disabled
                                    value={volunteerDetails?.dob}
                                    type="text"
                                    className={classes.input}
                                />
                            </div> */}

            {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
            <div className={classes.formGroup}>
              <label className={classes.label}>Date of Birth</label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={volunteerDetails?.dob}
                  onChange={(newValue) => {
                    handleFieldChange("dob", newValue);
                  }}
                  // maxDate={new Date()}
                  maxDate={subtractYears(5)}
                  renderInput={(params) => (
                    <TextField
                      type="text"
                      onKeyDown={(e) => {
                        handleKeypress(e);
                      }}
                      style={{
                        width: "100%",
                        height: 56,
                        color: "#949494",
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            {/* {JSON.stringify(volunteerDetails)} */}

            {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
            <div className={classes.formGroup}>
              <label className={classes.label}>Gender</label>
              <Select
                style={{
                  width: "100%",
                  fontSize: 14,
                  height: 56,

                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                value={volunteerDetails?.gender}
                onChange={(e) => handleFieldChange("gender", e)}
              >
                {Gender.map((item, index) => (
                  <MenuItem key={item.value} value={item?.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </div>

            {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
            <div className={classes.formGroup}>
              <label className={classes.label}>Blood Group</label>
              <Select
                style={{
                  width: "100%",
                  fontSize: 14,
                  height: 56,

                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                value={volunteerDetails?.bloodGroup}
                onChange={(e) => handleFieldChange("bloodGroup", e)}
              >
                {BloodGroup.map((item, index) => (
                  <MenuItem key={item.value} value={item?.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </div>

            
            <div className={classes.formGroup}>
              <label className={classes.label}>Email ID</label>
              <input
                //disabled
                value={volunteerDetails?.emailId}
                type="text"
                className={classes.emailinput}
                onChange={(e) => handleFieldChange("emailId", e)}
              />
            </div>
            {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Phone no
              </label>
              <input
                disabled
                value={volunteerDetails?.phoneNumber}
                type="text"
                className={classes.input}
                onChange={(e) => handleFieldChange("phoneNumber", e)}
              />
            </div>

            {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Highest Education
              </label>
              <Select
                style={{
                  width: "100%",
                  fontSize: 14,
                  height: 56,

                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                value={volunteerDetails?.highLevelEdu}
                onChange={(e) => handleFieldChange("highLevelEdu", e)}
              >
                {educationList.map((item, index) => (
                  <MenuItem key={item.value} value={item?.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            {/* {JSON.stringify(volunteerDetails?.hoursPerWeek)} */}

            {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
            <div className={classes.formGroup}>
              <label className={classes.label}>Hours Per Week</label>
              <Select
                style={{
                  width: "100%",
                  fontSize: 14,
                  height: 56,

                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                value={volunteerDetails?.hoursPerWeek}
                onChange={(e) => handleFieldChange("hoursPerWeek", e)}
              >
                {hoursEveryWeek.map((item, index) => (
                  <MenuItem key={item.value} value={item?.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </div>
            {/* <div className={classes.formGroup}>
                                <label htmlFor="create-yaad--title" className={classes.label}>
                                    Prefer Day
                                </label>
                                <input
                                    disabled
                                    value={volunteerDetails?.preferDays}
                                    type="text"
                                    accept="image/*"
                                    id="create-yaad--title"
                                    className={classes.input}
                                />
                            </div> */}
            {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
            <div className={classes.formGroup}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Prefer Day
              </label>
              <Select
                style={{
                  width: "100%",
                  fontSize: 14,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                value={volunteerDetails?.preferDays}
                onChange={(e) => handleFieldChange("preferDays", e)}
              >
                {preferWorkingDays.map((item, index) => (
                  <MenuItem key={item.value} value={item?.value}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </div>

            {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
            <div className={classes.formGroup}>
              <label className={classes.label}> Organization</label>
              <Select
                style={{
                  width: "100%",
                  fontSize: 14,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                value={volunteerDetails?.organization}
                onChange={(e) => {
                  handleFieldChange("organization", e);
                }}
              >
                {org?.map((item, index) => (
                  item?.label === "sponsor" ? (
                  <MenuItem key={item.name} value={item.name}>
                    {item.name} <PaidIcon />
                  </MenuItem>
                  ):(
                  <MenuItem key={item.name} value={item.name}>
                    {item.name}
                  </MenuItem>
                  )
                ))}

                {/* {organizationListSuccess &&
                    organizationList?.map((item, index) => (
                        item?.label === "sponsor" ? (
                        <MenuItem style={{ fontSize: 16}} value={item.name}>
                            {item.name} <PaidIcon />
                        </MenuItem>
                        ): (
                        <MenuItem style={{ fontSize: 16 }} value={item.name}>
                            {item.name}
                        </MenuItem>
                        )
                    
                ))} */}
              </Select>
            </div>

            {/* <div className={classes.formGroup} style={{ width: "25%" }}> */}
            <div className={classes.formGroup}>
              <label className={classes.label}>Organisation Role</label>
              <Select
                style={{
                  width: "100%",
                  fontSize: 14,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                value={volunteerDetails?.orgRole.toLowerCase()}
                onChange={(e) => {
                  handleFieldChange("orgRole", e);
                }}
              >
                {orgRole?.map((item, index) => (
                  <MenuItem key={item.role} value={item.role}>
                    {item.role}
                  </MenuItem>
                ))}
              </Select>
              {/* {JSON.stringify(volunteerDetails)} */}
            </div>

            <div className={classes.formGroup2}>
              <label htmlFor="create-yaad--title" className={classes.label2}>
                Edit your location{" "}
                <span
                  style={{
                    paddingLeft: 0,
                    fontSize: 16,
                    fontWeight: 300,
                    fontStyle: "italic",
                  }}
                >
                  {" "}
                  (Search for location, select and click on Confirm.)
                </span>
              </label>
              {/* <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>Enter your location </span>  */}

              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div style={{ padding: "25px 0px 0px 0px" }}>
                    {/* <input {...getInputProps({ placeholder: "Type Address", autoFocus: true, endAdornment:<SearchIcon />  })} /> */}
                    <TextField
                      // onChange={handleChange1}
                      // onChange={(e) => setcorrespendeceaddress(e.target.value)}
                      // onFilled={(e) => { handletextentered(e) }}
                      // onBlur={(e) => { handletextentered(e) }}
                      // onFocus={(e) => { handletextentered(e) }}
                      value={correspondenceaddress}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      // onBlur={() => {
                      //     if (!country) {
                      //         setCountryError(true);
                      //     } else {
                      //         setCountryError(false);
                      //     }
                      // }}

                      {...getInputProps({
                        placeholder: "Search for address..",
                        autoFocus: false,
                      })}
                    />
                    <div> {loading ? <div>...loading...</div> : null}</div>
                    {suggestions.map((suggestion) => {
                      const style = {
                        backgroundColor: suggestion.active ? "blue" : "white",
                      };
                      // console.log("Hi");
                      return (
                        <div {...getSuggestionItemProps(suggestion, { style })}>
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                )}
              </PlacesAutocomplete>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                width: "100%",
                transition:
                  "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
                backgroundColor: "#fff",
              }}
            >
              <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                <label htmlFor="create-yaad--title" className={classes.label1}>
                  Country
                  {/* <RedStar /> */}
                </label>
                {searchSelect
                  ? address.split(",")[address.split(",").length - 1]
                  : volunteerDetails?.country}
              </div>

              <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                <label htmlFor="create-yaad--title" className={classes.label1}>
                  State
                  {/* <RedStar /> */}
                </label>
                {searchSelect
                  ? address.split(",")[address.split(",").length - 2]
                  : volunteerDetails?.state}
              </div>

              <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                <label htmlFor="create-yaad--title" className={classes.label1}>
                  City/Town/Village
                  {/* <RedStar /> */}
                </label>
                {searchSelect
                  ? address.split(",")[address.split(",").length - 3]
                  : volunteerDetails?.city}
              </div>

              <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 customFieldWidth">
                <label htmlFor="create-yaad--title" className={classes.label1}>
                  Area
                  {/* <RedStar /> */}
                </label>
                {searchSelect
                  ? address.split(",")[address.split(",").length - 4]
                  : volunteerDetails?.area}
              </div>
            </div>
          </div>
          <div
            style={{
              // display: "flex",
              // flexDirection: "row",
              // flexWrap: "wrap",
              // width:"100%",
              transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
              backgroundColor: "#fff",
            }}
          >
            <div style={{ textAlign: "center", padding: "20px 0px 0px 0px" }}>
              <Button
                variant="contained"
                onClick={() => updatelocation(address)}
                // className="btn save-btn"
                disableElevation
                style={{
                  // marginTop: 1,
                  width: "86px",
                  height: "35px",
                  background: "#f5eceb",
                  // borderRadius: 20,
                  // color: "#fff",
                  color: "green",
                  fontSize: 16,
                  // borderBlockColor: "orange",
                  // borderBlock: 5,
                  fontFamily: "Poppins !important",
                }}
              >
                Confirm
              </Button>
              {added === "yes" ? (
                <DoneIcon style={{ color: "green", fontSize: "30px" }} />
              ) : (
                ""
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
              transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
              backgroundColor: "#fff",
            }}
          >
            <div className={classes.formGroup} style={{ width: "100%" }}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                House/Door/Flat No.
              </label>
              <input
                // disabled
                value={volunteerDetails?.correspondenceAddress}
                type="text"
                accept="image/*"
                id="create-yaad--title"
                className={classes.input}
                onChange={(e) => handleFieldChange("correspondenceAddress", e)}
              />
            </div>
          </div>
        </form>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => handleSave()}
            style={{
              marginTop: 8,
              // marginLeft:600,
              width: "200px",
              background: "#E88A00",
              borderRadius: 20,
              color: "#fff",
              fontFamily: "Poppins !important",
              alignSelf: "center",
            }}
          >
            <span>Save</span>
          </Button>

          <Button
            onClick={() => navigate(-1)}
            style={{
              marginTop: 8,
              // marginLeft: 600,
              width: "200px",
              background: "grey",
              borderRadius: 20,
              color: "#fff",
              fontFamily: "Poppins !important",
              alignSelf: "center",
            }}
          >
            <span>Cancel</span>
          </Button>
        </div>
        {profileSuccess && (
          <span
            style={{ color: "green", textAlign: "center", display: "block" }}
          >
            {response?.message}
          </span>
        )}
        {!profileSuccess && (
          // <span style={{ color: "red" }}>Error occured</span>
          <span style={{ color: "red", textAlign: "center", display: "block" }}>
            {response?.message}
          </span>
        )}
        {errorOnSave && (
          <span style={{ color: "red", textAlign: "center", display: "block" }}>
            {errorOnSave}
          </span>
        )}
        {/* {response && <span>{response?.message}</span>} */}
      </main>
      <CopyRight />
    </div>
  );
}
export default VolunteerEditScreen;
