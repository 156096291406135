/** @format */

const ADD_CAMP_REWARDS_FETCHING_DATA = "ADD_CAMP_REWARDS_FETCHING_DATA";
const ADD_CAMP_REWARDS_FETCHING_DATA_FAILURE = "ADD_CAMP_REWARDS_FETCHING_DATA_FAILURE";
const ADD_CAMP_REWARDS_FETCHING_DATA_RESET = "ADD_CAMP_REWARDS_FETCHING_DATA_RESET";
const ADD_CAMP_REWARDS_FETCHING_DATA_SUCCESS = "ADD_CAMP_REWARDS_FETCHING_DATA_SUCCESS";
const ADD_CAMP_REWARDS_FETCHING_DATA_ATTEMPT = "ADD_CAMP_REWARDS_FETCHING_DATA_ATTEMPT";
const addCampRewards = (payload) => ({
  type: ADD_CAMP_REWARDS_FETCHING_DATA_ATTEMPT,
  payload: payload,
});

const addCampRewardsSuccess = () => ({
  type: ADD_CAMP_REWARDS_FETCHING_DATA_SUCCESS,
});
const addCampRewardsReset = () => ({
  type: ADD_CAMP_REWARDS_FETCHING_DATA_RESET,
});

export {
    ADD_CAMP_REWARDS_FETCHING_DATA,
    ADD_CAMP_REWARDS_FETCHING_DATA_FAILURE,
    ADD_CAMP_REWARDS_FETCHING_DATA_RESET,
    ADD_CAMP_REWARDS_FETCHING_DATA_SUCCESS,
    ADD_CAMP_REWARDS_FETCHING_DATA_ATTEMPT,
    addCampRewards,
    addCampRewardsSuccess,
    addCampRewardsReset,
};