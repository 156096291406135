/** @format */

const REGISTER_FETCHING_DATA = 'REGISTER_FETCHING_DATA';
const REGISTER_FETCHING_DATA_FAILURE = 'REGISTER_FETCHING_DATA_FAILURE';
const REGISTER_FETCHING_DATA_RESET = 'REGISTER_FETCHING_DATA_RESET';
const REGISTER_FETCHING_DATA_SUCCESS = 'REGISTER_FETCHING_DATA_SUCCESS';
const REGISTER_FETCHING_DATA_ATTEMPT = 'REGISTER_FETCHING_DATA_ATTEMPT';
const registerUser = payload => ({
	type: REGISTER_FETCHING_DATA_ATTEMPT,
	payload: payload,
});

const registerSuccess = () => ({
	type: REGISTER_FETCHING_DATA_SUCCESS,
});
const registerReset = () => ({
	type: REGISTER_FETCHING_DATA_RESET,
});

export { REGISTER_FETCHING_DATA, REGISTER_FETCHING_DATA_FAILURE, REGISTER_FETCHING_DATA_RESET, REGISTER_FETCHING_DATA_SUCCESS, REGISTER_FETCHING_DATA_ATTEMPT, registerUser, registerSuccess, registerReset };
