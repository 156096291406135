/** @format */

import { call, put } from "redux-saga/effects";
import { get } from "lodash";

import {
  DELETE_KTCUSTOMERTRN_FETCHING_DATA,
  DELETE_KTCUSTOMERTRN_FETCHING_DATA_FAILURE,
  DELETE_KTCUSTOMERTRN_FETCHING_DATA_SUCCESS,

} from "../action/DeleteAction";
import { Api } from "../../services";

const myAPI = new Api();


export function* deleteKTCustomerTRN(action) {
 
  yield put({
    type: DELETE_KTCUSTOMERTRN_FETCHING_DATA,
  });

  try {
    const response = yield call(myAPI.deleteKTCustomerTRN, action.payload);
    const payload = get(response, "data");
    yield put({
      type: DELETE_KTCUSTOMERTRN_FETCHING_DATA_SUCCESS,
      payload: response,
    });
  } catch (e) {
    yield put({ type: DELETE_KTCUSTOMERTRN_FETCHING_DATA_FAILURE });
    // alert("Validation Failed");
  }
}
