import * as React from "react";
import FormControl from "@mui/material/FormControl";
import {
  Checkbox,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import {
  Avatar,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from 'multiselect-react-dropdown';

// import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import {
  focusAreaList,
  focusAreaDict,
  registeredAs,
  currencyType,
} from "../../utils/dict";

import {
  onboardActivityReset,
  onboardBulkReset,
  onboardCampaign,
  onboardCampaignReset,
  onboardIndividualReset,
  onboardOrganization,
  onboardOrganizationReset,
} from "../../redux/action/OnboardAction";
import {
  updateCampaign,
  updateCampaignReset,
} from "../../redux/action/UpdateAction";

import CallingCode from "../../services/CallingCode";
import { fetchCityAndState } from "../../services/commonServices";
import ResponsiveDrawer from "../../components/Header";
import { CopyRight } from "../../components/CopyRight";
import moment from "moment";
import axios from "axios";
import { API_URL } from "../../services/Constants";
import { getOrganization } from "../../redux/action/getOrganization";
import {
  activityDuration,
  campaignParticipate,
  campaignType,
  restrictedCountry,
} from "../../utils/dict";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AddCircleOutlineOutlined, RemoveCircleOutlineOutlined, Square } from "@mui/icons-material";
import { getCity } from "../../redux/action/cityAction";
import { MenuProps, states } from "../../utils/constants";
import ErrorMessage from "../../components/ErrorMessage";
import _ from "lodash";
import RedStar from "../../components/RedStar";
import TopHeaderScreen from "../../components/TopHeader1";
import { Link } from "react-router-dom";
import { getCampProfileLogo } from "../../redux/action/campLogoAction";
import IconButton from "@mui/material/IconButton";
import { getCampProfileBanner } from "../../redux/action/campBannerAction";
import { getRewardTitle } from "../../redux/action/RewardDetailAction";
// import { LocalConvenienceStoreOutlined } from "@material-ui/icons";
import { getCampProfileVideo } from "../../redux/action/campVideoAction";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import DoneIcon from '@mui/icons-material/Done';
import AsyncSelect from 'react-select/async';

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "80px 30px 100px 330px",
    },
  },

  input: {
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    boxShadow: "0px 3px 6px #00000029",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {

    },

    display: "block",
    display: "flex",
    //fontSize: 16,
    fontSize: "16px",
    marginTop: 16,
    padding: 10,
    fontWeight: "500",
    lineHeight: "18px",
    minHeight: 50,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  label1: {
    "& .css-u4tvz2-MuiFormLabel-root": {

    },

    fontSize: 14,
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroupFull: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%"
    },
    [theme.breakpoints.only("md")]: {
      width: "100%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup1: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "25%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup2: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",
    flexDirection: "column",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "75px 0px 0px 16px",
    // pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "100%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "100%"
    },
    [theme.breakpoints.only("md")]: {
      width: "100%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup3: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "20%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup4: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",
    // alignItems: "centerAlign",
    textAlign: "right",

    borderRadius: "8px",
    marginBottom: "4px",
    // padding: "0 16px 0px 300px",
    padding: "24px 0px 0px 0px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "50%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "25%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup5: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",


    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 4px 0px 2px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "20%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "20%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup6: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",


    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 4px 0px 18px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "15%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "20%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  formGroup7: {
    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    backgroundColor: "#fff",
    // alignItems: "centerAlign",
    textAlign: "left",

    borderRadius: "8px",
    marginBottom: "4px",
    // padding: "0 16px 0px 300px",
    padding: "24px 0px 0px 20px",
    pageBreakInside: "avoid",
    [theme.breakpoints.only("xl")]: {
      width: "50%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "25%"
    },
    [theme.breakpoints.only("md")]: {
      width: "50%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%"
    },
  },
  large: {
    width: "150px !important",
    height: "150px !important",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",

  },
  logobutton: {
    width: "8%",
    border: "none",
    padding: 10,
    // display: "block",
    display: "flex",
    // borderRadius: 6,
    height: 40,
    // boxShadow: "0px 3px 6px #00000029",
    // color: "#949494",
    color: "white",
    [theme.breakpoints.down("xl")]: {
      fontSize: 15,
      width:"12%",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: 15,
      width:"15%",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
      width:"13%",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 15,
      width:"20%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      width:"30%",
    },
  },
}));

function EditCampaignScreen1(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [image, setImage] = React.useState(null);
  const [banner, setBanner] = React.useState(null);
  const [video, setVideo] = React.useState(null);
  const inputRef = React.useRef(null);
  const videoRef = React.useRef(null);
  const navigate = useNavigate();
  const [address, setAddress] = React.useState("");
  const [oldaddress, setOldAddress] = React.useState("");

  const [newField, setNewField] = React.useState(false);
  const [newRewardField, setNewRewardField] = React.useState(false);
  const [rewardDetailResponseEffect, setrewardDetailResponseEffect] = React.useState({});

  // const [location, setLocation] = React.useState([
  //   { country: "", state: "", city: "", area: "", cityList: "" },
  // ]);

  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "", added: "" },
  ]);

  const [rewardtitle, setRewardtitle] = React.useState([
    { rewardtype: "", title: "", subtitle: "", sponsor: "", points: "", titleList: "" },
  ]);

  const [focusArea, setFocusArea] = React.useState([
    {
      rewards: "Certificate of Participation",
    },
    {
      rewards: "Discount Coupons",
    },
    { rewards: "Gift Cards" },
    { rewards: "Social Media Recognition" },
  ]);



  // const skillArea= [
  //   { skill: "Networking" },
  //   { skill: "Fundraising" },
  //   { skill: "Operational Support" },
  //   { skill: "Content Writing / Editing" },
  //   { skill: "Social Media and Digital marketing" },
  //   { skill: "Team Management" },
  //   { skill: "Painting / Drawing / Sculpture making" },
  //   { skill: "Photography" },
  //   { skill: "Video Editing" },
  //   { skill: "Coaching / Mentoring" },
  //   { skill: "pp Development / Coding" },

  // ];

  const [skill, setSkill] = React.useState([
    { skill: "Networking", },
    { skill: "Fundraising", },
    { skill: "Operational Support" },
    { skill: "Content Writing / Editing" },
    { skill: "Social Media and Digital marketing" },
    { skill: "Team Management" },
    { skill: "Painting / Drawing / Sculpture making" },
    { skill: "Photography" },
    { skill: "Video Editing" },
    { skill: "Coaching / Mentoring" },
    { skill: "pp Development / Coding" },

  ]);
  const [focusError, setFocusError] = React.useState(false);

  const [areas, setAreas] = React.useState([]);
  const [cityResponse, setCityResponse] = React.useState({});
  const [notificationShow, setNotificationShow] = React.useState(false);
  const [activityList, setActivityList] = React.useState(undefined);
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    setAddress(results[0]["formatted_address"]);

  }
  const [campaignValues, setCampaignValues] = React.useState({
    titleOfCampaign: "",
    descriptionOfCampaign: "",
    typeOfCampaign: "",
    volunteerCount: "",
    // impact: "",
    startDate: new Date(),
    endDate: new Date(),
    durationOfCampaign: "",
    rewardType: "",
    goal: "",
    whattodo: "",
    guidelines: "",
    campaignType: "",
    hourOfParticipation: "",
    pointsToCurrency: "",
    totalBudget: "",
    pointsPerReferral: "",
    budgetPointForReferral: "",
    socialMediaMessage: "",
    campaignLogo: "",
    campaignVideo: "",
    visibility: "",
    currencyType: "",
  });
  const [campaignValuesError, setCampaignValuesError] = React.useState({
    titleOfCampaignError: "",
    descriptionOfCampaignError: "",
    typeOfCampaignError: "",
    subtypeOfCampaignError: "",
    volunteerCountError: "",
    impactError: "",
    startDate: new Date(),
    endDate: new Date(),
    durationOfCampaignError: "",
    rewardTypeError: "",
    pointsCampaignError: "",
    titlerewardCampaignError: "",
    subtitlerewardCampaignError: "",
    goalError: "",
    campaignTypeError: "",
    hourOfParticipationError: "",
    pointsToCurrencyError: "",
    totalBudgetError: "",
    pointsPerReferralError: "",
    budgetPointForReferralError: "",
    socialMediaMessageError: "",
    campaignLogoError: "",
    campaignVideoError: "",
    visibilityError: "",
    currencyTypeError: "",
    selectedSkillsError: "",
  });

  const [selected, setSelected] = React.useState([]);
  const [selected1, setSelected1] = React.useState([]);
  const [selectedSponsor, setSelectedSponsor] = React.useState([]);
  const [campCategory, setCampCategory] = React.useState([]);
  const [campSubCategory, setCampSubCategory] = React.useState([]);
  const [rewardTypeList, setRewardTypeList] = React.useState(null);
  const [skillselected, setSkillSelected] = React.useState([]);
  // const {
  //   isCampaignSuccess,
  //   isCampaignFetching,
  //   campaignResponse,
  //   isCampaignError,
  // } = useSelector((state) => state.onBoard);
  const {
    isCampaignSuccess,
    isCampaignFetching,
    campaignResponse,
    isCampaignError,
  } = useSelector((state) => state.updateCampaign);

  const { isSuccess: citiesSuccess, response: citiesResponse } = useSelector(
    (state) => state.cities
  );

  const { titleSuccess: rewardDetailSuccess, titleResponse: rewardDetailResponse } = useSelector(
    (state) => state.getRewardTitle
  );

  function subtractYears(numOfYears, date = new Date()) {
    date.setFullYear(date.getFullYear() - numOfYears);

    return date;
  }

  /* For profile image handling */

  const [loading, setLoading] = React.useState(false);
  const [loadingBanner, setLoadingBanner] = React.useState(false);
  const [loadingVideo, setLoadingVideo] = React.useState(false);
  const [videoLoaded, setVideoLoaded] = React.useState(0);
  var videoLoadedCount = 0;
  const [sponsor, setSponsor] = React.useState([]);
  const [show, setShow] = React.useState(false);

  const {
    isSuccess: profileImageSuccess,
    response: profileImageResponse,
    isError: profileImageError,
  } = useSelector((state) => state.profileImage);

  const {
    isSuccess: campLogoSuccess,
    response: campLogoResponse,
    isError: campLogoError,
  } = useSelector((state) => state.getCampProfileLogo);

  const {
    isSuccess: campBannerSuccess,
    response: campBannerResponse,
    isError: campBannerError,
  } = useSelector((state) => state.getCampProfileBanner);

  const {
    isSuccess: campVideoSuccess,
    response: campVideoResponse,
    isError: campVideoError,
  } = useSelector((state) => state.getCampProfileVideo);

  const handleChangeFile = (e) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("logo", e.target.files[0]);
    dispatch(
      getCampProfileLogo({ campid: locationPath?.state?.campid, userId: localStorage.getItem("userid"), formData })

    );

    setImage(e.target.files[0]);
  };
  const handleBannerFile = (e) => {
    setLoadingBanner(true);
    let formData = new FormData();
    formData.append("banner", e.target.files[0]);

    dispatch(
      getCampProfileBanner({ campid: locationPath?.state?.campid, userId: localStorage.getItem("userid"), formData })
    );
    setBanner(e.target.files[0]);
  };

  const handleChangeVideoFile = (e) => {
    setLoadingVideo(true);
    let formData = new FormData();
    formData.append("video", e.target.files[0]);
    dispatch(
      getCampProfileVideo({ campid: locationPath?.state?.campid, userId: localStorage.getItem("userid"), formData })

    );

    setVideo(e.target.files[0]);
    videoLoadedCount = videoLoadedCount + 1;
  };

  const handleChangeRewards = (event, index) => {
    const value = event.target.value;

    setSelected([...selected, value[value?.length - 1]]);
  };

  // const handleChangeRewards1 = (id, event, field, index) => {
  //   // const value = event.tgetRewardDetailListarget.value;
  //   console.log("calling handleChangeRewards1");
  //   console.log(rewardDetailResponse);
    
  //   console.log(rewardDetailResponse.map((item, index) => (item.title)));
  //   console.log(rewardDetailResponse?.[0]?.title);
  //   console.log(rewardDetailResponse?.[0]?.subtitle);
  //   console.log(rewardDetailResponse.map((item, index) => (item.subtitle)));
  //   var rewardtypecount = 0;
  //   var titlecount = 0;
  //   var subtitlecount = 0;
  //   var sponsorcount = 0;
  //   var pointscount = 0;
  //   const value = [...rewardtitle];
  //   if (field === 'rewardtype') {
  //     value[index]["titleList"] = rewardDetailResponseEffect;
  //     console.log(value[index]["titleList"]);
  //     rewardtypecount  = 1
  //   }
        
  //   if (field === 'sponsor'){
  //     value[index][field] = event;
  //     sponsorcount = 1;
  //   } 

  //   if (field === 'points'){
  //     value[index][field] = event;
  //     pointscount = 1;
  //   } 

    
  //   else {
  //     value[index][field] = event.target.value;
  //   }
  //   if (field === 'rewardtype' && rewardDetailSuccess) {
  //     setRewardtitle("");
  //   } else {
    
  //   setRewardtitle(value);
  //   }

  //   // setSelected([...selected, value[value?.length - 1]]);
  // };

  const handleChangeRewards1 = (id, event, field, index) => {
    
    const value = [...rewardtitle];
    if (field === 'rewardtype') {
      value[index]["titleList"] = rewardDetailResponseEffect;
      // console.log(value[index]["titleList"]);
      
    } if (field === 'sponsor'){
      value[index][field] = event;
    }  else {
    
      value[index][field] = event.target.value;
    }
  

    setRewardtitle(value);
    

    // setSelected([...selected, value[value?.length - 1]]);
  };

  const handleChangeSkill = (event, index) => {
    const value = event.target.value;

    setSelected1([...selected1, value[value?.length - 1]]);
  };

  const handleOnboardCampaign = () => {
 
    if (!campaignValues?.titleOfCampaign) {
      setCampaignValuesError({
        ...campaignValuesError,
        titleOfCampaignError: true,
      });
      dispatch(updateCampaignReset());
      window.scrollTo(0, 200);

    }
    else if ((selectedSkills.length === 0)) {
      setCampaignValuesError({
        ...campaignValuesError,
        selectedSkillsError: true,
      });
      dispatch(updateCampaignReset());
      window.scrollTo(200, 600);

    } 
    else if (!campaignValues?.descriptionOfCampaign) {
      setCampaignValuesError({
        ...campaignValuesError,
        descriptionOfCampaignError: true,
      });
      dispatch(updateCampaignReset());
      window.scrollTo(0, 200);

    } else if (!campaignValues?.goal) {
      setCampaignValuesError({
        ...campaignValuesError,
        goalError: true,
        
      });
      dispatch(updateCampaignReset());
      window.scrollTo(0, 200);
     }  else if (!campaignValues?.whattodo) {
      setCampaignValuesError({
        ...campaignValuesError,
        whattodoError: true,
        
      });
      dispatch(updateCampaignReset());
      window.scrollTo(0, 200);
     } else if (!campaignValues?.guidelines) {
      setCampaignValuesError({
        ...campaignValuesError,
        guidelinesError: true,
        
      });
      dispatch(updateCampaignReset());
      window.scrollTo(0, 200);
     } else if (!campaignValues?.socialMediaMessage) {
      setCampaignValuesError({
        ...campaignValuesError,
        socialMediaMessageError: true,
      });
      dispatch(updateCampaignReset());
      window.scrollTo(0, 800);
    }  
    // else if (campaignValuesError?.rewardTypeError === true) {
      
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     rewardTypeError: true,
    //   });
    //   dispatch(updateCampaignReset());
    //   window.scrollTo(0, 1000);
    // }  else if (campaignValuesError?.pointsCampaignError === true) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     pointsCampaignError: true,
    //   });
    //   dispatch(updateCampaignReset());
    //   window.scrollTo(0, 1000);
    // } else if (campaignValuesError?.titlerewardCampaignError === true) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     titlerewardCampaignError: true,
    //   });
    //   dispatch(updateCampaignReset());
    //   window.scrollTo(0, 1000);
    // } else if (campaignValuesError?.subtitlerewardCampaignError === true) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     subtitlerewardCampaignError: true,
    //   });
    //   dispatch(updateCampaignReset());
    //   window.scrollTo(0, 1000);
    // } 
    else if (_.isEmpty(selectedcampcat)) {
      setCampaignValuesError({
        ...campaignValuesError,
        typeOfCampaignError: true,
      });
      dispatch(updateCampaignReset());
      window.scrollTo(0, 400);
    } else if (_.isEmpty(selectedcampsubcat)) {
      setCampaignValuesError({
        ...campaignValuesError,
        subtypeOfCampaignError: true,
      });
      dispatch(updateCampaignReset());
      window.scrollTo(0, 400);
    } else if (!campaignValues?.volunteerCount || campaignValues?.volunteerCount == "-1") {
      setCampaignValuesError({
        ...campaignValuesError,
        volunteerCountError: true,
      });
      dispatch(updateCampaignReset());
      window.scrollTo(0, 400);
    }  else if (!campaignValues?.hourOfParticipation || campaignValues?.hourOfParticipation == "-1") {
      setCampaignValuesError({
        ...campaignValuesError,
        hourOfParticipationError: true,
      });
      dispatch(updateCampaignReset());
      window.scrollTo(0, 800);
    }  else if (!campaignValues?.totalBudget) {
      setCampaignValuesError({
        ...campaignValuesError,
        totalBudgetError: true,
      });
      dispatch(updateCampaignReset());
      window.scrollTo(0, 1200);
    }  
    
    else if (campaignValuesError?.locationError) {
      setCampaignValuesError({
        ...campaignValuesError,
        locationError: true,
      });
      dispatch(updateCampaignReset());
      window.scrollTo(0, 1600);
    }  

    
    
    // }
    // else if (selected.filter((item) => item).length === 0) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     rewardTypeError: true,
    //   });
    //   window.scrollTo(0, 0);
    // }  

    // else if (selected1.filter((item) => item).length === 0) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     setSkillError: true,
    //   });
    //   window.scrollTo(0, 0);
    // }
    
    // else if (!campaignValues?.campaignLogo) {
    //   setCampaignValuesError({
    //     ...campaignValuesError,
    //     campaignLogoError: true,
    //   });
    //   window.scrollTo(0, 0);
    // } else {
    // dispatch(updateCampaignReset());
    else {
      dispatch(updateCampaignReset());
      
    let formData = new FormData();

    formData.append("type", JSON.stringify(selectedcampcat));
    formData.append("subtype", JSON.stringify(selectedcampsubcat));
    formData.append("title", campaignValues?.titleOfCampaign);
    formData.append("desc", campaignValues?.descriptionOfCampaign);
    formData.append("location", JSON.stringify(location));
    formData.append("hourofparticipation", campaignValues?.hourOfParticipation);
    formData.append("pointtocurrency", campaignValues?.pointsToCurrency);
    formData.append("totalbudget", campaignValues?.totalBudget);
    formData.append("pointsperreferral", campaignValues?.pointsPerReferral);
    formData.append("budgetpointforreferral", campaignValues?.budgetPointForReferral);
    formData.append("campaigntype", campaignValues?.campaignType);
    formData.append("visibility", campaignValues?.visibility);
    formData.append("currencytype", campaignValues?.currencyType);
    formData.append("goal", campaignValues?.goal);
    formData.append("whattodo", campaignValues?.whattodo);
    formData.append("guidelines", campaignValues?.guidelines);
    // formData.append("rewardstobeearned", JSON.stringify(selectedRewards));
    formData.append("rewardstobeearned", JSON.stringify(rewardtitle));
    // formData.append("skill", JSON.stringify(selected1));
    formData.append("skill", JSON.stringify(selectedSkills))
    
    // if (!campaignValues?.campaignLogo) {
    //   formData.append("logo", locationPath?.state?.logo);

    // } else {
    //   formData.append("logo", campaignValues?.campaignLogo);
    // }
    // if (!campaignValues?.campaignVideo) {
    //   formData.append("logo", locationPath?.state?.video);
    // } else {
    //   formData.append("video", campaignValues?.campaignVideo);

    // }
    //formData.append("impact", campaignValues?.impact);
    formData.append("video", locationPath?.state?.video);
    formData.append("targetvol", campaignValues?.volunteerCount);
    // formData.append("startdate", moment(campaignValues?.startDate, "YYYY-MM-DD") + " 00:00:00");
    // formData.append("startdate", campaignValues?.startDate + " 00:00:00");
    // formData.append("enddate", campaignValues?.endDate + " 00:00:00");
    formData.append("startdate", moment(campaignValues?.startDate).format("yyyy-MM-DD") + " 00:00:00");
    formData.append("enddate", moment(campaignValues?.endDate).format("yyyy-MM-DD") + " 00:00:00");

    // formData.append("enddate", moment(campaignValues?.endDate, "YYYY-MM-DD") + " 00:00:00");
    //formData.append("timeframe", campaignValues?.durationOfCampaign);
    formData.append("socialmediamsg", campaignValues?.socialMediaMessage);
    dispatch(
      updateCampaign({ campid: campaignValues?.campid, formData })
      // onboardCampaign({ userid: localStorage.getItem("userid"), formData })
    );
    }
    
  };
  
  const handleCampaignFieldChange = (field, e) => {
    (isCampaignError || isCampaignSuccess) && dispatch(onboardCampaignReset());
    setCampaignValues({
      ...campaignValues,
      [field]:
        field === "startDate" || field === "endDate"
          ? e
          : field === "campaignLogo" || field === "campaignVideo"
            // : field === "campaignLogo"
            ? e.target.files[0]
            : e.target.value,
    });
  };
  const getActivityList = async () => {
    try {
      const res = await axios.get(`/activity`);
      setActivityList(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getStateList = async (i, valll) => {
    try {
      const res = await axios.get(`/statecity/${valll}`).then((response) => {
        const values = [...location];
        values[i]["cityList"] = response?.data;
        return response?.data;
      });

    } catch (err) {
      throw err;
    }
  };

  const getRewardDetailList = async (i, valll) => {
    try {
      const res = await axios.get(`/getrewardtitle/${valll}`).then((response) => {
        const values = [...rewardtitle];
        values[i]["titleList"] = response?.data;
        return response?.data;
      });

    } catch (err) {
      throw err;
    }
  };

  const getRewardTypeList = async () => {
    try {
      const res = await axios.get(`getrewardtypes`);
      setRewardTypeList(res?.data);
      // console.log(res?.data);
      return res;
      // }
    } catch (err) {
      throw err;
    }
  };
  const getCampCategory = async () => {
    try {
      const res = await axios.get(`/camp/category`);
      // setActivityList(res?.data);
      setCampCategory(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getCampSubCategory = async () => {
    try {
      const res = await axios.get(`/camp/sub/category`);
      // setActivityList(res?.data);
      setCampSubCategory(res?.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const getSponsorList = async () => {
    try {
      const res = await axios.get(`/sponsor`).then((response) => {
        // const values = [...rewardtitle];
        
        setSponsor(response?.data)
        return response?.data;
      });

    } catch (err) {
      throw err;
    }
  };

  const unitOfCampaign = activityList?.filter(
    (item, index) => item?.activity === campaignValues?.typeOfCampaign
  );

  //  const unitOfCampaign = activityList?.filter(
  //   (item, index) => item?.activity === campaignValues?.typeOfCampaign
  // );

  // const resetFile = (event) => {
  //   inputRef.current.value = null;
  //   // inputRef.current.value = locationPath?.state?.logo;
  //   // inputRef.current.value = campaignValues?.campaignLogo;
  // videoRef.current.value = null;
  // };

  const onAddingItem = (event, option, index) => {
    const values = [...focusArea];
    values[index].isChecked = event.target.checked;
    setFocusArea(values);
  };

  const handleTextFieldCheckbox = (event, index) => {
    const values = [...focusArea];
    values[index].rewards = event.target.value;
    setFocusArea(values);
  };

  // const handleTextFieldCheckboxSkill = (event, index) => {
  //   const values = [...skill];
  //   values[index].skill = event.target.value;
  //   setFocusArea(values);
  // };

  // const handleChangeLocation = (id, e, field, index) => {
  //   const values = [...location];
  //   values[index][field] = e.target.value;
  //   setLocation(values);
  // };


  const updatelocation = (index, address) => {
    const values = [...location];
    if (!address) {
      if (values[index]["added"] === "no") {
      setCampaignValuesError({
        ...campaignValuesError,
        locationError: true,
      });
    }
      const values = [...location];
      // values[index]["country"] = "";
      // values[index]["state"] = "";
      // values[index]["city"] = "";
      // values[index]["area"] = "";
      // values[index]["added"] = "no";
    } else {
      setCampaignValuesError({
        ...campaignValuesError,
        locationError: false,
      });
      values[index]["country"] = String(address.split(",")[(address.split(",").length - 1)]).trim(" ");
      values[index]["state"] = String(address.split(",")[(address.split(",").length - 2)]).trim(" ");
      values[index]["city"] = String(address.split(",")[(address.split(",").length - 3)]).trim(" ");
      values[index]["area"] = String(address.split(",")[(address.split(",").length - 4)]).trim(" ");
      values[index]["added"] = "yes";
      if (values[index]["state"] === "undefined" && values[index]["city"] === "undefined"
        && values[index]["area"] === "undefined") {
        values[index]["added"] = "no";
        setCampaignValuesError({
          ...campaignValuesError,
          locationError: true,
        });
      }

      if (values[index]["state"] === "undefined") {
        values[index]["state"] = ""
      }
      if (values[index]["city"] === "undefined") {
        values[index]["city"] = ""
      }
      if (values[index]["area"] === "undefined") {
        values[index]["area"] = ""
      }
      if (values[index]["state"] == "" && values[index]["city"] == "" && values[index]["area"] == "") {
        setCampaignValuesError({
          ...campaignValuesError,
          locationError: true,
        });
        values[index]["added"] = "no";
        // console.log(organizationValuesError)
      } else {
        setLocation(values);
        setOldAddress(address);
      }
    }
  };


  // const handleChangeLocation = (id, e, field, index) => {
  //   const values = [...location];
  //   if (field === 'state') {
  //     values[index]["cityList"] = cityResponse;
  //   }

  //   values[index][field] = e.target.value;
  //   setLocation(values);
  // };

  // const handleAddFields = () => {
  //   setNewField(true);
  //   // setLocation([...location, { country: "", state: "", city: "", area: "" }]);
  //   setLocation([...location, { country: "", state: "", city: "", area: "", cityList: "" }]);
  // };

  const handleAddFields = () => {
 
    let errorcount = 0;
    for (let i = 0; i < location.length; i += 1) {
      if (location[i]["country"] == "" || location[i]["state"] == "" || location[i]["city"] == ""|| 
            location[i]["added"] == "no") {
              setNewField(false);
              errorcount = errorcount + 1;
      }
    }
    if (errorcount == 0) {
    setNewField(true);
    // setLocation([...location, { country: "", state: "", city: "", area: "", cityList: "" }]);
    setLocation([...location, { country: "", state: "", city: "", area: "", added: "no"}]);
    } else {
      setNewField(false);
    }
       
    // setRewardtitle([...rewardtitle, { rewardtype: "", title: "", subtitle: "", sponsor: "", titleList: "" }])
  };

  const handleRewardAddFields = () => {
    setNewRewardField(true);
    // setLocation([...location, { country: "", state: "", city: "", area: "", cityList: "" }]);
    setRewardtitle([...rewardtitle, { rewardtype: "", title: "", subtitle: "", sponsor: "", points: "", titleList: "" }])
  };

  const handleRemoveFields = (i) => {
    let newFormValues = [...location];
    newFormValues.splice(i, 1);
    setLocation(newFormValues);
  };

  const handleRewardRemoveFields = (i) => {

    let newRewardFormValues = [...rewardtitle];
    newRewardFormValues.splice(i, 1);
    setRewardtitle(newRewardFormValues);

  };


  React.useEffect(() => {
    if (campLogoSuccess || campLogoError) {
      setLoading(false);
    }
  }, [campLogoSuccess, campLogoError]);

  React.useEffect(() => {
    if (campBannerSuccess || campBannerError) {
      setLoadingBanner(false);
    }
  }, [campBannerSuccess, campBannerError]);

  React.useEffect(() => {
    if (campVideoSuccess || campVideoError) {
      setLoadingVideo(false);
      setVideoLoaded(1);
    }
  }, [campVideoSuccess, campVideoError]);

  React.useEffect(() => {
      setVideoLoaded(0);
  }, []);



  React.useEffect(() => {

    getSponsorList();
  }, []);

 

  const getAddress = (item, index) => {

    return (
      <div style={{ float: "left", width: "75%", marginTop: 50 }}>
        <form style={{ display: "flex", flexWrap: "wrap", width: "100%", float: "left" }}>
          {
            (index == location.length - 1 && location[index]?.country === "") ? (
              <div className={classes.formGroup3}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Country
                </label>
                {!(address && index > 0 && location[index]?.added == "no" && index == location.length - 1 && address == oldaddress) ? (
                  <span >{address.split(",")[(address.split(",").length - 1)]}</span>
                ) :
                  <span style={{ fontSize: 12 }}>Search location</span>
                }
              </div>
            )
              :
              <div className={classes.formGroup3}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Country
                </label>
                <span >{location[index]?.country}</span>
              </div>
          }
          {
            (index == location.length - 1 && location[index]?.state === "") ? (
              <div className={classes.formGroup3}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  State
                </label>
                {!(address && index > 0 && location[index]?.added == "no" && index == location.length - 1 && address == oldaddress) ? (
                  <span >{address.split(",")[(address.split(",").length - 2)]}</span>
                ) : 
                  <span style={{ fontSize: 12 }}>Search location</span>
                }
              </div>
            ) :
              <div className={classes.formGroup3}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  State
                </label>
                {address ? (
                  <span >{location[index]?.state}</span>
                ) : !address ? (
                  <span>{item.state}</span>
                ) : 
                <span style={{ fontSize: 12 }}>Search location</span>
                }
              </div>
          }

          {
            (index == location.length - 1 && location[index]?.state === "") ? (

              <div className={classes.formGroup3}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  City/Town
                </label>
                {!(address && index > 0 && location[index]?.added == "no" && index == location.length - 1 && address == oldaddress) ? (
                  <span >{address.split(",")[(address.split(",").length - 3)]}</span>
                ) :
                  <span style={{ fontSize: 12 }}>Search location</span>
                }
              </div>
            ) :
              <div className={classes.formGroup3}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  City/Town
                </label>
                {address ? (
                  <span >{location[index]?.city}</span>
                ) : !address ? (
                  <span>{item.city}</span>
                ) : 
                  <span style={{ fontSize: 12 }}>Search location</span>
                }
              </div>
          }

          {
            (index == location.length - 1 && location[index]?.area === "") ? (

              <div className={classes.formGroup3}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Area
                </label>
                {!(address && index > 0 && location[index]?.added == "no" && index == location.length - 1 && address == oldaddress) ? (
                  <span >{address.split(",")[(address.split(",").length - 4)]}</span>
                ) :
                  <span style={{ fontSize: 12 }}>Search location</span>
                }
              </div>
            ) :

              <div className={classes.formGroup3}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Area
                </label>

                {address ? (
                  <span >{location[index]?.area}</span>
                ) : !address ? (
                  <span>{item.area}</span>
                ) : 
                  <span style={{ fontSize: 12 }}>Search location</span>
                }
              </div>
          }
          <div className={classes.formGroup3}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Added
            </label>
            {location[index]?.added == "yes" ? (
              <DoneIcon style={{ color: "green", }} />
            ) :
              ""
            }
          </div>
          <div className={classes.formGroup4} >
            <Button
              variant="contained"
              onClick={() => updatelocation(index, address)}
              // className="btn save-btn"
              disableElevation
              style={{
                width: "50px",
                height: "35px",
                background: "#f5eceb",
                color: "green",
                fontSize: 16,
                fontFamily: "Poppins !important",

              }}

            >Add
            </Button>
          </div>
          {(index === 0) ? (

            <div className={classes.formGroup7} >
              <Button

                variant="contained"
                onClick={() => handleRemoveFields(index)}
                disableElevation
                disabled

                style={{
                  width: "92px",
                  height: "35px",
                  background: "gray",
                  color: "#DC143C",
                  fontSize: 16,
                  fontFamily: "Poppins !important",
                }}
              >
                Delete
              </Button>
            </div>
          ) :
            <div className={classes.formGroup7} >
              <Button
                variant="contained"
                onClick={() => handleRemoveFields(index)}
                // className="btn save-btn"
                disableElevation
                style={{
                  width: "92px",
                  height: "35px",
                  background: "#f5eceb",
                  color: "red",
                  fontSize: 16,
                  fontFamily: "Poppins !important",
                }}
              >
                Delete
              </Button>
            </div>
          }
        </form>
        {
          index ?
            <div style={{ float: "right", position: "relative", bottom: 44, cursor: "pointer", left: 19 }} onClick={(e) => handleRemoveFields(index)}><RemoveCircleOutlineOutlined></RemoveCircleOutlineOutlined></div>
            : <div className="handlePlus" style={{ float: "right", bottom: 45, position: "relative", left: 20, cursor: "pointer", right: "15px" }}><AddCircleOutlineOutlined
              onClick={handleAddFields}
            ></AddCircleOutlineOutlined></div>
        }
      </div>
    );
  };

  const getRewardDetails = (itemreward, index) => {
    return (
      <div style={{ float: "left", width: "75%", marginTop: 50 }}>
        <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>Reward Details</span> <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 300, fontStyle: "italic" }}> ("Area" field is optional, click on "+" icon to enter additional locations)</span>
        <form style={{ display: "flex", flexWrap: "wrap", width: "100%", float: "left" }}>
          <div className={classes.formGroup5}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Rewards Type <RedStar />
            </label>
            <Select
              style={{
                width: "100%",
                fontSize: 14,
                height: 56,
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              }}
              value={itemreward.rewardtype}
              onChange={(e) => {
                handleChangeRewards1(itemreward, e, "rewardtype", index)              
                getRewardDetailList(index, itemreward.rewardtype)
                dispatch(getRewardTitle(itemreward.rewardtype));
              }}
            >
              { rewardTypeList &&
              rewardTypeList.map((item, index) => (
                <MenuItem style={{ fontSize: 16 }} value={item.rewards}>
                  {item.rewards}
                </MenuItem>
              ))}
            </Select>
          </div>
          {index !== rewardtitle.length - 1 || (index === rewardtitle.length - 1 && rewardtitle[index].title !== "") || index === 0 ? (
            <div className={classes.formGroup5}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Reward Title <RedStar />
              </label>
              <Select
                style={{
                  width: "100%",
                  fontSize: 14,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                value={itemreward.title}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "title", index)
                 
                }}
                onBlur={(e) => {
                  // console.log(e);
                  // console.log(itemreward.title);
                  // if (!itemreward.title) {
                  //   console.log("title reward empty")
                  //   setCampaignValuesError({
                  //     ...campaignValuesError,
                  //     titlerewardCampaignError: true,
                  //   });
                  // } else {
                  //   setCampaignValuesError({
                  //     ...campaignValuesError,
                  //     titlerewardCampaignError: false,
                  //   });
                  // }
                }}
              >

                {
                  rewardDetailSuccess &&
                    rewardtitle[index]?.titleList === "" ? (
                    <MenuItem style={{ fontSize: 16 }} value={itemreward.title}>
                      {itemreward.title}
                    </MenuItem>
                  ) : rewardtitle[index]?.titleList?.length > 0 ? (
                    rewardtitle[index]?.titleList.map((item, index) => (
                      <MenuItem style={{ fontSize: 16 }} value={item.title}>
                        {item.title}
                      </MenuItem>
                    ))
                  ) :
                    <MenuItem style={{ fontSize: 16 }} value={rewardtitle[index]?.title}>
                      {rewardtitle[index]?.title}
                    </MenuItem>
                }
              </Select>

            </div>
          ) :
            <div className={classes.formGroup5}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Reward Title <RedStar />
              </label>
              <Select
                style={{
                  width: "100%",
                  fontSize: 14,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                value={itemreward.title}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "title", index)

                }}
                onBlur={(e) => {
                  // dispatch(getCity(item.state));
                }}
              >
                {rewardDetailSuccess ? (
                  rewardDetailResponse.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.title}>
                      {item.title}
                    </MenuItem>
                  ))
                ) :
                  <MenuItem style={{ fontSize: 16 }} value={itemreward.title}>
                    {itemreward.title}
                  </MenuItem>
                }
              </Select>
            </div>
          }
          {index !== rewardtitle.length - 1 || (index === rewardtitle.length - 1 && rewardtitle[index].subtitle !== "") || index === 0 ? (
            <div className={classes.formGroup5}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Reward Sub Title <RedStar />
              </label>
              <Select
                style={{
                  width: "100%",
                  fontSize: 14,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                value={itemreward.subtitle}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "subtitle", index)

                }}
                onBlur={(e) => {
                  // dispatch(getCity(item.state));
                }}
              >
                {
                  rewardDetailSuccess &&
                    rewardtitle[index]?.titleList === "" ? (
                    <MenuItem style={{ fontSize: 16 }} value={itemreward.subtitle}>
                      {itemreward.subtitle}
                    </MenuItem>
                  ) : rewardtitle[index]?.titleList?.length > 0 ? (
                    rewardtitle[index]?.titleList.map((item, index) => (
                      <MenuItem style={{ fontSize: 16 }} value={item.subtitle}>
                        {item.subtitle}
                      </MenuItem>
                    ))
                  ) :
                    <MenuItem style={{ fontSize: 16 }} value={rewardtitle[index]?.subtitle}>
                      {rewardtitle[index]?.subtitle}
                    </MenuItem>
                }
              </Select>
            </div>
          ) :
            <div className={classes.formGroup5}>
              <label htmlFor="create-yaad--title" className={classes.label}>
                Reward Sub Title <RedStar />
              </label>
              <Select
                style={{
                  width: "100%",
                  fontSize: 14,
                  height: 56,
                  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                }}
                value={itemreward.subtitle}
                onChange={(e) => {
                  handleChangeRewards1(itemreward, e, "subtitle", index)
                }}
                onBlur={(e) => { 
                }}
              >
                {rewardDetailSuccess ? (
                  rewardDetailResponse.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.subtitle}>
                      {item.subtitle}
                    </MenuItem>
                  ))
                ) :
                  <MenuItem style={{ fontSize: 16 }} value={itemreward.subtitle}>
                    {itemreward.subtitle}
                  </MenuItem>
                }
              </Select>
            </div>
          }


          <div className={classes.formGroup5}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Sponsor Name <RedStar />
            </label>
                    <Multiselect
                      displayValue="sponsor"
                      showCheckbox
                      selectedValues={itemreward?.sponsor}
                      selectionLimit={2}
                      options={sponsor}
                      onSelect={(event) => {
                        setSelectedSponsor(event);
                        
                        handleChangeRewards1(itemreward, event, "sponsor", index)
                        if (_.isEmpty(event)) {
                         
                          setCampaignValuesError({
                            ...campaignValuesError,
                            rewardTypeError: true,
                          });
                        } else {
                          
                          setCampaignValuesError({
                            ...campaignValuesError,
                            rewardTypeError: false,
                          });
                        }
                      }}
                      onRemove= {(event) => {
                        handleChangeRewards1(itemreward, event, "sponsor", index)
                        setSelectedSponsor(event)
                       
                        if (_.isEmpty(event)) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            rewardTypeError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            rewardTypeError: false,
                          });
                        }

                       }}
                      // value={selected1}
                      value={selectedSponsor}
                      style={{
                        multiselectContainer: {
                          boxShadow: "0px 3px 6px #00000029",
                        },
                        searchBox: {
                          minHeight: 54,
                          PaddingTop: 10
                        }
                      }}

                    />
                    {campaignValuesError?.rewardTypeError && (
                      <ErrorMessage message="Please enter the sponsor for the campaign" />
                    )}

            {/* } */}
          </div>

          <div className={classes.formGroup5}>
            <label htmlFor="create-yaad--title" className={classes.label}>
              Points <RedStar />
            </label>
            
            <input
              value={itemreward.points}
              type="text"
              id="create-yaad--title"
              className={classes.input}
              placeholder="Your Answer"
              onChange={(e) => handleChangeRewards1(itemreward, e, "points", index)}
              onBlur={(e) => {
                // console.log(e);
                // console.log(itemreward.points);
                if (!itemreward.points) {
                  // console.log("points empty")
                  setCampaignValuesError({
                    ...campaignValuesError,
                    pointsCampaignError: true,
                  });
                } else {
                  setCampaignValuesError({
                    ...campaignValuesError,
                    pointsCampaignError: false,
                  });
                }
              }}

            />
            {campaignValuesError?.pointsCampaignError && (
                      <ErrorMessage message="Please enter the points for this reward" />
                    )}
            {/* } */}
          </div>

        </form>
        {
          index ?
            <div style={{ float: "right", position: "relative", bottom: 44, cursor: "pointer", left: 19 }} onClick={(e) => handleRewardRemoveFields(index)}><RemoveCircleOutlineOutlined></RemoveCircleOutlineOutlined></div>
            : <div className="handlePlus" style={{ float: "right", bottom: 45, position: "relative", left: 20, cursor: "pointer", right: "15px" }}><AddCircleOutlineOutlined
              onClick={handleRewardAddFields}
            ></AddCircleOutlineOutlined></div>
        }
      </div>
    );
  };

  React.useEffect(() => {
    dispatch(updateCampaignReset());
  }, []);
  React.useEffect(() => {
    dispatch(getOrganization({ userid: localStorage.getItem("userid") }));
    getActivityList();
    getCampCategory();
    getCampSubCategory();
    getRewardTypeList();
  }, []);

  // 

  const locationPath = useLocation();
  const [selectedSkills, setSelectedSkills] = React.useState([]);
  const [selectedRewards, setSelectedRewards] = React.useState([]);
  const [selectedcampcat, setselectedcampcat] = React.useState([]);
  const [selectedcampsubcat, setselectedcampsubcat] = React.useState([]);

 
  React.useEffect(() => {
    if (locationPath?.state?.skill) {
      setSelectedSkills(locationPath?.state?.skill);
    }
    if (locationPath?.state?.type) {
      setselectedcampcat(locationPath?.state?.type);
    }
    if (locationPath?.state?.subtype) {
      setselectedcampsubcat(locationPath?.state?.subtype);
    }

    // if (locationPath?.state?.rewardstobeearned) {
    //   setSelectedRewards(locationPath?.state?.rewardstobeearned);
    // }
    if (locationPath?.state) {
      setCampaignValues({
        titleOfCampaign: locationPath?.state?.title,
        descriptionOfCampaign: locationPath?.state?.desc,
        typeOfCampaign: locationPath?.state?.type,
        
        volunteerCount: locationPath?.state?.targetvol,
        // impact: locationPath?.state?.impact,
        visibility: locationPath?.state.visibility,
        // startDate: moment(locationPath?.state?.startDate).format("YYYY-DD-MM"),
        // endDate: moment(locationPath?.state?.endDate).format("YYYY-DD-MM"),
        // startDate: moment(locationPath?.state?.startdate, "YYYY-MM-DD"),
        // endDate: moment(locationPath?.state?.enddate, "YYYY-MM-DD"),
        startDate: locationPath?.state?.startdate,
        endDate: locationPath?.state?.enddate,
        durationOfCampaign: locationPath?.state?.timeframe,
        goal: locationPath?.state?.goal,
        whattodo: locationPath?.state?.whattodo,
        guidelines: locationPath?.state?.guidelines,
        campaignType: locationPath?.state?.campaigntype,
        hourOfParticipation: locationPath?.state?.hourofparticipation,
        pointsToCurrency: locationPath?.state?.pointtocurrency,
        currencyType: locationPath?.state?.currencytype,
        totalBudget: locationPath?.state?.totalbudget,
        pointsPerReferral: locationPath?.state?.pointsperreferral,
        budgetPointForReferral: locationPath?.state?.budgetpointforreferral,
        socialMediaMessage: locationPath?.state?.socialmediamsg,
        campaignLogo: locationPath?.state?.logo,
        // banner: locationPath?.state?.banner,
        // campaignLogo: campaignValues.campaignLogo,
        campaignVideo: locationPath?.state?.video,
        rewardType: locationPath?.state?.rewardstobeearned,
        campid: locationPath?.state?.campid,
        skill: locationPath?.state?.skill,

      });
      setLocation(locationPath?.state?.location);
      setRewardtitle(locationPath?.state?.rewardstobeearned)
      // setFocusArea(JSON.parse(locationPath?.state?.rewardstobeearned))
    }
  }, [locationPath?.state]);
  

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  const colorStyles = {
    control: (styles) => ({...styles}),
    // option: (styles, {data, isDisabled, isFocussed, isSelected}) => {
    //   return{...styles, color: "blue"};
    // },
    multiValue: (styles, {data}) => {
      return {...styles, backgroundColor: "#0096FF",color:"#fff"};
    },
    multiValueLabel: (styles, {data}) => {
      return {...styles, color:"#fff"};
    },
    multiValueRemove: (styles, {data}) => {
      return {
        ...styles, 
        color:"#fff",
        cursor:"pointer",
        ':hover': {
          color:"#fff",
        }
      };
    },
  };

  const loadOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = campCategory.filter((category)=> 
        category.label.toLowerCase().includes(searchValue.toLowerCase())
      );
      callback(filteredOptions);

    },);
  };
  const loadSubOptions = (searchValue, callback) => {
    setTimeout(() => {
      const filteredOptions = campSubCategory.filter((subcategory)=> 
        subcategory.label.toLowerCase().includes(searchValue.toLowerCase())
      );
      callback(filteredOptions);

    },);
  };

  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <TopHeaderScreen {...props} />
        <div class="content content-wrap">
          <div class="content-mrg">
          <Button
                onClick={() => navigate(-1)}
                className={classes.backBtn}
            >
                <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
                <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            {/* <div style={{ fontFamily: "monospace", fontSize: 16, }}>
              <div style={{ display: "flex", width: "75%", justifyContent: "flex-end", marginRight: 5, marginBottom: 20, }}>
                <span style={{ color: "red" }}>Please do not leave this page until you submit as you may risk losing the partially filled in form </span>
              </div>
            </div> */}
            <div class="content-title" style={{ fontSize: 25, fontWeight: "600", marginBottom: 15, marginTop: 5 }}>Edit Campaign</div>


            {locationPath?.state?.logo &&
              (loading ? (
                <CircularProgress color="inherit" justifyContent= "center" size={16} />
                // <CircularProgress color="blue" size={16} />
              ) : (
                <div style={{
                  padding: "10px 0",
                  // height: "50%",
                  // boxShadow: "1px 1px 1px 5px #f0f0f0",
                  borderRadius: 30,
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center",
                  alignItems: "center",
                  // backgroundColor: "#fff"
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #dadce0",
                }}>
                  <span ><b>Update Campaign Logo</b></span>
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    // justifyContent= "center"
                    >
                      <Avatar
                        // {/* <img */}
                        src={
                          image
                            ? URL.createObjectURL(image)
                            : locationPath?.state?.logo
                          // : organizationValues?.organizationLogo
                        }
                        variant="rounded"
                        className={classes.large}
                        style={{ justifyContent: "center", alignItems: "center" }}
                      />
                    </IconButton>
                  </label>
                  <input
                    name="Upload your image"
                    accept="image/*"
                    className={classes.logobutton}
                    id="icon-button-file"
                    type="file"
                    onChange={handleChangeFile}
                    width="20"
                  />
                </div>
              ))}

            {locationPath?.state?.video &&
              (loadingVideo ? (
                <CircularProgress color="inherit" alignItems= "center" size={16} />
                // <CircularProgress color="blue" size={16} />
              ) : (
                <div style={{
                  padding: "10px 0",
                  // height: "50%",
                  // boxShadow: "1px 1px 1px 5px #f0f0f0",
                  borderRadius: 30,
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center",
                  alignItems: "center",
                  // backgroundColor: "#fff"
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #dadce0",
                }}>
                  <span ><b>Update Campaign Video</b></span>
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload video"
                      component="span"
                    // justifyContent= "center"
                    >
                      <Avatar
                        src={
                          video
                            ? URL.createObjectURL(video)
                            : locationPath?.state?.video
                          // : organizationValues?.organizationLogo
                        }
                        variant="rounded"
                        className={classes.large}
                        style={{ justifyContent: "center", alignItems: "center" }}
                      />
                    </IconButton>
                  </label>
                  <input
                    // ref={videoRef}
                    // value={campaignValues?.campaignVideo?.[0]?.name}

                    accept="video/*"
                    name="Upload your video"
                    className={classes.logobutton}
                    id="icon-button-file"
                    type="file"
                    onChange={handleChangeVideoFile}
                    width="20"
                  />
                  {videoLoaded === 1 &&
                  <span style={{color:"green"}}>Video uploaded</span>
                  }
                  
                </div>
              ))}


            {/* {locationPath?.state?.banner &&
              (loadingBanner ? (
                <CircularProgress color="inherit" size={16} />
                // <CircularProgress color="blue" size={16} />
              ) : (
                <div style={{
                  padding: "10px 0",
                  // height: "50%",
                  // boxShadow: "1px 1px 1px 5px #f0f0f0",
                  borderRadius: 30,
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center",
                  alignItems: "center",
                  // backgroundColor: "#fff"
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #dadce0",
                }}>
                  <span ><b>Update Campaign Banner</b></span>
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    // justifyContent= "center"
                    >
                      <Avatar
                        src={
                          banner
                            ? URL.createObjectURL(banner)
                            : locationPath?.state?.banner
                          // : organizationValues?.organizationLogo
                        }
                        className={classes.large}
                        style={{ justifyContent: "center", alignItems: "center" }}
                      />
                    </IconButton>
                  </label>
                  <input
                    name="Upload your banner"
                    accept="image/*"
                    className={classes.logobutton}
                    id="icon-button-file"
                    type="file"
                    onChange={handleBannerFile}
                    width="20"
                  />
                </div>
              ))} */}



            <div style={{
              // padding: "10px 0",
              // height: "50%",
              // boxShadow: "1px 1px 1px 5px #f0f0f0",
              // borderRadius: 30,
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              alignItems: "center",
              // backgroundColor: "#fff"
              // backgroundColor: "#FFFFFF",
              // border: "1px solid #dadce0",
            }}></div>
            <form autoComplete="off">
              <div style={{ border: "1px solid #dadce0", borderRadius: 8, backgroundColor: "#fff" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {/* {JSON.stringify(campaignValues)} */}
                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Name of Campaign<RedStar />
                    </label>

                    <input
                      value={campaignValues?.titleOfCampaign}
                      type="text"
                      className={classes.input}
                      placeholder="Your answer"
                      // maxLength="25"
                      autoFocus="true"
                      onBlur={() => {
                        if (!campaignValues?.titleOfCampaign) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            titleOfCampaignError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            titleOfCampaignError: false,
                          });
                        }
                      }}
                      onChange={(e) => {
                        handleCampaignFieldChange("titleOfCampaign", e);
                      }}
                    />
                    {campaignValuesError?.titleOfCampaignError && (
                      <ErrorMessage message="Please enter the title of campaign" />
                    )}
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Description <RedStar />
                    </label>
                    <input
                      value={campaignValues?.descriptionOfCampaign}
                      type="text"
                      className={classes.input}
                      placeholder="Your answer"
                      // maxLength="200"
                      onBlur={() => {
                        if (!campaignValues?.descriptionOfCampaign) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            descriptionOfCampaignError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            descriptionOfCampaignError: false,
                          });
                        }
                      }}
                      onChange={(e) => {
                        handleCampaignFieldChange("descriptionOfCampaign", e);
                      }}
                    />
                    {campaignValuesError?.descriptionOfCampaignError && (
                      <ErrorMessage message="Please enter the description of campaign" />
                    )}
                  </div>
                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Goal <RedStar />
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        value={campaignValues?.goal}
                        type="text"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        onBlur={() => {
                          if (!campaignValues?.goal) {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              goalError: true,
                            });
                          } else {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              goalError: false,
                            });
                          }
                        }}
                        onChange={(e) => {
                          handleCampaignFieldChange("goal", e);
                        }}
                      />
                    </div>
                    {campaignValuesError?.goalError && (
                      <ErrorMessage message="Please enter the goal" />
                    )}
                  </div>
                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Category <RedStar />
                    </label>
                    
                    <AsyncSelect
                      loadOptions={loadOptions}
                      defaultOptions ={campCategory}
                      value={selectedcampcat}
                      isMulti
                      
                      styles={colorStyles}
                      // onChange={(event) => {
                      //   setselectedcampcat(event);
                      //   if (_.isEmpty(event)) {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       typeOfCampaignError: true,
                      //     });
                      //   } else {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       typeOfCampaignError: false,
                      //     });
                      //   }
                      // }}
                      // onBlur={() => {
                      //   if (!selectedcampcat) {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       typeOfCampaignError: true,
                      //     });
                      //   } else {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       typeOfCampaignError: false,
                      //     });
                      //   }
                      // }}
                      />
                      {campaignValuesError?.typeOfCampaignError && (
                      <ErrorMessage message="Please select the campaign category" />
                    )}

                    {/* <Select
                      style={{
                        width: "100%",
                        fontSize: 14,
                        height: 56,
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                      }}
                      value={campaignValues?.typeOfCampaign}
                      
                      onChange={(e) => {
                        handleCampaignFieldChange("typeOfCampaign", e);
                      }}
                    >
                      {activityList?.map((item, index) => (
                        <MenuItem style={{ fontSize: 16 }} value={item.activity}>
                          {item.activity}
                        </MenuItem>
                      ))}
                    </Select> */}
                    {/* {campaignValuesError?.typeOfCampaignError && (
                      <ErrorMessage message="Please select the campaign category" />
                    )} */}
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Sub Category <RedStar />
                    </label>
                    
                    <AsyncSelect
                      loadOptions={loadSubOptions}
                      // loadOptions={locationPath?.state?.type}
                      defaultOptions ={campSubCategory}
                      value={selectedcampsubcat}
                      isMulti
                      styles={colorStyles}
                      
                      // onChange={(event) => {
                      //   setselectedcampsubcat(event);
                      //   if (_.isEmpty(event)) {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       subtypeOfCampaignError: true,
                      //     });
                      //   } else {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       subtypeOfCampaignError: false,
                      //     });
                      //   }
                      // }}
                      // onBlur={() => {
                      //   if (!selectedcampsubcat) {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       subtypeOfCampaignError: true,
                      //     });
                      //   } else {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       subtypeOfCampaignError: false,
                      //     });
                      //   }
                      // }}
                      />
                      {campaignValuesError?.subtypeOfCampaignError && (
                      <ErrorMessage message="Please select the campaign sub category" />
                    )}
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Type <RedStar />
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <Select
                        style={{
                          width: "100%",
                          fontSize: 14,
                          height: 56,
                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                        }}
                        value={campaignValues.campaignType}
                        // onBlur={() => {
                        //   if (!campaignValues?.campaignType) {
                        //     setCampaignValuesError({
                        //       ...campaignValuesError,
                        //       campaignTypeError: true,
                        //     });
                        //   } else {
                        //     setCampaignValuesError({
                        //       ...campaignValuesError,
                        //       campaignTypeError: false,
                        //     });
                        //   }
                        // }}
                        onChange={(e) =>
                          handleCampaignFieldChange("campaignType", e)
                        }
                      >
                        {campaignType.map((item, index) => (
                          <MenuItem style={{ fontSize: 16 }} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    {/* {campaignValuesError?.campaignTypeError && (
                      <ErrorMessage message="Please select the campaign type" />
                    )} */}
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Participation <RedStar />
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      {/* {JSON.stringify(campaignValues?.visibility)} */}
                      <Select
                        style={{
                          width: "100%",
                          fontSize: 14,
                          height: 56,
                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",

                        }}
                        value={campaignValues?.visibility}
                        // onBlur={() => {
                        //   if (!campaignValues?.visibility) {
                        //     setCampaignValuesError({
                        //       ...campaignValuesError,
                        //       visibilityError: true,
                        //     });
                        //   } else {
                        //     setCampaignValuesError({
                        //       ...campaignValuesError,
                        //       visibilityError: false,
                        //     });
                        //   }
                        // }}
                        onChange={(e) =>
                          handleCampaignFieldChange("visibility", e)
                        }
                      >
                        {campaignParticipate.map((item, index) => (
                          <MenuItem style={{ fontSize: 16 }} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    {/* {campaignValuesError?.visibilityError && (
                      <ErrorMessage message="Please select the campaign participate value" />
                    )} */}
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Number of volunteers Required <RedStar />
                    </label>

                    <input
                      value={campaignValues?.volunteerCount}
                      type="text"
                      id="create-yaad--title"
                      className={classes.input}
                      placeholder="Your answer"
                      onKeyPress={(e) => { preventMinus(e) }}
                      onBlur={() => {
                        if (!campaignValues?.volunteerCount || campaignValues?.volunteerCount == "-1") {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            volunteerCountError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            volunteerCountError: false,
                          });
                        }
                      }}
                      onChange={(e) => {
                        handleCampaignFieldChange("volunteerCount", e);
                      }}
                    />
                    {campaignValuesError?.volunteerCountError && (
                      <ErrorMessage message="Please enter the volunteer count" />
                    )}
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                    How to participate? <RedStar />
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        value={campaignValues?.whattodo}
                        type="text"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        onBlur={() => {
                          if (!campaignValues?.whattodo) {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              whattodoError: true,
                            });
                          } else {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              whattodoError: false,
                            });
                          }
                        }}
                        onChange={(e) => {
                          handleCampaignFieldChange("whattodo", e);
                        }}
                      />
                    </div>
                    {campaignValuesError?.whattodoError && (
                      <ErrorMessage message="Please enter the what to do" />
                    )}
                  </div>



                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Guidelines <RedStar />
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        value={campaignValues?.guidelines}
                        type="text"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        onBlur={() => {
                          if (!campaignValues?.guidelines) {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              guidelinesError: true,
                            });
                          } else {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              guidelinesError: false,
                            });
                          }
                        }}
                        onChange={(e) => {
                          handleCampaignFieldChange("guidelines", e);
                        }}
                      />
                    </div>
                    {campaignValuesError?.guidelinesError && (
                      <ErrorMessage message="Please enter the guidelines" />
                    )}
                  </div>


                  
                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Start Date <RedStar />
                    </label>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      {/* {JSON.stringify(campaignValues)} */}
                      <DatePicker
                        // minDate={new Date()}
                        inputFormat={"yyyy-MM-dd"}
                        // inputFormat={"YYYY-MM-DD"}
                        value={campaignValues?.startDate}
                        onChange={(newValue) => {
                          handleCampaignFieldChange("startDate", newValue);
                        }}
                        minDate={new Date()}
                        // maxDate={campaignValues.endDate}

                        renderInput={(params) => (
                          <TextField
                            style={{
                              width: "100%",
                              height: 56,
                              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      End Date <RedStar />
                    </label>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      {/* {JSON.stringify(campaignValues?.endDate)} */}
                      <DatePicker
                        // minDate={new Date()}
                        inputFormat={"yyyy-MM-dd"}
                        value={campaignValues.endDate}
                        onChange={(newValue) => {
                          handleCampaignFieldChange("endDate", newValue);
                        }}
                        // disabled={campaignValues.startDate=== "" ? true: false}
                        // minDate={new Date()}
                        minDate={campaignValues?.startDate}
                        //maxDate={}
                        renderInput={(params) => (
                          <TextField
                            style={{
                              width: "100%",
                              height: 56,
                              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>


                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      No. of Hours of Participation Required from a Volunteer <RedStar />
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        value={campaignValues?.hourOfParticipation}
                        type="text"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        min="0"
                        onKeyPress={(e) => { preventMinus(e) }}
                        // onKeyDown={(e) => { handleKeypress(e)}}
                        onBlur={() => {
                          if (!campaignValues?.hourOfParticipation || campaignValues?.hourOfParticipation == "-1") {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              hourOfParticipationError: true,
                            });
                          } else {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              hourOfParticipationError: false,
                            });
                          }
                        }}
                        onChange={(e) => {
                          handleCampaignFieldChange("hourOfParticipation", e);
                        }}
                      />
                    </div>
                    {campaignValuesError?.hourOfParticipationError && (
                      <ErrorMessage message="Please enter the hours of participation" />
                    )}
                  </div>


                  {/* <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Rewards to be Earned <RedStar />
                    </label> */}
                  {/* Hi {JSON.stringify(locationPath?.state?.rewardstobeearned)} */}
                  {/* <Multiselect
                      displayValue="rewards"
                      showCheckbox
                      selectedValues={locationPath?.state?.rewardstobeearned} */}

                  {/* // options={focusArea}
                      // onSelect={(event) => { */}
                  {/* //   setSelectedRewards(event);
                        // if (_.isEmpty(event)) {
                        //   setCampaignValuesError({
                        //     ...campaignValuesError,
                        //     rewardTypeError: true,
                        //   });
                        // } else {
                        //   setCampaignValuesError({
                        //     ...campaignValuesError,
                        //     rewardTypeError: false,
                        //   });
                        // }
                      // }}
                      // onBlur={(event) => {
                      //   if (_.isEmpty(event)) {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       rewardTypeError: true,
                      //     });
                      //   } else {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       rewardTypeError: false,
                      //     });
                      //   }
                      // }}
                    //   value={selectedRewards}
                    //   style={{
                    //     multiselectContainer: {
                    //       boxShadow: "0px 3px 6px #00000029",
                    //     },
                    //     searchBox: {
                    //       minHeight: 54,
                    //       PaddingTop: 10
                    //     }
                    //   }}

                    // /> */}

                  {/* <Select
                  onBlur={() => {
                    if (_.isEmpty(selected)) {
                      setFocusError(true);
                    } else {
                      setFocusError(false);
                    }
                  }}
                  style={{
                    width: 350,
                    fontSize: 14,
                    height: 56,
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  }}
                  multiple
                  value={selected.map((item) => item?.rewards)}
                  onChange={handleChangeRewards}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  {focusAreaList.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox checked={selected?.indexOf(option) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={option?.rewards} />
                    </MenuItem>
                  ))}
                </Select> */}

                  {/* {campaignValuesError?.rewardTypeError && (
                      <ErrorMessage message="Please select rewards to be earned" />
                    )} */}
                  {/* </div> */}

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Skills/Tags <RedStar />
                    </label>
                    {/* {JSON.stringify(locationPath?.state?.skill)} */}

                    <Multiselect
                      displayValue="skill"
                      showCheckbox
                      //    selectedValues={selected1}
                      selectedValues={locationPath?.state?.skill}
                      // selectedValues={campaignValues?.skill}
                      options={skill}
                      onSelect={(event) => {
                        // setSelected1(event);
                        setSelectedSkills(event)
                        // if (_.isEmpty(event)) {
                        //   setCampaignValuesError({
                        //     ...campaignValuesError,
                        //     setSkillError: true,
                        //   });
                        // } else {
                        //   setCampaignValuesError({
                        //     ...campaignValuesError,
                        //     setSkillError: false,
                        //   });
                        // }
                      }}
                      onRemove= {(event) => {
                        setSelectedSkills(event)
                       }}

                       onBlur={() => {
                        if (selectedSkills.length === 0) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            selectedSkillsError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            selectedSkillsError: false,
                          });
                        }
                      }}
                      // value={locationPath?.state?.skill}
                      value={selectedSkills}

                      style={{
                        multiselectContainer: {
                          boxShadow: "0px 3px 6px #00000029",
                        },
                        searchBox: {
                          minHeight: 54,
                          PaddingTop: 10
                        }
                      }}


                    />
                    {/* {locationPath?.state} */}

                    {/* <Select
                  onBlur={() => {
                    if (_.isEmpty(selected1)) {
                      setFocusError(true);
                    } else {
                      setFocusError(false);
                    }
                  }}
                  style={{
                    width: 350,
                    fontSize: 14,
                    height: 56,
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  }}
                  multiple
                  value={selected1.map((item) => item?.skill)}
                  onChange={handleChangeSkill}
                  renderValue={(selected1) => selected1.join(", ")}
                  MenuProps={MenuProps}
                >
                  {skill.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox checked={selected1?.indexOf(option) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={option?.skill} />
                    </MenuItem>
                  ))}
                </Select> */}

                    {selectedSkills.length === 0 && (
                      <ErrorMessage message="Please select skills/tags" />
                    )}
                  </div>

                  {/* <div className={classes.formGroup} style={{ width: "25%" }}>
                <label htmlFor="create-yaad--title" className={classes.label}>
                  Duration (In Days)
                </label> */}
                  {/* <Select
                  style={{
                    width: 200,
                    fontSize:14,
                    height: 56,
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                  }}
                  value={campaignValues.durationOfCampaign}
                  onChange={(e) =>
                    handleCampaignFieldChange("durationOfCampaign", e)
                  }
                >
                  {activityDuration.map((item, index) => (
                    <MenuItem style={{ fontSize: 16 }} value={item.value}>
                      {item.value}
                    </MenuItem>
                  ))}
                </Select> */}
                  {/* <input
                  className={classes.input}
                  placeholder="Your Answer"
                  value={campaignValues?.durationOfCampaign}
                  style={{ fontStyle: "normal" }}
                  type="text"
                  onBlur={() => {
                    if (!campaignValues?.durationOfCampaign) {
                      setCampaignValuesError({
                        ...campaignValuesError,
                        durationOfCampaignError: true,
                      });
                    } else {
                      setCampaignValuesError({
                        ...campaignValuesError,
                        durationOfCampaignError: false,
                      });
                    }
                  }}
                  onChange={(e) => {
                    handleCampaignFieldChange("durationOfCampaign", e);
                  }}
                />
                {campaignValuesError?.durationOfCampaignError && (
                  <ErrorMessage message="Please enter the duration in days" />
                )}
              </div> */}
              

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Social Media Message<RedStar />
                    </label>
                    <textarea
                      value={campaignValues?.socialMediaMessage}
                      type="text"
                      id="create-yaad--title"
                      className={classes.input}
                      placeholder="Your answer"
                      // maxLength="500"
                      // onBlur={() => {
                      //   if (!campaignValues?.socialMediaMessage) {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       socialMediaMessageError: true,
                      //     });
                      //   } else {
                      //     setCampaignValuesError({
                      //       ...campaignValuesError,
                      //       socialMediaMessageError: false,
                      //     });
                      //   }
                      // }}
                      onChange={(e) => {
                        handleCampaignFieldChange("socialMediaMessage", e);
                      }}
                    />
                    {campaignValuesError?.socialMediaMessageError && (
                      <ErrorMessage message="Please enter the social media message" />
                    )}
                  </div>


                  {/* <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Logo <RedStar />
                    </label>
                    {JSON.stringify(campaignValues?.campaignLogo?.[0]?.name)}
                    <input
                      ref={inputRef}
                      selected={campaignValues?.campaignLogo?.[0]?.name}
                      // value={campaignValues?.campaignLogo?.[0]?.name}
                      value={campaignValues?.campaignLogo?.[0]?.name}
                      type="file"
                      accept="image/*"
                      id="example-file"
                      className={classes.input}
                      placeholder="Your answer"
                      onBlur={() => {
                        if (!campaignValues?.socialMediaMessage) {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            campaignLogoError: true,
                          });
                        } else {
                          setCampaignValuesError({
                            ...campaignValuesError,
                            campaignLogoError: false,
                          });
                        }
                      }}
                      onChange={(e) => {
                        handleCampaignFieldChange("campaignLogo", e);
                      }}
                    />
                    {campaignValuesError?.campaignLogoError && (
                      <ErrorMessage message="Please enter the social media message" />
                    )}
                  </div> */}


                  {/* <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Campaign Video <RedStar />
                    </label>
                    <input
                      ref={videoRef}
                      value={campaignValues?.campaignVideo?.[0]?.name}
                      type="file"
                      accept="video/*"
                      id="create-yaad--title"
                      className={classes.input}
                      onChange={(e) => {
                        handleCampaignFieldChange("campaignVideo", e);
                      }}
                      progress="line"
                    />
                  </div> */}


                  {/* <div className={classes.formGroupFull}>
                    {rewardtitle.map((itemreward, index) => (
                      <div key={index}>{getRewardDetails(itemreward, index)}</div>
                    ))}
                  </div> */}


                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Reward budget for the campaign <RedStar />
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <input
                        value={campaignValues?.totalBudget}
                        type="number"
                        id="create-yaad--title"
                        className={classes.input}
                        placeholder="Your Answer"
                        min="0"
                        onKeyPress={(e) => { preventMinus(e) }}
                        // onKeyDown={(e) => { handleKeypress(e)}}
                        onBlur={() => {
                          if (!campaignValues?.totalBudget) {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              totalBudgetError: true,
                            });
                          } else {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              totalBudgetError: false,
                            });
                          }
                        }}
                        onChange={(e) => {
                          handleCampaignFieldChange("totalBudget", e);
                        }}
                      />
                    </div>
                    {campaignValuesError?.totalBudgetError && (
                      <ErrorMessage message="Please enter the total budget for the campaign" />
                    )}
                  </div>

                  <div className={classes.formGroup}>
                    <label htmlFor="create-yaad--title" className={classes.label}>
                      Currency Type <RedStar />
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <Select
                        style={{
                          width: "100%",
                          fontSize: 14,
                          height: 56,
                          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                        }}
                        value={campaignValues.currencyType}
                        onBlur={() => {
                          if (!campaignValues?.currencyType) {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              currencyTypeError: true,
                            });
                          } else {
                            setCampaignValuesError({
                              ...campaignValuesError,
                              currencyTypeeError: false,
                            });
                          }
                        }}
                        onChange={(e) =>
                          handleCampaignFieldChange("currencyType", e)
                        }
                      >
                        {currencyType.map((item, index) => (
                          <MenuItem style={{ fontSize: 16 }} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    {campaignValuesError?.currencyTypeError && (
                      <ErrorMessage message="Please select the currency type value" />
                    )}
                  </div>

                  {/* ************* {JSON.stringify(location)} */}
                  <div className={classes.formGroupFull}>
                    <div className={classes.formGroup2}>
                      <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 500 }}>Operational Areas</span> <span style={{ paddingLeft: 0, fontSize: 16, fontWeight: 300, fontStyle: "italic" }}> (Search for location, select and click on Add to confirm. Click on "+" icon to enter additional locations)</span>
                      <PlacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelect}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) =>
                          <div style={{ padding: "25px 0px 0px 0px" }}>

                            {/* <input {...getInputProps({ placeholder: "Type Address", autoFocus: true, endAdornment:<SearchIcon />  })} /> */}
                            <TextField
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment>
                                    <IconButton>
                                      <SearchIcon />
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                              {...getInputProps({ placeholder: "Search for location..", autoFocus: false })} />
                            <div> {loading ? <div>...loading...</div> : null}</div>
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active ? "blue" : "white"
                              }
                              return (
                                <div {...getSuggestionItemProps(suggestion, { style })}>
                                  {suggestion.description}

                                </div>
                              )
                            })}
                          </div>

                        }

                      </PlacesAutocomplete>
                    </div>

                    {location.map((item, index) => (
                      <div key={index}>{getAddress(item, index)}</div>
                    ))}
                   
                  </div>
                </div>
                <div>
                  {isCampaignSuccess &&

                    <span className={classes.formGroupFull} style={{ color: "green", textAlign: "center", display: "block", }}>{campaignResponse?.message}</span>
                  }
                  {isCampaignError &&
                    <span className={classes.formGroupFull} style={{ color: "red", textAlign: "center", display: "block", }}>{campaignResponse?.message}</span>

                  }
                  {campaignValuesError?.locationError && (
                <span style={{ color: "red", textAlign: "center", display: "block" }}>Please search for location, select it and click on "Add"</span>
              )
                  }
                </div>
                <div className="centerAlign" style={{ padding: "16px 0", marginBottom: 100 }}>
                  <Button
                    variant="contained"
                    // onClick={() => handleSave()}
                    onClick={() => handleOnboardCampaign()}
                    className="btn save-btn"
                    disableElevation
                    disabled={isCampaignFetching}
                  >
                    {isCampaignFetching ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      <span>Submit</span>
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => navigate("/reviewcampaign")}
                    style={{
                      // marginTop: 1,
                      width: "150px",
                      height: "50px",
                      background: "white",
                      // borderRadius: 20,
                      // color: "#fff",
                      color: "red",
                      fontSize: 16,
                      // borderBlockColor: "orange",
                      // borderBlock: 5,
                      fontFamily: "Poppins !important",
                      marginBottom: "50px",
                    }}
                  >
                    <span>Cancel</span>
                  </Button>
                  <br />
                 
                </div>
               
              </div>
            </form>

          </div></div>
        <CopyRight />
      </section>


    </div>
  );
}
export default EditCampaignScreen1;
