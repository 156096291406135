import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const FixedFooterIcon = (props) => {
    const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
      (state) => state.profile
    );
    const navigate = useNavigate();
    return (
      <div class="fixed-bottom-sec-mob text-center">
      <div class="row">
          <div class="col-3">
              <div onClick={() => navigate('/home')}>
                  <i class="flaticon-home-line"></i>
                  <h6>Home</h6>
              </div>
          </div>
          <div class="col-3">
                <div onClick={() => navigate('/searchpage')}>
                  {/* <div class="disabledIcon"> */}
                    <i class="flaticon-search"></i>
                    <h6>Search</h6>
                </div>
                  
          </div>
          <div class="col-3">
              <div class="disabledIcon">
                  <i class="flaticon-chat-bubble"></i>
                  <h6>Chat</h6>
              </div>
          </div>
          <div class="col-3">
              <div class="disabledIcon">
                  <i class="flaticon-users"></i>
                  <h6>Team</h6>
              </div>
          </div>
      </div>
  </div>
    );
  };