import {
  makeStyles,
  Checkbox,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Chip,
  CheckCircle,
  Grid,
  Box,
} from "@material-ui/core";
import {
  Avatar,
  FormControl,
  Select,
  MenuItem,
  CssBaseline,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import CustomDrawer from "../../components/CustomHeader";
import ResponsiveDrawer from "../../components/Header";
import { execMenuItems, volunteerMenuItems } from "../../utils/dict";
import { MenuProps, states } from "../../utils/constants";
import { getCity } from "../../redux/action/cityAction";
import TopHeaderScreen from "../../components/TopHeader1";
import { CopyRight } from "../../components/CopyRight";
import {
  activityDuration,
  campaignParticipate,
  campaignType,
  restrictedCountry,
} from "../../utils/dict";
import { Link } from "react-router-dom";
import { ColorModeContext, useMode, tokens } from "../../theme";
import { ThemeProvider } from "@emotion/react";
import axios from "axios";
import { VolStatsFileListTableComponent } from "../../components/Table";

const useStyles = makeStyles((theme) => ({
  homeSreenMainContent: {
    padding: "80px 0 100px 0px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      padding: "0px 30px 100px 25px",
      background: "#F5F8FB",
    },
  },
  large: {
    width: "150px !important",
    height: "150px !important",
  },
  input: {
    // textTransform: "capitalize",
    // width: "100%",
    width: "100%",
    border: "none",
    padding: 10,
    display: "block",
    borderRadius: 6,
    height: 54,
    // boxShadow: "0px 3px 6px #00000029",
    // backgroundColor: "#fff",
    backgroundColor: "#FFFFFF",
    // color: "#949494",
    color: "#0F0F0F",
    fontSize: 17,
    // fontWeight: "520",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },

  input1: {
    // textTransform: "capitalize",
    width: "25%",
    border: "none",
    padding: 7,
    display: "block",
    borderRadius: 6,
    height: 0,
    // boxShadow: "0px 3px 6px #00000029",
    // backgroundColor: "#fff",
    backgroundColor: "#FFFFFF",
    // color: "#949494",
    color: "#0F0F0F",
    fontSize: 17,
    // fontWeight: "520",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
    marginLeft: 10,
  },

  goalButton: {
    fontSize: 15,
    fontWeight: "bold",
    maxWidth: 107,
    lineHeight: 1.2,
    marginLeft: 40,
  },

  label: {
    "& .css-u4tvz2-MuiFormLabel-root": {
      fontFamily: "Poppins",
    },
    fontFamily: "Poppins",
    display: "block",

    fontSize: 17,
    marginTop: 16,
    padding: 10,
    fontWeight: "500",
    lineHeight: "15px",
    minHeight: 45,
    paddingBottom: 0,
    color: "#808080",
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: 14,
    // },
  },
  formGroup: {
    "& .react-datepicker__input-container": {
      "& input": {
        height: 40,
      },
    },

    transition: "background-color 200ms cubic-bezier(0.0,0.0,0.2,1)",
    // backgroundColor: "#fff",
    backgroundColor: "#FFFFFF",

    borderRadius: "8px",
    marginBottom: "4px",
    padding: "0 16px 0px 16px",
    pageBreakInside: "avoid",
    marginBottom: 25,
    [theme.breakpoints.only("xl")]: {
      width: "25%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.only("md")]: {
      width: "50%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  pointBox: {
    // top: "22px",
    // left: "200px",
    // top: "22%",
    minWidth: "50px",
    width: "150px",
    maxHeight: "130px",
    // height: "150px",
    // background: "#1A524D 0% 0% no-repeat padding-box",
    backgroundColor: "rgba(235, 149, 50, 1)",
    borderRadius: "20px",
    opacity: "90%",
    color: "#FFFFFF",
    fontSize: 15,
    fontWeight: "bold",
    padding: "20px 10px 20px 10px",
  },
  name: {
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
  },
  chipsize: {
    fontSize: "30px",
  },
  g1: {
    padding: "0px 0px 0px 480px",
    [theme.breakpoints.only("xl")]: {
      padding: "0px 0px 0px 360px",
    },
    [theme.breakpoints.only("lg")]: {
      padding: "0px 0px 0px 240px",
    },
    [theme.breakpoints.only("md")]: {
      padding: "0px 0px 0px 240px",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "0px 0px 0px 120px",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "0px 0px 0px 100px",
    },
  },
}));

const VolParticipationStat = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationPath = useLocation();
  const [theme, colorMode] = useMode();
  const [image, setImage] = React.useState(null);
  const [campWithFilesList, setCampWithFilesList] = useState([]);
  const [volList, setVolList] = useState([]);

  const { isSuccess: profileSuccess, response: profileResponse } = useSelector(
    (state) => state.profile
  );

  const [location, setLocation] = React.useState([
    { country: "", state: "", city: "", area: "" },
  ]);

  // This function will called only once
  useEffect(() => {
    dispatch(getCity(profileResponse?.state));
  }, []);

  
  const getCampFileList = async () => {
    try {
      // const res = await axios.get(
      //   `/camp/video/${selectedCategory}/${localStorage.getItem("userid")}`
      // );
      const res = await axios.get(
        `/list/myfiles/campaignwithid/${
          locationPath?.pathname.split("/")?.[6]
        }/${locationPath?.pathname.split("/")?.[4]}`
      );

     
      return res;
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    getCampFileList().then((data) => {
      setCampWithFilesList(data);
    });
  }, []);

  const getVolDetailst = async () => {
    try {
      // const res = await axios.get(
      //   `/camp/video/${selectedCategory}/${localStorage.getItem("userid")}`
      // );
      const res = await axios.get(
        `/cyaaguser/${locationPath?.pathname.split("/")?.[4]}`
      );

     
      return res;
    } catch (err) {
      throw err;
    }
  };
  useEffect(() => {
    getVolDetailst().then((data) => {
      setVolList(data);
    });
  }, []);


  //dispatch(getCity(profileResponse?.stat));
  return (
    <div className="wrapper">
      <ResponsiveDrawer {...props} />
      <section className="containers">
        <ColorModeContext.Provider value={colorMode}>
          <TopHeaderScreen {...props} />
        </ColorModeContext.Provider>

        <div class="content content-wrap">
          <div class="content-mrg">
            <Button onClick={() => navigate(-1)} className={classes.backBtn}>
              <i className={`fa fa-caret-left ${classes.pageHeadingIcon}`} />
              <span style={{ paddingLeft: 3, fontSize: 14 }}>Back</span>
            </Button>
            <main
              onClick={() => {
                props.setNotificationShow(false);
              }}
              className={classes.homeSreenMainContent}
              id="homepage-main"
            >
              <span
                style={{
                  fontSize: 25,
                  fontWeight: "600",
                  marginBottom: 15,
                  marginTop: 5,
                }}
              >
                Participation Statistics
              </span>
              <div
                style={{
                  boxShadow: "1px 1px 1px 5px #f0f0f0",
                  borderRadius: 8,
                  paddingTop: 30,
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#fff",
                }}
              >
                <Avatar
                  src={volList?.data?.profilephoto}
                  className={classes.large}
                />
                <Typography className={classes.name} variant="h1">
                  {volList?.data?.firstname} {volList?.data?.lastname}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  {volList?.data?.phoneno}
                </Typography>
                {campWithFilesList?.data?.map((item) => (
                  <div className={classes.pointBox}>
                    <div
                      style={{
                        fontSize: 14,
                        textAlign: "center",
                        color: "#36454F",
                      }}
                    >
                      Total Files uploaded
                    </div>
                    <div
                      style={{
                        fontSize: 30,
                        textAlign: "center",
                        textTransform: "uppercase",
                      }}
                    >
                      <Chip
                        key={item?.files.length}
                        fontSize="30"
                        label={item?.files.length}
                        className={classes.chipsize}
                      />
                      {/* {item?.files.length}  */}
                    </div>
                  </div>
                ))}

                <div
                  style={{
                    margin: "20 auto",
                    marginBottom: 10,
                    textAlign: "center",
                  }}
                >
                  <VolStatsFileListTableComponent rows={campWithFilesList} />
                </div>

                {/* <Grid container> */}
                {/* <Grid item xs={12} sm={8} md={6} lg={3} xl={3}> */}
                {/* <Grid item mx={5} my={5}> */}

                {/* <Grid item mx={10} my={2}  xs={1}> */}
                {/* <Grid  item className={classes.g1}>
                  {campWithFilesList?.data?.map((item) => (
                    
                    <div className={classes.pointBox} >
                      <div style={{fontSize: 14, textAlign: "center", color:"#36454F"}} >
                      Total Files uploaded
                      </div>
                      <div style={{fontSize: 30, textAlign: "center", textTransform:"uppercase"}} >
                      <Chip key={item?.files.length} fontSize="30" label={item?.files.length} className={classes.chipsize} />
                      
                      </div>
                    </div>
                    ))}
                    
                                      
                  </Grid> */}
                {/* <Grid xs={12}> */}
                {/* <div style={{
                        margin: "20 auto",
                        marginBottom: 10,
                        textAlign: "center",
                      }}>
                  <VolStatsFileListTableComponent rows={campWithFilesList}  />
                  </div> */}
                {/* </Grid> */}

                {/* </Grid> */}
              </div>
            </main>
          </div>
        </div>
        <CopyRight />
      </section>
    </div>
  );
};

export default VolParticipationStat;
